{
  "name": "mamar-web",
  "version": "1.0.0",
  "description": "Paper Mario music editor",
  "repository": "https://github.com/bates64/mamar",
  "author": "Alex Bates <alex@bates64.com>",
  "private": true,
  "scripts": {
    "start": "parcel src/index.html src/app/index.html",
    "build": "parcel build src/index.html src/app/index.html",
    "lint": "yarn run tsc --noEmit && yarn run eslint ."
  },
  "dependencies": {
    "@adobe/react-spectrum": "^3.21.2",
    "@parcel/service-worker": "^2.7.0",
    "@react-hook/resize-observer": "^1.2.6",
    "@spectrum-icons/workflow": "^4.0.2",
    "@types/stats.js": "^0.17.0",
    "browser-fs-access": "^0.31.0",
    "classnames": "^2.3.2",
    "idb-keyval": "^6.2.0",
    "immer": "^9.0.15",
    "mamar-wasm-bridge": "*",
    "mupen64plus-web": "bates64/mupen64plus-web#mamar",
    "patches": "*",
    "pm64-typegen": "*",
    "react": "^18.2.0",
    "react-aria": "^3.19.0",
    "react-beautiful-dnd": "^13.1.1",
    "react-dom": "^18.2.0",
    "react-feather": "^2.0.10",
    "react-movable": "^3.0.4",
    "react-stately": "^3.17.0",
    "react-tracked": "^1.7.10",
    "react-window": "^1.8.7",
    "use-undoable": "^3.3.11",
    "web-vitals": "^3.0.3"
  },
  "devDependencies": {
    "@axe-core/react": "^4.4.4",
    "@parcel/optimizer-data-url": "2.7.0",
    "@parcel/packager-raw-url": "^2.7.0",
    "@parcel/transformer-inline-string": "2.7.0",
    "@parcel/transformer-sass": "2.7.0",
    "@parcel/transformer-svg-react": "^2.7.0",
    "@parcel/transformer-webmanifest": "^2.7.0",
    "@types/node": "^18.7.18",
    "@types/react": "^18.0.20",
    "@types/react-beautiful-dnd": "^13.1.2",
    "@types/react-dom": "^18.0.6",
    "@types/react-window": "^1.8.5",
    "eslint": "^8.23.1",
    "eslint-config-react-app": "^7.0.1",
    "parcel": "^2.7.0",
    "parcel-reporter-multiple-static-file-copier": "^1.0.5",
    "process": "^0.11.10",
    "typescript": "^4.8.3"
  }
}
