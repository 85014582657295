.input {
    color: inherit;
    background: transparent;
    border: 0;
    font: inherit;
    text-align: center;

    cursor: text;

    &:active,
    &:focus {
        color: var(--yellow);
        outline: none;
    }
}
