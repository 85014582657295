// Generated by build.py

export const ASM_PATCH_state_step_logos = new Uint32Array([
    0x27bdffe8, // addiu sp,sp,-24
    0xafbf0010, // sw ra,16(sp)
    0x0c00cd3c, // jal 800334f0 <set_game_mode>
    0x24040002, // li a0,2
    0x24040010, // li a0,16
    0x0c051cda, // jal 80147368 <play_ambient_sounds>
    0x0000282d, // 0x282d
    0x8fbf0010, // lw ra,16(sp)
    0x03e00008, // jr ra
    0x27bd0018, // addiu sp,sp,24
])

export const ASM_PATCH_state_step_title_screen = new Uint32Array([
    0x27bdffd0, // addiu sp,sp,-48
    0x0000202d, // 0x202d
    0x0080382d, // 0x80382d
    0xafb00018, // sw s0,24(sp)
    0x0080802d, // 0x80802d
    0x3c058042, // lui a1,0x8042
    0x8ca50470, // lw a1,1136(a1)
    0x3c068042, // lui a2,0x8042
    0x8cc60474, // lw a2,1140(a2)
    0x24020008, // li v0,8
    0xafbf002c, // sw ra,44(sp)
    0xafb40028, // sw s4,40(sp)
    0xafb30024, // sw s3,36(sp)
    0xafb20020, // sw s2,32(sp)
    0xafb1001c, // sw s1,28(sp)
    0x0c052a46, // jal 8014a918 <bgm_set_song>
    0xafa20010, // sw v0,16(sp)
    0x3c048042, // lui a0,0x8042
    0x8c840478, // lw a0,1144(a0)
    0x0c051cda, // jal 80147368 <play_ambient_sounds>
    0x0000282d, // 0x282d
    0x3c02800a, // lui v0,0x800a
    0x8c42a664, // lw v0,-22940(v0)
    0x3c058042, // lui a1,0x8042
    0x24a504c4, // addiu a1,a1,1220
    0x0040382d, // 0x40382d
    0x00e0302d, // 0xe0302d
    0x8ce20068, // lw v0,104(a3)
    0x8ce30064, // lw v1,100(a3)
    0x8ce400b0, // lw a0,176(a3)
    0x00431023, // subu v0,v0,v1
    0x3c018042, // lui at,0x8042
    0xac2404bc, // sw a0,1212(at)
    0x3c018042, // lui at,0x8042
    0xac2204c0, // sw v0,1216(at)
    0x8cc3025c, // lw v1,604(a2)
    0x50600004, // beqzl v1,804000cc <PATCH_state_step_title_screen+0xa4>
    0xaca00000, // sw zero,0(a1)
    0x8ce20064, // lw v0,100(a3)
    0x00621023, // subu v0,v1,v0
    0xaca20000, // sw v0,0(a1)
    0x24a50004, // addiu a1,a1,4
    0x26100001, // addiu s0,s0,1
    0x2a020010, // slti v0,s0,16
    0x1440fff6, // bnez v0,804000b4 <PATCH_state_step_title_screen+0x8c>
    0x24c60060, // addiu a2,a2,96
    0x0000902d, // 0x902d
    0x0240802d, // 0x240802d
    0x24030002, // li v1,2
    0x00101080, // sll v0,s0,0x2
    0x3c018042, // lui at,0x8042
    0x00220821, // addu at,at,v0
    0x8c22047c, // lw v0,1148(at)
    0x14430003, // bne v0,v1,8040010c <PATCH_state_step_title_screen+0xe4>
    0x26100001, // addiu s0,s0,1
    0x08100046, // j 80400118 <PATCH_state_step_title_screen+0xf0>
    0x24120001, // li s2,1
    0x2a020010, // slti v0,s0,16
    0x1440fff7, // bnez v0,804000f0 <PATCH_state_step_title_screen+0xc8>
    0x00101080, // sll v0,s0,0x2
    0x0000802d, // 0x802d
    0x24140002, // li s4,2
    0x24130001, // li s3,1
    0x2411025c, // li s1,604
    0x00101080, // sll v0,s0,0x2
    0x3c018042, // lui at,0x8042
    0x00220821, // addu at,at,v0
    0x8c22047c, // lw v0,1148(at)
    0x10540005, // beq v0,s4,80400150 <PATCH_state_step_title_screen+0x128>
    0x0000302d, // 0x302d
    0x10530005, // beq v0,s3,80400158 <PATCH_state_step_title_screen+0x130>
    0x0000382d, // 0x382d
    0x16400003, // bnez s2,80400158 <PATCH_state_step_title_screen+0x130>
    0x00000000, // nop
    0x24060064, // li a2,100
    0x0000382d, // 0x382d
    0x3c04800a, // lui a0,0x800a
    0x8c84a664, // lw a0,-22940(a0)
    0x26100001, // addiu s0,s0,1
    0x0c014222, // jal 80050888 <func_80050888>
    0x00912821, // addu a1,a0,s1
    0x2a020010, // slti v0,s0,16
    0x1440ffed, // bnez v0,80400128 <PATCH_state_step_title_screen+0x100>
    0x26310060, // addiu s1,s1,96
    0x8fbf002c, // lw ra,44(sp)
    0x8fb40028, // lw s4,40(sp)
    0x8fb30024, // lw s3,36(sp)
    0x8fb20020, // lw s2,32(sp)
    0x8fb1001c, // lw s1,28(sp)
    0x8fb00018, // lw s0,24(sp)
    0x03e00008, // jr ra
    0x27bd0030, // addiu sp,sp,48
])

export const ASM_PATCH_appendGfx_title_screen = new Uint32Array([
    0x03e00008, // jr ra
    0x00000000, // nop
])

export const ASM_MAMAR_au_load_song_files = new Uint32Array([
    0x27bdffc0, // addiu sp,sp,-64
    0xafb60038, // sw s6,56(sp)
    0x0080b02d, // 0x80b02d
    0xafb3002c, // sw s3,44(sp)
    0x00a0982d, // 0xa0982d
    0xafb50034, // sw s5,52(sp)
    0x00c0a82d, // 0xc0a82d
    0xafb20028, // sw s2,40(sp)
    0x3c12800a, // lui s2,0x800a
    0x8e52a5c0, // lw s2,-23104(s2)
    0x24050010, // li a1,16
    0xafbf003c, // sw ra,60(sp)
    0xafb40030, // sw s4,48(sp)
    0xafb10024, // sw s1,36(sp)
    0xafb00020, // sw s0,32(sp)
    0x8e42002c, // lw v0,44(s2)
    0x001618c0, // sll v1,s6,0x3
    0x00431021, // addu v0,v0,v1
    0x94440000, // lhu a0,0(v0)
    0x0c01511f, // jal 8005447c <au_fetch_SBN_file>
    0x03a53021, // addu a2,sp,a1
    0x1440007f, // bnez v0,804003f4 <MAMAR_au_load_song_files+0x254>
    0x00000000, // nop
    0x0c0136ca, // jal 8004db28 <func_8004DB28>
    0x02a0202d, // 0x2a0202d
    0x1440007b, // bnez v0,804003f4 <MAMAR_au_load_song_files+0x254>
    0x240200c9, // li v0,201
    0x3c028042, // lui v0,0x8042
    0x8c420460, // lw v0,1120(v0)
    0x1840004e, // blez v0,80400350 <MAMAR_au_load_song_files+0x1b0>
    0x24020042, // li v0,66
    0x3c048040, // lui a0,0x8040
    0x24840460, // addiu a0,a0,1120
    0x90830000, // lbu v1,0(a0)
    0x1462000e, // bne v1,v0,80400264 <MAMAR_au_load_song_files+0xc4>
    0x0000302d, // 0x302d
    0x90830001, // lbu v1,1(a0)
    0x24020047, // li v0,71
    0x14620009, // bne v1,v0,80400260 <MAMAR_au_load_song_files+0xc0>
    0x2402004d, // li v0,77
    0x90830002, // lbu v1,2(a0)
    0x14620006, // bne v1,v0,80400260 <MAMAR_au_load_song_files+0xc0>
    0x00000000, // nop
    0x90820003, // lbu v0,3(a0)
    0x38420020, // xori v0,v0,0x20
    0x2c460001, // sltiu a2,v0,1
    0x3c048040, // lui a0,0x8040
    0x24840460, // addiu a0,a0,1120
    0x90830000, // lbu v1,0(a0)
    0x24020020, // li v0,32
    0x1462000b, // bne v1,v0,80400298 <MAMAR_au_load_song_files+0xf8>
    0x0000282d, // 0x282d
    0x90830001, // lbu v1,1(a0)
    0x2402004d, // li v0,77
    0x14620007, // bne v1,v0,80400298 <MAMAR_au_load_song_files+0xf8>
    0x24020047, // li v0,71
    0x90830002, // lbu v1,2(a0)
    0x14620004, // bne v1,v0,80400298 <MAMAR_au_load_song_files+0xf8>
    0x00000000, // nop
    0x90820003, // lbu v0,3(a0)
    0x38420042, // xori v0,v0,0x42
    0x2c450001, // sltiu a1,v0,1
    0x10c00009, // beqz a2,804002c0 <MAMAR_au_load_song_files+0x120>
    0x00000000, // nop
    0x3c048040, // lui a0,0x8040
    0x24840460, // addiu a0,a0,1120
    0x3c068042, // lui a2,0x8042
    0x8cc60460, // lw a2,1120(a2)
    0x0c0153d2, // jal 80054f48 <au_copy_bytes>
    0x0260282d, // 0x260282d
    0x081000c9, // j 80400324 <MAMAR_au_load_song_files+0x184>
    0x3c034247, // lui v1,0x4247
    0x10a00017, // beqz a1,80400320 <MAMAR_au_load_song_files+0x180>
    0x0000802d, // 0x802d
    0x3c028042, // lui v0,0x8042
    0x8c420460, // lw v0,1120(v0)
    0x3c068040, // lui a2,0x8040
    0x24c60460, // addiu a2,a2,1120
    0x18400011, // blez v0,80400320 <MAMAR_au_load_song_files+0x180>
    0x0260282d, // 0x260282d
    0x02062021, // addu a0,s0,a2
    0x90820003, // lbu v0,3(a0)
    0x00b01821, // addu v1,a1,s0
    0xa0620000, // sb v0,0(v1)
    0x90820002, // lbu v0,2(a0)
    0xa0620001, // sb v0,1(v1)
    0x90820001, // lbu v0,1(a0)
    0xa0620002, // sb v0,2(v1)
    0x90820000, // lbu v0,0(a0)
    0xa0620003, // sb v0,3(v1)
    0x3c028042, // lui v0,0x8042
    0x8c420460, // lw v0,1120(v0)
    0x26100004, // addiu s0,s0,4
    0x0202102a, // slt v0,s0,v0
    0x1440fff2, // bnez v0,804002e4 <MAMAR_au_load_song_files+0x144>
    0x02062021, // addu a0,s0,a2
    0x3c034247, // lui v1,0x4247
    0x8e620000, // lw v0,0(s3)
    0x34634d20, // ori v1,v1,0x4d20
    0x1043000f, // beq v0,v1,8040036c <MAMAR_au_load_song_files+0x1cc>
    0x2404004c, // li a0,76
    0x3c018042, // lui at,0x8042
    0xac200460, // sw zero,1120(at)
    0x0260282d, // 0x260282d
    0x0c100068, // jal 804001a0 <MAMAR_au_load_song_files>
    0x02a0302d, // 0x2a0302d
    0x081000fd, // j 804003f4 <MAMAR_au_load_song_files+0x254>
    0x00000000, // nop
    0x3c0200ff, // lui v0,0xff
    0x3442ffff, // ori v0,v0,0xffff
    0x0260282d, // 0x260282d
    0x8fa60014, // lw a2,20(sp)
    0x8fa40010, // lw a0,16(sp)
    0x0c015380, // jal 80054e00 <au_read_rom>
    0x00c23024, // and a2,a2,v0
    0x0000802d, // 0x802d
    0x3c1100ff, // lui s1,0xff
    0x3631ffff, // ori s1,s1,0xffff
    0x24140030, // li s4,48
    0x00101080, // sll v0,s0,0x2
    0x3c018042, // lui at,0x8042
    0x00220821, // addu at,at,v0
    0x94220466, // lhu v0,1126(at)
    0x50400012, // beqzl v0,804003d8 <MAMAR_au_load_song_files+0x238>
    0x26100001, // addiu s0,s0,1
    0x8e440020, // lw a0,32(s2)
    0x000210c0, // sll v0,v0,0x3
    0x00822021, // addu a0,a0,v0
    0x8c820000, // lw v0,0(a0)
    0x8e43001c, // lw v1,28(s2)
    0x00511024, // and v0,v0,s1
    0x00431821, // addu v1,v0,v1
    0xafa30018, // sw v1,24(sp)
    0x8c820004, // lw v0,4(a0)
    0xafa2001c, // sw v0,28(sp)
    0x00021602, // srl v0,v0,0x18
    0x54540005, // bnel v0,s4,804003d8 <MAMAR_au_load_song_files+0x238>
    0x26100001, // addiu s0,s0,1
    0x0060202d, // 0x60202d
    0x0c015313, // jal 80054c4c <snd_load_BK>
    0x0200282d, // 0x200282d
    0x26100001, // addiu s0,s0,1
    0x2a020003, // slti v0,s0,3
    0x1440ffe8, // bnez v0,80400380 <MAMAR_au_load_song_files+0x1e0>
    0x00101080, // sll v0,s0,0x2
    0xaeb60028, // sw s6,40(s5)
    0xaeb30064, // sw s3,100(s5)
    0xaea00024, // sw zero,36(s5)
    0x8e620008, // lw v0,8(s3)
    0x8fbf003c, // lw ra,60(sp)
    0x8fb60038, // lw s6,56(sp)
    0x8fb50034, // lw s5,52(sp)
    0x8fb40030, // lw s4,48(sp)
    0x8fb3002c, // lw s3,44(sp)
    0x8fb20028, // lw s2,40(sp)
    0x8fb10024, // lw s1,36(sp)
    0x8fb00020, // lw s0,32(sp)
    0x03e00008, // jr ra
    0x27bd0040, // addiu sp,sp,64
])

export const ASM_PATCH_au_load_song_files = new Uint32Array([
    0x27bdffe8, // addiu sp,sp,-24
    0xafbf0010, // sw ra,16(sp)
    0x0c100068, // jal 804001a0 <MAMAR_au_load_song_files>
    0x00000000, // nop
    0x8fbf0010, // lw ra,16(sp)
    0x03e00008, // jr ra
    0x27bd0018, // addiu sp,sp,24
])

export const RAM_arn_07_FlyingAI_JumpInit = 0x8024152c
export const RAM_gPauseWindowFlipDownFlags = 0x8024efe4
export const RAM_func_80241388_865EC8 = 0x80241388
export const RAM_battle_move_jump_charge_0_UnkMoveFunc1 = 0x802a1000
export const RAM_waterfall_render = 0xe00b629c
export const RAM_vtx_9722B8 = 0x802490f8
export const RAM_func_8026EBF8 = 0x8026ebf8
export const RAM_D_80232C7C_6849FC = 0x80232c7c
export const RAM_dup_hos_06_AddPlayerHandsOffset = 0x80240484
export const RAM_func_80240E80_801700 = 0x80240e80
export const RAM_func_802406B0_9D76D0 = 0x802406b0
export const RAM_obk_07_CheckItemFlags40 = 0x80240268
export const RAM_SetTransformGroupEnabled = 0x802c9748
export const RAM_b_area_trd_part_2_fake_bowser_802235E0 = 0x802235e0
export const RAM_set_current_item_entity_render_group = 0x80134230
export const RAM_isk_05_HoppingAI_Hop = 0x80240230
export const RAM_battle_item_ultra_shroom_EatItem = 0x802a180c
export const RAM_D_E0126BF8 = 0xe0126bf8
export const RAM_D_80078DF8 = 0x80078df8
export const RAM_GetCamDistance = 0x802cc084
export const RAM_b_area_trd_part_2_trd_03_clouds3 = 0x80232730
export const RAM_trd_04_UpdateTexturePanStepped = 0x80240124
export const RAM_D_8024F520_C0CDA0 = 0x8024f520
export const RAM_isk_08_TackleAI_Main = 0x80240394
export const RAM_SetBattleSong = 0x802d6090
export const RAM_b_area_dgb_tubba_blubba_802192E0 = 0x802192e0
export const RAM_func_802BB8A4_E2BFD4 = 0x802bb8a4
export const RAM_b_area_kzn_kzn_04_beforeBattle_8022810C = 0x8022810c
export const RAM_red_impact_init = 0xe00d018c
export const RAM_arn_13_entryList = 0x80240000
export const RAM_tik_04_GetFloorCollider = 0x80240bf4
export const RAM_mgm_01_InitPanelEmergeFromBlock = 0x8024058c
export const RAM_D_802461F4_8294B4 = 0x802461f4
export const RAM_D_E0020CFC = 0xe0020cfc
export const RAM_D_802A388C_74354C = 0x802a3874
export const RAM_test_ray_zones = 0x8005d124
export const RAM_IES_HappyCoin = 0x8008c8a4
export const RAM_mgm_01_draw_score_display = 0x802401d0
export const RAM_gAnimModelFogR = 0x80153a48
export const RAM_D_802EB0A8 = 0x802eb0a8
export const RAM_mac_02_Pipe_GetCameraYaw = 0x802401d0
export const RAM_b_area_sam_gray_magikoopa_parts = 0x8022c480
export const RAM_filemenu_draw_contents_stereo = 0x80244cec
export const RAM_b_area_mim_forest_fuzzy_8021A0D4 = 0x8021a0d4
export const RAM_D_802EB620 = 0x802eb620
export const RAM_D_800A0BC0 = 0x800a0bc0
export const RAM_ui_input_analog_stick_down_png = 0x800f9860
export const RAM_arn_05_npcSettings_802416B0 = 0x802416b0
export const RAM_ui_battle_cmd_aim_marker_0_pal = 0x8028e600
export const RAM_b_area_kzn_kzn_04c = 0x80228e10
export const RAM_HES_Item_FrightJar = 0x80082598
export const RAM_D_8024DBB0_C0B430 = 0x8024dbb0
export const RAM_D_E0122BA0 = 0xe0122ba0
export const RAM_IES_GearHammer3 = 0x8008b484
export const RAM_kmr_02_LetterDelivery_SavedNpcAnim = 0x8025811c
export const RAM_kpa_14_StashVars = 0x80240380
export const RAM_D_80244BB8_ED8DD8 = 0x80244bb8
export const RAM_star_outline_update = 0xe0126134
export const RAM_D_8024A5F0_C07E70 = 0x8024a5f0
export const RAM_flo_11_8024164C = 0x8024164c
export const RAM_func_800E2BB0 = 0x800e2bb0
export const RAM_arn_04_FlyingAI_LoiterInit = 0x80241668
export const RAM_GetMapID = 0x802ca490
export const RAM_flo_00_802477EC = 0x802477ec
export const RAM_func_80240818_D7F578 = 0x80240818
export const RAM_end_01_CreditsMessageBuffers = 0x8024a2d0
export const RAM_func_8025593C = 0x8025593c
export const RAM_state_step_end_battle = 0x80033bc0
export const RAM_b_area_kpa3_UnkBackgroundFunc3 = 0x8021950c
export const RAM_func_8005DFD4 = 0x8005dfd4
export const RAM_dgb_01_npcGroupList_80246F1C = 0x80246f1c
export const RAM_D_E0046614 = 0xe0046614
export const RAM_mim_01_MeleeHitbox_32 = 0x802405dc
export const RAM_D_8014C0E8 = 0x8014c0e8
export const RAM_D_8014F564 = 0x8014f564
export const RAM_MusicTargetVolume = 0x80159ae2
export const RAM_battle_item_please_come_back_main = 0x802a17fc
export const RAM_b_area_jan2_jan_03b_foregroundModelList_8021F450 = 0x8021f450
export const RAM_flo_16_SuperBlock_SwitchToPartner = 0x802404fc
export const RAM_D_802441BC_EA4ABC = 0x802441bc
export const RAM_dgb_10_func_80240000_C4C390 = 0x80240000
export const RAM_sam_01_Quizmo_Worker = 0x8024def8
export const RAM_flo_14_80243E78 = 0x80243e78
export const RAM_D_E0084E3C = 0xe0084e3c
export const RAM_b_area_mim_bzzap_8021BC74 = 0x8021bc74
export const RAM_pra_40_reflection_render_floor_fancy = 0x80240870
export const RAM_HES_Item_Unused_12D_disabled = 0x800865e8
export const RAM_evt_handle_print_debug_var = 0x802c6e1c
export const RAM_kzn_11_FlyingNoAttackAI_13 = 0x80241a88
export const RAM_func_80242804_9C7BE4 = 0x80242804
export const RAM_D_80242FF8_AA1D38 = 0x80242ff8
export const RAM_b_area_kmr_part_1_kmr_03_beforeBattle_80220188 = 0x80220188
export const RAM_b_area_kmr_part_1_goomba_statusTable_8021975C = 0x8021961c
export const RAM_HES_Item_HustleDrink_disabled = 0x80087728
export const RAM_ui_battle_status_pdown_1_png = 0x80102a40
export const RAM_dgb_01_80244C38 = 0x80244c38
export const RAM_btl_state_update_change_partner = 0x802456f8
export const RAM_ShowShopPurchaseDialog = 0x8028051c
export const RAM_ui_klevar_pal = 0x800fdc40
export const RAM_func_80240000_D7ED60 = 0x80240000
export const RAM_gPauseMsg_41 = 0x8026f6d8
export const RAM_EC9D00_SuperBlock_SwitchToPartner = 0x8024041c
export const RAM_enable_partner_ai = 0x800ef328
export const RAM_D_8024903C_82C2FC = 0x8024903c
export const RAM_func_80240A44_C6D364 = 0x80240a44
export const RAM_underwater_init = 0xe00ba16c
export const RAM_IES_KootTheTape = 0x8008bd04
export const RAM_D_80243098_C713C8 = 0x80243098
export const RAM_pause_badges_update = 0x8024961c
export const RAM_b_area_trd_part_2_blue_ninja_koopa_effectUnk = 0x80236560
export const RAM_music_note_main = 0xe004c000
export const RAM_D_802AD690 = 0x802ad690
export const RAM_b_area_kmr_part_3_kmr_06_clouds1 = 0x80232ff0
export const RAM_battle_item_insecticide_herb_func_802A1280_72A9D0 = 0x802a1280
export const RAM_func_802432B0_912960 = 0x802432b0
export const RAM_arn_03_main = 0x80241a58
export const RAM_b_area_trd_part_1_bullet_bill_80219BE0 = 0x80219be0
export const RAM_D_09001FD0_3521F0 = 0x09001fd0
export const RAM_D_E00B6758 = 0xe00b6758
export const RAM_arn_04_FlyingAI_Jump = 0x80241ae4
export const RAM_dgb_15_80241FBC = 0x80241fbc
export const RAM_D_80281104 = 0x80281104
export const RAM_b_area_isk_part_1_pokey_mummy_idleAnimations_80218E7C = 0x80218e7c
export const RAM_clear_entity_data = 0x80110cb8
export const RAM_battle_item_electro_pop_EatItem = 0x802a1a0c
export const RAM_au_stop_songs = 0x800559e0
export const RAM_Entity_HitRedBlock = 0x802ea660
export const RAM_func_80218108_4CF2B8 = 0x80218108
export const RAM_b_area_omo_sky_guy_idleAnimations_8022950C = 0x8022950c
export const RAM_b_area_isk_part_1_vector3D_802240A0 = 0x802240a0
export const RAM_sam_02_Quizmo_NPC_Aux_Impl = 0x80240ed8
export const RAM_HES_DescMsgNext = 0x80242820
export const RAM_calc_enemy_test_target = 0x802771f8
export const RAM_kmr_02_StationaryAI_Main = 0x80241c18
export const RAM_arn_09_pad_4CC = 0x802404cc
export const RAM_flo_24_TransformFoliage = 0x802400b0
export const RAM_D_80244020_BA4750 = 0x80244020
export const RAM_appendGfx_player_actor_blur = 0x80254c50
export const RAM_omo_11_SuperBlock_ClearOverride40 = 0x802407ac
export const RAM_phys_is_on_sloped_ground = 0x800e46c8
export const RAM_b_area_iwa_buzzar_partsTable_8021B708 = 0x8021b708
export const RAM_b_area_kmr_part_2_goomba_king_idleAnimations_80220888 = 0x80220888
export const RAM_func_802406C4_9EE2B4 = 0x802406c4
export const RAM_flo_08_SuperBlock_PartnerIDs = 0x80241fb8
export const RAM_D_80250798_C0E018 = 0x80250798
export const RAM_load_map_by_IDs = 0x8005a6f4
export const RAM_flower_trail_dispose = 0xe0012548
export const RAM_motion_blur_flame_appendGfx = 0xe00a223c
export const RAM_kzn_02_FlyingAI_Init = 0x802416c8
export const RAM_kzn_04_SuperBlock_HideBlockContent = 0x8024034c
export const RAM_dro_02_EVS_Quizmo_GiveItem_0 = 0x80244de4
export const RAM_HES_Item_WhackasBump_disabled = 0x80087128
export const RAM_D_80073E10 = 0x80073e10
export const RAM_D_E00147B0 = 0xe00147b0
export const RAM_BindHandleEvent = 0x80278c20
export const RAM_dro_02_func_8024240C_96B5CC = 0x8024240c
export const RAM_b_area_isk_part_2_isk_02b = 0x8021ff54
export const RAM_arn_07_FlyingAI_LosePlayer = 0x802417a8
export const RAM_D_E0116C64 = 0xe0116c64
export const RAM_EnableActorGlow = 0x8027dba4
export const RAM_fx_got_item_outline = 0x800702b0
export const RAM_tik_04_UnkPosFunc2 = 0x802405f0
export const RAM_b_area_sam_white_clubba = 0x80229bf8
export const RAM_dgb_04_dgb_00_name_hack = 0x802444d8
export const RAM_D_802435C8_E15D28 = 0x802435c8
export const RAM_HES_MashCUpButton = 0x802925f4
export const RAM_b_area_trd_part_2_trd_02b_80231DF8 = 0x80231df8
export const RAM_isk_10_SuperBlock_HideBlockContent = 0x8024003c
export const RAM_trd_07_ParatroopaAI_Dive = 0x80242104
export const RAM_arn_08_80240470 = 0x80240470
export const RAM_b_area_omo2_toy_tank_8021A324 = 0x8021a324
export const RAM_D_E00AC8D8 = 0xe00ac8d8
export const RAM_func_80240244_BAA384 = 0x80240244
export const RAM_kmr_20_StashVars = 0x80241620
export const RAM_kkj_20_ToadHouse_AwaitScriptComplete = 0x80240404
export const RAM_kpa_82_AddPlayerHandsOffset = 0x802400ac
export const RAM_exec_ShakeCamX = 0x802cb5c8
export const RAM_HES_Item_MegaJump_disabled = 0x80084cc8
export const RAM_HES_StatHammer0 = 0x8024290c
export const RAM_D_802DB5C0 = 0x802db5c0
export const RAM_b_area_omo_groove_guy_init_80224B38 = 0x80224b38
export const RAM_snd_ambient_80055590 = 0x80055590
export const RAM_ui_battle_menu_spirits_png = 0x8028a460
export const RAM_kzn_03_set_script_owner_npc_col_height = 0x80240624
export const RAM_battle_item_thunder_bolt_GiveRefund = 0x802a1000
export const RAM_HES_Item_Dictionary = 0x80081878
export const RAM_b_area_mac_mac_01_80232450 = 0x80232450
export const RAM_func_802417A8_8D2CB8 = 0x802417a8
export const RAM_SetVirtualEntityPosition = 0x802d36e0
export const RAM_btl_appendGfx_prim_quad = 0x8026787c
export const RAM_D_80228410_6CBFA0 = 0x80228410
export const RAM_ECAA80_AddPlayerHandsOffset = 0x802417cc
export const RAM_HES_StatusDigit1 = 0x801080e0
export const RAM_func_E00C0758 = 0xe00c0758
export const RAM_func_8025DD40 = 0x8025dd40
export const RAM_fright_jar_init = 0xe00c2124
export const RAM_HES_Item_BlueKey = 0x80081c68
export const RAM_D_09000080_32ECD0 = 0x09000080
export const RAM_D_801536C0 = 0x801536c0
export const RAM_flo_22_main = 0x802403d8
export const RAM_nok_02_Quizmo_Worker = 0x802549a0
export const RAM_b_area_jan2_putrid_piranha_partsTable_8021B1AC = 0x802187cc
export const RAM_load_map_script_lib = 0x8005a6c0
export const RAM_func_A4000974 = 0xa4000974
export const RAM_b_area_kzn_medi_guy_idle_8021FA8C = 0x8021fa8c
export const RAM_b_area_kmr_part_2_red_goomba_partsTable_8021B778 = 0x8021b778
export const RAM_D_09002900_3AD930 = 0x09002900
export const RAM_flo_00_PatrolAI_JumpInit = 0x80240770
export const RAM_func_802D4BDC = 0x802d4bdc
export const RAM_vtx_C26F40 = 0x80206000
export const RAM_get_area_byte = 0x80145650
export const RAM_dgb_04_SuperBlock_CantUpgradeMessages = 0x80243d30
export const RAM_func_80238590_6FB2A0 = 0x80238590
export const RAM_iwa_02_GetItemName = 0x80241094
export const RAM_D_80247C90_D869F0 = 0x80247c90
export const RAM_mac_02_Quizmo_UnkStageEffectMode = 0x80242230
export const RAM_IES_SlowGo = 0x8008c2c4
export const RAM_BattleMerleeFadeStageFromBlack = 0x802615c8
export const RAM_b_area_pra3_red_magikoopa_parts = 0x8022c290
export const RAM_HES_Item_PrettyLucky_disabled = 0x80084368
export const RAM_flo_03_8024122C = 0x8024122c
export const RAM_mac_03_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_80155D90 = 0x80155d90
export const RAM_gAnimModelFogB = 0x80153a50
export const RAM_func_80050970 = 0x80050970
export const RAM_PlayerTestEnemy = 0x802746f0
export const RAM_b_area_iwa_formation_05 = 0x80218344
export const RAM_func_80240020_AB62B0 = 0x80240020
export const RAM_hud_element_draw_next = 0x801447c4
export const RAM_world_kooper_put_away = 0x802beb9c
export const RAM_D_8014FEB0 = 0x8014feb0
export const RAM_D_8014BBB0 = 0x8014bbb0
export const RAM_D_8007F1FC = 0x8007f1fc
export const RAM_dgb_10_UnkDistFunc2 = 0x802400a0
export const RAM_func_80241F08_9F6948 = 0x80241f08
export const RAM_b_area_mac_mac_01 = 0x80232900
export const RAM_ui_ultra_hammer_disabled_pal = 0x800faec0
export const RAM_D_802C0560 = 0x802c0560
export const RAM_ui_status_pow_unit_5_pal = 0x80100820
export const RAM_sam_05_PiranhaPlantAI_14 = 0x80241668
export const RAM_pra_12_ReflectWall = 0x8024011c
export const RAM_D_802BFDB0_320B20 = 0x802bfdb0
export const RAM_flo_17_exitWalk_8024359C = 0x8024359c
export const RAM_func_802417C4_832FC4 = 0x802417c4
export const RAM_D_800A0924 = 0x800a0924
export const RAM_b_area_pra2_crystal_king_Heal = 0x8021fe90
export const RAM_b_area_pra3_duplighost_bow_init = 0x80221710
export const RAM_fx_76 = 0x800724d0
export const RAM_pra_18_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_802451B0_87ED90 = 0x802451b0
export const RAM_dro_02_8024B20C = 0x8024b20c
export const RAM_D_802347B0_6BB9B0 = 0x802347b0
export const RAM_D_8024D538_C0ADB8 = 0x8024d538
export const RAM_D_80150918 = 0x80150918
export const RAM_tik_01_Pipe_AwaitDownInput = 0x80240340
export const RAM_b_area_jan_jan_03b_beforeBattle_802283C0 = 0x802283c0
export const RAM_action_command_jump_free_hud_elements = 0x802a9590
export const RAM_HES_Spirit2Missing = 0x80242460
export const RAM_D_8014F4E4 = 0x8014f4e4
export const RAM_D_8024C808_C0A088 = 0x8024c808
export const RAM_dgb_03_80243608 = 0x80243608
export const RAM_b_area_isk_part_2_isk_03b_beforeBattle_802200B0 = 0x802200b0
export const RAM_ascii_EC2018 = 0x802491c8
export const RAM_osFlashReadStatus = 0x8006f0b8
export const RAM_func_80242328_DB45E8 = 0x80242328
export const RAM_b_area_iwa_iwa_02_takeTurn_80222000 = 0x80222000
export const RAM_D_8024BD80_C09600 = 0x8024bd80
export const RAM_dead_add_vec2D_polar = 0x8002b28c
export const RAM_hos_03_Quizmo_StashVars = 0x802424a4
export const RAM_dgb_18_npcSettings_802415FC = 0x802415fc
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022C6D4 = 0x8022c6d4
export const RAM_b_area_nok_goomba_idle_80219938 = 0x80219938
export const RAM_b_area_trd_part_2_trd_03_beforeBattle_80232888 = 0x80232888
export const RAM_dup_nok_02_GetItemName = 0x80240394
export const RAM_kmr_02_Quizmo_StashVars = 0x80240390
export const RAM_D_E0126BF0 = 0xe0126bf0
export const RAM_func_8004E4B8 = 0x8004e4b8
export const RAM_action_command_0A_free_hud_elements = 0x802a963c
export const RAM_IES_RuinsStoneStar = 0x8008b644
export const RAM_battle_star_time_out_UnkStarFunc2 = 0x802a10ac
export const RAM_IES_StrangeLeaf = 0x8008d9f4
export const RAM_PopBattleSong = 0x802d6050
export const RAM_func_802400CC_BBB44C = 0x802400cc
export const RAM_dgb_01_D_802535E0_C10E60 = 0x802535e0
export const RAM_ui_status_pow_star_4_pal = 0x801009e0
export const RAM_D_80241890_D7B280 = 0x80241890
export const RAM_dro_02_Quizmo_CreateReactionEffect = 0x802412f8
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_nextTurn_802313C4 = 0x802313c4
export const RAM_filemenu_choose_name_init = 0x80248a80
export const RAM_D_8014FEB8 = 0x8014feb8
export const RAM_GoombariaPutAway = 0x802bd1b4
export const RAM_b_area_jan_spike_top_idleAnimations_8021CA68 = 0x80223cd8
export const RAM_kpa_95_SetEntityFlags100000 = 0x80240000
export const RAM_obk_01_StashVars = 0x802407e0
export const RAM_D_8024DA98_A37CD8 = 0x8024da98
export const RAM_misc_particles_main = 0xe00e4000
export const RAM_queue_render_task = 0x8011d910
export const RAM_tik_12_func_802408B4_97BEE4 = 0x802421d4
export const RAM_mim_09_HoppingAI_LoiterInit = 0x80240608
export const RAM_D_80258340 = 0x80258340
export const RAM_kmr_20_varStash = 0x8024a908
export const RAM_func_802613A8 = 0x802613a8
export const RAM_battle_item_food_AddHP = 0x802a14f8
export const RAM_D_09002010_352230 = 0x09002010
export const RAM_battle_action_cmd_whirlwind_2_pal = 0x802a9b40
export const RAM_D_802427C0_A517C0 = 0x802427c0
export const RAM_HES_Eldstar = 0x80107990
export const RAM_RunAwayReset = 0x80287128
export const RAM_func_8027D32C = 0x8027d32c
export const RAM_D_80246630_EA6F30 = 0x80246630
export const RAM_b_area_kzn_putrid_piranha_partsTable_8021B1AC = 0x8021b1ac
export const RAM_HES_Item_FireShield = 0x80083fd8
export const RAM_D_802AA8A0_425510 = 0x802aa8a0
export const RAM_dgb_09_pad_3E4C = 0x80243e4c
export const RAM_trd_07_FlyingAI_Loiter = 0x8024137c
export const RAM_dgb_00_func_80240060_BFA100 = 0x80240060
export const RAM_b_area_kmr_part_3_para_jr_troopa_80225F7C = 0x80225f7c
export const RAM_mim_08_FlyingAI_Jump = 0x80240cf4
export const RAM_b_area_nok_fuzzy_idleAnimations_80222154 = 0x80222154
export const RAM_PostChapter7StatUpdate = 0x80241370
export const RAM_func_80240A00_872430 = 0x80240a00
export const RAM_b_area_dig_Set80218630_Var0 = 0x80218630
export const RAM_D_80247B44_EE6504 = 0x80247b44
export const RAM_func_802BD6DC_31842C = 0x802bd6dc
export const RAM_D_E0090A40 = 0xe0090a40
export const RAM_stub_effect_delegate = 0x80059c80
export const RAM_b_area_pra3_gray_magikoopa_takeTurn = 0x80231360
export const RAM_ShopContinueSpeech = 0x80283eb0
export const RAM_dgb_01_D_80251B5C_C0F3DC = 0x80251b5c
export const RAM_mgm_01_EndBowserPanelAnimation = 0x80240db8
export const RAM_vtx_drawbox1 = 0x8010bbc8
export const RAM_D_80253060 = 0x80253060
export const RAM_battle_star_star_storm_usePower = 0x802a25a8
export const RAM_battle_action_cmd_whirlwind_6_png = 0x802a9f20
export const RAM_IES_BakingButter = 0x8008dc14
export const RAM_D_80243090_C713C0 = 0x80243090
export const RAM_kmr_07_StationaryAI_Main = 0x802407f4
export const RAM_sam_08_MeleeHitbox_31 = 0x80240dac
export const RAM_b_area_trd_part_2_red_ninja_koopa_effectUnk = 0x802362d0
export const RAM_flo_00_npcGroup_80245054 = 0x80245054
export const RAM_b_area_omo_green_shy_guy_80220604 = 0x80220604
export const RAM_D_802D9EB8 = 0x802d9eb8
export const RAM_ascii_EC2010 = 0x802491c0
export const RAM_D_8029CC38 = 0x8029cc38
export const RAM_flo_03_idle_80242A94 = 0x80242a94
export const RAM_func_E006A0BC = 0xe006a0bc
export const RAM_b_area_arn_hyper_paragoomba_statusTable_8021B308 = 0x8021b308
export const RAM_func_80240590_DE5C40 = 0x80240590
export const RAM_flo_13_init_802462D0 = 0x802462d0
export const RAM_pra_40_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_802A9920_430940 = 0x802a9920
export const RAM_HES_MenuHammer1Disabled = 0x8010885c
export const RAM_iwa_04_CleftAI_PreAmbush = 0x80240384
export const RAM_IES_FlowerPoint = 0x8008cf1c
export const RAM_b_area_trd_part_2_fake_bowser_takeTurn_8021EC98 = 0x8021ec98
export const RAM_footprint_main = 0xe0018078
export const RAM_D_8010BED8 = 0x8010bed8
export const RAM_b_area_kmr_part_1_paragoomba_handleEvent_8021B768 = 0x8021d158
export const RAM_b_area_kmr_part_1_kmr_03_UnkAngleFunc2 = 0x802187e0
export const RAM_D_090020D0_3522F0 = 0x090020d0
export const RAM_fx_sun = 0x80072950
export const RAM_D_8014BA98 = 0x8014ba98
export const RAM_battle_item_strange_cake_GiveRefund = 0x802a1000
export const RAM_D_802BCF88_E2D6B8 = 0x802bcf88
export const RAM_state_drawUI_change_map = 0x800363fc
export const RAM_D_80246638_EA6F38 = 0x80246638
export const RAM_Entity_ShatteringHammer3BlockTiny = 0x802e9d40
export const RAM_kmr_05_FlyingAI_JumpInit = 0x80240bdc
export const RAM_kzn_09_SuperBlock_RadiateFaintEnergyFX = 0x802405a8
export const RAM_tik_25_UpdateTexturePanStepped = 0x80240124
export const RAM_jan_03_LetterDelivery_SaveNpcAnim = 0x80242eac
export const RAM_CamPreset_A = 0x80280e54
export const RAM_b_area_hos_magikoopa_StartRumbleWithParams = 0x802183a0
export const RAM_battle_star_smooch_usePower = 0x802a2510
export const RAM_arn_05_interact_80241D88 = 0x80241d88
export const RAM_sbk_01_npcAI_802403A0 = 0x802403a0
export const RAM_func_80240624_7EB334 = 0x80240624
export const RAM_chapter_change_appendGfx = 0xe010e558
export const RAM_tik_19_Pipe_GetCurrentFloor = 0x80240020
export const RAM_func_80240AAC_A1132C = 0x80240aac
export const RAM_func_80240000_C6E330 = 0x80240000
export const RAM_func_802419F4_A2BC34 = 0x802419f4
export const RAM_D_800B0B1A = 0x800b0b1a
export const RAM_b_area_mac_lee_UnkFunc62 = 0x80218300
export const RAM_omo_10_UpdateTexturePanStepped = 0x80240680
export const RAM_HES_Item_PowerRush = 0x80085238
export const RAM_b_area_omo_formation_08 = 0x8022fb44
export const RAM_entity_Chest_readargs = 0x802e7d38
export const RAM_func_8006A9F0 = 0x8006a9f0
export const RAM_D_80232C74_6849F4 = 0x80232c74
export const RAM_func_802A1518_78BB18 = 0x802a1518
export const RAM_isk_10_SuperBlock_GetPartnerRank = 0x80240068
export const RAM_b_area_jan_spear_guy_UnkBattleFunc1 = 0x80218000
export const RAM_purple_ring_update = 0xe003e400
export const RAM_EF7FB0_UnkFunc42 = 0x80240530
export const RAM_func_8002B45C = 0x8002b45c
export const RAM_kmr_12_BindExits = 0x80240178
export const RAM_gPauseTabsPanelIDs = 0x8024f1d0
export const RAM_isk_13_StoneChompAI_Main = 0x80240f3c
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_init = 0x80233ee4
export const RAM_HES_HPDigit3 = 0x80292020
export const RAM_D_8026FBD8 = 0x8026fbd8
export const RAM_quizmo_stage_appendGfx = 0xe009c220
export const RAM_kpa_08_MagikoopaAI_Main = 0x8024110c
export const RAM_vtx_C2B850 = 0x8020a910
export const RAM_D_E00B6750 = 0xe00b6750
export const RAM_D_8023D2A8 = 0x8023d2a8
export const RAM_mac_04_Quizmo_ShouldAppear = 0x8024059c
export const RAM_omo_11_SuperBlock_WaitForPlayerToLand = 0x80241498
export const RAM_func_80241774_844344 = 0x80241774
export const RAM_b_area_omo2_5_shy_squad_dup_next_phase = 0x80234370
export const RAM_bgm_pop_battle_song = 0x8014ae6c
export const RAM_b_area_kzn_putrid_piranha_idleAnimations_8021B0A0 = 0x8021b0a0
export const RAM_D_00E639C0 = 0x00e639c0
export const RAM_func_80269E80 = 0x80269e80
export const RAM_dup3_b_area_kpa2_RemoveChillOut = 0x80219414
export const RAM_func_80242A3C_8EE85C = 0x80242a3c
export const RAM_b_area_kzn_kzn_04_80227F5C = 0x80227f5c
export const RAM_b_area_kmr_part_3_para_jr_troopa_80227E1C = 0x80227e1c
export const RAM_mdl_make_local_vertex_copy = 0x8011c164
export const RAM_btl_state_update_player_move = 0x80245ad0
export const RAM_D_8024A170_C079F0 = 0x8024a170
export const RAM_b_area_mac_chan_idleAnimations_80219E70 = 0x80219e70
export const RAM_b_area_mim_forest_fuzzy_handleEvent_80218584 = 0x80218584
export const RAM_dgb_08_FlyingAI_Init = 0x80242948
export const RAM_nuScGraphicsStack = 0x800aa5d0
export const RAM_b_area_omo2_general_guy_idleAnimations3 = 0x802184c0
export const RAM_battle_star_focus_UnkStarFunc2 = 0x802a10ac
export const RAM_func_80240720_C6EA50 = 0x80240720
export const RAM_pause_set_cursor_pos_immediate = 0x80242bac
export const RAM_dgb_03_PatrolAI_PostLoiter = 0x8024119c
export const RAM_action_command_power_shock_free_hud_elements = 0x802a9a54
export const RAM_gMsgBGScrollAmtX = 0x801512d8
export const RAM_isk_10_SuperBlock_WaitForPlayerToLand = 0x80240d08
export const RAM_b_area_omo_pink_shy_guy_UnkBattleFunc1 = 0x80218270
export const RAM_sbk_30_varStash = 0x80241ff4
export const RAM_arn_04_FlyingAI_Init = 0x80242078
export const RAM_ring_blast_render = 0xe00481d4
export const RAM_flo_07_main = 0x80240ca0
export const RAM_func_802DDEC4 = 0x802ddec4
export const RAM_nuContRmbCallBack = 0x80093d00
export const RAM_kgr_maps = 0x80090410
export const RAM_mgm_01_DestroyMinigame = 0x80241690
export const RAM_b_area_omo2_2_stilt_guy = 0x80231418
export const RAM_SetHomePos = 0x8026a2d0
export const RAM_initialize_collision = 0x8005af94
export const RAM_IES_WhackasBump = 0x8008db34
export const RAM_kkj_18_GetNpcCollisionHeight = 0x80240340
export const RAM_HES_Item_CakeBaked = 0x80080550
export const RAM_b_area_iwa_monty_mole_statusTable_8021A45C = 0x8021a45c
export const RAM_trd_01_StationaryAI_LosePlayer = 0x802410bc
export const RAM_b_area_kzn_formation_11 = 0x80229d08
export const RAM_vtx_C24540 = 0x80203600
export const RAM_spr_get_quad_for_size = 0x802dbe78
export const RAM_omo_07_FlyingAI_WanderInit = 0x80241978
export const RAM_D_E00789AC = 0xe00789ac
export const RAM_D_80262898_823118 = 0x80262898
export const RAM_ui_unused_2_png = 0x800fc840
export const RAM_PartnerCanUseAbility = 0x802cfca0
export const RAM_battle_partner_kooper_defenseTable_8023846C = 0x8023846c
export const RAM_D_802AD003 = 0x802ad003
export const RAM_b_area_sam_frost_piranha_defenseTable = 0x8022aafc
export const RAM_battle_item_mystery_tempStc = 0x02800410
export const RAM_battle_item_stop_watch_func_802A12D4_7270A4 = 0x802a12d4
export const RAM_func_802BC2B4_E2EBE4 = 0x802bc2b4
export const RAM_get_model_env_color_parameters = 0x8011bfd4
export const RAM_D_E00EAA70 = 0xe00eaa70
export const RAM_b_area_omo_spy_guy_jump_text = 0x8022876c
export const RAM_D_802402E0_A93720 = 0x802402e0
export const RAM_b_area_mac_lee_formation_lakilester = 0x8022972c
export const RAM_D_E0122CB0 = 0xe0122cb0
export const RAM_HES_Item_Goomnut_disabled = 0x80086b28
export const RAM_jan_01_UpdateTexturePanSmooth = 0x80240000
export const RAM_world_parakarry_use_ability = 0x802beacc
export const RAM_dgb_03_settings = 0x80242830
export const RAM_battle_item_thunder_bolt_UseItem = 0x802a17a4
export const RAM_nuContRmbStart = 0x8005fe90
export const RAM_D_80245F40_9AE330 = 0x80245f40
export const RAM_func_8026E038 = 0x8026e038
export const RAM_omo_07_PatrolAI_PostLoiter = 0x80240be8
export const RAM_func_80240B3C_9001AC = 0x80240b3c
export const RAM_kkj_16_UnkModelMatrixFunc2 = 0x8024031c
export const RAM_gCurrentEncounter = 0x800b0f10
export const RAM_mdl_get_next_texture_address = 0x8011d7e4
export const RAM_HES_MenuHammer1 = 0x80108834
export const RAM_flo_00_interact_802439E8 = 0x802439e8
export const RAM_HES_Item_CoinSparkleE = 0x80080eac
export const RAM_gPausePartnersViewport = 0x8024f620
export const RAM_sleep_bubble_init = 0xe00520e8
export const RAM_CamPreset_I = 0x80280d58
export const RAM_D_802437B0_BFD850 = 0x802437b0
export const RAM_battle_move_power_quake_IsBerserkerEquipped = 0x802a1000
export const RAM_dup_b_area_tik_UnkBattleFunc1 = 0x80218128
export const RAM_func_8027FC90 = 0x8027fc90
export const RAM_kpa_95_ToadHouse_CamSetFOV = 0x802416cc
export const RAM_battle_star_peach_focus_FadeBackgroundToBlack = 0x802a12e4
export const RAM_D_802A2720_73EF80 = 0x802a2720
export const RAM_b_area_sbk_pokey_init_80218534 = 0x80218534
export const RAM_b_area_kmr_part_1_formation_04 = 0x802192c0
export const RAM_flo_00_80247BCC = 0x80247bcc
export const RAM_entity_WoodenCrate_setupGfx = 0x802e854c
export const RAM_func_802BDB30_317A50 = 0x802bdb30
export const RAM_kpa_03_func_802408B4_97BEE4 = 0x802409d4
export const RAM_b_area_pra3_duplighost_formation_bombette = 0x802205f4
export const RAM_mgm_00_PatrolAI_Chase = 0x80240c2c
export const RAM_flo_24_802409F4 = 0x802409f4
export const RAM_D_802AD612 = 0x802ad612
export const RAM_ui_battle_status_static_0_png = 0x80101e60
export const RAM_get_global_timespace = 0x802c420c
export const RAM_D_802EA870 = 0x802ea870
export const RAM_battle_star_time_out_802A1C50 = 0x802a1c50
export const RAM_battle_item_strange_cake_D_802A27D0 = 0x802a27d0
export const RAM_gAnimRotScaleMtx = 0x80153b28
export const RAM_D_80242B98_91E478 = 0x80242b98
export const RAM_gPauseDLBannerHp = 0x8026eb30
export const RAM_fx_recover = 0x80071090
export const RAM_D_09000AB0_32D970 = 0x09000ab0
export const RAM_wEntityBlueprintSize = 0x8015165c
export const RAM_func_80241610_990DF0 = 0x80241610
export const RAM_IES_Unused_0F3 = 0x8008cb44
export const RAM_b_area_pra3_gray_magikoopa_flee = 0x80230ff4
export const RAM_D_802BE930 = 0x802be930
export const RAM_D_8024CD10_C0A590 = 0x8024cd10
export const RAM_vtx_C2A8D0 = 0x80209990
export const RAM_D_E00D6EB0 = 0xe00d6eb0
export const RAM_D_80249908_A33B48 = 0x80249908
export const RAM_sam_02_Quizmo_AddViewRelativeOffset = 0x802417ac
export const RAM_flame_init = 0xe0040148
export const RAM_obk_03_AddPlayerHandsOffset = 0x802402e4
export const RAM_ui_item_png = 0x800fb5a0
export const RAM_kpa_51_MagikoopaAI_OnHitInit = 0x802413cc
export const RAM_dgb_15_enterSingleDoor_80241C88 = 0x80241c88
export const RAM_b_area_iwa_monty_mole_specialFormation_8021A6C4 = 0x8021a6c4
export const RAM_D_09000800_386BB0 = 0x09000800
export const RAM_AfflictActor = 0x8027d67c
export const RAM_b_area_sbk_sbk_02_8021F720 = 0x8021f720
export const RAM_sbk_30_get_tattle = 0x80240000
export const RAM_mac_02_Quizmo_AudienceEffect = 0x8024e564
export const RAM_b_area_omo2_toy_tank_start_battle_with_tank = 0x8022eb80
export const RAM_func_802403F8_B2C868 = 0x802403f8
export const RAM_b_area_nok_paratroopa_handleEvent_80221228 = 0x80226058
export const RAM_tik_12_SuperBlock_CantUpgradeMessages = 0x80243cac
export const RAM_action_command_spiny_surge_draw_hud_elements = 0x802a973c
export const RAM_NAMESPACE_partsTable_80238734 = 0x80238734
export const RAM_sprite_curtains_png = 0x80074730
export const RAM_pra_35_SetPartnerFlags80000 = 0x80240eb8
export const RAM_b_area_arn_tubba_blubba_idle_80225F58 = 0x80225f58
export const RAM_b_area_kmr_part_2_blue_goomba_idleAnimations_802199B4 = 0x802199b4
export const RAM_state_step_enter_world = 0x80035eec
export const RAM_pra_34_reflection_setup_floor = 0x80240500
export const RAM_wModelList = 0x80151770
export const RAM_dro_02_ItemChoiceList = 0x8024f248
export const RAM_D_8029F25C = 0x8029f25c
export const RAM_delete_trigger = 0x80145bf4
export const RAM_flo_18_802445D4 = 0x802445d4
export const RAM_func_8024FAFC = 0x8024fafc
export const RAM_HES_Item_ZapTap_disabled = 0x80084908
export const RAM_dead_basic_ai_loiter_init = 0x8004df64
export const RAM_snowflake_main = 0xe001c000
export const RAM_flo_00_PatrolAI_MoveInit = 0x80240040
export const RAM_b_area_sam2_sam_02d_Set80071270_0_16 = 0x802199f8
export const RAM_HES_PoisonedBegin = 0x80105c54
export const RAM_func_80240C60_B07660 = 0x80240c60
export const RAM_dgb_04_SentinelAI_ReturnHomeInit = 0x80241d94
export const RAM_dgb_02_ClubbaNappingAI_ReturnHomeInit = 0x80240e54
export const RAM_tubba_heart_attack_appendGfx = 0xe00cc9d0
export const RAM_gStatsMenuElements = 0x8024f330
export const RAM_flo_08_makeEntities = 0x802425f8
export const RAM_gCurtainScale = 0x8009ba90
export const RAM_shim_guLookAtHiliteF = 0xe02005b0
export const RAM_start_battle = 0x800446b4
export const RAM_set_area_flag = 0x801455a0
export const RAM_b_area_mac_lee_init_Goombario = 0x8021ea9c
export const RAM_b_area_hos_ember_split = 0x8021b5bc
export const RAM_D_8014BEF0 = 0x8014bef0
export const RAM_func_802417E0_8D2CF0 = 0x802417e0
export const RAM_kmr_09_FlyingAI_Chase = 0x80240df4
export const RAM_trd_00_CheckItemFlags40 = 0x80240578
export const RAM_D_802445F0_B97A30 = 0x802445f0
export const RAM_D_8029CC30 = 0x8029cc30
export const RAM_D_8029C7D0 = 0x8029c7d0
export const RAM_b_area_omo_medi_guy_idle_8021FA8C = 0x8022360c
export const RAM_vtx_C26890 = 0x80205950
export const RAM_kill_enemy = 0x8003e7ac
export const RAM_func_802A1528_7905F8 = 0x802a1528
export const RAM_D_802BDE80_317DA0 = 0x802bde80
export const RAM_b_area_sam_duplighost_bow = 0x80222668
export const RAM_dro_01_npcGroup_80249B34 = 0x80249b34
export const RAM_kpa_134_UpdateTexturePanSmooth = 0x80240000
export const RAM_dgb_08_802441D4 = 0x802441d4
export const RAM_dro_01_init_80248CC8 = 0x80248cc8
export const RAM_IES_Unused_0EF = 0x8008cac4
export const RAM_filemenu_update_show_name_input = 0x80243380
export const RAM_world_bombette_use_ability = 0x802be8bc
export const RAM_func_80218A50_4E3190 = 0x80218a50
export const RAM_mac_00_Quizmo_SetVannaAnim_Clap = 0x80241138
export const RAM_osContStartReadData = 0x800619a0
export const RAM_LoadMoveScript = 0x80268130
export const RAM_battle_star_lullaby_UnkBackgroundFunc = 0x802a1414
export const RAM_sparkles_main = 0xe0022000
export const RAM_tik_04_Pipe_SetAnimFlag = 0x8024030c
export const RAM_gPauseDLOrbs = 0x8026f348
export const RAM_D_80243CC4_D3E294 = 0x80243cc4
export const RAM_count_targets = 0x80269660
export const RAM_D_80243CD0_B06550 = 0x80243cd0
export const RAM_EB7540_SetEntityPositionF = 0x80240310
export const RAM_jan_03_LetterDelivery_Init = 0x80242c30
export const RAM_dgb_04_pad_3558 = 0x80243558
export const RAM_ui_status_star_point_2_png = 0x80101280
export const RAM_dgb_00_exitWalk_802401C0 = 0x802401c0
export const RAM_D_800AC5E8 = 0x800ac5e8
export const RAM_b_area_arn_hyper_cleft_SetSpinSmashable = 0x80218618
export const RAM_tik_17_Pipe_GetEntryPos = 0x80240e60
export const RAM_water_fountain_init = 0xe00b812c
export const RAM_mac_05_resolve_npc = 0x802433f0
export const RAM_D_E0122CB8 = 0xe0122cb8
export const RAM_isk_14_StoneChompFXB = 0x802411f8
export const RAM_dro_02_D_80248ACC_971C8C = 0x80248acc
export const RAM_EA0C10_PiranhaPlantAI_11 = 0x80241804
export const RAM_arn_10_npcSettings_80240450 = 0x80240450
export const RAM_flo_13_idle_80244BF8 = 0x80244bf8
export const RAM_D_80245928 = 0x80245928
export const RAM_jan_08_SuperBlock_UpgradeDescMessages = 0x80243e18
export const RAM_kkj_10_UnkAtan2Func = 0x80240644
export const RAM_D_8023D2A0 = 0x8023d2a0
export const RAM_vtx_C2CA20 = 0x8020bae0
export const RAM_ui_battle_status_danger_png = 0x80103280
export const RAM_func_8005E12C = 0x8005e12c
export const RAM_isk_10_SuperBlock_StartGlowEffect = 0x80240480
export const RAM_b_area_kmr_part_3_mim_01_afterBattle_8023369C = 0x8023369c
export const RAM_battle_action_cmd_whirlwind_bubble_png = 0x802aa1a0
export const RAM_pause_map_init = 0x8024e604
export const RAM_tattle_window_init = 0xe00d8120
export const RAM_D_802441E0_ED41D0 = 0x802441e0
export const RAM_windy_leaves_update = 0xe005630c
export const RAM_contQuery = 0x80060870
export const RAM_flo_08_ItemChoice_SelectedItemID = 0x8024308c
export const RAM_gPauseWS_27 = 0x8026fee8
export const RAM_func_802BC250_E2EB80 = 0x802bc250
export const RAM_flo_10_entryList = 0x80241470
export const RAM_func_802BB62C_E2BD5C = 0x802bb62c
export const RAM_D_802EC3F0 = 0x802ec3f0
export const RAM_D_8029D370 = 0x8029d370
export const RAM_func_80241610_993D40 = 0x80241610
export const RAM_flower_trail_init = 0xe0012440
export const RAM_b_area_pra3_red_magikoopa_8022C424 = 0x8022c424
export const RAM_SetBattleFlagBits = 0x8026cd3c
export const RAM_sam_02_foliage_setup_shear_mtx = 0x802405ec
export const RAM_func_80240310_C804B0 = 0x80240310
export const RAM_tik_10_UpdateTexturePanStepped = 0x80240124
export const RAM_trd_08_FireBarAI_Main = 0x80240310
export const RAM_b_area_jan_spear_guy_specialFormation_8021A8C0 = 0x8021a8c0
export const RAM_dgb_02_npcAISettings_80241C68 = 0x80241c68
export const RAM_func_802400C4_AB6354 = 0x802400c4
export const RAM_arn_13_enterSingleDoor_80240290 = 0x80240290
export const RAM_b_area_dgb_formation_03 = 0x8021b2d8
export const RAM_ui_battle_status_paralyze_1_pal = 0x801020c0
export const RAM_func_8024160C_B8578C = 0x8024160c
export const RAM_squirt_main = 0xe00b2000
export const RAM_HES_SmallText6 = 0x802421f8
export const RAM_func_802A1518_78A378 = 0x802a1518
export const RAM_kpa_50_set_script_owner_npc_col_height = 0x80241560
export const RAM_vtx_C2AF60 = 0x8020a020
export const RAM_nok_02_PatrolAI_Chase = 0x802413cc
export const RAM_btl_state_draw_begin_partner_turn = 0x80242ef8
export const RAM_arn_12_npcAISettings_8024046C = 0x8024046c
export const RAM_flo_11_vectorList_80241C54 = 0x80241c54
export const RAM_func_802B70B4 = 0x802b70b4
export const RAM_dgb_01_D_80251C38_C0F4B8 = 0x80251c38
export const RAM_kzn_17_UnkFunc50 = 0x80242f74
export const RAM_dgb_16_pad_17B4 = 0x802417b4
export const RAM_D_80242008_C80188 = 0x80242008
export const RAM_func_8024056C_AF320C = 0x8024056c
export const RAM_func_8024091C_92ABCC = 0x8024091c
export const RAM_arn_04_padding1 = 0x80243654
export const RAM_b_area_kzn_spike_top_defenseTable_8021C8CC = 0x8021c8cc
export const RAM_dro_02_Quizmo_CreateStage = 0x80240a70
export const RAM_func_80240050_842C20 = 0x80240050
export const RAM_sam_07_PiranhaPlantAI_Main = 0x80241da4
export const RAM_pause_tabs_draw_items = 0x80244de8
export const RAM_HES_Item_Unused_12E = 0x80086618
export const RAM_snd_set_voice_volume = 0x8004cd94
export const RAM_func_80241E34_991614 = 0x80241e34
export const RAM_D_E00D2508 = 0xe00d2508
export const RAM_battle_move_auto_multibounce_D_802A2600 = 0x802a2600
export const RAM_D_802AD05C = 0x802ad05c
export const RAM_kkj_12_UnkModelMatrixFunc2 = 0x8024032c
export const RAM_kmr_02_Quizmo_CreateStage = 0x80240b00
export const RAM_b_area_sam2_sam_02_UnkModelStuff_array = 0x80222200
export const RAM_D_E0044E40 = 0xe0044e40
export const RAM_arn_09_pad_5A8 = 0x802405a8
export const RAM_battle_move_auto_multibounce_D_802A2230 = 0x802a2230
export const RAM_b_area_jan2_white_magikoopa_wMagikoopa_ShapeSpell = 0x8021b458
export const RAM_pra_32_StarSpiritEffectFunc3 = 0x802401cc
export const RAM_D_8029EFBC = 0x8029efbc
export const RAM_b_area_pra3_duplighost_nextTurn = 0x8021a0f0
export const RAM_kpa_03_MagikoopaAI_23 = 0x80241918
export const RAM_D_8010C990 = 0x8010c990
export const RAM_nok_14_FlyingAI_Loiter = 0x8024122c
export const RAM_func_80243DB0_855F20 = 0x80243db0
export const RAM_pra_28_SomeItemEntityFunc = 0x80241068
export const RAM_appendGfx_animator = 0x8011f4d4
export const RAM_mim_05_WanderMeleeAI_Main = 0x8024068c
export const RAM_b_area_omo2_toy_tank_8022F4D0 = 0x8022f4d0
export const RAM_b_area_mac_lee_nextTurn_80224320 = 0x80224320
export const RAM_func_802A156C_7922FC = 0x802a156c
export const RAM_b_area_kmr_part_2_kmr_06_UnkFogFunc = 0x80219708
export const RAM_b_area_omo_sky_guy_init_8022987C = 0x8022987c
export const RAM_flo_03_802406A0 = 0x802406a0
export const RAM_battle_move_hammer_charge_1_IsBerserkerEquipped = 0x802a1000
export const RAM_HES_Item_Unused_0F1_disabled = 0x80085e68
export const RAM_D_E0026A48 = 0xe0026a48
export const RAM_D_E0026A40 = 0xe0026a40
export const RAM_D_E0022D48 = 0xe0022d48
export const RAM_b_area_iwa_iwa_02_idle_80221828 = 0x80221828
export const RAM_nuGfxThreadStart = 0x800601f0
export const RAM_func_80238E24_700BA4 = 0x80238e24
export const RAM_D_80232C30_6849B0 = 0x80232c30
export const RAM_func_802A133C_75CCBC = 0x802a133c
export const RAM_b_area_nok_kent_c_koopa_idleAnimations_802279B4 = 0x802279b4
export const RAM_flo_14_lavaResetList_802425FC = 0x802425fc
export const RAM_D_802BEC64 = 0x802bec64
export const RAM_b_area_trd_part_2_blue_ninja_koopa_StartRumbleWithParams = 0x80219360
export const RAM_D_8024BD80_A26E80 = 0x8024bd80
export const RAM_D_80231350_63CEF0 = 0x80231350
export const RAM_flo_10_802425BC = 0x802425bc
export const RAM_D_80247940_BF4730 = 0x80247940
export const RAM_HES_Item_Lime = 0x80086c18
export const RAM_omo_16_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_HES_Item_JellyShroom = 0x80083798
export const RAM_b_area_jan_jungle_fuzzy_init_8021EB28 = 0x8021eb28
export const RAM_sam_08_SuperBlock_SwitchToPartner = 0x8024041c
export const RAM_dead_sin_deg = 0x8002b5dc
export const RAM_pad_after_item_hudscripts = 0x800878d8
export const RAM_sbk_01_main = 0x80240258
export const RAM_D_802BCDD8_E2F708 = 0x802bcdd8
export const RAM_dgb_01_exitDoubleDoor_802436B8 = 0x802436b8
export const RAM_flo_09_triggerCoord_80243428 = 0x80243428
export const RAM_HES_Item_Unused_0F6 = 0x80086018
export const RAM_gPauseTabsPageWindowIDs = 0x8024f1e0
export const RAM_b_area_hos_ember_8021BE64 = 0x8021be64
export const RAM_status_menu_stop_blinking_hp = 0x800e9964
export const RAM_omo_04_ShyGuyWanderAI_Main = 0x80241ad0
export const RAM_setup_pause_menu_tab = 0x80147f30
export const RAM_sfx_play_sound_at_player = 0x80149cd8
export const RAM_b_area_trd_part_1_paratroopa_handleEvent_80221228 = 0x80221228
export const RAM_D_8014C138 = 0x8014c138
export const RAM_kkj_11_UnkGooberFunc = 0x80240728
export const RAM_func_80240038_BBD1D8 = 0x80240038
export const RAM_func_80240840_BD40B0 = 0x80240840
export const RAM_HES_Item_HoneySuper_disabled = 0x80082f88
export const RAM_func_80240B4C_AF849C = 0x80240b4c
export const RAM_battle_item_stone_cap_GiveRefundCleanup = 0x802a11d4
export const RAM_flo_13_entryList = 0x80243670
export const RAM_b_area_mac_master2_defenseTable_8022B6D8 = 0x8022b6d8
export const RAM_kmr_03_exitWalk_8024074C = 0x8024074c
export const RAM_btl_state_update_end_player_turn = 0x80246450
export const RAM_b_area_mac_master1_handleEvent_8022B2CC = 0x8022b2cc
export const RAM_arn_03_PatrolAI_Move = 0x80240158
export const RAM_entity_small_switch_idle = 0x802e1660
export const RAM_battle_star_star_storm_UnkStarFunc2 = 0x802a10ac
export const RAM_kmr_22_settings = 0x02800140
export const RAM_mac_00_PatrolAI_LoiterInit = 0x80242970
export const RAM_nuScGetAudioMQ = 0x8005ef84
export const RAM_evt_handle_end_loop = 0x802c4a48
export const RAM_show_first_strike_message = 0x80041c5c
export const RAM_dro_02_ToadHouse_Stub = 0x80243778
export const RAM_D_801568EC = 0x801568ec
export const RAM_gPauseSpiritsNumSpirits = 0x802706fc
export const RAM_D_80150910 = 0x80150910
export const RAM_evt_handle_case_default = 0x802c5444
export const RAM_D_80245D50_9FA790 = 0x80245d50
export const RAM_evt_handle_wait = 0x802c4b5c
export const RAM_ui_battle_cmd_aim_shimmer_5_png = 0x8028eda0
export const RAM_jan_03_Quizmo_UpdateRecords = 0x80242288
export const RAM_func_8024049C_ADB33C = 0x8024049c
export const RAM_func_802A9000_428A70 = 0x802a9000
export const RAM_D_8024F59C = 0x8024f59c
export const RAM_flo_09_npcSettings_80243710 = 0x80243710
export const RAM_tik_23_Pipe_AwaitDownInput = 0x80240034
export const RAM_D_80243D48_A72128 = 0x80243d48
export const RAM_b_area_isk_part_2_isk_06 = 0x802202c4
export const RAM_func_8013DAB4 = 0x8013dab4
export const RAM_fx_46_init = 0xe008c3f4
export const RAM_D_8024F498 = 0x8024f498
export const RAM_D_802421A0_D70B50 = 0x802421a0
export const RAM_mac_03_Pipe_GetCurrentFloor = 0x80240020
export const RAM_reset_model_animators = 0x802cd4b4
export const RAM_b_area_pra2_crystal_bit_1 = 0x80220644
export const RAM_flo_25_npcGroup_8024368C = 0x8024368c
export const RAM_func_802A56F8 = 0x802a56f8
export const RAM_big_smoke_puff_render = 0xe00022bc
export const RAM_nok_02_Pipe_GetCurrentFloor = 0x80240020
export const RAM_func_80241770_832F70 = 0x80241770
export const RAM_gCurrentAnimMeshListPtr = 0x80153a40
export const RAM_func_8013F1F8 = 0x8013f1f8
export const RAM_ui_battle_menu_pouch_png = 0x80289f40
export const RAM_battle_star_smooch_SetNpcCollision32 = 0x802a14e8
export const RAM_partner_suspend_ability_script = 0x800eb818
export const RAM_vtx_C27110 = 0x802061d0
export const RAM_b_area_omo_pyro_guy_defenseTable_8022CB4C = 0x8022cb4c
export const RAM_D_80242BB0_91E490 = 0x80242bb0
export const RAM_func_80218F58_6A0158 = 0x80218f58
export const RAM_D_E006EC20 = 0xe006ec20
export const RAM_b_area_sam_duplighost_bow_statusTable = 0x80222574
export const RAM_b_area_mac_formationTable = 0x80232a5c
export const RAM_func_80240A84_A27954 = 0x80240a84
export const RAM_D_E0056ED0 = 0xe0056ed0
export const RAM_osPfsDeclearPage = 0x8006849c
export const RAM_update_actor_shadows = 0x8023e404
export const RAM_flo_03_80240F3C = 0x80240f3c
export const RAM_D_E0002760 = 0xe0002760
export const RAM_D_800B430C = 0x800b430c
export const RAM_func_80218AA8_4EFF48 = 0x80218aa8
export const RAM_sam_01_Quizmo_SpinPartner = 0x80241580
export const RAM_D_80270394 = 0x80270394
export const RAM_b_area_pra3_gray_magikoopa_defenseTable_flying = 0x8022edec
export const RAM_D_802464D0_D36150 = 0x802464d0
export const RAM_func_8005AF84 = 0x8005af84
export const RAM_func_80273444 = 0x80273444
export const RAM_D_800771E8 = 0x800771e8
export const RAM_D_802EB0D8 = 0x802eb0d8
export const RAM_obk_maps = 0x800921d0
export const RAM_func_80260BF4 = 0x80260bf4
export const RAM_D_802347E8_6BB9E8 = 0x802347e8
export const RAM_HES_Item_StinkyHerb = 0x80086f18
export const RAM_kzn_20_LetterDelivery_Init = 0x8024077c
export const RAM_dgb_08_PatrolAI_Chase = 0x8024094c
export const RAM_dgb_16_ClubbaNappingAI_FallAsleep = 0x80240dc4
export const RAM_btl_set_popup_duration = 0x8024fac4
export const RAM_update_model_animator_with_transform = 0x8011ea54
export const RAM_b_area_isk_part_2_tutankoopa_8021DDE8 = 0x8021dde8
export const RAM_battle_star_chill_out_802A1C88 = 0x802a1c88
export const RAM_D_80247AF8_EE64B8 = 0x80247af8
export const RAM_func_802A10C8_74F1F8 = 0x802a10c8
export const RAM_func_80240EF4_AEE304 = 0x80240ef4
export const RAM_mac_01_ToadHouse_InitScreenOverlay = 0x802439c0
export const RAM_isk_04_AwaitPlayerNearNpc = 0x8024085c
export const RAM_b_area_jan_spike_top_init_8021CAAC = 0x80223d1c
export const RAM_func_80218B90_59F270 = 0x80218b90
export const RAM_tik_06_Pipe_GetEntryPos = 0x802400f0
export const RAM_func_802A948C_42A97C = 0x802a948c
export const RAM_func_802BD100_31B120 = 0x802bd100
export const RAM_sam_01_LetterDelivery_SaveNpcAnim = 0x80241a10
export const RAM_kpa_61_FlyingMagikoopaAI_24 = 0x80241e5c
export const RAM_jan_02_Quizmo_CreateReactionEffect = 0x8024260c
export const RAM_pra_35_PatrolAI_Move = 0x80241078
export const RAM_pra_19_reflection_unk_resolve_anim = 0x80240020
export const RAM_firework_rocket_init = 0xe010a184
export const RAM_func_80240C80_9B2410 = 0x80240c80
export const RAM_HES_TimingBar1Chance = 0x802922f0
export const RAM_pause_sort_item_list = 0x80244a4c
export const RAM_mim_02_SetForeverForestFog = 0x80240000
export const RAM_b_area_hos_magikoopa_partsTable_80221648 = 0x80221648
export const RAM_dgb_04_SuperBlock_StartGlowEffect = 0x80242850
export const RAM_sbk_56_SuperBlock_CountEligiblePartners = 0x802401a0
export const RAM_jan_06_TransformFoliage = 0x80240570
export const RAM_D_090000A0_32CF60 = 0x090000a0
export const RAM_arn_04_80243538 = 0x80243538
export const RAM_D_090010C0 = 0x090010c0
export const RAM_b_area_omo2_toy_tank_formation_stilt_guy_1 = 0x8022f570
export const RAM_b_area_kmr_part_2_blue_goomba_2_defenseTable_8021D400 = 0x8021d400
export const RAM_pause_map_location_png = 0x8026c230
export const RAM_create_cameras_b = 0x8002e038
export const RAM_D_80159AD0 = 0x80159ad0
export const RAM_remove_consumable = 0x800ea47c
export const RAM_func_8024F394 = 0x8024f394
export const RAM_D_802ACFEC = 0x802acfec
export const RAM_b_area_jan2_putrid_piranha_init_8021B1F8 = 0x80218818
export const RAM_D_E0200728 = 0xe0200728
export const RAM_b_area_omo_blue_shy_guy_handleEvent_8021B028 = 0x8021b028
export const RAM_b_area_sam2_80223490 = 0x80223490
export const RAM_IES_Peekaboo = 0x8008c9e4
export const RAM_b_area_pra3_duplighost_lakilester_init = 0x80225380
export const RAM_contRmbRetrace = 0x8005fb10
export const RAM_flo_10_80242AE8 = 0x80242ae8
export const RAM_func_80240020_D53CD0 = 0x80240020
export const RAM_D_800B0B08 = 0x800b0b08
export const RAM_D_80243BE0 = 0x80243be0
export const RAM_D_8024D828_C0B0A8 = 0x8024d828
export const RAM_action_update_hit_fire = 0x802b6000
export const RAM_SetBattleVar = 0x8026f638
export const RAM_dgb_01_D_80251CBC_C0F53C = 0x80251cbc
export const RAM_b_area_tik_ArcsinDeg = 0x80218200
export const RAM_evt_skip_if = 0x802c88fc
export const RAM_jan_03_LetterDelivery_CalcLetterPos = 0x80242d20
export const RAM_dead_npc_raycast_down_sides = 0x800e423c
export const RAM_D_80254BA0 = 0x80254ba0
export const RAM_D_E007EC30 = 0xe007ec30
export const RAM_b_area_flo_StartRumbleWithParams = 0x802187b0
export const RAM_flo_18_UnkFloatFunc = 0x80240394
export const RAM_D_8010CFE4 = 0x8010cfe4
export const RAM_clear_entity_model_flags = 0x80122f24
export const RAM_D_802347F8_6BB9F8 = 0x802347f8
export const RAM_TriggerBlockVanishEffect = 0x802eb3b0
export const RAM_WaitForPlayerTouchingFloor = 0x802d244c
export const RAM_b_area_mac_lee_nextTurn_8022A038 = 0x8022a038
export const RAM_b_area_jan_jan_01_foregroundModelList_80227A7C = 0x80227a7c
export const RAM_HasMerleeCastsLeft = 0x802619c8
export const RAM_flo_11_80242240 = 0x80242240
export const RAM_D_8024F593 = 0x8024f593
export const RAM_b_area_kmr_part_2_kmr_06_clouds2 = 0x802254f8
export const RAM_mac_04_GetPlayerCoins = 0x802419d0
export const RAM_render_player_model = 0x800e1168
export const RAM_HES_ArrowLeft = 0x801049b0
export const RAM_func_80243500_803D80 = 0x80243500
export const RAM_D_80243168_DE2478 = 0x80243168
export const RAM_mac_05_Quizmo_SpinPartner = 0x802421a0
export const RAM_SetSelfRotation = 0x8004545c
export const RAM_vtx_C25310 = 0x802043d0
export const RAM_b_area_kzn_stageTable = 0x8022a02c
export const RAM_battle_item_snowman_doll_DrinkItem = 0x802a1984
export const RAM_D_802466B0_EA6FB0 = 0x802466b0
export const RAM_btl_cam_use_preset_intl = 0x8024cf34
export const RAM_world_sushie_init = 0x802bf520
export const RAM_D_800B8DEC = 0x800b8dec
export const RAM_func_80240000_A26ED0 = 0x80240000
export const RAM_pra_20_reflection_unk_change_anim_facing = 0x80240054
export const RAM_b_area_arn_hyper_paragoomba_defenseTable_8021B2FC = 0x8021b2fc
export const RAM_dgb_18_pad_1578 = 0x80241578
export const RAM_battle_item_egg_missile_DrinkItem = 0x802a1854
export const RAM_D_80243F38_D2FC68 = 0x80243f38
export const RAM_func_80231000_54CC70 = 0x80231000
export const RAM_kzn_09_AddPlayerHandsOffset = 0x80243420
export const RAM_BattleCam_InitialPosZ = 0x8029f2b0
export const RAM_func_80144238 = 0x80144238
export const RAM_nuGfxCfbCounter = 0x8009a5f0
export const RAM_kpa_102_FlyingMagikoopaAI_OnHit = 0x8024353c
export const RAM_open_status_menu_long = 0x800e9760
export const RAM_D_80243BD4_A1ECD4 = 0x80243bd4
export const RAM_battle_item_electro_pop_func_802A133C_7308DC = 0x802a133c
export const RAM_vtx_C2AD20 = 0x80209de0
export const RAM_pra_06_ReflectWall = 0x8024011c
export const RAM_rodata_alignment = 0x80247938
export const RAM_D_80257F08_8C7F78 = 0x80257f08
export const RAM_taskDoneMsg = 0x8009c514
export const RAM_dgb_07_pad_1E74 = 0x80241e88
export const RAM_damage_stars_render = 0xe0030a24
export const RAM_kpa_52_SetEntityFlags100000 = 0x80240000
export const RAM_gCollisionPointZ = 0x800a4250
export const RAM_iwa_10_SuperBlock_ClearOverride40 = 0x8024030c
export const RAM_D_E0056ED8 = 0xe0056ed8
export const RAM_func_800445D4 = 0x800445d4
export const RAM_b_area_nok_spiked_goomba_ArcsinDeg = 0x80218560
export const RAM_DeleteNpc = 0x802cdc1c
export const RAM_func_80240350_B4ADC0 = 0x80240350
export const RAM_pra_27_ReflectPartner = 0x80240d6c
export const RAM_b_area_jan_jan_01_beforeBattle_80227994 = 0x80227994
export const RAM_HES_Item_QuickChange_disabled = 0x80085928
export const RAM_IES_KootOldPhoto = 0x8008bda4
export const RAM_dgb_04_SuperBlock_LoadCurrentPartnerName = 0x80242818
export const RAM_D_801565A8 = 0x801565a8
export const RAM_func_802A4494 = 0x802a4494
export const RAM_b_area_pra3_duplighost_formation_watt = 0x8022384c
export const RAM_D_80242E88_C52398 = 0x80242e88
export const RAM_flo_14_UpdateTexturePanStepped = 0x80240124
export const RAM_D_80232C3C_6849BC = 0x80232c3c
export const RAM_msg_play_speech_sound = 0x80124434
export const RAM_get_asset_offset = 0x8005add0
export const RAM_b_area_kzn_putrid_piranha_breath = 0x8021c2e8
export const RAM_func_802A91F8 = 0x802a91f8
export const RAM_battle_action_cmd_whirlwind_bubble_pal = 0x802aa6e0
export const RAM_HES_Item_AllorNothing = 0x80084458
export const RAM_embers_appendGfx = 0xe00e0600
export const RAM_gPauseItemsSelectedItem = 0x802705c8
export const RAM_D_8024092C_AF258C = 0x8024092c
export const RAM_b_area_isk_part_1_buzzy_beetle_partsTable_8021E594 = 0x8021e594
export const RAM_D_802A4018_740878 = 0x802a4018
export const RAM_dgb_18_npcGroup_802434C4 = 0x802434c4
export const RAM_ui_battle_menu_nothing_disabled_pal = 0x80289f20
export const RAM_D_E0084E40 = 0xe0084e40
export const RAM_DoReturnHome = 0x8029beb8
export const RAM_tik_10_SuperBlock_get_partner_rank = 0x80240754
export const RAM_D_802473F8_C68108 = 0x802473f8
export const RAM_D_800B0B00 = 0x800b0b00
export const RAM_nok_02_SwitchToPartner = 0x80242fe4
export const RAM_func_80268284 = 0x80268284
export const RAM_small_gold_sparkle_init = 0xe00921ac
export const RAM_b_area_jan_jan_04b = 0x80228558
export const RAM_StartBattle = 0x80044840
export const RAM_D_80248F18_7F3C28 = 0x80248f18
export const RAM_D_802347D0_6BB9D0 = 0x802347d0
export const RAM_au_load_PER = 0x800544f8
export const RAM_D_80235E6C_4CF05C = 0x80235e6c
export const RAM_D_80077814 = 0x80077814
export const RAM_func_80241C54_A2BE94 = 0x80241c54
export const RAM_func_80259A48 = 0x80259a48
export const RAM_D_802E0C20 = 0x802e0c20
export const RAM_D_80257F22 = 0x80257f22
export const RAM_clear_script_list = 0x802c31dc
export const RAM_func_802A17D4_78BDD4 = 0x802a17d4
export const RAM_mac_01_Quizmo_SetVannaAnim_Idle = 0x80242a00
export const RAM_func_800E9894 = 0x800e9894
export const RAM_HES_Item_MegaQuake_disabled = 0x80083e28
export const RAM_D_80074010 = 0x80074010
export const RAM_pause_spirits_update = 0x8024d800
export const RAM_D_80234880_6BBA80 = 0x80234880
export const RAM_b_area_isk_part_2_isk_01_afterBattle_8021FDAC = 0x8021fdac
export const RAM_func_80240500_AE5A90 = 0x80240500
export const RAM_dup2_nok_02_GetNpcCollisionHeight = 0x802439c0
export const RAM_b_area_trd_part_2_red_ninja_koopa_GetLastActorEventType = 0x80219308
export const RAM_jan_00_GetItemName = 0x802404a8
export const RAM_IES_BlueBerry = 0x8008d8b4
export const RAM_pra_38_SetPartnerFlags20000 = 0x80240f1c
export const RAM_flo_22_80240E24 = 0x80240e24
export const RAM_HES_Item_CakeBerries = 0x80080670
export const RAM_D_8024F2BB = 0x8024f2bb
export const RAM_b_area_trd_part_2_trd_02 = 0x80231cb0
export const RAM_battle_item_dizzy_dial_PlayerGoHome = 0x802a1800
export const RAM_lightning_main = 0xe006c000
export const RAM_action_update_step_down_land = 0x802b61c0
export const RAM_dgb_08_80244478 = 0x80244478
export const RAM_HES_ProjectorSpot = 0x80292c00
export const RAM_D_800D9668 = 0x800d9668
export const RAM_ui_battle_status_pdown_0_png = 0x801029a0
export const RAM_omo_12_SetCamera0Flag1000 = 0x802402dc
export const RAM_b_area_mac_lee_handleEvent_80226B54 = 0x80226b54
export const RAM_omo_09_ShyGuyPatrolAI_16 = 0x80241f1c
export const RAM_func_80271210 = 0x80271210
export const RAM_D_80241C10_C9DF10 = 0x80241c10
export const RAM_dro_02_EVS_Quizmo_MoveQuizmoToMicrophone = 0x80245540
export const RAM_b_area_kmr_part_3_mim_01_beforeBattle_80233670 = 0x80233670
export const RAM_jan_07_MeleeHitbox_Main = 0x802405d0
export const RAM_filemenu_pressedButtons = 0x8024c084
export const RAM_quizmo_stage_init = 0xe009c100
export const RAM_D_8021A2B8_63F498 = 0x8021a2b8
export const RAM_vtx_C26A60 = 0x80205b20
export const RAM_D_802B79B0_E21860 = 0x802b79b0
export const RAM_kpa_52_RangedAttackAI_Main = 0x80240fd4
export const RAM_D_8009A6A6 = 0x8009a6a6
export const RAM_IES_DojoCard4 = 0x8008bc44
export const RAM_kpa_50_UnkNpcAIFunc48 = 0x802401f0
export const RAM_D_8010C98C = 0x8010c98c
export const RAM_arn_02_npcAISettings_8024144C = 0x8024144c
export const RAM_b_area_nok_kent_c_koopa_partsTable_80227AB4 = 0x80227ab4
export const RAM_b_area_kmr_part_1_paragoomba_ArcsinDeg = 0x80218558
export const RAM_SetBattleInputButtons = 0x8026e068
export const RAM_flo_17_TackleAI_Main = 0x802424d8
export const RAM_trd_04_PatrolAI_MoveInit = 0x80240c84
export const RAM_sam_08_SuperBlock_AnimateEnergyOrbs = 0x80240600
export const RAM_dro_02_D_802478C8_970A88 = 0x802478c8
export const RAM_PutPartnerAway = 0x802cf988
export const RAM_HES_Item_DriedFruit = 0x80086cd8
export const RAM_sam_03_MeleeHitbox_33 = 0x80240204
export const RAM_kpa_09_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_D_090021A8_3523C8 = 0x090021a8
export const RAM_b_area_sam2_paragoomba_takeTurn_8021D6E8 = 0x8021e468
export const RAM_func_80122F8C = 0x80122f8c
export const RAM_battle_item_super_soda_AddFP = 0x802a14d8
export const RAM_D_802E9930 = 0x802e9930
export const RAM_dro_02_openDoor_80244340 = 0x80244340
export const RAM_EF2680_FlyingAI_ChaseInit = 0x80240d30
export const RAM_D_80240E10_AF8760 = 0x80240e10
export const RAM_tik_12_SuperBlock_PartnerIDs = 0x80243cb4
export const RAM_D_E00E4E30 = 0xe00e4e30
export const RAM_D_E009EE30 = 0xe009ee30
export const RAM_action_command_hurricane_draw_hud_elements = 0x802a97ac
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_8022906C = 0x8022906c
export const RAM_b_area_kmr_part_1_paragoomba_init_8021B3E8 = 0x8021cdd8
export const RAM_battle_item_pebble_UseItem = 0x802a1544
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_partsTable_80228014 = 0x80228014
export const RAM_jan_03_Quizmo_HideWorld = 0x80241fc8
export const RAM_flo_18_init_80245B30 = 0x80245b30
export const RAM_snd_start_sound_raw = 0x80055408
export const RAM_func_80240350_BDAB00 = 0x80240350
export const RAM_D_8024626C_9247DC = 0x8024626c
export const RAM_gPauseSpiritsWindowsBPs = 0x8024f9c8
export const RAM_dgb_01_SentinelAI_Descend = 0x80241954
export const RAM_tik_12_set_script_owner_npc_col_height = 0x80241c74
export const RAM_check_conversation_trigger = 0x800431dc
export const RAM_BattleCam_PosX = 0x8029f270
export const RAM_D_80242F58_A66FE8 = 0x80242f58
export const RAM_mac_02_LetterDelivery_SaveNpcAnim = 0x80243380
export const RAM_vtx_C22EC0 = 0x80201f80
export const RAM_dgb_01_FlyingAI_WanderInit = 0x80240120
export const RAM_mem_clear = 0x80029600
export const RAM_sfx_play_sound = 0x80149cb4
export const RAM_b_area_sam_gray_magikoopa_takeTurn = 0x8022e890
export const RAM_func_802BD144_31B164 = 0x802bd144
export const RAM_flo_17_FlyingAI_Wander = 0x80240220
export const RAM_b_area_kgr_kgr_01_8021C330 = 0x8021c330
export const RAM_mdl_clearRenderTasks = 0x802b7e00
export const RAM_jan_22_PatrolAI_LoiterInit = 0x80240400
export const RAM_general_heap_malloc_tail = 0x8002ac50
export const RAM_flo_21_npcSettings_802417EC = 0x802417ec
export const RAM_gCurtainScaleGoal = 0x8009ba94
export const RAM_omo_09_PatrolAI_LosePlayer = 0x802419b8
export const RAM_gEntityModelCount = 0x80151318
export const RAM_battle_item_pebble_main = 0x802a1b6c
export const RAM_b_area_trd_part_1_bob_omb_ignite = 0x8021a7e4
export const RAM_pra_33_reflection_render_floor_fancy = 0x80240870
export const RAM_create_shadow_type = 0x80112260
export const RAM_gGameStepDelayCount = 0x80074021
export const RAM_D_8024FA18_C0D298 = 0x8024fa18
export const RAM_stat_change_update = 0xe00ac124
export const RAM_fx_65_main = 0xe00ca000
export const RAM_au_compute_pitch_ratio = 0x800538f8
export const RAM_b_area_kgr_fuzzipede_80218D68 = 0x80218d68
export const RAM_EA8AE0_StarSpiritEffectFunc1 = 0x8024030c
export const RAM_omo_09_SomeItemEntityFunc = 0x80240838
export const RAM_func_802A5290 = 0x802a5290
export const RAM_sam_02_Quizmo_StageEffect = 0x8024b9b0
export const RAM_arn_13_npcGroupList_80240BCC = 0x80240bcc
export const RAM_mdl_textureHandles = 0x8015a590
export const RAM_dgb_05_ClubbaNappingAI_ReturnHome = 0x80240cac
export const RAM_b_area_nok_spiked_goomba_idle_8021E598 = 0x8021e598
export const RAM_D_802A3644_73FEA4 = 0x802a3644
export const RAM_HES_Spirit6 = 0x80242594
export const RAM_mac_03_Quizmo_UpdateRecords = 0x80240b9c
export const RAM_D_802498A8_A33AE8 = 0x802498a8
export const RAM_D_E00347C0 = 0xe00347c0
export const RAM_jan_02_foliage_setup_shear_mtx = 0x802426e0
export const RAM_b_area_kmr_part_3_paragoomba1_idle = 0x8021dc80
export const RAM_b_area_isk_part_1_buzzy_beetle_UnkBattleFunc1 = 0x802181a0
export const RAM_D_80244CA0_AF7940 = 0x80244ca0
export const RAM_dgb_10_func_802401C0_C4C550 = 0x802401c0
export const RAM_item_entity_enable_shadow = 0x80130fac
export const RAM_HES_Sushie = 0x80107ed8
export const RAM_b_area_omo2_2_stilt_guy_defenseTable2 = 0x80231180
export const RAM_b_area_kmr_part_2_blue_goomba_2_idleAnimations_8021D504 = 0x8021d504
export const RAM_tik_17_SuperBlock_ClearOverride40 = 0x8024004c
export const RAM_b_area_hos_magikoopa_80221144 = 0x80221144
export const RAM_b_area_mim_forest_fuzzy_80218C48 = 0x80218c48
export const RAM_flo_15_pad_11C = 0x8024011c
export const RAM_HES_Item_SSmashChg_disabled = 0x80083f48
export const RAM_func_802BFB44_323694 = 0x802bfb44
export const RAM_intro_logos_fade_out = 0x80033830
export const RAM_D_800A3FE8 = 0x800a3fe8
export const RAM_D_E0086AD4 = 0xe0086ad4
export const RAM_D_8023D2BC = 0x8023d2bc
export const RAM_pause_stats_bar_pal = 0x8026e930
export const RAM_D_8010C948 = 0x8010c948
export const RAM_battle_item_volt_shroom_GiveRefundCleanup = 0x802a11d4
export const RAM_shimmer_wave_main = 0xe0074000
export const RAM_arn_13_npcSettings_80240450 = 0x80240450
export const RAM_D_80247A18_DA4EE8 = 0x80247a18
export const RAM_D_80150900 = 0x80150900
export const RAM_arn_07_npcGroup_8024768C = 0x8024768c
export const RAM_vtx_C2D3E0 = 0x8020c4a0
export const RAM_D_80244208_C64F18 = 0x80244208
export const RAM_tik_02_GetItemName = 0x802403c4
export const RAM_D_802464A4_C60BE4 = 0x802464a4
export const RAM_func_80054D74 = 0x80054d74
export const RAM_D_80245CD8_A99418 = 0x80245cd8
export const RAM_arn_10_80240430 = 0x80240430
export const RAM_HES_BoostHammerLoop = 0x801072e8
export const RAM_dgb_04_SentinelAI_LosePlayer = 0x80241b4c
export const RAM_chapter_change_main = 0xe010e32c
export const RAM_D_80150908 = 0x80150908
export const RAM_D_E0084E48 = 0xe0084e48
export const RAM_D_8024AB18_DD77E8 = 0x8024ab18
export const RAM_flo_22_npcGroup_80240BF4 = 0x80240bf4
export const RAM_vtx_C26690 = 0x80205750
export const RAM_kpa_95_GetEntityPosition = 0x80240034
export const RAM_IES_YummyMeal = 0x8008d174
export const RAM_flo_18_defeat_80244C84 = 0x80244c84
export const RAM_b_area_sam2_sam_02d_UnkModelStuffScript2 = 0x80222d4c
export const RAM_b_area_omo_green_shy_guy_statusTable_8021FE24 = 0x8021fe24
export const RAM_update_effects = 0x80059d50
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022E198 = 0x8022e198
export const RAM_func_80240340_AE58D0 = 0x80240340
export const RAM_peach_check_for_parasol_input = 0x800e6428
export const RAM_func_8023E104 = 0x8023e104
export const RAM_gPauseWS_12 = 0x8026fba0
export const RAM_dro_01_EVS_Quizmo_Exit = 0x802452ac
export const RAM_ui_status_text_0_pal = 0x80100000
export const RAM_b_area_sam2_sam_02_UnkModelStuffScript4 = 0x802223f4
export const RAM_battle_move_hammer_throw_IsBerserkerEquipped = 0x802a1000
export const RAM_func_80219720_5B2BD0 = 0x80219720
export const RAM_b_area_omo2_general_guy_defenseTable = 0x80218330
export const RAM_ui_stencil_sharp_circle_png = 0x8014d300
export const RAM_dgb_13_80240270 = 0x80240270
export const RAM_b_area_arn_stageTable = 0x80228278
export const RAM_mac_05_GetNpcCollisionHeight = 0x802423b4
export const RAM_mac_05_Quizmo_FadeInWorld = 0x80241898
export const RAM_arn_10_npcAISettings_8024047C = 0x8024047c
export const RAM_D_802465F0 = 0x802465f0
export const RAM_kpa_51_set_script_owner_npc_col_height = 0x80241760
export const RAM_func_802A916C_425CBC = 0x802a916c
export const RAM_kzn_09_SuperBlock_SwitchToPartner = 0x8024041c
export const RAM_D_E00E4E38 = 0xe00e4e38
export const RAM_D_E009EE38 = 0xe009ee38
export const RAM_dgb_11_exitSingleDoor_80240170 = 0x80240170
export const RAM_func_80218000_6CC070 = 0x80218000
export const RAM_filemenu_get_menu_message = 0x80249aa4
export const RAM_jan_01_HoppingAI_Loiter = 0x80240968
export const RAM_battle_star_time_out_802A1E5C = 0x802a1e5c
export const RAM_PlayMerleeGatherFX = 0x8003f414
export const RAM_dgb_03_MeleeHitbox_Main = 0x8024060c
export const RAM_D_802A3554_799514 = 0x802a3554
export const RAM_D_802BDC40_317B60 = 0x802bdc40
export const RAM_SetActorVar = 0x8026c044
export const RAM_pra_39_reflection_setup_floor = 0x80240530
export const RAM_floating_cloud_puff_init = 0xe00d2118
export const RAM_b_area_isk_part_1_buzzy_beetle_takeTurn_8021FF8C = 0x8021ff8c
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_on_spin_smash = 0x80233918
export const RAM_b_area_kzn_formation_0C = 0x80229b48
export const RAM_battle_item_thunder_bolt_DrinkItem = 0x802a19e4
export const RAM_shim_guTranslateF = 0xe0200420
export const RAM_mac_02_Quizmo_CreateWorker = 0x80242824
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B6D0 = 0x8022b6d0
export const RAM_func_80036430 = 0x80036430
export const RAM_battle_menu_PartnerHudScripts = 0x802ab3f8
export const RAM_mac_00_PatrolNoAttackAI_15 = 0x80243044
export const RAM_IES_SweetShroom = 0x8008d114
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021B518 = 0x8021b518
export const RAM_sbk_30_LetterDelivery_CalcLetterPos = 0x802413d4
export const RAM_filemenu_draw_cursor = 0x80244030
export const RAM_arn_05_interact_80241E28 = 0x80241e28
export const RAM_EED1E0_SuperBlock_SwitchToPartner = 0x802404fc
export const RAM_b_area_kmr_part_2_kmr_04_afterBattle_80224FB0 = 0x80224fb0
export const RAM_arn_07_init_80245C9C = 0x80245c9c
export const RAM_bMarioDefenseTable = 0x80283420
export const RAM_flo_09_tree2_Callback = 0x8024330c
export const RAM_dgb_08_npcGroup_80245A50 = 0x80245a50
export const RAM_D_80245350_CD0660 = 0x80245350
export const RAM_D_802BCD1C_E2F64C = 0x802bcd1c
export const RAM_dup3_b_area_flo_StartRumbleWithParams = 0x80219300
export const RAM_D_09003CE8_3AED18 = 0x09003ce8
export const RAM_snowfall_render = 0xe008a6e4
export const RAM_EFEB00_UnkFloatFuncAbs = 0x80240340
export const RAM_ui_move_partner_2_png = 0x80103d20
export const RAM_dgb_18_func_80240EBC_C56DFC = 0x80240ebc
export const RAM_b_area_kzn_red_magikoopa_8022C414 = 0x80221154
export const RAM_D_80245BA0_A92620 = 0x80245ba0
export const RAM_battle_item_dried_shroom_EatItem = 0x802a180c
export const RAM_action_update_pushing_block = 0x802b6508
export const RAM_b_area_sam_gray_magikoopa = 0x8022c510
export const RAM_EF2680_LakituAI_Main = 0x80241c90
export const RAM_SetModelCustomGfx = 0x802c90fc
export const RAM_BattleCam_ModeY = 0x8029f2a2
export const RAM_dgb_16_ClubbaNappingAI_WakeUp = 0x8024095c
export const RAM_dup2_kmr_20_foliage_setup_shear_mtx = 0x80241060
export const RAM_dgb_03_PatrolAI_Loiter = 0x80240fe0
export const RAM_add_coins = 0x800ea0c0
export const RAM_dead_get_model_list_index_from_tree_index = 0x80125270
export const RAM_omo_11_SuperBlock_RadiateFaintEnergyFX = 0x80240d38
export const RAM_func_802403DC_BD3C4C = 0x802403dc
export const RAM_iwa_03_CleftAI_PreAmbush = 0x80240644
export const RAM_flo_03_npcGroup_8024404C = 0x8024404c
export const RAM_filemenu_draw_char = 0x80249380
export const RAM_b_area_kmr_part_3_paragoomba1_statusTable_8021C274 = 0x8021c274
export const RAM_battle_item_strange_cake_script7 = 0x802a2b70
export const RAM_D_8014BA48 = 0x8014ba48
export const RAM_flo_21_pad_17BC = 0x802417bc
export const RAM_D_80286540 = 0x80286540
export const RAM_kpa_51_MagikoopaAI_22 = 0x80240eb4
export const RAM_Entity_Signpost = 0x802eafdc
export const RAM_b_area_mac_chan_handleEvent_8021D0CC = 0x8021d0cc
export const RAM_b_area_jan_jan_03_afterBattle_80228370 = 0x80228370
export const RAM_nuAuDmaMesgQ = 0x800a3640
export const RAM_dro_01_EVS_Quizmo_OtherCamScript = 0x802454f8
export const RAM_vtx_C26390 = 0x80205450
export const RAM_MAMAR_song_id = 0x80420470
export const RAM_D_80159AD8 = 0x80159ad8
export const RAM_flo_12_interact_80241C8C = 0x80241c8c
export const RAM_flo_12_npcGroup_802425D8 = 0x802425d8
export const RAM_b_area_mac_formation_04 = 0x80232a40
export const RAM_func_802404A8_BBB828 = 0x802404a8
export const RAM_pra_34_reflection_render_wall = 0x802402f0
export const RAM_dgb_03_ClubbaNappingAI_Main = 0x80242480
export const RAM_arn_02_CleftAI_Main = 0x80240c90
export const RAM_D_80245638_C76AB8 = 0x80245638
export const RAM_dgb_03_main = 0x80242f30
export const RAM_b_area_trd_part_1_bill_blaster_specialFormation_8021A4E8 = 0x8021a4e8
export const RAM_D_E003A828 = 0xe003a828
export const RAM_world_kooper_test_first_strike = 0x802be818
export const RAM_jan_03_Quizmo_HideEntities = 0x80241cb4
export const RAM_dgb_07_MeleeHitbox_33 = 0x802401b4
export const RAM_update_model_animator = 0x8011e8bc
export const RAM_tik_22_Pipe_GetCameraYaw = 0x8024050c
export const RAM_b_area_isk_part_1_pokey_mummy_handleEvent_80219050 = 0x80219050
export const RAM_kkj_13_SetPlayerSpriteSet2 = 0x80240000
export const RAM_b_area_flo_GetBootsHammerLevel = 0x80218fac
export const RAM_is_picking_up_item = 0x801341e8
export const RAM_EEDF50_FlyingAI_LoiterInit = 0x80241628
export const RAM_arn_02_CleftAI_FindPlayerInit = 0x80240428
export const RAM_flo_25_pad_4734 = 0x80244734
export const RAM_set_animator_tree_to_node_map = 0x8011ffb4
export const RAM_b_area_pra3_duplighost_bombette_statusTable = 0x8021f408
export const RAM_kkj_01_PatrolAI_Jump = 0x802407a4
export const RAM_HES_Item_NuttyCake = 0x80083618
export const RAM_ui_box_corners6_png = 0x8010a6e0
export const RAM_kzn_09_MeleeHitbox_32 = 0x802410fc
export const RAM_flo_00_PatrolAI_PostLoiter = 0x802406bc
export const RAM_D_090003B0_3927F0 = 0x090003b0
export const RAM_HES_Peril = 0x801089c4
export const RAM_EC7590_StashVars = 0x8024061c
export const RAM_flo_18_interact_802457D4 = 0x802457d4
export const RAM_gAuAmbienceManager = 0x8009a628
export const RAM_D_802B7BC0_E23170 = 0x802b7bc0
export const RAM_b_area_isk_part_1_isk_03 = 0x80223a8c
export const RAM_b_area_hos_magikoopa_UnkFunc52 = 0x802183f8
export const RAM_battle_item_dusty_hammer_modelCommandList = 0x802a1b50
export const RAM_battle_move_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_b_area_kzn_putrid_piranha_statusTable_8021B100 = 0x8021b100
export const RAM_mac_maps = 0x80090050
export const RAM_func_802B6198_E24768 = 0x802b6198
export const RAM_end_00_CharAnim_FadeIn_0 = 0x80240020
export const RAM_dgb_15_defeat_802427B0 = 0x802427b0
export const RAM_b_area_trd_part_2_fake_bowser_8021BC88 = 0x8021bc88
export const RAM_toy_tank_as_fall_apart = 0x8021d318
export const RAM_D_E00E2A1C = 0xe00e2a1c
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_80220AEC = 0x80220aec
export const RAM_battle_action_cmd_whirlwind_2_png = 0x802a9a20
export const RAM_D_80247BB8_pra_19 = 0x80247bb8
export const RAM_suggest_player_anim_setUnkFlag = 0x800dff78
export const RAM_HES_UnusedDigit2 = 0x80107888
export const RAM_battle_star_time_out_UnkBackgroundFunc = 0x802a1414
export const RAM_func_80242B68_E07EC8 = 0x80242b68
export const RAM_func_802A2C84 = 0x802a2c84
export const RAM_entity_base_block_setupGfx = 0x802e30c0
export const RAM_ItemAfflictEnemy = 0x80252924
export const RAM_nok_12_UnkNpcAIFunc12 = 0x80240720
export const RAM_b_area_jan2_jan_00_beforeBattle_8021E1F4 = 0x8021e1f4
export const RAM_flo_16_npcGroup_802440F4 = 0x802440f4
export const RAM_gCollisionNormalX = 0x800a4258
export const RAM_D_E00D0B28 = 0xe00d0b28
export const RAM_D_802EAD7C = 0x802ead7c
export const RAM_flo_18_80245544 = 0x80245544
export const RAM_battle_menu_moveOptionSortPriorities = 0x802ad5d0
export const RAM_battle_item_stop_watch_GiveRefund = 0x802a1000
export const RAM_btl_state_update_twink_menu = 0x802aa0b8
export const RAM_D_80249B98 = 0x80249b98
export const RAM_func_802749F8 = 0x802749f8
export const RAM_D_09002150_352370 = 0x09002150
export const RAM_func_802D2B50 = 0x802d2b50
export const RAM_virtual_entity_create_at_index = 0x802d417c
export const RAM_dispatch_event_player = 0x80271658
export const RAM_HES_Item_BubbleBerry_disabled = 0x80086ac8
export const RAM_b_area_kpa4_UnkFunc52 = 0x80218588
export const RAM_D_80245390_C4BF70 = 0x80245390
export const RAM_IES_Unused_0E6 = 0x8008c9a4
export const RAM_flo_00_init_80244DD0 = 0x80244dd0
export const RAM_D_802EB3D0 = 0x802eb3d0
export const RAM_func_80240738_B20C78 = 0x80240738
export const RAM_D_80247678_D1FE58 = 0x80247678
export const RAM_mac_05_UpdateTexturePanStepped = 0x80240124
export const RAM_D_8010D644 = 0x8010d644
export const RAM_D_8024F5B8_C0CE38 = 0x8024f5b8
export const RAM_wTriggerList = 0x80159190
export const RAM_D_802BEBA8_31B118 = 0x802beba8
export const RAM_dgb_08_exitDoubleDoor_80243E44 = 0x80243e44
export const RAM_world_twink_put_away = 0x802bd244
export const RAM_EB9D90_SyncStatusMenu = 0x802413ac
export const RAM_load_data_for_models = 0x8011ad48
export const RAM_func_80059BD4 = 0x80059bd4
export const RAM_D_8024F1E0_A39420 = 0x8024f1e0
export const RAM_strlen = 0x80064a50
export const RAM_b_area_nok_formation_16 = 0x8021945c
export const RAM_kmr_02_Quizmo_SpinPlayer = 0x802411ac
export const RAM_D_E010E7FC = 0xe010e7fc
export const RAM_D_8023D27C = 0x8023d27c
export const RAM_b_area_hos_hos_00_beforeBattle_802256E8 = 0x802256e8
export const RAM_b_area_kmr_part_1_kmr_05_clouds3 = 0x802208d0
export const RAM_filemenu_init = 0x802448e0
export const RAM_SetActionSuccess = 0x802692c0
export const RAM_b_area_mac_chan = 0x80219e48
export const RAM_D_80249CCC = 0x80249ccc
export const RAM_nuGfxDisplayOff = 0x8005f400
export const RAM_dgb_09_ClubbaNappingAI_ReturnHome = 0x80240f00
export const RAM_D_80245358_CD0668 = 0x80245358
export const RAM_mim_01_PiranhaPlantAI_14 = 0x80241b68
export const RAM_btl_state_update_end_partner_turn = 0x8024721c
export const RAM_mac_04_Quizmo_SpinPlayer = 0x8024127c
export const RAM_pause_badges_count_all = 0x80247054
export const RAM_tik_09_UpdateTexturePanSmooth = 0x802402e0
export const RAM_D_80099BB0 = 0x80099bb0
export const RAM_b_area_kmr_part_2_blue_goomba = 0x8021998c
export const RAM_vtx_C2D500 = 0x8020c5c0
export const RAM_kpa_111_UnkNpcAIFunc48 = 0x80240360
export const RAM_dgb_01_D_80251E48_C0F6C8 = 0x80251e48
export const RAM_dgb_17_exitDoubleDoor_80240100 = 0x80240100
export const RAM_D_80153A4B = 0x80153a4b
export const RAM_sbk_01_npcAISettings_80240370 = 0x80240370
export const RAM_get_screen_overlay_params = 0x80137dc0
export const RAM_pause_banner_hammer_png = 0x8026d5b0
export const RAM_flower_trail_update = 0xe0012448
export const RAM_gStatsMenuWindowBPs = 0x8024f488
export const RAM_D_80228438_6CBFC8 = 0x80228438
export const RAM_isk_10_SuperBlock_get_partner_rank = 0x802400d4
export const RAM_func_80262130 = 0x80262130
export const RAM_dro_01_npcSettings_802450DC = 0x802450dc
export const RAM_battle_item_pebble_UseItemWithEffect = 0x802a1240
export const RAM_func_8024335C_8554CC = 0x8024335c
export const RAM_b_area_kmr_part_2_kmr_06_UnkAngleFunc2 = 0x802194a0
export const RAM_b_area_kkj_UnkLightningFXFunc = 0x80218550
export const RAM_heap_malloc = 0x8002ace4
export const RAM_b_area_trd_part_1_paratroopa_80220B84 = 0x80220b84
export const RAM_D_8010D67E = 0x8010d67e
export const RAM_EA0C10_MeleeHitbox_32 = 0x802403ec
export const RAM_sam_11_Quizmo_GetCamVfov = 0x802413b0
export const RAM_trd_04_ParatroopaAI_Overshoot = 0x802434f4
export const RAM_D_8009A040 = 0x8009a040
export const RAM_dgb_08_PatrolAI_JumpInit = 0x80240730
export const RAM_world_twink_take_out = 0x802bd1f0
export const RAM_gWindowGroups = 0x8014f2c0
export const RAM_gAnimVtxSegment = 0x80153a60
export const RAM_render_frame = 0x8002d73c
export const RAM_func_800E4F10 = 0x800e4f10
export const RAM_b_area_jan2_jan_02_foregroundModelList_8021F30C = 0x8021f30c
export const RAM_b_area_omo2_toy_tank_onDeath = 0x8022ed80
export const RAM_b_area_kzn_kzn_04c_afterBattle_80228DF0 = 0x80228df0
export const RAM_iwa_10_GetItemName = 0x802400f4
export const RAM_b_area_omo_formation_2C = 0x80230714
export const RAM_func_802414F4_D59D84 = 0x802414f4
export const RAM_func_80240054_DCCD24 = 0x80240054
export const RAM_arn_02_padding = 0x802412c4
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_8021ED00 = 0x8021ed00
export const RAM_mac_04_Quizmo_GetItemName = 0x80240514
export const RAM_IES_AttackFXB = 0x8008ca44
export const RAM_b_area_arn_arn_06 = 0x80227c38
export const RAM_b_area_kmr_part_3_paragoomba1_takeTurn_8021E788 = 0x8021e788
export const RAM_D_80251528_C0EDA8 = 0x80251528
export const RAM_D_E0022D78 = 0xe0022d78
export const RAM_mac_01_PatrolAI_JumpInit = 0x80245c80
export const RAM_func_80240DA4_8ECBC4 = 0x80240da4
export const RAM_func_80240214_B769C4 = 0x80240214
export const RAM_isk_16_StarSpiritEffectFunc6 = 0x80240708
export const RAM_ui_green_arrow_up_pal = 0x800fc4c0
export const RAM_func_80240000_907A30 = 0x80240000
export const RAM_b_area_trd_part_2_trd_05_afterBattle_802334B0 = 0x802334b0
export const RAM_func_802422C0_8B2330 = 0x802422c0
export const RAM_D_802A1140_73D9A0 = 0x802a1140
export const RAM_EB1170_GetItemName = 0x802403c4
export const RAM_kmr_11_StationaryAI_ReturnHomeInit = 0x802408b0
export const RAM_load_entity_model = 0x80120ce0
export const RAM_battle_partner_goombario_80239CA8 = 0x80239ca8
export const RAM_b_area_omo_omo_03 = 0x8022dc80
export const RAM_flo_00_PostChapter6StatUpdate = 0x80240ed0
export const RAM_D_8023CD78_6F5E58 = 0x8023cd78
export const RAM_b_area_sam_duplighost_UnkWattEffectFunc4 = 0x802193a4
export const RAM_kzn_02_StashVars = 0x80241fcc
export const RAM_mdl_renderModelFogPrimColorG = 0x8014b751
export const RAM_D_802B68B4 = 0x802b68b4
export const RAM_battle_action_cmd_whirlwind_7_pal = 0x802aa180
export const RAM_func_8024206C_83386C = 0x8024206c
export const RAM_D_802AD607 = 0x802ad607
export const RAM_kpa_70_UnkNpcAIFunc12 = 0x802404c0
export const RAM_b_area_jan_medi_guy_defenseTable_8021F8DC = 0x8022259c
export const RAM_b_area_mim_formation_07 = 0x8021e728
export const RAM_mim_08_PiranhaPlantAI_11 = 0x80242a10
export const RAM_ui_fp_cost_png = 0x800fc2a0
export const RAM_mgm_00_PatrolAI_JumpInit = 0x80240a10
export const RAM_HES_CDownButtonHeld = 0x8029265c
export const RAM_energy_in_out_update = 0xe00d6720
export const RAM_flo_21_func_80240B00_CE7200 = 0x80240b00
export const RAM_battle_star_focus_UnkBackgroundFunc2 = 0x802a137c
export const RAM_tik_07_FlyingAI_Init = 0x802425d8
export const RAM_D_80250820_C0E0A0 = 0x80250820
export const RAM_iwa_04_CleftAI_LosePlayer = 0x80240a04
export const RAM_arn_08_80240A70 = 0x80240a70
export const RAM_vtx_C2C6C0 = 0x8020b780
export const RAM_mac_00_PatrolAI_MoveInit = 0x80242570
export const RAM_dgb_01_init_80245E44 = 0x80245e44
export const RAM_flower_splash_update_part_transform = 0xe0010000
export const RAM_battle_item_coconut_coconutDL = 0x802a1a60
export const RAM_IES_DriedFruit = 0x8008d9d4
export const RAM_b_area_jan2_formation_02 = 0x8021f604
export const RAM_D_80245D60_9FA7A0 = 0x80245d60
export const RAM_mac_04_Quizmo_SetStageLightsDelay = 0x80240e9c
export const RAM_battle_item_sleepy_sheep_GiveRefundCleanup = 0x802a11d4
export const RAM_pra_01_reflection_unk_resolve_anim = 0x80240020
export const RAM_b_area_trd_part_1_formation_10 = 0x80218960
export const RAM_ui_coin_sparkle_2_png = 0x800fbd00
export const RAM_dro_01_Quizmo_FadeInWorld = 0x802419e8
export const RAM_hos_03_Quizmo_CreateReactionEffect = 0x8024349c
export const RAM_b_area_sbk_pokey_idleAnimations_80218528 = 0x80218528
export const RAM_EC9D00_SuperBlock_AnimateEnergyOrbs = 0x80240600
export const RAM_ui_fp_cost_reduced_pal = 0x800fc300
export const RAM_dgb_08_npcSettings_802441A8 = 0x802441a8
export const RAM_NAMESPACE_StartRumbleWithParams = 0x80267d9c
export const RAM_tik_01_UpdateTexturePanSmooth = 0x80240000
export const RAM_dgb_01_80244AD0 = 0x80244ad0
export const RAM_func_80072CF4 = 0x80072cf4
export const RAM_kzn_09_SuperBlock_CountEligiblePartners = 0x80240120
export const RAM_trd_08_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_09000EE0_3272F0 = 0x09000ee0
export const RAM_pra_27_SomeItemEntityFunc = 0x80241068
export const RAM_msg_get_printer_for_msg = 0x80125900
export const RAM_b_area_omo2_3_shy_stack_idle = 0x80232fa0
export const RAM_b_area_omo2_omo_07_afterBattle = 0x8022fb5c
export const RAM_HES_Item_KootEmptyWallet = 0x800820e8
export const RAM_arn_04_CleftAI_CanSeePlayer = 0x80242388
export const RAM_D_8029B8A8 = 0x8029b8a8
export const RAM_b_area_kmr_part_3_sam_01_beforeBattle_80233998 = 0x80233998
export const RAM_D_802BE938 = 0x802be938
export const RAM_set_main_pan_v = 0x8011bc34
export const RAM_D_E0200720 = 0xe0200720
export const RAM_b_area_kmr_part_3_paragoomba1_partsTable = 0x8021c320
export const RAM_b_area_iwa_buzzar_nextTurn_8021B918 = 0x8021b918
export const RAM_printf = 0x80025cc0
export const RAM_arn_04_CleftAI_PostDisguise = 0x80242fd4
export const RAM_pause_stats_handle_input = 0x80246b74
export const RAM_HES_Item_MysteryNote = 0x800818a8
export const RAM_D_E00C8740 = 0xe00c8740
export const RAM_D_E00C0A40 = 0xe00c0a40
export const RAM_D_E003E878 = 0xe003e878
export const RAM_air_lift_bss_pre = 0x802a98a8
export const RAM_arn_03_interact_80242DC4 = 0x80242dc4
export const RAM_b_area_jan2_white_magikoopa_handleEvent_80223E24 = 0x8021a1d4
export const RAM_FallPartTo = 0x8027a854
export const RAM_flo_18_entryList = 0x802407d0
export const RAM_aura_render = 0xe0076808
export const RAM_b_area_trd_part_1_formation_18 = 0x80218c08
export const RAM_dro_02_D_80248B24_971CE4 = 0x80248b24
export const RAM_b_area_isk_part_1_pokey_mummy_statusTable_80218C1C = 0x80218c1c
export const RAM_sbk_01_SetNpcB5_3 = 0x80240040
export const RAM_dist2D = 0x80029ed4
export const RAM_IES_UltraShroom = 0x8008d814
export const RAM_dro_01_PatrolAI_LosePlayer = 0x8024104c
export const RAM_D_802449B0_87E590 = 0x802449b0
export const RAM_gPauseMsg_37 = 0x8026f690
export const RAM_D_80243F20_D2FC50 = 0x80243f20
export const RAM_b_area_sam2_paragoomba_ArcsinDeg = 0x80218588
export const RAM_battle_item_dried_shroom_UseItemWithEffect = 0x802a1340
export const RAM_battle_partner_goombario_func_80238A20_6F1B00 = 0x80238a20
export const RAM_phys_save_ground_pos = 0x800e54e0
export const RAM_flo_08_pad_4F3C = 0x80244f3c
export const RAM_b_area_omo3_Add1Coin = 0x802186c8
export const RAM_ECFFF0_DeadUnkFunc1 = 0x80240000
export const RAM_dup_pra_02_GetEntityPosition = 0x802415bc
export const RAM_dup3_b_area_pra_GetSelectedMoveID = 0x80218458
export const RAM_pra_27_reflection_unk_change_anim_facing = 0x80240084
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_80220AA0 = 0x80220aa0
export const RAM_func_800E06C0 = 0x800e06c0
export const RAM_sam_06_ToadHouse_AwaitScriptComplete = 0x80242094
export const RAM_guLookAtReflect = 0x80063898
export const RAM_b_area_trd_part_2_trd_05d = 0x80234d58
export const RAM_osContInit = 0x80061b80
export const RAM_func_802407C4_B20D04 = 0x802407c4
export const RAM_D_802455F0_C76A70 = 0x802455f0
export const RAM_func_80238388_708028 = 0x80238388
export const RAM_get_dpad_input_radial = 0x8023e000
export const RAM_HES_Item_SweetShroom_disabled = 0x80082bc8
export const RAM_dgb_00_settings = 0x802400d0
export const RAM_ui_battle_status_happy_png = 0x801035e0
export const RAM_D_800B1B0D = 0x800b1b0d
export const RAM_D_E00D0B20 = 0xe00d0b20
export const RAM_partner_kill_ability_script = 0x800eb7cc
export const RAM_func_80240118_D36408 = 0x80240118
export const RAM_HES_HandPointDownHeld = 0x800801d8
export const RAM_EDEA00_LakituAI_GetAvailableSpiny = 0x802416e8
export const RAM_vtx_C22E00 = 0x80201ec0
export const RAM_b_area_nok_paratroopa_8021F26C = 0x8022409c
export const RAM_b_area_isk_part_1_buzzy_beetle_handleEvent_8021F228 = 0x8021f228
export const RAM_D_E00C49FC = 0xe00c49fc
export const RAM_dgb_01_D_80252EF4_C10774 = 0x80252ef4
export const RAM_mac_01_Quizmo_SpinPartner = 0x80242d90
export const RAM_dro_02_80248A50 = 0x80248a50
export const RAM_HES_MenuDefendDisabled = 0x802921d8
export const RAM_D_8010CCFA = 0x8010ccfa
export const RAM_b_area_trd_part_2_trd_00_beforeBattle_802313E0 = 0x802313e0
export const RAM_b_area_pra3_swoopula_returnHome = 0x80227060
export const RAM_EA0C10_MeleeHitbox_Main = 0x802406cc
export const RAM_sam_06_FlyingAI_WanderInit = 0x80240550
export const RAM_func_802BB664_E2BD94 = 0x802bb664
export const RAM_omo_09_PatrolAI_MoveInit = 0x80240f4c
export const RAM_gItemTable = 0x800878e0
export const RAM_TweesterTouchingPlayer = 0x8010c934
export const RAM_butterflies_update = 0xe00aa10c
export const RAM_func_802193D4_6BCF64 = 0x802193d4
export const RAM_sbk_31_SpawnSunEffect = 0x80240000
export const RAM_sam_01_Quizmo_SetVannaAnim_Clap = 0x80241208
export const RAM_D_80077800 = 0x80077800
export const RAM_b_area_hos_goombario_tutor_defenseTable_8021905C = 0x8021905c
export const RAM_HES_Item_Unused_12F_disabled = 0x800866a8
export const RAM_D_802ECD30 = 0x802ecd30
export const RAM_func_802405E8_800E68 = 0x802405e8
export const RAM_NAMESPACE_init_80238780 = 0x80238780
export const RAM_D_802E9788 = 0x802e9788
export const RAM_RotateModel = 0x802c8c64
export const RAM_D_802ED670 = 0x802ed670
export const RAM_b_area_jan_jan_01_802272D0 = 0x802272d0
export const RAM_D_802A2DA0_0073CC70 = 0x802a2da0
export const RAM_flo_25_80244740 = 0x80244740
export const RAM_b_area_arn_formation_04 = 0x80227d5c
export const RAM_get_enemy_safe = 0x8003ed68
export const RAM_D_09000AA0_326EB0 = 0x09000aa0
export const RAM_butterflies_main = 0xe00aa000
export const RAM_D_80245614 = 0x80245614
export const RAM_HES_Item_FirePop = 0x800832b8
export const RAM_flo_11_vectorList_80241C84 = 0x80241c84
export const RAM_evt_handle_break_case = 0x802c56d4
export const RAM_func_80266EA8 = 0x80266ea8
export const RAM_b_area_arn_hyper_paragoomba_8021F0F4 = 0x8021f0f4
export const RAM_b_area_kmr_part_3_final_jr_troopa_idleAnimations_8022C40C = 0x8022c40c
export const RAM_D_802463B8_915A68 = 0x802463b8
export const RAM_flower_trail_update_part_transform = 0xe0012000
export const RAM_NAMESPACE_takeTurn_80239AB8 = 0x80239ab8
export const RAM_ui_battle_cmd_aim_reticle_0_png = 0x8028ef80
export const RAM_HES_StickTapNeutral = 0x80108ff0
export const RAM_battle_menu_moveOptionDescriptions = 0x802ad570
export const RAM_b_area_trd_part_1_trd_02b_80223360 = 0x80223360
export const RAM_energy_orb_wave_appendGfx = 0xe00a4650
export const RAM_b_area_sam_formation_05 = 0x80230b0c
export const RAM_D_E003A820 = 0xe003a820
export const RAM_func_802BFBA0_3236F0 = 0x802bfba0
export const RAM_D_09002D40_3ADD70 = 0x09002d40
export const RAM_D_E00A29D0 = 0xe00a29d0
export const RAM_D_80243BCC_A1ECCC = 0x80243bcc
export const RAM_nok_14_FlyingAI_ChaseInit = 0x80241654
export const RAM_D_E0014780 = 0xe0014780
export const RAM_b_area_tik3_UnkBattleFunc2 = 0x8021819c
export const RAM_D_802BE300 = 0x802be300
export const RAM_dup_kmr_11_foliage_setup_shear_mtx = 0x80240f20
export const RAM_flo_14_GetNpcCollisionHeight = 0x80242008
export const RAM_nok_12_PatrolAI_Move = 0x80240e9c
export const RAM_flo_25_FlyingNoAttackAI_Main = 0x80241944
export const RAM_func_801341B0 = 0x801341b0
export const RAM_dead_switch_to_partner = 0x800f2d5c
export const RAM_HES_Item_FryingPan = 0x80081848
export const RAM_flo_13_npcGroupList_80247984 = 0x80247984
export const RAM_arn_09_npcGroupList_80240974 = 0x80240974
export const RAM_D_8009A140 = 0x8009a140
export const RAM_mgm_01_BlockPosY = 0x80241ad4
export const RAM_PrevPlayerDirection = 0x8010c95c
export const RAM_fio_init_flash = 0x8002b84c
export const RAM_D_80253378 = 0x80253378
export const RAM_ui_input_analog_stick3_png = 0x800f9640
export const RAM_func_802401AC_B7695C = 0x802401ac
export const RAM_func_800E98EC = 0x800e98ec
export const RAM_dgb_00_interact_80241CD8 = 0x80241cd8
export const RAM_HES_Item_FrightJar_disabled = 0x800825c8
export const RAM_btl_state_draw_prepare_menu = 0x802439d0
export const RAM_is_anim_model_fog_enabled = 0x8012008c
export const RAM_gAnimCount = 0x801512d4
export const RAM_func_802187F0_6DE5B0 = 0x802187f0
export const RAM_omo_09_ShyGuyPatrolAI_Main = 0x80242014
export const RAM_D_E0022D70 = 0xe0022d70
export const RAM_jan_05_get_tattle = 0x80240000
export const RAM_func_80238114_6F5F94 = 0x80238114
export const RAM_HES_Item_HoneyCandy_disabled = 0x80083228
export const RAM_func_80242934_C883E4 = 0x80242934
export const RAM_b_area_jan_jan_01b = 0x80228264
export const RAM_tik_08_UpdateTexturePanStepped = 0x80240404
export const RAM_sprite_unk_checkers_png = 0x800745e0
export const RAM_D_802461F0 = 0x802461f0
export const RAM_func_80116674 = 0x80116674
export const RAM_ShowGotItem = 0x802d73a4
export const RAM_snd_ambient_quick_fade_out = 0x800554a4
export const RAM_HES_Item_Lyrics = 0x80081d28
export const RAM_arn_04_pad_3654 = 0x80243654
export const RAM_sbk_34_LetterDelivery_Init = 0x802404ac
export const RAM_omo_05_FlyingAI_Main = 0x80241d60
export const RAM_dro_02_Quizmo_NPC_Aux_Impl = 0x802406ac
export const RAM_battle_item_stop_watch_UseItem = 0x802a1664
export const RAM_battle_partner_goombario_partsTable_802380C4 = 0x802380c4
export const RAM_create_audio_system = 0x8004ab00
export const RAM_jan_00_LetterDelivery_RestoreNpcAnim = 0x80240b20
export const RAM_partner_do_player_collision = 0x800ef640
export const RAM_D_E00C29A0 = 0xe00c29a0
export const RAM_flo_13_init_802463B0 = 0x802463b0
export const RAM_D_802466C8_EA6FC8 = 0x802466c8
export const RAM_dgb_09_MeleeHitbox_30 = 0x80240000
export const RAM_get_time_freeze_mode = 0x80027190
export const RAM_D_8024119C_D7FEFC = 0x8024119c
export const RAM_b_area_trd_part_1_vector3D_80218BA8 = 0x80218ba8
export const RAM_D_80243F28_D2FC58 = 0x80243f28
export const RAM_rising_bubble_render = 0xe004627c
export const RAM_mac_00_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_misc_particles_init = 0xe00e4288
export const RAM_b_area_nok_fuzzy_UnkBattleFunc2_2 = 0x80218964
export const RAM_D_8024E800_C0C080 = 0x8024e800
export const RAM_mac_00_Pipe_GetCameraYaw = 0x802401d0
export const RAM_b_area_trd_part_2_trd_05e_afterBattle_80235520 = 0x80235520
export const RAM_b_area_kmr_part_2_kmr_05_clouds2 = 0x802250f8
export const RAM_dgb_01_extraAnimationList_80246850 = 0x80246850
export const RAM_func_802A1098 = 0x802a1098
export const RAM_b_area_pra3_pra_02_foregroundModelList = 0x80231edc
export const RAM_dro_01_npcSettings_80245108 = 0x80245108
export const RAM_D_8015438B = 0x8015438b
export const RAM_gActionCommandStatus = 0x8029fbe0
export const RAM_dro_01_init_80249B10 = 0x80249b10
export const RAM_func_8024216C_8EDF8C = 0x8024216c
export const RAM_entity_Signpost_idle = 0x802e9050
export const RAM_D_8024FAB0_C0D330 = 0x8024fab0
export const RAM_D_800741A4 = 0x800741a4
export const RAM_trd_04_FlyingAI_WanderInit = 0x80241e18
export const RAM_flo_13_FlyingAI_Wander = 0x80240374
export const RAM_dgb_01_D_80252E9C_C1071C = 0x80252e9c
export const RAM_dead_sqrtf = 0x80067040
export const RAM_func_80241CC8_8D31D8 = 0x80241cc8
export const RAM_func_80050F64 = 0x80050f64
export const RAM_GetCamPitch = 0x802cbff4
export const RAM_EDEA00_FlyingAI_Main = 0x802414d0
export const RAM_D_8024F582 = 0x8024f582
export const RAM_pause_interp_vertical_scroll = 0x8024346c
export const RAM_dro_01_PatrolAI_JumpInit = 0x80240d10
export const RAM_func_80244224_9DB244 = 0x80244224
export const RAM_IES_PDownDUp = 0x8008c624
export const RAM_D_802441D8_ED41C8 = 0x802441d8
export const RAM_b_area_hos_formation_00 = 0x80225e60
export const RAM_battle_item_repel_gel_GiveRefund = 0x802a1000
export const RAM_sam_06_StashVars = 0x80241a48
export const RAM_D_80228480_6CC010 = 0x80228480
export const RAM_mgm_00_PatrolAI_MoveInit = 0x802402e0
export const RAM_func_800F4944 = 0x800f4944
export const RAM_func_E00D6978 = 0xe00d6978
export const RAM_mac_01_Quizmo_NPC_OnRender = 0x802422dc
export const RAM_dead_playFX_11 = 0x80075170
export const RAM_HES_UnusedCompass = 0x80241e50
export const RAM_func_802A10C8_759678 = 0x802a10c8
export const RAM_b_area_kzn_red_magikoopa_8022E6A0 = 0x802233e0
export const RAM_au_BGMCmd_F0_TrackTremolo = 0x8004ffc8
export const RAM_ui_status_pow_star_empty_png = 0x80100ac0
export const RAM_D_802DA1E0 = 0x802da1e0
export const RAM_SetCamBGColor = 0x802cad98
export const RAM_dro_02_D_80248C84_971E44 = 0x80248c84
export const RAM_jan_06_HoppingAI_ChaseInit = 0x80240f0c
export const RAM_func_E00E4954 = 0xe00e4954
export const RAM_battle_item_ultra_shroom_func_802A12FC_723A7C = 0x802a12fc
export const RAM_nuGfxTaskStart = 0x8002ccc4
export const RAM_tik_20_Pipe_GetCurrentFloor = 0x8024032c
export const RAM_D_80242070_8CC970 = 0x80242070
export const RAM_battle_action_cmd_water_block_2_png = 0x802aa2a0
export const RAM_flo_18_triggerCoord_802456B4 = 0x802456b4
export const RAM_D_80200000 = 0x80200000
export const RAM_b_area_kpa_UnkBattleFunc1 = 0x80218000
export const RAM_D_802BCCD4_E2F604 = 0x802bccd4
export const RAM_b_area_omo_groove_guy_idle_80224B9C = 0x80224b9c
export const RAM_D_80242330 = 0x80242330
export const RAM_entity_SaveBlock_setupGfx = 0x802e0de0
export const RAM_dgb_04_FlyingAI_Chase = 0x80240df4
export const RAM_D_E0116D08 = 0xe0116d08
export const RAM_D_80243448_8D14B8 = 0x80243448
export const RAM_au_bgm_update_main = 0x8004d510
export const RAM_b_area_kzn_lava_bubble_init_8021901C = 0x8021901c
export const RAM_b_area_nok_paragoomba = 0x8021b310
export const RAM_ui_unused_1_png = 0x800fc7a0
export const RAM_ui_coin_sparkle_1_png = 0x800fbcc0
export const RAM_entity_GiantChest_give_equipment = 0x802e7ae4
export const RAM_dgb_01_D_80251CE8_C0F568 = 0x80251ce8
export const RAM_kkj_10_SetEntityFlags100000 = 0x80240070
export const RAM_kpa_01_UnkNpcAIFunc12 = 0x80240310
export const RAM_kpa_61_FlyingMagikoopaAI_11 = 0x80241200
export const RAM_b_area_sbk_pokey_vector3D_8021AE04 = 0x8021ae04
export const RAM_omo_17_FlyingAI_LosePlayer = 0x80241e0c
export const RAM_EnemyNpcHit = 0x80077f70
export const RAM_vtx_C24AF0 = 0x80203bb0
export const RAM_b_area_omo_spy_guy_GetItemEntityPosition = 0x80218a98
export const RAM_pra_28_reflection_render_floor_fancy = 0x802408a0
export const RAM_purple_ring_main = 0xe003e000
export const RAM_dgb_01_D_802538A0_C11120 = 0x802538a0
export const RAM_nuGfxSwapCfbFunc = 0x800773f4
export const RAM_D_80244DF8_A1B018 = 0x80244df8
export const RAM_D_80078EE0 = 0x80078ee0
export const RAM_EC2610_foliage_setup_shear_mtx = 0x80240410
export const RAM_au_sfx_manager_update = 0x8004c444
export const RAM_gRenderModelEnvG = 0x8014b764
export const RAM_b_area_kmr_part_2_blue_goomba_2_init_8021D59C = 0x8021d59c
export const RAM_mac_05_PatrolAI_Loiter = 0x80240950
export const RAM_vtx_C22B00 = 0x80201bc0
export const RAM_b_area_mim_bzzap_defenseTable_8021A86C = 0x8021a86c
export const RAM_light_rays_update = 0xe006a580
export const RAM_D_802AD6C0 = 0x802ad6c0
export const RAM_HES_JpUltra = 0x80241f44
export const RAM_flo_12_D_80241854_CC2684 = 0x80241854
export const RAM_ui_status_pow_star_5_png = 0x80100a00
export const RAM_HES_StickBackAndForth = 0x80108e80
export const RAM_dro_02_D_80247A38_970BF8 = 0x80247a38
export const RAM_chapter_change_init = 0xe010e4a0
export const RAM_vtx_C21AE0 = 0x80200ba0
export const RAM_embers_update = 0xe00e01d8
export const RAM_dead_set_message_msg = 0x80130c74
export const RAM_kmr_02_StationaryAI_IdleInit = 0x80241424
export const RAM_entity_inactive_block_recoil_anim = 0x802e37e4
export const RAM_sam_02_Quizmo_GetItemName = 0x80240be0
export const RAM_mac_05_PatrolAI_ChaseInit = 0x80240cac
export const RAM_flo_08_SuperBlock_WaitForPlayerToLand = 0x80240d08
export const RAM_flo_19_80241B10 = 0x80241b10
export const RAM_IES_LuckyStar = 0x8008b4a4
export const RAM_battle_item_life_shroom_main = 0x802a1fcc
export const RAM_draw_encounters_conversation = 0x800431d4
export const RAM_HES_Item_SweetShroom = 0x80082b98
export const RAM_IES_Unused_089 = 0x8008be04
export const RAM_battle_menu_moveOptionsEnabled = 0x802ad510
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021C4F8 = 0x8021c4f8
export const RAM_func_8024226C_8B22DC = 0x8024226c
export const RAM_kkj_11_UnkModelMatrixFunc2 = 0x8024036c
export const RAM_D_80247444_B3C014 = 0x80247444
export const RAM_b_area_omo_anti_guy_802220FC = 0x802220fc
export const RAM_D_E02006F4 = 0xe02006f4
export const RAM_HES_StatusSPIncrement5 = 0x80108338
export const RAM_D_800AF914 = 0x800af914
export const RAM_omo_09_PatrolAI_Loiter = 0x8024140c
export const RAM_b_area_kmr_part_3_jr_troopa_80222B30 = 0x80222b30
export const RAM_D_8014BEA0 = 0x8014bea0
export const RAM_fx_smoke_ring = 0x80070130
export const RAM_HES_Item_HealthyJuice_disabled = 0x80082c28
export const RAM_tik_07_SuperBlock_LoadCurrentPartnerName = 0x80240728
export const RAM_b_area_isk_part_1_isk_02_afterBattle_802239EC = 0x802239ec
export const RAM_D_80249A90_A33CD0 = 0x80249a90
export const RAM_D_80245388_EEB938 = 0x80245388
export const RAM_gBattleShadowList = 0x80151568
export const RAM_HES_Item_Apple = 0x80087158
export const RAM_D_8029F64E = 0x8029f64e
export const RAM_func_802BE4E8_319238 = 0x802be4e8
export const RAM_D_8014C6E0 = 0x8014c6e0
export const RAM_b_area_trd_part_1_bob_omb_handleEvent_8021B4A8 = 0x8021b4a8
export const RAM_D_80244BEC_ED8E0C = 0x80244bec
export const RAM_b_area_kmr_part_3_mage_jr_troopa_nextTurn_8022BB44 = 0x8022bb44
export const RAM_arn_08_8024118C = 0x8024118c
export const RAM_IES_ITEM_127 = 0x8008d6b4
export const RAM_obfuscated_create_audio_system = 0x8004aa85
export const RAM_func_80244E10_805690 = 0x80244e10
export const RAM_HES_Whirlwind4 = 0x802aa778
export const RAM_IES_Unused_08A = 0x8008be24
export const RAM_sam_06_GetNpcCollisionHeight = 0x80241b60
export const RAM_func_E00C4300 = 0xe00c4300
export const RAM_dro_01_npcList_8024C210 = 0x8024c210
export const RAM_D_802AC090 = 0x802ac090
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022A750 = 0x8022a750
export const RAM_arn_03_PatrolAI_MoveInit = 0x80240000
export const RAM_func_80240FC0_B45730 = 0x80240fc0
export const RAM_b_area_kmr_part_3_egg_jr_troopa = 0x802244e8
export const RAM_b_area_sam_duplighost_bombette_takeTurn = 0x80220ca0
export const RAM_D_80240480_90B6C0 = 0x80240480
export const RAM_b_area_isk_part_2_isk_03_afterBattle_8022006C = 0x8022006c
export const RAM_pra_34_ReflectWall = 0x802400ec
export const RAM_b_area_mac_master1_statusTable_8022A2B8 = 0x8022a2b8
export const RAM_DoorModelsSwingCCW = 0x802dbd28
export const RAM_b_area_kmr_part_2_kmr_06 = 0x8022582c
export const RAM_mac_03_Pipe_GetCameraYaw = 0x802401d0
export const RAM_dup2_b_area_tik_UnkBattleFunc1 = 0x802189e0
export const RAM_kpa_50_ProjectileHitbox_GetUsableProjectileID = 0x80240000
export const RAM_D_80257B68 = 0x80257b68
export const RAM_func_E00B24A8 = 0xe00b24a8
export const RAM_b_area_omo_pyro_guy_handleEvent_8022CE60 = 0x8022ce60
export const RAM_func_802400DC_D7895C = 0x802400dc
export const RAM_b_area_kmr_part_1_kmr_05_afterBattle_80220A5C = 0x80220a5c
export const RAM_dro_02_idle_8024D154 = 0x8024d154
export const RAM_AddBadge = 0x802d6ff8
export const RAM_b_area_omo2_toy_tank_8021A3A8 = 0x8021a3a8
export const RAM_start_script_in_group = 0x802c35d0
export const RAM_func_80240584_8AC514 = 0x80240584
export const RAM_dgb_00_80240D90 = 0x80240d90
export const RAM_b_area_sam_pos_rocks_7 = 0x802307d8
export const RAM_ui_input_analog_stick_down_pal = 0x800f9a60
export const RAM_D_80244104 = 0x80244104
export const RAM_func_802A9000_42F6E0 = 0x802a9000
export const RAM_D_E003E870 = 0xe003e870
export const RAM_EDEA00_FlyingAI_JumpInit = 0x80240da0
export const RAM_b_area_sbk_formation_0C = 0x8021fcc0
export const RAM_D_80234860_6BBA60 = 0x80234860
export const RAM_sbk_36_foliage_setup_shear_mtx = 0x80240040
export const RAM_nok_02_Quizmo_SetVannaAnim_Idle = 0x80242400
export const RAM_msg_draw_rewind_arrow = 0x80126eac
export const RAM_b_area_kmr_part_3_final_jr_troopa_80232040 = 0x80232040
export const RAM_sam_04_TransformFoliage = 0x80240070
export const RAM_snd_load_BK_to_bank = 0x800547c8
export const RAM_nok_02_Quizmo_Answers = 0x8024712c
export const RAM_func_80240034_ACE174 = 0x80240034
export const RAM_IES_HealthyJuice = 0x8008d134
export const RAM_dgb_18_UnkFunc1 = 0x80240e90
export const RAM_IES_Hammer3 = 0x8008b258
export const RAM_osViGetCurrentContext = 0x8006cba0
export const RAM_HES_Item_SlowGo_disabled = 0x800845a8
export const RAM_flo_07_80241D6C = 0x80241d6c
export const RAM_guOrthoF = 0x80063910
export const RAM_filemenu_draw_message = 0x8024997c
export const RAM_b_area_sam_duplighost_watt_handleEvent = 0x80223adc
export const RAM_basic_ai_suspend = 0x8004a73c
export const RAM_D_E00328E8 = 0xe00328e8
export const RAM_func_802A9228 = 0x802a9228
export const RAM_IES_ShrinkSmash = 0x8008c7e4
export const RAM_delete_model_animator_node = 0x8011e398
export const RAM_entity_WoodenCrate_shatter = 0x802e88ec
export const RAM_D_8024F58B = 0x8024f58b
export const RAM_b_area_omo_groove_guy_partsTable_80224AEC = 0x80224aec
export const RAM_mac_03_Quizmo_StashVars = 0x802404b0
export const RAM_status_menu_draw_number = 0x800e7ca4
export const RAM_battle_star_star_storm_FadeBackgroundToBlack = 0x802a12e4
export const RAM_gCollisionStatus = 0x8015a550
export const RAM_MakeNpcs = 0x80044298
export const RAM_D_E00DE9D0 = 0xe00de9d0
export const RAM_EC9D00_SuperBlock_WhiteScreenFlash = 0x80240b38
export const RAM_ui_battle_cont_b_up_png = 0x8028abe0
export const RAM_pause_banner_star_energy_png = 0x8026d930
export const RAM_nok_02_Quizmo_SpinPlayer = 0x8024272c
export const RAM_dead_set_main_pan_u = 0x80126064
export const RAM_D_8029D950 = 0x8029d950
export const RAM_D_80244DE0_A1B000 = 0x80244de0
export const RAM_jan_00_LetterDelivery_SaveNpcAnim = 0x80240adc
export const RAM_b_area_pra3_gray_magikoopa_init_flying = 0x802312bc
export const RAM_b_area_dgb_clubba = 0x80218128
export const RAM_jan_04_TransformFoliage = 0x80240810
export const RAM_D_8029FB7C = 0x8029fb7c
export const RAM_ui_empty_bar_pal = 0x800fcd20
export const RAM_omo_04_FlyingAI_WanderInit = 0x802403a0
export const RAM_osr_01_LetterDelivery_CalcLetterPos = 0x802408bc
export const RAM_func_80240410_7E77B0 = 0x80240410
export const RAM_D_8024F2C0 = 0x8024f2c0
export const RAM_balloon_appendGfx = 0xe00961bc
export const RAM_b_area_kzn_putrid_piranha_init_8021B1F8 = 0x8021b1f8
export const RAM_dgb_08_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_D_800A0BB4 = 0x800a0bb4
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022C804 = 0x8022c804
export const RAM_D_8024AAB8_A34CF8 = 0x8024aab8
export const RAM_kzn_07_UpdateTexturePanStepped = 0x80240530
export const RAM_func_802A10A4_77F2E4 = 0x802a10a4
export const RAM_mgm_02_MsgImg_PeachPanel = 0x802484c0
export const RAM_dgb_18_main = 0x80241490
export const RAM_tik_07_SuperBlock_WaitForPlayerToLand = 0x80240fe8
export const RAM_trd_04_FlyingAI_Init = 0x80243000
export const RAM_D_80153223 = 0x80153223
export const RAM_D_80241880_D7B270 = 0x80241880
export const RAM_tik_09_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_80228418_6CBFA8 = 0x80228418
export const RAM_D_E0014788 = 0xe0014788
export const RAM_D_802AD044 = 0x802ad044
export const RAM_tik_19_SuperBlock_AnimateEnergyOrbs = 0x80240bf0
export const RAM_star_spirits_energy_appendGfx = 0xe012270c
export const RAM_func_80240BFC_A16E1C = 0x80240bfc
export const RAM_HES_MenuItem = 0x80108924
export const RAM_jan_12_SpearGuyAI_Main = 0x80240fa4
export const RAM_D_8010C940 = 0x8010c940
export const RAM_nok_02_PatrolAI_LosePlayer = 0x802414ec
export const RAM_osViBlack = 0x80067380
export const RAM_floating_flower_main = 0xe001a000
export const RAM_kzn_08_FlyingAI_Chase = 0x80241844
export const RAM_flo_25_triggerCoord_802446AC = 0x802446ac
export const RAM_disable_partner_blur = 0x80254668
export const RAM_resume_group_script_index = 0x802c4570
export const RAM_kzn_04_EVS_FireBar_Defeated = 0x8024231c
export const RAM_D_8014F068 = 0x8014f068
export const RAM_func_80241720_8442F0 = 0x80241720
export const RAM_battle_item_stone_cap_PlayerGoHome = 0x802a1800
export const RAM_b_area_sam_duplighost_takeTurn = 0x80227c40
export const RAM_RunAwayFail = 0x80287078
export const RAM_kkj_20_varStash = 0x80240880
export const RAM_dgb_15_unk_missing_80242158 = 0x80242158
export const RAM_D_090008C0_32D780 = 0x090008c0
export const RAM_HES_Item_TripleDip = 0x80084b18
export const RAM_D_8015A2C0 = 0x8015a2c0
export const RAM_Entity_ShatteringHammer1BlockTiny = 0x802e9cf8
export const RAM_IES_DriedShroom = 0x8008d854
export const RAM_b_area_omo2_general_guy_init = 0x8021858c
export const RAM_HES_MoveDiamondDisabled = 0x801093d8
export const RAM_reset_all_actor_sounds = 0x80267a9c
export const RAM_dgb_16_MeleeHitbox_32 = 0x802400dc
export const RAM_dgb_01_D_80252BB0_C10430 = 0x80252bb0
export const RAM_D_00E42240 = 0x00e42240
export const RAM_b_area_trd_part_2_trd_02_802315F8 = 0x802315f8
export const RAM_omo_08_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_dgb_18_init_80242924 = 0x80242924
export const RAM_b_area_omo_groove_guy_idleAnimations_802249E0 = 0x802249e0
export const RAM_dead_guTranslateF = 0x8006cac0
export const RAM_func_802BB108_E2FA58 = 0x802bb108
export const RAM_D_80241E28_AD5EB8 = 0x80241e28
export const RAM_D_802BE308 = 0x802be308
export const RAM_D_8014F51C = 0x8014f51c
export const RAM_b_area_sam_duplighost_UnkEffect6CFunc = 0x80218a90
export const RAM_SetVirtualEntityScale = 0x802d38ec
export const RAM_b_area_omo_blue_shy_guy_8021AFC4 = 0x8021afc4
export const RAM_arn_12_npcGroup_802407DC = 0x802407dc
export const RAM_func_80238028_714D18 = 0x80238028
export const RAM_b_area_trd_part_2_trd_05c_beforeBattle_802343B4 = 0x802343b4
export const RAM_EnablePlayerBlur = 0x80274978
export const RAM_b_area_trd_part_1_bullet_bill_idle_80219380 = 0x80219380
export const RAM_D_8024F304 = 0x8024f304
export const RAM_IES_CakeBerries = 0x8008b204
export const RAM_battle_action_cmd_water_block_4_png = 0x802aaf00
export const RAM_D_E0116D3B = 0xe0116d3b
export const RAM_sam_02_Quizmo_ShouldQuizmoLeave = 0x80241668
export const RAM_D_80247610_DF64B0 = 0x80247610
export const RAM_vtx_C256D0 = 0x80204790
export const RAM_motion_blur_flame_main = 0xe00a2000
export const RAM_D_802A1D60 = 0x802a1d60
export const RAM_ResetCam = 0x802cc8c8
export const RAM_D_8014B400 = 0x8014b400
export const RAM_kpa_15_StashVars = 0x80240310
export const RAM_EA8AE0_LetterDelivery_CalcLetterPos = 0x802419c4
export const RAM_mac_01_ToadHouse_PartnerResumeAbilityScript = 0x8024394c
export const RAM_D_802DF460 = 0x802df460
export const RAM_tik_19_SuperBlock_SwitchToPartner = 0x80240a0c
export const RAM_D_80245210 = 0x80245210
export const RAM_func_80241320_919ED0 = 0x80241320
export const RAM_b_area_kmr_part_3_kmr_03_clouds3 = 0x80232560
export const RAM_D_802E0090 = 0x802e0090
export const RAM_func_802416A4_D31324 = 0x802416a4
export const RAM_flo_00_PatrolAI_Jump = 0x802407e4
export const RAM_b_area_mim_bzzap_idleAnimations_8021A820 = 0x8021a820
export const RAM_D_802EE8D0 = 0x802ee8d0
export const RAM_battle_action_cmd_whirlwind_5_png = 0x802a9de0
export const RAM_omo_17_ShyGuyWanderAI_15 = 0x802405d0
export const RAM_gPauseDLBannerStarEnergy = 0x8026ef68
export const RAM_flo_14_80245224 = 0x80245224
export const RAM_pause_spirits_init = 0x8024d360
export const RAM_state_init_pause = 0x80034940
export const RAM_kmr_02_ToadHouse_GetPartnerBackOut = 0x80243334
export const RAM_update_heroes_start_turn_A2C264 = 0x80242024
export const RAM_flo_13_exitWalk_80243CEC = 0x80243cec
export const RAM_battle_item_life_shroom_PlayerGoHome = 0x802a1930
export const RAM_b_area_omo_green_shy_guy_idleAnimations_8021FF1C = 0x8021ff1c
export const RAM_pra_19_ReflectPartner = 0x80240d3c
export const RAM_update_entities = 0x8010f6d0
export const RAM_btl_state_draw_select_target = 0x802aacd8
export const RAM_func_802406BC_8EC4DC = 0x802406bc
export const RAM_D_80249980_A33BC0 = 0x80249980
export const RAM_HES_MenuDoNothingDisabled = 0x802922c8
export const RAM_dust_render = 0xe002a380
export const RAM_player_raycast_below_cam_relative = 0x800de46c
export const RAM_nuGfxTask = 0x800d9278
export const RAM_D_800A15A4 = 0x800a15a4
export const RAM_mtx_mirror_y = 0x80254288
export const RAM_b_area_jan_medi_guy_80220CEC = 0x802239ac
export const RAM_b_area_isk_part_2_chain_chomp_takeTurn_8021A7C4 = 0x8021a7c4
export const RAM_flo_10_80241CB4 = 0x80241cb4
export const RAM_D_802348E0_6BBAE0 = 0x802348e0
export const RAM_D_8029D958 = 0x8029d958
export const RAM_au_fade_clear = 0x80053a18
export const RAM_vtx_C22C00 = 0x80201cc0
export const RAM_b_area_iwa_whacka_idle_8021FE5C = 0x8021fe5c
export const RAM_cloud_trail_main = 0xe0016000
export const RAM_battle_item_electro_pop_GiveRefund = 0x802a1000
export const RAM_kpa_95_ToadHouse_ShowStatusMenu = 0x80241628
export const RAM_D_8024F2C8 = 0x8024f2c8
export const RAM_D_802985AC = 0x802985ac
export const RAM_set_part_yaw = 0x80263f9c
export const RAM_kpa_115_ProjectileHitbox_31 = 0x802405a4
export const RAM_hos_02_FlyingAI_JumpVels = 0x80244490
export const RAM_mim_maps = 0x80092050
export const RAM_kzn_11_UpdateTexturePanStepped = 0x80240124
export const RAM_dgb_01_D_80252738_C0FFB8 = 0x80252738
export const RAM_battle_star_star_storm_802A247C = 0x802a247c
export const RAM_D_800A0942 = 0x800a0942
export const RAM_b_area_sam2_sam_02b_UnkModelStuffScript3 = 0x80222754
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021C5E0 = 0x8021c5e0
export const RAM_gCurtainFade = 0x8009ba98
export const RAM_HES_FPCost = 0x80107638
export const RAM_b_area_nok_paratroopa_intTable_802211D0 = 0x80226000
export const RAM_D_80241BAE_86E2EE = 0x80241bae
export const RAM_pra_19_reflection_render_floor = 0x8024068c
export const RAM_b_area_omo2_toy_tank = 0x8022c95c
export const RAM_EC7590_GetItemName = 0x802406d0
export const RAM_kzn_03_func_802408B4_97BEE4 = 0x80240b84
export const RAM_b_area_mac_lee_takeTurn_80221200 = 0x80221200
export const RAM_b_area_isk_part_1_vector3D_802241E8 = 0x802241e8
export const RAM_D_80246D70_DC4020 = 0x80246d70
export const RAM_jan_03_Pipe_GetEntryPos = 0x802400f0
export const RAM_fx_gather_energy_pink = 0x8006ffb0
export const RAM_jan_22_foliage_setup_shear_mtx = 0x802413d0
export const RAM_ui_status_text_fp_png = 0x80100660
export const RAM_mac_01_ToadHouse_UpdateScreenOverlay = 0x802437f4
export const RAM_motion_blur_flame_update = 0xe00a2120
export const RAM_func_80240B3C_A0C82C = 0x80240b3c
export const RAM_D_80247BF0 = 0x80247bf0
export const RAM_disable_actor_blur = 0x8025455c
export const RAM_HES_Item_ShootingStar_disabled = 0x80086288
export const RAM_D_802A9F0C = 0x802a9f0c
export const RAM_IES_PeachKey = 0x8008bb44
export const RAM_arn_04_CleftAI_Disguise = 0x80242f6c
export const RAM_b_area_kzn_lava_bubble_statusTable_80218E3C = 0x80218e3c
export const RAM_pra_11_CheckItemFlags40 = 0x80241188
export const RAM_b_area_kzn_kzn_04_afterBattle_80228328 = 0x80228328
export const RAM_func_80241008_A3A4E8 = 0x80241008
export const RAM_quizmo_audience_appendGfx = 0xe00a8320
export const RAM_au_SEFCmd_12_NOP = 0x8004d31c
export const RAM_D_802435E0_ACE100 = 0x802435e0
export const RAM_func_A4000040 = 0xa4000040
export const RAM_trd_02_PatrolAI_Jump = 0x80241058
export const RAM_fx_3D_main = 0xe007a000
export const RAM_battle_partner_goombario_goombario = 0x802392c8
export const RAM_func_80243C40_9DAC60 = 0x80243c40
export const RAM_dgb_04_FlyingAI_WanderInit = 0x80240000
export const RAM_HES_Item_AttackFXF = 0x80085d18
export const RAM_dgb_02_MeleeHitbox_31 = 0x8024006c
export const RAM_IES_JellySuper = 0x8008d294
export const RAM_b_area_isk_part_2_chain_chomp_statusTable_80218C88 = 0x80218c88
export const RAM_flo_19_80241CC4 = 0x80241cc4
export const RAM_b_area_omo2_4_signal_guy_defenseTable = 0x80231000
export const RAM_flo_14_80244F30 = 0x80244f30
export const RAM_arn_08_pad_11FC = 0x802411fc
export const RAM_sam_06_LetterDelivery_CalcLetterPos = 0x8024233c
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022ED10 = 0x8022ed10
export const RAM_dgb_04_SuperBlock_PartnerSparkles4 = 0x80243090
export const RAM_omo_05_FlyingAI_Init = 0x80241c3c
export const RAM_D_80232BAC_68492C = 0x80232bac
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021B384 = 0x8021c104
export const RAM_D_802459CC_A001BC = 0x802459cc
export const RAM_kzn_02_GetItemName = 0x80242080
export const RAM_b_area_jan2_putrid_piranha_bite = 0x80218f60
export const RAM_b_area_trd_part_2_fake_bowser_80222C44 = 0x80222c44
export const RAM_b_area_sam_duplighost_parakarry_idleAnimations = 0x80221590
export const RAM_b_area_jan2_putrid_piranha_handleEvent_8021B3D0 = 0x802189f0
export const RAM_kzn_11_FlyingAI_Chase = 0x80241254
export const RAM_b_area_hos_ember_vector3D_8021B594 = 0x8021b594
export const RAM_b_area_jan2_white_magikoopa_UnkBattleFunc5 = 0x8021861c
export const RAM_D_802347C8_6BB9C8 = 0x802347c8
export const RAM_b_area_sam_sam_02c_UnkModelStuffScript3 = 0x8022fc84
export const RAM_flo_11_Pipe_ScriptD = 0x80240f1c
export const RAM_b_area_nok_spiked_goomba = 0x8021e48c
export const RAM_guRotateRPYF = 0x800642d0
export const RAM_func_802409E8_BD4258 = 0x802409e8
export const RAM_dro_01_pad_C574 = 0x8024c574
export const RAM_tik_12_SuperBlock_WaitForPlayerToLand = 0x80241924
export const RAM_dgb_07_npcGroup_80241A7C = 0x80241a7c
export const RAM_trd_01_StationaryAI_AlertInit = 0x80240d98
export const RAM_b_area_dig_IsSaveVar123 = 0x802183e0
export const RAM_UpdateCrystalBitEffect = 0x802185d0
export const RAM_b_area_sam2_gray_magikoopa_StartRumbleWithParams = 0x80218a60
export const RAM_D_80294280 = 0x80294280
export const RAM_dgb_00_entryList = 0x802400b0
export const RAM_b_area_sam2_gray_magikoopa_ShrinkActor = 0x80218810
export const RAM_pra_37_ReflectPartner = 0x80240d6c
export const RAM_battle_item_food_func_802A12EC_7333BC = 0x802a12ec
export const RAM_b_area_mac_master1_nextTurn_8022B06C = 0x8022b06c
export const RAM_tik_18_UnkPosFunc2 = 0x80240310
export const RAM_PeachEnterStage = 0x80284d04
export const RAM_omo_04_SetCamera0MoveFlag1 = 0x802402d8
export const RAM_iwa_10_SuperBlock_GatherEnergyFX = 0x80240834
export const RAM_b_area_arn_formation_0E = 0x80228050
export const RAM_entity_HeartBlock_idle = 0x802e5308
export const RAM_D_800A3BD4 = 0x800a3bd4
export const RAM_gPauseBadgesTargetScrollPos = 0x80270390
export const RAM_func_800EA52C = 0x800ea52c
export const RAM_func_8013B0EC = 0x8013b0ec
export const RAM_flo_18_80241284 = 0x80241284
export const RAM_battle_star_peach_beam_802A24F0 = 0x802a24f0
export const RAM_HES_Item_RedKey = 0x80081c38
export const RAM_func_802188E4_4EFD84 = 0x802188e4
export const RAM_has_full_hp = 0x800ea360
export const RAM_D_8015A1E2 = 0x8015a1e2
export const RAM_func_80114B58 = 0x80114b58
export const RAM_bgm_set_proximity_mix_far = 0x80056028
export const RAM_func_80059D48 = 0x80059d48
export const RAM_shockwave_main = 0xe004a000
export const RAM_gCurrentEntityModelList = 0x80154370
export const RAM_mac_01_PatrolAI_Loiter = 0x80241540
export const RAM_gPauseTabsMessages = 0x8024f2e8
export const RAM_gPauseMapTargetX = 0x80270714
export const RAM_func_802404E8_8AC478 = 0x802404e8
export const RAM_b_area_kmr_part_1_kmr_04_clouds1 = 0x80220250
export const RAM_SetOwnerID = 0x8026de28
export const RAM_arn_07_npcGroup_802472AC = 0x802472ac
export const RAM_pra_18_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_attack_result_text_main = 0xe0090000
export const RAM_hud_element_set_transform_scale = 0x801451d4
export const RAM_EE70C0_FlyingAI_Init = 0x80241cf8
export const RAM_func_80241B74_A3B054 = 0x80241b74
export const RAM_b_area_kmr_part_3_kmr_03 = 0x8023274c
export const RAM_tik_17_SuperBlock_WaitForPlayerToLand = 0x80240d38
export const RAM_b_area_hos_hos_01_foregroundModelList_802255A8 = 0x802255a8
export const RAM_dgb_09_SentinelAI_DescendInit = 0x80242b78
export const RAM_D_802A2DF0 = 0x802a2df0
export const RAM_D_E00E4E00 = 0xe00e4e00
export const RAM_arn_04_CleftAI_Main = 0x80243018
export const RAM_D_E009EE00 = 0xe009ee00
export const RAM_battle_item_fire_flower_GiveRefundCleanup = 0x802a11d4
export const RAM_b_area_omo_formation_23 = 0x80230404
export const RAM_clear_windows = 0x801473f0
export const RAM_HES_POWCostReducedTwice = 0x80107740
export const RAM_D_800778A0 = 0x800778a0
export const RAM_HES_Item_KootGlasses = 0x800821a8
export const RAM_entity_ItemBlock_replace_with_inactive = 0x802e421c
export const RAM_D_802A11AC_765DCC = 0x802a11ac
export const RAM_battle_item_strange_cake_D_802A2858_732B58 = 0x802a2858
export const RAM_nok_12_KoopaPatrolAI_Main = 0x80241bd4
export const RAM_dgb_01_init_80245CF0 = 0x80245cf0
export const RAM_b_area_jan_jan_03b_foregroundModelList_80228420 = 0x80228420
export const RAM_dro_02_idle_8024C450 = 0x8024c450
export const RAM_D_80244E30_A1B050 = 0x80244e30
export const RAM_D_802AD10A = 0x802ad10a
export const RAM_b_area_trd_part_1_trd_03_clouds3 = 0x80223db0
export const RAM_kzn_20_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802BBE8C_E2E7BC = 0x802bbe8c
export const RAM_trd_10_StarSpiritEffectFunc1 = 0x80240000
export const RAM_D_E002A738 = 0xe002a738
export const RAM_D_E0060738 = 0xe0060738
export const RAM_gPauseDLCheckAbilities = 0x8026f4c8
export const RAM_b_area_kmr_part_1_kmr_05_UnkAngleFunc2 = 0x80218cc0
export const RAM_dro_02_D_80247808_9709C8 = 0x80247808
export const RAM_mac_03_Quizmo_ShouldAppear = 0x802405ec
export const RAM_btl_state_draw_end_turn = 0x80243fd4
export const RAM_dgb_07_ClubbaNappingAI_WakeUp = 0x8024095c
export const RAM_omo_07_SetCamera0Flag1000 = 0x80242e70
export const RAM_battle_item_mushroom_UseItem = 0x802a1904
export const RAM_D_80151324 = 0x80151324
export const RAM_gPauseMapSpaces = 0x802502b8
export const RAM_dup5_b_area_flo_UnkBattleFunc1 = 0x80219234
export const RAM_b_area_trd_part_1_trd_02b = 0x80223b50
export const RAM_flo_00_func_802410B4_C9EFD4 = 0x802410b4
export const RAM_ui_battle_cmd_aim_reticle_1_pal = 0x8028f3a0
export const RAM_D_8023BDC4 = 0x8023bdc4
export const RAM_b_area_pra3_red_magikoopa_ShrinkActor = 0x80219830
export const RAM_dro_01_interact_80248BA0 = 0x80248ba0
export const RAM_flo_16_exitWalk_80242A9C = 0x80242a9c
export const RAM_func_802A9A54 = 0x802a9a54
export const RAM_jan_09_SpearGuyAI_Main = 0x80241424
export const RAM_jan_03_Quizmo_ShowEntities = 0x80241cc8
export const RAM_b_area_kmr_part_3_paragoomba2_StartRumbleWithParams = 0x80218560
export const RAM_arn_12_settings = 0x80240020
export const RAM_sbk_06_Bandit_DropCoin = 0x80240040
export const RAM_fx_breaking_junk = 0x800727d0
export const RAM_world_goombario_update = 0x802bdd8c
export const RAM_D_802AA85C_4254CC = 0x802aa85c
export const RAM_vtx_C257D0 = 0x80204890
export const RAM_set_global_byte = 0x80145520
export const RAM_SpriteUpdateNotifyValue = 0x802dfeac
export const RAM_jan_03_Pipe_GetCurrentFloor = 0x80240020
export const RAM_ui_battle_status_refund_pal = 0x801035c0
export const RAM_tik_09_Pipe_AwaitDownInput = 0x80240034
export const RAM_D_E0050570 = 0xe0050570
export const RAM_kmr_02_Quizmo_SetVannaAnim_Wave = 0x80240eb4
export const RAM_isk_13_HoppingAI_Hop = 0x80240230
export const RAM_get_shadow_list = 0x8011064c
export const RAM_pra_11_ReflectPartner = 0x80240d3c
export const RAM_mac_05_Quizmo_NPC_Aux_Impl = 0x802416cc
export const RAM_b_area_omo_omo_04_vector3D_8022DE40 = 0x8022de40
export const RAM_kkj_11_UnkAtan2Func = 0x80240644
export const RAM_D_802A10F0_740DB0 = 0x802a10f0
export const RAM_D_E00CCED8 = 0xe00cced8
export const RAM_dead_set_max_SP = 0x800f1d9c
export const RAM_b_area_omo2_2_stilt_guy_fallOff = 0x802333a4
export const RAM_BindNpcIdle = 0x80044bac
export const RAM_D_802E9B78 = 0x802e9b78
export const RAM_D_802A3258_739AE8 = 0x802a3288
export const RAM_flo_17_npcAISettings_80244424 = 0x80244424
export const RAM_b_area_omo_anti_guy_defenseTable_80221910 = 0x80221910
export const RAM_func_8024030C_B2084C = 0x8024030c
export const RAM_D_8007F180 = 0x8007f180
export const RAM_trd_02_TackleAI_Main = 0x80240474
export const RAM_func_802B704C = 0x802b704c
export const RAM_D_09000D08 = 0x09000d08
export const RAM_arn_07_FlyingAI_LoiterInit = 0x80241128
export const RAM_b_area_dgb_clubba_statusTable_80218058 = 0x80218058
export const RAM_func_80240318_7E76B8 = 0x80240318
export const RAM_flo_00_interact_802436C0 = 0x802436c0
export const RAM_D_80242F88_A67018 = 0x80242f88
export const RAM_D_80244328_BE7AD8 = 0x80244328
export const RAM_D_09001C80_351EA0 = 0x09001c80
export const RAM_D_E00CCED0 = 0xe00cced0
export const RAM_dgb_09_npcGroup_8024514C = 0x8024514c
export const RAM_D_80246E40_9F4A30 = 0x80246e40
export const RAM_b_area_nok_koopa_troopa_intTable_8021D1C0 = 0x80220740
export const RAM_b_area_trd_part_2_trd_01_afterBattle_8023149C = 0x8023149c
export const RAM_func_80240434_C805D4 = 0x80240434
export const RAM_mac_06_PlayFX3D = 0x80240c74
export const RAM_b_area_sam2_sam_03_UnkModelStuffScript2 = 0x802230cc
export const RAM_b_area_pra2_sam_04_Set80071270_0_16 = 0x802188b8
export const RAM_D_E007EC68 = 0xe007ec68
export const RAM_entity_TriggerBlock_disable_player_input = 0x802e44f8
export const RAM_hos_02_UpdateTexturePanSmooth = 0x80240000
export const RAM_gEffectGraphicsData = 0x800a4000
export const RAM_b_area_nok_paratroopa_partsTable_8021EC6C = 0x80223a9c
export const RAM_SetGameMode = 0x802d5a28
export const RAM_dead_fx_sparkles = 0x80075170
export const RAM_D_802A2DF8 = 0x802a2df8
export const RAM_phys_player_land = 0x800e3278
export const RAM_b_area_nok_paratroopa_takeTurn_80221DAC = 0x80226bdc
export const RAM_kmr_20_ToadHouse_DoesPlayerNeedSleep = 0x80241460
export const RAM_EA36E0_UnkFunc46 = 0x80242e2c
export const RAM_dgb_11_func_80240000_C4D3E0 = 0x80240000
export const RAM_func_80045AC0 = 0x80045ac0
export const RAM_trd_04_ParatroopaAI_Reset = 0x8024360c
export const RAM_omo_14_DarkRoomUpdate = 0x80240000
export const RAM_ui_battle_cmd_aim_shimmer_1_pal = 0x8028eba0
export const RAM_b_area_omo2_3_shy_stack_idleAnimations_damage = 0x80231484
export const RAM_b_area_pra3_duplighost_UnkBackgroundFunc3 = 0x8021943c
export const RAM_D_802B7C40_E20D50 = 0x802b7c40
export const RAM_dgb_15_SetEntityFlags100000 = 0x80241830
export const RAM_jan_05_HoppingAI_HopInit = 0x802408a4
export const RAM_b_area_sam2_sam_03_foregroundModelList = 0x80223458
export const RAM_arn_07_init_80245058 = 0x80245058
export const RAM_func_8024552C_805DAC = 0x8024552c
export const RAM_b_area_isk_part_1_isk_06b_beforeBattle_80223F40 = 0x80223f40
export const RAM_battle_star_star_beam_802A2888 = 0x802a2888
export const RAM_b_area_jan2_jan_01b_foregroundModelList_8021F28C = 0x8021f28c
export const RAM_func_8021875C_4EFBFC = 0x8021875c
export const RAM_b_area_mac_master1 = 0x8022a388
export const RAM_b_area_isk_part_2_isk_06b = 0x80220368
export const RAM_D_80151110 = 0x80151110
export const RAM_D_802DB7C0 = 0x802db7c0
export const RAM_D_8024D3C8_C0AC48 = 0x8024d3c8
export const RAM_tik_17_SuperBlock_HideBlockContent = 0x8024006c
export const RAM_chapter_change_update = 0xe010e4a8
export const RAM_D_8009A678 = 0x8009a678
export const RAM_b_area_kmr_part_1_goomba = 0x802196ec
export const RAM_dup_sam_11_Set80151310_2 = 0x80240540
export const RAM_enable_status_icon_boost_partner = 0x80047f28
export const RAM_init_entity_models = 0x80120c58
export const RAM_b_area_nok_formation_08 = 0x80219050
export const RAM_SetAnimatedModelRootPosition = 0x802ccfe4
export const RAM_D_80244448_C3A978 = 0x80244448
export const RAM_arn_09_802405B0 = 0x802405b0
export const RAM_func_80240AF0_BD4360 = 0x80240af0
export const RAM_check_for_pulse_stone = 0x800e0398
export const RAM_enable_status_icon_danger = 0x8004824c
export const RAM_dgb_01_D_8025223C_C0FABC = 0x8025223c
export const RAM_HES_AimMarkerA = 0x80292d1c
export const RAM_ai_check_player_dist = 0x800493ec
export const RAM_b_area_nok_stageTable = 0x80219728
export const RAM_vtx_C23240 = 0x80202300
export const RAM_b_area_trd_part_1_formation_02 = 0x802184e4
export const RAM_init_sprite_shading_data = 0x801480f0
export const RAM_pink_sparkles_main = 0xe0124000
export const RAM_D_802B7910_E217C0 = 0x802b7910
export const RAM_msg_draw_char = 0x8012c324
export const RAM_b_area_pra3_formation_07 = 0x80232690
export const RAM_b_area_sam_duplighost_formation_parakarry = 0x80222500
export const RAM_D_80154382 = 0x80154382
export const RAM_lldiv = 0x8006d6a4
export const RAM_D_800A0932 = 0x800a0932
export const RAM_D_E00BEB18 = 0xe00beb18
export const RAM_mac_05_Quizmo_AddViewRelativeOffset = 0x80241fa0
export const RAM_isk_04_func_802408B4_97BEE4 = 0x802408b4
export const RAM_dgb_09_80244000 = 0x80244000
export const RAM_D_802479B0_DF6850 = 0x802479b0
export const RAM_collision_heap_create = 0x8002ad74
export const RAM_battle_item_thunder_bolt_func_802A123C_722D7C = 0x802a123c
export const RAM_au_BGMCmd_EE_SubTrackFineTune = 0x8004ffa8
export const RAM_HES_BoostPartner = 0x801075e0
export const RAM_HES_Item_MagicalSeed1 = 0x80081908
export const RAM_kzn_04_SuperBlock_GatherEnergyFX = 0x80240854
export const RAM_fx_spiky_white_aura = 0x80070310
export const RAM_D_80078DB4 = 0x80078db4
export const RAM_vtx_C2DA20 = 0x8020cae0
export const RAM_flo_08_SuperBlock_StartGlowEffect = 0x80240480
export const RAM_pra_18_SetPartnerFlags80000 = 0x80240eb8
export const RAM_set_time_freeze_mode = 0x80027088
export const RAM_pra_10_reflection_setup_wall = 0x80240128
export const RAM_HES_Item_StoneCap = 0x800863d8
export const RAM_ui_battle_status_transparent_2_png = 0x80102ea0
export const RAM_kzn_04_SuperBlock_StartGlowEffect = 0x80240790
export const RAM_tik_17_SuperBlock_RadiateFaintEnergyFX = 0x802405d8
export const RAM_gPauseWS_3 = 0x8026f9a8
export const RAM_omo_05_ShyGuyWanderAI_14 = 0x80240000
export const RAM_b_area_arn_formationTable = 0x80228110
export const RAM_battle_item_insecticide_herb_PlayerGoHome = 0x802a1850
export const RAM_D_E0020C90 = 0xe0020c90
export const RAM_flo_13_npcSettings_80244100 = 0x80244100
export const RAM_ui_ultra_boots_png = 0x800fb360
export const RAM_D_802BCC8C_E2F5BC = 0x802bcc8c
export const RAM_D_80240E90_AF87E0 = 0x80240e90
export const RAM_D_800776FC = 0x800776fc
export const RAM_vtx_C25250 = 0x80204310
export const RAM_func_802BD1D0_3170F0 = 0x802bd1d0
export const RAM_b_area_sam2_paragoomba_init_8021B3E8 = 0x8021c168
export const RAM_nuGfxTaskEndFunc = 0x800773f8
export const RAM_sam_06_ToadHouse_Stub = 0x80242244
export const RAM_func_8025D640 = 0x8025d640
export const RAM_D_802A98E0_42FFC0 = 0x802a98e0
export const RAM_b_area_kmr_part_3_nok_01_foregroundModelList_80233630 = 0x80233630
export const RAM_b_area_trd_part_2_fake_bowser_partsTable_8021A748 = 0x8021a748
export const RAM_vtx_C296C0 = 0x80208780
export const RAM_iwa_01_CleftAI_PostDisguise = 0x80242f10
export const RAM_battle_item_strange_cake_func_802A1818_731B18 = 0x802a1818
export const RAM_kmr_05_TransformFoliage = 0x80241570
export const RAM_func_80282C40 = 0x80282c40
export const RAM_func_8024366C_8B36DC = 0x8024366c
export const RAM_battle_item_thunder_rage_GiveRefundCleanup = 0x802a11d4
export const RAM_HES_Item_JadeRaven = 0x80081ba8
export const RAM_SlashHudScript = 0x800f7fac
export const RAM_gPauseMsg_4B = 0x8026f738
export const RAM_sam_02_Quizmo_AudienceEffect = 0x8024b9b4
export const RAM_D_802DA0F0 = 0x802da0f0
export const RAM_b_area_sam2_sam_02 = 0x8022260c
export const RAM_evt_handle_mod = 0x802c59bc
export const RAM_EE70C0_FlyingAI_JumpInit = 0x802416ec
export const RAM_set_window_update = 0x80147e7c
export const RAM_HES_Item_BlandMeal = 0x80082c58
export const RAM_b_area_mac_lee_handleEvent_80222468 = 0x80222468
export const RAM_dro_02_D_80248D60_971F20 = 0x80248d60
export const RAM_get_stored_count = 0x800e7814
export const RAM_D_80242B90_91E470 = 0x80242b90
export const RAM_nok_01_Quizmo_UpdateRecords = 0x80241454
export const RAM_IES_PowerPlus = 0x8008bf24
export const RAM_func_80240374_D7F0D4 = 0x80240374
export const RAM_evt_handle_end_child_thread = 0x802c6df4
export const RAM_gLastCreatedEntityIndex = 0x801512ec
export const RAM_func_80259494 = 0x80259494
export const RAM_D_E00C8718 = 0xe00c8718
export const RAM_delete_entity_and_unload_data = 0x80110790
export const RAM_nok_12_TackleAI_Main = 0x80240904
export const RAM_kpa_102_FlyingMagikoopaAI_10 = 0x80242410
export const RAM_HES_Item_ElectroPop_disabled = 0x80083288
export const RAM_func_800E24F8 = 0x800e24f8
export const RAM_D_80152214 = 0x80152214
export const RAM_dgb_10_80240E68 = 0x80240e68
export const RAM_flo_18_TransformFoliage = 0x80240600
export const RAM_flo_11_entryList = 0x80240320
export const RAM_gv_01_Pipe_GetCameraYaw = 0x802401d0
export const RAM_b_area_kmr_part_2_formationTable = 0x80219840
export const RAM_SetDefenseTable = 0x8027ca74
export const RAM_b_area_mac_lee_formation_bombette = 0x8022336c
export const RAM_b_area_trd_part_1_trd_02d_foregroundModelList_80225428 = 0x80225428
export const RAM_obk_01_LetterDelivery_Init = 0x80240c4c
export const RAM_debuff_init = 0xe0062218
export const RAM_tik_17_SuperBlock_SwitchToPartner = 0x8024044c
export const RAM_func_802A9298 = 0x802a9298
export const RAM_b_area_omo_formation_1F = 0x80230298
export const RAM_battle_star_peach_beam_802A2C20 = 0x802a2c20
export const RAM_player_raycast_down = 0x800de54c
export const RAM_arn_08_80240300 = 0x80240300
export const RAM_kpa_51_UnkDistFunc = 0x802414c8
export const RAM_D_E00CCDD8 = 0xe00ccdd8
export const RAM_mac_02_PatrolAI_Chase = 0x8024127c
export const RAM_b_area_jan_jan_01b_80227BC8 = 0x80227bc8
export const RAM_iwa_02_CleftAI_RevUp = 0x80240694
export const RAM_sam_02_Quizmo_SetVannaAnim_Wave = 0x80241650
export const RAM_b_area_isk_part_1_pokey_mummy_defenseTable_80218C10 = 0x80218c10
export const RAM_b_area_pra3_duplighost_lakilester_defenseTable = 0x80225258
export const RAM_b_area_iwa_buzzar_8021FA2C = 0x8021fa2c
export const RAM_EA8AE0_UpdateTexturePanSmooth = 0x80240000
export const RAM_battle_partner_goombario_IsGlowing = 0x802390b0
export const RAM_b_area_trd_part_3_eldstar_handleEvent_80218404 = 0x80218404
export const RAM_D_09000C08 = 0x09000c08
export const RAM_b_area_isk_part_1_formation_02 = 0x8022415c
export const RAM_vtx_C238B0 = 0x80202970
export const RAM_jan_11_Pipe_GetPointAheadOfPlayer = 0x80240394
export const RAM_osFlashWriteArray = 0x8006f61c
export const RAM_dgb_17_pad_F8 = 0x802400f8
export const RAM_jan_03_PatrolAI_Move = 0x80240778
export const RAM_kkj_17_StashVars = 0x80240000
export const RAM_EA0C10_LetterDelivery_CalcLetterPos = 0x80242bd8
export const RAM_nuGfxTaskMgrInit = 0x8002cb38
export const RAM_RemoveItem = 0x802d6b9c
export const RAM_osr_00_UnkFunc27 = 0x80240380
export const RAM_D_E00C0A10 = 0xe00c0a10
export const RAM_flo_09_settings = 0x80241840
export const RAM_D_E00C8710 = 0xe00c8710
export const RAM_D_E00CCDD0 = 0xe00ccdd0
export const RAM_b_area_kmr_part_2_blue_goomba_sinTable = 0x8021a434
export const RAM_D_80243434_867F74 = 0x80243434
export const RAM_kpa_14_SomeItemEntityFunc = 0x80240498
export const RAM_D_8029D400 = 0x8029d400
export const RAM_b_area_kzn_kzn_04b_afterBattle_802288B8 = 0x802288b8
export const RAM_D_8024E4D8_C0BD58 = 0x8024e4d8
export const RAM_func_80240BB4_9085E4 = 0x80240bb4
export const RAM_b_area_mac_master3_nextTurn_80230D44 = 0x80230d44
export const RAM_nok_02_Quizmo_SetCamVfov = 0x8024247c
export const RAM_gPlayerMoveHistoryIndex = 0x8010cfb8
export const RAM_b_area_sam_duplighost_kooper_UnkActorPosFunc = 0x80218c40
export const RAM_b_area_pra3_duplighost_UnkActorPosFunc = 0x80218d64
export const RAM_kpa_03_MagikoopaAI_05 = 0x802412c8
export const RAM_nok_14_TackleAI_Main = 0x80240554
export const RAM_EB1170_LetterDelivery_Init = 0x8024077c
export const RAM_E20110_UnkAngleFunc1 = 0x802b7000
export const RAM_SetPartTargetFlagBits = 0x8026d158
export const RAM_D_E006EC0C = 0xe006ec0c
export const RAM_kill_script = 0x802c3f3c
export const RAM_D_80246398_915A48 = 0x80246398
export const RAM_HES_Item_Unused_0D6 = 0x80085418
export const RAM_create_generic_entity_frontUI = 0x8012323c
export const RAM_D_80077A38 = 0x80077a38
export const RAM_animator_make_mirrorX = 0x8011e058
export const RAM_flo_09_npcGroup_80243D6C = 0x80243d6c
export const RAM_D_8024F4C0 = 0x8024f4c0
export const RAM_func_80241A64_844634 = 0x80241a64
export const RAM_dup_jan_10_TransformFoliage = 0x80241570
export const RAM_dgb_15_settings = 0x802418a0
export const RAM_battle_move_hammer_charge_0_IsBerserkerEquipped = 0x802a1000
export const RAM_omo_02_ProjectileAI_Reflect = 0x802423e0
export const RAM_b_area_kmr_part_2_goomnut_tree_TransformFoliage = 0x80218b80
export const RAM_flo_10_80241E40 = 0x80241e40
export const RAM_kzn_maps = 0x800926b0
export const RAM_D_80248778_82BA38 = 0x80248778
export const RAM_gPauseItemsSelectedIndex = 0x802705c0
export const RAM_D_E00B4EE0 = 0xe00b4ee0
export const RAM_flo_21_init_8024371C = 0x8024371c
export const RAM_HES_MoveRedOrbDisabled = 0x80109450
export const RAM_flo_25_FlowerGuard_JudgeItemTastiness = 0x80241f44
export const RAM_ice_pillar_update = 0xe011e194
export const RAM_trd_00_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_hos_magikoopa_ShrinkActor = 0x80218150
export const RAM_D_801098A0 = 0x801098a0
export const RAM_battle_star_up_and_away_802A1E00 = 0x802a1e00
export const RAM_flo_07_802424F4 = 0x802424f4
export const RAM_GravityParamsStartJump = 0x800f7b50
export const RAM_b_area_pra2_crystal_bit_statusTable = 0x802204c0
export const RAM_kkj_23_UnkAtan2Func = 0x80240674
export const RAM_battle_partner_goombario_idle_80239350 = 0x80239350
export const RAM_b_area_kmr_part_3_final_jr_troopa_80230794 = 0x80230794
export const RAM_pra_35_MeleeHitbox_CanSeePlayer = 0x80241fb8
export const RAM_omo_05_GrooveGuyAI_Main = 0x80240780
export const RAM_IES_MegaHPDrain = 0x8008c5c4
export const RAM_pra_18_reflection_setup_floor = 0x80240500
export const RAM_light_rays_main = 0xe006a1e8
export const RAM_partner_buff_main = 0xe011a000
export const RAM_Entity_SuperBlockContent = 0x802ea934
export const RAM_func_8021812C_59E80C = 0x8021812c
export const RAM_tik_24_set_script_owner_npc_anim = 0x802406d0
export const RAM_evt_handle_get_3_word = 0x802c5d2c
export const RAM_sprite_checkers_pal = 0x80075880
export const RAM_au_BGMCmd_E0_MasterTempo = 0x8004fbbc
export const RAM_battle_star_lullaby_UnkStarFunc4 = 0x802a1218
export const RAM_osr_01_LetterDelivery_Init = 0x802407cc
export const RAM_func_80243294_9DA2B4 = 0x80243294
export const RAM_chomp_drop_main = 0xe009a000
export const RAM_b_area_jan_jungle_fuzzy_802210E0 = 0x802210e0
export const RAM_b_area_omo_sky_guy_takeTurn_8022CA9C = 0x8022ca9c
export const RAM_D_E01108B0 = 0xe01108b0
export const RAM_D_F5000007 = 0xf5000007
export const RAM_D_80098A24 = 0x80098a24
export const RAM_pra_18_reflection_render_wall = 0x802402f0
export const RAM_AddGoalPos = 0x8026a748
export const RAM_atan2 = 0x80029c80
export const RAM_b_area_trd_part_1_formation_0B = 0x802187bc
export const RAM_snd_ambient_manager_update = 0x800511bc
export const RAM_battle_item_strange_cake_PlayerGoHome = 0x802a1fe0
export const RAM_flo_17_pad_XXX = 0x802443d8
export const RAM_D_80243888_B7A038 = 0x80243888
export const RAM_au_song_stop = 0x800559c4
export const RAM_func_80240000_AAFFF0 = 0x80240000
export const RAM_D_80077A30 = 0x80077a30
export const RAM_kkj_03_UpdateTexturePanSmooth = 0x80240020
export const RAM_D_8014BF18 = 0x8014bf18
export const RAM_b_area_kmr_part_3_nok_01_afterBattle_80233620 = 0x80233620
export const RAM_kpa_11_UnkNpcAIFunc12 = 0x802404c0
export const RAM_iwa_10_SuperBlock_GetPartnerRank = 0x80240358
export const RAM_func_8013A854 = 0x8013a854
export const RAM_flo_25_pad_3CD8 = 0x80243cd8
export const RAM_b_area_mac_lee_idleAnimations_8021E94C = 0x8021e94c
export const RAM_func_80055EB4 = 0x80055eb4
export const RAM_mac_04_Quizmo_CreateReactionEffect = 0x80241458
export const RAM_set_animation_rate = 0x80263eb8
export const RAM_dro_01_8024C4C8 = 0x8024c4c8
export const RAM_flo_13_vectorList_802443A8 = 0x802443a8
export const RAM_SetPartScale = 0x8026cb4c
export const RAM_draw_box = 0x800f52e0
export const RAM_hos_03_Quizmo_ShowEntities = 0x802425d0
export const RAM_battle_partner_goombario_takeTurn_802383F4 = 0x802383f4
export const RAM_osYieldThread = 0x80066240
export const RAM_D_80153A55 = 0x80153a55
export const RAM_func_80240BD8_8ACB68 = 0x80240bd8
export const RAM_func_80241220_BD4A90 = 0x80241220
export const RAM_iwa_01_MontyMoleAI_PreBurrow = 0x80241c78
export const RAM_func_8003DA38 = 0x8003da38
export const RAM_b_area_omo_red_shy_guy_statusTable_80219224 = 0x80219224
export const RAM_D_80244338_BE7AE8 = 0x80244338
export const RAM_D_8024F47C = 0x8024f47c
export const RAM_D_09002480 = 0x09002480
export const RAM_MaxLoadedSpriteInstanceID = 0x802df530
export const RAM_func_802403A4_A78864 = 0x802403a4
export const RAM_func_8024046C_EE6A1C = 0x8024046c
export const RAM_b_area_sbk_pokey_idleAnimations_80218438 = 0x80218438
export const RAM_D_800A097C = 0x800a097c
export const RAM_func_8024033C_B2C7AC = 0x8024033c
export const RAM_D_802424E0_D95420 = 0x802424e0
export const RAM_func_80218B18_4EFFB8 = 0x80218b18
export const RAM_HES_TransparentLoop = 0x80106c5c
export const RAM_EED140_UnkFunc43 = 0x80240040
export const RAM_kzn_02_GetFloorCollider = 0x80240384
export const RAM_WasStatusInflicted = 0x8027dc68
export const RAM_D_8010D004 = 0x8010d004
export const RAM_func_8021A804_6A1A04 = 0x8021a804
export const RAM_light_rays_init = 0xe006a45c
export const RAM_arn_04_npcGroup_80243C20 = 0x80243c20
export const RAM_func_80240E40_A27D10 = 0x80240e40
export const RAM_flo_10_802423E4 = 0x802423e4
export const RAM_HES_MenuBoots3Disabled = 0x8010880c
export const RAM_D_8024EFCC = 0x8024efcc
export const RAM_HES_StatusDigit9 = 0x80108220
export const RAM_dup3_b_area_kpa2_GetJumpHammerCharge = 0x80219c74
export const RAM_ParasolTransformation = 0x802b6e80
export const RAM_func_802410E4_A2B324 = 0x802410e4
export const RAM_func_80240E80_8659C0 = 0x80240e80
export const RAM_D_80242FE8_AA1D28 = 0x80242fe8
export const RAM_b_area_omo3_UnkBattleFunc2 = 0x80218970
export const RAM_gPauseTabsInterpTable = 0x8024f300
export const RAM_spr_component_update = 0x802dd6dc
export const RAM_D_8024F558_C0CDD8 = 0x8024f558
export const RAM_func_802421E8_8339E8 = 0x802421e8
export const RAM_D_800936C0 = 0x800936c0
export const RAM_b_area_sam2_gray_magikoopa_idleAnimations3 = 0x8021f418
export const RAM_osAfterPreNMI = 0x8006a630
export const RAM_arn_13_main = 0x8024034c
export const RAM_flo_00_init_80243C18 = 0x80243c18
export const RAM_entity_init_Hammer1Block_normal = 0x802e3e00
export const RAM_b_area_trd_part_2_red_ninja_koopa_intTable_8022AD90 = 0x8022ad90
export const RAM_general_heap_malloc = 0x8002ac28
export const RAM_b_area_nok_nok_02 = 0x8022baec
export const RAM_b_area_kzn_medi_guy = 0x8021f9c0
export const RAM_state_drawUI_exit_file_select = 0x80035d20
export const RAM_tik_08_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_b_area_pra3_pra_04_802320D0 = 0x802320d0
export const RAM_D_80242930_C70C60 = 0x80242930
export const RAM_b_area_trd_part_2_fake_bowser_8021E3A0 = 0x8021e3a0
export const RAM_waterfall_main = 0xe00b6000
export const RAM_dgb_07_pad_1528 = 0x80241528
export const RAM_D_802440F7 = 0x802440f7
export const RAM_kmr_10_SetEntityPosition = 0x80240304
export const RAM_dgb_12_enterSingleDoor_80240464 = 0x80240464
export const RAM_whirlwind_appendGfx = 0xe00ce478
export const RAM_func_802AA0D8 = 0x802aa0d8
export const RAM_gPauseBadgesLevel = 0x80270398
export const RAM_D_8009A63C = 0x8009a63c
export const RAM_sam_01_UnkTrigFunc001 = 0x80241b20
export const RAM_HES_StatusStar4 = 0x80108450
export const RAM_arn_05_interact_80241924 = 0x80241924
export const RAM_EA8AE0_AddPlayerHandsOffset = 0x802415dc
export const RAM_sbk_25_TransformFoliage = 0x802401b0
export const RAM_EC9D00_SuperBlock_ClearOverride40 = 0x8024001c
export const RAM_mac_00_Quizmo_SetVannaAnim_Wave = 0x80241154
export const RAM_HES_Item_GearBoots3 = 0x80081338
export const RAM_jan_09_Pipe_SetAnimFlag = 0x80240000
export const RAM_ui_battle_status_shrink_1_pal = 0x80102c00
export const RAM_filemenu_draw_contents_option_center = 0x80244e54
export const RAM_battle_item_coconut_displayList = 0x802a1a60
export const RAM_HES_Item_DamageDodge = 0x800855f8
export const RAM_nok_02_LetterDelivery_CalcLetterPos = 0x80242de8
export const RAM_D_E00347C8 = 0xe00347c8
export const RAM_func_80241130_B6D4C0 = 0x80241130
export const RAM_HES_StoppedEnd = 0x801067b0
export const RAM_D_80246640_EA6F40 = 0x80246640
export const RAM_update_camera_mode_6 = 0x8002f990
export const RAM_func_80240628_B1BB98 = 0x80240628
export const RAM_func_80240000_7E73A0 = 0x80240000
export const RAM_initialize_status_menu = 0x800e7934
export const RAM_jan_08_SuperBlock_WhiteScreenFlash = 0x80241198
export const RAM_dgb_00_npcGroup_80242994 = 0x80242994
export const RAM_jan_12_PullVine_UpdatePosition = 0x802400f8
export const RAM_D_E0200704 = 0xe0200704
export const RAM_UseBattleCamPresetWait = 0x8024e6b4
export const RAM_HES_RightOn = 0x80292a2c
export const RAM_func_80240EF4_A17114 = 0x80240ef4
export const RAM_D_800A426C = 0x800a426c
export const RAM_func_802409C0_86F4C0 = 0x802409c0
export const RAM_battle_item_electro_pop_func_802A14F0_730A90 = 0x802a14f0
export const RAM_entity_SimpleSpring_enable_player_input = 0x802e6178
export const RAM_jan_22_PatrolAI_Chase = 0x8024094c
export const RAM_nok_01_Quizmo_UnkStageEffectMode = 0x802417d8
export const RAM_iwa_02_CleftAI_Tackle = 0x80240834
export const RAM_tik_07_SuperBlock_get_partner_rank = 0x802403b4
export const RAM_kzn_11_FireBarAI_Callback = 0x80242540
export const RAM_HES_RunAwayOK = 0x80109244
export const RAM_gCurrentScriptListPtr = 0x802da890
export const RAM_func_E00AE544 = 0xe00ae544
export const RAM_battle_partner_kooper_8023C754 = 0x8023c754
export const RAM_crash_screen_print_fpr = 0x8002c2b8
export const RAM_flo_10_80242030 = 0x80242030
export const RAM_D_09001918 = 0x09001918
export const RAM_func_802402A0_BC8100 = 0x802402a0
export const RAM_contRmbCheckMesg = 0x8005fd10
export const RAM_SetPlayerAnimation = 0x802d1084
export const RAM_arn_04_npcAISettings_80243660 = 0x80243660
export const RAM_MessageSingular = 0x8014c294
export const RAM_b_area_jan2_putrid_piranha_takeTurn_8021B8A8 = 0x80218ec8
export const RAM_evt_handle_call = 0x802c63e8
export const RAM_b_area_trd_part_1_bob_omb_handleEvent_8021ACE8 = 0x8021ace8
export const RAM_b_area_dgb_vector3D_8021B348 = 0x8021b348
export const RAM_action_command_air_lift_draw_hud_elements = 0x802a96ec
export const RAM_func_802402A0_D7F000 = 0x802402a0
export const RAM_HES_Item_Unused_08E = 0x80082418
export const RAM_battle_star_peach_beam_usePower = 0x802a35b4
export const RAM_D_802462C0_B4AA30 = 0x802462c0
export const RAM_InterpNpcYaw = 0x802ceb04
export const RAM_D_802BFF10 = 0x802bff10
export const RAM_D_09002FC8 = 0x09002fc8
export const RAM_dgb_18_802415B0 = 0x802415b0
export const RAM_pra_40_ReflectFloor = 0x8024049c
export const RAM_D_80073E08 = 0x80073e08
export const RAM_evt_handle_divide = 0x802c5934
export const RAM_kzn_19_StarSpiritEffectFunc2 = 0x80240358
export const RAM_dead_create_popup_menu = 0x800fcd0c
export const RAM_get_collider_center = 0x8005be10
export const RAM_flo_21_UpdateTexturePanStepped = 0x80240918
export const RAM_b_area_isk_part_1_formation_0B = 0x80224430
export const RAM_b_area_kmr_part_3_egg_jr_troopa_80224964 = 0x80224964
export const RAM_b_area_isk_part_2_tutankoopa_defenseTable_8021BA1C = 0x8021ba1c
export const RAM_ui_battle_status_frozen_1_pal = 0x801025c0
export const RAM_b_area_hos_goombario_tutor = 0x80219138
export const RAM_energy_shockwave_init = 0xe0072148
export const RAM_func_802401B0_926BA0 = 0x802401b0
export const RAM_entity_BlueWarpPipe_setupGfx = 0x802e8e4c
export const RAM_SetCamPosA = 0x802cbc58
export const RAM_func_802442E0_9DB300 = 0x802442e0
export const RAM_sfx_play_sound_with_params = 0x80149ab8
export const RAM_EC9D00_SuperBlock_StartGlowEffect = 0x80240480
export const RAM_b_area_kmr_part_3_mac_01_80234180 = 0x80234180
export const RAM_D_8024ED4C_7F9A5C = 0x8024ed4c
export const RAM_dgb_12_lavaResetList_802405C0 = 0x802405c0
export const RAM_dro_02_8024AC8C = 0x8024ac8c
export const RAM_hos_06_UpdateTexturePanStepped = 0x80240124
export const RAM_dgb_00_802408A0 = 0x802408a0
export const RAM_dgb_00_802408F8 = 0x802408f8
export const RAM_D_09000C00 = 0x09000c00
export const RAM_Merlow_ShopBadgesPopup = 0x80240ad0
export const RAM_D_80244EE0_CF7AB0 = 0x80244ee0
export const RAM_pra_35_reflection_render_wall = 0x802402f0
export const RAM_D_802AB4F0 = 0x802ab4f0
export const RAM_D_8029DA20 = 0x8029da20
export const RAM_D_8029D980 = 0x8029d980
export const RAM_D_8029D5A0 = 0x8029d5a0
export const RAM_omo_07_PatrolAI_ChaseInit = 0x80240d88
export const RAM_sbk_30_UnkFunc26 = 0x80240164
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuffScript2 = 0x8023372c
export const RAM_D_E00E6970 = 0xe00e6970
export const RAM_D_802AB180_42C670 = 0x802ab180
export const RAM_battle_item_sleepy_sheep3_png = 0x802a29f0
export const RAM_D_E02006A4 = 0xe02006a4
export const RAM_func_80240814_D87214 = 0x80240814
export const RAM_func_8026F1A0 = 0x8026f1a0
export const RAM_omo_17_FlyingAI_Loiter = 0x8024184c
export const RAM_SetBattleCamYaw = 0x8024eae4
export const RAM_kmr_02_Pipe_AwaitDownInput = 0x80240034
export const RAM_gStatsMenuEntries = 0x8024f3b0
export const RAM_D_802483EC_7F30FC = 0x802483ec
export const RAM_D_80098498 = 0x80098498
export const RAM_D_802447C0 = 0x802447c0
export const RAM_kpa_08_MagikoopaAI_06 = 0x802409c0
export const RAM_func_80218000_6DBDA0 = 0x80218000
export const RAM_D_09000600_3869B0 = 0x09000600
export const RAM_PartnerAfflictEnemy = 0x80280004
export const RAM_PartnerIsFlying = 0x802cfce8
export const RAM_IES_GoldCard = 0x8008bca4
export const RAM_isk_04_set_script_owner_npc_col_height = 0x80240354
export const RAM_func_80241274_D51264 = 0x80241274
export const RAM_b_area_arn_hyper_cleft_80222F1C = 0x80222f1c
export const RAM_b_area_kzn_lava_bubble_idleAnimations_80218FC4 = 0x80218fc4
export const RAM_tik_07_SuperBlock_GetPartnerRank = 0x80240348
export const RAM_b_area_kmr_part_3_kmr_02_afterBattle_802322F0 = 0x802322f0
export const RAM_b_area_iwa_buzzar_idleAnimations_8021B600 = 0x8021b600
export const RAM_D_802440DC_C89B8C = 0x802440dc
export const RAM_tik_05_CheckItemFlags40 = 0x802408e8
export const RAM_D_80109820 = 0x80109820
export const RAM_dgb_01_D_80252AD4_C10354 = 0x80252ad4
export const RAM_b_area_sbk_pokey_partsTable_80218338 = 0x80218338
export const RAM_D_8029D5A8 = 0x8029d5a8
export const RAM_func_80241630_BD0220 = 0x80241630
export const RAM_set_defeated = 0x8003ee40
export const RAM_D_80247690_D1FE70 = 0x80247690
export const RAM_D_09001910 = 0x09001910
export const RAM_func_802409E0_86F4E0 = 0x802409e0
export const RAM_D_80248570_9CD950 = 0x80248570
export const RAM_func_8024FB3C = 0x8024fb3c
export const RAM_b_area_dgb_dgb_04_afterBattle_8021B190 = 0x8021b190
export const RAM_D_09000240_32FD90 = 0x09000240
export const RAM_IES_SneakyParasol = 0x8008bb24
export const RAM_D_80073E00 = 0x80073e00
export const RAM_D_E010ACCC = 0xe010accc
export const RAM_b_area_omo_groove_guy_defenseTable_80224A2C = 0x80224a2c
export const RAM_world_parakarry_take_out = 0x802bea90
export const RAM_func_80241AF0_994220 = 0x80241af0
export const RAM_D_090020C0_3AD0F0 = 0x090020c0
export const RAM_nuGfxInitEX2 = 0x8005f560
export const RAM_battle_star_star_storm_UnkBackgroundFunc2 = 0x802a137c
export const RAM_b_area_sam_sam_02b_UnkModelStuffScript2 = 0x8022f86c
export const RAM_jan_04_SomeItemEntityFunc = 0x80240468
export const RAM_static_status_update = 0xe00e6184
export const RAM_flo_22_tree1_Trunk = 0x80241f04
export const RAM_D_090024E0_3AD510 = 0x090024e0
export const RAM_func_80241860_A3AD40 = 0x80241860
export const RAM_D_8014C2D8 = 0x8014c2d8
export const RAM_breaking_junk_main = 0xe0118000
export const RAM_kkj_00_PatrolAI_JumpInit = 0x802407e0
export const RAM_world_lakilester_update = 0x802bfe80
export const RAM_func_802BD660_319BD0 = 0x802bd660
export const RAM_D_802466C0_EA6FC0 = 0x802466c0
export const RAM_func_80240194_842D64 = 0x80240194
export const RAM_EC9D00_SuperBlock_PartnerIDs = 0x80241fe8
export const RAM_confetti_update = 0xe00885a8
export const RAM_update_npc_blur = 0x8003adf8
export const RAM_set_cam_viewport = 0x8002e314
export const RAM_func_80242C78_854DE8 = 0x80242c78
export const RAM_battle_item_coconut_GiveRefundCleanup = 0x802a11d4
export const RAM_battle_item_dizzy_dial_EatItem = 0x802a189c
export const RAM_b_area_sbk_formation_06 = 0x8021fae4
export const RAM_HES_Item_FriedShroom_disabled = 0x80082a48
export const RAM_pra_14_reflection_setup_wall = 0x80240128
export const RAM_D_8023CDA0 = 0x8023cda0
export const RAM_D_E002A730 = 0xe002a730
export const RAM_D_E0060730 = 0xe0060730
export const RAM_func_800E98C4 = 0x800e98c4
export const RAM_func_802A9000_429320 = 0x802a9000
export const RAM_sbk_56_SuperBlock_StartGlowEffect = 0x80240500
export const RAM_D_802BE314 = 0x802be314
export const RAM_func_80240654_EB14B4 = 0x80240654
export const RAM_MakeLocalVertexCopy = 0x802c9c70
export const RAM_vtx_C21F30 = 0x80200ff0
export const RAM_OpenCloseSounds = 0x8014f6b4
export const RAM_ui_battle_cmd_timing_3_chances_pal = 0x8028d420
export const RAM_b_area_kmr_part_3_formation_01 = 0x8021a1cc
export const RAM_nok_15_FlyingAI_Wander = 0x80240c64
export const RAM_HES_Item_SpecialShake_disabled = 0x80082da8
export const RAM_kzn_06_UpdateTexturePanStepped = 0x80240124
export const RAM_func_802403F8_ADB298 = 0x802403f8
export const RAM_sam_06_FlyingAI_JumpVels = 0x802441b8
export const RAM_D_802284A0_6CC030 = 0x802284a0
export const RAM_D_80241370_C6DC90 = 0x80241370
export const RAM_dro_02_npcSettings_80247500 = 0x80247500
export const RAM_npc_draw_palswap_mode_2 = 0x8003ba60
export const RAM_flo_13_npcGroup_80247794 = 0x80247794
export const RAM_kpa_01_UnkDistFunc = 0x802401dc
export const RAM_trd_03_TackleAI_Main = 0x80240434
export const RAM_dgb_09_FlyingAI_ChaseInit = 0x802420e4
export const RAM_sam_07_PiranhaPlantAI_14 = 0x80241cb8
export const RAM_func_80057E5C = 0x80057e5c
export const RAM_HES_MashStartButton = 0x80108cc8
export const RAM_b_area_isk_part_1_isk_08_D_80224DB0 = 0x80224db0
export const RAM_mim_06_MeleeHitbox_33 = 0x80240724
export const RAM_D_802A27D0 = 0x802a27d0
export const RAM_func_8024061C_90804C = 0x8024061c
export const RAM_tik_10_SuperBlock_GetPartnerRank = 0x802406e8
export const RAM_func_802403B8_8AC348 = 0x802403b8
export const RAM_jan_06_MeleeHitbox_32 = 0x802418f8
export const RAM_b_area_isk_part_2_chain_chomp_idleAnimations_80218BC8 = 0x80218bc8
export const RAM_b_area_isk_part_1_isk_06_D_80224D80 = 0x80224d80
export const RAM_UpdateColliderTransform = 0x802ca188
export const RAM_partner_is_idle = 0x800ea514
export const RAM_flo_22_pad_518 = 0x80240518
export const RAM_mac_06_unkAngleFunc002 = 0x802408d0
export const RAM_func_802A96B8 = 0x802a96b8
export const RAM_b_area_kmr_part_3_mage_jr_troopa_idleAnimations_8022A550 = 0x8022a550
export const RAM_func_802403F0_BA4B70 = 0x802403f0
export const RAM_D_80258404_818C84 = 0x80258404
export const RAM_D_802B6720_E27500 = 0x802b6720
export const RAM_SpriteDataHeader = 0x802e0c60
export const RAM_gMap = 0x800a41e0
export const RAM_cloud_puff_appendGfx = 0xe00144e0
export const RAM_vtx_C2E360 = 0x8020d420
export const RAM_pause_spirits_bg_pal = 0x8026be30
export const RAM_kzn_09_func_802408B4_97BEE4 = 0x80243238
export const RAM_end_01_CharAnim_FadeIn_0 = 0x80240030
export const RAM_ui_move_partner_3_disabled_pal = 0x80104180
export const RAM_get_player_normal_pitch = 0x80029e50
export const RAM_D_802BFDB8_320B28 = 0x802bfdb8
export const RAM_func_8011B1D8 = 0x8011b1d8
export const RAM_bgm_set_track_volumes = 0x8014ab0c
export const RAM_stars_spread_render = 0xe005a3b0
export const RAM_b_area_kmr_part_2_goomba_king_idleAnimations_802208CC = 0x802208cc
export const RAM_func_80240770_A3FEB0 = 0x80240770
export const RAM_D_80241FC0_ABB270 = 0x80241fc0
export const RAM_kzn_09_SuperBlock_WaitForPlayerToLand = 0x80240d08
export const RAM_arn_05_init_80241FB8 = 0x80241fb8
export const RAM_fold_vtxBuf = 0x80156950
export const RAM_b_area_kmr_part_1_goomba_CalculateRotationZ = 0x8021818c
export const RAM_b_area_jan_spike_top_idleAnimations_8021CA1C = 0x80223c8c
export const RAM_blast_render = 0xe007c1b0
export const RAM_hos_03_Quizmo_Worker = 0x8024bdd0
export const RAM_arn_03_init_80243E90 = 0x80243e90
export const RAM_kzn_04_SuperBlock_GetPartnerRank = 0x80240378
export const RAM_UseAdvancedDoorSounds = 0x802d6340
export const RAM_kpa_51_MagikoopaAI_OnPlayerFled = 0x80241374
export const RAM_b_area_pra3_duplighost_idleAnimations2 = 0x80219f5c
export const RAM_update_lerp_battle = 0x8027b0dc
export const RAM_kzn_20_GetFloorCollider = 0x80240a8c
export const RAM_b_area_mac_lee_unk_missing_8021D3B8 = 0x8021d3b8
export const RAM_b_area_kmr_part_2_stageTable = 0x8021987c
export const RAM_HES_HandPointLeftLoop = 0x80080208
export const RAM_flo_21_80240DA0 = 0x80240da0
export const RAM_omo_07_ShyGuyPatrolAI_15 = 0x8024146c
export const RAM_battle_partner_goombario_8023859C = 0x8023859c
export const RAM_b_area_omo_sky_guy_UnkBattleFunc1 = 0x80218e08
export const RAM_D_802449F0_C35ED0 = 0x802449f0
export const RAM_D_80242F90_A67020 = 0x80242f90
export const RAM_func_80219818_6A0A18 = 0x80219818
export const RAM_dro_02_D_8024EFCC = 0x8024efcc
export const RAM_energy_orb_wave_update = 0xe00a41ac
export const RAM_pra_02_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_ui_battle_cont_c_down_up_pal = 0x8028c6f8
export const RAM_DoSpinSmashHit = 0x8029b998
export const RAM_dup_mac_01_PatrolAI_MoveInit = 0x80241080
export const RAM_D_80099BB8 = 0x80099bb8
export const RAM_dro_02_802482F8 = 0x802482f8
export const RAM_func_80240C34_A114B4 = 0x80240c34
export const RAM_b_area_iwa_monty_mole_partsTable_8021A60C = 0x8021a60c
export const RAM_isk_04_set_script_owner_npc_anim = 0x80240040
export const RAM_b_area_jan2_putrid_piranha_idle_8021B244 = 0x80218864
export const RAM_func_802D2B6C = 0x802d2b6c
export const RAM_HES_PartnerRank2A = 0x801092e8
export const RAM_GetGoalPos = 0x8026a820
export const RAM_D_802DF490 = 0x802df490
export const RAM_func_8024207C_9C745C = 0x8024207c
export const RAM_HES_Item_Unused_0F5_disabled = 0x80085fe8
export const RAM_kzn_02_GetNpcCollisionHeight = 0x802420e4
export const RAM_b_area_mac_lee_takeTurn_80226004 = 0x80226004
export const RAM_D_E011C514 = 0xe011c514
export const RAM_SetNpcPos = 0x802cdcb0
export const RAM_state_drawUI_demo = 0x8003845c
export const RAM_b_area_kmr_part_3_kmr_04_foregroundModelList_80232B90 = 0x80232b90
export const RAM_func_8024005C_95401C = 0x8024005c
export const RAM_mac_01_Quizmo_AudienceEffect = 0x80262a64
export const RAM_ui_status_star_point_6_png = 0x80101500
export const RAM_quizmo_audience_main = 0xe00a8000
export const RAM_D_80077420 = 0x80077420
export const RAM_b_area_omo_red_shy_guy_80219A04 = 0x80219a04
export const RAM_b_area_trd_part_2_fake_bowser_8021CF4C = 0x8021cf4c
export const RAM_omo_09_ShyGuyWanderAI_Main = 0x80240c18
export const RAM_mac_03_Pipe_AwaitDownInput = 0x80240034
export const RAM_b_area_kmr_part_1_paragoomba_idle_8021B434 = 0x8021ce24
export const RAM_spirit_card_init = 0xe01121b8
export const RAM_dgb_09_npcAI_80244284 = 0x80244284
export const RAM_dgb_09_ClubbaNappingAI_WakeUp = 0x80240c10
export const RAM_tik_10_SuperBlock_WaitForPlayerToLand = 0x80241388
export const RAM_jan_03_ToadHouse_Stub = 0x802417a4
export const RAM_HES_Refund = 0x80108a64
export const RAM_battle_star_chill_out_usePower = 0x802a27a8
export const RAM_D_80244050_92E300 = 0x80244050
export const RAM_func_8003DC38 = 0x8003dc38
export const RAM_HES_Item_KoopaLeaf = 0x80086b58
export const RAM_omo_06_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_nok_02_Pipe_AwaitDownInput = 0x80240034
export const RAM_mdl_get_copied_gfx = 0x8011c2ec
export const RAM_dro_01_npcSettings_8024518C = 0x8024518c
export const RAM_func_80243EC8_9DAEE8 = 0x80243ec8
export const RAM_mac_01_Quizmo_ShouldAppear = 0x8024204c
export const RAM_func_80057BB4 = 0x80057bb4
export const RAM_flo_10_tree1_Leaves = 0x802449f4
export const RAM_nok_02_Quizmo_CreateWorker = 0x80242974
export const RAM_dro_01_Pipe_ExitVertical = 0x80244374
export const RAM_func_8013E904 = 0x8013e904
export const RAM_iwa_10_GetNpcCollisionHeight = 0x802411b4
export const RAM_func_80143C48 = 0x80143c48
export const RAM_b_area_jan_medi_guy_healOneAlly = 0x802236b8
export const RAM_mac_00_LetterDelivery_RestoreNpcAnim = 0x80241d74
export const RAM_b_area_omo2_2_stilt_guy_init = 0x80231440
export const RAM_b_area_isk_part_1_swooper_8021D7E4 = 0x8021d7e4
export const RAM_dro_02_Quizmo_StageEffect = 0x8024efc0
export const RAM_dup2_kmr_20_Pipe_AwaitDownInput = 0x80241d84
export const RAM_D_80242C30_D3A430 = 0x80242c30
export const RAM_sbk_02_AddPlayerHandsOffset = 0x8024074c
export const RAM_D_802D9EEC = 0x802d9eec
export const RAM_func_80240628_800EA8 = 0x80240628
export const RAM_hos_03_ToadHouse_ShowStatusMenu = 0x802421c8
export const RAM_pra_18_reflection_render_floor = 0x8024068c
export const RAM_b_area_kmr_part_3_kmr_05_beforeBattle_80232F58 = 0x80232f58
export const RAM_b_area_isk_part_2_chain_chomp_ChompChainUpdateHelperFunc2 = 0x802181e8
export const RAM_IES_KootAutographMerluvlee = 0x8008bd24
export const RAM_b_area_jan2_jan_01b_8021EAE0 = 0x8021eae0
export const RAM_b_area_isk_part_1_isk_02b_foregroundModelList_80223E54 = 0x80223e54
export const RAM_func_80240800_B07200 = 0x80240800
export const RAM_D_802A3044_007365E4 = 0x802a3044
export const RAM_gPausePartnersSpriteAnims = 0x8024f630
export const RAM_D_8024153B_B9EE9B = 0x8024153b
export const RAM_func_80144E74 = 0x80144e74
export const RAM_b_area_kzn_white_magikoopa_defenseTable_80223B2C = 0x80223b2c
export const RAM_b_area_trd_part_2_blue_ninja_koopa_UnkEnemyFunc = 0x80219490
export const RAM_tik_07_SuperBlock_AnimateEnergyOrbs = 0x802408e0
export const RAM_D_09000A00 = 0x09000a00
export const RAM_pra_11_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_802AB800 = 0x802ab800
export const RAM_b_area_jan_spear_guy_statusTable_80218698 = 0x80218698
export const RAM_entity_SaveBlock_init = 0x802e13b8
export const RAM_D_09000E58 = 0x09000e58
export const RAM_flo_25_interact_80242C58 = 0x80242c58
export const RAM_flo_16_FlyingNoAttackAI_12 = 0x80242348
export const RAM_D_80244630_A06E70 = 0x80244630
export const RAM_ui_coin_sparkle_4_png = 0x800fbd80
export const RAM_mac_00_PatrolAI_Loiter = 0x80242a30
export const RAM_battle_item_hustle_drink_DrinkItem = 0x802a1824
export const RAM_D_8014F4F4 = 0x8014f4f4
export const RAM_flo_19_80240BF8 = 0x80240bf8
export const RAM_ui_battle_cmd_aim_reticle_1_png = 0x8028f1a0
export const RAM_func_80266E14 = 0x80266e14
export const RAM_func_80240000_EDE9C0 = 0x80240000
export const RAM_D_802473F0_C68100 = 0x802473f0
export const RAM_dgb_15_init_802427EC = 0x802427ec
export const RAM_flo_08_SuperBlock_SwitchToPartner = 0x8024041c
export const RAM_D_09001E90_3520B0 = 0x09001e90
export const RAM_firework_init = 0xe0086270
export const RAM_func_802CB710 = 0x802cb710
export const RAM_HES_Item_Unused_0DF = 0x80085778
export const RAM_IES_ITEM_13E = 0x8008b3a4
export const RAM_sbk_01_exitWalk_80240198 = 0x80240198
export const RAM_dgb_00_npcGroup_80241FE4 = 0x80241fe4
export const RAM_battle_move_multibounce_D_802A1408 = 0x802a1408
export const RAM_b_area_omo_red_shy_guy_takeTurn_8021ACB4 = 0x8021acb4
export const RAM_iwa_01_CleftAI_RevUpInit = 0x80242908
export const RAM_b_area_mac_lee_parakarry = 0x802234d4
export const RAM_b_area_sam_duplighost_bombette_playEffects = 0x802204dc
export const RAM_kkj_14_GetPeachDisguise = 0x802403a0
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021CAFC = 0x8021e4ec
export const RAM_guRotate = 0x80067c80
export const RAM_reset_partner_blur = 0x8025468c
export const RAM_dgb_09_80243F6C = 0x80243f6c
export const RAM_IES_BoiledEgg = 0x8008d4d4
export const RAM_b_area_trd_part_1_bullet_bill = 0x8021903c
export const RAM_hud_element_get_render_pos = 0x801449ac
export const RAM_shim_sin_deg = 0xe0200500
export const RAM_D_800980F4 = 0x800980f4
export const RAM_D_E0034791 = 0xe0034791
export const RAM_b_area_tik2_UnfadeBackgroundToBlack = 0x80218384
export const RAM_b_area_omo_formation_16 = 0x8022ffc0
export const RAM_pause_badges_try_equip = 0x80247338
export const RAM_dispatch_damage_event_actor = 0x802786d4
export const RAM_b_area_omo_blue_shy_guy_defenseTable_8021AD10 = 0x8021ad10
export const RAM_gPauseItemsTargetScrollIndex = 0x802705dc
export const RAM_D_80245360_CD0670 = 0x80245360
export const RAM_b_area_omo_anti_guy_partsTable_802219C8 = 0x802219c8
export const RAM_b_area_kzn_white_magikoopa_GetBootsHammerLevel = 0x80218d0c
export const RAM_D_8014B0BC = 0x8014b0bc
export const RAM_battle_star_refresh_UnkStarFunc1 = 0x802a1000
export const RAM_snd_set_song_variation_fade_time = 0x80055abc
export const RAM_D_8014BB60 = 0x8014bb60
export const RAM_func_802BBD1C_E2E64C = 0x802bbd1c
export const RAM_HES_Item_KootRedJar = 0x80081cc8
export const RAM_osFlashAllErase = 0x8006f308
export const RAM_dro_01_foliage_setup_shear_mtx = 0x80243880
export const RAM_fx_windy_leaves = 0x800708b0
export const RAM_D_802434A8_A63E78 = 0x802434a8
export const RAM_battle_move_jump_MoveToJump = 0x802a11fc
export const RAM_D_80242134_A02734 = 0x80242134
export const RAM_D_800DA03C = 0x800da03c
export const RAM_func_802197E0_5B2C90 = 0x802197e0
export const RAM_D_80075EA0 = 0x80075ea0
export const RAM_HES_MashCLeftButton = 0x80292490
export const RAM_omo_07_UnsetCamera0MoveFlag1 = 0x802431dc
export const RAM_vtx_C23B70 = 0x80202c30
export const RAM_b_area_jan_formation_05 = 0x80228708
export const RAM_D_802484E0_E1E260 = 0x802484e0
export const RAM_D_09001A20 = 0x09001a20
export const RAM_kzn_09_LetterDelivery_Init = 0x80243718
export const RAM_dgb_03_itemList_80242F28 = 0x80242f28
export const RAM_func_800E5520 = 0x800e5520
export const RAM_D_8009A624 = 0x8009a624
export const RAM_b_area_trd_part_1_trd_05b = 0x80225c38
export const RAM_b_area_mac_lee_80220100 = 0x80220100
export const RAM_HES_Item_BakingStrawberry = 0x80087338
export const RAM_vtx_C211E0 = 0x802002a0
export const RAM_kzn_07_FlyingAI_LoiterInit = 0x80240ff8
export const RAM_sin_cos_deg = 0x8002a234
export const RAM_D_8028358C = 0x8028358c
export const RAM_b_area_trd_part_1_bullet_bill_handleEvent_802193E8 = 0x802193e8
export const RAM_sam_10_CheckItemFlags40 = 0x80240268
export const RAM_func_80240138_D53DE8 = 0x80240138
export const RAM_func_80238114_707DB4 = 0x80238114
export const RAM_b_area_kmr_part_3_paragoomba2_CalculateRotationZ = 0x80218744
export const RAM_omo_17_ProjectileHitbox_33 = 0x802429c4
export const RAM_bombette_breaking_render = 0xe00848e4
export const RAM_D_802431F8_C55738 = 0x802431f8
export const RAM_dgb_08_FlyingAI_Loiter = 0x80241ff8
export const RAM_nuSiMesgBuf = 0x8009e6f0
export const RAM_EB1170_LetterDelivery_SavedNpcAnim = 0x80244494
export const RAM_dro_01_PatrolAI_MoveInit = 0x802405e0
export const RAM_D_8015336E = 0x8015336e
export const RAM_ui_battle_cmd_charge_2_pal = 0x8028cf00
export const RAM_D_802499A8_A33BE8 = 0x802499a8
export const RAM_ui_input_start_button_pal = 0x800fa3a0
export const RAM_D_80243070_9B1770 = 0x80243070
export const RAM_D_80244620_A06E60 = 0x80244620
export const RAM_dgb_01_D_80251FD4_C0F854 = 0x80251fd4
export const RAM_D_802458A8_C85A48 = 0x802458a8
export const RAM_func_80243088_826348 = 0x80243088
export const RAM_dgb_11_8024062C = 0x8024062c
export const RAM_battle_item_tasty_tonic_UseItem = 0x802a15e4
export const RAM_entity_HiddenPanel_flip_over = 0x802e6574
export const RAM_func_8024088C_9082BC = 0x8024088c
export const RAM_kpa_12_func_80240350_A54CA0 = 0x80240350
export const RAM_b_area_omo2_toy_tank_8021A4B0 = 0x8021a4b0
export const RAM_HES_HPBar = 0x80291f80
export const RAM_InterpCamTargetPos = 0x802cb008
export const RAM_D_80247A90_B52500 = 0x80247a90
export const RAM_pra_37_reflection_unk_change_anim_facing = 0x80240084
export const RAM_dgb_08_pad_XXXX = 0x802469f8
export const RAM_versionString = 0x80077bd0
export const RAM_D_09001C00_351E20 = 0x09001c00
export const RAM_battle_menu_leftJustfiedMessagesA = 0x802ab490
export const RAM_b_area_kmr_part_3_para_jr_troopa_802279B0 = 0x802279b0
export const RAM_b_area_pra2_crystal_bit_defenseTable = 0x802204a4
export const RAM_dup_jan_09_TransformFoliage = 0x80241750
export const RAM_func_8021837C_4B156C = 0x8021837c
export const RAM_state_step_world = 0x80035d54
export const RAM_evt_handle_resume_others = 0x802c69e4
export const RAM_b_area_sbk_formationTable = 0x8021fe80
export const RAM_mdl_renderTaskBasePriorities = 0x8014c188
export const RAM_nok_01_ToadHouse_InitScreenOverlay = 0x80240c80
export const RAM_mim_05_PiranhaPlantAI_13 = 0x80241678
export const RAM_D_E00628C4 = 0xe00628c4
export const RAM_filemenu_set_cursor_goal_pos = 0x80242e00
export const RAM_b_area_kmr_part_3_paragoomba1_8021F4B0 = 0x8021f4b0
export const RAM_au_bgm_get_random_reverb = 0x8005068c
export const RAM_battle_item_strange_cake3_png = 0x802a25b0
export const RAM_flo_16_80243D48 = 0x80243d48
export const RAM_dgb_08_dgb_01_name_hack = 0x80246e28
export const RAM_HES_NotEnoughFP = 0x801076bc
export const RAM_kpa_102_FlyingMagikoopaAI_23 = 0x80242f74
export const RAM_tik_07_FlyingAI_JumpVels = 0x80244cb0
export const RAM_b_area_nok_koopa_troopa = 0x8021ffe8
export const RAM_PlayDrinkFX = 0x80287948
export const RAM_RemoveEncounter = 0x80044478
export const RAM_HES_ListNextPage = 0x802426cc
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_80225790 = 0x80225790
export const RAM_omo_09_ProjectileHitbox_33 = 0x80242870
export const RAM_jan_06_HoppingAI_HopInit = 0x80240740
export const RAM_btl_state_draw_switch_to_partner = 0x80242fd8
export const RAM_HES_RedBar1 = 0x801078e0
export const RAM_HES_TimingCharge4c = 0x802928a0
export const RAM_D_80197000 = 0x80197000
export const RAM_guScale = 0x80067700
export const RAM_func_8003E0D4 = 0x8003e0d4
export const RAM_battle_item_fire_flower_func_802A123C_716E9C = 0x802a123c
export const RAM_D_800A0968 = 0x800a0968
export const RAM_D_80246D38_C46AE8 = 0x80246d38
export const RAM_flo_00_init_80246224 = 0x80246224
export const RAM_b_area_omo_sky_guy_idleAnimations_802294C0 = 0x802294c0
export const RAM_flo_18_vectorList_80243FE0 = 0x80243fe0
export const RAM_au_BGMCmd_E1_MasterVolume = 0x8004fcb4
export const RAM_D_8024BE50_D2BCE0 = 0x8024be50
export const RAM_b_area_omo2_5_shy_squad_dup_init = 0x80231348
export const RAM_kzn_17_UnkFunc49 = 0x80242ebc
export const RAM_sam_07_SetEntityPositionF = 0x80240448
export const RAM_mac_00_PatrolAI_PostLoiter = 0x80242bec
export const RAM_b_area_omo_formation_06 = 0x8022fa9c
export const RAM_flo_10_tree1_Drops = 0x80244a0c
export const RAM_arn_04_npcAI_802437F0 = 0x802437f0
export const RAM_dup_kkj_11_UnkGooberFunc = 0x80240f60
export const RAM_breaking_junk_render = 0xe0118454
export const RAM_b_area_sam2_paragoomba_handleEvent_8021B768 = 0x8021c4e8
export const RAM_flo_15_exitWalk_80240120 = 0x80240120
export const RAM_D_8024EEC8_C0C748 = 0x8024eec8
export const RAM_b_area_sam2_sam_01_UnkModelStuff_func1 = 0x80218b80
export const RAM_mac_04_Quizmo_ShowEntities = 0x8024058c
export const RAM_battle_star_star_storm_802A16F0 = 0x802a16f0
export const RAM_dgb_09_SentinelAI_LosePlayerInit = 0x80242eb8
export const RAM_D_80257D40_8C7DB0 = 0x80257d40
export const RAM_b_area_isk_part_2_isk_00_beforeBattle_8021FCF0 = 0x8021fcf0
export const RAM_dgb_01_PatrolAI_LoiterInit = 0x802408f8
export const RAM_add_actor_decoration = 0x80267190
export const RAM_func_802427D8_DF91D8 = 0x802427d8
export const RAM_dgb_18_settings = 0x80241280
export const RAM_D_80245458_EF2558 = 0x80245458
export const RAM_flo_18_init_80245C78 = 0x80245c78
export const RAM_func_802422A0_DB4560 = 0x802422a0
export const RAM_D_80231358_63CEF8 = 0x80231358
export const RAM_D_80298E20 = 0x80298e20
export const RAM_dro_01_EVS_Quizmo_PlayerThinking = 0x80245ab8
export const RAM_b_area_kzn_formation_05 = 0x80229950
export const RAM_b_area_trd_part_2_trd_05b_802334F0 = 0x802334f0
export const RAM_func_802A4534 = 0x802a4534
export const RAM_gPauseTutorialDescMessages = 0x8024f03c
export const RAM_end_00_CreditsProjMatrices = 0x80250ef0
export const RAM_D_802470B0_DC4360 = 0x802470b0
export const RAM_kkj_16_UnkAtan2Func = 0x802405f4
export const RAM_arn_07_npcGroupList_802478B8 = 0x802478b8
export const RAM_b_area_omo_spy_guy_hammer_text = 0x80228754
export const RAM_func_802BC558_E30EA8 = 0x802bc558
export const RAM_load_path_data = 0x802d4dac
export const RAM_D_802437B0_BAD8F0 = 0x802437b0
export const RAM_btl_state_update_player_menu = 0x802a5988
export const RAM_trd_01_GetEntityPosition = 0x80240344
export const RAM_make_npcs = 0x8003e678
export const RAM_arn_maps = 0x800922f0
export const RAM_dgb_01_SentinelAI_DescendInit = 0x80241874
export const RAM_func_80241D20_D1A500 = 0x80241d20
export const RAM_mac_02_Quizmo_ShouldAppear = 0x802418fc
export const RAM_jan_03_GetNpcCollisionHeight = 0x802417ac
export const RAM_npc_find_closest_simple = 0x8003d0c4
export const RAM_ui_status_star_point_1_pal = 0x80101260
export const RAM_kmr_03_8024097C = 0x8024097c
export const RAM_D_802E9924 = 0x802e9924
export const RAM_b_area_omo_omo_04_specialFormation_8022DE64 = 0x8022de64
export const RAM_fx_throw_spiny = 0x80071e10
export const RAM_func_802A92D4 = 0x802a92d4
export const RAM_stars_shimmer_appendGfx = 0xe004491c
export const RAM_b_area_trd_part_1_bullet_bill_statusTable_80218F6C = 0x80218f6c
export const RAM_b_area_arn_hyper_cleft_statusTable_80220B68 = 0x80220b68
export const RAM_D_80243340_9A4320 = 0x80243340
export const RAM_flo_17_pad_3FCC = 0x80243fcc
export const RAM_D_090032C0 = 0x090032c0
export const RAM_spr_get_npc_raster_info = 0x802de9d8
export const RAM_tattle_window_main = 0xe00d8000
export const RAM_jan_03_UpdateTexturePanSmooth = 0x802402e0
export const RAM_func_802BB78C_E2E0BC = 0x802bb78c
export const RAM_gCurrentDynamicEntityListPtr = 0x80154420
export const RAM_D_802AD4A7 = 0x802ad4a7
export const RAM_HES_WaterBlockCircle = 0x802ab130
export const RAM_flo_10_802414E0 = 0x802414e0
export const RAM_battle_partner_goombario_80239784 = 0x80239784
export const RAM_b_area_mac_mac_02_beforeBattle_80232930 = 0x80232930
export const RAM_func_80218644_6DE404 = 0x80218644
export const RAM_D_80249CA4 = 0x80249ca4
export const RAM_evt_handle_get_3_float = 0x802c5f9c
export const RAM_jan_02_Quizmo_StashVars = 0x80241614
export const RAM_func_80242EA8_DC0158 = 0x80242ea8
export const RAM_jan_03_Quizmo_SetVannaAnim_Clap = 0x802426a4
export const RAM_game_mode_set_fpDrawAuxUI = 0x80112d84
export const RAM_battle_move_d_down_pound_IsRightOnEquipped = 0x802a1050
export const RAM_b_area_isk_part_2_chain_chomp_handleEvent_802197C4 = 0x802197c4
export const RAM_filemenu_8024C100 = 0x8024c100
export const RAM_tik_19_UpdateTexturePanSmooth = 0x802402e0
export const RAM_b_area_omo2_1_shy_squad_displace_guy_2 = 0x80233568
export const RAM_D_802EB0E8 = 0x802eb0e8
export const RAM_D_80242058_941608 = 0x80242058
export const RAM_get_hud_element = 0x801448d4
export const RAM_D_090002C0_392700 = 0x090002c0
export const RAM_nuGfxPreNMIFunc = 0x80093d14
export const RAM_action_command_jump_CreateHudElements = 0x802a9000
export const RAM_sam_09_GetItemName = 0x802400b4
export const RAM_end_01_CreditsData = 0x80249440
export const RAM_func_802427A4_DF91A4 = 0x802427a4
export const RAM_D_8024D608_C0AE88 = 0x8024d608
export const RAM_HES_AimShimmerD = 0x802933a4
export const RAM_b_area_omo_groove_guy_vector3D_8022739C = 0x8022739c
export const RAM_battle_star_smooch_UnkStarFunc4 = 0x802a1218
export const RAM_end_01_init_credits = 0x80242510
export const RAM_battle_item_unknown_item_DrinkItem = 0x802a1884
export const RAM_kzn_19_AddPlayerHandsOffset = 0x802415dc
export const RAM_TeleportPartnerToPlayer = 0x8028387c
export const RAM_func_80242840_E07BA0 = 0x80242840
export const RAM_b_area_sam2_sam_02c_afterBattle = 0x80222cb0
export const RAM_IES_PowerStar = 0x8008d714
export const RAM_HES_Item_CakePan = 0x80080580
export const RAM_jan_02_UpdateTexturePanStepped = 0x80240154
export const RAM_flo_23_npcAISettings_80241498 = 0x80241498
export const RAM_omo_02_ProjectileHitbox_32 = 0x80241dc0
export const RAM_dgb_04_SuperBlock_WaitForPlayerToLand = 0x802430d8
export const RAM_dro_02_EVS_Quizmo_OtherCamScript = 0x80245120
export const RAM_D_09000220_326630 = 0x09000220
export const RAM_dgb_01_80244CE8 = 0x80244ce8
export const RAM_omo_09_ProjectileAI_Main = 0x802428b4
export const RAM_b_area_kzn_lava_bubble_8021A218 = 0x8021a218
export const RAM_gSaveSlotMetadata = 0x800779c4
export const RAM_EA0C10_LetterDelivery_SavedNpcAnim = 0x80246894
export const RAM_battle_item_insecticide_herb_GiveRefundCleanup = 0x802a11d4
export const RAM_HES_Item_ShroomSteak = 0x80082ad8
export const RAM_evt_handle_if_equal = 0x802c4c40
export const RAM_GetEncounterState = 0x8027d7c4
export const RAM_trd_04_TackleAI_Main = 0x80240844
export const RAM_gHudElementCacheCapacity = 0x8014efc4
export const RAM_HES_Item_SnowmanScarf = 0x80081c08
export const RAM_b_area_trd_part_1_trd_05 = 0x80224b40
export const RAM_b_area_dgb_tubba_blubba_8021A36C = 0x8021a36c
export const RAM_D_802417E4_C97714 = 0x802417e4
export const RAM_ui_battle_cmd_timing_ready_pal = 0x8028cde0
export const RAM_arn_04_FlyingAI_Wander = 0x80241040
export const RAM_jan_06_HoppingAI_Chase = 0x80240fec
export const RAM_mac_00_Quizmo_NPC_OnRender = 0x802409fc
export const RAM_func_8021849C_6DC23C = 0x8021849c
export const RAM_omo_13_GrooveGuyAI_02 = 0x802407ec
export const RAM_EE70C0_FlyingAI_Wander = 0x80240cc0
export const RAM_GetActorScale = 0x8026ca84
export const RAM_dro_01_dropDoor_8024BBB8 = 0x8024bbb8
export const RAM_D_09000440_326850 = 0x09000440
export const RAM_dgb_01_D_802529A0_C10220 = 0x802529a0
export const RAM_func_80240530_AF7E80 = 0x80240530
export const RAM_entity_HeartBlockContent_setupGfx = 0x802e4aec
export const RAM_b_area_nok_paratroopa_idleAnimations_8021ED4C = 0x80223b7c
export const RAM_D_802465E8_C8C098 = 0x802465e8
export const RAM_b_area_omo_groove_guy_specialFormation_802273E0 = 0x802273e0
export const RAM_HES_Item_Cake_disabled = 0x80082e68
export const RAM_cloud_puff_render = 0xe001449c
export const RAM_func_80050C30 = 0x80050c30
export const RAM_hos_02_FlyingAI_LoiterInit = 0x802417c8
export const RAM_dro_01_GetNpcCollisionHeight = 0x80242504
export const RAM_explosion_render = 0xe0032420
export const RAM_IES_Screwdriver = 0x8008b984
export const RAM_func_802402B0_90CEF0 = 0x802402b0
export const RAM_arn_05_makeEntities = 0x80245000
export const RAM_vtx_C2B800 = 0x8020a8c0
export const RAM_test_player_entity_aabb = 0x80110aac
export const RAM_virtual_entity_list_update = 0x802d2fcc
export const RAM_D_80298724 = 0x80298724
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_80225670 = 0x80225670
export const RAM_gPauseMsg_55 = 0x8026f768
export const RAM_set_aux_pan_v = 0x8011bc64
export const RAM_D_802EB0E0 = 0x802eb0e0
export const RAM_b_area_pra2_sam_04_UnkModelStuffScript4 = 0x802213d4
export const RAM_hud_element_get_script = 0x801448b0
export const RAM_mgm_01_SetMsgImgs_Panels = 0x80241780
export const RAM_PeachScriptDispatcher = 0x80285010
export const RAM_D_8024716C_9DE18C = 0x8024716c
export const RAM_func_802D5EE0 = 0x802d5ee0
export const RAM_func_80052CFC = 0x80052cfc
export const RAM_D_80232AC0_684840 = 0x80232ac0
export const RAM_b_area_kmr_part_2_red_goomba_2_statusTable_8021EE0C = 0x8021ee0c
export const RAM_omo_08_CompareFloats = 0x802400e4
export const RAM_func_800445A8 = 0x800445a8
export const RAM_dgb_08_func_80243C50_C43A00 = 0x80243c50
export const RAM_steam_burst_init = 0xe005c178
export const RAM_D_80245B38_A99278 = 0x80245b38
export const RAM_flo_18_80244270 = 0x80244270
export const RAM_jan_02_Quizmo_UpdatePartnerPosition = 0x80242524
export const RAM_remove_status_icon_danger = 0x80048200
export const RAM_D_80243580_DA0A50 = 0x80243580
export const RAM_D_802AD050 = 0x802ad050
export const RAM_D_802402C0_A93420 = 0x802402c0
export const RAM_b_area_sam_sam_02c_beforeBattle = 0x8022fe28
export const RAM_b_area_nok_nok_02_beforeBattle_8022B918 = 0x8022b918
export const RAM_func_802407D4_EE6D84 = 0x802407d4
export const RAM_HES_Item_SmallStarPoint = 0x800810c8
export const RAM_D_80243260_A9C7F0 = 0x80243260
export const RAM_omo_09_PatrolNoAttackAI_Main = 0x80241ae0
export const RAM_battle_partner_goombario_8023B45C = 0x8023b45c
export const RAM_func_80241710_D700C0 = 0x80241710
export const RAM_battle_star_peach_dash_UnkBackgroundFunc2 = 0x802a137c
export const RAM_CamPresetUpdate_L = 0x8024cb68
export const RAM_btl_state_draw_end_battle = 0x80244d90
export const RAM_b_area_pra3_duplighost_UnkBattleFunc1 = 0x80218b74
export const RAM_flo_07_flo_25_name_hack = 0x80242b40
export const RAM_b_area_trd_part_2_trd_05c_80233E08 = 0x80233e08
export const RAM_ui_battle_cmd_aim_marker_2_png = 0x8028e6c0
export const RAM_func_80241C90_844860 = 0x80241c90
export const RAM_D_E0074B80 = 0xe0074b80
export const RAM_flo_25_npcAISettings_80242934 = 0x80242934
export const RAM_D_802BD5F0_324F00 = 0x802bd5f0
export const RAM_func_80240B8C_AF84DC = 0x80240b8c
export const RAM_D_8007798C = 0x8007798c
export const RAM_dro_01_Quizmo_ShowEntities = 0x8024159c
export const RAM_func_800E984C = 0x800e984c
export const RAM_ui_battle_menu_spirits_pal = 0x8028a660
export const RAM_dgb_00_pad_1B8 = 0x802401b8
export const RAM_b_area_trd_part_2_fake_bowser_8021B0E4 = 0x8021b0e4
export const RAM_b_area_sam2_gray_magikoopa_parts_flying = 0x8021f2c4
export const RAM_ED42A0_varStash = 0x80241da0
export const RAM_b_area_mac_master2_idleAnimations_8022B68C = 0x8022b68c
export const RAM_b_area_nok_fuzzy_handleEvent_802221FC = 0x802221fc
export const RAM_b_area_kmr_part_3_final_jr_troopa_idleAnimations_8022C3A4 = 0x8022c3a4
export const RAM_battle_star_focus_SetNpcCollision32 = 0x802a14e8
export const RAM_pra_20_ReflectWall = 0x802400ec
export const RAM_guMtxXFML = 0x800679e0
export const RAM_battle_move_super_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_D_8024CE08_C0A688 = 0x8024ce08
export const RAM_func_80240660_EFF160 = 0x80240660
export const RAM_b_area_sam_formation_0C = 0x8023104c
export const RAM_kzn_09_SuperBlock_PartnerSparkles2 = 0x80240c78
export const RAM_b_area_omo2_1_shy_squad = 0x802313a8
export const RAM_func_80055C94 = 0x80055c94
export const RAM_kkj_00_PatrolNoAttackAI_15 = 0x80240b84
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021B5D0 = 0x8021b5d0
export const RAM_nuSiMgrRestart = 0x8002cfac
export const RAM_b_area_kzn_kzn_01b_foregroundModelList_8022786C = 0x8022786c
export const RAM_D_80252400_9E9420 = 0x80252400
export const RAM_b_area_kzn_spike_top_intTable_8021D5CC = 0x8021d5cc
export const RAM_arn_07_StarSpiritEffectFunc3 = 0x802401ac
export const RAM_kmr_20_TransformFoliage = 0x802403f0
export const RAM_gSynDriverPtr = 0x80078e54
export const RAM_b_area_nok_kent_c_koopa_init_80227C20 = 0x80227c20
export const RAM_shape_spell_update = 0xe0024188
export const RAM_filemenu_draw_contents_file_info = 0x80244fd4
export const RAM_entity_ItemBlock_spawn_item = 0x802e3f0c
export const RAM_flo_25_FlyingNoAttackAI_12 = 0x80241538
export const RAM_D_80247960_BF4750 = 0x80247960
export const RAM_dgb_15_npcAI_8024274C = 0x8024274c
export const RAM_b_area_omo2_6_shy_guy_dup = 0x802311fc
export const RAM_D_802448A8_943E58 = 0x802448a8
export const RAM_bSavedOverrideFlags = 0x8029da34
export const RAM_clear_entity_models = 0x80120bb0
export const RAM_battle_star_chill_out_UnkStarFunc1 = 0x802a1000
export const RAM_D_80244D60_A0BC60 = 0x80244d60
export const RAM_omo_13_ShyGuyWanderAI_15 = 0x802402f0
export const RAM_func_80218E2C_4652BC = 0x80218e2c
export const RAM_action_update_use_tweester = 0x802b6000
export const RAM_D_80245A70_A00260 = 0x80245a70
export const RAM_vtx_C2E390 = 0x8020d450
export const RAM_D_802DAE50 = 0x802dae50
export const RAM_dgb_03_npcSettings_80243C94 = 0x80243c94
export const RAM_func_80241610_D31290 = 0x80241610
export const RAM_sbk_33_SpawnSunEffect = 0x80240000
export const RAM_flo_10_varStash = 0x80241cb0
export const RAM_btl_restore_world_cameras = 0x80240aa8
export const RAM_IES_ZapTap = 0x8008c3e4
export const RAM_b_area_trd_part_2_trd_04_afterBattle_80232CA0 = 0x80232ca0
export const RAM_D_8024CAA8_C0A328 = 0x8024caa8
export const RAM_b_area_omo2_1_shy_squad_defenseTable = 0x802310b0
export const RAM_pause_banner_hammer_pal = 0x8026d730
export const RAM_b_area_pra3_swoopula_takeTurn_flying = 0x80228184
export const RAM_func_80263CC4 = 0x80263cc4
export const RAM_mim_06_HoppingAI_ChaseInit = 0x802426e0
export const RAM_fx_65 = 0x80071e70
export const RAM_func_80240238_D4BC58 = 0x80240238
export const RAM_ice_shard_appendGfx = 0xe01103ec
export const RAM_dgb_00_npcGroup_802434FC = 0x802434fc
export const RAM_func_80241668_A3AB48 = 0x80241668
export const RAM_func_802DE894 = 0x802de894
export const RAM_pra_33_reflection_setup_floor = 0x80240500
export const RAM_render_curtains = 0x8002ba80
export const RAM_nuSiMesgQ = 0x800dac78
export const RAM_func_80240808_BD4078 = 0x80240808
export const RAM_kmr_04_func_8024002C_8CA92C = 0x8024002c
export const RAM_b_area_arn_formation_06 = 0x80227e3c
export const RAM_func_80245028_8058A8 = 0x80245028
export const RAM_func_802BD100_31DE70 = 0x802bd100
export const RAM_flo_16_SuperBlock_WaitForPlayerToLand = 0x80240de8
export const RAM_func_80243164_A2D3A4 = 0x80243164
export const RAM_flo_09_npcGroup_8024398C = 0x8024398c
export const RAM_D_802AD06C = 0x802ad06c
export const RAM_kzn_09_PiranhaPlantAI_01 = 0x8024195c
export const RAM_D_802430E8_C71418 = 0x802430e8
export const RAM_D_090016C0 = 0x090016c0
export const RAM_dgb_03_npcGroup_802445A8 = 0x802445a8
export const RAM_GetNpcAnimation = 0x802ce160
export const RAM_dro_02_BuildItemChoiceList = 0x802433e8
export const RAM_flo_13_FlyingAI_Main = 0x802414d0
export const RAM_b_area_omo_sky_guy_takeTurn_8022A9D0 = 0x8022a9d0
export const RAM_gCrashScreen = 0x8009bb30
export const RAM_HES_Item_DizzyStomp_disabled = 0x80084248
export const RAM_func_800E0514 = 0x800e0514
export const RAM_b_area_trd_part_1_koopa_troopa_handleEvent_8021D218 = 0x8021d218
export const RAM_b_area_nok_spiked_goomba_statusTable_8021E3BC = 0x8021e3bc
export const RAM_entity_HiddenItemBlock_idle = 0x802e3e9c
export const RAM_sbk_65_SpawnSunEffect = 0x80240000
export const RAM_gPausePressedButtons = 0x802700c4
export const RAM_create_shading_palette = 0x801481c4
export const RAM_enable_status_icon_surprise = 0x80048034
export const RAM_shockwave_render = 0xe004a748
export const RAM_D_802BCFE8_E2D718 = 0x802bcfe8
export const RAM_flo_11_80241F80 = 0x80241f80
export const RAM_func_80240000_B2C470 = 0x80240000
export const RAM_EEDF50_FlyingAI_Loiter = 0x802416e8
export const RAM_vtx_C24F30 = 0x80203ff0
export const RAM_EnterPlayerPostPipe = 0x80283a50
export const RAM_D_80151260 = 0x80151260
export const RAM_func_80218240_649050 = 0x80218240
export const RAM_HES_Item_FriedEgg = 0x80083138
export const RAM_D_09001470_3AC4A0 = 0x09001470
export const RAM_fx_75_update = 0xe00ea198
export const RAM_iwa_10_SuperBlock_StartGlowEffect = 0x80240770
export const RAM_fx_firework = 0x800711b0
export const RAM_b_area_kzn_spike_top_partsTable_8021C984 = 0x8021c984
export const RAM_UpdateLerp = 0x802d462c
export const RAM_CamPresetUpdate_D = 0x8024bda4
export const RAM_dgb_09_npcAISettings_802443AC = 0x802443ac
export const RAM_D_8014BF68 = 0x8014bf68
export const RAM_b_area_arn_tubbas_heart_defenseTable_80223C78 = 0x80223c78
export const RAM_D_80099F40 = 0x80099f40
export const RAM_dgb_16_WanderMeleeAI_Main = 0x80241170
export const RAM_b_area_sam_sam_02_foregroundModelList = 0x8022f7e0
export const RAM_GoombarioGetTattleID = 0x802bdf60
export const RAM_HES_Item_DojoCard3 = 0x80081f38
export const RAM_func_80240000_A784C0 = 0x80240000
export const RAM_snd_song_set_track_vol_full = 0x8005600c
export const RAM_tik_20_set_script_owner_npc_anim = 0x80240960
export const RAM_IES_GearHammer2 = 0x8008b464
export const RAM_tik_22_UpdateTexturePanSmooth = 0x80240030
export const RAM_hos_00_UnkEffect0FFunc2 = 0x80240050
export const RAM_gPauseTutorialFrameCounter = 0x802700e0
export const RAM_b_area_sam_duplighost_formation_bow = 0x802238d0
export const RAM_mac_05_Quizmo_StageEffect = 0x802529f0
export const RAM_flo_21_StarSpiritEffectFunc5 = 0x802405fc
export const RAM_ui_battle_cmd_flee_1_png = 0x8028d940
export const RAM_ui_coin_sparkle_1_pal = 0x800fbce0
export const RAM_sam_08_SuperBlock_WhiteScreenFlash = 0x80240b38
export const RAM_flo_07_80240890 = 0x80240890
export const RAM_dead_basic_ai_chase_init = 0x8004e39c
export const RAM_func_80240000_D62D10 = 0x80240000
export const RAM_get_flat_collider_normal = 0x8005bdd8
export const RAM_D_E0056F08 = 0xe0056f08
export const RAM_IES_Items = 0x8008b2c8
export const RAM_arn_03_PatrolAI_PostLoiter = 0x8024067c
export const RAM_dgb_06_80240320 = 0x80240320
export const RAM_battle_item_insecticide_herb_func_802A123C_72A98C = 0x802a123c
export const RAM_sbk_02_StashVars = 0x80240a44
export const RAM_pra_20_SetGameStatusUnk84_1 = 0x80240000
export const RAM_IES_HeartFinder = 0x8008c644
export const RAM_shiny_flare_init = 0xe00da0fc
export const RAM_vtx_C23D60 = 0x80202e20
export const RAM_omo_13_GetNpcCollisionHeight = 0x80240cd4
export const RAM_show_foreground_models_unchecked = 0x80267c48
export const RAM_battle_item_unknown_item_func_802A123C_72447C = 0x802a123c
export const RAM_b_area_iwa_buzzar_takeTurn_8021BCD8 = 0x8021bcd8
export const RAM_CheckButtonPress = 0x8026e098
export const RAM_nok_01_LetterDelivery_SaveNpcAnim = 0x802423cc
export const RAM_footprint_render = 0xe0018378
export const RAM_D_80248370_EB0E50 = 0x80248370
export const RAM_gPauseWindowFlipUpFlags = 0x8024efc4
export const RAM_func_802405BC_8EC3DC = 0x802405bc
export const RAM_flo_09_tree3_Trunk = 0x8024347c
export const RAM_filemenu_update_show_name_confirm = 0x80243ccc
export const RAM_b_area_pra3_duplighost_OnShockDeath = 0x8021b804
export const RAM_IES_SpeedySpin = 0x8008c6a4
export const RAM_mac_02_Pipe_GetCurrentFloor = 0x80240020
export const RAM_osPfsFreeBlocks = 0x80068f80
export const RAM_pra_10_ReflectPartner = 0x80240d3c
export const RAM_func_800E4404 = 0x800e4404
export const RAM_ui_battle_projector_reel_png = 0x80290c78
export const RAM_func_802BD7DC = 0x802bd7dc
export const RAM_battle_menu_JumpHudScripts = 0x802ab348
export const RAM_D_801041A0 = 0x801041a0
export const RAM_b_area_mac_mac_01_beforeBattle_802327F4 = 0x802327f4
export const RAM_func_802433BC_85552C = 0x802433bc
export const RAM_b_area_kgr_fuzzipede_hammer_icons = 0x8021afd8
export const RAM_B_801512B0 = 0x801512b0
export const RAM_npc_do_world_collision = 0x80038afc
export const RAM_b_area_nok_kent_c_koopa_handleEvent_80227E08 = 0x80227e08
export const RAM_b_area_trd_part_2_trd_05f_80235560 = 0x80235560
export const RAM_GetVirtualEntityPosition = 0x802d378c
export const RAM_dro_01_func_802431B4_95E3B4 = 0x802431b4
export const RAM_func_802BC4B8_E30E08 = 0x802bc4b8
export const RAM_D_8024A7D8_DD74A8 = 0x8024a7d8
export const RAM_D_80242618_9800E8 = 0x80242618
export const RAM_b_area_dgb_dgb_02_afterBattle_8021B0D0 = 0x8021b0d0
export const RAM_D_8009A5BC = 0x8009a5bc
export const RAM_tik_20_UnkNpcAIFunc12 = 0x80240b10
export const RAM_b_area_kmr_part_3_mage_jr_troopa_init_8022ACE4 = 0x8022ace4
export const RAM_D_8010CFD0 = 0x8010cfd0
export const RAM_func_80219D40_5B31F0 = 0x80219d40
export const RAM_D_802BFF24 = 0x802bff24
export const RAM_pause_prompt_check_abilities_png = 0x8026e0b0
export const RAM_func_80240C10_BC8A70 = 0x80240c10
export const RAM_D_E00AA6EC = 0xe00aa6ec
export const RAM_b_area_jan2_jan_01_foregroundModelList_8021EAAC = 0x8021eaac
export const RAM_jan_03_ToadHouse_InitScreenOverlay = 0x802416c4
export const RAM_GetPartOffset = 0x8026ab74
export const RAM_D_80242C50_91B800 = 0x80242c50
export const RAM_state_init_change_map = 0x80036130
export const RAM_isk_10_SuperBlock_PartnerSparkles4 = 0x80240cc0
export const RAM_b_area_pra3_pra_02_beforeBattle = 0x80231de8
export const RAM_omo_04_UnsetCamera0MoveFlag1 = 0x802402bc
export const RAM_D_800DC4D8 = 0x800dc4d8
export const RAM_HES_Item_HealthyHealthy_disabled = 0x80085e08
export const RAM_mac_02_PatrolAI_ChaseInit = 0x8024114c
export const RAM_dup4_b_area_kpa2_UnfadeBackgroundToBlack = 0x8021a754
export const RAM_D_8010CFCC = 0x8010cfcc
export const RAM_whirlwind_main = 0xe00ce000
export const RAM_b_area_kzn_kzn_04b = 0x802288d8
export const RAM_func_80241898_BD0488 = 0x80241898
export const RAM_flo_00_init_80244BF4 = 0x80244bf4
export const RAM_flo_08_tree1_Trunk = 0x802458ac
export const RAM_D_802943A0 = 0x802943a0
export const RAM_flo_17_exitWalk_802435F8 = 0x802435f8
export const RAM_kzn_10_SpinyTrompHit = 0x80240330
export const RAM_AddBattleCamOffsetZ = 0x8024ea98
export const RAM_func_802414A8_D51498 = 0x802414a8
export const RAM_pra_05_ReflectFloor = 0x8024049c
export const RAM_fx_lightning = 0x80070cd0
export const RAM_omo_10_CompareFloats = 0x802400e4
export const RAM_btl_state_draw_defend = 0x80244ef8
export const RAM_dead_set_screen_overlay_color = 0x80144200
export const RAM_JumpToGoal = 0x80278d08
export const RAM_dgb_01_D_80252E70_C106F0 = 0x80252e70
export const RAM_sam_06_FlyingAI_Chase = 0x80241344
export const RAM_FallToGoal = 0x80279bec
export const RAM_balloon_main = 0xe0096000
export const RAM_D_E0056F00 = 0xe0056f00
export const RAM_arn_02_npcGroup_802416BC = 0x802416bc
export const RAM_D_80099BD8 = 0x80099bd8
export const RAM_D_8014F494 = 0x8014f494
export const RAM_tik_12_Pipe_GetCurrentFloor = 0x80240020
export const RAM_func_8024053C_D13B0C = 0x8024053c
export const RAM_waterfall_init = 0xe00b6178
export const RAM_kpa_102_ProjectileHitbox_30 = 0x80240644
export const RAM_mim_01_MeleeHitbox_31 = 0x8024056c
export const RAM_dispatch_event_partner = 0x8027de80
export const RAM_world_bow_update = 0x802be000
export const RAM_b_area_nok_koopa_troopa_idleAnimations_8021CA90 = 0x80220010
export const RAM_sbk_00_exitWalk_802400E0 = 0x802400e0
export const RAM_func_8003E50C = 0x8003e50c
export const RAM_IES_Berserker = 0x8008c404
export const RAM_big_snowflakes_init = 0xe00601d8
export const RAM_D_801512BC = 0x801512bc
export const RAM_b_area_mac_lee_idleAnimations_80222290 = 0x80222290
export const RAM_D_802A99A8_429CC8 = 0x802a99a8
export const RAM_jan_14_foliage_setup_shear_mtx = 0x80240280
export const RAM_func_80259AAC = 0x80259aac
export const RAM_b_area_mac_lee_idleAnimations_80220540 = 0x80220540
export const RAM_IES_Dolly = 0x8008b584
export const RAM_trd_00_StashVars = 0x80240310
export const RAM_D_802EBF70 = 0x802ebf70
export const RAM_b_area_isk_part_2_isk_07_beforeBattle_80220390 = 0x80220390
export const RAM_flo_03_80240880 = 0x80240880
export const RAM_HES_Item_YoshiCookie_disabled = 0x80083768
export const RAM_record_jump_apex = 0x800e26b0
export const RAM_battle_item_stone_cap_UseItemWithEffect = 0x802a13d0
export const RAM_IES_BigCookie = 0x8008d1d4
export const RAM_b_area_isk_part_2_isk_01_beforeBattle_8021FD70 = 0x8021fd70
export const RAM_b_area_arn_hyper_paragoomba_init_8021B564 = 0x8021b564
export const RAM_D_8026FD98 = 0x8026fd98
export const RAM_b_area_nok_nok_01_foregroundModelList_8022B7D0 = 0x8022b7d0
export const RAM_flo_13_FlyingAI_LoiterInit = 0x8024099c
export const RAM_kmr_06_FlyingAI_Loiter = 0x80240898
export const RAM_mac_00_Quizmo_ShowEntities = 0x8024075c
export const RAM_func_802414F8_8D72F8 = 0x802414f8
export const RAM_b_area_sam2_sam_02d_afterBattle = 0x80223034
export const RAM_D_80094900 = 0x80094900
export const RAM_gPauseMsg_42 = 0x8026f6e8
export const RAM_fx_cold_breath = 0x80072230
export const RAM_func_80240BB0_BBDD50 = 0x80240bb0
export const RAM_nok_02_Quizmo_SetVannaAnim_Wave = 0x80242434
export const RAM_battle_star_up_and_away_UnkBackgroundFunc = 0x802a1414
export const RAM_btl_cam_set_zoffset = 0x8024e5e0
export const RAM_b_area_isk_part_1_stone_chomp_takeTurn_80222ED0 = 0x80222ed0
export const RAM_D_802A19B0 = 0x802a19b0
export const RAM_kkj_12_UnkPhysicsFunc = 0x80240070
export const RAM_D_802B7140 = 0x802b7140
export const RAM_state_drawUI_unpause = 0x80034ff0
export const RAM_battle_item_dried_shroom_GiveRefund = 0x802a1000
export const RAM_pra_13_SetGameStatusUnk84_1 = 0x80240000
export const RAM_tik_22_Pipe_GetEntryPos = 0x8024042c
export const RAM_evt_handle_if_AND = 0x802c4e90
export const RAM_ui_watt_disabled_pal = 0x800ff380
export const RAM_dgb_01_FlyingAI_JumpVels = 0x80244cd0
export const RAM_ui_boots_pal = 0x800fb0e0
export const RAM_entity_SaveBlock_save_data = 0x802e11a0
export const RAM_D_800A0F44 = 0x800a0f44
export const RAM_flo_07_80241BE4 = 0x80241be4
export const RAM_mac_00_Quizmo_varStash = 0x80245dc0
export const RAM_D_80098094 = 0x80098094
export const RAM_D_8014F4EC = 0x8014f4ec
export const RAM_D_80247438_C7E118 = 0x80247438
export const RAM_battle_item_strange_cake_func_802A13E4_7316E4 = 0x802a13e4
export const RAM_tik_12_SuperBlock_CountEligiblePartners = 0x80240d3c
export const RAM_ui_battle_cmd_100pct_pal = 0x8028e100
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021B3DC = 0x8021fa1c
export const RAM_sbk_02_get_tattle = 0x80240000
export const RAM_func_802380E4_710FD4 = 0x802380e4
export const RAM_basic_ai_found_player_jump_init = 0x80049e3c
export const RAM_D_802AD61C = 0x802ad61c
export const RAM_test_ray_triangle_horizontal = 0x8005c7a4
export const RAM_func_802A1030 = 0x802a1030
export const RAM_b_area_pra3_duplighost_sushie_idle = 0x80223a04
export const RAM_b_area_kmr_part_1_kmr_06_UnkFogFunc = 0x80219198
export const RAM_flo_10_802425E4 = 0x802425e4
export const RAM_D_80244E10_A1B030 = 0x80244e10
export const RAM_D_E00C6CE8 = 0xe00c6ce8
export const RAM_func_802A9914 = 0x802a9914
export const RAM_D_801507C8 = 0x801507c8
export const RAM_b_area_kmr_part_3_jr_troopa = 0x80222b08
export const RAM_tik_10_SuperBlock_CountEligiblePartners = 0x802407a0
export const RAM_D_802DF57C = 0x802df57c
export const RAM_D_8029FBD0 = 0x8029fbd0
export const RAM_D_E00DA502 = 0xe00da502
export const RAM_b_area_iwa_cleft_80219BA0 = 0x80219ba0
export const RAM_sbk_30_StationaryAI_Main = 0x80240b64
export const RAM_D_80246614_8491E4 = 0x80246614
export const RAM_fx_flame = 0x80070490
export const RAM_emote_update = 0xe0020468
export const RAM_battle_item_coconut_UseItemWithEffect = 0x802a1240
export const RAM_end_01_CharAnim_HoldClearFold = 0x80240ffc
export const RAM_set_actor_yaw = 0x80263f68
export const RAM_D_802EC870 = 0x802ec870
export const RAM_SetActorIdleSpeed = 0x8026b6d0
export const RAM_dead_partner_clear_player_tracking = 0x800f7278
export const RAM_b_area_mac_lee_init_LeeBombette = 0x802223e0
export const RAM_flo_00_802416C0 = 0x802416c0
export const RAM_dgb_18_npcSettings_80241628 = 0x80241628
export const RAM_pra_01_ReflectFloor = 0x8024049c
export const RAM_kmr_20_Pipe_AwaitDownInput = 0x80240064
export const RAM_battle_star_up_and_away_802A245C = 0x802a245c
export const RAM_D_80250F40_C0E7C0 = 0x80250f40
export const RAM_flo_03_intTable_80240FD8 = 0x80240fd8
export const RAM_HES_Item_SuperJump = 0x80084c38
export const RAM_omo_09_ShyGuyWanderAI_16 = 0x80240b20
export const RAM_ui_battle_menu_flee_pal = 0x80289840
export const RAM_D_802440E2 = 0x802440e2
export const RAM_b_area_isk_part_1_stone_chomp_defenseTable_8022154C = 0x8022154c
export const RAM_func_8024093C_9B20CC = 0x8024093c
export const RAM_dgb_02_ClubbaNappingAI_FallAsleep = 0x80241078
export const RAM_sbk_50_SpawnSunEffect = 0x80240000
export const RAM_b_area_kzn_white_magikoopa_80224448 = 0x80224448
export const RAM_D_80244968_8694A8 = 0x80244968
export const RAM_entity_start_script = 0x80110678
export const RAM_mac_04_Quizmo_HideWorld = 0x8024088c
export const RAM_kpa_03_MagikoopaAI_10 = 0x802413cc
export const RAM_arn_11_entryList = 0x80240000
export const RAM_func_8024091C_A0C60C = 0x8024091c
export const RAM_D_802BEC6C = 0x802bec6c
export const RAM_D_802D9DBC = 0x802d9dbc
export const RAM_battle_item_food_GiveRefund = 0x802a1000
export const RAM_fio_calc_header_checksum = 0x8002af40
export const RAM_sbk_61_SpawnSunEffect = 0x80240000
export const RAM_b_area_dgb_dgb_03_afterBattle_8021B130 = 0x8021b130
export const RAM_mim_08_FlyingAI_JumpInit = 0x80240c7c
export const RAM_battle_move_super_smash_IsBerserkerEquipped = 0x802a1000
export const RAM_dro_01_Quizmo_GetItemName = 0x80241524
export const RAM_Entity_HugeBlueSwitch = 0x802e9bf8
export const RAM_pra_21_SetGameStatusUnk84_1 = 0x80240000
export const RAM_b_area_nok_koopa_troopa_8021CBD8 = 0x80220158
export const RAM_HES_StatHammer3 = 0x80242984
export const RAM_kkj_00_PatrolAI_MoveInit = 0x802400b0
export const RAM_b_area_kmr_part_3_sam_01_UnkFloatFunc2 = 0x80219c5c
export const RAM_b_area_hos_ember_handleEvent_8021B8BC = 0x8021b8bc
export const RAM_jan_08_HeartPlant_SpawnHeart = 0x802413a0
export const RAM_sam_06_ToadHouse_PartnerResumeAbilityScript = 0x802420f0
export const RAM_GetCamType = 0x802cbf64
export const RAM_b_area_kzn_red_magikoopa_8022E5FC = 0x8022333c
export const RAM_HES_Item_GearHammer2 = 0x80081398
export const RAM_func_80240240_8FF8B0 = 0x80240240
export const RAM_b_area_pra3_duplighost_bow = 0x802216e8
export const RAM_vtx_curtains5 = 0x80075bb0
export const RAM_D_09002458 = 0x09002458
export const RAM_SetTattleMsg = 0x80045a58
export const RAM_battle_star_peach_focus_802A18C8 = 0x802a18c8
export const RAM_isk_13_HoppingAI_ChaseInit = 0x802407cc
export const RAM_D_80245850_C76CD0 = 0x80245850
export const RAM_func_80240730_BBD8D0 = 0x80240730
export const RAM_D_8009A6B0 = 0x8009a6b0
export const RAM_b_area_kzn_kzn_01_foregroundModelList_802277E0 = 0x802277e0
export const RAM_GetSelfNpcID = 0x80045614
export const RAM_HES_Item_AttackFXE_disabled = 0x80085c88
export const RAM_D_800A0960 = 0x800a0960
export const RAM_vtx_C2B250 = 0x8020a310
export const RAM_jan_02_Quizmo_NPC_Aux_Impl = 0x802419c0
export const RAM_HES_StatusDigit0 = 0x801080b8
export const RAM_fx_landing_dust = 0x8006fad0
export const RAM_b_area_mac_lee_nextTurn_802221A0 = 0x802221a0
export const RAM_flo_18_80245108 = 0x80245108
export const RAM_b_area_kmr_part_3_mac_01_802347D0 = 0x802347d0
export const RAM_IES_Kaiden = 0x8008c924
export const RAM_omo_05_GrooveGuyAI_03 = 0x802405bc
export const RAM_D_8026FE78 = 0x8026fe78
export const RAM_HES_Item_Heart = 0x80080820
export const RAM_dgb_05_makeEntities = 0x80241870
export const RAM_gPauseBadgesIconIDs = 0x802703a8
export const RAM_mac_05_Quizmo_VannaTEffect = 0x802529f8
export const RAM_state_step_battle = 0x800338e4
export const RAM_flo_24_pad_21A4 = 0x802421a4
export const RAM_b_area_kgr_fuzzipede_StartRumbleWithParams = 0x802184a0
export const RAM_func_8024140C_EA9EEC = 0x8024140c
export const RAM_HES_KooperDisabled = 0x80107d70
export const RAM_vtx_C2BFD0 = 0x8020b090
export const RAM_HES_MarioHead = 0x80107964
export const RAM_D_802439E8_A6AAE8 = 0x802439e8
export const RAM_func_80240000_D9AEF0 = 0x80240000
export const RAM_func_80243F10_9DAF30 = 0x80243f10
export const RAM_tik_07_SuperBlock_EndGlowEffect = 0x802407f0
export const RAM_dro_01_npcGroupList_8024B47C = 0x8024b47c
export const RAM_D_E005E680 = 0xe005e680
export const RAM_b_area_kmr_part_3_jr_troopa_80222C78 = 0x80222c78
export const RAM_fx_75 = 0x80072470
export const RAM_dgb_13_802404C8 = 0x802404c8
export const RAM_b_area_omo_formation_07 = 0x8022faf0
export const RAM_b_area_sam_gulpit_defenseTable = 0x80227e34
export const RAM_b_area_iwa_formation_06 = 0x80218360
export const RAM_gPausePartnersMessages = 0x8024f6d0
export const RAM_D_80244818_AF1C28 = 0x80244818
export const RAM_func_80240000_D955F0 = 0x80240000
export const RAM_func_8024146C_91F9DC = 0x8024146c
export const RAM_entity_HeartBlockContent_init = 0x802e540c
export const RAM_ui_battle_menu_unused_pal = 0x80289600
export const RAM_gPausePartnersAssetNames = 0x8024f718
export const RAM_func_802D2C40 = 0x802d2c40
export const RAM_b_area_hos_magikoopa_handleEvent_8021E6D8 = 0x8021e6d8
export const RAM_D_8024E020_C0B8A0 = 0x8024e020
export const RAM_D_8024EFB4 = 0x8024efb4
export const RAM_D_8010EFA0 = 0x8010efa0
export const RAM_arn_04_FlyingAI_Chase = 0x80241c84
export const RAM_battle_item_pow_block_GiveRefund = 0x802a1000
export const RAM_b_area_mac_mac_02 = 0x8023299c
export const RAM_b_area_kmr_part_3_paragoomba1_defenseTable_8021C1B0 = 0x8021c1b0
export const RAM_b_area_omo2_toy_tank_8021A5B8 = 0x8021a5b8
export const RAM_b_area_jan_jan_01b_afterBattle_8022824C = 0x8022824c
export const RAM_b_area_isk_part_1_stone_chomp_idleAnimations_80221450 = 0x80221450
export const RAM_D_8014C098 = 0x8014c098
export const RAM_D_80228408_6CBF98 = 0x80228408
export const RAM_func_802BBE3C_E2C56C = 0x802bbe3c
export const RAM_func_800E01DC = 0x800e01dc
export const RAM_lens_flare_init = 0xe0034108
export const RAM_npc_raycast_up_corner = 0x800dd04c
export const RAM_entity_can_collide_with_jumping_player = 0x802e0db0
export const RAM_kzn_02_FlyingAI_Jump = 0x80241134
export const RAM_D_E008CB70 = 0xe008cb70
export const RAM_update_npcs = 0x80039930
export const RAM_D_E000E670 = 0xe000e670
export const RAM_HES_Item_UNK_1DC650 = 0x80081e78
export const RAM_D_802453D8 = 0x802453d8
export const RAM_pra_36_reflection_render_wall = 0x80240320
export const RAM_D_09003B98_3AEBC8 = 0x09003b98
export const RAM_sam_11_get_tattle = 0x80240000
export const RAM_dgb_09_SentinelAI_ReturnHomeInit = 0x802431b8
export const RAM_func_802A1518_78ECE8 = 0x802a1518
export const RAM_ED0310_FlyingAI_Wander = 0x802404d0
export const RAM_gPauseWS_20 = 0x8026fd60
export const RAM_draw_adjustable_tiled_image = 0x8012fe78
export const RAM_flo_18_802436BC = 0x802436bc
export const RAM_btl_state_update_end_battle = 0x80244a50
export const RAM_func_80240048_7E73E8 = 0x80240048
export const RAM_sprite_shading_set_light_source = 0x80148154
export const RAM_b_area_kmr_part_3_mac_01_80233AF0 = 0x80233af0
export const RAM_b_area_jan2_white_magikoopa_partsTable_80223C90 = 0x8021a040
export const RAM_D_800D9880 = 0x800d9880
export const RAM_kzn_17_func_802408B4_97BEE4 = 0x80242528
export const RAM_shim_mdl_draw_hidden_panel_surface = 0xe0200560
export const RAM_b_area_sam2_gray_magikoopa_attackWandStrike = 0x80220cd4
export const RAM_omo_02_StationaryAI_IdleInit = 0x80240edc
export const RAM_D_8024F2A4 = 0x8024f2a4
export const RAM_b_area_jan2_jan_01_afterBattle_8021EA9C = 0x8021ea9c
export const RAM_D_80151308 = 0x80151308
export const RAM_func_8025D810 = 0x8025d810
export const RAM_osMapTLBRdb = 0x8006ca30
export const RAM_b_area_kmr_part_3_para_jr_troopa_802260AC = 0x802260ac
export const RAM_b_area_trd_part_1_koopa_troopa_idleAnimations_8021CB28 = 0x8021cb28
export const RAM_b_area_kzn_spike_top_spikeTop_TakeTurn_Floor = 0x8021ee6c
export const RAM_sam_02_ToadHouse_DisableStatusMenu = 0x80240830
export const RAM_vtx_C2D830 = 0x8020c8f0
export const RAM_b_area_isk_part_2_isk_02c = 0x80220004
export const RAM_D_800B0C34 = 0x800b0c34
export const RAM_D_09001420_327830 = 0x09001420
export const RAM_dgb_07_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_b_area_kmr_part_2_goomba_king_idleAnimations_80220844 = 0x80220844
export const RAM_b_area_iwa_buzzar_idle_8021B930 = 0x8021b930
export const RAM_sam_06_foliage_setup_shear_mtx = 0x8024030c
export const RAM_mgm_01_DestroyBlockEntities = 0x80240f90
export const RAM_D_80098174 = 0x80098174
export const RAM_HES_Item_PowerRush_disabled = 0x80085268
export const RAM_osViModeTable = 0x800946a0
export const RAM_b_area_omo2_3_shy_stack_idleAnimations_rock = 0x80231478
export const RAM_dro_01_searchBush = 0x8024d3c0
export const RAM_dgb_09_FlyingAI_WanderInit = 0x80241424
export const RAM_flo_19_pad_ABC = 0x80240abc
export const RAM_nok_03_HoppingAI_Main = 0x80240bf0
export const RAM_vtx_C27740 = 0x80206800
export const RAM_D_80240390_A9ED10 = 0x80240390
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_802285B4 = 0x802285b4
export const RAM_sam_01_Quizmo_GetItemName = 0x802407b4
export const RAM_dgb_15_D_80241F8C_C5149C = 0x80241f8c
export const RAM_jan_06_SpearGuyAI_Loiter = 0x80241658
export const RAM_fio_read_flash = 0x8002b868
export const RAM_dup_b_area_kpa2_UnkFireBreathFXFunc = 0x8021a254
export const RAM_flo_15_pad_2FC = 0x802402fc
export const RAM_omo_08_ShyGuyWanderAI_16 = 0x802409b0
export const RAM_appendGfx_npc = 0x80039fd0
export const RAM_D_802AD000 = 0x802ad000
export const RAM_func_802421A0_8EDFC0 = 0x802421a0
export const RAM_emote_render = 0xe002058c
export const RAM_func_8003D3BC = 0x8003d3bc
export const RAM_flo_22_80240040_CEC280 = 0x80240040
export const RAM_D_E00C6CE0 = 0xe00c6ce0
export const RAM_b_area_kmr_part_3_para_jr_troopa_nextTurn_80227724 = 0x80227724
export const RAM_sam_11_Quizmo_CreateWorker = 0x80241834
export const RAM_jan_08_TransformFoliage = 0x80240380
export const RAM_D_80247E34_8B7EA4 = 0x80247e34
export const RAM_poll_rumble = 0x80028ee0
export const RAM_D_802B7CA0_E22510 = 0x802b7ca0
export const RAM_dgb_08_PatrolAI_PostLoiter = 0x8024067c
export const RAM_kpa_91_ToadHouse_DoesPlayerNeedSleep = 0x802416e8
export const RAM_D_8024F220_A39460 = 0x8024f220
export const RAM_dgb_06_GetItemName = 0x802400b4
export const RAM_nok_11_FlyingAI_Loiter = 0x8024106c
export const RAM_func_E00B4574 = 0xe00b4574
export const RAM_CamPreset_B = 0x80280ce8
export const RAM_dro_01_makeEntities = 0x8024d2f0
export const RAM_jan_02_Quizmo_CreateStage = 0x80241d84
export const RAM_b_area_jan_spear_guy_vector3D_8021A8B4 = 0x8021a8b4
export const RAM_dup_b_area_kpa3_UnkActorPosFunc = 0x80218e34
export const RAM_D_800988C0 = 0x800988c0
export const RAM_func_80241FF0_7ECD00 = 0x80241ff0
export const RAM_func_802BD540_323E90 = 0x802bd540
export const RAM_dgb_17_entryList = 0x80240000
export const RAM_D_80242030_A548F0 = 0x80242030
export const RAM_debuff_appendGfx = 0xe00624ec
export const RAM_func_802400FC_B1B66C = 0x802400fc
export const RAM_b_area_kzn2_petit_piranha_SetFlameX = 0x80218d90
export const RAM_flo_14_80242EAC = 0x80242eac
export const RAM_pra_28_reflection_render_wall = 0x80240320
export const RAM_b_area_isk_part_1_pokey_mummy_8021B088 = 0x8021b088
export const RAM_dgb_05_enterSingleDoor_80241634 = 0x80241634
export const RAM_HES_MenuHammer2 = 0x80108884
export const RAM_arn_03_npcAISettings_80241BC0 = 0x80241bc0
export const RAM_D_802AD008 = 0x802ad008
export const RAM_RemoveFortressKeys = 0x802d6c94
export const RAM_flo_07_foliage_setup_shear_mtx = 0x802403e0
export const RAM_D_802A1A64_0073B934 = 0x802a1a64
export const RAM_b_area_nok_nok_01_beforeBattle_8022B6F8 = 0x8022b6f8
export const RAM_clear_area_flag = 0x8014554c
export const RAM_merlin_house_stars_render = 0xe00a6358
export const RAM_evt_handle_get_4_float = 0x802c605c
export const RAM_SetNpcPaletteSwapMode = 0x802cfe80
export const RAM_EED1E0_SuperBlock_UpgradeOrbAngles = 0x802437b8
export const RAM_b_area_kmr_part_3_paragoomba1_statusTable_8021C1C8 = 0x8021c1c8
export const RAM_D_802BE0E8 = 0x802be0e8
export const RAM_entity_SuperBlockContent_attach_to_parent = 0x802e581c
export const RAM_func_80240CDC_A1155C = 0x80240cdc
export const RAM_isk_18_set_script_owner_npc_anim = 0x80240000
export const RAM_D_802D9E68 = 0x802d9e68
export const RAM_GetPartYaw = 0x8026b4c0
export const RAM_world_parakarry_post_battle = 0x802be9d0
export const RAM_kmr_02_Quizmo_GetItemName = 0x80240444
export const RAM_gPausePartnersIconIDs = 0x80270640
export const RAM_b_area_kgr_kgr_01_foregroundModelList_8021D5A8 = 0x8021d5a8
export const RAM_IES_Mystery = 0x8008b344
export const RAM_dro_01_8024C41C = 0x8024c41c
export const RAM_func_80240040_978750 = 0x80240040
export const RAM_D_80232AD8_684858 = 0x80232ad8
export const RAM_evt_handle_switch_const = 0x802c4fe4
export const RAM_sbk_56_SuperBlock_ClearOverride40 = 0x8024009c
export const RAM_gPauseSpiritsSpriteIDs = 0x802706c0
export const RAM_spr_transform_point = 0x802dcd00
export const RAM_b_area_sam_gulpit_rocks_idleAnimations = 0x802294d0
export const RAM_HES_Item_SpeedySpin = 0x80085118
export const RAM_func_802A9834 = 0x802a9834
export const RAM_func_80241B84_833384 = 0x80241b84
export const RAM_tik_22_Pipe_GetCurrentFloor = 0x8024035c
export const RAM_dgb_01_D_80252C08_C10488 = 0x80252c08
export const RAM_gPauseMapWindowBPs = 0x80250590
export const RAM_flo_17_npcSettings_802444C4 = 0x802444c4
export const RAM_arn_04_npcAISettings_802436F4 = 0x802436f4
export const RAM_ResetAllActorSounds = 0x8026f6ec
export const RAM_func_80242C44_825F04 = 0x80242c44
export const RAM_battle_star_peach_beam_802A327C = 0x802a327c
export const RAM_nok_02_Quizmo_varStash = 0x802470c8
export const RAM_func_80271588 = 0x80271588
export const RAM_func_80219604_465A94 = 0x80219604
export const RAM_D_800B451E = 0x800b451e
export const RAM_D_8024AABC_A34CFC = 0x8024aabc
export const RAM_sam_08_MeleeHitbox_30 = 0x80240d40
export const RAM_D_802441A0_CB91A0 = 0x802441a0
export const RAM_b_area_isk_part_2_isk_07_afterBattle_802203D8 = 0x802203d8
export const RAM_D_80249870_C070F0 = 0x80249870
export const RAM_D_80232A60_6847E0 = 0x80232a60
export const RAM_trd_08_FireBar_Sounds = 0x80240f00
export const RAM_D_802420B0_D99940 = 0x802420b0
export const RAM_dro_02_D_8024EF88 = 0x8024ef88
export const RAM_func_80056228 = 0x80056228
export const RAM_D_802DA140 = 0x802da140
export const RAM_ClearDefeatedEnemies = 0x80045640
export const RAM_func_802400AC_D5473C = 0x802400ac
export const RAM_flo_13_init_80246280 = 0x80246280
export const RAM_D_8029D408 = 0x8029d408
export const RAM_D_80151300 = 0x80151300
export const RAM_D_80245838_C76CB8 = 0x80245838
export const RAM_func_802405B4_BC8414 = 0x802405b4
export const RAM_D_8014F12F = 0x8014f12f
export const RAM_ui_status_coin_8_png = 0x80101000
export const RAM_IES_JumpCharge0 = 0x8008c2a4
export const RAM_D_E00B4EE8 = 0xe00b4ee8
export const RAM_HES_Item_Mistake_disabled = 0x80082ec8
export const RAM_tik_02_CheckItemFlags40 = 0x80240578
export const RAM_D_E00E4DA8 = 0xe00e4da8
export const RAM_D_80150370 = 0x80150370
export const RAM_kpa_91_ToadHouse_Stub = 0x8024181c
export const RAM_trd_04_FlyingAI_LoiterInit = 0x802425f0
export const RAM_kkj_20_ToadHouse_DoesPlayerNeedSleep = 0x80240480
export const RAM_HES_HPDigit2 = 0x80291ff8
export const RAM_D_E0030E90 = 0xe0030e90
export const RAM_b_area_omo2_3_shy_stack_launch_rock = 0x80235d60
export const RAM_vtx_C215A0 = 0x80200660
export const RAM_b_area_omo_omo_04_intTable_8022DF14 = 0x8022df14
export const RAM_b_area_sam2_monstar_dlist = 0x80223570
export const RAM_D_80232B18_684898 = 0x80232b18
export const RAM_b_area_isk_part_2_formationTable = 0x80220448
export const RAM_D_09001600_351820 = 0x09001600
export const RAM_func_802A911C_429E3C = 0x802a911c
export const RAM_battle_star_smooch_802A1630 = 0x802a1630
export const RAM_D_8014B767 = 0x8014b767
export const RAM_dgb_01_D_802534A0_C10D20 = 0x802534a0
export const RAM_jan_01_HoppingAI_Hop = 0x80240570
export const RAM_D_E003CD40 = 0xe003cd40
export const RAM_parent_collider_to_model = 0x8005b780
export const RAM_snd_load_BK = 0x80054c4c
export const RAM_flo_17_npcGroup_80244E48 = 0x80244e48
export const RAM_flo_18_80244ED0 = 0x80244ed0
export const RAM_dro_01_EVS_Quizmo_MovePartnerToPodium = 0x802457e0
export const RAM_b_area_isk_part_2_tutankoopa_nextTurn_8021F9C4 = 0x8021f9c4
export const RAM_flo_09_npcGroup_80243B7C = 0x80243b7c
export const RAM_battle_star_focus_UnkStarFunc3 = 0x802a116c
export const RAM_b_area_isk_part_1_vector3D_80224624 = 0x80224624
export const RAM_D_80283F58_7E4DD8 = 0x80283f58
export const RAM_b_area_arn_arn_03_afterBattle_80227AB0 = 0x80227ab0
export const RAM_battle_item_sleepy_sheep_UseItem = 0x802a1c74
export const RAM_b_area_kmr_part_3_mac_01_afterBattle_80234C70 = 0x80234c70
export const RAM_mgm_02_EnableMenus = 0x80240580
export const RAM_D_802EB100 = 0x802eb100
export const RAM_b_area_trd_part_2_fake_bowser_8021D350 = 0x8021d350
export const RAM_D_80243B48_EEA0F8 = 0x80243b48
export const RAM_EA0C10_GetNpcCollisionHeight = 0x80242794
export const RAM_dgb_01_D_80252294_C0FB14 = 0x80252294
export const RAM_kpa_115_UnkFunc11 = 0x80240000
export const RAM_mac_01_GetNpcCollisionHeight = 0x80243aa8
export const RAM_D_80243240_A9C7D0 = 0x80243240
export const RAM_D_80243080_C713B0 = 0x80243080
export const RAM_b_area_mim_formation_10 = 0x8021e9e4
export const RAM_decrement_status_menu_disabled = 0x800e9b30
export const RAM_ui_battle_status_transparent_4_png = 0x80102fe0
export const RAM_b_area_kmr_part_2_blue_goomba_idleAnimations_80219A00 = 0x80219a00
export const RAM_b_area_mac_lee_idleAnimations_8022058C = 0x8022058c
export const RAM_func_802403F0_AD62C0 = 0x802403f0
export const RAM_D_800F7B70 = 0x800f7b70
export const RAM_D_80241440_9EF030 = 0x80241440
export const RAM_omo_09_SetCamera0Flag1000 = 0x802435cc
export const RAM_D_8014C620 = 0x8014c620
export const RAM_battle_item_stone_cap_DrinkItem = 0x802a1914
export const RAM_state_drawUI_title_screen = 0x800375a4
export const RAM_SongsUsingVariationFlag = 0x8014f720
export const RAM_b_area_omo_yellow_shy_guy = 0x8021c8f4
export const RAM_mdl_currentTransformGroupChildIndex = 0x8015336c
export const RAM_D_8014F48C = 0x8014f48c
export const RAM_ui_battle_cmd_flee_2_pal = 0x8028dae0
export const RAM_state_step_intro = 0x80036854
export const RAM_omo_10_SetAngleClamped = 0x8024001c
export const RAM_D_09002420_3887D0 = 0x09002420
export const RAM_b_area_omo_blue_shy_guy_UnkBattleFunc1 = 0x802180d0
export const RAM_dup_kmr_20_Pipe_SetAnimFlag = 0x80241780
export const RAM_update_encounters_post_battle = 0x80041f10
export const RAM_dgb_01_D_802529F8_C10278 = 0x802529f8
export const RAM_D_80245D98_A994D8 = 0x80245d98
export const RAM_iwa_02_CleftAI_LosePlayer = 0x80240984
export const RAM_D_802D9F00 = 0x802d9f00
export const RAM_D_80298494 = 0x80298494
export const RAM_HES_Item_AutoMultibounce_disabled = 0x800841e8
export const RAM_b_area_omo_yellow_shy_guy_handleEvent_8021CB28 = 0x8021cb28
export const RAM_arn_05_npcAISettings_802416DC = 0x802416dc
export const RAM_b_area_mac_lee_8021D5C4 = 0x8021d5c4
export const RAM_jan_03_Quizmo_StashVars = 0x80241b9c
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021DBE8 = 0x8021dbe8
export const RAM_arn_02_npcGroup_802418AC = 0x802418ac
export const RAM_b_area_dgb_dgb_04_beforeBattle_8021B170 = 0x8021b170
export const RAM_shimmer_burst_render = 0xe00706f0
export const RAM_kzn_04_FireBarAI_Callback = 0x80241644
export const RAM_D_80245F48_9AE338 = 0x80245f48
export const RAM_HPBarToCurrent = 0x8026d7a8
export const RAM_D_802EB108 = 0x802eb108
export const RAM_WaitForPlayerInputEnabled = 0x802d2508
export const RAM_D_8024EF84 = 0x8024ef84
export const RAM_D_80243258_A9C7E8 = 0x80243258
export const RAM_mac_04_TransformFoliage = 0x80240290
export const RAM_render_generic_entities_backUI = 0x80123550
export const RAM_trd_04_set_script_owner_npc_anim = 0x802404b0
export const RAM_HES_Item_Unused_08F_disabled = 0x800824a8
export const RAM_pra_27_GetItemName = 0x80241004
export const RAM_kpa_61_FlyingMagikoopaAI_OnHit = 0x802422f0
export const RAM_fx_big_smoke_puff = 0x8006f8f0
export const RAM_battle_menu_isMessageDisabled = 0x802ad0e8
export const RAM_isk_11_SetEntityFlags100000 = 0x80240120
export const RAM_HES_Item_CloseCall_disabled = 0x80085328
export const RAM_dgb_09_80243E60 = 0x80243e60
export const RAM_kpa_03_MagikoopaAI_CanShootSpell = 0x80240a48
export const RAM_D_8014BC00 = 0x8014bc00
export const RAM_D_80250DA8_C0E628 = 0x80250da8
export const RAM_sbk_01_npcGroup_802403F8 = 0x802403f8
export const RAM_HES_Item_Unused_0E6_disabled = 0x80085a48
export const RAM_func_8023A19C_70DEAC = 0x8023a19c
export const RAM_D_80249A98_A33CD8 = 0x80249a98
export const RAM_lightning_bolt_update = 0xe00bc238
export const RAM_b_area_jan2_jan_00_8021DB30 = 0x8021db30
export const RAM_b_area_omo_pink_shy_guy_init_8021E468 = 0x8021e468
export const RAM_IES_Unused_0F2 = 0x8008cb24
export const RAM_make_item_entity_delayed = 0x80131ac8
export const RAM_D_80243CC8_D3E298 = 0x80243cc8
export const RAM_D_802529B4_9E99D4 = 0x802529b4
export const RAM_mim_05_PiranhaPlantAI_00 = 0x80240940
export const RAM_CamPreset_J = 0x80280e00
export const RAM_D_802AD100 = 0x802ad100
export const RAM_parasol_get_npc = 0x802b6000
export const RAM_flo_16_lavaResetList_80242B40 = 0x80242b40
export const RAM_pra_06_reflection_setup_floor = 0x80240530
export const RAM_btl_are_all_enemies_defeated = 0x802633e8
export const RAM_flo_08_npcAI_8024303C = 0x8024303c
export const RAM_jan_05_WanderMeleeAI_Main = 0x80241ee8
export const RAM_b_area_sam2_gray_magikoopa = 0x8021f330
export const RAM_func_80241DAC_D3C37C = 0x80241dac
export const RAM_flo_09_FlyingAI_Main = 0x8024162c
export const RAM_bActorMessages = 0x802838c4
export const RAM_b_area_kmr_part_1_formation_03 = 0x80219288
export const RAM_D_8024D948_80E1C8 = 0x8024d948
export const RAM_ending_decals_main = 0xe0068000
export const RAM_b_area_jan2_white_magikoopa = 0x8021a0d0
export const RAM_dgb_01_D_802531A0_C10A20 = 0x802531a0
export const RAM_D_09001A00_351C20 = 0x09001a00
export const RAM_b_area_isk_part_1_vector3D_802240B8 = 0x802240b8
export const RAM_dgb_05_MeleeHitbox_Main = 0x8024041c
export const RAM_dgb_01_D_80252058_C0F8D8 = 0x80252058
export const RAM_b_area_omo2_5_shy_squad_dup_parts = 0x802310b8
export const RAM_D_800A08DC = 0x800a08dc
export const RAM_vtx_C2BA50 = 0x8020ab10
export const RAM_HES_Item_Unused_0DF_disabled = 0x800857a8
export const RAM_D_80270068 = 0x80270068
export const RAM_flo_00_npcGroup_80246264 = 0x80246264
export const RAM_AwaitPlayerApproach = 0x802d48ac
export const RAM_D_800989A0 = 0x800989a0
export const RAM_func_80260948 = 0x80260948
export const RAM_fx_purple_ring = 0x80070430
export const RAM_b_area_trd_part_1_paratroopa_handleEvent_8021F2EC = 0x8021f2ec
export const RAM_nuContQueryRead = 0x80060920
export const RAM_b_area_kmr_part_2_kmr_04_foregroundModelList_80224FC0 = 0x80224fc0
export const RAM_pause_set_cursor_pos = 0x80242d04
export const RAM_nok_02_StashVars = 0x802402e0
export const RAM_b_area_kmr_part_2_red_goomba_CalculateRotationZ = 0x8021840c
export const RAM_D_E1000010 = 0xe1000010
export const RAM_func_8013A8E0 = 0x8013a8e0
export const RAM_PlayerMoveTo = 0x802d1134
export const RAM_func_E008042C = 0xe008042c
export const RAM_arn_09_802400A0 = 0x802400a0
export const RAM_D_800A0950 = 0x800a0950
export const RAM_sam_02_Quizmo_UpdatePartnerPosition = 0x80241a3c
export const RAM_sfx_play_sound_at_npc = 0x80149d08
export const RAM_D_8024205C_94160C = 0x8024205c
export const RAM_func_80240A58_BC88B8 = 0x80240a58
export const RAM_mac_03_LetterDelivery_RestoreNpcAnim = 0x80241b58
export const RAM_flo_13_npcAI_80244240 = 0x80244240
export const RAM_dro_02_8024459C = 0x8024459c
export const RAM_ui_mamar_png = 0x800fd140
export const RAM_isk_13_StoneChompFXC = 0x802415c0
export const RAM_D_80246CB0_B594E0 = 0x80246cb0
export const RAM_D_802EB200 = 0x802eb200
export const RAM_D_802406F0_986BD0 = 0x802406f0
export const RAM_dro_01_func_80242858_95DA58 = 0x80242858
export const RAM_gPauseWS_28 = 0x8026ff20
export const RAM_gPauseDLBannerHammer = 0x8026ee90
export const RAM_kmr_20_ToadHouse_UpdateScreenOverlay = 0x802412e8
export const RAM_b_area_omo2_1_shy_squad_init = 0x8023142c
export const RAM_D_8024F5D0 = 0x8024f5d0
export const RAM_D_80246550 = 0x80246550
export const RAM_func_802536A8 = 0x802536a8
export const RAM_smoke_ring_appendGfx = 0xe002e368
export const RAM_nuAuDmaBufList = 0x800a3be0
export const RAM_b_area_kmr_part_3_formation_07 = 0x8021a290
export const RAM_kzn_09_SuperBlock_GetPartnerRank = 0x80240068
export const RAM_func_802422EC_EE889C = 0x802422ec
export const RAM_D_80225FC8_48D078 = 0x80225fc8
export const RAM_vtx_C27580 = 0x80206640
export const RAM_ui_status_text_6_pal = 0x801003c0
export const RAM_b_area_sam_sam_01_UnkModelStuffScript1 = 0x8022efe0
export const RAM_EA0C10_LetterDelivery_SaveNpcAnim = 0x80242d64
export const RAM_func_80240F88_A2B1C8 = 0x80240f88
export const RAM_render_windows = 0x80147698
export const RAM_omo_03_UnkBufferFunc = 0x80240188
export const RAM_tik_10_SuperBlock_HideBlockContent = 0x802406bc
export const RAM_dgb_07_entryList = 0x80241430
export const RAM_flo_16_main = 0x80242b70
export const RAM_b_area_trd_part_1_trd_04_afterBattle_80224320 = 0x80224320
export const RAM_kzn_19_GetNpcCollisionHeight = 0x80241580
export const RAM_kkj_18_UpdateTexturePanSmooth = 0x80240000
export const RAM_flo_00_npcSettings_80242914 = 0x80242914
export const RAM_D_802A2C50 = 0x802a2c50
export const RAM_mac_01_ToadHouse_ShowStatusMenu = 0x802437cc
export const RAM_kkj_25_GetFloorCollider = 0x80240080
export const RAM_kzn_07_FlyingNoAttackAI_13 = 0x80241e48
export const RAM_D_800A0958 = 0x800a0958
export const RAM_ui_battle_cmd_aim_marker_5_pal = 0x8028e920
export const RAM_b_area_trd_part_1_koopa_troopa_idleAnimations_8021CADC = 0x8021cadc
export const RAM_trd_02_PatrolAI_ChaseInit = 0x802410d0
export const RAM_func_8024079C_BD400C = 0x8024079c
export const RAM_b_area_trd_part_1_trd_00_foregroundModelList_80224BBC = 0x80224bbc
export const RAM_pra_02_reflection_setup_wall = 0x80240128
export const RAM_isk_14_StoneChompFXC = 0x802415c0
export const RAM_b_area_dgb_formation_00 = 0x8021b230
export const RAM_flo_25_AddPlayerHandsOffset = 0x80241b88
export const RAM_D_802AD658 = 0x802ad658
export const RAM_nok_15_ParatroopaAI_Main = 0x802422ec
export const RAM_HES_Item_Unused_12D = 0x800865b8
export const RAM_SetSelfEnemyFlags = 0x8004550c
export const RAM_b_area_dgb_clubba_handleEvent_802181AC = 0x802181ac
export const RAM_b_area_isk_part_1_buzzy_beetle_defenseTable_8021E4C0 = 0x8021e4c0
export const RAM_gPauseWindowFlipDownAngles_2 = 0x8024f00c
export const RAM_au_BGMCmd_ED_SubTrackCoarseTune = 0x8004ff88
export const RAM_pra_40_reflection_render_wall = 0x802402f0
export const RAM_jan_05_MeleeHitbox_CanSeePlayer = 0x80241ad4
export const RAM_b_area_nok_spiked_goomba_idleAnimations_8021E4B4 = 0x8021e4b4
export const RAM_battle_item_thunder_bolt_EatItem = 0x802a196c
export const RAM_b_area_trd_part_1_koopa_troopa_idle_8021CD30 = 0x8021cd30
export const RAM_func_8006AA34 = 0x8006aa34
export const RAM_set_animation = 0x80263cfc
export const RAM_wPartnerMoveGoalZ = 0x800f8028
export const RAM_dgb_01_D_80251AD8_C0F358 = 0x80251ad8
export const RAM_b_area_kpa2_GetPlayerHpPercent = 0x8021c9d4
export const RAM_kzn_08_varStash = 0x80244bb0
export const RAM_flo_00_npcSettings_802428E8 = 0x802428e8
export const RAM_battle_action_cmd_water_block_circle_png = 0x802aa880
export const RAM_func_8011BAE8 = 0x8011bae8
export const RAM_trd_03_set_script_owner_npc_col_height = 0x802403b4
export const RAM_kpa_03_MagikoopaAI_OnPlayerFled = 0x80241d6c
export const RAM_flo_17_LakituAI_Loiter = 0x80241a14
export const RAM_flo_09_tree2_Leaves = 0x80243438
export const RAM_D_802527B0_864920 = 0x802527b0
export const RAM_b_area_kgr_fuzzipede_80219668 = 0x80219668
export const RAM_pra_27_reflection_setup_floor = 0x80240530
export const RAM_kzn_09_LetterDelivery_SavedNpcAnim = 0x80247664
export const RAM_b_area_hos_ember = 0x8021ae34
export const RAM_sam_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_create_part_shadow = 0x8026731c
export const RAM_ui_battle_status_dizzy_1_png = 0x80102180
export const RAM_ED42A0_StashVars = 0x80240080
export const RAM_pause_badges_comparator = 0x80246fe0
export const RAM_func_80149A6C = 0x80149a6c
export const RAM_omo_11_SuperBlock_GetPartnerRank = 0x802407f8
export const RAM_EC2240_StashVars = 0x80240040
export const RAM_func_80219660_5B2B10 = 0x80219660
export const RAM_D_80241D3B_ADCBDB = 0x80241d3b
export const RAM_kzn_17_UnkFunc51 = 0x80243004
export const RAM_b_area_pra3_duplighost_lakilester_idleAnimations2 = 0x8022524c
export const RAM_func_80240B10_A11390 = 0x80240b10
export const RAM_hud_element_use_preset_size = 0x80144df4
export const RAM_ui_battle_cmd_aim_reticle_0_pal = 0x8028f180
export const RAM_b_area_sam_duplighost_parakarry_defenseTable = 0x802215dc
export const RAM_b_area_sam_duplighost_kooper_idleAnimations = 0x8021e67c
export const RAM_gCurrentModelTreeRoot = 0x801512fc
export const RAM_func_8021C14C_6A334C = 0x8021c14c
export const RAM_b_area_pra3_duplighost_formation_lakilester = 0x80226484
export const RAM_D_8029EFB0 = 0x8029efb0
export const RAM_pra_37_MeleeHitbox_30 = 0x80240f50
export const RAM_func_80240C4C_AF859C = 0x80240c4c
export const RAM_D_802ACFF0 = 0x802acff0
export const RAM_arn_05_interact_80241AB4 = 0x80241ab4
export const RAM_D_80245D88_A994C8 = 0x80245d88
export const RAM_kpa_50_ProjectileHitbox_33 = 0x80240518
export const RAM_func_80240408_AA93A8 = 0x80240408
export const RAM_func_8003B1A8 = 0x8003b1a8
export const RAM_flo_14_D_80245328_CD0638 = 0x80245328
export const RAM_omo_04_StashVars = 0x80240000
export const RAM_func_80240EC0_8DAFC0 = 0x80240ec0
export const RAM_HES_SmallText7 = 0x80242224
export const RAM_SpriteInstances = 0x802dfa48
export const RAM_b_area_isk_part_2_chain_chomp_idleAnimations_80218C50 = 0x80218c50
export const RAM_flo_10_80241630 = 0x80241630
export const RAM_HES_Item_UNK_1DC210 = 0x80081e18
export const RAM_b_area_isk_part_1_stone_chomp_ChompChainUpdateHelperFunc = 0x802183c0
export const RAM_mim_04_HoppingAI_Loiter = 0x80240828
export const RAM_D_8024F59B = 0x8024f59b
export const RAM_nuGfxDisplayOn = 0x8005f430
export const RAM_D_802BEBB4 = 0x802bebb4
export const RAM_SetCamViewport = 0x802cab18
export const RAM_flo_14_npcSettings_80244578 = 0x80244578
export const RAM_flo_08_npcSettings_80242FE0 = 0x80242fe0
export const RAM_b_area_trd_part_1_bullet_bill_init_80219088 = 0x80219088
export const RAM_base_GiveRefundCleanup = 0x80261064
export const RAM_b_area_jan_m_bush = 0x80221960
export const RAM_dgb_11_enterSingleDoor_80240224 = 0x80240224
export const RAM_b_area_kzn_formation_12 = 0x80229d5c
export const RAM_b_area_iwa_whacka_partsTable_8021FD54 = 0x8021fd54
export const RAM_LoadStarPowerScript = 0x80280950
export const RAM_HES_Item_Melody = 0x80081cf8
export const RAM_func_8025D90C = 0x8025d90c
export const RAM_kzn_11_EVS_FireBar_Defeated = 0x802437e0
export const RAM_D_802451C8_9B6958 = 0x802451c8
export const RAM_flo_08_SuperBlock_EndGlowEffect = 0x80240510
export const RAM_mac_01_Quizmo_VannaTEffect = 0x80262a68
export const RAM_action_command_07_free_hud_elements = 0x802a959c
export const RAM_HES_StatBoots0 = 0x8024286c
export const RAM_func_800E9900 = 0x800e9900
export const RAM_flo_21_80242918 = 0x80242918
export const RAM_func_8024228C_7ECF9C = 0x8024228c
export const RAM_D_80249D48 = 0x80249d48
export const RAM_D_80246538_kkj_19 = 0x80246538
export const RAM_battle_item_egg_missile_frame1_displayList = 0x802a2190
export const RAM_b_area_mim_formation_0B = 0x8021e840
export const RAM_b_area_sam_duplighost_sushie_parts = 0x802248ec
export const RAM_D_8014BA00 = 0x8014ba00
export const RAM_func_802400D8_BAA218 = 0x802400d8
export const RAM_battle_item_food_GiveRefundCleanup = 0x802a11d4
export const RAM_HES_Kooper = 0x80107d48
export const RAM_battle_star_star_beam_FadeBackgroundToBlack = 0x802a12e4
export const RAM_entity_Shadow_init = 0x802e0d90
export const RAM_D_802BCE20 = 0x802bce20
export const RAM_mac_03_Quizmo_HideWorld = 0x802408dc
export const RAM_b_area_isk_part_1_pokey_mummy_8021B998 = 0x8021b998
export const RAM_tik_17_SuperBlock_LoadCurrentPartnerName = 0x80240478
export const RAM_D_801508F8 = 0x801508f8
export const RAM_kpa_61_FlyingMagikoopaAI_23 = 0x80241d28
export const RAM_b_area_trd_part_1_trd_05_80224360 = 0x80224360
export const RAM_vtx_C23CE0 = 0x80202da0
export const RAM_partner_free_npc = 0x800ea6a8
export const RAM_HES_Item_BakingSalt_disabled = 0x800871e8
export const RAM_func_80057224 = 0x80057224
export const RAM_D_80234968_6BBB68 = 0x80234968
export const RAM_D_80244CB0_DB20B0 = 0x80244cb0
export const RAM_dgb_14_makeEntities = 0x80240480
export const RAM_b_area_kmr_part_3_final_jr_troopa_idleAnimations_8022C370 = 0x8022c370
export const RAM_arn_03_init_8024334C = 0x8024334c
export const RAM_GetIndexFromHome = 0x8026a0d4
export const RAM_entity_GiantChest_await_got_item = 0x802e7cb8
export const RAM_battle_star_lullaby_SetNpcCollision32 = 0x802a14e8
export const RAM_b_area_kzn_putrid_piranha_UnkEffect6FFunc = 0x802181fc
export const RAM_D_09002090_3522B0 = 0x09002090
export const RAM_au_SEFCmd_0C = 0x8004d180
export const RAM_sfx_stop_env_sounds = 0x80149838
export const RAM_D_80247A10_DA4EE0 = 0x80247a10
export const RAM_omo_07_FlyingAI_Chase = 0x8024276c
export const RAM_mgm_01_SetPanelState = 0x8024050c
export const RAM_D_8024FA78_C0D2F8 = 0x8024fa78
export const RAM_flo_00_pad_684C = 0x8024684c
export const RAM_music_note_render = 0xe004c364
export const RAM_does_script_exist_by_ref = 0x802c41a4
export const RAM_ui_battle_cont_b_up_pal = 0x8028afa0
export const RAM_dgb_01_D_80252A24_C102A4 = 0x80252a24
export const RAM_HES_Item_Unused_0F3 = 0x80085ef8
export const RAM_HES_Item_SuperSmash_disabled = 0x80084d88
export const RAM_dgb_17_main = 0x802403b8
export const RAM_arn_04_CleftAI_FindPlayerInit = 0x802427b0
export const RAM_D_8028F860 = 0x8028f860
export const RAM_D_A450000C = 0xa450000c
export const RAM_flo_08_ItemChoice_SaveSelected = 0x802419c4
export const RAM_btl_state_update_1C = 0x80243fdc
export const RAM_b_area_mac_master3_tripleStrike3 = 0x8022f3dc
export const RAM_battle_partner_goombario_80238488 = 0x80238488
export const RAM_world_sushie_while_riding = 0x802bfe34
export const RAM_arn_07_npcGroup_8024749C = 0x8024749c
export const RAM_HES_CursorHand = 0x80241ea0
export const RAM_D_E003CD48 = 0xe003cd48
export const RAM_func_80261DF4 = 0x80261df4
export const RAM_func_802BB76C_E300BC = 0x802bb76c
export const RAM_func_802D2520 = 0x802d2520
export const RAM_D_80246590_B6C040 = 0x80246590
export const RAM_HES_HeaderStats = 0x80241fe8
export const RAM_D_8029EFB8 = 0x8029efb8
export const RAM_b_area_isk_part_2_isk_05_afterBattle_80220218 = 0x80220218
export const RAM_hos_03_ToadHouse_InitScreenOverlay = 0x802423bc
export const RAM_arn_04_PatrolAI_ChaseInit = 0x8024081c
export const RAM_NAMESPACE_defenseTable_8023867C = 0x8023867c
export const RAM_flo_09_npcAISettings_802435B0 = 0x802435b0
export const RAM_dro_01_EVS_Quizmo_NPC_Aux = 0x8024771c
export const RAM_flo_25_FlyingAI_Init = 0x80241228
export const RAM_func_80240C20_872650 = 0x80240c20
export const RAM_obk_03_GetItemName = 0x80240224
export const RAM_arn_04_CleftAI_HidingInit = 0x802424e8
export const RAM_tik_20_UnkDistFunc = 0x802409dc
export const RAM_kmr_02_Quizmo_SetVannaAnim_Idle = 0x80240e80
export const RAM_D_802A1AC0 = 0x802a1ac0
export const RAM_Entity_ShatteringHammer3Block = 0x802e9cd4
export const RAM_obk_01_SetCamera0MoveFlag1 = 0x8024001c
export const RAM_func_80268798 = 0x80268798
export const RAM_b_area_mac_lee_vector3D_8021E940 = 0x8021e940
export const RAM_D_802BFEE0 = 0x802bfee0
export const RAM_dgb_18_802412C0 = 0x802412c0
export const RAM_gPausePartnersGridData = 0x8024f710
export const RAM_b_area_isk_part_2_isk_05 = 0x80220234
export const RAM_isk_05_StoneChompAI_Main = 0x80240f3c
export const RAM_func_80242898_9C7C78 = 0x80242898
export const RAM_b_area_tik_UpdateTexturePanStepped = 0x80219ed4
export const RAM_b_area_jan2_jan_01_8021E418 = 0x8021e418
export const RAM_flo_25_npcGroup_80243A6C = 0x80243a6c
export const RAM_D_090023B0 = 0x090023b0
export const RAM_battle_star_star_beam_UnkStarFunc1 = 0x802a1000
export const RAM_func_8023A1B0_70DEC0 = 0x8023a1b0
export const RAM_b_area_nok_paratroopa_defenseTable_8021EB08 = 0x80223938
export const RAM_D_8024B9A0_B8FB20 = 0x8024b9a0
export const RAM_dro_01_8024C8D4 = 0x8024c8d4
export const RAM_b_area_pra3_gray_magikoopa_GetSelectedMoveID = 0x80219cf8
export const RAM_kmr_02_Quizmo_UpdatePartnerPosition = 0x802412a0
export const RAM_D_E02006C0 = 0xe02006c0
export const RAM_trd_02_PatrolAI_LosePlayer = 0x80241320
export const RAM_arn_04_CleftAI_ReturnHome = 0x80242db8
export const RAM_HES_Item_BakingCream = 0x800872d8
export const RAM_func_80282594 = 0x80282594
export const RAM_IES_BoosPortrait = 0x8008b704
export const RAM_D_8029F038 = 0x8029f038
export const RAM_HES_Item_PDownDUp_disabled = 0x80084fc8
export const RAM_b_area_omo2_5_shy_squad_dup_handleEvent = 0x80233384
export const RAM_D_80231418_63CFB8 = 0x80231418
export const RAM_battle_star_star_storm_UnkStarFunc1 = 0x802a1000
export const RAM_b_area_kmr_part_3_final_jr_troopa_SetBackgroundAlpha = 0x80218fa4
export const RAM_battle_item_mushroom_func_802A1378_715BC8 = 0x802a1378
export const RAM_entity_SimpleSpring_idle = 0x802e6064
export const RAM_action_command_stop_leech_draw_hud_elements = 0x802a94a4
export const RAM_D_8010C9C0 = 0x8010c9c0
export const RAM_shim_guTranslate = 0xe0200430
export const RAM_b_area_kpa3_ActorJumpToPos = 0x80218960
export const RAM_sam_06_ToadHouse_DisableStatusMenu = 0x80241f50
export const RAM_D_802453B8_EF79C8 = 0x802453b8
export const RAM_fx_flower_splash = 0x8006fb90
export const RAM_HES_Item_DoubleDip_disabled = 0x80083d68
export const RAM_HES_StickTapDown = 0x80109124
export const RAM_iwa_10_SuperBlock_ShowSelectPartnerMenu = 0x802404bc
export const RAM_kzn_07_FlyingAI_Wander = 0x802409d0
export const RAM_EnableNpcAI = 0x80044cf0
export const RAM_vtx_C29540 = 0x80208600
export const RAM_b_area_jan_spear_guy_8021BB18 = 0x8021bb18
export const RAM_func_8021866C_4EFB0C = 0x8021866c
export const RAM_kzn_03_LetterDelivery_Init = 0x80242a08
export const RAM_end_00_CreditsViewport = 0x80242d60
export const RAM_battle_item_repel_gel_GiveRefundCleanup = 0x802a11d4
export const RAM_pra_29_SetPartnerFlags20000 = 0x80240eec
export const RAM_action_update_peach_falling = 0x802b647c
export const RAM_dgb_15_MeleeHitbox_33 = 0x802401b4
export const RAM_b_area_omo2_light_bulb_shake_tank = 0x8022f9f4
export const RAM_kkj_13_UpdateTexturePanStepped = 0x80240144
export const RAM_ui_muskular_pal = 0x800fd7c0
export const RAM_b_area_sam_formationTable = 0x80231164
export const RAM_battle_menu_moveOptionActive = 0x802ad600
export const RAM_dgb_04_SuperBlock_get_partner_rank = 0x802424a4
export const RAM_D_8029FBA8 = 0x8029fba8
export const RAM_pra_39_reflection_unk_change_anim_facing = 0x80240084
export const RAM_trd_04_PatrolAI_LosePlayer = 0x802416f0
export const RAM_D_8024F5A4 = 0x8024f5a4
export const RAM_jan_03_Quizmo_ShouldQuizmoLeave = 0x802426d8
export const RAM_b_area_jan_spear_guy_8021B770 = 0x8021b770
export const RAM_func_80241CA0_9943D0 = 0x80241ca0
export const RAM_func_802406DC_BD3F4C = 0x802406dc
export const RAM_battle_partner_goombario_statusTable_802391F8 = 0x802391f8
export const RAM_gSpinHistoryBufferPos = 0x8010c944
export const RAM_arn_05_npcSettings_80241758 = 0x80241758
export const RAM_HES_Item_Unused_02D = 0x80083858
export const RAM_ui_battle_status_peril_pal = 0x80103260
export const RAM_func_80240158_EC2358 = 0x80240158
export const RAM_b_area_nok_fuzzy_idle_802221EC = 0x802221ec
export const RAM_D_8015437D = 0x8015437d
export const RAM_hos_04_UpdateTexturePanSmooth = 0x802402a8
export const RAM_ModifyActorDecoration = 0x8026eff0
export const RAM_btl_state_update_defend = 0x80244e38
export const RAM_D_80240E94_AF87E4 = 0x80240e94
export const RAM_flo_25_80244D0C = 0x80244d0c
export const RAM_pause_tabs_draw_badges = 0x80244cc0
export const RAM_D_802EB208 = 0x802eb208
export const RAM_au_SEFCmd_SetRandomPitch = 0x8004d324
export const RAM_IES_DustyHammer = 0x8008d674
export const RAM_cancel_message = 0x80125b08
export const RAM_b_area_sam_duplighost_goombario_handleEvent = 0x8021cca0
export const RAM_func_802A48FC = 0x802a48fc
export const RAM_appendGfx_enemy_actor = 0x80257b28
export const RAM_func_80219428_6BCFB8 = 0x80219428
export const RAM_b_area_jan2_white_magikoopa_defenseTable_80223B2C = 0x80219edc
export const RAM_func_802405C8_D13B98 = 0x802405c8
export const RAM_kpa_102_set_script_owner_npc_anim = 0x8024155c
export const RAM_battle_item_tasty_tonic_EatItem = 0x802a17ac
export const RAM_au_bgm_stop_all = 0x8004da74
export const RAM_HES_Item_BakingFlour_disabled = 0x800874e8
export const RAM_func_802A955C = 0x802a955c
export const RAM_b_area_omo2_toy_tank_formation_stilt_guy_2 = 0x8022f58c
export const RAM_D_80251A28_C0F2A8 = 0x80251a28
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_takeTurn_802313B4 = 0x802313b4
export const RAM_D_090020E8 = 0x090020e8
export const RAM_D_80246558 = 0x80246558
export const RAM_HES_BoostJumpEnd = 0x8010717c
export const RAM_dup_kkj_10_UnkGooberFunc = 0x80240f60
export const RAM_b_area_kzn_kzn_01_afterBattle_802277D0 = 0x802277d0
export const RAM_func_80240248_BDA9F8 = 0x80240248
export const RAM_b_area_kzn_lava_bubble_defenseTable_80218E10 = 0x80218e10
export const RAM_pra_32_StarSpiritEffectFunc4 = 0x802405dc
export const RAM_b_area_trd_part_2_trd_03_afterBattle_80232920 = 0x80232920
export const RAM_b_area_pra3_gray_magikoopa_attackMagicBlast = 0x80230368
export const RAM_sPartYaws = 0xe00027d8
export const RAM_D_80242D48_918AB8 = 0x80242d48
export const RAM_D_80159D54 = 0x80159d54
export const RAM_b_area_pra3_red_magikoopa_8022C138 = 0x8022c138
export const RAM_iwa_01_CleftAI_Hiding = 0x80242498
export const RAM_ui_status_star_point_7_pal = 0x80101620
export const RAM_D_8024309C_DB535C = 0x8024309c
export const RAM_dgb_03_802438A8 = 0x802438a8
export const RAM_mim_06_PiranhaPlantAI_Main = 0x80241cc4
export const RAM_sbk_maps = 0x80090790
export const RAM_kpa_95_PatrolAI_LosePlayer = 0x80240b4c
export const RAM_ui_status_coin_6_pal = 0x80100f40
export const RAM_pad8 = 0x8008eff0
export const RAM_D_8024C3D0_C09C50 = 0x8024c3d0
export const RAM_func_80240454_BCF044 = 0x80240454
export const RAM_kpa_11_set_script_owner_npc_anim = 0x80240310
export const RAM_D_802A1458_73DCB8 = 0x802a1458
export const RAM_mgm_02_GiveCoinReward = 0x80241f38
export const RAM_isk_10_SuperBlock_ClearOverride40 = 0x8024001c
export const RAM_kill_all_scripts = 0x802c4114
export const RAM_flo_17_80244590 = 0x80244590
export const RAM_flo_12_802417F0 = 0x802417f0
export const RAM_battle_item_volt_shroom_GiveRefund = 0x802a1000
export const RAM_mgm_00_Pipe_GetCameraYaw = 0x802401d0
export const RAM_create_cameras_a = 0x8002def4
export const RAM_kpa_12_get_tattle = 0x80240000
export const RAM_b_area_jan_jungle_fuzzy_takeTurn_80221628 = 0x80221628
export const RAM_vtx_C2DD50 = 0x8020ce10
export const RAM_D_8024DCD3_A37F13 = 0x8024dcd3
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021B384 = 0x8021f9c4
export const RAM_mac_02_Quizmo_ShouldQuizmoLeave = 0x802422fc
export const RAM_quizmo_stage_main = 0xe009c000
export const RAM_ui_battle_swap_arrow_left_pal = 0x8028a260
export const RAM_IES_SuperFocus = 0x8008c904
export const RAM_D_8014BAC0 = 0x8014bac0
export const RAM_EB0E60_UpdateTexturePanStepped = 0x80240124
export const RAM_kmr_07_StationaryAI_Idle = 0x802400b4
export const RAM_GetBattleState = 0x8026e140
export const RAM_func_802A9228_425D78 = 0x802a9228
export const RAM_flo_09_tree3 = 0x80243484
export const RAM_func_80240000_ABB290 = 0x80240000
export const RAM_b_area_trd_part_1_paratroopa = 0x8021ecd8
export const RAM_pra_14_CheckItemFlags40 = 0x80241188
export const RAM_flo_03_exitWalk_80240B2C = 0x80240b2c
export const RAM_dgb_01_main = 0x80243c48
export const RAM_gPauseStatsSPIncElements = 0x8024f434
export const RAM_func_8004D428 = 0x8004d428
export const RAM_BattleCam_PosY = 0x8029f274
export const RAM_set_windows_visible = 0x80147ec4
export const RAM_remove_status_icon_peril = 0x800480f4
export const RAM_dgb_02_npcSettings_80241DDC = 0x80241ddc
export const RAM_sbk_14_SpawnSunEffect = 0x80240000
export const RAM_D_E02006C8 = 0xe02006c8
export const RAM_flo_08_FlowerGuard_SetItemEntityPosition = 0x80241a98
export const RAM_func_80240348_C717C8 = 0x80240348
export const RAM_gCollisionPointY = 0x800a424c
export const RAM_mim_04_HoppingAI_LoiterInit = 0x80240768
export const RAM_D_8015DD78 = 0x80155d78
export const RAM_D_80150378 = 0x80150378
export const RAM_D_80154374 = 0x80154374
export const RAM_dgb_01_D_802520B0_C0F930 = 0x802520b0
export const RAM_func_80242B10_DF9510 = 0x80242b10
export const RAM_D_80244F48_CAC1E8 = 0x80244f48
export const RAM_D_E00BCE60 = 0xe00bce60
export const RAM_set_npc_sprite = 0x8003ac5c
export const RAM_D_802ECCF0 = 0x802eccf0
export const RAM_D_8029D8F8 = 0x8029d8f8
export const RAM_HES_Spirit3 = 0x8024248c
export const RAM_b_area_jan_stageTable = 0x80228c60
export const RAM_pra_02_SetGameStatusUnk84_1 = 0x80240000
export const RAM_dgb_05_npcGroup_80241F2C = 0x80241f2c
export const RAM_dgb_16_extraAnimationList_802418A4 = 0x802418a4
export const RAM_sbk_30_GetItemName = 0x80240e90
export const RAM_func_80059AC8 = 0x80059ac8
export const RAM_entity_BlueWarpPipe_start_bound_script = 0x802e8e10
export const RAM_D_802433F0_89D740 = 0x802433f0
export const RAM_kmr_02_Quizmo_AnswerResult = 0x80257f40
export const RAM_ui_muskular_disabled_pal = 0x800fd7e0
export const RAM_kkj_01_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_D_802284D4_6CC064 = 0x802284d4
export const RAM_evtDebugPrintBuffer = 0x802daca0
export const RAM_hos_03_Quizmo_Answers = 0x80248080
export const RAM_action_command_flee_free_hud_elements = 0x802a9894
export const RAM_entity_SaveBlock_show_tutorial_message = 0x802e1204
export const RAM_nok_01_Quizmo_ShouldAppear = 0x80240ea4
export const RAM_D_80220770 = 0x80220770
export const RAM_dup2_b_area_pra_GetSelectedMoveID = 0x80218b38
export const RAM_jan_03_Quizmo_FadeInWorld = 0x80242114
export const RAM_dgb_12_settings = 0x802402d0
export const RAM_jan_03_Quizmo_SpinPartner = 0x80242a1c
export const RAM_FadeOutMusic = 0x802d5ce0
export const RAM_make_item_entity_nodelay = 0x80131a94
export const RAM_arn_05_func_8024113C_BE8D1C = 0x8024113c
export const RAM_ui_status_pow_star_empty_pal = 0x80100ae0
export const RAM_DisablePlayerInput = 0x802d0e28
export const RAM_flo_00_PatrolNoAttackAI_Main = 0x80240bd4
export const RAM_sam_06_varStash = 0x802443d0
export const RAM_SetActorSounds = 0x8026f740
export const RAM_func_802435F0_C7A2D0 = 0x802435f0
export const RAM_D_80245620 = 0x80245620
export const RAM_pra_27_StashVars = 0x80240f50
export const RAM_set_action_state = 0x800e5da4
export const RAM_D_802AD0BB = 0x802ad0bb
export const RAM_battle_item_dizzy_dial_func_802A1270_727B80 = 0x802a1270
export const RAM_battle_item_mystery_DrinkItem = 0x802a1e94
export const RAM_npc_test_move_with_slipping = 0x800dd618
export const RAM_b_area_pra2_crystal_king_parts = 0x80218af4
export const RAM_vtx_C2D6A0 = 0x8020c760
export const RAM_b_area_kmr_part_3_spiked_goomba_sinTable = 0x8021b030
export const RAM_func_80218BE8_4E3328 = 0x80218be8
export const RAM_dgb_09_SentinelAI_PostLosePlayer = 0x802430f0
export const RAM_iwa_10_SuperBlock_PartnerSparkles2 = 0x80240f68
export const RAM_func_802405F8_AF3298 = 0x802405f8
export const RAM_HES_WeakenedLoop = 0x801062a0
export const RAM_omo_11_SuperBlock_PartnerSparkles2 = 0x80241408
export const RAM_D_8024BAA0_C09320 = 0x8024baa0
export const RAM_trd_04_PatrolAI_Chase = 0x802415d0
export const RAM_D_802AD058 = 0x802ad058
export const RAM_ui_battle_hp_2_pal = 0x8028aa00
export const RAM_dgb_08_npcSettings_802449FC = 0x802449fc
export const RAM_b_area_omo_sky_guy_defenseTable_80229610 = 0x80229610
export const RAM_D_80257F21 = 0x80257f21
export const RAM_b_area_mac_master3_8022DC44 = 0x8022dc44
export const RAM_battle_item_pebble_modelCommandList = 0x802a1b50
export const RAM_dgb_01_D_802526E0_C0FF60 = 0x802526e0
export const RAM_battle_item_mushroom_script6 = 0x802a1bbc
export const RAM_flo_16_SuperBlock_SetOverride40 = 0x802400e0
export const RAM_RemovePartShadow = 0x8026f59c
export const RAM_b_area_isk_part_1_swooper_init_8021C64C = 0x8021c64c
export const RAM_HES_KlevarDisabled = 0x80107b74
export const RAM_dgb_04_SuperBlock_SwitchToPartner = 0x802427ec
export const RAM_pause_arrows_png = 0x8026c430
export const RAM_dup2_b_area_kpa2_UnkFireBreathFXFunc = 0x8021b60c
export const RAM_GetEntryID = 0x802ca460
export const RAM_b_area_kzn_kzn_04b_802284EC = 0x802284ec
export const RAM_b_area_sam2_paragoomba_8021B6F0 = 0x8021c470
export const RAM_D_802EB030 = 0x802eb030
export const RAM_dro_01_8024C218 = 0x8024c218
export const RAM_kpa_91_PatrolAI_ChaseInit = 0x8024081c
export const RAM_jan_16_TransformFoliage = 0x80240370
export const RAM_D_80284054_7E4ED4 = 0x80284054
export const RAM_fx_65_appendGfx = 0xe00ca4cc
export const RAM_D_80257320 = 0x80257320
export const RAM_D_E004AED8 = 0xe004aed8
export const RAM_D_09000470_3928B0 = 0x09000470
export const RAM_D_E004AED0 = 0xe004aed0
export const RAM_HES_SwapBackground = 0x80292cf4
export const RAM_D_8024F420 = 0x8024f420
export const RAM_b_area_pra3_pos_swoopula = 0x802324a0
export const RAM_battle_item_volt_shroom_DrinkItem = 0x802a17c4
export const RAM_nuAuDmaCallBack = 0x8004b074
export const RAM_PlayerRasterCache = 0x802e0c70
export const RAM_get_anim_model_fog_color = 0x801200b8
export const RAM_arn_12_npcAI_8024049C = 0x8024049c
export const RAM_D_E00BCE68 = 0xe00bce68
export const RAM_arn_03_80241E68 = 0x80241e68
export const RAM_func_802B65F8_E26D08 = 0x802b65f8
export const RAM_ShopEndSpeech = 0x80283f2c
export const RAM_snd_bpm_to_tempo = 0x8004fc08
export const RAM_D_802A97CC_42CEBC = 0x802a97cc
export const RAM_btl_state_update_switch_to_player = 0x80242ac8
export const RAM_D_80240A30_8AA440 = 0x80240a30
export const RAM_func_80052BC0 = 0x80052bc0
export const RAM_b_area_trd_part_2_trd_02_beforeBattle_80231BA4 = 0x80231ba4
export const RAM_func_800525A0 = 0x800525a0
export const RAM_flo_14_ItemChoice_HasSelectedItem = 0x802445fc
export const RAM_D_8010CD20 = 0x8010cd20
export const RAM_b_area_omo_omo_04_foregroundModelList_8022DDFC = 0x8022ddfc
export const RAM_ED42A0_UpdateTexturePanSmooth = 0x80240198
export const RAM_dro_01_Pipe_GetEntryPos = 0x802400f0
export const RAM_func_80241578_B2D9E8 = 0x80241578
export const RAM_entity_base_block_play_vanish_effect = 0x802e31b0
export const RAM_D_8015131C = 0x8015131c
export const RAM_b_area_sbk_sbk_02_beforeBattle_8021F800 = 0x8021f800
export const RAM_b_area_kzn_spike_top_UnkBattleFunc1 = 0x80218380
export const RAM_D_8029FBA0 = 0x8029fba0
export const RAM_D_802435E8_E15D48 = 0x802435e8
export const RAM_hos_05_UpdateTexturePanSmooth = 0x802402f8
export const RAM_D_8010D682 = 0x8010d682
export const RAM_D_09000F88_327398 = 0x09000f88
export const RAM_kzn_11_FlyingAI_LoiterInit = 0x80240c38
export const RAM_filemenu_yesno_draw_prompt_contents = 0x80246e24
export const RAM_KooperUpdate = 0x802bd260
export const RAM_HES_Item_RunawayPay = 0x800849f8
export const RAM_battle_item_electro_pop_AddFP = 0x802a149c
export const RAM_func_E00D8264 = 0xe00d8264
export const RAM_flo_00_80241B90 = 0x80241b90
export const RAM_pra_35_PatrolAI_ChaseInit = 0x8024173c
export const RAM_flo_10_StashVars = 0x80240080
export const RAM_tik_23_TackleAI_Main = 0x80240cf4
export const RAM_play_model_animation_starting_from = 0x80120198
export const RAM_func_80240694_A27564 = 0x80240694
export const RAM_func_80240364_A3FAA4 = 0x80240364
export const RAM_EF2680_FlyingAI_Chase = 0x80240e64
export const RAM_battle_item_snowman_doll_func_802A123C_71C06C = 0x802a123c
export const RAM_hos_05_UnkFunc26 = 0x80240124
export const RAM_D_8024F2F4 = 0x8024f2f4
export const RAM_D_8024F2B7 = 0x8024f2b7
export const RAM_kmr_02_Quizmo_Worker = 0x80257f2c
export const RAM_jan_07_foliage_setup_shear_mtx = 0x80240da0
export const RAM_flo_00_settings = 0x80241490
export const RAM_pra_18_reflection_unk_resolve_anim = 0x80240020
export const RAM_IdleRunToGoal = 0x8027a264
export const RAM_func_80240910_BC8770 = 0x80240910
export const RAM_D_802D9D98 = 0x802d9d98
export const RAM_increment_max_SP = 0x800ea1b4
export const RAM_IES_StrangeCake = 0x8008d414
export const RAM_D_80151118 = 0x80151118
export const RAM_make_item_entity_at_player = 0x80131b00
export const RAM_sam_07_PiranhaPlantAI_00 = 0x80240f20
export const RAM_D_09000880_331780 = 0x09000880
export const RAM_HES_Item_ForestPass = 0x800816c8
export const RAM_HES_Item_Unused_137 = 0x80087878
export const RAM_sam_08_SuperBlock_PartnerSparkles2 = 0x80240c78
export const RAM_dgb_04_SuperBlock_PartnerSparkles2 = 0x80243048
export const RAM_guTranslate = 0x80067820
export const RAM_kpa_51_TackleAI_Main = 0x802417e0
export const RAM_dgb_02_ClubbaNappingAI_Init = 0x802408d0
export const RAM_D_09000C68 = 0x09000c68
export const RAM_b_area_trd_part_2_trd_01 = 0x802314ac
export const RAM_fx_3D_update = 0xe007a50c
export const RAM_dro_01_dup_varStash = 0x8024b5b0
export const RAM_battle_item_sleepy_sheep1_pal = 0x802a2470
export const RAM_D_80248F28_7F3C38 = 0x80248f28
export const RAM_D_E007AC10 = 0xe007ac10
export const RAM_b_area_mac_mac_01_80231770 = 0x80231770
export const RAM_b_area_omo2_5_shy_squad_dup_80233E48 = 0x80233e48
export const RAM_mim_11_UpdateTexturePanStepped = 0x80240404
export const RAM_cloud_trail_init = 0xe0016220
export const RAM_func_80240654_A27524 = 0x80240654
export const RAM_b_area_trd_part_2_red_ninja_koopa_UnkEnemyFunc = 0x80218cd0
export const RAM_gMapTransitionAlpha = 0x800a0940
export const RAM_gCollisionDataBackup = 0x800a4264
export const RAM_func_80240280_926C70 = 0x80240280
export const RAM_reset_animator_list = 0x8011e310
export const RAM_gAnimModelFogStart = 0x80153a58
export const RAM_func_80240BEC_A4032C = 0x80240bec
export const RAM_func_8003034C = 0x8003034c
export const RAM_b_area_nok_fuzzy = 0x8022212c
export const RAM_D_802BCBD8_E31528 = 0x802bcbd8
export const RAM_flo_18_80241ED4 = 0x80241ed4
export const RAM_landing_dust_appendGfx = 0xe000c798
export const RAM_b_area_trd_part_2_red_ninja_koopa_defenseTable_80228500 = 0x80228500
export const RAM_nok_02_LetterDelivery_SavedNpcAnim = 0x80254b94
export const RAM_get_animator_node_with_id = 0x8011ff98
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022AC80 = 0x8022ac80
export const RAM_b_area_omo_omo_05b = 0x8022f5fc
export const RAM_entity_block_hit_animate_scale = 0x802e328c
export const RAM_ascii_EC2008 = 0x802491b8
export const RAM_DisablePlayerPhysics = 0x802d0de4
export const RAM_arn_13_npcGroup_802407EC = 0x802407ec
export const RAM_D_80245980_A16200 = 0x80245980
export const RAM_D_80242298_D97888 = 0x80242298
export const RAM_tik_20_TackleAI_Main = 0x80240cf4
export const RAM_omo_11_SuperBlock_CountEligiblePartners = 0x802408b0
export const RAM_IES_DojoCard3 = 0x8008bc24
export const RAM_sbk_06_TransformFoliage = 0x802401b0
export const RAM_dgb_01_D_80253960_C111E0 = 0x80253960
export const RAM_arn_04_npcSettings_80243794 = 0x80243794
export const RAM_b_area_mim_formation_02 = 0x8021e54c
export const RAM_D_8010C9C8 = 0x8010c9c8
export const RAM_kzn_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_b_area_kmr_part_2_kmr_06_afterBattle_8022580C = 0x8022580c
export const RAM_D_802AD608 = 0x802ad608
export const RAM_ParakarryTweesterPhysics = 0x802bebc8
export const RAM_flo_12_80241858 = 0x80241858
export const RAM_dgb_03_exitWalk_80242B2C = 0x80242b2c
export const RAM_heap_nextMallocID = 0x80074270
export const RAM_SetPartDispOffset = 0x8026bc38
export const RAM_mac_02_LetterDelivery_RestoreNpcAnim = 0x802433c4
export const RAM_nok_15_FlyingAI_JumpVels = 0x80243d5c
export const RAM_ui_battle_status_stop_0_png = 0x80102720
export const RAM_EA8AE0_StarSpiritEffectFunc2 = 0x80240358
export const RAM_b_area_isk_part_1_swooper_8021C6C0 = 0x8021c6c0
export const RAM_dgb_01_FlyingAI_ChaseInit = 0x80240de0
export const RAM_b_area_pra3_duplighost_lakilester_statusTable = 0x80225264
export const RAM_D_802423D0 = 0x802423d0
export const RAM_b_area_kmr_part_2_red_goomba_idleAnimations_8021B810 = 0x8021b810
export const RAM_AddPartDispOffset = 0x8026bd88
export const RAM_b_area_mac_master1_defenseTable_8022A2AC = 0x8022a2ac
export const RAM_end_00_CreditsData = 0x8024e960
export const RAM_b_area_isk_part_1_vector3D_80224630 = 0x80224630
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_80229160 = 0x80229160
export const RAM_battle_item_food_func_802A123C_73330C = 0x802a123c
export const RAM_get_cam_viewport = 0x8002e4e8
export const RAM_omo_10_SetPlayerStatusPosYaw = 0x802401ec
export const RAM_b_area_jan2_stageTable = 0x8021f72c
export const RAM_kmr_12_GetGoomba = 0x80240000
export const RAM_func_80240734_A27604 = 0x80240734
export const RAM_D_8029D8F0 = 0x8029d8f0
export const RAM_tik_10_SuperBlock_LoadCurrentPartnerName = 0x80240ac8
export const RAM_D_802A9620 = 0x802a9620
export const RAM_D_E00CACD0 = 0xe00cacd0
export const RAM_b_area_omo2_toy_tank_8021A4DC = 0x8021a4dc
export const RAM_b_area_sam2_sam_02d_UnkModelStuffScript1 = 0x80222d10
export const RAM_b_area_omo2_4_signal_guy_takeTurn = 0x80231754
export const RAM_func_8005DDF0 = 0x8005ddf0
export const RAM_fold_clear_state_data = 0x8013a9c8
export const RAM_b_area_trd_part_1_trd_05_80224478 = 0x80224478
export const RAM_D_8024C068 = 0x8024c068
export const RAM_action_command_smack_free_hud_elements = 0x802a9990
export const RAM_D_80234750_6BB950 = 0x80234750
export const RAM_lightning_bolt_init = 0xe00bc1d0
export const RAM_kpa_09_RangedAttackAI_Main = 0x80240f94
export const RAM_arn_07_FlyingAI_Jump = 0x802415a4
export const RAM_vtx_C27880 = 0x80206940
export const RAM_b_area_jan2_jan_00 = 0x8021e2d8
export const RAM_isk_14_HoppingAI_HopInit = 0x80240000
export const RAM_dgb_01_npcGroup_80246280 = 0x80246280
export const RAM_func_802400C0_9BD720 = 0x802400c0
export const RAM_tik_04_Pipe_AwaitDownInput = 0x80240340
export const RAM_func_8025CD28 = 0x8025cd28
export const RAM_omo_03_UpdateTexturePanStepped = 0x802406a0
export const RAM_D_800A0B98 = 0x800a0b98
export const RAM_kzn_19_LetterDelivery_Init = 0x802418d4
export const RAM_pra_35_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_80270060 = 0x80270060
export const RAM_mac_04_MonitorMusicProximityTrigger = 0x80241ef0
export const RAM_jan_08_SpearGuyAI_Main = 0x80241f38
export const RAM_D_802977BC = 0x802977bc
export const RAM_D_802441D0_ED41C0 = 0x802441d0
export const RAM_b_area_isk_part_1_isk_05_afterBattle_80223BA8 = 0x80223ba8
export const RAM_kzn_23_UpdateTexturePanSmooth = 0x80240000
export const RAM_fx_radial_shimmer = 0x80070bb0
export const RAM_gPauseMsg_38 = 0x8026f694
export const RAM_pause_prompt_check_abilities_pal = 0x8026e4b0
export const RAM_D_802451C0_9B6950 = 0x802451c0
export const RAM_SetNpcVar = 0x80045320
export const RAM_func_E0002738 = 0xe0002738
export const RAM_D_80242C30_91B7E0 = 0x80242c30
export const RAM_ui_battle_hp_2_png = 0x8028a9e0
export const RAM_HES_StickTapLeft = 0x80108f90
export const RAM_b_area_flo2_MakeSuctionPath = 0x80218620
export const RAM_flo_09_UnkFunc43 = 0x80240040
export const RAM_pra_09_GetNpcCollisionHeight = 0x80240f20
export const RAM_b_area_trd_part_1_bob_omb_partsTable_8021A70C = 0x8021a70c
export const RAM_IES_YellowBerry = 0x8008d8f4
export const RAM_D_802EB038 = 0x802eb038
export const RAM_kpa_51_MagikoopaAI_21 = 0x80240cf4
export const RAM_world_twink_update = 0x802bd20c
export const RAM_shim_func_80138D88 = 0xe0200630
export const RAM_AssignSwitchFlag = 0x80111ff8
export const RAM_D_E00CACD8 = 0xe00cacd8
export const RAM_IES_BooRecord = 0x8008b6c4
export const RAM_evt_handle_switch = 0x802c4f74
export const RAM_jan_15_TransformFoliage = 0x80240130
export const RAM_func_802A9894 = 0x802a9894
export const RAM_HES_AnimatedCursorHand = 0x80241ecc
export const RAM_HES_Item_StarStone = 0x80081d88
export const RAM_D_802498D4_A33B14 = 0x802498d4
export const RAM_func_802BBA60_E303B0 = 0x802bba60
export const RAM_entity_upgrade_block_hide_content = 0x802e5690
export const RAM_b_area_omo_red_shy_guy_partsTable_802192D0 = 0x802192d0
export const RAM_ui_input_analog_stick_left_png = 0x800f8ee0
export const RAM_func_80241D44_97F814 = 0x80241d44
export const RAM_battle_item_insecticide_herb_DrinkItem = 0x802a1964
export const RAM_gPauseCurrentDescIconScript = 0x802700cc
export const RAM_b_area_kmr_part_2_goomnut_tree_partsTable_8022339C = 0x8022339c
export const RAM_ascii_EC2000 = 0x802491b0
export const RAM_D_09000080_3924C0 = 0x09000080
export const RAM_HES_StartButton = 0x80108c70
export const RAM_D_80153A4A = 0x80153a4a
export const RAM_D_E0020CE0 = 0xe0020ce0
export const RAM_flo_23_interact_80241564 = 0x80241564
export const RAM_b_area_omo_medi_guy_MediGuySpriteRotationFunc = 0x802185ac
export const RAM_omo_07_CheckItemFlags40 = 0x80243188
export const RAM_kzn_09_MeleeHitbox_33 = 0x802411d4
export const RAM_b_area_kzn_formation_0D = 0x80229b9c
export const RAM_b_area_mim_bzzap_8021AC20 = 0x8021ac20
export const RAM_D_802EB150 = 0x802eb150
export const RAM_BtlPutPartnerAway = 0x80280a34
export const RAM_D_800B0C44 = 0x800b0c44
export const RAM_func_802597B0 = 0x802597b0
export const RAM_dgb_06_init_802408FC = 0x802408fc
export const RAM_isk_10_SuperBlock_SetOverride40 = 0x80240000
export const RAM_b_area_hos_magikoopa_defenseTable_802214C0 = 0x802214c0
export const RAM_func_80240000_D97890 = 0x80240000
export const RAM_dgb_00_npcSettings_8024066C = 0x8024066c
export const RAM_D_FA000028 = 0xfa000028
export const RAM_b_area_trd_part_2_red_ninja_koopa_nextTurn_8022B484 = 0x8022b484
export const RAM_D_E0070D70 = 0xe0070d70
export const RAM_mac_00_MonitorMusicProximityTrigger = 0x802422c0
export const RAM_gPauseTabsCurrentTab = 0x8024f2e4
export const RAM_sbk_32_SetNpcB5_3 = 0x80240140
export const RAM_b_area_sam2_gray_magikoopa_attackMagicBlast = 0x802206b8
export const RAM_ChooseNextTarget = 0x8026e414
export const RAM_kpa_09_GetEncounterEnemyIsOwner = 0x802412b4
export const RAM_func_80240090_AB6320 = 0x80240090
export const RAM_enemy_create_target_list = 0x80263048
export const RAM_D_80248C6C_83A46C = 0x80248c6c
export const RAM_fx_disable_x = 0x800710f0
export const RAM_D_E007AC18 = 0xe007ac18
export const RAM_mac_00_Quizmo_AddViewRelativeOffset = 0x802412b0
export const RAM_func_80240A6C_8D482C = 0x80240a6c
export const RAM_D_80243898_B7A048 = 0x80243898
export const RAM_sbk_30_GetNpcCollisionHeight = 0x80240ef4
export const RAM_func_802421D0_7ECEE0 = 0x802421d0
export const RAM_D_8024162C_ABC8BC = 0x8024162c
export const RAM_get_generic_entity = 0x8012363c
export const RAM_D_8010CFD8 = 0x8010cfd8
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022F468 = 0x8022f468
export const RAM_nuAuFrameCounter = 0x8007817c
export const RAM_func_80240F44_ACBA64 = 0x80240f44
export const RAM_dgb_03_exitDoubleDoor_80242C3C = 0x80242c3c
export const RAM_func_8004BA54 = 0x8004ba54
export const RAM_D_802A2A30_767650 = 0x802a2a30
export const RAM_SetForegroundModelsVisible = 0x80253aec
export const RAM_D_800A0908 = 0x800a0908
export const RAM_b_area_mac_chan_8021AA1C = 0x8021aa1c
export const RAM_fright_jar_appendGfx = 0xe00c221c
export const RAM_b_area_omo2_3_shy_stack_idleAnimations_shy_guy = 0x80231490
export const RAM_tik_17_SuperBlock_CantUpgradeMessages = 0x80241420
export const RAM_fio_load_game = 0x8002b2d0
export const RAM_b_area_kmr_part_3_sam_01_afterBattle_80233AA8 = 0x80233aa8
export const RAM_b_area_kzn_red_magikoopa_8022CA48 = 0x80221788
export const RAM_trd_00_GetItemName = 0x802403c4
export const RAM_HES_FPCostReducedTwice = 0x80107690
export const RAM_b_area_sam_duplighost_parakarry = 0x802216dc
export const RAM_D_8024F780_C0D000 = 0x8024f780
export const RAM_dup_b_area_kpa2_UnfadeBackgroundToBlack = 0x80218098
export const RAM_battle_item_food_UseItem = 0x802a1934
export const RAM_music_event_trigger = 0x800560bc
export const RAM_entity_HiddenPanel_is_item_on_top = 0x802e6b6c
export const RAM_b_area_trd_part_1_trd_05b_80225460 = 0x80225460
export const RAM_kzn_19_StashVars = 0x80241468
export const RAM_arn_13_settings = 0x80240020
export const RAM_battle_heap_create = 0x8002aca0
export const RAM_D_E0070D78 = 0xe0070d78
export const RAM_b_area_pra3_gray_magikoopa_handleEvent = 0x8022f0e4
export const RAM_b_area_hos_ember_8021CBC8 = 0x8021cbc8
export const RAM_flo_14_npcAISettings_802444D8 = 0x802444d8
export const RAM_omo_10_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_D_802413F8_9EEFE8 = 0x802413f8
export const RAM_b_area_isk_part_1_isk_02 = 0x80223a0c
export const RAM_b_area_omo_spy_guy_hammerAttack = 0x802289b4
export const RAM_sbk_10_SpawnSunEffect = 0x80240000
export const RAM_battle_item_dusty_hammer_model = 0x802a1a20
export const RAM_btl_cam_use_preset_immediately = 0x8024e3d8
export const RAM_end_00_CharAnim_FadeIn_3 = 0x8024081c
export const RAM_entity_SimpleSpring_set_jump_params = 0x802e6118
export const RAM_fx_75_render = 0xe00ea520
export const RAM_action_update_step_up_peach = 0x802b6230
export const RAM_battle_item_strange_cake_script6 = 0x802a2870
export const RAM_b_area_nok_spiked_goomba_takeTurn_8021F1BC = 0x8021f1bc
export const RAM_bgm_set_target_volume = 0x8014ac84
export const RAM_gPauseItemsElements = 0x8024f570
export const RAM_mac_02_Quizmo_SpinPartner = 0x80242640
export const RAM_kpa_95_PatrolNoAttackAI_15 = 0x80240bb4
export const RAM_D_80246D90_C46B40 = 0x80246d90
export const RAM_D_E0116C8C = 0xe0116c8c
export const RAM_iwa_11_UnkFloatFunc = 0x80240020
export const RAM_b_area_mac_lee_watt = 0x80225ac8
export const RAM_EA8AE0_GetNpcCollisionHeight = 0x80241580
export const RAM_mac_03_Quizmo_ShouldQuizmoLeave = 0x80240fec
export const RAM_D_802B679C = 0x802b679c
export const RAM_func_80240494_907EC4 = 0x80240494
export const RAM_BindNextTurn = 0x80278c94
export const RAM_b_area_isk_part_1_pokey_mummy_idle_80219040 = 0x80219040
export const RAM_dgb_13_80240424 = 0x80240424
export const RAM_evt_trigger_on_activate_exec_script = 0x802c667c
export const RAM_mac_00_Quizmo_SpinPlayer = 0x8024144c
export const RAM_spr_get_player_raster = 0x802deea0
export const RAM_arn_05_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_D_8024F328_C0CBA8 = 0x8024f328
export const RAM_func_802437D0_7EE4E0 = 0x802437d0
export const RAM_add_xz_vec3f_copy1 = 0x802641c0
export const RAM_b_area_trd_part_2_trd_05c_80233CF0 = 0x80233cf0
export const RAM_D_8024FEB0_C0D730 = 0x8024feb0
export const RAM_IES_Unused_0E5 = 0x8008c984
export const RAM_D_802941E0 = 0x802941e0
export const RAM_func_8024197C_7EC68C = 0x8024197c
export const RAM_battle_item_strange_cake1_png = 0x802a2170
export const RAM_HES_Item_DDownPound = 0x80084158
export const RAM_b_area_trd_part_1_paratroopa_init_8021EE0C = 0x8021ee0c
export const RAM_kzn_08_PiranhaPlantAI_01 = 0x80242e78
export const RAM_is_actortype_hpbar_visible = 0x80253c9c
export const RAM_filemenu_draw_contents_file_0_title = 0x802456c8
export const RAM_b_area_nok_formation_15 = 0x80219440
export const RAM_b_area_sbk_bandit_handleEvent_8021D7B4 = 0x8021d7b4
export const RAM_D_802BE254_31DDC4 = 0x802be254
export const RAM_spr_render_init = 0x802dda60
export const RAM_func_80053654 = 0x80053654
export const RAM_b_area_sam_gulpit_returnHome = 0x80227fdc
export const RAM_omo_04_UnkNpcPosFunc = 0x80241e40
export const RAM_D_80242230_D97820 = 0x80242230
export const RAM_b_area_omo_formation_2B = 0x802306c0
export const RAM_shop_close_item_select_popup = 0x80280b44
export const RAM_resume_group_script = 0x802c437c
export const RAM_obk_05_RetroJar_AwaitPlayerEntry = 0x80240038
export const RAM_dgb_01_D_80251D40_C0F5C0 = 0x80251d40
export const RAM_flo_08_SuperBlock_ShowSelectPartnerMenu = 0x802401cc
export const RAM_pra_02_reflection_render_floor_fancy = 0x80240870
export const RAM_HES_UnusedDigit3 = 0x801078b4
export const RAM_nuPiReadRom = 0x8005f490
export const RAM_b_area_kmr_part_3_egg_jr_troopa_idleAnimations_80224380 = 0x80224380
export const RAM_battle_item_fire_flower_func_802A1378_716FD8 = 0x802a1378
export const RAM_dgb_12_StashVars = 0x80240000
export const RAM_D_E0076ED0 = 0xe0076ed0
export const RAM_MAMAR_ambient_sounds = 0x80420478
export const RAM_spr_get_cached_quad = 0x802dbd8c
export const RAM_mim_01_PiranhaPlantAI_13 = 0x80241b08
export const RAM_battle_item_dusty_hammer_displayList = 0x802a1a60
export const RAM_D_801097D8 = 0x801097d8
export const RAM_D_09003228 = 0x09003228
export const RAM_func_80241658_BD4EC8 = 0x80241658
export const RAM_omo_09_PatrolAI_Chase = 0x80241898
export const RAM_b_area_jan_medi_guy_flyingAttack = 0x80222ed0
export const RAM_intro_logos_set_fade_color = 0x800337e0
export const RAM_func_802406A8_EB1508 = 0x802406a8
export const RAM_battle_star_peach_dash_UnkBackgroundFunc = 0x802a1414
export const RAM_appendGfx_animator_node = 0x8011fa54
export const RAM_mim_11_UpdateTexturePanSmooth = 0x802402e0
export const RAM_D_8024DFA0_C0B820 = 0x8024dfa0
export const RAM_D_E0088D18 = 0xe0088d18
export const RAM_D_800A0900 = 0x800a0900
export const RAM_b_area_omo_formation_21 = 0x8023035c
export const RAM_au_bgm_dispatch_player_event = 0x8004d7e0
export const RAM_D_802485C0_B34A30 = 0x802485c0
export const RAM_osCreatePiManager = 0x800609e0
export const RAM_b_area_mim_piranha_plant_init_8021D498 = 0x8021d498
export const RAM_func_80240344_EC74F4 = 0x80240344
export const RAM_EDEA00_UnkFunc42 = 0x80243418
export const RAM_func_802B203C = 0x802b203c
export const RAM_dgb_17_exitDoubleDoor_802401B4 = 0x802401b4
export const RAM_b_area_trd_part_1_paratroopa_80220BE8 = 0x80220be8
export const RAM_D_8010CD14 = 0x8010cd14
export const RAM_flo_25_80242704 = 0x80242704
export const RAM_b_area_arn_arn_05 = 0x80227bd4
export const RAM_D_E008CB14 = 0xe008cb14
export const RAM_b_area_nok_paragoomba_8021C574 = 0x8021c574
export const RAM_HES_Goombario = 0x80107cf8
export const RAM_EB0E60_UpdateTexturePanSmooth = 0x80240000
export const RAM_sun_render = 0xe012039c
export const RAM_battle_partner_goombario_802397E0 = 0x802397e0
export const RAM_EF2610_DeadUnkFunc1 = 0x80240000
export const RAM_D_E0126C00 = 0xe0126c00
export const RAM_D_802BCE1C_E2D54C = 0x802bce1c
export const RAM_b_area_mac_lee_defenseTable_80228464 = 0x80228464
export const RAM_EEDF50_FlyingNoAttackAI_13 = 0x802424a4
export const RAM_gPausePanels = 0x8024ef80
export const RAM_hos_20_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_omo_omo_04_beforeBattle_8022DD30 = 0x8022dd30
export const RAM_enable_status_chill_out = 0x80047be4
export const RAM_IES_AttackFXC = 0x8008ca84
export const RAM_b_area_omo_omo_04 = 0x8022de0c
export const RAM_D_802EB158 = 0x802eb158
export const RAM_HES_Item_YellowBerry_disabled = 0x80086a68
export const RAM_kmr_02_Pipe_GetEntryPos = 0x802400f0
export const RAM_flo_23_GetNpcCollisionHeight = 0x80240040
export const RAM_D_802A3124_7399B4 = 0x802a3154
export const RAM_b_area_trd_part_2_fake_bowser_nextTurn_8021F410 = 0x8021f410
export const RAM_func_80240000_892A20 = 0x80240000
export const RAM_flo_14_FlyingAI_LoiterInit = 0x802412e8
export const RAM_dgb_15_unk_missing_80241F5C = 0x80241f5c
export const RAM_D_8010EF9F = 0x8010ef9f
export const RAM_b_area_sam_gray_magikoopa_handleEvent_flying = 0x8022d234
export const RAM_EE65B0_UnkFunc43 = 0x80240340
export const RAM_D_801568F8 = 0x801568f8
export const RAM_D_8024C100 = 0x8024c100
export const RAM_D_80244810_AF1C20 = 0x80244810
export const RAM_D_80242514_D81274 = 0x80242514
export const RAM_SetAnimatedModelRenderMode = 0x802cd348
export const RAM_kzn_08_MeleeHitbox_33 = 0x802426f0
export const RAM_D_80244E18_A1B038 = 0x80244e18
export const RAM_sbk_66_TransformFoliage = 0x802401b0
export const RAM_D_8029D3F8 = 0x8029d3f8
export const RAM_b_area_mim_forest_fuzzy_defenseTable_802183C0 = 0x802183c0
export const RAM_b_area_hos_magikoopa_handleEvent_8022142C = 0x8022142c
export const RAM_gSpeakingActorIdleAnim = 0x8029fa70
export const RAM_BattleCam_CurrentPresetID = 0x80280ce4
export const RAM_b_area_trd_part_3_nok_01_80218DD0 = 0x80218dd0
export const RAM_D_80232C84_684A04 = 0x80232c84
export const RAM_D_800983F8 = 0x800983f8
export const RAM_battle_item_strange_cake_UseItemWithEffect = 0x802a1bb0
export const RAM_jan_04_foliage_setup_shear_mtx = 0x802407a0
export const RAM_D_802BEC7C = 0x802bec7c
export const RAM_enable_status_icon_peril = 0x80048140
export const RAM_hos_01_UnsetCamera0MoveFlag1 = 0x80240dd0
export const RAM_evt_handle_end_thread = 0x802c6d4c
export const RAM_D_8024561C = 0x8024561c
export const RAM_HES_Item_IcePalaceKey = 0x800814e8
export const RAM_sam_06_FlyingAI_Init = 0x80241738
export const RAM_D_80247AF0_EE64B0 = 0x80247af0
export const RAM_func_80268938 = 0x80268938
export const RAM_HES_PeachBeam = 0x80107c50
export const RAM_omo_17_UnkNpcAIFunc48 = 0x8024269c
export const RAM_func_802420B4_9C7494 = 0x802420b4
export const RAM_fio_validate_file_checksum = 0x8002b0f8
export const RAM_D_80219040 = 0x80219040
export const RAM_func_80240730_8AAB80 = 0x80240730
export const RAM_IES_SnowmanScarf = 0x8008ba04
export const RAM_mim_08_PiranhaPlantAI_12 = 0x80242aa0
export const RAM_D_802BCE30 = 0x802bce30
export const RAM_mac_05_PatrolAI_JumpInit = 0x80240bc0
export const RAM_dgb_03_exitDoubleDoor_80242A78 = 0x80242a78
export const RAM_filemenu_update_hidden_options_right = 0x802436f0
export const RAM_kpa_08_MagikoopaAI_CanShootSpell = 0x802400a0
export const RAM_b_area_kgr_fuzzipede_8021B008 = 0x8021b008
export const RAM_flo_12_foliage_setup_shear_mtx = 0x80240040
export const RAM_arn_04_CleftAI_PreAmbush = 0x8024268c
export const RAM_kpa_95_PatrolNoAttackAI_Main = 0x80240c74
export const RAM_flo_19_UpdateTexturePanStepped = 0x80240124
export const RAM_D_800B1B0E = 0x800b1b0e
export const RAM_D_800760B0 = 0x800760b0
export const RAM_b_area_jan2_formation_01 = 0x8021f5cc
export const RAM_D_80109490 = 0x80109490
export const RAM_EA0C10_MeleeHitbox_31 = 0x8024037c
export const RAM_gPauseTutorialCmdMessages = 0x8024f058
export const RAM_func_802D4D14 = 0x802d4d14
export const RAM_gPauseWS_13 = 0x8026fbd8
export const RAM_btl_popup_messages_draw_world_geometry = 0x8024eea8
export const RAM_func_80240020_B028A0 = 0x80240020
export const RAM_b_area_omo2_toy_tank_8021A484 = 0x8021a484
export const RAM_D_8029D3F0 = 0x8029d3f0
export const RAM_dro_02_dropDoor_80244474 = 0x80244474
export const RAM_func_80240E54_B07854 = 0x80240e54
export const RAM_sam_06_FlyingAI_Main = 0x8024185c
export const RAM_arn_07_npcGroup_802460EC = 0x802460ec
export const RAM_b_area_kmr_part_3_mim_01 = 0x802336ac
export const RAM_trd_04_ParatroopaAI_Windup = 0x80243310
export const RAM_D_E0084E1C = 0xe0084e1c
export const RAM_dgb_17_80240060 = 0x80240060
export const RAM_fx_05 = 0x8006fa70
export const RAM_dgb_01_func_80240000_BFD880 = 0x80240000
export const RAM_pra_35_reflection_setup_wall = 0x80240128
export const RAM_b_area_omo_pyro_guy_idleAnimations_8022CB00 = 0x8022cb00
export const RAM_firework_update = 0xe0086278
export const RAM_b_area_sbk_sbk_02_UnkFloatFunc3 = 0x802180d0
export const RAM_vtx_C2BE50 = 0x8020af10
export const RAM_b_area_trd_part_2_trd_05c = 0x80234510
export const RAM_func_800F0CB0 = 0x800f0cb0
export const RAM_D_E0126C08 = 0xe0126c08
export const RAM_flame_appendGfx = 0xe0040348
export const RAM_kpa_91_ToadHouse_PartnerSuspendAbilityScript = 0x8024169c
export const RAM_HES_Item_Mushroom = 0x800866d8
export const RAM_b_area_nok_formation_06 = 0x80218f8c
export const RAM_b_area_mac_mac_01_afterBattle_802328F0 = 0x802328f0
export const RAM_D_E0124C00 = 0xe0124c00
export const RAM_omo_10_UpdateTexturePanSmooth = 0x8024055c
export const RAM_D_80251430_D4BA00 = 0x80251430
export const RAM_func_80240E50_A2B090 = 0x80240e50
export const RAM_fx_firework_rocket = 0x80072530
export const RAM_b_area_kmr_part_2_kmr_03_afterBattle_80224B60 = 0x80224b60
export const RAM_b_area_trd_part_1_formation_17 = 0x80218bb4
export const RAM_arn_05_interact_802418F4 = 0x802418f4
export const RAM_dup_mac_06_UnkFloatFunc001 = 0x8024030c
export const RAM_D_8014F978 = 0x8014f978
export const RAM_D_8014F548 = 0x8014f548
export const RAM_flo_08_FlowerGuard_ItemChoiceList = 0x80246430
export const RAM_func_802440D0_A2E310 = 0x802440d0
export const RAM_D_802440EB = 0x802440eb
export const RAM_D_800B4308 = 0x800b4308
export const RAM_b_area_kmr_part_2_blue_goomba_ArcsinDeg = 0x80218000
export const RAM_dro_02_init_8024D04C = 0x8024d04c
export const RAM_set_script_group = 0x802c4218
export const RAM_func_802619B4 = 0x802619b4
export const RAM_D_80243FE0_D91230 = 0x80243fe0
export const RAM_D_090009F0_3D04E0 = 0x090009f0
export const RAM_au_mseq_set_volume = 0x80050fd0
export const RAM_sam_07_GetEntityPosition = 0x80240510
export const RAM_D_800A3FEE = 0x800a3fee
export const RAM_D_E0068600 = 0xe0068600
export const RAM_HES_Item_PowerJump = 0x80084bd8
export const RAM_b_area_kzn_medi_guy_statusTable_8021F8F0 = 0x8021f8f0
export const RAM_HES_Item_AttackFXB_disabled = 0x80085c28
export const RAM_func_80243D14_9DAD34 = 0x80243d14
export const RAM_fx_shimmer_burst = 0x80070d90
export const RAM_gPauseCursorTargetPosY = 0x8024efac
export const RAM_tik_12_SuperBlock_LoadCurrentPartnerName = 0x80241064
export const RAM_b_area_sam2_paragoomba_idle_8021CBE0 = 0x8021d960
export const RAM_dup_hos_06_StashVars = 0x802410a4
export const RAM_sam_08_SuperBlock_StartGlowEffect = 0x80240480
export const RAM_pra_11_SetPartnerFlags20000 = 0x80240eec
export const RAM_dro_02_Quizmo_SetVannaAnim_Idle = 0x80240df0
export const RAM_ui_input_analog_stick3_pal = 0x800f9840
export const RAM_func_802406F8_AB06E8 = 0x802406f8
export const RAM_arn_10_npcSettings_802404CC = 0x802404cc
export const RAM_gPauseWindowFlipUpAngles = 0x8024efcc
export const RAM_dgb_00_80240698 = 0x80240698
export const RAM_func_802C00AC = 0x802bd1ac
export const RAM_dgb_01_idle_8024598C = 0x8024598c
export const RAM_dro_01_shopInventory_8024B4FC = 0x8024b4fc
export const RAM_D_8014BE00 = 0x8014be00
export const RAM_D_802E97D8 = 0x802e9800
export const RAM_battle_star_peach_focus_802A22BC = 0x802a22bc
export const RAM_b_area_iwa_iwa_02_defenseTable_80221668 = 0x80221668
export const RAM_dro_02_moveWalls_80244390 = 0x80244390
export const RAM_iwa_03_SomeItemEntityFunc = 0x80240118
export const RAM_kkj_01_PatrolAI_PostLoiter = 0x8024067c
export const RAM_battle_item_sleepy_sheep_func_802A1848_71EE98 = 0x802a1848
export const RAM_b_area_jan_medi_guy_UnkBattleFunc1 = 0x80218310
export const RAM_gNpcCount = 0x8009a604
export const RAM_D_80247E30_8B7EA0 = 0x80247e30
export const RAM_b_area_jan_medi_guy_MediGuySpriteRotationFunc = 0x802183dc
export const RAM_sPartParams = 0xe00926a4
export const RAM_hos_03_Quizmo_VannaTEffect = 0x8024bdf8
export const RAM_b_area_omo2_toy_tank_UpdateBulbGlow = 0x80218120
export const RAM_fx_lens_flare = 0x80070250
export const RAM_pra_40_reflection_setup_floor = 0x80240500
export const RAM_omo_04_FlyingAI_LoiterInit = 0x80240b78
export const RAM_battle_item_insecticide_herb_UseItemWithEffect = 0x802a1420
export const RAM_action_update_peach_step_down = 0x802b6508
export const RAM_jan_04_UpdateTexturePanStepped = 0x80240164
export const RAM_collision_heap_malloc = 0x8002adb0
export const RAM_enable_player_input = 0x800e01a4
export const RAM_vtx_C2BF60 = 0x8020b020
export const RAM_battle_star_focus_UnkStarFunc = 0x802a1494
export const RAM_flo_13_FlyingAI_JumpInit = 0x80240da0
export const RAM_HES_MamarDisabled = 0x80107a14
export const RAM_dgb_03_ClubbaPatrolAI_Main = 0x802419b0
export const RAM_D_80244FEC_BD885C = 0x80244fec
export const RAM_func_802405C8_CF8138 = 0x802405c8
export const RAM_b_area_sam_duplighost_idleAnimations = 0x8021ae90
export const RAM_b_area_kmr_part_3_jr_troopa_8022325C = 0x8022325c
export const RAM_HES_Item_AttackFXC_disabled = 0x80085ce8
export const RAM_D_E000CD24 = 0xe000cd24
export const RAM_b_area_sam2_monstar_idle = 0x8021a2d0
export const RAM_fx_sparkles = 0x8006fef0
export const RAM_mac_02_PatrolAI_LoiterInit = 0x80240d30
export const RAM_iwa_04_CleftAI_RevUp = 0x80240714
export const RAM_D_800A094C = 0x800a094c
export const RAM_b_area_mim_forest_fuzzy_takeTurn_8021A61C = 0x8021a61c
export const RAM_D_80257D50_8C7DC0 = 0x80257d50
export const RAM_mac_01_PatrolNoAttackAI_15 = 0x80241b54
export const RAM_nuGfxSetCfb = 0x8005f2f0
export const RAM_func_E00CC9C8 = 0xe00cc9c8
export const RAM_func_80240FF0_D7FD50 = 0x80240ff0
export const RAM_b_area_trd_part_1_trd_05c_80225D78 = 0x80225d78
export const RAM_sam_01_Quizmo_HideEntities = 0x80240818
export const RAM_D_802EB350 = 0x802eb350
export const RAM_flo_17_pad_527C = 0x8024527c
export const RAM_func_80241E04_7ECB14 = 0x80241e04
export const RAM_HES_Item_BakingStrawberry_disabled = 0x80087368
export const RAM_omo_15_StarSpiritEffectFunc6 = 0x80240708
export const RAM_vtx_C2D1A0 = 0x8020c260
export const RAM_mgm_01_BlockPosZ = 0x80241ae0
export const RAM_b_area_kmr_part_3_paragoomba2_ArcsinDeg = 0x802185b8
export const RAM_end_00_CharAnim_FadeOut_3 = 0x802419f8
export const RAM_calc_player_damage_enemy = 0x80271944
export const RAM_evt_handle_get_2_word = 0x802c5cc4
export const RAM_HES_MoveBlueOrb = 0x80109360
export const RAM_nuContWaitMesgQ = 0x800b0eb8
export const RAM_cloud_puff_main = 0xe0014000
export const RAM_D_800D9620 = 0x800d9620
export const RAM_alAudioFrame = 0x800565c0
export const RAM_arn_10_exitSingleDoor_802401A4 = 0x802401a4
export const RAM_b_area_arn_hyper_cleft_handleEvent_80222140 = 0x80222140
export const RAM_dgb_06_D_80240620_C3D6A0 = 0x80240620
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_8022873C = 0x8022873c
export const RAM_D_E0124C08 = 0xe0124c08
export const RAM_D_80109498 = 0x80109498
export const RAM_flo_18_UnkFunc42 = 0x80240530
export const RAM_dgb_16_ClubbaNappingAI_Loiter = 0x80240a68
export const RAM_EA0C10_WanderMeleeAI_Main = 0x8024097c
export const RAM_iwa_01_CleftAI_CanSeePlayer = 0x802422c4
export const RAM_fx_75_main = 0xe00ea000
export const RAM_D_80251C70 = 0x80251c70
export const RAM_func_80242D38_C63A48 = 0x80242d38
export const RAM_flo_24_80241ABC = 0x80241abc
export const RAM_gPauseItemsPages = 0x802704d0
export const RAM_D_801507C0 = 0x801507c0
export const RAM_ShowSweat = 0x802d7e08
export const RAM_func_80266D6C = 0x80266d6c
export const RAM_D_E00328D0 = 0xe00328d0
export const RAM_func_802B74F0 = 0x802b74f0
export const RAM_AddVectorPolar = 0x802d4b14
export const RAM_kpa_03_MagikoopaAI_20 = 0x802416b0
export const RAM_osPfsIsPlug = 0x8006d340
export const RAM_b_area_sbk_sbk_02_foregroundModelList_8021F8F8 = 0x8021f8f8
export const RAM_D_8022ADD0 = 0x8022add0
export const RAM_dro_02_LetterDelivery_Init = 0x80243780
export const RAM_entity_free_static_data = 0x801117dc
export const RAM_b_area_mac_lee_SetBackgroundAlpha = 0x80219780
export const RAM_iwa_02_CleftAI_Disguise = 0x80240be4
export const RAM_omo_06_varStash = 0x802456c0
export const RAM_D_80242110_D999A0 = 0x80242110
export const RAM_D_802DFEB8 = 0x802dfeb8
export const RAM_mac_00_UpdateTexturePanStepped = 0x80240404
export const RAM_dup_b_area_flo_UnkFloatFunc = 0x80219960
export const RAM_gBattleMeshAnimationList = 0x802dadb0
export const RAM_GetHomePos = 0x8026ad74
export const RAM_b_area_hos_formation_01 = 0x80225e7c
export const RAM_entity_GiantChest_open = 0x802e75e4
export const RAM_func_802419A4_D1A184 = 0x802419a4
export const RAM_b_area_sam_gulpit_rocks_setAnim = 0x8022974c
export const RAM_HES_FillGaugeResult = 0x80292bac
export const RAM_intro_logos_update_fade = 0x80033874
export const RAM_b_area_trd_part_1_trd_05_beforeBattle_80224A24 = 0x80224a24
export const RAM_isk_13_HoppingAI_LosePlayer = 0x80240bac
export const RAM_D_802EB5C0 = 0x802eb5c0
export const RAM_func_80240F40_EB9D90 = 0x80240f40
export const RAM_D_E0116D83 = 0xe0116d83
export const RAM_D_802AB7F8 = 0x802ab7f8
export const RAM_battle_move_spin_smash_IsRightOnEquipped = 0x802a113c
export const RAM_D_09002320_3886D0 = 0x09002320
export const RAM_HES_Item_EarthquakeJump = 0x80085658
export const RAM_b_area_mac_lee_ActorJumpToPos = 0x80218b90
export const RAM_D_8010D678 = 0x8010d678
export const RAM_D_E0090AA8 = 0xe0090aa8
export const RAM_set_curtain_scale = 0x8002beec
export const RAM_func_80238C84_704774 = 0x80238c84
export const RAM_dgb_09_MeleeHitbox_33 = 0x802401b4
export const RAM_pra_40_reflection_unk_resolve_anim = 0x80240020
export const RAM_au_sfx_init = 0x8004b440
export const RAM_nuGfxTaskAllEndWait = 0x8005f2d0
export const RAM_btl_update = 0x8023e438
export const RAM_D_80243CD8_B06558 = 0x80243cd8
export const RAM_HES_Item_TastyTonic_disabled = 0x800868e8
export const RAM_dup2_b_area_flo_StartRumbleWithParams = 0x80218e90
export const RAM_D_80242858_8D3D68 = 0x80242858
export const RAM_flo_00_func_802413BC_C9F2DC = 0x802413bc
export const RAM_b_area_kmr_part_2_kmr_05_clouds1 = 0x80225000
export const RAM_radial_shimmer_init = 0xe0066490
export const RAM_battle_item_insecticide_herb_temp = 0x028003c0
export const RAM_D_80241510_D55BA0 = 0x80241510
export const RAM_tik_07_SuperBlock_ShowSelectPartnerMenu = 0x802404ac
export const RAM_arn_08_80241210 = 0x80241210
export const RAM_flo_25_ItemChoice_HasSelectedItem = 0x80242acc
export const RAM_b_area_sam_formation_04 = 0x80230a2c
export const RAM_func_8024EFE0 = 0x8024efe0
export const RAM_pra_02_ReflectFloor = 0x8024049c
export const RAM_b_area_kmr_part_3_sam_01_foregroundModelList_80233AB8 = 0x80233ab8
export const RAM_D_E006CB60 = 0xe006cb60
export const RAM_create_status_chill_out = 0x80047b38
export const RAM_flo_18_80242B18 = 0x80242b18
export const RAM_kmr_30_settings = 0x028001c0
export const RAM_b_area_nok_koopa_troopa_init_8021CB74 = 0x802200f4
export const RAM_npc_raycast_down = 0x800dc500
export const RAM_D_802A3044_0073CF14 = 0x802a3044
export const RAM_kpa_61_FlyingMagikoopaAI_10 = 0x802411c4
export const RAM_lookup_status_duration_mod = 0x80265eec
export const RAM_func_80218FB0_513580 = 0x80218fb0
export const RAM_kmr_02_Quizmo_CreateReactionEffect = 0x80241388
export const RAM_flo_08_SuperBlock_GatherEnergyFX = 0x80240544
export const RAM_nok_13_HoppingAI_HopInit = 0x80240000
export const RAM_SetTimeFreezeMode = 0x802d5830
export const RAM_popup_menu_draw_menu_contents = 0x800f3680
export const RAM_sam_02_UpdateTexturePanStepped = 0x80240404
export const RAM_cold_breath_init = 0xe00de11c
export const RAM_func_80240E58_BC8CB8 = 0x80240e58
export const RAM_func_80218A38_4EFED8 = 0x80218a38
export const RAM_flo_03_defeat_802411B8 = 0x802411b8
export const RAM_sam_02_Quizmo_GetCamVfov = 0x8024170c
export const RAM_dup3_b_area_pra_UnkFunc52 = 0x80218ff8
export const RAM_trd_02_UnkDistFunc = 0x8024015c
export const RAM_D_80243440_8D14B0 = 0x80243440
export const RAM_player_raycast_up_corner = 0x800dea08
export const RAM_func_80240910_B03190 = 0x80240910
export const RAM_func_8024F7C8 = 0x8024f7c8
export const RAM_D_802AB7F0 = 0x802ab7f0
export const RAM_D_09001230 = 0x09001230
export const RAM_func_80268858 = 0x80268858
export const RAM_pra_37_WanderMeleeAI_Main = 0x8024156c
export const RAM_battle_partner_goombario_idleAnimations_80238000 = 0x80238000
export const RAM_D_80246E50 = 0x80246e50
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021C424 = 0x8021c424
export const RAM_pra_34_reflection_render_floor = 0x8024068c
export const RAM_battle_star_peach_beam_SetNpcCollision32 = 0x802a14e8
export const RAM_ui_battle_cmd_gauge_result_pal = 0x8028d5c0
export const RAM_b_area_trd_part_1_paratroopa_802204DC = 0x802204dc
export const RAM_D_80153A49 = 0x80153a49
export const RAM_D_80156958 = 0x80156958
export const RAM_clear_character_set = 0x80123660
export const RAM_D_09001000_3873B0 = 0x09001000
export const RAM_D_8014F58C = 0x8014f58c
export const RAM_CancelMessage = 0x802d0c20
export const RAM_isk_13_HoppingAI_Chase = 0x802408ac
export const RAM_func_800F102C = 0x800f102c
export const RAM_action_command_bomb_MashActionCommandInit = 0x802a9170
export const RAM_GetCurrentActorType = 0x8027dad4
export const RAM_floating_rock_update = 0xe0098170
export const RAM_jan_14_HoppingAI_Loiter = 0x80240ae8
export const RAM_b_area_omo2_3_shy_stack_rotate = 0x80233bbc
export const RAM_dgb_01_npcAISettings_80244D24 = 0x80244d24
export const RAM_b_area_pra3_white_clubba_idleAnimations = 0x8022b130
export const RAM_func_80241DE0_D21C70 = 0x80241de0
export const RAM_CancelablePlayerRunToGoal = 0x80273efc
export const RAM_D_800984B8 = 0x800984b8
export const RAM_au_get_BK_instruments = 0x80054744
export const RAM_kkj_19_GetFloorCollider = 0x80240be4
export const RAM_pra_06_reflection_render_wall = 0x80240320
export const RAM_ui_battle_cont_b_down_png = 0x8028afc0
export const RAM_D_09001DE0_352000 = 0x09001de0
export const RAM_GetPlayerAnimation = 0x802d1e94
export const RAM_dup4_b_area_pra_UnkFunc52 = 0x80219368
export const RAM_D_802448A0_943E50 = 0x802448a0
export const RAM_wPartnerNpc = 0x8010c930
export const RAM_jan_08_SuperBlock_SetOverride40 = 0x80240660
export const RAM_D_80244D24_8F0B44 = 0x80244d24
export const RAM_D_802417D8_909208 = 0x802417d8
export const RAM_flo_09_80241C54 = 0x80241c54
export const RAM_contPakRepairId = 0x8005f8fc
export const RAM_b_area_omo_green_shy_guy_init_8021FF68 = 0x8021ff68
export const RAM_func_802BCC80_E2D3B0 = 0x802bcc80
export const RAM_HES_Whirlwind5 = 0x802aa7a0
export const RAM_func_80240160_8AA5B0 = 0x80240160
export const RAM_dro_01_func_80243084_95E284 = 0x80243084
export const RAM_PlayerRasterCacheSize = 0x802e0c58
export const RAM_D_80248380_kzn_19 = 0x80248380
export const RAM_b_area_pra2_crystal_bit_idle = 0x8022071c
export const RAM_get_current_merlee_message = 0x80045c88
export const RAM_b_area_pra3_duplighost_goombario_runToPlayer = 0x8021c718
export const RAM_b_area_nok_paragoomba_8021CB94 = 0x8021cb94
export const RAM_kpa_91_ToadHouse_UpdateScreenOverlay = 0x80241570
export const RAM_b_area_trd_part_2_trd_05d_80234658 = 0x80234658
export const RAM_evt_handle_get_4_word = 0x802c5db4
export const RAM_recover_render = 0xe0080424
export const RAM_D_802455E8_C76A68 = 0x802455e8
export const RAM_arn_10_npcGroup_802409C8 = 0x802409c8
export const RAM_battle_star_lullaby_802A1F5C = 0x802a1f5c
export const RAM_func_8028236C = 0x8028236c
export const RAM_D_E0086AA0 = 0xe0086aa0
export const RAM_gPausePartnersSpriteIDs = 0x80270660
export const RAM_D_80151288 = 0x80151288
export const RAM_dgb_13_8024014C = 0x8024014c
export const RAM_b_area_kmr_part_3_spiked_goomba_8021A52C = 0x8021a52c
export const RAM_D_8014F970 = 0x8014f970
export const RAM_D_8014F540 = 0x8014f540
export const RAM_D_800984B0 = 0x800984b0
export const RAM_func_802C6E14 = 0x802c6e14
export const RAM_b_area_kmr_part_2_kmr_05 = 0x802253d8
export const RAM_obk_01_GetItemName = 0x80240894
export const RAM_ShopBeginSpeech = 0x80283e80
export const RAM_D_80245613 = 0x80245613
export const RAM_au_bgm_player_init = 0x8004e158
export const RAM_D_E00B07E8 = 0xe00b07e8
export const RAM_msg_printer_set_origin_pos = 0x80125ab8
export const RAM_b_area_jan_spear_guy_takeTurn_8021BCB4 = 0x8021bcb4
export const RAM_ui_status_text_times_png = 0x800ffee0
export const RAM_HES_Muskular = 0x80107a98
export const RAM_D_8024EE18_C0C698 = 0x8024ee18
export const RAM_b_area_pra3_duplighost_init = 0x8021a078
export const RAM_D_80242C6C_A3C14C = 0x80242c6c
export const RAM_D_802EB358 = 0x802eb358
export const RAM_trd_07_FlyingAI_Init = 0x80241ccc
export const RAM_b_area_pra2_sam_04 = 0x802214d4
export const RAM_D_801512DA = 0x801512da
export const RAM_b_area_kmr_part_2_goomba_king_init_80220A38 = 0x80220a38
export const RAM_D_802459C0 = 0x802459c0
export const RAM_dgb_08_interact_80246310 = 0x80246310
export const RAM_func_E008A000 = 0xe008a000
export const RAM_b_area_nok_paragoomba_partsTable_8021B280 = 0x8021b280
export const RAM_timeFreezeMode = 0x8009a5d8
export const RAM_D_E0090AA0 = 0xe0090aa0
export const RAM_dgb_01_PatrolAI_ChaseInit = 0x80242d48
export const RAM_b_area_arn_tubba_blubba_defenseTable_80225C94 = 0x80225c94
export const RAM_create_status_icon_danger = 0x80048170
export const RAM_b_area_sam_duplighost_parakarry_doTakeTurn = 0x80221d54
export const RAM_func_80240000_800880 = 0x80240000
export const RAM_b_area_sbk_formation_0B = 0x8021fc6c
export const RAM_au_sfx_update_main = 0x8004b748
export const RAM_tik_05_SomeItemEntityFunc = 0x80240798
export const RAM_b_area_omo2_6_shy_guy_dup_run_to_pos = 0x80231620
export const RAM_battle_item_volt_shroom_UseItemWithEffect = 0x802a1280
export const RAM_battle_move_power_quake_IsRightOnEquipped = 0x802a1050
export const RAM_arn_03_ItemChoice_SelectedItemID = 0x80241cd0
export const RAM_SetNpcPaletteSwapLower = 0x802cfeec
export const RAM_b_area_trd_part_2_green_ninja_koopa_effectUnk = 0x80236040
export const RAM_b_area_sam2_monstar_Dist3D = 0x80218314
export const RAM_WaitForMessageBoxDone = 0x802513cc
export const RAM_heap_collisionHead = 0x80268000
export const RAM_dgb_01_pad_1 = 0x80251a78
export const RAM_IES_BooRecordOutline = 0x8008b6a4
export const RAM_battle_item_pow_block_func_802A1318_718B68 = 0x802a1318
export const RAM_D_800778AC = 0x800778ac
export const RAM_D_E00E6880 = 0xe00e6880
export const RAM_gActiveSynDriverPtr = 0x80078e50
export const RAM_battle_menu_HammerHudScripts = 0x802ab368
export const RAM_D_80245D37_E0B097 = 0x80245d37
export const RAM_sleep_bubble_main = 0xe0052000
export const RAM_npc_test_move_without_slipping = 0x800dd8fc
export const RAM_fx_water_fountain = 0x80071b10
export const RAM_b_area_omo_pink_shy_guy_handleEvent_8021E628 = 0x8021e628
export const RAM_func_80238668_704158 = 0x80238668
export const RAM_b_area_flo_UnkBattleFunc5 = 0x8021900c
export const RAM_butterflies_render = 0xe00aa3c0
export const RAM_obk_04_varStash = 0x80243a10
export const RAM_dgb_00_init_80241FA4 = 0x80241fa4
export const RAM_b_area_hos_magikoopa_healAll = 0x8021fbec
export const RAM_pra_20_reflection_setup_wall = 0x80240128
export const RAM_evt_get_variable_index = 0x802c7da8
export const RAM_kzn_17_PiranhaPlantAI_14 = 0x80241978
export const RAM_D_090024E0_388890 = 0x090024e0
export const RAM_toy_tank_as_idle = 0x8022c55c
export const RAM_dup2_b_area_dig_UnkBattleFunc1 = 0x80218310
export const RAM_drop_leaves_appendGfx = 0xe002852c
export const RAM_SetCamType = 0x802cbab4
export const RAM_sbk_34_foliage_setup_shear_mtx = 0x802407a0
export const RAM_sbk_34_TransformFoliage = 0x80240810
export const RAM_dgb_06_settings = 0x802402e0
export const RAM_kkj_03_UpdateTexturePanStepped = 0x80240144
export const RAM_b_area_kgr_kgr_01_intTable_8021CD64 = 0x8021cd64
export const RAM_EDEA00_FlyingAI_Init = 0x802413ac
export const RAM_b_area_kmr_part_2_kmr_02_afterBattle_80224720 = 0x80224720
export const RAM_b_area_iwa_iwa_01b_foregroundModelList_80221554 = 0x80221554
export const RAM_get_animator_node_for_tree_index = 0x8011ff74
export const RAM_ED0310_FlyingAI_Init = 0x80241508
export const RAM_dgb_07_ClubbaNappingAI_Main = 0x80240e20
export const RAM_sam_02_Quizmo_CreateWorker = 0x80241b90
export const RAM_D_80247CB4 = 0x80247cb4
export const RAM_D_802AD63E = 0x802ad63e
export const RAM_b_area_sam_pos_rocks_4 = 0x80230724
export const RAM_D_80150A50 = 0x80150a50
export const RAM_battle_item_coconut_EatItem = 0x802a170c
export const RAM_b_area_kzn_kzn_05_80228F0C = 0x80228f0c
export const RAM_tik_07_SuperBlock_PartnerSparkles2 = 0x80240f58
export const RAM_omo_05_FlyingAI_WanderInit = 0x80240a54
export const RAM_jan_08_SuperBlock_CountEligiblePartners = 0x80240780
export const RAM_HES_Item_FireFlower_disabled = 0x80086108
export const RAM_D_802DFEB0 = 0x802dfeb0
export const RAM_D_8009A680 = 0x8009a680
export const RAM_b_area_sam_frost_piranha_UnkBattleFunc1 = 0x802195f0
export const RAM_D_80245AD8_A002C8 = 0x80245ad8
export const RAM_func_80242074_D3C644 = 0x80242074
export const RAM_vtx_C2E7B0 = 0x8020d870
export const RAM_nok_01_LetterDelivery_CalcLetterPos = 0x80242240
export const RAM_entity_BlueWarpPipe_idle = 0x802e8adc
export const RAM_dro_01_npcSettings_80245134 = 0x80245134
export const RAM_battle_item_food_AddFP = 0x802a154c
export const RAM_mim_08_FlyingAI_ChaseInit = 0x80240d60
export const RAM_sbk_56_SuperBlock_UpgradeOrbAngles = 0x80241488
export const RAM_GetActorSize = 0x8027d8e0
export const RAM_dgb_01_D_8025286C_C100EC = 0x8025286c
export const RAM_ui_partner11_png = 0x800ffca0
export const RAM_obk_05_RockingChair_UpdatePhysics = 0x80240050
export const RAM_b_area_sam_gray_magikoopa_parts_flying = 0x8022c4a4
export const RAM_D_8007F048 = 0x8007f048
export const RAM_D_E007EC70 = 0xe007ec70
export const RAM_gMainGameState = 0x80151700
export const RAM_gHudElements = 0x80157960
export const RAM_kpa_11_TackleAI_Main = 0x802406a4
export const RAM_pra_02_GetNpcCollisionHeight = 0x80240f20
export const RAM_dgb_18_idle_80241654 = 0x80241654
export const RAM_Entity_SavePoint = 0x802e9a18
export const RAM_b_area_kmr_part_3_egg_jr_troopa_802245E8 = 0x802245e8
export const RAM_pra_22_SetGameStatusUnk84_1 = 0x80240000
export const RAM_isk_10_SuperBlock_EndGlowEffect = 0x80240510
export const RAM_battle_item_thunder_rage_PlayerGoHome = 0x802a18d0
export const RAM_flo_25_foliage_setup_shear_mtx = 0x80241ff0
export const RAM_b_area_omo_medi_guy_takeTurn_80220DCC = 0x8022494c
export const RAM_b_area_dgb_clubba_idleAnimations_80218000 = 0x80218000
export const RAM_dup3_b_area_kpa2_ItemEntityJumpToPos = 0x80219d44
export const RAM_gWorldEntityList = 0x80151380
export const RAM_AssignScript = 0x80111fb0
export const RAM_func_802620F8 = 0x802620f8
export const RAM_kmr_09_FlyingAI_ChaseInit = 0x80240cc0
export const RAM_delete_entity = 0x801106d0
export const RAM_sam_01_UpdateTexturePanSmooth = 0x80240000
export const RAM_ui_status_coin_7_pal = 0x80100fe0
export const RAM_b_area_trd_part_2_fake_bowser_8021C9A4 = 0x8021c9a4
export const RAM_battle_item_hustle_drink_UseItemWithEffect = 0x802a12e0
export const RAM_draw_ci_image_with_clipping = 0x8012e9a0
export const RAM_b_area_trd_part_1_koopa_troopa_takeTurn_8021DD9C = 0x8021dd9c
export const RAM_HasKeyItem = 0x802d699c
export const RAM_hos_01_SwitchToPartner = 0x80240cb0
export const RAM_b_area_mac_lee_handleEvent_80228614 = 0x80228614
export const RAM_b_area_sam_gulpit = 0x80227f58
export const RAM_func_8024486C_A2EAAC = 0x8024486c
export const RAM_D_800B0AD0 = 0x800b0ad0
export const RAM_dgb_01_802434A0 = 0x802434a0
export const RAM_pra_09_ReflectFloor = 0x8024049c
export const RAM_battle_star_peach_focus_802A1530 = 0x802a1530
export const RAM_b_area_iwa_whacka_defenseTable_8021FC9C = 0x8021fc9c
export const RAM_b_area_jan_spear_guy_defenseTable_80218684 = 0x80218684
export const RAM_D_E0088D10 = 0xe0088d10
export const RAM_HES_Item_KootPhoto = 0x80082178
export const RAM_func_8024003C_B1CA8C = 0x8024003c
export const RAM_ascii_EC2030 = 0x802491e0
export const RAM_kmr_10_TransformFoliage = 0x80240440
export const RAM_b_area_kmr_part_3_egg_jr_troopa_init_80224A94 = 0x80224a94
export const RAM_b_area_trd_part_2_green_ninja_koopa_StartRumbleWithParams = 0x802183e0
export const RAM_ui_battle_cmd_timing_3_chances_png = 0x8028cfa0
export const RAM_dgb_16_MeleeHitbox_31 = 0x8024006c
export const RAM_fx_65_render = 0xe00ca484
export const RAM_func_802BAE00_E2B530 = 0x802bae00
export const RAM_omo_17_ShyGuyWanderAI_14 = 0x80240560
export const RAM_b_area_arn_hyper_cleft_80221D6C = 0x80221d6c
export const RAM_battle_item_fright_jar_main = 0x802a191c
export const RAM_func_80244984_805204 = 0x80244984
export const RAM_nok_12_TransformFoliage = 0x802403a0
export const RAM_D_E000CCE0 = 0xe000cce0
export const RAM_b_area_pra3_red_magikoopa_8022C12C = 0x8022c12c
export const RAM_kzn_18_UpdateTexturePanStepped = 0x80240164
export const RAM_kzn_07_FlyingAI_Jump = 0x80241474
export const RAM_ending_decals_render = 0xe00681d8
export const RAM_vtx_C298E0 = 0x802089a0
export const RAM_evt_handle_bind_lock = 0x802c6b54
export const RAM_flo_16_SuperBlock_UpgradeOrbAngles = 0x80243758
export const RAM_kpa_03_func_80240814_97BE44 = 0x80240934
export const RAM_D_802EB060 = 0x802eb060
export const RAM_func_80240714_AD47A4 = 0x80240714
export const RAM_HES_Item_Berserker_disabled = 0x80084968
export const RAM_dgb_02_MeleeHitbox_30 = 0x80240000
export const RAM_pra_29_reflection_setup_wall = 0x80240128
export const RAM_func_8025AA80 = 0x8025aa80
export const RAM_D_802BDF34 = 0x802bdf34
export const RAM_D_E00BCE50 = 0xe00bce50
export const RAM_D_80245AB4 = 0x80245ab4
export const RAM_b_area_sam2_sam_02b_UnkModelStuffScript4 = 0x80222834
export const RAM_jan_05_SpearGuyAI_LoiterInit = 0x802416b8
export const RAM_bss_802BFEF4 = 0x802bfef4
export const RAM_b_area_arn_formation_0F = 0x802280cc
export const RAM_snowfall_appendGfx = 0xe008a728
export const RAM_b_area_nok_fuzzy_partsTable_80222108 = 0x80222108
export const RAM_virtual_entity_list_render_UI = 0x802d31e0
export const RAM_D_802434C0_A63E90 = 0x802434c0
export const RAM_ui_battle_cmd_timing_ready_png = 0x8028cd60
export const RAM_ui_status_pow_unit_4_png = 0x801007c0
export const RAM_arn_09_pad_398 = 0x80240398
export const RAM_arn_03_80241E18 = 0x80241e18
export const RAM_guScaleF = 0x800677c0
export const RAM_func_80218490_557870 = 0x80218490
export const RAM_iwa_10_SuperBlock_WhiteScreenFlash = 0x80240e28
export const RAM_arn_07_FlyingAI_Main = 0x80241c5c
export const RAM_D_8023D278 = 0x8023d278
export const RAM_D_8024F9F0_B445C0 = 0x8024f9f0
export const RAM_b_area_kzn_red_magikoopa_8022C138 = 0x80220e78
export const RAM_vtx_C2B3E0 = 0x8020a4a0
export const RAM_vtx_C27F20 = 0x80206fe0
export const RAM_b_area_pra2_crystal_bit_handleEvent = 0x802207c8
export const RAM_vtx_C2C0B0 = 0x8020b170
export const RAM_func_800E6904 = 0x800e6904
export const RAM_WattUpdate = 0x802bd2b4
export const RAM_vtx_C28BB0 = 0x80207c70
export const RAM_D_802F39D0 = 0x802f39d0
export const RAM_D_800A08C0 = 0x800a08c0
export const RAM_b_area_sam_duplighost_goombario_parts = 0x8021cbf8
export const RAM_D_8023D270 = 0x8023d270
export const RAM_guRotateRPY = 0x80064458
export const RAM_IES_CrystalBerry = 0x8008b884
export const RAM_b_area_sam2_sam_01_UnkFloatFunc2 = 0x80218d9c
export const RAM_nok_12_UnkDistFunc = 0x802405ec
export const RAM_b_area_trd_part_1_paratroopa_8021F26C = 0x8021f26c
export const RAM_D_8024F581 = 0x8024f581
export const RAM_bActorTattles = 0x80282b98
export const RAM_func_802413A4_D59C34 = 0x802413a4
export const RAM_b_area_omo_formation_22 = 0x802303b0
export const RAM_D_80242FB0 = 0x80242fb0
export const RAM_gPauseStatsStarElements = 0x8024f450
export const RAM_dgb_05_ClubbaNappingAI_ReturnHomeInit = 0x80240c00
export const RAM_kzn_17_PiranhaPlantAI_Main = 0x80241a64
export const RAM_DoPoisonHit = 0x80294634
export const RAM_b_area_arn_hyper_cleft_partsTable_80220C14 = 0x80220c14
export const RAM_D_802416AC_B992AC = 0x802416ac
export const RAM_func_8027D4C8 = 0x8027d4c8
export const RAM_SetMessageMsg = 0x802d0cec
export const RAM_flo_13_802438F8 = 0x802438f8
export const RAM_b_area_sam_sam_02c_UnkModelStuffScript4 = 0x8022fd64
export const RAM_BowTakeOut = 0x802bd130
export const RAM_evt_handle_case_greater = 0x802c5268
export const RAM_D_80247270_C7DF50 = 0x80247270
export const RAM_arn_07_extraAnimationList_80245200 = 0x80245200
export const RAM_b_area_iwa_monty_mole_idleAnimations_8021A59C = 0x8021a59c
export const RAM_D_802A9762_42A482 = 0x802a9762
export const RAM_D_E00D6F08 = 0xe00d6f08
export const RAM_HES_Item_SleepySheep_disabled = 0x80087608
export const RAM_mdl_nextTextureAddress = 0x80153368
export const RAM_b_area_trd_part_2_trd_05b_afterBattle_80233CB8 = 0x80233cb8
export const RAM_siAccessBuf = 0x800af900
export const RAM_guLookAtHiliteF = 0x800628c0
export const RAM_dgb_05_func_80240030_C3AA40 = 0x80240030
export const RAM_GetModelCenter = 0x802c8f80
export const RAM_dgb_08_8024490C = 0x8024490c
export const RAM_D_80249BFC = 0x80249bfc
export const RAM_kmr_07_StationaryAI_AlertInit = 0x802401d4
export const RAM_b_area_hos_ember_UnkSfxFunc = 0x80218020
export const RAM_nuGfxMesgBuf = 0x800ac6b0
export const RAM_EA8AE0_LetterDelivery_Init = 0x802418d4
export const RAM_D_8024BFC8_C09848 = 0x8024bfc8
export const RAM_arn_05_enterWalk_80241530 = 0x80241530
export const RAM_mac_02_Quizmo_varStash = 0x80246150
export const RAM_pause_stats_cleanup = 0x80246f94
export const RAM_D_802AD67B = 0x802ad67b
export const RAM_dup2_b_area_tik_ArcsinDeg = 0x80218758
export const RAM_dgb_01_D_80252CB8_C10538 = 0x80252cb8
export const RAM_ui_battle_status_stop_3_pal = 0x80102980
export const RAM_set_global_flag = 0x80145450
export const RAM_D_802A1BEC_73E44C = 0x802a1bec
export const RAM_D_802A1C20_7384B0 = 0x802a1c50
export const RAM_flo_11_npcGroupList_802430D4 = 0x802430d4
export const RAM_ui_move_basic_png = 0x801038a0
export const RAM_kmr_03_npcGroup_80241260 = 0x80241260
export const RAM_D_E00685BC = 0xe00685bc
export const RAM_pra_16_SetGameStatusUnk84_1 = 0x80240000
export const RAM_jan_11_UnkPosFunc2 = 0x80240438
export const RAM_D_09003880_3AE8B0 = 0x09003880
export const RAM_GetActorPos = 0x8026aaa8
export const RAM_jan_08_UpdateTexturePanSmooth = 0x80240000
export const RAM_gSpeakingActorPrintIsDone = 0x8029fa68
export const RAM_D_80150F88 = 0x80150f88
export const RAM_func_80242858_9C7C38 = 0x80242858
export const RAM_PATCH_au_load_song_files = 0x8040041c
export const RAM_HES_AimMarkerH = 0x80293144
export const RAM_kpa_111_ProjectileHitbox_31 = 0x802405a4
export const RAM_D_802EB068 = 0x802eb068
export const RAM_mac_00_Pipe_GetEntryPos = 0x802400f0
export const RAM_func_802A15B0_78A410 = 0x802a15b0
export const RAM_b_area_omo_spy_guy_80228778 = 0x80228778
export const RAM_func_802B2078 = 0x802b2078
export const RAM_flo_12_80240750 = 0x80240750
export const RAM_func_80242394_8B2404 = 0x80242394
export const RAM_jan_12_PullVine_apply_shear_mtx = 0x80240170
export const RAM_D_E00B07E0 = 0xe00b07e0
export const RAM_flo_17_LakituAI_Wander = 0x802415b0
export const RAM_D_E0024D00 = 0xe0024d00
export const RAM_D_802D9E00 = 0x802d9e00
export const RAM_GetActorFlags = 0x8026cf20
export const RAM_isk_07_SetEntityFlags100000 = 0x802400b0
export const RAM_HES_StarBeamDisabled = 0x80107c24
export const RAM_D_802BCC68_E2F598 = 0x802bcc68
export const RAM_nok_02_Quizmo_HideEntities = 0x80241a28
export const RAM_battle_move_multibounce_D_802A1B9C = 0x802a1b9c
export const RAM_func_802666E4 = 0x802666e4
export const RAM_battle_item_tasty_tonic_UseItemWithEffect = 0x802a12e0
export const RAM_battle_star_focus_usePower = 0x802a24e8
export const RAM_D_802A34EC_73FD4C = 0x802a34ec
export const RAM_b_area_arn_tubba_blubba_80226558 = 0x80226558
export const RAM_entity_ItemBlock_setupGfx = 0x802e455c
export const RAM_D_80256AEC_81736C = 0x80256aec
export const RAM_D_E00BEB00 = 0xe00beb00
export const RAM_tik_03_Pipe_GetEntryPos = 0x802400f0
export const RAM_dro_02_npcSettings_8024752C = 0x8024752c
export const RAM_flo_16_SuperBlock_OnHit = 0x802438c8
export const RAM_HES_Happy = 0x80108aac
export const RAM_HES_Item_AttackFXA = 0x800843f8
export const RAM_HES_UnusedCompass_disabled = 0x80241e78
export const RAM_omo_02_StationaryAI_Alert = 0x80241110
export const RAM_D_80150F80 = 0x80150f80
export const RAM_sam_02_ToadHouse_PartnerResumeAbilityScript = 0x802409d0
export const RAM_vtx_C26EB0 = 0x80205f70
export const RAM_flo_23_FlowerGuard_JudgeItemTastiness = 0x80240c2c
export const RAM_D_802471E0_C67EF0 = 0x802471e0
export const RAM_iwa_10_UnkFunc41 = 0x80241030
export const RAM_D_80243880_A4BE40 = 0x80243880
export const RAM_nuGfxRetraceWait = 0x80060370
export const RAM_b_area_kmr_part_3_kmr_02 = 0x80232300
export const RAM_omo_07_PatrolAI_Move = 0x802406c4
export const RAM_jan_03_LetterDelivery_RestoreNpcAnim = 0x80242ef0
export const RAM_dgb_08_npcAI_80244D7C = 0x80244d7c
export const RAM_pra_13_SetPartnerFlags20000 = 0x80240eec
export const RAM_flo_09_FlyingAI_Chase = 0x80241114
export const RAM_dgb_05_pad_18BC = 0x802418bc
export const RAM_D_80242AD0_D52AC0 = 0x80242ad0
export const RAM_dgb_15_PatrolAI_PostLoiter = 0x80240f4c
export const RAM_flo_16_FlyingNoAttackAI_20 = 0x802426f8
export const RAM_spiky_white_aura_update = 0xe0038424
export const RAM_flo_13_LakituAI_Main = 0x80241db8
export const RAM_b_area_nok_formation_07 = 0x80218fe0
export const RAM_D_8024D2B8_C0AB38 = 0x8024d2b8
export const RAM_player_check_collision_below = 0x800e3514
export const RAM_b_area_omo_omo_04_partsTable_8022DFEC = 0x8022dfec
export const RAM_world_bombette_test_first_strike = 0x802be520
export const RAM_arn_03_npcGroupList_80244788 = 0x80244788
export const RAM_b_area_isk_part_1_formation_09 = 0x80224388
export const RAM_dro_02_Quizmo_ShouldQuizmoLeave = 0x80240e3c
export const RAM_func_800F0C9C = 0x800f0c9c
export const RAM_func_80240040_ABB2D0 = 0x80240040
export const RAM_IES_Coconut = 0x8008da54
export const RAM_D_8010C97A = 0x8010c97a
export const RAM_mac_04_Quizmo_VannaTEffect = 0x8024f5c8
export const RAM_remove_item_entity_by_index = 0x801339fc
export const RAM_D_8024EC68_C0C4E8 = 0x8024ec68
export const RAM_fx_attack_result_text = 0x80071390
export const RAM_b_area_omo_sky_guy_8022B150 = 0x8022b150
export const RAM_mim_02_AddPlayerHandsOffset = 0x80240ee0
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E67C = 0x8022e67c
export const RAM_spr_init_quad_cache = 0x802dbd40
export const RAM_b_area_trd_part_1_trd_03_clouds2 = 0x80223c78
export const RAM_end_01_CharAnim_FadeOut_1 = 0x802414dc
export const RAM_mgm_01_MsgImgs_Panels = 0x80243560
export const RAM_kzn_11_FlyingAI_Init = 0x80241648
export const RAM_dro_01_8024DD78 = 0x8024dd78
export const RAM_jan_06_foliage_setup_shear_mtx = 0x80240500
export const RAM_D_802B7BA0_E23150 = 0x802b7ba0
export const RAM_NAMESPACE_80239D08 = 0x80239d08
export const RAM_sam_01_Quizmo_ShouldQuizmoLeave = 0x8024123c
export const RAM_dgb_01_dgb_00_name_hack = 0x80263508
export const RAM_func_A40004C0 = 0xa40004c0
export const RAM_mac_01_Quizmo_SetVannaAnim_Wave = 0x80242a34
export const RAM_mdl_textureBaseAddress = 0x8014b748
export const RAM_npc_remove_decoration_glow_in_front = 0x8003cb20
export const RAM_HES_Item_RuinsStoneStar = 0x80081668
export const RAM_dgb_01_init_80245814 = 0x80245814
export const RAM_func_80253B30 = 0x80253b30
export const RAM_b_area_iwa_buzzar = 0x8021b7bc
export const RAM_water_block_appendGfx = 0xe00b457c
export const RAM_fio_write_flash = 0x8002b948
export const RAM_func_80133A94 = 0x80133a94
export const RAM_pra_09_ReflectPartner = 0x80240d3c
export const RAM_IsPlayerOnValidFloor = 0x802d249c
export const RAM_gUIStatus = 0x8010ef58
export const RAM_use_consumable = 0x800ea400
export const RAM_dro_01_EVS_Quizmo_MovePlayerToPodium = 0x80245630
export const RAM_PlaySoundAt = 0x802d61dc
export const RAM_func_80240614_EDC5F4 = 0x80240614
export const RAM_trd_10_StarSpiritEffectFunc2 = 0x8024004c
export const RAM_npc_update_decoration_glow_in_front = 0x8003c9a8
export const RAM_b_area_mim_bzzap_idle_8021AB10 = 0x8021ab10
export const RAM_fx_light_rays = 0x80070c70
export const RAM_func_80032970 = 0x80032970
export const RAM_kpa_50_UnkDistFunc = 0x802412c8
export const RAM_b_area_sam2_monstar_UnkBackgroundFunc3 = 0x80218458
export const RAM_b_area_pra3_duplighost_kooper_takeTurn = 0x8021e3f4
export const RAM_func_802429A0_EEFAA0 = 0x802429a0
export const RAM_pra_39_reflection_setup_wall = 0x80240158
export const RAM_tik_08_GetFloorCollider = 0x80240a30
export const RAM_flo_13_npcGroup_80246A04 = 0x80246a04
export const RAM_D_802BEBA0_31B110 = 0x802beba0
export const RAM_func_80241310_D59BA0 = 0x80241310
export const RAM_dro_02_EVS_Quizmo_PlayerReaction_WrongAnswer = 0x802462a4
export const RAM_battle_item_mushroom_func_802A12EC_715B3C = 0x802a12ec
export const RAM_D_80240970_8AADC0 = 0x80240970
export const RAM_func_802A11CC_74F2FC = 0x802a11cc
export const RAM_func_802BB314_E2DC44 = 0x802bb314
export const RAM_dro_01_pad_D_8024DFDC = 0x8024dfdc
export const RAM_func_800EA5B8 = 0x800ea5b8
export const RAM_pra_39_UnkFunc001 = 0x80240f50
export const RAM_obk_02_UnkAngleFunc004 = 0x8024000c
export const RAM_arn_03_ConsumableChoiceList = 0x80244a20
export const RAM_D_800F803A = 0x800f803a
export const RAM_D_E00C0C18 = 0xe00c0c18
export const RAM_dead_decrement_status_menu_disabled = 0x800f16f4
export const RAM_D_8024292C_BD151C = 0x8024292c
export const RAM_btl_state_update_partner_striking_first = 0x80248190
export const RAM_D_A4300008 = 0xa4300008
export const RAM_pra_39_ReflectFloor = 0x802404cc
export const RAM_shim_atan2 = 0xe0200520
export const RAM_gMoveTable = 0x8008f060
export const RAM_kpa_102_FlyingMagikoopaAI_17 = 0x80242abc
export const RAM_b_area_sam_gray_magikoopa_UnkBattleFunc1 = 0x802199c4
export const RAM_D_80248A98_82BD58 = 0x80248a98
export const RAM_battle_item_life_shroom_func_802A1484_72E9B4 = 0x802a1484
export const RAM_pra_40_reflection_unk_change_anim_facing = 0x80240054
export const RAM_vtx_C2CBA0 = 0x8020bc60
export const RAM_D_80220754 = 0x80220754
export const RAM_action_command_fire_shell_free_hud_elements = 0x802a9914
export const RAM_b_area_hos_hos_01_afterBattle_80225598 = 0x80225598
export const RAM_evt_handle_if_not_equal = 0x802c4ca0
export const RAM_b_area_kmr_part_3_spiked_goomba_8021BF1C = 0x8021bf1c
export const RAM_arn_07_npcGroup_80245D0C = 0x80245d0c
export const RAM_flo_08_shakeTree = 0x802452a0
export const RAM_func_802D0C94 = 0x802d0c94
export const RAM_ending_decals_init = 0xe0068140
export const RAM_b_area_iwa_buzzar_StartRumbleWithParams = 0x802180c0
export const RAM_HES_Item_JumpCharge0 = 0x80084518
export const RAM_func_80244308_804B88 = 0x80244308
export const RAM_b_area_pra3_pra_04 = 0x8023246c
export const RAM_b_area_kmr_part_2_goomba_king_80221680 = 0x80221680
export const RAM_b_area_isk_part_2_tutankoopa_8021E008 = 0x8021e008
export const RAM_func_802413D0_BCFFC0 = 0x802413d0
export const RAM_func_80218344_660A94 = 0x80218344
export const RAM_func_802A57C8 = 0x802a57c8
export const RAM_func_80244774_A2E9B4 = 0x80244774
export const RAM_trd_04_PatrolAI_LoiterInit = 0x80241084
export const RAM_pra_02_SetEntityFlags100000 = 0x80241588
export const RAM_battle_move_d_down_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_D_E00E0A44 = 0xe00e0a44
export const RAM_b_area_kmr_part_1_kmr_04_afterBattle_80220650 = 0x80220650
export const RAM_func_E0200044 = 0xe0200044
export const RAM_flo_16_SuperBlock_AnimateEnergyOrbs = 0x802406e0
export const RAM_snd_ambient_80055848 = 0x80055848
export const RAM_b_area_pra2_crystal_king_AttackIceBolt = 0x8021bdbc
export const RAM_flo_19_entryList = 0x80240940
export const RAM_func_802423C8_DB4688 = 0x802423c8
export const RAM_D_80241EA0_8D5C60 = 0x80241ea0
export const RAM_b_area_omo_formation_1E = 0x80230260
export const RAM_gBattleNpcList = 0x800a0a90
export const RAM_dgb_01_D_802529CC_C1024C = 0x802529cc
export const RAM_D_8014B870 = 0x8014b870
export const RAM_DoScareAway = 0x8029b22c
export const RAM_entity_breakable_block_create_shattering_entity = 0x802e2fd0
export const RAM_D_802491C0 = 0x802491c0
export const RAM_b_area_kzn_white_magikoopa_ShrinkActor = 0x802189a0
export const RAM_sam_08_SuperBlock_LoadCurrentPartnerName = 0x80240448
export const RAM_ui_eldstar_disabled_pal = 0x800fd120
export const RAM_kpa_91_ToadHouse_PartnerResumeAbilityScript = 0x802416c8
export const RAM_func_802A2AB8 = 0x802a2ab8
export const RAM_func_80218A98_4E31D8 = 0x80218a98
export const RAM_mac_04_Quizmo_Answers = 0x8024404c
export const RAM_draw_encounters_neutral = 0x8004135c
export const RAM_nok_02_Quizmo_SetStageLightsDelay = 0x8024234c
export const RAM_D_80245698_C76B18 = 0x80245698
export const RAM_D_802348F0_6BBAF0 = 0x802348f0
export const RAM_HES_Item_SpecialShake = 0x80082d78
export const RAM_func_800EA4B0 = 0x800ea4b0
export const RAM_b_area_mac_master2 = 0x8022b7b4
export const RAM_ui_coin_sparkle_5_png = 0x800fbdc0
export const RAM_kzn_09_SuperBlock_WhiteScreenFlash = 0x80240b38
export const RAM_kmr_11_StationaryAI_ReturnHome = 0x8024095c
export const RAM_D_802A1204_007347A4 = 0x802a1204
export const RAM_b_area_trd_part_3_eldstar_idleAnimations_80218280 = 0x80218280
export const RAM_hos_02_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_omo_sky_guy_idleAnimations_8022B104 = 0x8022b104
export const RAM_gameMode = 0x800a08f0
export const RAM_trd_04_UnkDistFunc = 0x8024052c
export const RAM_func_802404F0_BD3D60 = 0x802404f0
export const RAM_battle_move_auto_multibounce_D_802A2EC0 = 0x802a2ec0
export const RAM_b_area_nok_goomba_ArcsinDeg = 0x80218000
export const RAM_SetPlayerPositionFromSaveData = 0x80283908
export const RAM_nok_03_HoppingAI_HopInit = 0x80240000
export const RAM_btl_state_update_switch_to_partner = 0x80242f00
export const RAM_b_area_kmr_part_3_para_jr_troopa_UnkBattleFunc1 = 0x80218840
export const RAM_dgb_08_MeleeHitbox_30 = 0x80240e90
export const RAM_D_8010D670 = 0x8010d670
export const RAM_IES_SmashCharge0 = 0x8008c1e4
export const RAM_isk_05_HoppingAI_LoiterInit = 0x80240568
export const RAM_dup_mac_00_GetItemName = 0x802434b4
export const RAM_b_area_sam_duplighost_sushie_init = 0x80224938
export const RAM_func_80238BCC_6F1CAC = 0x80238bcc
export const RAM_HES_Item_Mistake = 0x80082e98
export const RAM_GetDemoState = 0x802d9a90
export const RAM_IES_Unused_0D7 = 0x8008c7c4
export const RAM_func_802184C0_47F570 = 0x802184c0
export const RAM_walking_dust_render = 0xe000e244
export const RAM_battle_move_jump_charge_0_IsJumpMaxCharged = 0x802a10e4
export const RAM_flo_13_SpinyAI_Main = 0x80242a6c
export const RAM_enable_background_wave = 0x8014720c
export const RAM_func_8024ECF8 = 0x8024ecf8
export const RAM_ui_battle_status_sleep_2_pal = 0x80101e40
export const RAM_b_area_hos_ember_idleAnimations_8021AE5C = 0x8021ae5c
export const RAM_D_8024560F = 0x8024560f
export const RAM_D_E0076E90 = 0xe0076e90
export const RAM_DoNpcDefeat = 0x8004462c
export const RAM_jan_03_Quizmo_Worker = 0x8024fed8
export const RAM_b_area_trd_part_1_formation_01 = 0x802184ac
export const RAM_kzn_04_SuperBlock_LoadCurrentPartnerName = 0x80240758
export const RAM_b_area_jan2_jan_02_beforeBattle_8021F2C0 = 0x8021f2c0
export const RAM_D_E0020CB8 = 0xe0020cb8
export const RAM_D_8024BDC8_C09648 = 0x8024bdc8
export const RAM_func_802B20B4 = 0x802b20b4
export const RAM_b_area_pra3_formation_08 = 0x802326e4
export const RAM_kmr_04_StashVars = 0x802402c8
export const RAM_flo_08_UnkFunc43 = 0x80241e40
export const RAM_D_8014EE18 = 0x8014ee18
export const RAM_D_8014EA48 = 0x8014ea48
export const RAM_sam_01_Quizmo_CreateStage = 0x80240e70
export const RAM_omo_05_ShyGuyWanderAI_15 = 0x80240070
export const RAM_EF8010_TransformFoliage = 0x80240600
export const RAM_func_8023ED5C = 0x8023ed5c
export const RAM_battle_star_time_out_802A22AC = 0x802a22ac
export const RAM_func_8013EE48 = 0x8013ee48
export const RAM_HES_MoveBlueOrbDisabled = 0x80109400
export const RAM_b_area_pra3_red_magikoopa_8022C408 = 0x8022c408
export const RAM_D_8007FEB8 = 0x8007feb8
export const RAM_dup_mac_01_PatrolAI_Loiter = 0x80245a10
export const RAM_dup2_b_area_pra_UnkFunc52 = 0x80218c88
export const RAM_b_area_omo_omo_04_init_8022E060 = 0x8022e060
export const RAM_b_area_trd_part_2_fake_bowser_8021C67C = 0x8021c67c
export const RAM_b_area_pra3_duplighost_parakarry_statusTable = 0x80220668
export const RAM_func_80240000_BBB380 = 0x80240000
export const RAM_kpa_95_UnkDistFunc = 0x80240fec
export const RAM_HES_TimingMiss = 0x80292b80
export const RAM_D_80099BF0 = 0x80099bf0
export const RAM_b_area_isk_part_1_stone_chomp_statusTable_80221558 = 0x80221558
export const RAM_dup2_b_area_flo_UnkBattleFunc1 = 0x802186e4
export const RAM_D_80251798_C0F018 = 0x80251798
export const RAM_ui_battle_cmd_aim_shimmer_2_png = 0x8028ebc0
export const RAM_D_80240B00_B14870 = 0x80240b00
export const RAM_kmr_06_FlyingAI_LosePlayer = 0x80240e58
export const RAM_D_802435EC_A3CACC = 0x802435ec
export const RAM_D_8024F030_C0C8B0 = 0x8024f030
export const RAM_jan_03_ToadHouse_ShowStatusMenu = 0x802414d0
export const RAM_D_800A0974 = 0x800a0974
export const RAM_b_area_mac_master3_8023059C = 0x8023059c
export const RAM_b_area_kmr_part_3_paragoomba2_init_8021B3E8 = 0x8021fa28
export const RAM_b_area_iwa_iwa_01_vector3D_802208D8 = 0x802208d8
export const RAM_kmr_02_StationaryAI_LosePlayer = 0x8024191c
export const RAM_D_802451AC_DD1E7C = 0x802451ac
export const RAM_jan_00_UpdateTexturePanSmooth = 0x80240000
export const RAM_RemoveNpc = 0x80044340
export const RAM_func_802414BC_D65A9C = 0x802414bc
export const RAM_D_09003680 = 0x09003680
export const RAM_gPauseItemsCurrentPage = 0x802704c8
export const RAM_b_area_sbk_pokey_8021AB44 = 0x8021ab44
export const RAM_GetDoorPtr = 0x80282868
export const RAM_HES_Item_MagicalSeed2 = 0x80081938
export const RAM_D_E0044E60 = 0xe0044e60
export const RAM_battle_item_strange_cake3_pal = 0x802a27b0
export const RAM_vtx_C2B050 = 0x8020a110
export const RAM_b_area_mim_piranha_plant_idle_8021D4E4 = 0x8021d4e4
export const RAM_D_80257F29 = 0x80257f29
export const RAM_fx_ending_decals = 0x80070c10
export const RAM_D_80224D64 = 0x80224d64
export const RAM_ui_star_beam_pal = 0x800fe0c0
export const RAM_b_area_iwa_iwa_02_80222130 = 0x80222130
export const RAM_b_area_nok_fuzzy_defenseTable_80222050 = 0x80222050
export const RAM_pra_02_reflection_render_wall = 0x802402f0
export const RAM_vtx_C26FB0 = 0x80206070
export const RAM_D_80234850_6BBA50 = 0x80234850
export const RAM_dgb_02_entryList = 0x80241430
export const RAM_mac_04_Quizmo_Worker = 0x8024f5a0
export const RAM_D_802BCB90_E314E0 = 0x802bcb90
export const RAM_stop_watch_render = 0xe00c42c4
export const RAM_dro_01_EVS_Quizmo_MoveQuizmoToMicrophone = 0x80245918
export const RAM_b_area_kzn_lava_bubble = 0x80218f9c
export const RAM_b_area_sam2_sam_01 = 0x802221d4
export const RAM_D_E010AF80 = 0xe010af80
export const RAM_arn_11_idle_802406CC = 0x802406cc
export const RAM_ui_super_hammer_png = 0x800faa60
export const RAM_tik_14_UnkPosFunc2 = 0x802405f0
export const RAM_mim_06_PiranhaPlantAI_14 = 0x80241bd8
export const RAM_ui_battle_cmd_aim_shimmer_6_pal = 0x8028eec0
export const RAM_nok_02_GetNpcCollisionHeight = 0x802429a4
export const RAM_b_area_kzn_white_magikoopa_statusTable_80223BE4 = 0x80223be4
export const RAM_D_80242344 = 0x80242344
export const RAM_HES_LabelBpNeeded = 0x80242750
export const RAM_D_8024F474 = 0x8024f474
export const RAM_func_80240240_BC2CD0 = 0x80240240
export const RAM_func_8003D660 = 0x8003d660
export const RAM_D_E00328B0 = 0xe00328b0
export const RAM_IES_Unused_030 = 0x8008d5b4
export const RAM_pause_update_tab_default = 0x80242f90
export const RAM_D_8029F642 = 0x8029f642
export const RAM_dgb_07_ClubbaNappingAI_FallAsleep = 0x80240dc4
export const RAM_D_80240DE0_900450 = 0x80240de0
export const RAM_battle_star_up_and_away_usePower = 0x802a2588
export const RAM_small_gold_sparkle_main = 0xe0092000
export const RAM_gPauseMsg_43 = 0x8026f6f8
export const RAM_vtx_C2A540 = 0x80209600
export const RAM_b_area_sam2_sam_02b_foregroundModelList = 0x8022295c
export const RAM_func_802406C4_AF8014 = 0x802406c4
export const RAM_isk_16_StarSpiritEffectFunc4 = 0x802405bc
export const RAM_dgb_03_ClubbaNappingAI_WakeUp = 0x80241fbc
export const RAM_dead_sfx_play_sound_with_params = 0x80157118
export const RAM_D_802549D0 = 0x802549d0
export const RAM_kzn_11_GetFloorCollider = 0x80240408
export const RAM_func_8025D290 = 0x8025d290
export const RAM_entity_fog_dist_max = 0x80154390
export const RAM_b_area_kzn_kzn_01b_afterBattle_8022785C = 0x8022785c
export const RAM_D_800DC4F0 = 0x800dc4f0
export const RAM_sam_11_Quizmo_AnswerResult = 0x80251468
export const RAM_arn_05_func_802412B0_BE8E90 = 0x802412b0
export const RAM_bgm_set_battle_song = 0x8014af8c
export const RAM_dgb_08_main = 0x80243fac
export const RAM_flo_21_entryList = 0x80240cf0
export const RAM_tik_21_Pipe_GetCameraYaw = 0x802404dc
export const RAM_osViGetNextFramebuffer = 0x800669f0
export const RAM_pause_update_page_active_1 = 0x80243238
export const RAM_dro_01_Quizmo_SetVannaAnim_Idle = 0x80241f60
export const RAM_b_area_omo2_general_guy_takeTurn = 0x80218ba0
export const RAM_b_area_trd_part_1_formation_09 = 0x80218730
export const RAM_AuSynUseStereo = 0x80078181
export const RAM_D_8029FB78 = 0x8029fb78
export const RAM_arn_03_BuildKeyItemChoiceList = 0x80241680
export const RAM_mim_08_PiranhaPlantAI_00 = 0x80241e68
export const RAM_D_802A4020 = 0x802a4020
export const RAM_au_sfx_stop_by_id = 0x8004c2a4
export const RAM_b_area_jan_jungle_fuzzy_vector3D_80221800 = 0x80221800
export const RAM_flo_00_init_80243B7C = 0x80243b7c
export const RAM_close_status_menu = 0x800e97b8
export const RAM_b_area_nok_paratroopa_StartRumbleWithParams = 0x80218a20
export const RAM_D_80253384 = 0x80253384
export const RAM_D_80294FE4 = 0x80294fe4
export const RAM_b_area_mac_master2_takeTurn_8022CF48 = 0x8022cf48
export const RAM_D_80249CF0_C07570 = 0x80249cf0
export const RAM_evt_get_float_variable = 0x802c842c
export const RAM_D_802C04F4 = 0x802c04f4
export const RAM_b_area_nok_nok_04_afterBattle_8022BBB0 = 0x8022bbb0
export const RAM_b_area_isk_part_1_formation_01 = 0x80224108
export const RAM_battle_item_dusty_hammer_EatItem = 0x802a170c
export const RAM_iwa_00_varStash = 0x80240ff8
export const RAM_sqrtf = 0x80061ef0
export const RAM_got_item_outline_main = 0xe0036000
export const RAM_dgb_01_D_80252B00_C10380 = 0x80252b00
export const RAM_hos_02_FlyingAI_Loiter = 0x80241888
export const RAM_D_80097D68 = 0x80097d68
export const RAM_b_area_omo2_toy_tank_shake_tank = 0x8022d5b8
export const RAM_sam_11_Quizmo_StageEffect = 0x80251470
export const RAM_pause_get_total_equipped_bp_cost = 0x802447f4
export const RAM_b_area_trd_part_1_bullet_bill_80219390 = 0x80219390
export const RAM_b_area_pra2_crystal_bit_nextTurn = 0x802211d0
export const RAM_arn_04_npcGroup_80243A30 = 0x80243a30
export const RAM_arn_11_exitSingleDoor_80240428 = 0x80240428
export const RAM_b_area_pra2_crystal_bit_parts_3 = 0x802205fc
export const RAM_btl_cam_unfreeze = 0x8024e600
export const RAM_state_drawUI_enter_world = 0x800360fc
export const RAM_sbk_30_StationaryAI_ChaseInit = 0x8024061c
export const RAM_tik_19_SuperBlock_StartGlowEffect = 0x80240a70
export const RAM_dup2_b_area_tik3_UnkFunc56 = 0x80218c50
export const RAM_D_80154387 = 0x80154387
export const RAM_D_E0038A38 = 0xe0038a38
export const RAM_battle_move_auto_jump_D_802A10F0 = 0x802a10f0
export const RAM_arn_04_makeEntities = 0x80244040
export const RAM_green_impact_init = 0xe006419c
export const RAM_b_area_omo_yellow_shy_guy_UnkBattleFunc1 = 0x802181a0
export const RAM_D_80242D00_B20460 = 0x80242d00
export const RAM_osr_00_UnkFunc26 = 0x802404a4
export const RAM_D_E008CB68 = 0xe008cb68
export const RAM_kpa_52_ProjectileHitbox_31 = 0x80240514
export const RAM_b_area_sam_sam_02d_UnkFloatFunc2 = 0x8021aa4c
export const RAM_b_area_trd_part_2_blue_ninja_koopa = 0x8022b588
export const RAM_pra_35_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_battle_item_strange_cake_DrinkItem = 0x802a20f4
export const RAM_snowflake_init = 0xe001c120
export const RAM_btl_init_menu_boots = 0x802634b8
export const RAM_mac_05_Quizmo_CreateStage = 0x80241a90
export const RAM_entity_init_HammerBlock_small = 0x802e3e30
export const RAM_D_800A363C = 0x800a363c
export const RAM_b_area_kmr_part_1_paragoomba_takeTurn_8021D6E8 = 0x8021f0d8
export const RAM_D_80244798_B97BD8 = 0x80244798
export const RAM_tik_09_GetDefeatedEnemyCount = 0x80241204
export const RAM_arn_03_PatrolAI_JumpInit = 0x80240730
export const RAM_b_area_sam_frost_piranha_statusTable = 0x8022ab18
export const RAM_D_802425E4_A4ABA4 = 0x802425e4
export const RAM_D_802B6ED4 = 0x802b6ed4
export const RAM_D_80156920 = 0x80156920
export const RAM_falling_leaves_render = 0xe0058490
export const RAM_b_area_sam_sam_02b_afterBattle = 0x8022fb2c
export const RAM_HES_Item_BooRecord = 0x80081728
export const RAM_stars_orbiting_init = 0xe005e1b0
export const RAM_flo_21_80240E3C = 0x80240e3c
export const RAM_HES_UnusedBadge = 0x80241e00
export const RAM_EnterSavePoint = 0x80285cb0
export const RAM_flo_08_FlowerGuard_JudgeItemTastiness = 0x80241b5c
export const RAM_mac_04_Quizmo_SetVannaAnim_Clap = 0x80240f68
export const RAM_b_area_trd_part_1_trd_00_afterBattle_80224BAC = 0x80224bac
export const RAM_D_802BCFD8_E2D708 = 0x802bcfd8
export const RAM_D_8024DC18_C0B498 = 0x8024dc18
export const RAM_flo_13_set_script_owner_npc_anim = 0x80242298
export const RAM_D_800A4270 = 0x800a4270
export const RAM_D_80242BA0_A0E890 = 0x80242ba0
export const RAM_func_E0112330 = 0xe0112330
export const RAM_HeartBlockPrinterClosed = 0x802eb3c0
export const RAM_D_80244FA0_8F0DC0 = 0x80244fa0
export const RAM_osPfsRepairId = 0x80069460
export const RAM_omo_07_PatrolAI_Jump = 0x80240d10
export const RAM_tik_12_SuperBlock_GatherEnergyFX = 0x80241160
export const RAM_b_area_kmr_part_3_paragoomba1 = 0x8021c3b0
export const RAM_Entity_RedSwitch = 0x802e9bb0
export const RAM_flo_09_FlyingAI_LosePlayer = 0x80241178
export const RAM_flo_00_init_8024414C = 0x8024414c
export const RAM_func_80240624_9D7644 = 0x80240624
export const RAM_func_80240158_D36448 = 0x80240158
export const RAM_D_B0000000 = 0xb0000000
export const RAM_battle_star_lullaby_UnkStarFunc3 = 0x802a116c
export const RAM_b_area_mac_lee_nextTurn_80229658 = 0x80229658
export const RAM_kpa_102_ProjectileAI_Main = 0x8024086c
export const RAM_sam_05_WanderMeleeAI_Main = 0x8024061c
export const RAM_dro_02_ItemChoice_WaitForSelection = 0x802432c0
export const RAM_D_8024C560_C09DE0 = 0x8024c560
export const RAM_nok_01_Quizmo_SetCamVfov = 0x802418d4
export const RAM_battle_move_hammer_charge_1_UnkMoveFunc2 = 0x802a11e8
export const RAM_b_area_kmr_part_3_para_jr_troopa_handleEvent_802266B0 = 0x802266b0
export const RAM_clear_generic_entity_list = 0x801230b8
export const RAM_build_custom_gfx = 0x8011bcec
export const RAM_b_area_mim_piranha_plant_8021D4F4 = 0x8021d4f4
export const RAM_b_area_omo_omo_06_8022F630 = 0x8022f630
export const RAM_HES_Item_Unused_0D7 = 0x80085478
export const RAM_pause_spirits_draw_contents = 0x8024ca00
export const RAM_state_drawUI_file_select = 0x800355cc
export const RAM_D_E00789D0 = 0xe00789d0
export const RAM_jan_12_MeleeHitbox_Main = 0x80240a90
export const RAM_D_80294320 = 0x80294320
export const RAM_sfx_adjust_env_sound_params = 0x80149be4
export const RAM_D_802AB734 = 0x802ab734
export const RAM_func_800E6860 = 0x800e6860
export const RAM_vtx_C2D5C0 = 0x8020c680
export const RAM_snowman_doll_init = 0xe00c0158
export const RAM_arn_08_entryList = 0x80240290
export const RAM_dispatch_damage_event_partner_0 = 0x8027fb80
export const RAM_iwa_01_ProjectileHitbox_GetUsableProjectileID = 0x80240520
export const RAM_bgm_get_map_default_variation = 0x8014a430
export const RAM_isk_18_func_80240814_97BE44 = 0x802407d4
export const RAM_D_B0000008 = 0xb0000008
export const RAM_ai_suspend_for_time = 0x8004a6f0
export const RAM_sam_02_Quizmo_SetCamVfov = 0x80241698
export const RAM_hide_foreground_models = 0x80267cb4
export const RAM_b_area_trd_part_1_formation_0A = 0x80218784
export const RAM_func_802413CC_A019CC = 0x802413cc
export const RAM_b_area_sam_frost_piranha = 0x8022abe8
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuff_func1 = 0x80219a40
export const RAM_b_area_sam2_gray_magikoopa_knockOff = 0x8021fa58
export const RAM_D_09000200_3CFCF0 = 0x09000200
export const RAM_kzn_10_UnkFunc47 = 0x80240414
export const RAM_b_area_flo_ShrinkActor = 0x80218560
export const RAM_b_area_nok_nok_02_foregroundModelList_8022BADC = 0x8022badc
export const RAM_sam_01_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_kzn_putrid_piranha_takeTurn_8021B8A8 = 0x8021b8a8
export const RAM_b_area_sam2_gray_magikoopa_idle = 0x8021f424
export const RAM_omo_09_PatrolAI_PostLoiter = 0x802415c8
export const RAM_D_09003780 = 0x09003780
export const RAM_isk_10_SuperBlock_SwitchToPartner = 0x8024041c
export const RAM_arn_08_80241200 = 0x80241200
export const RAM_b_area_omo_groove_guy_80224CAC = 0x80224cac
export const RAM_sbk_35_TransformFoliage = 0x802401b0
export const RAM_blast_main = 0xe007c000
export const RAM_get_item_empty_count = 0x800e7620
export const RAM_b_area_omo_medi_guy_healOneAlly = 0x80224578
export const RAM_nok_12_SetCamera0Flag1000 = 0x802402f0
export const RAM_HES_Misstar = 0x80107af0
export const RAM_flo_19_exitWalk_80240B9C = 0x80240b9c
export const RAM_IES_ChillOut = 0x8008c3a4
export const RAM_D_80246D98_C46B48 = 0x80246d98
export const RAM_dgb_01_80243EF0 = 0x80243ef0
export const RAM_D_800A3FF0 = 0x800a3ff0
export const RAM_func_8024045C_7E77FC = 0x8024045c
export const RAM_contPakFileOpen = 0x8005f7b0
export const RAM_dgb_01_D_80251C90_C0F510 = 0x80251c90
export const RAM_dup_mac_00_LetterDelivery_Init = 0x8024386c
export const RAM_pause_map_update = 0x8024ecb0
export const RAM_EnableModel = 0x802c9288
export const RAM_D_E00A4AF0 = 0xe00a4af0
export const RAM_vtx_C22620 = 0x802016e0
export const RAM_b_area_nok_fuzzy_UnkBattleFunc2 = 0x802188b0
export const RAM_func_802BC2BC_E30C0C = 0x802bc2bc
export const RAM_D_802466B8_EA6FB8 = 0x802466b8
export const RAM_b_area_kzn_kzn_04_80227DE0 = 0x80227de0
export const RAM_D_802B7580_E22B30 = 0x802b7580
export const RAM_func_E0082A84 = 0xe0082a84
export const RAM_get_script_by_id = 0x802c47a8
export const RAM_flo_14_80244F70 = 0x80244f70
export const RAM_pra_35_PatrolAI_LoiterInit = 0x80241320
export const RAM_D_80244CF0_A0BBF0 = 0x80244cf0
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022C2A4 = 0x8022c2a4
export const RAM_HES_StatusDigit8 = 0x801081f8
export const RAM_D_80242430_97DA60 = 0x80242430
export const RAM_omo_15_StarSpiritEffectFunc5 = 0x802405fc
export const RAM_vtx_C26550 = 0x80205610
export const RAM_HES_StatusSPEmptyIncrement = 0x801083b0
export const RAM_sbk_51_SpawnSunEffect = 0x80240000
export const RAM_b_area_kzn_white_magikoopa_UnkBattleFunc1 = 0x80218b24
export const RAM_HES_Item_Boots3 = 0x80080790
export const RAM_D_80263480_C20D00 = 0x80263480
export const RAM_dro_02_interact_8024BBD0 = 0x8024bbd0
export const RAM_trd_04_ParatroopaAI_Dive = 0x80243438
export const RAM_ui_input_analog_stick_right_pal = 0x800f9620
export const RAM_star_main = 0xe001e000
export const RAM_dead_npc_test_move_simple_with_slipping = 0x800e5304
export const RAM_D_80150A20 = 0x80150a20
export const RAM_flo_17_settings = 0x80243240
export const RAM_kkj_16_UnkModelMatrixFunc = 0x80240270
export const RAM_dro_02_init_8024BD28 = 0x8024bd28
export const RAM_kzn_07_FlyingAI_Main = 0x80241b2c
export const RAM_ui_battle_cont_c_right_down_pal = 0x8028bea0
export const RAM_gWorldMeshAnimationList = 0x802dadf0
export const RAM_b_area_kzn_kzn_02_beforeBattle_80227BDC = 0x80227bdc
export const RAM_b_area_sam_duplighost_bow_parts = 0x80222620
export const RAM_static_status_appendGfx = 0xe00e6524
export const RAM_b_area_kzn_kzn_04b_beforeBattle_8022869C = 0x8022869c
export const RAM_npc_test_move_taller_with_slipping = 0x800ddae4
export const RAM_D_8010CFC4 = 0x8010cfc4
export const RAM_D_80249AA4_A33CE4 = 0x80249aa4
export const RAM_EnterDoubleDoor = 0x80285e74
export const RAM_b_area_kmr_part_2_red_goomba_statusTable_8021B6CC = 0x8021b6cc
export const RAM_flo_00_interact_8024352C = 0x8024352c
export const RAM_b_area_isk_part_1_buzzy_beetle = 0x8021e5b8
export const RAM_b_area_sam_duplighost_SetBackgroundAlpha = 0x80219480
export const RAM_dup2_b_area_flo2_UnkFloatFunc = 0x80219310
export const RAM_battle_menu_DoNothingHudScripts = 0x802ab480
export const RAM_D_80076038 = 0x80076038
export const RAM_D_80097D60 = 0x80097d60
export const RAM_hos_03_Quizmo_ShouldQuizmoLeave = 0x80242fe0
export const RAM_b_area_mac_master2_doubleStrike2 = 0x8022c5d0
export const RAM_arn_07_extraAnimationList_80245208 = 0x80245208
export const RAM_D_8024F9F8_B445C8 = 0x8024f9f8
export const RAM_iwa_02_CleftAI_HidingInit = 0x80240160
export const RAM_IES_ShrinkStomp = 0x8008c804
export const RAM_sbk_23_SpawnSunEffect = 0x80240000
export const RAM_tik_12_SuperBlock_StartGlowEffect = 0x8024109c
export const RAM_GetAnimatedPositionByTreeIndex = 0x802cd7d8
export const RAM_vtx_C24CD0 = 0x80203d90
export const RAM_b_area_omo_sky_guy_8022B7EC = 0x8022b7ec
export const RAM_func_802BBE70_E307C0 = 0x802bbe70
export const RAM_vtx_C28550 = 0x80207610
export const RAM_D_E00947B0 = 0xe00947b0
export const RAM_nuContDataLockKey = 0x8009a5e0
export const RAM_vtx_C24600 = 0x802036c0
export const RAM_HES_Item_FirstAttack_disabled = 0x80083c48
export const RAM_hos_03_FlyingAI_Jump = 0x80241904
export const RAM_world_watt_update = 0x802be27c
export const RAM_HES_Item_Unused_08F = 0x80082478
export const RAM_b_area_nok_goomba_sinTable = 0x8021a2d4
export const RAM_start_rumble = 0x80028f08
export const RAM_b_area_iwa_formation_0C = 0x80218548
export const RAM_D_80243284_A2A154 = 0x80243284
export const RAM_D_F0000008 = 0xf0000008
export const RAM_pra_37_SetPartnerFlags20000 = 0x80240f1c
export const RAM_D_E00685F4 = 0xe00685f4
export const RAM_D_80245B30_A99270 = 0x80245b30
export const RAM_omo_05_FlyingAI_Wander = 0x80240c04
export const RAM_D_E00947B8 = 0xe00947b8
export const RAM_func_80241C9C_8EDABC = 0x80241c9c
export const RAM_D_8029FB70 = 0x8029fb70
export const RAM_flo_08_80245914 = 0x80245914
export const RAM_dgb_09_SentinelAI_Chase = 0x80242a74
export const RAM_b_area_kmr_part_3_kmr_06_afterBattle_802333FC = 0x802333fc
export const RAM_gSpinHistoryPosZ = 0x8010ef40
export const RAM_crash_screen_draw_glyph = 0x8002c054
export const RAM_disable_player_input = 0x800e0178
export const RAM_nok_11_TackleAI_Main = 0x80240394
export const RAM_battle_item_insecticide_herb_modelCommandList = 0x802a1d30
export const RAM_pause_tabs_draw_stats_805418 = 0x80244b98
export const RAM_nok_03_HoppingAI_LoiterInit = 0x80240568
export const RAM_D_80078E5C = 0x80078e5c
export const RAM_b_area_kmr_part_1_paragoomba_partsTable_8021B280 = 0x8021cc70
export const RAM_setup_demo_player = 0x80072d2c
export const RAM_nok_01_Quizmo_GetCamVfov = 0x80241948
export const RAM_dup_mac_00_LetterDelivery_RestoreNpcAnim = 0x80243b2c
export const RAM_HES_BowDisabled = 0x80107e60
export const RAM_nuContDataOpen = 0x80060634
export const RAM_gather_energy_pink_init = 0xe0026184
export const RAM_flo_09_pad_2874 = 0x80242874
export const RAM_SetDoorState = 0x80282338
export const RAM_shim_set_screen_overlay_params_back = 0xe02005c0
export const RAM_jan_03_Quizmo_GetItemName = 0x80241c50
export const RAM_arn_08_main = 0x802408f4
export const RAM_D_E0044E68 = 0xe0044e68
export const RAM_D_80270634 = 0x80270634
export const RAM_kpa_91_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_mim_05_MeleeHitbox_33 = 0x80240224
export const RAM_sam_02_Quizmo_Worker = 0x8024b990
export const RAM_au_BGMCmd_F5_TrackVoice = 0x80050020
export const RAM_entity_SimpleSpring_init = 0x802e619c
export const RAM_flo_18_settings = 0x802407f0
export const RAM_D_80150A28 = 0x80150a28
export const RAM_pra_20_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_flo_18_80244E2C = 0x80244e2c
export const RAM_D_09000170_3305D0 = 0x09000170
export const RAM_fx_star_outline = 0x80072a70
export const RAM_battle_menu_moveScrollOffset = 0x802ad110
export const RAM_b_area_omo2_general_guy_parts = 0x802183e8
export const RAM_b_area_sbk_pokey_80219EB4 = 0x80219eb4
export const RAM_SetPartSize = 0x8027d970
export const RAM_b_area_omo_spy_guy_partsTable_80227564 = 0x80227564
export const RAM_b_area_isk_part_1_buzzy_beetle_handleEvent_8021ECD0 = 0x8021ecd0
export const RAM_kzn_19_StarSpiritEffectFunc3 = 0x802404b8
export const RAM_spiky_white_aura_init = 0xe003841c
export const RAM_omo_08_SetPlayerStatusPosYaw = 0x802401ec
export const RAM_ui_peach_beam_png = 0x800fe100
export const RAM_HES_Item_FrozenFries = 0x80083558
export const RAM_b_area_mac_lee_partsTable_8022069C = 0x8022069c
export const RAM_func_80053888 = 0x80053888
export const RAM_func_80240BE8_B45358 = 0x80240be8
export const RAM_D_80152A20 = 0x80152a20
export const RAM_GetNpcYaw = 0x802cea10
export const RAM_EA8AE0_varStash = 0x80243d74
export const RAM_omo_02_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_b_area_tik3_UpdateTexturePanSmooth = 0x80219540
export const RAM_kmr_03_802406C0 = 0x802406c0
export const RAM_b_area_isk_part_1_formation_0A = 0x802243c0
export const RAM_flo_08_npcGroup_802449D8 = 0x802449d8
export const RAM_func_80241FA0_8337A0 = 0x80241fa0
export const RAM_mgm_02_TakeCoinCost = 0x80242340
export const RAM_kmr_03_802407A8 = 0x802407a8
export const RAM_b_area_isk_part_2_tutankoopa_vector3D_8021E9A0 = 0x8021e9a0
export const RAM_pra_36_reflection_setup_floor = 0x80240530
export const RAM_b_area_arn_hyper_paragoomba_sinTable = 0x8021fd00
export const RAM_HES_StatusStar3 = 0x80108428
export const RAM_D_8023CD68_6F5E48 = 0x8023cd68
export const RAM_flo_07_exitWalk_80240C18 = 0x80240c18
export const RAM_au_BGMCmd_F1_TrackTremoloSpeed = 0x8004ffe4
export const RAM_b_area_sam_sam_03_UnkModelStuffScript1 = 0x80230270
export const RAM_b_area_jan2_white_magikoopa_healAll = 0x8021d1cc
export const RAM_flo_03_tree1_Trunk = 0x80244dec
export const RAM_entity_SaveBlock_wait_for_close_result = 0x802e1328
export const RAM_func_8013A4D0 = 0x8013a4d0
export const RAM_GetActorPartOpacity = 0x802181e8
export const RAM_vtx_C2D6E0 = 0x8020c7a0
export const RAM_status_menu_start_blinking_starpoints = 0x800e9ae4
export const RAM_battle_star_peach_beam_802A33A8 = 0x802a33a8
export const RAM_D_802433B4_A195D4 = 0x802433b4
export const RAM_D_80151258 = 0x80151258
export const RAM_sbk_30_UnkFunc27 = 0x80240040
export const RAM_func_800E4AD8 = 0x800e4ad8
export const RAM_step_mesh_animator = 0x801206f8
export const RAM_D_802A9994_429CB4 = 0x802a9994
export const RAM_D_802A9950_427320 = 0x802a9950
export const RAM_HES_Item_SJumpChg = 0x80084098
export const RAM_D_800B0AEC = 0x800b0aec
export const RAM_D_800DC4E4 = 0x800dc4e4
export const RAM_b_area_sam_sam_02b_UnkModelStuffScript3 = 0x8022f934
export const RAM_sbk_13_SetNpcB5_3 = 0x80240140
export const RAM_func_802381EC_707E8C = 0x802381ec
export const RAM_battle_star_peach_beam_D_802A3514_7FCCC4 = 0x802a3514
export const RAM_nok_01_LetterDelivery_SavedNpcAnim = 0x80251e34
export const RAM_dgb_09_exitSingleDoor_80243A88 = 0x80243a88
export const RAM_D_80294420 = 0x80294420
export const RAM_dgb_04_SuperBlock_SetOverride40 = 0x802423d0
export const RAM_battle_star_peach_beam_UnkStarFunc1 = 0x802a1000
export const RAM_flo_23_80241448 = 0x80241448
export const RAM_mac_05_PatrolAI_Move = 0x802405e8
export const RAM_kpa_91_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_nuAuPreNMIFunc = 0x80078174
export const RAM_rand_float = 0x80029934
export const RAM_D_8024625C_B4A9CC = 0x8024625c
export const RAM_sweat_update = 0xe00501d8
export const RAM_kmr_20_WaitForPlayerToLand = 0x802415ec
export const RAM_btl_draw_menu_wheel = 0x802a1c54
export const RAM_sbk_00_makeEntities = 0x80240790
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuffScript3 = 0x802337f4
export const RAM_jan_12_TransformFoliage = 0x802402f0
export const RAM_D_8024E570 = 0x8024e570
export const RAM_D_800746E0 = 0x800746e0
export const RAM_sbk_30_StationaryAI_Idle = 0x80240424
export const RAM_func_80266F60 = 0x80266f60
export const RAM_func_80240000_A93160 = 0x80240000
export const RAM_b_area_trd_part_1_trd_05f_802275E8 = 0x802275e8
export const RAM_D_80242480_8A7F00 = 0x80242480
export const RAM_arn_05_80241360 = 0x80241360
export const RAM_walking_dust_update = 0xe000e174
export const RAM_D_09000BA8 = 0x09000ba8
export const RAM_D_800B0B20 = 0x800b0b20
export const RAM_D_8024F650_C0CED0 = 0x8024f650
export const RAM_b_area_jan_jungle_fuzzy_divide = 0x8021f260
export const RAM_IES_BlueKey = 0x8008ba44
export const RAM_b_area_sbk_formation_05 = 0x8021fa90
export const RAM_hos_03_TransformFoliage = 0x80240ae0
export const RAM_btl_state_draw_normal_start = 0x80241ab8
export const RAM_b_area_omo2_2_stilt_guy_statusTable2 = 0x8023124c
export const RAM_jan_02_SwitchToPartner = 0x802426a8
export const RAM_dgb_01_D_80252DC0_C10640 = 0x80252dc0
export const RAM_update_camera_mode_5 = 0x80030000
export const RAM_jan_14_HoppingAI_HopInit = 0x802404c0
export const RAM_arn_07_FlyingAI_Init = 0x80241b38
export const RAM_should_collider_allow_interact = 0x80145ce8
export const RAM_get_animator_by_index = 0x8011ffe0
export const RAM_flo_14_D_80243B18_CCEE28 = 0x80243b18
export const RAM_jan_02_TransformFoliage = 0x80242750
export const RAM_func_802417AC_EAA28C = 0x802417ac
export const RAM_pause_available_pal = 0x8026deb0
export const RAM_gPauseItemsWindowBPs = 0x8024f5c0
export const RAM_b_area_nok_kent_c_koopa_defenseTable_802279F0 = 0x802279f0
export const RAM_D_80250D58_C0E5D8 = 0x80250d58
export const RAM_HES_Skolar = 0x80107a40
export const RAM_D_80254620_9EB640 = 0x80254620
export const RAM_mgm_01_GetCoinCount = 0x802416cc
export const RAM_D_E0064A88 = 0xe0064a88
export const RAM_dgb_03_ClubbaNappingAI_FallAsleep = 0x80242424
export const RAM_D_80247A20_DA4EF0 = 0x80247a20
export const RAM_dgb_01_D_802527BC_C1003C = 0x802527bc
export const RAM_b_area_omo_stageTable = 0x80230ac8
export const RAM_kkj_00_PatrolAI_LoiterInit = 0x802404b0
export const RAM_arn_05_PatrolAI_JumpInit = 0x80240730
export const RAM_dro_01_Quizmo_SetCamVfov = 0x80241fdc
export const RAM_D_E0046620 = 0xe0046620
export const RAM_ui_battle_status_hp_drain_pal = 0x80103880
export const RAM_nok_02_Quizmo_NPC_Aux_Impl = 0x80241cbc
export const RAM_D_802430B0_BD1CA0 = 0x802430b0
export const RAM_tik_maps = 0x80090150
export const RAM_D_802BEC28_31CC48 = 0x802bec28
export const RAM_battle_item_volt_shroom_UseItem = 0x802a1584
export const RAM_D_8029F248 = 0x8029f248
export const RAM_kpa_13_get_tattle = 0x80240000
export const RAM_ui_kaime_png = 0x800fc4e0
export const RAM_dead_gPartnerActionStatus = 0x80117160
export const RAM_func_8024086C_B0726C = 0x8024086c
export const RAM_func_E0024324 = 0xe0024324
export const RAM_sfx_adjust_env_sound_pos = 0x801499ec
export const RAM_D_800B1B90 = 0x800b1b90
export const RAM_ui_battle_status_sleep_0_png = 0x80101c80
export const RAM_kmr_07_StationaryAI_ReturnHomeInit = 0x80240540
export const RAM_star_spirits_energy_main = 0xe0122000
export const RAM_b_area_kzn_red_magikoopa_8022C370 = 0x802210b0
export const RAM_ui_arrow_right_png = 0x800fbac0
export const RAM_filemenu_draw_contents_file_1_info = 0x802455d8
export const RAM_vtx_C2B2D0 = 0x8020a390
export const RAM_dro_02_init_8024D130 = 0x8024d130
export const RAM_sam_02_Pipe_SetAnimFlag = 0x80240000
export const RAM_b_area_iwa_iwa_02_handleEvent_8022185C = 0x8022185c
export const RAM_OnFleeBattleDrops = 0x8003f6e8
export const RAM_crash_screen_thread_entry = 0x8002c81c
export const RAM_mim_11_Pipe_GetCurrentFloor = 0x80240020
export const RAM_ui_partner10_disabled_pal = 0x800ffc80
export const RAM_iwa_03_CleftAI_ReturnHome = 0x80240d70
export const RAM_mac_04_Quizmo_DestroyEffects = 0x80240de8
export const RAM_init_npc_list = 0x8003852c
export const RAM_b_area_kgr_fuzzipede_handleEvent_80219ED4 = 0x80219ed4
export const RAM_hos_03_Quizmo_SetVannaAnim_Clap = 0x80242fac
export const RAM_SyncOverrideEnemyPos = 0x80045580
export const RAM_b_area_pra3_duplighost_watt_init = 0x80222ae8
export const RAM_D_802BCB88_E314D8 = 0x802bcb88
export const RAM_kpa_95_PatrolAI_Jump = 0x80240884
export const RAM_flo_12_80240830 = 0x80240830
export const RAM_dgb_00_80240DDC = 0x80240ddc
export const RAM_mim_06_MeleeHitbox_32 = 0x8024064c
export const RAM_mim_04_HoppingAI_Chase = 0x80240aac
export const RAM_func_80240B44_A40284 = 0x80240b44
export const RAM_D_8024C7D0_C0A050 = 0x8024c7d0
export const RAM_set_actor_home_position = 0x80269d20
export const RAM_func_802BBDB8_E30708 = 0x802bbdb8
export const RAM_gv_01_Pipe_GetEntryPos = 0x802400f0
export const RAM_kmr_02_StationaryAI_ReturnHome = 0x80241a10
export const RAM_dgb_03_MeleeHitbox_CanSeePlayer = 0x80240458
export const RAM_D_E00C0C10 = 0xe00c0c10
export const RAM_hud_element_draw_rect = 0x8013f704
export const RAM_D_802A1A90 = 0x802a1a90
export const RAM_gPauseWS_6 = 0x8026fa50
export const RAM_flo_09_triggerCoord_80243498 = 0x80243498
export const RAM_D_E0010710 = 0xe0010710
export const RAM_gHudElementCacheSize = 0x8015133c
export const RAM_iwa_01_CleftAI_FindPlayerInit = 0x802426ec
export const RAM_func_802A96F4 = 0x802a96f4
export const RAM_func_80055068 = 0x80055068
export const RAM_trd_04_ParatroopaAI_Main = 0x80243650
export const RAM_gBattleDynamicEntityList = 0x801543e0
export const RAM_trd_07_FlyingAI_Wander = 0x80240c94
export const RAM_D_E00287E8 = 0xe00287e8
export const RAM_sync_status_menu = 0x800e9b6c
export const RAM_func_802AA0A4 = 0x802aa0a4
export const RAM_b_area_omo2_general_guy_nextTurn = 0x802184fc
export const RAM_b_area_omo2_toy_tank_start_shy_stack_phase = 0x8022dea8
export const RAM_func_802C022C = 0x802bd32c
export const RAM_D_802D9D30 = 0x802d9d30
export const RAM_b_area_trd_part_2_trd_05b_beforeBattle_80233BB4 = 0x80233bb4
export const RAM_D_8024EF58_978118 = 0x8024ef58
export const RAM_dgb_15_MeleeHitbox_Main = 0x802403bc
export const RAM_func_8024CE9C = 0x8024ce9c
export const RAM_nok_02_Quizmo_GetItemName = 0x802419c4
export const RAM_battle_move_multibounce_CheckForAPress = 0x802a1130
export const RAM_battle_partner_goombario_defenseTable_8023800C = 0x8023800c
export const RAM_fx_63_main = 0xe00c6000
export const RAM_HES_Item_KookyCookie = 0x800834f8
export const RAM_b_area_omo2_3_shy_stack_idleAnimations4 = 0x8023141c
export const RAM_D_8010C964 = 0x8010c964
export const RAM_pra_29_reflection_render_floor = 0x8024068c
export const RAM_pra_14_SetPartnerFlags80000 = 0x80240eb8
export const RAM_D_8014F4FC = 0x8014f4fc
export const RAM_arn_03_npcGroup_80243378 = 0x80243378
export const RAM_hos_04_SetCamXXVfov = 0x802405e0
export const RAM_D_8024F420_C0CCA0 = 0x8024f420
export const RAM_flo_00_8024196C = 0x8024196c
export const RAM_b_area_sam_sam_02d_Set80071270_0_16 = 0x8021aa28
export const RAM_entity_BlueSwitch_init = 0x802e2380
export const RAM_b_area_jan_spear_guy_80218D7C = 0x80218d7c
export const RAM_kmr_02_Quizmo_AudienceEffect = 0x80257f4c
export const RAM_tik_12_UpdateTexturePanSmooth = 0x802402e0
export const RAM_battle_item_food_UseItemWithEffect = 0x802a1630
export const RAM_D_80234838_6BBA38 = 0x80234838
export const RAM_dgb_07_exitSingleDoor_80241530 = 0x80241530
export const RAM_func_80240208_8FF878 = 0x80240208
export const RAM_isk_04_SetEntityFlags100000 = 0x80240000
export const RAM_ui_status_pow_unit_1_png = 0x80100700
export const RAM_arn_02_CleftAI_Hiding = 0x802401d4
export const RAM_D_E00408A0 = 0xe00408a0
export const RAM_b_area_trd_part_1_paratroopa_statusTable_8021EBC0 = 0x8021ebc0
export const RAM_D_802B6784 = 0x802b6784
export const RAM_dgb_03_npcSettings_80243D68 = 0x80243d68
export const RAM_osSetTime = 0x80066330
export const RAM_GetCamPosC = 0x802cc214
export const RAM_kmr_05_FlyingAI_Loiter = 0x80240898
export const RAM_iwa_10_SuperBlock_HideBlockContent = 0x8024032c
export const RAM_pra_33_reflection_unk_change_anim_facing = 0x80240054
export const RAM_nok_11_UnkNpcAIFunc12 = 0x802401b0
export const RAM_HES_Item_GearBoots2 = 0x80081308
export const RAM_D_8009A630 = 0x8009a630
export const RAM_gPausePanelItems = 0x8024f5e4
export const RAM_init_encounter_status = 0x8003e338
export const RAM_func_802BD188_3170A8 = 0x802bd188
export const RAM_vtx_C24730 = 0x802037f0
export const RAM_shattering_stones_update = 0xe002c23c
export const RAM_dgb_08_SentinelAI_Descend = 0x80242f94
export const RAM_osViSetYScale = 0x80066fd0
export const RAM_shop_owner_begin_speech = 0x80280000
export const RAM_func_802A9000_42A4F0 = 0x802a9000
export const RAM_dgb_01_D_80252604_C0FE84 = 0x80252604
export const RAM_b_area_sam_white_clubba_idleAnimations = 0x80229ac0
export const RAM_HES_Item_FeelingFine_disabled = 0x800843c8
export const RAM_draw_title_screen_NOP = 0x80037960
export const RAM_D_802498E4_A33B24 = 0x802498e4
export const RAM_npc_set_palswap_2 = 0x8003b464
export const RAM_HES_StatusSPIncrement4 = 0x80108310
export const RAM_b_area_mac_chan_UnkBattleFunc1 = 0x80218000
export const RAM_b_area_kmr_part_1_paragoomba_8021C574 = 0x8021df64
export const RAM_D_E00C4A98 = 0xe00c4a98
export const RAM_au_mseq_manager_init = 0x80050b90
export const RAM_jan_10_HoppingAI_LosePlayer = 0x80240ebc
export const RAM_D_E00C6CB8 = 0xe00c6cb8
export const RAM_D_802A3168_0073D038 = 0x802a3168
export const RAM_D_802EB090 = 0x802eb090
export const RAM_DisablePartnerAI = 0x802cf4e8
export const RAM_dgb_01_D_80252C34_C104B4 = 0x80252c34
export const RAM_flo_03_npcSettings_80240EB0 = 0x80240eb0
export const RAM_GetActionCommandMode = 0x80269318
export const RAM_add_fortress_keys = 0x800ea398
export const RAM_b_area_kmr_part_2_blue_goomba_statusTable_802198BC = 0x802198bc
export const RAM_kpa_91_set_script_owner_npc_anim = 0x80240e90
export const RAM_D_800B42F4 = 0x800b42f4
export const RAM_gCurrentCustomModelGfxBuildersPtr = 0x801512b8
export const RAM_HES_Item_BakingButter = 0x80087398
export const RAM_func_8024140C_C8EFBC = 0x8024140c
export const RAM_func_80240D5C_90878C = 0x80240d5c
export const RAM_func_802DE780 = 0x802de780
export const RAM_filemenu_8024C090 = 0x8024c090
export const RAM_NAMESPACE_idleAnimations_80238640 = 0x80238640
export const RAM_D_802B6768_E29198 = 0x802b6768
export const RAM_find_script_labels = 0x802c3148
export const RAM_flo_19_func_8024030C_CE39FC = 0x8024030c
export const RAM_flo_03_entryList = 0x80240630
export const RAM_D_E010CA68 = 0xe010ca68
export const RAM_b_area_isk_part_2_isk_02_beforeBattle_8021FDF0 = 0x8021fdf0
export const RAM_dgb_01_makeEntities = 0x80244c64
export const RAM_D_E0046628 = 0xe0046628
export const RAM_b_area_mac_master1_idleAnimations_8022A260 = 0x8022a260
export const RAM_D_80245960_BAA0E0 = 0x80245960
export const RAM_flo_10_UpdateTexturePanSmooth = 0x80240198
export const RAM_func_80240B64_A113E4 = 0x80240b64
export const RAM_D_802A20E0_738970 = 0x802a2110
export const RAM_D_E0126BC4 = 0xe0126bc4
export const RAM_dgb_01_D_80251F50_C0F7D0 = 0x80251f50
export const RAM_battle_star_refresh_802A1E08 = 0x802a1e08
export const RAM_clear_trigger_data = 0x80145680
export const RAM_arn_08_pad_B98 = 0x80240b98
export const RAM_mac_05_PlayFX3D = 0x80243ba4
export const RAM_b_area_iwa_iwa_01_whacka = 0x80220a28
export const RAM_mac_04_Quizmo_SetVannaAnim_Wave = 0x80240f84
export const RAM_func_80055BB8 = 0x80055bb8
export const RAM_D_8014B0B8 = 0x8014b0b8
export const RAM_b_area_arn_tubba_blubba_statusTable_80225CA0 = 0x80225ca0
export const RAM_PlayerHappy = 0x80288d64
export const RAM_D_E00C6CB0 = 0xe00c6cb0
export const RAM_func_802194C0_6BD050 = 0x802194c0
export const RAM_vtx_C241D0 = 0x80203290
export const RAM_HES_Item_BigCookie_disabled = 0x80082e08
export const RAM_battle_item_stop_watch_UseItemWithEffect = 0x802a1360
export const RAM_jan_16_UnkFunc26 = 0x80240124
export const RAM_b_area_hos_magikoopa_electrify = 0x80222c50
export const RAM_battle_star_star_storm_UnkBackgroundFunc = 0x802a1414
export const RAM_D_802A3F00_721550 = 0x802a3f00
export const RAM_SetPlayerJumpscale = 0x802d1054
export const RAM_b_area_trd_part_2_fake_bowser_8021D1CC = 0x8021d1cc
export const RAM_D_80296014 = 0x80296014
export const RAM_func_802A94A4 = 0x802a94a4
export const RAM_D_00E4E7F0 = 0x00e4e7f0
export const RAM_fx_86_render = 0xe0128198
export const RAM_D_802A1998_0073B868 = 0x802a1998
export const RAM_omo_09_GetEncounterEnemyIsOwner = 0x80243580
export const RAM_update_item_entities = 0x80131eec
export const RAM_b_area_jan2_jan_04_foregroundModelList_8021F4F0 = 0x8021f4f0
export const RAM_HES_BoostJumpBegin = 0x8010701c
export const RAM_battle_partner_goombario_D_8023BB78_6ECC58 = 0x8023bb78
export const RAM_func_E00A4648 = 0xe00a4648
export const RAM_D_802EB670 = 0x802eb670
export const RAM_b_area_sam2_sam_02d_UnkModelStuff_array = 0x80222d00
export const RAM_func_80242910_9C7CF0 = 0x80242910
export const RAM_kpa_51_MagikoopaAI_06 = 0x80240970
export const RAM_D_802441B0_CB91B0 = 0x802441b0
export const RAM_battle_item_shooting_star_GiveRefundCleanup = 0x802a11d4
export const RAM_b_area_trd_part_2_green_ninja_koopa_takeTurn_802284D4 = 0x802284d4
export const RAM_b_area_nok_paratroopa = 0x80223b08
export const RAM_action_update_spin = 0x802b6000
export const RAM_D_8029BBB4 = 0x8029bbb4
export const RAM_ring_blast_init = 0xe0048128
export const RAM_gPauseMsg_56 = 0x8026f774
export const RAM_HES_FilenameCaret = 0x80241f70
export const RAM_nok_01_ToadHouse_CamSetFOV = 0x80240b30
export const RAM_battle_menu_moveOptionDisplayCostReductionColors = 0x802ad3e0
export const RAM_nuScEventHandler = 0x8005efa8
export const RAM_dgb_01_exitDoubleDoor_80243550 = 0x80243550
export const RAM_b_area_mac_lee_idle_80222458 = 0x80222458
export const RAM_kpa_95_ToadHouse_AwaitScriptComplete = 0x8024174c
export const RAM_flo_25_tree1_Leaves = 0x80244644
export const RAM_b_area_omo_formation_15 = 0x8022ff88
export const RAM_func_8024061C_8A04DC = 0x8024061c
export const RAM_battle_item_mystery_D_802A229C_72D84C = 0x802a229c
export const RAM_int_to_string = 0x800294c4
export const RAM_bind_npc_ai = 0x8003ea84
export const RAM_D_09000A90_32D950 = 0x09000a90
export const RAM_shim_guMtxIdentF = 0xe0200590
export const RAM_D_80242680_CF08E0 = 0x80242680
export const RAM_func_E007A884 = 0xe007a884
export const RAM_FadeInMerlee = 0x8003f018
export const RAM_b_area_isk_part_1_stone_chomp_ChompChainUpdateHelperFunc2 = 0x80218458
export const RAM_b_area_sam2_sam_02c_UnkModelStuff_func1 = 0x802194e0
export const RAM_func_80240DD0_8ECBF0 = 0x80240dd0
export const RAM_flo_09_FlyingAI_Init = 0x80241508
export const RAM_b_area_hos_ember_8021B398 = 0x8021b398
export const RAM_func_802411A0_D6FB50 = 0x802411a0
export const RAM_ui_battle_status_shrink_2_pal = 0x80102ca0
export const RAM_flo_21_exitWalk_802412F4 = 0x802412f4
export const RAM_D_E007EC2F = 0xe007ec2f
export const RAM_dead_basic_ai_lose_player = 0x8004e808
export const RAM_pra_01_SetGameStatusUnk84_1 = 0x80240000
export const RAM_sam_02_ToadHouse_DoesPlayerNeedSleep = 0x802409f0
export const RAM_func_80260E5C = 0x80260e5c
export const RAM_vtx_C26650 = 0x80205710
export const RAM_b_area_trd_part_1_bob_omb_8021AAE8 = 0x8021aae8
export const RAM_trd_01_StationaryAI_Chase = 0x80240fa0
export const RAM_b_area_trd_part_2_fake_bowser_defenseTable_8021A684 = 0x8021a684
export const RAM_func_802430D0_826390 = 0x802430d0
export const RAM_chomp_drop_appendGfx = 0xe009a3e0
export const RAM_D_E0034792 = 0xe0034792
export const RAM_D_8024E470_831730 = 0x8024e470
export const RAM_D_800F84F8 = 0x800f84f8
export const RAM_ui_battle_cmd_aim_marker_4_pal = 0x8028e880
export const RAM_pra_13_GetNpcCollisionHeight = 0x80240f20
export const RAM_battle_star_star_beam_802A2E2C = 0x802a2e2c
export const RAM_sbk_30_StationaryAI_Chase = 0x8024074c
export const RAM_D_E00287E0 = 0xe00287e0
export const RAM_arn_09_idle_802405FC = 0x802405fc
export const RAM_b_area_omo2_6_shy_guy_dup_handleEvent = 0x8023172c
export const RAM_b_area_kmr_part_2_red_goomba_2_idleAnimations_8021EF04 = 0x8021ef04
export const RAM_b_area_omo_spy_guy = 0x802275ac
export const RAM_dro_02_npcAI_80247438 = 0x80247438
export const RAM_ui_status_text_slash_png = 0x800fff20
export const RAM_HES_Item_IcedPotato = 0x80086f78
export const RAM_stars_orbiting_main = 0xe005e000
export const RAM_b_area_kzn_red_magikoopa_8022C12C = 0x80220e6c
export const RAM_b_area_kmr_part_2_pos00 = 0x80219750
export const RAM_HES_Item_FirstAttack = 0x80083c18
export const RAM_ui_bluepip2_png = 0x801016e0
export const RAM_D_E0038A30 = 0xe0038a30
export const RAM_D_802ED690 = 0x802ed690
export const RAM_mac_01_UnkPositionFunc = 0x802446e0
export const RAM_func_80059310 = 0x80059310
export const RAM_nuContMgrInit = 0x80060510
export const RAM_D_80241740_DDDF70 = 0x80241740
export const RAM_D_E0020D24 = 0xe0020d24
export const RAM_ascii_EA6D70 = 0x80246470
export const RAM_battle_partner_bow_IsPartnerImmobile = 0x80238198
export const RAM_arn_05_npcGroupList_80244FC8 = 0x80244fc8
export const RAM_kzn_20_LetterDelivery_SavedNpcAnim = 0x80244494
export const RAM_flo_25_exitWalk_8024264C = 0x8024264c
export const RAM_battle_move_unknown_move_UnkMoveFunc1 = 0x802a1000
export const RAM_D_802B6E90_E2A300 = 0x802b6e90
export const RAM_jan_03_Quizmo_RenderInit = 0x80241efc
export const RAM_HES_Item_MagicMirror = 0x80086558
export const RAM_D_80241430_D58860 = 0x80241430
export const RAM_NpcFacePlayer = 0x802cecc8
export const RAM_D_090020C0_388470 = 0x090020c0
export const RAM_pra_13_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_msg_get_print_char_width = 0x80125c84
export const RAM_filemenu_draw_contents_file_2_title = 0x80245768
export const RAM_dgb_15_pad_2AC8 = 0x80242ac8
export const RAM_dgb_12_CheckItemFlags40 = 0x80240268
export const RAM_entity_updateCounter = 0x80151330
export const RAM_dup6_b_area_kpa2_CanPlayerLoseAction = 0x8021b570
export const RAM_b_area_omo_medi_guy_handleEvent_8021FC60 = 0x802237e0
export const RAM_b_area_kzn_kzn_02_8022797C = 0x8022797c
export const RAM_flo_23_settings = 0x80240cf0
export const RAM_omo_17_CompareFloats = 0x802400e4
export const RAM_kpa_03_MagikoopaAI_OnHitInit = 0x80241dc4
export const RAM_b_area_jan_formation_06 = 0x8022875c
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_defenseTable_8022E440 = 0x8022e440
export const RAM_guFrustum = 0x80062060
export const RAM_battle_menu_leftJustfiedMessagesB = 0x802ab4a4
export const RAM_D_E0124BE4 = 0xe0124be4
export const RAM_D_802498F8_A33B38 = 0x802498f8
export const RAM_flo_17_entryList = 0x80243220
export const RAM_D_80249CE0 = 0x80249ce0
export const RAM_gMsgGlobalWaveCounter = 0x801512e4
export const RAM_arn_03_D_80241C68_BE09F8 = 0x80241c68
export const RAM_func_80240830_A2AA70 = 0x80240830
export const RAM_arn_07_npcSettings_80243CC4 = 0x80243cc4
export const RAM_D_802A3818 = 0x802a3818
export const RAM_dro_02_entryList = 0x80243a70
export const RAM_b_area_trd_part_2_trd_05d_beforeBattle_80234C04 = 0x80234c04
export const RAM_state_drawUI_pause = 0x80034bf4
export const RAM_omo_04_ShyGuyWanderAI_15 = 0x80241908
export const RAM_kzn_09_set_script_owner_npc_anim = 0x802429c4
export const RAM_dro_01_Quizmo_Answers = 0x8024521c
export const RAM_mim_06_HoppingAI_Chase = 0x802427c0
export const RAM_dead_disable_npc_shadow = 0x8003e5e8
export const RAM_D_802ED550 = 0x802ed550
export const RAM_D_8010CD3C = 0x8010cd3c
export const RAM_find_item = 0x800e7644
export const RAM_D_802E9270 = 0x802e9270
export const RAM_b_area_trd_part_1_koopa_troopa_intTable_8021D1C0 = 0x8021d1c0
export const RAM_player_team_is_ability_active = 0x802672ac
export const RAM_hos_02_FlyingNoAttackAI_13 = 0x80242618
export const RAM_b_area_sam_duplighost_OnShockHit = 0x8021c0f8
export const RAM_func_80240160_DE5810 = 0x80240160
export const RAM_dro_02_Quizmo_AddViewRelativeOffset = 0x80240f80
export const RAM_b_area_omo2_2_stilt_guy_check_fall_off_2 = 0x80232b84
export const RAM_dgb_08_func_80243B98_C43948 = 0x80243b98
export const RAM_b_area_isk_part_1_isk_06_beforeBattle_80223BF0 = 0x80223bf0
export const RAM_jan_06_MeleeHitbox_33 = 0x802419d0
export const RAM_b_area_omo2_2_stilt_guy_UnkBattleFunc1 = 0x80231054
export const RAM_au_SEFCmd_10_Jump = 0x8004d2e4
export const RAM_mim_05_PiranhaPlantAI_14 = 0x802416d8
export const RAM_flo_12_80241BE4 = 0x80241be4
export const RAM_HES_RedBar2 = 0x80107938
export const RAM_D_80098288 = 0x80098288
export const RAM_D_09000C10_32DAD0 = 0x09000c10
export const RAM_arn_04_CleftAI_RevUp = 0x80242a1c
export const RAM_b_area_kmr_part_2_goomba_king_partsTable_802209C8 = 0x802209c8
export const RAM_pra_06_reflection_unk_resolve_anim = 0x80240050
export const RAM_dup2_nok_02_AddPlayerHandsOffset = 0x80243a1c
export const RAM_D_802943C0 = 0x802943c0
export const RAM_func_802DDA84 = 0x802dda84
export const RAM_battle_star_peach_dash_802A1CE0 = 0x802a1ce0
export const RAM_D_8026A2B0 = 0x8026a2b0
export const RAM_dup_kmr_04_CheckPartnerFlags1000 = 0x8024029c
export const RAM_func_80240654_C96584 = 0x80240654
export const RAM_b_area_trd_part_2_fake_bowser_handleEvent_802242FC = 0x802242fc
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021C638 = 0x8021c638
export const RAM_D_8024963C_A3387C = 0x8024963c
export const RAM_b_area_isk_part_1_isk_05_CreateTorchFX = 0x80218980
export const RAM_b_area_sbk_formation_0D = 0x8021fd30
export const RAM_omo_08_UpdateTexturePanStepped = 0x80240680
export const RAM_nok_15_FlyingAI_Loiter = 0x8024134c
export const RAM_b_area_nok_paragoomba_idleAnimations_8021CAFC = 0x8021cafc
export const RAM_func_802571F0 = 0x802571f0
export const RAM_func_80057EB0 = 0x80057eb0
export const RAM_D_802EB098 = 0x802eb098
export const RAM_HES_Item_BlueBerry_disabled = 0x800869a8
export const RAM_b_area_iwa_iwa_02_foregroundModelList_802215C0 = 0x802215c0
export const RAM_D_802942A0 = 0x802942a0
export const RAM_ui_battle_status_stop_0_pal = 0x801027a0
export const RAM_dgb_16_extraAnimationList_8024187C = 0x8024187c
export const RAM_b_area_nok_koopa_troopa_idleAnimations_8021CADC = 0x8022005c
export const RAM_D_E002C940 = 0xe002c940
export const RAM_kzn_08_MeleeHitbox_CanSeePlayer = 0x80242744
export const RAM_flo_16_npcAI_80244058 = 0x80244058
export const RAM_D_8024F228_A39468 = 0x8024f228
export const RAM_appendGfx_partner_actor_blur = 0x8025599c
export const RAM_kpa_95_PatrolAI_Loiter = 0x802405a0
export const RAM_D_800B0CEC = 0x800b0cec
export const RAM_D_8024527C = 0x8024527c
export const RAM_b_area_sam_duplighost_formation_bombette = 0x80221574
export const RAM_ui_stat_flower_png = 0x801045b0
export const RAM_action_update_tornado_jump = 0x802b6000
export const RAM_create_status_icon_boost_jump = 0x80047c14
export const RAM_b_area_kzn_formation_06 = 0x802299a4
export const RAM_HES_Item_CakeBatter = 0x800805b0
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021C0CC = 0x8021c0cc
export const RAM_SetPartEventFlags = 0x8026d340
export const RAM_D_802BCAA0_E313F0 = 0x802bcaa0
export const RAM_b_area_sam_sam_02c_foregroundModelList = 0x8022fea0
export const RAM_func_80245794_A2F9D4 = 0x80245794
export const RAM_func_8024F940 = 0x8024f940
export const RAM_flo_16_80243E80 = 0x80243e80
export const RAM_func_8025D150 = 0x8025d150
export const RAM_battle_item_dried_shroom_DrinkItem = 0x802a1884
export const RAM_flo_21_extraAnimationList_802437E0 = 0x802437e0
export const RAM_D_80245E64_7F0B74 = 0x80245e64
export const RAM_battle_item_shooting_star_func_802A1444_71DBB4 = 0x802a1444
export const RAM_b_area_trd_part_1_trd_00 = 0x80224bc4
export const RAM_create_model_animator = 0x8011e4b8
export const RAM_func_8024114C_D599DC = 0x8024114c
export const RAM_D_80242010_A7A4D0 = 0x80242010
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022DD48 = 0x8022dd48
export const RAM_EA0C10_varStash = 0x80244154
export const RAM_D_E0028810 = 0xe0028810
export const RAM_guLookAtF = 0x800621b0
export const RAM_dgb_16_npcGroupList_8024318C = 0x8024318c
export const RAM_flo_10_pad_406C = 0x8024406c
export const RAM_SMALLROOM_PARAMS_N = 0x8007efe0
export const RAM_b_area_omo_omo_05b_afterBattle_8022F5E4 = 0x8022f5e4
export const RAM_gPausePanelMap = 0x8025068c
export const RAM_battle_item_super_soda_DrinkItem = 0x802a1a74
export const RAM_func_802BC3A8_E2CAD8 = 0x802bc3a8
export const RAM_b_area_arn_hyper_paragoomba_ArcsinDeg = 0x80218338
export const RAM_b_area_kmr_part_3_para_jr_troopa_80226D4C = 0x80226d4c
export const RAM_D_802EC7F0 = 0x802ec7f0
export const RAM_load_map_hit_asset = 0x8005afc0
export const RAM_D_8029C0A4 = 0x8029c0a4
export const RAM_tik_07_SuperBlock_HideBlockContent = 0x8024031c
export const RAM_HES_AimShimmerC = 0x8029330c
export const RAM_D_802AD4A8 = 0x802ad4a8
export const RAM_HES_Item_ShootingStar = 0x80086258
export const RAM_b_area_kzn_white_magikoopa_idle_80223E14 = 0x80223e14
export const RAM_tik_23_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_80243880_B7A030 = 0x80243880
export const RAM_dgb_01_PatrolAI_Loiter = 0x802429ec
export const RAM_tik_19_SuperBlock_HideBlockContent = 0x8024062c
export const RAM_b_area_nok_paragoomba_StartRumbleWithParams = 0x80218280
export const RAM_jan_02_Quizmo_Answers = 0x80243248
export const RAM_bombette_breaking_get_model_from_list_index = 0xe008403c
export const RAM_func_8024F5AC = 0x8024f5ac
export const RAM_b_area_pra3_duplighost_kooper_idleAnimations = 0x8021d6fc
export const RAM_vtx_C26490 = 0x80205550
export const RAM_b_area_arn_tubbas_heart_80224414 = 0x80224414
export const RAM_omo_17_UnkFloatFunc = 0x80240388
export const RAM_func_802430E4 = 0x802430e4
export const RAM_dro_01_interact_802483CC = 0x802483cc
export const RAM_func_800F2E98 = 0x800f2e98
export const RAM_HES_Item_SuperJump_disabled = 0x80084c68
export const RAM_func_80240690_A2A8D0 = 0x80240690
export const RAM_sbk_35_SpawnSunEffect = 0x80240000
export const RAM_b_area_kmr_part_2_red_goomba_2_idle_8021EFE8 = 0x8021efe8
export const RAM_dgb_12_EVS_Chest_ShowGotItem = 0x80240874
export const RAM_mgm_02_DisableMenus = 0x80240544
export const RAM_arn_09_802401F4 = 0x802401f4
export const RAM_func_E00DA228 = 0xe00da228
export const RAM_D_80150728 = 0x80150728
export const RAM_func_802A9718 = 0x802a9718
export const RAM_gPauseItemsCurrentTab = 0x802705e4
export const RAM_fx_misc_particles = 0x80072350
export const RAM_b_area_arn_hyper_paragoomba_defenseTable_8021B2F0 = 0x8021b2f0
export const RAM_arn_03_npcGroup_80243758 = 0x80243758
export const RAM_flo_09_tree3_Leaves = 0x80243470
export const RAM_func_80240840_A57CF0 = 0x80240840
export const RAM_lightning_bolt_main = 0xe00bc000
export const RAM_collision_main_lateral = 0x800e363c
export const RAM_jan_02_PatrolAI_ChaseInit = 0x80240c4c
export const RAM_b_area_isk_part_2_isk_02_afterBattle_8021FE2C = 0x8021fe2c
export const RAM_ui_mario_head_pal = 0x800fe3c0
export const RAM_ice_pillar_init = 0xe011e18c
export const RAM_arn_05_npcSettings_8024172C = 0x8024172c
export const RAM_dgb_16_ClubbaNappingAI_Init = 0x8024061c
export const RAM_D_802348A0_6BBAA0 = 0x802348a0
export const RAM_end_00_credits_worker_render = 0x802421d8
export const RAM_SetVirtualEntityRenderCommands = 0x802d3674
export const RAM_b_area_nok_nok_01 = 0x8022b7e0
export const RAM_b_area_sam_frost_piranha_parts = 0x8022abc4
export const RAM_gHudElementsDLBackUI = 0x8014f078
export const RAM_battle_star_smooch_UnkStarFunc3 = 0x802a116c
export const RAM_b_area_omo_formation_0F = 0x8022fd90
export const RAM_nok_01_ToadHouse_PutPartnerAway = 0x80240cfc
export const RAM_ui_partner11_pal = 0x800ffea0
export const RAM_func_802406A8_C965D8 = 0x802406a8
export const RAM_dead_sync_status_menu = 0x800f1730
export const RAM_b_area_sam_gray_magikoopa_statusTable = 0x8022c328
export const RAM_entity_GreenStompSwitch_retract = 0x802e1460
export const RAM_LoadAnimatedMesh = 0x802ccdac
export const RAM_pause_items_get_row = 0x802498e0
export const RAM_GoompaPutAway = 0x802bd52c
export const RAM_dgb_01_D_80252CE4_C10564 = 0x80252ce4
export const RAM_pause_cleanup = 0x80244708
export const RAM_D_80242B7E_86C02E = 0x80242b7e
export const RAM_kzn_02_varStash = 0x80244a10
export const RAM_dro_01_tree1_Drops = 0x8024dd34
export const RAM_dgb_01_D_802524FC_C0FD7C = 0x802524fc
export const RAM_flo_24_foliage_setup_shear_mtx = 0x80240040
export const RAM_HES_PartnerRank2B = 0x80109310
export const RAM_D_8010D65A = 0x8010d65a
export const RAM_b_area_kmr_part_3_final_jr_troopa = 0x8022c6ac
export const RAM_jan_02_Quizmo_UpdateRecords = 0x80241d00
export const RAM_D_8024E8C0_C0C140 = 0x8024e8c0
export const RAM_tik_15_Pipe_GetCameraYaw = 0x802401d0
export const RAM_MusicMaxVolume = 0x80159ae4
export const RAM_gMapSettings = 0x800a41e8
export const RAM_func_E00B62D8 = 0xe00b62d8
export const RAM_GetPartFlags = 0x8026d218
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021C47C = 0x8021c47c
export const RAM_func_80238D8C_70CA9C = 0x80238d8c
export const RAM_HES_Item_StoneCap_disabled = 0x80086408
export const RAM_pra_35_reflection_render_floor = 0x8024068c
export const RAM_mim_08_WanderMeleeAI_Main = 0x80241bb4
export const RAM_mim_06_SetForeverForestFog = 0x80240000
export const RAM_flo_25_FlyingAI_Jump = 0x80240c94
export const RAM_D_802A1B40 = 0x802a1b40
export const RAM_kpa_102_FlyingMagikoopaAI_22 = 0x80242f08
export const RAM_energy_orb_wave_init = 0xe00a41a4
export const RAM_func_80243370_8B33E0 = 0x80243370
export const RAM_ui_battle_status_sleep_0_pal = 0x80101d00
export const RAM_dgb_03_PatrolAI_LosePlayer = 0x8024158c
export const RAM_kzn_04_SuperBlock_AnimateEnergyOrbs = 0x80240910
export const RAM_D_802D9E24 = 0x802d9e24
export const RAM_dup_p_area_kpa4_StartRumbleWithParams = 0x802180ec
export const RAM_flo_07_npcGroupList_80242B0C = 0x80242b0c
export const RAM_pra_10_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_D_E00C8720 = 0xe00c8720
export const RAM_mac_03_TransformFoliage = 0x80241d70
export const RAM_kzn_03_WanderMeleeAI_Main = 0x80241214
export const RAM_b_area_trd_part_2_green_ninja_koopa_init_80227CEC = 0x80227cec
export const RAM_IES_Melody = 0x8008baa4
export const RAM_b_area_isk_part_1_pokey_mummy_takeTurn_80219D88 = 0x80219d88
export const RAM_omo_13_GrooveGuyAI_03 = 0x8024083c
export const RAM_b_area_pra3_swoopula_takeTurn = 0x80229da4
export const RAM_b_area_nok_kent_c_koopa_flipOver_KentC = 0x8022adc0
export const RAM_b_area_omo_formation_05 = 0x8022fa64
export const RAM_kpa_60_GetEntityPosition = 0x80240034
export const RAM_func_802409EC_D9B8DC = 0x802409ec
export const RAM_b_area_omo2_1_shy_squad_move_guy_to_pos = 0x80232488
export const RAM_D_8029F650 = 0x8029f650
export const RAM_sam_05_MeleeHitbox_Main = 0x802403bc
export const RAM_D_802451F0 = 0x802451f0
export const RAM_stars_shimmer_update = 0xe0044424
export const RAM_b_area_pra3_duplighost_sushie_defenseTable = 0x802238b4
export const RAM_get_anim_model_fog_distance = 0x8012009c
export const RAM_b_area_kzn_medi_guy_partsTable_8021F99C = 0x8021f99c
export const RAM_omo_07_FlyingAI_Jump = 0x802425cc
export const RAM_water_splash_render = 0xe00be578
export const RAM_mdl_renderModelFogColorA = 0x8014b757
export const RAM_dgb_04_SentinelAI_DescendInit = 0x80241754
export const RAM_D_802BE250_31DDC0 = 0x802be250
export const RAM_pra_11_ReflectWall = 0x802400ec
export const RAM_D_E00D0B40 = 0xe00d0b40
export const RAM_ui_status_text_4_pal = 0x80100280
export const RAM_tik_17_SuperBlock_get_partner_rank = 0x80240104
export const RAM_D_802A9A08_42EDA8 = 0x802a9a08
export const RAM_flo_08_ItemChoice_HasSelectedItem = 0x80243088
export const RAM_IES_QuickChange = 0x8008c944
export const RAM_dgb_08_PatrolAI_MoveInit = 0x80240000
export const RAM_D_80150720 = 0x80150720
export const RAM_EFEB00_UpdateTexturePanStepped = 0x80240124
export const RAM_ui_status_pow_star_3_pal = 0x801009a0
export const RAM_vtx_C28190 = 0x80207250
export const RAM_vtx_C24690 = 0x80203750
export const RAM_func_80242030_8EDE50 = 0x80242030
export const RAM_battle_item_ultra_shroom_DrinkItem = 0x802a1884
export const RAM_is_merlee_message_done = 0x80045fa4
export const RAM_dgb_17_makeEntities = 0x80240470
export const RAM_func_80238C14_70C924 = 0x80238c14
export const RAM_D_09001880_3AC8B0 = 0x09001880
export const RAM_D_E02006D4 = 0xe02006d4
export const RAM_hos_01_GetFloorCollider = 0x80240768
export const RAM_flo_10_8024339C = 0x8024339c
export const RAM_ED00D0_TransformFoliage = 0x80240150
export const RAM_lil_oink_appendGfx = 0xe0114388
export const RAM_HES_Item_Unused_13F = 0x80082778
export const RAM_mgm_01_SetMsgVars_BlocksRemaining = 0x802416f8
export const RAM_EEDF50_FlyingAI_ChaseInit = 0x80241b10
export const RAM_b_area_sam2_sam_03_UnkModelStuff_func1 = 0x80219b20
export const RAM_dgb_05_settings = 0x802414a0
export const RAM_sam_11_Quizmo_SpinPlayer = 0x802415ec
export const RAM_dgb_09_ClubbaNappingAI_Sleep = 0x80240958
export const RAM_ui_battle_cmd_aim_shimmer_5_pal = 0x8028ee20
export const RAM_ui_status_star_point_5_png = 0x80101460
export const RAM_fx_big_snowflakes = 0x80070a90
export const RAM_show_damage_popup = 0x802665ac
export const RAM_D_802A1C40 = 0x802a1c40
export const RAM_b_area_sam_formation_0B = 0x80230f50
export const RAM_water_fountain_main = 0xe00b8000
export const RAM_b_area_nok_spiked_goomba_defenseTable_8021E3B0 = 0x8021e3b0
export const RAM_D_801599B8 = 0x801599b8
export const RAM_D_802944A0 = 0x802944a0
export const RAM_entity_base_switch_animate_scale = 0x802e1edc
export const RAM_func_80266AF8 = 0x80266af8
export const RAM_D_E0064A80 = 0xe0064a80
export const RAM_kzn_07_FlyingAI_Init = 0x80241a08
export const RAM_gCurrentTileDescriptor = 0x80151740
export const RAM_battle_star_star_beam_802A2C20 = 0x802a2c20
export const RAM_sam_01_GetItemEntityPosition = 0x80241a80
export const RAM_battle_star_smooch_802A1D60 = 0x802a1d60
export const RAM_btl_state_draw_begin_player_turn = 0x80242ac0
export const RAM_b_area_arn_arn_06_beforeBattle_80227C00 = 0x80227c00
export const RAM_battle_item_pow_block_UseItemWithEffect = 0x802a13a0
export const RAM_D_09000200_32FD50 = 0x09000200
export const RAM_func_8002B608 = 0x8002b608
export const RAM_b_area_flo_UnkFunc52 = 0x80218808
export const RAM_pra_11_reflection_setup_floor = 0x80240500
export const RAM_D_800F7B4C = 0x800f7b4c
export const RAM_b_area_kzn_red_magikoopa_8022E558 = 0x80223298
export const RAM_D_E00E6888 = 0xe00e6888
export const RAM_b_area_omo2_5_shy_squad_dup_statusTable = 0x8023100c
export const RAM_hud_element_create = 0x801414a4
export const RAM_flo_12_main = 0x80240ba4
export const RAM_omo_13_ShyGuyWanderAI_16 = 0x802403c0
export const RAM_SetPartEventBits = 0x8026d3c8
export const RAM_end_01_CharAnim_FadeIn_1 = 0x80240280
export const RAM_b_area_pra3_duplighost_CopyPriority = 0x80219524
export const RAM_pra_14_StashVars = 0x80240f20
export const RAM_dgb_10_80240860 = 0x80240860
export const RAM_arn_07_StarSpiritEffectFunc4 = 0x802405bc
export const RAM_ui_muskular_png = 0x800fd5c0
export const RAM_HES_Item_BakingSugar_disabled = 0x80087248
export const RAM_b_area_kmr_part_2_goomba_king_idleAnimations_80220800 = 0x80220800
export const RAM_update_player_actor_shadow = 0x80257bac
export const RAM_spirit_card_appendGfx = 0xe01124cc
export const RAM_D_802A126C_737AFC = 0x802a129c
export const RAM_IES_CakePan = 0x8008b178
export const RAM_func_80240000_A64090 = 0x80240000
export const RAM_battle_item_unknown_item_GiveRefund = 0x802a1000
export const RAM_mgm_02_UpdateRecords = 0x80241dcc
export const RAM_kzn_02_AddPlayerHandsOffset = 0x80242140
export const RAM_battle_item_coconut_main = 0x802a1b6c
export const RAM_battle_item_unknown_item_func_802A12FC_72453C = 0x802a12fc
export const RAM_func_800397E8 = 0x800397e8
export const RAM_tik_17_SuperBlock_EndGlowEffect = 0x80240540
export const RAM_monstar_bubbles = 0x80223680
export const RAM_D_802BEBC0 = 0x802bebc0
export const RAM_kmr_20_UnsetCamera0MoveFlag1 = 0x80240df8
export const RAM_sbk_01_npcGroupList_802407D8 = 0x802407d8
export const RAM_func_80055AF0 = 0x80055af0
export const RAM_dgb_16_ClubbaNappingAI_Sleep = 0x802406a4
export const RAM_D_802452B0_DB7570 = 0x802452b0
export const RAM_dgb_03_PatrolNoAttackAI_Main = 0x802416b4
export const RAM_evt_handle_resume = 0x802c6a4c
export const RAM_EnableStatusMenu = 0x802d5998
export const RAM_au_BGMCmd_E6_MasterEffect = 0x8004fd38
export const RAM_D_09000CE8 = 0x09000ce8
export const RAM_b_area_kmr_part_2_goomnut_tree_idleAnimations_802232D0 = 0x802232d0
export const RAM_dup_mac_05_UnkFloatFunc001 = 0x8024030c
export const RAM_D_802AD0A8 = 0x802ad0a8
export const RAM_flo_17_npcGroup_80244878 = 0x80244878
export const RAM_get_entity_fog_distance = 0x80123060
export const RAM_energy_shockwave_main = 0xe0072000
export const RAM_dgb_05_npcGroupList_8024230C = 0x8024230c
export const RAM_sbk_40_SpawnSunEffect = 0x80240000
export const RAM_sbk_03_SpawnSunEffect = 0x80240000
export const RAM_b_area_pra3_gray_magikoopa_UnkBattleFunc1 = 0x80219d24
export const RAM_D_800A4420 = 0x800a4420
export const RAM_D_802ECD50 = 0x802ecd50
export const RAM_battle_item_pow_block_PlayerGoHome = 0x802a17d0
export const RAM_b_area_arn_formation_07 = 0x80227e58
export const RAM_pause_items_scroll_offset_x = 0x80249968
export const RAM_kkj_18_UpdateTexturePanStepped = 0x80240124
export const RAM_tik_09_UnkPosFunc2 = 0x802405f0
export const RAM_func_802A96DC = 0x802a96dc
export const RAM_D_80243258_A2A128 = 0x80243258
export const RAM_D_80242ACC_D52ABC = 0x80242acc
export const RAM_D_A4500004 = 0xa4500004
export const RAM_pra_37_reflection_unk_resolve_anim = 0x80240050
export const RAM_gFPCSRFaultCauses = 0x800773d8
export const RAM_PostChapter4StatUpdate = 0x80241944
export const RAM_mac_06_UnkAngleFunc001 = 0x8024050c
export const RAM_b_area_isk_part_1_vector3D_80224618 = 0x80224618
export const RAM_dgb_16_npcGroup_802425EC = 0x802425ec
export const RAM_mac_00_Quizmo_NPC_Aux_Impl = 0x802409dc
export const RAM_omo_17_GrooveGuyAI_03 = 0x80240b1c
export const RAM_remove_player_buffs = 0x80267454
export const RAM_func_802D62E4 = 0x802d62e4
export const RAM_dgb_10_80240EE0 = 0x80240ee0
export const RAM_falling_leaves_appendGfx = 0xe00584d4
export const RAM_D_8014BFE0 = 0x8014bfe0
export const RAM_func_80240888_B66338 = 0x80240888
export const RAM_func_8025D158 = 0x8025d158
export const RAM_D_802315CC_63D16C = 0x802315cc
export const RAM_omo_02_SetCamera0MoveFlag1 = 0x8024001c
export const RAM_omo_06_SetAngleClamped = 0x8024001c
export const RAM_D_8024143C_9EF02C = 0x8024143c
export const RAM_ui_pow_cost_reduced_twice_pal = 0x800fc3e0
export const RAM_D_80097D30 = 0x80097d30
export const RAM_clear_part_flag_bits = 0x80264084
export const RAM_b_area_trd_part_1_trd_05b_afterBattle_80225C28 = 0x80225c28
export const RAM_sbk_56_SuperBlock_get_partner_rank = 0x80240154
export const RAM_dro_01_npcAI_80245040 = 0x80245040
export const RAM_mgm_01_TakeCoinCost = 0x80241510
export const RAM_ui_battle_status_static_0_pal = 0x80101ee0
export const RAM_ui_status_star_point_5_pal = 0x801014e0
export const RAM_hos_03_Quizmo_SetCamVfov = 0x80243010
export const RAM_arn_07_FlyingAI_Wander = 0x80240b00
export const RAM_jan_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_802BEB10_31B080 = 0x802beb10
export const RAM_kmr_03_init_802411A8 = 0x802411a8
export const RAM_b_area_kmr_part_2_kmr_05_foregroundModelList_802253CC = 0x802253cc
export const RAM_dgb_18_makeEntities = 0x80243874
export const RAM_D_80232C48_6849C8 = 0x80232c48
export const RAM_create_entity = 0x801118c4
export const RAM_hos_00_UnkEffect0FFunc3 = 0x8024036c
export const RAM_SetActorFlagBits = 0x8026ce8c
export const RAM_HES_CUpButton = 0x8029259c
export const RAM_b_area_kmr_part_1_paragoomba_takeTurn_8021BD5C = 0x8021d74c
export const RAM_flo_07_80241DBC = 0x80241dbc
export const RAM_sbk_24_Bandit_DropCoin = 0x80240040
export const RAM_D_E0122B90 = 0xe0122b90
export const RAM_end_00_CreditsDataPtr = 0x8024f7e4
export const RAM_func_80240000_EDBFE0 = 0x80240000
export const RAM_D_802946E0 = 0x802946e0
export const RAM_battle_item_super_soda_main = 0x802a1ffc
export const RAM_D_802A1690_0073B560 = 0x802a1690
export const RAM_ui_status_text_times_pal = 0x800fff00
export const RAM_b_area_kmr_part_1_kmr_03_afterBattle_80220200 = 0x80220200
export const RAM_SetStatusTable = 0x8027cb08
export const RAM_shattering_stones_appendGfx = 0xe002c414
export const RAM_func_802A9208_429F28 = 0x802a9208
export const RAM_HES_ElectrifiedBegin = 0x801050a8
export const RAM_D_E0056EA8 = 0xe0056ea8
export const RAM_pra_11_reflection_render_floor = 0x8024068c
export const RAM_CamPresetUpdate_C = 0x8024b9a0
export const RAM_battle_item_life_shroom_script6 = 0x802a1abc
export const RAM_jan_02_Quizmo_AudienceEffect = 0x80248944
export const RAM_EE70C0_FlyingAI_Main = 0x80241e1c
export const RAM_func_800EFE2C = 0x800efe2c
export const RAM_HES_MoveRedOrb = 0x801093b0
export const RAM_b_area_jan_hurt_plant_8021DBFC = 0x8021dbfc
export const RAM_b_area_sam2_sam_02b_UnkModelStuff_func1 = 0x802191c0
export const RAM_D_80245850_C859F0 = 0x80245850
export const RAM_func_80242C94_E07FF4 = 0x80242c94
export const RAM_jan_14_PullVine_WaitForPlayerGrab = 0x80240000
export const RAM_b_area_mac_lee_defenseTable_802205E4 = 0x802205e4
export const RAM_gWindows = 0x80159d50
export const RAM_D_802A9AA0_42D9B0 = 0x802a9aa0
export const RAM_b_area_pra3_duplighost_parakarry_handleEvent = 0x802207e0
export const RAM_D_8014C6C8 = 0x8014c6c8
export const RAM_arn_13_80240BF0 = 0x80240bf0
export const RAM_kzn_09_PiranhaPlantAI_00 = 0x802418f0
export const RAM_func_8005087C = 0x8005087c
export const RAM_omo_17_FlyingAI_JumpInit = 0x80241b90
export const RAM_flo_00_8024427C = 0x8024427c
export const RAM_b_area_kmr_part_2_red_goomba_init_8021D078 = 0x8021d078
export const RAM_D_802BFE50_320BC0 = 0x802bfe50
export const RAM_action_command_tidal_wave_draw_hud_elements = 0x802a9718
export const RAM_b_area_trd_part_2_trd_02b_afterBattle_802324C0 = 0x802324c0
export const RAM_basic_ai_chase_init = 0x80049f7c
export const RAM_D_E00BEAF0 = 0xe00beaf0
export const RAM_func_802BB6E0_E2E010 = 0x802bb6e0
export const RAM_hos_01_UpdateTexturePanStepped = 0x802408c4
export const RAM_EC7590_foliage_setup_shear_mtx = 0x802403e0
export const RAM_flo_21_StarSpiritEffectFunc6 = 0x80240708
export const RAM_func_80241A18_7EC728 = 0x80241a18
export const RAM_gPlayerMoveHistory = 0x8010cd38
export const RAM_D_802EC3F8 = 0x802ec3f8
export const RAM_D_8029D378 = 0x8029d378
export const RAM_D_8024E7C8_C0C048 = 0x8024e7c8
export const RAM_HES_Item_Mailbag = 0x80081d58
export const RAM_arn_02_80241040 = 0x80241040
export const RAM_ui_unk_bar_pal = 0x800f9ee0
export const RAM_D_80232B40_6848C0 = 0x80232b40
export const RAM_pra_34_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_HES_YellowArrow = 0x80292138
export const RAM_kmr_06_FlyingAI_Wander = 0x802401b0
export const RAM_D_8015DCE0 = 0x801516e0
export const RAM_omo_17_FlyingAI_Jump = 0x80241c08
export const RAM_osContGetQuery = 0x80061980
export const RAM_arn_13_init_8024076C = 0x8024076c
export const RAM_nok_02_Quizmo_FadeInWorld = 0x80241e88
export const RAM_b_area_iwa_monty_mole_unk_missing_8021A600 = 0x8021a600
export const RAM_kkj_23_UnkModelMatrixFunc = 0x802402f0
export const RAM_au_reset_instrument_entry = 0x800533a8
export const RAM_D_E00C4A90 = 0xe00c4a90
export const RAM_D_8014F52C = 0x8014f52c
export const RAM_func_80251434 = 0x80251434
export const RAM_tik_20_Pipe_GetCameraYaw = 0x802404dc
export const RAM_tik_06_Pipe_SetAnimFlag = 0x80240000
export const RAM_bss_802BFF08 = 0x802bff08
export const RAM_HES_CUpButtonHeld = 0x802925c8
export const RAM_b_area_omo_red_shy_guy_UnkBattleFunc1 = 0x80218000
export const RAM_battle_star_chill_out_UnkBackgroundFunc2 = 0x802a137c
export const RAM_flo_25_func_80240000_CF2BD0 = 0x80240000
export const RAM_b_area_nok_paragoomba_8021B6F0 = 0x8021b6f0
export const RAM_ui_move_basic_pal = 0x80103aa0
export const RAM_D_802704CC = 0x802704cc
export const RAM_kpa_102_ProjectileHitbox_31 = 0x80240744
export const RAM_nok_15_FlyingAI_LosePlayer = 0x8024190c
export const RAM_D_E0056EA0 = 0xe0056ea0
export const RAM_b_area_trd_part_1_trd_05f_802274D0 = 0x802274d0
export const RAM_D_8029D930 = 0x8029d930
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_8022A39C = 0x8022a39c
export const RAM_dgb_00_init_80241FD4 = 0x80241fd4
export const RAM_b_area_isk_part_1_isk_00_beforeBattle_802238B0 = 0x802238b0
export const RAM_D_090024A8 = 0x090024a8
export const RAM_IES_GearHammer1 = 0x8008b444
export const RAM_dgb_15_main = 0x80241da0
export const RAM_HES_MashCRightButton1 = 0x80292524
export const RAM_D_80247958_BF4748 = 0x80247958
export const RAM_func_802A163C_79070C = 0x802a163c
export const RAM_D_80247BB8_EE6578 = 0x80247bb8
export const RAM_big_smoke_puff_update = 0xe00021bc
export const RAM_D_8010D6A4 = 0x8010d6a4
export const RAM_func_80240208_926BF8 = 0x80240208
export const RAM_pra_12_reflection_render_floor = 0x802406bc
export const RAM_battle_item_coconut_PlayerGoHome = 0x802a1670
export const RAM_b_area_trd_part_2_trd_03_clouds1 = 0x80232500
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E550 = 0x8022e550
export const RAM_battle_item_mushroom_func_802A1438_715C88 = 0x802a1438
export const RAM_vtx_C2D170 = 0x8020c230
export const RAM_D_80246538 = 0x80246538
export const RAM_D_80249D34 = 0x80249d34
export const RAM_kzn_08_FlyingNoAttackAI_12 = 0x80241f48
export const RAM_ui_status_pow_unit_2_png = 0x80100740
export const RAM_b_area_sam_duplighost_statusTable = 0x8021af00
export const RAM_nuYieldBuf = 0x800b8590
export const RAM_gPauseDescTextPos = 0x80270110
export const RAM_D_A4001000 = 0xa4001000
export const RAM_b_area_arn_hyper_cleft_80223310 = 0x80223310
export const RAM_pause_partners_init = 0x8024c0e0
export const RAM_bss_802BFF00 = 0x802bff00
export const RAM_D_802AD6A8 = 0x802ad6a8
export const RAM_D_8014C5A0 = 0x8014c5a0
export const RAM_mim_08_FlyingAI_WanderInit = 0x802400a0
export const RAM_SparkleScript_Coin = 0x80104ac0
export const RAM_dgb_01_D_80251B04_C0F384 = 0x80251b04
export const RAM_D_8024404C_92E2FC = 0x8024404c
export const RAM_b_area_sam_sam_02_UnkFloatFunc2 = 0x8021a0ec
export const RAM_tik_02_varStash = 0x802410c0
export const RAM_dgb_15_PatrolAI_MoveInit = 0x802408d0
export const RAM_CamPresetUpdate_K = 0x8024c944
export const RAM_b_area_omo_anti_guy_80222824 = 0x80222824
export const RAM_ui_point_right_pal = 0x800fbbe0
export const RAM_GetLastElement = 0x80269e54
export const RAM_ui_battle_cmd_charge_3_png = 0x8028cf20
export const RAM_battle_item_thunder_bolt_GiveRefundCleanup = 0x802a11d4
export const RAM_sam_08_SuperBlock_SetOverride40 = 0x80240000
export const RAM_vtx_C222D0 = 0x80201390
export const RAM_b_area_omo2_toy_tank_start_stilt_guys_phase = 0x8022dba0
export const RAM_D_80093CE4 = 0x80093ce4
export const RAM_D_802A9988_427358 = 0x802a9988
export const RAM_D_802A2AFC_0073609C = 0x802a2afc
export const RAM_D_802284A8_6CC038 = 0x802284a8
export const RAM_arn_09_802403A0 = 0x802403a0
export const RAM_HES_Item_CocoPop = 0x80083378
export const RAM_arn_07_FlyingAI_Loiter = 0x802411e8
export const RAM_bMarioParts = 0x80283500
export const RAM_b_area_kmr_part_3_final_jr_troopa_UnkBackgroundFunc3 = 0x80218f60
export const RAM_D_8014AFC0 = 0x8014afc0
export const RAM_HES_StatusHeart = 0x80108518
export const RAM_b_area_tik_UnkBattleFunc1 = 0x80218000
export const RAM_gCollisionRayStartY = 0x800a4234
export const RAM_battle_move_shrink_smash_IsBerserkerEquipped = 0x802a1000
export const RAM_D_80246530 = 0x80246530
export const RAM_sam_11_foliage_setup_shear_mtx = 0x80240040
export const RAM_start_script = 0x802c33e0
export const RAM_EDC020_foliage_setup_shear_mtx = 0x80240040
export const RAM_D_80246370_915A20 = 0x80246370
export const RAM_dro_01_EVS_Quizmo_ReturnPlayerToOriginalPos = 0x802466bc
export const RAM_arn_13_init_80240720 = 0x80240720
export const RAM_b_area_kmr_part_3_final_jr_troopa_nextTurn_8023147C = 0x8023147c
export const RAM_sun_init = 0xe0120134
export const RAM_func_80240A5C_9B21EC = 0x80240a5c
export const RAM_PlayModelAnimation = 0x802cce6c
export const RAM_b_area_arn_arn_02_foregroundModelList_80227A58 = 0x80227a58
export const RAM_kmr_09_FlyingAI_JumpInit = 0x80240bdc
export const RAM_sbk_06_foliage_setup_shear_mtx = 0x80240140
export const RAM_D_802AD064 = 0x802ad064
export const RAM_kmr_05_FlyingAI_LosePlayer = 0x80240e58
export const RAM_reload_mesh_animator_node = 0x80120530
export const RAM_nok_15_ParatroopaAI_Reset = 0x802422a8
export const RAM_D_80098488 = 0x80098488
export const RAM_ui_klevar_png = 0x800fda40
export const RAM_ui_battle_cmd_aim_shimmer_4_pal = 0x8028ed80
export const RAM_D_802430A0_C713D0 = 0x802430a0
export const RAM_b_area_sam_duplighost_defenseTable = 0x8021aef4
export const RAM_HES_Item_FlowerFinder_disabled = 0x80085088
export const RAM_filemenu_update_show_name_input_8554F0 = 0x80243380
export const RAM_dgb_16_npcAI_802418DC = 0x802418dc
export const RAM_arn_04_FlyingAI_ChaseInit = 0x80241b50
export const RAM_D_E00C6C90 = 0xe00c6c90
export const RAM_fx_86_init = 0xe0128128
export const RAM_dro_02_Quizmo_UpdatePartnerPosition = 0x80241210
export const RAM_omo_16_UnkBufferFunc = 0x80240168
export const RAM_D_80159D60 = 0x80159d60
export const RAM_pra_06_reflection_unk_change_anim_facing = 0x80240084
export const RAM_dgb_09_SentinelAI_GrabPlayer = 0x80243138
export const RAM_b_area_sam_duplighost_sushie = 0x80224910
export const RAM_func_80242D20_C887D0 = 0x80242d20
export const RAM_func_802425A0_A2C7E0 = 0x802425a0
export const RAM_D_802418A0_D645B0 = 0x802418a0
export const RAM_ui_pip2_png = 0x800fbc40
export const RAM_gPauseTabsGridData = 0x8024f1c8
export const RAM_b_area_kmr_part_3_kmr_06_clouds3 = 0x80233220
export const RAM_dgb_02_npcAI_80241C98 = 0x80241c98
export const RAM_sam_06_TransformFoliage = 0x8024037c
export const RAM_mac_05_PatrolAI_Chase = 0x80240ddc
export const RAM_set_tex_panner = 0x8011bc14
export const RAM_sam_11_Quizmo_SetCamVfov = 0x8024133c
export const RAM_create_encounters = 0x800436b0
export const RAM_D_802AB344 = 0x802ab344
export const RAM_HealActor = 0x80270ca4
export const RAM_dtor = 0x80093dc0
export const RAM_mac_00_PatrolAI_Chase = 0x80242ebc
export const RAM_D_802440E1 = 0x802440e1
export const RAM_iwa_02_CleftAI_FindPlayerInit = 0x80240428
export const RAM_omo_02_StationaryAI_ChaseInit = 0x80241188
export const RAM_dgb_00_npcGroup_802423C4 = 0x802423c4
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_partsTable_8022E504 = 0x8022e504
export const RAM_arn_04_CleftAI_Tackle = 0x80242bbc
export const RAM_b_area_sam2_sam_02_beforeBattle = 0x802224b8
export const RAM_sfx_play_sound_looping = 0x80149908
export const RAM_func_8023803C_70BD4C = 0x8023803c
export const RAM_cloud_trail_appendGfx = 0xe0016454
export const RAM_b_area_mac_lee_takeTurn_80228B78 = 0x80228b78
export const RAM_func_80240A68_C96998 = 0x80240a68
export const RAM_btl_state_update_next_enemy = 0x802472ac
export const RAM_jan_03_PatrolNoAttackAI_15 = 0x802410f4
export const RAM_omo_12_UnkFunc41 = 0x80240000
export const RAM_SaveBlockTutorialPrinter = 0x802eb398
export const RAM_gPauseBadgesShowNotEnoughBP = 0x802703a0
export const RAM_gPauseMapIconIDs = 0x80270700
export const RAM_isk_05_ChompAmbush = 0x80242614
export const RAM_au_bgm_player_update_stop = 0x8004ec04
export const RAM_filemenu_cleanup = 0x80244b00
export const RAM_battle_menu_moveOptionDisplayCostReductions = 0x802ad380
export const RAM_radiating_energy_orb_main = 0xe009e000
export const RAM_func_80218770_6DE530 = 0x80218770
export const RAM_battle_item_volt_shroom_EatItem = 0x802a174c
export const RAM_kpa_03_MagikoopaAI_OnHit = 0x80241dfc
export const RAM_radiating_energy_orb_update = 0xe009e1e4
export const RAM_omo_09_ShyGuyWanderAI_17 = 0x80240bb8
export const RAM_dro_01_init_80248AE4 = 0x80248ae4
export const RAM_sbk_30_StationaryAI_ReturnHomeInit = 0x802408b0
export const RAM_vtx_C240D0 = 0x80203190
export const RAM_flo_08_npcGroup_80243E38 = 0x80243e38
export const RAM_omo_05_GrooveGuyAI_02 = 0x8024056c
export const RAM_entity_WoodenCrate_init = 0x802e7f40
export const RAM_kmr_03_UnkFunc41 = 0x80240000
export const RAM_IES_BowserCastleKey = 0x8008b564
export const RAM_appendGfx_npc_actor = 0x80255fe0
export const RAM_snd_ambient_800555E4 = 0x800555e4
export const RAM_HES_Item_JellySuper_disabled = 0x80083048
export const RAM_D_09003A48_3AEA78 = 0x09003a48
export const RAM_D_8029D938 = 0x8029d938
export const RAM_D_80298668 = 0x80298668
export const RAM_b_area_nok_kent_c_koopa_idleAnimations_80227968 = 0x80227968
export const RAM_func_80240030_BB5A10 = 0x80240030
export const RAM_D_80248768_DCCB38 = 0x80248768
export const RAM_NpcFaceNpc = 0x802cee80
export const RAM_D_800F7FE8 = 0x800f7fe8
export const RAM_npc_do_other_npc_collision = 0x8003900c
export const RAM_b_area_kmr_part_2_kmr_04_clouds2 = 0x80224ca8
export const RAM_b_area_sam_duplighost_bow_idle = 0x802226dc
export const RAM_vtx_C26250 = 0x80205310
export const RAM_flo_13_main = 0x80243dec
export const RAM_battle_partner_watt_UnkBackgroundFunc3 = 0x80238434
export const RAM_entity_Chest_clear_item_id = 0x802e7d28
export const RAM_flo_08_pad_2EA8 = 0x80242ea8
export const RAM_D_09000430_392870 = 0x09000430
export const RAM_D_80242EBC_8719BC = 0x80242ebc
export const RAM_gPauseBadgesPages = 0x80270288
export const RAM_ui_ultra_boots_pal = 0x800fb560
export const RAM_entity_Chest_idle = 0x802e70b0
export const RAM_dgb_01_D_80252814_C10094 = 0x80252814
export const RAM_D_8014F108 = 0x8014f108
export const RAM_arn_04_PatrolAI_Loiter = 0x802404c0
export const RAM_HES_Item_HoneyUltra = 0x80082898
export const RAM_IncrementPlayerFP = 0x80261d60
export const RAM_HES_Item_GearHammer1 = 0x80081368
export const RAM_dgb_10_80240250 = 0x80240250
export const RAM_ui_point_right_png = 0x800fbb60
export const RAM_flo_03_npcGroup_80243A7C = 0x80243a7c
export const RAM_flo_23_main = 0x8024114c
export const RAM_vtx_curtains4 = 0x80075b70
export const RAM_entity_HiddenPanel_set_ispy_notification = 0x802e6338
export const RAM_func_800EF314 = 0x800ef314
export const RAM_b_area_omo2_light_bulb_parts = 0x8022f6c0
export const RAM_HES_Item_PleaseComeBack_disabled = 0x80082508
export const RAM_b_area_nok_koopa_troopa_handleEvent_8021D218 = 0x80220798
export const RAM_b_area_iwa_formation_03 = 0x802182b8
export const RAM_D_802DF3F0 = 0x802df3f0
export const RAM_func_80242760_9C7B40 = 0x80242760
export const RAM_func_8026D51C = 0x8026d51c
export const RAM_omo_02_ShyGuyWanderAI_Main = 0x80240ba8
export const RAM_PlayMerleeOrbFX = 0x8003f4cc
export const RAM_flo_08_UnkFloAI_Main = 0x8024150c
export const RAM_D_8023D298 = 0x8023d298
export const RAM_peach_star_beam_appendGfx = 0xe010c39c
export const RAM_D_802314A0_63D040 = 0x802314a0
export const RAM_func_80240DA0_A2AFE0 = 0x80240da0
export const RAM_func_80238C88_700A08 = 0x80238c88
export const RAM_kmr_02_foliage_setup_shear_mtx = 0x80243760
export const RAM_HES_Item_Hammer3 = 0x80080700
export const RAM_func_80241BC0_EAA6A0 = 0x80241bc0
export const RAM_flo_12_exitWalk_80240B1C = 0x80240b1c
export const RAM_flo_24_shakeTree = 0x802410a0
export const RAM_b_area_mac_lee_formation_parakarry = 0x802243f4
export const RAM_func_E00CE470 = 0xe00ce470
export const RAM_SwitchMessage = 0x802d0afc
export const RAM_pra_09_reflection_setup_wall = 0x80240128
export const RAM_func_8024030C_AE589C = 0x8024030c
export const RAM_UseBattleCamPreset = 0x8024e61c
export const RAM_flo_03_tree1_Drops = 0x80244df4
export const RAM_b_area_kmr_part_2_red_goomba_2_init_802207B8 = 0x802207b8
export const RAM_b_area_sam2_monstar_attack = 0x8021ab64
export const RAM_b_area_kgr_fuzzipede_8021A6D8 = 0x8021a6d8
export const RAM_sbk_34_LetterDelivery_SavedNpcAnim = 0x802429a4
export const RAM_dgb_01_D_80252DEC_C1066C = 0x80252dec
export const RAM_b_area_sam2_paragoomba_defenseTable_8021B110 = 0x8021be90
export const RAM_EC9D00_SuperBlock_LoadCurrentPartnerName = 0x80240448
export const RAM_func_8027D434 = 0x8027d434
export const RAM_sam_11_Quizmo_GetItemName = 0x80240884
export const RAM_tik_12_SuperBlock_RadiateFaintEnergyFX = 0x802411c4
export const RAM_b_area_hos_magikoopa_init_8021E2C0 = 0x8021e2c0
export const RAM_D_8024F2E8 = 0x8024f2e8
export const RAM_b_area_sam_sam_02c_UnkModelStuff_array = 0x8022fb70
export const RAM_dgb_01_D_8025239C_C0FC1C = 0x8025239c
export const RAM_pra_14_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_80294260 = 0x80294260
export const RAM_b_area_mim_forest_fuzzy_UnkBattleFunc2_2 = 0x802180b4
export const RAM_D_E006EC28 = 0xe006ec28
export const RAM_fx_small_gold_sparkle = 0x800713f0
export const RAM_EE2040_LoadPartyImage = 0x80243680
export const RAM_gPartnerAnimations = 0x800f8348
export const RAM_func_80238014_710F04 = 0x80238014
export const RAM_b_area_sam_duplighost_bombette = 0x80220458
export const RAM_IES_Unused_0F1 = 0x8008cb04
export const RAM_func_80242BD0_9D9BF0 = 0x80242bd0
export const RAM_HES_StatHammer2 = 0x8024295c
export const RAM_nok_11_UnkDistFunc = 0x8024007c
export const RAM_HES_HPDigit9 = 0x80292110
export const RAM_mac_00_UpdateTexturePanSmooth = 0x802402e0
export const RAM_Entity_ShatteringHammer2Block = 0x802e9cb0
export const RAM_b_area_omo_omo_04_Add1Coin = 0x802191e8
export const RAM_flo_23_npcGroup_80242188 = 0x80242188
export const RAM_HES_WattDisabled = 0x80107eb0
export const RAM_battle_partner_goombario_8023C5B8 = 0x8023c5b8
export const RAM_b_area_sam_sam_03_Set80071270_0_16 = 0x8021ad48
export const RAM_kkj_20_SomeItemEntityFunc = 0x80240118
export const RAM_pause_items_get_pos_x = 0x802497d0
export const RAM_battle_star_smooch_802A1F6C = 0x802a1f6c
export const RAM_set_custom_gfx_builders = 0x8011bcd0
export const RAM_ECAA80_FlowerGuard_MakeItemList = 0x80241bf8
export const RAM_D_80257B88 = 0x80257b88
export const RAM_pra_16_reflection_setup_wall = 0x80240128
export const RAM_vtx_C2D620 = 0x8020c6e0
export const RAM_D_8024F2E0 = 0x8024f2e0
export const RAM_D_8024F2A5 = 0x8024f2a5
export const RAM_battle_star_smooch_UnkStarFunc = 0x802a1494
export const RAM_func_802A98DC = 0x802a98dc
export const RAM_D_09000230_330690 = 0x09000230
export const RAM_b_area_sam_frost_piranha_attack_icy_breath = 0x8022bd44
export const RAM_mac_05_Quizmo_NPC_OnRender = 0x802416ec
export const RAM_AddPartMovementVar = 0x8026c2e8
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_init_80228618 = 0x80228618
export const RAM_battle_star_star_storm_UnkStarFunc4 = 0x802a1218
export const RAM_jan_22_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_mac_03_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_kzn_04_SuperBlock_ShowSelectPartnerMenu = 0x802404dc
export const RAM_D_E000CC5C = 0xe000cc5c
export const RAM_D_802A2DD8 = 0x802a2dd8
export const RAM_D_800A41C0 = 0x800a41c0
export const RAM_HES_Item_GroupFocus = 0x80085b38
export const RAM_D_80242B90_BB5990 = 0x80242b90
export const RAM_func_802406E0_A39BC0 = 0x802406e0
export const RAM_omo_08_UpdateTexturePanSmooth = 0x8024055c
export const RAM_arn_04_FlyingAI_WanderInit = 0x80240e90
export const RAM_sfx_register_looping_sound_at_position = 0x80149974
export const RAM_pra_12_ReflectPartner = 0x80240d6c
export const RAM_b_area_jan_spike_top_idleAnimations_8021C9D0 = 0x80223c40
export const RAM_D_802447D8_A3DCB8 = 0x802447d8
export const RAM_corrupted_init = 0x8006d080
export const RAM_D_802AD001 = 0x802ad001
export const RAM_D_80151050 = 0x80151050
export const RAM_dgb_04_FlyingAI_Main = 0x8024130c
export const RAM_D_800778C0 = 0x800778c0
export const RAM_tik_07_FlyingAI_JumpInit = 0x80241fcc
export const RAM_D_80245858_C76CD8 = 0x80245858
export const RAM_b_area_trd_part_1_paratroopa_nextTurn_8021EDE4 = 0x8021ede4
export const RAM_HES_Item_DizzyDial = 0x800877b8
export const RAM_au_SEFCmd_0A_EndLoop = 0x8004d130
export const RAM_sbk_02_CheckTradeEventTime = 0x80240b98
export const RAM_battle_item_dizzy_dial_GiveRefund = 0x802a1000
export const RAM_D_8024BE40_D2BCD0 = 0x8024be40
export const RAM_func_80055B80 = 0x80055b80
export const RAM_D_80241F00_901570 = 0x80241f00
export const RAM_nok_15_FlyingAI_WanderInit = 0x80240ab4
export const RAM_func_80056D50 = 0x80056d50
export const RAM_D_802AA694 = 0x802aa694
export const RAM_D_800B0B1C = 0x800b0b1c
export const RAM_tik_20_Pipe_GetEntryPos = 0x802403fc
export const RAM_jan_02_Quizmo_ShowEntities = 0x80241740
export const RAM_D_80156970 = 0x80156970
export const RAM_b_area_nok_kent_c_koopa_8022B204 = 0x8022b204
export const RAM_func_80240000_D110C0 = 0x80240000
export const RAM_D_802440FF = 0x802440ff
export const RAM_omo_08_ShyGuyWanderAI_15 = 0x802408e0
export const RAM_dro_02_Quizmo_FadeInWorld = 0x80240878
export const RAM_flo_09_npcAI_80243674 = 0x80243674
export const RAM_arn_05_PatrolAI_MoveInit = 0x80240000
export const RAM_D_E010CA38 = 0xe010ca38
export const RAM_D_802348D0_6BBAD0 = 0x802348d0
export const RAM_vtx_C29F50 = 0x80209010
export const RAM_b_area_sam2_monstar_StartRumbleWithParams = 0x802184d4
export const RAM_EED1E0_SuperBlock_LoadCurrentPartnerName = 0x80240528
export const RAM_osTvType = 0x80000300
export const RAM_mgm_01_UpdatePanelMoveToTally = 0x80240aac
export const RAM_jan_00_resolve_npc = 0x802411a0
export const RAM_gPauseWS_21 = 0x8026fd98
export const RAM_dup_b_area_flo2_UnkFloatFunc = 0x80218c40
export const RAM_D_80245230 = 0x80245230
export const RAM_b_area_arn_tubba_blubba_init_80225D98 = 0x80225d98
export const RAM_kpa_51_MagikoopaAI_CanShootSpell = 0x80240050
export const RAM_dup3_b_area_flo_ShrinkActor = 0x802190b0
export const RAM_arn_03_padding3 = 0x802447dc
export const RAM_dgb_09_FlyingAI_Jump = 0x80242078
export const RAM_pra_05_reflection_unk_change_anim_facing = 0x80240054
export const RAM_b_area_omo2_1_shy_squad_onDeath = 0x8023465c
export const RAM_jan_08_MeleeHitbox_30 = 0x80241668
export const RAM_tik_19_SuperBlock_WaitForPlayerToLand = 0x802412f8
export const RAM_mac_02_Quizmo_NPC_Aux_Impl = 0x80241b6c
export const RAM_btl_delete_actor_D7F91C = 0x80240bbc
export const RAM_mim_07_HoppingAI_HopInit = 0x80240270
export const RAM_func_80240CD4_B076D4 = 0x80240cd4
export const RAM_HES_Item_RepelGel = 0x80082658
export const RAM_entity_HeartBlock_wait_for_close_tutorial = 0x802e555c
export const RAM_battle_item_thunder_bolt_UseItemWithEffect = 0x802a14a0
export const RAM_pra_16_ReflectPartner = 0x80240d3c
export const RAM_pra_12_reflection_render_floor_fancy = 0x802408a0
export const RAM_dgb_01_D_80251D14_C0F594 = 0x80251d14
export const RAM_HES_MenuHammer3 = 0x801088d4
export const RAM_disable_x_main = 0xe0082000
export const RAM_dgb_08_npcSettings_8024417C = 0x8024417c
export const RAM_b_area_jan_spear_guy_specialFormation_8021A8DC = 0x8021a8dc
export const RAM_appendGfx_player_actor = 0x80257da4
export const RAM_D_80244E20_A1B040 = 0x80244e20
export const RAM_dispatch_damage_event_actor_0 = 0x802788f8
export const RAM_dgb_08_PatrolAI_Loiter = 0x802404c0
export const RAM_sbk_35_foliage_setup_shear_mtx = 0x80240140
export const RAM_D_802A1CD4 = 0x802a1cd4
export const RAM_D_090021D0 = 0x090021d0
export const RAM_vtx_C224E0 = 0x802015a0
export const RAM_drop_leaves_init = 0xe0028240
export const RAM_func_802A92D4_4285B4 = 0x802a92d4
export const RAM_ui_status_text_7_pal = 0x80100460
export const RAM_b_area_kmr_part_3_paragoomba2_defenseTable_8021B11C = 0x8021f75c
export const RAM_func_80240A54_A0C744 = 0x80240a54
export const RAM_kzn_04_UpdateTexturePanSmooth = 0x80240000
export const RAM_filemenu_draw_contents_file_2_info = 0x80245628
export const RAM_b_area_iwa_iwa_01_idle_80220B0C = 0x80220b0c
export const RAM_D_80097E18 = 0x80097e18
export const RAM_func_8024046C_AD633C = 0x8024046c
export const RAM_HES_PartnerRank1A = 0x80109298
export const RAM_D_090002E8_364EE8 = 0x090002e8
export const RAM_battle_partner_goombario_8023A06C = 0x8023a06c
export const RAM_kpa_91_ToadHouse_PutPartnerAway = 0x802417b8
export const RAM_D_802EA744 = 0x802ea744
export const RAM_pra_13_ReflectFloor = 0x8024049c
export const RAM_sam_11_Quizmo_HideWorld = 0x80240bfc
export const RAM_D_8024D390_C0AC10 = 0x8024d390
export const RAM_GravityParamsStartFall = 0x800f7b60
export const RAM_dup_kmr_20_TransformFoliage = 0x80240e90
export const RAM_tik_09_Pipe_GetEntryPos = 0x802400f0
export const RAM_entity_model_set_shadow_color = 0x8010fe68
export const RAM_dead_fx_spirit_card = 0x80077930
export const RAM_D_80243C40_88C810 = 0x80243c40
export const RAM_b_area_isk_part_1_swooper_defenseTable_8021C4B0 = 0x8021c4b0
export const RAM_mim_05_PiranhaPlantAI_Main = 0x802417c4
export const RAM_dro_02_ToadHouse_DoesPlayerNeedSleep = 0x80243644
export const RAM_sbk_26_SpawnSunEffect = 0x80240000
export const RAM_b_area_pra3_duplighost_bow_defenseTable = 0x802215e8
export const RAM_b_area_kgr_fuzzipede_8021B22C = 0x8021b22c
export const RAM_nuContPakMgrRemove = 0x8005f6d4
export const RAM_EA8AE0_LetterDelivery_RestoreNpcAnim = 0x80241b94
export const RAM_D_800B451D = 0x800b451d
export const RAM_battle_item_fright_jar_EatItem = 0x802a182c
export const RAM_flo_24_UpdateTexturePanSmooth = 0x80240280
export const RAM_D_E007C510 = 0xe007c510
export const RAM_func_802B72C0_E22870 = 0x802b72c0
export const RAM_D_80248B10_82BDD0 = 0x80248b10
export const RAM_HES_SmallText0 = 0x802420f0
export const RAM_kkj_00_PatrolNoAttackAI_Main = 0x80240c44
export const RAM_ui_battle_status_shrink_0_pal = 0x80102b60
export const RAM_kzn_07_FlyingAI_Chase = 0x80241614
export const RAM_BindNpcAI = 0x80044a78
export const RAM_ui_battle_cmd_timing_1_chance_png = 0x8028c9e0
export const RAM_osSpTaskStartGo = 0x80065c1c
export const RAM_kmr_04_varStash = 0x802429f4
export const RAM_HES_AsleepBegin = 0x80104cc4
export const RAM_func_80218250_52B8F0 = 0x80218250
export const RAM_D_80151344 = 0x80151344
export const RAM_b_area_omo2_3_shy_stack_statusTable = 0x8023103c
export const RAM_nok_15_UnkNpcAIFunc12 = 0x80240490
export const RAM_D_802988F0 = 0x802988f0
export const RAM_npc_remove_decoration_bowser_aura = 0x8003c78c
export const RAM_b_area_kmr_part_2_goomnut_tree_shakeTree = 0x80223890
export const RAM_tik_03_Pipe_AwaitDownInput = 0x80240034
export const RAM_b_area_jan_hurt_plant_takeTurn_8021E33C = 0x8021e33c
export const RAM_func_802383C0_703EB0 = 0x802383c0
export const RAM_func_802B6648_E27428 = 0x802b6648
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021C62C = 0x8021c62c
export const RAM_b_area_tik_UpdateTexturePanSmooth = 0x80219db0
export const RAM_hos_03_FlyingAI_Init = 0x80241e98
export const RAM_func_802BD100_320C50 = 0x802bd100
export const RAM_dgb_01_enterDoubleDoor_80243A3C = 0x80243a3c
export const RAM_dro_02_EVS_Quizmo_MovePartnerToPodium = 0x80245408
export const RAM_ui_empty_bar_png = 0x800fcb60
export const RAM_HES_HPDigit1 = 0x80291fd0
export const RAM_func_80240738_B4B1A8 = 0x80240738
export const RAM_dgb_09_MeleeHitbox_Main = 0x802403bc
export const RAM_D_8023D290 = 0x8023d290
export const RAM_b_area_isk_part_2_chain_chomp_8021B50C = 0x8021b50c
export const RAM_dgb_02_ClubbaNappingAI_Sleep = 0x80240958
export const RAM_dgb_01_D_80253420_C10CA0 = 0x80253420
export const RAM_SetBattleCamZoom = 0x8024ec6c
export const RAM_D_80234790_6BB990 = 0x80234790
export const RAM_pra_35_PatrolAI_PostLoiter = 0x8024159c
export const RAM_func_80027BAC = 0x80027bac
export const RAM_SpeakToNpc = 0x802d0304
export const RAM_D_8014B766 = 0x8014b766
export const RAM_b_area_mac_lee_bow = 0x8022455c
export const RAM_func_802A9258_422258 = 0x802a9258
export const RAM_b_area_arn_tubbas_heart_80224B3C = 0x80224b3c
export const RAM_flo_18_802452D0 = 0x802452d0
export const RAM_func_80252B3C = 0x80252b3c
export const RAM_kmr_03_tree1_Callback = 0x802421a8
export const RAM_D_80244A20 = 0x80244a20
export const RAM_flo_16_npcSettings_802440C8 = 0x802440c8
export const RAM_kpa_115_UnkFunc12 = 0x802400bc
export const RAM_D_802313B0_63CF50 = 0x802313b0
export const RAM_D_80099B70 = 0x80099b70
export const RAM_D_8024EF68_978128 = 0x8024ef68
export const RAM_omo_13_CheckItemFlags40 = 0x80241498
export const RAM_D_80097E10 = 0x80097e10
export const RAM_battle_item_electro_pop_UseItem = 0x802a1844
export const RAM_mim_08_FlyingAI_LoiterInit = 0x80240878
export const RAM_D_E003CD1C = 0xe003cd1c
export const RAM_gCurrentSaveFile = 0x800dacc0
export const RAM_D_802BFED0_320C40 = 0x802bfed0
export const RAM_btl_cam_target_actor_part = 0x8024e45c
export const RAM_mim_01_PiranhaPlantAI_01 = 0x80240e3c
export const RAM_PlayModelAnimationStartingFrom = 0x802ccedc
export const RAM_HES_Item_Mystery = 0x800825f8
export const RAM_sbk_30_LetterDelivery_RestoreNpcAnim = 0x802415a4
export const RAM_b_area_omo_green_shy_guy_idle_8021FFB4 = 0x8021ffb4
export const RAM_b_area_trd_part_2_green_ninja_koopa_nextTurn_802284E4 = 0x802284e4
export const RAM_arn_03_exitWalk_8024188C = 0x8024188c
export const RAM_GetEncounterTriggerHitTier = 0x8004580c
export const RAM_draw_image_with_clipping = 0x8012ec94
export const RAM_pause_textbox_draw_contents = 0x80243570
export const RAM_EF2680_FlyingAI_Jump = 0x80240cc4
export const RAM_D_802ED970 = 0x802ed970
export const RAM_b_area_pra3_duplighost_watt_statusTable = 0x802229cc
export const RAM_get_player_back_anim = 0x800e0fec
export const RAM_func_80269118 = 0x80269118
export const RAM_kzn_02_FlyingAI_LoiterInit = 0x80240cb8
export const RAM_D_80294300 = 0x80294300
export const RAM_D_802A2230 = 0x802a2230
export const RAM_kzn_11_FireBarAI_Main = 0x80241f4c
export const RAM_dgb_00_80240950 = 0x80240950
export const RAM_CamPreset_G = 0x80280d3c
export const RAM_D_80254628_9EB648 = 0x80254628
export const RAM_func_802402C0_BCEEB0 = 0x802402c0
export const RAM_b_area_kgr_formation_00 = 0x8021d5e0
export const RAM_vtx_C25B30 = 0x80204bf0
export const RAM_flo_14_updateTexturePan_80242460 = 0x80242460
export const RAM_D_80159880 = 0x80159880
export const RAM_b_area_isk_part_1_isk_05_DeleteTorchFX = 0x80218a10
export const RAM_b_area_pra2_crystal_king_MakeIllusions = 0x8021c834
export const RAM_dup2_hos_06_StashVars = 0x80241fd0
export const RAM_dgb_15_exitDoubleDoor_802419C8 = 0x802419c8
export const RAM_dgb_01_D_802533A0_C10C20 = 0x802533a0
export const RAM_func_80240940_86D080 = 0x80240940
export const RAM_mim_05_PiranhaPlantAI_01 = 0x802409ac
export const RAM_D_800AF8AC = 0x800af8ac
export const RAM_nok_01_StationaryAI_Main = 0x802407f4
export const RAM_mac_04_Quizmo_CreateStage = 0x80240bd0
export const RAM_tik_17_Pipe_SetAnimFlag = 0x80240d70
export const RAM_flo_08_foliage_setup_shear_mtx = 0x80241c00
export const RAM_recover_update = 0xe00801d0
export const RAM_SetAnimatedModelRootScale = 0x802cd29c
export const RAM_dup_b_area_pra_ShrinkActor = 0x80218670
export const RAM_func_80144E4C = 0x80144e4c
export const RAM_D_09001148 = 0x09001148
export const RAM_D_80244B2C_8B4B9C = 0x80244b2c
export const RAM_ui_battle_menu_flee_png = 0x80289640
export const RAM_b_area_mac_lee_idleAnimations_8022840C = 0x8022840c
export const RAM_CreateVirtualEntityAt = 0x802d33d4
export const RAM_footprint_update = 0xe00182b4
export const RAM_SetActionResult = 0x802695d4
export const RAM_kzn_11_FlyingAI_Loiter = 0x80240cf8
export const RAM_dead_is_point_within_region = 0x8004d0c4
export const RAM_dgb_16_exitSingleDoor_80241520 = 0x80241520
export const RAM_b_area_kmr_part_1_formation_02 = 0x80219234
export const RAM_vtx_C257F0 = 0x802048b0
export const RAM_pause_map_draw_contents = 0x8024db1c
export const RAM_isk_13_StoneChompFXB = 0x802411f8
export const RAM_npc_test_move_complex_with_slipping = 0x800ddd94
export const RAM_func_8024296C_C6367C = 0x8024296c
export const RAM_b_area_kzn_kzn_04c_beforeBattle_80228C2C = 0x80228c2c
export const RAM_HES_Item_Artifact = 0x800815d8
export const RAM_UseItemDmaArgs = 0x800f8010
export const RAM_b_area_jan2_jan_04b = 0x8021f588
export const RAM_tik_07_SuperBlock_PartnerIDs = 0x802439f8
export const RAM_b_area_flo_UnkFloatFunc = 0x802181f0
export const RAM_dgb_04_80243A0C = 0x80243a0c
export const RAM_ui_battle_cont_c_down_up_png = 0x8028c450
export const RAM_mac_05_PatrolAI_Jump = 0x80240c34
export const RAM_flo_00_80242AF0 = 0x80242af0
export const RAM_flo_15_npcGroupList_802412C0 = 0x802412c0
export const RAM_D_8009E6C8 = 0x8009e6c8
export const RAM_snaking_static_appendGfx = 0xe00ae54c
export const RAM_D_802700D = 0x802700d8
export const RAM_sam_01_Quizmo_FadeInWorld = 0x80240c78
export const RAM_nuContRmbMgrInit = 0x8005fc8c
export const RAM_battle_menu_moveOptionNames = 0x802ad2c0
export const RAM_func_802190D0_5CF760 = 0x802190d0
export const RAM_pra_35_MeleeHitbox_32 = 0x80241e8c
export const RAM_D_80150018 = 0x80150018
export const RAM_ECAA80_GetNpcCollisionHeight = 0x80241770
export const RAM_b_area_iwa_cleft_80218944 = 0x80218944
export const RAM_D_8024E120_C0B9A0 = 0x8024e120
export const RAM_D_09000400_3AB430 = 0x09000400
export const RAM_D_8025B2A8 = 0x8025b2a8
export const RAM_func_802405EC_B351BC = 0x802405ec
export const RAM_EC7590_TransformFoliage = 0x80240450
export const RAM_mac_03_foliage_setup_shear_mtx = 0x80241d00
export const RAM_b_area_mac_lee_statusTable_802205F0 = 0x802205f0
export const RAM_HES_Item_SuperShroom = 0x80086738
export const RAM_b_area_omo_sky_guy_8022B2AC = 0x8022b2ac
export const RAM_D_802515B0_C0EE30 = 0x802515b0
export const RAM_pra_06_SetPartnerFlags20000 = 0x80240f1c
export const RAM_dup2_kmr_20_Pipe_GetCurrentFloor = 0x80241d70
export const RAM_action_command_whirlwind_free_hud_elements = 0x802a9898
export const RAM_D_802452B8_C7BF98 = 0x802452b8
export const RAM_tik_14_Pipe_GetCameraYaw = 0x802401d0
export const RAM_tik_23_set_script_owner_npc_anim = 0x80240960
export const RAM_D_80244E78 = 0x80244e78
export const RAM_ui_battle_status_transparent_5_png = 0x80103080
export const RAM_D_802BCDC8_E2F6F8 = 0x802bcdc8
export const RAM_dup_hos_06_GetItemName = 0x80242084
export const RAM_b_area_dgb_formation_01 = 0x8021b24c
export const RAM_D_09001360_327770 = 0x09001360
export const RAM_D_800DC064 = 0x800dc064
export const RAM_b_area_nok_koopa_troopa_idle_8021CD30 = 0x802202b0
export const RAM_EB1170_varStash = 0x80241780
export const RAM_mac_05_UpdatePosFX3D = 0x80243cd4
export const RAM_D_80242370_D13430 = 0x80242370
export const RAM_omo_17_FlyingAI_Main = 0x802422c0
export const RAM_D_E0200718 = 0xe0200718
export const RAM_func_8024093C_8756FC = 0x8024093c
export const RAM_b_area_iwa_whacka_idleAnimations_8021FC50 = 0x8021fc50
export const RAM_b_area_nok_nok_03_foregroundModelList_8022BB50 = 0x8022bb50
export const RAM_D_8010CD00 = 0x8010cd00
export const RAM_dup_kkj_10_UnkModelMatrixFunc2 = 0x80240b5c
export const RAM_HES_Item_SmashCharge0 = 0x800842d8
export const RAM_D_802AD614 = 0x802ad614
export const RAM_screen_overlay_frontType = 0x80156900
export const RAM_battle_item_mushroom_func_802A123C_715A8C = 0x802a123c
export const RAM_osViGetCurrentFramebuffer = 0x800669b0
export const RAM_nok_02_Quizmo_ShouldAppear = 0x80241a4c
export const RAM_dead_sin_cos_deg = 0x8002b524
export const RAM_spr_init_player_raster_cache = 0x802ded60
export const RAM_isk_05_HoppingAI_Loiter = 0x80240628
export const RAM_arn_07_idle_80244C54 = 0x80244c54
export const RAM_dgb_00_exitDoubleDoor_8024021C = 0x8024021c
export const RAM_bgm_reset_volume = 0x8014a52c
export const RAM_fx_3D_render = 0xe007a840
export const RAM_battle_move_power_bounce_UnkMoveFunc1 = 0x802a1000
export const RAM_CreateVirtualEntity_ALT = 0x802d354c
export const RAM_D_8029FB50 = 0x8029fb50
export const RAM_D_80151130 = 0x80151130
export const RAM_mac_03_Quizmo_DestroyEffects = 0x80240e38
export const RAM_arn_13_80240440 = 0x80240440
export const RAM_battle_star_peach_focus_SetNpcCollision32 = 0x802a14e8
export const RAM_HES_TimingGaugeResult = 0x80292b28
export const RAM_dgb_15_WanderMeleeAI_Main = 0x8024061c
export const RAM_b_area_kmr_part_2_kmr_03_clouds3 = 0x80224990
export const RAM_D_802347A8_6BB9A8 = 0x802347a8
export const RAM_clear_saved_variables = 0x80145320
export const RAM_battle_move_auto_smash_IsRightOnEquipped = 0x802a1050
export const RAM_paragoomba = 0x8021cd00
export const RAM_mac_03_Quizmo_GetItemName = 0x80240564
export const RAM_ui_battle_cmd_right_on_pal = 0x8028e3c0
export const RAM_world_parakarry_init = 0x802bd100
export const RAM_gStatsHammerElements = 0x8024f370
export const RAM_load_split_entity_data = 0x80111408
export const RAM_D_802BEBBC = 0x802bebbc
export const RAM_D_80245FE8_9CB3C8 = 0x80245fe8
export const RAM_D_09000820_3AB850 = 0x09000820
export const RAM_kmr_09_FlyingAI_Loiter = 0x80240898
export const RAM_D_8024652C = 0x8024652c
export const RAM_pause_partners_draw_contents = 0x8024aef8
export const RAM_flo_03_80240814 = 0x80240814
export const RAM_ui_battle_status_dizzy_1_pal = 0x80102200
export const RAM_kzn_17_MeleeHitbox_30 = 0x80240310
export const RAM_spaces = 0x800945e0
export const RAM_flo_21_80241560 = 0x80241560
export const RAM_sbk_11_Bandit_DropCoin = 0x80240040
export const RAM_func_80243EE0_C75360 = 0x80243ee0
export const RAM_vtx_C26710 = 0x802057d0
export const RAM_omo_05_FlyingAI_ChaseInit = 0x80241714
export const RAM_D_802B7BE0_E23190 = 0x802b7be0
export const RAM_D_80245430 = 0x80245430
export const RAM_b_area_jan2_putrid_piranha_defenseTable_8021B0EC = 0x8021870c
export const RAM_flo_00_func_80240F10_C9EE30 = 0x80240f10
export const RAM_kkj_03_SetPlayerSpriteSet2 = 0x80240000
export const RAM_GetActorLevel = 0x8027fd48
export const RAM_mgm_01_UpdatePanelEmergeFromBlock = 0x802406c4
export const RAM_D_80243248_A9C7D8 = 0x80243248
export const RAM_D_802423B0_9EDBA0 = 0x802423b0
export const RAM_D_8014BFB8 = 0x8014bfb8
export const RAM_func_802BD368_31E0D8 = 0x802bd368
export const RAM_b_area_isk_part_2_tutankoopa_idleAnimations_8021B960 = 0x8021b960
export const RAM_mac_01_ToadHouse_DisableStatusMenu = 0x802437ac
export const RAM_func_80056204 = 0x80056204
export const RAM_b_area_kgr_fuzzipede_jump_text = 0x8021affc
export const RAM_tik_02_SomeItemEntityFunc = 0x80240428
export const RAM_D_8009A694 = 0x8009a694
export const RAM_D_80242D58_918AC8 = 0x80242d58
export const RAM_GetActorRotation = 0x8026c56c
export const RAM_D_802483C0 = 0x802483c0
export const RAM_b_area_jan_formation_0E = 0x802289fc
export const RAM_D_E01146A0 = 0xe01146a0
export const RAM_dro_02_Quizmo_UnkStageEffectMode = 0x80240d70
export const RAM_D_802BE92C = 0x802be92c
export const RAM_D_E0200710 = 0xe0200710
export const RAM_IES_JamminJelly = 0x8008db14
export const RAM_func_80243680_803F00 = 0x80243680
export const RAM_D_802A9760_42A480 = 0x802a9760
export const RAM_func_8023F088 = 0x8023f088
export const RAM_btl_show_variable_battle_message = 0x8024fa04
export const RAM_IES_HotShroom = 0x8008d0f4
export const RAM_mac_04_Quizmo_SetCamVfov = 0x80240fcc
export const RAM_func_80238E04_6F1EE4 = 0x80238e04
export const RAM_nok_01_Quizmo_VannaTEffect = 0x80251c68
export const RAM_HES_Item_KooperShell = 0x80081578
export const RAM_D_80246550_kkj_19 = 0x80246550
export const RAM_D_80246508_C60C48 = 0x80246508
export const RAM_func_8004E0F4 = 0x8004e0f4
export const RAM_b_area_sam_white_clubba_defenseTable = 0x80229b0c
export const RAM_isk_10_SuperBlock_UpgradeDescMessages = 0x80241118
export const RAM_D_8015A2BC = 0x8015a2bc
export const RAM_dgb_10_8024034C = 0x8024034c
export const RAM_EF2680_FlyingAI_LoiterInit = 0x80240848
export const RAM_kpa_50_ProjectileHitbox_32 = 0x80240478
export const RAM_omo_07_FlyingAI_Wander = 0x80241b28
export const RAM_D_802465C0_915C70 = 0x802465c0
export const RAM_dgb_08_init_802464C4 = 0x802464c4
export const RAM_b_area_kzn_formation_13 = 0x80229db0
export const RAM_b_area_jan2_jan_04b_beforeBattle_8021F520 = 0x8021f520
export const RAM_b_area_omo_pink_shy_guy_8021E5C4 = 0x8021e5c4
export const RAM_b_area_omo2_4_signal_guy_idleAnimations = 0x80231104
export const RAM_D_090000A0_330500 = 0x090000a0
export const RAM_pra_01_reflection_unk_change_anim_facing = 0x80240054
export const RAM_b_area_trd_part_2_fake_bowser_D_8021AE50_48C040 = 0x8021ae50
export const RAM_D_8009A67C = 0x8009a67c
export const RAM_HES_GreenArrowDown = 0x80107798
export const RAM_D_802A9952_427322 = 0x802a9952
export const RAM_battle_move_auto_multibounce_D_802A10F0 = 0x802a10f0
export const RAM_b_area_kzn_lava_bubble_fireballs_Partner = 0x8021a274
export const RAM_gPausePartnersPortraitScrollInterpTable = 0x8024f87c
export const RAM_D_80245154_8572C4 = 0x80245154
export const RAM_func_80240344_7E76E4 = 0x80240344
export const RAM_dgb_08_npcGroup_80245E30 = 0x80245e30
export const RAM_b_area_iwa_monty_mole_vector3D_8021A6B8 = 0x8021a6b8
export const RAM_ascii_EA6D78 = 0x80246478
export const RAM_b_area_kmr_part_2_goomnut_tree_shake_goomnut_tree = 0x80223d90
export const RAM_arn_13_npcAISettings_8024047C = 0x8024047c
export const RAM_EA8AE0_LetterDelivery_SavedNpcAnim = 0x8024854c
export const RAM_D_80294400 = 0x80294400
export const RAM_D_802470B8_DC4368 = 0x802470b8
export const RAM_dro_01_PatrolAI_Loiter = 0x80240aa0
export const RAM_arn_03_PatrolAI_Jump = 0x802407a4
export const RAM_iwa_01_CleftAI_LosePlayer = 0x80242c48
export const RAM_flo_16_settings = 0x80242990
export const RAM_D_802A1690_00734C30 = 0x802a1690
export const RAM_b_area_trd_part_2_trd_05f_beforeBattle_80235C24 = 0x80235c24
export const RAM_dgb_08_PatrolAI_Jump = 0x802407a4
export const RAM_HES_Item_BumpAttack = 0x80084ed8
export const RAM_D_80294AFC = 0x80294afc
export const RAM_gPauseMsg_17 = 0x8026f640
export const RAM_mim_02_HoppingAI_LosePlayer = 0x80240c1c
export const RAM_HES_Item_Unused_11F_disabled = 0x800875a8
export const RAM_tik_12_GetItemName = 0x80240a14
export const RAM_gPauseHeldButtons = 0x802700c0
export const RAM_func_8021CFC0_6A41C0 = 0x8021cfc0
export const RAM_func_80218850_4EFCF0 = 0x80218850
export const RAM_trd_02_PatrolAI_JumpInit = 0x80240fe4
export const RAM_b_area_pra3_duplighost_kooper_handleEvent = 0x8021da98
export const RAM_func_80240590_CF8100 = 0x80240590
export const RAM_b_area_isk_part_1_buzzy_beetle_8021F18C = 0x8021f18c
export const RAM_state_drawUI_startup = 0x80033788
export const RAM_SetNpcPaletteSwapping = 0x802cffc0
export const RAM_D_8024987C_A33ABC = 0x8024987c
export const RAM_func_802A9210_42D120 = 0x802a9210
export const RAM_osEPiStartDma = 0x80060e20
export const RAM_tik_17_SuperBlock_UpgradeDescMessages = 0x80241438
export const RAM_start_falling = 0x800e5fd0
export const RAM_kpa_15_UpdateTexturePanStepped = 0x80240124
export const RAM_SetEnemyTargetOffset = 0x8026b0a0
export const RAM_dgb_03_npcAI_80243C24 = 0x80243c24
export const RAM_D_80153374 = 0x80153374
export const RAM_hos_02_FlyingNoAttackAI_Main = 0x802428f4
export const RAM_battle_item_dried_shroom_PlayerGoHome = 0x802a1770
export const RAM_battle_item_pebble_PlayerGoHome = 0x802a1670
export const RAM_kpa_15_varStash = 0x80240aa0
export const RAM_b_area_kmr_part_3_final_jr_troopa_defenseTable_8022C418 = 0x8022c418
export const RAM_trd_01_SetEntityFlags100000 = 0x80240310
export const RAM_D_80247750 = 0x80247750
export const RAM_b_area_kpa4_UnkFloatFunc = 0x802182a8
export const RAM_dro_01_8024C580 = 0x8024c580
export const RAM_b_area_mim_formation_0A = 0x8021e7ec
export const RAM_pra_37_MeleeHitbox_31 = 0x80240fbc
export const RAM_WaitForCam = 0x802cc354
export const RAM_au_SEFCmd_0B = 0x8004d15c
export const RAM_b_area_kmr_part_1_formation_0B = 0x8021949c
export const RAM_arn_11_main = 0x802405a8
export const RAM_KoopatrolDisguiseExtraAnims = 0x800f7be8
export const RAM_HES_SwapArrowRight = 0x80292cb8
export const RAM_peach_sync_disguise_npc = 0x800e6500
export const RAM_sbk_30_LetterDelivery_SaveNpcAnim = 0x80241560
export const RAM_flo_15_makeEntities = 0x80241300
export const RAM_RunAwayStart = 0x802868bc
export const RAM_HES_SmallText8 = 0x80242250
export const RAM_jan_06_HoppingAI_LoiterInit = 0x80240ca8
export const RAM_D_80099B78 = 0x80099b78
export const RAM_func_8023E11C = 0x8023e11c
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_idle = 0x80233f30
export const RAM_HES_CLeftButton = 0x80292438
export const RAM_D_800A095A = 0x800a095a
export const RAM_sam_06_LetterDelivery_RestoreNpcAnim = 0x8024250c
export const RAM_func_80056DCC = 0x80056dcc
export const RAM_func_80240CD8_C8E888 = 0x80240cd8
export const RAM_HES_Item_Unused_0F4 = 0x80085f58
export const RAM_D_802433B0_A195D0 = 0x802433b0
export const RAM_func_80240010_907A40 = 0x80240010
export const RAM_HES_Item_DojoCard4 = 0x80081f68
export const RAM_D_8024DCD0_A37F10 = 0x8024dcd0
export const RAM_flo_14_func_8024030C_CCB61C = 0x8024030c
export const RAM_SetGoalToIndex = 0x80269f94
export const RAM_func_8005904C = 0x8005904c
export const RAM_trd_04_GetEntityPosition = 0x80240408
export const RAM_dgb_01_SentinelAI_LosePlayerInit = 0x80241bf0
export const RAM_flo_25_FlyingNoAttackAI_20 = 0x802418e8
export const RAM_sbk_00_exitWalk_8024013C = 0x8024013c
export const RAM_flo_03_pad_EA4 = 0x80240ea4
export const RAM_b_area_trd_part_1_trd_05d_beforeBattle_80226B74 = 0x80226b74
export const RAM_D_8024C600_C09E80 = 0x8024c600
export const RAM_evt_fixed_var_to_float = 0x802c4920
export const RAM_mac_05_Quizmo_AnswerResult = 0x802529e8
export const RAM_func_8024064C_EDC62C = 0x8024064c
export const RAM_D_8014C298 = 0x8014c298
export const RAM_mac_02_Quizmo_SetStageLightsDelay = 0x802421fc
export const RAM_func_802404D0_9AEBD0 = 0x802404d0
export const RAM_func_80240880_A2AAC0 = 0x80240880
export const RAM_flo_09_exitWalk_80241B9C = 0x80241b9c
export const RAM_func_802405CC_8AC55C = 0x802405cc
export const RAM_D_80246D48_C46AF8 = 0x80246d48
export const RAM_b_area_sbk_pokey_802193B8 = 0x802193b8
export const RAM_b_area_kmr_part_1_spiked_goomba_sinTable = 0x8021bc70
export const RAM_flo_13_D_80247D00 = 0x80247d00
export const RAM_nok_01_ToadHouse_DoesPlayerNeedSleep = 0x80240c2c
export const RAM_load_tattle_flags = 0x80253e78
export const RAM_sam_02_Quizmo_SpinPlayer = 0x80241948
export const RAM_pra_19_SetPartnerFlags20000 = 0x80240eec
export const RAM_sbk_00_SpawnSunEffect = 0x80240000
export const RAM_EE70C0_FlyingAI_LoiterInit = 0x802412e8
export const RAM_arn_02_CleftAI_Ambush = 0x802403a0
export const RAM_D_80151250 = 0x80151250
export const RAM_b_area_kmr_part_3_paragoomba2_sinTable = 0x80221bbc
export const RAM_MakeOwnerTargetIndex = 0x8027fbb8
export const RAM_gather_magic_main = 0xe008e000
export const RAM_b_area_mac_lee_partsTable_80222394 = 0x80222394
export const RAM_IES_VoltShroom = 0x8008d6f4
export const RAM_battle_item_super_soda_func_802A12EC_724FCC = 0x802a12ec
export const RAM_update_merlee_message = 0x80045d7c
export const RAM_D_80224920_4EF060 = 0x80224920
export const RAM_GetActorDefenseBoost = 0x8026fc6c
export const RAM_D_80250A50_862BC0 = 0x80250a50
export const RAM_D_80247418_C7E0F8 = 0x80247418
export const RAM_D_800B0ED4 = 0x800b0ed4
export const RAM_action_command_hammer_CreateHudElements = 0x802a9000
export const RAM_ui_battle_cmd_aim_marker_0_png = 0x8028e580
export const RAM_b_area_pra3_duplighost_bow_statusTable = 0x802215f4
export const RAM_wExtraPartnerNpcID = 0x802dae44
export const RAM_water_fountain_render = 0xe00b81b8
export const RAM_trd_07_FlyingAI_LoiterInit = 0x802412bc
export const RAM_b_area_trd_part_1_trd_04_80223FF0 = 0x80223ff0
export const RAM_b_area_arn_arn_04_foregroundModelList_80227B4C = 0x80227b4c
export const RAM_trd_04_SetEntityFlags100000 = 0x80240310
export const RAM_D_8010C9A0 = 0x8010c9a0
export const RAM_b_area_pra_GetBootsHammerLevel = 0x8021942c
export const RAM_D_80151030 = 0x80151030
export const RAM_HES_Item_Unused_02E = 0x800838b8
export const RAM_D_E0016738 = 0xe0016738
export const RAM_item_entity_update = 0x80131dd4
export const RAM_jan_03_ToadHouse_PartnerResumeAbilityScript = 0x80241650
export const RAM_D_8014F57C = 0x8014f57c
export const RAM_obk_04_GetItemName = 0x802409f4
export const RAM_func_800E98A8 = 0x800e98a8
export const RAM_dro_02_8024B530 = 0x8024b530
export const RAM_hos_03_FlyingAI_LoiterInit = 0x80241488
export const RAM_flo_25_GetNpcCollisionHeight = 0x80241b2c
export const RAM_flo_07_tree1_Callback = 0x802425f8
export const RAM_func_8026EA7C = 0x8026ea7c
export const RAM_mac_04_GetFloorCollider = 0x80241980
export const RAM_NAMESPACE_80239E8C = 0x80239e8c
export const RAM_tik_17_SuperBlock_PartnerIDs = 0x80241428
export const RAM_ui_peach_beam_pal = 0x800fe300
export const RAM_D_8029FAC4 = 0x8029fac4
export const RAM_D_8010D68A = 0x8010d68a
export const RAM_stars_burst_main = 0xe0042000
export const RAM_osContStartQuery = 0x80061900
export const RAM_HES_Item_LastStand = 0x80085298
export const RAM_b_area_sam_duplighost_kooper_handleEvent = 0x8021ea18
export const RAM_HES_Item_Pebble = 0x80086318
export const RAM_machi_80240030_7E73D0 = 0x80240030
export const RAM_kpa_60_SetEntityFlags100000 = 0x80240000
export const RAM_b_area_kmr_part_2_goomnut_tree_statusTable_802232F0 = 0x802232f0
export const RAM_IES_Calculator = 0x8008b964
export const RAM_sam_08_SuperBlock_WaitForPlayerToLand = 0x80240d08
export const RAM_b_area_omo_blue_shy_guy_init_8021AE68 = 0x8021ae68
export const RAM_func_E0070738 = 0xe0070738
export const RAM_func_80240068_BAD9A8 = 0x80240068
export const RAM_MoveBattleCamOver = 0x8024ec08
export const RAM_fold_currentImage = 0x8014ee10
export const RAM_osSyncPrintf = 0x80025cfc
export const RAM_b_area_pra3_gray_magikoopa_flying = 0x8022f008
export const RAM_D_80242160_AB2150 = 0x80242160
export const RAM_kmr_12_goombaNpc = 0x802407b8
export const RAM_IES_FriedShroom = 0x8008d094
export const RAM_b_area_mac_lee_partsTable_8021EA50 = 0x8021ea50
export const RAM_b_area_kmr_part_1_goomba_partsTable_80219808 = 0x802196c8
export const RAM_D_802BFF30 = 0x802bff30
export const RAM_pra_12_reflection_setup_wall = 0x80240158
export const RAM_ui_battle_status_stop_1_pal = 0x80102840
export const RAM_func_800F52BC = 0x800f52bc
export const RAM_D_8029DA00 = 0x8029da00
export const RAM_D_8029D960 = 0x8029d960
export const RAM_mac_01_LetterDelivery_Init = 0x80243dfc
export const RAM_pra_05_varStash = 0x80241680
export const RAM_D_8015437E = 0x8015437e
export const RAM_kzn_17_varStash = 0x80244074
export const RAM_dgb_16_80241780 = 0x80241780
export const RAM_D_E00E6990 = 0xe00e6990
export const RAM_dgb_11_makeEntities = 0x80240a84
export const RAM_flo_10_npcGroup_80243E64 = 0x80243e64
export const RAM_MakeShopOwner = 0x80281bd8
export const RAM_confetti_init = 0xe00885a0
export const RAM_pra_32_StarSpiritEffectFunc5 = 0x8024061c
export const RAM_b_area_pra_ShrinkActor = 0x80218300
export const RAM_draw_entity_model_A = 0x80121ce8
export const RAM_battle_star_lullaby_usePower = 0x802a24d8
export const RAM_func_802713B0 = 0x802713b0
export const RAM_b_area_trd_part_2_trd_05d_afterBattle_80234D48 = 0x80234d48
export const RAM_D_09000800 = 0x09000800
export const RAM_kmr_05_foliage_setup_shear_mtx = 0x80241500
export const RAM_ED8E20_Pipe_GetCameraYaw = 0x80240208
export const RAM_kpa_61_FlyingMagikoopaAI_22 = 0x80241cbc
export const RAM_snowman_doll_appendGfx = 0xe00c0760
export const RAM_func_80266978 = 0x80266978
export const RAM_flo_03_tree1 = 0x80244e30
export const RAM_D_801565A6 = 0x801565a6
export const RAM_b_area_sam_duplighost_formation_watt = 0x802247cc
export const RAM_fire_flower_main = 0xe007e000
export const RAM_btl_cam_use_preset = 0x8024e40c
export const RAM_dgb_04_SuperBlock_AnimateEnergyOrbs = 0x802429d0
export const RAM_CreateNpc = 0x802cdba4
export const RAM_entity_block_hit_init_scale = 0x802e31ec
export const RAM_b_area_sam_sam_02d_UnkModelStuffScript1 = 0x8022fef0
export const RAM_b_area_omo2_toy_tank_8021A3D4 = 0x8021a3d4
export const RAM_flo_08_SuperBlock_LoadCurrentPartnerName = 0x80240448
export const RAM_HES_StatBoots1 = 0x80242894
export const RAM_flo_14_ItemChoice_WaitForSelection = 0x80242234
export const RAM_func_800E34D8 = 0x800e34d8
export const RAM_D_80243720 = 0x80243720
export const RAM_D_801A7000 = 0x801a7000
export const RAM_sbk_26_foliage_setup_shear_mtx = 0x80240160
export const RAM_entity_SuperBlock_init = 0x802e57e4
export const RAM_flo_14_npcGroupList_80244F00 = 0x80244f00
export const RAM_mac_02_Quizmo_NPC_OnRender = 0x80241b8c
export const RAM_vtx_C26090 = 0x80205150
export const RAM_dro_01_Quizmo_GetCamVfov = 0x80242050
export const RAM_b_area_jan_jan_03b_afterBattle_80228410 = 0x80228410
export const RAM_HES_TimingCharge1 = 0x8029281c
export const RAM_RemoveKeyItemAt = 0x802d6858
export const RAM_b_area_trd_part_2_fake_bowser_D_8021C7F4_48D9E4 = 0x8021c7f4
export const RAM_kkj_01_PatrolAI_MoveInit = 0x80240000
export const RAM_evt_handle_suspend_all = 0x802c6950
export const RAM_func_80241EB4_D21D44 = 0x80241eb4
export const RAM_mgm_01_CreateBlockEntities = 0x80241234
export const RAM_hos_03_FlyingAI_JumpInit = 0x8024188c
export const RAM_cos_rad = 0x8002a1d0
export const RAM_BattleCam_PosZ = 0x8029f278
export const RAM_func_802418F0_7EC600 = 0x802418f0
export const RAM_func_802BB228_E2DB58 = 0x802bb228
export const RAM_peach_disguise_check_overlaps = 0x800e66c4
export const RAM_D_80246794 = 0x80246794
export const RAM_D_8009BA28 = 0x8009bb28
export const RAM_gCollisionPointX = 0x800a4248
export const RAM_flo_07_npcSettings_80241220 = 0x80241220
export const RAM_D_80231428_63CFC8 = 0x80231428
export const RAM_is_actor_hp_bar_visible = 0x80253c20
export const RAM_D_80240D98_A79258 = 0x80240d98
export const RAM_flo_09_tree2 = 0x8024344c
export const RAM_PlayAmbientSounds = 0x802d611c
export const RAM_dro_01_dup_StashVars = 0x80243460
export const RAM_b_area_kzn_lava_bubble_takeTurn_8021AEA4 = 0x8021aea4
export const RAM_D_80245B28_A99268 = 0x80245b28
export const RAM_func_802A1518_78D668 = 0x802a1518
export const RAM_func_802414B4_BD00A4 = 0x802414b4
export const RAM_nok_13_HoppingAI_Loiter = 0x80240628
export const RAM_mac_06_UnkPlayerPosFunc = 0x80240b88
export const RAM_dgb_01_exitDoubleDoor_80243604 = 0x80243604
export const RAM_IES_MagicalSeed2 = 0x8008b824
export const RAM_tik_19_SuperBlock_PartnerIDs = 0x802426b8
export const RAM_bulb_glow_render = 0xe0078210
export const RAM_CamPresetUpdate_F = 0x80248de4
export const RAM_b_area_arn_hyper_cleft_defenseTable_80220B40 = 0x80220b40
export const RAM_jan_01_HoppingAI_HopInit = 0x80240340
export const RAM_omo_17_UnkBufferFunc = 0x80240168
export const RAM_HES_Spirit4 = 0x802424e4
export const RAM_D_E0076F00 = 0xe0076f00
export const RAM_D_8024F2B0 = 0x8024f2b0
export const RAM_D_802429E0 = 0x802429e0
export const RAM_HES_NotEnoughPOW = 0x8010776c
export const RAM_flo_18_triggerCoord_802436AC = 0x802436ac
export const RAM_D_80243D88_A72168 = 0x80243d88
export const RAM_func_80241F54_A2C194 = 0x80241f54
export const RAM_D_8025811C = 0x8025811c
export const RAM_D_80257F20 = 0x80257f20
export const RAM_HES_Item_Unused_08C_disabled = 0x80082388
export const RAM_func_8024056C_8FFBDC = 0x8024056c
export const RAM_dgb_16_npcGroup_8024220C = 0x8024220c
export const RAM_b_area_omo_yellow_shy_guy_partsTable_8021C8D0 = 0x8021c8d0
export const RAM_func_80241360_A01960 = 0x80241360
export const RAM_D_802E9898 = 0x802e9898
export const RAM_trd_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_ui_battle_cmd_aim_marker_1_pal = 0x8028e6a0
export const RAM_pause_items_load_items = 0x8024a4dc
export const RAM_kpa_51_UnkNpcAIFunc12 = 0x802415fc
export const RAM_D_E005E674 = 0xe005e674
export const RAM_D_8024EFEC = 0x8024efec
export const RAM_func_802A1628_78EDF8 = 0x802a1628
export const RAM_D_802AD0BC = 0x802ad0bc
export const RAM_D_E000CC10 = 0xe000cc10
export const RAM_tik_05_UnkPosFunc2 = 0x80240310
export const RAM_D_E0050568 = 0xe0050568
export const RAM_D_E005C710 = 0xe005c710
export const RAM_pra_39_reflection_render_wall = 0x80240320
export const RAM_battle_item_strange_cake1_pal = 0x802a2370
export const RAM_ui_battle_hp_4_pal = 0x8028aa80
export const RAM_arn_05_pad_16A8 = 0x802416a8
export const RAM_D_800B0B02 = 0x800b0b02
export const RAM_b_area_kmr_part_3_kmr_06_foregroundModelList_8023340C = 0x8023340c
export const RAM_sam_10_MeleeHitbox_Main = 0x8024078c
export const RAM_b_area_kzn_formation_0E = 0x80229c0c
export const RAM_b_area_sam_white_clubba_parts = 0x80229bd4
export const RAM_b_area_isk_part_1_buzzy_beetle_idleAnimations_8021E62C = 0x8021e62c
export const RAM_hos_00_GetItemName = 0x802408a4
export const RAM_battle_partner_goombario_8023BB9C = 0x8023bb9c
export const RAM_action_command_air_lift_free_hud_elements = 0x802a9810
export const RAM_peach_force_disguise_action = 0x800e63a4
export const RAM_RunningMusicEventIDs = 0x802db800
export const RAM_dgb_03_80242870 = 0x80242870
export const RAM_D_80248714_808F94 = 0x80248714
export const RAM_ui_bluepip_pal = 0x801016c0
export const RAM_D_80235E00 = 0x80235e00
export const RAM_ui_battle_status_frozen_3_pal = 0x80102700
export const RAM_sbk_56_SuperBlock_SetOverride40 = 0x80240080
export const RAM_ui_battle_status_stop_3_png = 0x80102900
export const RAM_flo_21_intTable_802425E4 = 0x802425e4
export const RAM_tik_09_UnkNpcAIFunc12 = 0x80240be0
export const RAM_gSpriteHeapPtr = 0x8034f800
export const RAM_func_80218AA4_69FCA4 = 0x80218aa4
export const RAM_ui_battle_status_transparent_4_pal = 0x80103060
export const RAM_b_area_trd_part_2_trd_05_80232CE0 = 0x80232ce0
export const RAM_ui_partner0_disabled_pal = 0x800fe600
export const RAM_D_802A2650_73EEB0 = 0x802a2650
export const RAM_D_80109800 = 0x80109800
export const RAM_sbk_31_Bandit_DropCoin = 0x80240040
export const RAM_jan_03_ToadHouse_DisableStatusMenu = 0x802414b0
export const RAM_HES_Item_Unused_136 = 0x80087818
export const RAM_HES_Item_JamminJelly = 0x80087098
export const RAM_b_area_mim_piranha_plant_defenseTable_8021D394 = 0x8021d394
export const RAM_dgb_16_pad_1774 = 0x80241774
export const RAM_b_area_sam_duplighost_parakarry_parts = 0x80221694
export const RAM_btl_state_draw_twink_menu = 0x802aa640
export const RAM_draw_encounters_post_battle = 0x8004304c
export const RAM_b_area_trd_part_2_green_ninja_koopa_statusTable_80225578 = 0x80225578
export const RAM_ui_battle_cmd_aim_shimmer_2_pal = 0x8028ec40
export const RAM_D_80244B40_A10830 = 0x80244b40
export const RAM_dgb_01_D_80253860_C110E0 = 0x80253860
export const RAM_end_01_CharAnim_Hold = 0x802411dc
export const RAM_D_80242C38_91B7E8 = 0x80242c38
export const RAM_D_80252794_9E97B4 = 0x80252794
export const RAM_D_802480C0_A231C0 = 0x802480c0
export const RAM_D_80245D34_E0B094 = 0x80245d34
export const RAM_kpa_03_MagikoopaAI_21 = 0x802416ec
export const RAM_nuSiMgrInit = 0x8002ce30
export const RAM_pra_34_ReflectFloor = 0x8024049c
export const RAM_D_8024F2B8 = 0x8024f2b8
export const RAM_D_8024AB10_DD77E0 = 0x8024ab10
export const RAM_D_802443F0_C3A920 = 0x802443f0
export const RAM_func_8023906C_70CD7C = 0x8023906c
export const RAM_D_E0016730 = 0xe0016730
export const RAM_au_BGMCmd_E8_TrackOverridePatch = 0x8004fe6c
export const RAM_bgm_update_music_settings = 0x8014a548
export const RAM_HES_SwapArrowLeft = 0x80292c7c
export const RAM_func_802A9110_4256A0 = 0x802a9110
export const RAM_func_80072CEC = 0x80072cec
export const RAM_jan_14_PullVine_ShearBushModel = 0x802401cc
export const RAM_set_standard_shadow_scale = 0x8011285c
export const RAM_IES_SnowmanDoll = 0x8008d5f4
export const RAM_emote_main = 0xe00202cc
export const RAM_omo_09_ShyGuyPatrolAI_14 = 0x80241ddc
export const RAM_b_area_trd_part_1_bob_omb_8021AB54 = 0x8021ab54
export const RAM_HES_Item_EggMissile = 0x800830d8
export const RAM_D_8029DA4C = 0x8029da4c
export const RAM_HES_Item_Unused_08A_disabled = 0x800822c8
export const RAM_b_area_omo2_3_shy_stack_lower_actor_pos = 0x8023476c
export const RAM_func_80059008 = 0x80059008
export const RAM_battle_item_super_soda_PlayerGoHome = 0x802a1960
export const RAM_D_802AD673 = 0x802ad673
export const RAM_pra_40_SetPartnerFlags80000 = 0x80240eb8
export const RAM_arn_05_init_80242008 = 0x80242008
export const RAM_func_8011CFBC = 0x8011cfbc
export const RAM_IES_PUpDDown = 0x8008c604
export const RAM_hos_05_UnkFunc27 = 0x80240000
export const RAM_D_802475E4 = 0x802475e4
export const RAM_D_800A0F50 = 0x800a0f50
export const RAM_nok_02_Quizmo_CreateReactionEffect = 0x80242908
export const RAM_vtx_C2DD90 = 0x8020ce50
export const RAM_bgm_flush_music_events = 0x800560a8
export const RAM_b_area_isk_part_1_isk_02c_afterBattle_80223EF4 = 0x80223ef4
export const RAM_dro_01_8024B894 = 0x8024b894
export const RAM_mdl_local_gfx_copy_vertices = 0x8011c130
export const RAM_evt_handle_case_range = 0x802c5388
export const RAM_D_8029DA08 = 0x8029da08
export const RAM_D_8029D968 = 0x8029d968
export const RAM_GetColliderCenter = 0x802ca0ac
export const RAM_b_area_mim_formation_01 = 0x8021e4f8
export const RAM_D_090017B0 = 0x090017b0
export const RAM_D_8024AAB4_A34CF4 = 0x8024aab4
export const RAM_b_area_nok_paratroopa_statusTable_8021EB14 = 0x80223944
export const RAM_filemenu_draw_contents_file_3_title = 0x802457b8
export const RAM_AssignBlockFlag = 0x8011206c
export const RAM_filemenu_yesno_init = 0x80247198
export const RAM_b_area_sam2_monstar_statusTable = 0x80219e74
export const RAM_b_area_trd_part_2_trd_00 = 0x80231434
export const RAM_b_area_sam_gray_magikoopa_idle = 0x8022c604
export const RAM_func_80045BC8 = 0x80045bc8
export const RAM_func_80240038_7E73D8 = 0x80240038
export const RAM_ShowEmote = 0x802d78a0
export const RAM_D_80150010 = 0x80150010
export const RAM_b_area_kmr_part_2_kmr_03_UnkAngleFunc2 = 0x80218d50
export const RAM_mac_04_Quizmo_SpinPartner = 0x802412e0
export const RAM_D_802AD60A = 0x802ad60a
export const RAM_D_80252D50_8FEB70 = 0x80252d50
export const RAM_D_80245968_BAA0E8 = 0x80245968
export const RAM_b_area_trd_part_2_yellow_ninja_koopa = 0x8022e528
export const RAM_b_area_trd_part_1_bill_blaster = 0x80219e20
export const RAM_D_80159E70 = 0x80159e70
export const RAM_dgb_03_npcAI_80243CC0 = 0x80243cc0
export const RAM_b_area_isk_part_2_isk_03b = 0x802200fc
export const RAM_flo_14_npcSettings_802445D0 = 0x802445d0
export const RAM_FXRecoverFP = 0x80261c68
export const RAM_func_80241A64_B36634 = 0x80241a64
export const RAM_b_area_trd_part_2_trd_05f_80235678 = 0x80235678
export const RAM_D_8009A6A4 = 0x8009a6a4
export const RAM_world_goombaria_use_ability = 0x802bd228
export const RAM_EA0C10_func_80240814_97BE44 = 0x80242568
export const RAM_kzn_05_UpdateTexturePanStepped = 0x80240124
export const RAM_sfx_play_sound_at_position = 0x80149d5c
export const RAM_func_80240680_8A8600 = 0x80240680
export const RAM_D_09000800_350A20 = 0x09000800
export const RAM_tik_17_SuperBlock_PartnerSparkles4 = 0x80240cf0
export const RAM_remove_status_static = 0x800479a0
export const RAM_func_80240784_B03004 = 0x80240784
export const RAM_fx_waterfall = 0x80071ab0
export const RAM_flashing_box_shockwave_appendGfx = 0xe00942fc
export const RAM_b_area_kzn_putrid_piranha_8021B354 = 0x8021b354
export const RAM_world_sushie_update = 0x802bfdfc
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_idleAnimations_80227F00 = 0x80227f00
export const RAM_npc_remove_decoration_charged = 0x8003cfa0
export const RAM_kmr_02_Quizmo_varStash = 0x802455d0
export const RAM_kpa_95_KoopaPatrolAI_Main = 0x80241304
export const RAM_D_802AD609 = 0x802ad609
export const RAM_b_area_kmr_part_2_goomba_king_doDeath_80222F50 = 0x80222f50
export const RAM_create_trigger = 0x8014576c
export const RAM_b_area_mac_lee_nextTurn_8022831C = 0x8022831c
export const RAM_dup2_b_area_kpa_UnkBattleFunc1 = 0x80218514
export const RAM_free_npc_by_index = 0x800388a0
export const RAM_entity_GreenStompSwitch_idle = 0x802e1400
export const RAM_tik_12_SuperBlock_GetPartnerRank = 0x80240c84
export const RAM_npc_raycast_down_around = 0x800dc778
export const RAM_D_80242E90_C523A0 = 0x80242e90
export const RAM_b_area_sbk_bandit_idleAnimations_8021D5A8 = 0x8021d5a8
export const RAM_func_80240E2C_EA990C = 0x80240e2c
export const RAM_b_area_kmr_part_3_egg_jr_troopa_defenseTable_8022440C = 0x8022440c
export const RAM_battle_move_mega_quake_IsBerserkerEquipped = 0x802a1000
export const RAM_D_80249BA0 = 0x80249ba0
export const RAM_b_area_pra3_swoopula = 0x80226f2c
export const RAM_func_802A18E8_78BEE8 = 0x802a18e8
export const RAM_func_80240384_946254 = 0x80240384
export const RAM_partner_use_ability = 0x800eb314
export const RAM_HES_Item_SpicySoup_disabled = 0x80082808
export const RAM_battle_partner_goombario_defenseTable_802391EC = 0x802391ec
export const RAM_b_area_omo_yellow_shy_guy_defenseTable_8021C810 = 0x8021c810
export const RAM_entity_ScriptSpring_idle = 0x802e5fb0
export const RAM_D_80248624 = 0x80248624
export const RAM_trd_00_varStash = 0x80241340
export const RAM_kzn_04_SuperBlock_PartnerSparkles2 = 0x80240f88
export const RAM_dgb_01_pad_111111 = 0x80251a78
export const RAM_D_8024F010 = 0x8024f010
export const RAM_func_80243514_8B3584 = 0x80243514
export const RAM_b_area_sam_duplighost_goombario = 0x8021cc1c
export const RAM_pra_16_reflection_render_wall = 0x802402f0
export const RAM_HES_PartnerA = 0x80107fc8
export const RAM_SetBattleCamTarget = 0x8024e90c
export const RAM_dgb_04_exitDoubleDoor_802432C4 = 0x802432c4
export const RAM_EA8AE0_StarSpiritEffectFunc3 = 0x802404b8
export const RAM_IES_DojoCard2 = 0x8008bc04
export const RAM_nok_01_Quizmo_SpinPlayer = 0x80241b84
export const RAM_b_area_trd_part_1_trd_05e_80226E08 = 0x80226e08
export const RAM_isk_09_GetItemName = 0x802400b4
export const RAM_func_80240D30_A3A210 = 0x80240d30
export const RAM_ring_blast_update = 0xe0048130
export const RAM_osFlashReadId = 0x8006f184
export const RAM_dro_02_npcSettings_802475B0 = 0x802475b0
export const RAM_flo_00_80248F48 = 0x80248f48
export const RAM_D_8023162C_63D1CC = 0x8023162c
export const RAM_b_area_jan_spear_guy_idleAnimations_80218664 = 0x80218664
export const RAM_dro_01_Quizmo_HideWorld = 0x8024189c
export const RAM_btl_state_draw_defeat = 0x802456b0
export const RAM_HES_Item_PowerQuake = 0x80083d98
export const RAM_au_BGMCmd_EC_SegTrackVolume = 0x8004ff70
export const RAM_func_80058004 = 0x80058004
export const RAM_D_80247CA0 = 0x80247ca0
export const RAM_HES_Item_BakingEgg_disabled = 0x800872a8
export const RAM_func_8023808C_710F7C = 0x8023808c
export const RAM_D_8014B820 = 0x8014b820
export const RAM_battle_item_fright_jar_UseItemWithEffect = 0x802a1360
export const RAM_b_area_iwa_whacka_handleEvent_8021FE90 = 0x8021fe90
export const RAM_func_80268224 = 0x80268224
export const RAM_IES_FriedEgg = 0x8008d2f4
export const RAM_entity_interacts_with_current_partner = 0x801109dc
export const RAM_world_lakilester_while_riding = 0x802bfed4
export const RAM_stop_watch_appendGfx = 0xe00c4308
export const RAM_kpa_51_MagikoopaAI_24 = 0x80241054
export const RAM_gPauseWS_14 = 0x8026fc10
export const RAM_func_80260B70 = 0x80260b70
export const RAM_kpa_08_MagikoopaAI_OnHitInit = 0x8024141c
export const RAM_D_802438E0_A4BEA0 = 0x802438e0
export const RAM_dgb_11_main = 0x80240498
export const RAM_IES_SSmashChg = 0x8008c0a4
export const RAM_ui_item_disabled_pal = 0x800fb7c0
export const RAM_b_area_trd_part_2_red_ninja_koopa_partsTable_802285C4 = 0x802285c4
export const RAM_func_8024268C_B4D0FC = 0x8024268c
export const RAM_b_area_omo_omo_04_slot_machine_stop = 0x8022e010
export const RAM_b_area_kmr_part_3_para_jr_troopa_statusTable_80225E60 = 0x80225e60
export const RAM_b_area_trd_part_1_trd_05c_afterBattle_80226470 = 0x80226470
export const RAM_filemenu_8024C098 = 0x8024c098
export const RAM_HES_Spirit7Missing = 0x80242618
export const RAM_func_8024173C_8CF7AC = 0x8024173c
export const RAM_D_80243870_B7D8C0 = 0x80243870
export const RAM_D_802454A8_EF25A8 = 0x802454a8
export const RAM_b_area_sam_sam_03_foregroundModelList = 0x80230638
export const RAM_func_802BD4FC_323E4C = 0x802bd4fc
export const RAM_gPauseMsg_39 = 0x8026f698
export const RAM_b_area_pra3_red_magikoopa_8022E6A0 = 0x8022e6a0
export const RAM_filemenu_draw_contents_mono = 0x80244d60
export const RAM_D_802454A8_BB2DE8 = 0x802454a8
export const RAM_world_twink_init = 0x802bd100
export const RAM_isk_14_HoppingAI_Chase = 0x802408ac
export const RAM_func_802A91B0_4235A0 = 0x802a91b0
export const RAM_b_area_sbk_pokey_8021AE2C = 0x8021ae2c
export const RAM_IES_ITEM_11F = 0x8008dcb4
export const RAM_pra_35_ReflectFloor = 0x8024049c
export const RAM_dgb_03_pad_2908 = 0x80242908
export const RAM_pause_badges_get_column = 0x802471d8
export const RAM_func_80245440_805CC0 = 0x80245440
export const RAM_vtx_C2A790 = 0x80209850
export const RAM_func_800F0490 = 0x800f0490
export const RAM_btl_draw_upgrade_windows = 0x8025ff8c
export const RAM_nok_03_HoppingAI_ChaseInit = 0x802407cc
export const RAM_jan_00_GetNpcCollisionHeight = 0x8024050c
export const RAM_D_80257F58 = 0x80257f58
export const RAM_nok_01_StationaryAI_ReturnHomeInit = 0x80240540
export const RAM_sbk_00_pad_78C = 0x8024078c
export const RAM_kzn_09_set_script_owner_npc_col_height = 0x80242cd8
export const RAM_IES_Unused_0E4 = 0x8008c964
export const RAM_nok_01_GetNpcCollisionHeight = 0x80241dfc
export const RAM_func_80269550 = 0x80269550
export const RAM_D_802B6768_E27C78 = 0x802b6768
export const RAM_func_802390B4_700E34 = 0x802390b4
export const RAM_end_00_SetNpcShadowScale = 0x80242830
export const RAM_b_area_dgb_clubba_partsTable_80218104 = 0x80218104
export const RAM_kpa_130_BillBlasterAI_Main = 0x802404ac
export const RAM_snd_ambient_800557CC = 0x800557cc
export const RAM_b_area_dgb_tubba_blubba_80219238 = 0x80219238
export const RAM_D_8023BB98_6ECC78 = 0x8023bb98
export const RAM_D_80246424 = 0x80246424
export const RAM_func_802408F8_A277C8 = 0x802408f8
export const RAM_kzn_09_MeleeHitbox_30 = 0x80241020
export const RAM_nok_13_HoppingAI_LoiterInit = 0x80240568
export const RAM_underwater_appendGfx = 0xe00ba620
export const RAM_mgm_02_draw_score_display = 0x80240000
export const RAM_nuAuHeap = 0x800d9248
export const RAM_flo_13_D_80247B00 = 0x80247b00
export const RAM_func_802456C8_A2F908 = 0x802456c8
export const RAM_D_80245A08_A92488 = 0x80245a08
export const RAM_lightning_appendGfx = 0xe006c5e8
export const RAM_b_area_kzn_white_magikoopa_handleEvent_80223E24 = 0x80223e24
export const RAM_func_802425E0_854750 = 0x802425e0
export const RAM_entity_base_block_init = 0x802e36e4
export const RAM_b_area_jan_spear_guy_statusTable_80218744 = 0x80218744
export const RAM_func_802A1518_799CC8 = 0x802a1518
export const RAM_tik_19_SuperBlock_SetOverride40 = 0x802405f0
export const RAM_dro_01_PatrolNoAttackAI_Main = 0x80241174
export const RAM_stars_orbiting_render = 0xe005e310
export const RAM_flo_10_80242188 = 0x80242188
export const RAM_D_802BCDC0_E2F6F0 = 0x802bcdc0
export const RAM_func_802A936C = 0x802a936c
export const RAM_battle_star_refresh_AddHP = 0x802a1840
export const RAM_dgb_00_80242B84 = 0x80242b84
export const RAM_func_802413FC_EA9EDC = 0x802413fc
export const RAM_fx_chapter_change = 0x800725f0
export const RAM_D_80241FB0_ABB260 = 0x80241fb0
export const RAM_b_area_kmr_part_3_final_jr_troopa_80230034 = 0x80230034
export const RAM_check_block_input = 0x80268ecc
export const RAM_func_8025BAA0 = 0x8025baa0
export const RAM_battle_star_smooch_FadeBackgroundToBlack = 0x802a12e4
export const RAM_HES_Partner9 = 0x80107f78
export const RAM_b_area_dgb_dgb_04 = 0x8021b1a8
export const RAM_D_800A098C = 0x800a098c
export const RAM_kpa_51_MagikoopaAI_Main = 0x802410bc
export const RAM_dup_mac_00_StashVars = 0x80243400
export const RAM_func_80240F2C_D7FC8C = 0x80240f2c
export const RAM_D_80245A14_A00204 = 0x80245a14
export const RAM_omo_16_SetPlayerStatusPosYaw = 0x802401ec
export const RAM_ECAA80_UnkFloAI_ChaseInit = 0x80240d80
export const RAM_D_802433B2_A195D2 = 0x802433b2
export const RAM_D_800758A0 = 0x800758a0
export const RAM_b_area_sbk_pokey_specialFormation_8021AE10 = 0x8021ae10
export const RAM_end_00_CharAnim_FadeIn_2 = 0x80240540
export const RAM_flo_12_npcSettings_80240E30 = 0x80240e30
export const RAM_D_80249A4C_A33C8C = 0x80249a4c
export const RAM_dead_guMtxIdentF = 0x8006c660
export const RAM_dro_02_80243D10 = 0x80243d10
export const RAM_ClampAngleFloat = 0x802d5ab4
export const RAM_b_area_sam2_paragoomba_partsTable_8021B280 = 0x8021c000
export const RAM_pra_35_PatrolAI_MoveInit = 0x80240f20
export const RAM_sam_10_SomeItemEntityFunc = 0x80240118
export const RAM_D_8024C1D8 = 0x8024c1d8
export const RAM_flo_17_npcSettings_802443D8 = 0x802443e0
export const RAM_D_E0050560 = 0xe0050560
export const RAM_Entity_HeartBlockContent = 0x802ea7bc
export const RAM_evt_handle_if_less = 0x802c4d00
export const RAM_hos_03_ToadHouse_GetPartnerBackOut = 0x8024246c
export const RAM_b_area_isk_part_2_chain_chomp_idleAnimations_80218B84 = 0x80218b84
export const RAM_vtx_C2A180 = 0x80209240
export const RAM_sam_02_UpdateTexturePanSmooth = 0x802402e0
export const RAM_radiating_energy_orb_init = 0xe009e1dc
export const RAM_D_802498D0_A33B10 = 0x802498d0
export const RAM_func_802413CC_EBA21C = 0x802413cc
export const RAM_func_80056D44 = 0x80056d44
export const RAM_dead_get_entity_by_index = 0x80118bb0
export const RAM_b_area_pra3_red_magikoopa_8022C370 = 0x8022c370
export const RAM_remove_status_icon_boost_hammer = 0x80047dc0
export const RAM_b_area_kzn_putrid_piranha_bite = 0x8021b940
export const RAM_vtx_C2C4E0 = 0x8020b5a0
export const RAM_func_80238000_70BD10 = 0x80238000
export const RAM_D_8024AEC8_A35108 = 0x8024aec8
export const RAM_dgb_00_80240BE0 = 0x80240be0
export const RAM_HES_Item_Screwdriver = 0x80081b48
export const RAM_D_8029FA64 = 0x8029fa64
export const RAM_b_area_kmr_part_3_jr_troopa_defenseTable_80222A2C = 0x80222a2c
export const RAM_func_8024313C = 0x8024313c
export const RAM_D_802ACC38 = 0x802acc38
export const RAM_D_80151280 = 0x80151280
export const RAM_dgb_06_npcSettings_80240840 = 0x80240840
export const RAM_shockwave_update = 0xe004a330
export const RAM_func_80259D9C = 0x80259d9c
export const RAM_b_area_sam_sam_02_UnkModelStuff_func1 = 0x80219ed0
export const RAM_ED42A0_GetItemName = 0x80240134
export const RAM_b_area_dig_UnkBattleFunc2_2 = 0x80218184
export const RAM_dro_01_pad_D_8024DFEC = 0x8024dfec
export const RAM_osPfsChecker = 0x8006cc20
export const RAM_b_area_pra3_duplighost_returnHome = 0x8021a1a0
export const RAM_b_area_isk_part_1_isk_01 = 0x8022397c
export const RAM_kzn_11_FireBar_Sounds = 0x802437b4
export const RAM_D_09000540 = 0x09000540
export const RAM_kzn_08_MeleeHitbox_32 = 0x80242618
export const RAM_kpa_113_UnkDistFunc = 0x8024022c
export const RAM_func_80240F40_ABC1D0 = 0x80240f40
export const RAM_D_80241C04_DE4094 = 0x80241c04
export const RAM_fx_partner_buff = 0x80072830
export const RAM_kzn_08_PiranhaPlantAI_00 = 0x80242e0c
export const RAM_b_area_nok_formation_14 = 0x802193d0
export const RAM_b_area_arn_arn_04 = 0x80227b54
export const RAM_dead_basic_ai_check_player_dist = 0x8004d1a4
export const RAM_func_80238C08_7046F8 = 0x80238c08
export const RAM_func_80241FE8_D3C5B8 = 0x80241fe8
export const RAM_hos_03_Quizmo_RenderInit = 0x80242804
export const RAM_tik_15_UpdateTexturePanSmooth = 0x802402e0
export const RAM_arn_07_exitSingleDoor_802428D4 = 0x802428d4
export const RAM_flo_22_defeat_802405E4 = 0x802405e4
export const RAM_bMessages = 0x80283770
export const RAM_b_area_trd_part_1_trd_01_afterBattle_80222B1C = 0x80222b1c
export const RAM_IES_Map = 0x8008b4c4
export const RAM_obk_01_LetterDelivery_SaveNpcAnim = 0x80240ec8
export const RAM_b_area_omo2_toy_tank_8021A694 = 0x8021a694
export const RAM_D_802435E0_E15D40 = 0x802435e0
export const RAM_flo_03_tree1_Leaves = 0x80244de4
export const RAM_arn_08_pad_CB4 = 0x80240cb4
export const RAM_D_80244DF0_A1B010 = 0x80244df0
export const RAM_b_area_kmr_part_1_goomba_handleEvent_80219C14 = 0x80219ad4
export const RAM_D_80248F90 = 0x80248f90
export const RAM_flo_25_ConsumableChoiceList = 0x80244fa0
export const RAM_D_802510B0 = 0x802510b0
export const RAM_flo_16_8024346C = 0x8024346c
export const RAM_D_80242AC4 = 0x80242ac4
export const RAM_EDEA00_UnkFunc41 = 0x80240040
export const RAM_D_80244B48_A10838 = 0x80244b48
export const RAM_battle_item_please_come_back_EatItem = 0x802a170c
export const RAM_func_802383D4_703EC4 = 0x802383d4
export const RAM_GetLastEvent = 0x8027c4e0
export const RAM_D_800F7B84 = 0x800f7b84
export const RAM_dup_nok_02_GetNpcCollisionHeight = 0x802403f8
export const RAM_BattleCam_InitialBoomYaw = 0x8029f290
export const RAM_HES_Item_Unused_13F_disabled = 0x800827a8
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_802281B4 = 0x802281b4
export const RAM_b_area_pra2_crystal_king_takeTurn = 0x8021aeec
export const RAM_arn_04_FlyingAI_JumpInit = 0x80241a6c
export const RAM_texPannerAuxU = 0x801532e8
export const RAM_func_802449F8_805278 = 0x802449f8
export const RAM_D_800A0944 = 0x800a0944
export const RAM_SetIdleGoalToHome = 0x80269f2c
export const RAM_evt_handle_case_equal_OR = 0x802c5518
export const RAM_pra_33_ReflectWall = 0x802400ec
export const RAM_b_area_omo_sky_guy_defenseTable_802295FC = 0x802295fc
export const RAM_nok_12_PatrolNoAttackAI_15 = 0x80241818
export const RAM_HES_Item_CloseCall = 0x800852f8
export const RAM_dgb_01_D_802525AC_C0FE2C = 0x802525ac
export const RAM_hos_03_Quizmo_SetVannaAnim_Wave = 0x80242fc8
export const RAM_b_area_kmr_part_1_kmr_05_clouds1 = 0x802206a0
export const RAM_b_area_omo_omo_05 = 0x8022f550
export const RAM_dgb_15_PatrolAI_JumpInit = 0x80241000
export const RAM_action_update_ride = 0x802b6000
export const RAM_D_80220160 = 0x80218000
export const RAM_flo_13_npcGroup_80246BF4 = 0x80246bf4
export const RAM_b_area_sam_frost_piranha_UnkEffect6FFunc = 0x802196bc
export const RAM_SetCamSpeed = 0x802cbef0
export const RAM_D_80243300_A2A1D0 = 0x80243300
export const RAM_kzn_17_StashVars = 0x8024259c
export const RAM_pra_09_AddPlayerHandsOffset = 0x80240f7c
export const RAM_jan_02_Quizmo_SetVannaAnim_Idle = 0x80242104
export const RAM_func_80261FB4 = 0x80261fb4
export const RAM_D_802433AE_A195CE = 0x802433ae
export const RAM_D_80241C2C_A124AC = 0x80241c2c
export const RAM_jan_05_HoppingAI_ChaseInit = 0x80241070
export const RAM_b_area_mim_formation_09 = 0x8021e7b4
export const RAM_EEDF50_FlyingNoAttackAI_12 = 0x80242374
export const RAM_sam_08_WanderMeleeAI_Main = 0x8024135c
export const RAM_EEDF50_FlyingAI_Init = 0x80242038
export const RAM_world_partner_can_player_pause_default = 0x800ea524
export const RAM_D_80243A18_AC8B88 = 0x80243a18
export const RAM_battle_star_star_beam_UnkBackgroundFunc2 = 0x802a137c
export const RAM_func_80240180_9FA970 = 0x80240180
export const RAM_D_802BCFA0_E2D6D0 = 0x802bcfa0
export const RAM_action_update_jump = 0x802b60b4
export const RAM_EA0C10_MeleeHitbox_30 = 0x80240310
export const RAM_b_area_sam2_gray_magikoopa_takeTurn = 0x802216b0
export const RAM_dro_02_UnkFunc26 = 0x80240124
export const RAM_b_area_jan2_formation_00 = 0x8021f5b0
export const RAM_D_8024561B = 0x8024561b
export const RAM_func_80240098_BBD238 = 0x80240098
export const RAM_pra_35_WanderMeleeAI_Main = 0x80242698
export const RAM_b_area_kmr_part_3_egg_jr_troopa_statusTable_80224418 = 0x80224418
export const RAM_HES_Item_LuckyDay = 0x80084e18
export const RAM_enforce_hpfp_limits = 0x800e786c
export const RAM_HES_Item_MagicalSeed3 = 0x80081968
export const RAM_SpinningFlower_AngleToCenter = 0x802b6eec
export const RAM_D_802A39C4_740224 = 0x802a39c4
export const RAM_battle_item_ultra_shroom_GiveRefund = 0x802a1000
export const RAM_b_area_sam_duplighost_bombette_idle = 0x802204cc
export const RAM_D_E0080AC0 = 0xe0080ac0
export const RAM_EA0C10_LetterDelivery_RestoreNpcAnim = 0x80242da8
export const RAM_destroy_popup_menu = 0x800f1538
export const RAM_sbk_26_SetNpcB5_3 = 0x80240140
export const RAM_arn_10_pad_424 = 0x80240424
export const RAM_D_80244790_B97BD0 = 0x80244790
export const RAM_flo_08_SuperBlock_CountEligiblePartners = 0x80240120
export const RAM_flo_03_idle_8024219C = 0x8024219c
export const RAM_D_800B1B0F = 0x800b1b0f
export const RAM_b_area_pra3_duplighost_parts = 0x8021a02c
export const RAM_mac_01_Quizmo_Worker = 0x80262a40
export const RAM_D_E0020CA4 = 0xe0020ca4
export const RAM_battle_action_cmd_water_block_cloud_pal = 0x802aaee0
export const RAM_b_area_mac_lee_idleAnimations_80225974 = 0x80225974
export const RAM_func_80242F08_8B2F78 = 0x80242f08
export const RAM_flo_13_FlyingAI_JumpVels = 0x8024412c
export const RAM_D_E0114740 = 0xe0114740
export const RAM_omo_09_SetNpcB5_3 = 0x8024356c
export const RAM_b_area_mac_lee_init_8021D4C8 = 0x8021d4c8
export const RAM_MakeLerp = 0x802d45b0
export const RAM_b_area_omo_formation_2A = 0x8023066c
export const RAM_b_area_kmr_part_3_mac_01_80234564 = 0x80234564
export const RAM_D_E009EE08 = 0xe009ee08
export const RAM_D_80250E88_C0E708 = 0x80250e88
export const RAM_func_80051050 = 0x80051050
export const RAM_set_part_absolute_position = 0x80269c70
export const RAM_flo_18_exitWalk_8024095C = 0x8024095c
export const RAM_b_area_isk_part_2_chain_chomp_init_80218EC4 = 0x80218ec4
export const RAM_b_area_kmr_part_3_mage_jr_troopa_idleAnimations_8022A5D8 = 0x8022a5d8
export const RAM_state_init_enter_demo = 0x80035e00
export const RAM_D_80099E18 = 0x80099e18
export const RAM_omo_04_SetCamera0Flag1000 = 0x80241e04
export const RAM_b_area_arn_arn_05_foregroundModelList_80227BCC = 0x80227bcc
export const RAM_D_800DAC90 = 0x800dac90
export const RAM_kkj_13_UpdateTexturePanSmooth = 0x80240020
export const RAM_get_model_center_and_size = 0x8011b0ec
export const RAM_D_E0058780 = 0xe0058780
export const RAM_b_area_sam_sam_02b_UnkModelStuff_func1 = 0x8021a1f0
export const RAM_flo_21_npcGroupList_80245AEC = 0x80245aec
export const RAM_b_area_trd_part_1_trd_03_foregroundModelList_80223FB0 = 0x80223fb0
export const RAM_evt_handle_thread = 0x802c6c78
export const RAM_mim_06_HoppingAI_HopInit = 0x80241f14
export const RAM_tik_23_UnkPosFunc2 = 0x802405ec
export const RAM_gPauseMsg_3A = 0x8026f69c
export const RAM_sam_06_ToadHouse_CamSetFOV = 0x80242014
export const RAM_b_area_pra3_duplighost_sushie_handleEvent = 0x80223a14
export const RAM_arn_03_80242C60 = 0x80242c60
export const RAM_add_xz_vec3f_copy2 = 0x80264258
export const RAM_kpa_91_ToadHouse_DisableStatusMenu = 0x80241528
export const RAM_kzn_09_MeleeHitbox_Main = 0x802413dc
export const RAM_func_E00BC36C = 0xe00bc36c
export const RAM_func_802BD100_31CC70 = 0x802bd100
export const RAM_b_area_trd_part_1_trd_05e_afterBattle_80227490 = 0x80227490
export const RAM_D_8024EF60_978120 = 0x8024ef60
export const RAM_appendGfx_startup_prim_rect = 0x8002a904
export const RAM_D_8024F458_C0CCD8 = 0x8024f458
export const RAM_flo_18_80242910 = 0x80242910
export const RAM_stars_burst_init = 0xe004222c
export const RAM_gPauseTabsIconIDs = 0x80270130
export const RAM_radiating_energy_orb_render = 0xe009e3dc
export const RAM_func_80238B60_6F1C40 = 0x80238b60
export const RAM_D_8024B770_C08FF0 = 0x8024b770
export const RAM_kpa_61_FlyingMagikoopaAI_17 = 0x80241870
export const RAM_D_09000A80_326E90 = 0x09000a80
export const RAM_SetNpcAnimation = 0x802ce0f4
export const RAM_D_802466E8_837EE8 = 0x802466e8
export const RAM_recover_init = 0xe00801c8
export const RAM_battle_item_repel_gel_UseItem = 0x802a15a4
export const RAM_mim_08_PiranhaPlantAI_13 = 0x80242ba0
export const RAM_flo_10_TransformFoliage = 0x802412a0
export const RAM_D_802EF0D0 = 0x802ef0d0
export const RAM_arn_12_80240BE0 = 0x80240be0
export const RAM_ui_status_coin_2_pal = 0x80100cc0
export const RAM_kmr_02_GetEntityPosition = 0x80243474
export const RAM_HES_Item_GroupFocus_disabled = 0x80085b68
export const RAM_ShowCoinCounter = 0x800e96f8
export const RAM_flo_07_shakeTree = 0x802415ac
export const RAM_IES_Unused_0DF = 0x8008c8c4
export const RAM_func_802407C0_A0C4B0 = 0x802407c0
export const RAM_func_802A4A54 = 0x802a4a54
export const RAM_b_area_mac_lee_partsTable_80224514 = 0x80224514
export const RAM_func_80240A8C_EB18EC = 0x80240a8c
export const RAM_gCurtainFadeGoal = 0x8009ba9c
export const RAM_jan_05_foliage_setup_shear_mtx = 0x802404a0
export const RAM_spr_allocate_components = 0x802df2d8
export const RAM_func_8024081C_9B1FAC = 0x8024081c
export const RAM_arn_04_FlyingAI_Loiter = 0x80241728
export const RAM_b_area_omo_formation_20 = 0x802302ec
export const RAM_b_area_jan_jan_04 = 0x802284c8
export const RAM_pra_11_GetItemName = 0x80240fd4
export const RAM_bActorNames = 0x80281104
export const RAM_omo_03_UpdateTexturePanSmooth = 0x8024057c
export const RAM_PartnerTestEnemy = 0x802804d0
export const RAM_arn_04_CleftAI_Hiding = 0x8024255c
export const RAM_state_step_exit_language_select = 0x80035704
export const RAM_func_80240000_8ABF90 = 0x80240000
export const RAM_smoke_impact_main = 0xe003a000
export const RAM_arn_03_PatrolAI_LoiterInit = 0x80240400
export const RAM_vtx_C25C80 = 0x80204d40
export const RAM_flo_16_makeEntities = 0x80243f44
export const RAM_b_area_trd_part_2_trd_05b = 0x80233cc8
export const RAM_b_area_nok_paragoomba_handleEvent_8021B768 = 0x8021b768
export const RAM_func_8024140C_D659EC = 0x8024140c
export const RAM_D_80228448_6CBFD8 = 0x80228448
export const RAM_HES_Item_IcePower_disabled = 0x800846c8
export const RAM_EF2680_FlyingAI_JumpInit = 0x80240c4c
export const RAM_omo_07_FlyingAI_ChaseInit = 0x80242638
export const RAM_func_80243754_9DA774 = 0x80243754
export const RAM_battle_item_please_come_back_GiveRefundCleanup = 0x802a11d4
export const RAM_D_09003640 = 0x09003640
export const RAM_battle_partner_goombario_func_80239190_6F2270 = 0x80239190
export const RAM_pra_35_reflection_setup_floor = 0x80240500
export const RAM_EnableSpriteShading = 0x802d9a3c
export const RAM_kpa_03_MagikoopaAI_TeleportAnim = 0x802425e0
export const RAM_arn_10_idle_802404F8 = 0x802404f8
export const RAM_D_80224D70 = 0x80224d70
export const RAM_D_E00C2990 = 0xe00c2990
export const RAM_D_802A25E4 = 0x802a25e4
export const RAM_func_E0082528 = 0xe0082528
export const RAM_D_80245330_CD0640 = 0x80245330
export const RAM_omo_06_StashVars = 0x80240870
export const RAM_kpa_09_ProjectileAI_Main = 0x802405fc
export const RAM_gPauseCursorTargetPosX = 0x8024efa8
export const RAM_D_80249DAC = 0x80249dac
export const RAM_nok_02_Pipe_GetEntryPos = 0x802400f0
export const RAM_pra_09_reflection_render_floor_fancy = 0x80240870
export const RAM_set_entity_model_flags = 0x80122ee8
export const RAM_vtx_C24960 = 0x80203a20
export const RAM_dro_01_Pipe_EnterHorizontal = 0x80243f84
export const RAM_nok_14_SetCamera0Flag1000 = 0x80240180
export const RAM_D_80243D38_A72118 = 0x80243d38
export const RAM_dgb_15_itemList_802419C0 = 0x802419c0
export const RAM_D_800A41B4 = 0x800a41b4
export const RAM_func_80240074_90B2B4 = 0x80240074
export const RAM_D_80243A80_DD0750 = 0x80243a80
export const RAM_HES_Item_ZapTap = 0x800848d8
export const RAM_b_area_isk_part_2_formation_00 = 0x8022042c
export const RAM_b_area_sam2_gray_magikoopa_GetSelectedMoveID = 0x80218968
export const RAM_HES_Item_KootShell = 0x80082148
export const RAM_D_80283D98 = 0x80283d98
export const RAM_D_800AC6D8 = 0x800ae6d8
export const RAM_func_802383F8_703EE8 = 0x802383f8
export const RAM_func_802415E0_D6FF90 = 0x802415e0
export const RAM_fx_04 = 0x8006fa10
export const RAM_dgb_05_802417F0 = 0x802417f0
export const RAM_end_01_UnkFunc26 = 0x80242e14
export const RAM_D_8010CCFC = 0x8010ccfc
export const RAM_vtx_C282D0 = 0x80207390
export const RAM_D_80243D78_A72158 = 0x80243d78
export const RAM_gCurtainDrawCallback = 0x8009baa0
export const RAM_battle_item_egg_missile1_pal = 0x802a1ad0
export const RAM_kzn_09_LetterDelivery_CalcLetterPos = 0x80243808
export const RAM_kpa_91_PatrolAI_PostLoiter = 0x8024067c
export const RAM_b_area_kmr_part_2_blue_goomba_partsTable_80219968 = 0x80219968
export const RAM_b_area_omo2_5_shy_squad_dup_onHit = 0x8023392c
export const RAM_D_802A15EC_737E7C = 0x802a161c
export const RAM_b_area_trd_part_1_formation_16 = 0x80218b3c
export const RAM_kzn_03_PiranhaPlantAI_10 = 0x80241630
export const RAM_D_09000CC8 = 0x09000cc8
export const RAM_PlayerRasterHeader = 0x802e0c10
export const RAM_gPausePartnersPartnerIdx = 0x80270680
export const RAM_dro_02_ItemChoice_SaveSelected = 0x80243314
export const RAM_b_area_nok_paragoomba_idleAnimations_8021CB48 = 0x8021cb48
export const RAM_mgm_01_UpdatetPanelHoldAboveBlock = 0x8024084c
export const RAM_b_area_arn_hyper_cleft_80220DC0 = 0x80220dc0
export const RAM_D_80250108_C0D988 = 0x80250108
export const RAM_b_area_arn_hyper_paragoomba_takeTurn_8021EE34 = 0x8021ee34
export const RAM_D_800AC5D0 = 0x800ac5d0
export const RAM_HES_Item_SpinSmash_disabled = 0x80083a08
export const RAM_entity_upgrade_block_check_if_inactive = 0x802e56ec
export const RAM_D_80258120 = 0x80258120
export const RAM_b_area_nok_formation_0F = 0x80219248
export const RAM_kkj_27_UpdateTexturePanStepped = 0x80240124
export const RAM_filemenu_main_handle_input = 0x80245a40
export const RAM_D_E0036630 = 0xe0036630
export const RAM_end_00_CharAnim_FadeOut_2 = 0x80241768
export const RAM_UseBattleCamPresetImmediately = 0x8024e664
export const RAM_D_80246568_C8C018 = 0x80246568
export const RAM_kzn_04_SuperBlock_ClearOverride40 = 0x8024032c
export const RAM_D_802465E0_C8C090 = 0x802465e0
export const RAM_dro_01_Pipe_GetCurrentFloor = 0x80240020
export const RAM_b_area_nok_paragoomba_CalculateRotationZ = 0x80218464
export const RAM_b_area_mac_lee_flyingTackle = 0x8021db18
export const RAM_flo_16_UnkFunc43 = 0x80240040
export const RAM_func_80218BC4_6BC754 = 0x80218bc4
export const RAM_dgb_01_D_80252420_C0FCA0 = 0x80252420
export const RAM_battle_star_star_beam_802A33A8 = 0x802a33a8
export const RAM_func_80218F50_5CF5E0 = 0x80218f50
export const RAM_HES_Item_FrozenFries_disabled = 0x80083588
export const RAM_purple_ring_render = 0xe003e5f4
export const RAM_calc_enemy_damage_target = 0x8027742c
export const RAM_dro_01_LetterDelivery_RestoreNpcAnim = 0x80243058
export const RAM_b_area_jan_spike_top_spikeTop_FallDown = 0x80223efc
export const RAM_D_8024C100_C09980 = 0x8024c100
export const RAM_btl_state_update_partner_menu = 0x802a81c8
export const RAM_npc_update_decoration_bowser_aura = 0x8003c668
export const RAM_energy_shockwave_update = 0xe0072150
export const RAM_dead_general_heap_malloc = 0x8002bf18
export const RAM_dup_kkj_16_UnkModelMatrixFunc = 0x80240a60
export const RAM_jan_09_MeleeHitbox_CanSeePlayer = 0x80240d5c
export const RAM_D_802BEC74 = 0x802bec74
export const RAM_flo_08_npcGroup_80243C48 = 0x80243c48
export const RAM_omo_11_SuperBlock_StartGlowEffect = 0x80240c10
export const RAM_func_8026709C = 0x8026709c
export const RAM_b_area_nok_formation_05 = 0x80218f38
export const RAM_func_802A928C_427CFC = 0x802a928c
export const RAM_vtx_C26410 = 0x802054d0
export const RAM_b_area_sam2_paragoomba_takeTurn_8021BD5C = 0x8021cadc
export const RAM_b_area_kmr_part_3_final_jr_troopa_sinTable = 0x8022fec8
export const RAM_dro_01_Quizmo_HideEntities = 0x80241588
export const RAM_D_8014FED0 = 0x8014fed0
export const RAM_func_802419E4_8445B4 = 0x802419e4
export const RAM_vtx_C2C080 = 0x8020b140
export const RAM_HES_FilenameSpace = 0x80241fbc
export const RAM_D_80245A00_A92480 = 0x80245a00
export const RAM_HES_Item_DriedFruit_disabled = 0x80086d08
export const RAM_D_802496F4_9E0714 = 0x802496f4
export const RAM_EB8E90_PatrolAI_Move = 0x80240198
export const RAM_D_8014C280 = 0x8014c280
export const RAM_IES_EggMissile = 0x8008d2d4
export const RAM_D_8010C9B4 = 0x8010c9b4
export const RAM_D_802B6EE0 = 0x802b6ee0
export const RAM_NpcSpriteInstanceCount = 0x802df958
export const RAM_flo_07_pad_8CC = 0x802408cc
export const RAM_b_area_kmr_part_3_mac_01_beforeBattle_80234B74 = 0x80234b74
export const RAM_flo_16_SuperBlock_HideBlockContent = 0x8024011c
export const RAM_func_80242FE0 = 0x80242fe0
export const RAM_dup_jan_08_foliage_setup_shear_mtx = 0x802421f0
export const RAM_b_area_mac_lee_idle_8021D5B4 = 0x8021d5b4
export const RAM_D_802AAA30 = 0x802aaa30
export const RAM_func_80240404_A3FB44 = 0x80240404
export const RAM_HES_Item_ApplePie_disabled = 0x80082868
export const RAM_jan_03_PatrolAI_Chase = 0x80240f6c
export const RAM_b_area_trd_part_2_fake_bowser_takeTurn_80224D84 = 0x80224d84
export const RAM_btl_state_draw_first_stike = 0x802480f0
export const RAM_D_8024AC9C_A34EDC = 0x8024ac9c
export const RAM_D_E0058788 = 0xe0058788
export const RAM_b_area_omo2_toy_tank_onHit = 0x8022d238
export const RAM_D_802A9A20_42EDC0 = 0x802a9a20
export const RAM_iwa_01_CleftAI_Tackle = 0x80242af8
export const RAM_D_09001F10_352130 = 0x09001f10
export const RAM_HES_DizzyEnd = 0x80105aa0
export const RAM_D_800A3FE4 = 0x800a3fe4
export const RAM_arn_02_CleftAI_Tackle = 0x80240834
export const RAM_vtx_C265D0 = 0x80205690
export const RAM_HES_Item_CrazyHeart = 0x80085358
export const RAM_b_area_trd_part_1_koopa_troopa_8021CBD8 = 0x8021cbd8
export const RAM_b_area_isk_part_1_formation_11 = 0x802245b8
export const RAM_au_swizzle_BK_instruments = 0x800549f8
export const RAM_partner_is_flying = 0x800ea5a4
export const RAM_something_rotating_appendGfx = 0xe0116884
export const RAM_jan_09_TransformFoliage = 0x80240660
export const RAM_b_area_hos_hos_00_foregroundModelList_802258AC = 0x802258ac
export const RAM_D_80225FC0_48D070 = 0x80225fc0
export const RAM_flo_09_TransformFoliage = 0x80240150
export const RAM_attack_result_text_render = 0xe0090420
export const RAM_enable_partner_blur = 0x80254644
export const RAM_D_8023D334 = 0x8023d334
export const RAM_PlaySoundAtModel = 0x802ca558
export const RAM_gPauseTabsHudScripts = 0x8024f1b0
export const RAM_HES_Item_JellyShroom_disabled = 0x800837c8
export const RAM_draw_encounter_ui = 0x8003e5b0
export const RAM_func_80242600_9B8F80 = 0x80242600
export const RAM_dgb_14_settings = 0x80240020
export const RAM_D_80248F70_A83470 = 0x80248f70
export const RAM_flo_15_main = 0x802401a8
export const RAM_battle_item_mystery_UseItem = 0x802a1c54
export const RAM_b_area_jan2_putrid_piranha_statusTable_8021B100 = 0x80218720
export const RAM_D_E00C49D0 = 0xe00c49d0
export const RAM_b_area_trd_part_3_eldstar_idle_802183E8 = 0x802183e8
export const RAM_HES_Item_HappyFlower_disabled = 0x800856e8
export const RAM_flo_21_intTable_8024258C = 0x8024258c
export const RAM_ui_battle_hp_1_pal = 0x8028a9c0
export const RAM_b_area_omo_green_shy_guy = 0x8021fef4
export const RAM_SetupMashMeter = 0x802691c8
export const RAM_IES_JellyUltra = 0x8008d054
export const RAM_b_area_pra3_red_magikoopa_UnkFunc52 = 0x80219ad8
export const RAM_func_8025DEB0 = 0x8025deb0
export const RAM_battle_move_shell_crack_IsRightOnEquipped = 0x802a1050
export const RAM_func_80136A08 = 0x80136a08
export const RAM_end_01_CreditsViewport = 0x80243200
export const RAM_dgb_09_MeleeHitbox_32 = 0x802400dc
export const RAM_sbk_30_foliage_setup_shear_mtx = 0x802415d0
export const RAM_b_area_mac_master2_idle_8022B8B0 = 0x8022b8b0
export const RAM_b_area_trd_part_2_red_ninja_koopa_UnkEnemyPosFunc = 0x80218d70
export const RAM_dgb_14_pad_F8 = 0x802400f8
export const RAM_D_802416B0_B992B0 = 0x802416b0
export const RAM_func_80219250_5CF8E0 = 0x80219250
export const RAM_D_8024F550_852120 = 0x8024f550
export const RAM_BringPartnerOut = 0x802cf5b8
export const RAM_D_802A1A64_00735004 = 0x802a1a64
export const RAM_IES_Mailbag = 0x8008bae4
export const RAM_battle_item_hustle_drink_GiveRefund = 0x802a1000
export const RAM_kpa_61_UnkNpcAIFunc12 = 0x802404c0
export const RAM_firework_rocket_main = 0xe010a000
export const RAM_force_player_anim = 0x800dff50
export const RAM_D_8014F584 = 0x8014f584
export const RAM_kpa_95_ToadHouse_PartnerResumeAbilityScript = 0x802417a8
export const RAM_b_area_kmr_part_2_kmr_04 = 0x80224fcc
export const RAM_vtx_C28110 = 0x802071d0
export const RAM_b_area_sam2_paragoomba_CalculateRotationZ = 0x80218714
export const RAM_func_8021B9A8_6A2BA8 = 0x8021b9a8
export const RAM_D_802BEC20_31CC40 = 0x802bec20
export const RAM_footprint_appendGfx = 0xe00183d8
export const RAM_dgb_01_SentinelAI_ReturnHomeInit = 0x80241ef0
export const RAM_b_area_trd_part_1_bob_omb_tackleAttack = 0x8021bb40
export const RAM_kzn_08_FlyingAI_JumpInit = 0x8024162c
export const RAM_b_area_kmr_part_1_paragoomba_idle_8021CBE0 = 0x8021e5d0
export const RAM_JumpWithBounce = 0x80279728
export const RAM_D_802B6EE8 = 0x802b6ee8
export const RAM_action_command_power_shock_draw_hud_elements = 0x802a98d0
export const RAM_D_8024DCCC_A37F0C = 0x8024dccc
export const RAM_D_80243000_AA1D40 = 0x80243000
export const RAM_D_09003740 = 0x09003740
export const RAM_D_E0126BD0 = 0xe0126bd0
export const RAM_b_area_isk_part_2_tutankoopa_idleAnimations_8021B9AC = 0x8021b9ac
export const RAM_PeachDisguiseExtraAnims = 0x800f7c7c
export const RAM_kzn_17_PiranhaPlantAI_13 = 0x80241918
export const RAM_kzn_07_UpdateTexturePanSmooth = 0x8024040c
export const RAM_b_area_kmr_part_1_spiked_goomba_idleAnimations_8021E4B4 = 0x8021b0d4
export const RAM_energy_in_out_init = 0xe00d6718
export const RAM_vtx_C236E0 = 0x802027a0
export const RAM_omo_17_IsAOrBPressed = 0x80240518
export const RAM_D_80241778_DDDFA8 = 0x80241778
export const RAM_PlayerFallToGoal = 0x80273894
export const RAM_squirt_init = 0xe00b21dc
export const RAM_msg_get_draw_char_width = 0x80125df4
export const RAM_func_80025F44 = 0x80025f44
export const RAM_filemenu_yesno_cleanup = 0x80247d1c
export const RAM_pra_35_reflection_render_floor_fancy = 0x80240870
export const RAM_D_802453E0_EEB990 = 0x802453e0
export const RAM_deduct_current_move_fp = 0x80263bc8
export const RAM_b_area_sam_frost_piranha_attack_bite = 0x8022b3ac
export const RAM_b_area_trd_part_1_bill_blaster_handleEvent_80219EE0 = 0x80219ee0
export const RAM_tik_22_Pipe_GetPointAheadOfPlayer = 0x80240578
export const RAM_kzn_04_SuperBlock_get_partner_rank = 0x802403e4
export const RAM_tik_08_Pipe_GetCameraYaw = 0x802401d0
export const RAM_D_090016E8 = 0x090016e8
export const RAM_msg_draw_speech_arrow = 0x8012d3dc
export const RAM_func_E00183BC = 0xe00183bc
export const RAM_flo_25_makeEntities = 0x80243c90
export const RAM_b_area_omo_omo_05b_beforeBattle_8022F580 = 0x8022f580
export const RAM_GotoMap = 0x802ca400
export const RAM_sbk_30_StationaryAI_ReturnHome = 0x8024095c
export const RAM_ClearPartnerMoveHistory = 0x802cf370
export const RAM_EVS_UpdateMotionBlurParams = 0x802d9d50
export const RAM_D_E00E2A08 = 0xe00e2a08
export const RAM_flo_03_npcGroupList_8024442C = 0x8024442c
export const RAM_battle_item_dusty_hammer_main = 0x802a1b6c
export const RAM_b_area_isk_part_1_isk_04_afterBattle_80223B08 = 0x80223b08
export const RAM_func_80241274_D59B04 = 0x80241274
export const RAM_D_09000BE8 = 0x09000be8
export const RAM_filemenu_draw_contents_file_create_header = 0x802481b8
export const RAM_D_80246558_C8C008 = 0x80246558
export const RAM_au_BGMCmd_EF_SegTrackTune = 0x8004ffb4
export const RAM_D_80155C98 = 0x80155c98
export const RAM_bss_802BFEFC = 0x802bfefc
export const RAM_mim_07_HoppingAI_Loiter = 0x80240898
export const RAM_HES_Item_RedBerry = 0x800869d8
export const RAM_D_802AD00A = 0x802ad00a
export const RAM_gameModeMap = 0x80077850
export const RAM_nuGfxPreNMIFuncSet = 0x8005f290
export const RAM_btl_merlee_on_start_turn = 0x80240e60
export const RAM_D_8029F64C = 0x8029f64c
export const RAM_IES_ISpy = 0x8008c6e4
export const RAM_flo_16_SuperBlock_ClearOverride40 = 0x802400fc
export const RAM_D_8009A5D0 = 0x8009a5d0
export const RAM_update_item_entity_temp = 0x801356dc
export const RAM_status_menu_stop_blinking_starpoints = 0x800e9b0c
export const RAM_func_80240528_907F58 = 0x80240528
export const RAM_D_8009A644 = 0x8009a644
export const RAM_PlaySoundAtNpc = 0x802d01ac
export const RAM_SetVirtualEntityFlags = 0x802d3f74
export const RAM_init_trigger_list = 0x80145728
export const RAM_D_801568FC = 0x801568fc
export const RAM_btl_state_draw_peach_menu = 0x802aa05c
export const RAM_dgb_12_varStash = 0x80240870
export const RAM_omo_09_PatrolAI_JumpInit = 0x8024167c
export const RAM_kzn_03_MeleeHitbox_33 = 0x80240dac
export const RAM_D_802B6790 = 0x802b6790
export const RAM_b_area_kgr_kgr_01_8021C9C0 = 0x8021c9c0
export const RAM_func_8011B7C0 = 0x8011b7c0
export const RAM_D_802315A8_63D148 = 0x802315a8
export const RAM_kkj_25_SetPlayerSpriteSet2 = 0x80240000
export const RAM_D_800F7FFC = 0x800f7ffc
export const RAM_D_802B6798 = 0x802b6798
export const RAM_flo_15_init_80240CD0 = 0x80240cd0
export const RAM_HES_Bow = 0x80107e38
export const RAM_D_8024DFC0 = 0x8024dfc0
export const RAM_HES_StatusSPIncrement7 = 0x80108388
export const RAM_nuGfxSwapCfb = 0x80060350
export const RAM_b_area_sam_pos_rocks_5 = 0x80230760
export const RAM_sam_01_LetterDelivery_RestoreNpcAnim = 0x80241a54
export const RAM_b_area_kzn_kzn_02_foregroundModelList_80227DA8 = 0x80227da8
export const RAM_ui_bow_png = 0x800fef20
export const RAM_mac_02_Quizmo_Worker = 0x8024e540
export const RAM_flo_16_FlyingAI_LoiterInit = 0x80241628
export const RAM_nok_11_FlyingAI_ChaseInit = 0x80241494
export const RAM_kmr_02_Quizmo_GetCamVfov = 0x80240f70
export const RAM_tik_04_UpdateTexturePanStepped = 0x80240124
export const RAM_D_09002550 = 0x09002550
export const RAM_flo_08_npcAISettings_80242EB0 = 0x80242eb0
export const RAM_pra_16_reflection_setup_floor = 0x80240500
export const RAM_hieroglyphs_update = 0xe00e2134
export const RAM_HES_Item_Coin = 0x80080868
export const RAM_world_sushie_take_out = 0x802bfddc
export const RAM_D_80252BC8 = 0x80252bc8
export const RAM_flo_14_func_8024042C_CCB73C = 0x8024042c
export const RAM_dgb_03_entryList = 0x802427d0
export const RAM_EC9D00_SuperBlock_RadiateFaintEnergyFX = 0x802405a8
export const RAM_D_802429BC = 0x802429bc
export const RAM_func_80261388 = 0x80261388
export const RAM_D_8014FED8 = 0x8014fed8
export const RAM_D_8014BBD8 = 0x8014bbd8
export const RAM_battle_item_repel_gel_func_802A123C_72DDAC = 0x802a123c
export const RAM_battle_item_food_func_802A1438_733508 = 0x802a1438
export const RAM_D_80242EE0_DE5370 = 0x80242ee0
export const RAM_SetEntityCullMode = 0x80111e9c
export const RAM_func_802BE724_322274 = 0x802be724
export const RAM_b_area_dgb_tubba_blubba_partsTable_80218D24 = 0x80218d24
export const RAM_D_802445C0_A06E00 = 0x802445c0
export const RAM_D_8010CD34 = 0x8010cd34
export const RAM_b_area_kgr_fuzzipede_defenseTable_80218A48 = 0x80218a48
export const RAM_kkj_27_UpdateTexturePanSmooth = 0x80240000
export const RAM_obk_03_LetterDelivery_SavedNpcAnim = 0x80243da4
export const RAM_D_8024F588 = 0x8024f588
export const RAM_D_802491F8_EC2048 = 0x802491f8
export const RAM_PlayBattleMerleeGatherFX = 0x802613bc
export const RAM_dgb_02_exitSingleDoor_802416B8 = 0x802416b8
export const RAM_D_80078DDC = 0x80078ddc
export const RAM_b_area_sam_duplighost_OnHitElectric = 0x8021bc34
export const RAM_mdl_renderModelFogPrimColorA = 0x8014b753
export const RAM_dgb_01_D_80252160_C0F9E0 = 0x80252160
export const RAM_flo_14_npcAI_80244508 = 0x80244508
export const RAM_sam_11_Quizmo_ShouldQuizmoLeave = 0x8024130c
export const RAM_osVirtualToPhysical = 0x80061e90
export const RAM_func_8024092C_B0732C = 0x8024092c
export const RAM_jan_00_unkVtxFunc001 = 0x80241338
export const RAM_b_area_omo2_toy_tank_formation_shy_stack_1 = 0x8022f5a8
export const RAM_get_background_color_blend = 0x8011be80
export const RAM_D_80245498_BB2DD8 = 0x80245498
export const RAM_got_item_outline_appendGfx = 0xe0036224
export const RAM_au_reset_drum_entry = 0x80053370
export const RAM_flo_18_npcSettings_80243F10 = 0x80243f10
export const RAM_battle_move_auto_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_nok_14_UnkDistFunc = 0x8024023c
export const RAM_b_area_mim_piranha_plant_statusTable_8021D3A0 = 0x8021d3a0
export const RAM_evt_handle_set_var = 0x802c5744
export const RAM_dgb_08_FlyingAI_LosePlayer = 0x802425b8
export const RAM_b_area_isk_part_2_isk_00 = 0x8021fd3c
export const RAM_func_802403D8_9462A8 = 0x802403d8
export const RAM_flo_21_init_802435E4 = 0x802435e4
export const RAM_flo_13_LakituAI_Loiter = 0x80241b68
export const RAM_battle_item_sleepy_sheep3_pal = 0x802a2f30
export const RAM_battle_item_egg_missile_PlayerGoHome = 0x802a1740
export const RAM_D_E0126BD8 = 0xe0126bd8
export const RAM_pra_34_ReflectPartner = 0x80240d3c
export const RAM_func_80240358_8EC178 = 0x80240358
export const RAM_func_80240434_AA93D4 = 0x80240434
export const RAM_D_E008CB20 = 0xe008cb20
export const RAM_partner_init_put_away = 0x800ee994
export const RAM_dgb_01_exitDoubleDoor_80243988 = 0x80243988
export const RAM_vtx_C266D0 = 0x80205790
export const RAM_b_area_kmr_part_2_goomnut_tree_idle_80223678 = 0x80223678
export const RAM_D_802A3920_73A1B0 = 0x802a3950
export const RAM_hos_20_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_800E9860 = 0x800e9860
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_802286A0 = 0x802286a0
export const RAM_dgb_04_SuperBlock_WhiteScreenFlash = 0x80242f08
export const RAM_vtx_C24720 = 0x802037e0
export const RAM_func_802406A8_A39B88 = 0x802406a8
export const RAM_D_8014C0C0 = 0x8014c0c0
export const RAM_sam_07_PiranhaPlantAI_12 = 0x80241b58
export const RAM_D_E0064A70 = 0xe0064a70
export const RAM_arn_10_npcGroupList_80240BB8 = 0x80240bb8
export const RAM_HES_MenuDefend = 0x802921b0
export const RAM_dgb_16_MeleeHitbox_30 = 0x80240000
export const RAM_func_8024025C_D78ADC = 0x8024025c
export const RAM_kzn_08_PiranhaPlantAI_14 = 0x80243ba4
export const RAM_D_E0064A78 = 0xe0064a78
export const RAM_func_80242E84_854FF4 = 0x80242e84
export const RAM_nok_14_ParatroopaAI_Overshoot = 0x80242070
export const RAM_EED1E0_SuperBlock_EndGlowEffect = 0x802405f0
export const RAM_mgm_02_CreateScoreDisplay = 0x80240468
export const RAM_CreateEntityVarArgBuffer = 0x8015c7d0
export const RAM_dro_02_8024D434 = 0x8024d434
export const RAM_sbk_02_varStash = 0x802441d8
export const RAM_D_E0034780 = 0xe0034780
export const RAM_D_80280FC0 = 0x80280fc0
export const RAM_D_80098194 = 0x80098194
export const RAM_kzn_07_FlyingAI_ChaseInit = 0x802414e0
export const RAM_D_E0084E10 = 0xe0084e10
export const RAM_dgb_08_SentinelAI_LosePlayerInit = 0x80243230
export const RAM_EB7230_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802B7000_E225B0 = 0x802b7000
export const RAM_func_80241044_A2B284 = 0x80241044
export const RAM_func_802BB044_E2B774 = 0x802bb044
export const RAM_b_area_sam2_sam_02b_UnkModelStuffScript1 = 0x80222650
export const RAM_func_80218B60_5CF1F0 = 0x80218b60
export const RAM_nok_01_ToadHouse_PartnerResumeAbilityScript = 0x80240c0c
export const RAM_floating_rock_init = 0xe0098168
export const RAM_ui_partner0_pal = 0x800fe5e0
export const RAM_fx_balloon = 0x800714b0
export const RAM_arn_07_init_8024706C = 0x8024706c
export const RAM_D_8024A6B0_AE5550 = 0x8024a6b0
export const RAM_b_area_trd_part_1_vector3D_80218B90 = 0x80218b90
export const RAM_flo_18_802413F0 = 0x802413f0
export const RAM_dgb_18_dgb_01_name_hack = 0x80243a80
export const RAM_b_area_dgb_clubba_init_80218150 = 0x80218150
export const RAM_gAreas = 0x800934f0
export const RAM_D_802BE0C0 = 0x802be0c0
export const RAM_D_802E9900 = 0x802e9900
export const RAM_battle_menu_hasSpiritsMenu = 0x802ad4a0
export const RAM_entity_TriggerBlock_enable_player_input = 0x802e4540
export const RAM_dgb_09_ClubbaNappingAI_Init = 0x802408d0
export const RAM_dgb_15_PatrolNoAttackAI_15 = 0x802413a4
export const RAM_battle_star_time_out_FadeBackgroundToBlack = 0x802a12e4
export const RAM_func_80112DFC = 0x80112dfc
export const RAM_resume_all_script = 0x802c448c
export const RAM_b_area_isk_part_1_pokey_mummy_8021A6C8 = 0x8021a6c8
export const RAM_music_note_update = 0xe004c20c
export const RAM_pra_10_ReflectWall = 0x802400ec
export const RAM_D_80246A20 = 0x80246a20
export const RAM_activate_defend_command = 0x80260b04
export const RAM_battle_move_d_down_pound_IsBerserkerEquipped = 0x802a1000
export const RAM_b_area_mim_bzzap_UnkBattleFunc1 = 0x80218170
export const RAM_bombette_breaking_main = 0xe0084084
export const RAM_b_area_kzn_red_magikoopa_8022C93C = 0x8022167c
export const RAM_b_area_jan_spike_top_spikeTop_HandleEvent_Ceiling = 0x80224330
export const RAM_jan_09_SpearGuyAI_Loiter = 0x80240990
export const RAM_D_8024ECB0_C0C530 = 0x8024ecb0
export const RAM_dup_b_area_kpa_StartRumbleWithParams = 0x802185e0
export const RAM_b_area_pra3_swoopula_hadleEvent_flying = 0x80227220
export const RAM_b_area_nok_paratroopa_idleAnimations_8021ED00 = 0x80223b30
export const RAM_entity_Chest_start_bound_script = 0x802e7c70
export const RAM_IES_KookyCookie = 0x8008d434
export const RAM_D_800741A2 = 0x800741a2
export const RAM_MakeShop = 0x80281860
export const RAM_b_area_iwa_cleft_SetSpinSmashable = 0x80218000
export const RAM_fx_shockwave = 0x80070670
export const RAM_evt_handle_bind = 0x802c6714
export const RAM_b_area_omo_green_shy_guy_partsTable_8021FED0 = 0x8021fed0
export const RAM_state_drawUI_exit_language_select = 0x80035d18
export const RAM_D_E00AC7F8 = 0xe00ac7f8
export const RAM_D_8014F4C4 = 0x8014f4c4
export const RAM_func_802BBD78_E2E6A8 = 0x802bbd78
export const RAM_HES_Item_Multibounce = 0x80083a38
export const RAM_dup_mac_01_PatrolNoAttackAI_15 = 0x80246024
export const RAM_HES_Whirlwind6 = 0x802aa7c8
export const RAM_D_8000031C = 0x8000031c
export const RAM_kpa_15_CheckItemFlags40 = 0x80240578
export const RAM_btl_state_draw_run_away = 0x80245438
export const RAM_func_8026D8EC = 0x8026d8ec
export const RAM_gAnimModelFogEnabled = 0x80153a44
export const RAM_pra_35_PatrolAI_Chase = 0x8024186c
export const RAM_phys_main_collision_below = 0x800e4744
export const RAM_kzn_03_varStash = 0x802441a0
export const RAM_D_802A2BDC_73946C = 0x802a2c0c
export const RAM_b_area_sam_sam_02_UnkModelStuff_array = 0x8022f3e0
export const RAM_b_area_kgr_fuzzipede_idleAnimations_802189FC = 0x802189fc
export const RAM_func_80240688_E1EAE8 = 0x80240688
export const RAM_isk_18_AwaitPlayerNearNpc = 0x8024081c
export const RAM_basic_ai_chase = 0x8004a124
export const RAM_ui_battle_status_charge_goombario_pal = 0x80101b20
export const RAM_func_802428C0_E07C20 = 0x802428c0
export const RAM_nuScExecuteAudio = 0x8005ebe0
export const RAM_tik_17_UpdateTexturePanStepped = 0x80241174
export const RAM_flo_19_func_802404D0_CE3BC0 = 0x802404d0
export const RAM_func_802441EC_804A6C = 0x802441ec
export const RAM_b_area_sam2_sam_02d = 0x80223050
export const RAM_D_802BFF18 = 0x802bff18
export const RAM_b_area_sam_frost_piranha_takeTurn = 0x8022b2d4
export const RAM_sbk_34_SpawnSunEffect = 0x80240000
export const RAM_D_80246E28_9F4A18 = 0x80246e28
export const RAM_dgb_03_npcSettings_80243AFC = 0x80243afc
export const RAM_sam_09_StashVars = 0x80240000
export const RAM_b_area_mim_piranha_plant_takeTurn_8021DCD4 = 0x8021dcd4
export const RAM_vtx_C2A080 = 0x80209140
export const RAM_strcmp = 0x8006ed70
export const RAM_D_090025E0_388990 = 0x090025e0
export const RAM_D_80150BC0 = 0x80150bc0
export const RAM_vtx_C25EA0 = 0x80204f60
export const RAM_tik_07_FlyingAI_Main = 0x802426fc
export const RAM_phys_adjust_cam_on_landing = 0x800e5530
export const RAM_kpa_111_ProjectileHitbox_32 = 0x802405e8
export const RAM_pra_40_ReflectPartner = 0x80240d3c
export const RAM_pause_interp_cursor = 0x80242e6c
export const RAM_b_area_kmr_part_3_paragoomba2_takeTurn_8021BD5C = 0x8022039c
export const RAM_func_802402BC_AF1F1C = 0x802402bc
export const RAM_vtx_C2C1C0 = 0x8020b280
export const RAM_flo_12_GetNpcCollisionHeight = 0x80240394
export const RAM_IES_CakeBatter = 0x8008b194
export const RAM_dro_02_settings = 0x80243ab0
export const RAM_PauseTakeTurn = 0x802789a4
export const RAM_func_802687E4 = 0x802687e4
export const RAM_func_80240358_8C82E8 = 0x80240358
export const RAM_battle_action_cmd_whirlwind_6_pal = 0x802aa040
export const RAM_D_80243DD8_C91988 = 0x80243dd8
export const RAM_pra_34_reflection_unk_change_anim_facing = 0x80240054
export const RAM_npc_set_decoration = 0x8003c3d8
export const RAM_mac_03_Quizmo_SetVannaAnim_Wave = 0x80240fd4
export const RAM_entity_Chest_reset_camera = 0x802e6e90
export const RAM_b_area_omo_formation_29 = 0x802305fc
export const RAM_create_generic_entity_backUI = 0x80123320
export const RAM_obk_03_LetterDelivery_RestoreNpcAnim = 0x8024089c
export const RAM_D_8024DD88 = 0x8024dd88
export const RAM_D_8024AE70_C086F0 = 0x8024ae70
export const RAM_inflict_partner_ko = 0x80266254
export const RAM_sam_02_ToadHouse_ShowStatusMenu = 0x80240850
export const RAM_D_80294200 = 0x80294200
export const RAM_tik_02_StashVars = 0x80240310
export const RAM_b_area_omo2_3_shy_stack_idleAnimations = 0x80231308
export const RAM_gPauseTabsHorizScrollPos = 0x8027014c
export const RAM_mac_04_Quizmo_SetVannaAnim_Idle = 0x80240f50
export const RAM_IES_MysticKey = 0x8008b724
export const RAM_func_802DDEE4 = 0x802ddee4
export const RAM_b_area_kmr_part_3_paragoomba1_StartRumbleWithParams = 0x80218280
export const RAM_radial_shimmer_update = 0xe0066498
export const RAM_b_area_omo2_toy_tank_update_phase = 0x8022db10
export const RAM_jan_08_MeleeHitbox_31 = 0x802416d4
export const RAM_iwa_10_SuperBlock_RadiateFaintEnergyFX = 0x80240898
export const RAM_b_area_isk_part_1_vector3D_802240C4 = 0x802240c4
export const RAM_SetPlayerActionState = 0x802d10d8
export const RAM_pra_27_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_b_area_jan_m_bush_statusTable_80221890 = 0x80221890
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_handleEvent = 0x802340a4
export const RAM_dro_02_image1 = 0x802475e8
export const RAM_D_802E9C70 = 0x802e9c70
export const RAM_D_802432D4_CFAE44 = 0x802432d4
export const RAM_b_area_arn_tubba_blubba = 0x80225d70
export const RAM_IES_Unused_0D6 = 0x8008c7a4
export const RAM_b_area_trd_part_2_green_ninja_koopa_idle_80227DE0 = 0x80227de0
export const RAM_tik_22_UnkPosFunc2 = 0x80240640
export const RAM_osStartThread = 0x80066020
export const RAM_mac_00_PatrolNoAttackAI_Main = 0x80243104
export const RAM_pra_39_SetPartnerFlags80000 = 0x80240ee8
export const RAM_D_80242680_8D8480 = 0x80242680
export const RAM_kpa_51_MagikoopaAI_11 = 0x80240b14
export const RAM_D_8015A1E0 = 0x8015a1e0
export const RAM_sam_01_Quizmo_DestroyEffects = 0x80241088
export const RAM_kmr_12_npcGroupList = 0x802409a8
export const RAM_Entity_ShatteringHammer2BlockTiny = 0x802e9d1c
export const RAM_mac_00_PatrolAI_ChaseInit = 0x80242d8c
export const RAM_D_8015A57C = 0x8015a57c
export const RAM_D_80250220_C0DAA0 = 0x80250220
export const RAM_func_80240000_A927C0 = 0x80240000
export const RAM_jan_04_CheckItemFlags40 = 0x802405b8
export const RAM_b_area_kmr_part_1_kmr_04_clouds3 = 0x80220480
export const RAM_something_rotating_update = 0xe011618c
export const RAM_dup_b_area_pra_UnkBattleFunc1 = 0x80218484
export const RAM_HES_Item_VoltShroom = 0x80086438
export const RAM_D_8010C93C = 0x8010c93c
export const RAM_nok_01_Quizmo_ShowEntities = 0x80240e94
export const RAM_b_area_kmr_part_3_kmr_05 = 0x80232fbc
export const RAM_render_effects_UI = 0x80059f94
export const RAM_b_area_omo2_3_shy_stack_onSpinSmash = 0x80233c4c
export const RAM_ui_status_coin_1_png = 0x80100ba0
export const RAM_state_init_unpause = 0x80034bfc
export const RAM_func_8024089C_AD492C = 0x8024089c
export const RAM_cloud_trail_update = 0xe0016228
export const RAM_b_area_sam_duplighost_idle = 0x8021b110
export const RAM_battle_item_strange_cake_func_802A1AD8_731DD8 = 0x802a1ad8
export const RAM_update_triggers = 0x80145860
export const RAM_D_09000E00_3871B0 = 0x09000e00
export const RAM_IES_Mushroom = 0x8008d7d4
export const RAM_arn_10_init_80240720 = 0x80240720
export const RAM_nuAuPreNMI = 0x80078170
export const RAM_b_area_iwa_whacka_takeTurn_80220634 = 0x80220634
export const RAM_b_area_mac_chan_defenseTable_80219D6C = 0x80219d6c
export const RAM_dro_01_PatrolAI_Jump = 0x80240d84
export const RAM_partner_move_to_goal = 0x800ef82c
export const RAM_fold_groupDescriptors = 0x80156980
export const RAM_NAMESPACE_8023A584 = 0x8023a584
export const RAM_D_8010C978 = 0x8010c978
export const RAM_func_80240928_A1BA28 = 0x80240928
export const RAM_D_8010BAE8 = 0x8010bae8
export const RAM_func_8014AD40 = 0x8014ad40
export const RAM_b_area_isk_part_1_swooper_partsTable_8021C568 = 0x8021c568
export const RAM_flo_23_TackleAI_Main = 0x80240728
export const RAM_func_80266970 = 0x80266970
export const RAM_vtx_C2E8C0 = 0x8020d980
export const RAM_vtx_C2A3C0 = 0x80209480
export const RAM_dgb_09_80244370 = 0x80244370
export const RAM_D_8023D1E0 = 0x8023d1e0
export const RAM_D_09000A40_32D900 = 0x09000a40
export const RAM_func_802A1074_737904 = 0x802a1074
export const RAM_D_8023D2C8 = 0x8023d2c8
export const RAM_arn_10_init_8024076C = 0x8024076c
export const RAM_battle_item_thunder_rage_DrinkItem = 0x802a19e4
export const RAM_b_area_pra3_duplighost_formation_goombario = 0x8021d6e0
export const RAM_func_802A9278_428CE8 = 0x802a9278
export const RAM_AssignCrateFlag = 0x80112170
export const RAM_b_area_jan2_jan_04_beforeBattle_8021F490 = 0x8021f490
export const RAM_dgb_01_D_80252580_C0FE00 = 0x80252580
export const RAM_flo_25_D_8024510C_pad = 0x8024510c
export const RAM_b_area_iwa_monty_mole_takeTurn_8021AFAC = 0x8021afac
export const RAM_flo_07_UpdateTexturePanSmooth = 0x80240000
export const RAM_flo_12_80240B78 = 0x80240b78
export const RAM_energy_in_out_appendGfx = 0xe00d6980
export const RAM_mac_05_Quizmo_Worker = 0x802529d0
export const RAM_b_area_omo_red_shy_guy_idleAnimations_8021931C = 0x8021931c
export const RAM_b_area_sam2_sam_03_UnkModelStuffScript4 = 0x80223274
export const RAM_fx_motion_blur_flame = 0x800716f0
export const RAM_D_80246380_915A30 = 0x80246380
export const RAM_mac_03_GetFloorCollider = 0x80241f40
export const RAM_b_area_pra3_pra_03 = 0x80231f6c
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_takeTurn = 0x80235830
export const RAM_flo_23_set_script_owner_npc_col_height = 0x802406a8
export const RAM_flo_21_80243010 = 0x80243010
export const RAM_snd_load_BGM = 0x8005465c
export const RAM_func_80243494_803D14 = 0x80243494
export const RAM_nok_13_HoppingAI_Chase = 0x802408ac
export const RAM_npc_move_heading = 0x8003aa54
export const RAM_b_area_kmr_part_3_jr_troopa_partsTable_80222AE4 = 0x80222ae4
export const RAM_btl_state_update_end_turn = 0x802439d8
export const RAM_battle_star_lullaby_802A1D50 = 0x802a1d50
export const RAM_D_E0122CD8 = 0xe0122cd8
export const RAM_kzn_09_SuperBlock_UpgradeOrbAngles = 0x80243d58
export const RAM_D_8024877C_82BA3C = 0x8024877c
export const RAM_kpa_102_FlyingMagikoopaAI_16 = 0x802429c8
export const RAM_b_area_mac_master3 = 0x8022db38
export const RAM_mac_00_PatrolAI_LosePlayer = 0x80242fdc
export const RAM_EDEA00_LakituAI_Wander = 0x80241730
export const RAM_func_802411BC_A3A69C = 0x802411bc
export const RAM_b_area_hos_magikoopa_defenseTable_802214B4 = 0x802214b4
export const RAM_func_80242384_833B84 = 0x80242384
export const RAM_omo_07_PatrolAI_MoveInit = 0x8024056c
export const RAM_dro_01_dup_Pipe_GetPointAheadOfPlayer = 0x802437dc
export const RAM_dup_kkj_16_UnkPhysicsFunc = 0x80240850
export const RAM_b_area_omo_sky_guy = 0x80229854
export const RAM_battle_item_mystery_pal = 0x802a2110
export const RAM_firework_main = 0xe0086000
export const RAM_dgb_10_exitWalk_802402F0 = 0x802402f0
export const RAM_arn_11_80240680 = 0x80240680
export const RAM_action_command_break_free_free_hud_elements = 0x802a97fc
export const RAM_EC9D00_SuperBlock_get_partner_rank = 0x802400d4
export const RAM_D_80245970_BAA0F0 = 0x80245970
export const RAM_D_80242180 = 0x80242180
export const RAM_flo_24_makeEntities = 0x802421b0
export const RAM_dgb_01_init_80245F08 = 0x80245f08
export const RAM_b_area_omo_blue_shy_guy_partsTable_8021ADD0 = 0x8021add0
export const RAM_func_80218A60_4EFF00 = 0x80218a60
export const RAM_nuScSetFrameBufferNum = 0x8005ef90
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_UnkEnemyFunc = 0x80219c50
export const RAM_b_area_mim_bzzap_statusTable_8021A878 = 0x8021a878
export const RAM_b_area_trd_part_2_fake_bowser_8021B6F0 = 0x8021b6f0
export const RAM_b_area_sam_sam_01_UnkModelStuff_func1 = 0x80219bb0
export const RAM_pra_34_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_80099B90 = 0x80099b90
export const RAM_flo_25_shakeTree = 0x80244040
export const RAM_D_80283744 = 0x80283744
export const RAM_D_802DB6C0 = 0x802db6c0
export const RAM_isk_14_HoppingAI_LosePlayer = 0x80240bac
export const RAM_D_80245F38_AD4078 = 0x80245f38
export const RAM_D_802DFE9C = 0x802dfe9c
export const RAM_Disable8bitMario = 0x802d2b88
export const RAM_func_80057344 = 0x80057344
export const RAM_dro_02_8024D700 = 0x8024d700
export const RAM_D_80098250 = 0x80098250
export const RAM_jan_01_foliage_setup_shear_mtx = 0x80241190
export const RAM_func_80240FE4_BD4854 = 0x80240fe4
export const RAM_b_area_jan_jan_04b_beforeBattle_802284F0 = 0x802284f0
export const RAM_func_80241BC0_C8F770 = 0x80241bc0
export const RAM_flo_13_FlyingAI_ChaseInit = 0x80240e84
export const RAM_func_8012DFE8 = 0x8012dfe8
export const RAM_b_area_sam2_sam_02d_UnkModelStuff_func1 = 0x80219800
export const RAM_EVS_MusicEventMonitor = 0x802d9d34
export const RAM_nok_12_PatrolAI_LosePlayer = 0x802417b0
export const RAM_check_input_status_menu = 0x800e728c
export const RAM_popup_menu_draw_title_contents = 0x800f4648
export const RAM_dgb_08_MeleeHitbox_31 = 0x80240efc
export const RAM_func_802BB704_E30054 = 0x802bb704
export const RAM_HES_Spirit1Missing = 0x80242408
export const RAM_ui_battle_cmd_aim_marker_7_png = 0x8028e9e0
export const RAM_big_smoke_puff_appendGfx = 0xe0002300
export const RAM_gCurrentDoorSoundsSet = 0x80151340
export const RAM_b_area_omo_formation_1D = 0x8023020c
export const RAM_nok_01_Quizmo_NPC_Aux_Impl = 0x80241114
export const RAM_func_80240020_D77F40 = 0x80240020
export const RAM_func_802A19A8_78BFA8 = 0x802a19a8
export const RAM_state_step_logos = 0x800340a4
export const RAM_func_80240468_D7F1C8 = 0x80240468
export const RAM_D_802ACC68 = 0x802acc68
export const RAM_D_E0034788 = 0xe0034788
export const RAM_D_8029D350 = 0x8029d350
export const RAM_fold_add_to_gfx_cache = 0x8013a610
export const RAM_gRenderModelPrimR = 0x8014b760
export const RAM_tik_12_SomeItemEntityFunc = 0x80240a78
export const RAM_func_E0078274 = 0xe0078274
export const RAM_D_80225FB0_48D060 = 0x80225fb0
export const RAM_aura_update = 0xe0076378
export const RAM_D_801512F0 = 0x801512f0
export const RAM_func_8026DF88 = 0x8026df88
export const RAM_FadeOutMerlee = 0x8003f084
export const RAM_D_802E9A3C = 0x802e9a3c
export const RAM_D_8025338C = 0x8025338c
export const RAM_func_802402BC_918E6C = 0x802402bc
export const RAM_shim_guPerspectiveF = 0xe0200580
export const RAM_func_801356CC = 0x801356cc
export const RAM_dgb_03_PatrolAI_Jump = 0x802412c4
export const RAM_D_802B6EDC = 0x802b6edc
export const RAM_dro_01_shopOwnerNPC_8024BA80 = 0x8024ba80
export const RAM_mac_01_AddPlayerHandsOffset = 0x80243b04
export const RAM_func_8023849C_6FB1AC = 0x8023849c
export const RAM_func_802BE070 = 0x802be070
export const RAM_init_encounters_ui = 0x80044140
export const RAM_dgb_08_npcGroup_80246020 = 0x80246020
export const RAM_tik_20_UpdateTexturePanStepped = 0x80240124
export const RAM_ItemEntityRenderGroup = 0x80155d88
export const RAM_smoke_impact_init = 0xe003a1d4
export const RAM_shim_guOrthoF = 0xe0200610
export const RAM_D_E0074BF8 = 0xe0074bf8
export const RAM_fx_spirit_card = 0x800726b0
export const RAM_b_area_sam_duplighost_goombario_runToPlayer = 0x8021d698
export const RAM_b_area_trd_part_3_eldstar_partsTable_80218344 = 0x80218344
export const RAM_HES_StickTapRight = 0x801091e4
export const RAM_D_8024F74C = 0x8024f74c
export const RAM_water_splash_main = 0xe00be000
export const RAM_world_bombette_can_player_pause = 0x802bd748
export const RAM_flo_10_802427EC = 0x802427ec
export const RAM_mim_08_PiranhaPlantAI_01 = 0x80241ed4
export const RAM_osPfsReadWriteFile = 0x800687e0
export const RAM_b_area_omo2_4_signal_guy_init = 0x80231110
export const RAM_D_80249910_A33B50 = 0x80249910
export const RAM_battle_item_snowman_doll_GiveRefundCleanup = 0x802a11d4
export const RAM_D_802523B4_9E93D4 = 0x802523b4
export const RAM_flo_14_FlyingAI_ChaseInit = 0x802417d0
export const RAM_omo_05_ShyGuyWanderAI_16 = 0x80240140
export const RAM_dro_02_D_8024EFD0 = 0x8024efd0
export const RAM_func_8027D75C = 0x8027d75c
export const RAM_Gfx_LoadStencilTex_Star = 0x8014e6c0
export const RAM_EDEA00_FlyingAI_LoiterInit = 0x8024099c
export const RAM_flo_00_PatrolNoAttackAI_15 = 0x80240b14
export const RAM_omo_07_PatrolAI_LosePlayer = 0x80240fd8
export const RAM_b_area_tik2_UnkBattleFunc2_2 = 0x80218b2c
export const RAM_sbk_11_SetNpcB5_3 = 0x80240140
export const RAM_snd_song_get_playing_info = 0x80055ce8
export const RAM_b_area_iwa_iwa_02_80222050 = 0x80222050
export const RAM_b_area_trd_part_2_trd_04_beforeBattle_80232C18 = 0x80232c18
export const RAM_get_script_by_index = 0x802c4790
export const RAM_ui_move_partner_3_png = 0x80103f60
export const RAM_b_area_pra3_formation_05 = 0x802325cc
export const RAM_func_8006EF20 = 0x8006ef20
export const RAM_omo_05_FlyingAI_LoiterInit = 0x8024122c
export const RAM_dgb_01_D_80252898_C10118 = 0x80252898
export const RAM_dgb_02_exitDoubleDoor_80241550 = 0x80241550
export const RAM_dgb_18_init_8024329C = 0x8024329c
export const RAM_ui_battle_cont_c_left_up_pal = 0x8028b648
export const RAM_entity_HeartBlock_init = 0x802e5648
export const RAM_D_80245368_EEB918 = 0x80245368
export const RAM_gPauseMapSpacesInSnapRange = 0x80270724
export const RAM_pra_01_reflection_setup_floor = 0x80240500
export const RAM_star_init = 0xe001e370
export const RAM_flo_08_80243090 = 0x80243090
export const RAM_tik_20_Pipe_SetAnimFlag = 0x8024030c
export const RAM_b_area_mim_bzzap_init_8021AA24 = 0x8021aa24
export const RAM_D_802ACC60 = 0x802acc60
export const RAM_D_8009A60C = 0x8009a60c
export const RAM_b_area_jan2_white_magikoopa_flying = 0x8021a0f8
export const RAM_D_E0074BF0 = 0xe0074bf0
export const RAM_arn_07_npcGroupList_802478E8 = 0x802478e8
export const RAM_iwa_03_GetItemName = 0x802400b4
export const RAM_func_802BC17C_E2EAAC = 0x802bc17c
export const RAM_battle_star_time_out_SetNpcCollision32 = 0x802a14e8
export const RAM_b_area_mac_lee_idle_80220790 = 0x80220790
export const RAM_dgb_01_D_80252528_C0FDA8 = 0x80252528
export const RAM_mgm_00_PatrolAI_PostLoiter = 0x8024095c
export const RAM_ui_input_analog_stick_left_pal = 0x800f9160
export const RAM_hide_coin_counter_immediately = 0x800e96c8
export const RAM_vtx_971F98 = 0x80248dd8
export const RAM_func_802C3C10 = 0x802c3c10
export const RAM_func_802A91A0_42F880 = 0x802a91a0
export const RAM_b_area_hos_magikoopa_handleEvent_8021EDF0 = 0x8021edf0
export const RAM_end_01_CharAnim_FadeOut_2 = 0x80241778
export const RAM_D_801568E4 = 0x801568e4
export const RAM_D_80257F28 = 0x80257f28
export const RAM_battle_partner_kooper_statusTable_80238478 = 0x80238478
export const RAM_IES_Cookbook = 0x8008b9a4
export const RAM_exec_entity_model_commandlist = 0x80120f04
export const RAM_jan_00_foliage_setup_shear_mtx = 0x80241960
export const RAM_sbk_12_SetNpcB5_3 = 0x80240040
export const RAM_sam_02_Quizmo_ShowEntities = 0x80240c58
export const RAM_world_lakilester_put_away = 0x802bfeb8
export const RAM_battle_item_dusty_hammer_GiveRefund = 0x802a1000
export const RAM_D_802463A0_C8BE50 = 0x802463a0
export const RAM_func_800576EC = 0x800576ec
export const RAM_b_area_kmr_part_1_paragoomba_CalculateRotationZ = 0x802186e4
export const RAM_D_80246D78_DC4028 = 0x80246d78
export const RAM_D_80245058_BECC38 = 0x80245058
export const RAM_dgb_01_SentinelAI_PostLosePlayer = 0x80241e28
export const RAM_ui_battle_status_transparent_0_pal = 0x80102de0
export const RAM_flo_09_pad_2004 = 0x80242004
export const RAM_func_802428D8_9C7CB8 = 0x802428d8
export const RAM_flo_11_80241610 = 0x80241610
export const RAM_jan_22_PatrolAI_Move = 0x80240158
export const RAM_update_all_status_icons = 0x80046030
export const RAM_jan_03_ToadHouse_GetPartnerBackOut = 0x80241774
export const RAM_dgb_10_80240AF4 = 0x80240af4
export const RAM_RandInt = 0x802d46b0
export const RAM_kkj_01_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_b_area_arn_hypergoomba_takeTurn_8021A628 = 0x8021a628
export const RAM_ui_status_pow_unit_2_pal = 0x80100760
export const RAM_HES_Item_FertileSoil = 0x80081a58
export const RAM_pause_update_page_active_2 = 0x80243188
export const RAM_flo_18_vectorList_8024401C = 0x8024401c
export const RAM_partner_walking_update_player_tracking = 0x800eba3c
export const RAM_dgb_02_ClubbaNappingAI_LoiterInit = 0x80240c74
export const RAM_is_world_fog_enabled = 0x8011bba4
export const RAM_jan_00_unkAngleFunc002 = 0x802415b0
export const RAM_flame_update = 0xe0040150
export const RAM_gPauseMsg_44 = 0x8026f700
export const RAM_snaking_static_update = 0xe00ae1b4
export const RAM_b_area_omo2_toy_tank_8021A58C = 0x8021a58c
export const RAM_dgb_01_npcAISettings_80245298 = 0x80245298
export const RAM_func_80240FD0_A3A4B0 = 0x80240fd0
export const RAM_dgb_08_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_enable_status_icon_boost_jump = 0x80047d00
export const RAM_get_npc_safe = 0x8003ab48
export const RAM_D_802516A8_C0EF28 = 0x802516a8
export const RAM_D_802BCB70_E314C0 = 0x802bcb70
export const RAM_func_8024053C_CF80AC = 0x8024053c
export const RAM_isk_16_StarSpiritEffectFunc5 = 0x802405fc
export const RAM_dgb_08_npcAI_80244100 = 0x80244100
export const RAM_D_800B0B30 = 0x800b0b30
export const RAM_flo_00_interact_80244C98 = 0x80244c98
export const RAM_b_area_omo_green_shy_guy_handleEvent_80220128 = 0x80220128
export const RAM_update_enemy_shadows = 0x80255f5c
export const RAM_HES_StatusStar2 = 0x80108400
export const RAM_dgb_09_ClubbaNappingAI_Main = 0x802410d4
export const RAM_bEntityBlueprint = 0x801516e8
export const RAM_pra_27_reflection_render_floor_fancy = 0x802408a0
export const RAM_KooperTweesterPhysicsPtr = 0x802beb60
export const RAM_jan_03_Quizmo_Answers = 0x802477c8
export const RAM_D_8029B494 = 0x8029b494
export const RAM_HES_Item_Unused_0DE_disabled = 0x80085748
export const RAM_D_802B7C60_E224D0 = 0x802b7c60
export const RAM_func_8024174C_D313CC = 0x8024174c
export const RAM_gCurrentCameraID = 0x80077410
export const RAM_D_80234970_6BBB70 = 0x80234970
export const RAM_func_802415F4_BD01E4 = 0x802415f4
export const RAM_obk_03_varStash = 0x80242ecc
export const RAM_func_802A92A0 = 0x802a92a0
export const RAM_D_8023D2C0 = 0x8023d2c0
export const RAM_HES_AimMarkerC = 0x80292e4c
export const RAM_func_8005DB00 = 0x8005db00
export const RAM_D_E01207A8 = 0xe01207a8
export const RAM_D_8014F554 = 0x8014f554
export const RAM_battle_item_sleepy_sheep_frame3_displayList = 0x802a3170
export const RAM_shattering_stones_render = 0xe002c3d0
export const RAM_nok_15_UnkDistFunc = 0x8024035c
export const RAM_get_defeated = 0x8003edf0
export const RAM_pra_27_ReflectFloor = 0x802404cc
export const RAM_disable_background_wave = 0x8014721c
export const RAM_b_area_omo_sky_guy_80229F08 = 0x80229f08
export const RAM_b_area_pra2_crystal_bit_parts_2 = 0x802205b4
export const RAM_func_80240298_8FF118 = 0x80240298
export const RAM_dgb_01_UnkFunc1 = 0x802433bc
export const RAM_func_802A98E0 = 0x802a98e0
export const RAM_magicSaveString = 0x800745d0
export const RAM_flo_00_PatrolAI_Chase = 0x8024098c
export const RAM_battle_move_earthquake_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_b_area_nok_paratroopa_idleAnimations_80220B38 = 0x80225968
export const RAM_b_area_dig_UnkBattleFunc1 = 0x80218000
export const RAM_kpa_81_AddPlayerHandsOffset = 0x802400ac
export const RAM_D_80245358_CDC638 = 0x80245358
export const RAM_D_8029CD90 = 0x8029cd90
export const RAM_ECAA80_ItemChoice_SaveSelected = 0x802419f0
export const RAM_b_area_jan_hurt_plant_idle_8021DAE4 = 0x8021dae4
export const RAM_dro_01_init_802481F8 = 0x802481f8
export const RAM_b_area_isk_part_2_tutankoopa_idleAnimations_8021BA04 = 0x8021ba04
export const RAM_get_npc_pos = 0x8005de10
export const RAM_nuGfxFunc = 0x80093d10
export const RAM_ui_battle_status_dizzy_3_png = 0x801022c0
export const RAM_b_area_trd_part_1_formation_08 = 0x802186f8
export const RAM_kpa_52_ProjectileHitbox_30 = 0x80240414
export const RAM_IES_SuperSoda = 0x8008d894
export const RAM_D_8014C588 = 0x8014c588
export const RAM_sam_01_Quizmo_AudienceEffect = 0x8024df1c
export const RAM_b_area_omo_omo_06 = 0x8022f87c
export const RAM_D_802EB0B0 = 0x802eb0b0
export const RAM_gPauseSpiritsIndexes = 0x802706e0
export const RAM_b_area_sam2_gray_magikoopa_idleAnimations1 = 0x8021f380
export const RAM_GoombarioTweesterPhysics = 0x802bdf40
export const RAM_dgb_12_EVS_Chest_Interact = 0x802409bc
export const RAM_set_battle_formation = 0x80072cdc
export const RAM_D_E008CB1C = 0xe008cb1c
export const RAM_D_09000CC0 = 0x09000cc0
export const RAM_D_E0122CD0 = 0xe0122cd0
export const RAM_func_8003D624 = 0x8003d624
export const RAM_EDC020_GetNpcCollisionHeight = 0x80240394
export const RAM_b_area_omo_groove_guy_specialFormation_802273C4 = 0x802273c4
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022DF54 = 0x8022df54
export const RAM_omo_04_FlyingAI_Chase = 0x80241194
export const RAM_trd_08_UpdateTexturePanStepped = 0x80240124
export const RAM_func_800522A8 = 0x800522a8
export const RAM_star_update = 0xe001e378
export const RAM_fold_gfxDataCache = 0x80156f20
export const RAM_b_area_mac_lee_formation_goombario = 0x80220524
export const RAM_IES_MapleUltra = 0x8008d034
export const RAM_D_802A1628_73DE88 = 0x802a1628
export const RAM_kmr_02_Quizmo_SetStageLightsDelay = 0x80240dcc
export const RAM_b_area_isk_part_1_vector3D_8022460C = 0x8022460c
export const RAM_D_802483E8_7F30F8 = 0x802483e8
export const RAM_b_area_mac_lee_defenseTable_802205D8 = 0x802205d8
export const RAM_IES_FirstAttack = 0x8008bfa4
export const RAM_D_8014BDD8 = 0x8014bdd8
export const RAM_tik_23_set_script_owner_npc_col_height = 0x80240c74
export const RAM_b_area_sam_sam_02_UnkModelStuffScript4 = 0x8022f5d4
export const RAM_D_E0020D30 = 0xe0020d30
export const RAM_b_area_sam_sam_02b_UnkModelStuff_array = 0x8022f820
export const RAM_flo_23_FlowerGuard_ItemChoiceList = 0x80242850
export const RAM_func_8024022C_BC808C = 0x8024022c
export const RAM_pra_40_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_func_802181B4_4CF364 = 0x802181b4
export const RAM_D_80256ADC_81735C = 0x80256adc
export const RAM_b_area_iwa_iwa_01_defenseTable_8022094C = 0x8022094c
export const RAM_fx_3D = 0x80070f70
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_statusTable_8022E458 = 0x8022e458
export const RAM_HES_133FE0_134060 = 0x80242724
export const RAM_SCRIPT_NpcDefeat = 0x80077e38
export const RAM_shimmer_burst_main = 0xe0070000
export const RAM_bulb_glow_appendGfx = 0xe007827c
export const RAM_kpa_133_UpdateTexturePanSmooth = 0x80240000
export const RAM_battle_menu_submenuIDs = 0x802ad0b8
export const RAM_D_802B6E70_E2B520 = 0x802b6e70
export const RAM_mim_07_HoppingAI_Hop = 0x802404a0
export const RAM_DoParalyzeHit = 0x80294618
export const RAM_kpa_52_ProjectileAI_Reflect = 0x80240b78
export const RAM_D_E0056EB8 = 0xe0056eb8
export const RAM_battle_move_spin_smash_IsBerserkerEquipped = 0x802a10ec
export const RAM_kzn_09_PiranhaPlantAI_14 = 0x80242688
export const RAM_b_area_sam_gulpit_attack_lick = 0x80228518
export const RAM_b_area_hos_magikoopa_nextTurn_8021E0B0 = 0x8021e0b0
export const RAM_b_area_isk_part_1_formation_04 = 0x80224200
export const RAM_D_09001120_327530 = 0x09001120
export const RAM_kpa_102_UnkNpcAIFunc12 = 0x8024170c
export const RAM_mac_02_Quizmo_VannaTEffect = 0x8024e568
export const RAM_HES_Item_PayOff_disabled = 0x80084788
export const RAM_mac_05_Quizmo_SetStageLightsDelay = 0x80241d5c
export const RAM_fright_jar_main = 0xe00c2000
export const RAM_HES_Item_HammerThrow = 0x80084278
export const RAM_phys_can_player_interact = 0x800e52f8
export const RAM_battle_star_peach_dash_802A233C = 0x802a233c
export const RAM_func_E02000AC = 0xe02000ac
export const RAM_D_E011E800 = 0xe011e800
export const RAM_D_80243D80_A72160 = 0x80243d80
export const RAM_spr_allocateBtlComponentsOnWorldHeap = 0x802df524
export const RAM_dgb_06_enterSingleDoor_80240474 = 0x80240474
export const RAM_dgb_02_npcAI_80241D34 = 0x80241d34
export const RAM_render_effects_world = 0x80059ef4
export const RAM_func_8025D6FC = 0x8025d6fc
export const RAM_IES_BakingEgg = 0x8008dbb4
export const RAM_JumpedOnSwitchZ = 0x8010c97c
export const RAM_guRotateF = 0x80067b20
export const RAM_func_80056EE8 = 0x80056ee8
export const RAM_jan_07_WanderMeleeAI_Main = 0x80240830
export const RAM_b_area_pra3_swoopula_idleAnimations = 0x80226f54
export const RAM_b_area_omo_blue_shy_guy_8021B504 = 0x8021b504
export const RAM_D_8014F120 = 0x8014f120
export const RAM_b_area_kmr_part_3_egg_jr_troopa_takeTurn_80225314 = 0x80225314
export const RAM_kzn_10_UnkFunc48 = 0x802404b4
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_StartRumbleWithParams = 0x80219b20
export const RAM_dro_02_npcAI_80247A90 = 0x80247a90
export const RAM_func_8023F060 = 0x8023f060
export const RAM_dgb_01_FlyingAI_LosePlayer = 0x80240f78
export const RAM_arn_07_GetEntityPosition = 0x802408a4
export const RAM_D_E005C718 = 0xe005c718
export const RAM_D_09001070_3AC0A0 = 0x09001070
export const RAM_HES_Item_AutoJump_disabled = 0x80084128
export const RAM_flo_14_80243420 = 0x80243420
export const RAM_dgb_00_init_802434EC = 0x802434ec
export const RAM_get_area_flag = 0x801455f0
export const RAM_jan_03_Quizmo_CreateStage = 0x8024230c
export const RAM_kmr_02_LetterDelivery_RestoreNpcAnim = 0x802426e4
export const RAM_bActorSoundTable = 0x80281468
export const RAM_HES_StatusDigit7 = 0x801081d0
export const RAM_mac_05_Quizmo_Answers = 0x80245114
export const RAM_D_80234810_6BBA10 = 0x80234810
export const RAM_add_xz_vec3f = 0x80264128
export const RAM_D_E0078900 = 0xe0078900
export const RAM_b_area_kmr_part_1_paragoomba_handleEvent_8021CEBC = 0x8021e8ac
export const RAM_func_80242608_833E08 = 0x80242608
export const RAM_HES_Item_GearBoots1 = 0x800812d8
export const RAM_arn_10_80240BE0 = 0x80240be0
export const RAM_dispatch_damage_event_partner_1 = 0x8027fb9c
export const RAM_b_area_kpa_GetSelectedMoveID = 0x802184e8
export const RAM_battle_item_coconut_model = 0x802a1a20
export const RAM_kpa_01_set_script_owner_npc_anim = 0x80240160
export const RAM_SetActionCommandMode = 0x802692ec
export const RAM_dup2_b_area_kpa2_StartRumbleWithParams = 0x80218f00
export const RAM_EED1E0_SuperBlock_HideBlockContent = 0x8024011c
export const RAM_MultiplyByActorScale = 0x80253fdc
export const RAM_flo_24_exitWalk_80240998 = 0x80240998
export const RAM_omo_15_StarSpiritEffectFunc4 = 0x802405bc
export const RAM_flo_11_vectorList_80241C30 = 0x80241c30
export const RAM_gRenderModelPrimB = 0x8014b762
export const RAM_jan_00_StashVars = 0x802403f4
export const RAM_kpa_01_DarkRoomUpdate = 0x80240000
export const RAM_b_area_kmr_part_3_paragoomba1_sinTable = 0x8021e61c
export const RAM_dgb_03_SetEntityFlags100000 = 0x80240170
export const RAM_dup2_kmr_20_Pipe_GetCameraYaw = 0x80241f20
export const RAM_landing_dust_render = 0xe000c754
export const RAM_D_8024F210_A39450 = 0x8024f210
export const RAM_HES_Item_Boots2 = 0x80080760
export const RAM_func_8024351C_9DA53C = 0x8024351c
export const RAM_flo_03_StashVars = 0x80240040
export const RAM_HES_Item_Unused_08C = 0x80082358
export const RAM_HES_Item_Letter_disabled = 0x80082208
export const RAM_HES_Item_YummyMeal_disabled = 0x80082ce8
export const RAM_b_area_trd_part_2_stageTable = 0x8021a604
export const RAM_evt_handle_set_float_buffer_ptr = 0x802c5c5c
export const RAM_ui_battle_cmd_timing_wait_pal = 0x8028ce80
export const RAM_D_E0200694 = 0xe0200694
export const RAM_dgb_11_802400D0 = 0x802400d0
export const RAM_D_E004E5E0 = 0xe004e5e0
export const RAM_collision_check_player_overlaps = 0x800e4508
export const RAM_b_area_mac_lee_handleEvent_8021EB24 = 0x8021eb24
export const RAM_func_80241104_B98D04 = 0x80241104
export const RAM_D_E00A4B38 = 0xe00a4b38
export const RAM_iwa_04_CleftAI_PostDisguise = 0x80240ccc
export const RAM_arn_12_entryList = 0x80240000
export const RAM_b_area_isk_part_1_isk_08_afterBattle_80224028 = 0x80224028
export const RAM_D_E01207A0 = 0xe01207a0
export const RAM_EF2680_SpinyAI_Main = 0x8024299c
export const RAM_D_802455F0_DB78B0 = 0x802455f0
export const RAM_bgm_pop_song = 0x8014ada4
export const RAM_D_80246E30_9F4A20 = 0x80246e30
export const RAM_b_area_trd_part_2_fake_bowser_8021BA68 = 0x8021ba68
export const RAM_D_802A118C_0073B05C = 0x802a118c
export const RAM_sbk_16_TransformFoliage = 0x802400b0
export const RAM_b_area_arn_hyper_paragoomba_8021E218 = 0x8021e218
export const RAM_func_802A9254_42F074 = 0x802a9254
export const RAM_flo_22_802403AC = 0x802403ac
export const RAM_ui_battle_cmd_aim_target_1_png = 0x8028f500
export const RAM_dgb_02_exitSingleDoor_8024176C = 0x8024176c
export const RAM_D_8024F2DC = 0x8024f2dc
export const RAM_SetActorFlags = 0x8026ce30
export const RAM_HES_MenuFleeDisabled = 0x80292188
export const RAM_dgb_03_npcAISettings_80243A5C = 0x80243a5c
export const RAM_kpa_61_TackleAI_Main = 0x802406a4
export const RAM_status_menu_stop_blinking_coins = 0x800e9a38
export const RAM_D_80098480 = 0x80098480
export const RAM_D_80098050 = 0x80098050
export const RAM_D_E0026A28 = 0xe0026a28
export const RAM_D_80250910_C0E190 = 0x80250910
export const RAM_flo_24_func_80240000_CF0940 = 0x80240000
export const RAM_func_80240000_8FF670 = 0x80240000
export const RAM_vtx_C23C90 = 0x80202d50
export const RAM_b_area_omo2_5_shy_squad_dup_attack = 0x80234624
export const RAM_D_80249CB8 = 0x80249cb8
export const RAM_start_child_script = 0x802c37c8
export const RAM_nok_01_Quizmo_RenderInit = 0x802410c8
export const RAM_HES_MenuBoots2Disabled = 0x801087bc
export const RAM_D_80245528_C4C108 = 0x80245528
export const RAM_IES_ElectroPop = 0x8008d354
export const RAM_vtx_C213C0 = 0x80200480
export const RAM_update_enter_map_screen_overlay = 0x801385d4
export const RAM_b_area_sam_duplighost_goombario_init = 0x8021cc44
export const RAM_D_80243DF0_E12590 = 0x80243df0
export const RAM_mim_05_MeleeHitbox_32 = 0x8024014c
export const RAM_nuSiMgrStop = 0x8002cf8c
export const RAM_nuSiCallBackList = 0x80077400
export const RAM_fx_lightning_bolt = 0x80071bd0
export const RAM_D_8014BA70 = 0x8014ba70
export const RAM_kzn_02_UpdateTexturePanStepped = 0x80240124
export const RAM_dro_02_pad_XX111 = 0x802475e4
export const RAM_trd_01_UpdateTexturePanStepped = 0x80240124
export const RAM_jan_04_varStash = 0x80241800
export const RAM_b_area_trd_part_3_stageTable = 0x80218264
export const RAM_dgb_03_WanderMeleeAI_Main = 0x8024086c
export const RAM_D_E0056EB0 = 0xe0056eb0
export const RAM_flo_22_tree2 = 0x80241f48
export const RAM_b_area_pra2_sam_04_afterBattle = 0x802214c4
export const RAM_pause_draw_cursor = 0x802434dc
export const RAM_D_80150938 = 0x80150938
export const RAM_D_802A9932_42E342 = 0x802a9932
export const RAM_fire_breath_appendGfx = 0xe006e764
export const RAM_logicalSaveInfo = 0x8009ba30
export const RAM_windy_leaves_init = 0xe0056304
export const RAM_b_area_mim_forest_fuzzy_statusTable_802183CC = 0x802183cc
export const RAM_func_80244848_8050C8 = 0x80244848
export const RAM_D_802EB0B8 = 0x802eb0b8
export const RAM_pra_15_SetGameStatusUnk84_1 = 0x80240000
export const RAM_HES_Item_FlowerFanatic = 0x80084b78
export const RAM_battle_partner_kooper_802392A4 = 0x802392a4
export const RAM_D_80077950 = 0x80077950
export const RAM_b_area_omo2_toy_tank_8021A2F8 = 0x8021a2f8
export const RAM_func_802A9298_42E638 = 0x802a9298
export const RAM_dgb_08_npcAI_802444E4 = 0x802444e4
export const RAM_dro_02_exitWalk_80243C40 = 0x80243c40
export const RAM_pause_tabs_draw_invis = 0x80244b90
export const RAM_b_area_omo2_2_stilt_guy_idle = 0x802322d0
export const RAM_HES_Item_SSmashChg = 0x80083f18
export const RAM_entity_base_switch_start_bound_script = 0x802e1ea8
export const RAM_b_area_mac_chan_partsTable_80219E24 = 0x80219e24
export const RAM_fx_ring_blast = 0x80070610
export const RAM_animator_make_identity = 0x8011e09c
export const RAM_GetPlayerActorID = 0x8026e974
export const RAM_GetCamPosition = 0x802cc2a4
export const RAM_flo_08_D_8024659C = 0x8024659c
export const RAM_dro_02_Quizmo_varStash = 0x80244de0
export const RAM_D_802452A8_BB2BE8 = 0x802452a8
export const RAM_action_command_air_raid_draw_hud_elements = 0x802a984c
export const RAM_func_80241630_99DFC0 = 0x80241630
export const RAM_b_area_kmr_part_3_paragoomba2 = 0x8021f950
export const RAM_b_area_pra3_duplighost_UnkWattEffectFunc1 = 0x80218e88
export const RAM_func_80240000_D2BD30 = 0x80240000
export const RAM_D_8010BEE0 = 0x8010bee0
export const RAM_b_area_hos_magikoopa_partsTable_80221624 = 0x80221624
export const RAM_ShowConsumableChoicePopup = 0x802d663c
export const RAM_D_802706DC = 0x802706dc
export const RAM_IES_ITEM_137 = 0x8008ddb4
export const RAM_flo_25_FlowerGuard_MakeItemList = 0x80241fb4
export const RAM_dead_subtract_hp = 0x800f1ef8
export const RAM_b_area_isk_part_1_isk_04_D_80224D64 = 0x80224d64
export const RAM_IES_SpikeShield = 0x8008bf84
export const RAM_kpa_62_GetEntityPosition = 0x80240034
export const RAM_flo_07_UpdateTexturePanStepped = 0x80240124
export const RAM_gPauseCursorTargetOpacity = 0x8024efb0
export const RAM_dro_02_80243C9C = 0x80243c9c
export const RAM_b_area_trd_part_1_bill_blaster_idle_80219ED0 = 0x80219ed0
export const RAM_jan_15_HeartPlant_SpawnHeart = 0x80240000
export const RAM_dro_01_interact_80248864 = 0x80248864
export const RAM_jan_05_HoppingAI_Main = 0x80241494
export const RAM_b_area_omo_blue_shy_guy_idleAnimations_8021AE1C = 0x8021ae1c
export const RAM_tik_04_Pipe_GetPointAheadOfPlayer = 0x80240548
export const RAM_jan_04_SetEntityPositionF = 0x80240a80
export const RAM_func_8025DA60 = 0x8025da60
export const RAM_func_80242BA8_825E68 = 0x80242ba8
export const RAM_au_BGMCmd_FE_Detour = 0x80050184
export const RAM_mac_00_dojoBattleIDs = 0x8024a632
export const RAM_kzn_07_FlyingAI_Loiter = 0x802410b8
export const RAM_D_80150930 = 0x80150930
export const RAM_D_80150580 = 0x80150580
export const RAM_au_SEFCmd_05 = 0x8004d00c
export const RAM_dro_02_func_80241394_96A554 = 0x80241394
export const RAM_dgb_18_PatrolAI_PostLoiter = 0x8024067c
export const RAM_D_8023CD88_6F5E68 = 0x8023cd88
export const RAM_jan_00_UnkFloatFunc001 = 0x80240a18
export const RAM_b_area_iwa_formation_0D = 0x80218564
export const RAM_D_E006EC40 = 0xe006ec40
export const RAM_b_area_kmr_part_2_goomnut_tree = 0x80223450
export const RAM_isk_10_SuperBlock_LoadCurrentPartnerName = 0x80240448
export const RAM_func_802BD710_31D280 = 0x802bd710
export const RAM_D_E009AA80 = 0xe009aa80
export const RAM_kmr_03_entryList = 0x80240650
export const RAM_PlayerRasterMaxSize = 0x802e0c5c
export const RAM_IES_LifeShroom = 0x8008d834
export const RAM_update_camera_mode_0 = 0x8002f850
export const RAM_kill_encounter = 0x8003e730
export const RAM_battle_partner_kooper_80238A20 = 0x80238a20
export const RAM_D_8014C580 = 0x8014c580
export const RAM_func_802408D0_BC4B50 = 0x802408d0
export const RAM_D_80153A53 = 0x80153a53
export const RAM_vtx_C28720 = 0x802077e0
export const RAM_btl_state_draw_switch_to_player_8B2C18 = 0x80242ba8
export const RAM_func_80144258 = 0x80144258
export const RAM_free_animator_nodes = 0x8011e1c4
export const RAM_ShowKeyChoicePopup = 0x802d6420
export const RAM_update_ambient_sounds = 0x80147264
export const RAM_kkj_00_PatrolAI_Move = 0x80240208
export const RAM_b_area_kgr_fuzzipede_UnkBattleFunc1 = 0x80218000
export const RAM_IES_CakeBaked = 0x8008b15c
export const RAM_hos_06_StashVars = 0x80240310
export const RAM_b_area_isk_part_1_isk_03b = 0x80223d5c
export const RAM_b_area_kmr_part_3_paragoomba1_8021D614 = 0x8021d614
export const RAM_b_area_kzn_kzn_05_beforeBattle_8022916C = 0x8022916c
export const RAM_arn_03_interact_802431C8 = 0x802431c8
export const RAM_mim_01_PiranhaPlantAI_Main = 0x80241c54
export const RAM_D_80242BA8_91E488 = 0x80242ba8
export const RAM_func_8013673C = 0x8013673c
export const RAM_collision_main_above = 0x800e27e0
export const RAM_D_802EAB30 = 0x802eab30
export const RAM_wPartnerTetherDistance = 0x8010cfc0
export const RAM_D_80251A98_9D6E78 = 0x80251a98
export const RAM_D_802975C8 = 0x802975c8
export const RAM_D_8024DC50_C0B4D0 = 0x8024dc50
export const RAM_omo_07_varStash = 0x80245e00
export const RAM_D_803B5000 = 0x803b5000
export const RAM_D_80245605 = 0x80245605
export const RAM_filemenu_choose_name_handle_input = 0x80248bf4
export const RAM_ExitWalk = 0x80285cf4
export const RAM_D_802310D0 = 0x802310d0
export const RAM_flo_00_80247770 = 0x80247770
export const RAM_hos_02_FlyingAI_Wander = 0x802411a0
export const RAM_D_8010C970 = 0x8010c970
export const RAM_D_8024C638_C09EB8 = 0x8024c638
export const RAM_world_goombaria_update = 0x802bd20c
export const RAM_D_802809FA = 0x802809fa
export const RAM_battle_item_insecticide_herb_pal = 0x802a1be0
export const RAM_dgb_04_FlyingAI_JumpVels = 0x80243560
export const RAM_ui_status_star_piece_1_png = 0x800fc120
export const RAM_func_80240574_B1BAE4 = 0x80240574
export const RAM_battle_item_strange_cake_func_802A1B14_731E14 = 0x802a1b14
export const RAM_sbk_51_Bandit_DropCoin = 0x80240040
export const RAM_got_item_outline_init = 0xe00360dc
export const RAM_D_8029FB8C = 0x8029fb8c
export const RAM_b_area_omo_medi_guy_defenseTable_8021F8DC = 0x8022345c
export const RAM_IES_DoubleDip = 0x8008c004
export const RAM_D_E00147A0 = 0xe00147a0
export const RAM_battle_star_smooch_802A23BC = 0x802a23bc
export const RAM_func_800E97E4 = 0x800e97e4
export const RAM_shim_cos_deg = 0xe0200510
export const RAM_func_8025CCC8 = 0x8025ccc8
export const RAM_cloud_trail_render = 0xe0016410
export const RAM_func_802186B8_47F768 = 0x802186b8
export const RAM_func_80115498 = 0x80115498
export const RAM_D_802BFED8_320C48 = 0x802bfed8
export const RAM_peach_set_disguise_anim = 0x800e636c
export const RAM_flo_13_func_80240000_CC3810 = 0x80240000
export const RAM_kmr_02_Quizmo_FadeInWorld = 0x80240908
export const RAM_SetNpcAnimationSpeed = 0x802ce1c0
export const RAM_b_area_pra_GetSelectedMoveID = 0x80218ea8
export const RAM_D_802A43D0 = 0x802a43d0
export const RAM_b_area_isk_part_2_isk_02b_beforeBattle_8021FE80 = 0x8021fe80
export const RAM_ui_battle_hp_5_png = 0x8028aaa0
export const RAM_FXRecoverHP = 0x80261ba8
export const RAM_b_area_dgb_dgb_03_beforeBattle_8021B110 = 0x8021b110
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022ABA8 = 0x8022aba8
export const RAM_func_80242744_DF9144 = 0x80242744
export const RAM_flo_23_FlowerGuard_MakeItemList = 0x80240c9c
export const RAM_partner_walking_update_motion = 0x800ebb40
export const RAM_update_max_rumble_duration = 0x80028f8c
export const RAM_kzn_04_SuperBlock_PartnerIDs = 0x80241c48
export const RAM_ui_status_pow_star_7_png = 0x80100a80
export const RAM_SetCamPosC = 0x802cbd90
export const RAM_sbk_46_Bandit_DropCoin = 0x80240040
export const RAM_D_802417E8_C97718 = 0x802417e8
export const RAM_sbk_00_npcAI_80240330 = 0x80240330
export const RAM_D_8024219C_D70B4C = 0x8024219c
export const RAM_shop_owner_continue_speech_with_quantity = 0x80280208
export const RAM_b_area_sbk_formation_04 = 0x8021fa58
export const RAM_D_8024ED50_7F9A60 = 0x8024ed50
export const RAM_partner_flying_follow_player = 0x800ed9f8
export const RAM_flo_00_npcAI_8024289C = 0x8024289c
export const RAM_func_802418A4_A3AD84 = 0x802418a4
export const RAM_flo_14_FlyingAI_Jump = 0x80241764
export const RAM_gPauseMapCameraX = 0x80270704
export const RAM_b_area_pra3_duplighost_kooper_idle = 0x8021d920
export const RAM_func_802B70B4_E201C4 = 0x802b70b4
export const RAM_battle_move_hammer_D_802A1B40 = 0x802a1b40
export const RAM_action_update_spin_jump = 0x802b6000
export const RAM_D_E0084E60 = 0xe0084e60
export const RAM_render_hud_elements_frontUI = 0x8014271c
export const RAM_b_area_jan_m_bush_idle_802219D4 = 0x802219d4
export const RAM_dro_01_EVS_Quizmo_PlayerHitBuzzer = 0x80245af8
export const RAM_battle_item_insecticide_herb_GiveRefund = 0x802a1000
export const RAM_isk_14_StoneChompAI_HopInit = 0x80240e14
export const RAM_HES_HPDigit0 = 0x80291fa8
export const RAM_D_80098530 = 0x80098530
export const RAM_D_E0026A20 = 0xe0026a20
export const RAM_D_E01284B0 = 0xe01284b0
export const RAM_b_area_trd_part_2_green_ninja_koopa_UnkWhirlwindEffectFunc = 0x80218438
export const RAM_D_802466D0 = 0x802466d0
export const RAM_D_09000000_326410 = 0x09000000
export const RAM_b_area_sam_gray_magikoopa_ShrinkActor = 0x80219840
export const RAM_b_area_isk_part_1_isk_03b_afterBattle_80223D4C = 0x80223d4c
export const RAM_func_E011A700 = 0xe011a700
export const RAM_GetPlayerPos = 0x802d1dfc
export const RAM_D_8024E5B0_C0BE30 = 0x8024e5b0
export const RAM_b_area_iwa_iwa_01_takeTurn_802212E4 = 0x802212e4
export const RAM_iwa_04_CleftAI_ReturnHome = 0x80240ab0
export const RAM_pause_banner_hp_pal = 0x8026c830
export const RAM_isk_18_TackleAI_Main = 0x80240394
export const RAM_omo_05_FlyingAI_JumpVels = 0x80242f88
export const RAM_b_area_kmr_part_1_spiked_goomba_takeTurn_8021F1BC = 0x8021bddc
export const RAM_D_8029AF08 = 0x8029af08
export const RAM_effectFuncs = 0xe0200694
export const RAM_mgm_02_PanelModelIDs = 0x802429f8
export const RAM_gPauseWS_5 = 0x8026fa18
export const RAM_D_8010D664 = 0x8010d664
export const RAM_flo_03_triggerCoord_80244E44 = 0x80244e44
export const RAM_D_802D9DCC = 0x802d9dcc
export const RAM_D_E002C984 = 0xe002c984
export const RAM_pause_available_png = 0x8026dcb0
export const RAM_D_8029D900 = 0x8029d900
export const RAM_D_8029D590 = 0x8029d590
export const RAM_kpa_102_TackleAI_Main = 0x802418f0
export const RAM_b_area_mac_master2_idleAnimations_8022B640 = 0x8022b640
export const RAM_sam_02_Quizmo_CreateStage = 0x8024129c
export const RAM_func_80240310_B52B40 = 0x80240310
export const RAM_jan_00_LetterDelivery_CalcLetterPos = 0x80240950
export const RAM_dgb_01_D_80252790_C10010 = 0x80252790
export const RAM_b_area_nok_paragoomba_ArcsinDeg = 0x802182d8
export const RAM_b_area_isk_part_1_formation_0D = 0x802244bc
export const RAM_hos_03_Quizmo_DestroyEffects = 0x80242e2c
export const RAM_D_80243450_8D14C0 = 0x80243450
export const RAM_D_E0084E68 = 0xe0084e68
export const RAM_mim_06_MeleeHitbox_31 = 0x802405dc
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuff_array = 0x802336e0
export const RAM_func_8026E020 = 0x8026e020
export const RAM_gMessageDrawStatePtr = 0x80155d74
export const RAM_b_area_mac_lee_defenseTable_802233D4 = 0x802233d4
export const RAM_GetMenuSelection = 0x802733c4
export const RAM_b_area_kmr_part_1_stageTable = 0x802195d8
export const RAM_D_80150FA0 = 0x80150fa0
export const RAM_b_area_kmr_part_3_final_jr_troopa_CalculateRotationZ = 0x80218d28
export const RAM_func_80240F38_BBE0D8 = 0x80240f38
export const RAM_func_80240970_92AC20 = 0x80240970
export const RAM_b_area_sam2_paragoomba_statusTable_8021B128 = 0x8021bea8
export const RAM_b_area_trd_part_1_bob_omb_statusTable_8021A5B4 = 0x8021a5b4
export const RAM_gFaultCauses = 0x80077390
export const RAM_sam_01_Quizmo_CreateWorker = 0x80241764
export const RAM_dgb_04_FlyingAI_ChaseInit = 0x80240cc0
export const RAM_dro_02_npcSettings_80247584 = 0x80247584
export const RAM_SushiePutAway = 0x802bf964
export const RAM_snowflake_appendGfx = 0xe001c30c
export const RAM_b_area_omo_sky_guy_partsTable_8022977C = 0x8022977c
export const RAM_tik_06_UpdateTexturePanStepped = 0x80240404
export const RAM_jan_03_PatrolAI_LosePlayer = 0x8024108c
export const RAM_D_80159720 = 0x80159720
export const RAM_windy_leaves_appendGfx = 0xe0056bd0
export const RAM_resume_group_others = 0x802c471c
export const RAM_D_802D9EA8 = 0x802d9ea8
export const RAM_D_802479C8_DF6868 = 0x802479c8
export const RAM_D_8010C928 = 0x8010c928
export const RAM_b_area_sam_pos_rocks_2 = 0x802306ac
export const RAM_func_8024027C_A7A77C = 0x8024027c
export const RAM_func_E00BE5B4 = 0xe00be5b4
export const RAM_flo_18_npcGroup_80245CEC = 0x80245cec
export const RAM_CartRomHandle = 0x800b4518
export const RAM_D_E00C6C9C = 0xe00c6c9c
export const RAM_D_09002308 = 0x09002308
export const RAM_au_fetch_SBN_file = 0x8005447c
export const RAM_battle_item_coconut_modelCommandList = 0x802a1b50
export const RAM_partner_set_goal_pos = 0x800ef3c0
export const RAM_D_E0086AB4 = 0xe0086ab4
export const RAM_D_09002080_388430 = 0x09002080
export const RAM_battle_move_multibounce_D_802A19B0 = 0x802a19b0
export const RAM_D_8024C8E0_C0A160 = 0x8024c8e0
export const RAM_ascii_EC2028 = 0x802491d8
export const RAM_npc_set_palswap_1 = 0x8003b44c
export const RAM_sam_06_ToadHouse_PartnerSuspendAbilityScript = 0x802420c4
export const RAM_D_802AD66F = 0x802ad66f
export const RAM_ui_battle_cmd_timing_1_chance_pal = 0x8028cd40
export const RAM_dro_02_interact_8024ADE4 = 0x8024ade4
export const RAM_D_800A0934 = 0x800a0934
export const RAM_SetAnimatedNodeFlags = 0x802cd9f0
export const RAM_D_80253038_8FEE58 = 0x80253038
export const RAM_b_area_jan_formation_0C = 0x80228938
export const RAM_D_802429E8_BDD198 = 0x802429e8
export const RAM_flo_09_tree3_Callback = 0x80243354
export const RAM_filemenu_info_cleanup = 0x80248168
export const RAM_SetVirtualEntityJumpGravity = 0x802d39fc
export const RAM_func_80240308_A7A808 = 0x80240308
export const RAM_D_80150928 = 0x80150928
export const RAM_D_80150578 = 0x80150578
export const RAM_flo_07_varStash = 0x80241bb0
export const RAM_fx_86 = 0x80072ad0
export const RAM_osGetCount = 0x80065a00
export const RAM_b_area_pra2_crystal_king_SummonCrystalBits = 0x8021da2c
export const RAM_update_status_menu = 0x800e80b4
export const RAM_flo_00_flo_19_name_hack = 0x80249378
export const RAM_au_SEFCmd_03_SetReverb = 0x8004cf0c
export const RAM_kzn_03_AwaitPlayerNearNpc = 0x80240b2c
export const RAM_battle_partner_goombario_8023994C = 0x8023994c
export const RAM_omo_04_pad_8 = 0x80244ea8
export const RAM_pra_35_reflection_unk_resolve_anim = 0x80240020
export const RAM_gPauseItemsLevel = 0x802705e0
export const RAM_D_E00D6E74 = 0xe00d6e74
export const RAM_b_area_hos_goombario_tutor_handleEvent_802191E0 = 0x802191e0
export const RAM_flo_18_init_80245B90 = 0x80245b90
export const RAM_b_area_arn_hyper_cleft_idleAnimations_80220C60 = 0x80220c60
export const RAM_enable_world_fog = 0x8011bb50
export const RAM_func_802D2C14 = 0x802d2c14
export const RAM_func_E003C47C = 0xe003c47c
export const RAM_evt_handle_end_case_group = 0x802c565c
export const RAM_dro_01_Quizmo_RenderInit = 0x802417d0
export const RAM_b_area_kgr_formationTable = 0x8021d5fc
export const RAM_func_80240260_D9B150 = 0x80240260
export const RAM_D_8009BA84 = 0x8009ba84
export const RAM_MAMAR_bk_files = 0x80420464
export const RAM_func_80242898_DF9298 = 0x80242898
export const RAM_func_80057874 = 0x80057874
export const RAM_IdleFlyToGoal = 0x8027bbb8
export const RAM_D_802348B0_6BBAB0 = 0x802348b0
export const RAM_b_area_omo_anti_guy_statusTable_8022191C = 0x8022191c
export const RAM_kkj_20_ToadHouse_CamSetFOV = 0x80240384
export const RAM_audio_set_mono = 0x80056180
export const RAM_embers_render = 0xe00e05b0
export const RAM_HES_HandPointLeft = 0x80080388
export const RAM_kzn_04_SuperBlock_UpgradeOrbAngles = 0x80241c98
export const RAM_D_8024FB10_C0D390 = 0x8024fb10
export const RAM_D_E00E4E50 = 0xe00e4e50
export const RAM_func_802BFAB8_320828 = 0x802bfab8
export const RAM_pra_11_SomeItemEntityFunc = 0x80241038
export const RAM_b_area_omo2_toy_tank_8021A610 = 0x8021a610
export const RAM_func_8024095C_97BF8C = 0x8024095c
export const RAM_omo_07_ShyGuyPatrolAI_17 = 0x802415d4
export const RAM_HES_Item_HealthyJuice = 0x80082bf8
export const RAM_b_area_omo2_light_bulb = 0x8022f6e4
export const RAM_func_802196F0_465B80 = 0x802196f0
export const RAM_SushieTakeOut = 0x802bf568
export const RAM_mac_04_Quizmo_AnswerResult = 0x8024f5b8
export const RAM_flo_21_80241350 = 0x80241350
export const RAM_jan_16_UnkFunc27 = 0x80240000
export const RAM_b_area_mac_master3_80230914 = 0x80230914
export const RAM_func_8021AE70_626A10 = 0x8021ae70
export const RAM_HES_Item_HPPlus = 0x80083c78
export const RAM_dro_01_toggleVis_8024C190 = 0x8024c190
export const RAM_D_8024BEF8_C09778 = 0x8024bef8
export const RAM_b_area_mac_lee_init_Sushie = 0x80226acc
export const RAM_D_8024AB3C_A34D7C = 0x8024ab3c
export const RAM_D_09000080_3304E0 = 0x09000080
export const RAM_b_area_dgb_dgb_05_beforeBattle_8021B1D0 = 0x8021b1d0
export const RAM_tik_17_Pipe_GetCurrentFloor = 0x80240d90
export const RAM_kzn_03_TackleAI_Main = 0x802406a4
export const RAM_b_area_sam_sam_02d_UnkModelStuff_array = 0x8022fee0
export const RAM_b_area_sam_duplighost_goombario_8021C7FC = 0x8021d77c
export const RAM_BattleCam_InitialBoomPitch = 0x8029f294
export const RAM_create_actor = 0x802653a4
export const RAM_b_area_kpa2_UnkFireBreathFXFunc = 0x8021cf54
export const RAM_SetPartRotationOffset = 0x8026c740
export const RAM_dgb_01_D_80251E1C_C0F69C = 0x80251e1c
export const RAM_ascii_EC2020 = 0x802491d0
export const RAM_func_80241F98_A3B478 = 0x80241f98
export const RAM_ice_pillar_main = 0xe011e000
export const RAM_func_80240010_8FF680 = 0x80240010
export const RAM_b_area_pra3_duplighost_copyPartner = 0x802264a0
export const RAM_D_80234788_6BB988 = 0x80234788
export const RAM_dro_01_func_802430C8_95E2C8 = 0x802430c8
export const RAM_dgb_09_exitDoubleDoor_80243920 = 0x80243920
export const RAM_D_802976E8 = 0x802976e8
export const RAM_D_8010BEE8 = 0x8010bee8
export const RAM_flo_18_80247024 = 0x80247024
export const RAM_HES_HeaderSpirits = 0x80242098
export const RAM_D_802A30F4_73F954 = 0x802a30f4
export const RAM_vtx_C295B0 = 0x80208670
export const RAM_flo_17_FlyingAI_LoiterInit = 0x80240848
export const RAM_tik_09_SetEntityPosition = 0x80240960
export const RAM_flo_23_npcAI_802414C8 = 0x802414c8
export const RAM_pra_32_SetGameStatusUnk84_1 = 0x80240000
export const RAM_au_bgm_is_song_playing = 0x8004dae0
export const RAM_hieroglyphs_init = 0xe00e212c
export const RAM_filemenu_loadedFileIdx = 0x8024c094
export const RAM_b_area_omo_formation_14 = 0x8022ff34
export const RAM_cloud_puff_init = 0xe0014258
export const RAM_jan_05_HoppingAI_Loiter = 0x80240ecc
export const RAM_b_area_jan_formation_03 = 0x80228644
export const RAM_dgb_03_ClubbaNappingAI_ReturnHome = 0x802422ac
export const RAM_set_zone_enabled = 0x802ca1b8
export const RAM_b_area_omo2_4_signal_guy_statusTable = 0x8023100c
export const RAM_dgb_01_npcGroupList_80246E8C = 0x80246e8c
export const RAM_D_80246620_EA6F20 = 0x80246620
export const RAM_battle_item_coconut_png = 0x802a1800
export const RAM_au_load_INIT = 0x800542bc
export const RAM_create_status_transparent = 0x80047a30
export const RAM_action_update_idle = 0x802b6000
export const RAM_kpa_51_MagikoopaAI_05 = 0x802408d0
export const RAM_D_09000400_3867B0 = 0x09000400
export const RAM_flo_03_80244E54 = 0x80244e54
export const RAM_kzn_18_UnsetCamera0Flag1000 = 0x8024036c
export const RAM_D_8024F128_C0C9A8 = 0x8024f128
export const RAM_SetNpcRenderMode = 0x802d0244
export const RAM_smoke_burst_appendGfx = 0xe004e214
export const RAM_D_80250898_C0E118 = 0x80250898
export const RAM_tik_08_Pipe_SetAnimFlag = 0x80240000
export const RAM_b_area_arn_hyper_paragoomba_takeTurn_8021C36C = 0x8021c36c
export const RAM_update_partner_timers = 0x800e0b38
export const RAM_battle_item_fright_jar_GiveRefund = 0x802a1000
export const RAM_btl_delete_player_actor = 0x80240d68
export const RAM_iwa_10_SuperBlock_SetOverride40 = 0x802402f0
export const RAM_sbk_60_SetNpcB5_3 = 0x80240040
export const RAM_D_8023D284 = 0x8023d284
export const RAM_flo_12_8024080C = 0x8024080c
export const RAM_balloon_render = 0xe0096178
export const RAM_kzn_08_GetItemName = 0x80240844
export const RAM_misc_particles_update = 0xe00e4290
export const RAM_HES_Spirit4Missing = 0x80242510
export const RAM_b_area_dgb_tubba_blubba = 0x80218d48
export const RAM_arn_05_settings = 0x80241320
export const RAM_D_E00E4E58 = 0xe00e4e58
export const RAM_D_E00AC7C8 = 0xe00ac7c8
export const RAM_mdl_set_all_fog_mode = 0x8011d82c
export const RAM_kpa_03_MagikoopaAI_OnPlayerWon = 0x80241d20
export const RAM_ui_box_corners3_png = 0x8010ab60
export const RAM_D_F1FFFFFF = 0xf1ffffff
export const RAM_gCurrentCamID = 0x8009a634
export const RAM_HES_Mamar = 0x801079e8
export const RAM_dro_02_npcSettings_80247484 = 0x80247484
export const RAM_ui_status_pow_unit_empty_pal = 0x801008e0
export const RAM_mac_01_Quizmo_ShowEntities = 0x8024203c
export const RAM_dgb_00_npcGroupList_80243740 = 0x80243740
export const RAM_arn_12_exitSingleDoor_802401A4 = 0x802401a4
export const RAM_func_802403C0_9EDFB0 = 0x802403c0
export const RAM_set_animator_flags = 0x801200ec
export const RAM_mim_05_SetForeverForestFog = 0x80240000
export const RAM_func_8023E3FC = 0x8023e3fc
export const RAM_D_80244D54_8F0B74 = 0x80244d54
export const RAM_dgb_05_pad_1578 = 0x80241578
export const RAM_SetJumpAnimations = 0x8026b88c
export const RAM_b_area_kmr_part_2_red_goomba_handleEvent_8021BB84 = 0x8021bb84
export const RAM_b_area_trd_part_1_trd_05d = 0x80226cc8
export const RAM_b_area_omo_pyro_guy_init_8022CC80 = 0x8022cc80
export const RAM_func_800E4B40 = 0x800e4b40
export const RAM_mac_02_UpdateTexturePanStepped = 0x80240404
export const RAM_dup_jan_04_foliage_setup_shear_mtx = 0x80240d80
export const RAM_clear_render_tasks = 0x8011d890
export const RAM_D_80232B38_6848B8 = 0x80232b38
export const RAM_D_090012E0 = 0x090012e0
export const RAM_flo_17_LakituAI_Main = 0x80241c64
export const RAM_D_8024EAD0_C0C350 = 0x8024ead0
export const RAM_HES_Item_Unused_08E_disabled = 0x80082448
export const RAM_pra_37_reflection_setup_floor = 0x80240530
export const RAM_dgb_09_npcGroup_80244920 = 0x80244920
export const RAM_gMsgCharsets = 0x802eb5a8
export const RAM_b_area_sam_duplighost_lakilester_takeTurn = 0x80226924
export const RAM_omo_13_ShyGuyWanderAI_17 = 0x80240458
export const RAM_b_area_pra3_duplighost_sushie_init = 0x802239b8
export const RAM_osMemSize = 0x80000318
export const RAM_func_80240418_8FFA88 = 0x80240418
export const RAM_IES_BakingSalt = 0x8008db74
export const RAM_D_80242F34_BC71B4 = 0x80242f34
export const RAM_func_80240000_ACAB20 = 0x80240000
export const RAM_flo_09_FlyingAI_JumpVels = 0x8024362c
export const RAM_tik_02_UpdateTexturePanSmooth = 0x80240000
export const RAM_b_area_arn_hyper_paragoomba = 0x8021b4f0
export const RAM_IES_HeartPiece = 0x8008cde4
export const RAM_dup3_b_area_kpa2_FadeBackgroundToBlack = 0x802192fc
export const RAM_render_player = 0x800e1138
export const RAM_entity_shattering_setupGfx = 0x802e2ba4
export const RAM_func_80240028_AED438 = 0x80240028
export const RAM_vtx_C22330 = 0x802013f0
export const RAM_D_80234798_6BB998 = 0x80234798
export const RAM_iwa_03_CleftAI_DisguiseInit = 0x80240ea8
export const RAM_ED0310_FlyingAI_LoiterInit = 0x80240af8
export const RAM_au_engine_init = 0x80052e5c
export const RAM_iwa_01_CleftAI_DisguiseInit = 0x80242e2c
export const RAM_func_80241E50_A4A410 = 0x80241e50
export const RAM_D_80242D50_918AC0 = 0x80242d50
export const RAM_HES_Item_SneakyParasol = 0x80081db8
export const RAM_DoDeath = 0x8029aec0
export const RAM_bind_enemy_interact = 0x8003ea1c
export const RAM_guMtxIdent = 0x80067440
export const RAM_ui_arrow_right_pal = 0x800fbb40
export const RAM_entity_MulticoinBlock_check_if_inactive = 0x802e3b08
export const RAM_D_802A18AC_0073B77C = 0x802a18ac
export const RAM_mim_05_PiranhaPlantAI_11 = 0x802414e8
export const RAM_DoAirLift = 0x8029c37c
export const RAM_npc_disable_collisions = 0x8003b198
export const RAM_flo_18_triggerCoord_8024368C = 0x8024368c
export const RAM_D_09000EC0_3272D0 = 0x09000ec0
export const RAM_mim_09_HoppingAI_ChaseInit = 0x8024086c
export const RAM_b_area_nok_paratroopa_takeTurn_8021FA24 = 0x80224854
export const RAM_D_80243658_A64028 = 0x80243658
export const RAM_func_802403C0_AA7840 = 0x802403c0
export const RAM_func_800510A4 = 0x800510a4
export const RAM_clear_effect_data = 0x80059c9c
export const RAM_omo_04_ShyGuyWanderAI_14 = 0x80241898
export const RAM_dead_set_npc_yaw = 0x8003ed50
export const RAM_HES_Item_Unused_030 = 0x80083978
export const RAM_kpa_08_MagikoopaAI_10 = 0x80240a24
export const RAM_b_area_sam_duplighost_bombette_init = 0x80220480
export const RAM_contPakOpen = 0x8005f6f8
export const RAM_vtx_C27FC0 = 0x80207080
export const RAM_b_area_isk_part_1_vector3D_802240AC = 0x802240ac
export const RAM_npc_update_decoration_charged = 0x8003ccb0
export const RAM_HES_TimingCharge4a = 0x802927f0
export const RAM_set_background_color_blend = 0x8011be5c
export const RAM_arn_07_802439B0 = 0x802439b0
export const RAM_func_80240000_D92F40 = 0x80240000
export const RAM_trd_04_FlyingAI_Loiter = 0x802426b0
export const RAM_D_09002DD8 = 0x09002dd8
export const RAM_dgb_10_pad_2E8 = 0x802402e8
export const RAM_kpa_61_FlyingMagikoopaAI_Main = 0x80242024
export const RAM_D_8024408C_846C5C = 0x8024408c
export const RAM_mac_01_Quizmo_StashVars = 0x80241f10
export const RAM_snd_adjust_sound = 0x800552d0
export const RAM_b_area_kmr_part_2_pos01 = 0x8021975c
export const RAM_dro_02_Quizmo_HideWorld = 0x8024072c
export const RAM_D_80241B84_A28A54 = 0x80241b84
export const RAM_entity_SaveBlock_resume_game = 0x802e117c
export const RAM_mim_02_HoppingAI_Chase = 0x8024091c
export const RAM_func_80245718_A2F958 = 0x80245718
export const RAM_HES_StatusCoin = 0x80108558
export const RAM_IES_FlowerSaver = 0x8008c484
export const RAM_npc_draw_palswap_mode_0 = 0x8003b500
export const RAM_flo_00_pad_XXXX = 0x802491b8
export const RAM_func_80240000_8A5A80 = 0x80240000
export const RAM_gCameras = 0x800b1d80
export const RAM_kkj_10_UnkGooberFunc = 0x80240728
export const RAM_butterflies_appendGfx = 0xe00aa404
export const RAM_D_E00EAA60 = 0xe00eaa60
export const RAM_b_area_omo2_1_shy_squad_set_alive_guys_animation = 0x802343dc
export const RAM_flo_25_FlyingAI_ChaseInit = 0x80240d00
export const RAM_D_E00A4B30 = 0xe00a4b30
export const RAM_dro_02_init_8024C038 = 0x8024c038
export const RAM_IsPlayerWithin = 0x802d4964
export const RAM_b_area_isk_part_1_isk_06b_afterBattle_80223F9C = 0x80223f9c
export const RAM_func_802749D8 = 0x802749d8
export const RAM_fire_breath_render = 0xe006e6e4
export const RAM_sam_11_Quizmo_FadeInWorld = 0x80240d48
export const RAM_b_area_iwa_vector3D_8021853C = 0x8021853c
export const RAM_func_802383E4_703ED4 = 0x802383e4
export const RAM_battle_item_dried_shroom_main = 0x802a18fc
export const RAM_D_802AD0B0 = 0x802ad0b0
export const RAM_PlayerJump1 = 0x802d1904
export const RAM_battle_star_smooch_UnkStarFunc2 = 0x802a10ac
export const RAM_flo_03_80244ED0 = 0x80244ed0
export const RAM_D_802ACBE0 = 0x802acbe0
export const RAM_flo_18_80245624 = 0x80245624
export const RAM_HES_Item_QuakeHammer = 0x80083cd8
export const RAM_HES_Item_DriedPasta_disabled = 0x80086be8
export const RAM_battle_item_mystery_func_802A188C_72CE3C = 0x802a188c
export const RAM_DeletePartner = 0x802807a8
export const RAM_IES_KooperShell = 0x8008b5a4
export const RAM_HES_Item_ThunderRage = 0x80086198
export const RAM_D_80246628_EA6F28 = 0x80246628
export const RAM_b_area_mac_chan_defenseTable_80219D50 = 0x80219d50
export const RAM_battle_action_cmd_water_block_1_pal = 0x802aa280
export const RAM_jan_03_UpdateTexturePanStepped = 0x80240404
export const RAM_ED0310_FlyingAI_JumpInit = 0x80240efc
export const RAM_func_802403FC_DE5AAC = 0x802403fc
export const RAM_flo_11_npcSettings_80241AC0 = 0x80241ac0
export const RAM_b_area_kzn_formation_07 = 0x802299f8
export const RAM_mim_11_foliage_setup_shear_mtx = 0x80240980
export const RAM_guMtxXFMF = 0x80067a70
export const RAM_battle_partner_goombario_idleAnimations_802391B0 = 0x802391b0
export const RAM_D_80241B00_BDECB0 = 0x80241b00
export const RAM_func_80054AA0 = 0x80054aa0
export const RAM_ShowMerleeRanOutMessage = 0x8003eecc
export const RAM_appendGfx_npc_blur = 0x8003ae38
export const RAM_omo_02_ProjectileHitbox_30 = 0x80241c7c
export const RAM_b_area_sam_duplighost_lakilester_init = 0x80226300
export const RAM_dup2_b_area_tik2_UnkWaterFountainFunc = 0x80218884
export const RAM_iwa_00_GetItemName = 0x802400b4
export const RAM_func_8004E444 = 0x8004e444
export const RAM_jan_08_SuperBlock_GatherEnergyFX = 0x80240ba4
export const RAM_func_8024569C_A2F8DC = 0x8024569c
export const RAM_D_8014C678 = 0x8014c678
export const RAM_D_80099B40 = 0x80099b40
export const RAM_kmr_03_func_802401B0_8C8140 = 0x802401b0
export const RAM_D_80243CE0_B06560 = 0x80243ce0
export const RAM_D_802BE920 = 0x802be920
export const RAM_D_80243A10_AC8B80 = 0x80243a10
export const RAM_flo_11_vectorList_80241B4C = 0x80241b4c
export const RAM_mac_02_Quizmo_Answers = 0x802461b4
export const RAM_draw_coin_sparkles = 0x80130b0c
export const RAM_render_transform_group_node = 0x80117e74
export const RAM_dgb_01_D_802523C8_C0FC48 = 0x802523c8
export const RAM_TranslateGroup = 0x802c97b4
export const RAM_HES_Item_LuckyDay_disabled = 0x80084e48
export const RAM_dro_01_npcAISettings_8024508C = 0x8024508c
export const RAM_floating_flower_update = 0xe001a224
export const RAM_b_area_omo_omo_06_afterBattle_8022F860 = 0x8022f860
export const RAM_D_E008CB78 = 0xe008cb78
export const RAM_tik_08_Pipe_AwaitDownInput = 0x80240034
export const RAM_D_E009EDF4 = 0xe009edf4
export const RAM_b_area_kmr_part_3_spiked_goomba_CalculateRotationZ = 0x8021818c
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_80228664 = 0x80228664
export const RAM_mac_04_SetEntityFlags100000 = 0x80241eb0
export const RAM_flo_00_npcSettings_8024296C = 0x8024296c
export const RAM_b_area_omo_groove_guy_80226C00 = 0x80226c00
export const RAM_D_80244040_BA4770 = 0x80244040
export const RAM_func_802AE000_316C00 = 0x802ae000
export const RAM_D_8024AC7C_A34EBC = 0x8024ac7c
export const RAM_func_80268834 = 0x80268834
export const RAM_stop_watch_main = 0xe00c4000
export const RAM_jan_01_HoppingAI_Main = 0x80240f30
export const RAM_PlayLoopingSoundAtActor = 0x80253974
export const RAM_filemenu_update_show_options_bottom = 0x802434dc
export const RAM_ui_super_hammer_disabled_pal = 0x800fac80
export const RAM_wBgRenderType = 0x80153220
export const RAM_jan_22_PatrolAI_ChaseInit = 0x8024081c
export const RAM_func_802415B8_BD01A8 = 0x802415b8
export const RAM_kkj_10_UnkModelMatrixFunc = 0x802402c0
export const RAM_omo_02_ProjectileHitbox_GetUsableProjectileID = 0x80241948
export const RAM_tik_12_SuperBlock_WhiteScreenFlash = 0x80241754
export const RAM_b_area_kmr_part_2_blue_goomba_idle_80219A98 = 0x80219a98
export const RAM_func_802667F0 = 0x802667f0
export const RAM_isk_18_UnkNpcAIFunc12 = 0x802401b0
export const RAM_flo_13_80243A40 = 0x80243a40
export const RAM_func_802A9298_4302B8 = 0x802a9298
export const RAM_D_802A3168_00736708 = 0x802a3168
export const RAM_kmr_03_hit_80240F64 = 0x80240f64
export const RAM_b_area_jan_m_bush_takeTurn_80221F78 = 0x80221f78
export const RAM_pause_update_tab_active = 0x80243090
export const RAM_tik_20_UnkPosFunc2 = 0x802405ec
export const RAM_dup4_b_area_kpa2_RemoveChillOut = 0x8021a7d4
export const RAM_hos_03_Quizmo_SetStageLightsDelay = 0x80242ee0
export const RAM_tik_14_Pipe_SetAnimFlag = 0x80240000
export const RAM_jan_03_PatrolAI_Loiter = 0x80240ae0
export const RAM_D_090018F8 = 0x090018f8
export const RAM_D_80243348_9A4328 = 0x80243348
export const RAM_func_8014A964 = 0x8014a964
export const RAM_pra_01_AddPlayerHandsOffset = 0x80240fdc
export const RAM_D_80270284 = 0x80270284
export const RAM_b_area_omo_formation_0E = 0x8022fd58
export const RAM_hos_03_FlyingAI_ChaseInit = 0x80241970
export const RAM_HES_Item_ISpy_disabled = 0x80085208
export const RAM_hos_03_Quizmo_AudienceEffect = 0x8024bdf4
export const RAM_func_80240510_D7F270 = 0x80240510
export const RAM_func_80240FD0_A171F0 = 0x80240fd0
export const RAM_HES_Item_Unused_13E = 0x80082718
export const RAM_D_8029D380 = 0x8029d380
export const RAM_dgb_08_npcSettings_80244A28 = 0x80244a28
export const RAM_gPauseBadgesBShowNotEnoughBP = 0x802703a0
export const RAM_ui_battle_cmd_aim_marker_3_pal = 0x8028e7e0
export const RAM_D_802452A0_BB2BE0 = 0x802452a0
export const RAM_b_area_mac_lee_idle_80226B44 = 0x80226b44
export const RAM_HES_Item_Mushroom_disabled = 0x80086708
export const RAM_evt_handle_return = 0x802c4998
export const RAM_func_80238E48_6F1F28 = 0x80238e48
export const RAM_jan_02_Quizmo_RenderInit = 0x80241974
export const RAM_func_80241A58_A3AF38 = 0x80241a58
export const RAM_func_802413D0_D659B0 = 0x802413d0
export const RAM_D_802DA0A0 = 0x802da0a0
export const RAM_flo_21_80241920 = 0x80241920
export const RAM_HES_AimShimmerB = 0x80293274
export const RAM_func_80238000_6FAD10 = 0x80238000
export const RAM_arn_07_extraAnimationList_802451F8 = 0x802451f8
export const RAM_SetPartYaw = 0x8026b438
export const RAM_dup4_b_area_pra_StartRumbleWithParams = 0x80219310
export const RAM_b_area_pra2_crystal_king_array_2 = 0x8021d67c
export const RAM_func_80242BC0_8B2C30 = 0x80242bc0
export const RAM_func_800E069C = 0x800e069c
export const RAM_kpa_102_FlyingMagikoopaAI_21 = 0x80242d3c
export const RAM_osPfsCheckRamArea = 0x80069388
export const RAM_ui_battle_status_charge_goombario_png = 0x80101a00
export const RAM_nok_14_FlyingAI_LoiterInit = 0x8024116c
export const RAM_hos_02_FlyingNoAttackAI_12 = 0x802424e8
export const RAM_ui_status_pow_star_7_pal = 0x80100aa0
export const RAM_D_80247870_DA4D40 = 0x80247870
export const RAM_func_802A11C0_743FB0 = 0x802a11c0
export const RAM_D_802447A8_B97BE8 = 0x802447a8
export const RAM_b_area_kzn_lava_bubble_idle_802191E4 = 0x802191e4
export const RAM_D_8024F388_C0CC08 = 0x8024f388
export const RAM_make_transform_group = 0x8011b37c
export const RAM_ui_battle_status_frozen_2_pal = 0x80102660
export const RAM_D_802AD06A = 0x802ad06a
export const RAM_dgb_03_80243740 = 0x80243740
export const RAM_kpa_15_GetItemName = 0x802403c4
export const RAM_arn_07_StarSpiritEffectFunc5 = 0x802405fc
export const RAM_func_802A928C_42763C = 0x802a928c
export const RAM_iwa_02_CleftAI_CanSeePlayer = 0x80240000
export const RAM_guPerspectiveF = 0x80063b90
export const RAM_kkj_18_AddPlayerHandsOffset = 0x8024039c
export const RAM_D_E00C0A21 = 0xe00c0a21
export const RAM_dro_01_npcGroup_8024AE94 = 0x8024ae94
export const RAM_kzn_11_FlyingAI_WanderInit = 0x80240460
export const RAM_b_area_omo_formation_04 = 0x8022fa2c
export const RAM_D_80253390 = 0x80253390
export const RAM_action_command_smack_MashActionCommandInit = 0x802a917c
export const RAM_peach_make_disguise_npc = 0x800e657c
export const RAM_kkj_23_UnkModelMatrixFunc2 = 0x8024039c
export const RAM_D_802BCFB0_E2D6E0 = 0x802bcfb0
export const RAM_battle_move_auto_smash_IsBerserkerEquipped = 0x802a1000
export const RAM_hos_06_GetItemName = 0x80241158
export const RAM_msg_draw_speech_bubble = 0x8012cc08
export const RAM_VanishActor = 0x80270614
export const RAM_pause_main_draw_contents = 0x80243568
export const RAM_b_area_omo2_toy_tank_8021A2A0 = 0x8021a2a0
export const RAM_npc_get_render_yaw = 0x80039da4
export const RAM_D_80243FE8_D91238 = 0x80243fe8
export const RAM_nok_01_StationaryAI_Chase = 0x802403dc
export const RAM_func_8024042C_EE69DC = 0x8024042c
export const RAM_func_80266684 = 0x80266684
export const RAM_filemenu_update_hidden_name_input = 0x80243628
export const RAM_func_80240310_A45C40 = 0x80240310
export const RAM_sam_06_FlyingAI_LosePlayer = 0x802413a8
export const RAM_func_802402BC_D37ABC = 0x802402bc
export const RAM_HES_Item_SpeedySpin_disabled = 0x80085148
export const RAM_dro_02_Quizmo_AnswerResult = 0x8024efb8
export const RAM_HES_TimingBar3Chances = 0x80292afc
export const RAM_D_E00CCE70 = 0xe00cce70
export const RAM_kpa_91_UnkNpcAIFunc12 = 0x80241040
export const RAM_b_area_kmr_part_2_red_goomba_init_8021B85C = 0x8021b85c
export const RAM_gStatsBootsElements = 0x8024f360
export const RAM_hos_03_Quizmo_FadeInWorld = 0x80242a1c
export const RAM_D_802AD069 = 0x802ad069
export const RAM_b_area_kmr_part_3_spiked_goomba = 0x8021a46c
export const RAM_D_E003E898 = 0xe003e898
export const RAM_D_E0022D58 = 0xe0022d58
export const RAM_func_80219630_5CFCC0 = 0x80219630
export const RAM_HES_OptionStereoOn = 0x80242384
export const RAM_D_802BEC54 = 0x802bec54
export const RAM_dead_atan2 = 0x8002af70
export const RAM_func_802A4448 = 0x802a4448
export const RAM_func_80240310_AA92B0 = 0x80240310
export const RAM_kzn_03_MeleeHitbox_CanSeePlayer = 0x80240e00
export const RAM_flower_trail_render = 0xe0012504
export const RAM_goompa_802BD600 = 0x802bd600
export const RAM_set_entity_fog_dist = 0x80123010
export const RAM_b_area_kmr_part_1_spiked_goomba_defenseTable_8021E3B0 = 0x8021afd0
export const RAM_func_8006EF58 = 0x8006ef58
export const RAM_IES_PrettyLucky = 0x8008c204
export const RAM_HES_Item_Unused_0E7 = 0x80085a78
export const RAM_D_80242530_A4EF50 = 0x80242530
export const RAM_D_8024F380 = 0x8024f380
export const RAM_D_8024BB80 = 0x8024bb80
export const RAM_D_E002C9F0 = 0xe002c9f0
export const RAM_b_area_iwa_buzzar_init_8021B7E4 = 0x8021b7e4
export const RAM_dro_01_Quizmo_DestroyEffects = 0x80241df8
export const RAM_D_80232C94_684A14 = 0x80232c94
export const RAM_func_80244550_A2E790 = 0x80244550
export const RAM_mac_03_Quizmo_StageEffect = 0x802513f0
export const RAM_flo_12_func_802405C0_CC13F0 = 0x802405c0
export const RAM_kkj_20_GetPeachDisguise = 0x802405bc
export const RAM_D_E0070CF0 = 0xe0070cf0
export const RAM_D_8014F524 = 0x8014f524
export const RAM_b_area_isk_part_2_tutankoopa_8021D04C = 0x8021d04c
export const RAM_isk_10_SuperBlock_GatherEnergyFX = 0x80240544
export const RAM_HES_Item_Unused_0F1 = 0x80085e38
export const RAM_dead_basic_ai_found_player_jump_init = 0x8004e25c
export const RAM_b_area_kmr_part_1_kmr_06_afterBattle_80220EAC = 0x80220eac
export const RAM_D_E0022D50 = 0xe0022d50
export const RAM_func_802A9000_42EE20 = 0x802a9000
export const RAM_D_80098258 = 0x80098258
export const RAM_tik_07_SuperBlock_CountEligiblePartners = 0x80240400
export const RAM_arn_11_intTable_80240988 = 0x80240988
export const RAM_LakilesterTweesterPhysicsPtr = 0x802bfe7c
export const RAM_vtx_C2AC90 = 0x80209d50
export const RAM_IES_1DC870 = 0x8008bbc4
export const RAM_func_8024146C_A2B6AC = 0x8024146c
export const RAM_sam_04_SetAngleClamped = 0x80240240
export const RAM_ui_status_star_point_3_png = 0x80101320
export const RAM_flo_16_SuperBlock_PartnerIDs = 0x80243708
export const RAM_D_80235F44_4CF134 = 0x80235f44
export const RAM_b_area_isk_part_2_isk_06_beforeBattle_80220260 = 0x80220260
export const RAM_D_80243918_A4BED8 = 0x80243918
export const RAM_D_800A08E4 = 0x800a08e4
export const RAM_b_area_pra3_duplighost_UnkFunc62 = 0x80218000
export const RAM_b_area_arn_formation_08 = 0x80227e90
export const RAM_end_01_CharAnim_FadeIn_2 = 0x80240550
export const RAM_battle_item_coconut_GiveRefund = 0x802a1000
export const RAM_func_802427B4_E07B14 = 0x802427b4
export const RAM_D_8024BB40_B8FCC0 = 0x8024bb40
export const RAM_kpa_102_ProjectileHitbox_32 = 0x80240788
export const RAM_D_80232B38_47EFC8 = 0x80232b38
export const RAM_func_80242DB0_C63AC0 = 0x80242db0
export const RAM_b_area_isk_part_1_pokey_mummy_init_80218EC4 = 0x80218ec4
export const RAM_CamPresetUpdate_N = 0x8024b5fc
export const RAM_dust_main = 0xe002a000
export const RAM_kpa_91_PatrolAI_MoveInit = 0x80240000
export const RAM_EB8E90_PatrolAI_Chase = 0x8024098c
export const RAM_D_8025B0A8 = 0x8025b0a8
export const RAM_b_area_sam_formation_0E = 0x802310f4
export const RAM_pra_28_reflection_render_floor = 0x802406bc
export const RAM_load_font = 0x80123734
export const RAM_func_802400C8_7E7468 = 0x802400c8
export const RAM_trd_07_ParatroopaAI_Main = 0x8024231c
export const RAM_D_E0036650 = 0xe0036650
export const RAM_EDEA00_set_script_owner_npc_anim = 0x802422f0
export const RAM_pra_06_reflection_render_floor_fancy = 0x802408a0
export const RAM_ui_battle_hp_9_png = 0x8028aba0
export const RAM_D_80241EA0_98AA00 = 0x80241ea0
export const RAM_dro_01_dup_Pipe_SetAnimFlag = 0x802435a0
export const RAM_D_80245448_CD7278 = 0x80245448
export const RAM_dgb_04_SentinelAI_Main = 0x80242154
export const RAM_arn_04_pad_403C = 0x8024403c
export const RAM_IES_FertileSoil = 0x8008b8e4
export const RAM_func_80219010_5CF6A0 = 0x80219010
export const RAM_b_area_sam_white_clubba_init = 0x80229c20
export const RAM_func_802CDE68 = 0x802cde68
export const RAM_D_80150920 = 0x80150920
export const RAM_b_area_sam_duplighost_goombario_idleAnimations = 0x8021caf4
export const RAM_tik_21_Pipe_AwaitDownInput = 0x80240340
export const RAM_appendGfx_model = 0x80113090
export const RAM_au_sfx_set_player_modifiers = 0x8004c3d4
export const RAM_b_area_sam2_sam_02c_beforeBattle = 0x80222c48
export const RAM_EnterWalkShort = 0x80285a60
export const RAM_dro_02_EVS_Quizmo_NPC_Aux = 0x80247344
export const RAM_b_area_jan_jan_02_beforeBattle_80228290 = 0x80228290
export const RAM_battle_item_pow_block_main = 0x802a195c
export const RAM_STUB_npc_callback = 0x80038470
export const RAM_pra_27_varStash = 0x80241690
export const RAM_b_area_kzn_lava_bubble_fireballs_Player = 0x8021a7e8
export const RAM_flo_21_idle_80243428 = 0x80243428
export const RAM_func_80240610_A16830 = 0x80240610
export const RAM_flo_13_npcGroup_802473B4 = 0x802473b4
export const RAM_b_area_trd_part_2_trd_05c_afterBattle_80234500 = 0x80234500
export const RAM_pause_items_scroll_offset_y = 0x80249958
export const RAM_vtx_C25BD0 = 0x80204c90
export const RAM_dispatch_event_partner_continue_turn = 0x8027df0c
export const RAM_EnableIdleScript = 0x80278b4c
export const RAM_b_area_iwa_iwa_01b_afterBattle_80221544 = 0x80221544
export const RAM_IES_Letter = 0x8008bde4
export const RAM_b_area_iwa_whacka_IsSaveVar123 = 0x80218120
export const RAM_D_800F7B48 = 0x800f7b48
export const RAM_stars_spread_main = 0xe005a000
export const RAM_D_E0022D1C = 0xe0022d1c
export const RAM_arn_04_npcAI_80243690 = 0x80243690
export const RAM_mac_05_unkVtxFunc001 = 0x80243588
export const RAM_D_80074268 = 0x80074268
export const RAM_osDispatchThreadSave = 0x8006b1f4
export const RAM_gGameStepDelayAmount = 0x80074020
export const RAM_D_E008CB28 = 0xe008cb28
export const RAM_b_area_sam2_monstar_OnBurn = 0x8021b898
export const RAM_func_801369D0 = 0x801369d0
export const RAM_HES_Item_Calculator = 0x80081b18
export const RAM_func_802A2468_79AC18 = 0x802a2468
export const RAM_battle_item_shooting_star_DrinkItem = 0x802a1a94
export const RAM_D_E0056EE0 = 0xe0056ee0
export const RAM_sort_items = 0x800e76cc
export const RAM_func_802403B0_C5AAF0 = 0x802403b0
export const RAM_D_80242054_A660E4 = 0x80242054
export const RAM_BattleCam_DoneMoving = 0x8029f29c
export const RAM_func_802411A0_D51190 = 0x802411a0
export const RAM_load_hit_data = 0x8005b23c
export const RAM_draw_encounters_pre_battle = 0x80041964
export const RAM_kpa_102_FlyingMagikoopaAI_OnHitInit = 0x80243504
export const RAM_jan_11_Pipe_GetCameraYaw = 0x80240328
export const RAM_b_area_kmr_part_3_para_jr_troopa = 0x80225f54
export const RAM_func_802402B8_B76A68 = 0x802402b8
export const RAM_world_lakilester_use_ability = 0x802bfe9c
export const RAM_D_8014BA20 = 0x8014ba20
export const RAM_world_goompa_take_out = 0x802bd570
export const RAM_tik_04_Pipe_GetEntryPos = 0x802403fc
export const RAM_b_area_hos_ember_8021C5F8 = 0x8021c5f8
export const RAM_flo_10_get_tattle = 0x80240000
export const RAM_evt_handle_exec_wait = 0x802c65fc
export const RAM_dgb_09_entryList = 0x80243800
export const RAM_create_mesh_animator = 0x8011e5e0
export const RAM_func_8026E558 = 0x8026e558
export const RAM_D_E006EC30 = 0xe006ec30
export const RAM_b_area_kmr_part_3_paragoomba2_idle_8021B434 = 0x8021fa74
export const RAM_flo_11_exitWalk_8024142C = 0x8024142c
export const RAM_b_area_kzn_spike_top_idleAnimations_8021CA68 = 0x8021ca68
export const RAM_dro_01_EVS_Quizmo_PlayerReaction_WrongAnswer = 0x8024667c
export const RAM_D_80150ED8 = 0x80150ed8
export const RAM_b_area_mac_lee_bombette_UnkActorPosFunc = 0x80219064
export const RAM_b_area_omo2_5_shy_squad_dup_onDeath = 0x80234024
export const RAM_func_E0086398 = 0xe0086398
export const RAM_flo_16_func_80240000_CD1E30 = 0x80240000
export const RAM_func_802402D0_A7A7D0 = 0x802402d0
export const RAM_EnemyCreateTargetList = 0x8026e310
export const RAM_b_area_kgr_kgr_01_8021D010 = 0x8021d010
export const RAM_HES_Item_Melon_disabled = 0x80086ee8
export const RAM_func_8024073C_A57BEC = 0x8024073c
export const RAM_dgb_08_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_mac_05_AddPlayerHandsOffset = 0x80242410
export const RAM_EE70C0_FlyingAI_Jump = 0x80241764
export const RAM_dgb_09_80243B98 = 0x80243b98
export const RAM_kmr_02_StationaryAI_Idle = 0x802414d8
export const RAM_D_802ECD10 = 0x802ecd10
export const RAM_gHudElementsBattle = 0x80157460
export const RAM_D_80240C0C_90C2DC = 0x80240c0c
export const RAM_battle_partner_goombario_8023A66C = 0x8023a66c
export const RAM_flo_23_npcSettings_802412E0 = 0x802412e0
export const RAM_arn_04_CleftAI_DisguiseInit = 0x80242ef0
export const RAM_kmr_11_TransformFoliage = 0x80240070
export const RAM_D_E01248C0 = 0xe01248c0
export const RAM_D_E011AC58 = 0xe011ac58
export const RAM_dro_02_interact_8024BA9C = 0x8024ba9c
export const RAM_tik_05_UpdateTexturePanSmooth = 0x80240000
export const RAM_collision_lava_reset_check_additional_overlaps = 0x800e4bb8
export const RAM_nok_14_ParatroopaAI_Main = 0x802421cc
export const RAM_flo_21_StarSpiritEffectFunc3 = 0x802401ac
export const RAM_nuGfxCfbNum = 0x800773f0
export const RAM_intro_logos_fade_in = 0x800337f8
export const RAM_D_09003AB8_3AEAE8 = 0x09003ab8
export const RAM_tik_09_UpdateTexturePanStepped = 0x80240404
export const RAM_flo_03_80240F0C = 0x80240f0c
export const RAM_dgb_08_FlyingAI_LoiterInit = 0x80241f38
export const RAM_SetActorSize = 0x8027d810
export const RAM_create_effect_instance = 0x8005a2bc
export const RAM_btl_set_state = 0x80241190
export const RAM_D_8014F4A4 = 0x8014f4a4
export const RAM_D_8010D69A = 0x8010d69a
export const RAM_D_8024DEC0_C0B740 = 0x8024dec0
export const RAM_func_A4000AC4 = 0xa4000ac4
export const RAM_b_area_kmr_part_3_paragoomba1_8021C488 = 0x8021c488
export const RAM_gAnimModelFogG = 0x80153a4c
export const RAM_func_802406E0_EB1540 = 0x802406e0
export const RAM_b_area_sam_duplighost_UnkBackgroundFunc3 = 0x8021943c
export const RAM_mac_05_GetFloorCollider = 0x80243088
export const RAM_func_80240510_B1BA80 = 0x80240510
export const RAM_b_area_isk_part_1_stone_chomp_handleEvent_80222364 = 0x80222364
export const RAM_b_area_sam_duplighost_UnkBattleFunc1 = 0x80218b74
export const RAM_D_00E62370 = 0x00e62370
export const RAM_battle_star_chill_out_UnkStarFunc3 = 0x802a116c
export const RAM_HasMerleeCasts = 0x8003f3f4
export const RAM_D_8010D699 = 0x8010d699
export const RAM_func_802407F4_C6EB24 = 0x802407f4
export const RAM_walking_dust_appendGfx = 0xe000e288
export const RAM_b_area_trd_part_1_trd_05f_afterBattle_80227C8C = 0x80227c8c
export const RAM_mac_01_Quizmo_RenderInit = 0x80242270
export const RAM_kzn_09_SuperBlock_HideBlockContent = 0x8024003c
export const RAM_dgb_12_802405E0 = 0x802405e0
export const RAM_b_area_omo_spy_guy_ItemEntityJumpToPos = 0x80218880
export const RAM_flo_25_TransformFoliage = 0x80242060
export const RAM_dro_01_80243BB0 = 0x80243bb0
export const RAM_D_80093D20 = 0x80093d20
export const RAM_func_80240FD0_D7FD30 = 0x80240fd0
export const RAM_D_802465CC = 0x802465cc
export const RAM_tik_07_SuperBlock_RadiateFaintEnergyFX = 0x80240888
export const RAM_b_area_kmr_part_3_spiked_goomba_handleEvent_8021A854 = 0x8021a854
export const RAM_D_80245F18_AD4058 = 0x80245f18
export const RAM_D_802440E4 = 0x802440e4
export const RAM_dup_b_area_kpa2_StartRumbleWithParams = 0x80218120
export const RAM_D_80241CF8_A4E718 = 0x80241cf8
export const RAM_dgb_06_SomeItemEntityFunc = 0x80240118
export const RAM_dup3_kmr_20_TransformFoliage = 0x80241ad0
export const RAM_dro_02_D_802475DC_97079C = 0x802475dc
export const RAM_tik_02_UnkPosFunc2 = 0x802405d0
export const RAM_b_area_trd_part_2_trd_03_clouds2 = 0x802325f8
export const RAM_func_8013A6E8 = 0x8013a6e8
export const RAM_HES_Parakarry = 0x80107de8
export const RAM_func_8024061C_BD3E8C = 0x8024061c
export const RAM_create_status_icon_boost_partner = 0x80047e4c
export const RAM_b_area_jan_medi_guy_8021FB9C = 0x8022285c
export const RAM_dro_02_interact_8024BF34 = 0x8024bf34
export const RAM_b_area_nok_paratroopa_init_8021EE0C = 0x80223c3c
export const RAM_ui_battle_cmd_aim_marker_1_png = 0x8028e620
export const RAM_dgb_06_StashVars = 0x80240000
export const RAM_tik_12_Pipe_GetCameraYaw = 0x802401d0
export const RAM_star_appendGfx = 0xe001e730
export const RAM_huff_puff_breath_render = 0xe00dc2c0
export const RAM_D_E00DE810 = 0xe00de810
export const RAM_battle_move_jump_charge_1_UnkMoveFunc2 = 0x802a1228
export const RAM_pra_28_ReflectWall = 0x8024011c
export const RAM_b_area_omo_medi_guy = 0x80223540
export const RAM_D_8024FEE0_C0D760 = 0x8024fee0
export const RAM_flo_10_searchBush = 0x80244090
export const RAM_D_E0030ED8 = 0xe0030ed8
export const RAM_D_80245F20_AD4060 = 0x80245f20
export const RAM_b_area_kmr_part_3_egg_jr_troopa_idleAnimations_802243BC = 0x802243bc
export const RAM_gCollisionRayStartX = 0x800a4230
export const RAM_D_80242350 = 0x80242350
export const RAM_D_8024F480 = 0x8024f480
export const RAM_arn_02_npcAI_80241400 = 0x80241400
export const RAM_ui_pip_pal = 0x800fbc20
export const RAM_D_090021C0_3523E0 = 0x090021c0
export const RAM_jan_09_Pipe_GetEntryPos = 0x802400f0
export const RAM_flo_17_FlyingAI_Init = 0x80241258
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idle_80230CC0 = 0x80230cc0
export const RAM_battle_item_unknown_item_main = 0x802a18fc
export const RAM_b_area_mim_forest_fuzzy_vector3D_8021A7F4 = 0x8021a7f4
export const RAM_D_80241850_EDD830 = 0x80241850
export const RAM_D_8010C920 = 0x8010c920
export const RAM_HES_Item_DeepFocus_disabled = 0x80085808
export const RAM_world_rodata_alignment = 0x80099a9c
export const RAM_vtx_C2AD70 = 0x80209e30
export const RAM_filemenu_draw_contents_title = 0x80244c30
export const RAM_EE65B0_UpdateTexturePanSmooth = 0x80240000
export const RAM_RestorePreDefeatState = 0x8026127c
export const RAM_kzn_11_UpdateTexturePanSmooth = 0x80240000
export const RAM_kpa_08_MagikoopaAI_SpellMain = 0x80240270
export const RAM_GetNpcPointer = 0x802cdc58
export const RAM_battle_star_refresh_AddFP = 0x802a1894
export const RAM_mim_01_MeleeHitbox_33 = 0x802406b4
export const RAM_func_80266F8C = 0x80266f8c
export const RAM_PlayBattleMerleeOrbFX = 0x80261478
export const RAM_kzn_09_PiranhaPlantAI_Main = 0x80242774
export const RAM_func_8025DE88 = 0x8025de88
export const RAM_D_E0056EE8 = 0xe0056ee8
export const RAM_b_area_jan_medi_guy_handleEvent_8021FC60 = 0x80222920
export const RAM_b_area_kmr_part_3_sam_01 = 0x80233ac4
export const RAM_sam_11_Quizmo_ShowEntities = 0x802408fc
export const RAM_D_802BDF5C = 0x802bdf5c
export const RAM_D_80243880_D16E50 = 0x80243880
export const RAM_evt_handle_case_not_equal = 0x802c50bc
export const RAM_D_802B6730 = 0x802b6730
export const RAM_EB1170_LetterDelivery_SaveNpcAnim = 0x802409f8
export const RAM_dro_02_80243CC8 = 0x80243cc8
export const RAM_flo_22_80241528 = 0x80241528
export const RAM_D_8010CFCE = 0x8010cfce
export const RAM_func_8024001C_A4CA3C = 0x8024001c
export const RAM_b_area_mim_formation_0D = 0x8021e8e8
export const RAM_flo_11_pad_1AB8 = 0x80241ab8
export const RAM_GetAnimation = 0x8026b238
export const RAM_D_8024EF60_C0C7E0 = 0x8024ef60
export const RAM_gEntityHideMode = 0x80151310
export const RAM_gPauseCurrentDescMsg = 0x802700c8
export const RAM_dro_02_pad_41B4 = 0x802441b4
export const RAM_D_E00628E0 = 0xe00628e0
export const RAM_func_802A9544 = 0x802a9544
export const RAM_jan_08_SuperBlock_WaitForPlayerToLand = 0x80241368
export const RAM_func_8026324C = 0x8026324c
export const RAM_ui_star_beam_disabled_pal = 0x800fe0e0
export const RAM_kzn_10_UnkFunc50 = 0x802405a4
export const RAM_func_80240508_9AEC08 = 0x80240508
export const RAM_b_area_iwa_formation_04 = 0x802182f0
export const RAM_D_09002238 = 0x09002238
export const RAM_D_80245970_A161F0 = 0x80245970
export const RAM_entity_SaveBlock_show_choice_message = 0x802e1298
export const RAM_kpa_61_MagikoopaAI_CanShootSpell = 0x80240ae4
export const RAM_func_80240E2C_C8E9DC = 0x80240e2c
export const RAM_func_80027600 = 0x80027600
export const RAM_kpa_113_SetEntityFlags100000 = 0x80240000
export const RAM_D_E009AA78 = 0xe009aa78
export const RAM_flo_07_tree1 = 0x802428a0
export const RAM_flo_25_FlyingAI_Main = 0x8024134c
export const RAM_shimmer_wave_render = 0xe0074598
export const RAM_b_area_kmr_part_1_formationTable = 0x802194d4
export const RAM_NAMESPACE_8023B228 = 0x8023b228
export const RAM_dro_02_EVS_Quizmo_GiveItem_16 = 0x80244e14
export const RAM_HES_ShrunkBegin = 0x80106964
export const RAM_arn_05_init_802447AC = 0x802447ac
export const RAM_NpcSetHomePosToCurrent = 0x802cf3bc
export const RAM_D_80097DE0 = 0x80097de0
export const RAM_omo_13_SomeItemEntityFunc = 0x80241348
export const RAM_HES_MenuBoots2 = 0x80108794
export const RAM_EnterSingleDoor = 0x80285dd4
export const RAM_b_area_hos_ember_specialFormation_8021B5A0 = 0x8021b5a0
export const RAM_func_802415FC_86613C = 0x802415fc
export const RAM_b_area_isk_part_1_buzzy_beetle_idleAnimations_8021E5E0 = 0x8021e5e0
export const RAM_sbk_24_SpawnSunEffect = 0x80240000
export const RAM_obfuscated_general_heap_create = 0x7012bc11
export const RAM_D_E003E890 = 0xe003e890
export const RAM_dgb_01_D_80252A7C_C102FC = 0x80252a7c
export const RAM_gv_maps = 0x800934d0
export const RAM_HES_StatusSlash = 0x80108090
export const RAM_func_802406E0_DCD3B0 = 0x802406e0
export const RAM_HES_Item_Unused_0F2_disabled = 0x80085ec8
export const RAM_HES_MashCRightButton2 = 0x80292560
export const RAM_jan_02_Quizmo_SpinPlayer = 0x80242430
export const RAM_spiky_white_aura_render = 0xe0038704
export const RAM_pra_11_SetPartnerFlags80000 = 0x80240eb8
export const RAM_D_802A9970_429C90 = 0x802a9970
export const RAM_b_area_mim_mim_01_beforeBattle_8021E450 = 0x8021e450
export const RAM_EA0C10_PiranhaPlantAI_Main = 0x80241ae0
export const RAM_SetCamEnabled = 0x802ca6c0
export const RAM_omo_02_RangedAttackAI_Main = 0x8024283c
export const RAM_b_area_sam2_paragoomba_defenseTable_8021B11C = 0x8021be9c
export const RAM_b_area_isk_part_2_tutankoopa_init_8021BC1C = 0x8021bc1c
export const RAM_end_00_PlayFX3D = 0x80242900
export const RAM_SetMessageValue = 0x802d0d44
export const RAM_D_802A9812_428282 = 0x802a9812
export const RAM_b_area_arn_hyper_paragoomba_nextTurn_8021B5F4 = 0x8021b5f4
export const RAM_D_09000100_392540 = 0x09000100
export const RAM_gPauseCursorOpacity = 0x8024efa4
export const RAM_IES_GearBoots1 = 0x8008b3e4
export const RAM_battle_item_hustle_drink_PlayerGoHome = 0x802a1710
export const RAM_length2D = 0x80028ff0
export const RAM_HES_StatusFlower = 0x80108538
export const RAM_dro_01_moveWalls_8024BE38 = 0x8024be38
export const RAM_vtx_curtains7 = 0x80075de0
export const RAM_D_8007F200 = 0x8007f200
export const RAM_b_area_kmr_part_2_kmr_04_clouds3 = 0x80224de0
export const RAM_dgb_09_extraAnimationList_802440E4 = 0x802440e4
export const RAM_D_80234740_6BB940 = 0x80234740
export const RAM_b_area_omo2_6_shy_guy_dup_parts = 0x802310b8
export const RAM_D_8014BF90 = 0x8014bf90
export const RAM_ui_battle_status_pdown_0_pal = 0x80102a20
export const RAM_D_80257B48 = 0x80257b48
export const RAM_world_sushie_put_away = 0x802bfe18
export const RAM_D_8024F2A2 = 0x8024f2a2
export const RAM_b_area_sam2_sam_03_beforeBattle = 0x80223338
export const RAM_iwa_10_SuperBlock_PartnerIDs = 0x80245a68
export const RAM_update_actor_shadow = 0x802559bc
export const RAM_D_E006AE10 = 0xe006ae10
export const RAM_D_80286538 = 0x80286538
export const RAM_IES_HealthyHealthy = 0x8008cae4
export const RAM_mac_04_Quizmo_StashVars = 0x80240460
export const RAM_enable_player_shadow = 0x800e00b0
export const RAM_hide_foreground_models_unchecked = 0x80267bdc
export const RAM_D_8024F2AB = 0x8024f2ab
export const RAM_HES_Item_HoneyUltra_disabled = 0x800828c8
export const RAM_D_802A1770_738000 = 0x802a17a0
export const RAM_e20eb0_UnkAngleFunc1 = 0x802b7000
export const RAM_func_80240500_AF2160 = 0x80240500
export const RAM_ShowShopOwnerDialog = 0x80280bd0
export const RAM_osCartRomInit = 0x80060f10
export const RAM_D_802448B0_943E60 = 0x802448b0
export const RAM_battle_star_lullaby_802A19B8 = 0x802a19b8
export const RAM_func_802BB870_E2BFA0 = 0x802bb870
export const RAM_D_802A9778_42A498 = 0x802a9778
export const RAM_pra_14_reflection_unk_resolve_anim = 0x80240020
export const RAM_battle_menu_moveCursorPos = 0x802ad109
export const RAM_omo_13_StashVars = 0x80241230
export const RAM_D_E000CC48 = 0xe000cc48
export const RAM_b_area_sam_sam_02d = 0x80230230
export const RAM_sam_08_SuperBlock_RadiateFaintEnergyFX = 0x802405a8
export const RAM_flo_23_entryList = 0x80240cd0
export const RAM_D_09000200_350420 = 0x09000200
export const RAM_flo_25_FlyingAI_LosePlayer = 0x80240e98
export const RAM_isk_02_SetNpcB5_3 = 0x802400f0
export const RAM_D_8010EF94 = 0x8010ef94
export const RAM_func_802A92A0_422D70 = 0x802a92a0
export const RAM_CurrentSeqCmdHandler = 0x800a3fd0
export const RAM_HES_Item_Unused_0F4_disabled = 0x80085f88
export const RAM_battle_item_mushroom_AddFP = 0x802a154c
export const RAM_arn_07_80243790 = 0x80243790
export const RAM_D_802455E0_C76A60 = 0x802455e0
export const RAM_b_area_kmr_part_2_blue_goomba_takeTurn_8021A5A0 = 0x8021a5a0
export const RAM_b_area_pra_UnkFunc52 = 0x802185a8
export const RAM_bTriggerList = 0x80159290
export const RAM_vtx_C21810 = 0x802008d0
export const RAM_func_80218AC0_4E3200 = 0x80218ac0
export const RAM_sparkle_script_update = 0x80130acc
export const RAM_jan_05_TransformFoliage = 0x80240510
export const RAM_HES_HPDigit8 = 0x802920e8
export const RAM_b_area_nok_paragoomba_idleAnimations_8021B384 = 0x8021b384
export const RAM_EC9D00_SuperBlock_ShowSelectPartnerMenu = 0x802401cc
export const RAM_D_8023C1B8 = 0x8023c1b8
export const RAM_IES_HappyFlower = 0x8008c884
export const RAM_b_area_sam_sam_01_UnkModelStuffScript3 = 0x8022f0e4
export const RAM_b_area_omo2_general_guy_summon_pos = 0x8021a278
export const RAM_D_80242F30_A66FC0 = 0x80242f30
export const RAM_dgb_01_D_80251BB4_C0F434 = 0x80251bb4
export const RAM_D_802348A8_6BBAA8 = 0x802348a8
export const RAM_HES_Item_SJumpChg_disabled = 0x800840c8
export const RAM_sbk_03_SetNpcB5_3 = 0x80240040
export const RAM_sam_11_Quizmo_CreateStage = 0x80240f40
export const RAM_b_area_omo_omo_03b = 0x8022dcfc
export const RAM_func_80238784_704274 = 0x80238784
export const RAM_b_area_sam_sam_02_beforeBattle = 0x8022f698
export const RAM_ui_battle_menu_pouch_disabled_pal = 0x8028a160
export const RAM_D_E00E4E20 = 0xe00e4e20
export const RAM_D_E009EE20 = 0xe009ee20
export const RAM_b_area_sam_duplighost_watt_parts = 0x802239f8
export const RAM_D_80240E00_946CD0 = 0x80240e00
export const RAM_arn_13_makeEntities = 0x80240c38
export const RAM_dup_jan_05_foliage_setup_shear_mtx = 0x80242450
export const RAM_func_80240370_8B03E0 = 0x80240370
export const RAM_b_area_trd_part_1_bob_omb_idle_8021A7D4 = 0x8021a7d4
export const RAM_tik_17_Pipe_GetPointAheadOfPlayer = 0x80240fac
export const RAM_D_80245848_C76CC8 = 0x80245848
export const RAM_ECAA80_ItemChoice_SelectedItemID = 0x802430bc
export const RAM_iwa_03_CleftAI_LosePlayer = 0x80240cc4
export const RAM_D_802A150C_0073B3DC = 0x802a150c
export const RAM_set_goal_pos_to_part = 0x80269754
export const RAM_b_area_pra2_crystal_bit_takeTurn = 0x802211c0
export const RAM_kzn_07_FlyingNoAttackAI_20 = 0x802420c8
export const RAM_D_80295AC0 = 0x80295ac0
export const RAM_b_area_kmr_part_3_mage_jr_troopa_idleAnimations_8022A61C = 0x8022a61c
export const RAM_pra_36_reflection_render_floor = 0x802406bc
export const RAM_flo_12_func_8024064C_CC147C = 0x8024064c
export const RAM_func_802411AC_94075C = 0x802411ac
export const RAM_dgb_02_WanderMeleeAI_Main = 0x8024061c
export const RAM_mac_00_Quizmo_DestroyEffects = 0x80240fb8
export const RAM_radial_shimmer_render = 0xe006672c
export const RAM_HES_PartnerRank1B = 0x801092c0
export const RAM_ui_battle_status_transparent_1_pal = 0x80102e80
export const RAM_dgb_06_pad_834 = 0x80240834
export const RAM_func_802A2910 = 0x802a2910
export const RAM_init_script_list = 0x802c32ec
export const RAM_b_area_trd_part_2_fake_bowser_statusTable_8021A690 = 0x8021a690
export const RAM_has_full_fp = 0x800ea37c
export const RAM_b_area_kzn_spike_top_spikeTop_FallDown = 0x8021cc8c
export const RAM_D_80099E40 = 0x80099e40
export const RAM_IES_1DC210 = 0x8008bb64
export const RAM_kmr_12_GoombaIdle = 0x80240450
export const RAM_D_80225FD0_48D080 = 0x80225fd0
export const RAM_vtx_C23DD0 = 0x80202e90
export const RAM_kpa_11_set_script_owner_npc_col_height = 0x80240624
export const RAM_b_area_jan_hurt_plant_UnkBattleFunc1 = 0x802180d0
export const RAM_b_area_sam_duplighost_sushie_idle = 0x80224984
export const RAM_Entity_HitGroundedYellowBlock = 0x802ea618
export const RAM_action_command_whirlwind_draw_hud_elements = 0x802a9744
export const RAM_sbk_05_SetNpcB5_3 = 0x80240040
export const RAM_D_8014C368 = 0x8014c368
export const RAM_D_80244CB8_DB20B8 = 0x80244cb8
export const RAM_mac_03_GetNpcCollisionHeight = 0x80241544
export const RAM_nuAuDmaMesgBuf = 0x800a3658
export const RAM_pra_35_PatrolAI_LosePlayer = 0x8024198c
export const RAM_mac_02_PatrolNoAttackAI_15 = 0x80241404
export const RAM_vtx_C211F0 = 0x802002b0
export const RAM_omo_07_UnsetCamera0Flag1000 = 0x80242e8c
export const RAM_dgb_06_CheckItemFlags40 = 0x80240268
export const RAM_b_area_pra3_duplighost_lakilester = 0x80225358
export const RAM_tik_10_SuperBlock_SetOverride40 = 0x80240680
export const RAM_update_scripts = 0x802c3d74
export const RAM_tik_14_Pipe_GetEntryPos = 0x802400f0
export const RAM_D_80257DA4 = 0x80257da4
export const RAM_dgb_15_80242058 = 0x80242058
export const RAM_au_mseq_player_stop = 0x800521e8
export const RAM_func_E00EA664 = 0xe00ea664
export const RAM_b_area_kmr_part_3_kmr_03_beforeBattle_802326B8 = 0x802326b8
export const RAM_battle_star_star_storm_UnkStarFunc3 = 0x802a116c
export const RAM_D_E00B4CF0 = 0xe00b4cf0
export const RAM_D_E0086AC4 = 0xe0086ac4
export const RAM_D_80098540 = 0x80098540
export const RAM_D_8024BD70_A26E70 = 0x8024bd70
export const RAM_func_802417EC_A3ACCC = 0x802417ec
export const RAM_b_area_omo2_6_shy_guy_dup_init = 0x80231280
export const RAM_D_802AD006 = 0x802ad006
export const RAM_D_09000C00_350E20 = 0x09000c00
export const RAM_b_area_trd_part_1_trd_04_802241AC = 0x802241ac
export const RAM_D_80249A94_A33CD4 = 0x80249a94
export const RAM_dup2_kmr_20_Pipe_GetPointAheadOfPlayer = 0x80241f8c
export const RAM_b_area_isk_part_2_isk_06b_afterBattle_8022034C = 0x8022034c
export const RAM_D_090000E0_32ED30 = 0x090000e0
export const RAM_trd_00_UpdateTexturePanSmooth = 0x80240000
export const RAM_arn_03_idle_80242064 = 0x80242064
export const RAM_ui_ok_png = 0x800f9f00
export const RAM_CamPreset_H = 0x80280d74
export const RAM_snd_set_song_variation = 0x80055cc4
export const RAM_D_E00D6EF0 = 0xe00d6ef0
export const RAM_b_area_kmr_part_3_jr_troopa_statusTable_80222A38 = 0x80222a38
export const RAM_b_area_kmr_part_1_kmr_06 = 0x80220ecc
export const RAM_NAMESPACE_8023A4BC = 0x8023a4bc
export const RAM_D_E004AEC8 = 0xe004aec8
export const RAM_nok_01_StationaryAI_Idle = 0x802400b4
export const RAM_dgb_16_npcAI_80241978 = 0x80241978
export const RAM_GetBattleVar = 0x8026f694
export const RAM_ui_coin_sparkle_3_png = 0x800fbd40
export const RAM_D_802EB2A0 = 0x802eb2a0
export const RAM_D_8010C958 = 0x8010c958
export const RAM_D_E00DA500 = 0xe00da500
export const RAM_D_8024C030_C098B0 = 0x8024c030
export const RAM_dup_kkj_11_UnkModelMatrixFunc = 0x80240ab0
export const RAM_IES_Koopasta = 0x8008d074
export const RAM_dgb_05_npcSettings_80241A4C = 0x80241a4c
export const RAM_D_80294220 = 0x80294220
export const RAM_D_09000E00_351020 = 0x09000e00
export const RAM_IES_ApplePie = 0x8008cff4
export const RAM_iwa_01_MontyMoleAI_Main = 0x80241d70
export const RAM_dup_b_area_tik3_UnkFunc56 = 0x802187b0
export const RAM_EE70C0_FlyingAI_Chase = 0x80241904
export const RAM_iwa_01_CleftAI_Main = 0x80242f54
export const RAM_NAMESPACE_statusTable_80238688 = 0x80238688
export const RAM_shimmer_wave_init = 0xe00741e0
export const RAM_D_E005A760 = 0xe005a760
export const RAM_static_status_main = 0xe00e6000
export const RAM_D_80150958 = 0x80150958
export const RAM_func_802A9990 = 0x802a9990
export const RAM_D_E0084E38 = 0xe0084e38
export const RAM_D_E00E4E28 = 0xe00e4e28
export const RAM_D_E009EE28 = 0xe009ee28
export const RAM_nok_02_PatrolNoAttackAI_Main = 0x80241614
export const RAM_b_area_pra3_duplighost_statusTable = 0x80219f80
export const RAM_SetEnemyFlagBits = 0x80045688
export const RAM_nok_01_ToadHouse_UpdateScreenOverlay = 0x80240ab4
export const RAM_D_802EB088 = 0x802eb088
export const RAM_spr_get_npc_color_variations = 0x802dea6c
export const RAM_D_802519A0_C0F220 = 0x802519a0
export const RAM_spr_clear_quad_cache = 0x802dbfc0
export const RAM_sam_05_PiranhaPlantAI_11 = 0x80241478
export const RAM_apply_transform_to_children = 0x802c95a0
export const RAM_gPauseWS_22 = 0x8026fdd0
export const RAM_action_update_peach_land = 0x802b62cc
export const RAM_flo_14_func_802403D4_CCB6E4 = 0x802403d4
export const RAM_sam_08_SuperBlock_ShowSelectPartnerMenu = 0x802401cc
export const RAM_flo_14_main = 0x802426bc
export const RAM_dro_01_80244A28 = 0x80244a28
export const RAM_nok_01_Quizmo_SetVannaAnim_Idle = 0x80241858
export const RAM_flo_03_npcGroup_8024388C = 0x8024388c
export const RAM_func_8024055C_BD3DCC = 0x8024055c
export const RAM_HES_Item_Coconut_disabled = 0x80086e88
export const RAM_jan_05_HoppingAI_Hop = 0x80240ad4
export const RAM_HES_BButtonHeld = 0x80292348
export const RAM_initialize_next_camera = 0x8002e16c
export const RAM_func_80240030_B150E0 = 0x80240030
export const RAM_D_8024EA10 = 0x8024ea10
export const RAM_b_area_arn_hypergoomba_statusTable_8021877C = 0x8021877c
export const RAM_D_80247808_B3C3D8 = 0x80247808
export const RAM_fright_jar_update = 0xe00c212c
export const RAM_omo_02_StationaryAI_ReturnHome = 0x802414c8
export const RAM_thunderbolt_ring_main = 0xe00b0000
export const RAM_dgb_01_npcSettings_802452E8 = 0x802452e8
export const RAM_b_area_pra2_stageTable = 0x802215c8
export const RAM_tik_04_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_802A3B28_007370C8 = 0x802a3b28
export const RAM_BattleCam_InitialBoomZOffset = 0x8029f298
export const RAM_func_80240000_B1D760 = 0x80240000
export const RAM_dro_01_D_80248088_963288 = 0x80248088
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021B3DC = 0x8021cdcc
export const RAM_mim_02_HoppingAI_Main = 0x80240c60
export const RAM_D_E004C67D = 0xe004c67d
export const RAM_HES_Partner0 = 0x80107ca8
export const RAM_D_8023CD80_6F5E60 = 0x8023cd80
export const RAM_b_area_kmr_part_1_formation_01 = 0x802191fc
export const RAM_D_802456C0_C4C2A0 = 0x802456c0
export const RAM_func_802694A4 = 0x802694a4
export const RAM_tik_23_UnkDistFunc = 0x802409dc
export const RAM_func_802BD558_319AC8 = 0x802bd558
export const RAM_D_80263420_C20CA0 = 0x80263420
export const RAM_stars_burst_render = 0xe0042374
export const RAM_D_00E38890 = 0x00e38890
export const RAM_b_area_isk_part_2_isk_00_afterBattle_8021FD2C = 0x8021fd2c
export const RAM_kzn_23_UnsetCamera0MoveFlag1 = 0x80240504
export const RAM_b_area_omo_pyro_guy_partsTable_8022CC34 = 0x8022cc34
export const RAM_update_messages = 0x801237d8
export const RAM_func_80240434_A16654 = 0x80240434
export const RAM_btl_state_update_partner_move = 0x80246b34
export const RAM_b_area_kmr_part_3_paragoomba2_partsTable_8021B280 = 0x8021f8c0
export const RAM_flo_08_settings = 0x80241f00
export const RAM_pra_13_SetPartnerFlags80000 = 0x80240eb8
export const RAM_kkj_25_GetEntityPosition = 0x802401c4
export const RAM_func_80240B00_C8E6B0 = 0x80240b00
export const RAM_func_E006A000 = 0xe006a000
export const RAM_D_802439F0_A6AAF0 = 0x802439f0
export const RAM_func_802BE014_31DB84 = 0x802be014
export const RAM_HES_Item_CakeDone = 0x80080520
export const RAM_jan_06_WanderMeleeAI_Main = 0x80241e38
export const RAM_D_80286530 = 0x80286530
export const RAM_D_8024C090 = 0x8024c090
export const RAM_b_area_omo_anti_guy_UnkBattleFunc1 = 0x80218410
export const RAM_D_E00D6EF8 = 0xe00d6ef8
export const RAM_arn_03_pad_1BB8 = 0x80241bb8
export const RAM_MerleeStopFX = 0x8003f384
export const RAM_b_area_trd_part_1_trd_02d_beforeBattle_80225364 = 0x80225364
export const RAM_D_80249A84_A33CC4 = 0x80249a84
export const RAM_omo_17_FlyingAI_WanderInit = 0x80240fb4
export const RAM_b_area_isk_part_1_stone_chomp_8022181C = 0x8022181c
export const RAM_func_80241BA8_991388 = 0x80241ba8
export const RAM_flo_00_init_80243BE4 = 0x80243be4
export const RAM_kpa_102_set_script_owner_npc_col_height = 0x80241870
export const RAM_HES_SmallText1 = 0x8024211c
export const RAM_func_8024030C_C7E48C = 0x8024030c
export const RAM_b_area_arn_arn_02_80227960 = 0x80227960
export const RAM_b_area_sam_sam_01_Set80071270_0_16 = 0x80219da8
export const RAM_HES_JpFileDisabled = 0x80242300
export const RAM_D_8024F378 = 0x8024f378
export const RAM_func_8024030C_EB543C = 0x8024030c
export const RAM_b_area_omo2_toy_tank_formation_signal_guy = 0x8022f554
export const RAM_au_SEFCmd_0E = 0x8004d23c
export const RAM_b_area_jan_jan_00_80226B00 = 0x80226b00
export const RAM_ui_status_coin_9_png = 0x801010a0
export const RAM_b_area_sam_sam_02b_UnkFloatFunc2 = 0x8021a40c
export const RAM_fx_drop_leaves = 0x80070010
export const RAM_b_area_kmr_part_1_formation_09 = 0x8021942c
export const RAM_IES_FirePop = 0x8008d374
export const RAM_tik_06_Pipe_AwaitDownInput = 0x80240034
export const RAM_func_8025A74C = 0x8025a74c
export const RAM_ui_status_coin_5_pal = 0x80100ea0
export const RAM_b_area_kpa2_RemoveChillOut = 0x8021c11c
export const RAM_dup_kkj_16_UnkModelMatrixFunc2 = 0x80240b0c
export const RAM_D_80097DE8 = 0x80097de8
export const RAM_crash_screen_sleep = 0x8002bf40
export const RAM_b_area_trd_part_1_formation_00 = 0x80218490
export const RAM_MAMAR_out_segmentReadPos = 0x804204c0
export const RAM_HES_Item_POWBlock_disabled = 0x800876c8
export const RAM_DoDizzyAttack = 0x8028907c
export const RAM_partner_buff_render = 0xe011a398
export const RAM_PollMusicEvents = 0x802d5b10
export const RAM_nok_15_Pipe_GetEntryPos = 0x802400f0
export const RAM_gPausePartnersPaletteBuffers = 0x8024f744
export const RAM_load_player_actor = 0x802644a4
export const RAM_D_E00628E8 = 0xe00628e8
export const RAM_osViSetMode = 0x80066e10
export const RAM_func_80240000_90F6B0 = 0x80240000
export const RAM_func_8025597C = 0x8025597c
export const RAM_D_E005C700 = 0xe005c700
export const RAM_disable_x_init = 0xe00821b0
export const RAM_load_font_data = 0x80123708
export const RAM_dro_02_D_802475E0_9707A0 = 0x802475e0
export const RAM_D_80243178 = 0x80243178
export const RAM_ForceNextTarget = 0x80298bf0
export const RAM_flo_07_8024086C = 0x8024086c
export const RAM_b_area_kmr_part_3_mim_01_UnkFogFunc2 = 0x802199e0
export const RAM_func_802A4718 = 0x802a4718
export const RAM_mac_05_Quizmo_AudienceEffect = 0x802529f4
export const RAM_dro_01_shakeTree = 0x8024d720
export const RAM_dro_01_LetterDelivery_Init = 0x80242d98
export const RAM_iwa_01_MontyMoleAI_PreSurface = 0x8024192c
export const RAM_func_802B6000_E29470 = 0x802b6000
export const RAM_b_area_sam_duplighost_ActorJumpToPos = 0x80218890
export const RAM_D_8025B2AC = 0x8025b2ac
export const RAM_D_E00E4D90 = 0xe00e4d90
export const RAM_D_09001E40 = 0x09001e40
export const RAM_b_area_kmr_part_3_paragoomba2_idle_8021CBE0 = 0x80221220
export const RAM_fx_energy_in_out = 0x800720b0
export const RAM_func_80240190_BDA940 = 0x80240190
export const RAM_func_80244288_9DB2A8 = 0x80244288
export const RAM_kmr_23_settings = 0x02800180
export const RAM_arn_08_8024049C = 0x8024049c
export const RAM_pra_35_MeleeHitbox_33 = 0x80241f64
export const RAM_HES_Item_DodgeMaster_disabled = 0x80083b28
export const RAM_mim_01_PiranhaPlantAI_00 = 0x80240dd0
export const RAM_func_8025AD90 = 0x8025ad90
export const RAM_action_command_mega_shock_free_hud_elements = 0x802a98e0
export const RAM_au_bgm_get_random_pan = 0x80050568
export const RAM_dro_02_interact_8024B8F0 = 0x8024b8f0
export const RAM_D_80077710 = 0x80077710
export const RAM_SushieTweesterPhysics = 0x802bff10
export const RAM_b_area_sam_duplighost_bow_defenseTable = 0x80222568
export const RAM_func_80240478_C9C778 = 0x80240478
export const RAM_b_area_sam_gulpit_rocks_defenseTable = 0x802294e8
export const RAM_tik_15_Pipe_SetAnimFlag = 0x80240000
export const RAM_HES_StatusStarEmpty = 0x801084f0
export const RAM_fire_breath_init = 0xe006e354
export const RAM_tik_01_SetEntityPosition = 0x80240960
export const RAM_pra_09_SetPartnerFlags20000 = 0x80240eec
export const RAM_b_area_kzn_formation_14 = 0x80229e04
export const RAM_ui_partner10_pal = 0x800ffc60
export const RAM_au_song_start_variation = 0x80055970
export const RAM_func_802A11B0 = 0x802a11b0
export const RAM_nok_14_FlyingAI_JumpInit = 0x80241570
export const RAM_D_8024BA90 = 0x8024ba90
export const RAM_D_E00147A8 = 0xe00147a8
export const RAM_D_E00A86A0 = 0xe00a86a0
export const RAM_D_8024F1F8_C0CA78 = 0x8024f1f8
export const RAM_D_80295EC4 = 0x80295ec4
export const RAM_func_80242730_C5CE70 = 0x80242730
export const RAM_trd_02_KoopaPatrolAI_Main = 0x80241744
export const RAM_tik_07_FlyingAI_LosePlayer = 0x80242248
export const RAM_mac_05_LetterDelivery_RestoreNpcAnim = 0x80242a64
export const RAM_dgb_18_PatrolAI_JumpInit = 0x80240730
export const RAM_battle_move_jump_charge_2_UnkMoveFunc3 = 0x802a1228
export const RAM_D_80099BC0 = 0x80099bc0
export const RAM_b_area_trd_part_2_trd_02b_beforeBattle_802323A4 = 0x802323a4
export const RAM_mac_02_MonitorMusicProximityTrigger = 0x802433f0
export const RAM_b_area_sam_duplighost_lakilester = 0x802262d8
export const RAM_D_80242BD8_B2F048 = 0x80242bd8
export const RAM_fx_floating_flower = 0x8006fd70
export const RAM_b_area_sam_duplighost_init = 0x8021aff8
export const RAM_func_802BD99C_3214EC = 0x802bd99c
export const RAM_FindKeyItem = 0x802d6a14
export const RAM_func_E008A290 = 0xe008a290
export const RAM_update_animated_models = 0x802ccac0
export const RAM_D_80243600_ACE120 = 0x80243600
export const RAM_D_8023C1B0 = 0x8023c1b0
export const RAM_D_8010D691 = 0x8010d691
export const RAM_D_8010D654 = 0x8010d654
export const RAM_sbk_30_LetterDelivery_SavedNpcAnim = 0x80244d88
export const RAM_D_8024A290 = 0x8024a290
export const RAM_sam_08_SuperBlock_CantUpgradeMessages = 0x80241ae0
export const RAM_hos_02_FlyingAI_JumpInit = 0x80241bcc
export const RAM_evt_handle_kill = 0x802c6870
export const RAM_isk_13_StoneChompFXA = 0x80241108
export const RAM_D_80242568_filemenu = 0x80242568
export const RAM_D_802453C0_EF79D0 = 0x802453c0
export const RAM_battle_move_auto_multibounce_CheckForAPress = 0x802a1130
export const RAM_func_80243D60_8045E0 = 0x80243d60
export const RAM_D_802E0C68 = 0x802e0c68
export const RAM_D_8023D26C = 0x8023d26c
export const RAM_func_802B7140 = 0x802b7140
export const RAM_battle_star_time_out_UnkStarFunc = 0x802a1494
export const RAM_flo_03_varStash = 0x80240f08
export const RAM_jan_05_SpearGuyAI_Main = 0x8024219c
export const RAM_arn_03_PatrolAI_Loiter = 0x802404c0
export const RAM_filemenu_update_select_file = 0x80243908
export const RAM_b_area_sam_sam_02d_beforeBattle = 0x80230198
export const RAM_b_area_hos_magikoopa_defenseTable_8021D480 = 0x8021d480
export const RAM_hos_02_FlyingAI_ChaseInit = 0x80241cb0
export const RAM_hud_element_set_flags = 0x80144a00
export const RAM_func_802407A0_BCD800 = 0x802407a0
export const RAM_b_area_mim_forest_fuzzy_idleAnimations_802184C4 = 0x802184c4
export const RAM_func_8004D484 = 0x8004d484
export const RAM_hos_03_ToadHouse_AwaitScriptComplete = 0x802422ec
export const RAM_func_802411D8_D657B8 = 0x802411d8
export const RAM_arn_04_exitWalk_802434DC = 0x802434dc
export const RAM_dgb_10_80240F20 = 0x80240f20
export const RAM_func_80255FD8 = 0x80255fd8
export const RAM_sbk_56_SuperBlock_AnimateEnergyOrbs = 0x80240680
export const RAM_sam_11_Quizmo_SetStageLightsDelay = 0x8024120c
export const RAM_nok_01_StationaryAI_AlertInit = 0x802401d4
export const RAM_b_area_omo_groove_guy_countActiveSummoners = 0x80226204
export const RAM_sbk_63_SpawnSunEffect = 0x80240000
export const RAM_kpa_maps = 0x80091390
export const RAM_RegainAbility = 0x80289110
export const RAM_b_area_omo2_light_bulb_takeTurn = 0x8022f9c8
export const RAM_omo_03_SetNpcPosYaw = 0x802402b0
export const RAM_func_80240F7C_A3A45C = 0x80240f7c
export const RAM_b_area_iwa_cleft = 0x80218810
export const RAM_btl_state_draw_enemy_striking_first = 0x80248aa4
export const RAM_battle_item_sleepy_sheep_EatItem = 0x802a1e3c
export const RAM_osInvalDCache = 0x80061720
export const RAM_D_E00D2510 = 0xe00d2510
export const RAM_b_area_flo_GetSelectedMoveID = 0x80218a28
export const RAM_dro_02_ItemChoice_SelectedItemID = 0x80248784
export const RAM_b_area_trd_part_2_red_ninja_koopa = 0x802285e8
export const RAM_dro_01_Quizmo_StageEffect = 0x8024dfe0
export const RAM_fold_appendGfx_component = 0x8013af70
export const RAM_HES_OptionStereoOff = 0x802423b0
export const RAM_btl_state_draw_celebration = 0x802601bc
export const RAM_b_area_trd_part_2_fake_bowser_defenseTable_8021A670 = 0x8021a670
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021DB9C = 0x8021db9c
export const RAM_D_80245400_CD7230 = 0x80245400
export const RAM_SetPlayerSpeed = 0x802d1024
export const RAM_evt_trigger_on_activate_lock = 0x802c6ad0
export const RAM_func_8013EE68 = 0x8013ee68
export const RAM_D_802B7C80_E224F0 = 0x802b7c80
export const RAM_ui_status_text_9_pal = 0x801005a0
export const RAM_b_area_sbk_stageTable = 0x8021ffe8
export const RAM_omo_07_ShyGuyWanderAI_17 = 0x802401d8
export const RAM_dgb_08_entryList = 0x80243c90
export const RAM_func_80112DD4 = 0x80112dd4
export const RAM_b_area_kmr_part_2_kmr_03_clouds2 = 0x80224858
export const RAM_flo_03_TransformFoliage = 0x80240460
export const RAM_D_09000840_3AB870 = 0x09000840
export const RAM_mac_01_MonitorMusicProximityTrigger = 0x80240f50
export const RAM_battle_item_stop_watch_PlayerGoHome = 0x802a1790
export const RAM_D_800B4314 = 0x800b4314
export const RAM_vtx_C2E5D0 = 0x8020d690
export const RAM_WorldMerleeWaveEffect = 0x800a0bac
export const RAM_dgb_01_D_802526B4_C0FF34 = 0x802526b4
export const RAM_omo_05_FlyingAI_Loiter = 0x802412ec
export const RAM_b_area_arn_hypergoomba_802189D4 = 0x802189d4
export const RAM_dispatch_damage_event_player = 0x802731bc
export const RAM_flo_25_tree1 = 0x80244698
export const RAM_kmr_02_CheckPartnerFlags1000 = 0x80243640
export const RAM_BowUpdate = 0x802bd168
export const RAM_D_80251A50_C0F2D0 = 0x80251a50
export const RAM_vtx_C2AEB0 = 0x80209f70
export const RAM_dro_01_settings = 0x80243b70
export const RAM_pra_01_UnkFogFunc = 0x80240f20
export const RAM_VirtualEntityJumpTo = 0x802d3c58
export const RAM_shop_update_item_select_popup = 0x80280ac4
export const RAM_func_802403B8_B1CE08 = 0x802403b8
export const RAM_entity_base_switch_init = 0x802e234c
export const RAM_b_area_trd_part_1_paratroopa_takeTurn_80221DAC = 0x80221dac
export const RAM_D_8029FB94 = 0x8029fb94
export const RAM_func_80243BEC_9DAC0C = 0x80243bec
export const RAM_pra_05_ReflectPartner = 0x80240d3c
export const RAM_tik_15_UnkPosFunc2 = 0x802405f0
export const RAM_obk_01_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_vtx_C2E1E0 = 0x8020d2a0
export const RAM_b_area_nok_nok_01_afterBattle_8022B7C0 = 0x8022b7c0
export const RAM_kzn_11_FlyingNoAttackAI_12 = 0x80241958
export const RAM_hos_03_ToadHouse_UpdateScreenOverlay = 0x802421f0
export const RAM_dgb_01_D_80252210_C0FA90 = 0x80252210
export const RAM_btl_update_ko_status = 0x8026777c
export const RAM_vtx_C21DD0 = 0x80200e90
export const RAM_dead_get_clamped_angle_diff = 0x8002af18
export const RAM_func_8025B5C0 = 0x8025b5c0
export const RAM_D_8024F3C0_C0CC40 = 0x8024f3c0
export const RAM_D_E008CB48 = 0xe008cb48
export const RAM_func_802C0358 = 0x802bd458
export const RAM_arn_03_80241C6C = 0x80241c6c
export const RAM_dgb_12_SomeItemEntityFunc = 0x80240118
export const RAM_func_802A1518_79C4B8 = 0x802a1518
export const RAM_b_area_sam_sam_01_UnkModelStuff_array = 0x8022efd0
export const RAM_b_area_hos_magikoopa_specialFormation_802216E4 = 0x802216e4
export const RAM_gPauseTabsWindowBPs = 0x8024f1e8
export const RAM_dro_01_GetFloorCollider = 0x8024309c
export const RAM_gPauseTutorialButtons = 0x8024f020
export const RAM_b_area_trd_part_2_green_ninja_koopa_GetLastActorEventType = 0x80218b48
export const RAM_b_area_sam2_sam_02d_foregroundModelList = 0x80223044
export const RAM_kpa_102_MagikoopaAI_CanShootSpell = 0x80241d30
export const RAM_b_area_mac_chan_handleEvent_8021AAB8 = 0x8021aab8
export const RAM_D_8014AFB4 = 0x8014afb4
export const RAM_ui_box_bg_tile_png = 0x8010b460
export const RAM_vtx_C25AB0 = 0x80204b70
export const RAM_ED0310_FlyingAI_ChaseInit = 0x80240fe0
export const RAM_isk_10_SuperBlock_RadiateFaintEnergyFX = 0x802405a8
export const RAM_IES_TastyTonic = 0x8008d874
export const RAM_PlayerScriptDispatcher = 0x80284ebc
export const RAM_kzn_02_LetterDelivery_CalcLetterPos = 0x80242528
export const RAM_D_802441A8_CB91A8 = 0x802441a8
export const RAM_D_802BE928 = 0x802be928
export const RAM_confetti_main = 0xe008817c
export const RAM_battle_star_refresh_802A21A0 = 0x802a21a0
export const RAM_crash_screen_copy_to_buf = 0x8002c1d4
export const RAM_D_E00EAA68 = 0xe00eaa68
export const RAM_wPartnerMoveGoalX = 0x800f8024
export const RAM_dead_fx_energy_orb_wave = 0x800769d0
export const RAM_arn_04_padding2 = 0x8024403c
export const RAM_arn_04_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_gPauseCursorIconID = 0x802700d0
export const RAM_D_80247DF0 = 0x80247df0
export const RAM_func_802428E0_C635F0 = 0x802428e0
export const RAM_kpa_111_RangedAttackAI_Main = 0x80241064
export const RAM_dro_01_PatrolAI_Chase = 0x80240f2c
export const RAM_D_090018A0_3AC8D0 = 0x090018a0
export const RAM_battle_move_hammer_charge_2_IsHammerMaxCharged = 0x802a10a4
export const RAM_D_09002000 = 0x09002000
export const RAM_mim_04_HoppingAI_Main = 0x80240df0
export const RAM_mac_04_Quizmo_ShouldQuizmoLeave = 0x80240f9c
export const RAM_dgb_02_pad_1AA8 = 0x80241aa8
export const RAM_world_bombette_pre_battle = 0x802be6e8
export const RAM_D_800F7FF0 = 0x800f7ff0
export const RAM_nok_14_ParatroopaAI_Windup = 0x80241e8c
export const RAM_D_80150718 = 0x80150718
export const RAM_D_80078DD0 = 0x80078dd0
export const RAM_battle_item_shooting_star_EatItem = 0x802a1a1c
export const RAM_nok_02_LetterDelivery_SaveNpcAnim = 0x80242f74
export const RAM_D_09002868 = 0x09002868
export const RAM_D_802B6E60_E2B510 = 0x802b6e60
export const RAM_func_802A10B8 = 0x802a10b8
export const RAM_b_area_pra3_swoopula_UnkBattleFunc1 = 0x802195f0
export const RAM_water_splash_update = 0xe00be3a4
export const RAM_snd_adjust_sound_with_shift = 0x80055330
export const RAM_kpa_95_PatrolAI_Move = 0x80240238
export const RAM_btl_popup_messages_init = 0x8024edc0
export const RAM_IES_BooWeight = 0x8008b6e4
export const RAM_func_8024030C_A1652C = 0x8024030c
export const RAM_b_area_kmr_part_1_formation_0A = 0x80219480
export const RAM_sbk_22_Bandit_DropCoin = 0x80240040
export const RAM_D_8029F250 = 0x8029f250
export const RAM_func_80050E84 = 0x80050e84
export const RAM_flo_12_80241B6C = 0x80241b6c
export const RAM_b_area_omo_green_shy_guy_takeTurn_802218B4 = 0x802218b4
export const RAM_jan_02_Quizmo_UnkStageEffectMode = 0x80242084
export const RAM_HES_Item_DefendPlus_disabled = 0x80084728
export const RAM_dro_02_Quizmo_HideEntities = 0x80240418
export const RAM_trd_01_StationaryAI_IdleInit = 0x80240bc4
export const RAM_D_80232A50_6847D0 = 0x80232a50
export const RAM_b_area_mac_lee_8021E118 = 0x8021e118
export const RAM_D_8014BDB0 = 0x8014bdb0
export const RAM_snowflake_render = 0xe001c200
export const RAM_D_802442D0 = 0x802442d0
export const RAM_dead_clamp_angle = 0x8002ae14
export const RAM_func_802400D0_BC7F30 = 0x802400d0
export const RAM_func_E011A48C = 0xe011a48c
export const RAM_b_area_kmr_part_3_mage_jr_troopa_defenseTable_8022A628 = 0x8022a628
export const RAM_D_E00E8634 = 0xe00e8634
export const RAM_D_8010D684 = 0x8010d684
export const RAM_AddActorDecoration = 0x8026ee88
export const RAM_dgb_16_npcGroup_802429CC = 0x802429cc
export const RAM_D_8029F2A7 = 0x8029f2a7
export const RAM_nok_02_Quizmo_StageEffect = 0x802549c0
export const RAM_vtx_C27D60 = 0x80206e20
export const RAM_mainmenu_draw_rect = 0x80242ba0
export const RAM_pra_38_reflection_setup_wall = 0x80240158
export const RAM_D_09000CC0_3270D0 = 0x09000cc0
export const RAM_func_802A133C_761C5C = 0x802a133c
export const RAM_flo_23_D_8024284C_pad = 0x8024284c
export const RAM_D_800A3FD4 = 0x800a3fd4
export const RAM_state_drawUI_intro = 0x80036de0
export const RAM_fx_cloud_trail = 0x8006fcb0
export const RAM_IES_ToadDoll = 0x8008b944
export const RAM_D_800DC06C = 0x800dc06c
export const RAM_b_area_trd_part_2_fake_bowser_init_8021A968 = 0x8021a968
export const RAM_dro_02_ItemChoice_HasSelectedItem = 0x80248780
export const RAM_kpa_15_UpdateTexturePanSmooth = 0x80240000
export const RAM_dgb_03_exitSingleDoor_80242B88 = 0x80242b88
export const RAM_btl_draw_ui = 0x8023ea7c
export const RAM_HES_Item_DriedPasta = 0x80086bb8
export const RAM_disable_npc_shadow = 0x8003abfc
export const RAM_mac_05_Quizmo_UpdatePartnerPosition = 0x80242230
export const RAM_sam_02_ToadHouse_UpdateScreenOverlay = 0x80240878
export const RAM_func_802BF4F0_323040 = 0x802bf4f0
export const RAM_pra_37_MeleeHitbox_32 = 0x8024102c
export const RAM_trd_07_ParatroopaAI_Windup = 0x80241fdc
export const RAM_memset = 0x8006edb0
export const RAM_gPauseMsg_18 = 0x8026f648
export const RAM_flo_09_entryList = 0x80241820
export const RAM_D_80246548_C8BFF8 = 0x80246548
export const RAM_func_8025E044 = 0x8025e044
export const RAM_func_80219960_5EDDF0 = 0x80219960
export const RAM_D_E003A830 = 0xe003a830
export const RAM_b_area_trd_part_1_paratroopa_idle_80220D40 = 0x80220d40
export const RAM_D_E020072C = 0xe020072c
export const RAM_MerleeUpdateFX = 0x8003f0c4
export const RAM_tik_09_set_script_owner_npc_anim = 0x80240a30
export const RAM_b_area_trd_part_2_blue_ninja_koopa_defenseTable_8022B4A0 = 0x8022b4a0
export const RAM_free_entity_model_by_index = 0x80122dfc
export const RAM_gather_magic_init = 0xe008e1fc
export const RAM_func_80242934_C63644 = 0x80242934
export const RAM_kmr_02_ToadHouse_InitScreenOverlay = 0x80243284
export const RAM_arn_09_802404D0 = 0x802404d0
export const RAM_tik_15_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_HES_SmallText9 = 0x8024227c
export const RAM_D_80244348_BE7AF8 = 0x80244348
export const RAM_dro_01_toggleVis_8024BFDC = 0x8024bfdc
export const RAM_flo_08_SuperBlock_ShowUpgradeEffects = 0x80242014
export const RAM_b_area_sam2_formation_01 = 0x8022349c
export const RAM_D_802A3838 = 0x802a3838
export const RAM_mac_03_Quizmo_GetCamVfov = 0x80241090
export const RAM_mim_08_MeleeHitbox_31 = 0x80241604
export const RAM_BattleFadeOutMerlee = 0x802616b4
export const RAM_general_heap_create = 0x8002ac00
export const RAM_D_8024C108 = 0x8024c108
export const RAM_HES_TimingReady = 0x80292788
export const RAM_D_8024BEC0_C09740 = 0x8024bec0
export const RAM_nok_11_FlyingAI_LosePlayer = 0x8024162c
export const RAM_HES_Item_HotShroom_disabled = 0x80082b68
export const RAM_func_802A9000_422AD0 = 0x802a9000
export const RAM_tik_07_SuperBlock_CantUpgradeMessages = 0x802439f0
export const RAM_sbk_56_SuperBlock_CantUpgradeMessages = 0x80241430
export const RAM_ai_enemy_play_sound = 0x800494c0
export const RAM_D_09000900_331800 = 0x09000900
export const RAM_pra_27_reflection_render_wall = 0x80240320
export const RAM_b_area_mac_lee = 0x8021d4a0
export const RAM_au_fx_param_hdl = 0x800598a0
export const RAM_pause_tabs_handle_input = 0x80245354
export const RAM_D_8024DFD8 = 0x8024dfd8
export const RAM_pause_partners_draw_title = 0x8024bbac
export const RAM_lil_oink_main = 0xe0114000
export const RAM_D_800B0CF4 = 0x800b0cf4
export const RAM_appendGfx_msg_prim_rect = 0x8012ca08
export const RAM_b_area_omo_spy_guy_idleAnimations_8022744C = 0x8022744c
export const RAM_func_8024114C_D6572C = 0x8024114c
export const RAM_battle_star_chill_out_UnkBackgroundFunc = 0x802a1414
export const RAM_kpa_50_ProjectileHitbox_31 = 0x80240434
export const RAM_D_800A095B = 0x800a095b
export const RAM_func_8024033C_A1B43C = 0x8024033c
export const RAM_HES_Item_DojoCard5 = 0x80081f98
export const RAM_DoShrinkHit = 0x802946b8
export const RAM_D_80077A50 = 0x80077a50
export const RAM_GetAnimatedNodeRotation = 0x802cd6e0
export const RAM_omo_11_SuperBlock_PartnerSparkles4 = 0x80241450
export const RAM_D_802BCFB8_E2D6E8 = 0x802bcfb8
export const RAM_func_8004572C = 0x8004572c
export const RAM_D_8024F590 = 0x8024f590
export const RAM_dgb_00_80240CB8 = 0x80240cb8
export const RAM_D_80241880_D7CB20 = 0x80241880
export const RAM_D_80231410_63CFB0 = 0x80231410
export const RAM_embers_init = 0xe00e01d0
export const RAM_func_80057C04 = 0x80057c04
export const RAM_func_80240050_BC7EB0 = 0x80240050
export const RAM_battle_item_thunder_rage_func_802A1354_71B4F4 = 0x802a1354
export const RAM_D_80246540_C8BFF0 = 0x80246540
export const RAM_au_bgm_get_player_with_song_name = 0x8004d794
export const RAM_b_area_jan_formation_0F = 0x80228a34
export const RAM_sfx_stop_sound = 0x80149c6c
export const RAM_sam_08_SuperBlock_GetPartnerRank = 0x80240068
export const RAM_flo_18_UpdateTexturePanSmooth = 0x80240000
export const RAM_battle_partner_goombario_handleEvent_8023819C = 0x8023819c
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_idleAnimations_80227F44 = 0x80227f44
export const RAM_b_area_iwa_buzzar_8021BEBC = 0x8021bebc
export const RAM_arn_03_interact_8024254C = 0x8024254c
export const RAM_b_area_isk_part_2_isk_07 = 0x802203f4
export const RAM_nuScEventBroadcast = 0x8005f0c0
export const RAM_b_area_isk_part_2_tutankoopa_8021C748 = 0x8021c748
export const RAM_SetNpcJumpscale = 0x802ce088
export const RAM_D_80247240_C67F50 = 0x80247240
export const RAM_b_area_trd_part_1_trd_00_beforeBattle_80224B70 = 0x80224b70
export const RAM_close_message = 0x80125c70
export const RAM_arn_08_interact_802412E8 = 0x802412e8
export const RAM_func_802619E8 = 0x802619e8
export const RAM_func_802BB298_E2DBC8 = 0x802bb298
export const RAM_sam_09_varStash = 0x802406c0
export const RAM_dup_b_area_tik2_StartRumbleWithParams = 0x80218200
export const RAM_func_802BB3B8_E2FD08 = 0x802bb3b8
export const RAM_mac_05_Quizmo_varStash = 0x802450b0
export const RAM_dro_02_Quizmo_UpdateRecords = 0x802409ec
export const RAM_flo_16_SuperBlock_PartnerSparkles2 = 0x80240d58
export const RAM_D_8015C7E0 = 0x8015c7e0
export const RAM_flo_00_PatrolAI_LosePlayer = 0x80240aac
export const RAM_HES_CRightButton = 0x802924cc
export const RAM_func_8025DEC4 = 0x8025dec4
export const RAM_D_80077774 = 0x80077774
export const RAM_LoadActionCommand = 0x802681c0
export const RAM_nuGfxTask_ptr = 0x8009c510
export const RAM_ui_menu_times_pal = 0x800fc280
export const RAM_D_80224D80 = 0x80224d80
export const RAM_func_802BC1A8_E2C8D8 = 0x802bc1a8
export const RAM_mgm_02_DestroyMinigame = 0x802422d0
export const RAM_IES_LemonCandy = 0x8008d3d4
export const RAM_flo_13_vectorList_80244420 = 0x80244420
export const RAM_ui_klevar_disabled_pal = 0x800fdc60
export const RAM_PushBattleSong = 0x802d6070
export const RAM_kpa_61_FlyingMagikoopaAI_21 = 0x80241af0
export const RAM_battle_item_strange_cake_EatItem = 0x802a207c
export const RAM_dgb_01_D_802524D0_C0FD50 = 0x802524d0
export const RAM_au_SEFCmd_02_SetInstrument = 0x8004cec4
export const RAM_b_area_sam_gray_magikoopa_idleAnimations2 = 0x8022c5ac
export const RAM_D_80241AF8_BDECA8 = 0x80241af8
export const RAM_func_80241BEC_99431C = 0x80241bec
export const RAM_func_8025DA68 = 0x8025da68
export const RAM_b_area_jan_m_bush_handleEvent_80221A74 = 0x80221a74
export const RAM_HPBarToHome = 0x8026d664
export const RAM_b_area_trd_part_1_bob_omb_init_8021A758 = 0x8021a758
export const RAM_func_802410C0_BCFCB0 = 0x802410c0
export const RAM_entity_UltraBlock_init = 0x802e5800
export const RAM_battle_partner_goombario_StopGlowing = 0x8023903c
export const RAM_b_area_pra3_pra_01_afterBattle = 0x80231aec
export const RAM_kmr_10_settings = 0x02800000
export const RAM_draw_entity_model_B = 0x80121eb8
export const RAM_HES_Item_PowerBounce = 0x80083b58
export const RAM_D_80241AF0_BDECA0 = 0x80241af0
export const RAM_func_80055DDC = 0x80055ddc
export const RAM_D_802EB380 = 0x802eb380
export const RAM_EEDF50_FlyingAI_LosePlayer = 0x80241ca8
export const RAM_D_80074230 = 0x80074230
export const RAM_vtx_C29470 = 0x80208530
export const RAM_peach_star_beam_update = 0xe010c198
export const RAM_D_8024F1D8_A39418 = 0x8024f1d8
export const RAM_jan_05_UpdateTexturePanSmooth = 0x80240040
export const RAM_btl_state_update_end_demo_battle = 0x80248b08
export const RAM_sbk_00_entryList = 0x80240060
export const RAM_b_area_mac_master1_8022A4A4 = 0x8022a4a4
export const RAM_b_area_sam_gray_magikoopa_UnkFunc52 = 0x80219ae8
export const RAM_b_area_arn_tubbas_heart_80223FD8 = 0x80223fd8
export const RAM_func_802822A8 = 0x802822a8
export const RAM_FlyingAI_JumpVels = 0x802441dc
export const RAM_flo_14_ConsumableChoiceList = 0x802453b0
export const RAM_D_80241B54_A28A24 = 0x80241b54
export const RAM_func_80218DD0_5CF460 = 0x80218dd0
export const RAM_D_8024F598 = 0x8024f598
export const RAM_HES_StatBoots2 = 0x802428bc
export const RAM_D_E0126BE8 = 0xe0126be8
export const RAM_set_script_flags = 0x802c47e8
export const RAM_flo_13_init_802463E8 = 0x802463e8
export const RAM_world_bombette_take_out = 0x802be880
export const RAM_D_802465C8_915C78 = 0x802465c8
export const RAM_flo_08_ItemChoice_WaitForSelection = 0x80241970
export const RAM_D_80150950 = 0x80150950
export const RAM_flo_23_ItemChoice_WaitForSelection = 0x8024026c
export const RAM_b_area_pra3_duplighost_goombario_tattle = 0x8021d370
export const RAM_pra_37_SetPartnerFlags80000 = 0x80240ee8
export const RAM_HES_Item_SnowmanDoll_disabled = 0x80086168
export const RAM_sam_01_LetterDelivery_Init = 0x80241794
export const RAM_end_01_CreditsDataPtr = 0x8024a2c4
export const RAM_func_8021A318_6A1518 = 0x8021a318
export const RAM_ItemCheckHit = 0x80252d40
export const RAM_snd_add_sfx_output = 0x800535c0
export const RAM_dup2_b_area_pra_StartRumbleWithParams = 0x80218c30
export const RAM_mim_06_PiranhaPlantAI_00 = 0x80240e40
export const RAM_b_area_iwa_buzzar_8021F824 = 0x8021f824
export const RAM_DisablePartner = 0x802d1f38
export const RAM_dgb_05_extraAnimationList_802419A4 = 0x802419a4
export const RAM_D_800AF904 = 0x800af904
export const RAM_mac_05_Quizmo_DestroyEffects = 0x80241ca8
export const RAM_b_area_mim_forest_fuzzy = 0x8021849c
export const RAM_arn_03_802418E8 = 0x802418e8
export const RAM_func_80241C5C_97F72C = 0x80241c5c
export const RAM_EA0C10_PiranhaPlantAI_14 = 0x802419f4
export const RAM_pra_32_StarSpiritEffectFunc6 = 0x80240728
export const RAM_D_8014F574 = 0x8014f574
export const RAM_D_80258E14 = 0x80258e14
export const RAM_dro_02_func_80243068_96C228 = 0x80243068
export const RAM_D_E004C6B0 = 0xe004c6b0
export const RAM_HES_Item_YummyMeal = 0x80082cb8
export const RAM_func_802A1000 = 0x802a1000
export const RAM_kpa_115_ProjectileAI_Reflect = 0x80240c08
export const RAM_b_area_jan_spear_guy_defenseTable_80218670 = 0x80218670
export const RAM_kpa_70_set_script_owner_npc_anim = 0x80240310
export const RAM_flo_03_makeEntities = 0x80244ee0
export const RAM_btl_delete_actor = 0x80240bbc
export const RAM_ui_status_pow_unit_6_pal = 0x80100860
export const RAM_dup5_b_area_pra_UnkBattleFunc1 = 0x80219244
export const RAM_flo_03_interact_802412BC = 0x802412bc
export const RAM_D_80241E98_C3FA88 = 0x80241e98
export const RAM_play_hit_sound = 0x80277004
export const RAM_iwa_10_SuperBlock_PartnerSparkles4 = 0x80240fb0
export const RAM_func_802E2B74 = 0x802e2b74
export const RAM_D_802A9862_42F682 = 0x802a9862
export const RAM_nok_04_UnkFunc41 = 0x80240000
export const RAM_func_80240960_872390 = 0x80240960
export const RAM_status_menu_start_blinking_fp = 0x800e998c
export const RAM_ui_battle_cont_c_right_up_pal = 0x8028bbd8
export const RAM_tik_12_SuperBlock_SetOverride40 = 0x80240c1c
export const RAM_D_802417CC_9091FC = 0x802417cc
export const RAM_D_E0046604 = 0xe0046604
export const RAM_b_area_sam2_gray_magikoopa_parts = 0x8021f2a0
export const RAM_D_80247420 = 0x80247420
export const RAM_virtual_entity_list_render_world = 0x802d3028
export const RAM_IES_MagicalSeed1 = 0x8008b804
export const RAM_b_area_mac_lee_handleEvent_8022460C = 0x8022460c
export const RAM_func_8021ABD8_6A1DD8 = 0x8021abd8
export const RAM_func_800EF3D4 = 0x800ef3d4
export const RAM_D_802B6EF0 = 0x802b6ef0
export const RAM_wSavedPartnerPosX = 0x800f833c
export const RAM_kmr_04_settings = 0x02800040
export const RAM_water_block_main = 0xe00b4000
export const RAM_dro_01_entryList = 0x80243ac0
export const RAM_btl_state_update_begin_partner_turn = 0x80242bb0
export const RAM_shiny_flare_main = 0xe00da000
export const RAM_b_area_sam2_sam_01_UnkModelStuffScript4 = 0x80221fe4
export const RAM_b_area_omo_pyro_guy_statusTable_8022CB88 = 0x8022cb88
export const RAM_b_area_pra3_pra_03b_afterBattle = 0x80231ff0
export const RAM_mac_00_Quizmo_Answers = 0x80245e24
export const RAM_D_800F7FF8 = 0x800f7ff8
export const RAM_kmr_07_StationaryAI_ChaseInit = 0x802402ac
export const RAM_func_80057BDC = 0x80057bdc
export const RAM_gCurrentShadowListPtr = 0x80151658
export const RAM_sbk_55_foliage_setup_shear_mtx = 0x80240060
export const RAM_mac_02_Quizmo_SpinPlayer = 0x802425dc
export const RAM_dgb_08_unk_80244100 = 0x802469f0
export const RAM_kpa_113_func_80240814_97BE44 = 0x80240984
export const RAM_entity_HiddenPanel_idle = 0x802e63a8
export const RAM_b_area_hos_magikoopa_flying = 0x8021d6a8
export const RAM_fx_whirlwind = 0x80071f30
export const RAM_IES_CakeMixed = 0x8008b1cc
export const RAM_tik_24_UnkPosFunc2 = 0x80240360
export const RAM_b_area_nok_goomba_idleAnimations_802198A0 = 0x802198a0
export const RAM_D_8029F264 = 0x8029f264
export const RAM_flo_18_80240830 = 0x80240830
export const RAM_omo_12_DarkRoomUpdate = 0x80240184
export const RAM_spr_draw_component = 0x802dcedc
export const RAM_basic_window_update = 0x80147474
export const RAM_HES_Danger = 0x80108a14
export const RAM_sbk_04_SetNpcB5_3 = 0x80240040
export const RAM_HES_Item_MagicMirror_disabled = 0x80086588
export const RAM_D_E00B6768 = 0xe00b6768
export const RAM_HES_Spirit1 = 0x802423dc
export const RAM_b_area_pra_UnkBattleFunc1 = 0x80218000
export const RAM_D_802D9DA8 = 0x802d9da8
export const RAM_D_8029DA44 = 0x8029da44
export const RAM_func_80240360_B1B8D0 = 0x80240360
export const RAM_dgb_01_802449C4 = 0x802449c4
export const RAM_func_80030210 = 0x80030210
export const RAM_D_802A98C0 = 0x802a98c0
export const RAM_D_E00328D8 = 0xe00328d8
export const RAM_kpa_14_CheckItemFlags40 = 0x802405e8
export const RAM_pra_29_SetGameStatusUnk84_1 = 0x80240000
export const RAM_mac_03_Quizmo_SetVannaAnim_Idle = 0x80240fa0
export const RAM_SetCamLeadPlayer = 0x802cb680
export const RAM_b_area_trd_part_3_formation_00 = 0x80218220
export const RAM_flo_00_enterWalk_8024205C = 0x8024205c
export const RAM_arn_09_80240220 = 0x80240220
export const RAM_DeleteVirtualEntity = 0x802d3624
export const RAM_partner_get_out = 0x800eece8
export const RAM_D_80234758_6BB958 = 0x80234758
export const RAM_ui_battle_status_dizzy_2_pal = 0x801022a0
export const RAM_sam_11_Quizmo_UpdatePartnerPosition = 0x802416e0
export const RAM_D_E004C6B8 = 0xe004c6b8
export const RAM_fio_serialize_state = 0x8002b630
export const RAM_D_8010C950 = 0x8010c950
export const RAM_ui_lakilester_disabled_pal = 0x800ff800
export const RAM_D_802BE060_3249B0 = 0x802be060
export const RAM_draw_status_ui = 0x800e973c
export const RAM_kzn_11_FlyingAI_JumpInit = 0x8024103c
export const RAM_D_800A0BA4 = 0x800a0ba4
export const RAM_b_area_trd_part_1_trd_02c_beforeBattle_80224BF0 = 0x80224bf0
export const RAM_D_802474E0_D1FCC0 = 0x802474e0
export const RAM_func_80218920_4EFDC0 = 0x80218920
export const RAM_b_area_omo2_1_shy_squad_attack = 0x802348e0
export const RAM_rising_bubble_appendGfx = 0xe00462c0
export const RAM_render_screen_overlay_backUI = 0x80138220
export const RAM_D_8024F160_C0C9E0 = 0x8024f160
export const RAM_water_block_init = 0xe00b4134
export const RAM_jan_06_SpearGuyAI_Main = 0x802420ec
export const RAM_pra_27_reflection_unk_resolve_anim = 0x80240050
export const RAM_func_802BD758_3184A8 = 0x802bd758
export const RAM_D_E0124BC0 = 0xe0124bc0
export const RAM_b_area_jan_medi_guy_idleAnimations_8021F890 = 0x80222550
export const RAM_dgb_08_80244130 = 0x80244130
export const RAM_D_802DB5B0 = 0x802db5b0
export const RAM_hos_03_Quizmo_AnswerResult = 0x8024bde8
export const RAM_world_bombette_update = 0x802be8a0
export const RAM_obk_05_SetCamera0MoveFlag1 = 0x8024001c
export const RAM_D_80246BD0_DC3E80 = 0x80246bd0
export const RAM_D_80242F90_D1B770 = 0x80242f90
export const RAM_func_80240360_B2C7D0 = 0x80240360
export const RAM_battle_item_tasty_tonic_main = 0x802a189c
export const RAM_contReadData = 0x80060738
export const RAM_dro_01_Quizmo_CreateWorker = 0x802424d4
export const RAM_b_area_dig_UnkFloatFunc3 = 0x80218430
export const RAM_kpa_95_PatrolAI_PostLoiter = 0x8024075c
export const RAM_func_8005AF8C = 0x8005af8c
export const RAM_kpa_08_MagikoopaAI_OnPlayerWon = 0x80241378
export const RAM_b_area_nok_paratroopa_handleEvent_8021F2EC = 0x8022411c
export const RAM_whirlwind_render = 0xe00ce428
export const RAM_basic_ai_lose_player = 0x8004a3e8
export const RAM_D_800DAAC0 = 0x800daac0
export const RAM_b_area_mac_lee_kooper = 0x802206c0
export const RAM_battle_item_strange_cake_AddFP = 0x802a19ec
export const RAM_D_8023BD70 = 0x8023bd70
export const RAM_D_80244A74_C5F1B4 = 0x80244a74
export const RAM_b_area_kmr_part_1_goomba_init_802198EC = 0x802197ac
export const RAM_arn_08_pad_184C = 0x8024184c
export const RAM_EE70C0_ItemChoice_SaveSelected = 0x802422b4
export const RAM_flo_00_sam_11_name_hack = 0x80249370
export const RAM_D_80243250_A2A120 = 0x80243250
export const RAM_b_area_trd_part_1_trd_05c_80225C60 = 0x80225c60
export const RAM_dgb_07_ClubbaNappingAI_ReturnHome = 0x80240c4c
export const RAM_kpa_113_UnkFunc12 = 0x802400fc
export const RAM_sbk_01_exitWalk_8024013C = 0x8024013c
export const RAM_IES_FrightJar = 0x8008b324
export const RAM_D_80231624_63D1C4 = 0x80231624
export const RAM_battle_move_dizzy_stomp_UnkMoveFunc1 = 0x802a1000
export const RAM_D_E0026A00 = 0xe0026a00
export const RAM_pause_banner_fp_pal = 0x8026cc30
export const RAM_b_area_trd_part_1_trd_05b_beforeBattle_80225B24 = 0x80225b24
export const RAM_dgb_01_D_80252C8C_C1050C = 0x80252c8c
export const RAM_battle_partner_goombario_init_802392F0 = 0x802392f0
export const RAM_kpa_01_set_script_owner_npc_col_height = 0x80240474
export const RAM_arn_07_npcGroup_80246C8C = 0x80246c8c
export const RAM_ED0030_UnkFunc43 = 0x80240040
export const RAM_EA0C10_LetterDelivery_Init = 0x80242ae8
export const RAM_b_area_omo_medi_guy_partsTable_8021F99C = 0x8022351c
export const RAM_b_area_trd_part_1_formationTable = 0x80218cb0
export const RAM_b_area_kzn_kzn_04c_80228A7C = 0x80228a7c
export const RAM_func_8003D788 = 0x8003d788
export const RAM_battle_menu_moveIndices = 0x802ad4b0
export const RAM_GetStatusFlags = 0x8026f230
export const RAM_jan_02_Quizmo_GetItemName = 0x802416c8
export const RAM_ui_ultra_hammer_pal = 0x800faea0
export const RAM_D_8009A654 = 0x8009a654
export const RAM_D_8023BD78 = 0x8023bd78
export const RAM_flo_14_pad_2BF4 = 0x80242bf4
export const RAM_flo_25_FlyingAI_JumpInit = 0x80240c1c
export const RAM_b_area_trd_part_1_bill_blaster_8021A2BC = 0x8021a2bc
export const RAM_b_area_sbk_bandit_idle_8021D810 = 0x8021d810
export const RAM_dgb_00_80240D24 = 0x80240d24
export const RAM_D_E011AC20 = 0xe011ac20
export const RAM_kpa_08_MagikoopaAI_23 = 0x80240f70
export const RAM_b_area_omo2_5_shy_squad_dup_run_squad_to_pos = 0x80234ec4
export const RAM_func_802A99D4 = 0x802a99d4
export const RAM_D_8009C4E0 = 0x8009c4e0
export const RAM_pra_14_SetPartnerFlags20000 = 0x80240eec
export const RAM_D_8014FEC0 = 0x8014fec0
export const RAM_pra_33_SetPartnerFlags20000 = 0x80240eec
export const RAM_state_step_title_screen = 0x800370b4
export const RAM_pra_05_reflection_render_floor_fancy = 0x80240870
export const RAM_pause_partners_cleanup = 0x8024c984
export const RAM_arn_03_PatrolAI_ChaseInit = 0x8024081c
export const RAM_b_area_kzn_red_magikoopa_ShrinkActor = 0x80218630
export const RAM_b_area_trd_part_1_trd_02c_foregroundModelList_80224C64 = 0x80224c64
export const RAM_b_area_nok_nok_02_8022B810 = 0x8022b810
export const RAM_HES_Item_AttackFXA_disabled = 0x80084428
export const RAM_smoke_ring_update = 0xe002e250
export const RAM_func_802A936C_42236C = 0x802a936c
export const RAM_EA0C10_StashVars = 0x8024267c
export const RAM_gMapFlags = 0x802da480
export const RAM_gStaticScriptCounter = 0x802d9ca0
export const RAM_omo_02_StationaryAI_ReturnHomeInit = 0x8024141c
export const RAM_func_80050770 = 0x80050770
export const RAM_lil_oink_update = 0xe01140dc
export const RAM_D_80245AD0_A002C0 = 0x80245ad0
export const RAM_arn_10_entryList = 0x80240000
export const RAM_D_E002C964 = 0xe002c964
export const RAM_func_802402C0_D9D790 = 0x802402c0
export const RAM_mgm_00_PatrolAI_Move = 0x80240438
export const RAM_fx_63 = 0x80071db0
export const RAM_D_8014F4D4 = 0x8014f4d4
export const RAM_nok_02_Pipe_SetAnimFlag = 0x80240000
export const RAM_func_801356C4 = 0x801356c4
export const RAM_gPlayerActionState = 0x8010f07c
export const RAM_render_item_entities = 0x80132e54
export const RAM_dro_02_D_80247888_970A48 = 0x80247888
export const RAM_gPlayerAnimation = 0x8010f080
export const RAM_piMgrThread = 0x800ae6e0
export const RAM_b_area_iwa_iwa_02_partsTable_80221720 = 0x80221720
export const RAM_b_area_omo_red_shy_guy_init_80219368 = 0x80219368
export const RAM_sam_08_SuperBlock_PartnerSparkles4 = 0x80240cc0
export const RAM_au_load_BK_headers = 0x80054fc0
export const RAM_pra_09_reflection_render_wall = 0x802402f0
export const RAM_D_8010C988 = 0x8010c988
export const RAM_dgb_13_settings = 0x80240010
export const RAM_sbk_53_SpawnSunEffect = 0x80240000
export const RAM_omo_11_SuperBlock_SwitchToPartner = 0x80240bac
export const RAM_D_E0126BE0 = 0xe0126be0
export const RAM_func_802A9138_42C828 = 0x802a9138
export const RAM_D_8024C1F0_C09A70 = 0x8024c1f0
export const RAM_pra_18_ReflectWall = 0x802400ec
export const RAM_load_simple_entity_data = 0x801112a4
export const RAM_D_09002950 = 0x09002950
export const RAM_mac_01_Quizmo_CreateWorker = 0x80242f74
export const RAM_pause_banner_boots_png = 0x8026d230
export const RAM_battle_action_cmd_water_block_0_png = 0x802aa520
export const RAM_b_area_jan_spear_guy_8021C628 = 0x8021c628
export const RAM_D_8023C070 = 0x8023c070
export const RAM_b_area_trd_part_1_bob_omb_idleAnimations_8021A55C = 0x8021a55c
export const RAM_mdl_renderTaskCount = 0x801533b0
export const RAM_au_SEFCmd_09_StartLoop = 0x8004d114
export const RAM_ShakeCam1 = 0x802d9cb0
export const RAM_npc_remove_decoration_sweat = 0x8003c8ac
export const RAM_flo_11_defeat_802428B8 = 0x802428b8
export const RAM_D_E00E68F8 = 0xe00e68f8
export const RAM_b_area_isk_part_1_isk_08 = 0x80224044
export const RAM_partner_reset_data = 0x800eb474
export const RAM_b_area_mac_lee_nextTurn_80220450 = 0x80220450
export const RAM_spr_update_player_sprite = 0x802dda8c
export const RAM_func_800505E4 = 0x800505e4
export const RAM_func_8024289C_E07BFC = 0x8024289c
export const RAM_flo_10_exitWalk_8024192C = 0x8024192c
export const RAM_gCurrentMeshAnimationListPtr = 0x802dae30
export const RAM_D_80247958_DF67F8 = 0x80247958
export const RAM_kpa_03_UnkNpcAIFunc12 = 0x80240310
export const RAM_func_80218158_4CF308 = 0x80218158
export const RAM_au_BGMCmd_FD_EventTrigger = 0x8005015c
export const RAM_b_area_trd_part_1_trd_02d = 0x80225430
export const RAM_IES_DojoCard1 = 0x8008bbe4
export const RAM_dup_jan_09_foliage_setup_shear_mtx = 0x802416e0
export const RAM_music_note_init = 0xe004c204
export const RAM_b_area_mac_lee_idle_802245FC = 0x802245fc
export const RAM_b_area_kzn_spike_top_idleAnimations_8021C9D0 = 0x8021c9d0
export const RAM_dro_01_Quizmo_CreateStage = 0x80241be0
export const RAM_func_80241738_8ED558 = 0x80241738
export const RAM_HES_Item_MapleSyrup_disabled = 0x80087068
export const RAM_battle_action_cmd_whirlwind_5_pal = 0x802a9f00
export const RAM_func_80242FA4_803824 = 0x80242fa4
export const RAM_D_8024F580_hos_05 = 0x8024f580
export const RAM_D_8014FEC8 = 0x8014fec8
export const RAM_iwa_01_MontyMoleAI_Wander = 0x802416f0
export const RAM_arn_03_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_flo_10_pad_167C = 0x8024167c
export const RAM_b_area_pra3_pra_02_80231CFC = 0x80231cfc
export const RAM_IES_IcedPotato = 0x8008dab4
export const RAM_EA8AE0_StarSpiritEffectFunc4 = 0x802408c8
export const RAM_HES_ShrunkLoop = 0x80106908
export const RAM_hos_00_UnkEffect0FFunc4 = 0x80240584
export const RAM_vtx_C2CEA0 = 0x8020bf60
export const RAM_vtx_C23E20 = 0x80202ee0
export const RAM_tik_21_Pipe_GetEntryPos = 0x802403fc
export const RAM_D_80246F10_DC41C0 = 0x80246f10
export const RAM_b_area_mac_stageTable = 0x80232ad4
export const RAM_get_item_entity = 0x80130f58
export const RAM_kzn_08_CheckItemFlags40 = 0x802409f8
export const RAM_dgb_05_MeleeHitbox_32 = 0x8024013c
export const RAM_func_8021C520_6A3720 = 0x8021c520
export const RAM_flo_12_TransformFoliage = 0x802400b0
export const RAM_dgb_07_main = 0x80241660
export const RAM_dro_01_func_80243578_95E778 = 0x80243578
export const RAM_func_80240B4C_9001BC = 0x80240b4c
export const RAM_b_area_sam2_monstar_init = 0x80219ff0
export const RAM_b_area_kmr_part_3_mage_jr_troopa = 0x8022a728
export const RAM_NAMESPACE_8023C990 = 0x8023c990
export const RAM_battle_partner_goombario_8023A52C = 0x8023a52c
export const RAM_crash_screen_print_fpcsr = 0x8002c324
export const RAM_b_area_nok_paratroopa_statusTable_8021EBC0 = 0x802239f0
export const RAM_Entity_InertRedBlock = 0x802ea0a0
export const RAM_dro_02_EVS_Quizmo_NPC_OtherAI = 0x80247250
export const RAM_dup_hos_05_UnkFunc27 = 0x80244cc0
export const RAM_EnableNpcShadow = 0x802cf268
export const RAM_arn_03_npcGroup_80243B38 = 0x80243b38
export const RAM_D_802A3E88_7214D8 = 0x802a3e88
export const RAM_D_802AD60B = 0x802ad60b
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_defenseTable_80227F5C = 0x80227f5c
export const RAM_D_80241E20_A6CAC0 = 0x80241e20
export const RAM_jan_03_Quizmo_AddViewRelativeOffset = 0x8024281c
export const RAM_func_80218BFC_6BC78C = 0x80218bfc
export const RAM_mac_06_unkVtxFunc001 = 0x80240658
export const RAM_b_area_kmr_part_3_egg_jr_troopa_80224510 = 0x80224510
export const RAM_HES_Item_KootMerluvleeAutograph = 0x800820b8
export const RAM_b_area_trd_part_1_trd_02_foregroundModelList_80223328 = 0x80223328
export const RAM_stars_shimmer_init = 0xe004441c
export const RAM_kmr_02_AddPlayerHandsOffset = 0x8024209c
export const RAM_D_E00D6EA8 = 0xe00d6ea8
export const RAM_func_80218000_47F0B0 = 0x80232c20
export const RAM_world_kooper_take_out = 0x802beb44
export const RAM_b_area_trd_part_1_trd_02_beforeBattle_80223224 = 0x80223224
export const RAM_flo_15_settings = 0x80240020
export const RAM_flo_25_FlyingAI_Wander = 0x802401f0
export const RAM_entity_HiddenPanel_setupGfx = 0x802e61b0
export const RAM_battle_item_food_func_802A15A0_733670 = 0x802a15a0
export const RAM_mim_09_SetForeverForestFog = 0x80240000
export const RAM_flo_22_entryList = 0x80240290
export const RAM_b_area_kzn_formation_0F = 0x80229c7c
export const RAM_render_animated_model_with_vertices = 0x8011f3e8
export const RAM_D_80246280_9AE670 = 0x80246280
export const RAM_battle_area_sam2_actor_img_png = 0x8021a098
export const RAM_tik_17_SuperBlock_ShowSelectPartnerMenu = 0x802401fc
export const RAM_dro_02_LetterDelivery_SaveNpcAnim = 0x802439fc
export const RAM_b_area_sam2_sam_01_Set80071270_0_16 = 0x80218d78
export const RAM_dead_gOverrideFlags = 0x800a15a8
export const RAM_evt_handle_if_greater = 0x802c4d64
export const RAM_D_80150CC8 = 0x80150cc8
export const RAM_IES_Cake = 0x8008d1f4
export const RAM_dgb_15_802424E8 = 0x802424e8
export const RAM_HES_Item_MegaHPDrain_disabled = 0x80084ea8
export const RAM_pause_tabs_draw_stats = 0x80244b98
export const RAM_D_8024F5F0_C0CE70 = 0x8024f5f0
export const RAM_ui_partner9_pal = 0x800ffa20
export const RAM_iwa_03_CleftAI_FindPlayer = 0x80240810
export const RAM_kpa_51_MagikoopaAI_23 = 0x80240f20
export const RAM_D_80245378_EEB928 = 0x80245378
export const RAM_func_802BD638_31B658 = 0x802bd638
export const RAM_tik_01_UnkPosFunc2 = 0x802405f0
export const RAM_pra_37_ReflectWall = 0x8024011c
export const RAM_world_goombario_use_ability = 0x802bdda8
export const RAM_b_area_mim_formation_04 = 0x8021e5f4
export const RAM_ui_mamar_pal = 0x800fd340
export const RAM_sam_11_Quizmo_SetVannaAnim_Idle = 0x802412c0
export const RAM_battle_item_stop_watch_EatItem = 0x802a182c
export const RAM_D_E00CCE04 = 0xe00cce04
export const RAM_HES_Item_PowerSmash1 = 0x80084cf8
export const RAM_dro_02_D_80248D8C_971F4C = 0x80248d8c
export const RAM_arn_11_enterSingleDoor_802404F8 = 0x802404f8
export const RAM_flo_16_npcAISettings_80244028 = 0x80244028
export const RAM_fx_snaking_static = 0x80071930
export const RAM_D_090023E0 = 0x090023e0
export const RAM_dgb_09_ClubbaNappingAI_LoiterInit = 0x80240c74
export const RAM_btl_popup_messages_draw_ui = 0x8024ef08
export const RAM_omo_07_FlyingAI_Init = 0x80242b60
export const RAM_render_generic_entities_frontUI = 0x801234e0
export const RAM_HES_Item_BlueBerry = 0x80086978
export const RAM_D_80244750_AA7470 = 0x80244750
export const RAM_battle_item_fright_jar_UseItem = 0x802a1664
export const RAM_kmr_maps = 0x80090450
export const RAM_D_8014E8F0 = 0x8014e8f0
export const RAM_gPauseShownDescIconScript = 0x80270118
export const RAM_texPannerMainV = 0x801532a8
export const RAM_kpa_102_MagikoopaAI_SpellMain = 0x80241f00
export const RAM_dgb_02_npcGroup_80241E08 = 0x80241e08
export const RAM_sins = 0x800645f0
export const RAM_b_area_kzn_red_magikoopa_8022C408 = 0x80221148
export const RAM_b_area_isk_part_1_isk_03_afterBattle_80223A7C = 0x80223a7c
export const RAM_arn_02_CleftAI_HidingInit = 0x80240160
export const RAM_flo_23_UnkNpcAIFunc12 = 0x80240544
export const RAM_D_E00B6760 = 0xe00b6760
export const RAM_audio_set_stereo = 0x8005615c
export const RAM_D_8023D2B8 = 0x8023d2b8
export const RAM_b_area_sam_duplighost_watt_takeTurn = 0x80223f50
export const RAM_D_802A1A60 = 0x802a1a60
export const RAM_D_090037C0 = 0x090037c0
export const RAM_sbk_25_Bandit_DropCoin = 0x80240040
export const RAM_dgb_01_D_80253820_C110A0 = 0x80253820
export const RAM_b_area_dgb_dgb_05 = 0x8021b200
export const RAM_kmr_20_settings = 0x02800380
export const RAM_flo_08_func_80240D40_CAFA80 = 0x80240d40
export const RAM_b_area_flo_UnkFloatFunc4 = 0x802180cc
export const RAM_gPauseTutorialState = 0x8024f018
export const RAM_gCollisionRayDirZ = 0x800a4244
export const RAM_remove_status_icon_surprise = 0x80047fe8
export const RAM_D_E0060748 = 0xe0060748
export const RAM_D_8024F1D0_A39410 = 0x8024f1d0
export const RAM_entity_BrickBlock_idle = 0x802e2450
export const RAM_kkj_25_SetEntityPositionF = 0x802400fc
export const RAM_func_80240E4C_9EEA3C = 0x80240e4c
export const RAM_sfx_reset_door_sounds = 0x80149600
export const RAM_set_curtain_draw_callback = 0x8002bf04
export const RAM_tik_23_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_D_802473E8_C680F8 = 0x802473e8
export const RAM_end_00_CharAnim_FadeIn_5 = 0x80240eb0
export const RAM_RemoveActorDecoration = 0x8026ef4c
export const RAM_dup2_nok_02_StashVars = 0x80243db0
export const RAM_D_80150710 = 0x80150710
export const RAM_HES_TimingOK = 0x80292b54
export const RAM_RestartNpcAux = 0x80044f38
export const RAM_ui_battle_hp_0_pal = 0x8028a980
export const RAM_dro_02_Quizmo_SetVannaAnim_Wave = 0x80240e24
export const RAM_D_802A9972_429C92 = 0x802a9972
export const RAM_arn_07_npcAI_80243C08 = 0x80243c08
export const RAM_kzn_09_MeleeHitbox_31 = 0x8024108c
export const RAM_b_area_jan_spike_top_8021D57C = 0x802247ec
export const RAM_D_8010CD12 = 0x8010cd12
export const RAM_D_8024387C_D16E4C = 0x8024387c
export const RAM_ui_status_star_point_6_pal = 0x80101580
export const RAM_EF2680_TackleAI_Main = 0x80242530
export const RAM_PrevPlayerCamRelativeYaw = 0x800f7b40
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021B3D0 = 0x8021c150
export const RAM_arn_03_80241C9C = 0x80241c9c
export const RAM_sbk_56_SuperBlock_HideBlockContent = 0x802400bc
export const RAM_flo_16_SuperBlock_StartGlowEffect = 0x80240560
export const RAM_D_802453F8_CD7228 = 0x802453f8
export const RAM_D_09002100_3884B0 = 0x09002100
export const RAM_kpa_95_ToadHouse_Stub = 0x802418fc
export const RAM_battle_item_strange_cake_func_802A123C_73153C = 0x802a123c
export const RAM_D_E00BCE58 = 0xe00bce58
export const RAM_kzn_17_LetterDelivery_RestoreNpcAnim = 0x80242cc8
export const RAM_dgb_09_init_80244D00 = 0x80244d00
export const RAM_ClampAngleInt = 0x802d5a58
export const RAM_SetBattleState = 0x8026e16c
export const RAM_D_80294720 = 0x80294720
export const RAM_HES_Item_POWBlock = 0x80087698
export const RAM_D_8014BCA0 = 0x8014bca0
export const RAM_omo_05_FlyingAI_LosePlayer = 0x802418ac
export const RAM_sbk_42_SpawnSunEffect = 0x80240000
export const RAM_sbk_05_SpawnSunEffect = 0x80240000
export const RAM_dgb_15_pad_1E94 = 0x80241e94
export const RAM_D_802BE93C = 0x802be93c
export const RAM_filemenu_update_hidden_options_bottom = 0x802437b8
export const RAM_nok_02_Quizmo_UpdatePartnerPosition = 0x80242820
export const RAM_D_80245948_BAA0C8 = 0x80245948
export const RAM_mac_00_Quizmo_GetItemName = 0x802406e4
export const RAM_b_area_kzn_kzn_05 = 0x8022975c
export const RAM_func_80240894_D7F5F4 = 0x80240894
export const RAM_vtx_C28890 = 0x80207950
export const RAM_isk_13_HoppingAI_LoiterInit = 0x80240568
export const RAM_func_80250818 = 0x80250818
export const RAM_b_area_arn_arn_03 = 0x80227acc
export const RAM_battle_partner_goombario_8023993C = 0x8023993c
export const RAM_vtx_C20F40 = 0x80200000
export const RAM_b_area_isk_part_1_isk_00 = 0x802238fc
export const RAM_mac_05_PatrolAI_LosePlayer = 0x80240efc
export const RAM_b_area_isk_part_1_isk_04_foregroundModelList_80223B24 = 0x80223b24
export const RAM_tik_19_SuperBlock_CantUpgradeMessages = 0x802426b0
export const RAM_explosion_init = 0xe00321d0
export const RAM_kpa_51_MagikoopaAI_SpellMain = 0x80240220
export const RAM_contRmbForceStopEndMesg = 0x8005fe54
export const RAM_dgb_09_FlyingAI_JumpVels = 0x80244358
export const RAM_npc_remove_decoration_none = 0x8003c660
export const RAM_HES_Item_CakeMixed = 0x80080610
export const RAM_dup_b_area_flo_StartRumbleWithParams = 0x80218b20
export const RAM_D_E0112640 = 0xe0112640
export const RAM_D_80244F98_EC7198 = 0x80244f98
export const RAM_vtx_C281E0 = 0x802072a0
export const RAM_BattleCamTargetActorPart = 0x8024eb84
export const RAM_D_E00C0C08 = 0xe00c0c08
export const RAM_b_area_pra3_duplighost_kooper_defenseTable2 = 0x8021d7a0
export const RAM_D_80232A98_684818 = 0x80232a98
export const RAM_IES_SuperSmash = 0x8008c564
export const RAM_SetNpcDecoration = 0x802d0118
export const RAM_pra_28_reflection_unk_resolve_anim = 0x80240050
export const RAM_b_area_kmr_part_1_goomba_idle_80219938 = 0x802197f8
export const RAM_GetNpcVar = 0x800453bc
export const RAM_D_80243008_A67098 = 0x80243008
export const RAM_SetBattleCamOffsetZ = 0x8024ea58
export const RAM_func_80241674_BD0264 = 0x80241674
export const RAM_kmr_03_bush1 = 0x8024216c
export const RAM_InitTargetIterator = 0x8026e354
export const RAM_D_80109492 = 0x80109492
export const RAM_pra_29_ReflectPartner = 0x80240d3c
export const RAM_flashing_box_shockwave_render = 0xe00942c0
export const RAM_hud_element_free = 0x801448f4
export const RAM_jan_12_PullVine_WaitForPlayerGrab = 0x80240000
export const RAM_osEPiLinkHandle = 0x80060ec0
export const RAM_func_80240F2C_B0792C = 0x80240f2c
export const RAM_mgm_01_InitPanelMoveToTally = 0x802408ec
export const RAM_D_E0122CA0 = 0xe0122ca0
export const RAM_D_80251A90_9D6E70 = 0x80251a90
export const RAM_D_E011C52C = 0xe011c52c
export const RAM_flo_22_tree2_Trunk = 0x80241f38
export const RAM_LoadBattleDmaData = 0x80253624
export const RAM_omo_02_SetNpcB5_3 = 0x80242b5c
export const RAM_func_802610CC = 0x802610cc
export const RAM_arn_03_80241780 = 0x80241780
export const RAM_D_09003190 = 0x09003190
export const RAM_vtx_C2CFC0 = 0x8020c080
export const RAM_HES_Item_ShrinkStomp = 0x80085538
export const RAM_D_802EA862 = 0x802ea862
export const RAM_func_8024143C_BD002C = 0x8024143c
export const RAM_D_E00D6EA0 = 0xe00d6ea0
export const RAM_isk_05_StoneChompFXA = 0x80241108
export const RAM_func_80269EAC = 0x80269eac
export const RAM_b_area_pra3_pra_03b = 0x80232000
export const RAM_dead_evt_set_float_variable = 0x802d5a10
export const RAM_D_80244F88_EC7188 = 0x80244f88
export const RAM_func_80219824_465CB4 = 0x80219824
export const RAM_ui_kooper_pal = 0x800fea60
export const RAM_FrameBuf = 0x80093ba8
export const RAM_func_80240624_BC8484 = 0x80240624
export const RAM_D_80244D58_A0BC58 = 0x80244d58
export const RAM_b_area_sam2_gray_magikoopa_defenseTable_flying = 0x8021f13c
export const RAM_IES_AttackFXA = 0x8008c244
export const RAM_b_area_nok_formation_13 = 0x8021937c
export const RAM_func_802406EC_B070EC = 0x802406ec
export const RAM_ui_status_coin_4_pal = 0x80100e00
export const RAM_D_09000F20_327330 = 0x09000f20
export const RAM_func_8003B420 = 0x8003b420
export const RAM_dead_fx_radial_shimmer = 0x80075e30
export const RAM_IES_CrystalBall = 0x8008bc84
export const RAM_b_area_kpa_UnkSfxFunc = 0x80218700
export const RAM_tik_17_SuperBlock_CountEligiblePartners = 0x80240150
export const RAM_kzn_08_MeleeHitbox_31 = 0x802425a8
export const RAM_ui_status_text_2_pal = 0x80100140
export const RAM_func_80238480_708120 = 0x80238480
export const RAM_explosion_update = 0xe00321d8
export const RAM_gMessageMsgVars = 0x80155c38
export const RAM_func_80240310_C5AA50 = 0x80240310
export const RAM_D_800A0984 = 0x800a0984
export const RAM_flo_16_npcGroupList_802444D4 = 0x802444d4
export const RAM_sam_01_GetNpcCollisionHeight = 0x80240310
export const RAM_EEDF50_FlyingNoAttackAI_Main = 0x80242780
export const RAM_D_09003C08_3AEC38 = 0x09003c08
export const RAM_kpa_134_UpdateTexturePanStepped = 0x80240124
export const RAM_battle_partner_kooper_partsTable_80238524 = 0x80238524
export const RAM_dead_fx_star_spirits_energy = 0x80077c30
export const RAM_HES_Item_MagicalSeed4 = 0x80081998
export const RAM_dro_02_UnkFunc27 = 0x80240000
export const RAM_func_802CFD30 = 0x802cfd30
export const RAM_func_8004DB28 = 0x8004db28
export const RAM_b_area_omo2_1_shy_squad_handleEvent = 0x802337d0
export const RAM_fx_music_note = 0x800706d0
export const RAM_dgb_01_defeat_802457D8 = 0x802457d8
export const RAM_dgb_00_80240F50 = 0x80240f50
export const RAM_mac_01_Quizmo_GetCamVfov = 0x80242af0
export const RAM_func_80241A68_8D2F78 = 0x80241a68
export const RAM_flower_trail_update_part = 0xe0012104
export const RAM_arn_03_func_8024113C_BDFECC = 0x8024113c
export const RAM_D_E00BEB08 = 0xe00beb08
export const RAM_gPauseMsg_3B = 0x8026f6a0
export const RAM_vtx_C26CC0 = 0x80205d80
export const RAM_gPauseBadgesSelectedIndex = 0x80270378
export const RAM_tik_05_SetEntityPosition = 0x80240984
export const RAM_D_8024592C = 0x8024592c
export const RAM_b_area_kmr_part_1_spiked_goomba_idleAnimations_8021E500 = 0x8021b120
export const RAM_lens_flare_appendGfx = 0xe0034310
export const RAM_b_area_jan_medi_guy_takeTurn_80220DCC = 0x80223a8c
export const RAM_HES_RunningAway = 0x802928f8
export const RAM_b_area_mac_lee_idleAnimations_80228458 = 0x80228458
export const RAM_star_spirits_energy_render = 0xe01226c4
export const RAM_nok_15_Pipe_GetCameraYaw = 0x802401d0
export const RAM_D_802312E0_battle_area_omo2 = 0x802312e0
export const RAM_D_8010EF9D = 0x8010ef9d
export const RAM_D_8029CC40 = 0x8029cc40
export const RAM_flo_11_802420C8 = 0x802420c8
export const RAM_dgb_14_80240060 = 0x80240060
export const RAM_battle_item_snowman_doll_main = 0x802a19fc
export const RAM_b_area_isk_part_1_isk_05_D_80224D70 = 0x80224d70
export const RAM_b_area_hos_magikoopa_idle_8021D774 = 0x8021d774
export const RAM_AssignPanelFlag = 0x80112114
export const RAM_snd_ambient_80055760 = 0x80055760
export const RAM_func_80266ADC = 0x80266adc
export const RAM_b_area_kmr_part_3_kmr_06_beforeBattle_80233378 = 0x80233378
export const RAM_mac_05_UnkFloatFunc001 = 0x80243948
export const RAM_D_E0122CA8 = 0xe0122ca8
export const RAM_D_80075E60 = 0x80075e60
export const RAM_dro_02_pad_D_8024EFA4 = 0x8024efa4
export const RAM_D_802BCE32 = 0x802bce32
export const RAM_func_E0018000 = 0xe0018000
export const RAM_btl_state_update_celebration = 0x8025e190
export const RAM_dro_02_D_80248BA8_971D68 = 0x80248ba8
export const RAM_evt_handle_wait_seconds = 0x802c4bb8
export const RAM_npc_update_decoration_sweat = 0x8003c7a8
export const RAM_func_80240040_7E73E0 = 0x80240040
export const RAM_D_80240330_8FF1B0 = 0x80240330
export const RAM_vtx_C282F0 = 0x802073b0
export const RAM_nok_02_Quizmo_UpdateRecords = 0x80241ffc
export const RAM_HES_Item_ShrinkStomp_disabled = 0x80085568
export const RAM_arn_03_npcGroup_80243948 = 0x80243948
export const RAM_D_802491C0_CA70E0 = 0x802491c0
export const RAM_EA0C10_PiranhaPlantAI_01 = 0x80240cc8
export const RAM_func_E00B8200 = 0xe00b8200
export const RAM_kpa_61_FlyingMagikoopaAI_16 = 0x8024177c
export const RAM_b_area_sam2_monstar_handleEvent = 0x8021a4fc
export const RAM_flo_13_npcGroup_80246DE4 = 0x80246de4
export const RAM_flo_07_func_80240344_CAC534 = 0x80240344
export const RAM_AdjustMusicProximityMix = 0x802d5f28
export const RAM_D_802445D4_A3DAB4 = 0x802445d4
export const RAM_kzn_02_FlyingNoAttackAI_Main = 0x80241de4
export const RAM_au_SEFCmd_SetRandomVelocity = 0x8004d33c
export const RAM_trd_04_PatrolAI_JumpInit = 0x802413b4
export const RAM_D_802430A0_BD1C90 = 0x802430a0
export const RAM_D_8024343C_867F7C = 0x8024343c
export const RAM_DeleteAnimatedModel = 0x802cd3c0
export const RAM_b_area_hos_magikoopa_statusTable_80221578 = 0x80221578
export const RAM_b_area_mac_chan_80219F4C = 0x80219f4c
export const RAM_something_rotating_main = 0xe0116000
export const RAM_D_8023D2B0 = 0x8023d2b0
export const RAM_flo_10_80241680 = 0x80241680
export const RAM_enable_entity_fog = 0x80122fec
export const RAM_b_area_iwa_iwa_01_partsTable_80220A04 = 0x80220a04
export const RAM_pause_badges_get_pos_x = 0x8024710c
export const RAM_battle_item_coconut_DrinkItem = 0x802a1784
export const RAM_gPauseWS_15 = 0x8026fc48
export const RAM_func_802A973C = 0x802a973c
export const RAM_D_E0120780 = 0xe0120780
export const RAM_btl_check_player_defeated = 0x80263464
export const RAM_func_80240970_9D7990 = 0x80240970
export const RAM_D_80243300_A86790 = 0x80243300
export const RAM_end_00_init_credits = 0x80242500
export const RAM_D_09001800_351A20 = 0x09001800
export const RAM_flo_12_80241820 = 0x80241820
export const RAM_dgb_01_pad_3EE4 = 0x80243ee4
export const RAM_vtx_C230A0 = 0x80202160
export const RAM_D_80231318_63CEB8 = 0x80231318
export const RAM_StartBattleWith = 0x80044860
export const RAM_b_area_sam_duplighost_UnkWattEffectFunc1 = 0x80218e88
export const RAM_D_E00D2518 = 0xe00d2518
export const RAM_HES_Item_Unused_0D5 = 0x800853b8
export const RAM_vtx_C28970 = 0x80207a30
export const RAM_D_802A1A78_738308 = 0x802a1aa8
export const RAM_b_area_omo_omo_07_beforeBattle_8022F8B0 = 0x8022f8b0
export const RAM_arn_04_PatrolAI_Move = 0x80240158
export const RAM_flo_23_ItemChoice_SaveSelected = 0x802402c0
export const RAM_battle_menu_moveOptionIndexMap = 0x802ad1f8
export const RAM_D_8008EEC0 = 0x8008eec0
export const RAM_mim_08_PiranhaPlantAI_14 = 0x80242c00
export const RAM_func_80244078_9DB098 = 0x80244078
export const RAM_jan_02_PatrolAI_JumpInit = 0x80240b60
export const RAM_func_80240210_8FF090 = 0x80240210
export const RAM_D_E00BCD38 = 0xe00bcd38
export const RAM_func_80218A60_43A4F0 = 0x80218a60
export const RAM_b_area_trd_part_1_bob_omb_idleAnimations_8021A510 = 0x8021a510
export const RAM_D_8010C980 = 0x8010c980
export const RAM_HES_Item_Unused_089_disabled = 0x80082268
export const RAM_func_8023817C_6F125C = 0x8023817c
export const RAM_fx_03 = 0x8006f9b0
export const RAM_b_area_kzn_kzn_04_foregroundModelList_80228338 = 0x80228338
export const RAM_damage_indicator_init = 0xe003c23c
export const RAM_HES_PoisonedEnd = 0x80105df4
export const RAM_virtual_entity_set_scale = 0x802d43ac
export const RAM_b_area_omo_anti_guy = 0x802219ec
export const RAM_tik_05_StashVars = 0x80240680
export const RAM_dgb_04_makeEntities = 0x80244378
export const RAM_D_80241810_D92F10 = 0x80241810
export const RAM_func_80240E70_D7FBD0 = 0x80240e70
export const RAM_EB8E90_PatrolAI_LosePlayer = 0x80240aac
export const RAM_gBattleStatus = 0x800dc070
export const RAM_jan_03_Quizmo_ShouldAppear = 0x80241cd8
export const RAM_func_802D7690 = 0x802d7690
export const RAM_b_area_nok_goomba_takeTurn_8021A440 = 0x8021a440
export const RAM_SetPartRotation = 0x8026c634
export const RAM_spr_playerSpriteSets = 0x802df4c4
export const RAM_sbk_55_SpawnSunEffect = 0x80240000
export const RAM_D_802498DC_A33B1C = 0x802498dc
export const RAM_gPopupMenu = 0x8010d69c
export const RAM_b_area_omo2_1_shy_squad_updateActorSize = 0x80235d9c
export const RAM_tik_07_Pipe_AwaitDownInput = 0x80240034
export const RAM_dgb_04_FlyingAI_LosePlayer = 0x80240e58
export const RAM_jan_02_Quizmo_HideWorld = 0x80241a40
export const RAM_omo_09_GetItemName = 0x802407d4
export const RAM_b_area_mac_master1_idle_8022A494 = 0x8022a494
export const RAM_world_goombario_take_out = 0x802bdd6c
export const RAM_action_update_landing_on_switch = 0x802b6198
export const RAM_func_80242C5C_9D9C7C = 0x80242c5c
export const RAM_ui_status_coin_2_png = 0x80100c40
export const RAM_mac_02_Quizmo_AnswerResult = 0x8024e558
export const RAM_func_80276EFC = 0x80276efc
export const RAM_throw_spiny_appendGfx = 0xe00c8454
export const RAM_b_area_trd_part_1_formation_15 = 0x80218ae8
export const RAM_HES_Item_SnowmanDoll = 0x80086138
export const RAM_func_80241434_91F9A4 = 0x80241434
export const RAM_pra_14_reflection_render_floor = 0x8024068c
export const RAM_b_area_trd_part_2_red_ninja_koopa_handleEvent_8022ADE8 = 0x8022ade8
export const RAM_kmr_20_ToadHouse_InitScreenOverlay = 0x802414b4
export const RAM_phys_update_lava_reset = 0x800dfbe8
export const RAM_trd_07_GetDefeatedEnemyCount = 0x8024256c
export const RAM_battle_menu_PeachStarPowerHudScripts = 0x802ab720
export const RAM_func_80218258_608D08 = 0x80218258
export const RAM_nok_01_Quizmo_FadeInWorld = 0x802412e0
export const RAM_tik_21_UpdateTexturePanSmooth = 0x80240000
export const RAM_dro_01_UnkFunc26 = 0x80240404
export const RAM_kpa_95_ToadHouse_GetPartnerBackOut = 0x802418cc
export const RAM_ui_input_start_button2_pal = 0x800fa780
export const RAM_func_802404E0_AD63B0 = 0x802404e0
export const RAM_mgm_01_PanelTypes = 0x80241b70
export const RAM_snd_song_clear_track_volumes = 0x80055e48
export const RAM_D_80109710 = 0x80109710
export const RAM_D_8029FB6C = 0x8029fb6c
export const RAM_D_8029621C = 0x8029621c
export const RAM_hud_element_set_tint = 0x80144efc
export const RAM_osViSetSpecialFeatures = 0x80066e60
export const RAM_mdl_bgMultiplyColorR = 0x8014b74d
export const RAM_ui_battle_cmd_flee_1_pal = 0x8028da00
export const RAM_D_80244C18 = 0x80244c18
export const RAM_b_area_nok_goomba_CalculateRotationZ = 0x8021818c
export const RAM_dro_01_npcGroupList_8024B464 = 0x8024b464
export const RAM_jan_03_Quizmo_SpinPlayer = 0x802429b8
export const RAM_flo_16_SuperBlock_EndGlowEffect = 0x802405f0
export const RAM_EE70C0_FlyingAI_WanderInit = 0x80240b10
export const RAM_pra_38_reflection_render_floor = 0x802406bc
export const RAM_arn_05_PatrolAI_PostLoiter = 0x8024067c
export const RAM_action_update_hit_lava = 0x802b6000
export const RAM_dgb_03_ClubbaNappingAI_Init = 0x80241c7c
export const RAM_nok_02_foliage_setup_shear_mtx = 0x80244360
export const RAM_kzn_02_FlyingAI_Chase = 0x802412d4
export const RAM_func_802402F4_B1CD44 = 0x802402f4
export const RAM_func_E00D8334 = 0xe00d8334
export const RAM_D_80077A40 = 0x80077a40
export const RAM_IES_BubbleBerry = 0x8008d914
export const RAM_npc_remove_decoration_glow_behind = 0x8003cc8c
export const RAM_dgb_05_ClubbaNappingAI_WakeUp = 0x802409bc
export const RAM_flo_03_init_8024360C = 0x8024360c
export const RAM_D_8023CD70_6F5E50 = 0x8023cd70
export const RAM_b_area_nok_formation_0E = 0x802191f4
export const RAM_dgb_18_enterDoubleDoor_80241414 = 0x80241414
export const RAM_func_80240BB8_A11438 = 0x80240bb8
export const RAM_pra_39_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_D_8024F2CC = 0x8024f2cc
export const RAM_b_area_jan_jungle_fuzzy_idleAnimations_8021EADC = 0x8021eadc
export const RAM_hos_01_UpdateTexturePanSmooth = 0x802407a0
export const RAM_b_area_sam_duplighost_lakilester_defenseTable = 0x802261d8
export const RAM_flo_16_UnkFunc44 = 0x80240e18
export const RAM_mac_01_PatrolNoAttackAI_Main = 0x80241c14
export const RAM_floating_cloud_puff_appendGfx = 0xe00d2284
export const RAM_D_8024EFC0 = 0x8024efc0
export const RAM_D_802809F0 = 0x802809f0
export const RAM_D_090008D8_3D03C8 = 0x090008d8
export const RAM_flo_08_SuperBlock_UpgradeDescMessages = 0x80241fc8
export const RAM_flo_08_80242F00 = 0x80242f00
export const RAM_D_80077724 = 0x80077724
export const RAM_IES_BakingFlour = 0x8008dc74
export const RAM_b_area_isk_part_1_isk_02b_afterBattle_80223E44 = 0x80223e44
export const RAM_b_area_sam_duplighost_watt_idle = 0x80223ab4
export const RAM_func_80240730_AB0720 = 0x80240730
export const RAM_b_area_kmr_part_2_kmr_05_clouds3 = 0x80225230
export const RAM_D_8024F46C = 0x8024f46c
export const RAM_D_80153A00 = 0x80153a00
export const RAM_ui_battle_status_danger_pal = 0x801033c0
export const RAM_arn_08_pad_A6C = 0x80240a6c
export const RAM_end_00_CharAnim_FadeOut_1 = 0x802414cc
export const RAM_flo_11_makeEntities = 0x802430f0
export const RAM_flo_18_searchBush = 0x802466c0
export const RAM_D_802A150C_00734AAC = 0x802a150c
export const RAM_D_801094A4 = 0x801094a4
export const RAM_b_area_nok_kent_c_koopa_statusTable_80227A08 = 0x80227a08
export const RAM_b_area_isk_part_1_buzzy_beetle_init_8021E6BC = 0x8021e6bc
export const RAM_osr_00_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802439A0_8B3A10 = 0x802439a0
export const RAM_something_rotating_render = 0xe01166a0
export const RAM_b_area_isk_part_1_isk_04_CreateTorchFX = 0x802188b0
export const RAM_pra_14_reflection_setup_floor = 0x80240500
export const RAM_kkj_20_StashVars = 0x80240000
export const RAM_dgb_07_enterSingleDoor_802415E4 = 0x802415e4
export const RAM_b_area_nok_formation_04 = 0x80218f00
export const RAM_func_802549F4 = 0x802549f4
export const RAM_jan_07_MeleeHitbox_33 = 0x802403c8
export const RAM_filemenu_set_cursor_alpha = 0x80242dec
export const RAM_rodata_padding_2 = 0x80150bbc
export const RAM_gHudElementAuxCache = 0x8014efc0
export const RAM_mac_05_UnkPlayerPosFunc = 0x80243ab8
export const RAM_b_area_pra3_gray_magikoopa_canBeKnockedOff = 0x8022f5fc
export const RAM_dgb_05_main = 0x802416b0
export const RAM_D_80074200 = 0x80074200
export const RAM_b_area_mac_lee_8021E0E0 = 0x8021e0e0
export const RAM_nuScGetGfxMQ = 0x8005ef78
export const RAM_kmr_12_main = 0x802401c0
export const RAM_flo_09_exitWalk_80241BF8 = 0x80241bf8
export const RAM_partner_get_ride_script = 0x800eb638
export const RAM_fx_underwater = 0x80071b70
export const RAM_kpa_102_ProjectileAI_Reflect = 0x80240da8
export const RAM_pause_partners_handle_input = 0x8024c338
export const RAM_jan_14_HoppingAI_Chase = 0x80240d6c
export const RAM_dead_evt_get_float_variable = 0x802d57fc
export const RAM_kmr_10_SomeItemEntityFunc = 0x80240118
export const RAM_D_802284C0_6CC050 = 0x802284c0
export const RAM_arn_04_npcGroup_80243840 = 0x80243840
export const RAM_func_80242754_E07AB4 = 0x80242754
export const RAM_EA0C10_func_802408B4_97BEE4 = 0x80242608
export const RAM_b_area_arn_arn_01_beforeBattle_802278F0 = 0x802278f0
export const RAM_kmr_30_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_kmr_part_1_kmr_05_foregroundModelList_80220A6C = 0x80220a6c
export const RAM_b_area_jan2_white_magikoopa_handleEvent_80224A44 = 0x8021adf4
export const RAM_b_area_nok_spiked_goomba_idleAnimations_8021E500 = 0x8021e500
export const RAM_b_area_isk_part_1_formation_10 = 0x80224564
export const RAM_kpa_91_PatrolAI_Chase = 0x8024094c
export const RAM_D_8024AB20_DD77F0 = 0x8024ab20
export const RAM_mac_05_Quizmo_GetItemName = 0x802413d4
export const RAM_func_802BC514_E30E64 = 0x802bc514
export const RAM_kzn_09_TackleAI_Main = 0x80242d58
export const RAM_sbk_63_SetNpcB5_3 = 0x80240040
export const RAM_tubba_heart_attack_init = 0xe00cc250
export const RAM_tik_17_SuperBlock_GetPartnerRank = 0x80240098
export const RAM_mtx_ident_mirror_y = 0x80038478
export const RAM_func_80244454_A2E694 = 0x80244454
export const RAM_HES_Item_Peekaboo_disabled = 0x80085b08
export const RAM_func_802418B4_D3BE84 = 0x802418b4
export const RAM_b_area_sam_sam_02d_UnkModelStuff_func1 = 0x8021a830
export const RAM_bModelList = 0x80151b70
export const RAM_gPauseMapArrowWobble = 0x80250560
export const RAM_entity_set_render_script = 0x80110bcc
export const RAM_D_802420A0_D99930 = 0x802420a0
export const RAM_battle_item_tasty_tonic_PlayerGoHome = 0x802a1710
export const RAM_radial_shimmer_appendGfx = 0xe0066770
export const RAM_func_80240BA8_900218 = 0x80240ba8
export const RAM_mac_01_Quizmo_StageEffect = 0x80262a60
export const RAM_gPausePartnersNumPartners = 0x802706b0
export const RAM_ED0310_FlyingAI_Loiter = 0x80240bb8
export const RAM_D_8014B910 = 0x8014b910
export const RAM_D_801512DC = 0x801512dc
export const RAM_nuSiSendMesg = 0x8002cf28
export const RAM_pra_31_UnkFunc12 = 0x80240dbc
export const RAM_b_area_kmr_part_2_kmr_03 = 0x80224b7c
export const RAM_iwa_02_CleftAI_Ambush = 0x802403a0
export const RAM_action_command_0A_MashActionCommandInit = 0x802a9170
export const RAM_hdwrBugFlag = 0x80093da0
export const RAM_D_80242370 = 0x80242370
export const RAM_func_802408A0_BD4110 = 0x802408a0
export const RAM_battle_move_shrink_smash_IsRightOnEquipped = 0x802a1050
export const RAM_reset_status_menu = 0x800e9bb0
export const RAM_func_800EFD00 = 0x800efd00
export const RAM_D_802348C0_6BBAC0 = 0x802348c0
export const RAM_HES_POWCostReduced = 0x80107714
export const RAM_kzn_17_SpinyTrompHit = 0x80242d00
export const RAM_D_E00A4B20 = 0xe00a4b20
export const RAM_ui_battle_status_dizzy_3_pal = 0x80102340
export const RAM_SetCustomGfx = 0x802c9428
export const RAM_EE70C0_FlyingAI_Loiter = 0x802413a8
export const RAM_D_09002520_3888D0 = 0x09002520
export const RAM_func_802410B0_D7DC00 = 0x802410b0
export const RAM_trd_01_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_801598A0 = 0x801598a0
export const RAM_D_8010CFF4 = 0x8010cff4
export const RAM_nok_11_TransformFoliage = 0x80241d3c
export const RAM_arn_08_npcGroup_80241638 = 0x80241638
export const RAM_D_80262F68 = 0x80262f68
export const RAM_kpa_01_AwaitPlayerNearNpc = 0x8024097c
export const RAM_HES_Item_BakingCleanser = 0x800873f8
export const RAM_flo_18_lavaResetList_802409E4 = 0x802409e4
export const RAM_D_80247AF0 = 0x80247af0
export const RAM_D_802444B0_9A0E40 = 0x802444b0
export const RAM_dgb_12_D_80240870_C4E760 = 0x80240870
export const RAM_D_802AD10F = 0x802ad10f
export const RAM_mdl_renderModelFogPrimColorR = 0x8014b750
export const RAM_D_E00A4B28 = 0xe00a4b28
export const RAM_partner_initialize_data = 0x800eb580
export const RAM_partner_buff_update = 0xe011a104
export const RAM_flo_09_shakeTree = 0x80242be0
export const RAM_transform_point = 0x80029620
export const RAM_omo_03_IsAOrBPressed = 0x80240538
export const RAM_b_area_mac_lee_statusTable_8021E9A4 = 0x8021e9a4
export const RAM_D_802A23D4_73EC34 = 0x802a23d4
export const RAM_D_802ECAB0 = 0x802ecab0
export const RAM_D_8029D5B0 = 0x8029d5b0
export const RAM_func_80238D48_70CA58 = 0x80238d48
export const RAM_func_80050818 = 0x80050818
export const RAM_D_802AA8B7_425527 = 0x802aa8b7
export const RAM_b_area_arn_hyper_cleft_defenseTable_80220B54 = 0x80220b54
export const RAM_D_80251958_C0F1D8 = 0x80251958
export const RAM_flo_25_802423A0 = 0x802423a0
export const RAM_func_802BD100_317020 = 0x802bd100
export const RAM_D_E02006B4 = 0xe02006b4
export const RAM_thunderbolt_ring_update = 0xe00b0124
export const RAM_IES_Coin = 0x8008cc1c
export const RAM_fio_fetch_saved_file_info = 0x8002b154
export const RAM_D_800DC4F8 = 0x800dc4f8
export const RAM_jan_10_UpdateTexturePanSmooth = 0x80240000
export const RAM_flo_14_UpdateTexturePanSmooth = 0x80240000
export const RAM_b_area_mim_forest_fuzzy_8021A45C = 0x8021a45c
export const RAM_set_item_entity_flags = 0x80134148
export const RAM_b_area_isk_part_1_isk_07_D_80224D90 = 0x80224d90
export const RAM_b_area_jan_hurt_plant = 0x8021da70
export const RAM_flo_00_SyncStatusMenu = 0x8024137c
export const RAM_flower_splash_update = 0xe00103f4
export const RAM_flo_21_npcSettings_80241818 = 0x80241818
export const RAM_gPauseCursorPosX = 0x8024efa0
export const RAM_mac_04_Quizmo_StageEffect = 0x8024f5c0
export const RAM_kzn_17_PiranhaPlantAI_12 = 0x80241818
export const RAM_GetActorVar = 0x8026bfb4
export const RAM_dro_01_dup_Pipe_AwaitDownInput = 0x802435d4
export const RAM_state_drawUI_logos = 0x80034388
export const RAM_func_E00E2210 = 0xe00e2210
export const RAM_EnemyDamageTarget = 0x8027ccb4
export const RAM_b_area_hos_goombario_tutor_80219C74 = 0x80219c74
export const RAM_D_80248CB0_82BF70 = 0x80248cb0
export const RAM_D_802B6360_E2A6A0 = 0x802b6360
export const RAM_dgb_01_D_80251B88_C0F408 = 0x80251b88
export const RAM_D_80153226 = 0x80153226
export const RAM_b_area_hos_goombario_tutor_statusTable_80219068 = 0x80219068
export const RAM_kmr_02_LetterDelivery_CalcLetterPos = 0x80242514
export const RAM_D_80099BF8 = 0x80099bf8
export const RAM_kpa_51_MagikoopaAI_10 = 0x802409d4
export const RAM_flo_10_tree1_Trunk = 0x80244a04
export const RAM_b_area_mac_lee_formation_sushie = 0x802283f0
export const RAM_static_status_init = 0xe00e617c
export const RAM_D_802444A8_9A0E38 = 0x802444a8
export const RAM_gfxRetrace_Callback = 0x8002605c
export const RAM_jan_03_Quizmo_UnkStageEffectMode = 0x8024260c
export const RAM_flame_render = 0xe0040210
export const RAM_D_80154385 = 0x80154385
export const RAM_D_802451B8_87ED98 = 0x802451b8
export const RAM_D_802D9DDC = 0x802d9ddc
export const RAM_D_8014C6A0 = 0x8014c6a0
export const RAM_EC2240_GetItemName = 0x802400f4
export const RAM_func_802B79C8_E20AD8 = 0x802b79c8
export const RAM_D_E0044E58 = 0xe0044e58
export const RAM_D_802EAC0C = 0x802eac0c
export const RAM_dgb_18_PatrolAI_Loiter = 0x802404c0
export const RAM_BindIdle = 0x80278a94
export const RAM_dgb_00_npcGroupList_802436EC = 0x802436ec
export const RAM_GetActionSuccessCopy = 0x802694f8
export const RAM_pra_05_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_HES_Item_AncientVase = 0x80081608
export const RAM_b_area_kzn_medi_guy_80220CEC = 0x80220cec
export const RAM_D_8029D5B8 = 0x8029d5b8
export const RAM_func_80240784_EFF284 = 0x80240784
export const RAM_HES_StatFp_2 = 0x80242afc
export const RAM_func_80243744_7EE454 = 0x80243744
export const RAM_HES_StatusSPIncrement6 = 0x80108360
export const RAM_spr_free_sprite = 0x802de5e8
export const RAM_breaking_junk_init = 0xe01182ec
export const RAM_trd_08_EVS_FireBar_Defeated = 0x80240f2c
export const RAM_flo_16_802451C4 = 0x802451c4
export const RAM_D_09003370 = 0x09003370
export const RAM_IES_Unused_08F = 0x8008bec4
export const RAM_MAMAR_bgm_size = 0x80420460
export const RAM_func_800EFD08 = 0x800efd08
export const RAM_vtx_C27180 = 0x80206240
export const RAM_vtx_C23480 = 0x80202540
export const RAM_kzn_02_LetterDelivery_SavedNpcAnim = 0x80246794
export const RAM_func_80240000_89FEC0 = 0x80240000
export const RAM_dgb_04_SuperBlock_GatherEnergyFX = 0x80242914
export const RAM_omo_17_FlyingAI_ChaseInit = 0x80241c74
export const RAM_obk_01_varStash = 0x802440f0
export const RAM_draw_item_entities_UI = 0x80132d94
export const RAM_flo_23_makeEntities = 0x802425a0
export const RAM_sam_02_Quizmo_VannaTEffect = 0x8024b9b8
export const RAM_b_area_trd_part_2_fake_bowser_handleEvent_8021E6F0 = 0x8021e6f0
export const RAM_dgb_01_D_80252084_C0F904 = 0x80252084
export const RAM_PlayerRasterSetsLoaded = 0x802e004c
export const RAM_FadeBackgroundToBlack = 0x8003ef00
export const RAM_ALT_load_entity_model = 0x80120de4
export const RAM_mim_09_HoppingAI_LosePlayer = 0x80240c4c
export const RAM_dgb_01_D_80252F4C_C107CC = 0x80252f4c
export const RAM_nok_03_HoppingAI_Loiter = 0x80240628
export const RAM_HES_Item_StrangeLeaf_disabled = 0x80086d68
export const RAM_HES_DizzyLoop = 0x801058a4
export const RAM_b_area_omo2_6_shy_guy_dup_statusTable = 0x8023100c
export const RAM_flo_19_80241780 = 0x80241780
export const RAM_b_area_omo_omo_01_beforeBattle_8022DA50 = 0x8022da50
export const RAM_D_E0060740 = 0xe0060740
export const RAM_au_SEFCmd_16 = 0x8004d36c
export const RAM_count_power_plus = 0x80263b10
export const RAM_battle_move_hammer_charge_0_IsHammerMaxCharged = 0x802a10a4
export const RAM_b_area_kgr_fuzzipede_ItemEntityJumpToPos = 0x802180cc
export const RAM_HES_MenuPouch = 0x80292250
export const RAM_pause_badges_draw_contents = 0x80247508
export const RAM_func_802A247C_79843C = 0x802a247c
export const RAM_dro_01_80248504 = 0x80248504
export const RAM_pra_39_SetPartnerFlags20000 = 0x80240f1c
export const RAM_basic_ai_found_player_jump = 0x80049ecc
export const RAM_b_area_isk_part_2_tutankoopa_8021D81C = 0x8021d81c
export const RAM_func_802405F0_8AC580 = 0x802405f0
export const RAM_entity_SaveBlock_wait_for_close_tutorial = 0x802e1270
export const RAM_D_09001610 = 0x09001610
export const RAM_battle_item_egg_missile2_png = 0x802a1af0
export const RAM_D_80242C0E_8819FE = 0x80242c0e
export const RAM_D_80243AD8_EAC5B8 = 0x80243ad8
export const RAM_battle_item_egg_missile_frame2_displayList = 0x802a2280
export const RAM_gCollisionRayLength = 0x800a4254
export const RAM_b_area_kmr_part_1_paragoomba_statusTable_8021B1D4 = 0x8021cbc4
export const RAM_D_802424C0_D2E1F0 = 0x802424c0
export const RAM_func_8021B3B4_6A25B4 = 0x8021b3b4
export const RAM_D_80243BD0_BC7E50 = 0x80243bd0
export const RAM_D_802A198C_73821C = 0x802a19bc
export const RAM_b_area_arn_tubbas_heart_idleAnimations_80223BC0 = 0x80223bc0
export const RAM_D_8014F4CC = 0x8014f4cc
export const RAM_add_badge = 0x800e773c
export const RAM_D_80242A00 = 0x80242a00
export const RAM_HES_Item_SpicySoup = 0x800827d8
export const RAM_battle_partner_goombario_nextTurn_80239A3C = 0x80239a3c
export const RAM_battle_item_thunder_bolt_FadeBackgroundToBlack = 0x802a1388
export const RAM_GetBattleOutcome = 0x8004457c
export const RAM_mdl_renderModelFogPrimColorB = 0x8014b752
export const RAM_D_8014E9A8 = 0x8014e9a8
export const RAM_pause_map_draw_border_arrows = 0x8024da28
export const RAM_load_area_specific_entity_data = 0x80110c14
export const RAM_func_802402A0_8DA3A0 = 0x802402a0
export const RAM_func_802402E0_831AE0 = 0x802402e0
export const RAM_b_area_arn_vector3D_802280C0 = 0x802280c0
export const RAM_arn_11_802400F0 = 0x802400f0
export const RAM_battle_item_sleepy_sheep_GiveRefund = 0x802a1000
export const RAM_kgr_01_add_tongue_deformation = 0x80240160
export const RAM_func_8004DE2C = 0x8004de2c
export const RAM_dro_02_Quizmo_SetVannaAnim_Clap = 0x80240e08
export const RAM_world_bow_init = 0x802bff00
export const RAM_HES_ProjectorBeam = 0x80292c28
export const RAM_battle_star_up_and_away_802A200C = 0x802a200c
export const RAM_sam_07_PiranhaPlantAI_13 = 0x80241c58
export const RAM_btl_popup_messages_delete = 0x8024edec
export const RAM_ui_battle_hp_1_png = 0x8028a9a0
export const RAM_world_sushie_use_ability = 0x802bfdc0
export const RAM_DemoJoystickRadial = 0x802d9b64
export const RAM_HES_Item_XBandage = 0x80087638
export const RAM_b_area_sam2_sam_02b_afterBattle = 0x8022294c
export const RAM_IES_StarPiece = 0x8008cf6c
export const RAM_ui_partner10_png = 0x800ffa60
export const RAM_b_area_sam2_sam_02b_UnkFloatFunc2 = 0x802193dc
export const RAM_sam_08_SuperBlock_GatherEnergyFX = 0x80240544
export const RAM_omo_07_PatrolAI_Chase = 0x80240eb8
export const RAM_pra_13_reflection_render_floor_fancy = 0x80240870
export const RAM_b_area_kzn_putrid_piranha_handleEvent_8021B3D0 = 0x8021b3d0
export const RAM_pra_19_reflection_render_wall = 0x802402f0
export const RAM_D_E004C6A0 = 0xe004c6a0
export const RAM_blast_appendGfx = 0xe007c1f4
export const RAM_dgb_18_npcSettings_802415D0 = 0x802415d0
export const RAM_steam_burst_main = 0xe005c000
export const RAM_b_area_kmr_part_1_paragoomba_sinTable = 0x8021ef6c
export const RAM_flo_18_triggerCoord_8024369C = 0x8024369c
export const RAM_D_09003C78_3AECA8 = 0x09003c78
export const RAM_got_item_outline_render = 0xe00361d8
export const RAM_mac_01_Quizmo_Answers = 0x80248c94
export const RAM_gMessageBuffers = 0x80154430
export const RAM_basic_ai_wander_init = 0x800495a0
export const RAM_mac_04_Quizmo_NPC_Aux_Impl = 0x8024080c
export const RAM_b_area_sam2_sam_02c = 0x80222ccc
export const RAM_D_E00C0C00 = 0xe00c0c00
export const RAM_kzn_08_PiranhaPlantAI_13 = 0x80243b44
export const RAM_b_area_sam2_sam_02b_UnkModelStuffScript2 = 0x8022268c
export const RAM_debuff_render = 0xe00624a8
export const RAM_func_80240790_BBD930 = 0x80240790
export const RAM_obk_05_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_flo_09_foliage_setup_shear_mtx = 0x802400e0
export const RAM_D_80248F20_7F3C30 = 0x80248f20
export const RAM_dro_01_D_802477E4_9629E4 = 0x802477e4
export const RAM_func_802D2884 = 0x802d2884
export const RAM_IES_RedKey = 0x8008ba24
export const RAM_pra_05_GetItemName = 0x80240fd4
export const RAM_D_80099BC8 = 0x80099bc8
export const RAM_b_area_omo_pyro_guy_8022CDDC = 0x8022cddc
export const RAM_D_80244D10_DB2110 = 0x80244d10
export const RAM_pra_10_reflection_render_wall = 0x802402f0
export const RAM_dgb_08_80244D08 = 0x80244d08
export const RAM_func_802403A4_A7A8A4 = 0x802403a4
export const RAM_battle_star_focus_FadeBackgroundToBlack = 0x802a12e4
export const RAM_D_E010CA14 = 0xe010ca14
export const RAM_b_area_kzn_kzn_04c_foregroundModelList_80228E00 = 0x80228e00
export const RAM_pra_01_reflection_setup_wall = 0x80240128
export const RAM_mac_05_UnkAngleFunc001 = 0x8024343c
export const RAM_gItemIconPaletteOffsets = 0x8008e94c
export const RAM_sbk_01_entryList = 0x80240060
export const RAM_mac_00_SetDojoBattle = 0x80242178
export const RAM_mac_01_Quizmo_UpdateRecords = 0x802425fc
export const RAM_strcpy = 0x8006ed40
export const RAM_D_80243D68_A72148 = 0x80243d68
export const RAM_spr_component_update_commands = 0x802dd11c
export const RAM_ui_status_pow_unit_3_png = 0x80100780
export const RAM_ActorSpeak = 0x80252f30
export const RAM_b_area_omo2_light_bulb_onDeath = 0x8022fa54
export const RAM_b_area_omo_sky_guy_handleEvent_8022A398 = 0x8022a398
export const RAM_nok_11_FlyingAI_WanderInit = 0x802407d4
export const RAM_arn_07_80241F10 = 0x80241f10
export const RAM_flo_03_init_8024338C = 0x8024338c
export const RAM_HES_Item_SpinAttack = 0x80085178
export const RAM_bEntityBlueprintSize = 0x80151660
export const RAM_flo_10_pad_4084 = 0x80244084
export const RAM_rmonPrintf = 0x80025d38
export const RAM_D_09003060 = 0x09003060
export const RAM_dgb_09_npcGroupList_8024533C = 0x8024533c
export const RAM_flo_13_interact_802461C0 = 0x802461c0
export const RAM_b_area_trd_part_3_nok_01_afterBattle_80218FA0 = 0x80218fa0
export const RAM_D_8014F55C = 0x8014f55c
export const RAM_gEffectTable = 0x8007f210
export const RAM_b_area_iwa_buzzar_8021CCE8 = 0x8021cce8
export const RAM_dro_02_80244A68 = 0x80244a68
export const RAM_tik_07_SuperBlock_PartnerSparkles4 = 0x80240fa0
export const RAM_SetGoalToHome = 0x80269ec4
export const RAM_kkj_20_ToadHouse_PutPartnerAway = 0x80240550
export const RAM_jan_01_HoppingAI_ChaseInit = 0x80240b0c
export const RAM_func_80240660_B1BBD0 = 0x80240660
export const RAM_pause_map_draw_title = 0x8024e54c
export const RAM_HES_PressAButton = 0x80108bbc
export const RAM_dgb_16_pad_1518 = 0x80241518
export const RAM_tik_24_UnkNpcAIFunc12 = 0x80240880
export const RAM_func_802C739C = 0x802c739c
export const RAM_D_E0080AF0 = 0xe0080af0
export const RAM_b_area_kmr_part_3_kmr_04_afterBattle_80232B80 = 0x80232b80
export const RAM_bzero = 0x80064970
export const RAM_b_area_sam_duplighost_nextTurn = 0x8021b070
export const RAM_HES_Item_YoshiCookie = 0x80083738
export const RAM_b_area_sam_sam_02_afterBattle = 0x8022f7d0
export const RAM_flo_18_func_8024030C_CDC9AC = 0x8024030c
export const RAM_D_802A2DFC = 0x802a2dfc
export const RAM_b_area_omo2_1_shy_squad_idle = 0x80232454
export const RAM_pra_28_reflection_unk_change_anim_facing = 0x80240084
export const RAM_Munchlesia_LaunchVelocity = 0x802b62d0
export const RAM_func_80242E54_C63B64 = 0x80242e54
export const RAM_HES_Item_KookyCookie_disabled = 0x80083528
export const RAM_flo_17_UnkNpcAIFunc12 = 0x802422f4
export const RAM_func_801356D4 = 0x801356d4
export const RAM_sbk_00_npcSettings_8024035C = 0x8024035c
export const RAM_flo_22_TransformFoliage = 0x802400c0
export const RAM_IES_AllorNothing = 0x8008c264
export const RAM_update_entity_shadow_position = 0x80112344
export const RAM_D_802AB184_42C674 = 0x802ab184
export const RAM_dgb_01_FlyingAI_LoiterInit = 0x8024292c
export const RAM_b_area_kmr_part_3_kpa_01 = 0x80234dac
export const RAM_HES_Whirlwind7 = 0x802aa7f0
export const RAM_dgb_04_SentinelAI_Descend = 0x802417f8
export const RAM_ui_box_corners7_png = 0x8010a960
export const RAM_D_800A41E4 = 0x800a41e4
export const RAM_pra_12_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_b_area_kzn_spike_top_idle_8021CC7C = 0x8021cc7c
export const RAM_func_80243D28_8045A8 = 0x80243d28
export const RAM_func_802A9810 = 0x802a9810
export const RAM_ui_green_arrow_up_png = 0x800fc480
export const RAM_dgb_16_npcAISettings_802418AC = 0x802418ac
export const RAM_func_8024564C_A2F88C = 0x8024564c
export const RAM_isk_18_UnkDistFunc = 0x8024007c
export const RAM_nok_01_Quizmo_HideWorld = 0x80241194
export const RAM_trd_00_SomeItemEntityFunc = 0x80240428
export const RAM_dgb_08_FlyingAI_Wander = 0x80241910
export const RAM_b_area_sbk_bandit_2 = 0x8021d700
export const RAM_func_802B6CF0_E2B3A0 = 0x802b6cf0
export const RAM_EB1170_LetterDelivery_CalcLetterPos = 0x8024086c
export const RAM_mac_04_Quizmo_GetCamVfov = 0x80241040
export const RAM_flo_14_func_802407D4_CCBAE4 = 0x802407d4
export const RAM_fold_clear_state_gfx = 0x8013a93c
export const RAM_disable_player_static_collisions = 0x800e0114
export const RAM_b_area_mim_bzzap_takeTurn_8021D28C = 0x8021d28c
export const RAM_D_802A26BC_7672DC = 0x802a26bc
export const RAM_func_8024036C_C9A56C = 0x8024036c
export const RAM_b_area_omo2_toy_tank_handleEvent = 0x8022ce5c
export const RAM_b_area_omo_blue_shy_guy_8021BC2C = 0x8021bc2c
export const RAM_D_8024659C = 0x8024659c
export const RAM_tik_10_SuperBlock_ShowSelectPartnerMenu = 0x8024084c
export const RAM_vtx_C28690 = 0x80207750
export const RAM_D_80234748_6BB948 = 0x80234748
export const RAM_flo_08_GetNpcCollisionHeight = 0x80241744
export const RAM_D_80241BF0_8CC4F0 = 0x80241bf0
export const RAM_D_80243610_ACE130 = 0x80243610
export const RAM_gPausePartnersMoveBase = 0x8024f6f0
export const RAM_kmr_20_Pipe_GetCameraYaw = 0x80240200
export const RAM_gSoundGlobals = 0x8009a5c0
export const RAM_b_area_omo_formation_28 = 0x802305a8
export const RAM_flo_00_flo_07_name_hack = 0x80249360
export const RAM_arn_05_PatrolAI_Chase = 0x8024094c
export const RAM_b_area_sbk_pokey_UnkBattleFunc1 = 0x80218000
export const RAM_gv_01_Pipe_AwaitDownInput = 0x80240034
export const RAM_flo_00_80247FAC = 0x80247fac
export const RAM_flo_24_80241728 = 0x80241728
export const RAM_sam_07_MeleeHitbox_CanSeePlayer = 0x80240858
export const RAM_IES_ITEM_12D = 0x8008d774
export const RAM_flo_00_init_80246170 = 0x80246170
export const RAM_omo_06_SomeItemEntityFunc = 0x80240988
export const RAM_kzn_09_UnkNpcAIFunc12 = 0x80242b74
export const RAM_func_8024157C_BD016C = 0x8024157c
export const RAM_D_8024BC48_C094C8 = 0x8024bc48
export const RAM_HES_Item_AttackFXC = 0x80085cb8
export const RAM_flo_23_npcSettings_80241538 = 0x80241538
export const RAM_func_80240E6C_A27D3C = 0x80240e6c
export const RAM_flo_10_main = 0x802419b4
export const RAM_HES_Item_Items = 0x800807c0
export const RAM_IES_Unused_0D5 = 0x8008c784
export const RAM_set_global_timespace = 0x802c4200
export const RAM_b_area_arn_hyper_cleft_init_80220CAC = 0x80220cac
export const RAM_kpa_15_SomeItemEntityFunc = 0x80240428
export const RAM_b_area_trd_part_2_blue_ninja_koopa_8022B6E8 = 0x8022b6e8
export const RAM_D_E011C500 = 0xe011c500
export const RAM_fx_damage_stars = 0x80070190
export const RAM_tik_09_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_IES_CakeIcing = 0x8008b1e8
export const RAM_state_world_draw_aux_ui = 0x80035dcc
export const RAM_func_802383C0_6F6240 = 0x802383c0
export const RAM_hos_03_GetFloorCollider = 0x80240000
export const RAM_D_090011E0_3275F0 = 0x090011e0
export const RAM_func_802402F0_9867D0 = 0x802402f0
export const RAM_isk_14_HoppingAI_Main = 0x80240bf0
export const RAM_D_802AD0C0 = 0x802ad0c0
export const RAM_D_09001730 = 0x09001730
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_takeTurn_8022936C = 0x8022936c
export const RAM_shim_create_effect_instance = 0xe0200490
export const RAM_trd_07_ParatroopaAI_Overshoot = 0x802421c0
export const RAM_dgb_09_FlyingAI_Main = 0x80242730
export const RAM_update_camera_from_controller = 0x800304fc
export const RAM_mac_04_Quizmo_FadeInWorld = 0x802409d8
export const RAM_jan_14_TransformFoliage = 0x802402f0
export const RAM_subtract_hp = 0x800ea334
export const RAM_action_command_jump_update = 0x802a9234
export const RAM_set_battle_stage = 0x80072ccc
export const RAM_D_800A3520 = 0x800a3520
export const RAM_cos_deg = 0x8002a350
export const RAM_kzn_17_GetNpcCollisionHeight = 0x802426b4
export const RAM_func_8024055C_DEC42C = 0x8024055c
export const RAM_flo_10_D_80244070_CBD270 = 0x80244070
export const RAM_kpa_61_set_script_owner_npc_col_height = 0x80240624
export const RAM_D_802A10B0_734650 = 0x802a10b0
export const RAM_b_area_omo_omo_02_beforeBattle_8022DBA0 = 0x8022dba0
export const RAM_b_area_mac_master1_partsTable_8022A364 = 0x8022a364
export const RAM_b_area_kmr_part_3_kmr_04 = 0x80232b9c
export const RAM_get_spirits_rescued = 0x8002ae40
export const RAM_sam_06_ToadHouse_DoesPlayerNeedSleep = 0x80242110
export const RAM_battle_star_time_out_UnkBackgroundFunc2 = 0x802a137c
export const RAM_func_802406AC_BBD84C = 0x802406ac
export const RAM_D_802B6760_E29190 = 0x802b6760
export const RAM_flo_19_8024169C = 0x8024169c
export const RAM_func_802196F0_5CFD80 = 0x802196f0
export const RAM_D_802AA320 = 0x802aa320
export const RAM_func_802405D4_B1BB44 = 0x802405d4
export const RAM_D_80240C20_A7B120 = 0x80240c20
export const RAM_D_E00EAA50 = 0xe00eaa50
export const RAM_b_area_omo_blue_shy_guy_idle_8021AEB4 = 0x8021aeb4
export const RAM_kzn_17_UnkDistFunc = 0x80241d30
export const RAM_is_another_trigger_bound = 0x80145c6c
export const RAM_b_area_arn_hypergoomba_CalculateRotationZ = 0x802181e4
export const RAM_jan_08_MeleeHitbox_32 = 0x80241744
export const RAM_func_80240000_B1B570 = 0x80240000
export const RAM_func_802B2000 = 0x802b2000
export const RAM_sam_02_Quizmo_SetVannaAnim_Idle = 0x8024161c
export const RAM_battle_item_stop_watch_GiveRefundCleanup = 0x802a11d4
export const RAM_jan_07_SpearGuyAI_Loiter = 0x80240050
export const RAM_GetModelIndex = 0x802c8e8c
export const RAM_hos_03_Quizmo_GetCamVfov = 0x80243084
export const RAM_spiky_white_aura_main = 0xe0038000
export const RAM_pause_partners_draw_movelist_title = 0x8024c048
export const RAM_pra_01_reflection_render_floor_fancy = 0x80240870
export const RAM_sam_01_Quizmo_NPC_OnRender = 0x80240acc
export const RAM_CopyStatusEffects = 0x8027dcbc
export const RAM_dgb_01_exitDoubleDoor_80243820 = 0x80243820
export const RAM_EFEB00_UnkFloatFunc = 0x802407b0
export const RAM_mac_00_Quizmo_HideEntities = 0x80240748
export const RAM_b_area_pra3_duplighost_lakilester_handleEvent = 0x802253dc
export const RAM_D_80245358_EEB908 = 0x80245358
export const RAM_dgb_03_npcAISettings_80243B28 = 0x80243b28
export const RAM_D_8014BC28 = 0x8014bc28
export const RAM_func_80242FA0_B37B70 = 0x80242fa0
export const RAM_func_80243ED0_A2E110 = 0x80243ed0
export const RAM_rodata_padding = 0x8026ff74
export const RAM_flo_08_SuperBlock_PartnerSparkles2 = 0x80240c78
export const RAM_update_shadows = 0x8010fa98
export const RAM_D_8014C028 = 0x8014c028
export const RAM_ui_battle_cont_c_up_down_pal = 0x8028c430
export const RAM_pra_12_SetPartnerFlags80000 = 0x80240ee8
export const RAM_b_area_kmr_part_3_kmr_03_afterBattle_80232730 = 0x80232730
export const RAM_D_09002B20 = 0x09002b20
export const RAM_b_area_omo_spy_guy_SpyGuyActionFunc = 0x80218cf4
export const RAM_D_80241680_BAB7C0 = 0x80241680
export const RAM_virtual_entity_render_quad = 0x802d2ed4
export const RAM_D_802DF3D0 = 0x802df3d0
export const RAM_battle_item_food_DrinkItem = 0x802a1b74
export const RAM_set_model_env_color_parameters = 0x8011bf98
export const RAM_arn_04_npcGroupList_80244000 = 0x80244000
export const RAM_D_8024A9C8_A34C08 = 0x8024a9c8
export const RAM_dgb_01_D_80253260_C10AE0 = 0x80253260
export const RAM_IES_MegaJump = 0x8008c524
export const RAM_D_80109480 = 0x80109480
export const RAM_b_area_jan2_jan_01b_beforeBattle_8021F1A4 = 0x8021f1a4
export const RAM_omo_04_CheckItemFlags40 = 0x80240268
export const RAM_mac_02_TransformFoliage = 0x80240720
export const RAM_sam_05_MeleeHitbox_32 = 0x802400dc
export const RAM_dgb_09_settings = 0x80243840
export const RAM_D_802B6E78_E2B528 = 0x802b6e78
export const RAM_dgb_08_MeleeHitbox_32 = 0x80240f6c
export const RAM_D_8009BAA4 = 0x8009baa4
export const RAM_sweat_appendGfx = 0xe0050308
export const RAM_D_80247448_B3C018 = 0x80247448
export const RAM_dgb_09_npcAISettings_802440EC = 0x802440ec
export const RAM_b_area_omo_omo_02_afterBattle_8022DBDC = 0x8022dbdc
export const RAM_kpa_102_FlyingMagikoopaAI_50 = 0x80243198
export const RAM_func_80240534_DCD204 = 0x80240534
export const RAM_fx_squirt = 0x800719f0
export const RAM_HES_Item_VoltShroom_disabled = 0x80086468
export const RAM_b_area_iwa_buzzar_8021DF2C = 0x8021df2c
export const RAM_sbk_01_settings = 0x802400a0
export const RAM_mac_02_Quizmo_ShowEntities = 0x802418ec
export const RAM_osUnmapTLB = 0x80066920
export const RAM_D_80109488 = 0x80109488
export const RAM_func_802BB34C_E2DC7C = 0x802bb34c
export const RAM_b_area_omo2_omo_07 = 0x8022fb6c
export const RAM_dro_02_init_8024BF10 = 0x8024bf10
export const RAM_func_8005736C = 0x8005736c
export const RAM_b_area_omo_yellow_shy_guy_idleAnimations_8021C91C = 0x8021c91c
export const RAM_sbk_34_StashVars = 0x80240040
export const RAM_trd_02_set_script_owner_npc_anim = 0x802400e0
export const RAM_kpa_102_FlyingMagikoopaAI_15 = 0x802425d8
export const RAM_pause_tabs_init = 0x80245288
export const RAM_dgb_00_npcGroup_802425B4 = 0x802425b4
export const RAM_D_8029D390 = 0x8029d390
export const RAM_flo_22_npcSettings_80240520 = 0x80240520
export const RAM_flo_14_vectorList_80243384 = 0x80243384
export const RAM_tik_07_SuperBlock_StartGlowEffect = 0x80240760
export const RAM_ui_battle_status_static_1_pal = 0x80101f80
export const RAM_D_802AD4B3 = 0x802ad4b3
export const RAM_battle_star_chill_out_FadeBackgroundToBlack = 0x802a12e4
export const RAM_gPausePanelBadges = 0x8024f54c
export const RAM_dgb_01_D_80252BDC_C1045C = 0x80252bdc
export const RAM_mdl_get_child_count = 0x80115ca8
export const RAM_func_802A9378 = 0x802a9378
export const RAM_b_area_kzn_spike_top_8021D57C = 0x8021d57c
export const RAM_au_bgm_stop_player = 0x8004daa8
export const RAM_HES_Item_TubbaCastleKey = 0x800814b8
export const RAM_D_80244EB8_C36398 = 0x80244eb8
export const RAM_state_init_exit_file_select = 0x80035660
export const RAM_GetBattleFlags = 0x8026cd10
export const RAM_MusicDefaultVolume = 0x80159ae0
export const RAM_b_area_pra3_duplighost_kooper_defenseTable = 0x8021d794
export const RAM_dead_func_80041080 = 0x80041080
export const RAM_hos_03_Quizmo_CreateWorker = 0x80243508
export const RAM_appendGfx_entity_model = 0x80121184
export const RAM_flo_00_npcGroup_80244E64 = 0x80244e64
export const RAM_flo_15_npcGroup_80240EE0 = 0x80240ee0
export const RAM_battle_item_electro_pop_func_802A1450_7309F0 = 0x802a1450
export const RAM_battle_item_thunder_rage_GiveRefund = 0x802a1000
export const RAM_sin_rad = 0x8002a16c
export const RAM_dup_mac_01_PatrolAI_Move = 0x802456a8
export const RAM_dgb_03_init_80243D94 = 0x80243d94
export const RAM_battle_menu_centeredMessagesB = 0x802ab4c8
export const RAM_D_80234920_6BBB20 = 0x80234920
export const RAM_IES_GroupFocus = 0x8008ca04
export const RAM_omo_05_ShyGuyWanderAI_17 = 0x802401d8
export const RAM_HES_AimMarkerB = 0x80292db4
export const RAM_entity_HitItemBlock_show_inactive = 0x802e4108
export const RAM_b_area_trd_part_1_bob_omb_defenseTable_8021A5A8 = 0x8021a5a8
export const RAM_big_smoke_puff_init = 0xe00021b4
export const RAM_kmr_02_UnkNpcPosFunc = 0x80242860
export const RAM_b_area_omo2_5_shy_squad_dup_displace_guy = 0x8023233c
export const RAM_D_800A3628 = 0x800a3628
export const RAM_b_area_sam2_monstar_nextTurn = 0x8021b384
export const RAM_flo_21_80241580 = 0x80241580
export const RAM_mim_05_MeleeHitbox_31 = 0x802400dc
export const RAM_func_80057B64 = 0x80057b64
export const RAM_dup_b_area_kpa2_GetItemEntityPosition = 0x80219f5c
export const RAM_D_09000A00_350C20 = 0x09000a00
export const RAM_kzn_22_UpdateTexturePanSmooth = 0x80240000
export const RAM_arn_07_SetEntityFlags100000 = 0x80240870
export const RAM_pad = 0x80098474
export const RAM_D_80243440_867F80 = 0x80243440
export const RAM_damage_indicator_update = 0xe003c244
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021CB48 = 0x8021d8c8
export const RAM_jan_03_Pipe_AwaitDownInput = 0x80240034
export const RAM_b_area_omo_formation_1C = 0x802301d4
export const RAM_osr_maps = 0x800919d0
export const RAM_get_entity_by_index = 0x801105e8
export const RAM_D_80242C00 = 0x80242c00
export const RAM_arn_05_init_80244774 = 0x80244774
export const RAM_D_E0022D68 = 0xe0022d68
export const RAM_dgb_01_D_80251BE0_C0F460 = 0x80251be0
export const RAM_b_area_trd_part_1_paratroopa_defenseTable_8021EAFC = 0x8021eafc
export const RAM_HES_Item_Unused_0D6_disabled = 0x80085448
export const RAM_sparkles_render = 0xe0022960
export const RAM_b_area_kmr_part_3_final_jr_troopa_802318F8 = 0x802318f8
export const RAM_omo_08_SetAngleClamped = 0x8024001c
export const RAM_D_80244718_B5DF88 = 0x80244718
export const RAM_b_area_pra3_red_magikoopa_GetSelectedMoveID = 0x80219988
export const RAM_mac_02_Quizmo_CreateReactionEffect = 0x802427b8
export const RAM_nok_11_FlyingAI_Wander = 0x80240984
export const RAM_flo_18_8024527C = 0x8024527c
export const RAM_D_E00EAA58 = 0xe00eaa58
export const RAM_gPauseTutorialViewport = 0x8024f100
export const RAM_b_area_iwa_iwa_02_whacka = 0x80221744
export const RAM_func_80218BA0_4E32E0 = 0x80218ba0
export const RAM_omo_17_FlyingAI_Init = 0x8024219c
export const RAM_b_area_mac_lee_defenseTable_802222DC = 0x802222dc
export const RAM_battle_action_cmd_whirlwind_4_png = 0x802a9ca0
export const RAM_dro_01_8024CCC4 = 0x8024ccc4
export const RAM_osr_01_UpdateTexturePanStepped = 0x80240174
export const RAM_D_8014C6F0 = 0x8014c6f0
export const RAM_render_screen_overlay_frontUI = 0x801381a0
export const RAM_func_80240F68_A17188 = 0x80240f68
export const RAM_nuScGetFrameRate = 0x8005ef9c
export const RAM_SetAnimatedModelRootRotation = 0x802cd1f0
export const RAM_b_area_iwa_buzzar_statusTable_8021B65C = 0x8021b65c
export const RAM_tik_17_Pipe_AwaitDownInput = 0x80240da4
export const RAM_dup_b_area_kpa4_UnkBattleFunc1 = 0x80218020
export const RAM_D_8029F640 = 0x8029f640
export const RAM_func_80053F64 = 0x80053f64
export const RAM_b_area_pra3_formation_06 = 0x8023263c
export const RAM_dgb_05_ClubbaNappingAI_Main = 0x80240e80
export const RAM_b_area_sam_frost_piranha_init = 0x8022ac10
export const RAM_func_80240CD8_EA97B8 = 0x80240cd8
export const RAM_EB8E90_PostChapter6StatUpdate = 0x80240efc
export const RAM_flo_14_FlyingAI_JumpInit = 0x802416ec
export const RAM_b_area_mim_forest_fuzzy_specialFormation_8021A800 = 0x8021a800
export const RAM_kmr_03_treeModelList_Tree1_Leaves = 0x8024217c
export const RAM_EEDF50_FlyingAI_Jump = 0x80241aa4
export const RAM_dead_get_npc_by_index = 0x8003c43c
export const RAM_dro_02_func_802414C0_96A680 = 0x802414c0
export const RAM_pra_19_SetPartnerFlags80000 = 0x80240eb8
export const RAM_mim_06_PiranhaPlantAI_12 = 0x80241a78
export const RAM_set_screen_overlay_alpha = 0x801380d4
export const RAM_b_area_kmr_part_3_final_jr_troopa_idleAnimations_8022CC68 = 0x8022cc68
export const RAM_b_area_trd_part_2_trd_05e_80234D80 = 0x80234d80
export const RAM_D_80093D80 = 0x80093d80
export const RAM_D_09000200_364E00 = 0x09000200
export const RAM_BasicPeachDisguiseAnims = 0x800f7c1c
export const RAM_b_area_kmr_part_2_goomnut_tree_D_80223D60_41D7F0 = 0x80223d60
export const RAM_b_area_iwa_iwa_02_idleAnimations_8022161C = 0x8022161c
export const RAM_D_80241E90_C3FA80 = 0x80241e90
export const RAM_mac_05_PatrolAI_MoveInit = 0x80240490
export const RAM_firework_rocket_update = 0xe010a18c
export const RAM_D_802F4560 = 0x802f4560
export const RAM_D_8029D398 = 0x8029d398
export const RAM_b_area_jan_spear_guy = 0x80218880
export const RAM_func_80240540_BC47C0 = 0x80240540
export const RAM_D_80246510_C60C50 = 0x80246510
export const RAM_b_area_jan_hurt_plant_statusTable_8021D9A0 = 0x8021d9a0
export const RAM_mac_02_LetterDelivery_CalcLetterPos = 0x802431f4
export const RAM_dgb_01_8024517C = 0x8024517c
export const RAM_EDEA00_SpinyAI_Main = 0x80242af0
export const RAM_func_800EF43C = 0x800ef43c
export const RAM_iwa_10_SuperBlock_EndGlowEffect = 0x80240800
export const RAM_D_80232BC8_684948 = 0x80232bc8
export const RAM_func_802405A8_8AC538 = 0x802405a8
export const RAM_pra_maps = 0x80092cd0
export const RAM_vtx_C21B20 = 0x80200be0
export const RAM_dgb_16_ClubbaNappingAI_ReturnHome = 0x80240c4c
export const RAM_D_80243438_867F78 = 0x80243438
export const RAM_b_area_tik3_UnkBattleFunc1 = 0x80218000
export const RAM_ui_battle_hp_7_png = 0x8028ab20
export const RAM_ai_check_fwd_collisions = 0x8004a784
export const RAM_ui_battle_cmd_aim_shimmer_7_pal = 0x8028ef60
export const RAM_b_area_kzn_red_magikoopa_UnkFunc52 = 0x802188d8
export const RAM_init_enter_world_shared = 0x80035e54
export const RAM_b_area_omo_green_shy_guy_UnkBattleFunc1 = 0x80218340
export const RAM_b_area_mac_lee_statusTable_802259D4 = 0x802259d4
export const RAM_dgb_01_npcGroup_80246A70 = 0x80246a70
export const RAM_D_80257F27 = 0x80257f27
export const RAM_end_01_credits_worker_render = 0x802421e8
export const RAM_battle_item_snowman_doll_func_802A13B8_71C1E8 = 0x802a13b8
export const RAM_D_80243078_9B1778 = 0x80243078
export const RAM_aura_init = 0xe0076370
export const RAM_mac_02_PatrolAI_Move = 0x80240a88
export const RAM_flo_10_pad_1624 = 0x80241624
export const RAM_dup_b_area_tik_ArcsinDeg = 0x80218480
export const RAM_kzn_23_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_jan2_putrid_piranha_UnkBattleFunc1 = 0x80218000
export const RAM_jan_00_LetterDelivery_SavedNpcAnim = 0x80247cb4
export const RAM_D_802D9E58 = 0x802d9e58
export const RAM_btl_is_popup_displayed = 0x8024fab4
export const RAM_b_area_omo_medi_guy_flyingAttack = 0x80223d90
export const RAM_dead_fx_motion_blur_flame = 0x80076970
export const RAM_D_80249A48_A33C88 = 0x80249a48
export const RAM_dist3D = 0x80029f2c
export const RAM_dgb_14_main = 0x8024038c
export const RAM_D_802B7D90_E20EA0 = 0x802b7d90
export const RAM_guMtxL2F = 0x80067580
export const RAM_flo_12_D_802429E0 = 0x802429e0
export const RAM_b_area_dgb_tubba_blubba_statusTable_80218C78 = 0x80218c78
export const RAM_kpa_113_func_802408B4_97BEE4 = 0x80240a24
export const RAM_D_E0116D3C = 0xe0116d3c
export const RAM_arn_03_interact_80241EB0 = 0x80241eb0
export const RAM_D_E0200730 = 0xe0200730
export const RAM_kzn_01_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_80242450 = 0x80242450
export const RAM_func_8005610C = 0x8005610c
export const RAM_D_8024F948_C0D1C8 = 0x8024f948
export const RAM_D_802A1204_0073B0D4 = 0x802a1204
export const RAM_D_802BFF1C = 0x802bff1c
export const RAM_cold_breath_appendGfx = 0xe00de2a8
export const RAM_func_80240748_8AAB98 = 0x80240748
export const RAM_b_area_pra2_crystal_bit_parts_1 = 0x8022056c
export const RAM_trd_01_StationaryAI_ChaseInit = 0x80240e70
export const RAM_D_80245954_B25E94 = 0x80245954
export const RAM_boot_main = 0x80025f70
export const RAM_flo_21_UnsetCamera0MoveFlag1 = 0x80240cd0
export const RAM_isk_16_StarSpiritEffectFunc2 = 0x8024004c
export const RAM_dgb_01_GetEntityPosition = 0x80240074
export const RAM_ui_battle_status_happy_pal = 0x80103720
export const RAM_D_802B7C78_E23228 = 0x802b7c78
export const RAM_hos_03_UpdateTexturePanStepped = 0x80240154
export const RAM_dgb_08_npcSettings_80244150 = 0x80244150
export const RAM_HES_Item_Unused_0DE = 0x80085718
export const RAM_D_80245D58_9FA798 = 0x80245d58
export const RAM_clear_animator_list = 0x8011e224
export const RAM_D_8024D700_C0AF80 = 0x8024d700
export const RAM_gMsgUnkArrowQuad = 0x8014c480
export const RAM_mac_02_PatrolAI_MoveInit = 0x80240930
export const RAM_GetAnimatedModelRootPosition = 0x802cd090
export const RAM_snd_song_change_track_volume = 0x80055f58
export const RAM_b_area_dig_UnkBattleFunc2 = 0x802180d0
export const RAM_pra_01_reflection_render_floor = 0x8024068c
export const RAM_b_area_pra3_gray_magikoopa_ShrinkActor = 0x80219ba0
export const RAM_D_E00D0B10 = 0xe00d0b10
export const RAM_D_E0048A10 = 0xe0048a10
export const RAM_b_area_omo2_1_shy_squad_parts = 0x80231168
export const RAM_b_area_kpa2_GetJumpHammerCharge = 0x8021c974
export const RAM_dro_01_Quizmo_UpdateRecords = 0x80241b5c
export const RAM_D_09001738 = 0x09001738
export const RAM_D_09001368 = 0x09001368
export const RAM_b_area_sam2_gray_magikoopa_handleEvent_flying = 0x80220054
export const RAM_ui_lakilester_pal = 0x800ff7e0
export const RAM_D_802465D0_C8C080 = 0x802465d0
export const RAM_D_090015D0 = 0x090015d0
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021B948 = 0x8021b948
export const RAM_EED1E0_SuperBlock_PartnerSparkles4 = 0x80240da0
export const RAM_clamp_angle = 0x80029b24
export const RAM_b_area_trd_part_1_formation_07 = 0x80218688
export const RAM_flo_09_tree1_Trunk = 0x8024340c
export const RAM_iwa_03_varStash = 0x80241530
export const RAM_func_800F48F4 = 0x800f48f4
export const RAM_MakeDoorAdvanced = 0x80281cac
export const RAM_kmr_02_LetterDelivery_Init = 0x80242424
export const RAM_D_80242930_871430 = 0x80242930
export const RAM_ChangeModelAnimation = 0x802ccf6c
export const RAM_entity_SuperBlockContent_idle = 0x802e5e50
export const RAM_ui_partner11_disabled_pal = 0x800ffec0
export const RAM_b_area_sam_sam_01_foregroundModelList = 0x8022f3a8
export const RAM_kpa_52_ProjectileHitbox_33 = 0x802405f8
export const RAM_ui_status_pow_unit_4_pal = 0x801007e0
export const RAM_D_8014F940 = 0x8014f940
export const RAM_D_80241ECC = 0x80241ecc
export const RAM_HES_Item_PowerBounce_disabled = 0x80083b88
export const RAM_SetItemPos = 0x802d70d0
export const RAM_arn_03_AddPlayerHandsOffset = 0x80241424
export const RAM_D_802DBC88 = 0x802dbc88
export const RAM_battle_menu_moveScrollLine = 0x802ad10c
export const RAM_dgb_01_npcGroup_80246090 = 0x80246090
export const RAM_b_area_pra2_crystal_king_init = 0x80218b40
export const RAM_func_802BAE00_E2D730 = 0x802bae00
export const RAM_kzn_08_UpdateTexturePanSmooth = 0x8024040c
export const RAM_battle_move_hammer_charge_1_IsRightOnEquipped = 0x802a1050
export const RAM_flo_25_FlyingAI_JumpVels = 0x8024291c
export const RAM_b_area_sbk_bandit = 0x8021d6d8
export const RAM_SetSelfVar = 0x8004525c
export const RAM_b_area_pra3_pra_02 = 0x80231eec
export const RAM_dgb_01_D_802527E8_C10068 = 0x802527e8
export const RAM_b_area_jan_spear_guy_idleAnimations_802185C0 = 0x802185c0
export const RAM_dgb_00_func_80240000_BFA0A0 = 0x80240000
export const RAM_b_area_kmr_part_3_jr_troopa_idleAnimations_80222C6C = 0x80222c6c
export const RAM_sbk_36_TransformFoliage = 0x802400b0
export const RAM_D_8029F648 = 0x8029f648
export const RAM_b_area_isk_part_1_swooper_idleAnimations_8021C600 = 0x8021c600
export const RAM_vtx_C2D440 = 0x8020c500
export const RAM_battle_star_lullaby_FadeBackgroundToBlack = 0x802a12e4
export const RAM_battle_move_multibounce_D_802A2184 = 0x802a2184
export const RAM_kpa_14_GetEntityPosition = 0x80240670
export const RAM_b_area_pra2_crystal_king_StartRumbleWithParams = 0x80218000
export const RAM_dro_01_npcList_8024C200 = 0x8024c200
export const RAM_arn_05_init_8024212C = 0x8024212c
export const RAM_jan_14_HoppingAI_Hop = 0x802406f0
export const RAM_trd_04_KoopaPatrolAI_Main = 0x80241b14
export const RAM_nuGfxCfb_ptr = 0x8009a64c
export const RAM_kpa_91_PatrolAI_Loiter = 0x802404c0
export const RAM_flo_13_LoadPartyImage = 0x802435d0
export const RAM_hos_03_Quizmo_UpdateRecords = 0x80242b90
export const RAM_func_80240B98_A402D8 = 0x80240b98
export const RAM_b_area_sam2_gray_magikoopa_idleAnimations2 = 0x8021f3cc
export const RAM_mim_06_MeleeHitbox_30 = 0x80240570
export const RAM_D_80245604 = 0x80245604
export const RAM_b_area_omo_pink_shy_guy_defenseTable_8021E310 = 0x8021e310
export const RAM_IES_RuinsKey = 0x8008b504
export const RAM_jan_03_TransformFoliage = 0x802430c0
export const RAM_IES_KoopaFortressKey = 0x8008b4e4
export const RAM_jan_10_HoppingAI_HopInit = 0x80240310
export const RAM_bgm_quiet_max_volume = 0x8014ac5c
export const RAM_D_80078DCC = 0x80078dcc
export const RAM_ui_battle_menu_nothing_pal = 0x80289f00
export const RAM_kpa_08_MagikoopaAI_11 = 0x80240b64
export const RAM_D_8014F948 = 0x8014f948
export const RAM_HES_StatusStar1 = 0x801083d8
export const RAM_EnablePartnerBlur = 0x802456b8
export const RAM_EB8E90_PatrolAI_PostLoiter = 0x802406bc
export const RAM_D_802A25F4 = 0x802a25f4
export const RAM_arn_05_init_802421EC = 0x802421ec
export const RAM_IES_SpinAttack = 0x8008c6c4
export const RAM_b_area_omo_omo_03_beforeBattle_8022DC20 = 0x8022dc20
export const RAM_b_area_arn_hyper_paragoomba_8021C644 = 0x8021c644
export const RAM_mgm_02_GetCoinCount = 0x80242314
export const RAM_kzn_09_SuperBlock_UpgradeDescMessages = 0x80243d18
export const RAM_battle_partner_goombario_80238510 = 0x80238510
export const RAM_ParakarryTweesterPhysicsPtr = 0x802beaac
export const RAM_omo_17_SetNpcB5_3 = 0x802436c0
export const RAM_b_area_kmr_part_2_blue_goomba_2_CalculateRotationZ = 0x8021868c
export const RAM_dgb_15_npcGroup_802428C0 = 0x802428c0
export const RAM_b_area_omo_red_shy_guy_idle_802193B4 = 0x802193b4
export const RAM_HES_MashBButton1 = 0x80292374
export const RAM_mac_03_Quizmo_SetCamVfov = 0x8024101c
export const RAM_osMotorInit = 0x80067f44
export const RAM_NAMESPACE_80239DC8 = 0x80239dc8
export const RAM_D_E020070C = 0xe020070c
export const RAM_flo_00_pad_5E2C = 0x80245e2c
export const RAM_D_09002560_388910 = 0x09002560
export const RAM_func_8002AA2C = 0x8002aa2c
export const RAM_D_09002440 = 0x09002440
export const RAM_dro_01_npcList_8024C208 = 0x8024c208
export const RAM_D_E0022D60 = 0xe0022d60
export const RAM_player_reset_data = 0x800dfc74
export const RAM_b_area_kzn_white_magikoopa_idleAnimations_80223D70 = 0x80223d70
export const RAM_func_80241248_9407F8 = 0x80241248
export const RAM_kzn_09_PiranhaPlantAI_13 = 0x80242628
export const RAM_hos_02_FlyingAI_Init = 0x802421d8
export const RAM_b_area_sam_gulpit_rocks = 0x802295c4
export const RAM_mgm_01_BlockPosX = 0x80241ac8
export const RAM_flo_03_init_80241E10 = 0x80241e10
export const RAM_osSpTaskLoad = 0x80065a10
export const RAM_GetActorHP = 0x8027c6a0
export const RAM_ui_battle_status_poison_0_png = 0x80102360
export const RAM_flo_08_80245990 = 0x80245990
export const RAM_jan_09_UpdateTexturePanSmooth = 0x802402e0
export const RAM_b_area_isk_part_1_formation_03 = 0x80224194
export const RAM_battle_item_electro_pop_GiveRefundCleanup = 0x802a11d4
export const RAM_D_802AD0B3 = 0x802ad0b3
export const RAM_HES_Item_DizzyStomp = 0x80084218
export const RAM_func_80240DF8_A2B038 = 0x80240df8
export const RAM_battle_item_egg_missile4_pal = 0x802a2130
export const RAM_func_802402C8_8FF148 = 0x802402c8
export const RAM_kmr_06_FlyingAI_ChaseInit = 0x80240cc0
export const RAM_flo_15_802404D8 = 0x802404d8
export const RAM_HES_StatusDigit6 = 0x801081a8
export const RAM_D_80151270 = 0x80151270
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_UnkEnemyPosFunc = 0x80219cf0
export const RAM_flo_19_80241650 = 0x80241650
export const RAM_arn_03_80241CD4 = 0x80241cd4
export const RAM_kzn_20_varStash = 0x80241780
export const RAM_func_802BDDF0_324740 = 0x802bddf0
export const RAM_HES_Item_Boots1 = 0x80080730
export const RAM_dead_dist3D = 0x8002b21c
export const RAM_battle_item_dizzy_dial_main = 0x802a198c
export const RAM_D_80244FA0_ADAE70 = 0x80244fa0
export const RAM_func_802402D8_D7F038 = 0x802402d8
export const RAM_HES_TimingBlink = 0x802927b4
export const RAM_kzn_19_LetterDelivery_SaveNpcAnim = 0x80241b50
export const RAM_battle_action_cmd_water_block_4_pal = 0x802ab020
export const RAM_D_09000000_330460 = 0x09000000
export const RAM_Entity_ShatteringBrickBlock = 0x802e9d64
export const RAM_b_area_pra3_duplighost_watt_parts = 0x80222a78
export const RAM_b_area_omo_pyro_guy = 0x8022cc58
export const RAM_func_80241EE0_9FC6D0 = 0x80241ee0
export const RAM_D_8010BBB4 = 0x8010bbb4
export const RAM_func_80218BE8_59F2C8 = 0x80218be8
export const RAM_snowflake_update = 0xe001c128
export const RAM_b_area_omo3_UnkBattleFunc1 = 0x80218ae0
export const RAM_IES_ITEM_13F = 0x8008b3c4
export const RAM_omo_09_UnsetCamera0Flag1000 = 0x802435e8
export const RAM_D_E00E29F4 = 0xe00e29f4
export const RAM_isk_16_UnkFunc27 = 0x80240800
export const RAM_D_802A28B0_739140 = 0x802a28e0
export const RAM_dgb_07_ClubbaNappingAI_Sleep = 0x802406a4
export const RAM_iwa_03_CleftAI_CanSeePlayer = 0x80240340
export const RAM_filemenu_main_update = 0x80246b2c
export const RAM_fx_smoke_burst = 0x80070730
export const RAM_b_area_kmr_part_2_kmr_04_beforeBattle_80224F38 = 0x80224f38
export const RAM_HES_FrozenEnd = 0x80106148
export const RAM_b_area_kmr_part_1_kmr_02_beforeBattle_8021FDA0 = 0x8021fda0
export const RAM_ui_sushie_png = 0x800ff3a0
export const RAM_b_area_kmr_part_2_blue_goomba_2_DivActorLevel4 = 0x80218780
export const RAM_world_watt_init = 0x802bd23c
export const RAM_flo_16_FlyingAI_LosePlayer = 0x80241ca8
export const RAM_battle_item_dizzy_dial_GiveRefundCleanup = 0x802a11d4
export const RAM_shim_guScaleF = 0xe0200440
export const RAM_b_area_trd_part_1_trd_03_UnkAngleFunc2 = 0x80218220
export const RAM_DoNormalHit = 0x80299044
export const RAM_HES_Item_Unused_08D = 0x800823b8
export const RAM_dup_kzn_22_GetFloorCollider = 0x80240340
export const RAM_gPauseDescTextMaxPos = 0x8027010c
export const RAM_D_802705D0 = 0x802705d0
export const RAM_dgb_04_SuperBlock_EndGlowEffect = 0x802428e0
export const RAM_sam_02_Pipe_GetCurrentFloor = 0x80240020
export const RAM_D_802BCF78_E2D6A8 = 0x802bcf78
export const RAM_D_802427C8_A517C8 = 0x802427c8
export const RAM_can_trigger_loading_zone = 0x800e26c4
export const RAM_D_80153A52 = 0x80153a52
export const RAM_btl_state_draw_end_demo_battle = 0x80248d78
export const RAM_D_80244820_9BB1A0 = 0x80244820
export const RAM_D_8008EEF0 = 0x8008eef0
export const RAM_decode_yay0 = 0x8006edf0
export const RAM_dgb_01_D_802537A0_C11020 = 0x802537a0
export const RAM_gSpinHistoryPosX = 0x8010ef28
export const RAM_gBattleItemTable = 0x80293c04
export const RAM_b_area_trd_part_2_red_ninja_koopa_UnkWhirlwindEffectFunc = 0x80218bf8
export const RAM_b_area_pra3_duplighost_takeTurn = 0x80226cc0
export const RAM_mim_03_SetForeverForestFog = 0x80240000
export const RAM_EED1E0_SuperBlock_GetPartnerRank = 0x80240148
export const RAM_b_area_sam_duplighost_goombario_takeTurn = 0x8021e604
export const RAM_pink_sparkles_update = 0xe0124304
export const RAM_ui_battle_cmd_aim_marker_5_png = 0x8028e8a0
export const RAM_D_E0088D98 = 0xe0088d98
export const RAM_func_80240398_90FA48 = 0x80240398
export const RAM_InterpPlayerYaw = 0x802d193c
export const RAM_HES_Item_Berserker = 0x80084938
export const RAM_mac_01_Quizmo_UnkStageEffectMode = 0x80242980
export const RAM_battle_item_food_EatItem = 0x802a1afc
export const RAM_omo_15_StarSpiritEffectFunc3 = 0x802401ac
export const RAM_throw_spiny_render = 0xe00c8404
export const RAM_D_802440F0 = 0x802440f0
export const RAM_tik_06_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_flo_03_npcGroup_8024423C = 0x8024423c
export const RAM_arn_12_enterSingleDoor_80240290 = 0x80240290
export const RAM_b_area_hos_magikoopa_statusTable_802214CC = 0x802214cc
export const RAM_b_area_kzn_spike_top_idleAnimations_8021CA1C = 0x8021ca1c
export const RAM_D_802440F8 = 0x802440f8
export const RAM_HES_Item_KoopaTea = 0x80082ef8
export const RAM_ui_partner9_disabled_pal = 0x800ffa40
export const RAM_dro_01_Quizmo_ShouldAppear = 0x802415ac
export const RAM_D_8024D680_C0AF00 = 0x8024d680
export const RAM_jan_03_AddPlayerHandsOffset = 0x80241808
export const RAM_check_input_use_partner = 0x800dfa00
export const RAM_func_80240B00_900170 = 0x80240b00
export const RAM_gPlayerStatus = 0x8010efc8
export const RAM_D_802DB264 = 0x802db264
export const RAM_battle_star_refresh_UnkBackgroundFunc = 0x802a1414
export const RAM_sam_06_FlyingAI_Wander = 0x80240700
export const RAM_flo_14_802423F0 = 0x802423f0
export const RAM_jan_02_Quizmo_GetCamVfov = 0x802421f4
export const RAM_ui_battle_cmd_aim_marker_2_pal = 0x8028e740
export const RAM_dro_02_8024B18C = 0x8024b18c
export const RAM_hos_06_LetterDelivery_Init = 0x8024077c
export const RAM_kkj_01_PatrolAI_JumpInit = 0x80240730
export const RAM_kzn_19_StarSpiritEffectFunc1 = 0x8024030c
export const RAM_evt_handle_add = 0x802c5804
export const RAM_D_802432D0_CFAE40 = 0x802432d0
export const RAM_arn_07_80243CA4 = 0x80243ca4
export const RAM_au_read_rom = 0x80054e00
export const RAM_dup2_b_area_kpa2_UnfadeBackgroundToBlack = 0x80218620
export const RAM_au_SEFCmd_04 = 0x8004cf3c
export const RAM_gPauseCommonWindowsBPs = 0x8024f114
export const RAM_pra_19_ReflectFloor = 0x8024049c
export const RAM_func_802A959C = 0x802a959c
export const RAM_mac_00_Quizmo_SpinPartner = 0x802414b0
export const RAM_osPfsGetInitData = 0x8006d560
export const RAM_b_area_kmr_part_3_kmr_04_clouds2 = 0x80232878
export const RAM_jan_08_SuperBlock_PartnerSparkles2 = 0x802412d8
export const RAM_D_E02006E4 = 0xe02006e4
export const RAM_b_area_kmr_part_3_spiked_goomba_takeTurn_8021B19C = 0x8021b19c
export const RAM_D_802EB320 = 0x802eb320
export const RAM_tik_10_SuperBlock_EndGlowEffect = 0x80240b90
export const RAM_action_command_jump_draw_hud_elements = 0x802a9544
export const RAM_pause_tabs_draw_party = 0x80244f10
export const RAM_func_8025C120 = 0x8025c120
export const RAM_ui_status_pow_unit_3_pal = 0x801007a0
export const RAM_dgb_09_pad_3918 = 0x80243918
export const RAM_isk_08_set_script_owner_npc_anim = 0x80240000
export const RAM_flo_07_802407A0 = 0x802407a0
export const RAM_D_802527B8_864928 = 0x802527b8
export const RAM_D_09000E20_32DCE0 = 0x09000e20
export const RAM_dgb_08_FlyingAI_JumpInit = 0x8024233c
export const RAM_b_area_iwa_formation_0A = 0x80218494
export const RAM_b_area_tik3_UnkFunc56 = 0x80218310
export const RAM_D_80246390_915A40 = 0x80246390
export const RAM_nok_02_TransformFoliage = 0x802443d0
export const RAM_D_8029FB4C = 0x8029fb4c
export const RAM_b_area_pra3_swoopula_statusTable = 0x80226e5c
export const RAM_b_area_sam_duplighost_CopyPriority = 0x80219524
export const RAM_b_area_kmr_part_3_egg_jr_troopa_handleEvent_80224B58 = 0x80224b58
export const RAM_AddKeyItem = 0x802d68c8
export const RAM_wDisabledPartnerHudScripts = 0x800f7f40
export const RAM_dro_02_Quizmo_SpinPlayer = 0x8024111c
export const RAM_flo_22_init_802409CC = 0x802409cc
export const RAM_b_area_flo2_UnkFloatFunc = 0x80218114
export const RAM_D_80242A30_995160 = 0x80242a30
export const RAM_mac_02_PatrolAI_JumpInit = 0x80241060
export const RAM_IES_ITEM_136 = 0x8008dd94
export const RAM_shop_owner_buy_dialog = 0x80280088
export const RAM_b_area_trd_part_3_eldstar_80218424 = 0x80218424
export const RAM_D_802EB328 = 0x802eb328
export const RAM_HES_PartnerBDisabled = 0x80108040
export const RAM_pra_36_reflection_setup_wall = 0x80240158
export const RAM_b_area_omo2_general_guy_FadeBackgroundToBlack = 0x80218000
export const RAM_func_802A5738 = 0x802a5738
export const RAM_tik_10_SuperBlock_StartGlowEffect = 0x80240b00
export const RAM_dgb_01_D_80252EC8_C10748 = 0x80252ec8
export const RAM_gPauseBadgesElements = 0x8024f4d0
export const RAM_b_area_trd_part_1_trd_04_foregroundModelList_80224330 = 0x80224330
export const RAM_func_802402EC_7E768C = 0x802402ec
export const RAM_dro_01_Quizmo_SpinPartner = 0x802422f0
export const RAM_start_bounce_a = 0x800e6034
export const RAM_dro_01_Quizmo_StashVars = 0x80241470
export const RAM_jan_03_PatrolAI_JumpInit = 0x80240d50
export const RAM_b_area_iwa_monty_mole_8021A6FC = 0x8021a6fc
export const RAM_iwa_10_TransformFoliage = 0x80241620
export const RAM_D_800F8020 = 0x800f8020
export const RAM_omo_02_ShyGuyWanderAI_15 = 0x802409e0
export const RAM_arn_04_npcAI_80243724 = 0x80243724
export const RAM_dgb_02_npcSettings_80241D08 = 0x80241d08
export const RAM_b_area_trd_part_1_bill_blaster_init_80219E6C = 0x80219e6c
export const RAM_partner_disable_input = 0x800ef628
export const RAM_isk_14_HoppingAI_ChaseInit = 0x802407cc
export const RAM_mim_11_Pipe_SetAnimFlag = 0x80240000
export const RAM_nok_15_ParatroopaAI_Windup = 0x80241fac
export const RAM_b_area_isk_part_1_buzzy_beetle_statusTable_8021E4E8 = 0x8021e4e8
export const RAM_b_area_nok_kent_c_koopa_shellToss_KentC = 0x802291a4
export const RAM_IES_IcePalaceKey = 0x8008b544
export const RAM_dro_02_802441C0 = 0x802441c0
export const RAM_b_area_omo_medi_guy_init_8021F9E8 = 0x80223568
export const RAM_fx_flower_trail = 0x8006fbf0
export const RAM_gPauseBadgesSelectedItem = 0x80270380
export const RAM_IES_DriedPasta = 0x8008d974
export const RAM_gPauseWindowFlipDownAngles = 0x8024f000
export const RAM_b_area_trd_part_2_blue_ninja_koopa_nextTurn_8022E424 = 0x8022e424
export const RAM_hos_03_Quizmo_ShouldAppear = 0x802425e0
export const RAM_func_802BD5D8_3174F8 = 0x802bd5d8
export const RAM_battle_item_dried_shroom_UseItem = 0x802a1644
export const RAM_gPauseMsg_32 = 0x8026f660
export const RAM_ice_shard_update = 0xe011022c
export const RAM_steam_burst_appendGfx = 0xe005c2f8
export const RAM_load_partner_actor = 0x80264b74
export const RAM_D_80245648_DB7908 = 0x80245648
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuffScript1 = 0x802336f0
export const RAM_D_09000000_350220 = 0x09000000
export const RAM_b_area_kmr_part_2_goomnut_tree_init_80223488 = 0x80223488
export const RAM_b_area_sam_duplighost_lakilester_idleAnimations2 = 0x802261cc
export const RAM_D_A4600004 = 0xa4600004
export const RAM_arn_07_npcSettings_80243D1C = 0x80243d1c
export const RAM_HES_Item_BakingMilk = 0x80087518
export const RAM_D_8024F280_A394C0 = 0x8024f280
export const RAM_battle_item_dried_shroom_func_802A12FC_71CFDC = 0x802a12fc
export const RAM_isk_10_SuperBlock_CountEligiblePartners = 0x80240120
export const RAM_arn_02_CleftAI_Disguise = 0x80240be4
export const RAM_b_area_jan_formation_0D = 0x802289a8
export const RAM_dro_01_802479FC = 0x802479fc
export const RAM_b_area_sam_gulpit_rocks_idle = 0x8022973c
export const RAM_b_area_mac_lee_defenseTable_802259C0 = 0x802259c0
export const RAM_D_802D9D71 = 0x802d9d71
export const RAM_D_8029D940 = 0x8029d940
export const RAM_func_80240A50_A1BB50 = 0x80240a50
export const RAM_breaking_junk_appendGfx = 0xe011849c
export const RAM_battle_move_auto_multibounce_D_802A1D88 = 0x802a1d88
export const RAM_nuContDataUnLock = 0x800608f0
export const RAM_jan_05_MeleeHitbox_31 = 0x80241938
export const RAM_HES_MenuStrategies = 0x80292200
export const RAM_D_80093D34 = 0x80093d34
export const RAM_pause_banner_boots_pal = 0x8026d3b0
export const RAM_D_800B1B14 = 0x800b1b14
export const RAM_b_area_sbk_formation_03 = 0x8021f9e8
export const RAM_D_8024BEA0 = 0x8024bea0
export const RAM_func_802196A4_465B34 = 0x802196a4
export const RAM_b_area_kmr_part_3_paragoomba2_takeTurn_8021D6E8 = 0x80221d28
export const RAM_b_area_pra2_crystal_king_array_1 = 0x8021d63c
export const RAM_D_090017D0 = 0x090017d0
export const RAM_DemoReleaseButton = 0x802d9af8
export const RAM_D_802B7CB8_E20DC8 = 0x802b7cb8
export const RAM_D_8024F1C0_C0CA40 = 0x8024f1c0
export const RAM_b_area_sam_sam_02b_UnkModelStuffScript1 = 0x8022f830
export const RAM_func_E006A85C = 0xe006a85c
export const RAM_pause_badges_init = 0x80248fc0
export const RAM_dgb_08_SentinelAI_PostLosePlayer = 0x80243468
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_8022E688 = 0x8022e688
export const RAM_D_7599F6D8 = 0x7599f6d8
export const RAM_func_802411D8_D511C8 = 0x802411d8
export const RAM_sam_11_Quizmo_AudienceEffect = 0x80251474
export const RAM_obk_04_StashVars = 0x80240940
export const RAM_D_802BE30C = 0x802be30c
export const RAM_dgb_01_defeat_80245CB4 = 0x80245cb4
export const RAM_CountFortessKeys = 0x802d6c4c
export const RAM_flo_16_8024314C = 0x8024314c
export const RAM_lightning_render = 0xe006c5a0
export const RAM_b_area_kmr_part_3_final_jr_troopa_8023106C = 0x8023106c
export const RAM_b_area_sam_duplighost_formation_lakilester = 0x80227404
export const RAM_kmr_02_ToadHouse_AwaitScriptComplete = 0x802431b4
export const RAM_increment_status_menu_disabled = 0x800e9b54
export const RAM_corrupted = 0x8006d1d8
export const RAM_dro_02_8024C248 = 0x8024c248
export const RAM_HES_Item_BakingWater_disabled = 0x80087488
export const RAM_b_area_dgb_stageTable = 0x8021b418
export const RAM_dgb_01_D_802535A0_C10E20 = 0x802535a0
export const RAM_popupMessages = 0x8029f2c0
export const RAM_IES_DeluxeFeast = 0x8008d194
export const RAM_b_area_omo_omo_06_foregroundModelList_8022F870 = 0x8022f870
export const RAM_b_area_pra2_crystal_king_idle = 0x80218c4c
export const RAM_HES_Item_JellyUltra_disabled = 0x80082988
export const RAM_dgb_07_80241490 = 0x80241490
export const RAM_wMapTexName = 0x800b0cf0
export const RAM_flo_10_interact_802437C8 = 0x802437c8
export const RAM_kmr_06_FlyingAI_Jump = 0x80240c54
export const RAM_kzn_09_StashVars = 0x802432ac
export const RAM_func_80057C2C = 0x80057c2c
export const RAM_D_80232A18_684798 = 0x80232a18
export const RAM_D_09003968_3AE998 = 0x09003968
export const RAM_D_80078E14 = 0x80078e14
export const RAM_battle_item_tasty_tonic_GiveRefund = 0x802a1000
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B5B0 = 0x8022b5b0
export const RAM_DemoPressButton = 0x802d9ac0
export const RAM_dgb_08_npcGroupList_802469C4 = 0x802469c4
export const RAM_dgb_01_npcGroup_80246C60 = 0x80246c60
export const RAM_func_802BC9CC_E3131C = 0x802bc9cc
export const RAM_func_80066FE0 = 0x80066fe0
export const RAM_D_802BCF80_E2D6B0 = 0x802bcf80
export const RAM_battle_item_fire_flower_GiveRefund = 0x802a1000
export const RAM_osJamMesg = 0x800655b0
export const RAM_D_8015A2D0 = 0x8015a2d0
export const RAM_IES_RuinsStoneMoon = 0x8008b664
export const RAM_kpa_95_ToadHouse_PartnerSuspendAbilityScript = 0x8024177c
export const RAM_bgm_reset_sequence_players = 0x8014a498
export const RAM_IES_LuckyDay = 0x8008c5a4
export const RAM_gfxThread = 0x80060248
export const RAM_pra_28_reflection_setup_floor = 0x80240530
export const RAM_D_8024E0A8_C0B928 = 0x8024e0a8
export const RAM_D_E00E0A40 = 0xe00e0a40
export const RAM_HES_Item_Unused_08D_disabled = 0x800823e8
export const RAM_D_8024E208_8314C8 = 0x8024e208
export const RAM_kpa_08_MagikoopaAI_05 = 0x80240920
export const RAM_SetCamPosB = 0x802cbcf4
export const RAM_D_09000B50_32DA10 = 0x09000b50
export const RAM_D_800777B0 = 0x800777b0
export const RAM_nok_02_PatrolAI_Loiter = 0x80240f40
export const RAM_osPackRequestData = 0x80061dd0
export const RAM_ui_status_coin_3_pal = 0x80100d60
export const RAM_kpa_08_MagikoopaAI_OnPlayerFled = 0x802413c4
export const RAM_flo_16_80242EA0 = 0x80242ea0
export const RAM_appendGfx_background_texture = 0x80145ec0
export const RAM_sam_01_Quizmo_Answers = 0x802436fc
export const RAM_D_802313F8_63CF98 = 0x802313f8
export const RAM_b_area_nok_kent_c_koopa_nextTurn_80227D2C = 0x80227d2c
export const RAM_flo_16_802429D0 = 0x802429d0
export const RAM_EF2680_FlyingAI_Main = 0x8024137c
export const RAM_HES_Item_BowserCastleKey = 0x80081518
export const RAM_b_area_jan_jan_03_beforeBattle_80228320 = 0x80228320
export const RAM_func_802A92A0_42F980 = 0x802a92a0
export const RAM_action_update_hammer = 0x802b66a8
export const RAM_dgb_18_PatrolAI_LoiterInit = 0x80240400
export const RAM_flo_13_80243DA4 = 0x80243da4
export const RAM_dro_02_npcAISettings_802474B0 = 0x802474b0
export const RAM_kpa_03_MagikoopaAI_00 = 0x80241128
export const RAM_func_800E0B90 = 0x800e0b90
export const RAM_b_area_jan_m_bush_partsTable_8022193C = 0x8022193c
export const RAM_omo_03_SetAngleClamped = 0x8024003c
export const RAM_D_80246300 = 0x80246300
export const RAM_b_area_trd_part_1_paratroopa_intTable_802211D0 = 0x802211d0
export const RAM_D_09001600_3879B0 = 0x09001600
export const RAM_D_800980B4 = 0x800980b4
export const RAM_entity_fog_red = 0x8015437c
export const RAM_HES_Item_StarPiece = 0x80081260
export const RAM_b_area_omo2_3_shy_stack_fall_apart = 0x80234280
export const RAM_D_80252798_9E97B8 = 0x80252798
export const RAM_b_area_isk_part_1_formation_0C = 0x80224484
export const RAM_func_8025CD40 = 0x8025cd40
export const RAM_ui_eldstar_pal = 0x800fd100
export const RAM_mac_03_Quizmo_NPC_OnRender = 0x8024087c
export const RAM_action_hammer_is_swinging_away = 0x802b6000
export const RAM_dgb_15_unk_missing_8024202C = 0x8024202c
export const RAM_func_80242978_E07CD8 = 0x80242978
export const RAM_BattleMerleeWaveEffect = 0x8029fb9c
export const RAM_D_E0116CA4 = 0xe0116ca4
export const RAM_vtx_C25A30 = 0x80204af0
export const RAM_vtx_C21D30 = 0x80200df0
export const RAM_HES_StartButtonDown = 0x80108c9c
export const RAM_D_8000030C = 0x8000030c
export const RAM_iwa_01_ProjectileAI_Reflect = 0x80240fb8
export const RAM_dgb_01_D_802534E0_C10D60 = 0x802534e0
export const RAM_D_802A1FEC_766C0C = 0x802a1fec
export const RAM_IES_ShroomSteak = 0x8008d0d4
export const RAM_arn_02_entryList = 0x80240fe0
export const RAM_NAMESPACE_80239BC0 = 0x80239bc0
export const RAM_omo_09_UnkNpcPosFunc = 0x80243610
export const RAM_D_8024FC48_C0D4C8 = 0x8024fc48
export const RAM_gMsgVarImages = 0x801512f4
export const RAM_dgb_08_extraAnimationList_802442B8 = 0x802442b8
export const RAM_hieroglyphs_render = 0xe00e21c8
export const RAM_nok_02_Quizmo_DestroyEffects = 0x80242298
export const RAM_D_E0082D30 = 0xe0082d30
export const RAM_fx_gather_magic = 0x80071330
export const RAM_b_area_arn_hypergoomba_StartRumbleWithParams = 0x80218000
export const RAM_b_area_nok_paratroopa_idleAnimations_8021ED98 = 0x80223bc8
export const RAM_UseLifeShroom = 0x802879c0
export const RAM_D_8024DEF8 = 0x8024def8
export const RAM_arn_03_802428CC = 0x802428cc
export const RAM_func_802BB69C_E2BDCC = 0x802bb69c
export const RAM_b_area_sam_pos_rocks_3 = 0x802306e8
export const RAM_D_8024F2D8 = 0x8024f2d8
export const RAM_render_models = 0x80116b90
export const RAM_GetCamPosA = 0x802cc0f4
export const RAM_sam_05_PiranhaPlantAI_01 = 0x8024093c
export const RAM_b_area_kgr_fuzzipede_8021C004 = 0x8021c004
export const RAM_HES_Item_BoiledEgg_disabled = 0x80083708
export const RAM_func_80240FB0_EB9E00 = 0x80240fb0
export const RAM_func_80240360_D3A930 = 0x80240360
export const RAM_b_area_kzn_medi_guy_takeTurn_80220DCC = 0x80220dcc
export const RAM_HES_Item_SuperShroom_disabled = 0x80086768
export const RAM_arn_11_settings = 0x80240010
export const RAM_D_8029D948 = 0x8029d948
export const RAM_D_80270048 = 0x80270048
export const RAM_au_update_clients_2 = 0x800533d0
export const RAM_arn_03_enterWalk_80241930 = 0x80241930
export const RAM_b_area_pra3_duplighost_idle = 0x8021a190
export const RAM_b_area_omo2_toy_tank_8021A560 = 0x8021a560
export const RAM_HES_Item_CakeMix_disabled = 0x80086dc8
export const RAM_ui_battle_hp_5_pal = 0x8028aac0
export const RAM_D_802A2CC0 = 0x802a2cc0
export const RAM_dgb_16_npcGroup_80241A4C = 0x80241a4c
export const RAM_func_8005DECC = 0x8005decc
export const RAM_sam_02_Quizmo_UpdateRecords = 0x80241218
export const RAM_sbk_56_SuperBlock_PartnerIDs = 0x80241438
export const RAM_vtx_C2BD50 = 0x8020ae10
export const RAM_kzn_02_FlyingAI_JumpVels = 0x80244900
export const RAM_D_80245D50_A99490 = 0x80245d50
export const RAM_get_current_item_entity_render_group = 0x80134240
export const RAM_D_09000C48 = 0x09000c48
export const RAM_HES_Item_Egg_disabled = 0x80086e28
export const RAM_osSetIntMask = 0x80060940
export const RAM_arn_02_CleftAI_PostDisguise = 0x80240c4c
export const RAM_D_E0088D28 = 0xe0088d28
export const RAM_D_E0080B38 = 0xe0080b38
export const RAM_dgb_06_pad_3B8 = 0x802403b8
export const RAM_gv_01_Pipe_GetCurrentFloor = 0x80240020
export const RAM_b_area_pra3_duplighost_idleAnimations3 = 0x80219f68
export const RAM_mgm_02_BoxColliderIDs = 0x8024296c
export const RAM_flo_17_npcAISettings_802444F0 = 0x802444f0
export const RAM_D_802BEC5C = 0x802bec5c
export const RAM_func_8025C8A0 = 0x8025c8a0
export const RAM_SaveBlockResultPrinterClosed = 0x802eb394
export const RAM_pra_11_ReflectFloor = 0x8024049c
export const RAM_ui_battle_menu_defend_png = 0x80289880
export const RAM_kmr_09_FlyingAI_Init = 0x802411e8
export const RAM_dup2_b_area_tik2_UnkFunc56 = 0x80219520
export const RAM_jan_08_SuperBlock_SwitchToPartner = 0x80240a7c
export const RAM_D_E00B88F0 = 0xe00b88f0
export const RAM_flo_24_8024183C = 0x8024183c
export const RAM_arn_02_main = 0x802411f0
export const RAM_b_area_trd_part_2_green_ninja_koopa_UnkEnemyFunc = 0x80218510
export const RAM_flo_03_init_802434CC = 0x802434cc
export const RAM_b_area_jan_formation_04 = 0x802286b4
export const RAM_b_area_mac_lee_UnkBackgroundFunc3 = 0x8021973c
export const RAM_world_lakilester_post_battle = 0x802bfaa8
export const RAM_flo_12_D_802419EC_CC281C = 0x802419ec
export const RAM_D_80248600 = 0x80248600
export const RAM_IdlePeachAnims = 0x802b6430
export const RAM_arn_09_npcGroup_80240784 = 0x80240784
export const RAM_sam_05_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_set_anim_model_fog_color = 0x80120064
export const RAM_D_802E9AC4 = 0x802e9ac4
export const RAM_nok_14_UnkNpcAIFunc12 = 0x80240370
export const RAM_hos_02_FlyingAI_LosePlayer = 0x80241e48
export const RAM_dro_02_init_8024BA50 = 0x8024ba50
export const RAM_D_E001A628 = 0xe001a628
export const RAM_dgb_04_SuperBlock_PartnerIDs = 0x80243d38
export const RAM_b_area_kmr_part_3_para_jr_troopa_init_80226510 = 0x80226510
export const RAM_D_80242860_B1FFC0 = 0x80242860
export const RAM_nuScAddClient = 0x8005ee30
export const RAM_dgb_01_FlyingAI_Jump = 0x80240d74
export const RAM_virtual_entity_create = 0x802d420c
export const RAM_flo_15_80240060 = 0x80240060
export const RAM_ui_skolar_disabled_pal = 0x800fd5a0
export const RAM_gPauseWS_0 = 0x8026f900
export const RAM_flo_22_shakeTree = 0x80241900
export const RAM_DisablePartnerBlur = 0x802456d8
export const RAM_D_802423F0 = 0x802423f0
export const RAM_action_update_walk = 0x802b6000
export const RAM_entity_raycast_down = 0x801125e8
export const RAM_arn_05_npcAI_8024170C = 0x8024170c
export const RAM_startup_draw_prim_rect = 0x8002ab5c
export const RAM_func_802412D4_D4E314 = 0x802412d4
export const RAM_func_802198A0_5B2D50 = 0x802198a0
export const RAM_b_area_flo_MediGuySpriteRotationFunc = 0x8021844c
export const RAM_ui_battle_unk_star_pal = 0x8028f840
export const RAM_vtx_C25050 = 0x80204110
export const RAM_gPauseDLAvailable = 0x8026f3f0
export const RAM_pause_stats_init = 0x80246ac8
export const RAM_arn_10_80240060 = 0x80240060
export const RAM_D_802EB130 = 0x802eb130
export const RAM_D_FD100008 = 0xfd100008
export const RAM_battle_star_focus_802A1630 = 0x802a1630
export const RAM_func_80238000_6FFD80 = 0x80238000
export const RAM_D_8024EFC8 = 0x8024efc8
export const RAM_D_802809F8 = 0x802809f8
export const RAM_D_80078E5A = 0x80078e5a
export const RAM_CamPreset_M = 0x80280d20
export const RAM_initialize_printer = 0x801256a4
export const RAM_D_802A2184_766DA4 = 0x802a2184
export const RAM_D_E0086AE4 = 0xe0086ae4
export const RAM_b_area_omo_pink_shy_guy_idleAnimations_8021E41C = 0x8021e41c
export const RAM_D_E0088D90 = 0xe0088d90
export const RAM_func_8010FE44 = 0x8010fe44
export const RAM_kzn_08_FlyingAI_Main = 0x80241d5c
export const RAM_DidActionSucceed = 0x80276eb4
export const RAM_dgb_01_idle_8024536C = 0x8024536c
export const RAM_func_802A153C_79060C = 0x802a153c
export const RAM_auSynDriver = 0x800b91a0
export const RAM_HES_Item_RunawayPay_disabled = 0x80084a28
export const RAM_func_802A10C8_75E698 = 0x802a10c8
export const RAM_D_80245860_C85A00 = 0x80245860
export const RAM_D_E0088D20 = 0xe0088d20
export const RAM_D_E0080B30 = 0xe0080b30
export const RAM_kkj_23_UnkGooberFunc50 = 0x802407a0
export const RAM_D_80098280 = 0x80098280
export const RAM_crash_screen_printf = 0x8002c208
export const RAM_kkj_10_UnkPhysicsFunc = 0x802400b0
export const RAM_au_SEFCmd_SetUnkA3 = 0x8004d354
export const RAM_flo_09_80242010 = 0x80242010
export const RAM_ResetPartnerTetherDistance = 0x80283bb0
export const RAM_battle_menu_ItemHudScripts = 0x802ab388
export const RAM_pra_02_reflection_unk_resolve_anim = 0x80240020
export const RAM_kzn_08_UpdateTexturePanStepped = 0x80240530
export const RAM_AlternatingSounds = 0x8014f5a4
export const RAM_dgb_15_GetEntityPosition = 0x80241790
export const RAM_HES_Item_BakingCream_disabled = 0x80087308
export const RAM_D_80270040 = 0x80270040
export const RAM_D_80286524 = 0x80286524
export const RAM_ice_pillar_render = 0xe011e530
export const RAM_battle_item_pow_block_DrinkItem = 0x802a18e4
export const RAM_dro_02_ToadHouse_PutPartnerAway = 0x80243714
export const RAM_gAnimModelFogEnd = 0x80153a5c
export const RAM_b_area_hos_hos_01_beforeBattle_80224BD0 = 0x80224bd0
export const RAM_dro_01_Pipe_AwaitDownInput = 0x80240034
export const RAM_gPauseWS_8 = 0x8026fac0
export const RAM_D_80241CF4_A4E714 = 0x80241cf4
export const RAM_D_8029FA88 = 0x8029fa88
export const RAM_flo_16_SuperBlock_CountEligiblePartners = 0x80240200
export const RAM_dgb_03_makeEntities = 0x802438d4
export const RAM_FlyPartTo = 0x8027bf38
export const RAM_D_8024F818_C0D098 = 0x8024f818
export const RAM_dead_basic_ai_loiter = 0x8004e024
export const RAM_HES_Item_PeachKey = 0x80081de8
export const RAM_D_802AA888_4254F8 = 0x802aa888
export const RAM_D_8014BD68 = 0x8014bd68
export const RAM_mgm_02_InitialConfigurations = 0x8024273c
export const RAM_D_80244FB8_ADAE88 = 0x80244fb8
export const RAM_filemenu_update_hidden_title = 0x80243754
export const RAM_player_jump = 0x802d14e0
export const RAM_mdl_RDPIdentity = 0x8014b768
export const RAM_D_80231534_63D0D4 = 0x80231534
export const RAM_battle_item_electro_pop_AddHP = 0x802a13fc
export const RAM_D_E003CD50 = 0xe003cd50
export const RAM_gHudElementSizes = 0x8014efcc
export const RAM_world_watt_sync_held_position = 0x802be070
export const RAM_b_area_pra3_duplighost_parakarry_idle = 0x802207d0
export const RAM_func_802BC3A0_E2ECD0 = 0x802bc3a0
export const RAM_mac_06_SpawnSunEffect = 0x8024047c
export const RAM_D_E009EE10 = 0xe009ee10
export const RAM_ui_battle_status_stop_2_pal = 0x801028e0
export const RAM_b_area_arn_hyper_cleft_idleAnimations_80221D20 = 0x80221d20
export const RAM_flo_08_SuperBlock_HideBlockContent = 0x8024003c
export const RAM_b_area_omo2_light_bulb_init = 0x8022f70c
export const RAM_D_8014B848 = 0x8014b848
export const RAM_b_area_arn_hypergoomba_802198B0 = 0x802198b0
export const RAM_D_80255D68 = 0x80255d68
export const RAM_HES_Item_Lime_disabled = 0x80086c48
export const RAM_dead_gCollisionStatus = 0x80169b10
export const RAM_b_area_kmr_part_3_para_jr_troopa_802264AC = 0x802264ac
export const RAM_D_802430C0_BD1CB0 = 0x802430c0
export const RAM_D_8014F49C = 0x8014f49c
export const RAM_pause_tabs_draw_badges_805540 = 0x80244cc0
export const RAM_dgb_01_D_80251B30_C0F3B0 = 0x80251b30
export const RAM_dgb_02_ClubbaNappingAI_Main = 0x802410d4
export const RAM_evt_handle_jump = 0x802c6640
export const RAM_D_80248760 = 0x80248760
export const RAM_dro_02_ToadHouse_CamSetFOV = 0x80243548
export const RAM_flo_16_SuperBlock_UpgradeDescMessages = 0x80243718
export const RAM_iwa_10_SuperBlock_SwitchToPartner = 0x8024070c
export const RAM_D_09002200_352420 = 0x09002200
export const RAM_mac_00_AddPlayerHandsOffset = 0x80241720
export const RAM_b_area_trd_part_1_trd_05c = 0x80226480
export const RAM_HES_Item_Unused_0F7_disabled = 0x800860a8
export const RAM_flo_18_vectorList_80243FA4 = 0x80243fa4
export const RAM_SummonEnemy = 0x8026db64
export const RAM_func_802BD678_3211C8 = 0x802bd678
export const RAM_entity_HeartBlockContent_set_initial_pos = 0x802e4b10
export const RAM_D_800981B4 = 0x800981b4
export const RAM_dgb_11_80240600 = 0x80240600
export const RAM_func_80241168_BCFD58 = 0x80241168
export const RAM_dgb_08_npcGroup_80246528 = 0x80246528
export const RAM_mac_05_Quizmo_ShouldQuizmoLeave = 0x80241e5c
export const RAM_b_area_sbk_pokey_idleAnimations_80218474 = 0x80218474
export const RAM_b_area_kgr_fuzzipede_idle_80218D58 = 0x80218d58
export const RAM_GoombariaTakeOut = 0x802bd114
export const RAM_func_E006A464 = 0xe006a464
export const RAM_b_area_omo_formation_13 = 0x8022fec4
export const RAM_gHudElementCacheBuffer = 0x80151314
export const RAM_b_area_kzn_white_magikoopa_takeTurn_802274E4 = 0x802274e4
export const RAM_EF8010_foliage_setup_shear_mtx = 0x80240590
export const RAM_isk_09_CheckItemFlags40 = 0x80240268
export const RAM_func_80243584_EE1F44 = 0x80243584
export const RAM_flo_11_func_802402E0_CBDFB0 = 0x802402e0
export const RAM_phys_update_interact_collider = 0x800e5150
export const RAM_D_8022DA30_528000 = 0x8022da30
export const RAM_kpa_51_MagikoopaAI_00 = 0x80240730
export const RAM_au_driver_init = 0x80056250
export const RAM_sbk_24_TransformFoliage = 0x802401b0
export const RAM_func_80240AAC_B074AC = 0x80240aac
export const RAM_func_802BD514_319A84 = 0x802bd514
export const RAM_sam_10_GetItemName = 0x802400b4
export const RAM_hos_06_GetItemEmptyCount = 0x80242534
export const RAM_sbk_56_foliage_setup_shear_mtx = 0x80240dc0
export const RAM_dgb_08_80244998 = 0x80244998
export const RAM_D_802BCA30_E31380 = 0x802bca30
export const RAM_shim_guPositionF = 0xe0200600
export const RAM_world_goompa_update = 0x802bd590
export const RAM_dgb_02_npcGroup_802421E8 = 0x802421e8
export const RAM_b_area_pra3_duplighost_goombario_handleEvent = 0x8021bd20
export const RAM_spirit_card_render = 0xe01122e8
export const RAM_battle_item_egg_missile3_pal = 0x802a1f10
export const RAM_omo_16_SetNpcPosYaw = 0x80240290
export const RAM_D_802BEBC0_31CBE0 = 0x802bebc0
export const RAM_kzn_05_UpdateTexturePanSmooth = 0x80240000
export const RAM_b_area_kzn_putrid_piranha_defenseTable_8021B0EC = 0x8021b0ec
export const RAM_ui_battle_status_frozen_3_png = 0x80102680
export const RAM_trd_04_PatrolAI_Move = 0x80240ddc
export const RAM_damage_stars_appendGfx = 0xe0030a68
export const RAM_b_area_sam_sam_03 = 0x80230644
export const RAM_D_8023BDB4 = 0x8023bdb4
export const RAM_IES_DamageDodge = 0x8008c844
export const RAM_flo_03_npcGroup_80243C6C = 0x80243c6c
export const RAM_b_area_jan_medi_guy_init_8021F9E8 = 0x802226a8
export const RAM_func_802A9000_423C70 = 0x802a9000
export const RAM_b_area_trd_part_2_trd_05e_beforeBattle_80235444 = 0x80235444
export const RAM_kmr_03_CheckPartnerFlags1000 = 0x80240184
export const RAM_osr_02_GetPeachDisguise = 0x80240120
export const RAM_SetMessageBoxDuration = 0x80251408
export const RAM_b_area_iwa_formation_09 = 0x8021845c
export const RAM_fx_falling_leaves = 0x80070910
export const RAM_HES_Item_HeartFinder_disabled = 0x80085028
export const RAM_AlCmdListBuffers = 0x800a3510
export const RAM_b_area_trd_part_3_eldstar_statusTable_80218298 = 0x80218298
export const RAM_MAMAR_au_load_song_files = 0x804001a0
export const RAM_fold_vtxCount = 0x8014ee14
export const RAM_mim_05_PiranhaPlantAI_12 = 0x80241578
export const RAM_D_090020E0_3AD110 = 0x090020e0
export const RAM_EB1170_StashVars = 0x80240310
export const RAM_dgb_01_D_802524A4_C0FD24 = 0x802524a4
export const RAM_npc_get_collider_below = 0x8003d2f8
export const RAM_func_8024032C_A3FA6C = 0x8024032c
export const RAM_D_800A0928 = 0x800a0928
export const RAM_battle_move_multibounce_D_802A2384 = 0x802a2384
export const RAM_pra_37_reflection_render_wall = 0x80240320
export const RAM_dro_02_Quizmo_GetItemName = 0x802403b4
export const RAM_recover_hp = 0x800ea2cc
export const RAM_partner_handle_after_battle = 0x800eb6c4
export const RAM_D_8023C1C4 = 0x8023c1c4
export const RAM_flo_22_npcGroup_80240A04 = 0x80240a04
export const RAM_dgb_01_D_802538E0_C11160 = 0x802538e0
export const RAM_battle_item_life_shroom_GiveRefundCleanup = 0x802a11d4
export const RAM_b_area_jan2_jan_03_foregroundModelList_8021F3B0 = 0x8021f3b0
export const RAM_b_area_sam_sam_01_beforeBattle = 0x8022f288
export const RAM_tik_07_Pipe_GetCurrentFloor = 0x80240020
export const RAM_DispatchEvent = 0x8027d264
export const RAM_mac_03_Quizmo_RenderInit = 0x80240810
export const RAM_D_80241AE8_BDEC98 = 0x80241ae8
export const RAM_b_area_kmr_part_3_spiked_goomba_idle_8021A578 = 0x8021a578
export const RAM_virtual_entity_delete_by_index = 0x802d43f4
export const RAM_flo_13_makeEntities = 0x80243978
export const RAM_dgb_03_ClubbaNappingAI_ReturnHomeInit = 0x80242200
export const RAM_ui_battle_status_transparent_1_png = 0x80102e00
export const RAM_flo_14_8024352C = 0x8024352c
export const RAM_kpa_50_GetEncounterEnemyIsOwner = 0x80241214
export const RAM_dgb_08_PatrolAI_Move = 0x80240158
export const RAM_dgb_15_entryList = 0x80241870
export const RAM_b_area_nok_paragoomba_idle_8021CBE0 = 0x8021cbe0
export const RAM_D_E010AF98 = 0xe010af98
export const RAM_D_80244EF0_CF7AC0 = 0x80244ef0
export const RAM_b_area_kzn_formation_00 = 0x80229790
export const RAM_flo_12_init_802423D0 = 0x802423d0
export const RAM_b_area_iwa_iwa_02_IsSaveVar123 = 0x802181c0
export const RAM_set_screen_overlay_center = 0x80137e4c
export const RAM_D_80231398_63CF38 = 0x80231398
export const RAM_omo_04_ShyGuyWanderAI_17 = 0x80241a70
export const RAM_IES_Unused_02F = 0x8008d594
export const RAM_D_802AD010 = 0x802ad010
export const RAM_HES_Item_MapleSuper = 0x80082fb8
export const RAM_HES_Item_SuperFocus_disabled = 0x80085868
export const RAM_EF7A80_UpdateTexturePanStepped = 0x80240124
export const RAM_D_802EB138 = 0x802eb138
export const RAM_b_area_sam_duplighost_parakarry_init = 0x80221704
export const RAM_snaking_static_init = 0xe00ae1ac
export const RAM_HES_133F40_133FC0 = 0x802426f8
export const RAM_nok_14_FlyingAI_JumpVels = 0x80243a18
export const RAM_dgb_18_GetItemName = 0x80241064
export const RAM_b_area_arn_tubba_blubba_handleEvent_802260D0 = 0x802260d0
export const RAM_HES_Item_LemonCandy = 0x800833d8
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E61C = 0x8022e61c
export const RAM_HES_Item_HPDrain = 0x800844b8
export const RAM_b_area_mim_piranha_plant_unk_missing_8021D37C = 0x8021d37c
export const RAM_D_80246990 = 0x80246990
export const RAM_D_80246560 = 0x80246560
export const RAM_trd_06_fake_sym = 0x802491c0
export const RAM_npc_draw_palswap_mode_1 = 0x8003b5b4
export const RAM_func_802419D8_B365A8 = 0x802419d8
export const RAM_HES_Item_CrystalBall = 0x80081fc8
export const RAM_dro_01_LetterDelivery_CalcLetterPos = 0x80242e88
export const RAM_HES_HandPointLeftHeld = 0x800804c0
export const RAM_dro_02_Quizmo_Answers = 0x80244e44
export const RAM_battle_move_hammer_charge_1_IsHammerMaxCharged = 0x802a10a4
export const RAM_battle_move_auto_multibounce_D_802A1408 = 0x802a1408
export const RAM_flo_18_80242620 = 0x80242620
export const RAM_mim_09_HoppingAI_Loiter = 0x802406c8
export const RAM_jan_08_SuperBlock_CantUpgradeMessages = 0x80243e00
export const RAM_dup_nok_02_AddPlayerHandsOffset = 0x80240454
export const RAM_HES_Item_MoneyMoney_disabled = 0x800847e8
export const RAM_b_area_jan2_jan_04b_foregroundModelList_8021F580 = 0x8021f580
export const RAM_D_802AD624 = 0x802ad624
export const RAM_func_80218100_464590 = 0x80218100
export const RAM_SetPlayerFlagBits = 0x802d1d60
export const RAM_IES_FeelingFine = 0x8008c224
export const RAM_ui_stat_heart_png = 0x801041a8
export const RAM_mim_04_StashVars = 0x80241014
export const RAM_D_80243AD8_C91688 = 0x80243ad8
export const RAM_D_800DC4E0 = 0x800dc4e0
export const RAM_b_area_trd_part_2_fake_bowser_StartRumbleWithParams = 0x80218000
export const RAM_b_area_kmr_part_1_kmr_05_beforeBattle_80220A28 = 0x80220a28
export const RAM_HES_Item_ChillOut = 0x80084818
export const RAM_func_80241170_E0F910 = 0x80241170
export const RAM_HES_Item_Parasol = 0x800864f8
export const RAM_sparkles_appendGfx = 0xe0022998
export const RAM_shimmer_wave_appendGfx = 0xe00745e0
export const RAM_kpa_82_GetNpcCollisionHeight = 0x80240050
export const RAM_gPauseBadgesCurrentPage = 0x80270280
export const RAM_wExtraPartnerID = 0x802dae40
export const RAM_arn_05_init_80244704 = 0x80244704
export const RAM_b_area_pra2_crystal_king_RemoveClone = 0x80219270
export const RAM_b_area_kmr_part_2_pos02 = 0x802197a0
export const RAM_D_800A0963 = 0x800a0963
export const RAM_partner_buff_init = 0xe011a0fc
export const RAM_func_E00BC1D8 = 0xe00bc1d8
export const RAM_osGetThreadPri = 0x80065f30
export const RAM_resume_all_group = 0x802c4648
export const RAM_tik_12_Pipe_AwaitDownInput = 0x80240034
export const RAM_mac_01_Quizmo_UpdatePartnerPosition = 0x80242e20
export const RAM_D_80151120 = 0x80151120
export const RAM_D_800A0920 = 0x800a0920
export const RAM_round = 0x80029ac4
export const RAM_func_802A9184_428BF4 = 0x802a9184
export const RAM_b_area_omo2_3_shy_stack_doSpinSmashHitLastGuy = 0x80233d10
export const RAM_script = 0x802a1d4c
export const RAM_dgb_02_npcSettings_80241C3C = 0x80241c3c
export const RAM_battle_item_thunder_rage_UseItem = 0x802a17a4
export const RAM_gWorldNpcList = 0x800a0990
export const RAM_func_80041080 = 0x80041080
export const RAM_b_area_jan2_white_magikoopa_takeTurn_802274E4 = 0x8021d894
export const RAM_load_battle_section = 0x80072bdc
export const RAM_battle_action_cmd_water_block_3_png = 0x802aa3e0
export const RAM_D_802447A0_B97BE0 = 0x802447a0
export const RAM_pra_16_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_D_80249BBC = 0x80249bbc
export const RAM_HES_MenuFlee = 0x80292160
export const RAM_dro_02_D_80248C2C_971DEC = 0x80248c2c
export const RAM_HES_Item_RuinsStoneMoon = 0x80081698
export const RAM_D_8024C098_C09918 = 0x8024c098
export const RAM_gPausePanelSpirits = 0x8024fa10
export const RAM_b_area_sam_sam_02d_UnkModelStuffScript2 = 0x8022ff2c
export const RAM_func_802CF56C = 0x802cf56c
export const RAM_func_80240934_BCD994 = 0x80240934
export const RAM_msg_drawState = 0x80155d74
export const RAM_D_80244FD0_8F0DF0 = 0x80244fd0
export const RAM_func_80240138_D547C8 = 0x80240138
export const RAM_func_802189D0_47FA80 = 0x802189d0
export const RAM_D_80154394 = 0x80154394
export const RAM_func_800F513C = 0x800f513c
export const RAM_b_area_trd_part_1_trd_02 = 0x80223330
export const RAM_kpa_62_UpdateTexturePanSmooth = 0x802400e0
export const RAM_b_area_kgr_fuzzipede_jump_icons = 0x8021aff0
export const RAM_b_area_kzn_formation_08 = 0x80229a4c
export const RAM_sun_main = 0xe0120000
export const RAM_HES_Item_StrangeCake_disabled = 0x800834c8
export const RAM_vtx_C294F0 = 0x802085b0
export const RAM_b_area_trd_part_2_blue_ninja_koopa_init_8022DC2C = 0x8022dc2c
export const RAM_HES_Item_PUpDDown_disabled = 0x80084f68
export const RAM_Entity_RenderNone_Script = 0x802e9830
export const RAM_D_80247420_kzn_03 = 0x80247420
export const RAM_b_area_jan_spear_guy_idleAnimations_80218658 = 0x80218658
export const RAM_arn_03_init_80243F08 = 0x80243f08
export const RAM_HES_AimShimmerA = 0x802931dc
export const RAM_tik_12_SuperBlock_UpgradeOrbAngles = 0x80243d04
export const RAM_kmr_30_UpdateTexturePanSmooth = 0x80240000
export const RAM_vtx_C25F90 = 0x80205050
export const RAM_HES_Item_UltraShroom_disabled = 0x800867c8
export const RAM_IES_FryingPan = 0x8008b784
export const RAM_D_8029FB88 = 0x8029fb88
export const RAM_func_800E0374 = 0x800e0374
export const RAM_D_80098718 = 0x80098718
export const RAM_D_80245350_EEB900 = 0x80245350
export const RAM_sbk_56_SuperBlock_EndGlowEffect = 0x80240590
export const RAM_b_area_nok_paragoomba_idleAnimations_8021B3D0 = 0x8021b3d0
export const RAM_fx_blast = 0x80070fd0
export const RAM_pra_13_reflection_unk_change_anim_facing = 0x80240054
export const RAM_nok_14_FlyingAI_Init = 0x80241b7c
export const RAM_osFlashInit = 0x8006ef68
export const RAM_HES_WaterBlock0 = 0x802ab0b8
export const RAM_lens_flare_main = 0xe0034000
export const RAM_battle_item_stone_cap_func_802A123C_7217DC = 0x802a123c
export const RAM_flo_17_FlyingAI_Main = 0x8024137c
export const RAM_dgb_16_enterSingleDoor_802415D4 = 0x802415d4
export const RAM_EDC020_TransformFoliage = 0x802400b0
export const RAM_b_area_mac_chan_8021A12C = 0x8021a12c
export const RAM_nok_03_HoppingAI_Hop = 0x80240230
export const RAM_snowfall_main = 0xe008a188
export const RAM_sbk_00_main = 0x802401e0
export const RAM_dgb_13_80240050 = 0x80240050
export const RAM_SetIdleAnimations = 0x8027cb7c
export const RAM_dro_01_tree1_Leaves = 0x8024dd24
export const RAM_osWritebackDCacheAll = 0x800618d0
export const RAM_gBattleScriptList = 0x802da690
export const RAM_trd_01_StationaryAI_ReturnHome = 0x802411b0
export const RAM_D_8029FA80 = 0x8029fa80
export const RAM_b_area_omo2_toy_tank_formation_shy_squad_dup = 0x8022f5e0
export const RAM_b_area_kpa4_UnkBattleFunc1 = 0x80218464
export const RAM_main_battle_menu_JumpHudScripts = 0x802ad078
export const RAM_kpa_102_FlyingMagikoopaAI_20 = 0x80242d00
export const RAM_D_E009EDFC = 0xe009edfc
export const RAM_b_area_omo_formation_0D = 0x8022fd20
export const RAM_dgb_00_npcGroup_802421D4 = 0x802421d4
export const RAM_D_E00BCC30 = 0xe00bcc30
export const RAM_dgb_00_80240C4C = 0x80240c4c
export const RAM_func_802404DC_AF7E2C = 0x802404dc
export const RAM_b_area_omo_spy_guy_defenseTable_802274A4 = 0x802274a4
export const RAM_hos_03_ToadHouse_PartnerResumeAbilityScript = 0x80242348
export const RAM_D_80099BE0 = 0x80099be0
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021C3D8 = 0x8021c3d8
export const RAM_flo_17_func_80240040_CD7320 = 0x80240040
export const RAM_dgb_04_FlyingAI_Loiter = 0x80240898
export const RAM_ECAA80_UnkFloAI_Main = 0x8024150c
export const RAM_fx_energy_shockwave = 0x80070df0
export const RAM_battle_star_up_and_away_UnkStarFunc = 0x802a1494
export const RAM_pra_13_AddPlayerHandsOffset = 0x80240f7c
export const RAM_HES_MashAButton = 0x80108b80
export const RAM_b_area_omo_formation_03 = 0x8022f9d8
export const RAM_nok_13_HoppingAI_Hop = 0x80240230
export const RAM_dgb_03_pad_30D8 = 0x802430d8
export const RAM_func_8005232C = 0x8005232c
export const RAM_D_80252DC0_8FEBE0 = 0x80252dc0
export const RAM_tik_14_UpdateTexturePanStepped = 0x80240404
export const RAM_test_item_player_collision = 0x80133b0c
export const RAM_D_80231440_63CFE0 = 0x80231440
export const RAM_HES_UnusedPinkFrame = 0x80107830
export const RAM_b_area_isk_part_1_isk_07_beforeBattle_80223C80 = 0x80223c80
export const RAM_flo_00_exitWalk_80241EEC = 0x80241eec
export const RAM_resolve_npc = 0x802cdac0
export const RAM_dro_02_Quizmo_AudienceEffect = 0x8024efc4
export const RAM_dup2_b_area_kpa2_FadeBackgroundToBlack = 0x80218588
export const RAM_func_80053B04 = 0x80053b04
export const RAM_flo_21_802415C0 = 0x802415c0
export const RAM_D_80246E20_9F4A10 = 0x80246e20
export const RAM_b_area_trd_part_1_paratroopa_StartRumbleWithParams = 0x802180f0
export const RAM_kpa_12_UpdateTexturePanStepped = 0x80240164
export const RAM_AddStarPoints = 0x802d72b4
export const RAM_IES_MoneyMoney = 0x8008c384
export const RAM_D_800778C8 = 0x800778c8
export const RAM_IES_PowerJump = 0x8008c4e4
export const RAM_omo_08_UnkBufferFunc = 0x80240168
export const RAM_flo_13_80245444 = 0x80245444
export const RAM_SetMessageImages = 0x802d0c70
export const RAM_D_80234828_6BBA28 = 0x80234828
export const RAM_kmr_09_FlyingAI_Wander = 0x802401b0
export const RAM_b_area_pra2_crystal_king_array_3 = 0x8021d6bc
export const RAM_sam_02_Pipe_GetCameraYaw = 0x802401d0
export const RAM_spr_animUpdateTimeScale = 0x802df4c0
export const RAM_ui_star_spirit_disabled_pal = 0x800fba00
export const RAM_b_area_isk_part_2_chain_chomp_8021B41C = 0x8021b41c
export const RAM_pra_06_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_flo_03_npcSettings_80240EDC = 0x80240edc
export const RAM_SetPartGoalToTarget = 0x8026a404
export const RAM_BattleCam_ControlScript = 0x8029f2b4
export const RAM_EnemyNpcDefeat = 0x8007809c
export const RAM_mim_07_HoppingAI_Main = 0x80240e60
export const RAM_D_802AD06B = 0x802ad06b
export const RAM_D_800779C0 = 0x800779c0
export const RAM_kpa_11_UpdateTexturePanSmooth = 0x80240000
export const RAM_arn_07_StarSpiritEffectFunc6 = 0x80240708
export const RAM_test_ray_entities = 0x8005d448
export const RAM_D_E0022CD0 = 0xe0022cd0
export const RAM_dgb_01_D_80251EA0_C0F720 = 0x80251ea0
export const RAM_bind_enemy_ai = 0x8003e94c
export const RAM_isk_05_StoneChompAI_ChaseInit = 0x80240ea8
export const RAM_pra_20_ReflectFloor = 0x8024049c
export const RAM_get_animator_child_for_model = 0x8011e150
export const RAM_D_E00CCDE0 = 0xe00ccde0
export const RAM_func_8025DBD0 = 0x8025dbd0
export const RAM_osPfsRequestData = 0x8006d4bc
export const RAM_mac_06_DisposeFX3D = 0x80240e48
export const RAM_HES_Item_Unused_0F2 = 0x80085e98
export const RAM_CreateVirtualEntity = 0x802d3474
export const RAM_D_802A2ABC_73F31C = 0x802a2abc
export const RAM_b_area_pra2_sam_04_UnkModelStuffScript1 = 0x802211f0
export const RAM_flo_00_PatrolAI_Loiter = 0x80240500
export const RAM_b_area_isk_part_2_tutankoopa_idleAnimations_8021BA10 = 0x8021ba10
export const RAM_EA36E0_UnkFunc51 = 0x802430e4
export const RAM_CamPresetUpdate_M = 0x80249804
export const RAM_omo_03_UnkFloatFunc = 0x802403a8
export const RAM_arn_05_interact_80241A84 = 0x80241a84
export const RAM_func_802BE6A0_3221F0 = 0x802be6a0
export const RAM_dgb_06_interact_8024086C = 0x8024086c
export const RAM_dup6_b_area_kpa2_GetJumpHammerCharge = 0x8021b02c
export const RAM_b_area_omo_omo_05_foregroundModelList_8022F544 = 0x8022f544
export const RAM_HES_ProjectorReel = 0x80292bd8
export const RAM_end_01_CharAnim_FadeIn_3 = 0x8024082c
export const RAM_battle_star_smooch_UnkStarFunc1 = 0x802a1000
export const RAM_UseSettingsFrom = 0x802cb860
export const RAM_nok_02_PatrolAI_Jump = 0x80241224
export const RAM_D_802441A0_886360 = 0x802441a0
export const RAM_jan_09_SpearGuyAI_LoiterInit = 0x80240940
export const RAM_b_area_kmr_part_3_final_jr_troopa_defenseTable_8022C424 = 0x8022c424
export const RAM_b_area_kmr_part_3_paragoomba1_defenseTable_8021C1BC = 0x8021c1bc
export const RAM_dgb_01_npcGroupList_80246E50 = 0x80246e50
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_rotate = 0x80233888
export const RAM_action_command_spiny_surge_free_hud_elements = 0x802a981c
export const RAM_fold_groupOffsets = 0x8014ef64
export const RAM_dro_01_dup_GetItemName = 0x80243514
export const RAM_kmr_20_ToadHouse_Stub = 0x80241594
export const RAM_mac_02_Quizmo_SetCamVfov = 0x8024232c
export const RAM_flo_16_FlyingNoAttackAI_13 = 0x80242478
export const RAM_HES_FrozenBegin = 0x80105fa8
export const RAM_snowfall_update = 0xe008a53c
export const RAM_dgb_16_npcGroup_80241E2C = 0x80241e2c
export const RAM_b_area_sam_formation_0D = 0x80231084
export const RAM_D_E0022D14 = 0xe0022d14
export const RAM_dgb_00_802413F4 = 0x802413f4
export const RAM_vtx_curtains6 = 0x80075bf0
export const RAM_AlNumFields = 0x800daab8
export const RAM_D_802A2280 = 0x802a2280
export const RAM_D_802EAE7C = 0x802eae7c
export const RAM_ui_status_text_1_pal = 0x801000a0
export const RAM_remove_part_decoration = 0x80267218
export const RAM_D_8029F244 = 0x8029f244
export const RAM_D_80244C48_A3E128 = 0x80244c48
export const RAM_D_09000900_3641C0 = 0x09000900
export const RAM_ui_battle_hp_7_pal = 0x8028ab40
export const RAM_HES_Item_PayOff = 0x80084758
export const RAM_D_8015DD24 = 0x80155d24
export const RAM_SetBattleCamPos = 0x8024e9b0
export const RAM_D_E010AF90 = 0xe010af90
export const RAM_kzn_03_PiranhaPlantAI_Main = 0x8024234c
export const RAM_initialize_curtains = 0x8002ba40
export const RAM_flo_16_SuperBlock_GetPartnerRank = 0x80240148
export const RAM_battle_move_auto_multibounce_D_802A15D8 = 0x802a15d8
export const RAM_update_player_input = 0x800e1f90
export const RAM_wCustomModelGfxBuilders = 0x80152090
export const RAM_EED1E0_UnkFunc44 = 0x80240e18
export const RAM_b_area_omo2_2_stilt_guy_idleAnimations2 = 0x80231120
export const RAM_dro_02_func_8024190C_96AACC = 0x8024190c
export const RAM_Gfx_LoadStencilTex_Mario = 0x8014e670
export const RAM_nuContNum = 0x8009a614
export const RAM_b_area_sam2_sam_02_Set80071270_0_16 = 0x80219098
export const RAM_entity_HiddenPanel_hide = 0x802e6390
export const RAM_sam_01_Quizmo_GetCamVfov = 0x802412e0
export const RAM_ui_status_text_hp_pal = 0x80100640
export const RAM_b_area_trd_part_2_fake_bowser_8021CAE4 = 0x8021cae4
export const RAM_PlaySoundAtActor = 0x8025385c
export const RAM_func_80241D30_844900 = 0x80241d30
export const RAM_omo_06_GetItemName = 0x80240924
export const RAM_D_802A1B44_7383D4 = 0x802a1b74
export const RAM_kzn_17_LetterDelivery_SavedNpcAnim = 0x802467b4
export const RAM_omo_07_StashVars = 0x80242f20
export const RAM_dgb_01_D_80253520_C10DA0 = 0x80253520
export const RAM_battle_item_sleepy_sheep_DrinkItem = 0x802a1eb4
export const RAM_dgb_08_npcAI_802442F0 = 0x802442f0
export const RAM_func_800553F4 = 0x800553f4
export const RAM_D_090003A0 = 0x090003a0
export const RAM_HES_Item_InsecticideHerb_disabled = 0x800826e8
export const RAM_jan_03_PatrolAI_MoveInit = 0x80240620
export const RAM_b_area_iwa_cleft_802197AC = 0x802197ac
export const RAM_set_entity_commandlist = 0x8010fbc0
export const RAM_D_80296C8C = 0x80296c8c
export const RAM_battle_star_chill_out_UnkStarFunc2 = 0x802a10ac
export const RAM_dgb_14_pad_47C = 0x8024047c
export const RAM_func_8004DB4C = 0x8004db4c
export const RAM_vtx_C2A740 = 0x80209800
export const RAM_gMessagePrinters = 0x80154c30
export const RAM_dgb_16_npcSettings_80241A20 = 0x80241a20
export const RAM_battle_star_peach_beam_D_802A3534_7FCCE4 = 0x802a3534
export const RAM_iwa_01_UnkNpcAIFunc48 = 0x80240710
export const RAM_end_00_credits_update_line = 0x80241b34
export const RAM_sam_02_Quizmo_Answers = 0x80246bc4
export const RAM_D_802439F8_A6AAF8 = 0x802439f8
export const RAM_battle_item_mystery_model = 0x802a2130
export const RAM_b_area_isk_part_2_isk_02b_foregroundModelList_8021FF44 = 0x8021ff44
export const RAM_func_80240000_AED410 = 0x80240000
export const RAM_vtx_C29A40 = 0x80208b00
export const RAM_GetTransformGroup = 0x802c9b40
export const RAM_b_area_kmr_part_1_kmr_04_UnkAngleFunc2 = 0x80218a50
export const RAM_battle_star_star_beam_UnkBackgroundFunc = 0x802a1414
export const RAM_gCurrentCustomModelGfxPtr = 0x801512e8
export const RAM_func_80238370_703E60 = 0x80238370
export const RAM_b_area_jan_spear_guy_takeTurn_8021D8D8 = 0x8021d8d8
export const RAM_b_area_omo_groove_guy_UnkBattleFunc1 = 0x802186c0
export const RAM_dup_kkj_10_GetPeachDisguise = 0x80240f18
export const RAM_NAMESPACE_8023A1AC = 0x8023a1ac
export const RAM_D_80077A2C = 0x80077a2c
export const RAM_omo_07_PatrolAI_Loiter = 0x80240a2c
export const RAM_D_8024DFE4 = 0x8024dfe4
export const RAM_au_BGMCmd_E2_MasterPitchShift = 0x8004fce4
export const RAM_b_area_arn_formation_09 = 0x80227ee4
export const RAM_AddAnimatedModelRootPosition = 0x802cd12c
export const RAM_gPartnerIconHudScripts = 0x8008eff8
export const RAM_func_80241274_A2B4B4 = 0x80241274
export const RAM_trd_02_SetEntityFlags100000 = 0x80240000
export const RAM_b_area_arn_hyper_cleft_takeTurn_80221A58 = 0x80221a58
export const RAM_flo_03_foliage_setup_shear_mtx = 0x802403f0
export const RAM_D_8015C790 = 0x8015c790
export const RAM_D_090008C0_3317C0 = 0x090008c0
export const RAM_func_80240070_BA47F0 = 0x80240070
export const RAM_Entity_PinkFlowerLight = 0x802bc7d0
export const RAM_D_80246568 = 0x80246568
export const RAM_dro_01_PatrolAI_PostLoiter = 0x80240c5c
export const RAM_CamPresetUpdate_E = 0x8024c180
export const RAM_D_8009A5B4 = 0x8009a5b4
export const RAM_osRomBase = 0x80000308
export const RAM_ED00D0_foliage_setup_shear_mtx = 0x802400e0
export const RAM_D_80098710 = 0x80098710
export const RAM_gSimpleWindowUpdates = 0x8014f250
export const RAM_jan_00_LetterDelivery_Init = 0x80240860
export const RAM_b_area_nok_paragoomba_init_8021B3E8 = 0x8021b3e8
export const RAM_kpa_102_ProjectileHitbox_33 = 0x80240828
export const RAM_osFlashReadArray = 0x8006f75c
export const RAM_D_802EB370 = 0x802eb370
export const RAM_HES_Item_Unused_139_disabled = 0x80082568
export const RAM_sbk_12_SpawnSunEffect = 0x80240000
export const RAM_D_80075FF8 = 0x80075ff8
export const RAM_D_802A3378_73FBD8 = 0x802a3378
export const RAM_flo_14_D_80245320_CD0630 = 0x80245320
export const RAM_vtx_C2BB50 = 0x8020ac10
export const RAM_isk_13_HoppingAI_Main = 0x80240bf0
export const RAM_TwinkPutAway = 0x802bd1b0
export const RAM_tik_17_SuperBlock_SetOverride40 = 0x80240030
export const RAM_dgb_07_ClubbaNappingAI_Loiter = 0x80240a68
export const RAM_func_8026ED20 = 0x8026ed20
export const RAM_b_area_mac_lee_handleEvent_80225B90 = 0x80225b90
export const RAM_b_area_arn_tubbas_heart = 0x80223d78
export const RAM_D_8029FB80 = 0x8029fb80
export const RAM_D_802E97B0 = 0x802e97d8
export const RAM_HES_StickHoldLeft = 0x80108e48
export const RAM_stars_spread_appendGfx = 0xe005a3f4
export const RAM_func_80029994 = 0x80029994
export const RAM_osViRepeatLine = 0x800673e0
export const RAM_rumbleMaxDuration = 0x80074260
export const RAM_entity_init_SuperBlockContent1 = 0x802e5f50
export const RAM_b_area_omo2_5_shy_squad_dup_idleAnimations = 0x80231320
export const RAM_func_80240B60_A27A30 = 0x80240b60
export const RAM_evt_handle_if_greater_equal = 0x802c4e2c
export const RAM_b_area_mac_lee_intTable_802208B0 = 0x802208b0
export const RAM_omo_09_ProjectileHitbox_30 = 0x8024268c
export const RAM_EB8E90_PatrolAI_Loiter = 0x80240500
export const RAM_mim_07_HoppingAI_ChaseInit = 0x80240a3c
export const RAM_dgb_11_pad_56C = 0x8024056c
export const RAM_action_command_dizzy_shell_draw_hud_elements = 0x802a96dc
export const RAM_kpa_91_ToadHouse_GetPartnerBackOut = 0x802417ec
export const RAM_dead_rand_int = 0x8002acec
export const RAM_dro_01_init_802484E0 = 0x802484e0
export const RAM_arn_12_init_8024075C = 0x8024075c
export const RAM_bombette_breaking_update = 0xe0084660
export const RAM_dead_npc_test_move_simple_without_slipping = 0x800e53ac
export const RAM_render_window_root = 0x80147ac4
export const RAM_gPauseMapMarioY = 0x80270710
export const RAM_D_8023161C_63D1BC = 0x8023161c
export const RAM_D_E00CCEE0 = 0xe00ccee0
export const RAM_flo_21_StarSpiritEffectFunc4 = 0x802405bc
export const RAM_func_802403B0_B52BE0 = 0x802403b0
export const RAM_TimesHudScript = 0x800f7fa8
export const RAM_IES_GearBoots2 = 0x8008b404
export const RAM_D_8010D65C = 0x8010d65c
export const RAM_pra_02_SetPartnerFlags80000 = 0x80240eb8
export const RAM_func_80261164 = 0x80261164
export const RAM_HES_UnusedBadge_disabled = 0x80241e28
export const RAM_kmr_02_ToadHouse_UpdateScreenOverlay = 0x802430b8
export const RAM_dro_01_EVS_Quizmo_GiveItem_0 = 0x802451bc
export const RAM_dgb_16_ClubbaNappingAI_ReturnHomeInit = 0x80240ba0
export const RAM_b_area_hos_magikoopa_8021E46C = 0x8021e46c
export const RAM_func_802A1078 = 0x802a1078
export const RAM_nok_02_PatrolNoAttackAI_15 = 0x80241554
export const RAM_D_8014EE98 = 0x8014ee98
export const RAM_D_8010D698 = 0x8010d698
export const RAM_smoke_impact_render = 0xe003a310
export const RAM_action_command_fire_shell_MashActionCommandInit = 0x802a9178
export const RAM_flo_08_npcGroup_80244408 = 0x80244408
export const RAM_D_E001A620 = 0xe001a620
export const RAM_trd_02_set_script_owner_npc_col_height = 0x802403f4
export const RAM_mdl_renderModelFogColorG = 0x8014b755
export const RAM_flo_14_AddPlayerHandsOffset = 0x80242064
export const RAM_D_80243E80_BA45B0 = 0x80243e80
export const RAM_tik_12_UpdateTexturePanStepped = 0x80240404
export const RAM_pra_39_ReflectPartner = 0x80240d6c
export const RAM_b_area_pra3_gray_magikoopa_UnkFunc52 = 0x80219e48
export const RAM_b_area_omo_yellow_shy_guy_8021CAC4 = 0x8021cac4
export const RAM_kzn_09_MeleeHitbox_CanSeePlayer = 0x80241228
export const RAM_kzn_11_FlyingAI_Main = 0x8024176c
export const RAM_func_8024280C_8B287C = 0x8024280c
export const RAM_kmr_02_Quizmo_ShouldAppear = 0x802404cc
export const RAM_shattering_stones_main = 0xe002c000
export const RAM_shim_get_background_color_blend = 0xe0200670
export const RAM_battle_item_mushroom_DrinkItem = 0x802a1b44
export const RAM_b_area_pra3_duplighost_parakarry_doTakeTurn = 0x80220dd4
export const RAM_HES_SmallText2 = 0x80242148
export const RAM_ui_status_pow_star_2_png = 0x80100940
export const RAM_btl_cam_set_pos = 0x8024e554
export const RAM_D_802440E3 = 0x802440e3
export const RAM_b_area_arn_tubbas_heart_handleEvent_80224038 = 0x80224038
export const RAM_dgb_08_npcSettings_80244360 = 0x80244360
export const RAM_au_SEFCmd_11_Restart = 0x8004d310
export const RAM_func_802408F0_BB7960 = 0x802408f0
export const RAM_nok_02_Quizmo_HideWorld = 0x80241d3c
export const RAM_b_area_omo3_IsGameStatusUnkAA_1 = 0x802186a0
export const RAM_light_rays_render = 0xe006a814
export const RAM_func_800E98D4 = 0x800e98d4
export const RAM_flo_15_npcSettings_80240300 = 0x80240300
export const RAM_func_802A47E0 = 0x802a47e0
export const RAM_dgb_08_PatrolAI_ChaseInit = 0x8024081c
export const RAM_battle_move_jump_charge_1_UnkMoveFunc1 = 0x802a1000
export const RAM_b_area_omo_omo_04_slot_machine_start = 0x8022e038
export const RAM_pause_draw_rect = 0x80244858
export const RAM_b_area_omo_medi_guy_80220CEC = 0x8022486c
export const RAM_battle_move_multibounce_D_802A1FEC = 0x802a1fec
export const RAM_pra_29_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_btl_state_draw_end_player_turn = 0x80246b2c
export const RAM_pra_40_ReflectWall = 0x802400ec
export const RAM_dro_02_ToadHouse_ShowStatusMenu = 0x802434a4
export const RAM_SetActorScaleModifier = 0x8026c9c4
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_nextTurn_80229B38 = 0x80229b38
export const RAM_hos_04_UpdateTexturePanStepped = 0x802403cc
export const RAM_entity_HeartBlockContent_idle = 0x802e545c
export const RAM_b_area_kmr_part_2_blue_goomba_2_idleAnimations_8021D550 = 0x8021d550
export const RAM_osPfsInitPak = 0x80069190
export const RAM_kzn_04_SuperBlock_SetOverride40 = 0x80240310
export const RAM_func_80238810_704300 = 0x80238810
export const RAM_b_area_pra3_duplighost_kooper = 0x8021d87c
export const RAM_b_area_isk_part_1_buzzy_beetle_8021E89C = 0x8021e89c
export const RAM_UseDynamicShadow = 0x80111f2c
export const RAM_battle_item_strange_cake_func_802A1B68_731E68 = 0x802a1b68
export const RAM_D_8024A7D0_DD74A0 = 0x8024a7d0
export const RAM_b_area_nok_kent_c_koopa_idle_80227DD4 = 0x80227dd4
export const RAM_add_star_pieces = 0x800ea170
export const RAM_hos_10_UpdateTexturePanStepped = 0x80240144
export const RAM_state_init_language_select = 0x80035000
export const RAM_D_80228440_6CBFD0 = 0x80228440
export const RAM_pra_38_ReflectWall = 0x8024011c
export const RAM_func_80240D38_989898 = 0x80240d38
export const RAM_nok_01_Quizmo_StashVars = 0x80240d68
export const RAM_func_80240FE0_A2B220 = 0x80240fe0
export const RAM_D_8029D5D8 = 0x8029d5d8
export const RAM_D_8009839C = 0x8009839c
export const RAM_flo_21_npcSettings_80241870 = 0x80241870
export const RAM_func_80241134_B21674 = 0x80241134
export const RAM_dro_01_D_80248D4C_963F4C = 0x80248d4c
export const RAM_D_E0122BA4 = 0xe0122ba4
export const RAM_world_goombario_pre_battle = 0x802bdbbc
export const RAM_kzn_10_UnkFunc51 = 0x80240638
export const RAM_GetPartTargetFlags = 0x8026d2ac
export const RAM_omo_02_StationaryAI_Chase = 0x802412b8
export const RAM_D_E0116C68 = 0xe0116c68
export const RAM_sbk_66_foliage_setup_shear_mtx = 0x80240140
export const RAM_b_area_pra3_duplighost_sushie_parts = 0x8022396c
export const RAM_arn_03_npcGroup_80243568 = 0x80243568
export const RAM_ice_shard_init = 0xe0110224
export const RAM_kkj_16_UnkPhysicsFunc = 0x80240060
export const RAM_b_area_sbk_formation_10 = 0x8021fe2c
export const RAM_b_area_iwa_iwa_01_handleEvent_80220B40 = 0x80220b40
export const RAM_JumpPartTo = 0x8027a4e4
export const RAM_sam_01_Quizmo_StageEffect = 0x8024df18
export const RAM_end_01_credits_update_line = 0x80241b44
export const RAM_D_09000000_3638C0 = 0x09000000
export const RAM_D_802314C8_63D068 = 0x802314c8
export const RAM_IES_ShootingStar = 0x8008d654
export const RAM_screen_overlay_frontZoom = 0x80156904
export const RAM_EnablePartner = 0x802d1efc
export const RAM_b_area_kzn_putrid_piranha = 0x8021b1d0
export const RAM_flo_17_npcGroup_80244C58 = 0x80244c58
export const RAM_SetTargetOffset = 0x8027d380
export const RAM_arn_10_enterSingleDoor_80240290 = 0x80240290
export const RAM_b_area_omo2_4_signal_guy_idle = 0x8023129c
export const RAM_nok_13_HoppingAI_ChaseInit = 0x802407cc
export const RAM_flo_03_intTable_80240FB4 = 0x80240fb4
export const RAM_filemenu_update_hidden_with_rotation = 0x80243898
export const RAM_omo_07_FlyingAI_LoiterInit = 0x80242150
export const RAM_dgb_00_init_80241FC4 = 0x80241fc4
export const RAM_arn_02_npcAI_8024147C = 0x8024147c
export const RAM_jan_03_Pipe_GetCameraYaw = 0x802401d0
export const RAM_dgb_01_npcSettings_80245340 = 0x80245340
export const RAM_ui_green_arrow_down_pal = 0x800fc460
export const RAM_hos_06_SomeItemEntityFunc = 0x802420e8
export const RAM_b_area_sam_duplighost_bow_idleAnimations = 0x8022251c
export const RAM_battle_move_power_smash_IsRightOnEquipped = 0x802a1050
export const RAM_shop_draw_item_name = 0x80281434
export const RAM_HES_MenuBoots1 = 0x80108744
export const RAM_IES_DDownJump = 0x8008c824
export const RAM_ui_battle_cmd_flee_2_png = 0x8028da20
export const RAM_flo_22_80241F6C = 0x80241f6c
export const RAM_b_area_arn_hyper_paragoomba_8021E058 = 0x8021e058
export const RAM_obfuscated_battle_heap_create = 0x7012aca1
export const RAM_func_80240AD0_890670 = 0x80240ad0
export const RAM_func_80240010_8FEE90 = 0x80240010
export const RAM_func_80263064 = 0x80263064
export const RAM_dgb_15_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_mac_03_Quizmo_CreateReactionEffect = 0x802414a8
export const RAM_b_area_mim_formation_0C = 0x8021e8b0
export const RAM_SetCommandAutoSuccess = 0x8026939c
export const RAM_motion_blur_flame_render = 0xe00a21ec
export const RAM_mac_02_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_b_area_pra3_gray_magikoopa_idle = 0x8022f0d4
export const RAM_flo_17_main = 0x802437dc
export const RAM_dup_omo_03_UnsetCamera0MoveFlag1 = 0x80240020
export const RAM_func_802BB7E0_E30130 = 0x802bb7e0
export const RAM_draw_entity_model_C = 0x80122088
export const RAM_b_area_trd_part_2_red_ninja_koopa_init_8022AC8C = 0x8022ac8c
export const RAM_dup_mac_01_PatrolAI_LosePlayer = 0x80241aec
export const RAM_b_area_jan2_white_magikoopa_UnkFunc52 = 0x802184f8
export const RAM_dro_02_802488CC = 0x802488cc
export const RAM_HES_Item_Hammer1 = 0x800806a0
export const RAM_func_E005E318 = 0xe005e318
export const RAM_CountPlayerTargets = 0x8026a14c
export const RAM_fx_something_rotating = 0x80072770
export const RAM_UseDoorSounds = 0x802d6314
export const RAM_sDlists2 = 0xe000cc24
export const RAM_pra_13_ReflectWall = 0x802400ec
export const RAM_flo_15_init_80240DB4 = 0x80240db4
export const RAM_dgb_00_enterDoubleDoor_8024038C = 0x8024038c
export const RAM_D_802EB040 = 0x802eb040
export const RAM_filemenu_info_draw_message_contents = 0x80247d30
export const RAM_D_8024F2AC = 0x8024f2ac
export const RAM_ui_coin_sparkle_4_pal = 0x800fbda0
export const RAM_battle_move_hammer_throw_IsRightOnEquipped = 0x802a1050
export const RAM_flo_19_func_80240540_CE3C30 = 0x80240540
export const RAM_arn_05_npcGroup_8024223C = 0x8024223c
export const RAM_b_area_kmr_part_2_blue_goomba_handleEvent_80219D74 = 0x80219d74
export const RAM_HES_Item_KootBook = 0x80082058
export const RAM_pra_19_GetNpcCollisionHeight = 0x80240f20
export const RAM_battle_item_egg_missile_UseItem = 0x802a1614
export const RAM_dgb_08_extraAnimationList_80244290 = 0x80244290
export const RAM_load_mesh_animator_node = 0x80120420
export const RAM_drop_leaves_main = 0xe0028000
export const RAM_battle_item_stone_cap_UseItem = 0x802a16d4
export const RAM_dro_01_Quizmo_ScriptArray = 0x8024dfc8
export const RAM_func_80240920_BD4190 = 0x80240920
export const RAM_func_8004E880 = 0x8004e880
export const RAM_func_800E5348 = 0x800e5348
export const RAM_b_area_omo2_1_shy_squad_displace_last_guy = 0x802327e4
export const RAM_draw_msg = 0x801264ec
export const RAM_add_SP = 0x800ea1f4
export const RAM_D_8024F2A3 = 0x8024f2a3
export const RAM_GetPlayerActionState = 0x802d1dd0
export const RAM_func_802A9208 = 0x802a9208
export const RAM_jan_02_PatrolNoAttackAI_15 = 0x80240f04
export const RAM_func_8024034C_A577FC = 0x8024034c
export const RAM_D_802E9A88 = 0x802e9a88
export const RAM_dead_gPlayerStatus = 0x80117578
export const RAM_b_area_pra3_duplighost_attack = 0x8021a6dc
export const RAM_dgb_03_npcSettings_80243BC8 = 0x80243bc8
export const RAM_tik_10_UnkPosFunc2 = 0x80240310
export const RAM_D_E00E6910 = 0xe00e6910
export const RAM_D_E001C5E0 = 0xe001c5e0
export const RAM_floating_rock_render = 0xe009829c
export const RAM_battle_star_up_and_away_UnkStarFunc3 = 0x802a116c
export const RAM_au_song_load = 0x800558d4
export const RAM_b_area_isk_part_1_formation_0F = 0x8022452c
export const RAM_b_area_sam_sam_01_UnkModelStuffScript2 = 0x8022f01c
export const RAM_init_model_data = 0x80115fe8
export const RAM_wPartnerMoveSpeed = 0x800f8030
export const RAM_dgb_01_settings = 0x80243460
export const RAM_battle_item_insecticide_herb_model = 0x802a1c00
export const RAM_kmr_20_ToadHouse_CamSetFOV = 0x80241364
export const RAM_nok_11_FlyingAI_Jump = 0x80241428
export const RAM_D_E0046618 = 0xe0046618
export const RAM_func_80240000_9A4650 = 0x80240000
export const RAM_func_8026220C = 0x8026220c
export const RAM_dgb_08_settings = 0x80243cb0
export const RAM_b_area_iwa_formation_01 = 0x8021822c
export const RAM_HES_Item_XBandage_disabled = 0x80087668
export const RAM_b_area_omo_omo_05_beforeBattle_8022F4D0 = 0x8022f4d0
export const RAM_func_80238C58_70C968 = 0x80238c58
export const RAM_D_80258407_818C87 = 0x80258407
export const RAM_load_model_textures = 0x80115c24
export const RAM_sbk_01_exitWalk_802400E0 = 0x802400e0
export const RAM_b_area_sam_gulpit_rocks_parts = 0x802295a0
export const RAM_func_802435C4 = 0x802435c4
export const RAM_HES_HPDigit7 = 0x802920c0
export const RAM_func_802449F0_A2EC30 = 0x802449f0
export const RAM_mac_01_Quizmo_AddViewRelativeOffset = 0x80242b90
export const RAM_ShowSleepBubble = 0x802d8028
export const RAM_spr_load_npc_sprite = 0x802de0ec
export const RAM_D_802A2F80_742C40 = 0x802a2f68
export const RAM_kzn_02_FlyingNoAttackAI_12 = 0x802419d8
export const RAM_mac_04_GetNpcCollisionHeight = 0x802414f4
export const RAM_EED1E0_SuperBlock_SetOverride40 = 0x802400e0
export const RAM_kzn_03_UnkNpcAIFunc12 = 0x802404c0
export const RAM_kkj_17_GetItemName = 0x802400b4
export const RAM_flo_13_802442B0 = 0x802442b0
export const RAM_D_802474D8_B65788 = 0x802474d8
export const RAM_jan_03_ToadHouse_PutPartnerAway = 0x80241740
export const RAM_confetti_render = 0xe00887a8
export const RAM_func_80241DAC_84497C = 0x80241dac
export const RAM_b_area_omo2_3_shy_stack_doSpinSmashHit = 0x80234a90
export const RAM_D_80241C10_EB8E40 = 0x80241c10
export const RAM_b_area_pra3_pra_02_afterBattle = 0x80231ecc
export const RAM_D_8014C050 = 0x8014c050
export const RAM_pra_13_802438E8 = 0x802438e8
export const RAM_nok_01_Quizmo_CreateReactionEffect = 0x80241d60
export const RAM_mdl_bgMultiplyColorB = 0x8014b74f
export const RAM_battle_item_mushroom_GiveRefund = 0x802a1000
export const RAM_pra_12_reflection_setup_floor = 0x80240530
export const RAM_shape_spell_appendGfx = 0xe0024454
export const RAM_D_090023A0_388750 = 0x090023a0
export const RAM_D_09000220_3CFD10 = 0x09000220
export const RAM_b_area_mac_lee_idle_80228604 = 0x80228604
export const RAM_world_bow_use_ability = 0x802be01c
export const RAM_func_80240290_AB6520 = 0x80240290
export const RAM_dgb_08_npcAI_8024438C = 0x8024438c
export const RAM_ice_shard_render = 0xe01103a4
export const RAM_HES_Item_GearHammer3 = 0x800813c8
export const RAM_battle_item_dried_shroom_GiveRefundCleanup = 0x802a11d4
export const RAM_D_80263510_C20D90 = 0x80263510
export const RAM_b_area_mac_lee_kooper_UnkBattleFunc1 = 0x80218e74
export const RAM_dgb_09_SentinelAI_Main = 0x80243578
export const RAM_FadeInMusic = 0x802d5df4
export const RAM_b_area_kmr_part_2_goomnut_tree_tree_leaves = 0x80223d44
export const RAM_kmr_05_FlyingAI_Wander = 0x802401b0
export const RAM_trd_04_PatrolNoAttackAI_15 = 0x80241758
export const RAM_dro_01_EVS_Quizmo_NPC_Interact = 0x8024769c
export const RAM_b_area_omo2_general_guy_idle = 0x802186b8
export const RAM_EED1E0_SuperBlock_AnimateEnergyOrbs = 0x802406e0
export const RAM_nok_01_Quizmo_SetStageLightsDelay = 0x802417a4
export const RAM_kpa_09_ProjectileHitbox_GetUsableProjectileID = 0x802400a0
export const RAM_BattleCamTargetActor = 0x8024eb24
export const RAM_D_8023C1CC = 0x8023c1cc
export const RAM_HES_Item_ApplePie = 0x80082838
export const RAM_osContGetReadData = 0x80061a28
export const RAM_b_area_sam_sam_02c = 0x8022feac
export const RAM_battle_move_hammer_charge_2_UnkMoveFunc3 = 0x802a11e8
export const RAM_func_80241610_97F0E0 = 0x80241610
export const RAM_D_80232C54_6849D4 = 0x80232c54
export const RAM_b_area_sam2_monstar_idleAnimations = 0x80219f90
export const RAM_D_80220780 = 0x80220780
export const RAM_b_area_omo2_5_shy_squad_dup = 0x802312f8
export const RAM_entity_base_block_update_slow_sinking = 0x802e3370
export const RAM_D_80232B00_684880 = 0x80232b00
export const RAM_pra_12_ReflectFloor = 0x802404cc
export const RAM_mac_01_Quizmo_CreateReactionEffect = 0x80242f08
export const RAM_D_E0088D70 = 0xe0088d70
export const RAM_b_area_sam_sam_02c_Set80071270_0_16 = 0x8021a708
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_idleAnimations_80227F50 = 0x80227f50
export const RAM_SetActorDispOffset = 0x8026ba04
export const RAM_sam_06_FlyingAI_JumpInit = 0x8024112c
export const RAM_D_8009A660 = 0x8009a660
export const RAM_is_player_action_state = 0x80110bb0
export const RAM_HES_StatusDigit5 = 0x80108180
export const RAM_iwa_04_CleftAI_Tackle = 0x802408b4
export const RAM_entity_fog_green = 0x80154380
export const RAM_water_splash_init = 0xe00be39c
export const RAM_sam_02_Quizmo_ShouldAppear = 0x80240c68
export const RAM_flo_11_main = 0x802418a8
export const RAM_pra_14_SomeItemEntityFunc = 0x80241038
export const RAM_WaitForState = 0x8026e198
export const RAM_HES_WeakenedBegin = 0x801062dc
export const RAM_dgb_01_npcGroup_80246880 = 0x80246880
export const RAM_D_8024462C_EEABDC = 0x8024462c
export const RAM_func_802407E8_A1B8E8 = 0x802407e8
export const RAM_dgb_01_npcGroup_80246470 = 0x80246470
export const RAM_D_80270070 = 0x80270070
export const RAM_mac_05_PatrolNoAttackAI_15 = 0x80240f64
export const RAM_spr_init_sprites = 0x802dd8f8
export const RAM_D_80249BDC = 0x80249bdc
export const RAM_arn_12_80240420 = 0x80240420
export const RAM_D_8010C994 = 0x8010c994
export const RAM_SetNpcAux = 0x80044da4
export const RAM_D_E00DA501 = 0xe00da501
export const RAM_jan_10_HoppingAI_Hop = 0x80240540
export const RAM_contRmbForceStopMesg = 0x8005fe0c
export const RAM_b_area_isk_part_1_stone_chomp_idle_80221D00 = 0x80221d00
export const RAM_ui_battle_cmd_aim_marker_4_png = 0x8028e800
export const RAM_arn_03_padding1 = 0x80241bb8
export const RAM_gPauseDLWorldMap = 0x8026f118
export const RAM_dust_update = 0xe002a28c
export const RAM_D_E0122C20 = 0xe0122c20
export const RAM_D_80234900_6BBB00 = 0x80234900
export const RAM_HES_Item_Koopasta_disabled = 0x800829e8
export const RAM_b_area_trd_part_1_paratroopa_idle_8021EF5C = 0x8021ef5c
export const RAM_btl_state_update_begin_turn = 0x80241b14
export const RAM_HES_Item_PowerQuake_disabled = 0x80083dc8
export const RAM_HES_Item_SpinSmash = 0x800839d8
export const RAM_omo_08_ShyGuyWanderAI_17 = 0x80240a48
export const RAM_omo_07_FlyingAI_JumpInit = 0x80242554
export const RAM_b_area_trd_part_3_eldstar_defenseTable_8021828C = 0x8021828c
export const RAM_au_fade_init = 0x80053974
export const RAM_jan_10_HoppingAI_LoiterInit = 0x80240878
export const RAM_arn_05_PatrolAI_Jump = 0x802407a4
export const RAM_ui_no_controller_png = 0x80076140
export const RAM_pra_14_ReflectWall = 0x802400ec
export const RAM_b_area_arn_arn_06_afterBattle_80227C20 = 0x80227c20
export const RAM_func_802BC3CC_E2ECFC = 0x802bc3cc
export const RAM_b_area_kmr_part_1_kmr_05 = 0x80220a78
export const RAM_flo_13_LakituAI_GetAvailableSpiny = 0x802416bc
export const RAM_HES_Item_MapleUltra_disabled = 0x80082928
export const RAM_b_area_trd_part_1_trd_05d_802265C8 = 0x802265c8
export const RAM_sam_11_Quizmo_Answers = 0x80244fdc
export const RAM_CamPreset_E = 0x80280de4
export const RAM_kmr_12_GoombaAI = 0x802402e0
export const RAM_gSpeakingActorTalkAnim = 0x8029fa6c
export const RAM_flo_00_npcGroup_80245A04 = 0x80245a04
export const RAM_osPfsFileState = 0x80068c20
export const RAM_osStopThread = 0x80066140
export const RAM_D_8029DA50 = 0x8029da50
export const RAM_D_8029D5D0 = 0x8029d5d0
export const RAM_battle_item_tasty_tonic_DrinkItem = 0x802a1824
export const RAM_gPauseWS_23 = 0x8026fe08
export const RAM_modify_collider_family_flags = 0x802c9ce8
export const RAM_D_09002190_3523B0 = 0x09002190
export const RAM_HES_Item_KoopaLeaf_disabled = 0x80086b88
export const RAM_action_command_air_raid_free_hud_elements = 0x802a992c
export const RAM_B_801512F0 = 0x801512f0
export const RAM_D_E008E8A0 = 0xe008e8a0
export const RAM_D_E008AA50 = 0xe008aa50
export const RAM_D_80242850_8D3D60 = 0x80242850
export const RAM_D_802EB048 = 0x802eb048
export const RAM_gPartnerPopupProperties = 0x8008ef20
export const RAM_D_E004C660 = 0xe004c660
export const RAM_au_reset_voice = 0x800538c4
export const RAM_dup_jan_10_foliage_setup_shear_mtx = 0x80241500
export const RAM_btl_cam_target_actor = 0x8024e43c
export const RAM_flo_25_npcGroupList_80243C5C = 0x80243c5c
export const RAM_au_BGMCmd_NOP = 0x80050560
export const RAM_func_80280818 = 0x80280818
export const RAM_flo_07_settings = 0x80240760
export const RAM_func_80072BCC = 0x80072bcc
export const RAM_b_area_pra2_crystal_king_RemoveCrystalBit = 0x8021f150
export const RAM_D_80241440_D58870 = 0x80241440
export const RAM_sam_05_PiranhaPlantAI_10 = 0x80240a38
export const RAM_flo_07_8024096C = 0x8024096c
export const RAM_D_09000860_326C70 = 0x09000860
export const RAM_D_80244028_BA4758 = 0x80244028
export const RAM_D_800B0C74 = 0x800b0c74
export const RAM_gHudElementCacheSizeBattle = 0x80158574
export const RAM_func_80239F84_70DC94 = 0x80239f84
export const RAM_func_802BC0B8_E2E9E8 = 0x802bc0b8
export const RAM_D_E0116C60 = 0xe0116c60
export const RAM_b_area_nok_spiked_goomba_init_8021E54C = 0x8021e54c
export const RAM_flo_13_set_script_owner_npc_col_height = 0x802425ac
export const RAM_arn_02_npcAISettings_802413D0 = 0x802413d0
export const RAM_HES_Klevar = 0x80107b48
export const RAM_D_802EB140 = 0x802eb140
export const RAM_b_area_sam_duplighost_kooper_statusTable = 0x8021e72c
export const RAM_flo_03_intTable_80240F6C = 0x80240f6c
export const RAM_b_area_kmr_part_3_final_jr_troopa_UnkBattleFunc1 = 0x80218ad0
export const RAM_walking_dust_main = 0xe000e000
export const RAM_arn_09_makeEntities = 0x80240488
export const RAM_D_80234858_6BBA58 = 0x80234858
export const RAM_npc_raycast_down_sides = 0x800dcb7c
export const RAM_kmr_02_Quizmo_StageEffect = 0x80257f48
export const RAM_D_80251438_D4BA08 = 0x80251438
export const RAM_arn_02_802411A8 = 0x802411a8
export const RAM_mac_01_ToadHouse_PartnerSuspendAbilityScript = 0x80243920
export const RAM_func_802182A4_5CE934 = 0x802182a4
export const RAM_battle_star_peach_focus_802A1C60 = 0x802a1c60
export const RAM_set_map_transition_effect = 0x801382a0
export const RAM_load_map_bg = 0x80145d70
export const RAM_iwa_04_CleftAI_FindPlayer = 0x80240550
export const RAM_D_09001C80 = 0x09001c80
export const RAM_dgb_00_80240110 = 0x80240110
export const RAM_func_80242704_A2C944 = 0x80242704
export const RAM_D_E004C67E = 0xe004c67e
export const RAM_b_area_kmr_part_3_spiked_goomba_idleAnimations_8021A4E0 = 0x8021a4e0
export const RAM_func_80268E88 = 0x80268e88
export const RAM_sam_03_MeleeHitbox_30 = 0x80240050
export const RAM_hos_03_Quizmo_UnkStageEffectMode = 0x80242f14
export const RAM_b_area_kmr_part_1_formation_00 = 0x802191e0
export const RAM_b_area_pra3_red_magikoopa_8022E558 = 0x8022e558
export const RAM_shockwave_appendGfx = 0xe004a78c
export const RAM_subtract_fortress_keys = 0x800ea3b8
export const RAM_b_area_mac_lee_formation_kooper = 0x80222274
export const RAM_D_800B431C = 0x800b431c
export const RAM_flo_12_D_80241850_CC2680 = 0x80241850
export const RAM_flo_13_npcGroup_802475A4 = 0x802475a4
export const RAM_D_802BE278_31DDE8 = 0x802be278
export const RAM_vtx_C2B6D0 = 0x8020a790
export const RAM_b_area_trd_part_2_red_ninja_koopa_defenseTable_8022850C = 0x8022850c
export const RAM_dro_01_Pipe_ExitHorizontal = 0x8024462c
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_8022579C = 0x8022579c
export const RAM_D_E0040898 = 0xe0040898
export const RAM_ui_parakarry_png = 0x800fece0
export const RAM_b_area_kmr_part_1_formation_08 = 0x802193f4
export const RAM_func_802423CC_D8F61C = 0x802423cc
export const RAM_b_area_kgr_fuzzipede_SpyGuyActionFunc = 0x802184f8
export const RAM_dro_01_802477E8 = 0x802477e8
export const RAM_SushieUpdate = 0x802bf5a0
export const RAM_func_802411AC_D4E1EC = 0x802411ac
export const RAM_kzn_03_LetterDelivery_SaveNpcAnim = 0x80242c84
export const RAM_EC9D00_SuperBlock_UpgradeOrbAngles = 0x80242038
export const RAM_D_802347F0_6BB9F0 = 0x802347f0
export const RAM_flo_03_idle_80242618 = 0x80242618
export const RAM_func_80241D8C_D21C1C = 0x80241d8c
export const RAM_b_area_pra3_duplighost_bombette_takeTurn = 0x8021fd20
export const RAM_b_area_mim_formation_11 = 0x8021ea1c
export const RAM_osFlashClearStatus = 0x8006f29c
export const RAM_arn_05_main = 0x802415b0
export const RAM_func_802A9378_422E48 = 0x802a9378
export const RAM_battle_item_dizzy_dial_UseItemWithEffect = 0x802a13d0
export const RAM_trd_07_set_script_owner_npc_col_height = 0x80240624
export const RAM_D_8029B818 = 0x8029b818
export const RAM_mac_00_PatrolAI_JumpInit = 0x80242ca0
export const RAM_pra_11_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_8024C110 = 0x8024c110
export const RAM_D_800B451F = 0x800b451f
export const RAM_au_SEFCmd_0D = 0x8004d1b4
export const RAM_func_802414F4_D6FEA4 = 0x802414f4
export const RAM_b_area_trd_part_1_koopa_troopa_partsTable_8021CA44 = 0x8021ca44
export const RAM_snowman_doll_render = 0xe00c0710
export const RAM_dgb_03_exitDoubleDoor_80242910 = 0x80242910
export const RAM_b_area_kmr_part_3_formation_04 = 0x8021a23c
export const RAM_D_E010AF68 = 0xe010af68
export const RAM_D_80252404_9E9424 = 0x80252404
export const RAM_b_area_omo_pink_shy_guy = 0x8021e3f4
export const RAM_au_sfx_get_scaled_volume = 0x8004c844
export const RAM_HES_Item_CocoPop_disabled = 0x800833a8
export const RAM_dead_fx_emote = 0x80075110
export const RAM_is_entity_fog_enabled = 0x80123050
export const RAM_add_star_points = 0x800ea128
export const RAM_D_8024F3F8 = 0x8024f3f8
export const RAM_func_802380E4_6F11C4 = 0x802380e4
export const RAM_D_80249AA0_A33CE0 = 0x80249aa0
export const RAM_D_8024BF58_C097D8 = 0x8024bf58
export const RAM_pra_09_reflection_unk_change_anim_facing = 0x80240054
export const RAM_EED1E0_SuperBlock_RadiateFaintEnergyFX = 0x80240688
export const RAM_D_80231600_63D1A0 = 0x80231600
export const RAM_D_09000D68 = 0x09000d68
export const RAM_func_80241510_91FA80 = 0x80241510
export const RAM_entity_WoodenCrate_idle = 0x802e8858
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021B3D0 = 0x8021cdc0
export const RAM_D_8023D2A4 = 0x8023d2a4
export const RAM_test_ray_triangle_down = 0x8005c49c
export const RAM_sbk_22_SpawnSunEffect = 0x80240000
export const RAM_b_area_jan2_white_magikoopa_defenseTable_80223B20 = 0x80219ed0
export const RAM_status_menu_start_blinking_coins = 0x800e99f4
export const RAM_entity_HitItemBlock_play_anim = 0x802e40a0
export const RAM_b_area_jan_spear_guy_idle_8021BFD8 = 0x8021bfd8
export const RAM_get_coin_drop_amount = 0x80048d50
export const RAM_flo_00_80246850 = 0x80246850
export const RAM_flo_00_80242FCC = 0x80242fcc
export const RAM_MakeStatusField = 0x80253b94
export const RAM_flo_18_triggerCoord_8024367C = 0x8024367c
export const RAM_func_80241864_D3BE34 = 0x80241864
export const RAM_jan_00_HeartPlant_SpawnHeart = 0x80240340
export const RAM_func_80240000_A7A500 = 0x80240000
export const RAM_isk_14_HoppingAI_Hop = 0x80240230
export const RAM_dup_mac_01_PatrolAI_JumpInit = 0x802417b0
export const RAM_func_80241D08_A2BF48 = 0x80241d08
export const RAM_fx_quizmo_answer = 0x80071690
export const RAM_D_802421D0_A027D0 = 0x802421d0
export const RAM_jan_14_PullVine_UpdatePosition = 0x802400f8
export const RAM_battle_star_peach_beam_UnkStarFunc3 = 0x802a116c
export const RAM_flo_25_npcSettings_80242AA0 = 0x80242aa0
export const RAM_spr_update_player_raster_cache = 0x802defb4
export const RAM_step_current_game_mode = 0x80112e78
export const RAM_kmr_20_GetItemName = 0x802416d4
export const RAM_HES_Item_LifeShroom = 0x800867f8
export const RAM_HES_Item_StarPoint = 0x80080ff0
export const RAM_func_8024036C_EB549C = 0x8024036c
export const RAM_sbk_30_AddPlayerHandsOffset = 0x80240f50
export const RAM_hos_02_FlyingNoAttackAI_20 = 0x80242898
export const RAM_b_area_kzn_red_magikoopa_StartRumbleWithParams = 0x80218880
export const RAM_func_80260DB8 = 0x80260db8
export const RAM_dma_write = 0x800297d4
export const RAM_b_area_omo_blue_shy_guy = 0x8021adf4
export const RAM_battle_partner_watt_SetBackgroundAlpha = 0x80238478
export const RAM_D_8010D690 = 0x8010d690
export const RAM_gPauseSpiritsCursorPositions = 0x8024f990
export const RAM_IES_Unused_0F7 = 0x8008cbc4
export const RAM_b_area_omo2_toy_tank_summon_stilt_guys = 0x8022e3dc
export const RAM_func_802418E0_BD04D0 = 0x802418e0
export const RAM_func_802421E0_A2C420 = 0x802421e0
export const RAM_func_80243470_EE1E30 = 0x80243470
export const RAM_D_800B0D0C = 0x800b0d0c
export const RAM_HES_MenuStrategiesDisabled = 0x80292228
export const RAM_dup4_b_area_pra_UnkBattleFunc1 = 0x80218ed4
export const RAM_tik_19_UpdateTexturePanStepped = 0x80240404
export const RAM_add_anim_node = 0x8011e718
export const RAM_flo_18_802454D4 = 0x802454d4
export const RAM_func_80240000_9269F0 = 0x80240000
export const RAM_D_8024D060_C0A8E0 = 0x8024d060
export const RAM_vtx_C287A0 = 0x80207860
export const RAM_D_8024BD60_A26E60 = 0x8024bd60
export const RAM_fx_86_appendGfx = 0xe01281e0
export const RAM_partner_walking_follow_player = 0x800ebc74
export const RAM_b_area_isk_part_2_isk_03b_afterBattle_802200EC = 0x802200ec
export const RAM_HES_Item_MagicBean = 0x80081a28
export const RAM_dgb_15_PatrolAI_Chase = 0x8024121c
export const RAM_HES_Item_DDownPound_disabled = 0x80084188
export const RAM_func_80240034_AD40C4 = 0x80240034
export const RAM_HES_Item_Koopasta = 0x800829b8
export const RAM_jan_11_Pipe_SetAnimFlag = 0x80240158
export const RAM_kpa_70_UpdateTexturePanSmooth = 0x80240000
export const RAM_EE65B0_UpdateTexturePanStepped = 0x80240124
export const RAM_func_8013C3F0 = 0x8013c3f0
export const RAM_arn_07_main = 0x802435a8
export const RAM_GoompaUseAbility = 0x802bd524
export const RAM_D_80242240_D614F0 = 0x80242240
export const RAM_omo_02_ProjectileAI_Main = 0x80241ea4
export const RAM_D_8010D68C = 0x8010d68c
export const RAM_D_80243DDC_C9198C = 0x80243ddc
export const RAM_dgb_00_8024079C = 0x8024079c
export const RAM_func_80240348_C6E678 = 0x80240348
export const RAM_sbk_30_StashVars = 0x80240ddc
export const RAM_b_area_kgr_fuzzipede_8021A2E8 = 0x8021a2e8
export const RAM_D_8026FFA0 = 0x8026ffa0
export const RAM_mac_02_Quizmo_HideWorld = 0x80241bec
export const RAM_D_8029CAAC = 0x8029caac
export const RAM_contRmbStopMesg = 0x8005fde4
export const RAM_dgb_09_80244804 = 0x80244804
export const RAM_nuContInit = 0x8005f610
export const RAM_dgb_15_PatrolAI_Jump = 0x80241074
export const RAM_D_80241B10_AF9460 = 0x80241b10
export const RAM_EDEA00_FlyingAI_Jump = 0x80240e18
export const RAM_jan_02_PatrolAI_LosePlayer = 0x80240e9c
export const RAM_kpa_50_set_script_owner_npc_anim = 0x8024124c
export const RAM_D_09001400_3877B0 = 0x09001400
export const RAM_b_area_kmr_part_2_goomba_king_80220DB0 = 0x80220db0
export const RAM_set_status_icons_offset = 0x800477f4
export const RAM_func_8024296C_C8841C = 0x8024296c
export const RAM_pra_16_SetPartnerFlags20000 = 0x80240eec
export const RAM_D_802EB148 = 0x802eb148
export const RAM_tik_19_SuperBlock_ShowSelectPartnerMenu = 0x802407bc
export const RAM_hos_01_UnkEffect0FFunc2 = 0x80240000
export const RAM_dro_02_Quizmo_NpcSettings = 0x802473b0
export const RAM_b_area_sam_gulpit_rocks_statusTable = 0x802294f4
export const RAM_flo_16_FlyingAI_Chase = 0x80241c44
export const RAM_D_E0040890 = 0xe0040890
export const RAM_battle_item_insecticide_herb_func_802A12E0_72AA30 = 0x802a12e0
export const RAM_flo_10_flo_24_name_hack = 0x80244ab8
export const RAM_b_area_omo2_3_shy_stack_idleAnimations2 = 0x80231364
export const RAM_flo_00_80242998 = 0x80242998
export const RAM_func_802A1518_79DDB8 = 0x802a1518
export const RAM_flo_15_aux_8024079C = 0x8024079c
export const RAM_kpa_115_GetEncounterEnemyIsOwner = 0x80241384
export const RAM_dgb_01_D_8025218C_C0FA0C = 0x8025218c
export const RAM_func_80240F30_A2B170 = 0x80240f30
export const RAM_HES_Item_CoinSparkleA = 0x80080a4c
export const RAM_mdl_project_tex_coords = 0x8011c32c
export const RAM_omo_07_ShyGuyWanderAI_16 = 0x80240140
export const RAM_battle_partner_goombario_partsTable_802392A4 = 0x802392a4
export const RAM_D_8010CFE0 = 0x8010cfe0
export const RAM_b_area_kmr_part_2_kmr_03_clouds1 = 0x80224760
export const RAM_flo_11_vectorList_80241BDC = 0x80241bdc
export const RAM_fx_63_update = 0xe00c639c
export const RAM_jan_02_Quizmo_AnswerResult = 0x80248938
export const RAM_b_area_kmr_part_3_paragoomba1_handleEvent_8021C808 = 0x8021c808
export const RAM_b_area_kzn_kzn_04b_foregroundModelList_802288C8 = 0x802288c8
export const RAM_dro_02_802481D8 = 0x802481d8
export const RAM_func_8026DEF0 = 0x8026def0
export const RAM_tik_10_SuperBlock_UpgradeDescMessages = 0x80242048
export const RAM_au_sfx_set_modifiers = 0x8004c358
export const RAM_b_area_omo2_5_shy_squad_dup_nextTurn = 0x80231fc8
export const RAM_pra_35_MeleeHitbox_30 = 0x80241db0
export const RAM_D_802AD040 = 0x802ad040
export const RAM_D_802479F0 = 0x802479f0
export const RAM_func_80052660 = 0x80052660
export const RAM_flo_07_tree1_Leaves = 0x80242888
export const RAM_iwa_02_CleftAI_Main = 0x80240c90
export const RAM_func_802413A4_D65984 = 0x802413a4
export const RAM_func_80240000_BD3870 = 0x80240000
export const RAM_flo_17_npcGroup_80244A68 = 0x80244a68
export const RAM_npc_do_gravity = 0x80039688
export const RAM_D_8024253C_AB46AC = 0x8024253c
export const RAM_D_E0126C30 = 0xe0126c30
export const RAM_ui_battle_cmd_aim_shimmer_0_pal = 0x8028eb00
export const RAM_default_trigger_on_activate = 0x80145670
export const RAM_b_area_kmr_part_3_jr_troopa_80222BC8 = 0x80222bc8
export const RAM_func_80117D00 = 0x80117d00
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_8022A018 = 0x8022a018
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_8022A480 = 0x8022a480
export const RAM_HES_BoostHammerBegin = 0x80107314
export const RAM_b_area_sam2_sam_02_UnkModelStuff_func1 = 0x80218ea0
export const RAM_dgb_03_PatrolAI_JumpInit = 0x80241250
export const RAM_D_E000E684 = 0xe000e684
export const RAM_jan_06_MeleeHitbox_CanSeePlayer = 0x80241a24
export const RAM_bgm_push_song = 0x8014adf8
export const RAM_dgb_12_pad_868 = 0x80240868
export const RAM_dgb_03_PatrolNoAttackAI_15 = 0x802415f4
export const RAM_func_80240000_ADAEA0 = 0x80240000
export const RAM_SetMusicTrackVolumes = 0x802d5fa4
export const RAM_b_area_trd_part_3_formationTable = 0x8021823c
export const RAM_func_80240A78_890618 = 0x80240a78
export const RAM_iwa_04_CleftAI_DisguiseInit = 0x80240be8
export const RAM_HES_Item_ElectroPop = 0x80083258
export const RAM_energy_orb_wave_render = 0xe00a45dc
export const RAM_D_8015A2BE = 0x8015a2be
export const RAM_D_E006ADF0 = 0xe006adf0
export const RAM_flo_00_802414D0 = 0x802414d0
export const RAM_dro_01_EVS_Quizmo_ResetCamera = 0x802455f4
export const RAM_b_area_pra3_swoopula_UnkBattleFunc2 = 0x802196bc
export const RAM_b_area_jan_spike_top_spikeTop_TakeTurn_Floor = 0x802260dc
export const RAM_pra_37_reflection_setup_wall = 0x80240158
export const RAM_ui_lakilester_png = 0x800ff5e0
export const RAM_D_8026FFA8 = 0x8026ffa8
export const RAM_battle_item_mystery_GiveRefundCleanup = 0x802a11d4
export const RAM_D_09001ED0_3520F0 = 0x09001ed0
export const RAM_b_area_pra3_duplighost_watt = 0x80222ac0
export const RAM_obk_09_UnkFunc41 = 0x80240000
export const RAM_b_area_mim_mim_01_afterBattle_8021E47C = 0x8021e47c
export const RAM_func_80240000_A735B0 = 0x80240000
export const RAM_D_802E92B0 = 0x802e92b0
export const RAM_dup_b_area_kpa_UnkBattleFunc1 = 0x802180d0
export const RAM_func_802D4CC4 = 0x802d4cc4
export const RAM_D_802A24C8_738D58 = 0x802a24f8
export const RAM_nok_02_Quizmo_AnswerResult = 0x802549b8
export const RAM_D_802465D8_C8C088 = 0x802465d8
export const RAM_arn_07_80242498 = 0x80242498
export const RAM_IES_RunawayPay = 0x8008c444
export const RAM_nok_12_PatrolAI_PostLoiter = 0x802413c0
export const RAM_func_802D7B44 = 0x802d7b44
export const RAM_D_80153376 = 0x80153376
export const RAM_battle_item_electro_pop_DrinkItem = 0x802a1a84
export const RAM_jan_09_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_flo_21_80241B98 = 0x80241b98
export const RAM_flo_14_exitWalk_80242558 = 0x80242558
export const RAM_battle_star_peach_dash_usePower = 0x802a2468
export const RAM_EB1170_AddPlayerHandsOffset = 0x80240484
export const RAM_D_802435F0_ACE110 = 0x802435f0
export const RAM_pra_35_SomeItemEntityFunc = 0x80242a68
export const RAM_dup2_kmr_20_Pipe_GetEntryPos = 0x80241e40
export const RAM_mgm_00_PatrolAI_Jump = 0x80240a84
export const RAM_kmr_11_StationaryAI_IdleInit = 0x80240370
export const RAM_au_BGMCmd_F4 = 0x80050004
export const RAM_kpa_91_ToadHouse_ShowStatusMenu = 0x80241548
export const RAM_battle_item_repel_gel_DrinkItem = 0x802a17e4
export const RAM_HES_Item_HPPlus_disabled = 0x80083ca8
export const RAM_flo_22_func_80240000_CEC240 = 0x80240000
export const RAM_DemoSetButtons = 0x802d9b34
export const RAM_SetCamFlag80 = 0x802ca774
export const RAM_tik_18_UpdateTexturePanSmooth = 0x80240000
export const RAM_virtual_entity_set_pos = 0x802d4364
export const RAM_gRandSeed = 0x80074410
export const RAM_dgb_01_D_802521E4_C0FA64 = 0x802521e4
export const RAM_vtx_C2BF50 = 0x8020b010
export const RAM_sam_04_GetNpcCollisionHeight = 0x80240310
export const RAM_func_80240444_A56364 = 0x80240444
export const RAM_ui_battle_yellow_arrow_pal = 0x8028a700
export const RAM_D_8029F2A6 = 0x8029f2a6
export const RAM_D_802A9930_42E340 = 0x802a9930
export const RAM_gMsgSpeechBoxRQuad = 0x8014c440
export const RAM_D_E00CEE58 = 0xe00cee58
export const RAM_jan_22_GetItemName = 0x80240f44
export const RAM_IES_MagicalBean = 0x8008b8c4
export const RAM_D_80249B80 = 0x80249b80
export const RAM_crash_screen_get_faulted_thread = 0x8002c7b0
export const RAM_b_area_mac_lee_takeTurn_80224304 = 0x80224304
export const RAM_D_8014F968 = 0x8014f968
export const RAM_D_8014F538 = 0x8014f538
export const RAM_dup3_kmr_20_foliage_setup_shear_mtx = 0x80241a60
export const RAM_dgb_08_init_80246728 = 0x80246728
export const RAM_filemenu_main_init = 0x80245808
export const RAM_b_area_pra2_pos_crystal_bit_2 = 0x80221518
export const RAM_D_E0124C10 = 0xe0124c10
export const RAM_D_E0126C38 = 0xe0126c38
export const RAM_ui_battle_cmd_charge_1_pal = 0x8028cec0
export const RAM_D_802284B4_6CC044 = 0x802284b4
export const RAM_bBgRenderType = 0x80153221
export const RAM_shim_guFrustumF = 0xe0200620
export const RAM_D_8029D8B8 = 0x8029d8b8
export const RAM_mgm_02_CleanupGame = 0x80241fe4
export const RAM_HES_Item_HotShroom = 0x80082b38
export const RAM_func_80240BD0_A27AA0 = 0x80240bd0
export const RAM_kzn_04_UpdateTexturePanStepped = 0x80240124
export const RAM_D_8010D648 = 0x8010d648
export const RAM_b_area_jan_jan_04b_foregroundModelList_80228550 = 0x80228550
export const RAM_IES_FireFlower = 0x8008d5d4
export const RAM_dgb_16_entryList = 0x80241430
export const RAM_omo_11_SuperBlock_UpgradeOrbAngles = 0x80241e98
export const RAM_pra_37_MeleeHitbox_33 = 0x80241104
export const RAM_jan_13_foliage_setup_shear_mtx = 0x80240390
export const RAM_D_80156903 = 0x80156903
export const RAM_dgb_08_PatrolAI_LoiterInit = 0x80240400
export const RAM_D_8024F4C0_C0CD40 = 0x8024f4c0
export const RAM_D_80159E7F = 0x80159e7f
export const RAM_HES_Item_LimeCandy = 0x80083318
export const RAM_D_E004AEA8 = 0xe004aea8
export const RAM_arn_04_settings = 0x80243390
export const RAM_dgb_09_SentinelAI_Descend = 0x80242c1c
export const RAM_HES_Item_EarthquakeJump_disabled = 0x80085688
export const RAM_NAMESPACE_80239D8C = 0x80239d8c
export const RAM_b_area_hos_magikoopa_partsTable_8021D614 = 0x8021d614
export const RAM_ui_status_coin_0_png = 0x80100b00
export const RAM_dgb_16_npcGroup_80242DAC = 0x80242dac
export const RAM_b_area_sam_gulpit_rocks_idleAnimations2 = 0x802294dc
export const RAM_b_area_kgr_kgr_01_beforeBattle_8021D3B4 = 0x8021d3b4
export const RAM_mac_04_Quizmo_AddViewRelativeOffset = 0x802410e0
export const RAM_kzn_03_LetterDelivery_SavedNpcAnim = 0x802475e4
export const RAM_D_80098034 = 0x80098034
export const RAM_dup3_b_area_tik_UnkBattleFunc1 = 0x80218ab0
export const RAM_gPauseMsg_19 = 0x8026f650
export const RAM_D_8024168B_BAB7CB = 0x8024168b
export const RAM_D_80241B88_A28A58 = 0x80241b88
export const RAM_func_80240D30_B07730 = 0x80240d30
export const RAM_ui_fp_cost_notenough_pal = 0x800fc340
export const RAM_D_80242610_C311B0 = 0x80242610
export const RAM_HES_Item_DustyHammer_disabled = 0x800862e8
export const RAM_flo_16_FlyingAI_WanderInit = 0x80240e50
export const RAM_omo_07_FlyingAI_LosePlayer = 0x802427d0
export const RAM_D_80240A38_8AA448 = 0x80240a38
export const RAM_lens_flare_update = 0xe0034110
export const RAM_D_80241FE0_901650 = 0x80241fe0
export const RAM_func_8024243C_9C781C = 0x8024243c
export const RAM_D_8007779C = 0x8007779c
export const RAM_set_script_priority = 0x802c41d8
export const RAM_D_80159AD4 = 0x80159ad4
export const RAM_D_09001800_387BB0 = 0x09001800
export const RAM_func_80242B0C_825DCC = 0x80242b0c
export const RAM_D_80242D60_8AECF0 = 0x80242d60
export const RAM_dro_01_init_802498C4 = 0x802498c4
export const RAM_b_area_sam2_formation_02 = 0x802234b8
export const RAM_end_00_credits_load_message = 0x80242358
export const RAM_func_80240198_BC7FF8 = 0x80240198
export const RAM_arn_03_entryList = 0x80241720
export const RAM_D_802440D8_C89B88 = 0x802440d8
export const RAM_dgb_00_8024103C = 0x8024103c
export const RAM_flo_21_UpdateTexturePanSmooth = 0x802407f4
export const RAM_D_80294380 = 0x80294380
export const RAM_arn_02_CleftAI_FindPlayer = 0x802404d0
export const RAM_animator_update_model_transforms = 0x8011f058
export const RAM_tik_07_FlyingAI_Jump = 0x80242044
export const RAM_kpa_50_ProjectileHitbox_30 = 0x80240334
export const RAM_D_802315E8_63D188 = 0x802315e8
export const RAM_SetCamPitch = 0x802cbb48
export const RAM_world_bow_take_out = 0x802bdfe0
export const RAM_b_area_kzn_white_magikoopa_statusTable_80223B38 = 0x80223b38
export const RAM_sbk_43_SpawnSunEffect = 0x80240000
export const RAM_sbk_06_SpawnSunEffect = 0x80240000
export const RAM_flo_09_tree1_Leaves = 0x80243400
export const RAM_appendGfx_player_spin = 0x800e1704
export const RAM_battle_item_stone_cap_EatItem = 0x802a189c
export const RAM_D_802BEC60 = 0x802bec60
export const RAM_func_80240A68_EB18C8 = 0x80240a68
export const RAM_func_8024E60C = 0x8024e60c
export const RAM_gPauseDLSpiritsBg = 0x8026f5a0
export const RAM_func_A400076C = 0xa400076c
export const RAM_debuff_main = 0xe0062000
export const RAM_dro_02_80243B70 = 0x80243b70
export const RAM_HES_Item_AutoJump = 0x800840f8
export const RAM_D_802452B8_DB7578 = 0x802452b8
export const RAM_mac_02_Quizmo_SetVannaAnim_Idle = 0x802422b0
export const RAM_isk_05_StoneChompFXB = 0x802411f8
export const RAM_D_802347C0_6BB9C0 = 0x802347c0
export const RAM_b_area_sam2_sam_01_UnkModelStuffScript3 = 0x80221f04
export const RAM_jan_14_HoppingAI_LosePlayer = 0x8024106c
export const RAM_HES_AimTarget = 0x802936d4
export const RAM_D_800A095C = 0x800a095c
export const RAM_entity_upgrade_block_init = 0x802e578c
export const RAM_HES_Item_Unused_02C = 0x800837f8
export const RAM_b_area_pra3_duplighost_lakilester_idleAnimations = 0x80225200
export const RAM_world_lakilester_init = 0x802bd21c
export const RAM_b_area_pra3_duplighost_bombette_playEffects = 0x8021f55c
export const RAM_guTranslateF = 0x80067900
export const RAM_vtx_C2B8E0 = 0x8020a9a0
export const RAM_shim_general_heap_malloc = 0xe02004b0
export const RAM_IES_POWBlock = 0x8008dd14
export const RAM_gameModeBlueprints = 0x800776e8
export const RAM_flo_03_shakeTree = 0x802447e0
export const RAM_func_80238A6C_70C77C = 0x80238a6c
export const RAM_b_area_omo_green_shy_guy_802200C4 = 0x802200c4
export const RAM_b_area_iwa_buzzar_8021F7EC = 0x8021f7ec
export const RAM_iwa_01_CleftAI_Ambush = 0x80242664
export const RAM_npc_update_decorations = 0x8003c444
export const RAM_mac_03_Quizmo_FadeInWorld = 0x80240a28
export const RAM_IES_BakingMilk = 0x8008dc94
export const RAM_func_8024037C_A5629C = 0x8024037c
export const RAM_D_8024D8F0_D05460 = 0x8024d8f0
export const RAM_D_80156948 = 0x80156948
export const RAM_func_8002E82C = 0x8002e82c
export const RAM_func_802BDE88_31D9F8 = 0x802bde88
export const RAM_bgm_push_battle_song = 0x8014aef8
export const RAM_dro_01_pad_XXX = 0x8024dfac
export const RAM_func_8025E14C = 0x8025e14c
export const RAM_HES_Item_BakingEgg = 0x80087278
export const RAM_HES_StickNeutral = 0x80108e1c
export const RAM_dgb_02_main = 0x80241974
export const RAM_HES_BoostHammerEnd = 0x80107474
export const RAM_jan_08_SpearGuyAI_LoiterInit = 0x80241454
export const RAM_dgb_09_FlyingAI_Init = 0x8024260c
export const RAM_free_popup = 0x8024efa4
export const RAM_D_80150A40 = 0x80150a40
export const RAM_b_area_trd_part_1_koopa_troopa_defenseTable_8021C98C = 0x8021c98c
export const RAM_IES_WaterStone = 0x8008b8a4
export const RAM_D_802456D0_C4C2B0 = 0x802456d0
export const RAM_battle_move_jump_charge_1_IsJumpMaxCharged = 0x802a10e4
export const RAM_b_area_kgr_kgr_01 = 0x8021d5b4
export const RAM_green_impact_appendGfx = 0xe0064414
export const RAM_fx_explosion = 0x800701f0
export const RAM_D_E0124C18 = 0xe0124c18
export const RAM_animator_copy_vertices_to_buffer = 0x8011de80
export const RAM_D_8026FCB8 = 0x8026fcb8
export const RAM_b_area_jan_m_bush_defenseTable_8022187C = 0x8022187c
export const RAM_func_802BAE00_E2F750 = 0x802bae00
export const RAM_dgb_01_SentinelAI_ReturnHome = 0x80241f98
export const RAM_b_area_isk_part_1_isk_02_beforeBattle_802239B0 = 0x802239b0
export const RAM_b_area_sam_gulpit_idle = 0x80227fcc
export const RAM_kpa_134_SetEntityPosition = 0x80240564
export const RAM_arn_12_npcSettings_80240440 = 0x80240440
export const RAM_b_area_nok_paragoomba_takeTurn_8021D6E8 = 0x8021d6e8
export const RAM_D_802BEC68 = 0x802bec68
export const RAM_mim_08_MeleeHitbox_30 = 0x80241598
export const RAM_end_00_InitCredits = 0x80242558
export const RAM_pra_28_ReflectFloor = 0x802404cc
export const RAM_MerleeDefenseBonus = 0x8028870c
export const RAM_b_area_mac_lee_idleAnimations_8021D3AC = 0x8021d3ac
export const RAM_set_next_game_mode = 0x80112bbc
export const RAM_D_802E9E54 = 0x802e9e54
export const RAM_D_802465A0 = 0x802465a0
export const RAM_jan_02_Quizmo_SetVannaAnim_Clap = 0x8024211c
export const RAM_func_80055050 = 0x80055050
export const RAM_D_80243088_DE5518 = 0x80243088
export const RAM_D_80245438_9DC458 = 0x80245438
export const RAM_throw_spiny_update = 0xe00c8220
export const RAM_b_area_pra3_gray_magikoopa = 0x8022efe0
export const RAM_D_80245408_EF7A18 = 0x80245408
export const RAM_MusicEventList = 0x802db7d0
export const RAM_iwa_04_CleftAI_FindPlayerInit = 0x802404a8
export const RAM_b_area_jan_spike_top_partsTable_8021C984 = 0x80223bf4
export const RAM_D_80241650_986480 = 0x80241650
export const RAM_kpa_03_MagikoopaAI_SpellMain = 0x80240c18
export const RAM_gPauseMsg_1A = 0x8026f658
export const RAM_vtx_C297F0 = 0x802088b0
export const RAM_b_area_jan_hurt_plant_handleEvent_8021DCF4 = 0x8021dcf4
export const RAM_b_area_pra3_pra_04_8023228C = 0x8023228c
export const RAM_kpa_61_FlyingMagikoopaAI_20 = 0x80241ab4
export const RAM_dgb_15_MeleeHitbox_30 = 0x80240000
export const RAM_nuAuDmaNew = 0x8004b224
export const RAM_flo_12_settings = 0x80240710
export const RAM_D_80244C4C_A3E12C = 0x80244c4c
export const RAM_tst_11_SetPartnerFlagsA0000 = 0x80240730
export const RAM_b_area_arn_tubbas_heart_80225258 = 0x80225258
export const RAM_dro_02_D_80248BD4_971D94 = 0x80248bd4
export const RAM_trd_04_FlyingAI_JumpVels = 0x802456f4
export const RAM_kpa_102_FlyingMagikoopaAI_Main = 0x80243270
export const RAM_b_area_sam_gray_magikoopa_idleAnimations1 = 0x8022c560
export const RAM_dgb_01_D_80252370_C0FBF0 = 0x80252370
export const RAM_b_area_sam_duplighost_bombette_statusTable = 0x80220388
export const RAM_EC9D00_SuperBlock_CantUpgradeMessages = 0x80241fe0
export const RAM_ED5540_foliage_setup_shear_mtx = 0x80241320
export const RAM_tik_24_UnkDistFunc = 0x8024074c
export const RAM_vtx_C22510 = 0x802015d0
export const RAM_D_8024F597 = 0x8024f597
export const RAM_b_area_sam_duplighost_parakarry_idle = 0x80221750
export const RAM_HES_StatBoots3 = 0x802428e4
export const RAM_b_area_isk_part_1_isk_03b_beforeBattle_80223D10 = 0x80223d10
export const RAM_SetPartJumpGravity = 0x8026b74c
export const RAM_tik_08_SetEntityPosition = 0x80240960
export const RAM_b_area_kgr_fuzzipede_takeTurn_8021C1A4 = 0x8021c1a4
export const RAM_action_update_raise_arms = 0x802b6478
export const RAM_flo_07_interact_80242044 = 0x80242044
export const RAM_D_802BEC40_31CC60 = 0x802bec40
export const RAM_kpa_91_GetEntityPosition = 0x80241864
export const RAM_pause_map_path_marker_png = 0x8026c330
export const RAM_dgb_00_80241AA0 = 0x80241aa0
export const RAM_HES_Spirit2 = 0x80242434
export const RAM_kmr_05_FlyingAI_JumpVels = 0x80241b2c
export const RAM_dgb_08_UnkFunc1 = 0x80243b6c
export const RAM_D_E00E4DE0 = 0xe00e4de0
export const RAM_gPauseCommonIconIDs = 0x802700e8
export const RAM_D_802EB5F0 = 0x802eb5f0
export const RAM_dgb_03_PatrolAI_ChaseInit = 0x8024133c
export const RAM_pra_13_reflection_unk_resolve_anim = 0x80240020
export const RAM_func_8013A370 = 0x8013a370
export const RAM_ui_status_coin_6_png = 0x80100ec0
export const RAM_kmr_02_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_misc_particles_appendGfx = 0xe00e495c
export const RAM_gfx_task_main = 0x80026164
export const RAM_DefaultMoveSpeeds = 0x800f7b74
export const RAM_arn_05_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_func_80240CA0_BC8B00 = 0x80240ca0
export const RAM_flo_17_80243654 = 0x80243654
export const RAM_b_area_pra3_pra_04_beforeBattle = 0x80232378
export const RAM_kmr_12_GoombaInit = 0x80240794
export const RAM_dgb_08_npcAISettings_802444B4 = 0x802444b4
export const RAM_battle_action_cmd_water_block_1_png = 0x802aa160
export const RAM_sweat_render = 0xe00502c4
export const RAM_b_area_omo_anti_guy_handleEvent_80221C20 = 0x80221c20
export const RAM_func_80057D0C = 0x80057d0c
export const RAM_battle_star_star_beam_UnkStarFunc4 = 0x802a1218
export const RAM_dro_02_ToadHouse_InitScreenOverlay = 0x80243698
export const RAM_au_bgm_change_track_volume = 0x8005083c
export const RAM_arn_05_PatrolAI_Move = 0x80240158
export const RAM_b_area_sam_white_clubba_handleEvent = 0x80229c7c
export const RAM_ui_battle_menu_nothing_png = 0x80289d00
export const RAM_spr_init_anim_state = 0x802dd820
export const RAM_mim_06_PiranhaPlantAI_01 = 0x80240eac
export const RAM_wSavedPartnerPosY = 0x800f8340
export const RAM_b_area_pra3_duplighost_parakarry = 0x8022075c
export const RAM_D_802BFEF8_323A48 = 0x802bfef8
export const RAM_D_80098538 = 0x80098538
export const RAM_D_802453B8_CDC698 = 0x802453b8
export const RAM_au_get_bgm_player = 0x80053cb4
export const RAM_func_802BC99C_E312EC = 0x802bc99c
export const RAM_b_area_trd_part_1_trd_02c_afterBattle_80224C54 = 0x80224c54
export const RAM_ui_battle_yellow_arrow_png = 0x8028a680
export const RAM_b_area_isk_part_2_tutankoopa_8021F5E8 = 0x8021f5e8
export const RAM_D_80150A48 = 0x80150a48
export const RAM_D_801509F8 = 0x801509f8
export const RAM_D_80241BB0_EC8D60 = 0x80241bb0
export const RAM_D_E0046640 = 0xe0046640
export const RAM_HES_Item_Lemon = 0x80086c78
export const RAM_D_80242F60_A66FF0 = 0x80242f60
export const RAM_D_8014F960 = 0x8014f960
export const RAM_b_area_omo_spy_guy_802276D4 = 0x802276d4
export const RAM_D_802456C8_C4C2A8 = 0x802456c8
export const RAM_SaveBlockTutorialPrinterClosed = 0x802eb390
export const RAM_EA0C10_AwaitPlayerNearNpc = 0x802425b0
export const RAM_pause_partners_load_portrait = 0x8024ade0
export const RAM_D_802424F0_D81250 = 0x802424f0
export const RAM_dro_02_ToadHouse_UpdateScreenOverlay = 0x802434cc
export const RAM_flo_17_FlyingAI_JumpInit = 0x80240c4c
export const RAM_trd_03_UnkTrigFunc001 = 0x80240880
export const RAM_kkj_12_UnkModelMatrixFunc = 0x80240280
export const RAM_D_E00CEE50 = 0xe00cee50
export const RAM_func_80242014_8B2084 = 0x80242014
export const RAM_D_802424A0_8A7F20 = 0x802424a0
export const RAM_HES_Item_PowerPlus = 0x80083a98
export const RAM_dgb_01_D_80251C0C_C0F48C = 0x80251c0c
export const RAM_MessagePlural = 0x8014c290
export const RAM_battle_star_smooch_AddHP = 0x802a1518
export const RAM_func_802BD27C_31CDEC = 0x802bd27c
export const RAM_D_80098A1C = 0x80098a1c
export const RAM_spiked_goomba = 0x8021b0ac
export const RAM_flo_22_itemList_80240E10 = 0x80240e10
export const RAM_b_area_pra3_pra_04_afterBattle = 0x8023245c
export const RAM_btl_merlee_on_first_strike = 0x80240fdc
export const RAM_dup3_b_area_kpa2_GetPlayerHpPercent = 0x80219cd4
export const RAM_D_E0056F10 = 0xe0056f10
export const RAM_flo_00_PatrolAI_ChaseInit = 0x8024085c
export const RAM_func_802BD1AC_31CD1C = 0x802bd1ac
export const RAM_b_area_isk_part_1_isk_07_afterBattle_80223CC8 = 0x80223cc8
export const RAM_dro_01_interact_8024821C = 0x8024821c
export const RAM_gPauseMsg_50 = 0x8026f74c
export const RAM_world_goompa_put_away = 0x802bd5c8
export const RAM_iwa_01_MontyMoleAI_Init = 0x802415e0
export const RAM_func_80241BD8_8333D8 = 0x80241bd8
export const RAM_b_area_mac_lee_pad = 0x80232c18
export const RAM_b_area_omo2_toy_tank_8021A42C = 0x8021a42c
export const RAM_D_09000660_326A70 = 0x09000660
export const RAM_HES_WeakenedEnd = 0x8010645c
export const RAM_osr_01_StashVars = 0x80240360
export const RAM_b_area_kzn_kzn_02_afterBattle_80227D98 = 0x80227d98
export const RAM_kpa_52_ProjectileAI_Main = 0x8024063c
export const RAM_flo_11_80240450 = 0x80240450
export const RAM_func_802403CC_ADB26C = 0x802403cc
export const RAM_sbk_66_SpawnSunEffect = 0x80240000
export const RAM_D_802B7D18_E22588 = 0x802b7d18
export const RAM_enable_transform_group = 0x8011b5d0
export const RAM_IES_MagicalSeed4 = 0x8008b864
export const RAM_npc_find_closest = 0x8003cfb4
export const RAM_battle_star_up_and_away_UnkBackgroundFunc2 = 0x802a137c
export const RAM_entity_BlueWarpPipe_wait_for_player_to_get_off = 0x802e8a58
export const RAM_D_E0056F18 = 0xe0056f18
export const RAM_b_area_arn_tubbas_heart_takeTurn_802245C8 = 0x802245c8
export const RAM_arn_04_npcSettings_802436B0 = 0x802436b0
export const RAM_b_area_kzn_medi_guy_UnkBattleFunc1 = 0x80218450
export const RAM_D_8023160C_63D1AC = 0x8023160c
export const RAM_create_entity_shadow = 0x801121cc
export const RAM_BattleCam_MoveTimeLeft = 0x8029f29e
export const RAM_func_802B71C8 = 0x802b71c8
export const RAM_mim_02_HoppingAI_LoiterInit = 0x802405d8
export const RAM_dgb_05_unk_missing_8024197C = 0x8024197c
export const RAM_D_8024F2FC = 0x8024f2fc
export const RAM_D_8024F2BF = 0x8024f2bf
export const RAM_D_E004AEA0 = 0xe004aea0
export const RAM_D_800B0F0C = 0x800b0f0c
export const RAM_D_80228420_6CBFB0 = 0x80228420
export const RAM_dgb_04_pad_3208 = 0x80243208
export const RAM_D_8029D8B0 = 0x8029d8b0
export const RAM_b_area_tik2_UpdateTexturePanStepped = 0x80219f34
export const RAM_battle_item_fire_flower_main = 0x802a19bc
export const RAM_mgm_01_InitializePanels = 0x80241574
export const RAM_D_8025279C_9E97BC = 0x8025279c
export const RAM_NAMESPACE_idle_802387E0 = 0x802387e0
export const RAM_b_area_kmr_part_3_para_jr_troopa_80227D38 = 0x80227d38
export const RAM_b_area_sam_duplighost_sushie_statusTable = 0x80224840
export const RAM_flo_18_802456D4 = 0x802456d4
export const RAM_kzn_17_MeleeHitbox_32 = 0x802403ec
export const RAM_vtx_C2A2C0 = 0x80209380
export const RAM_D_80244DB4_8F0BD4 = 0x80244db4
export const RAM_D_8009A6A2 = 0x8009a6a2
export const RAM_EC9D00_SuperBlock_GetPartnerRank = 0x80240068
export const RAM_drop_leaves_update = 0xe0028248
export const RAM_D_802EBB70 = 0x802ebb70
export const RAM_gPauseDLStatsBar = 0x8026f040
export const RAM_dgb_01_npcSettings_80245314 = 0x80245314
export const RAM_kpa_102_RangedAttackAI_Main = 0x80241204
export const RAM_ui_status_coin_7_png = 0x80100f60
export const RAM_D_8024F218_A39458 = 0x8024f218
export const RAM_D_802BE908_319658 = 0x802be908
export const RAM_b_area_isk_part_1_stone_chomp_idleAnimations_80221540 = 0x80221540
export const RAM_b_area_pra2_crystal_king_AttackIcyBreathWithClones = 0x8021de9c
export const RAM_dro_02_D_8024EF84 = 0x8024ef84
export const RAM_dro_02_EVS_Quizmo_SetCharacterPositons = 0x802456ac
export const RAM_battle_partner_kooper_nextTurn_80238B6C = 0x80238b6c
export const RAM_mac_02_LetterDelivery_SavedNpcAnim = 0x8024ea18
export const RAM_nok_14_set_script_owner_npc_col_height = 0x802404d4
export const RAM_b_area_mac_lee_takeTurn_80229F9C = 0x80229f9c
export const RAM_D_E0098530 = 0xe0098530
export const RAM_EFEB00_DeadUnkFunc1 = 0x8024030c
export const RAM_b_area_trd_part_1_koopa_troopa_idleAnimations_8021CA90 = 0x8021ca90
export const RAM_func_802404B4_BC4734 = 0x802404b4
export const RAM_D_80250A58_862BC8 = 0x80250a58
export const RAM_chomp_drop_update = 0xe009a140
export const RAM_nok_12_PatrolAI_MoveInit = 0x80240d44
export const RAM_b_area_nok_goomba = 0x8021982c
export const RAM_ui_hammer_png = 0x800fa820
export const RAM_kzn_04_SuperBlock_RadiateFaintEnergyFX = 0x802408b8
export const RAM_b_area_nok_kent_c_koopa_8022B158 = 0x8022b158
export const RAM_HES_Item_JellyPop = 0x80083438
export const RAM_D_80151278 = 0x80151278
export const RAM_kpa_14_UpdateTexturePanSmooth = 0x80240040
export const RAM_func_802552EC = 0x802552ec
export const RAM_dro_01_EVS_Quizmo_QuizMain = 0x802468f8
export const RAM_set_screen_overlay_color = 0x80137e10
export const RAM_D_8010CFDC = 0x8010cfdc
export const RAM_jan_10_HoppingAI_Main = 0x80240f00
export const RAM_b_area_mim_formationTable = 0x8021ea70
export const RAM_disable_x_update = 0xe00821b8
export const RAM_mac_01_PatrolAI_LoiterInit = 0x80245950
export const RAM_pra_03_SetGameStatusUnk84_1 = 0x80240000
export const RAM_func_802407B8_9081E8 = 0x802407b8
export const RAM_gPauseDLBannerBoots = 0x8026edb8
export const RAM_D_09000E38 = 0x09000e38
export const RAM_D_800984C0 = 0x800984c0
export const RAM_D_8024F358 = 0x8024f358
export const RAM_D_80242208 = 0x80242208
export const RAM_gCurrentDisplayContextIndex = 0x800741f4
export const RAM_b_area_pra_UnkBattleFunc2 = 0x802180cc
export const RAM_func_80283080 = 0x80283080
export const RAM_func_80243100_A2D340 = 0x80243100
export const RAM_D_80249D4C = 0x80249d5c
export const RAM_D_E011AC64 = 0xe011ac64
export const RAM_world_goombario_put_away = 0x802bde60
export const RAM_entity_shattering_idle = 0x802e263c
export const RAM_iwa_01_CleftAI_Disguise = 0x80242ea8
export const RAM_func_80241390_87AF70 = 0x80241390
export const RAM_kpa_16_GetFloorCollider = 0x80240370
export const RAM_b_area_pra3_red_magikoopa_8022D044 = 0x8022d044
export const RAM_dispose_merlee_message = 0x80045cc4
export const RAM_D_802C0000 = 0x802c0000
export const RAM_kzn_17_PiranhaPlantAI_01 = 0x80240c4c
export const RAM_kpa_08_MagikoopaAI_24 = 0x802410a4
export const RAM_EDEA00_FlyingAI_LosePlayer = 0x8024101c
export const RAM_EA0C10_set_script_owner_npc_col_height = 0x8024207c
export const RAM_func_802452C4_A2F504 = 0x802452c4
export const RAM_D_8014F4DC = 0x8014f4dc
export const RAM_func_80241838_D701E8 = 0x80241838
export const RAM_IES_Boots1 = 0x8008b274
export const RAM_dead_set_aux_pan_v = 0x801260ac
export const RAM_D_E007EC40 = 0xe007ec40
export const RAM_kmr_03_UnkPositionFunc = 0x80240218
export const RAM_D_80248760_jan_02 = 0x80248760
export const RAM_b_area_kkj_GetEntityPosition = 0x80218180
export const RAM_D_80242538_D81298 = 0x80242538
export const RAM_flo_18_pad_E88 = 0x80240e88
export const RAM_dro_01_idle_8024918C = 0x8024918c
export const RAM_sprite_checkers_png = 0x80075780
export const RAM_tik_24_UpdateTexturePanSmooth = 0x80240050
export const RAM_get_stored_empty_count = 0x800e7848
export const RAM_b_area_jan2_jan_03 = 0x8021f3c0
export const RAM_func_802D7B74 = 0x802d7b74
export const RAM_D_8009A690 = 0x8009a690
export const RAM_D_80234868_6BBA68 = 0x80234868
export const RAM_func_80241FD0_9FC7C0 = 0x80241fd0
export const RAM_flo_14_settings = 0x802423b0
export const RAM_b_area_sam_duplighost_parakarry_takeTurn = 0x802224e4
export const RAM_D_802495DC_A3381C = 0x802495dc
export const RAM_D_E0044E38 = 0xe0044e38
export const RAM_b_area_pra3_duplighost_kooper_init = 0x8021d8a4
export const RAM_b_area_omo_omo_03_afterBattle_8022DC70 = 0x8022dc70
export const RAM_fx_2A = 0x80070850
export const RAM_D_80248C70_83A470 = 0x80248c70
export const RAM_D_802EACC8 = 0x802eacc8
export const RAM_flo_10_init_80243D78 = 0x80243d78
export const RAM_b_area_omo_omo_04_intTable_8022DEF4 = 0x8022def4
export const RAM_D_802AB728 = 0x802ab728
export const RAM_IES_KootPackage = 0x8008ba64
export const RAM_sam_01_Quizmo_AnswerResult = 0x8024df10
export const RAM_b_area_jan_jungle_fuzzy_specialFormation_8022180C = 0x8022180c
export const RAM_au_get_instrument = 0x80053be8
export const RAM_b_area_kmr_part_2_kmr_04_UnkAngleFunc2 = 0x80218fc0
export const RAM_omo_08_ShyGuyWanderAI_Main = 0x80240aa8
export const RAM_func_80240E70_9EEA60 = 0x80240e70
export const RAM_D_8007774C = 0x8007774c
export const RAM_mim_09_HoppingAI_Chase = 0x8024094c
export const RAM_nok_02_Quizmo_NPC_OnRender = 0x80241cdc
export const RAM_HES_Item_Parasol_disabled = 0x80086528
export const RAM_D_80249D38_AC4FC8 = 0x80249d38
export const RAM_b_area_dgb_tubba_blubba_idleAnimations_80218C10 = 0x80218c10
export const RAM_D_E0044E30 = 0xe0044e30
export const RAM_smoke_burst_main = 0xe004e000
export const RAM_b_area_sam2_sam_02c_UnkFloatFunc2 = 0x802196fc
export const RAM_HES_Item_Unused_13E_disabled = 0x80082748
export const RAM_dgb_09_npcSettings_8024432C = 0x8024432c
export const RAM_D_80249900_A33B40 = 0x80249900
export const RAM_D_80224BB0_48BC60 = 0x80224bb0
export const RAM_b_area_trd_part_1_trd_02c = 0x80224c6c
export const RAM_D_80245958_B25E98 = 0x80245958
export const RAM_func_80242DBC_82607C = 0x80242dbc
export const RAM_IES_BakingCleanser = 0x8008dc34
export const RAM_b_area_kmr_part_3_paragoomba1_8021C790 = 0x8021c790
export const RAM_b_area_iwa_buzzar_8021F030 = 0x8021f030
export const RAM_func_802402F0_D37AF0 = 0x802402f0
export const RAM_b_area_hos_magikoopa_GetSelectedMoveID = 0x802182a8
export const RAM_omo_maps = 0x800930d0
export const RAM_D_80077738 = 0x80077738
export const RAM_func_80240000_8FEE80 = 0x80240000
export const RAM_dgb_12_GetItemName = 0x802400b4
export const RAM_func_802752AC = 0x802752ac
export const RAM_D_80241CF0_A9E920 = 0x80241cf0
export const RAM_b_area_mac_lee_copyPartner = 0x80229748
export const RAM_b_area_isk_part_1_stone_chomp_partsTable_80221604 = 0x80221604
export const RAM_flo_09_npcGroupList_8024414C = 0x8024414c
export const RAM_func_802423D4_A2C614 = 0x802423d4
export const RAM_IES_SuperJump = 0x8008c504
export const RAM_D_80249A9C_A33CDC = 0x80249a9c
export const RAM_b_area_isk_part_2_tutankoopa_specialFormation_8021E9AC = 0x8021e9ac
export const RAM_GoompaTweesterPhysicsPtr = 0x802bd58c
export const RAM_D_802529C8_864B38 = 0x802529c8
export const RAM_EC71B0_UpdateTexturePanStepped = 0x80240124
export const RAM_evt_handle_label = 0x802c49b8
export const RAM_func_80240410_9462E0 = 0x80240410
export const RAM_func_80138D88 = 0x80138d88
export const RAM_sam_11_Quizmo_NPC_Aux_Impl = 0x80240b7c
export const RAM_gPauseBackgroundFade = 0x800741f8
export const RAM_dgb_01_PatrolNoAttackAI_15 = 0x80243000
export const RAM_HES_Item_DamageDodge_disabled = 0x80085628
export const RAM_b_area_sam2_paragoomba_sinTable = 0x8021e2fc
export const RAM_b_area_isk_part_1_isk_06_pad = 0x80224d78
export const RAM_jan_09_Pipe_GetCurrentFloor = 0x80240020
export const RAM_dup_hos_10_UpdateTexturePanStepped = 0x80240584
export const RAM_pra_14_reflection_render_wall = 0x802402f0
export const RAM_jan_10_HoppingAI_ChaseInit = 0x80240adc
export const RAM_arn_07_extraAnimationList_802451D4 = 0x802451d4
export const RAM_tik_17_SuperBlock_PartnerSparkles2 = 0x80240ca8
export const RAM_b_area_kmr_part_1_kmr_06_beforeBattle_80220E28 = 0x80220e28
export const RAM_b_area_kzn_medi_guy_MediGuySpriteRotationFunc = 0x8021851c
export const RAM_func_802431B0_855320 = 0x802431b0
export const RAM_dro_01_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_802A369C_00736C3C = 0x802a369c
export const RAM_gMapVars = 0x802da484
export const RAM_ui_coin_sparkle_5_pal = 0x800fbde0
export const RAM_obk_01_LetterDelivery_RestoreNpcAnim = 0x80240f0c
export const RAM_remove_status_transparent = 0x80047aa8
export const RAM_b_area_mac_lee_UnkActorPosFunc = 0x80218f40
export const RAM_EF7A80_DeadUnkFunc1 = 0x8024030c
export const RAM_flo_23_80241314 = 0x80241314
export const RAM_battle_item_mystery_displayList = 0x802a2170
export const RAM_b_area_isk_part_1_isk_07 = 0x80223ce4
export const RAM_flo_21_80242D34 = 0x80242d34
export const RAM_nok_01_ToadHouse_Stub = 0x80240d60
export const RAM_reset_background_settings = 0x80145df8
export const RAM_b_area_hos_goombario_tutor_partsTable_80219114 = 0x80219114
export const RAM_D_8024A638_DD7308 = 0x8024a638
export const RAM_D_E01187C0 = 0xe01187c0
export const RAM_gCurrentModelLocalVtxBuffers = 0x80152210
export const RAM_kzn_08_FlyingAI_LosePlayer = 0x802418a8
export const RAM_func_802A9000_425590 = 0x802a9000
export const RAM_b_area_omo_green_shy_guy_defenseTable_8021FE10 = 0x8021fe10
export const RAM_D_802AD60C = 0x802ad60c
export const RAM_battle_partner_bow_AverageTargetStatusChance = 0x802381e8
export const RAM_gBattleEntityList = 0x801513f8
export const RAM_mgm_02_CreateMinigame = 0x80242274
export const RAM_b_area_trd_part_2_trd_00_afterBattle_8023141C = 0x8023141c
export const RAM_func_E0090428 = 0xe0090428
export const RAM_jan_07_SpearGuyAI_Main = 0x80240ae4
export const RAM_func_801266F8 = 0x801266f8
export const RAM_pause_badges_get_pos_y = 0x80247168
export const RAM_b_area_mim_formation_03 = 0x8021e5bc
export const RAM_enable_status_icon_boost_hammer = 0x80047e1c
export const RAM_D_09001038 = 0x09001038
export const RAM_D_E00AC83C = 0xe00ac83c
export const RAM_func_80240114_7E74B4 = 0x80240114
export const RAM_D_80242FE0_AA1D20 = 0x80242fe0
export const RAM_vtx_C2D8F0 = 0x8020c9b0
export const RAM_b_area_trd_part_2_fake_bowser_8021CD50 = 0x8021cd50
export const RAM_action_command_0A_draw_hud_elements = 0x802a955c
export const RAM_func_802405C0_AF3260 = 0x802405c0
export const RAM_kzn_11_FlyingAI_ChaseInit = 0x80241120
export const RAM_mim_07_HoppingAI_LoiterInit = 0x802407d8
export const RAM_sam_01_Quizmo_CreateReactionEffect = 0x802416f8
export const RAM_filemenu_yesno_draw_options_contents = 0x80246d10
export const RAM_action_command_dizzy_shell_MashActionCommandInit = 0x802a9170
export const RAM_b_area_jan_medi_guy_idle_8021FA8C = 0x8022274c
export const RAM_func_802BB778_E2E0A8 = 0x802bb778
export const RAM_D_80241D98_D5A628 = 0x80241d98
export const RAM_func_802189DC_447E1C = 0x802189dc
export const RAM_func_80240EB8_B078B8 = 0x80240eb8
export const RAM_func_8024522C_805AAC = 0x8024522c
export const RAM_SPStarHudScripts = 0x800f7fcc
export const RAM_func_80240070_B9D9D0 = 0x80240070
export const RAM_HES_MashCDownButton1 = 0x80292688
export const RAM_snd_ambient_slow_fade_out = 0x800554e8
export const RAM_func_802CF54C = 0x802cf54c
export const RAM_D_8024E6A0_C0BF20 = 0x8024e6a0
export const RAM_HES_Item_BakingButter_disabled = 0x800873c8
export const RAM_func_802400A0_B9B520 = 0x802400a0
export const RAM_ECAA80_UnkFloAI_LosePlayer = 0x802414c8
export const RAM_RestorePrevItemVisGroup = 0x802827a8
export const RAM_au_fade_set_vol_scale = 0x80053aec
export const RAM_enable_player_static_collisions = 0x800e0140
export const RAM_gItemHudScripts = 0x8008a680
export const RAM_ui_battle_cmd_aim_target_1_pal = 0x8028f620
export const RAM_kpa_115_RangedAttackAI_Main = 0x80241064
export const RAM_Entity_ShatteringHammer1Block = 0x802e9c8c
export const RAM_sam_01_LetterDelivery_CalcLetterPos = 0x80241884
export const RAM_osPfsAllocateFile = 0x80068190
export const RAM_b_area_kmr_part_2_red_goomba_sinTable = 0x8021c244
export const RAM_battle_menu_moveTextColor = 0x802ad114
export const RAM_fio_flush_backups = 0x8002b04c
export const RAM_nok_01_StationaryAI_IdleInit = 0x80240000
export const RAM_flo_11_vectorList_80241BAC = 0x80241bac
export const RAM_pause_orbs_png = 0x8026c030
export const RAM_D_802A2DE8_73F648 = 0x802a2de8
export const RAM_func_80240770_BCD7D0 = 0x80240770
export const RAM_dgb_10_pad_66C = 0x8024066c
export const RAM_EnableNpcAux = 0x80044ff0
export const RAM_b_area_kpa2_CanPlayerLoseAction = 0x8021ceb8
export const RAM_dgb_04_SentinelAI_PostLosePlayer = 0x80241ccc
export const RAM_func_802188EC_59EFCC = 0x802188ec
export const RAM_b_area_jan2_jan_02_afterBattle_8021F2FC = 0x8021f2fc
export const RAM_b_area_dgb_tubba_blubba_80218E60 = 0x80218e60
export const RAM_flo_08_tree1_Drops = 0x802458b4
export const RAM_BombetteTweesterPhysicsPtr = 0x802be89c
export const RAM_tik_12_UnkNpcAIFunc12 = 0x80241b10
export const RAM_D_802942C0 = 0x802942c0
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022BF1C = 0x8022bf1c
export const RAM_D_00E32420 = 0x00e32420
export const RAM_dgb_04_SentinelAI_ChaseInit = 0x802414f8
export const RAM_sam_11_Quizmo_CreateReactionEffect = 0x802417c8
export const RAM_initialize_battle = 0x8023e130
export const RAM_texPannerMainU = 0x80153268
export const RAM_nok_02_PatrolAI_MoveInit = 0x80240a80
export const RAM_end_00_CharAnim_FadeIn_4 = 0x80240ae8
export const RAM_dgb_18_npcGroup_802432D4 = 0x802432d4
export const RAM_nuAuMgr = 0x8004ae08
export const RAM_phys_should_player_be_sliding = 0x800e45e0
export const RAM_b_area_mac_master3_defenseTable_8022DA38 = 0x8022da38
export const RAM_HES_Item_MapleShroom_disabled = 0x800836a8
export const RAM_sam_08_SuperBlock_get_partner_rank = 0x802400d4
export const RAM_iwa_03_CleftAI_FindPlayerInit = 0x80240768
export const RAM_breaking_junk_update = 0xe01182f4
export const RAM_kkj_11_UnkModelMatrixFunc = 0x802402c0
export const RAM_D_09000000_32FB50 = 0x09000000
export const RAM_vtx_C29750 = 0x80208810
export const RAM_obk_07_StashVars = 0x80240000
export const RAM_b_area_kmr_part_1_goomba_takeTurn_8021A440 = 0x8021a300
export const RAM_b_area_pra3_duplighost_idleAnimations = 0x80219f10
export const RAM_b_area_mac_lee_takeTurn_80224CA0 = 0x80224ca0
export const RAM_b_area_isk_part_1_stone_chomp_idleAnimations_8022148C = 0x8022148c
export const RAM_trd_07_FlyingAI_Chase = 0x802418d8
export const RAM_action_command_mega_shock_draw_hud_elements = 0x802a97f8
export const RAM_ui_battle_status_charge_hammer_pal = 0x801019e0
export const RAM_func_802A58D0 = 0x802a58d0
export const RAM_func_80242204_DB44C4 = 0x80242204
export const RAM_kzn_08_WanderMeleeAI_Main = 0x80242b58
export const RAM_kpa_95_set_script_owner_npc_col_height = 0x80241284
export const RAM_vtx_C21E00 = 0x80200ec0
export const RAM_b_area_pra2_crystal_king_clone_statusTable = 0x8021c6dc
export const RAM_b_area_omo_pyro_guy_takeTurn_8022D344 = 0x8022d344
export const RAM_ui_status_star_point_2_pal = 0x80101300
export const RAM_sin_deg = 0x8002a2ec
export const RAM_b_area_mac_lee_8021F6E0 = 0x8021f6e0
export const RAM_D_802A2C20 = 0x802a2c20
export const RAM_filemenu_draw_file_name = 0x80249ab8
export const RAM_func_80240B00_EA95E0 = 0x80240b00
export const RAM_D_802EB110 = 0x802eb110
export const RAM_D_800A0946 = 0x800a0946
export const RAM_battle_move_quake_hammer_IsBerserkerEquipped = 0x802a1000
export const RAM_func_802416F8_BD02E8 = 0x802416f8
export const RAM_kkj_00_PatrolAI_Chase = 0x802409fc
export const RAM_arn_05_extraAnimationList_80244390 = 0x80244390
export const RAM_func_80240FD8_BD4848 = 0x80240fd8
export const RAM_battle_partner_kooper_AverageTargetDizzyChance = 0x80238298
export const RAM_b_area_dgb_dgb_02 = 0x8021b0e0
export const RAM_func_80240100_B1CB50 = 0x80240100
export const RAM_EnableNpcBlur = 0x802cf2ec
export const RAM_dgb_03_enterDoubleDoor_80242D1C = 0x80242d1c
export const RAM_gMsgUnkQuad = 0x8014c4c0
export const RAM_kpa_91_set_script_owner_npc_col_height = 0x802411a4
export const RAM_battle_star_peach_beam_802A2888 = 0x802a2888
export const RAM_D_8014EE40 = 0x8014ee40
export const RAM_D_8010D640 = 0x8010d640
export const RAM_IES_Lyrics = 0x8008bac4
export const RAM_b_area_trd_part_2_trd_05_beforeBattle_802333A4 = 0x802333a4
export const RAM_entry_point = 0x80025c00
export const RAM_D_8029F010 = 0x8029f010
export const RAM_ui_status_pow_unit_7_png = 0x80100880
export const RAM_flo_15_interact_80240B28 = 0x80240b28
export const RAM_purple_ring_appendGfx = 0xe003e638
export const RAM_mim_01_PiranhaPlantAI_10 = 0x80240f38
export const RAM_func_80050D50 = 0x80050d50
export const RAM_trd_07_FlyingAI_LosePlayer = 0x8024193c
export const RAM_ui_input_start_button2_png = 0x800fa3c0
export const RAM_D_80232A88_684808 = 0x80232a88
export const RAM_dro_01_pad_5004 = 0x80245004
export const RAM_b_area_pra3_pra_03c = 0x802320a4
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_80225700 = 0x80225700
export const RAM_battle_item_mushroom_PlayerGoHome = 0x802a1a30
export const RAM_HES_Item_StinkyHerb_disabled = 0x80086f48
export const RAM_show_coin_counter = 0x800e9518
export const RAM_func_802BC37C_E2ECAC = 0x802bc37c
export const RAM_b_area_kzn_kzn_04 = 0x80228348
export const RAM_func_80240E30_A2B070 = 0x80240e30
export const RAM_func_80263230 = 0x80263230
export const RAM_ui_battle_status_paralyze_1_png = 0x80102040
export const RAM_b_area_hos_goombario_tutor_idle_802191D0 = 0x802191d0
export const RAM_D_80153A4F = 0x80153a4f
export const RAM_red_impact_appendGfx = 0xe00d048c
export const RAM_MakeEntity = 0x80111d38
export const RAM_D_80255EE0 = 0x80255ee0
export const RAM_D_800DA47C = 0x800da47c
export const RAM_D_80241D38_ADCBD8 = 0x80241d38
export const RAM_HES_Item_DriedShroom_disabled = 0x80086888
export const RAM_b_area_sam_frost_piranha_idleAnimations = 0x8022aab0
export const RAM_kzn_17_TackleAI_Main = 0x80242048
export const RAM_MAMAR_out_masterTempo = 0x804204bc
export const RAM_D_80249C3C = 0x80249c3c
export const RAM_flo_10_UpdateTexturePanStepped = 0x802402bc
export const RAM_send_mesg = 0x8006af7c
export const RAM_D_80078DC4 = 0x80078dc4
export const RAM_omo_07_UnkNpcPosFunc = 0x80243220
export const RAM_D_E010E79C = 0xe010e79c
export const RAM_b_area_sbk_bandit_takeTurn_8021E900 = 0x8021e900
export const RAM_tik_10_SuperBlock_GatherEnergyFX = 0x80240bc4
export const RAM_dgb_04_SentinelAI_Chase = 0x80241650
export const RAM_b_area_jan2_jan_01_8021E300 = 0x8021e300
export const RAM_func_8011C80C = 0x8011c80c
export const RAM_dgb_15_80241EA0 = 0x80241ea0
export const RAM_b_area_mac_lee_statusTable_8021D3D0 = 0x8021d3d0
export const RAM_ui_battle_cont_c_down_down_png = 0x8028c718
export const RAM_b_area_arn_hyper_paragoomba_8021B678 = 0x8021b678
export const RAM_mac_01_ToadHouse_PutPartnerAway = 0x80243a3c
export const RAM_b_area_sam_duplighost_parakarry_statusTable = 0x802215e8
export const RAM_D_E0200724 = 0xe0200724
export const RAM_test_ray_collider_horizontal = 0x8005d2dc
export const RAM_func_802409A8_92AC58 = 0x802409a8
export const RAM_fx_stop_watch = 0x80071d50
export const RAM_D_802EB118 = 0x802eb118
export const RAM_func_80240994_A0C684 = 0x80240994
export const RAM_sbk_02_UnkFunc27 = 0x80240040
export const RAM_arn_07_FlyingAI_Chase = 0x80241744
export const RAM_flo_18_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_nok_formation_12 = 0x80219344
export const RAM_IES_KoopaTea = 0x8008d234
export const RAM_dead_set_screen_overlay_params_front = 0x80144178
export const RAM_D_802B6770_E27C80 = 0x802b6770
export const RAM_dgb_07_npcAI_802419A8 = 0x802419a8
export const RAM_gDisplayContext = 0x8009a674
export const RAM_battle_item_insecticide_herb_temp2 = 0x028003d0
export const RAM_b_area_kmr_part_2_blue_goomba_takeTurn_8021B2C8 = 0x8021b2c8
export const RAM_ui_box_corners1_png = 0x8010b4e0
export const RAM_D_802AD603 = 0x802ad603
export const RAM_func_802181E4_69F3E4 = 0x802181e4
export const RAM_mac_01_LetterDelivery_SaveNpcAnim = 0x80244078
export const RAM_D_802B68B8 = 0x802b68b8
export const RAM_battle_star_chill_out_802A222C = 0x802a222c
export const RAM_dro_02_D_80248AF8_971CB8 = 0x80248af8
export const RAM_contReadNW = 0x800607b4
export const RAM_b_area_nok_paratroopa_defenseTable_8021EAD0 = 0x80223900
export const RAM_pra_37_MeleeHitbox_CanSeePlayer = 0x80241158
export const RAM_btl_state_update_victory = 0x80244008
export const RAM_b_area_kgr_fuzzipede_DisableRandomAbility = 0x802182e4
export const RAM_ui_battle_cmd_charge_3_pal = 0x8028cf40
export const RAM_D_802403A0_A9ED20 = 0x802403a0
export const RAM_disable_npc_blur = 0x8003adac
export const RAM_IES_ThunderRage = 0x8008d614
export const RAM_btl_init_menu_hammer = 0x802636e4
export const RAM_end_01_ShowCreditList = 0x80242590
export const RAM_jan_08_MeleeHitbox_CanSeePlayer = 0x80241870
export const RAM_osAiSetNextBuffer = 0x80061680
export const RAM_b_area_hos_magikoopa_healOne = 0x8021f660
export const RAM_kzn_08_MeleeHitbox_30 = 0x8024253c
export const RAM_func_80240548_9EE138 = 0x80240548
export const RAM_b_area_sam_gulpit_rocks_init = 0x802295ec
export const RAM_dgb_01_SentinelAI_Chase = 0x80241770
export const RAM_func_802BDF08_324858 = 0x802bdf08
export const RAM_b_area_jan_m_bush_802219E4 = 0x802219e4
export const RAM_ui_battle_status_transparent_3_png = 0x80102f40
export const RAM_fx_02 = 0x8006f950
export const RAM_trd_07_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_jan2_white_magikoopa_init_80225FBC = 0x8021c36c
export const RAM_func_8025E030 = 0x8025e030
export const RAM_D_802EC670 = 0x802ec670
export const RAM_flo_00_pad_9128 = 0x80249128
export const RAM_snd_stop_sound = 0x800553c0
export const RAM_kzn_09_SuperBlock_LoadCurrentPartnerName = 0x80240448
export const RAM_D_80243A00_A6AB00 = 0x80243a00
export const RAM_D_80244F38_CF7B08 = 0x80244f38
export const RAM_flo_03_80240FFC = 0x80240ffc
export const RAM_EED1E0_SuperBlock_StartGlowEffect = 0x80240560
export const RAM_b_area_dgb_clubba_idle_8021819C = 0x8021819c
export const RAM_b_area_mac_master2_init_8022B7DC = 0x8022b7dc
export const RAM_func_80242224_7ECF34 = 0x80242224
export const RAM_D_8024E1A8_C0BA28 = 0x8024e1a8
export const RAM_flo_21_8024263C = 0x8024263c
export const RAM_flo_23_ItemChoiceList = 0x802426e0
export const RAM_ExitDoubleDoor = 0x80285e4c
export const RAM_D_800AF910 = 0x800af910
export const RAM_battle_star_lullaby_802A1620 = 0x802a1620
export const RAM_D_8010EF9E = 0x8010ef9e
export const RAM_battle_item_stop_watch_main = 0x802a191c
export const RAM_b_area_omo2_toy_tank_select_phase = 0x8022d5e8
export const RAM_flo_13_FlyingAI_Chase = 0x80240fb8
export const RAM_entity_ItemBlock_check_if_inactive = 0x802e4154
export const RAM_b_area_kgr_fuzzipede_partsTable_80218B00 = 0x80218b00
export const RAM_b_area_kmr_part_3_final_jr_troopa_idleAnimations_8022C3D8 = 0x8022c3d8
export const RAM_func_80240000_AF7950 = 0x80240000
export const RAM_b_area_pra3_swoopula_init = 0x80226fec
export const RAM_battle_action_cmd_water_block_0_pal = 0x802aa640
export const RAM_gCrashScreencharToGlyph = 0x80077210
export const RAM_b_area_arn_arn_02 = 0x80227a64
export const RAM_gPauseMsg_3C = 0x8026f6a8
export const RAM_dgb_18_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_b_area_omo_omo_07 = 0x8022f8fc
export const RAM_State18PeachAnims = 0x802b6300
export const RAM_D_80246260 = 0x80246260
export const RAM_dro_01_Quizmo_UpdatePartnerPosition = 0x80242380
export const RAM_HES_Item_MegaHPDrain = 0x80084e78
export const RAM_HES_MoveGreenOrbDisabled = 0x80109428
export const RAM_b_area_pra2_crystal_king_clone_formation = 0x8021c818
export const RAM_D_80249B94 = 0x80249b94
export const RAM_EF2680_LakituAI_Wander = 0x802415dc
export const RAM_b_area_jan_jan_02 = 0x802282ec
export const RAM_dro_01_dup_Pipe_GetCurrentFloor = 0x802435c0
export const RAM_EA8AE0_StarSpiritEffectFunc5 = 0x80240908
export const RAM_draw_tiled_image = 0x8012f500
export const RAM_D_09003D58_3AED88 = 0x09003d58
export const RAM_b_area_arn_hypergoomba_idleAnimations_80218874 = 0x80218874
export const RAM_contPakFileNum = 0x8005f8d0
export const RAM_HES_MenuTimes = 0x80109270
export const RAM_b_area_jan2_jan_03_beforeBattle_8021F350 = 0x8021f350
export const RAM_IES_AttackFXF = 0x8008caa4
export const RAM_dro_01_Quizmo_AnswerResult = 0x8024dfd8
export const RAM_gLogosImage1 = 0x800a0918
export const RAM_func_80242C24_9D9C44 = 0x80242c24
export const RAM_dgb_04_SentinelAI_LosePlayerInit = 0x80241a94
export const RAM_IES_CakeBowl = 0x8008b1b0
export const RAM_sam_04_AddPlayerHandsOffset = 0x8024036c
export const RAM_b_area_trd_part_1_formation_14 = 0x80218a94
export const RAM_b_area_trd_part_2_fake_bowser_idleAnimations_8021A73C = 0x8021a73c
export const RAM_tik_21_Pipe_GetCurrentFloor = 0x8024032c
export const RAM_b_area_kzn_red_magikoopa_8022C1E4 = 0x80220f24
export const RAM_D_800A08EC = 0x800a08ec
export const RAM_ui_battle_unk_star_png = 0x8028f640
export const RAM_D_00E62AC0 = 0x00e62ac0
export const RAM_pause_map_calc_path_points = 0x8024d930
export const RAM_func_802403A0_7E7740 = 0x802403a0
export const RAM_D_80240E8C_AF87DC = 0x80240e8c
export const RAM_b_area_pra3_duplighost_goombario_8021C7FC = 0x8021c7fc
export const RAM_D_802476A0_D1FE80 = 0x802476a0
export const RAM_tik_12_CheckItemFlags40 = 0x80240bc8
export const RAM_sbk_60_SpawnSunEffect = 0x80240000
export const RAM_D_802440EC = 0x802440ec
export const RAM_dgb_15_exitDoubleDoor_80241A7C = 0x80241a7c
export const RAM_func_80126734 = 0x80126734
export const RAM_b_area_sam_gray_magikoopa_flee = 0x8022e524
export const RAM_sam_06_ToadHouse_ShowStatusMenu = 0x80241f70
export const RAM_HES_Item_HoneySyrup_disabled = 0x80087008
export const RAM_b_area_kmr_part_2_goomnut_tree_foliage_setup_shear_mtx = 0x80218b10
export const RAM_vtx_C25C00 = 0x80204cc0
export const RAM_kzn_03_func_80240814_97BE44 = 0x80240ae4
export const RAM_pra_05_SetPartnerFlags80000 = 0x80240eb8
export const RAM_mgm_00_animate_and_draw_record = 0x80241540
export const RAM_world_kooper_update = 0x802beb64
export const RAM_jan_08_SpearGuyAI_Loiter = 0x802414a4
export const RAM_b_area_nok_spiked_goomba_partsTable_8021E468 = 0x8021e468
export const RAM_flo_03_init_80241DA4 = 0x80241da4
export const RAM_func_80240070_B934B0 = 0x80240070
export const RAM_b_area_sbk_pokey_statusTable_8021828C = 0x8021828c
export const RAM_pause_tabs_draw_map = 0x80245160
export const RAM_nuScStack = 0x800a65d0
export const RAM_gPauseWS_16 = 0x8026fc80
export const RAM_b_area_pra2_sam_04_UnkModelStuff_array = 0x802211e0
export const RAM_D_802AD070 = 0x802ad070
export const RAM_HES_Item_RightOn = 0x80084998
export const RAM_tik_24_UpdateTexturePanStepped = 0x80240174
export const RAM_tik_21_UpdateTexturePanStepped = 0x80240124
export const RAM_mim_09_HoppingAI_Hop = 0x802402d0
export const RAM_sam_01_Quizmo_AddViewRelativeOffset = 0x80241380
export const RAM_pra_27_SetPartnerFlags80000 = 0x80240ee8
export const RAM_battle_move_hammer_charge_2_IsRightOnEquipped = 0x802a1050
export const RAM_b_area_arn_hypergoomba_sinTable = 0x8021a4bc
export const RAM_gTriggerCount = 0x80151334
export const RAM_b_area_omo3_CalculateRotationZ = 0x8021887c
export const RAM_guMtxCatF = 0x80067600
export const RAM_ui_status_text_6_png = 0x80100340
export const RAM_kzn_19_varStash = 0x80243d74
export const RAM_D_802315F4_63D194 = 0x802315f4
export const RAM_dgb_05_MeleeHitbox_33 = 0x80240214
export const RAM_func_80240194_BC2C24 = 0x80240194
export const RAM_trd_01_TackleAI_Main = 0x80240784
export const RAM_battle_item_mushroom_func_802A15A0_715DF0 = 0x802a15a0
export const RAM_HES_Item_Unused_0D5_disabled = 0x800853e8
export const RAM_b_area_isk_part_1_formation_13 = 0x802246ac
export const RAM_D_800A0BB0 = 0x800a0bb0
export const RAM_jan_03_Quizmo_SetVannaAnim_Wave = 0x802426c0
export const RAM_dgb_01_D_80253720_C10FA0 = 0x80253720
export const RAM_jan_09_MeleeHitbox_33 = 0x80240d08
export const RAM_b_area_omo2_toy_tank_8021A63C = 0x8021a63c
export const RAM_fx_snowflake = 0x8006fdd0
export const RAM_b_area_arn_formation_00 = 0x80227c60
export const RAM_red_impact_render = 0xe00d0448
export const RAM_D_8023CD60_6F5E40 = 0x8023cd60
export const RAM_HES_Item_UNK_1DC870 = 0x80081ea8
export const RAM_func_80054248 = 0x80054248
export const RAM_D_802AD112 = 0x802ad112
export const RAM_dro_02_pad_D_8024EFAC = 0x8024efac
export const RAM_HES_Item_Unused_11F = 0x80087578
export const RAM_dgb_03_npcAISettings_80243BF4 = 0x80243bf4
export const RAM_ui_kooper_png = 0x800fe860
export const RAM_bMsgHeights = 0x802835d0
export const RAM_pause_map_handle_input = 0x8024e878
export const RAM_dro_02_EVS_Quizmo_PlayerThinking = 0x802456e0
export const RAM_D_802AD048 = 0x802ad048
export const RAM_bgm_set_proximity_mix_near = 0x80056044
export const RAM_filemenu_8024C0A4 = 0x8024c0a4
export const RAM_D_E00DE96C = 0xe00de96c
export const RAM_flo_18_80245228 = 0x80245228
export const RAM_dgb_15_npcSettings_8024212C = 0x8024212c
export const RAM_D_E0116D04 = 0xe0116d04
export const RAM_b_area_arn_hyper_paragoomba_takeTurn_8021D1DC = 0x8021d1dc
export const RAM_func_802400EC_BB5ACC = 0x802400ec
export const RAM_b_area_pra2_crystal_king_idleAnimations = 0x80218a00
export const RAM_world_watt_put_away = 0x802be2b4
export const RAM_b_area_tik_StartRumbleWithParams = 0x802180d0
export const RAM_b_area_kgr_fuzzipede_80219D68 = 0x80219d68
export const RAM_b_area_jan2_white_magikoopa_idleAnimations_80223D70 = 0x8021a120
export const RAM_flo_10_8024230C = 0x8024230c
export const RAM_kmr_02_Pipe_SetAnimFlag = 0x80240000
export const RAM_sam_02_ToadHouse_InitScreenOverlay = 0x80240a44
export const RAM_D_802D9E44 = 0x802d9e44
export const RAM_NAMESPACE_nextTurn_80239E7C = 0x80239e7c
export const RAM_D_800B0B04 = 0x800b0b04
export const RAM_entity_inactive_block_hit_anim = 0x802e3728
export const RAM_kzn_08_FlyingAI_Init = 0x80241c38
export const RAM_func_80240F44_ACF084 = 0x80240f44
export const RAM_flo_10_80241988 = 0x80241988
export const RAM_func_80238EDC_6F1FBC = 0x80238edc
export const RAM_dgb_01_D_802531E0_C10A60 = 0x802531e0
export const RAM_remove_part_shadow = 0x802673a0
export const RAM_omo_17_FlyingAI_LoiterInit = 0x8024178c
export const RAM_battle_partner_kooper = 0x80238548
export const RAM_D_802BE304 = 0x802be304
export const RAM_toy_tank_as_open_hatch = 0x80220d88
export const RAM_func_802A2468_798428 = 0x802a2468
export const RAM_D_802B68B0 = 0x802b68b0
export const RAM_blast_update = 0xe007c130
export const RAM_dgb_01_D_80251C64_C0F4E4 = 0x80251c64
export const RAM_func_802405EC_BB765C = 0x802405ec
export const RAM_D_09001CC0_351EE0 = 0x09001cc0
export const RAM_kkj_15_UpdateTexturePanSmooth = 0x80240000
export const RAM_b_area_kzn_white_magikoopa_init_80225F58 = 0x80225f58
export const RAM_D_802705C4 = 0x802705c4
export const RAM_gather_magic_render = 0xe008e498
export const RAM_entity_HeartBlock_change_render_script = 0x802e54a8
export const RAM_dro_01_interact_80249AE0 = 0x80249ae0
export const RAM_ApplyShrinkFromOwner = 0x80254198
export const RAM_func_800334E8 = 0x800334e8
export const RAM_dup_hos_05_UpdateTexturePanSmooth = 0x80244fb8
export const RAM_D_80251E34 = 0x80251e34
export const RAM_D_802EC770 = 0x802ec770
export const RAM_D_80232B28_6848A8 = 0x80232b28
export const RAM_tik_19_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_LoadFreeItemScript = 0x8026801c
export const RAM_dro_01_UnkFunc27 = 0x802402e0
export const RAM_b_area_sam_sam_02d_foregroundModelList = 0x80230224
export const RAM_D_80243D80_D2FAB0 = 0x80243d80
export const RAM_arn_07_init_80245164 = 0x80245164
export const RAM_func_80240330_8EC150 = 0x80240330
export const RAM_SetActorSpeed = 0x8026b654
export const RAM_b_area_kmr_part_3_final_jr_troopa_partsTable_8022C640 = 0x8022c640
export const RAM_fx_green_impact = 0x80070b50
export const RAM_b_area_kzn_lava_bubble_8021A074 = 0x8021a074
export const RAM_D_80243C50_E123F0 = 0x80243c50
export const RAM_SetEncounterStatusFlags = 0x80044180
export const RAM_D_E02006BC = 0xe02006bc
export const RAM_osPfsDeleteFile = 0x800685c0
export const RAM_kzn_03_PiranhaPlantAI_12 = 0x80242100
export const RAM_vtx_C234B0 = 0x80202570
export const RAM_end_00_CharAnim_FadeOut_0 = 0x80241294
export const RAM_iwa_10_SuperBlock_CountEligiblePartners = 0x80240410
export const RAM_b_area_nok_formation_03 = 0x80218ec8
export const RAM_b_area_nok_formation_0D = 0x802191a0
export const RAM_kmr_03_shakeTree = 0x80241b50
export const RAM_kpa_08_MagikoopaAI_21 = 0x80240d44
export const RAM_ui_status_pow_unit_6_png = 0x80100840
export const RAM_b_area_pra3_formation_0C = 0x80232834
export const RAM_dro_02_init_8024BCD4 = 0x8024bcd4
export const RAM_func_80219900_465D90 = 0x80219900
export const RAM_D_80077760 = 0x80077760
export const RAM_HES_Item_AllorNothing_disabled = 0x80084488
export const RAM_entity_fog_blue = 0x80154384
export const RAM_mgm_02_CreateSignpost = 0x802405d0
export const RAM_jan_10_HoppingAI_Chase = 0x80240bbc
export const RAM_flo_21_defeat_802435D4 = 0x802435d4
export const RAM_EED1E0_SuperBlock_ClearOverride40 = 0x802400fc
export const RAM_b_area_omo_omo_04_IsGameStatusUnkAA_1 = 0x802191c0
export const RAM_D_802AA844_4254B4 = 0x802aa844
export const RAM_D_8024F58F = 0x8024f58f
export const RAM_b_area_sam_formation_01 = 0x80230850
export const RAM_bHPDigitHudScripts = 0x802809fc
export const RAM_kpa_61_FlyingMagikoopaAI_15 = 0x8024138c
export const RAM_kpa_52_GetEncounterEnemyIsOwner = 0x802412f4
export const RAM_func_80240388_EA7358 = 0x80240388
export const RAM_arn_02_npcGroupList_80241A9C = 0x80241a9c
export const RAM_EB5130_UpdateTexturePanStepped = 0x80240124
export const RAM_func_80242178_DB4438 = 0x80242178
export const RAM_b_area_trd_part_2_fake_bowser_idle_802239BC = 0x802239bc
export const RAM_dgb_01_SetEntityFlags100000 = 0x80240040
export const RAM_mim_08_FlyingAI_Main = 0x802413ac
export const RAM_entity_fog_alpha = 0x80154388
export const RAM_b_area_kmr_part_2_red_goomba_defenseTable_8021B6C0 = 0x8021b6c0
export const RAM_b_area_omo2_toy_tank_8021A2CC = 0x8021a2cc
export const RAM_func_80240A5C_87F84C = 0x80240a5c
export const RAM_b_area_isk_part_1_pokey_mummy_idleAnimations_80218E04 = 0x80218e04
export const RAM_create_status_icon_peril = 0x80048064
export const RAM_dgb_09_SentinelAI_LosePlayer = 0x80242f70
export const RAM_func_80238000_707CA0 = 0x80238000
export const RAM_D_80244F90_EC7190 = 0x80244f90
export const RAM_dgb_01_PatrolAI_Jump = 0x80242cd0
export const RAM_evt_set_variable = 0x802c8098
export const RAM_flo_22_triggerCoord_80241F5C = 0x80241f5c
export const RAM_b_area_kzn_spike_top_statusTable_8021C8D8 = 0x8021c8d8
export const RAM_evt_handle_suspend = 0x802c6a20
export const RAM_func_802195F4_6BD184 = 0x802195f4
export const RAM_b_area_jan2_white_magikoopa_run_away = 0x8021c0e4
export const RAM_func_802405B0_B1BB20 = 0x802405b0
export const RAM_D_80244830_AF1C40 = 0x80244830
export const RAM_kkj_23_GetPeachDisguise = 0x80240758
export const RAM_func_800E6B68 = 0x800e6b68
export const RAM_PreventBGMPlayerUpdate = 0x80078db0
export const RAM_arn_07_FlyingAI_ChaseInit = 0x80241610
export const RAM_D_80245F10_AD4050 = 0x80245f10
export const RAM_D_80245610 = 0x80245610
export const RAM_dup2_b_area_flo_GetSelectedMoveID = 0x80218d98
export const RAM_goto_map = 0x802ca304
export const RAM_D_8029D358 = 0x8029d358
export const RAM_action_update_sliding = 0x802b6060
export const RAM_mac_04_80241C20_8447F0 = 0x80241c20
export const RAM_dro_01_8024CDA0 = 0x8024cda0
export const RAM_stat_change_main = 0xe00ac000
export const RAM_action_command_dizzy_shell_free_hud_elements = 0x802a97bc
export const RAM_dro_01_Pipe_ScriptD = 0x80244450
export const RAM_b_area_kmr_part_3_kmr_05_UnkAngleFunc2 = 0x802194c0
export const RAM_D_09003B28_3AEB58 = 0x09003b28
export const RAM_ShowChoice = 0x802d0b6c
export const RAM_flo_08_pad_598C = 0x8024598c
export const RAM_BtlBringPartnerOut = 0x80280b6c
export const RAM_BattleCam_BoomLength = 0x8029f280
export const RAM_D_8024E200_8314C0 = 0x8024e200
export const RAM_omo_07_PatrolAI_JumpInit = 0x80240c9c
export const RAM_func_802B742C = 0x802b742c
export const RAM_D_8014F504 = 0x8014f504
export const RAM_D_8024DA94_A37CD4 = 0x8024da94
export const RAM_dgb_10_80240CB8 = 0x80240cb8
export const RAM_ED0310_FlyingAI_LosePlayer = 0x80241178
export const RAM_b_area_trd_part_2_green_ninja_koopa_defenseTable_8022556C = 0x8022556c
export const RAM_flower_splash_init = 0xe00103ec
export const RAM_arn_04_npcSettings_80243814 = 0x80243814
export const RAM_D_802E98DC = 0x802e98dc
export const RAM_GetEntryCoords = 0x80283c34
export const RAM_ui_battle_cont_c_right_up_png = 0x8028b930
export const RAM_tik_19_SuperBlock_GetPartnerRank = 0x80240658
export const RAM_jan_07_MeleeHitbox_32 = 0x802402f0
export const RAM_arn_11_npcSettings_802406A0 = 0x802406a0
export const RAM_flo_22_exitWalk_80240350 = 0x80240350
export const RAM_ECAA80_FlowerGuard_SetItemEntityPosition = 0x80241ac4
export const RAM_IES_HoneyCandy = 0x8008d334
export const RAM_pra_13_reflection_render_wall = 0x802402f0
export const RAM_D_80241F14_AF4BB4 = 0x80241f14
export const RAM_sbk_00_npcGroupList_80240768 = 0x80240768
export const RAM_flo_08_tree1_Leaves = 0x802458a4
export const RAM_battle_partner_goombario_802384D4 = 0x802384d4
export const RAM_world_bow_pre_battle = 0x802bdf64
export const RAM_HES_Item_TastyTonic = 0x800868b8
export const RAM_au_BGMCmd_E3 = 0x8004fd04
export const RAM_b_area_kmr_part_2_kmr_02 = 0x80224730
export const RAM_flo_23_ConsumableChoiceList = 0x802426e0
export const RAM_jan_03_PatrolAI_ChaseInit = 0x80240e3c
export const RAM_func_80242F60_826220 = 0x80242f60
export const RAM_mac_01_ToadHouse_DoesPlayerNeedSleep = 0x8024396c
export const RAM_b_area_arn_tubbas_heart_802244DC = 0x802244dc
export const RAM_b_area_mim_bzzap_partsTable_8021A924 = 0x8021a924
export const RAM_kpa_61_FlyingMagikoopaAI_50 = 0x80241f4c
export const RAM_arn_07_80245304 = 0x80245304
export const RAM_func_80240340_EB5470 = 0x80240340
export const RAM_func_80240DC4_EA16C4 = 0x80240dc4
export const RAM_func_802A9294_4295B4 = 0x802a9294
export const RAM_D_80150098 = 0x80150098
export const RAM_nok_14_FlyingAI_Jump = 0x802415e8
export const RAM_nok_01_ToadHouse_ShowStatusMenu = 0x80240a8c
export const RAM_msg_update_rewind_arrow = 0x80126790
export const RAM_mim_08_FlyingAI_LosePlayer = 0x80240ef8
export const RAM_dgb_09_exitDoubleDoor_802439D4 = 0x802439d4
export const RAM_D_8024F2C4 = 0x8024f2c4
export const RAM_arn_07_npcGroup_80246E7C = 0x80246e7c
export const RAM_dro_01_80248090 = 0x80248090
export const RAM_arn_10_settings = 0x80240020
export const RAM_ui_pip2_pal = 0x800fbc60
export const RAM_ui_battle_cmd_flee_0_png = 0x8028d860
export const RAM_D_E00AA6C0 = 0xe00aa6c0
export const RAM_tik_15_GetItemName = 0x80240a14
export const RAM_D_802EC970 = 0x802ec970
export const RAM_kpa_01_TackleAI_Main = 0x802404f4
export const RAM_tik_22_Pipe_SetAnimFlag = 0x8024033c
export const RAM_battle_item_pebble_DrinkItem = 0x802a1784
export const RAM_func_80240820_9C3130 = 0x80240820
export const RAM_D_80244100 = 0x80244100
export const RAM_b_area_pra2_crystal_king_UnkFloatFunc = 0x80218058
export const RAM_b_area_trd_part_1_bill_blaster_statusTable_80219D2C = 0x80219d2c
export const RAM_func_802416F8_D700A8 = 0x802416f8
export const RAM_D_800777C4 = 0x800777c4
export const RAM_kzn_17_PiranhaPlantAI_11 = 0x80241788
export const RAM_HES_Item_Apple_disabled = 0x80087188
export const RAM_evt_handle_child_thread = 0x802c6d6c
export const RAM_arn_12_npcSettings_802404BC = 0x802404bc
export const RAM_D_E00B8900 = 0xe00b8900
export const RAM_battle_partner_goombario_takeTurn_802396D8 = 0x802396d8
export const RAM_HES_AimBlinkA = 0x8029370c
export const RAM_crash_screen_draw = 0x8002c3b0
export const RAM_mdl_get_vertex_count = 0x8011c028
export const RAM_battle_star_chill_out_SetNpcCollision32 = 0x802a14e8
export const RAM_kmr_06_FlyingAI_WanderInit = 0x80240000
export const RAM_D_E0200000 = 0xe0200000
export const RAM_arn_11_pad_674 = 0x80240674
export const RAM_IES_Unused_08E = 0x8008bea4
export const RAM_func_8013C048 = 0x8013c048
export const RAM_flo_08_SuperBlock_get_partner_rank = 0x802400d4
export const RAM_sbk_46_SpawnSunEffect = 0x80240000
export const RAM_func_80240310_B14080 = 0x80240310
export const RAM_sam_07_MeleeHitbox_32 = 0x8024072c
export const RAM_b_area_mac_lee_init_Bow = 0x80224584
export const RAM_b_area_mac_mac_02_afterBattle_8023297C = 0x8023297c
export const RAM_b_area_pra3_pra_03c_afterBattle = 0x80232094
export const RAM_pra_36_ReflectWall = 0x8024011c
export const RAM_kmr_09_settings = 0x02800080
export const RAM_jan_22_TransformFoliage = 0x80241440
export const RAM_b_area_nok_paratroopa_80220BE8 = 0x80225a18
export const RAM_D_8029D8C8 = 0x8029d8c8
export const RAM_D_800741A8 = 0x800741a8
export const RAM_func_802BB98C_E2E2BC = 0x802bb98c
export const RAM_flashing_box_shockwave_init = 0xe0094190
export const RAM_moving_cloud_init = 0xe00e825c
export const RAM_pra_38_UnkFunc001 = 0x80240f50
export const RAM_D_802ED8D0 = 0x802ed8d0
export const RAM_D_802AD10E = 0x802ad10e
export const RAM_omo_17_GetEncounterEnemyIsOwner = 0x802436d4
export const RAM_D_A4600010 = 0xa4600010
export const RAM_virtual_entity_delete_by_ref = 0x802d4434
export const RAM_HidePlayerShadow = 0x802d0da0
export const RAM_dro_02_Quizmo_ShouldAppear = 0x8024043c
export const RAM_D_8015A07C = 0x8015a07c
export const RAM_D_80245618 = 0x80245618
export const RAM_HES_Item_MapleUltra = 0x800828f8
export const RAM_flo_00_npcGroupList_80245DE4 = 0x80245de4
export const RAM_end_01_Font4Patterns = 0x80243110
export const RAM_nok_15_FlyingAI_ChaseInit = 0x80241774
export const RAM_draw_digit = 0x801274c0
export const RAM_battle_star_star_beam_802A327C = 0x802a327c
export const RAM_D_802A1540_73DDA0 = 0x802a1540
export const RAM_tik_10_SuperBlock_CantUpgradeMessages = 0x80242030
export const RAM_D_E02006F0 = 0xe02006f0
export const RAM_battle_item_dusty_hammer_UseItem = 0x802a1544
export const RAM_b_area_pra2_formation_01 = 0x80221530
export const RAM_pra_06_ReflectFloor = 0x802404cc
export const RAM_nok_11_FlyingAI_Main = 0x80241ae0
export const RAM_D_802BE934 = 0x802be934
export const RAM_func_80241D38_991518 = 0x80241d38
export const RAM_b_area_omo2_1_shy_squad_takeTurn = 0x80235468
export const RAM_pra_37_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_D_E02006F8 = 0xe02006f8
export const RAM_rand_int = 0x800299fc
export const RAM_dgb_18_8024382C = 0x8024382c
export const RAM_radiating_energy_orb_appendGfx = 0xe009e424
export const RAM_dgb_05_ClubbaNappingAI_LoiterInit = 0x80240a20
export const RAM_shape_spell_render = 0xe00242e0
export const RAM_D_802835D8 = 0x802835d8
export const RAM_HES_Item_Letter = 0x800821d8
export const RAM_tik_07_FlyingAI_Chase = 0x802421e4
export const RAM_HES_StatFp_1 = 0x80242ad4
export const RAM_kkj_11_SetEntityFlags100000 = 0x80240070
export const RAM_mgm_00_Pipe_SetAnimFlag = 0x80240000
export const RAM_flo_13_pad_3A3C = 0x80243a3c
export const RAM_arn_04_FlyingAI_Main = 0x8024219c
export const RAM_D_802510C0_8EB1C0 = 0x802510c0
export const RAM_D_8029F64A = 0x8029f64a
export const RAM_flo_08_80241F40 = 0x80241f40
export const RAM_HES_Spirit6Missing = 0x802425c0
export const RAM_hos_03_Quizmo_SetVannaAnim_Idle = 0x80242f94
export const RAM_D_8014BE78 = 0x8014be78
export const RAM_D_801000A0 = 0x801000a0
export const RAM_kzn_17_MeleeHitbox_Main = 0x802406cc
export const RAM_b_area_omo_omo_04_defenseTable_8022DF34 = 0x8022df34
export const RAM_iwa_10_SuperBlock_AnimateEnergyOrbs = 0x802408f0
export const RAM_D_80154386 = 0x80154386
export const RAM_D_8014C6E4 = 0x8014c6e4
export const RAM_kpa_91_PatrolAI_Jump = 0x802407a4
export const RAM_b_area_pra2_crystal_king_clone_init = 0x8021c798
export const RAM_fx_86_main = 0xe0128000
export const RAM_IES_Parasol = 0x8008d734
export const RAM_npc_reload_all = 0x8003b1b0
export const RAM_tik_10_SuperBlock_WhiteScreenFlash = 0x802411b8
export const RAM_D_802EA760 = 0x802ea760
export const RAM_jan_22_PatrolAI_Jump = 0x802407a4
export const RAM_tik_01_UpdateTexturePanStepped = 0x80240124
export const RAM_ui_star_spirit_pal = 0x800fb9e0
export const RAM_func_802414EC_B2D95C = 0x802414ec
export const RAM_b_area_kmr_part_3_paragoomba1_ArcsinDeg = 0x802182d8
export const RAM_b_area_sam_duplighost_goombario_returnBack = 0x8021d210
export const RAM_hos_06_LetterDelivery_SaveNpcAnim = 0x802409f8
export const RAM_func_802A10E4_743ED4 = 0x802a10e4
export const RAM_HES_Item_RedBerry_disabled = 0x80086a08
export const RAM_entity_Chest_init = 0x802e7d84
export const RAM_btl_state_draw_partner_move = 0x80247214
export const RAM_b_area_sbk_bandit_handleEvent_8021DF88 = 0x8021df88
export const RAM_draw_merlee_messages = 0x80045c28
export const RAM_b_area_sam2_monstar_unused = 0x8021a9bc
export const RAM_D_8024D448_C0ACC8 = 0x8024d448
export const RAM_func_80241094_BBE234 = 0x80241094
export const RAM_floating_cloud_puff_main = 0xe00d2000
export const RAM_b_area_jan_spear_guy_8021C0E8 = 0x8021c0e8
export const RAM_shape_spell_init = 0xe0024180
export const RAM_D_E004AEE8 = 0xe004aee8
export const RAM_vtx_C26040 = 0x80205100
export const RAM_fx_stars_burst = 0x800704f0
export const RAM_battle_menu_moveOptionCantUseTypes = 0x802ad5e8
export const RAM_b_area_jan_jungle_fuzzy_leechAttack = 0x8021f66c
export const RAM_b_area_kpa2_ItemEntityJumpToPos = 0x8021ca44
export const RAM_ui_unused_3_pal = 0x800fc960
export const RAM_entity_HitItemBlock_hide = 0x802e4484
export const RAM_pra_29_ReflectWall = 0x802400ec
export const RAM_D_8024D788_C0B008 = 0x8024d788
export const RAM_b_area_jan2_white_magikoopa_UnkBattleFunc1 = 0x802183d4
export const RAM_DoDizzyHit = 0x802945fc
export const RAM_pra_20_reflection_setup_floor = 0x80240500
export const RAM_b_area_dgb_dgb_05_afterBattle_8021B1F0 = 0x8021b1f0
export const RAM_flo_25_npcAI_80242964 = 0x80242964
export const RAM_world_sushie_pre_battle = 0x802bf9b8
export const RAM_D_802EAC84 = 0x802eac84
export const RAM_dgb_08_npcGroup_80245670 = 0x80245670
export const RAM_func_802438D0_9DA8F0 = 0x802438d0
export const RAM_func_8002A910 = 0x8002a910
export const RAM_nok_12_PatrolAI_Jump = 0x802414e8
export const RAM_D_E00B4ED0 = 0xe00b4ed0
export const RAM_b_area_isk_part_2_isk_02 = 0x8021fe4c
export const RAM_func_802596C0 = 0x802596c0
export const RAM_b_area_pra2_crystal_king_CrystalBitAppear = 0x8021d6fc
export const RAM_battle_item_pow_block_EatItem = 0x802a186c
export const RAM_BattleCam_BoomYaw = 0x8029f282
export const RAM_mgm_01_TallyPosY = 0x80241b18
export const RAM_flo_13_exitWalk_80243D48 = 0x80243d48
export const RAM_battle_item_shooting_star_PlayerGoHome = 0x802a1980
export const RAM_D_80234890_6BBA90 = 0x80234890
export const RAM_D_80247408_C7E0E8 = 0x80247408
export const RAM_battle_item_dusty_hammer_UseItemWithEffect = 0x802a1240
export const RAM_vtx_C25CF0 = 0x80204db0
export const RAM_D_E004AEE0 = 0xe004aee0
export const RAM_vtx_C29630 = 0x802086f0
export const RAM_fx_shimmer_wave = 0x80070e50
export const RAM_EE70C0_GetNpcCollisionHeight = 0x80242034
export const RAM_b_area_kgr_fuzzipede_statusTable_80218A54 = 0x80218a54
export const RAM_b_area_omo_omo_01_afterBattle_8022DB68 = 0x8022db68
export const RAM_func_80240000_A0BCF0 = 0x80240000
export const RAM_b_area_iwa_iwa_02_statusTable_80221674 = 0x80221674
export const RAM_HES_Item_MegaSmash_disabled = 0x80084de8
export const RAM_D_80243288_B2F6F8 = 0x80243288
export const RAM_b_area_trd_part_1_bill_blaster_defenseTable_80219D20 = 0x80219d20
export const RAM_func_8024146C_BD005C = 0x8024146c
export const RAM_phys_update_standard = 0x800dfaac
export const RAM_func_80056D78 = 0x80056d78
export const RAM_D_8023C1D0 = 0x8023c1d0
export const RAM_tik_14_UpdateTexturePanSmooth = 0x802402e0
export const RAM_D_8010C318 = 0x8010c318
export const RAM_sbk_53_SetNpcB5_3 = 0x80240040
export const RAM_guLookAt = 0x80062530
export const RAM_battle_star_smooch_intTable_802A24E8 = 0x802a24e8
export const RAM_dgb_11_entryList = 0x80240050
export const RAM_kzn_20_StashVars = 0x80240310
export const RAM_jan_00_varStash = 0x802423c8
export const RAM_mac_03_Quizmo_AddViewRelativeOffset = 0x80241130
export const RAM_mac_03_LetterDelivery_Init = 0x80241898
export const RAM_partner_enable_input = 0x800ef600
export const RAM_func_8004D8E0 = 0x8004d8e0
export const RAM_D_80234938_6BBB38 = 0x80234938
export const RAM_EEDF50_FlyingAI_JumpInit = 0x80241a2c
export const RAM_fx_embers = 0x80072290
export const RAM_dro_02_interact_8024BD4C = 0x8024bd4c
export const RAM_HES_Item_Coconut = 0x80086e58
export const RAM_pra_10_reflection_unk_resolve_anim = 0x80240020
export const RAM_D_E003A80C = 0xe003a80c
export const RAM_vtx_C2C0F0 = 0x8020b1b0
export const RAM_gCurrentModels = 0x801512cc
export const RAM_b_area_sam_duplighost_kooper_takeTurn = 0x8021f374
export const RAM_b_area_nok_goomba_defenseTable_80219750 = 0x80219750
export const RAM_D_8010C310 = 0x8010c310
export const RAM_arn_04_CleftAI_RevUpInit = 0x802429cc
export const RAM_D_802E9B40 = 0x802e9b40
export const RAM_b_area_sam_sam_01_UnkFloatFunc2 = 0x80219dcc
export const RAM_func_8002E754 = 0x8002e754
export const RAM_D_802BCFE0_E2D710 = 0x802bcfe0
export const RAM_flo_13_802438B0 = 0x802438b0
export const RAM_ui_battle_status_shrink_3_png = 0x80102cc0
export const RAM_D_E011E7F0 = 0xe011e7f0
export const RAM_HES_JpFile = 0x802422d4
export const RAM_IES_Egg = 0x8008da34
export const RAM_battle_star_star_storm_SetNpcCollision32 = 0x802a14e8
export const RAM_pra_33_ReflectFloor = 0x8024049c
export const RAM_sam_11_Quizmo_ShouldAppear = 0x8024090c
export const RAM_D_802496F0_9E0710 = 0x802496f0
export const RAM_sam_07_PiranhaPlantAI_10 = 0x80241088
export const RAM_b_area_trd_part_2_trd_01_beforeBattle_80231460 = 0x80231460
export const RAM_shim_set_screen_overlay_center = 0xe02005d0
export const RAM_omo_17_SetPlayerStatusPosYaw = 0x802401ec
export const RAM_ECAA80_ItemChoice_HasSelectedItem = 0x802430b8
export const RAM_HES_Item_JellyPop_disabled = 0x80083468
export const RAM_b_area_pra3_duplighost_watt_handleEvent = 0x80222b5c
export const RAM_HES_Item_StrangeCake = 0x80083498
export const RAM_b_area_trd_part_2_fake_bowser_80223718 = 0x80223718
export const RAM_dro_01_interact_80249750 = 0x80249750
export const RAM_func_80242790_9C7B70 = 0x80242790
export const RAM_star_outline_render = 0xe01264a4
export const RAM_b_area_kmr_part_3_egg_jr_troopa_802245A8 = 0x802245a8
export const RAM_HES_ParalyzedEnd = 0x8010574c
export const RAM_jan_03_Quizmo_CreateReactionEffect = 0x80242b94
export const RAM_kzn_01_UpdateTexturePanStepped = 0x80240124
export const RAM_dgb_04_FlyingAI_JumpInit = 0x80240bdc
export const RAM_func_802BC3DC_E2CB0C = 0x802bc3dc
export const RAM_flo_08_80242680 = 0x80242680
export const RAM_b_area_sam2_sam_02b = 0x80222968
export const RAM_func_80241A2C_B365FC = 0x80241a2c
export const RAM_ui_battle_status_poison_0_pal = 0x801023e0
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021CAFC = 0x8021d87c
export const RAM_flo_25_entryList = 0x802422d0
export const RAM_dgb_04_SentinelAI_ReturnHome = 0x80241e3c
export const RAM_b_area_kpa3_SetBackgroundAlpha = 0x80219550
export const RAM_D_800A3BD8 = 0x800a3bd8
export const RAM_kkj_16_GetPeachDisguise = 0x80240ec8
export const RAM_func_802591EC = 0x802591ec
export const RAM_confetti_appendGfx = 0xe00887e0
export const RAM_b_area_isk_part_1_vector3D_8022407C = 0x8022407c
export const RAM_D_800B42E4 = 0x800b42e4
export const RAM_func_802550BC = 0x802550bc
export const RAM_vtx_C263D0 = 0x80205490
export const RAM_func_80219188_465618 = 0x80219188
export const RAM_func_802B71D4 = 0x802b71d4
export const RAM_battle_star_focus_802A1F6C = 0x802a1f6c
export const RAM_func_E00E651C = 0xe00e651c
export const RAM_battle_partner_goombario_func_80238000_6F10E0 = 0x80238000
export const RAM_gPauseItemsNumItems = 0x802705cc
export const RAM_kzn_03_MeleeHitbox_31 = 0x80240c64
export const RAM_GetNpcPos = 0x802cf0f4
export const RAM_D_8029D8C0 = 0x8029d8c0
export const RAM_D_800741A0 = 0x800741a0
export const RAM_D_E007AC20 = 0xe007ac20
export const RAM_D_E002E754 = 0xe002e754
export const RAM_D_8014F128 = 0x8014f128
export const RAM_D_80244138_BE78E8 = 0x80244138
export const RAM_dgb_16_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_jan_02_GetNpcCollisionHeight = 0x802412c0
export const RAM_tubba_heart_attack_render = 0xe00cc980
export const RAM_D_09000240 = 0x09000240
export const RAM_HES_HandPointDownLoop = 0x8007ff20
export const RAM_HES_Item_FlowerPoint = 0x80081200
export const RAM_D_802313C8_63CF68 = 0x802313c8
export const RAM_au_song_start = 0x8005591c
export const RAM_battle_item_strange_cake_func_802A1A8C_731D8C = 0x802a1a8c
export const RAM_func_8014AFA0 = 0x8014afa0
export const RAM_b_area_nok_paratroopa_80220B84 = 0x802259b4
export const RAM_pra_33_reflection_render_wall = 0x802402f0
export const RAM_nextAvailableSavePage = 0x8009ba80
export const RAM_HES_MenuHammer2Disabled = 0x801088ac
export const RAM_D_802407D0_8FF650 = 0x802407d0
export const RAM_flo_09_FlyingAI_WanderInit = 0x80240320
export const RAM_D_8014BC78 = 0x8014bc78
export const RAM_D_090008C0_364180 = 0x090008c0
export const RAM_pra_29_reflection_render_wall = 0x802402f0
export const RAM_IncrementPlayerHP = 0x80261d28
export const RAM_D_E007AC28 = 0xe007ac28
export const RAM_D_8029D9E8 = 0x8029d9e8
export const RAM_func_E000C094 = 0xe000c094
export const RAM_mgm_02_DestroySignpost = 0x802405a4
export const RAM_D_802A25FC = 0x802a25fc
export const RAM_D_E006EC48 = 0xe006ec48
export const RAM_kzn_08_FlyingAI_Loiter = 0x802412e8
export const RAM_b_area_kmr_part_1_kmr_03_foregroundModelList_80220210 = 0x80220210
export const RAM_mac_01_Quizmo_CreateStage = 0x80242680
export const RAM_SetTexPanOffset = 0x802c9364
export const RAM_b_area_jan_jungle_fuzzy_UnkBattleFunc2_2 = 0x80218254
export const RAM_func_8025D3CC = 0x8025d3cc
export const RAM_mdl_draw_hidden_panel_surface = 0x8011d72c
export const RAM_mim_01_SetForeverForestFog = 0x80240000
export const RAM_ui_ok_pal = 0x800f9fc0
export const RAM_b_area_pra2_crystal_king_formation_bit_3 = 0x8021d620
export const RAM_red_impact_update = 0xe00d0194
export const RAM_hud_element_set_transform_pos = 0x80145184
export const RAM_func_80149828 = 0x80149828
export const RAM_attack_result_text_init = 0xe00901c0
export const RAM_SetEntityHideMode2 = 0x80282610
export const RAM_D_8010CFE8 = 0x8010cfe8
export const RAM_get_world_fog_distance = 0x8011bbb4
export const RAM_kzn_03_set_script_owner_npc_anim = 0x80240310
export const RAM_fio_erase_flash = 0x8002ba1c
export const RAM_D_8009A5EC = 0x8009a5ec
export const RAM_mac_04_Quizmo_NPC_OnRender = 0x8024082c
export const RAM_IES_TripleDip = 0x8008c4a4
export const RAM_D_A4700000 = 0xa4700000
export const RAM_D_8015A57A = 0x8015a57a
export const RAM_D_80252A00 = 0x80252a00
export const RAM_ElectrifyActor = 0x8027095c
export const RAM_omo_06_UpdateTexturePanSmooth = 0x8024055c
export const RAM_D_80247BC8_DA5098 = 0x80247bc8
export const RAM_battle_item_life_shroom_EatItem = 0x802a19cc
export const RAM_flo_07_entryList = 0x80240740
export const RAM_kpa_08_MagikoopaAI_OnHit = 0x80241454
export const RAM_D_09000400_3CFEF0 = 0x09000400
export const RAM_action_command_squirt_draw_hud_elements = 0x802a9634
export const RAM_flo_25_searchBush = 0x80243ce0
export const RAM_b_area_arn_arn_03_beforeBattle_80227A90 = 0x80227a90
export const RAM_b_area_kmr_part_2_goomnut_tree_takeTurn_80223804 = 0x80223804
export const RAM_D_80241530_B9EE90 = 0x80241530
export const RAM_mgm_02_SetMsgImgs_Panel = 0x802423d0
export const RAM_D_09000000_32CEC0 = 0x09000000
export const RAM_PostChapter2StatUpdate = 0x80240b5c
export const RAM_HES_StatusStarPiece = 0x80104c10
export const RAM_b_area_isk_part_1_swooper_idleAnimations_8021C5B4 = 0x8021c5b4
export const RAM_func_80240630_9EE220 = 0x80240630
export const RAM_hos_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_8014B404 = 0x8014b404
export const RAM_func_80263300 = 0x80263300
export const RAM_dro_02_LetterDelivery_CalcLetterPos = 0x80243870
export const RAM_jan_08_MeleeHitbox_33 = 0x8024181c
export const RAM_battle_item_unknown_item_PlayerGoHome = 0x802a1770
export const RAM_arn_02_pad_12C4 = 0x802412c4
export const RAM_IES_PotatoSalad = 0x8008d474
export const RAM_dgb_01_PatrolAI_Move = 0x80242684
export const RAM_flo_11_npcGroup_80242CF4 = 0x80242cf4
export const RAM_sam_10_MeleeHitbox_31 = 0x8024043c
export const RAM_D_80245458_DB7718 = 0x80245458
export const RAM_IES_PowerBounce = 0x8008bf64
export const RAM_switch_to_partner = 0x800eb168
export const RAM_flo_07_npcGroup_8024291C = 0x8024291c
export const RAM_kpa_32_UpdateTexturePanStepped = 0x80240124
export const RAM_hos_maps = 0x80091270
export const RAM_nok_15_Pipe_AwaitDownInput = 0x80240034
export const RAM_pra_01_SetPartnerFlags80000 = 0x80240eb8
export const RAM_HES_Item_ThunderBolt_disabled = 0x80086228
export const RAM_fx_snowfall = 0x80071270
export const RAM_kzn_17_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_isk_part_1_isk_05_beforeBattle_80223B60 = 0x80223b60
export const RAM_dgb_06_EVS_Chest_ShowGotItem = 0x80240624
export const RAM_arn_03_ItemChoice_HasSelectedItem = 0x80241ccc
export const RAM_kkj_00_PatrolAI_LosePlayer = 0x80240b1c
export const RAM_func_802188B0_59EF90 = 0x802188b0
export const RAM_ui_battle_status_paralyze_0_pal = 0x80102020
export const RAM_HES_Item_AttackFXB = 0x80085bf8
export const RAM_func_802410C4_B85244 = 0x802410c4
export const RAM_b_area_omo_formation_27 = 0x80230554
export const RAM_D_80231634_63D1D4 = 0x80231634
export const RAM_D_802483B0_907A20 = 0x802483b0
export const RAM_IES_DeepFocus = 0x8008c8e4
export const RAM_osr_01_GetItemName = 0x80240414
export const RAM_dro_01_func_80242730_95D930 = 0x80242730
export const RAM_nuContPakGameCode = 0x80093ca4
export const RAM_dgb_03_802430E0 = 0x802430e0
export const RAM_dup2_b_area_flo_UnkFunc52 = 0x80218ee8
export const RAM_snowman_doll_update = 0xe00c0160
export const RAM_IES_SnowmanBucket = 0x8008b9e4
export const RAM_battle_item_volt_shroom_func_802A123C_71AA2C = 0x802a123c
export const RAM_b_area_arn_hyper_paragoomba_idle_8021F140 = 0x8021f140
export const RAM_func_802828DC = 0x802828dc
export const RAM_sam_05_MeleeHitbox_33 = 0x802401b4
export const RAM_D_8024E538_8BE5A8 = 0x8024e538
export const RAM_b_area_pra2_crystal_king_clone_script = 0x8021c788
export const RAM_mac_05_Quizmo_SetVannaAnim_Clap = 0x80241e28
export const RAM_sam_01_Quizmo_varStash = 0x802435f0
export const RAM_JumpToGoalSimple2 = 0x80279420
export const RAM_kmr_04_TransformFoliage = 0x802400d0
export const RAM_dgb_01_PatrolAI_JumpInit = 0x80242c5c
export const RAM_D_80243E00_9134B0 = 0x80243e00
export const RAM_func_80240DA4_C8E954 = 0x80240da4
export const RAM_func_802447E0_805060 = 0x802447e0
export const RAM_b_area_pra3_duplighost_UnkActorSizeFunc = 0x802189e4
export const RAM_BattleCam_TargetActor = 0x8029f27c
export const RAM_battle_menu_moveDownArrowIcon = 0x802ad120
export const RAM_b_area_sbk_pokey_idle_802186B0 = 0x802186b0
export const RAM_GetOwnerTarget = 0x8026e8ac
export const RAM_mac_05_Quizmo_SetVannaAnim_Idle = 0x80241e10
export const RAM_flo_16_SuperBlock_CantUpgradeMessages = 0x80243700
export const RAM_end_00_CreditsBufferIndex = 0x80242d70
export const RAM_D_80247950_DF67F0 = 0x80247950
export const RAM_func_80242538_D223C8 = 0x80242538
export const RAM_D_8024121C_AB120C = 0x8024121c
export const RAM_func_80240010_90B250 = 0x80240010
export const RAM_b_area_isk_part_1_pokey_mummy_idleAnimations_80218E40 = 0x80218e40
export const RAM_vtx_C2C300 = 0x8020b3c0
export const RAM_battle_item_shooting_star_main = 0x802a1b0c
export const RAM_D_802B678C = 0x802b678c
export const RAM_ui_bluepip2_pal = 0x80101760
export const RAM_D_80247BC0_DA5090 = 0x80247bc0
export const RAM_D_80243390_omo_12 = 0x80243390
export const RAM_nok_14_FlyingAI_Main = 0x80241ca0
export const RAM_D_80232A78_6847F8 = 0x80232a78
export const RAM_flo_22_80240E84 = 0x80240e84
export const RAM_flo_25_UnkFunc43 = 0x80242230
export const RAM_arn_09_80240140 = 0x80240140
export const RAM_b_area_mac_lee_handleEvent_80223584 = 0x80223584
export const RAM_ui_boots_png = 0x800faee0
export const RAM_ui_battle_menu_flee_disabled_pal = 0x80289860
export const RAM_D_09001CC0_3ACCF0 = 0x09001cc0
export const RAM_sbk_44_SpawnSunEffect = 0x80240000
export const RAM_battle_item_fire_flower_DrinkItem = 0x802a1944
export const RAM_flo_23_ItemChoice_HasSelectedItem = 0x8024130c
export const RAM_D_E0076EE8 = 0xe0076ee8
export const RAM_D_8029D908 = 0x8029d908
export const RAM_D_8029D598 = 0x8029d598
export const RAM_b_area_sam_duplighost_parts = 0x8021afac
export const RAM_waterfall_appendGfx = 0xe00b62e0
export const RAM_D_802529F8 = 0x802529f8
export const RAM_GetBattleFlags2 = 0x8026cda0
export const RAM_flo_08_npcGroup_80244218 = 0x80244218
export const RAM_gPausePartnersCurrentPortraitIndex = 0x8024f7c0
export const RAM_dup_jan_05_TransformFoliage = 0x802424c0
export const RAM_b_area_pra3_red_magikoopa_8022DCC4 = 0x8022dcc4
export const RAM_gPauseMsg_4F = 0x8026f754
export const RAM_D_8014B8C0 = 0x8014b8c0
export const RAM_dgb_18_entryList = 0x80241270
export const RAM_pra_19_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_8023D330 = 0x8023d330
export const RAM_D_80241534_B9EE94 = 0x80241534
export const RAM_D_802E9C40 = 0x802e9c40
export const RAM_flo_14_func_8024046C_CCB77C = 0x8024046c
export const RAM_IES_StopWatch = 0x8008dd54
export const RAM_b_area_hos_goombario_tutor_takeTurn_80219444 = 0x80219444
export const RAM_wPartner = 0x8010cfec
export const RAM_D_8023BDC8 = 0x8023bdc8
export const RAM_D_802A2E80_739710 = 0x802a2eb0
export const RAM_flo_03_func_80240158_CA73F8 = 0x80240158
export const RAM_D_802431FC_EE97AC = 0x802431fc
export const RAM_b_area_sam_gulpit_init = 0x80227f80
export const RAM_dro_01_Quizmo_UnkStageEffectMode = 0x80241ee0
export const RAM_HES_StatusFP = 0x80108270
export const RAM_func_8025C840 = 0x8025c840
export const RAM_D_80151320 = 0x80151320
export const RAM_kpa_102_UpdateTexturePanStepped = 0x80240124
export const RAM_func_80248170 = 0x80248170
export const RAM_nok_15_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_bgm_clear_track_volumes = 0x8014ab60
export const RAM_b_area_sbk_bandit_partsTable_8021D66C = 0x8021d66c
export const RAM_b_area_dgb_clubba_takeTurn_802186BC = 0x802186bc
export const RAM_b_area_kmr_part_3_final_jr_troopa_defenseTable_8022C430 = 0x8022c430
export const RAM_battle_item_life_shroom_DrinkItem = 0x802a1a44
export const RAM_kpa_09_ProjectileHitbox_33 = 0x802405b8
export const RAM_huff_puff_breath_appendGfx = 0xe00dc304
export const RAM_btl_update_starpoints_display = 0x802403bc
export const RAM_sbk_32_Bandit_DropCoin = 0x80240040
export const RAM_D_E011AC4C = 0xe011ac4c
export const RAM_kmr_20_ToadHouse_PartnerSuspendAbilityScript = 0x80241414
export const RAM_D_80159180 = 0x80159180
export const RAM_kzn_20_UpdateTexturePanStepped = 0x80240124
export const RAM_flo_18_80244774 = 0x80244774
export const RAM_func_80218058_59E738 = 0x80218058
export const RAM_D_802498E8_A33B28 = 0x802498e8
export const RAM_nuScAudioStack = 0x800a85d0
export const RAM_D_80242F38_992718 = 0x80242f38
export const RAM_b_area_mac_master3_singleStrike3 = 0x8022e304
export const RAM_kzn_08_PiranhaPlantAI_12 = 0x80243a44
export const RAM_iwa_01_MontyMoleAI_Surface = 0x802419c0
export const RAM_D_802463A0_915A50 = 0x802463a0
export const RAM_D_80151328 = 0x80151328
export const RAM_D_802B7710_E215C0 = 0x802b7710
export const RAM_flo_14_FlyingAI_Chase = 0x80241904
export const RAM_func_802419EC_8D2EFC = 0x802419ec
export const RAM_gPauseMapIconScripts = 0x8024fa30
export const RAM_func_80240A6C_BCF65C = 0x80240a6c
export const RAM_filemenu_heldButtons = 0x8024c08c
export const RAM_b_area_jan_jan_00_afterBattle_80227298 = 0x80227298
export const RAM_tik_18_UpdateTexturePanStepped = 0x80240124
export const RAM_vtx_C25F00 = 0x80204fc0
export const RAM_filemenu_draw_contents_choose_name = 0x8024830c
export const RAM_obk_01_GetNpcCollisionHeight = 0x802408f8
export const RAM_battle_star_chill_out_802A18F0 = 0x802a18f0
export const RAM_HES_EmptyCircle = 0x80104a98
export const RAM_dup_b_area_kpa2_CanPlayerLoseAction = 0x8021a1b8
export const RAM_vtx_C264D0 = 0x80205590
export const RAM_dro_01_8024B5B4 = 0x8024b5b4
export const RAM_D_E010E868 = 0xe010e868
export const RAM_b_area_kmr_part_2_goomba_king_80222D9C = 0x80222d9c
export const RAM_BombetteTweesterPhysics = 0x802be940
export const RAM_ui_battle_status_exclamation_pal = 0x80101c60
export const RAM_vtx_C281B0 = 0x80207270
export const RAM_dgb_01_D_80253560_C10DE0 = 0x80253560
export const RAM_update_entity_transform_matrix = 0x8011022c
export const RAM_D_802442E0_BE7A90 = 0x802442e0
export const RAM_kmr_03_padding2 = 0x80241468
export const RAM_b_area_kmr_part_2_kmr_06_foregroundModelList_8022581C = 0x8022581c
export const RAM_end_00_ParadeNpcsTable = 0x80244d74
export const RAM_D_802A25B8 = 0x802a25b8
export const RAM_mim_05_MeleeHitbox_30 = 0x80240070
export const RAM_D_8024560C = 0x8024560c
export const RAM_mac_00_Quizmo_Worker = 0x80255b70
export const RAM_D_80150E18 = 0x80150e18
export const RAM_ui_misstar_pal = 0x800fda00
export const RAM_kkj_20_ToadHouse_UpdateScreenOverlay = 0x80240308
export const RAM_D_8029D9E0 = 0x8029d9e0
export const RAM_dgb_01_D_80253148_C109C8 = 0x80253148
export const RAM_func_8011B950 = 0x8011b950
export const RAM_wMapHitName = 0x800d91e0
export const RAM_battle_item_life_shroom_func_802A1378_72E8A8 = 0x802a1378
export const RAM_D_802434B0_A63E80 = 0x802434b0
export const RAM_mac_02_Pipe_GetEntryPos = 0x802400f0
export const RAM_battle_menu_centeredMessagesA = 0x802ab4c0
export const RAM_func_8025D160 = 0x8025d160
export const RAM_pra_12_CheckItemFlags40 = 0x802411b8
export const RAM_flo_10_802424DC = 0x802424dc
export const RAM_ui_kalmar_pal = 0x800fde80
export const RAM_b_area_kmr_part_3_nok_01_beforeBattle_80233558 = 0x80233558
export const RAM_D_80078DB8 = 0x80078db8
export const RAM_HES_LakilesterDisabled = 0x80107f50
export const RAM_D_80242348_C3CD58 = 0x80242348
export const RAM_kmr_07_settings = 0x02800200
export const RAM_D_802470C0_DC4370 = 0x802470c0
export const RAM_npc_blend_palette_colors = 0x8003b96c
export const RAM_func_802A9720 = 0x802a9720
export const RAM_pause_badges_is_visible = 0x80247270
export const RAM_b_area_jan_jan_04_beforeBattle_80228460 = 0x80228460
export const RAM_func_80243034_8B30A4 = 0x80243034
export const RAM_D_09000E38_32DCF8 = 0x09000e38
export const RAM_battle_menu_moveState = 0x802ad108
export const RAM_flame_main = 0xe0040000
export const RAM_kkj_23_UnkPhysicsFunc = 0x802400e0
export const RAM_pra_36_SetPartnerFlags80000 = 0x80240ee8
export const RAM_D_E0120794 = 0xe0120794
export const RAM_mim_11_Pipe_GetEntryPos = 0x802400f0
export const RAM_SetNpcScale = 0x802cded4
export const RAM_HES_HandPointDown = 0x800800a0
export const RAM_mac_02_PatrolAI_LosePlayer = 0x8024139c
export const RAM_D_802402D0_A93710 = 0x802402d0
export const RAM_dgb_03_PatrolAI_Move = 0x80240c78
export const RAM_jan_12_MeleeHitbox_33 = 0x80240888
export const RAM_D_80098114 = 0x80098114
export const RAM_dgb_08_MeleeHitbox_33 = 0x80241044
export const RAM_b_area_iwa_monty_mole_init_8021A7B0 = 0x8021a7b0
export const RAM_D_802443F0_B2C420 = 0x802443f0
export const RAM_D_8028652C = 0x8028652c
export const RAM_pra_02_UnkFunc44 = 0x80241478
export const RAM_HES_Item_PDownDUp = 0x80084f98
export const RAM_b_area_omo_formation_1B = 0x80230164
export const RAM_dgb_00_80240B74 = 0x80240b74
export const RAM_ClearAmbientSounds = 0x802d60e8
export const RAM_sbk_56_SuperBlock_GatherEnergyFX = 0x802405c4
export const RAM_ui_battle_status_poison_1_png = 0x80102400
export const RAM_pra_09_reflection_render_floor = 0x8024068c
export const RAM_HES_Item_DustyHammer = 0x800862b8
export const RAM_D_80249988_A33BC8 = 0x80249988
export const RAM_D_8014BF40 = 0x8014bf40
export const RAM_isk_08_func_802408B4_97BEE4 = 0x80240874
export const RAM_D_802A2DF4 = 0x802a2df4
export const RAM_pause_badges_get_row = 0x80247220
export const RAM_pra_27_CheckItemFlags40 = 0x802411b8
export const RAM_world_goompa_use_ability = 0x802bd5ac
export const RAM_is_identity_fixed_mtx = 0x8011be14
export const RAM_b_area_pra3_red_magikoopa_8022C120 = 0x8022c120
export const RAM_D_E02006EC = 0xe02006ec
export const RAM_func_802414E4_A3A9C4 = 0x802414e4
export const RAM_jan_03_LetterDelivery_SavedNpcAnim = 0x8024ff04
export const RAM_D_80242D40_918AB0 = 0x80242d40
export const RAM_gv_01_UpdateTexturePanStepped = 0x80240404
export const RAM_func_802A9000_4269D0 = 0x802a9000
export const RAM_spr_playerCurrentAnimInfo = 0x802df588
export const RAM_ui_goombario_pal = 0x800fe820
export const RAM_IES_FPPlus = 0x8008c2e4
export const RAM_backup_map_collision_data = 0x8005aea0
export const RAM_b_area_omo2_general_guy_UnfadeBackgroundToBlack = 0x80218098
export const RAM_kzn_08_FlyingNoAttackAI_Main = 0x80242354
export const RAM_D_800985F0 = 0x800985f0
export const RAM_get_model_fog_color_parameters = 0x8011bf14
export const RAM_dgb_01_D_80252974_C101F4 = 0x80252974
export const RAM_b_area_dgb_tubba_blubba_handleEvent_80219338 = 0x80219338
export const RAM_mac_03_UnkFloatFunc = 0x80240320
export const RAM_tik_12_SuperBlock_HideBlockContent = 0x80240c58
export const RAM_b_area_kmr_part_3_mac_01_intTable_80234524 = 0x80234524
export const RAM_b_area_arn_hypergoomba_nextTurn_80218950 = 0x80218950
export const RAM_b_area_trd_part_2_trd_02_foregroundModelList_80231CA8 = 0x80231ca8
export const RAM_partner_player_can_pause = 0x800eb3e0
export const RAM_D_802BEC30_31CC50 = 0x802bec30
export const RAM_check_input_jump = 0x800e6160
export const RAM_arn_08_npcGroup_80241448 = 0x80241448
export const RAM_CloneModel = 0x802c8f28
export const RAM_world_watt_pre_battle = 0x802bdd9c
export const RAM_tik_12_SuperBlock_UpgradeDescMessages = 0x80243cc4
export const RAM_trd_10_StarSpiritEffectFunc5 = 0x802405fc
export const RAM_flo_08_SuperBlock_UpgradeOrbAngles = 0x80242008
export const RAM_D_09001480_3AC4B0 = 0x09001480
export const RAM_dro_02_func_80242EAC_96C06C = 0x80242eac
export const RAM_mim_06_PiranhaPlantAI_13 = 0x80241b78
export const RAM_pause_items_get_column = 0x80249898
export const RAM_func_802A1108_74D678 = 0x802a1108
export const RAM_ui_input_start_text_pal = 0x800fa800
export const RAM_dgb_01_D_8025270C_C0FF8C = 0x8025270c
export const RAM_b_area_kzn_red_magikoopa_8022C120 = 0x80220e60
export const RAM_gHudElementsNumber = 0x801512b4
export const RAM_func_80240404_9AEB04 = 0x80240404
export const RAM_b_area_pra3_formation_03 = 0x80232540
export const RAM_mac_02_foliage_setup_shear_mtx = 0x802406b0
export const RAM_gPausePanelStats = 0x8024f4ac
export const RAM_mac_05_Quizmo_UnkStageEffectMode = 0x80241d90
export const RAM_b_area_omo2_toy_tank_defenseTable = 0x8022c838
export const RAM_b_area_trd_part_1_formation_06 = 0x80218634
export const RAM_func_802D23F8 = 0x802d23f8
export const RAM_func_80036640 = 0x80036640
export const RAM_func_E0200000 = 0xe0200000
export const RAM_HES_Item_MapleShroom = 0x80083678
export const RAM_tik_10_SuperBlock_ClearOverride40 = 0x8024069c
export const RAM_HES_Bombette = 0x80107d98
export const RAM_b_area_mim_piranha_plant_idleAnimations_8021D330 = 0x8021d330
export const RAM_D_80231368_63CF08 = 0x80231368
export const RAM_create_status_debuff = 0x80047820
export const RAM_set_entity_model_render_command_list = 0x80122d7c
export const RAM_D_80249904_A33B44 = 0x80249904
export const RAM_battle_item_life_shroom_func_802A1438_72E968 = 0x802a1438
export const RAM_HES_Item_BumpAttack_disabled = 0x80084f08
export const RAM_dispatch_event_actor = 0x80277160
export const RAM_GoombariaUpdate = 0x802bd14c
export const RAM_kill_script_by_ID = 0x802c40ac
export const RAM_D_80231458_63CFF8 = 0x80231458
export const RAM_bss_802BFEE8 = 0x802bfee8
export const RAM_b_area_omo2_4_signal_guy_handleEvent = 0x802312d0
export const RAM_D_E01108B4 = 0xe01108b4
export const RAM_dgb_01_D_80252840_C100C0 = 0x80252840
export const RAM_b_area_sam_sam_02_UnkModelStuffScript2 = 0x8022f42c
export const RAM_b_area_mac_lee_idleAnimations_80223388 = 0x80223388
export const RAM_b_area_sam2_monstar_parts = 0x80219f20
export const RAM_D_E0014790 = 0xe0014790
export const RAM_state_step_demo = 0x800380f4
export const RAM_dro_01_EVS_Quizmo_WrongAnswer = 0x80246848
export const RAM_dro_02_interact_8024C4EC = 0x8024c4ec
export const RAM_func_8013CFA8 = 0x8013cfa8
export const RAM_D_802704D1 = 0x802704d1
export const RAM_snd_song_set_track_vol_mute = 0x80055fd4
export const RAM_HES_134F60_135120 = 0x80242b74
export const RAM_func_8005513C = 0x8005513c
export const RAM_b_area_iwa_iwa_01 = 0x802208b0
export const RAM_func_800F16CC = 0x800f16cc
export const RAM_func_80265CE8 = 0x80265ce8
export const RAM_D_80257F26 = 0x80257f26
export const RAM_end_01_CharAnim_FadeOut_0 = 0x802412a4
export const RAM_func_802D7B10 = 0x802d7b10
export const RAM_D_090018C0 = 0x090018c0
export const RAM_game_input_to_move_vector = 0x800e23fc
export const RAM_func_802A9120_421B10 = 0x802a9120
export const RAM_flo_07_80241BB4 = 0x80241bb4
export const RAM_omo_08_IsAOrBPressed = 0x80240518
export const RAM_D_8029FAA8 = 0x8029faa8
export const RAM_dro_02_GetNpcCollisionHeight = 0x80243094
export const RAM_sam_06_FlyingAI_ChaseInit = 0x80241210
export const RAM_trd_04_FlyingAI_Wander = 0x80241fc8
export const RAM_kmr_07_StationaryAI_IdleInit = 0x80240000
export const RAM_D_8024B6F0_C08F70 = 0x8024b6f0
export const RAM_trd_maps = 0x80090ff0
export const RAM_D_09001CA0_3ACCD0 = 0x09001ca0
export const RAM_kmr_02_StationaryAI_ChaseInit = 0x802416d0
export const RAM_HES_Item_MapleSyrup = 0x80087038
export const RAM_EndActorSpeech = 0x802532d0
export const RAM_HES_AimMarkerE = 0x80292f7c
export const RAM_b_area_pra3_duplighost_parakarry_idleAnimations = 0x80220610
export const RAM_D_802453F0_CD7220 = 0x802453f0
export const RAM_battle_item_sleepy_sheep_main = 0x802a3320
export const RAM_HES_Partner9Disabled = 0x80107fa0
export const RAM_gPauseMsg_46 = 0x8026f708
export const RAM_battle_item_food_main = 0x802a20fc
export const RAM_set_background_size = 0x80145e70
export const RAM_isk_16_StarSpiritEffectFunc3 = 0x802401ac
export const RAM_LandJumpPart = 0x8027ab5c
export const RAM_HES_Item_GoldCard = 0x80081ff8
export const RAM_pra_04_SetGameStatusUnk84_1 = 0x80240000
export const RAM_iwa_01_CleftAI_ReturnHome = 0x80242cf4
export const RAM_dgb_09_FlyingAI_LosePlayer = 0x8024227c
export const RAM_get_clamped_angle_diff = 0x80029c28
export const RAM_dro_01_Quizmo_SetStageLightsDelay = 0x80241eac
export const RAM_b_area_kmr_part_3_jr_troopa_handleEvent_80223290 = 0x80223290
export const RAM_pra_10_SetPartnerFlags20000 = 0x80240eec
export const RAM_flo_18_init_80245AA8 = 0x80245aa8
export const RAM_isk_04_TackleAI_Main = 0x802403d4
export const RAM_nuContRmbForceStopEnd = 0x800600c0
export const RAM_ParentColliderToModel = 0x802ca114
export const RAM_shim_guRotateF = 0xe0200410
export const RAM_iwa_01_ProjectileHitbox_31 = 0x80240954
export const RAM_func_80052B44 = 0x80052b44
export const RAM_flo_09_tree1_Callback = 0x80243264
export const RAM_kpa_52_ProjectileHitbox_32 = 0x80240558
export const RAM_snd_start_sound_with_shift = 0x80055240
export const RAM_b_area_omo2_toy_tank_init = 0x8022c984
export const RAM_dma_copy = 0x8002973c
export const RAM_ui_battle_cmd_timing_ok_pal = 0x8028d700
export const RAM_flo_12_varStash = 0x802417ec
export const RAM_dro_02_Quizmo_ScriptArray = 0x8024efa8
export const RAM_b_area_sam_duplighost_kooper_idle = 0x8021e8a0
export const RAM_func_802402F4_DAD6F4 = 0x802402f4
export const RAM_action_command_hurricane_free_hud_elements = 0x802a9894
export const RAM_flo_18_80241094 = 0x80241094
export const RAM_D_8023CDA4 = 0x8023cda4
export const RAM_b_area_sam_gulpit_statusTable = 0x80227e40
export const RAM_arn_05_init_8024202C = 0x8024202c
export const RAM_D_E0066D20 = 0xe0066d20
export const RAM_b_area_pra3_duplighost_UnkEffect6CFunc = 0x80218a90
export const RAM_D_E00C0A8C = 0xe00c0a8c
export const RAM_battle_item_thunder_bolt_PlayerGoHome = 0x802a18d0
export const RAM_mac_04_Quizmo_RenderInit = 0x802407c0
export const RAM_D_E0076EE0 = 0xe0076ee0
export const RAM_ui_status_pow_star_3_png = 0x80100980
export const RAM_D_E00D87E0 = 0xe00d87e0
export const RAM_HES_StoppedLoop = 0x801065b4
export const RAM_EnableTexPanning = 0x802c9208
export const RAM_au_BGMCmd_E9_SubTrackVolume = 0x8004feb0
export const RAM_mac_05_Quizmo_HideWorld = 0x8024174c
export const RAM_dead_evt_set_variable = 0x802d5468
export const RAM_battle_item_pebble_model = 0x802a1a20
export const RAM_D_802BCDD0_E2F700 = 0x802bcdd0
export const RAM_HES_AimShimmerH = 0x80293604
export const RAM_D_80245603 = 0x80245603
export const RAM_battle_menu_messageIDs = 0x802ad090
export const RAM_mac_02_GetNpcCollisionHeight = 0x80242854
export const RAM_flo_10_80244074 = 0x80244074
export const RAM_render_hud_elements_backUI = 0x80142210
export const RAM_kkj_20_ToadHouse_ShowStatusMenu = 0x802402e0
export const RAM_jan_22_PatrolAI_PostLoiter = 0x8024067c
export const RAM_b_area_pra3_pra_01 = 0x80231b0c
export const RAM_kmr_12_goombaNpcSettings = 0x80240300
export const RAM_func_802A9294 = 0x802a9294
export const RAM_flo_25_init_80243564 = 0x80243564
export const RAM_func_80240080_BB5A60 = 0x80240080
export const RAM_D_8024242C_B2296C = 0x8024242c
export const RAM_D_80247BB0_EE6570 = 0x80247bb0
export const RAM_b_area_sam_gray_magikoopa_attackMagicBlast = 0x8022d898
export const RAM_HES_Item_KootTheTape = 0x80082088
export const RAM_flo_14_FlyingAI_LosePlayer = 0x80241968
export const RAM_func_80242044_991824 = 0x80242044
export const RAM_b_area_isk_part_1_stone_chomp_idleAnimations_802214C8 = 0x802214c8
export const RAM_nusched = 0x800da440
export const RAM_flo_13_802436D0 = 0x802436d0
export const RAM_ai_try_set_state = 0x80049550
export const RAM_trd_02_PatrolNoAttackAI_Main = 0x80241448
export const RAM_func_E00243BC = 0xe00243bc
export const RAM_b_area_tik2_UpdateTexturePanSmooth = 0x80219e10
export const RAM_dgb_15_PatrolAI_Loiter = 0x80240d90
export const RAM_iwa_10_SuperBlock_get_partner_rank = 0x802403c4
export const RAM_iwa_maps = 0x80090670
export const RAM_b_area_jan_spike_top_defenseTable_8021C8CC = 0x80223b3c
export const RAM_b_area_kzn_medi_guy_8021FB9C = 0x8021fb9c
export const RAM_jan_08_SuperBlock_AnimateEnergyOrbs = 0x80240c60
export const RAM_clear_model_data = 0x80115d44
export const RAM_evt_handle_AND_const = 0x802c62d4
export const RAM_ui_battle_status_paralyze_0_png = 0x80101fa0
export const RAM_battle_item_food_PlayerGoHome = 0x802a1a60
export const RAM_energy_in_out_render = 0xe00d693c
export const RAM_battle_star_peach_dash_UnkStarFunc1 = 0x802a1000
export const RAM_dro_01_TransformFoliage = 0x802438f0
export const RAM_func_80240C84_A11504 = 0x80240c84
export const RAM_bind_entity_model_setupGfx = 0x80122f64
export const RAM_flo_23_npcGroup_80242378 = 0x80242378
export const RAM_D_8023BDC0 = 0x8023bdc0
export const RAM_b_area_omo_yellow_shy_guy_statusTable_8021C824 = 0x8021c824
export const RAM_b_area_pra3_gray_magikoopa_attackWandStrike = 0x80230984
export const RAM_world_kooper_use_ability = 0x802beb80
export const RAM_D_E0042780 = 0xe0042780
export const RAM_func_802A91A0_42DBB0 = 0x802a91a0
export const RAM_D_8022B550 = 0x8022b550
export const RAM_D_802B69A0_E26700 = 0x802b69a0
export const RAM_D_8014F59C = 0x8014f59c
export const RAM_gBGMPlayerB = 0x8009a5fc
export const RAM_D_090036C0 = 0x090036c0
export const RAM_func_E00BA618 = 0xe00ba618
export const RAM_title_screen_draw_images = 0x80037968
export const RAM_entity_ScriptSpring_init = 0x802e6194
export const RAM_D_80077A34 = 0x80077a34
export const RAM_b_area_mac_lee_formation_watt = 0x80226960
export const RAM_battle_item_mushroom_AddHP = 0x802a14f8
export const RAM_EB8E90_PatrolNoAttackAI_Main = 0x80240bd4
export const RAM_kzn_09_LetterDelivery_RestoreNpcAnim = 0x802439d8
export const RAM_HES_Item_JamminJelly_disabled = 0x800870c8
export const RAM_NpcFlyTo = 0x802ce6fc
export const RAM_kzn_04_SuperBlock_CountEligiblePartners = 0x80240430
export const RAM_MultiplyVec3ByActorScale = 0x802540dc
export const RAM_dgb_05_ClubbaNappingAI_Loiter = 0x80240ac8
export const RAM_func_80058F88 = 0x80058f88
export const RAM_flo_12_pad_86C = 0x8024086c
export const RAM_D_E02006A0 = 0xe02006a0
export const RAM_ui_battle_status_frozen_0_pal = 0x80102520
export const RAM_ui_input_start_button_png = 0x800f9fe0
export const RAM_D_802474A0 = 0x802474a0
export const RAM_dro_02_D_80248AA0_971C60 = 0x80248aa0
export const RAM_D_80247A88_CCB298 = 0x80247a88
export const RAM_ShowMessageBox = 0x802512b4
export const RAM_kzn_09_PiranhaPlantAI_12 = 0x80242528
export const RAM_b_area_iwa_iwa_02_D_802215F4_4B17E4 = 0x802215f4
export const RAM_gWorldDynamicEntityList = 0x801543a0
export const RAM_D_8024F308 = 0x8024f308
export const RAM_D_802BFF14 = 0x802bff14
export const RAM_func_80253734 = 0x80253734
export const RAM_func_80240000_B837C0 = 0x80240000
export const RAM_SetCustomGfxBuilders = 0x802c94a0
export const RAM_b_area_omo2_3_shy_stack_takeTurn = 0x802357b4
export const RAM_kzn_19_StarSpiritEffectFunc6 = 0x80240a14
export const RAM_state_step_unpause = 0x80034c3c
export const RAM_smoke_burst_render = 0xe004e1d0
export const RAM_b_area_kmr_part_1_kmr_04_foregroundModelList_80220660 = 0x80220660
export const RAM_D_80244A78_C5F1B8 = 0x80244a78
export const RAM_D_8029FBC0 = 0x8029fbc0
export const RAM_func_800E315C = 0x800e315c
export const RAM_b_area_jan_spear_guy_8021BF8C = 0x8021bf8c
export const RAM_action_update_parasol = 0x802b6120
export const RAM_JumpedOnSwitchX = 0x8010c960
export const RAM_b_area_trd_part_1_formation_0F = 0x80218944
export const RAM_iwa_02_CleftAI_FindPlayer = 0x802404d0
export const RAM_hos_03_ToadHouse_Stub = 0x8024249c
export const RAM_arn_04_PatrolAI_Jump = 0x802407a4
export const RAM_dgb_01_D_802537E0_C11060 = 0x802537e0
export const RAM_b_area_kmr_part_2_blue_goomba_2_statusTable_8021D40C = 0x8021d40c
export const RAM_kzn_10_UnkFunc46 = 0x8024037c
export const RAM_func_80240314_DDF624 = 0x80240314
export const RAM_omo_17_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_ui_status_pow_star_4_png = 0x801009c0
export const RAM_D_8014C500 = 0x8014c500
export const RAM_b_area_jan_spear_guy_80218B5C = 0x80218b5c
export const RAM_dead_gCurrentEncounter = 0x800b7ef0
export const RAM_D_09000000_3863B0 = 0x09000000
export const RAM_flo_08_SuperBlock_CantUpgradeMessages = 0x80241fb0
export const RAM_battle_item_fire_flower_PlayerGoHome = 0x802a1830
export const RAM_ui_partner9_png = 0x800ff820
export const RAM_MerleeAttackBonus = 0x80288400
export const RAM_func_80218AD0_4EFF70 = 0x80218ad0
export const RAM_b_area_pra2_sam_04_beforeBattle = 0x80221498
export const RAM_b_area_kmr_part_2_kmr_02_beforeBattle_80224700 = 0x80224700
export const RAM_ui_battle_cmd_aim_target_0_png = 0x8028f3c0
export const RAM_trd_01_StationaryAI_Alert = 0x80240df8
export const RAM_flo_15_8024017C = 0x8024017c
export const RAM_entity_init_BlueWarpPipe = 0x802e8f94
export const RAM_dup2_b_area_flo_UnkFloatFunc = 0x80219af0
export const RAM_flo_00_interact_80243C4C = 0x80243c4c
export const RAM_vtx_C2B010 = 0x8020a0d0
export const RAM_func_80050888 = 0x80050888
export const RAM_b_area_sam_duplighost_watt = 0x80223a40
export const RAM_flo_08_802431C4 = 0x802431c4
export const RAM_kzn_11_FlyingNoAttackAI_Main = 0x80241d64
export const RAM_merlin_house_stars_main = 0xe00a6000
export const RAM_clear_global_flag = 0x801453e0
export const RAM_D_80099BA0 = 0x80099ba0
export const RAM_flo_18_triggerCoord_80245694 = 0x80245694
export const RAM_b_area_dgb_tubba_blubba_nextTurn_80218FB0 = 0x80218fb0
export const RAM_ui_red_bar2_pal = 0x800fcee0
export const RAM_FullyRestoreHPandFP = 0x802d1ec0
export const RAM_func_80240814_9EE404 = 0x80240814
export const RAM_jan_03_ToadHouse_UpdateScreenOverlay = 0x802414f8
export const RAM_D_80074210 = 0x80074210
export const RAM_SetGoalToTarget = 0x8026a3a8
export const RAM_dro_02_D_80248C00_971DC0 = 0x80248c00
export const RAM_func_8026D5A4 = 0x8026d5a4
export const RAM_dgb_09_FlyingAI_LoiterInit = 0x80241bfc
export const RAM_dgb_02_npcAI_80241BCC = 0x80241bcc
export const RAM_load_engine_data = 0x80026ddc
export const RAM_D_8010D000 = 0x8010d000
export const RAM_ui_battle_menu_strategies_disabled_pal = 0x80289ce0
export const RAM_b_area_jan2_jan_01b = 0x8021f294
export const RAM_D_80153A51 = 0x80153a51
export const RAM_b_area_jan_hurt_plant_defenseTable_8021D98C = 0x8021d98c
export const RAM_D_8024F300 = 0x8024f300
export const RAM_gSpinHistoryPosY = 0x8010ef10
export const RAM_kzn_08_FlyingNoAttackAI_20 = 0x802422f8
export const RAM_D_8029D388 = 0x8029d388
export const RAM_sbk_34_GetNpcCollisionHeight = 0x80240158
export const RAM_D_80241A78_DE3F08 = 0x80241a78
export const RAM_PartnerDamageEnemy = 0x8027fdb4
export const RAM_D_80243960_7EAD00 = 0x80243960
export const RAM_flo_08_npcGroup_80244028 = 0x80244028
export const RAM_dro_01_AddPlayerHandsOffset = 0x80242560
export const RAM_D_80241820_D92F20 = 0x80241820
export const RAM_func_802188FC_59EFDC = 0x802188fc
export const RAM_omo_15_StarSpiritEffectFunc2 = 0x8024004c
export const RAM_thunderbolt_ring_init = 0xe00b011c
export const RAM_set_script_timescale = 0x802c41e0
export const RAM_ui_battle_status_charge_jump_pal = 0x801018a0
export const RAM_tik_08_Pipe_GetCurrentFloor = 0x80240020
export const RAM_D_8024F2F0_C0CB70 = 0x8024f2f0
export const RAM_HES_Item_Unused_08A = 0x80082298
export const RAM_b_area_sam2_monstar_idleAnimations2 = 0x80219fe4
export const RAM_b_area_iwa_iwa_01_idleAnimations_80220900 = 0x80220900
export const RAM_restart_script = 0x802c3d04
export const RAM_D_8024AC7B_A34EBB = 0x8024ac7b
export const RAM_D_E00685B0 = 0xe00685b0
export const RAM_D_80245978_A161F8 = 0x80245978
export const RAM_startup_draw_prim_rect_COPY = 0x8002aac4
export const RAM_dgb_14_exitDoubleDoor_80240100 = 0x80240100
export const RAM_D_800DA040 = 0x800da040
export const RAM_b_area_omo_omo_04_handleEvent_8022ED54 = 0x8022ed54
export const RAM_guMtxF2L = 0x80067500
export const RAM_b_area_pra2_crystal_king_clone = 0x8021c7e4
export const RAM_HES_StickHoldDown = 0x8010908c
export const RAM_func_80254950 = 0x80254950
export const RAM_kkj_25_UnkFunc1 = 0x80240050
export const RAM_item_entity_load = 0x80131128
export const RAM_b_area_kpa2_UnfadeBackgroundToBlack = 0x8021c09c
export const RAM_fx_75_init = 0xe00ea190
export const RAM_mac_00_GetNpcCollisionHeight = 0x802416c4
export const RAM_b_area_trd_part_1_paratroopa_UnkBattleFunc1 = 0x80218148
export const RAM_evt_float_to_fixed_var = 0x802c496c
export const RAM_D_80150F90 = 0x80150f90
export const RAM_SetActorType = 0x8026fb20
export const RAM_EnablePartnerAI = 0x802cf52c
export const RAM_star_outline_init = 0xe012612c
export const RAM_flo_25_npcAI_80242A30 = 0x80242a30
export const RAM_func_802402BC_98679C = 0x802402bc
export const RAM_b_area_kmr_part_1_paragoomba_StartRumbleWithParams = 0x80218500
export const RAM_func_8004C0E4 = 0x8004c0e4
export const RAM_ui_stencil_star_png = 0x8014c700
export const RAM_D_80245450_EF2550 = 0x80245450
export const RAM_D_80263518_C20D98 = 0x80263518
export const RAM_update_item_entity_collectable = 0x80134250
export const RAM_b_area_sam2_monstar_defenseTable = 0x80219e60
export const RAM_D_80098064 = 0x80098064
export const RAM_jan_01_HoppingAI_Chase = 0x80240bec
export const RAM_b_area_pra3_red_magikoopa_StartRumbleWithParams = 0x80219a80
export const RAM_tik_07_UnkPosFunc2 = 0x80241020
export const RAM_dgb_01_D_80251AAC_C0F32C = 0x80251aac
export const RAM_D_090000C0_32CF80 = 0x090000c0
export const RAM_gMasterGfxPos = 0x8009a66c
export const RAM_D_80245338_CD0648 = 0x80245338
export const RAM_func_80240000_D91700 = 0x80240000
export const RAM_kzn_22_GetFloorCollider = 0x8024030c
export const RAM_pra_29_reflection_unk_change_anim_facing = 0x80240054
export const RAM_GetDoorState = 0x80282324
export const RAM_sam_11_Quizmo_varStash = 0x80244f78
export const RAM_small_gold_sparkle_render = 0xe0092258
export const RAM_b_area_kmr_part_3_kmr_04_clouds3 = 0x802329b0
export const RAM_func_8003E514 = 0x8003e514
export const RAM_D_E02006A8 = 0xe02006a8
export const RAM_dgb_15_npcGroupList_80242AB0 = 0x80242ab0
export const RAM_b_area_kmr_part_2_blue_goomba_2_idle_8021D5E8 = 0x8021d5e8
export const RAM_b_area_kmr_part_3_formationTable = 0x8021a2ac
export const RAM_func_802A9B30 = 0x802a9b30
export const RAM_func_80045B10 = 0x80045b10
export const RAM_sbk_55_SetNpcB5_3 = 0x80240040
export const RAM_gMessageBoxFrameParts = 0x8008fee0
export const RAM_battle_star_peach_focus_UnkStarFunc3 = 0x802a116c
export const RAM_EE70C0_AddPlayerHandsOffset = 0x80242090
export const RAM_D_802313E0_63CF80 = 0x802313e0
export const RAM_action_command_break_free_draw_hud_elements = 0x802a96b8
export const RAM_sbk_00_80240198 = 0x80240198
export const RAM_func_80056DA4 = 0x80056da4
export const RAM_dgb_01_D_80252E18_C10698 = 0x80252e18
export const RAM_jan_22_StashVars = 0x80240e90
export const RAM_func_80242788_8B27F8 = 0x80242788
export const RAM_b_area_sam_duplighost_sushie_takeTurn = 0x80224f04
export const RAM_PATCH_state_step_title_screen = 0x80400028
export const RAM_D_80244DE8_A1B008 = 0x80244de8
export const RAM_flo_17_80243FD0 = 0x80243fd0
export const RAM_b_area_iwa_formation_0B = 0x802184e8
export const RAM_guOrtho = 0x80063a40
export const RAM_world_kooper_post_battle = 0x802beb10
export const RAM_isk_10_SuperBlock_AnimateEnergyOrbs = 0x80240600
export const RAM_b_area_pra2_crystal_king_handleEvent = 0x80219f30
export const RAM_D_09002110_352330 = 0x09002110
export const RAM_flower_trail_appendGfx = 0xe0012564
export const RAM_update_camera_mode_2 = 0x8002ecf8
export const RAM_IES_ITEM_139 = 0x8008b304
export const RAM_kgr_02_DarkRoomUpdate = 0x80240000
export const RAM_D_E0014798 = 0xe0014798
export const RAM_spawn_drops = 0x80048280
export const RAM_fx_tattle_window = 0x80072110
export const RAM_func_802B6550_E23C30 = 0x802b6550
export const RAM_gGameStatus = 0x80074024
export const RAM_battle_move_mega_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_func_800EF414 = 0x800ef414
export const RAM_floating_flower_init = 0xe001a21c
export const RAM_peach_star_beam_init = 0xe010c190
export const RAM_HES_EldstarDisabled = 0x801079bc
export const RAM_D_80243D30_A72110 = 0x80243d30
export const RAM_gPauseMsg_33 = 0x8026f668
export const RAM_b_area_pra3_duplighost_UnkWattEffectFunc3 = 0x80219358
export const RAM_b_area_arn_hyper_paragoomba_takeTurn_8021FE6C = 0x8021fe6c
export const RAM_bind_npc_aux = 0x8003eb50
export const RAM_bMarioDefendAnims = 0x802832d4
export const RAM_omo_02_ShyGuyWanderAI_14 = 0x80240970
export const RAM_D_8009A620 = 0x8009a620
export const RAM_action_update_land = 0x802b6000
export const RAM_D_801094AC = 0x801094ac
export const RAM_HES_Item_BubbleBerry = 0x80086a98
export const RAM_func_A4000900 = 0xa4000900
export const RAM_kpa_11_UnkDistFunc = 0x8024038c
export const RAM_AmbientSound = 0x8014f140
export const RAM_flo_21_vectorList_8024189C = 0x8024189c
export const RAM_b_area_hos_ember_statusTable_8021ACD4 = 0x8021acd4
export const RAM_func_80219D60_4491A0 = 0x80219d60
export const RAM_D_80073E04 = 0x80073e04
export const RAM_au_SEFCmd_07 = 0x8004d050
export const RAM_HES_Item_QuickChange = 0x800858f8
export const RAM_D_802D9D70 = 0x802d9d70
export const RAM_func_802B71E8_E202F8 = 0x802b71e8
export const RAM_dro_01_init_802483A8 = 0x802483a8
export const RAM_b_area_pra3_duplighost_formation_kooper = 0x8021f394
export const RAM_kzn_22_UpdateTexturePanStepped = 0x80240124
export const RAM_mac_03_Quizmo_ShowEntities = 0x802405dc
export const RAM_D_8029F288 = 0x8029f288
export const RAM_action_update_step_down = 0x802b6348
export const RAM_dgb_09_SentinelAI_ReturnHome = 0x80243260
export const RAM_D_802BFDBC_320B2C = 0x802bfdbc
export const RAM_update_merlee_messages = 0x80045b68
export const RAM_D_80236040 = 0x80236040
export const RAM_gEffectInstances = 0x800b4398
export const RAM_isk_14_HoppingAI_Loiter = 0x80240628
export const RAM_sfx_clear_env_sounds = 0x80149670
export const RAM_ShortenPartnerTetherDistance = 0x80283b88
export const RAM_HES_Surprise = 0x8010760c
export const RAM_b_area_kmr_part_3_kmr_05_clouds1 = 0x80232bd0
export const RAM_gPlayerSpinState = 0x8010f250
export const RAM_func_80056D5C = 0x80056d5c
export const RAM_kzn_03_UnsetCamera0MoveFlag1 = 0x80242d00
export const RAM_func_802BDA90_3215E0 = 0x802bda90
export const RAM_sam_06_LetterDelivery_SaveNpcAnim = 0x802424c8
export const RAM_mgm_00_Pipe_GetEntryPos = 0x802400f0
export const RAM_D_80240E88_AF87D8 = 0x80240e88
export const RAM_omo_13_varStash = 0x80242c70
export const RAM_b_area_sam_sam_03_UnkModelStuffScript4 = 0x80230454
export const RAM_TranslateModel = 0x802c8b60
export const RAM_dma_load_msg = 0x80125804
export const RAM_func_80241FB0_D3C580 = 0x80241fb0
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B604 = 0x8022b604
export const RAM_jan_04_GetItemName = 0x80240404
export const RAM_b_area_sbk_formation_02 = 0x8021f994
export const RAM_func_802404A0_8AC430 = 0x802404a0
export const RAM_GetEnemyMaxHP = 0x8027c748
export const RAM_dgb_08_SentinelAI_Chase = 0x80242db0
export const RAM_b_area_sam_duplighost_bow_handleEvent = 0x802226ec
export const RAM_ECAA80_ItemChoiceList = 0x802462f0
export const RAM_D_80076078 = 0x80076078
export const RAM_tik_01_Pipe_SetAnimFlag = 0x8024030c
export const RAM_func_802411D8_D6FB88 = 0x802411d8
export const RAM_D_802445D0_A3DAB0 = 0x802445d0
export const RAM_HES_Item_Unused_0D7_disabled = 0x800854a8
export const RAM_nok_01_Quizmo_ShouldQuizmoLeave = 0x802418a4
export const RAM_IsStartingConversation = 0x800441f0
export const RAM_b_area_sam_duplighost_formation_goombario = 0x8021e660
export const RAM_b_area_omo_anti_guy_idle_80221AAC = 0x80221aac
export const RAM_HES_StatusSPIncrement1 = 0x80108298
export const RAM_battle_star_peach_beam_UnkStarFunc2 = 0x802a10ac
export const RAM_dro_01_8024B6B4 = 0x8024b6b4
export const RAM_jan_05_MeleeHitbox_30 = 0x802418cc
export const RAM_end_00_CreditsMessageBuffers = 0x8024f7f0
export const RAM_vtx_C262D0 = 0x80205390
export const RAM_SetupSingleDoor = 0x80283d00
export const RAM_TwinkUpdate = 0x802bd148
export const RAM_HES_Item_TripleDip_disabled = 0x80084b48
export const RAM_gBattleMapVars = 0x802dbce8
export const RAM_func_802C0000 = 0x802bd100
export const RAM_D_802440F3 = 0x802440f3
export const RAM_get_defense = 0x802662bc
export const RAM_flo_11_pad_30EC = 0x802430ec
export const RAM_D_80249934_A33B74 = 0x80249934
export const RAM_func_E00863B4 = 0xe00863b4
export const RAM_battle_move_auto_multibounce_D_802A372C = 0x802a372c
export const RAM_isk_04_UnkDistFunc = 0x802400bc
export const RAM_func_802413A4_D6FD54 = 0x802413a4
export const RAM_flo_10_updateTexturePan_80241D14 = 0x80241d14
export const RAM_b_area_isk_part_1_formation_06 = 0x802242a8
export const RAM_D_80152218 = 0x80152218
export const RAM_func_802A472C = 0x802a472c
export const RAM_pra_01_ReflectPartner = 0x80240d3c
export const RAM_func_80122FB8 = 0x80122fb8
export const RAM_AddBattleCamZoom = 0x8024ecac
export const RAM_kpa_03_MagikoopaAI_01 = 0x8024119c
export const RAM_D_800F8034 = 0x800f8034
export const RAM_func_8024025C_BBB5DC = 0x8024025c
export const RAM_kpa_95_PatrolAI_LoiterInit = 0x802404e0
export const RAM_reset_max_rumble_duration = 0x80028fe0
export const RAM_battle_move_multibounce_D_802A1D88 = 0x802a1d88
export const RAM_SetAnimation = 0x8026b1b0
export const RAM_kmr_03_npcAI_80240B50 = 0x80240b50
export const RAM_dro_01_8024792C = 0x8024792c
export const RAM_D_8024EA60_C0C2E0 = 0x8024ea60
export const RAM_BindNpcHit = 0x80045140
export const RAM_IES_SilverCard = 0x8008bcc4
export const RAM_update_camera_mode_unused = 0x8002fce0
export const RAM_kkj_25_UnkLightningFXFunc = 0x802400ac
export const RAM_jan_08_SuperBlock_StartGlowEffect = 0x80240ae0
export const RAM_dro_02_8024B5A0 = 0x8024b5a0
export const RAM_nuSiMgrThread = 0x8002cfd0
export const RAM_ui_bombette_png = 0x800feaa0
export const RAM_D_80297A38 = 0x80297a38
export const RAM_nok_12_set_script_owner_npc_anim = 0x80240570
export const RAM_flo_08_SuperBlock_SetOverride40 = 0x80240000
export const RAM_entity_HeartBlockContent_reset = 0x802e5428
export const RAM_func_8021878C_512D5C = 0x8021878c
export const RAM_gWindowAppearFlags = 0x8014f2a0
export const RAM_func_802537C0 = 0x802537c0
export const RAM_battle_partner_goombario_80239988 = 0x80239988
export const RAM_D_800A090C = 0x800a090c
export const RAM_jan_07_TransformFoliage = 0x80240e10
export const RAM_D_8029D488 = 0x8029d488
export const RAM_HES_RotateStickCW = 0x80108d6c
export const RAM_kzn_17_set_script_owner_npc_anim = 0x80241cb4
export const RAM_flo_00_npcGroup_80245BF4 = 0x80245bf4
export const RAM_vtx_C2E800 = 0x8020d8c0
export const RAM_D_802EB050 = 0x802eb050
export const RAM_b_area_isk_part_1_vector3D_80224088 = 0x80224088
export const RAM_isk_14_HoppingAI_LoiterInit = 0x80240568
export const RAM_D_80078E58 = 0x80078e58
export const RAM_D_E0078918 = 0xe0078918
export const RAM_D_E003CCF8 = 0xe003ccf8
export const RAM_pra_12_StashVars = 0x80240f50
export const RAM_dgb_01_D_802536E0_C10F60 = 0x802536e0
export const RAM_b_area_mac_lee_statusTable_80228470 = 0x80228470
export const RAM_phys_reset_spin_history = 0x800e59a0
export const RAM_SetVirtualEntityRenderMode = 0x802d4050
export const RAM_IES_ShroomCake = 0x8008d0b4
export const RAM_GetCamPosB = 0x802cc184
export const RAM_func_80240B78_BBDD18 = 0x80240b78
export const RAM_b_area_omo_spy_guy_StartRumbleWithParams = 0x80218d54
export const RAM_func_80265D44 = 0x80265d44
export const RAM_dgb_13_pad_37C = 0x8024037c
export const RAM_func_80240308_A787C8 = 0x80240308
export const RAM_b_area_omo2_4_signal_guy = 0x802310dc
export const RAM_b_area_kmr_part_3_para_jr_troopa_idle_802265A0 = 0x802265a0
export const RAM_D_802DF3E0 = 0x802df3e0
export const RAM_HES_StickSlowlyTapDown = 0x80109184
export const RAM_nok_02_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_tik_03_Pipe_GetCurrentFloor = 0x80240020
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_FreeIcon = 0x80218aa4
export const RAM_get_model_property = 0x80115b0c
export const RAM_pra_02_reflection_setup_floor = 0x80240500
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022A880 = 0x8022a880
export const RAM_D_801568E0 = 0x801568e0
export const RAM_b_area_jan_formation_0A = 0x802288ac
export const RAM_SetPartMoveSpeed = 0x8026b7ec
export const RAM_kzn_17_GetItemName = 0x80242650
export const RAM_func_80045900 = 0x80045900
export const RAM_dro_02_func_8024303C_96C1FC = 0x8024303c
export const RAM_D_E0114718 = 0xe0114718
export const RAM_arn_05_802441FC = 0x802441fc
export const RAM_HES_Item_DeepFocus = 0x800857d8
export const RAM_flo_08_interact_80243214 = 0x80243214
export const RAM_kzn_04_SuperBlock_WhiteScreenFlash = 0x80240e48
export const RAM_mac_01_Quizmo_NPC_Aux_Impl = 0x802422bc
export const RAM_mac_00_Pipe_AwaitDownInput = 0x80240034
export const RAM_sam_05_PiranhaPlantAI_00 = 0x802408d0
export const RAM_func_80240634_A2A874 = 0x80240634
export const RAM_b_area_sam2_sam_02c_UnkModelStuffScript3 = 0x80222aa4
export const RAM_D_80245050_BECC30 = 0x80245050
export const RAM_func_80057DC8 = 0x80057dc8
export const RAM_b_area_sam_sam_03_beforeBattle = 0x80230518
export const RAM_evt_goto_end_case = 0x802c89e4
export const RAM_D_800B0EBC = 0x800b0ebc
export const RAM_D_80243190 = 0x80243190
export const RAM_Munchlesia_LateralVelocity = 0x802b62d4
export const RAM_ShowMerleeCoinMessage = 0x8003ee98
export const RAM_func_80218000_4A10A0 = 0x80218000
export const RAM_kzn_08_FlyingAI_Jump = 0x802416a4
export const RAM_D_09000200_326610 = 0x09000200
export const RAM_D_E00C6CF0 = 0xe00c6cf0
export const RAM_b_area_kpa_StartRumbleWithParams = 0x8021819c
export const RAM_vtx_C2D260 = 0x8020c320
export const RAM_arn_07_exitWalk_802429D4 = 0x802429d4
export const RAM_kmr_03_802422B8 = 0x802422b8
export const RAM_dup4_b_area_kpa2_FadeBackgroundToBlack = 0x8021a6bc
export const RAM_D_8024BD78_A26E78 = 0x8024bd78
export const RAM_SetNewItemVisGroup = 0x80282774
export const RAM_kmr_12_MakeEntities = 0x80240a64
export const RAM_arn_03_exitWalk_80241830 = 0x80241830
export const RAM_dro_01_pad_BA9C = 0x8024ba9c
export const RAM_b_area_omo_pink_shy_guy_idle_8021E4B4 = 0x8021e4b4
export const RAM_D_E006EC38 = 0xe006ec38
export const RAM_D_8024AA20_A34C60 = 0x8024aa20
export const RAM_kmr_20_ToadHouse_GetPartnerBackOut = 0x80241564
export const RAM_fx_stars_orbiting = 0x80070a30
export const RAM_D_802BCC44_E2F574 = 0x802bcc44
export const RAM_dgb_01_npcGroupList_80246ED4 = 0x80246ed4
export const RAM_D_E0116D6B = 0xe0116d6b
export const RAM_b_area_kmr_part_1_goomba_idleAnimations_80219854 = 0x80219714
export const RAM_D_80299828 = 0x80299828
export const RAM_flo_11_Pipe_SetAnimFlag = 0x80240000
export const RAM_b_area_omo_spy_guy_hammer_icons = 0x80228748
export const RAM_sbk_24_foliage_setup_shear_mtx = 0x80240140
export const RAM_gfx_frame_filter_pass_0 = 0x800271fc
export const RAM_func_802402E0_8B0350 = 0x802402e0
export const RAM_jan_05_MeleeHitbox_Main = 0x80241c88
export const RAM_osContGetInitData = 0x80061d1c
export const RAM_flo_08_AddPlayerHandsOffset = 0x802417a0
export const RAM_func_802400AC_D53D5C = 0x802400ac
export const RAM_isk_04_SwitchToPartner = 0x80240930
export const RAM_arn_07_npcSettings_80243C78 = 0x80243c78
export const RAM_SetItemAlpha = 0x802d721c
export const RAM_gHudElementCacheBufferBattle = 0x80158570
export const RAM_D_80150F98 = 0x80150f98
export const RAM_MakeItemEntity = 0x802d6cc0
export const RAM_D_8024E9D8_C0C258 = 0x8024e9d8
export const RAM_MAMAR_trackMute = 0x8042047c
export const RAM_b_area_sam_duplighost_kooper_defenseTable = 0x8021e714
export const RAM_pause_items_draw_contents = 0x80249970
export const RAM_nok_14_ParatroopaAI_Reset = 0x80242188
export const RAM_CamPreset_N = 0x80280d90
export const RAM_b_area_kzn_kzn_05_80228FBC = 0x80228fbc
export const RAM_kkj_01_PatrolAI_Move = 0x80240158
export const RAM_D_802500C8_C0D948 = 0x802500c8
export const RAM_D_802EB058 = 0x802eb058
export const RAM_func_802A9000_4233F0 = 0x802a9000
export const RAM_D_802DFE48 = 0x802dfe48
export const RAM_D_802A3F88 = 0x802a3f88
export const RAM_world_watt_while_riding = 0x802be2d0
export const RAM_action_command_spook_MashActionCommandInit = 0x802a917c
export const RAM_vtx_C23E00 = 0x80202ec0
export const RAM_jan_11_Pipe_GetCurrentFloor = 0x80240178
export const RAM_dead_set_main_pan_v = 0x8012607c
export const RAM_D_09003388 = 0x09003388
export const RAM_D_802AD6AA = 0x802ad6aa
export const RAM_D_8024856C_9CD94C = 0x8024856c
export const RAM_mac_00_LetterDelivery_CalcLetterPos = 0x80241ba4
export const RAM_IES_SJumpChg = 0x8008c124
export const RAM_D_E0044DB0 = 0xe0044db0
export const RAM_world_lakilester_pre_battle = 0x802bfa00
export const RAM_func_80240774_AF3414 = 0x80240774
export const RAM_D_8029CC20 = 0x8029cc20
export const RAM_dgb_01_D_80253760_C10FE0 = 0x80253760
export const RAM_func_8003E1D0 = 0x8003e1d0
export const RAM_IsMessageBoxDisplayed = 0x80251384
export const RAM_b_area_omo2_3_shy_stack_onDeath = 0x80236468
export const RAM_ui_super_boots_pal = 0x800fb320
export const RAM_D_800AF918 = 0x800af918
export const RAM_tik_12_SuperBlock_PartnerSparkles4 = 0x802418dc
export const RAM_func_800325E4 = 0x800325e4
export const RAM_b_area_jan_formation_01 = 0x802285b8
export const RAM_gPlayerStatusPtr = 0x800f7b30
export const RAM_battle_move_auto_multibounce_D_802A17C4 = 0x802a17c4
export const RAM_D_80246A08_C467B8 = 0x80246a08
export const RAM_CreatePartShadow = 0x8026f52c
export const RAM_D_80245300_CDC5E0 = 0x80245300
export const RAM_battle_action_cmd_whirlwind_4_pal = 0x802a9dc0
export const RAM_omo_09_PatrolAI_ChaseInit = 0x80241768
export const RAM_btl_state_draw_player_move = 0x80246448
export const RAM_HES_Item_AutoSmash = 0x80083f78
export const RAM_b_area_pra3_red_magikoopa_8022C2B4 = 0x8022c2b4
export const RAM_func_80240000_D7B2A0 = 0x80240000
export const RAM_mim_11_TransformFoliage = 0x802409f0
export const RAM_b_area_omo2_2_stilt_guy_statusTable = 0x802311a0
export const RAM_dgb_01_D_802532E0_C10B60 = 0x802532e0
export const RAM_dgb_01_npcAI_80244D54 = 0x80244d54
export const RAM_D_09000080_32CF40 = 0x09000080
export const RAM_kpa_01_func_80240814_97BE44 = 0x80240934
export const RAM_b_area_sam_sam_02 = 0x8022f7ec
export const RAM_DemoJoystickXY = 0x802d9c10
export const RAM_D_09002D70 = 0x09002d70
export const RAM_dgb_01_FlyingAI_Wander = 0x802402d0
export const RAM_flo_24_tree1_Trunk = 0x802416c0
export const RAM_reload_mesh_animator_tree = 0x80120678
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E670 = 0x8022e670
export const RAM_gPauseWS_7 = 0x8026fa88
export const RAM_D_802438E0_C59820 = 0x802438e0
export const RAM_gCollisionData = 0x800b42e0
export const RAM_func_8025D71C = 0x8025d71c
export const RAM_flo_03_func_80240000_CA72A0 = 0x80240000
export const RAM_func_802405B0_D2C2E0 = 0x802405b0
export const RAM_render_generic_entities_world = 0x80123470
export const RAM_HES_Item_BooPortrait = 0x80081788
export const RAM_draw_item_entities = 0x80132c80
export const RAM_b_area_isk_part_1_isk_08_beforeBattle_80223FE0 = 0x80223fe0
export const RAM_IES_AutoJump = 0x8008c144
export const RAM_dro_01_80244A70 = 0x80244a70
export const RAM_SetVirtualEntityFlagBits = 0x802d3fc8
export const RAM_b_area_pra3_white_clubba_idle = 0x8022b2dc
export const RAM_b_area_jan_medi_guy_statusTable_8021F8F0 = 0x802225b0
export const RAM_iwa_01_CleftAI_RevUp = 0x80242958
export const RAM_sbk_02_GetItemName = 0x80240af8
export const RAM_MAMAR_bgm = 0x80400460
export const RAM_func_80240564_DE5C14 = 0x80240564
export const RAM_dgb_01_D_802539A0_C11220 = 0x802539a0
export const RAM_omo_09_RangedAttackAI_Main = 0x8024324c
export const RAM_b_area_jan_formation_09 = 0x80228858
export const RAM_D_8029CC28 = 0x8029cc28
export const RAM_b_area_omo_formation_12 = 0x8022fe70
export const RAM_bModelSpecialDls = 0x80152010
export const RAM_LoadItemScript = 0x80267ed0
export const RAM_func_800EB2A4 = 0x800eb2a4
export const RAM_pra_18_SetGameStatusUnk84_1 = 0x80240000
export const RAM_b_area_trd_part_2_trd_02_802314E0 = 0x802314e0
export const RAM_b_area_trd_part_1_trd_05e_beforeBattle_802273B4 = 0x802273b4
export const RAM_b_area_trd_part_1_trd_05f = 0x80227c9c
export const RAM_kzn_02_LetterDelivery_RestoreNpcAnim = 0x802426f8
export const RAM_D_800DC4EC = 0x800dc4ec
export const RAM_dup2_b_area_omo3_UnkBattleFunc1 = 0x80218ce0
export const RAM_b_area_trd_part_1_trd_05d_802264B0 = 0x802264b0
export const RAM_dgb_01_D_80252D10_C10590 = 0x80252d10
export const RAM_gPausePartnersWindowBPs = 0x8024f7c8
export const RAM_b_area_kzn_putrid_piranha_UnkBattleFunc1 = 0x80218130
export const RAM_nok_15_Pipe_GetCurrentFloor = 0x80240020
export const RAM_HES_HandPointer = 0x80104a00
export const RAM_kzn_02_FlyingAI_JumpInit = 0x802410bc
export const RAM_PlaySoundAtPart = 0x802538d0
export const RAM_D_80247548 = 0x80247548
export const RAM_D_800A0BB8 = 0x800a0bb8
export const RAM_flo_17_80243280 = 0x80243280
export const RAM_iwa_04_CleftAI_Main = 0x80240d10
export const RAM_kmr_03_treeModelList_Bush1_Bush = 0x80242154
export const RAM_dgb_18_CheckItemFlags40 = 0x80241218
export const RAM_battle_item_life_shroom_func_802A12EC_72E81C = 0x802a12ec
export const RAM_AlCosineBlend = 0x80078e60
export const RAM_b_area_kmr_part_2_goomba_king_takeTurn_80221530 = 0x80221530
export const RAM_mgm_02_PeachPanelPal = 0x802484a0
export const RAM_mac_04_Quizmo_HideEntities = 0x80240578
export const RAM_IES_KootKoopaShell = 0x8008bd84
export const RAM_dgb_08_idle_80244A54 = 0x80244a54
export const RAM_jan_14_HoppingAI_ChaseInit = 0x80240c8c
export const RAM_vtx_C26610 = 0x802056d0
export const RAM_kkj_16_UnkGooberFunc50 = 0x802406d8
export const RAM_is_block_on_ground = 0x80112b20
export const RAM_D_8024DA90_A37CD0 = 0x8024da90
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021B7D4 = 0x8021b7d4
export const RAM_func_8004CDF8 = 0x8004cdf8
export const RAM_arn_05_init_80244358 = 0x80244358
export const RAM_D_80241800_8765C0 = 0x80241800
export const RAM_b_area_omo2_3_shy_stack_onSpinSmashLaunchDeath = 0x80236518
export const RAM_D_E002A728 = 0xe002a728
export const RAM_D_80245F30_AD4070 = 0x80245f30
export const RAM_fold_init = 0x8013a37c
export const RAM_btl_cam_move = 0x8024e4d8
export const RAM_D_8009C4FC = 0x8009c4fc
export const RAM_mac_03_LetterDelivery_SavedNpcAnim = 0x802515c4
export const RAM_D_802A1D88 = 0x802a1d88
export const RAM_fx_quizmo_stage = 0x800715d0
export const RAM_D_80245858_C859F8 = 0x80245858
export const RAM_dro_02_npcGroup_8024D7B4 = 0x8024d7b4
export const RAM_dgb_07_npcSettings_80241A50 = 0x80241a50
export const RAM_D_8025578C_8C57FC = 0x8025578c
export const RAM_dgb_02_MeleeHitbox_Main = 0x802403bc
export const RAM_D_802D9E98 = 0x802d9e98
export const RAM_func_802BB648_E2DF78 = 0x802bb648
export const RAM_pra_02_ReflectWall = 0x802400ec
export const RAM_bss_802BFF0C = 0x802bff0c
export const RAM_dead_heap_free = 0x8002c01c
export const RAM_D_80241120_C6DA40 = 0x80241120
export const RAM_arn_02_CleftAI_LosePlayer = 0x80240984
export const RAM_D_8010BF60 = 0x8010bf60
export const RAM_b_area_sam_formation_07 = 0x80230ce8
export const RAM_omo_11_SuperBlock_get_partner_rank = 0x80240864
export const RAM_flo_16_FlyingAI_Main = 0x8024215c
export const RAM_D_80242F98_A67028 = 0x80242f98
export const RAM_b_area_kmr_part_2_pos03 = 0x802197ac
export const RAM_vtx_C2EA60 = 0x8020db20
export const RAM_hos_03_Quizmo_CreateStage = 0x80242c14
export const RAM_mac_05_Quizmo_SpinPlayer = 0x8024213c
export const RAM_kzn_10_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802402D0_DCCFA0 = 0x802402d0
export const RAM_ui_kalmar_disabled_pal = 0x800fdea0
export const RAM_D_800FBBE0 = 0x800fbbe0
export const RAM_fx_floating_cloud_puff = 0x80071ff0
export const RAM_ui_battle_cmd_100pct_png = 0x8028db00
export const RAM_b_area_omo_yellow_shy_guy_8021D004 = 0x8021d004
export const RAM_D_80220744 = 0x80220744
export const RAM_smoke_ring_main = 0xe002e000
export const RAM_HES_Item_LuckyStar = 0x800813f8
export const RAM_evt_find_label = 0x802c8890
export const RAM_AddItem = 0x802d6a98
export const RAM_IES_Unused_02E = 0x8008d574
export const RAM_b_area_iwa_iwa_01b = 0x80221560
export const RAM_mac_05_LetterDelivery_SaveNpcAnim = 0x80242a20
export const RAM_chomp_drop_render = 0xe009a398
export const RAM_HES_MenuItemDisabled = 0x8010894c
export const RAM_D_800A1530 = 0x800a1530
export const RAM_ui_battle_cmd_right_on_png = 0x8028e120
export const RAM_D_802495E0_A33820 = 0x802495e0
export const RAM_hud_element_set_scale = 0x80144c20
export const RAM_filemenu_update = 0x802431a0
export const RAM_func_80240000_A02840 = 0x80240000
export const RAM_GetBlockResult = 0x8026957c
export const RAM_kzn_04_SuperBlock_EndGlowEffect = 0x80240820
export const RAM_b_area_omo_red_shy_guy_8021A12C = 0x8021a12c
export const RAM_sam_01_Quizmo_SpinPlayer = 0x8024151c
export const RAM_func_802BD20C_31DF7C = 0x802bd20c
export const RAM_D_8029A76C = 0x8029a76c
export const RAM_omo_02_StationaryAI_LosePlayer = 0x802413d4
export const RAM_omo_05_FlyingAI_Chase = 0x80241848
export const RAM_vtx_C2D560 = 0x8020c620
export const RAM_ui_battle_status_transparent_5_pal = 0x80103100
export const RAM_iwa_10_SuperBlock_UpgradeOrbAngles = 0x80245ab8
export const RAM_flo_11_802415D4 = 0x802415d4
export const RAM_omo_04_ShyGuyWanderAI_16 = 0x802419d8
export const RAM_b_area_omo2_1_shy_squad_kill_guy = 0x80234480
export const RAM_HES_Item_AutoMultibounce = 0x800841b8
export const RAM_D_8010CCFE = 0x8010ccfe
export const RAM_b_area_trd_part_1_bullet_bill_idleAnimations_80219064 = 0x80219064
export const RAM_D_E00B4ED8 = 0xe00b4ed8
export const RAM_D_802E9928 = 0x802e9928
export const RAM_nuGfxMesgQ = 0x800b42f0
export const RAM_D_8024F470_hos_bss = 0x8024f470
export const RAM_HES_Item_ToadDoll = 0x80081ae8
export const RAM_b_area_mac_lee_8021F514 = 0x8021f514
export const RAM_dgb_10_settings = 0x80240210
export const RAM_tik_07_FlyingAI_WanderInit = 0x802413f0
export const RAM_ui_coin_sparkle_none_png = 0x800fbe00
export const RAM_gPauseTabsWindowIDs = 0x8024f1d8
export const RAM_mac_03_Quizmo_SetVannaAnim_Clap = 0x80240fb8
export const RAM_D_80244018_BA4748 = 0x80244018
export const RAM_b_area_trd_part_2_fake_bowser_8021AF70 = 0x8021af70
export const RAM_D_80241B80_A28A50 = 0x80241b80
export const RAM_D_80150860 = 0x80150860
export const RAM_sfx_get_env_sound_instance = 0x801498c4
export const RAM_dgb_01_FlyingAI_Init = 0x80241308
export const RAM_b_area_flo2_StartRumbleWithParams = 0x80218000
export const RAM_ui_battle_projector_beam_png = 0x8028fc70
export const RAM_vtx_C28310 = 0x802073d0
export const RAM_kmr_02_TransformFoliage = 0x802437d0
export const RAM_sam_06_UpdateTexturePanStepped = 0x80240124
export const RAM_nuAuCleanDMABuffers = 0x8004b268
export const RAM_DropTinyItemEntity = 0x802d6ec0
export const RAM_b_area_iwa_iwa_01_statusTable_80220958 = 0x80220958
export const RAM_D_80243390 = 0x80243390
export const RAM_b_area_sbk_formation_0A = 0x8021fc34
export const RAM_ui_battle_status_sleep_1_pal = 0x80101da0
export const RAM_kmr_03_treeModelList_Tree1_Trunk = 0x80242184
export const RAM_nok_11_FlyingAI_LoiterInit = 0x80240fac
export const RAM_b_area_kmr_part_3_kmr_05_foregroundModelList_80232FB0 = 0x80232fb0
export const RAM_D_8026FF88 = 0x8026ff88
export const RAM_arn_07_npcSettings_80243D48 = 0x80243d48
export const RAM_D_8029F24C = 0x8029f24c
export const RAM_battle_menu_moveOptionCostUnitIconIDs = 0x802ad190
export const RAM_vtx_C26310 = 0x802053d0
export const RAM_vtx_C22810 = 0x802018d0
export const RAM_player_test_lateral_overlap = 0x800dec04
export const RAM_arn_04_npcAISettings_802437C0 = 0x802437c0
export const RAM_D_80150868 = 0x80150868
export const RAM_get_animator_child_with_id = 0x8011e0dc
export const RAM_hos_03_Quizmo_SpinPlayer = 0x802432c0
export const RAM_b_area_kzn_white_magikoopa_partsTable_80223CB4 = 0x80223cb4
export const RAM_obk_01_LetterDelivery_SavedNpcAnim = 0x80245ab4
export const RAM_flo_13_FlyingAI_LosePlayer = 0x8024101c
export const RAM_GetLastDamage = 0x8027db3c
export const RAM_func_8003DFA0 = 0x8003dfa0
export const RAM_b_area_sam_gray_magikoopa_knockOff = 0x8022cc38
export const RAM_battle_menu_moveOptionCount = 0x802ad4a4
export const RAM_HES_WaterBlock1 = 0x802ab040
export const RAM_ui_battle_cmd_fill_result_png = 0x8028e3e0
export const RAM_gHudElementCacheTablePalette = 0x801512d0
export const RAM_D_E00CACB0 = 0xe00cacb0
export const RAM_D_802417E8_EB2648 = 0x802417e8
export const RAM_dro_01_pad_D_8024E1B0 = 0x8024e1b0
export const RAM_bCustomModelGfxBuilders = 0x80152110
export const RAM_D_800A0964 = 0x800a0964
export const RAM_battle_item_volt_shroom_main = 0x802a183c
export const RAM_b_area_isk_part_2_isk_02c_beforeBattle_8021FF80 = 0x8021ff80
export const RAM_D_E0040880 = 0xe0040880
export const RAM_action_update_run = 0x802b6288
export const RAM_func_E00AC2A4 = 0xe00ac2a4
export const RAM_nok_11_FlyingAI_JumpInit = 0x802413b0
export const RAM_D_800A0921 = 0x800a0921
export const RAM_b_area_kzn_formation_09 = 0x80229a84
export const RAM_func_80240000_E05360 = 0x80240000
export const RAM_SetCamTarget = 0x802caf2c
export const RAM_b_area_sam2_sam_01_UnkModelStuff_array = 0x80221df0
export const RAM_pra_16_SetPartnerFlags80000 = 0x80240eb8
export const RAM_battle_item_dried_shroom_func_802A123C_71CF1C = 0x802a123c
export const RAM_flo_09_tree2_Trunk = 0x80243444
export const RAM_alCopy = 0x80057fd8
export const RAM_D_80244A54_BC1BF4 = 0x80244a54
export const RAM_b_area_kpa4_GetSelectedMoveID = 0x80218438
export const RAM_ui_battle_cmd_timing_miss_pal = 0x8028d840
export const RAM_kkj_17_SomeItemEntityFunc = 0x80240118
export const RAM_battle_item_fire_flower_EatItem = 0x802a18cc
export const RAM_D_80245490_BB2DD0 = 0x80245490
export const RAM_b_area_trd_part_1_trd_01 = 0x80222b2c
export const RAM_b_area_sam_duplighost_goombario_defenseTable = 0x8021cb40
export const RAM_flo_23_BuildItemChoiceList = 0x802402f8
export const RAM_shim_draw_msg = 0xe0200650
export const RAM_b_area_mac_master1_init_8022A3B0 = 0x8022a3b0
export const RAM_EED1E0_SuperBlock_PartnerIDs = 0x80243768
export const RAM_kmr_02_Quizmo_DestroyEffects = 0x80240d18
export const RAM_b_area_pra3_duplighost_goombario_attack = 0x8021c8e4
export const RAM_b_area_kmr_part_3_paragoomba2_defenseTable_8021B110 = 0x8021f750
export const RAM_tik_12_StashVars = 0x80240960
export const RAM_D_E0044E70 = 0xe0044e70
export const RAM_func_E010E000 = 0xe010e000
export const RAM_tik_06_Pipe_GetCameraYaw = 0x802401d0
export const RAM_b_area_sam_sam_02d_UnkModelStuffScript3 = 0x8022fff4
export const RAM_flo_17_80244284 = 0x80244284
export const RAM_D_80225FD8_48D088 = 0x80225fd8
export const RAM_sam_10_MeleeHitbox_CanSeePlayer = 0x802405d8
export const RAM_D_E00328C0 = 0xe00328c0
export const RAM_D_E00628C0 = 0xe00628c0
export const RAM_b_area_nok_paratroopa_802204DC = 0x8022530c
export const RAM_dgb_03_PatrolAI_MoveInit = 0x80240b20
export const RAM_D_E0200708 = 0xe0200708
export const RAM_partner_handle_before_battle = 0x800eb658
export const RAM_func_80238000_6F5E80 = 0x80238000
export const RAM_b_area_hos_magikoopa_makeCopy = 0x80221700
export const RAM_pra_02_AddPlayerHandsOffset = 0x80240f7c
export const RAM_dgb_18_defeat_802428E8 = 0x802428e8
export const RAM_func_80242A4C_EA334C = 0x80242a4c
export const RAM_dgb_07_ClubbaNappingAI_ReturnHomeInit = 0x80240ba0
export const RAM_iwa_03_CleftAI_Main = 0x80240fd0
export const RAM_flo_25_npcGroup_8024387C = 0x8024387c
export const RAM_InitAnimatedModels = 0x802cccb0
export const RAM_SetBattleFlagBits2 = 0x8026cdcc
export const RAM_D_802A1320_765F40 = 0x802a1320
export const RAM_b_area_kzn_white_magikoopa_8022433C = 0x8022433c
export const RAM_b_area_omo_formation_02 = 0x8022f9a0
export const RAM_osInvalICache = 0x800617d0
export const RAM_entity_BlueWarpPipe_check_if_active = 0x802e89b0
export const RAM_b_area_sam_sam_02d_afterBattle = 0x80230214
export const RAM_b_area_mac_lee_statusTable_802233E0 = 0x802233e0
export const RAM_dup_b_area_kpa3_UnkBattleFunc1 = 0x80218c44
export const RAM_b_area_sam2_monstar_takeTurn = 0x8021b394
export const RAM_mac_00_Quizmo_FadeInWorld = 0x80240ba8
export const RAM_b_area_sbk_pokey = 0x80218410
export const RAM_HES_Item_SmashCharge_disabled = 0x80083ee8
export const RAM_func_802BD6BC_32120C = 0x802bd6bc
export const RAM_mac_05_Quizmo_SetCamVfov = 0x80241e8c
export const RAM_arn_04_PatrolAI_PostLoiter = 0x8024067c
export const RAM_flo_17_FlyingAI_Jump = 0x80240cc4
export const RAM_b_area_jan_spike_top_UnkBattleFunc1 = 0x802184f0
export const RAM_func_802A3C98 = 0x802a3c98
export const RAM_pra_35_StashVars = 0x80242950
export const RAM_func_80111790 = 0x80111790
export const RAM_D_80243170_EDBF90 = 0x80243170
export const RAM_basic_ai_wander = 0x800496b8
export const RAM_func_802664DC = 0x802664dc
export const RAM_set_area_byte = 0x80145638
export const RAM_func_80282414 = 0x80282414
export const RAM_D_090023C8 = 0x090023c8
export const RAM_vtx_C24510 = 0x802035d0
export const RAM_sbk_34_802427E0 = 0x802427e0
export const RAM_D_802483D0 = 0x802483d0
export const RAM_kzn_20_AddPlayerHandsOffset = 0x80240484
export const RAM_remove_status_icon_boost_partner = 0x80047edc
export const RAM_dro_02_npcAI_802474E0 = 0x802474e0
export const RAM_EC2610_TransformFoliage = 0x80240480
export const RAM_arn_07_init_80245104 = 0x80245104
export const RAM_GetBattlePhase = 0x80269e28
export const RAM_BattleMerleeUpdateFX = 0x802616f4
export const RAM_D_8029FB48 = 0x8029fb48
export const RAM_flo_10_triggerCoord_80244A40 = 0x80244a40
export const RAM_D_E0200700 = 0xe0200700
export const RAM_mac_04_Quizmo_UpdatePartnerPosition = 0x80241370
export const RAM_nuContStatus = 0x800b1b7c
export const RAM_b_area_omo_formation_0C = 0x8022fccc
export const RAM_func_80240C08_A11488 = 0x80240c08
export const RAM_kgr_01_GetEffectiveTongueOffset = 0x802403e0
export const RAM_b_area_iwa_iwa_02_beforeBattle_80221590 = 0x80221590
export const RAM_reset_actor_turn_info = 0x80263c60
export const RAM_D_E0038A28 = 0xe0038a28
export const RAM_pause_update_tab_inactive = 0x80242ff4
export const RAM_D_E00E2A30 = 0xe00e2a30
export const RAM_battle_partner_kooper_80239084 = 0x80239084
export const RAM_b_area_pra2_sam_04_UnkModelStuff_func1 = 0x802186c0
export const RAM_VirtualEntityLandJump = 0x802d3eb8
export const RAM_pra_12_SetPartnerFlags20000 = 0x80240f1c
export const RAM_b_area_pra3_duplighost_parakarry_defenseTable = 0x8022065c
export const RAM_dgb_03_MeleeHitbox_30 = 0x80240250
export const RAM_arn_04_PatrolAI_JumpInit = 0x80240730
export const RAM_D_8010BC08 = 0x8010bc08
export const RAM_PostChapter1StatUpdate = 0x802405d0
export const RAM_b_area_pra3_red_magikoopa_UnkBattleFunc1 = 0x802199b4
export const RAM_func_80240694_AF3334 = 0x80240694
export const RAM_func_8024019C_BBB51C = 0x8024019c
export const RAM_b_area_kmr_part_2_blue_goomba_2_sinTable = 0x8021df84
export const RAM_sbk_30_StationaryAI_IdleInit = 0x80240370
export const RAM_battle_move_hammer_charge_2_IsBerserkerEquipped = 0x802a1000
export const RAM_func_800561C4 = 0x800561c4
export const RAM_jan_03_ToadHouse_AwaitScriptComplete = 0x802415f4
export const RAM_D_E00D8818 = 0xe00d8818
export const RAM_b_area_trd_part_2_trd_05b_80233608 = 0x80233608
export const RAM_arn_08_settings = 0x802402c0
export const RAM_func_802670C8 = 0x802670c8
export const RAM_battle_item_strange_cake2_pal = 0x802a2590
export const RAM_D_802DAE54 = 0x802dae54
export const RAM_b_area_nok_goomba_init_802198EC = 0x802198ec
export const RAM_battle_item_mystery_png = 0x802a1f10
export const RAM_b_area_iwa_cleft_handleEvent_80218C3C = 0x80218c3c
export const RAM_vtx_C2D660 = 0x8020c720
export const RAM_sam_02_Quizmo_NPC_OnRender = 0x80240ef8
export const RAM_b_area_kzn_formation_01 = 0x802297c8
export const RAM_dup4_b_area_flo_UnkBattleFunc1 = 0x80218dc4
export const RAM_func_80240040_907A70 = 0x80240040
export const RAM_flo_10_802415C4 = 0x802415c4
export const RAM_pause_update_page_inactive_1 = 0x802432e8
export const RAM_flo_14_ItemChoice_SaveSelected = 0x80242288
export const RAM_world_bombette_can_use_ability = 0x802bd720
export const RAM_ShowVariableMessageBox = 0x8025130c
export const RAM_CloseChoicePopup = 0x802d6954
export const RAM_vtx_C2DBE0 = 0x8020cca0
export const RAM_battle_item_shooting_star_func_802A123C_71D9AC = 0x802a123c
export const RAM_mim_02_GetNpcCollisionHeight = 0x80240e84
export const RAM_pra_13_reflection_setup_floor = 0x80240500
export const RAM_HES_Item_PowerSmash1_disabled = 0x80084d28
export const RAM_HES_Item_SuperSoda_disabled = 0x80086948
export const RAM_dgb_01_D_8025202C_C0F8AC = 0x8025202c
export const RAM_D_E00963E0 = 0xe00963e0
export const RAM_dgb_05_ClubbaNappingAI_Init = 0x8024067c
export const RAM_guMtxIdentF = 0x800674a0
export const RAM_b_area_kmr_part_3_egg_jr_troopa_nextTurn_80225B4C = 0x80225b4c
export const RAM_attack_result_text_update = 0xe00901c8
export const RAM_D_B0000010 = 0xb0000010
export const RAM_dgb_03_80243470 = 0x80243470
export const RAM_kpa_03_TackleAI_Main = 0x802404f4
export const RAM_gCollisionDataZoneBackup = 0x800a4268
export const RAM_action_update_use_spinning_flower = 0x802b60a4
export const RAM_dgb_03_npcGroup_802441C8 = 0x802441c8
export const RAM_func_80240020_9AE720 = 0x80240020
export const RAM_iwa_03_CleftAI_Tackle = 0x80240b74
export const RAM_b_area_kzn_kzn_04c_802289CC = 0x802289cc
export const RAM_HES_Item_FlowerFanatic_disabled = 0x80084ba8
export const RAM_mdl_create_model = 0x8011620c
export const RAM_b_area_hos_magikoopa_UnkBattleFunc1 = 0x802182d4
export const RAM_HES_Item_Unused_12E_disabled = 0x80086648
export const RAM_D_8024A5AC_B3F17C = 0x8024a5ac
export const RAM_b_area_kmr_part_3_spiked_goomba_idleAnimations_8021A494 = 0x8021a494
export const RAM_create_shadow_from_data = 0x80111bd8
export const RAM_b_area_omo_anti_guy_idleAnimations_80221A14 = 0x80221a14
export const RAM_Entity_BrickBlock = 0x802ea0c4
export const RAM_GetIdleGoal = 0x8026a8ec
export const RAM_arn_10_npcAI_802404AC = 0x802404ac
export const RAM_shim_guMtxL2F = 0xe0200470
export const RAM_pra_35_ReflectPartner = 0x80240d3c
export const RAM_pra_05_reflection_setup_wall = 0x80240128
export const RAM_dup3_b_area_kpa2_StartRumbleWithParams = 0x8021a2c0
export const RAM_Entity_InertYellowBlock = 0x802ea07c
export const RAM_HES_Item_Unused_0E5 = 0x800859b8
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idle_8022DD20 = 0x8022dd20
export const RAM_b_area_omo2_5_shy_squad_dup_set_squad_animation = 0x80233da4
export const RAM_b_area_kzn2_petit_piranha_SetFlameUnk2C = 0x80218d40
export const RAM_b_area_pra3_duplighost_sushie_statusTable = 0x802238c0
export const RAM_CreatePushBlockGrid = 0x802832e0
export const RAM_D_E0076EB0 = 0xe0076eb0
export const RAM_b_area_trd_part_2_red_ninja_koopa_takeTurn_8022B474 = 0x8022b474
export const RAM_D_8024F230_A39470 = 0x8024f230
export const RAM_big_snowflakes_appendGfx = 0xe0060450
export const RAM_func_80048F0C = 0x80048f0c
export const RAM_D_80232C9C_684A1C = 0x80232c9c
export const RAM_nuAuDmaIOMesgBuf = 0x800a3720
export const RAM_D_8029D898 = 0x8029d898
export const RAM_tik_23_Pipe_GetCurrentFloor = 0x80240020
export const RAM_kpa_70_TackleAI_Main = 0x802406a4
export const RAM_battle_move_jump_charge_2_UnkMoveFunc1 = 0x802a1000
export const RAM_func_80240310_A5A340 = 0x80240310
export const RAM_b_area_omo_groove_guy_802271A0 = 0x802271a0
export const RAM_entity_WoodenCrate_update_fragments = 0x802e7fa0
export const RAM_end_01_CharAnim_FadeIn_4 = 0x80240af8
export const RAM_func_800EF3E4 = 0x800ef3e4
export const RAM_D_8024F720_C0CFA0 = 0x8024f720
export const RAM_D_80242558_A4EF78 = 0x80242558
export const RAM_HES_PoisonedLoop = 0x80105bf8
export const RAM_func_80240D40_C77A20 = 0x80240d40
export const RAM_D_802EB298 = 0x802eb298
export const RAM_vtx_curtains1 = 0x80075920
export const RAM_ClearStatusEffects = 0x8027dda0
export const RAM_dro_01_Quizmo_SetVannaAnim_Clap = 0x80241f78
export const RAM_dgb_01_80245208 = 0x80245208
export const RAM_arn_03_pad_47DC = 0x802447dc
export const RAM_D_090000A0_32ECF0 = 0x090000a0
export const RAM_func_80053AC8 = 0x80053ac8
export const RAM_EC9D00_SuperBlock_CountEligiblePartners = 0x80240120
export const RAM_b_area_pra2_crystal_bit_idleAnimations_2 = 0x8022048c
export const RAM_set_npc_shadow_scale = 0x80112900
export const RAM_omo_17_GrooveGuyAI_02 = 0x80240acc
export const RAM_battle_item_thunder_rage_func_802A12D4_71B474 = 0x802a12d4
export const RAM_flo_12_func_80240000_CC0E30 = 0x80240000
export const RAM_D_09001180 = 0x09001180
export const RAM_D_E020071C = 0xe020071c
export const RAM_D_802313D8_63CF78 = 0x802313d8
export const RAM_entity_BlueWarpPipe_enter_pipe_init = 0x802e8ccc
export const RAM_world_parakarry_pre_battle = 0x802be90c
export const RAM_ui_battle_status_transparent_0_png = 0x80102d60
export const RAM_bcmp = 0x8006b870
export const RAM_fx_46_update = 0xe008c3fc
export const RAM_b_area_kzn_white_magikoopa_idleAnimations_80223DBC = 0x80223dbc
export const RAM_D_8024E738 = 0x8024e738
export const RAM_b_area_mac_lee_idleAnimations_8021D360 = 0x8021d360
export const RAM_sam_06_LetterDelivery_SavedNpcAnim = 0x8024c1d8
export const RAM_func_80240BD4_C8E784 = 0x80240bd4
export const RAM_dro_02_D_80248D34_971EF4 = 0x80248d34
export const RAM_nok_11_foliage_setup_shear_mtx = 0x80241ccc
export const RAM_b_area_sam_frost_piranha_idle = 0x8022ac5c
export const RAM_D_800A15C4 = 0x800a15c4
export const RAM_kpa_10_UpdateTexturePanSmooth = 0x80240000
export const RAM_CreateEntityVarArgBuffer3 = 0x8015c7dc
export const RAM_sam_01_Quizmo_SetVannaAnim_Wave = 0x80241224
export const RAM_world_goombario_init = 0x802bd174
export const RAM_b_area_jan_jungle_fuzzy_partsTable_8021EA90 = 0x8021ea90
export const RAM_fire_flower_init = 0xe007e1fc
export const RAM_func_80242A14_EA3314 = 0x80242a14
export const RAM_bcopy = 0x80064650
export const RAM_dro_02_8024B8A0 = 0x8024b8a0
export const RAM_D_800777EC = 0x800777ec
export const RAM_b_area_mim_formation_0F = 0x8021e990
export const RAM_func_800E0658 = 0x800e0658
export const RAM_D_802EB290 = 0x802eb290
export const RAM_pra_19_AddPlayerHandsOffset = 0x80240f7c
export const RAM_b_area_trd_part_2_fake_bowser_8021D054 = 0x8021d054
export const RAM_NAMESPACE_8023A4F8 = 0x8023a4f8
export const RAM_bFogSettings = 0x80153248
export const RAM_tik_19_SuperBlock_RadiateFaintEnergyFX = 0x80240b98
export const RAM_D_8024F290_C0CB10 = 0x8024f290
export const RAM_sleep_bubble_render = 0xe005241c
export const RAM_basic_ai_loiter_init = 0x80049b44
export const RAM_jan_02_Quizmo_SetStageLightsDelay = 0x80242050
export const RAM_render_animated_models = 0x802ccb18
export const RAM_b_area_trd_part_1_bob_omb_takeTurn_8021C89C = 0x8021c89c
export const RAM_func_80241668_B857E8 = 0x80241668
export const RAM_dro_02_EVS_Quizmo_ResetCamera = 0x8024521c
export const RAM_D_80234780_6BB980 = 0x80234780
export const RAM_dro_02_makeEntities = 0x80243bd0
export const RAM_b_area_trd_part_2_fake_bowser_8021B864 = 0x8021b864
export const RAM_get_item_count = 0x800e75ec
export const RAM_D_80295744 = 0x80295744
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_defenseTable_8022E44C = 0x8022e44c
export const RAM_vtx_C2B3C0 = 0x8020a480
export const RAM_COPY_set_defeated = 0x8003e2e0
export const RAM_mac_00_Quizmo_SetStageLightsDelay = 0x8024106c
export const RAM_step_entity_model_commandlist = 0x80120fb8
export const RAM_bombette_breaking_init = 0xe0084658
export const RAM_D_8024F2EC = 0x8024f2ec
export const RAM_memcpy = 0x80064a74
export const RAM_b_area_hos_magikoopa_takeTurn_80223B24 = 0x80223b24
export const RAM_reset_battle_status = 0x80072b30
export const RAM_nok_02_Quizmo_AudienceEffect = 0x802549c4
export const RAM_func_800E0208 = 0x800e0208
export const RAM_green_impact_render = 0xe00643dc
export const RAM_pause_tutorial_draw_contents = 0x802437f4
export const RAM_D_802AD0EB = 0x802ad0eb
export const RAM_D_8010CFD4 = 0x8010cfd4
export const RAM_fx_shiny_flare = 0x80072170
export const RAM_omo_07_PatrolNoAttackAI_15 = 0x80241040
export const RAM_b_area_hos_ember_8021B2FC = 0x8021b2fc
export const RAM_clear_encounter_status = 0x8003e400
export const RAM_D_09003580 = 0x09003580
export const RAM_SetActorRotationOffset = 0x8026c4ac
export const RAM_arn_04_FlyingAI_LosePlayer = 0x80241ce8
export const RAM_arn_09_func_80240000_BF6060 = 0x80240000
export const RAM_tik_09_TackleAI_Main = 0x80240dc4
export const RAM_func_800457C4 = 0x800457c4
export const RAM_D_802B6240 = 0x802b6240
export const RAM_D_802BFF20 = 0x802bff20
export const RAM_dro_02_EVS_Quizmo_Exit = 0x80244ed4
export const RAM_omo_11_SuperBlock_WhiteScreenFlash = 0x802412c8
export const RAM_D_80155D8E = 0x80155d8e
export const RAM_GetCurrentPartner = 0x802d2b0c
export const RAM_b_area_isk_part_1_isk_02c_foregroundModelList_80223F04 = 0x80223f04
export const RAM_D_09001C10_351E30 = 0x09001c10
export const RAM_dup2_b_area_flo_ShrinkActor = 0x80218c40
export const RAM_au_fade_update = 0x80053a28
export const RAM_b_area_pra3_gray_magikoopa_idleAnimations1 = 0x8022f030
export const RAM_flo_11_Pipe_GetEntryPos = 0x802400f0
export const RAM_D_09002F30 = 0x09002f30
export const RAM_D_8014BCF8 = 0x8014bcf8
export const RAM_D_80242D60_918AD0 = 0x80242d60
export const RAM_jan_02_Quizmo_VannaTEffect = 0x80248948
export const RAM_D_E01108C8 = 0xe01108c8
export const RAM_D_80077A28 = 0x80077a28
export const RAM_dro_02_D_8024F010 = 0x8024f010
export const RAM_func_802C031C = 0x802bd41c
export const RAM_clear_screen_overlays = 0x80138108
export const RAM_flo_10_80241CE4 = 0x80241ce4
export const RAM_peach_star_beam_render = 0xe010c354
export const RAM_b_area_trd_part_3_eldstar_init_80218390 = 0x80218390
export const RAM_D_80249DCC = 0x80249dcc
export const RAM_dgb_04_SuperBlock_CountEligiblePartners = 0x802424f0
export const RAM_IES_GearBoots3 = 0x8008b424
export const RAM_b_area_nok_kent_c_koopa_UnkBattleFunc1 = 0x80218ba8
export const RAM_D_8029DA10 = 0x8029da10
export const RAM_D_8029D970 = 0x8029d970
export const RAM_hos_03_Quizmo_GetItemName = 0x80242558
export const RAM_b_area_mim_bzzap_handleEvent_8021ACA8 = 0x8021aca8
export const RAM_D_E0044E78 = 0xe0044e78
export const RAM_b_area_isk_part_2_tutankoopa_8021F42C = 0x8021f42c
export const RAM_flo_18_80245004 = 0x80245004
export const RAM_func_80240CF8_B21238 = 0x80240cf8
export const RAM_DoBlowAway = 0x8029c4a8
export const RAM_D_8026FF80 = 0x8026ff80
export const RAM_action_command_body_slam_MashActionCommandInit = 0x802a91b8
export const RAM_battle_move_multibounce_D_802A2230 = 0x802a2230
export const RAM_gPauseMapMarioX = 0x8027070c
export const RAM_arn_10_defeat_802406F4 = 0x802406f4
export const RAM_battle_move_multibounce_D_802A2600 = 0x802a2600
export const RAM_nok_02_Quizmo_GetCamVfov = 0x802424f0
export const RAM_flo_00_entryList = 0x802413e0
export const RAM_bind_npc_interact = 0x8003ec1c
export const RAM_sfx_update_looping_sound_params = 0x80149734
export const RAM_D_E01108C0 = 0xe01108c0
export const RAM_guMtxCatL = 0x80067960
export const RAM_CamPresetUpdate_H = 0x8024afe4
export const RAM_b_area_hos_magikoopa_boostAttack = 0x80221dc0
export const RAM_spr_unpack_signed_12bit = 0x802dd0dc
export const RAM_GetIndexFromPos = 0x8026a05c
export const RAM_D_802432F0_A86780 = 0x802432f0
export const RAM_NextVolumeUpdateTimer = 0x8014f738
export const RAM_IES_HoneySuper = 0x8008d254
export const RAM_osRecvMesg = 0x800656f0
export const RAM_D_80244204_C64F14 = 0x80244204
export const RAM_gAnimRotMtx = 0x80153a68
export const RAM_battle_item_unknown_item_GiveRefundCleanup = 0x802a11d4
export const RAM_sam_01_CheckItemFlags40 = 0x80241ef8
export const RAM_kpa_51_MagikoopaAI_OnPlayerWon = 0x80241328
export const RAM_D_E00C8738 = 0xe00c8738
export const RAM_tik_21_Pipe_GetPointAheadOfPlayer = 0x80240548
export const RAM_EDC020_varStash = 0x802417ec
export const RAM_HES_SmallText3 = 0x80242174
export const RAM_mim_06_MeleeHitbox_Main = 0x8024092c
export const RAM_b_area_pra2_crystal_king = 0x80218b18
export const RAM_b_area_omo2_6_shy_guy_dup_idleAnimations = 0x80231224
export const RAM_b_area_hos_magikoopa_vanish = 0x802233dc
export const RAM_flo_18_802409B8 = 0x802409b8
export const RAM_func_80251474 = 0x80251474
export const RAM_b_area_pra3_duplighost_bombette_parts = 0x8021f4b4
export const RAM_energy_shockwave_render = 0xe007238c
export const RAM_HES_Item_HammerThrow_disabled = 0x800842a8
export const RAM_IES_Spaghetti = 0x8008d2b4
export const RAM_b_area_kmr_part_2_red_goomba_doDeath_8021D0C4 = 0x8021d0c4
export const RAM_b_area_trd_part_1_bill_blaster_8021A470 = 0x8021a470
export const RAM_b_area_kzn_kzn_04b_8022843C = 0x8022843c
export const RAM_vtx_C26AE0 = 0x80205ba0
export const RAM_b_area_isk_part_1_isk_06b = 0x80223fb8
export const RAM_arn_03_makeEntities = 0x802447e0
export const RAM_iwa_04_CleftAI_CanSeePlayer = 0x80240080
export const RAM_battle_item_super_soda_GiveRefundCleanup = 0x802a11d4
export const RAM_big_snowflakes_main = 0xe0060000
export const RAM_D_09000CA8 = 0x09000ca8
export const RAM_b_area_isk_part_1_isk_02c = 0x80223f14
export const RAM_gWorldScriptList = 0x802da490
export const RAM_flo_10_settings = 0x802414a0
export const RAM_b_area_isk_part_2_isk_02b_afterBattle_8021FF34 = 0x8021ff34
export const RAM_b_area_sam_duplighost_kooper_init = 0x8021e824
export const RAM_isk_18_set_script_owner_npc_col_height = 0x80240314
export const RAM_arn_05_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_D_800DC4D4 = 0x800dc4d4
export const RAM_vtx_C26290 = 0x80205350
export const RAM_b_area_dgb_tubba_blubba_init_80218D70 = 0x80218d70
export const RAM_D_80150090 = 0x80150090
export const RAM_flo_03_npcAI_8024119C = 0x8024119c
export const RAM_b_area_kzn_kzn_01b_beforeBattle_80227820 = 0x80227820
export const RAM_HES_Item_FlowerSaver_disabled = 0x80084ae8
export const RAM_b_area_kmr_part_3_kmr_05_afterBattle_80232FA0 = 0x80232fa0
export const RAM_sun_appendGfx = 0xe01203e4
export const RAM_gMusicSettings = 0x80159af0
export const RAM_flo_14_BuildItemChoiceList = 0x802422c0
export const RAM_shim_transform_point = 0xe02005a0
export const RAM_sbk_56_SuperBlock_UpgradeDescMessages = 0x80241448
export const RAM_pra_12_GetItemName = 0x80241004
export const RAM_player_test_move_with_slipping = 0x800df460
export const RAM_jan_08_SuperBlock_RadiateFaintEnergyFX = 0x80240c08
export const RAM_func_80240754_D3AD24 = 0x80240754
export const RAM_btl_state_draw_victory = 0x80244708
export const RAM_nok_maps = 0x80091150
export const RAM_func_E0088000 = 0xe0088000
export const RAM_b_area_omo2_2_stilt_guy_defenseTable = 0x8023118c
export const RAM_func_802403C0_800C40 = 0x802403c0
export const RAM_osDestroyThread = 0x8006c950
export const RAM_flo_14_D_80243B14_CCEE24 = 0x80243b14
export const RAM_dro_01_npcSettings_80245060 = 0x80245060
export const RAM_D_E010E7D8 = 0xe010e7d8
export const RAM_battle_move_shell_crack_IsBerserkerEquipped = 0x802a1000
export const RAM_D_802E9A40 = 0x802e9a40
export const RAM_D_80098390 = 0x80098390
export const RAM_tik_25_UnkPosFunc2 = 0x8024030c
export const RAM_D_8024F2A0 = 0x8024f2a0
export const RAM_HES_Item_MegaRush_disabled = 0x80084668
export const RAM_func_80218280_6609D0 = 0x80218280
export const RAM_arn_09_main = 0x802402c0
export const RAM_func_80240520_EB7750 = 0x80240520
export const RAM_mim_09_HoppingAI_Main = 0x80240c90
export const RAM_IES_ToyTrain = 0x8008b764
export const RAM_func_80240420_DDF730 = 0x80240420
export const RAM_D_09002180_3523A0 = 0x09002180
export const RAM_flo_21_intTable_8024262C = 0x8024262c
export const RAM_ui_status_pow_unit_1_pal = 0x80100720
export const RAM_func_8004C300 = 0x8004c300
export const RAM_HES_Item_Hammer2 = 0x800806d0
export const RAM_create_status_static = 0x80047928
export const RAM_merlin_house_stars_appendGfx = 0xe00a63a4
export const RAM_D_8029FAE8 = 0x8029fae8
export const RAM_sam_02_ToadHouse_AwaitScriptComplete = 0x80240974
export const RAM_func_80240000_91B8E0 = 0x80240000
export const RAM_get_fortress_key_count = 0x800ea3e4
export const RAM_check_input_midair_jump = 0x800e4fcc
export const RAM_gCollisionRayStartZ = 0x800a4238
export const RAM_b_area_trd_part_2_fake_bowser_80221DB4 = 0x80221db4
export const RAM_action_command_body_slam_CreateHudElements = 0x802a9000
export const RAM_mac_05_UpdateTexturePanSmooth = 0x80240000
export const RAM_nok_01_StationaryAI_Alert = 0x80240234
export const RAM_b_area_omo2_2_stilt_guy_idleAnimations3 = 0x80231174
export const RAM_ItemDamageEnemy = 0x80252728
export const RAM_nok_01_Quizmo_NPC_OnRender = 0x80241134
export const RAM_au_sfx_set_bits_C00 = 0x8004c268
export const RAM_vtx_C2A700 = 0x802097c0
export const RAM_IES_CompleteCake = 0x8008b124
export const RAM_filemenu_choose_name_update = 0x80249324
export const RAM_D_80244438_D242C8 = 0x80244438
export const RAM_dup2_b_area_pra_ShrinkActor = 0x802189e0
export const RAM_trd_07_FlyingAI_JumpInit = 0x802416c0
export const RAM_evt_handle_suspend_others = 0x802c69a8
export const RAM_dgb_01_D_80252AA8_C10328 = 0x80252aa8
export const RAM_nuContPakMgrInit = 0x8005f6b0
export const RAM_func_802A963C = 0x802a963c
export const RAM_ui_input_analog_stick_pal = 0x800f8ec0
export const RAM_b_area_isk_part_1_swooper_handleEvent_8021D064 = 0x8021d064
export const RAM_dup_b_area_flo_ShrinkActor = 0x802188d0
export const RAM_NAMESPACE_8023A5F8 = 0x8023a5f8
export const RAM_battle_partner_kooper_80239E7C = 0x80239e7c
export const RAM_draw_entity_model_D = 0x80122188
export const RAM_func_802C39F8 = 0x802c39f8
export const RAM_D_E002A720 = 0xe002a720
export const RAM_b_area_pra2_crystal_king_NormalAttack = 0x8021f6b0
export const RAM_func_802A94D8 = 0x802a94d8
export const RAM_b_area_sam_duplighost_idleAnimations2 = 0x8021aedc
export const RAM_b_area_kmr_part_2_goomnut_tree_tree_trunk = 0x80223d50
export const RAM_dead_basic_ai_wander = 0x8004dad8
export const RAM_D_8029F258 = 0x8029f258
export const RAM_D_802E9738 = 0x802e9738
export const RAM_kpa_113_set_script_owner_npc_anim = 0x802401b0
export const RAM_func_80219E00_5B32B0 = 0x80219e00
export const RAM_flo_18_intTable_80242DC8 = 0x80242dc8
export const RAM_D_E00AC880 = 0xe00ac880
export const RAM_msg_reset_gfx_state = 0x8012c2e0
export const RAM_dgb_10_func_8024013C_C4C4CC = 0x8024013c
export const RAM_D_09000FA0_3273B0 = 0x09000fa0
export const RAM_does_script_exist = 0x802c4164
export const RAM_update_shadow_transform_matrix = 0x80110374
export const RAM_npc_draw_with_palswap = 0x8003b47c
export const RAM_HES_HPDigit6 = 0x80292098
export const RAM_flo_13_pad_3944 = 0x80243944
export const RAM_osEPiRawStartDma = 0x80060b70
export const RAM_D_800B0B18 = 0x800b0b18
export const RAM_D_8029FBD4 = 0x8029fbd4
export const RAM_b_area_iwa_formation_02 = 0x80218264
export const RAM_D_80244868_BE35F8 = 0x80244868
export const RAM_D_8010D693 = 0x8010d693
export const RAM_D_8010D656 = 0x8010d656
export const RAM_dup_kmr_20_Pipe_GetCurrentFloor = 0x802417a0
export const RAM_TwinkTakeOut = 0x802bd110
export const RAM_dead_get_enemy = 0x80042af4
export const RAM_b_area_arn_tubba_blubba_80225FD4 = 0x80225fd4
export const RAM_D_8014B938 = 0x8014b938
export const RAM_nok_14_FlyingAI_WanderInit = 0x80240994
export const RAM_dgb_01_D_802523F4_C0FC74 = 0x802523f4
export const RAM_D_80234848_6BBA48 = 0x80234848
export const RAM_sam_05_PiranhaPlantAI_13 = 0x80241608
export const RAM_func_80219474_6BD004 = 0x80219474
export const RAM_flo_10_80241DB0 = 0x80241db0
export const RAM_func_80269600 = 0x80269600
export const RAM_iwa_03_CleftAI_RevUpInit = 0x80240984
export const RAM_D_E00726B0 = 0xe00726b0
export const RAM_nok_12_PatrolAI_Loiter = 0x80241204
export const RAM_HES_TimingCharge3 = 0x802928cc
export const RAM_kpa_95_PatrolAI_ChaseInit = 0x802408fc
export const RAM_D_8024F2A8 = 0x8024f2a8
export const RAM_D_80231470_63D010 = 0x80231470
export const RAM_D_E0016740 = 0xe0016740
export const RAM_dgb_15_PatrolAI_ChaseInit = 0x802410ec
export const RAM_check_for_interactables = 0x800e0818
export const RAM_D_E00B2BA0 = 0xe00b2ba0
export const RAM_b_area_omo_groove_guy_handleEvent_80224D10 = 0x80224d10
export const RAM_msg_draw_prim_rect = 0x8012c9a8
export const RAM_nuContDataLock = 0x800608c0
export const RAM_D_802437BC_BFD85C = 0x802437bc
export const RAM_b_area_isk_part_1_formation_0E = 0x802244f4
export const RAM_D_8029DA18 = 0x8029da18
export const RAM_D_8029D978 = 0x8029d978
export const RAM_EDC020_GetItemName = 0x80240330
export const RAM_func_802406A0_AF7FF0 = 0x802406a0
export const RAM_battle_star_up_and_away_UnkStarFunc4 = 0x802a1218
export const RAM_flo_19_80241C58 = 0x80241c58
export const RAM_ui_unused_1_pal = 0x800fc820
export const RAM_fx_3D_appendGfx = 0xe007a88c
export const RAM_b_area_mac_lee_bombette = 0x802223b8
export const RAM_dgb_15_PatrolAI_Move = 0x80240a28
export const RAM_D_E0116C6C = 0xe0116c6c
export const RAM_battle_move_auto_multibounce_D_802A1FEC = 0x802a1fec
export const RAM_b_area_omo2_5_shy_squad_dup_takeTurn = 0x802342a0
export const RAM_b_area_trd_part_1_trd_02b_beforeBattle_80223A24 = 0x80223a24
export const RAM_b_area_tik_CalculateRotationZ = 0x8021838c
export const RAM_bgm_set_variation = 0x8014abb4
export const RAM_D_E00287F8 = 0xe00287f8
export const RAM_flo_24_UpdateTexturePanStepped = 0x802403a4
export const RAM_b_area_kmr_part_2_goomba_king = 0x80220a10
export const RAM_D_802A17C4 = 0x802a17c4
export const RAM_arn_03_init_80243F44 = 0x80243f44
export const RAM_hos_03_FlyingAI_LosePlayer = 0x80241b08
export const RAM_entity_GiantChest_init = 0x802e7d54
export const RAM_DropItemEntity = 0x802d6dc0
export const RAM_battle_item_electro_pop_main = 0x802a1afc
export const RAM_mac_04_Quizmo_AudienceEffect = 0x8024f5c4
export const RAM_b_area_mac_formation_00 = 0x802329d0
export const RAM_IES_PulseStone = 0x8008b5c4
export const RAM_D_800FBB60 = 0x800fbb60
export const RAM_D_800A0F40 = 0x800a0f40
export const RAM_dgb_04_80243578 = 0x80243578
export const RAM_evt_get_variable_index_alt = 0x802c7f20
export const RAM_arn_05_exitWalk_8024148C = 0x8024148c
export const RAM_jan_03_PatrolAI_Jump = 0x80240dc4
export const RAM_dgb_18_varStash = 0x802436e0
export const RAM_D_09002E70 = 0x09002e70
export const RAM_D_80243BCE_A1ECCE = 0x80243bce
export const RAM_battle_star_lullaby_UnkBackgroundFunc2 = 0x802a137c
export const RAM_battle_partner_kooper_runAwayFail = 0x80238ab8
export const RAM_appendGfx_title_screen = 0x80037638
export const RAM_D_09001558 = 0x09001558
export const RAM_func_80244BC4 = 0x80244bc4
export const RAM_kzn_02_FlyingNoAttackAI_13 = 0x80241b08
export const RAM_kzn_08_FlyingNoAttackAI_13 = 0x80242078
export const RAM_kpa_70_set_script_owner_npc_col_height = 0x80240624
export const RAM_mac_03_AddPlayerHandsOffset = 0x802415a0
export const RAM_guPosition = 0x800640e8
export const RAM_gCrashScreenFont = 0x80077290
export const RAM_battle_item_super_soda_func_802A1378_725058 = 0x802a1378
export const RAM_func_8026E260 = 0x8026e260
export const RAM_WaitForPlayerMoveToComplete = 0x802d24f4
export const RAM_explosion_appendGfx = 0xe003245c
export const RAM_b_area_sam_sam_02b = 0x8022fb48
export const RAM_ui_battle_cmd_aim_shimmer_7_png = 0x8028eee0
export const RAM_kmr_02_Quizmo_ShowEntities = 0x802404bc
export const RAM_battle_star_time_out_UnkStarFunc3 = 0x802a116c
export const RAM_flo_19_80240AC0 = 0x80240ac0
export const RAM_flo_00_func_80240000_C9DF20 = 0x80240000
export const RAM_ui_battle_status_static_1_png = 0x80101f00
export const RAM_D_8010948C = 0x8010948c
export const RAM_b_area_omo_omo_06_beforeBattle_8022F790 = 0x8022f790
export const RAM_func_802A91E0_423E50 = 0x802a91e0
export const RAM_D_802B7640_E21EB0 = 0x802b7640
export const RAM_pra_34_SetPartnerFlags20000 = 0x80240eec
export const RAM_shim_rand_int = 0xe02004e0
export const RAM_D_80231578_63D118 = 0x80231578
export const RAM_D_802A3F58 = 0x802a3f58
export const RAM_set_peach_shadow_scale = 0x801129d4
export const RAM_omo_02_GetEncounterEnemyIsOwner = 0x80242b70
export const RAM_has_valid_conversation_npc = 0x800e0538
export const RAM_flo_19_802423F8 = 0x802423f8
export const RAM_au_BGMCmd_EA_SubTrackPan = 0x8004ff3c
export const RAM_dro_02_func_802416FC_96A8BC = 0x802416fc
export const RAM_jan_03_Quizmo_SetCamVfov = 0x80242708
export const RAM_CircularShadowB = 0x802e98e0
export const RAM_flo_09_802418F0 = 0x802418f0
export const RAM_vtx_C2CED0 = 0x8020bf90
export const RAM_vtx_C23E50 = 0x80202f10
export const RAM_D_09000BC8 = 0x09000bc8
export const RAM_DoStopHit = 0x80294650
export const RAM_kpa_61_UpdateTexturePanStepped = 0x80240124
export const RAM_func_80056E34 = 0x80056e34
export const RAM_func_8025C918 = 0x8025c918
export const RAM_mdl_bgMultiplyColorA = 0x8014b74c
export const RAM_action_update_talk = 0x802b6638
export const RAM_nok_01_foliage_setup_shear_mtx = 0x80242930
export const RAM_HES_Item_AttackFXD_disabled = 0x80085bc8
export const RAM_is_status_menu_visible = 0x800e9910
export const RAM_D_8010EF92 = 0x8010ef92
export const RAM_D_80248470_9269E0 = 0x80248470
export const RAM_dgb_18_StashVars = 0x80240fb0
export const RAM_b_area_kmr_part_2_kmr_04_clouds1 = 0x80224bb0
export const RAM_ui_battle_cmd_charge_4_png = 0x8028cf60
export const RAM_flo_21_StarSpiritEffectFunc1 = 0x80240000
export const RAM_D_8024EFB8 = 0x8024efb8
export const RAM_HES_StatusDigit4 = 0x80108158
export const RAM_sintable = 0x80093de0
export const RAM_D_80248CD0_82BF90 = 0x80248cd0
export const RAM_D_802447B0_B97BF0 = 0x802447b0
export const RAM_arn_04_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_nok_14_FlyingAI_Chase = 0x80241788
export const RAM_toy_tank_as_7 = 0x8022ae48
export const RAM_dro_02_ToadHouse_PartnerResumeAbilityScript = 0x80243624
export const RAM_gWorldMapVars = 0x802dbca8
export const RAM_func_8004E844 = 0x8004e844
export const RAM_D_09001D40_351F60 = 0x09001d40
export const RAM_flo_19_func_80240340_CE3A30 = 0x80240340
export const RAM_damage_indicator_render = 0xe003c474
export const RAM_b_area_pra3_red_magikoopa_8022C3BC = 0x8022c3bc
export const RAM_b_area_mac_lee_partsTable_8021D47C = 0x8021d47c
export const RAM_IES_PowerRush = 0x8008c704
export const RAM_D_80231380_63CF20 = 0x80231380
export const RAM_gAnimModelFogA = 0x80153a54
export const RAM_wPartnerMoveTime = 0x800f802c
export const RAM_D_80075F20 = 0x80075f20
export const RAM_b_area_iwa_stageTable = 0x802186e4
export const RAM_dup_b_area_tik_StartRumbleWithParams = 0x80218700
export const RAM_D_80247960_DF6800 = 0x80247960
export const RAM_jan_03_Quizmo_GetCamVfov = 0x8024277c
export const RAM_UseIdleAnimation = 0x8026f0ec
export const RAM_D_E0092690 = 0xe0092690
export const RAM_D_E00A8690 = 0xe00a8690
export const RAM_b_area_nok_goomba_partsTable_80219808 = 0x80219808
export const RAM_EC9D00_SuperBlock_EndGlowEffect = 0x80240510
export const RAM_battle_item_mystery_main = 0x802a22bc
export const RAM_pra_37_reflection_render_floor = 0x802406bc
export const RAM_iwa_04_CleftAI_HidingInit = 0x802401e0
export const RAM_kmr_03_pad_948 = 0x80240948
export const RAM_arn_08_func_802400D4_BF4874 = 0x802400d4
export const RAM_CamPreset_F = 0x80280d04
export const RAM_D_80242F50_A66FE0 = 0x80242f50
export const RAM_pra_16_reflection_render_floor_fancy = 0x80240870
export const RAM_get_entity_model = 0x80122ddc
export const RAM_pra_33_SetGameStatusUnk84_1 = 0x80240000
export const RAM_tik_12_SuperBlock_AnimateEnergyOrbs = 0x8024121c
export const RAM_b_area_sam2_sam_02b_Set80071270_0_16 = 0x802193b8
export const RAM_D_800A0F48 = 0x800a0f48
export const RAM_flo_14_80242C00 = 0x80242c00
export const RAM_iwa_03_CleftAI_Disguise = 0x80240f24
export const RAM_D_80099BA8 = 0x80099ba8
export const RAM_kmr_11_foliage_setup_shear_mtx = 0x80240000
export const RAM_sbk_56_SuperBlock_PartnerSparkles4 = 0x80240d40
export const RAM_kzn_09_SuperBlock_PartnerIDs = 0x80243d08
export const RAM_dgb_01_D_80252948_C101C8 = 0x80252948
export const RAM_D_80249984_A33BC4 = 0x80249984
export const RAM_sam_03_MeleeHitbox_31 = 0x802400bc
export const RAM_b_area_sam2_sam_02c_UnkModelStuff_array = 0x80222990
export const RAM_gBattleAreas = 0x80095a30
export const RAM_flo_15_802413B0 = 0x802413b0
export const RAM_gfx_draw_background = 0x80027e10
export const RAM_smoke_ring_render = 0xe002e330
export const RAM_vtx_C2D140 = 0x8020c200
export const RAM_HES_Item_SmashCharge = 0x80083eb8
export const RAM_D_09003358 = 0x09003358
export const RAM_ui_move_partner_1_disabled_pal = 0x80103d00
export const RAM_dgb_04_SuperBlock_UpgradeDescMessages = 0x80243d48
export const RAM_b_area_kmr_part_1_paragoomba_8021CB94 = 0x8021e584
export const RAM_npc_do_player_collision = 0x800392d8
export const RAM_ui_battle_status_shrink_0_png = 0x80102ae0
export const RAM_D_80241AE0_BDEC90 = 0x80241ae0
export const RAM_gPausePartnersRotAngle = 0x802706a8
export const RAM_D_802DF540 = 0x802df540
export const RAM_b_area_kzn_red_magikoopa_8022C424 = 0x80221164
export const RAM_nok_02_LetterDelivery_Init = 0x80242cf8
export const RAM_func_802183A4_660AF4 = 0x802183a4
export const RAM_flo_16_FlyingAI_JumpInit = 0x80241a2c
export const RAM_dgb_05_func_80240000_C3AA10 = 0x80240000
export const RAM_quizmo_audience_render = 0xe00a82d4
export const RAM_vtx_C28D90 = 0x80207e50
export const RAM_star_outline_appendGfx = 0xe01264ec
export const RAM_dgb_12_entryList = 0x802402c0
export const RAM_dead_fx_misc_particles = 0x800775d0
export const RAM_func_80240DA4_EA9884 = 0x80240da4
export const RAM_D_80249A70_A33CB0 = 0x80249a70
export const RAM_mac_00_Quizmo_VannaTEffect = 0x80255b98
export const RAM_D_8023D2AC = 0x8023d2ac
export const RAM_D_802AD004 = 0x802ad004
export const RAM_D_09000A10_3642D0 = 0x09000a10
export const RAM_b_area_hos_hos_00_802255E0 = 0x802255e0
export const RAM_b_area_pra3_red_magikoopa = 0x8022c320
export const RAM_filemenu_set_selected = 0x80242d94
export const RAM_mac_00_Quizmo_CreateWorker = 0x80241694
export const RAM_IES_StinkyHerb = 0x8008da94
export const RAM_func_80241800_EAA2E0 = 0x80241800
export const RAM_flo_16_SuperBlock_RadiateFaintEnergyFX = 0x80240688
export const RAM_ui_status_text_0_png = 0x800fff80
export const RAM_mac_01_Quizmo_varStash = 0x80248c30
export const RAM_gBackgroundImage = 0x80200000
export const RAM_D_E00287F0 = 0xe00287f0
export const RAM_b_area_hos_magikoopa_idleAnimations_8021D768 = 0x8021d768
export const RAM_D_802BE0E4 = 0x802be0e4
export const RAM_flo_11_8024155C = 0x8024155c
export const RAM_b_area_sam_duplighost_bombette_handleEvent = 0x80220654
export const RAM_appendGfx_intro_logos = 0x800343a4
export const RAM_flo_25_80242330 = 0x80242330
export const RAM_jan_05_UpdateTexturePanStepped = 0x80240164
export const RAM_lightning_bolt_render = 0xe00bc2e8
export const RAM_b_area_kpa_UnkFunc52 = 0x80218638
export const RAM_D_80245D90_A994D0 = 0x80245d90
export const RAM_jan_02_Quizmo_SpinPartner = 0x80242494
export const RAM_kzn_03_SetCamera0MoveFlag1 = 0x80242d1c
export const RAM_throw_spiny_main = 0xe00c8000
export const RAM_kzn_09_func_80240814_97BE44 = 0x80243198
export const RAM_flo_13_80243950 = 0x80243950
export const RAM_func_800E0330 = 0x800e0330
export const RAM_func_80240420_B9B8A0 = 0x80240420
export const RAM_arn_07_itemList_80242040 = 0x80242040
export const RAM_HES_Item_Unused_089 = 0x80082238
export const RAM_func_802405FC_9AECFC = 0x802405fc
export const RAM_dgb_08_80243CF0 = 0x80243cf0
export const RAM_sam_01_AddPlayerHandsOffset = 0x8024036c
export const RAM_b_area_omo_omo_03b_beforeBattle_8022DCB0 = 0x8022dcb0
export const RAM_iwa_02_CleftAI_Hiding = 0x802401d4
export const RAM_D_E00D0B38 = 0xe00d0b38
export const RAM_jan_03_Quizmo_NPC_Aux_Impl = 0x80241f48
export const RAM_jan_06_MeleeHitbox_Main = 0x80241bd8
export const RAM_D_E0038A20 = 0xe0038a20
export const RAM_fx_chomp_drop = 0x80071570
export const RAM_b_area_pra3_swoopula_idle = 0x80227050
export const RAM_battle_item_egg_missile3_png = 0x802a1d10
export const RAM_dgb_01_D_802525D8_C0FE58 = 0x802525d8
export const RAM_b_area_omo2_1_shy_squad_next_phase = 0x80235d64
export const RAM_bulb_glow_main = 0xe0078000
export const RAM_kzn_09_LetterDelivery_SaveNpcAnim = 0x80243994
export const RAM_D_80247400_C68110 = 0x80247400
export const RAM_D_80251AA0_9D6E80 = 0x80251aa0
export const RAM_kmr_02_Quizmo_HideWorld = 0x802407bc
export const RAM_EA0C10_UnkNpcAIFunc12 = 0x80241f18
export const RAM_battle_action_cmd_whirlwind_3_pal = 0x802a9c80
export const RAM_BowUseAbility = 0x802bd694
export const RAM_arn_05_PatrolAI_LoiterInit = 0x80240400
export const RAM_kpa_102_ProjectileHitbox_GetUsableProjectileID = 0x80240310
export const RAM_D_802425E8_A4ABA8 = 0x802425e8
export const RAM_D_802BEBB8 = 0x802bebb8
export const RAM_HES_Item_CoinSparkleB = 0x80080b64
export const RAM_D_09000000_364C00 = 0x09000000
export const RAM_gPauseStatsGridData = 0x8024f3a0
export const RAM_D_09002B40 = 0x09002b40
export const RAM_fx_radiating_energy_orb = 0x80071630
export const RAM_hos_01_dup_GetFloorCollider = 0x80240dec
export const RAM_D_80098278 = 0x80098278
export const RAM_jan_12_PullVine_ShearBushModel = 0x802401cc
export const RAM_battle_item_mystery_PlayerGoHome = 0x802a1d80
export const RAM_arn_12_makeEntities = 0x80240c28
export const RAM_dgb_04_main = 0x8024343c
export const RAM_b_area_kmr_part_3_formation_03 = 0x8021a220
export const RAM_pra_36_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_hud_element_clear_flags = 0x80144a2c
export const RAM_D_8024C2E0_C09B60 = 0x8024c2e0
export const RAM_func_801180E8 = 0x801180e8
export const RAM_flo_16_FlyingAI_JumpVels = 0x80244010
export const RAM_b_area_kzn_kzn_04_80227EAC = 0x80227eac
export const RAM_gAnimScaleMtx = 0x80153aa8
export const RAM_flo_maps = 0x800928d0
export const RAM_dro_02_ToadHouse_PartnerSuspendAbilityScript = 0x802435f8
export const RAM_mgm_00_PatrolAI_Loiter = 0x802407a0
export const RAM_b_area_nok_koopa_troopa_UnkBattleFunc1 = 0x802187e0
export const RAM_func_80240C40_A0C930 = 0x80240c40
export const RAM_drop_leaves_render = 0xe00284e8
export const RAM_dro_02_8024891C = 0x8024891c
export const RAM_b_area_trd_part_2_green_ninja_koopa_UnkEnemyPosFunc = 0x802185b0
export const RAM_D_80151304 = 0x80151304
export const RAM_jan_03_Quizmo_StageEffect = 0x8024fef8
export const RAM_flo_11_defeat_80242AC4 = 0x80242ac4
export const RAM_flo_16_FlyingAI_Loiter = 0x802416e8
export const RAM_vtx_C24840 = 0x80203900
export const RAM_EA36E0_SpinyTrompHit = 0x80242de0
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_intTable_80230CD0 = 0x80230cd0
export const RAM_D_802D9DF0 = 0x802d9df0
export const RAM_func_8024054C_B5E7FC = 0x8024054c
export const RAM_b_area_kmr_part_1_formation_07 = 0x802193d8
export const RAM_gPauseWindowFlipUpAngles_2 = 0x8024efd8
export const RAM_b_area_kmr_part_2_goomba_king_idle_80220B50 = 0x80220b50
export const RAM_D_09002360_388710 = 0x09002360
export const RAM_D_802A1A00_73E260 = 0x802a1a00
export const RAM_IES_Unused_0F6 = 0x8008cba4
export const RAM_dgb_15_pad_XXXX = 0x80242ce8
export const RAM_omo_07_ShyGuyWanderAI_15 = 0x80240070
export const RAM_b_area_sam2_sam_02_foregroundModelList = 0x80222600
export const RAM_func_80241290_865DD0 = 0x80241290
export const RAM_func_E0020000 = 0xe0020000
export const RAM_vtx_C224A0 = 0x80201560
export const RAM_b_area_omo2_light_bulb_defenseTable = 0x8022f600
export const RAM_func_802BC630_E2EF60 = 0x802bc630
export const RAM_entity_MulticoinBlock_idle = 0x802e3a48
export const RAM_battle_star_lullaby_UnkStarFunc = 0x802a1494
export const RAM_HES_ElectrifiedEnd = 0x801052d8
export const RAM_B_80240FD0_tst_04 = 0x80240fd0
export const RAM_flo_08_triggerCoord_80245904 = 0x80245904
export const RAM_D_802432E0_9A42C0 = 0x802432e0
export const RAM_func_80241C60_844830 = 0x80241c60
export const RAM_vtx_C28080 = 0x80207140
export const RAM_IES_PowerQuake = 0x8008c024
export const RAM_D_09000E08_32DCC8 = 0x09000e08
export const RAM_ui_battle_status_stop_1_png = 0x801027c0
export const RAM_flo_13_802446AC = 0x802446ac
export const RAM_end_01_ParadeNpcsTable = 0x802441f0
export const RAM_D_800A0954 = 0x800a0954
export const RAM_kmr_04_func_80240434_8CAD34 = 0x80240434
export const RAM_D_E0028800 = 0xe0028800
export const RAM_D_80245650 = 0x80245650
export const RAM_func_80054C84 = 0x80054c84
export const RAM_gPauseDescTextOffset = 0x80270114
export const RAM_sam_08_SuperBlock_CountEligiblePartners = 0x80240120
export const RAM_Munchlesia_LaunchYaw = 0x802b62dc
export const RAM_dgb_01_D_80253620_C10EA0 = 0x80253620
export const RAM_kkj_01_PatrolAI_ChaseInit = 0x8024081c
export const RAM_D_802498F0_A33B30 = 0x802498f0
export const RAM_func_802403A0_D36690 = 0x802403a0
export const RAM_D_80244860_BE35F0 = 0x80244860
export const RAM_get_shadow_by_index = 0x80110604
export const RAM_D_802A9F5C = 0x802a9f5c
export const RAM_tubba_heart_attack_update = 0xe00cc258
export const RAM_set_custom_gfx = 0x8011bcb4
export const RAM_func_802A928C_4263FC = 0x802a928c
export const RAM_load_asset_by_name = 0x8005ace8
export const RAM_func_80243F0C_85607C = 0x80243f0c
export const RAM_D_80241180_7E8520 = 0x80241180
export const RAM_nok_02_Quizmo_VannaTEffect = 0x802549c8
export const RAM_filemenu_cursorHudElemID = 0x8024c0a0
export const RAM_jan_09_UpdateTexturePanStepped = 0x80240404
export const RAM_arn_09_settings = 0x80240060
export const RAM_func_802425A8_833DA8 = 0x802425a8
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021CAFC = 0x8022113c
export const RAM_battle_menu_moveOptionIconScripts = 0x802ad260
export const RAM_tik_07_SuperBlock_WhiteScreenFlash = 0x80240e18
export const RAM_D_8015690B = 0x8015690b
export const RAM_D_80246554 = 0x80246554
export const RAM_func_802404F8_BA4C78 = 0x802404f8
export const RAM_trd_01_UnkNpcAIFunc12 = 0x802405a0
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_8021ED4C = 0x8021ed4c
export const RAM_D_802BEC38_31CC58 = 0x802bec38
export const RAM_b_area_mac_lee_idleAnimations_8022697C = 0x8022697c
export const RAM_mac_05_Quizmo_StashVars = 0x80241320
export const RAM_func_80045798 = 0x80045798
export const RAM_func_E011A3A0 = 0xe011a3a0
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021DF7C = 0x8021df7c
export const RAM_dgb_18_PatrolAI_MoveInit = 0x80240000
export const RAM_player_render_interact_prompts = 0x800e0260
export const RAM_HES_Item_PotatoSalad_disabled = 0x800835e8
export const RAM_b_area_omo_red_shy_guy_handleEvent_80219528 = 0x80219528
export const RAM_D_8010BB00 = 0x8010bb00
export const RAM_omo_17_ShyGuyWanderAI_Main = 0x80240798
export const RAM_NpcSpriteData = 0x802df5b0
export const RAM_dup_hos_06_GetNpcCollisionHeight = 0x80240428
export const RAM_delete_model_animator = 0x8011e438
export const RAM_get_enemy = 0x8003ece8
export const RAM_sbk_26_Bandit_DropCoin = 0x80240040
export const RAM_func_8024126C_A3A74C = 0x8024126c
export const RAM_D_09001C40_351E60 = 0x09001c40
export const RAM_b_area_sam_duplighost_watt_statusTable = 0x8022394c
export const RAM_func_80263268 = 0x80263268
export const RAM_func_80240314_9EDF04 = 0x80240314
export const RAM_ui_status_star_piece_2_png = 0x800fc1c0
export const RAM_D_E00347B0 = 0xe00347b0
export const RAM_D_802AD104 = 0x802ad104
export const RAM_iwa_04_CleftAI_Disguise = 0x80240c64
export const RAM_gCurrentNpcListPtr = 0x800a0b90
export const RAM_arn_07_80242048 = 0x80242048
export const RAM_gfxPreNMI_Callback = 0x8002670c
export const RAM_func_8002D160 = 0x8002d160
export const RAM_mim_06_HoppingAI_Hop = 0x80242144
export const RAM_D_802A1370_73DBD0 = 0x802a1370
export const RAM_D_802626E8_822F68 = 0x802626e8
export const RAM_func_80240680_895D60 = 0x80240680
export const RAM_b_area_pra3_gray_magikoopa_defenseTable = 0x8022ede0
export const RAM_arn_04_FlyingAI_JumpVels = 0x802436dc
export const RAM_func_802448A0_805120 = 0x802448a0
export const RAM_HES_Item_Unused_0F7 = 0x80086078
export const RAM_kzn_09_SuperBlock_ClearOverride40 = 0x8024001c
export const RAM_filemenu_hudElemIDs = 0x8024c0b0
export const RAM_pra_36_reflection_unk_resolve_anim = 0x80240050
export const RAM_flo_18_updateTexturePan_802408C0 = 0x802408c0
export const RAM_dup2_nok_02_GetItemName = 0x80243e64
export const RAM_HES_Item_ShroomCake = 0x80082a78
export const RAM_D_80243D40_A72120 = 0x80243d40
export const RAM_b_area_iwa_cleft_defenseTable_8021872C = 0x8021872c
export const RAM_func_802E2A80 = 0x802e2a80
export const RAM_D_E0112630 = 0xe0112630
export const RAM_D_802422CC_D668AC = 0x802422cc
export const RAM_kmr_06_settings = 0x02800300
export const RAM_gNextMessageBuffer = 0x8014c296
export const RAM_dup_kmr_20_Pipe_AwaitDownInput = 0x802417b4
export const RAM_clear_printers = 0x80123674
export const RAM_battle_item_mushroom_main = 0x802a20cc
export const RAM_get_msg_width = 0x80126474
export const RAM_sweat_init = 0xe00501d0
export const RAM_gPauseWS_24 = 0x8026fe40
export const RAM_kzn_20_GetNpcCollisionHeight = 0x80240428
export const RAM_dgb_07_ClubbaNappingAI_LoiterInit = 0x802409c0
export const RAM_jan_15_foliage_setup_shear_mtx = 0x802400c0
export const RAM_hieroglyphs_main = 0xe00e2000
export const RAM_b_area_omo2_3_shy_stack_idleAnimations3 = 0x802313c0
export const RAM_osr_01_AddPlayerHandsOffset = 0x802404d4
export const RAM_flo_15_pad_135C = 0x8024135c
export const RAM_func_80050EF0 = 0x80050ef0
export const RAM_D_800A0B94 = 0x800a0b94
export const RAM_dgb_12_EVS_Chest_GetItem = 0x802408dc
export const RAM_flo_19_func_80240784_CE3E74 = 0x80240784
export const RAM_D_80242220_D97810 = 0x80242220
export const RAM_D_8010D64C = 0x8010d64c
export const RAM_D_802476D8_859848 = 0x802476d8
export const RAM_b_area_mac_lee_8021E5DC = 0x8021e5dc
export const RAM_mac_00_Quizmo_GetCamVfov = 0x80241210
export const RAM_func_80128510 = 0x80128510
export const RAM_dgb_08_WanderMeleeAI_Main = 0x802414ac
export const RAM_D_80246BD8_DC3E88 = 0x80246bd8
export const RAM_func_802189E0_661130 = 0x802189e0
export const RAM_b_area_nok_formation_10 = 0x8021929c
export const RAM_bss = 0x802700e4
export const RAM_D_E0028808 = 0xe0028808
export const RAM_battle_move_multibounce_UnkMoveFunc1 = 0x802a1000
export const RAM_func_802BB8E4_E30234 = 0x802bb8e4
export const RAM_init_entity_data = 0x80110e58
export const RAM_SpinningFlower_EntityIndex = 0x802b6ed0
export const RAM_b_area_pra3_pra_03_afterBattle = 0x80231f5c
export const RAM_set_anim_model_fog_dist = 0x8012004c
export const RAM_D_80232C04_684984 = 0x80232c04
export const RAM_omo_07_FlyingAI_Main = 0x80242c84
export const RAM_battle_star_peach_focus_UnkBackgroundFunc = 0x802a1414
export const RAM_D_80250720_C0DFA0 = 0x80250720
export const RAM_pra_35_MeleeHitbox_31 = 0x80241e1c
export const RAM_D_80243770 = 0x80243770
export const RAM_HES_Item_DodgeMaster = 0x80083af8
export const RAM_ForceCloseMessageBox = 0x802513f0
export const RAM_D_802440FC = 0x802440fc
export const RAM_D_802D9E34 = 0x802d9e34
export const RAM_dro_02_pad_D_8024EF80 = 0x8024ef80
export const RAM_D_80249DD0 = 0x80249dd0
export const RAM_gAnimScriptDefault = 0x8014c250
export const RAM_b_area_nok_paragoomba_defenseTable_8021B110 = 0x8021b110
export const RAM_D_800DA4B4 = 0x800da4b4
export const RAM_filemenu_info_handle_input = 0x80248024
export const RAM_nok_04_UnkFloatFunc = 0x80240184
export const RAM_func_80257B88 = 0x80257b88
export const RAM_nok_02_Quizmo_StashVars = 0x80241910
export const RAM_b_area_sam2_sam_02_UnkModelStuffScript1 = 0x80222210
export const RAM_D_E00C8730 = 0xe00c8730
export const RAM_D_802412A0_D9C190 = 0x802412a0
export const RAM_pra_38_reflection_unk_change_anim_facing = 0x80240084
export const RAM_nok_03_HoppingAI_LosePlayer = 0x80240bac
export const RAM_func_80240B4C_B2108C = 0x80240b4c
export const RAM_battle_star_peach_dash_SetNpcCollision32 = 0x802a14e8
export const RAM_D_8010D68F = 0x8010d68f
export const RAM_dro_01_D_8024884C_963A4C = 0x8024884c
export const RAM_dgb_01_D_80253320_C10BA0 = 0x80253320
export const RAM_IsPartnerImmobile = 0x80260ad4
export const RAM_mac_05_unkAngleFunc003 = 0x802438a4
export const RAM_gScriptIdList = 0x802daa98
export const RAM_HES_Item_FlowerFinder = 0x80085058
export const RAM_flo_09_FlyingAI_LoiterInit = 0x80240af8
export const RAM_b_area_trd_part_1_stageTable = 0x80218ee0
export const RAM_func_8025995C = 0x8025995c
export const RAM_dup_b_area_tik_UnkFunc56 = 0x80218b80
export const RAM_dgb_04_SuperBlock_ClearOverride40 = 0x802423ec
export const RAM_fx_snowman_doll = 0x80071c90
export const RAM_dgb_03_npcAI_80243A8C = 0x80243a8c
export const RAM_b_area_omo2_6_shy_guy_dup_idle = 0x802315ec
export const RAM_b_area_trd_part_1_trd_02_80222C78 = 0x80222c78
export const RAM_shim_sqrtf = 0xe0200550
export const RAM_isk_08_set_script_owner_npc_col_height = 0x80240314
export const RAM_ui_move_partner_1_pal = 0x80103ce0
export const RAM_b_area_sbk_bandit_vector3D_8021D728 = 0x8021d728
export const RAM_ui_status_star_piece_0_png = 0x800fc080
export const RAM_sbk_26_TransformFoliage = 0x802401d0
export const RAM_sam_07_UnsetCamera0MoveFlag1 = 0x80240634
export const RAM_D_E00D0B30 = 0xe00d0b30
export const RAM_flo_22_defeat_80240810 = 0x80240810
export const RAM_au_bgm_player_read_segment = 0x8004e904
export const RAM_D_8024A2A0 = 0x8024a2a0
export const RAM_pra_31_GetNpcCollisionHeight = 0x80240020
export const RAM_dead_ai_enemy_play_sound = 0x8004d800
export const RAM_Gfx_LoadStencilTex_CommonParams = 0x8014e5a0
export const RAM_D_802A1468_737CF8 = 0x802a1498
export const RAM_func_802A96EC = 0x802a96ec
export const RAM_kpa_10_UpdateTexturePanStepped = 0x80240124
export const RAM_enable_npc_blur = 0x8003ad24
export const RAM_b_area_isk_part_1_stone_chomp_80221BC4 = 0x80221bc4
export const RAM_func_802180D0_464560 = 0x802180d0
export const RAM_D_E0064A90 = 0xe0064a90
export const RAM_b_area_omo3_StartRumbleWithParams = 0x80218c7c
export const RAM_flo_00_80248E30 = 0x80248e30
export const RAM_D_80241518_D55BA8 = 0x80241518
export const RAM_jan_02_Quizmo_FadeInWorld = 0x80241b8c
export const RAM_D_E02006C4 = 0xe02006c4
export const RAM_battle_item_pebble_displayList = 0x802a1a60
export const RAM_D_E0112638 = 0xe0112638
export const RAM_RemoveItemAt = 0x802d688c
export const RAM_flo_13_settings = 0x80243690
export const RAM_phys_peach_update = 0x800e5c78
export const RAM_pra_02_GetEntityPosition = 0x802413d8
export const RAM_mac_05_LetterDelivery_CalcLetterPos = 0x80242894
export const RAM_au_sfx_reset_players = 0x8004d4bc
export const RAM_func_80241868_B21DA8 = 0x80241868
export const RAM_pra_38_ReflectPartner = 0x80240d6c
export const RAM_pra_19_reflection_render_floor_fancy = 0x80240870
export const RAM_b_area_mim_piranha_plant_partsTable_8021D44C = 0x8021d44c
export const RAM_filemenu_main_cleanup = 0x80246cbc
export const RAM_kmr_11_UnkFogFunc = 0x8024023c
export const RAM_func_80274A18 = 0x80274a18
export const RAM_misc_particles_render = 0xe00e490c
export const RAM_D_80246278_9AE668 = 0x80246278
export const RAM_b_area_omo_green_shy_guy_80220D2C = 0x80220d2c
export const RAM_dgb_03_80243970 = 0x80243970
export const RAM_flashing_box_shockwave_main = 0xe0094000
export const RAM_b_area_sam_gray_magikoopa_defenseTable = 0x8022c310
export const RAM_b_area_mac_lee_init_Kooper = 0x802206e8
export const RAM_dgb_07_802417F0 = 0x802417f0
export const RAM_D_E011C518 = 0xe011c518
export const RAM_D_8029FBA4 = 0x8029fba4
export const RAM_animator_node_update_model_transform = 0x8011f118
export const RAM_func_80240664_D13C34 = 0x80240664
export const RAM_WattStaticEffect = 0x802be310
export const RAM_dup_mac_00_GetNpcCollisionHeight = 0x80243518
export const RAM_flo_08_npcGroupList_80244EC4 = 0x80244ec4
export const RAM_iwa_01_GetEncounterEnemyIsOwner = 0x8024228c
export const RAM_flo_14_80243870 = 0x80243870
export const RAM_set_message_images = 0x80125b2c
export const RAM_D_800A095D = 0x800a095d
export const RAM_nok_01_Quizmo_AddViewRelativeOffset = 0x802419e8
export const RAM_HES_GoombarioDisabled = 0x80107d20
export const RAM_battle_star_refresh_802A27FC = 0x802a27fc
export const RAM_battle_star_refresh_802A23AC = 0x802a23ac
export const RAM_b_area_pra2_pos_crystal_bit_1 = 0x8022150c
export const RAM_osContRamWrite = 0x8006a3c0
export const RAM_dgb_18_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_iwa_10_StashVars = 0x80240040
export const RAM_D_802BEBB0 = 0x802bebb0
export const RAM_b_area_omo_sky_guy_80229CB0 = 0x80229cb0
export const RAM_world_watt_post_battle = 0x802bde10
export const RAM_dro_02_D_8024F248 = 0x8024f248
export const RAM_gPauseBadgesItemIds = 0x80270180
export const RAM_func_80050C54 = 0x80050c54
export const RAM_kmr_11_StationaryAI_AlertInit = 0x80240544
export const RAM_ui_status_text_1_png = 0x80100020
export const RAM_dgb_08_pad_69DC = 0x802469dc
export const RAM_D_80159E7E = 0x80159e7e
export const RAM_D_802DFEA0 = 0x802dfea0
export const RAM_func_80242734_8B27A4 = 0x80242734
export const RAM_battle_action_cmd_water_block_block_png = 0x802aa660
export const RAM_func_8013A704 = 0x8013a704
export const RAM_b_area_arn_hyper_paragoomba_statusTable_8021B3B4 = 0x8021b3b4
export const RAM_steam_burst_render = 0xe005c2b4
export const RAM_kmr_24_settings = 0x02800340
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021B338 = 0x8021f978
export const RAM_b_area_sam_duplighost_goombario_idle = 0x8021cc90
export const RAM_flo_13_npcSettings_8024437C = 0x8024437c
export const RAM_func_80240178_AF7AC8 = 0x80240178
export const RAM_kpa_91_ToadHouse_AwaitScriptComplete = 0x8024166c
export const RAM_ED8E20_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_80242670_8D8470 = 0x80242670
export const RAM_jan_09_Pipe_AwaitDownInput = 0x80240034
export const RAM_vtx_C21100 = 0x802001c0
export const RAM_battle_item_snowman_doll_EatItem = 0x802a190c
export const RAM_D_802A98E2_42FFC2 = 0x802a98e2
export const RAM_func_80238244_6FAF54 = 0x80238244
export const RAM_D_E0036640 = 0xe0036640
export const RAM_func_80238570_704060 = 0x80238570
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B6DC = 0x8022b6dc
export const RAM_func_80053F80 = 0x80053f80
export const RAM_D_80245380_EEB930 = 0x80245380
export const RAM_calc_item_check_hit = 0x802515d0
export const RAM_b_area_omo2_toy_tank_parts = 0x8022c8f0
export const RAM_b_area_pra3_duplighost_goombario_takeTurn = 0x8021d684
export const RAM_battle_item_super_soda_EatItem = 0x802a19fc
export const RAM_D_801565AC = 0x801565ac
export const RAM_D_801597D0 = 0x801597d0
export const RAM_D_801593A0 = 0x801593a0
export const RAM_ui_hammer_disabled_pal = 0x800faa40
export const RAM_sbk_00_npcAISettings_80240300 = 0x80240300
export const RAM_mim_08_MeleeHitbox_33 = 0x8024174c
export const RAM_recover_main = 0xe0080000
export const RAM_dgb_01_D_80253460_C10CE0 = 0x80253460
export const RAM_isk_05_StoneChompFXC = 0x802415c0
export const RAM_dgb_08_dgb_00_name_hack = 0x80246e20
export const RAM_init_all_status_icons = 0x80045fb4
export const RAM_b_area_isk_part_1_stone_chomp_ChompChainInit = 0x80218270
export const RAM_phys_init_integrator_for_current_state = 0x800e2f60
export const RAM_nok_01_ToadHouse_PartnerSuspendAbilityScript = 0x80240be0
export const RAM_D_E006AF60 = 0xe006af60
export const RAM_gather_magic_update = 0xe008e204
export const RAM_D_E00B8CD0 = 0xe00b8cd0
export const RAM_b_area_nok_koopa_troopa_partsTable_8021CA44 = 0x8021ffc4
export const RAM_func_80253FB0 = 0x80253fb0
export const RAM_IES_LastStand = 0x8008c724
export const RAM_contRmbStartMesg = 0x8005fd58
export const RAM_D_802434F8_DA09C8 = 0x802434f8
export const RAM_D_8010D686 = 0x8010d686
export const RAM_func_80266B14 = 0x80266b14
export const RAM_kpa_51_MagikoopaAI_OnHit = 0x80241404
export const RAM_state_delegate_NOP = 0x80112b90
export const RAM_func_80240264_D36554 = 0x80240264
export const RAM_D_802E9850 = 0x802e9850
export const RAM_jan_01_UnsetCamera0Flag1000 = 0x80241170
export const RAM_flo_16_802448FC = 0x802448fc
export const RAM_mim_04_HoppingAI_ChaseInit = 0x802409cc
export const RAM_battle_star_up_and_away_802A16D0 = 0x802a16d0
export const RAM_func_80275F00 = 0x80275f00
export const RAM_D_8010C9C4 = 0x8010c9c4
export const RAM_b_area_pra3_gray_magikoopa_statusTable_flying = 0x8022eea4
export const RAM_flo_14_exitWalk_802424FC = 0x802424fc
export const RAM_D_8024F59F = 0x8024f59f
export const RAM_func_80218924_5CEFB4 = 0x80218924
export const RAM_HES_JpSuper = 0x80241f18
export const RAM_ui_boots_disabled_pal = 0x800fb100
export const RAM_gPausePartnersLevel = 0x802706ac
export const RAM_IES_JumpCharge = 0x8008c104
export const RAM_dgb_03_extraAnimationList_80243A54 = 0x80243a54
export const RAM_b_area_isk_part_1_swooper = 0x8021c58c
export const RAM_D_802DA1D0 = 0x802da1d0
export const RAM_D_80241C08_DE4098 = 0x80241c08
export const RAM_quizmo_answer_main = 0xe00a0000
export const RAM_kpa_03_MagikoopaAI_24 = 0x80241a4c
export const RAM_D_80244434_D242C4 = 0x80244434
export const RAM_b_area_sam2_sam_01_UnkModelStuffScript2 = 0x80221e3c
export const RAM_battle_item_hustle_drink_func_802A123C_72619C = 0x802a123c
export const RAM_battle_item_shooting_star_UseItem = 0x802a1854
export const RAM_dro_01_Pipe_GetCameraYaw = 0x802401d0
export const RAM_nok_02_PatrolAI_Move = 0x80240bd8
export const RAM_D_8024EF88 = 0x8024ef88
export const RAM_D_80249158 = 0x80249158
export const RAM_kzn_08_FlyingAI_JumpVels = 0x80244da0
export const RAM_dro_01_shopItemPositions_8024BA68 = 0x8024ba68
export const RAM_D_802A21F0 = 0x802a21f0
export const RAM_au_sfx_load_groups_from_SEF = 0x8004b62c
export const RAM_basic_ai_check_player_dist = 0x800490b4
export const RAM_func_80240A30_869EE0 = 0x80240a30
export const RAM_nok_02_Quizmo_AddViewRelativeOffset = 0x80242590
export const RAM_D_802439E0_A6AAE0 = 0x802439e0
export const RAM_func_802549C0 = 0x802549c0
export const RAM_lightning_update = 0xe006c2a0
export const RAM_toy_tank_model = 0x8021a6c0
export const RAM_InvalidateModelTransform = 0x802c8ee4
export const RAM_battle_item_please_come_back_PlayerGoHome = 0x802a1670
export const RAM_IES_Pebble = 0x8008d694
export const RAM_evt_handle_subtractF = 0x802c5aec
export const RAM_func_80219420_5B28D0 = 0x80219420
export const RAM_flo_19_main = 0x80240c24
export const RAM_dgb_03_npcGroup_80243DE8 = 0x80243de8
export const RAM_D_8029EFB4 = 0x8029efb4
export const RAM_func_80138188 = 0x80138188
export const RAM_EndSpeech = 0x802d02cc
export const RAM_update_exit_map_screen_overlay = 0x801382ac
export const RAM_damage_stars_main = 0xe0030000
export const RAM_trd_02_PatrolNoAttackAI_15 = 0x80241388
export const RAM_pra_20_SetPartnerFlags80000 = 0x80240eb8
export const RAM_nok_15_ParatroopaAI_Dive = 0x802420d4
export const RAM_battle_star_refresh_802A1A70 = 0x802a1a70
export const RAM_dro_01_func_802431FC_95E3FC = 0x802431fc
export const RAM_vtx_C23330 = 0x802023f0
export const RAM_sun_update = 0xe012013c
export const RAM_b_area_trd_part_2_formationTable = 0x8021a5dc
export const RAM_dgb_13_pad_E8 = 0x802400e8
export const RAM_partner_walking_enable = 0x800eb8a8
export const RAM_gWorldShadowList = 0x80151478
export const RAM_dro_01_interact_80248D54 = 0x80248d54
export const RAM_ui_unused_3_png = 0x800fc8e0
export const RAM_trd_04_PatrolNoAttackAI_Main = 0x80241818
export const RAM_disable_entity_fog = 0x80123000
export const RAM_func_80240044_8008C4 = 0x80240044
export const RAM_b_area_kzn_white_magikoopa_GetSelectedMoveID = 0x80218af8
export const RAM_D_80245D80_A994C0 = 0x80245d80
export const RAM_D_802B79A8 = 0x802b79a8
export const RAM_fx_6A = 0x80072050
export const RAM_omo_10_SetNpcPosYaw = 0x80240290
export const RAM_flo_18_triggerCoord_802456C4 = 0x802456c4
export const RAM_virtual_entity_appendGfx_quad = 0x802d2d30
export const RAM_pra_13_reflection_setup_wall = 0x80240128
export const RAM_D_E0030EC0 = 0xe0030ec0
export const RAM_b_area_jan_jan_02_afterBattle_802282CC = 0x802282cc
export const RAM_D_800B430F = 0x800b430f
export const RAM_D_80243890_B7A040 = 0x80243890
export const RAM_dgb_01_FlyingAI_Chase = 0x80240f14
export const RAM_filemenu_draw_contents_file_3_info = 0x80245678
export const RAM_nok_01_StationaryAI_ReturnHome = 0x802405ec
export const RAM_kzn_08_MeleeHitbox_Main = 0x802428f8
export const RAM_SetPartAlpha = 0x8026f470
export const RAM_damage_indicator_main = 0xe003c000
export const RAM_end_00_ShowCreditList = 0x80242580
export const RAM_dgb_06_entryList = 0x802402c0
export const RAM_D_09001C30_351E50 = 0x09001c30
export const RAM_sam_11_Quizmo_SetVannaAnim_Clap = 0x802412d8
export const RAM_func_802400AC_D77FCC = 0x802400ac
export const RAM_dgb_10_80240770 = 0x80240770
export const RAM_arn_07_init_8024519C = 0x8024519c
export const RAM_b_area_trd_part_2_fake_bowser_8021C3F4 = 0x8021c3f4
export const RAM_gather_energy_pink_render = 0xe00264b0
export const RAM_D_80151108 = 0x80151108
export const RAM_EA0C10_PiranhaPlantAI_12 = 0x80241894
export const RAM_mdl_renderTaskQueueIdx = 0x801533ac
export const RAM_hos_03_UpdateTexturePanSmooth = 0x80240030
export const RAM_msg_draw_choice_pointer = 0x80126f78
export const RAM_D_00E3B870 = 0x00e3b870
export const RAM_D_80245624 = 0x80245624
export const RAM_b_area_hos_ember_defenseTable_8021ACB0 = 0x8021acb0
export const RAM_D_80246528 = 0x80246528
export const RAM_tik_19_Pipe_GetEntryPos = 0x802400f0
export const RAM_flo_19_pad_AF4 = 0x80240af4
export const RAM_D_802EC5F0 = 0x802ec5f0
export const RAM_D_8029D920 = 0x8029d920
export const RAM_battle_item_egg_missile_model = 0x802a2150
export const RAM_osSetThreadPri = 0x80065f50
export const RAM_D_800980D4 = 0x800980d4
export const RAM_func_80242F1C_B37AEC = 0x80242f1c
export const RAM_HES_Item_DizzyDial_disabled = 0x800877e8
export const RAM_tik_09_UnkDistFunc = 0x80240aac
export const RAM_D_8014B960 = 0x8014b960
export const RAM_jan_02_Quizmo_varStash = 0x802431e4
export const RAM_evt_goto_end_loop = 0x802c8ae4
export const RAM_SetActorScale = 0x8026c904
export const RAM_flo_14_npcGroup_80244B20 = 0x80244b20
export const RAM_flo_16_SuperBlock_PartnerSparkles4 = 0x80240da0
export const RAM_D_80099D70 = 0x80099d70
export const RAM_func_802A1518_7974D8 = 0x802a1518
export const RAM_b_area_kmr_part_3_paragoomba2_handleEvent_8021CEBC = 0x802214fc
export const RAM_b_area_trd_part_1_trd_05e_80226CF0 = 0x80226cf0
export const RAM_D_8026FC10 = 0x8026fc10
export const RAM_HES_Item_Spaghetti_disabled = 0x800830a8
export const RAM_D_80232AF0_684870 = 0x80232af0
export const RAM_PlaySoundAtF = 0x802d636c
export const RAM_ui_battle_status_charge_hammer_png = 0x801018c0
export const RAM_tik_12_Pipe_SetAnimFlag = 0x80240000
export const RAM_arn_11_8024081C = 0x8024081c
export const RAM_dead_set_aux_pan_u = 0x80126094
export const RAM_IES_ThunderBolt = 0x8008d634
export const RAM_IES_1DC650 = 0x8008bba4
export const RAM_func_80283174 = 0x80283174
export const RAM_render_entities = 0x8010feb4
export const RAM_mac_00_LetterDelivery_SaveNpcAnim = 0x80241d30
export const RAM_D_E0092700 = 0xe0092700
export const RAM_b_area_omo_yellow_shy_guy_idle_8021C9B4 = 0x8021c9b4
export const RAM_D_E011E820 = 0xe011e820
export const RAM_arn_12_exitSingleDoor_80240100 = 0x80240100
export const RAM_b_area_kmr_part_2_kmr_06_clouds3 = 0x80225630
export const RAM_kmr_02_Quizmo_NPC_Aux_Impl = 0x8024073c
export const RAM_entity_HitItemBlock_appear = 0x802e3ee0
export const RAM_func_80240000_B1CA50 = 0x80240000
export const RAM_arn_04_entryList = 0x80243370
export const RAM_func_802415C4_8ED3E4 = 0x802415c4
export const RAM_set_actor_current_position = 0x80269c24
export const RAM_dgb_08_npcGroupList_802469AC = 0x802469ac
export const RAM_flo_16_exitWalk_80242A40 = 0x80242a40
export const RAM_D_802A2AFC_0073C9CC = 0x802a2afc
export const RAM_tik_12_TackleAI_Main = 0x80241cf4
export const RAM_D_802AD054 = 0x802ad054
export const RAM_D_80241A00_C6E320 = 0x80241a00
export const RAM_b_area_kmr_part_3_kmr_06_UnkFogFunc = 0x80219998
export const RAM_tik_22_UpdateTexturePanStepped = 0x80240154
export const RAM_gPauseStatsHammerMessages = 0x8024f390
export const RAM_D_80250290_C0DB10 = 0x80250290
export const RAM_zeroes = 0x80094604
export const RAM_ui_battle_cmd_aim_shimmer_1_png = 0x8028eb20
export const RAM_b_area_hos_magikoopa_runAway = 0x8021de8c
export const RAM_b_area_pra3_duplighost_parakarry_parts = 0x80220714
export const RAM_arn_05_init_8024473C = 0x8024473c
export const RAM_func_80240D0C_B4547C = 0x80240d0c
export const RAM_nok_02_Quizmo_SpinPartner = 0x80242790
export const RAM_lil_oink_init = 0xe01140d4
export const RAM_func_80218F58_6BCAE8 = 0x80218f58
export const RAM_pra_37_reflection_render_floor_fancy = 0x802408a0
export const RAM_func_800F0D80 = 0x800f0d80
export const RAM_kzn_08_StashVars = 0x80240790
export const RAM_D_800741F2 = 0x800741f2
export const RAM_dead_basic_ai_suspend = 0x8004eb8c
export const RAM_dro_01_Quizmo_NPC_OnRender = 0x8024183c
export const RAM_state_step_exit_file_select = 0x80035b40
export const RAM_partner_clear_player_tracking = 0x800ef45c
export const RAM_b_area_jan_spike_top_statusTable_8021C8D8 = 0x80223b48
export const RAM_pra_33_reflection_setup_wall = 0x80240128
export const RAM_flo_17_802432F0 = 0x802432f0
export const RAM_ui_battle_cmd_aim_shimmer_3_pal = 0x8028ece0
export const RAM_vtx_C25350 = 0x80204410
export const RAM_kmr_06_FlyingAI_Main = 0x8024130c
export const RAM_b_area_pra3_duplighost_SetBackgroundAlpha = 0x80219480
export const RAM_func_8024295C_8B29CC = 0x8024295c
export const RAM_func_80219588_6BD118 = 0x80219588
export const RAM_battle_move_mega_smash_IsBerserkerEquipped = 0x802a1000
export const RAM_bgm_set_proximity_mix_full = 0x80056068
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E5A4 = 0x8022e5a4
export const RAM_gPauseDLBannerBp = 0x8026ece0
export const RAM_ring_blast_appendGfx = 0xe0048218
export const RAM_IES_MagicalSeed3 = 0x8008b844
export const RAM_flo_13_npcSettings_802441E4 = 0x802441e4
export const RAM_arn_11_80240050 = 0x80240050
export const RAM_dup_jan_22_TransformFoliage = 0x80241790
export const RAM_func_802407F4_9C3104 = 0x802407f4
export const RAM_b_area_mac_master2_statusTable_8022B6E4 = 0x8022b6e4
export const RAM_wSavedPartnerPosZ = 0x800f8344
export const RAM_D_090023E0_388790 = 0x090023e0
export const RAM_HES_Item_SleepStomp_disabled = 0x80083e88
export const RAM_dro_01_func_80242784_95D984 = 0x80242784
export const RAM_func_80056EC0 = 0x80056ec0
export const RAM_flo_03_802407C0 = 0x802407c0
export const RAM_mgm_02_HideCoinCounter = 0x802423a4
export const RAM_b_area_jan2_white_magikoopa_StartRumbleWithParams = 0x802184a0
export const RAM_b_area_pra3_duplighost_goombario_init = 0x8021bcc4
export const RAM_vtx_C22780 = 0x80201840
export const RAM_pra_28_GetItemName = 0x80241004
export const RAM_jan_22_PatrolAI_JumpInit = 0x80240730
export const RAM_func_80240A00_908430 = 0x80240a00
export const RAM_func_802403F0_C6CD10 = 0x802403f0
export const RAM_D_802ACFF4 = 0x802acff4
export const RAM_HES_Item_JumpCharge_disabled = 0x80084068
export const RAM_SetPartPos = 0x8026af18
export const RAM_b_area_omo_pyro_guy_idle_8022CCCC = 0x8022cccc
export const RAM_kzn_17_MeleeHitbox_31 = 0x8024037c
export const RAM_func_8024130C_BCFEFC = 0x8024130c
export const RAM_osr_03_SetPlayerSpriteSet2 = 0x80240000
export const RAM_D_80150870 = 0x80150870
export const RAM_b_area_pra3_duplighost_parakarry_init = 0x80220784
export const RAM_HES_ElectrifiedLoop = 0x80104fbc
export const RAM_D_800F7FEC = 0x800f7fec
export const RAM_D_801516DC = 0x801516dc
export const RAM_dgb_01_D_80252344_C0FBC4 = 0x80252344
export const RAM_D_80243F90_E12730 = 0x80243f90
export const RAM_HES_TimingWait = 0x8029275c
export const RAM_set_screen_overlay_params_front = 0x80137d88
export const RAM_D_8024F2BC = 0x8024f2bc
export const RAM_HES_Item_JumpCharge = 0x80084038
export const RAM_D_8010CD24 = 0x8010cd24
export const RAM_flo_11_80241598 = 0x80241598
export const RAM_sbk_56_SuperBlock_SwitchToPartner = 0x8024049c
export const RAM_D_802447D0_A3DCB0 = 0x802447d0
export const RAM_D_802479B8_DF6858 = 0x802479b8
export const RAM_func_802406A4_AB0694 = 0x802406a4
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022CC74 = 0x8022cc74
export const RAM_AddActorPos = 0x8026b92c
export const RAM_GoombarioTweesterPhysicsPtr = 0x802bdd88
export const RAM_func_802180E8_4CF298 = 0x802180e8
export const RAM_D_80097DF0 = 0x80097df0
export const RAM_battle_partner_kooper_8023B660 = 0x8023b660
export const RAM_osr_01_LetterDelivery_SavedNpcAnim = 0x80242344
export const RAM_gHudElementCacheTablePaletteBattle = 0x80158b80
export const RAM_func_80242C68_E07FC8 = 0x80242c68
export const RAM_D_80246894 = 0x80246894
export const RAM_D_8029D928 = 0x8029d928
export const RAM_func_80243040_826300 = 0x80243040
export const RAM_dup_b_area_pra_UnkFunc52 = 0x80218918
export const RAM_b_area_omo2_toy_tank_idle = 0x8022cc38
export const RAM_iwa_01_ProjectileHitbox_33 = 0x80240a38
export const RAM_mac_03_Quizmo_Worker = 0x802513d0
export const RAM_b_area_kmr_part_3_egg_jr_troopa_partsTable_802244C4 = 0x802244c4
export const RAM_b_area_dgb_formation_04 = 0x8021b354
export const RAM_appendGfx_model_group = 0x80117c94
export const RAM_D_8014F50C = 0x8014f50c
export const RAM_nok_12_set_script_owner_npc_col_height = 0x80240884
export const RAM_hos_02_FlyingAI_Main = 0x802422fc
export const RAM_kpa_50_RangedAttackAI_Main = 0x80240ef4
export const RAM_pra_02_SetEntityPositionF = 0x80241310
export const RAM_b_area_sbk_bandit_idleAnimations_8021D510 = 0x8021d510
export const RAM_dgb_07_makeEntities = 0x80241760
export const RAM_b_area_omo2_toy_tank_8021A37C = 0x8021a37c
export const RAM_b_area_mac_lee_8021F08C = 0x8021f08c
export const RAM_jan_08_SuperBlock_PartnerIDs = 0x80243e08
export const RAM_arn_07_settings = 0x80241ed0
export const RAM_b_area_trd_part_2_trd_05 = 0x802334c0
export const RAM_flo_07_GetItemName = 0x802406d0
export const RAM_D_80244B90_C36070 = 0x80244b90
export const RAM_HandleEvent_Player = 0x802852d4
export const RAM_emote_init = 0xe0020460
export const RAM_battle_item_strange_cake_D_802A2848_732B48 = 0x802a2848
export const RAM_entity_Chest_close = 0x802e742c
export const RAM_D_E0036648 = 0xe0036648
export const RAM_btl_init_menu_partner = 0x80263914
export const RAM_HES_Item_Egg = 0x80086df8
export const RAM_btl_state_draw_change_partner = 0x80245ac8
export const RAM_b_area_omo_sky_guy_statusTable_80229624 = 0x80229624
export const RAM_tik_01_Pipe_GetPointAheadOfPlayer = 0x80240548
export const RAM_CheckRideScriptForEnterExit = 0x80283810
export const RAM_dgb_03_8024353C = 0x8024353c
export const RAM_D_80245250_C7BF30 = 0x80245250
export const RAM_dgb_07_MeleeHitbox_30 = 0x80240000
export const RAM_dgb_01_D_802528C4_C10144 = 0x802528c4
export const RAM_func_80242710_8B2780 = 0x80242710
export const RAM_func_802410B0_B98CB0 = 0x802410b0
export const RAM_star_render = 0xe001e6e8
export const RAM_b_area_nok_kent_c_koopa_intTable_80227910 = 0x80227910
export const RAM_omo_17_ProjectileHitbox_GetUsableProjectileID = 0x802424ac
export const RAM_D_E011E828 = 0xe011e828
export const RAM_b_area_omo_groove_guy_takeTurn_80226338 = 0x80226338
export const RAM_b_area_pra3_duplighost_bow_parts = 0x802216a0
export const RAM_D_80246618_EA6F18 = 0x80246618
export const RAM_stars_shimmer_render = 0xe00448e4
export const RAM_b_area_jan2_jan_04 = 0x8021f4f8
export const RAM_D_E0064A60 = 0xe0064a60
export const RAM_kmr_06_FlyingAI_JumpInit = 0x80240bdc
export const RAM_fire_breath_main = 0xe006e000
export const RAM_EB8E90_PatrolAI_JumpInit = 0x80240770
export const RAM_shimmer_burst_init = 0xe00701ec
export const RAM_func_800458CC = 0x800458cc
export const RAM_func_80240444_A54D94 = 0x80240444
export const RAM_b_area_kmr_part_3_paragoomba2_handleEvent_8021B768 = 0x8021fda8
export const RAM_kzn_17_PiranhaPlantAI_00 = 0x80240be0
export const RAM_flo_13_npcGroup_802471C4 = 0x802471c4
export const RAM_b_area_omo2_general_guy_attack_lightning_shot = 0x8021963c
export const RAM_step_game_loop = 0x80026740
export const RAM_flo_00_D_80245E88_CA3DA8 = 0x80245e88
export const RAM_ui_battle_status_frozen_0_png = 0x801024a0
export const RAM_b_area_kmr_part_3_paragoomba1_8021DC34 = 0x8021dc34
export const RAM_func_80240BFC_A4033C = 0x80240bfc
export const RAM_sam_02_Quizmo_SetStageLightsDelay = 0x80241568
export const RAM_dro_02_Quizmo_VannaTEffect = 0x8024efc8
export const RAM_set_animator_render_callback = 0x80120000
export const RAM_pause_spirits_handle_input = 0x8024d44c
export const RAM_vtx_C21BD0 = 0x80200c90
export const RAM_D_80232AC8_684848 = 0x80232ac8
export const RAM_ECAA40_DeadUnkFunc1 = 0x80240d40
export const RAM_SetCustomGfxEnabled = 0x802c907c
export const RAM_dead_gPlayerStatusPtr = 0x800ffc90
export const RAM_func_8025A2C4 = 0x8025a2c4
export const RAM_D_802EC6F0 = 0x802ec6f0
export const RAM_D_8024EFE8 = 0x8024efe8
export const RAM_b_area_omo_red_shy_guy_802194C4 = 0x802194c4
export const RAM_vtx_C29E00 = 0x80208ec0
export const RAM_fx_static_status = 0x800723b0
export const RAM_move_player = 0x800e2778
export const RAM_HES_Item_RuinsStonePyramid = 0x80081638
export const RAM_kmr_04_foliage_setup_shear_mtx = 0x80240060
export const RAM_contPakFileReadWrite = 0x8005f770
export const RAM_jan_02_Quizmo_Worker = 0x80248924
export const RAM_pause_tabs_cleanup = 0x80245930
export const RAM_D_800A08F4 = 0x800a08f4
export const RAM_entity_WoodenCrate_reset_fragments = 0x802e7f6c
export const RAM_func_80240000_EA6FD0 = 0x80240000
export const RAM_nuPiInit = 0x8005f450
export const RAM_omo_09_ShyGuyPatrolAI_17 = 0x80241fb4
export const RAM_PanToTarget = 0x802cb79c
export const RAM_nuContCallBack = 0x80093d38
export const RAM_func_80271484 = 0x80271484
export const RAM_evt_handle_if_not_AND = 0x802c4ee8
export const RAM_dgb_08_SentinelAI_ReturnHome = 0x802435d8
export const RAM_func_8024078C_B20CCC = 0x8024078c
export const RAM_D_8024F2F8 = 0x8024f2f8
export const RAM_vtx_C2D410 = 0x8020c4d0
export const RAM_au_BGMCmd_FC_Jump = 0x800501a8
export const RAM_arn_08_func_8024008C_BF482C = 0x8024008c
export const RAM_trd_04_PatrolAI_Loiter = 0x80241144
export const RAM_jan_02_AddPlayerHandsOffset = 0x8024131c
export const RAM_func_802413FC_C8EFAC = 0x802413fc
export const RAM_tik_23_UpdateTexturePanSmooth = 0x802402e0
export const RAM_ending_decals_update = 0xe0068148
export const RAM_b_area_jan_spear_guy_8021CD50 = 0x8021cd50
export const RAM_D_09002290_388640 = 0x09002290
export const RAM_au_load_song_files = 0x80053cf8
export const RAM_flo_13_FlyingAI_Loiter = 0x80240a5c
export const RAM_big_snowflakes_update = 0xe00601e0
export const RAM_isk_14_StoneChompAI_Main = 0x80240f3c
export const RAM_D_802442D8_BE7A88 = 0x802442d8
export const RAM_D_802A2DC8 = 0x802a2dc8
export const RAM_state_drawUI_battle = 0x80033b54
export const RAM_D_80241B60_8666A0 = 0x80241b60
export const RAM_func_802A9898 = 0x802a9898
export const RAM_mim_02_HoppingAI_Loiter = 0x80240698
export const RAM_arn_08_802410AC = 0x802410ac
export const RAM_fx_big_dust_puff = 0x8006f890
export const RAM_ui_battle_cmd_timing_wait_png = 0x8028ce00
export const RAM_EF2680_FlyingAI_Wander = 0x80240220
export const RAM_IES_Lemon = 0x8008d9b4
export const RAM_draw_all_status_icons = 0x800465e0
export const RAM_func_E003621C = 0xe003621c
export const RAM_b_area_pra3_duplighost_formation_parakarry = 0x80221580
export const RAM_arn_13_pad_424 = 0x80240424
export const RAM_D_8024E4A0_C0BD20 = 0x8024e4a0
export const RAM_D_8024F2F0 = 0x8024f2f0
export const RAM_D_8024F2B3 = 0x8024f2b3
export const RAM_b_area_isk_part_1_isk_06 = 0x80223c54
export const RAM_entity_Chest_adjust_camera = 0x802e6e20
export const RAM_func_802BD338_318088 = 0x802bd338
export const RAM_kmr_03_treeEffectVectors_Bush1 = 0x8024215c
export const RAM_D_E00328C8 = 0xe00328c8
export const RAM_D_E00C49E8 = 0xe00c49e8
export const RAM_ui_battle_hp_bar_pal = 0x8028a940
export const RAM_kmr_11_StationaryAI_ChaseInit = 0x8024061c
export const RAM_EEDF50_FlyingAI_Wander = 0x80241000
export const RAM_dro_01_Pipe_EnterVertical = 0x80243c30
export const RAM_D_802BFF08 = 0x802bff08
export const RAM_dgb_08_npcAISettings_802442C0 = 0x802442c0
export const RAM_GetFrameCounter = 0x8024006c
export const RAM_dro_02_Quizmo_RenderInit = 0x80240660
export const RAM_b_area_nok_kent_c_koopa_8022B444 = 0x8022b444
export const RAM_D_802A2000_0073BED0 = 0x802a2000
export const RAM_D_8024E8C0_E052C0 = 0x8024e8c0
export const RAM_func_80240000_B13120 = 0x80240000
export const RAM_dgb_00_80240B08 = 0x80240b08
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021B338 = 0x8021c0b8
export const RAM_D_802465E0_915C90 = 0x802465e0
export const RAM_D_802440EF = 0x802440ef
export const RAM_exec_entity_commandlist = 0x8010fd68
export const RAM_nok_01_StationaryAI_ChaseInit = 0x802402ac
export const RAM_func_80241F30_9F6970 = 0x80241f30
export const RAM_nok_01_Quizmo_AnswerResult = 0x80251c58
export const RAM_IES_CakeDone = 0x8008b140
export const RAM_EC9D00_SuperBlock_UpgradeDescMessages = 0x80241ff8
export const RAM_b_area_kmr_part_2_blue_goomba_2_takeTurn_8021E0F0 = 0x8021e0f0
export const RAM_D_80241E20_AD5EB0 = 0x80241e20
export const RAM_func_80241000_D8A6E0 = 0x80241000
export const RAM_kmr_11_StationaryAI_Main = 0x80240b64
export const RAM_b_area_sam2_formationTable = 0x802234f0
export const RAM_flo_19_80241050 = 0x80241050
export const RAM_func_80242690_E079F0 = 0x80242690
export const RAM_mim_08_FlyingAI_Wander = 0x80240250
export const RAM_pra_27_SetPartnerFlags20000 = 0x80240f1c
export const RAM_dgb_01_D_802521B8_C0FA38 = 0x802521b8
export const RAM_HES_Spirit7 = 0x802425ec
export const RAM_btl_state_draw_begin_partner_turn_C63C08 = 0x80242ef8
export const RAM_entity_ItemBlock_init = 0x802e464c
export const RAM_HES_PartnerB = 0x80108018
export const RAM_arn_03_KeyItemChoiceList = 0x80244a20
export const RAM_free_entity_model_by_ref = 0x80122e94
export const RAM_dgb_05_MeleeHitbox_30 = 0x80240060
export const RAM_sparkle_script_step = 0x80130a04
export const RAM_mac_02_Quizmo_DestroyEffects = 0x80242148
export const RAM_D_80244D88 = 0x80244d88
export const RAM_func_80240F54_DE33E4 = 0x80240f54
export const RAM_vtx_C26350 = 0x80205410
export const RAM_func_E01166E8 = 0xe01166e8
export const RAM_battle_item_pow_block_func_802A123C_718A8C = 0x802a123c
export const RAM_ScaleModel = 0x802c8d88
export const RAM_func_802405A8_B5E858 = 0x802405a8
export const RAM_b_area_omo2_light_bulb_onHit = 0x8022f9d8
export const RAM_b_area_nok_paragoomba_idle_8021B434 = 0x8021b434
export const RAM_D_09001F50_352170 = 0x09001f50
export const RAM_dgb_10_entryList = 0x802401f0
export const RAM_D_E005A750 = 0xe005a750
export const RAM_D_80074580 = 0x80074580
export const RAM_D_80097E08 = 0x80097e08
export const RAM_HES_Item_BakingSalt = 0x800871b8
export const RAM_dgb_08_SentinelAI_ReturnHomeInit = 0x80243530
export const RAM_pra_35_PatrolNoAttackAI_Main = 0x80241ab4
export const RAM_func_80030450 = 0x80030450
export const RAM_IES_SpicySoup = 0x8008cfd4
export const RAM_b_area_nok_formation_11 = 0x8021930c
export const RAM_D_80281454 = 0x80281454
export const RAM_chapter_change_render = 0xe010e510
export const RAM_D_80236560 = 0x80236560
export const RAM_underwater_render = 0xe00ba5dc
export const RAM_HES_MashCDownButton2 = 0x80292710
export const RAM_gCollisionRayDirX = 0x800a423c
export const RAM_battle_item_sleepy_sheep_func_802A1740_71ED90 = 0x802a1740
export const RAM_D_8010C94C = 0x8010c94c
export const RAM_kmr_03_treeEffectVectors_Tree1 = 0x8024218c
export const RAM_sfx_compute_spatialized_sound_params_0 = 0x80149f58
export const RAM_b_area_iwa_iwa_02_init_8022176C = 0x8022176c
export const RAM_D_80242678_8D8478 = 0x80242678
export const RAM_collision_check_player_intersecting_world = 0x800e4308
export const RAM_D_802A1190_737A20 = 0x802a11c0
export const RAM_flo_00_pad_XXX = 0x80249368
export const RAM_trd_08_FireBarAI_Callback = 0x80240904
export const RAM_flo_18_func_80240340_CDC9E0 = 0x80240340
export const RAM_dgb_01_D_80253140_C109C0 = 0x80253140
export const RAM_b_area_mac_mac_01_802321E4 = 0x802321e4
export const RAM_b_area_flo_UnkBattleFunc1 = 0x80218000
export const RAM_dgb_09_triggerCoord_80243E50 = 0x80243e50
export const RAM_D_80249C7C = 0x80249c7c
export const RAM_b_area_dgb_dgb_03 = 0x8021b140
export const RAM_pra_14_ReflectFloor = 0x8024049c
export const RAM_b_area_pra2_crystal_king_statusTable = 0x80218a48
export const RAM_func_8026EDE4 = 0x8026ede4
export const RAM_trd_04_PatrolAI_ChaseInit = 0x802414a0
export const RAM_b_area_omo2_3_shy_stack_handleEvent = 0x80232fd4
export const RAM_EDEA00_TackleAI_Main = 0x80242684
export const RAM_func_802BC050_E2E980 = 0x802bc050
export const RAM_D_E004C6A8 = 0xe004c6a8
export const RAM_omo_06_SetPlayerStatusPosYaw = 0x802401ec
export const RAM_obk_03_LetterDelivery_SaveNpcAnim = 0x80240858
export const RAM_dro_02_pad_D_8024EF94 = 0x8024efa0
export const RAM_IES_MapleShroom = 0x8008d4b4
export const RAM_sam_08_SuperBlock_HideBlockContent = 0x8024003c
export const RAM_dgb_00_80240A00 = 0x80240a00
export const RAM_update_player = 0x800df740
export const RAM_gRenderModelEnvR = 0x8014b763
export const RAM_sleep_bubble_appendGfx = 0xe0052460
export const RAM_trd_07_FlyingAI_Jump = 0x80241738
export const RAM_HES_Item_Unused_02D_disabled = 0x80083888
export const RAM_evt_handle_goto = 0x802c49c0
export const RAM_gPauseMsg_3D = 0x8026f6b0
export const RAM_D_E00CCE4C = 0xe00cce4c
export const RAM_b_area_kmr_part_1_paragoomba_defenseTable_8021B11C = 0x8021cb0c
export const RAM_D_80244D50_A0BC50 = 0x80244d50
export const RAM_b_area_sam_duplighost_UnkFunc62 = 0x80218000
export const RAM_b_area_sam_sam_02c_UnkFloatFunc2 = 0x8021a72c
export const RAM_b_area_omo2_light_bulb_idle = 0x8022f774
export const RAM_EB7540_GetEntityPosition = 0x802403d8
export const RAM_b_area_pra3_pra_03_beforeBattle = 0x80231f20
export const RAM_D_8024AEC4_A35104 = 0x8024aec4
export const RAM_D_E0020D80 = 0xe0020d80
export const RAM_vtx_C27FE0 = 0x802070a0
export const RAM_D_80109494 = 0x80109494
export const RAM_flo_10_func_80240040_CB9240 = 0x80240040
export const RAM_hos_06_LetterDelivery_RestoreNpcAnim = 0x80240a3c
export const RAM_D_802427D0_A517D0 = 0x802427d0
export const RAM_D_80241220_AB1210 = 0x80241220
export const RAM_b_area_trd_part_1_trd_02b_afterBattle_80223B40 = 0x80223b40
export const RAM_b_area_kmr_part_1_kmr_03_clouds3 = 0x80220030
export const RAM_nuScPreNMIFlag = 0x8009a5b0
export const RAM_D_802480AC_8B811C = 0x802480ac
export const RAM_battle_star_refresh_usePower = 0x802a2928
export const RAM_b_area_omo_sky_guy_idleAnimations_802295A4 = 0x802295a4
export const RAM_vtx_C2AE70 = 0x80209f30
export const RAM_D_80099B60 = 0x80099b60
export const RAM_HES_Item_SuperSoda = 0x80086918
export const RAM_arn_02_CleftAI_RevUpInit = 0x80240644
export const RAM_nok_02_ItemEntityJumpToPos = 0x80240750
export const RAM_arn_08_80240B50 = 0x80240b50
export const RAM_ui_status_coin_4_png = 0x80100d80
export const RAM_draw_message_window = 0x80127ba4
export const RAM_HES_Kalmar = 0x80107ba0
export const RAM_b_area_pra3_duplighost_goombario = 0x8021bc9c
export const RAM_D_80097E00 = 0x80097e00
export const RAM_pra_06_SetPartnerFlags80000 = 0x80240ee8
export const RAM_func_802A97BC = 0x802a97bc
export const RAM_b_area_sam_gulpit_idleAnimations3 = 0x80227e28
export const RAM_D_801045A8 = 0x801045a8
export const RAM_ui_battle_swap_arrow_right_png = 0x8028a280
export const RAM_D_802498EC_A33B2C = 0x802498ec
export const RAM_b_area_isk_part_1_stone_chomp_idleAnimations_80221504 = 0x80221504
export const RAM_hos_03_Quizmo_HideEntities = 0x802425bc
export const RAM_func_800561A4 = 0x800561a4
export const RAM_D_8026FC48 = 0x8026fc48
export const RAM_func_802401D0_D78A50 = 0x802401d0
export const RAM_D_8024DCCF_A37F0F = 0x8024dccf
export const RAM_b_area_iwa_whacka = 0x8021fd78
export const RAM_vtx_C24980 = 0x80203a40
export const RAM_flower_splash_appendGfx = 0xe0010510
export const RAM_dead_destroy_popup_menu = 0x800f93e8
export const RAM_D_802438F0_A4BEB0 = 0x802438f0
export const RAM_func_802402B8_B1CD08 = 0x802402b8
export const RAM_battle_item_sleepy_sheep_UseItemWithEffect = 0x802a1970
export const RAM_D_8014C248 = 0x8014c248
export const RAM_dgb_18_pad_1358 = 0x80241358
export const RAM_kmr_02_ToadHouse_PartnerResumeAbilityScript = 0x80243210
export const RAM_GetPlayerHP = 0x80274234
export const RAM_hos_03_Quizmo_varStash = 0x8024801c
export const RAM_flo_25_tree1_Drops = 0x8024465c
export const RAM_D_80098260 = 0x80098260
export const RAM_sam_10_varStash = 0x80241040
export const RAM_b_area_jan_jan_03_foregroundModelList_80228380 = 0x80228380
export const RAM_kmr_11_StationaryAI_Chase = 0x8024074c
export const RAM_nuContWaitMesgBuf = 0x800ae6d0
export const RAM_b_area_jan2_jan_03b_beforeBattle_8021F3F0 = 0x8021f3f0
export const RAM_D_802AB513 = 0x802ab513
export const RAM_pra_12_varStash = 0x802416b0
export const RAM_mac_00_Quizmo_ShouldAppear = 0x8024076c
export const RAM_func_80240724_9B1EB4 = 0x80240724
export const RAM_kkj_17_GetItemEmptyCount = 0x80240568
export const RAM_b_area_jan_spike_top_spikeTop_TakeTurn_Ceiling = 0x80225624
export const RAM_btl_state_update_enemy_striking_first = 0x80248660
export const RAM_flo_23_exitWalk_802410A8 = 0x802410a8
export const RAM_func_802440FC_80497C = 0x802440fc
export const RAM_b_area_kmr_part_2_kmr_05_beforeBattle_80225388 = 0x80225388
export const RAM_D_80153A4E = 0x80153a4e
export const RAM_HES_MenuStarPowerDisabled = 0x8010899c
export const RAM_omo_06_UnkFloatFunc = 0x80240388
export const RAM_flo_17_npcGroup_80244688 = 0x80244688
export const RAM_dgb_09_WanderMeleeAI_Main = 0x8024061c
export const RAM_SetEntityHideMode1 = 0x802825fc
export const RAM_remove_all_effects = 0x8005a4e0
export const RAM_kpa_91_UnkDistFunc = 0x80240f0c
export const RAM_arn_11_80240690 = 0x80240690
export const RAM_b_area_isk_part_1_pokey_mummy_idleAnimations_80218DC8 = 0x80218dc8
export const RAM_func_80244F5C_8057DC = 0x80244f5c
export const RAM_nok_02_Quizmo_SetVannaAnim_Clap = 0x80242418
export const RAM_osPfsFindFile = 0x80068dc0
export const RAM_gHudElementCacheTableRaster = 0x801512c8
export const RAM_nok_01_Quizmo_Answers = 0x80245fa8
export const RAM_D_80150EE0 = 0x80150ee0
export const RAM_b_area_mim_formation_06 = 0x8021e6b8
export const RAM_sbk_02_UnkFunc26 = 0x80240164
export const RAM_b_area_kmr_part_2_red_goomba_2_partsTable_8021EEB8 = 0x8021eeb8
export const RAM_D_80241B64_8666A4 = 0x80241b64
export const RAM_D_80249A7C_A33CBC = 0x80249a7c
export const RAM_D_80242510_D95450 = 0x80242510
export const RAM_func_80260E38 = 0x80260e38
export const RAM_D_80150028 = 0x80150028
export const RAM_kpa_61_UnkDistFunc = 0x8024038c
export const RAM_D_8024F980_C0D200 = 0x8024f980
export const RAM_flo_10_itemList_80243394 = 0x80243394
export const RAM_D_8029D360 = 0x8029d360
export const RAM_pra_35_PatrolAI_JumpInit = 0x80241650
export const RAM_dro_02_80244C78 = 0x80244c78
export const RAM_dgb_04_SuperBlock_RadiateFaintEnergyFX = 0x80242978
export const RAM_func_802427EC_8B285C = 0x802427ec
export const RAM_texPannerAuxV = 0x80153328
export const RAM_HES_AsleepLoop = 0x80104c68
export const RAM_dup_b_area_pra_StartRumbleWithParams = 0x802188c0
export const RAM_HES_KalmarDisabled = 0x80107bcc
export const RAM_appendGfx_partner_actor = 0x80257b48
export const RAM_b_area_isk_part_1_pokey_mummy_8021BDE4 = 0x8021bde4
export const RAM_b_area_mac_lee_takeTurn_802203F4 = 0x802203f4
export const RAM_GetPartEventFlags = 0x8026d488
export const RAM_D_8024F850_C0D0D0 = 0x8024f850
export const RAM_fx_lil_oink = 0x80072710
export const RAM_func_802A1000_737890 = 0x802a1000
export const RAM_D_09002428 = 0x09002428
export const RAM_btl_actorHomePositions = 0x80283524
export const RAM_b_area_trd_part_1_trd_03_afterBattle_80223FA0 = 0x80223fa0
export const RAM_func_80268AF8 = 0x80268af8
export const RAM_D_80251638_C0EEB8 = 0x80251638
export const RAM_kzn_18_UpdateTexturePanSmooth = 0x80240040
export const RAM_osSendMesg = 0x80065820
export const RAM_nok_02_PatrolAI_JumpInit = 0x802411b0
export const RAM_func_80240F00_DE3390 = 0x80240f00
export const RAM_flo_23_init_80241E70 = 0x80241e70
export const RAM_D_802ACC70 = 0x802acc70
export const RAM_D_E0090A68 = 0xe0090a68
export const RAM_flo_14_FlyingAI_JumpVels = 0x802444c0
export const RAM_D_802BCE34 = 0x802bce34
export const RAM_D_802500A0_C0D920 = 0x802500a0
export const RAM_evt_handle_case_greater_equal = 0x802c52f8
export const RAM_gPauseWS_17 = 0x8026fcb8
export const RAM_func_80238CE0_700A60 = 0x80238ce0
export const RAM_func_80218560_56E800 = 0x80218560
export const RAM_battle_item_mushroom_EatItem = 0x802a1acc
export const RAM_D_802A19C0 = 0x802a19c0
export const RAM_dgb_09_npcSettings_80244258 = 0x80244258
export const RAM_load_mesh_animator_tree = 0x80120474
export const RAM_jan_00_unkAngleFunc003 = 0x80241654
export const RAM_kpa_102_UnkNpcAIFunc48 = 0x80240500
export const RAM_isk_05_HoppingAI_HopInit = 0x80240000
export const RAM_gEntityHeapBase = 0x801512c4
export const RAM_b_area_kzn_kzn_04b_80228370 = 0x80228370
export const RAM_b_area_omo_spy_guy_jump_icons = 0x80228760
export const RAM_star_spirits_energy_init = 0xe0122174
export const RAM_D_80155D20 = 0x80155d20
export const RAM_action_command_bomb_free_hud_elements = 0x802a97cc
export const RAM_func_802400F0_982530 = 0x802400f0
export const RAM_D_8024D280_C0AB00 = 0x8024d280
export const RAM_D_800A0904 = 0x800a0904
export const RAM_dgb_08_FlyingAI_WanderInit = 0x80241760
export const RAM_jan_04_UnkFunc41 = 0x80240610
export const RAM_battle_item_thunder_rage_FadeBackgroundToBlack = 0x802a123c
export const RAM_b_area_trd_part_2_fake_bowser_D_8021ACCC_48BEBC = 0x8021accc
export const RAM_D_80151100 = 0x80151100
export const RAM_nok_02_Quizmo_ShowEntities = 0x80241a3c
export const RAM_D_8010CD10 = 0x8010cd10
export const RAM_D_8024F258_C0CAD8 = 0x8024f258
export const RAM_b_area_omo2_2_stilt_guy_onDeath = 0x8023379c
export const RAM_D_802AD604 = 0x802ad604
export const RAM_D_801568F4 = 0x801568f4
export const RAM_D_8028FC68 = 0x8028fc68
export const RAM_D_802348D8_6BBAD8 = 0x802348d8
export const RAM_func_80139F10 = 0x80139f10
export const RAM_b_area_hos_hos_00 = 0x802258b8
export const RAM_dgb_12_makeEntities = 0x80240a08
export const RAM_EA8AE0_StarSpiritEffectFunc6 = 0x80240a14
export const RAM_b_area_flo2_UnkBackgroundFunc3 = 0x802188a8
export const RAM_gPauseMsg_34 = 0x8026f674
export const RAM_ED8E20_Pipe_AwaitDownInput = 0x80240034
export const RAM_b_area_trd_part_1_bob_omb_8021A9C8 = 0x8021a9c8
export const RAM_add_vec2D_polar = 0x80029f9c
export const RAM_ui_battle_status_shrink_3_pal = 0x80102d40
export const RAM_kmr_07_StationaryAI_Alert = 0x80240234
export const RAM_render_hud_elements_world = 0x801436b4
export const RAM_b_area_trd_part_1_formation_13 = 0x80218a5c
export const RAM_battle_item_snowman_doll_GiveRefund = 0x802a1000
export const RAM_b_area_jan_jan_03 = 0x80228390
export const RAM_b_area_sam_duplighost_bow_takeTurn = 0x80222c7c
export const RAM_func_E000C000 = 0xe000c000
export const RAM_b_area_sbk_formation_01 = 0x8021f95c
export const RAM_D_8029F260 = 0x8029f260
export const RAM_flo_03_exitWalk_80240B88 = 0x80240b88
export const RAM_func_E003C498 = 0xe003c498
export const RAM_b_area_sam_duplighost_UnkWattEffectFunc3 = 0x80219358
export const RAM_dro_01_KeyItemChoiceList = 0x8024dff0
export const RAM_pra_09_reflection_unk_resolve_anim = 0x80240020
export const RAM_snaking_static_main = 0xe00ae000
export const RAM_arn_03_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_b_area_omo2_1_shy_squad_nextTurn = 0x80232058
export const RAM_dup_kkj_11_UnkPhysicsFunc = 0x802408a0
export const RAM_func_802A92F0 = 0x802a92f0
export const RAM_flo_11_Pipe_EnterVertical = 0x802406fc
export const RAM_D_80242F68_A66FF8 = 0x80242f68
export const RAM_b_area_trd_part_2_red_ninja_koopa_80228748 = 0x80228748
export const RAM_dgb_00_802402D0 = 0x802402d0
export const RAM_b_area_isk_part_1_formation_12 = 0x8022463c
export const RAM_b_area_pra3_red_magikoopa_8022C414 = 0x8022c414
export const RAM_vtx_C23BA0 = 0x80202c60
export const RAM_end_01_UnkFunc27 = 0x80242cf0
export const RAM_jan_08_WanderMeleeAI_Main = 0x80241c84
export const RAM_omo_06_IsAOrBPressed = 0x80240518
export const RAM_IES_Mistake = 0x8008d214
export const RAM_kzn_17_LetterDelivery_Init = 0x80242a08
export const RAM_HES_Item_Melon = 0x80086eb8
export const RAM_D_80250698_C0DF18 = 0x80250698
export const RAM_b_area_arn_formation_01 = 0x80227c7c
export const RAM_flo_25_ItemChoiceList = 0x80244fa0
export const RAM_ui_partner0_png = 0x800fe3e0
export const RAM_stat_change_render = 0xe00ac280
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021CB48 = 0x8021e538
export const RAM_entity_SaveBlock_idle = 0x802e10f4
export const RAM_mim_08_FlyingAI_Chase = 0x80240e94
export const RAM_b_area_trd_part_1_trd_02d_80224CA0 = 0x80224ca0
export const RAM_bombette_breaking_get_model_list_index_from_tree_index = 0xe0084060
export const RAM_ui_status_star_piece_1_pal = 0x800fc1a0
export const RAM_func_8002ACDC = 0x8002acdc
export const RAM_func_80244E90_805710 = 0x80244e90
export const RAM_func_802404B0_ED46D0 = 0x802404b0
export const RAM_vtx_C22190 = 0x80201250
export const RAM_filemenu_update_hidden_options_left = 0x8024368c
export const RAM_dro_02_EVS_Quizmo_PlayerReaction_RightAnswer = 0x8024575c
export const RAM_nok_01_Quizmo_StageEffect = 0x80251c60
export const RAM_D_80232C8C_684A0C = 0x80232c8c
export const RAM_D_80247120 = 0x80247120
export const RAM_b_area_arn_arn_01 = 0x8022792c
export const RAM_func_80240000_AB92B0 = 0x80240000
export const RAM_D_80242F40_980A10 = 0x80242f40
export const RAM_mim_11_Pipe_GetCameraYaw = 0x802401d0
export const RAM_func_80240000_90B240 = 0x80240000
export const RAM_battle_move_hammer_IsRightOnEquipped = 0x802a1050
export const RAM_D_802A9922_430942 = 0x802a9922
export const RAM_phys_update_jump = 0x800e2d18
export const RAM_trd_04_set_script_owner_npc_col_height = 0x802407c4
export const RAM_jan_09_MeleeHitbox_32 = 0x80240c30
export const RAM_b_area_isk_part_1_buzzy_beetle_takeTurn_80220A44 = 0x80220a44
export const RAM_gWindowDisappearScales = 0x8014f2a8
export const RAM_spr_set_anim_timescale = 0x802dd88c
export const RAM_D_09000CD0_32DB90 = 0x09000cd0
export const RAM_D_802BCB80_E314D0 = 0x802bcb80
export const RAM_b_area_pra3_duplighost_kooper_idleAnimations2 = 0x8021d748
export const RAM_check_input_hammer = 0x800e60e0
export const RAM_flo_23_D_802429BC = 0x802429bc
export const RAM_b_area_sam_duplighost_OnDeath = 0x8021bbfc
export const RAM_D_801533C0 = 0x801533c0
export const RAM_b_area_kpa2_StartRumbleWithParams = 0x8021b950
export const RAM_battle_menu_moveUpArrowIcon = 0x802ad11c
export const RAM_wModelLocalVtxBuffers = 0x80152190
export const RAM_entity_shattering_init_pieces = 0x802e246c
export const RAM_D_80249A80_A33CC0 = 0x80249a80
export const RAM_FlyToGoal = 0x8027b624
export const RAM_fx_46 = 0x800712d0
export const RAM_ui_battle_status_poison_1_pal = 0x80102480
export const RAM_reset_player_status = 0x800e205c
export const RAM_is_debug_print = 0x80025d74
export const RAM_dead_gPlayerData = 0x80117840
export const RAM_arn_03_varStash = 0x80241c68
export const RAM_dgb_15_idle_80242238 = 0x80242238
export const RAM_pra_14_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_80247688_D1FE68 = 0x80247688
export const RAM_nok_15_FlyingAI_Chase = 0x802418a8
export const RAM_EA7010_UpdateTexturePanStepped = 0x80240164
export const RAM_b_area_omo_omo_04_vector3D_8022DE58 = 0x8022de58
export const RAM_b_area_arn_hyper_cleft = 0x80220c38
export const RAM_IES_DefendPlus = 0x8008c344
export const RAM_fx_stars_spread = 0x80070970
export const RAM_dup_b_area_flo_UnkBattleFunc1 = 0x80218380
export const RAM_sam_11_GetEntityPosition = 0x802402b4
export const RAM_title_screen_draw_logo = 0x80037994
export const RAM_b_area_kzn_kzn_05_80228E40 = 0x80228e40
export const RAM_flo_25_settings = 0x802422f0
export const RAM_osSpTaskYield = 0x80065c50
export const RAM_D_E02006FC = 0xe02006fc
export const RAM_arn_13_npcGroup_802409DC = 0x802409dc
export const RAM_dro_02_image1_pal = 0x802477e8
export const RAM_flo_25_ItemChoice_SelectedItemID = 0x80242ad0
export const RAM_D_E002C9D0 = 0xe002c9d0
export const RAM_D_802BCF90_E2D6C0 = 0x802bcf90
export const RAM_kmr_06_FlyingAI_Chase = 0x80240df4
export const RAM_flo_03_idle_80242F10 = 0x80242f10
export const RAM_sam_02_Quizmo_FadeInWorld = 0x802410a4
export const RAM_b_area_kmr_part_1_kmr_06_clouds3 = 0x80220cd0
export const RAM_dgb_04_npcGroupList_80243D18 = 0x80243d18
export const RAM_b_area_nok_formation_02 = 0x80218e90
export const RAM_flo_18_main = 0x80240a24
export const RAM_ui_red_bar2_png = 0x800fcd40
export const RAM_kzn_03_PiranhaPlantAI_11 = 0x80242070
export const RAM_update_encounters_pre_battle = 0x80041364
export const RAM_D_8009E6D0 = 0x8009e6d0
export const RAM_fx_dust = 0x80070070
export const RAM_mim_01_WanderMeleeAI_Main = 0x80240b1c
export const RAM_D_E0056EC8 = 0xe0056ec8
export const RAM_dup_mac_01_PatrolAI_LoiterInit = 0x80241480
export const RAM_D_8014F110 = 0x8014f110
export const RAM_pra_11_reflection_setup_wall = 0x80240128
export const RAM_b_area_kgr_fuzzipede = 0x80218b48
export const RAM_b_area_isk_part_1_isk_08_pad = 0x80224da8
export const RAM_D_80158578 = 0x80158578
export const RAM_func_80240504_CCB814 = 0x80240504
export const RAM_omo_13_ShyGuyWanderAI_Main = 0x802404b8
export const RAM_kpa_14_get_tattle = 0x80240000
export const RAM_func_8021D290_6A4490 = 0x8021d290
export const RAM_D_8025B2B0 = 0x8025b2b0
export const RAM_test_ray_zone = 0x80032210
export const RAM_Gfx_LoadStencilTex_SharpCircle = 0x8014e618
export const RAM_D_80099B68 = 0x80099b68
export const RAM_dgb_07_MeleeHitbox_Main = 0x802403bc
export const RAM_mgm_00_PatrolAI_LosePlayer = 0x80240d4c
export const RAM_kkj_20_ToadHouse_DisableStatusMenu = 0x802402c0
export const RAM_isPickingUpItem = 0x801565a4
export const RAM_jan_02_Quizmo_CreateWorker = 0x80242678
export const RAM_ui_status_text_7_png = 0x801003e0
export const RAM_set_main_pan_u = 0x8011bc1c
export const RAM_b_area_kmr_part_3_para_jr_troopa_8022646C = 0x8022646c
export const RAM_PlayerLandJump = 0x80273b04
export const RAM_dgb_00_main = 0x80240520
export const RAM_func_80240508_BC4788 = 0x80240508
export const RAM_arn_13_exitSingleDoor_802401A4 = 0x802401a4
export const RAM_SetNpcYaw = 0x802cea84
export const RAM_fx_bombette_breaking = 0x80071150
export const RAM_hud_element_set_transform_rotation_pivot = 0x80145274
export const RAM_ALT_clear_hud_element_cache = 0x80144a5c
export const RAM_b_area_mim_piranha_plant_handleEvent_8021D60C = 0x8021d60c
export const RAM_func_80218000_63D1E0 = 0x80218000
export const RAM_b_area_sam_duplighost_kooper_parts = 0x8021e7d8
export const RAM_IES_Lime = 0x8008d994
export const RAM_iwa_03_CleftAI_RevUp = 0x802409d4
export const RAM_ED0310_FlyingAI_WanderInit = 0x80240320
export const RAM_kpa_08_MagikoopaAI_22 = 0x80240f04
export const RAM_HammerBroDisguiseExtraAnims = 0x800f7bbc
export const RAM_b_area_trd_part_2_fake_bowser_UnkFunc27 = 0x80218058
export const RAM_dgb_12_pad_3A8 = 0x802403a8
export const RAM_D_802BCDE0_E2F710 = 0x802bcde0
export const RAM_pra_35_GetItemName = 0x80242a04
export const RAM_D_80295350 = 0x80295350
export const RAM_b_area_nok_formation_0C = 0x8021914c
export const RAM_dgb_03_npcGroupList_80244988 = 0x80244988
export const RAM_b_area_arn_hyper_paragoomba_8021DFC8 = 0x8021dfc8
export const RAM_D_80156954 = 0x80156954
export const RAM_HES_Item_HoneyShroom_disabled = 0x800831c8
export const RAM_b_area_isk_part_1_isk_06b_D_80224DA0 = 0x80224da0
export const RAM_flo_13_init_80246370 = 0x80246370
export const RAM_func_80243010_9DA030 = 0x80243010
export const RAM_D_090002F0_330750 = 0x090002f0
export const RAM_kpa_13_UpdateTexturePanSmooth = 0x80240040
export const RAM_D_E0070CD0 = 0xe0070cd0
export const RAM_D_E003CCD0 = 0xe003ccd0
export const RAM_btl_draw_enemy_health_bars = 0x8023ffa0
export const RAM_sam_02_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_D_80151240 = 0x80151240
export const RAM_osr_01_LetterDelivery_SaveNpcAnim = 0x80240a48
export const RAM_dup_b_area_tik2_UnkWaterFountainFunc = 0x802184e4
export const RAM_whirlwind_init = 0xe00ce21c
export const RAM_D_801512E8 = 0x801512e8
export const RAM_func_80240448_B141B8 = 0x80240448
export const RAM_func_80238E30_708AD0 = 0x80238e30
export const RAM_flo_24_searchBush = 0x80240d40
export const RAM_tik_24_TackleAI_Main = 0x80240a64
export const RAM_b_area_omo2_toy_tank_8021A350 = 0x8021a350
export const RAM_arn_05_PatrolAI_ChaseInit = 0x8024081c
export const RAM_func_800F4D28 = 0x800f4d28
export const RAM_dup3_b_area_flo2_UnkFloatFunc = 0x802194a0
export const RAM_free_generic_entity = 0x801235c0
export const RAM_jan_07_MeleeHitbox_31 = 0x80240280
export const RAM_tik_14_Pipe_GetCurrentFloor = 0x80240020
export const RAM_dgb_01_D_80252268_C0FAE8 = 0x80252268
export const RAM_func_802BC1D0_E2EB00 = 0x802bc1d0
export const RAM_b_area_jan_jan_01_802273E8 = 0x802273e8
export const RAM_draw_first_strike_ui = 0x8003e63c
export const RAM_D_E01248A0 = 0xe01248a0
export const RAM_jan_12_SpearGuyAI_Loiter = 0x80240510
export const RAM_func_80242F7C_8B2FEC = 0x80242f7c
export const RAM_b_area_mac_lee_idle_8021EB14 = 0x8021eb14
export const RAM_D_8024F585 = 0x8024f585
export const RAM_func_802BC0F0_E2EA20 = 0x802bc0f0
export const RAM_HES_SlowlyPressAButton = 0x80108bf8
export const RAM_tik_19_Pipe_SetAnimFlag = 0x80240000
export const RAM_D_8024AB28_DD77F8 = 0x8024ab28
export const RAM_appendGfx_item_entity = 0x801321a4
export const RAM_D_80098040 = 0x80098040
export const RAM_battle_item_thunder_rage_UseItemWithEffect = 0x802a14a0
export const RAM_func_800F4C6C = 0x800f4c6c
export const RAM_func_80032C64 = 0x80032c64
export const RAM_ui_move_partner_2_pal = 0x80103f20
export const RAM_HES_FilledCircle = 0x80104a70
export const RAM_battle_star_chill_out_802A2020 = 0x802a2020
export const RAM_flo_19_80242FD0 = 0x80242fd0
export const RAM_func_800579D8 = 0x800579d8
export const RAM_func_8025B1A8 = 0x8025b1a8
export const RAM_get_current_map_settings = 0x8005abe8
export const RAM_D_E0044E04 = 0xe0044e04
export const RAM_flo_00_80241FA4 = 0x80241fa4
export const RAM_ui_battle_cmd_aim_shimmer_3_png = 0x8028ec60
export const RAM_flo_18_foliage_setup_shear_mtx = 0x80240590
export const RAM_iwa_10_SuperBlock_UpgradeDescMessages = 0x80245a78
export const RAM_get_entity_type = 0x801106a4
export const RAM_small_gold_sparkle_appendGfx = 0xe00922a0
export const RAM_dro_01_intTable_8024B4A0 = 0x8024b4a0
export const RAM_dgb_04_enterSingleDoor_80243378 = 0x80243378
export const RAM_ui_box_corners8_png = 0x80109de0
export const RAM_D_E0026A38 = 0xe0026a38
export const RAM_evt_handle_divideF = 0x802c5bc0
export const RAM_mac_03_Quizmo_CreateWorker = 0x80241514
export const RAM_D_80150948 = 0x80150948
export const RAM_moving_cloud_render = 0xe00e8374
export const RAM_obk_07_80241788_BD0378 = 0x80241788
export const RAM_flo_25_npcSettings_802428F0 = 0x802428f0
export const RAM_kzn_08_PiranhaPlantAI_Main = 0x80243c90
export const RAM_evt_handle_end_if = 0x802c4f6c
export const RAM_pra_34_reflection_render_floor_fancy = 0x80240870
export const RAM_D_090002E8 = 0x090002e8
export const RAM_arn_09_entryList = 0x80240040
export const RAM_D_09000880_326C90 = 0x09000880
export const RAM_dup_jan_08_TransformFoliage = 0x80242260
export const RAM_b_area_omo2_2_stilt_guy_handleEvent = 0x8023239c
export const RAM_b_area_arn_arn_01_foregroundModelList_80227920 = 0x80227920
export const RAM_kmr_02_ToadHouse_DoesPlayerNeedSleep = 0x80243230
export const RAM_iwa_02_CleftAI_PostDisguise = 0x80240c4c
export const RAM_b_area_isk_part_1_stone_chomp_init_80221ABC = 0x80221abc
export const RAM_dgb_08_SentinelAI_DescendInit = 0x80242eb4
export const RAM_kzn_23_GetEntityPosition = 0x802403d8
export const RAM_IES_HoneySyrup = 0x8008dad4
export const RAM_D_E0056EC0 = 0xe0056ec0
export const RAM_IES_Unused_08D = 0x8008be84
export const RAM_func_802417AC_84437C = 0x802417ac
export const RAM_step_entity_commandlist = 0x8010fbd8
export const RAM_D_80241F94_D4EFD4 = 0x80241f94
export const RAM_b_area_kmr_part_2_goomba_king_statusTable_8022091C = 0x8022091c
export const RAM_tik_15_Pipe_GetCurrentFloor = 0x80240020
export const RAM_b_area_mim_mim_01 = 0x8021e48c
export const RAM_b_area_dgb_clubba_defenseTable_8021804C = 0x8021804c
export const RAM_kpa_95_PatrolAI_JumpInit = 0x80240810
export const RAM_kzn_23_SetEntityPositionF = 0x80240310
export const RAM_b_area_trd_part_2_fake_bowser_8021BD6C = 0x8021bd6c
export const RAM_b_area_dgb_dgb_02_beforeBattle_8021B0B0 = 0x8021b0b0
export const RAM_flo_11_exitWalk_80241488 = 0x80241488
export const RAM_ui_status_star_point_4_pal = 0x80101440
export const RAM_dgb_05_ClubbaNappingAI_FallAsleep = 0x80240e24
export const RAM_D_80253030_8FEE50 = 0x80253030
export const RAM_sam_07_MeleeHitbox_33 = 0x80240804
export const RAM_jan_02_Quizmo_ShouldQuizmoLeave = 0x80242150
export const RAM_b_area_arn_tubbas_heart_802255D8 = 0x802255d8
export const RAM_ModifyColliderFlags = 0x802c9dcc
export const RAM_D_80247B50_EE6510 = 0x80247b50
export const RAM_hos_02_FlyingAI_Chase = 0x80241de4
export const RAM_calculate_model_sizes = 0x8011612c
export const RAM_HES_ParalyzedBegin = 0x8010551c
export const RAM_b_area_sam_gray_magikoopa_GetSelectedMoveID = 0x80219998
export const RAM_kzn_17_PiranhaPlantAI_10 = 0x80240d48
export const RAM_func_80240960_86F460 = 0x80240960
export const RAM_dgb_08_802469E0 = 0x802469e0
export const RAM_ui_mario_head_png = 0x800fe340
export const RAM_b_area_pra3_duplighost_OnShockHit = 0x8021b178
export const RAM_flo_18_idle_80244B3C = 0x80244b3c
export const RAM_b_area_omo_sky_guy_idle_8022B19C = 0x8022b19c
export const RAM_obk_07_GetItemName = 0x802400b4
export const RAM_func_802BF920_320690 = 0x802bf920
export const RAM_dead_set_message_images = 0x80130c64
export const RAM_D_E006AF50 = 0xe006af50
export const RAM_D_8010BEF0 = 0x8010bef0
export const RAM_snd_set_song_variation_fade = 0x80055a18
export const RAM_D_8015A1E3 = 0x8015a1e3
export const RAM_D_8024F30C = 0x8024f30c
export const RAM_b_area_omo2_1_shy_squad_idleAnimations = 0x802313d0
export const RAM_IES_Melon = 0x8008da74
export const RAM_dead_guMtxCatF = 0x8006c7c0
export const RAM_b_area_trd_part_1_bullet_bill_takeTurn_802197C0 = 0x802197c0
export const RAM_dgb_16_802417C0 = 0x802417c0
export const RAM_D_80245958_BAA0D8 = 0x80245958
export const RAM_D_80150940 = 0x80150940
export const RAM_arn_02_npcSettings_802414A0 = 0x802414a0
export const RAM_b_area_pra3_swoopula_handleEvent = 0x80227a04
export const RAM_sbk_30_SpawnSunEffect = 0x80240338
export const RAM_au_SEFCmd_18 = 0x8004d3f8
export const RAM_b_area_kmr_part_3_final_jr_troopa_802315F0 = 0x802315f0
export const RAM_func_802A1050 = 0x802a1050
export const RAM_D_E00347B8 = 0xe00347b8
export const RAM_b_area_kpa2_FadeBackgroundToBlack = 0x8021c004
export const RAM_D_80154383 = 0x80154383
export const RAM_dgb_14_exitDoubleDoor_802401B4 = 0x802401b4
export const RAM_D_80244888_9BB208 = 0x80244888
export const RAM_fx_water_splash = 0x80071c30
export const RAM_gRenderModelEnvB = 0x8014b765
export const RAM_isk_04_func_80240814_97BE44 = 0x80240814
export const RAM_battle_partner_goombario = 0x802380e8
export const RAM_dgb_05_802414E0 = 0x802414e0
export const RAM_func_802A10E4_779D74 = 0x802a10e4
export const RAM_D_80232B5C_6848DC = 0x80232b5c
export const RAM_si = 0x8006ad90
export const RAM_b_area_kmr_part_3_final_jr_troopa_Dist3D = 0x80218e1c
export const RAM_au_SEFCmd_01_SetPan = 0x8004cea4
export const RAM_D_80245617 = 0x80245617
export const RAM_D_8010BEF8 = 0x8010bef8
export const RAM_dgb_08_pad_40FC = 0x802440fc
export const RAM_action_update_knockback = 0x802b6000
export const RAM_func_80240154_A27024 = 0x80240154
export const RAM_D_09000000_392440 = 0x09000000
export const RAM_kkj_17_varStash = 0x802408c0
export const RAM_func_80243CC0_A2DF00 = 0x80243cc0
export const RAM_ResetFromLava = 0x802c9ed8
export const RAM_bMarioHideAnims = 0x80283370
export const RAM_D_8024D580_C0AE00 = 0x8024d580
export const RAM_jan_03_ToadHouse_CamSetFOV = 0x80241574
export const RAM_pra_29_reflection_render_floor_fancy = 0x80240870
export const RAM_kzn_02_UpdateTexturePanSmooth = 0x80240000
export const RAM_dgb_05_npcGroup_80241B4C = 0x80241b4c
export const RAM_D_802491F0_EC2040 = 0x802491f0
export const RAM_tik_10_SuperBlock_PartnerSparkles2 = 0x802412f8
export const RAM_flo_13_npcGroup_80246434 = 0x80246434
export const RAM_b_area_sbk_pokey_8021CCAC = 0x8021ccac
export const RAM_shop_get_sell_price = 0x80280b78
export const RAM_dro_01_exitWalk_802449CC = 0x802449cc
export const RAM_gfx_task_background = 0x800269ec
export const RAM_battle_move_auto_multibounce_D_802A26A8 = 0x802a26a8
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021C594 = 0x8021c594
export const RAM_D_80250810_9D5BF0 = 0x80250810
export const RAM_red_impact_main = 0xe00d0000
export const RAM_D_E005C720 = 0xe005c720
export const RAM_battle_item_egg_missile_UseItemWithEffect = 0x802a1310
export const RAM_isk_04_UnkNpcAIFunc12 = 0x802401f0
export const RAM_dgb_01_PatrolAI_PostLoiter = 0x80242ba8
export const RAM_arn_08_npcSettings_80241220 = 0x80241220
export const RAM_sam_08_MeleeHitbox_Main = 0x802410fc
export const RAM_spr_make_quad_for_size = 0x802dbdbc
export const RAM_dgb_11_settings = 0x80240090
export const RAM_sbk_34_varStash = 0x80240d9c
export const RAM_btl_state_update_enemy_move = 0x80247570
export const RAM_dro_maps = 0x80090750
export const RAM_mim_02_HoppingAI_ChaseInit = 0x8024083c
export const RAM_flo_08_D_802462C4_pad = 0x8024642c
export const RAM_tik_20_set_script_owner_npc_col_height = 0x80240c74
export const RAM_kpa_113_UnkNpcAIFunc12 = 0x80240360
export const RAM_func_802405EC_AE5B7C = 0x802405ec
export const RAM_battle_move_auto_multibounce_D_802A1320 = 0x802a1320
export const RAM_func_8003646C = 0x8003646c
export const RAM_dgb_16_settings = 0x80241440
export const RAM_update_hud_elements = 0x80141610
export const RAM_D_8024EC00_C0C480 = 0x8024ec00
export const RAM_jan_04_UpdateTexturePanSmooth = 0x80240040
export const RAM_sbk_30_StationaryAI_Alert = 0x802405a4
export const RAM_flo_19_802409C0 = 0x802409c0
export const RAM_arn_08_80240BA0 = 0x80240ba0
export const RAM_ui_battle_cmd_aim_marker_6_pal = 0x8028e9c0
export const RAM_b_area_sam_gray_magikoopa_handleEvent = 0x8022c614
export const RAM_D_80249A74_A33CB4 = 0x80249a74
export const RAM_D_80246A00_C467B0 = 0x80246a00
export const RAM_omo_11_SuperBlock_HideBlockContent = 0x802407cc
export const RAM_arn_11_802408D8 = 0x802408d8
export const RAM_thunderbolt_ring_render = 0xe00b01f8
export const RAM_b_area_nok_koopa_troopa_statusTable_8021C998 = 0x8021ff18
export const RAM_b_area_sbk_formation_0F = 0x8021fdbc
export const RAM_shiny_flare_update = 0xe00da104
export const RAM_b_area_sam_pos_rocks_8 = 0x80230814
export const RAM_EDEA00_FlyingAI_WanderInit = 0x802401c4
export const RAM_D_090009B0_3D04A0 = 0x090009b0
export const RAM_tik_15_StashVars = 0x80240960
export const RAM_D_80098520 = 0x80098520
export const RAM_kpa_51_MagikoopaAI_TeleportAnim = 0x80242050
export const RAM_mim_11_Pipe_AwaitDownInput = 0x80240034
export const RAM_crash_screen_set_draw_info = 0x8002c890
export const RAM_D_E0026A30 = 0xe0026a30
export const RAM_D_E0022D30 = 0xe0022d30
export const RAM_D_E0030EF0 = 0xe0030ef0
export const RAM_b_area_sam2_monstar_OnDeath = 0x8021b55c
export const RAM_ui_arrow_left_png = 0x800fba20
export const RAM_func_80240530_8AC4C0 = 0x80240530
export const RAM_b_area_sam2_gray_magikoopa_flying = 0x8021f358
export const RAM_ui_red_bar1_png = 0x800fc980
export const RAM_kpa_13_UpdateTexturePanStepped = 0x80240164
export const RAM_battle_move_shrink_stomp_UnkMoveFunc1 = 0x802a1000
export const RAM_b_area_trd_part_1_paratroopa_partsTable_8021EC6C = 0x8021ec6c
export const RAM_b_area_isk_part_2_isk_01 = 0x8021fdbc
export const RAM_HES_Item_Goomnut = 0x80086af8
export const RAM_D_8024A9CC_A34C0C = 0x8024a9cc
export const RAM_tubba_heart_attack_main = 0xe00cc000
export const RAM_IES_Apple = 0x8008db54
export const RAM_func_80240844_D9B734 = 0x80240844
export const RAM_battle_item_insecticide_herb_UseItem = 0x802a1724
export const RAM_mgm_01_TallyPosX = 0x80241aec
export const RAM_func_8026BF48 = 0x8026bf48
export const RAM_pra_20_reflection_unk_resolve_anim = 0x80240020
export const RAM_HES_AimBlinkB = 0x80293764
export const RAM_D_80159670 = 0x80159670
export const RAM_D_E0084E50 = 0xe0084e50
export const RAM_func_80242390_EE8940 = 0x80242390
export const RAM_kmr_07_StationaryAI_LosePlayer = 0x802404f8
export const RAM_flo_08_UnkFloAI_Chase = 0x80241364
export const RAM_D_8010D674 = 0x8010d674
export const RAM_kmr_06_FlyingAI_LoiterInit = 0x802407d8
export const RAM_vtx_C24350 = 0x80203410
export const RAM_D_8029D910 = 0x8029d910
export const RAM_battle_item_electro_pop_PlayerGoHome = 0x802a1970
export const RAM_play_model_animation = 0x80120148
export const RAM_flo_18_npcSettings_80243F3C = 0x80243f3c
export const RAM_D_80243D70_A72150 = 0x80243d70
export const RAM_nok_01_Quizmo_CreateStage = 0x802414d8
export const RAM_b_area_kmr_part_3_kmr_03_clouds2 = 0x80232428
export const RAM_flo_23_set_script_owner_npc_anim = 0x80240394
export const RAM_mac_00_Pipe_GetCurrentFloor = 0x80240020
export const RAM_D_E0084E58 = 0xe0084e58
export const RAM_func_8026F60C = 0x8026f60c
export const RAM_b_area_isk_part_2_tutankoopa_statusTable_8021BA28 = 0x8021ba28
export const RAM_tik_20_Pipe_GetPointAheadOfPlayer = 0x80240548
export const RAM_dup_jan_06_foliage_setup_shear_mtx = 0x802423a0
export const RAM_func_802402F4_BC8154 = 0x802402f4
export const RAM_pra_09_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_func_8025DD60 = 0x8025dd60
export const RAM_HES_Item_PotatoSalad = 0x800835b8
export const RAM_D_E00DA520 = 0xe00da520
export const RAM_b_area_trd_part_1_paratroopa_takeTurn_8021FA24 = 0x8021fa24
export const RAM_b_area_hos_hos_02_beforeBattle_802258E0 = 0x802258e0
export const RAM_D_80247A40_CCB250 = 0x80247a40
export const RAM_IES_HammerThrow = 0x8008c1c4
export const RAM_dead_cos_rad = 0x8002b4c0
export const RAM_player_raycast_general = 0x800dee5c
export const RAM_b_area_mac_chan_init_8021CCDC = 0x8021ccdc
export const RAM_HES_Item_FPPlus_disabled = 0x80084608
export const RAM_HES_Item_HoneyCandy = 0x800831f8
export const RAM_D_80247BB8 = 0x80247bb8
export const RAM_b_area_trd_part_1_trd_05d_afterBattle_80226CB8 = 0x80226cb8
export const RAM_sam_07_PiranhaPlantAI_11 = 0x80241ac8
export const RAM_dgb_05_pad_1868 = 0x80241868
export const RAM_mac_03_Pipe_GetEntryPos = 0x802400f0
export const RAM_b_area_arn_hypergoomba_defenseTable_80218770 = 0x80218770
export const RAM_D_8024DF24 = 0x8024df24
export const RAM_b_area_mim_bzzap_UnkFloatFunc4 = 0x8021823c
export const RAM_D_80242560_A4EF80 = 0x80242560
export const RAM_kzn_17_func_80240814_97BE44 = 0x80242488
export const RAM_jan_03_Quizmo_AnswerResult = 0x8024fef0
export const RAM_b_area_kzn_spike_top_spikeTop_TakeTurn_Ceiling = 0x8021e3b4
export const RAM_D_8010C938 = 0x8010c938
export const RAM_PlaySoundAtCollider = 0x802ca614
export const RAM_battle_menu_StarPowerHudScripts = 0x802ab390
export const RAM_partner_flying_update_motion = 0x800ed5d0
export const RAM_kzn_08_SomeItemEntityFunc = 0x802408a8
export const RAM_D_802A3F28_721578 = 0x802a3f28
export const RAM_jan_04_GetEntityPosition = 0x80240b48
export const RAM_battle_star_star_storm_802A1A88 = 0x802a1a88
export const RAM_D_090011B0 = 0x090011b0
export const RAM_mgm_02_BoxModelIDs = 0x802428e0
export const RAM_HES_StoppedBegin = 0x80106610
export const RAM_dgb_00_80240A58 = 0x80240a58
export const RAM_gPauseBadgesCurrentScrollPos = 0x8027038c
export const RAM_battle_item_egg_missile_frame4_displayList = 0x802a2460
export const RAM_func_80241300_BD4B70 = 0x80241300
export const RAM_func_80242310_C5CA50 = 0x80242310
export const RAM_D_E002C9D8 = 0xe002c9d8
export const RAM_mac_02_Quizmo_StashVars = 0x802417c0
export const RAM_pra_29_SetPartnerFlags80000 = 0x80240eb8
export const RAM_b_area_kmr_part_3_kmr_06 = 0x8023341c
export const RAM_hos_06_LetterDelivery_CalcLetterPos = 0x8024086c
export const RAM_jan_09_WanderMeleeAI_Main = 0x80241170
export const RAM_b_area_kzn_spike_top_spikeTop_HandleEvent_Ceiling = 0x8021d0c0
export const RAM_battle_star_star_storm_802A1E20 = 0x802a1e20
export const RAM_func_802BB710_E2BE40 = 0x802bb710
export const RAM_D_80150020 = 0x80150020
export const RAM_kzn_20_bss = 0x802442d0
export const RAM_tik_03_UpdateTexturePanSmooth = 0x802402e0
export const RAM_b_area_nok_paratroopa_UnkBattleFunc1 = 0x80218a78
export const RAM_func_80058E84 = 0x80058e84
export const RAM_SetBattleCamParam = 0x8024e748
export const RAM_isk_13_StoneChompAI_HopInit = 0x80240e14
export const RAM_func_80240000_E12760 = 0x80240000
export const RAM_obk_03_GetEntityPosition = 0x802400c4
export const RAM_HES_HeaderMap = 0x802420c4
export const RAM_mac_01_Quizmo_HideEntities = 0x80242028
export const RAM_arn_05_npcGroup_802443AC = 0x802443ac
export const RAM_D_80242D80_BA06E0 = 0x80242d80
export const RAM_HES_Whirlwind1 = 0x802aa700
export const RAM_func_802181F8_4CF3A8 = 0x802181f8
export const RAM_D_802BDF30 = 0x802bdf30
export const RAM_proutSprintf = 0x80065548
export const RAM_btl_state_update_select_target = 0x802aa688
export const RAM_ED8E20_Pipe_GetPointAheadOfPlayer = 0x80240274
export const RAM_dead_get_screen_coords = 0x8002fcbc
export const RAM_kmr_03_SetCamera0MoveFlag1 = 0x8024033c
export const RAM_b_area_omo2_toy_tank_formation_shy_stack_2 = 0x8022f5c4
export const RAM_D_09000000_3AB030 = 0x09000000
export const RAM_create_status_icon_boost_hammer = 0x80047d30
export const RAM_b_area_trd_part_2_fake_bowser_nextTurn_80225438 = 0x80225438
export const RAM_dgb_06_exitSingleDoor_802403C0 = 0x802403c0
export const RAM_msg_get_glyph = 0x8012e8e0
export const RAM_D_E00D6E84 = 0xe00d6e84
export const RAM_D_E00E4E40 = 0xe00e4e40
export const RAM_D_E00AC7B0 = 0xe00ac7b0
export const RAM_spr_draw_npc_sprite = 0x802de3d8
export const RAM_b_area_trd_part_2_trd_04_80232B2C = 0x80232b2c
export const RAM_sam_06_ToadHouse_GetPartnerBackOut = 0x80242214
export const RAM_pause_get_menu_msg = 0x8024ee80
export const RAM_D_801565B0 = 0x801565b0
export const RAM_EB8E90_PatrolAI_Jump = 0x802407e4
export const RAM_b_area_jan_formation_10 = 0x80228a88
export const RAM_D_80257B28 = 0x80257b28
export const RAM_end_00_DisposeFX3D = 0x80242ad4
export const RAM_sbk_00_SetNpcB5_3 = 0x80240040
export const RAM_arn_12_main = 0x8024034c
export const RAM_gLavaResetList = 0x802dada0
export const RAM_arn_08_80240CC0 = 0x80240cc0
export const RAM_trd_00_SyncStatusMenu = 0x8024060c
export const RAM_flo_19_func_80240660_CE3D50 = 0x80240660
export const RAM_omo_07_FlyingAI_JumpVels = 0x80243a14
export const RAM_vtx_C29AB0 = 0x80208b70
export const RAM_D_80099E20 = 0x80099e20
export const RAM_dgb_01_FlyingAI_JumpInit = 0x80240cfc
export const RAM_func_802A2684 = 0x802a2684
export const RAM_func_80281C84 = 0x80281c84
export const RAM_sbk_56_SuperBlock_RadiateFaintEnergyFX = 0x80240628
export const RAM_func_80240000_9FA7F0 = 0x80240000
export const RAM_func_80242708_854878 = 0x80242708
export const RAM_b_area_sam2_paragoomba_handleEvent_8021CEBC = 0x8021dc3c
export const RAM_osCreateMesgQueue = 0x80065580
export const RAM_D_80257D48_8C7DB8 = 0x80257d48
export const RAM_EDEA00_LakituAI_Loiter = 0x80241b94
export const RAM_dro_01_8024C2EC = 0x8024c2ec
export const RAM_pra_33_SetPartnerFlags80000 = 0x80240eb8
export const RAM_battle_move_auto_jump_CheckForAPress = 0x802a1130
export const RAM_D_802EAC40 = 0x802eac40
export const RAM_func_80271258 = 0x80271258
export const RAM_jan_00_UpdateTexturePanStepped = 0x80240124
export const RAM_ui_status_pow_star_2_pal = 0x80100960
export const RAM_dgb_01_SentinelAI_GrabPlayer = 0x80241e70
export const RAM_D_802B6728_E27508 = 0x802b6728
export const RAM_entity_fog_dist_min = 0x8015438c
export const RAM_motion_blur_flame_init = 0xe00a2118
export const RAM_D_802A2000_007355A0 = 0x802a2000
export const RAM_HES_Item_AttackFXE = 0x80085c58
export const RAM_ui_status_text_5_pal = 0x80100320
export const RAM_arn_07_80243FE8 = 0x80243fe8
export const RAM_b_area_pra2_crystal_king_formation_bit_2 = 0x8021d604
export const RAM_nuScExecuteGraphics = 0x8005ed14
export const RAM_btl_state_update_first_strike = 0x80247b04
export const RAM_b_area_pra3_gray_magikoopa_StartRumbleWithParams = 0x80219df0
export const RAM_fx_emote = 0x8006fe90
export const RAM_au_BGMCmd_F6_TrackVolumeFade = 0x8004fed0
export const RAM_func_80054DA8 = 0x80054da8
export const RAM_gPauseMsg_47 = 0x8026f70c
export const RAM_func_80051434 = 0x80051434
export const RAM_flo_23_FlowerGuard_SetItemEntityPosition = 0x80240b68
export const RAM_D_80240C0F_90C2DF = 0x80240c0f
export const RAM_flo_09_init_80243954 = 0x80243954
export const RAM_kzn_03_MeleeHitbox_32 = 0x80240cd4
export const RAM_gHudElementCacheTableRasterBattle = 0x80158580
export const RAM_dgb_09_npcAI_8024411C = 0x8024411c
export const RAM_sam_01_Quizmo_SetStageLightsDelay = 0x8024113c
export const RAM_battle_item_unknown_item_UseItemWithEffect = 0x802a1340
export const RAM_D_800D95E8 = 0x800d95e8
export const RAM_b_area_mac_lee_idle_80223574 = 0x80223574
export const RAM_b_area_omo_formation_26 = 0x80230500
export const RAM_D_80243650_8A5A20 = 0x80243650
export const RAM_state_drawUI_language_select = 0x800355c4
export const RAM_bModelLocalVtxBuffers = 0x801521d0
export const RAM_guLookAtHilite = 0x80063244
export const RAM_kkj_20_ToadHouse_PartnerSuspendAbilityScript = 0x80240434
export const RAM_flo_11_Pipe_ExitVertical = 0x80240e40
export const RAM_pra_34_reflection_setup_wall = 0x80240128
export const RAM_pra_20_reflection_render_wall = 0x802402f0
export const RAM_world_goombaria_take_out = 0x802bd1f0
export const RAM_sam_01_SomeItemEntityFunc = 0x80241da8
export const RAM_stars_burst_update = 0xe0042234
export const RAM_kmr_03_settings = 0x80240680
export const RAM_D_800B0AD4 = 0x800b0ad4
export const RAM_func_800F0B3C = 0x800f0b3c
export const RAM_flo_13_802438CC = 0x802438cc
export const RAM_flo_08_SuperBlock_AnimateEnergyOrbs = 0x80240600
export const RAM_pra_35_MeleeHitbox_Main = 0x8024216c
export const RAM_func_80110BF8 = 0x80110bf8
export const RAM_bss_802BFEF0 = 0x802bfef0
export const RAM_D_802AD10D = 0x802ad10d
export const RAM_dro_02_ToadHouse_AwaitScriptComplete = 0x802435c8
export const RAM_ui_bow_pal = 0x800ff120
export const RAM_D_80242EB0_992690 = 0x80242eb0
export const RAM_mac_03_Quizmo_SetStageLightsDelay = 0x80240eec
export const RAM_IES_ITEM_12F = 0x8008d7b4
export const RAM_dgb_03_PatrolAI_LoiterInit = 0x80240f20
export const RAM_b_area_nok_koopa_troopa_defenseTable_8021C960 = 0x8021fee0
export const RAM_get_trigger_by_id = 0x80145cd0
export const RAM_LoopingSounds = 0x8014f2d0
export const RAM_contRetrace = 0x8006065c
export const RAM_arn_08_802403B0 = 0x802403b0
export const RAM_b_area_kzn_kzn_02_80227A2C = 0x80227a2c
export const RAM_D_09000420_3AB450 = 0x09000420
export const RAM_kmr_09_FlyingAI_Jump = 0x80240c54
export const RAM_D_802BDF38 = 0x802bdf38
export const RAM_D_8023D274 = 0x8023d274
export const RAM_sbk_21_SpawnSunEffect = 0x80240000
export const RAM_D_801096B0 = 0x801096b0
export const RAM_b_area_nok_kent_c_koopa = 0x80227bf8
export const RAM_func_802413C0_C8EF70 = 0x802413c0
export const RAM_HES_Item_BooRecordOutline = 0x800816f8
export const RAM_sam_10_MeleeHitbox_30 = 0x802403d0
export const RAM_kmr_12_ReadWestSign = 0x8024032c
export const RAM_D_80249A78_A33CB8 = 0x80249a78
export const RAM_dead_general_heap_free = 0x8002bf68
export const RAM_D_802DBB60 = 0x802dbb60
export const RAM_D_8024168C_9090BC = 0x8024168c
export const RAM_func_802BC308_E2EC38 = 0x802bc308
export const RAM_jan_01_HoppingAI_LosePlayer = 0x80240eec
export const RAM_tik_07_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_D_80077980 = 0x80077980
export const RAM_D_802435E8_ACE108 = 0x802435e8
export const RAM_D_E00E0A48 = 0xe00e0a48
export const RAM_battle_item_super_soda_script6 = 0x802a1aec
export const RAM_kzn_17_LetterDelivery_CalcLetterPos = 0x80242af8
export const RAM_D_80242340_C3CD50 = 0x80242340
export const RAM_D_09002780 = 0x09002780
export const RAM_b_area_omo2_toy_tank_statusTable = 0x8022c844
export const RAM_jan_06_HoppingAI_LosePlayer = 0x802412ec
export const RAM_b_area_kmr_part_2_red_goomba_ArcsinDeg = 0x80218280
export const RAM_D_A4040010 = 0xa4040010
export const RAM_arn_07_exitWalk_80242978 = 0x80242978
export const RAM_D_E00E4E48 = 0xe00e4e48
export const RAM_gMoveScriptTable = 0x80293e80
export const RAM_tik_12_AwaitPlayerNearNpc = 0x8024217c
export const RAM_func_802A9764 = 0x802a9764
export const RAM_gItemIconRasterOffsets = 0x8008e3d8
export const RAM_ui_battle_status_transparent_3_pal = 0x80102fc0
export const RAM_SetPushBlockFallEffect = 0x802837ac
export const RAM_kpa_115_ProjectileHitbox_32 = 0x802405e8
export const RAM_kzn_04_SuperBlock_PartnerSparkles4 = 0x80240fd0
export const RAM_D_80249CF4 = 0x80249cf4
export const RAM_appendGfx_player = 0x800e12f4
export const RAM_nok_01_Quizmo_Worker = 0x80251c40
export const RAM_D_802D9EDC = 0x802d9edc
export const RAM_UseEntryHeading = 0x802d1f70
export const RAM_b_area_isk_part_1_vector3D_80224070 = 0x80224070
export const RAM_D_80294240 = 0x80294240
export const RAM_func_802BD4E0_324DF0 = 0x802bd4e0
export const RAM_ui_kalmar_png = 0x800fdc80
export const RAM_fx_debuff = 0x80070af0
export const RAM_dup_mac_00_AddPlayerHandsOffset = 0x80243574
export const RAM_flo_21_80241600 = 0x80241600
export const RAM_D_800DA55C = 0x800da55c
export const RAM_mac_05_PatrolNoAttackAI_Main = 0x80241024
export const RAM_state_init_battle = 0x800338d0
export const RAM_tik_04_Pipe_GetCurrentFloor = 0x8024032c
export const RAM_sbk_20_SpawnSunEffect = 0x80240000
export const RAM_sbk_30_StationaryAI_AlertInit = 0x80240544
export const RAM_D_80159AE8 = 0x80159ae8
export const RAM_flo_13_UnkFunc41 = 0x80240040
export const RAM_init_item_entity_list = 0x801310cc
export const RAM_get_global_flag = 0x801454bc
export const RAM_b_area_sbk_bandit_defenseTable_8021D5B4 = 0x8021d5b4
export const RAM_D_8007F1D0 = 0x8007f1d0
export const RAM_D_8007795C = 0x8007795c
export const RAM_b_area_kzn_white_magikoopa_UnkFunc52 = 0x80218c48
export const RAM_func_802A92DC_4236CC = 0x802a92dc
export const RAM_kmr_09_FlyingAI_LoiterInit = 0x802407d8
export const RAM_b_area_trd_part_2_fake_bowser_8021CB98 = 0x8021cb98
export const RAM_IES_FrozenFries = 0x8008d454
export const RAM_stat_change_init = 0xe00ac11c
export const RAM_GetAnimatedNodePosition = 0x802cd5c0
export const RAM_func_80218DF0_59F4D0 = 0x80218df0
export const RAM_b_area_kmr_part_3_kmr_04_beforeBattle_80232B08 = 0x80232b08
export const RAM_vtx_C27460 = 0x80206520
export const RAM_render_messages = 0x80124204
export const RAM_b_area_pra3_swoopula_UnkBattleFunc2_2 = 0x80219770
export const RAM_b_area_omo_groove_guy_80226DEC = 0x80226dec
export const RAM_sam_05_MeleeHitbox_30 = 0x80240000
export const RAM_b_area_sam2_sam_03_UnkModelStuffScript1 = 0x80223090
export const RAM_HES_Item_DDownJump_disabled = 0x800855c8
export const RAM_fio_save_game = 0x8002b374
export const RAM_b_area_hos_ember_init_8021AEB4 = 0x8021aeb4
export const RAM_func_80260DD8 = 0x80260dd8
export const RAM_flo_14_entryList = 0x80242390
export const RAM_sbk_20_Bandit_DropCoin = 0x80240040
export const RAM_dup6_b_area_kpa2_GetPlayerHpPercent = 0x8021b08c
export const RAM_func_8002E7CC = 0x8002e7cc
export const RAM_sbk_34_LetterDelivery_SaveNpcAnim = 0x80240728
export const RAM_omo_06_CheckItemFlags40 = 0x80240ad8
export const RAM_flo_08_SuperBlock_PartnerSparkles4 = 0x80240cc0
export const RAM_hud_element_create_transform_C = 0x80145070
export const RAM_D_801510E8 = 0x801510e8
export const RAM_hud_element_free_transform = 0x80145108
export const RAM_kpa_09_ProjectileHitbox_32 = 0x80240518
export const RAM_dgb_07_settings = 0x80241450
export const RAM_b_area_kmr_part_2_red_goomba_2_ArcsinDeg = 0x802187b0
export const RAM_b_area_jan2_white_magikoopa_idle_80223E14 = 0x8021a1c4
export const RAM_D_802835DC = 0x802835dc
export const RAM_falling_leaves_init = 0xe00581ac
export const RAM_D_802ACC6C = 0x802acc6c
export const RAM_b_area_jan_spike_top = 0x80223c18
export const RAM_b_area_omo_spy_guy_idle_802276C4 = 0x802276c4
export const RAM_kzn_08_PiranhaPlantAI_11 = 0x802439b4
export const RAM_evt_handle_case_AND = 0x802c549c
export const RAM_jan_02_Quizmo_NPC_OnRender = 0x802419e0
export const RAM_jan_08_SuperBlock_ShowSelectPartnerMenu = 0x8024082c
export const RAM_D_8024F090_C0C910 = 0x8024f090
export const RAM_mac_00_Quizmo_HideWorld = 0x80240a5c
export const RAM_b_area_sam_duplighost_bombette_parts = 0x80220434
export const RAM_get_msg_properties = 0x80125f68
export const RAM_kkj_14_UpdateTexturePanSmooth = 0x80240050
export const RAM_ui_battle_cont_c_down_down_pal = 0x8028c9c0
export const RAM_func_80240C94_9086C4 = 0x80240c94
export const RAM_b_area_omo_formation_1A = 0x802300f4
export const RAM_D_80248CF0_82BFB0 = 0x80248cf0
export const RAM_pra_33_reflection_unk_resolve_anim = 0x80240020
export const RAM_D_80220750 = 0x80220750
export const RAM_flo_00_8024834C = 0x8024834c
export const RAM_screen_overlay_backZoom = 0x8015690c
export const RAM_b_area_mac_chan_takeTurn_Chan = 0x8021c2d4
export const RAM_HES_Spirit3Missing = 0x802424b8
export const RAM_func_80240000_A6ACA0 = 0x80240000
export const RAM_HES_Item_YellowBerry = 0x80086a38
export const RAM_D_80228430_6CBFC0 = 0x80228430
export const RAM_dgb_03_ClubbaNappingAI_Sleep = 0x80241d04
export const RAM_b_area_trd_part_2_blue_ninja_koopa_intTable_8022DD30 = 0x8022dd30
export const RAM_nok_15_ParatroopaAI_Overshoot = 0x80242190
export const RAM_green_impact_main = 0xe0064000
export const RAM_b_area_sam2_gray_magikoopa_handleEvent = 0x8021f434
export const RAM_flo_10_npcGroupList_80244054 = 0x80244054
export const RAM_lightning_bolt_appendGfx = 0xe00bc374
export const RAM_b_area_arn_hyper_cleft_StartRumbleWithParams = 0x802185c0
export const RAM_dgb_15_dgb_14_name_hack = 0x80243028
export const RAM_EB5130_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802384B0_703FA0 = 0x802384b0
export const RAM_D_80097DC8 = 0x80097dc8
export const RAM_func_802BD300_318050 = 0x802bd300
export const RAM_battle_partner_goombario_handleEvent_80239360 = 0x80239360
export const RAM_GetTargetListLength = 0x8026e864
export const RAM_D_800A0978 = 0x800a0978
export const RAM_arn_04_PatrolNoAttackAI_Main = 0x80240b94
export const RAM_D_802422D0_D668B0 = 0x802422d0
export const RAM_D_80248388 = 0x80248388
export const RAM_tik_07_SuperBlock_SetOverride40 = 0x802402e0
export const RAM_fold_set_state_flags = 0x8013af20
export const RAM_b_area_trd_part_1_trd_02d_afterBattle_80225418 = 0x80225418
export const RAM_b_area_nok_paragoomba_takeTurn_8021BD5C = 0x8021bd5c
export const RAM_pra_05_SetGameStatusUnk84_1 = 0x80240000
export const RAM_b_area_omo_formation_10 = 0x8022fde4
export const RAM_b_area_kmr_part_3_final_jr_troopa_statusTable_8022C594 = 0x8022c594
export const RAM_D_80242010_C80190 = 0x80242010
export const RAM_b_area_isk_part_2_isk_02c_afterBattle_8021FFE4 = 0x8021ffe4
export const RAM_arn_03_func_80240E90_BDFC20 = 0x80240e90
export const RAM_func_80241200_D4E240 = 0x80241200
export const RAM_D_A4300004 = 0xa4300004
export const RAM_battle_move_multibounce_D_802A14F0 = 0x802a14f0
export const RAM_kmr_02_settings = 0x02800240
export const RAM_BindNpcInteract = 0x800450a4
export const RAM_vtx_C29BB0 = 0x80208c70
export const RAM_func_80240000_D4BA20 = 0x80240000
export const RAM_b_area_jan2_white_magikoopa_statusTable_80223BE4 = 0x80219f94
export const RAM_dup_mac_00_LetterDelivery_SavedNpcAnim = 0x802560a4
export const RAM_underwater_update = 0xe00ba174
export const RAM_battle_action_cmd_water_block_cloud_png = 0x802aabc0
export const RAM_battle_star_peach_dash_UnkStarFunc2 = 0x802a10ac
export const RAM_D_80232C24_6849A4 = 0x80232c24
export const RAM_omo_17_SetAngleClamped = 0x8024001c
export const RAM_battle_star_time_out_802A18B8 = 0x802a18b8
export const RAM_b_area_jan2_putrid_piranha_breath = 0x80219908
export const RAM_b_area_kmr_part_3_mage_jr_troopa_handleEvent_8022ADD8 = 0x8022add8
export const RAM_battle_menu_centeredMessagesD = 0x802ab4e0
export const RAM_func_80242084_8EDEA4 = 0x80242084
export const RAM_main_menu_window_update = 0x80147650
export const RAM_ui_battle_swap_arrow_left_png = 0x8028a220
export const RAM_D_80224DA0 = 0x80224da0
export const RAM_jan_12_MeleeHitbox_32 = 0x802407b0
export const RAM_battle_item_egg_missile_GiveRefundCleanup = 0x802a11d4
export const RAM_b_area_omo_sky_guy_idle_80229A24 = 0x80229a24
export const RAM_func_80055C2C = 0x80055c2c
export const RAM_sbk_44_SetNpcB5_3 = 0x80240140
export const RAM_mim_06_PiranhaPlantAI_10 = 0x80240fa8
export const RAM_HES_AimShimmerG = 0x8029356c
export const RAM_EEDF50_FlyingNoAttackAI_20 = 0x80242724
export const RAM_D_8024C918_C0A198 = 0x8024c918
export const RAM_tik_19_SuperBlock_CountEligiblePartners = 0x80240710
export const RAM_D_802704D2 = 0x802704d2
export const RAM_func_80238540_7081E0 = 0x80238540
export const RAM_D_A4800018 = 0xa4800018
export const RAM_dgb_01_D_802520DC_C0F95C = 0x802520dc
export const RAM_func_E00D8630 = 0xe00d8630
export const RAM_filemenu_8024C110 = 0x8024c110
export const RAM_shim_sfx_play_sound_at_position = 0xe0200680
export const RAM_BattleCam_BoomZOffset = 0x8029f286
export const RAM_jan_03_Quizmo_SetVannaAnim_Idle = 0x8024268c
export const RAM_nok_12_PatrolAI_LoiterInit = 0x80241144
export const RAM_dup4_b_area_pra_GetSelectedMoveID = 0x802187c8
export const RAM_tik_20_Pipe_AwaitDownInput = 0x80240340
export const RAM_D_802BCCF8_E2F628 = 0x802bccf8
export const RAM_D_800B0C5C = 0x800b0c5c
export const RAM_kpa_50_UnkNpcAIFunc12 = 0x802413fc
export const RAM_flo_22_triggerCoord_80241F28 = 0x80241f28
export const RAM_SaveBlockResultPrinter = 0x802eb39c
export const RAM_dgb_02_pad_1548 = 0x80241548
export const RAM_b_area_jan_jan_04_foregroundModelList_802284C0 = 0x802284c0
export const RAM_pra_09_SetPartnerFlags80000 = 0x80240eb8
export const RAM_HES_MoveGreenOrb = 0x80109388
export const RAM_gPausePartnersDL = 0x8024f750
export const RAM_obk_03_LetterDelivery_Init = 0x802405dc
export const RAM_func_80240564_BCF154 = 0x80240564
export const RAM_b_area_trd_part_2_red_ninja_koopa_statusTable_80228518 = 0x80228518
export const RAM_b_area_trd_part_2_blue_ninja_koopa_takeTurn_8022E414 = 0x8022e414
export const RAM_b_area_omo_formation_19 = 0x80230084
export const RAM_D_802EB0F8 = 0x802eb0f8
export const RAM_D_8023D28C = 0x8023d28c
export const RAM_D_802417E4_EB2644 = 0x802417e4
export const RAM_world_sushie_post_battle = 0x802bfa58
export const RAM_b_area_omo_spy_guy_DisableRandomAbility = 0x80218b38
export const RAM_D_8024560B = 0x8024560b
export const RAM_get_npc_unsafe = 0x8003aaec
export const RAM_battle_item_pow_block_GiveRefundCleanup = 0x802a11d4
export const RAM_b_area_pra3_formation_04 = 0x80232578
export const RAM_dgb_08_FlyingAI_Chase = 0x80242554
export const RAM_dgb_08_func_80243C10_C439C0 = 0x80243c10
export const RAM_EA8AE0_UpdateTexturePanStepped = 0x80240124
export const RAM_D_80241684_BAB7C4 = 0x80241684
export const RAM_battle_star_peach_beam_UnkStarFunc = 0x802a1494
export const RAM_func_8024034C_A5A37C = 0x8024034c
export const RAM_b_area_trd_part_1_formation_05 = 0x802185c4
export const RAM_vtx_C29330 = 0x802083f0
export const RAM_b_area_sam_duplighost_sushie_idleAnimations = 0x802247e8
export const RAM_b_area_jan2_jan_03b_afterBattle_8021F440 = 0x8021f440
export const RAM_D_802BCCB0_E2F5E0 = 0x802bccb0
export const RAM_vtx_C25600 = 0x802046c0
export const RAM_omo_08_UnkFloatFunc = 0x80240388
export const RAM_D_E009EE18 = 0xe009ee18
export const RAM_trd_10_StarSpiritEffectFunc6 = 0x80240708
export const RAM_gPauseWS_2 = 0x8026f970
export const RAM_b_area_kmr_part_2_goomba_king_80221CD4 = 0x80221cd4
export const RAM_b_area_sam_sam_02_UnkModelStuffScript3 = 0x8022f4f4
export const RAM_b_area_sam_gray_magikoopa_attackWandStrike = 0x8022deb4
export const RAM_kmr_03_makeEntities = 0x80241530
export const RAM_b_area_sbk_pokey_takeTurn_8021A018 = 0x8021a018
export const RAM_battle_item_dusty_hammer_png = 0x802a1800
export const RAM_trd_02_GetEntityPosition = 0x80240034
export const RAM_pause_badges_handle_input = 0x802490a8
export const RAM_b_area_pra3_duplighost_pos_summon = 0x8021bb68
export const RAM_nok_15_set_script_owner_npc_anim = 0x802402e0
export const RAM_b_area_nok_kent_c_koopa_idleAnimations_802279D0 = 0x802279d0
export const RAM_func_80240300_907D30 = 0x80240300
export const RAM_obk_03_SetEntityPosition = 0x80240000
export const RAM_D_800A3514 = 0x800a3514
export const RAM_dro_02_EVS_Quizmo_SetQuizCamera = 0x80244f84
export const RAM_b_area_mac_lee_takeTurn_802229C4 = 0x802229c4
export const RAM_b_area_omo_medi_guy_idleAnimations_8021F890 = 0x80223410
export const RAM_b_area_sam_stageTable = 0x80231290
export const RAM_arn_10_main = 0x8024034c
export const RAM_pause_set_cursor_opacity = 0x80242ba0
export const RAM_dgb_05_npcAISettings_802419AC = 0x802419ac
export const RAM_D_80243BB8_C91768 = 0x80243bb8
export const RAM_b_area_sam_sam_03_UnkFloatFunc2 = 0x8021ad6c
export const RAM_dup_b_area_flo_GetSelectedMoveID = 0x802186b8
export const RAM_func_802BC148_E2C878 = 0x802bc148
export const RAM_func_80240310_8EC130 = 0x80240310
export const RAM_D_80257F25 = 0x80257f25
export const RAM_HES_StarBeam = 0x80107bf8
export const RAM_ui_battle_cmd_charge_2_png = 0x8028cee0
export const RAM_D_80099E28 = 0x80099e28
export const RAM_func_802A97F8 = 0x802a97f8
export const RAM_func_80218C74_48DE14 = 0x80218c74
export const RAM_func_802A9000_425B50 = 0x802a9000
export const RAM_func_802A4A10 = 0x802a4a10
export const RAM_func_802DE8DC = 0x802de8dc
export const RAM_b_area_isk_part_1_buzzy_beetle_intTable_8021F1DC = 0x8021f1dc
export const RAM_nok_15_FlyingAI_Main = 0x80241dc0
export const RAM_dro_02_D_80248CDC_971E9C = 0x80248cdc
export const RAM_D_8024E6D8_C0BF58 = 0x8024e6d8
export const RAM_func_800EF4E0 = 0x800ef4e0
export const RAM_func_802A9310_42D220 = 0x802a9310
export const RAM_D_E0058790 = 0xe0058790
export const RAM_flo_18_pad_8B8 = 0x802408b8
export const RAM_jan_02_UpdateTexturePanSmooth = 0x80240030
export const RAM_func_8024051C_9EE10C = 0x8024051c
export const RAM_func_80240568_AF7EB8 = 0x80240568
export const RAM_nok_14_ParatroopaAI_Dive = 0x80241fb4
export const RAM_func_80240A10_BCF600 = 0x80240a10
export const RAM_HES_AimMarkerD = 0x80292ee4
export const RAM_pra_02_reflection_render_floor = 0x8024068c
export const RAM_battle_menu_StarPowerMovesHudScripts = 0x802ab398
export const RAM_gRenderModelPrimG = 0x8014b761
export const RAM_EA36E0_UnkFunc49 = 0x80242f9c
export const RAM_kpa_09_ProjectileAI_Reflect = 0x80240b38
export const RAM_iwa_01_MontyMoleAI_ThrowRock = 0x80241b74
export const RAM_gPauseTutorialScrollPos = 0x8024f110
export const RAM_animator_make_mirrorY = 0x8011e014
export const RAM_func_802401FC_AF7B4C = 0x802401fc
export const RAM_b_area_pra3_white_clubba = 0x8022b268
export const RAM_quizmo_audience_init = 0xe00a80fc
export const RAM_nok_15_foliage_setup_shear_mtx = 0x80242540
export const RAM_arn_08_idle_80241278 = 0x80241278
export const RAM_func_80218070_557450 = 0x80218070
export const RAM_b_area_iwa_iwa_02 = 0x802215cc
export const RAM_D_80247430_C7E110 = 0x80247430
export const RAM_b_area_kzn_formationTable = 0x80229e74
export const RAM_dup2_b_area_pra_UnkBattleFunc1 = 0x802187f4
export const RAM_fx_fire_breath = 0x80070d30
export const RAM_iwa_01_ProjectileHitbox_32 = 0x80240998
export const RAM_omo_17_FlyingAI_Wander = 0x80241164
export const RAM_entity_ItemlessBlock_init = 0x802e46bc
export const RAM_arn_13_defeat_802406F4 = 0x802406f4
export const RAM_dup_kkj_11_UnkAtan2Func = 0x80240e34
export const RAM_b_area_omo2_3_shy_stack_init = 0x8023149c
export const RAM_func_802189F0_4EFE90 = 0x802189f0
export const RAM_IES_HoneyUltra = 0x8008d014
export const RAM_D_09000AF0_32D9B0 = 0x09000af0
export const RAM_ui_fp_cost_reduced_twice_pal = 0x800fc320
export const RAM_HES_Item_Pebble_disabled = 0x80086348
export const RAM_D_802A2D5C_76797C = 0x802a2d5c
export const RAM_EED1E0_SuperBlock_PartnerSparkles2 = 0x80240d58
export const RAM_ui_status_coin_0_pal = 0x80100b80
export const RAM_b_area_tik2_FadeBackgroundToBlack = 0x802182ec
export const RAM_update_encounters_neutral = 0x8003f7cc
export const RAM_dro_01_Quizmo_AudienceEffect = 0x8024dfe4
export const RAM_D_802EB0F0 = 0x802eb0f0
export const RAM_rspbootUcodeBuffer = 0x800d9780
export const RAM_tik_19_SuperBlock_PartnerSparkles4 = 0x802412b0
export const RAM_HES_StatStarPiece_2 = 0x80242a7c
export const RAM_func_80240040_EF2650 = 0x80240040
export const RAM_tik_19_SuperBlock_WhiteScreenFlash = 0x80241128
export const RAM_func_8024239C_C5CADC = 0x8024239c
export const RAM_func_80240350_C7E4D0 = 0x80240350
export const RAM_D_A0000000 = 0xa0000000
export const RAM_ForceHomePos = 0x8026a1ec
export const RAM_IES_1DC430 = 0x8008bb84
export const RAM_D_8029FB74 = 0x8029fb74
export const RAM_flo_23_npcGroup_80241F98 = 0x80241f98
export const RAM_D_802AD060 = 0x802ad060
export const RAM_gCurrentFogSettings = 0x80153264
export const RAM_dgb_16_pad_XXX = 0x802431e8
export const RAM_filemenu_info_init = 0x80247fd0
export const RAM_func_80240000_D57430 = 0x80240000
export const RAM_D_80249960_A33BA0 = 0x80249960
export const RAM_func_80241B28_97F5F8 = 0x80241b28
export const RAM_au_sfx_clear_queue = 0x8004b698
export const RAM_ShowStatusMenu = 0x802d59dc
export const RAM_D_E003E888 = 0xe003e888
export const RAM_b_area_kzn_white_magikoopa_wMagikoopa_WandStrike = 0x802256c4
export const RAM_b_area_hos_magikoopa_init_8021E100 = 0x8021e100
export const RAM_b_area_isk_part_2_isk_04_foregroundModelList_80220194 = 0x80220194
export const RAM_HES_OptionMonoOff = 0x80242358
export const RAM_flo_11_idle_80242810 = 0x80242810
export const RAM_D_8024F478 = 0x8024f478
export const RAM_kzn_02_FlyingAI_Main = 0x802417ec
export const RAM_b_area_sam2_sam_02c_Set80071270_0_16 = 0x802196d8
export const RAM_func_802C016C = 0x802bd26c
export const RAM_dgb_00_npcGroup_802427A4 = 0x802427a4
export const RAM_ItemChoiceList = 0x8024f248
export const RAM_b_area_trd_part_1_bullet_bill_partsTable_80219018 = 0x80219018
export const RAM_D_80224D60 = 0x80224d60
export const RAM_func_80261DD4 = 0x80261dd4
export const RAM_D_80231448_63CFE8 = 0x80231448
export const RAM_clear_npcs = 0x800384bc
export const RAM_D_80073E0C = 0x80073e0c
export const RAM_IES_SleepySheep = 0x8008dcd4
export const RAM_D_E005A758 = 0xe005a758
export const RAM_b_area_jan2_white_magikoopa_init_80225F58 = 0x8021c308
export const RAM_PlayerActionsTable = 0x800f7c8c
export const RAM_b_area_nok_paragoomba_defenseTable_8021B11C = 0x8021b11c
export const RAM_BindNpcDefeat = 0x800451dc
export const RAM_entity_SuperBlockContent_setupGfx = 0x802e586c
export const RAM_CloseMessage = 0x802d0a98
export const RAM_HES_HeaderItems = 0x80242040
export const RAM_EB9D90_UnkFloatFunc = 0x8024121c
export const RAM_partner_flying_update_player_tracking = 0x800ed4b8
export const RAM_b_area_kmr_part_2_blue_goomba_2_partsTable_8021D4B8 = 0x8021d4b8
export const RAM_D_80242568_A4EF88 = 0x80242568
export const RAM_D_E010AF84 = 0xe010af84
export const RAM_func_802A1518_7957F8 = 0x802a1518
export const RAM_EC9D00_SuperBlock_PartnerSparkles4 = 0x80240cc0
export const RAM_floating_flower_appendGfx = 0xe001a440
export const RAM_func_802424CC_A3B9AC = 0x802424cc
export const RAM_test_ray_colliders = 0x8005ccd0
export const RAM_dgb_13_80240680 = 0x80240680
export const RAM_func_80241274_D6FC24 = 0x80241274
export const RAM_vtx_C27220 = 0x802062e0
export const RAM_MusicCurrentVolume = 0x80159ae6
export const RAM_mac_01_PatrolAI_Move = 0x802411d8
export const RAM_jan_06_HoppingAI_Loiter = 0x80240d68
export const RAM_b_area_iwa_buzzar_8021DCB8 = 0x8021dcb8
export const RAM_sbk_56_SpawnSunEffect = 0x80240000
export const RAM_isk_05_HoppingAI_LosePlayer = 0x80240bac
export const RAM_func_802409A8_D9B898 = 0x802409a8
export const RAM_func_80240790_BB7800 = 0x80240790
export const RAM_jan_03_Quizmo_NPC_OnRender = 0x80241f68
export const RAM_mac_02_Quizmo_UpdateRecords = 0x80241eac
export const RAM_SetSpriteShading = 0x802d9700
export const RAM_D_800B0B70 = 0x800b0b70
export const RAM_func_802CABE8 = 0x802cabe8
export const RAM_dro_02_init_8024D790 = 0x8024d790
export const RAM_fx_46_main = 0xe008c000
export const RAM_b_area_omo_blue_shy_guy_statusTable_8021AD24 = 0x8021ad24
export const RAM_D_09002250_388600 = 0x09002250
export const RAM_omo_07_SetCamera0MoveFlag1 = 0x802431f8
export const RAM_b_area_kzn_kzn_02_802278B0 = 0x802278b0
export const RAM_b_area_kzn_kzn_05_afterBattle_8022974C = 0x8022974c
export const RAM_flo_24_entryList = 0x80240590
export const RAM_ALT_reset_battle_status = 0x80072bb0
export const RAM_D_8024FBA0 = 0x8024fba0
export const RAM_has_enchanted_part = 0x80276f50
export const RAM_wPartners = 0x800f803c
export const RAM_jan_22_PatrolAI_Loiter = 0x802404c0
export const RAM_arn_02_CleftAI_RevUp = 0x80240694
export const RAM_b_area_sbk_bandit_specialFormation_8021D734 = 0x8021d734
export const RAM_func_8024F768 = 0x8024f768
export const RAM_b_area_isk_part_1_isk_00_afterBattle_802238EC = 0x802238ec
export const RAM_tik_09_set_script_owner_npc_col_height = 0x80240d44
export const RAM_D_80243F18_D2FC48 = 0x80243f18
export const RAM_dgb_01_D_80252478_C0FCF8 = 0x80252478
export const RAM_D_8029F646 = 0x8029f646
export const RAM_hud_element_set_render_pos = 0x80144984
export const RAM_b_area_mac_chan_intTable_8021AA6C = 0x8021aa6c
export const RAM_D_8014C270 = 0x8014c270
export const RAM_b_area_isk_part_2_isk_04_beforeBattle_80220130 = 0x80220130
export const RAM_func_80240038_953FF8 = 0x80240038
export const RAM_func_80052BF8 = 0x80052bf8
export const RAM_mac_05_LetterDelivery_SavedNpcAnim = 0x80252d38
export const RAM_D_801098E0 = 0x801098e0
export const RAM_D_E02006AC = 0xe02006ac
export const RAM_HES_WaterBlockCloud = 0x802ab158
export const RAM_ui_battle_menu_defend_disabled_pal = 0x80289aa0
export const RAM_Munchlesia_LaunchAccel = 0x802b62d8
export const RAM_ModifyGlobalOverrideFlags = 0x802d585c
export const RAM_b_area_trd_part_2_formation_00 = 0x8021a550
export const RAM_gBGMPlayerC = 0x8009a5cc
export const RAM_gMsgNumbers = 0x8014c340
export const RAM_b_area_omo2_toy_tank_8021A5E4 = 0x8021a5e4
export const RAM_arn_03_80242B0C = 0x80242b0c
export const RAM_D_80242C70_A3C150 = 0x80242c70
export const RAM_func_8021CCFC_6A3EFC = 0x8021ccfc
export const RAM_b_area_kmr_part_3_jr_troopa_init_80223180 = 0x80223180
export const RAM_action_update_launch = 0x802b6350
export const RAM_func_802401A0_AB6430 = 0x802401a0
export const RAM_b_area_omo_omo_01 = 0x8022db78
export const RAM_D_8014BCC8 = 0x8014bcc8
export const RAM_flo_22_pad_E08 = 0x80240e08
export const RAM_D_801098BC = 0x801098bc
export const RAM_osSetTimer = 0x80066350
export const RAM_D_E0058798 = 0xe0058798
export const RAM_remove_all_status_icons = 0x800476f4
export const RAM_kzn_11_FlyingAI_LosePlayer = 0x802412b8
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B640 = 0x8022b640
export const RAM_chomp_drop_init = 0xe009a138
export const RAM_battle_item_fire_flower_UseItem = 0x802a1704
export const RAM_D_802EB1F8 = 0x802eb1f8
export const RAM_evt_handle_loop = 0x802c4a00
export const RAM_b_area_trd_part_1_formation_0E = 0x802188d4
export const RAM_filemenu_draw_contents_file_0_info = 0x80245588
export const RAM_b_area_jan_spear_guy_idleAnimations_8021860C = 0x8021860c
export const RAM_shape_spell_main = 0xe0024000
export const RAM_func_802411A0_D59A30 = 0x802411a0
export const RAM_btl_save_world_cameras = 0x802409f4
export const RAM_HES_Item_WaterStone = 0x800819f8
export const RAM_D_80243CC0_D3E290 = 0x80243cc0
export const RAM_btl_show_message_popup = 0x80250ea4
export const RAM_kpa_70_UnkDistFunc = 0x8024038c
export const RAM_func_8026DA94 = 0x8026da94
export const RAM_battle_item_strange_cake_D_802A2820 = 0x802a2820
export const RAM_FindItem = 0x802d6b18
export const RAM_tik_20_UpdateTexturePanSmooth = 0x80240000
export const RAM_jan_03_Quizmo_CreateWorker = 0x80242c00
export const RAM_pra_19_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_E008CB18 = 0xe008cb18
export const RAM_kpa_03_MagikoopaAI_06 = 0x80241368
export const RAM_kmr_06_FlyingAI_JumpVels = 0x802420bc
export const RAM_b_area_kmr_part_1_kmr_04 = 0x8022066c
export const RAM_func_802382A4_707F44 = 0x802382a4
export const RAM_b_area_trd_part_1_koopa_troopa_defenseTable_8021C960 = 0x8021c960
export const RAM_gPauseSpiritsSpriteAnims = 0x8024f8c0
export const RAM_kzn_09_AwaitPlayerNearNpc = 0x802431e0
export const RAM_b_area_sam_gulpit_rocks_takeTurn = 0x80229aac
export const RAM_b_area_isk_part_1_isk_04_pad = 0x80224d58
export const RAM_D_800A3FF4 = 0x800a3ff4
export const RAM_vtx_C2C8A0 = 0x8020b960
export const RAM_D_802C05CC_32579C = 0x802c05cc
export const RAM_func_802423F0_7ED100 = 0x802423f0
export const RAM_func_802712A0 = 0x802712a0
export const RAM_b_area_omo_omo_04_vector3D_8022DE4C = 0x8022de4c
export const RAM_ui_status_coin_3_png = 0x80100ce0
export const RAM_kzn_09_PiranhaPlantAI_11 = 0x80242498
export const RAM_kzn_09_SuperBlock_StartGlowEffect = 0x80240480
export const RAM_sam_07_MeleeHitbox_Main = 0x80240a0c
export const RAM_D_E0056EF0 = 0xe0056ef0
export const RAM_b_area_jan2_white_magikoopa_80224448 = 0x8021a7f8
export const RAM_jan_08_SuperBlock_PartnerSparkles4 = 0x80241320
export const RAM_D_09000280_3926C0 = 0x09000280
export const RAM_jan_08_SuperBlock_UpgradeOrbAngles = 0x80243e58
export const RAM_D_802BE0EC = 0x802be0ec
export const RAM_D_80150EE8 = 0x80150ee8
export const RAM_D_80243090_BD1C80 = 0x80243090
export const RAM_kzn_04_FireBarAI_Main = 0x80241050
export const RAM_D_80244160_ED8380 = 0x80244160
export const RAM_arn_03_ItemChoice_SaveSelected = 0x80241648
export const RAM_D_802B6DC0_E25C90 = 0x802b6dc0
export const RAM_func_802AA014 = 0x802aa014
export const RAM_flo_25_main = 0x8024274c
export const RAM_jan_05_HeartPlant_SpawnHeart = 0x802407f0
export const RAM_gScriptListCount = 0x802dac98
export const RAM_nuScRemoveClient = 0x8005eebc
export const RAM_vtx_C27A70 = 0x80206b30
export const RAM_arn_04_npcGroup_80243E10 = 0x80243e10
export const RAM_sbk_52_SpawnSunEffect = 0x80240000
export const RAM_HES_MenuStarPower = 0x80108974
export const RAM_vtx_C29150 = 0x80208210
export const RAM_b_area_iwa_cleft_defenseTable_80218710 = 0x80218710
export const RAM_is_debug_init = 0x80025c60
export const RAM_set_npc_yaw = 0x8003b340
export const RAM_D_E0122CE8 = 0xe0122ce8
export const RAM_GetOwnerID = 0x8026de00
export const RAM_D_800DAAA8 = 0x800daaa8
export const RAM_IES_HPPlus = 0x8008bfc4
export const RAM_HES_Item_SleepStomp = 0x80083e58
export const RAM_dro_01_shopPriceList_8024B550 = 0x8024b550
export const RAM_func_802A9000_429D20 = 0x802a9000
export const RAM_D_E006EC00 = 0xe006ec00
export const RAM_flo_12_func_80240614_CC1444 = 0x80240614
export const RAM_D_802EB1F0 = 0x802eb1f0
export const RAM_HES_Item_Unused_08B = 0x800822f8
export const RAM_arn_12_pad_418 = 0x80240418
export const RAM_nok_01_Quizmo_HideEntities = 0x80240e80
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_802284DC = 0x802284dc
export const RAM_func_802A9664 = 0x802a9664
export const RAM_D_802347E0_6BB9E0 = 0x802347e0
export const RAM_b_area_isk_part_1_swooper_8021CD04 = 0x8021cd04
export const RAM_jan_02_Quizmo_SetVannaAnim_Wave = 0x80242138
export const RAM_flo_18_80245378 = 0x80245378
export const RAM_IES_FlowerFinder = 0x8008c664
export const RAM_b_area_kmr_part_3_formation_00 = 0x8021a1b0
export const RAM_flo_14_D_802431CC_CCE4DC = 0x802431cc
export const RAM_D_802D9D73 = 0x802d9d73
export const RAM_jan_22_SetCamera0Flag1000 = 0x80240fa8
export const RAM_func_80240ED8_801758 = 0x80240ed8
export const RAM_b_area_kzn_white_magikoopa_UnkBattleFunc5 = 0x80218d6c
export const RAM_func_8024367C_C7A35C = 0x8024367c
export const RAM_dgb_01_FlyingAI_Main = 0x8024142c
export const RAM_func_80240500_BCF0F0 = 0x80240500
export const RAM_nuGfxTaskSpool = 0x8009a618
export const RAM_PlayerMoveToDoor = 0x80283bd0
export const RAM_SetBattleCamParams = 0x8024e820
export const RAM_ShowStartRecoveryShimmer = 0x802d7520
export const RAM_HES_StatusStar7 = 0x801084c8
export const RAM_battle_star_refresh_UnkStarFunc4 = 0x802a1218
export const RAM_kpa_52_ProjectileHitbox_GetUsableProjectileID = 0x802400e0
export const RAM_kzn_17_MeleeHitbox_CanSeePlayer = 0x80240518
export const RAM_battle_star_peach_focus_UnkStarFunc4 = 0x802a1218
export const RAM_jan_08_foliage_setup_shear_mtx = 0x80240310
export const RAM_D_80099BE8 = 0x80099be8
export const RAM_func_80242A04_8EE824 = 0x80242a04
export const RAM_vtx_C25DD0 = 0x80204e90
export const RAM_battle_partner_kooper_init_80238570 = 0x80238570
export const RAM_flo_08_entryList = 0x80241ee0
export const RAM_dro_02_Quizmo_AltNpcSettings = 0x802473dc
export const RAM_dgb_01_FlyingAI_Loiter = 0x802409b8
export const RAM_D_8014F594 = 0x8014f594
export const RAM_vtx_C21C90 = 0x80200d50
export const RAM_D_80245602 = 0x80245602
export const RAM_D_800DC4E8 = 0x800dc4e8
export const RAM_mac_03_LetterDelivery_SaveNpcAnim = 0x80241b14
export const RAM_D_80249A88_A33CC8 = 0x80249a88
export const RAM_b_area_jan_spike_top_idle_8021CC7C = 0x80223eec
export const RAM_HES_Item_Unused_02C_disabled = 0x80083828
export const RAM_D_80241B68_8666A8 = 0x80241b68
export const RAM_flo_00_80248D3C = 0x80248d3c
export const RAM_isk_10_SuperBlock_ShowSelectPartnerMenu = 0x802401cc
export const RAM_flo_07_TransformFoliage = 0x80240450
export const RAM_integrate_gravity = 0x800e3448
export const RAM_D_800A41EC = 0x800a41ec
export const RAM_D_802B6ED8 = 0x802b6ed8
export const RAM_arn_05_80242BEC = 0x80242bec
export const RAM_npc_find_standing_on_entity = 0x8003d1d4
export const RAM_osPfsReleasePages = 0x8006876c
export const RAM_tik_08_UpdateTexturePanSmooth = 0x802402e0
export const RAM_IES_PleaseComeBack = 0x8008b2e4
export const RAM_battle_item_strange_cake_AddHP = 0x802a1998
export const RAM_EF7A80_UnkFloatFunc = 0x80240394
export const RAM_b_area_jan_jan_01b_foregroundModelList_8022825C = 0x8022825c
export const RAM_D_E0126BC0 = 0xe0126bc0
export const RAM_battle_star_peach_beam_UnkBackgroundFunc = 0x802a1414
export const RAM_mac_01_PatrolAI_Jump = 0x80245cf4
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_80228FE8 = 0x80228fe8
export const RAM_D_8024F470 = 0x8024f470
export const RAM_b_area_omo2_1_shy_squad_move_squad_to_home = 0x802325ec
export const RAM_func_80241174_940724 = 0x80241174
export const RAM_update_camera_mode_1 = 0x8002f290
export const RAM_D_8015221C = 0x8015221c
export const RAM_D_E0056EF8 = 0xe0056ef8
export const RAM_arn_02_CleftAI_CanSeePlayer = 0x80240000
export const RAM_dgb_03_GetEntityPosition = 0x802401a4
export const RAM_b_area_dgb_tubba_blubba_80219D88 = 0x80219d88
export const RAM_func_80271328 = 0x80271328
export const RAM_kzn_07_FlyingNoAttackAI_12 = 0x80241d18
export const RAM_b_area_mac_lee_statusTable_802222E8 = 0x802222e8
export const RAM_au_SEFCmd_06 = 0x8004d038
export const RAM_battle_partner_kooper_firstStrike = 0x80238d84
export const RAM_arn_02_CleftAI_PreAmbush = 0x80240304
export const RAM_IES_StarPoint = 0x8008ce04
export const RAM_D_E0088DA0 = 0xe0088da0
export const RAM_vtx_C27770 = 0x80206830
export const RAM_func_80241E18_D21CA8 = 0x80241e18
export const RAM_HES_Item_FirePop_disabled = 0x800832e8
export const RAM_store_item = 0x800e77c4
export const RAM_GetAngleToNPC = 0x802d47b4
export const RAM_func_802A15B4_795894 = 0x802a15b4
export const RAM_render_shadows = 0x801100cc
export const RAM_func_80240310_9B1AA0 = 0x80240310
export const RAM_nok_13_HoppingAI_Main = 0x80240bf0
export const RAM_dup3_b_area_tik2_StartRumbleWithParams = 0x80218a20
export const RAM_ui_box_corners2_png = 0x8010b8e0
export const RAM_evt_get_variable = 0x802c7abc
export const RAM_b_area_jan_m_bush_idleAnimations_80221830 = 0x80221830
export const RAM_sbk_46_foliage_setup_shear_mtx = 0x80240160
export const RAM_dgb_08_idle_80246718 = 0x80246718
export const RAM_MerleeRunOut = 0x80288398
export const RAM_b_area_iwa_monty_mole_init_8021A630 = 0x8021a630
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_UnkWhirlwindEffectFunc = 0x80219b78
export const RAM_mac_00_Quizmo_UnkStageEffectMode = 0x802410a0
export const RAM_NAMESPACE_80239B64 = 0x80239b64
export const RAM_D_800AF91C = 0x800af91c
export const RAM_func_802BC628_E2EF58 = 0x802bc628
export const RAM_static_status_render = 0xe00e64d4
export const RAM_D_802437A0_BFD840 = 0x802437a0
export const RAM_omo_07_SomeItemEntityFunc = 0x80243038
export const RAM_D_802463C0_915A70 = 0x802463c0
export const RAM_func_80240718_C71B98 = 0x80240718
export const RAM_DoorModelsSwingCW = 0x802dbc7c
export const RAM_D_802BFDB4_320B24 = 0x802bfdb4
export const RAM_D_09000A00_386DB0 = 0x09000a00
export const RAM_battle_star_peach_beam_D_802A3554_7FCD04 = 0x802a3554
export const RAM_vtx_stencil2 = 0x8014e770
export const RAM_get_npc_by_index = 0x80038adc
export const RAM_D_80247BD0_DA50A0 = 0x80247bd0
export const RAM_b_area_sam2_paragoomba_8021CB94 = 0x8021d914
export const RAM_ECB930_TransformFoliage = 0x80241ca0
export const RAM_entity_HeartBlockContent__anim_heal = 0x802e4e04
export const RAM_D_802B6788 = 0x802b6788
export const RAM_func_80240730_AF33D0 = 0x80240730
export const RAM_flo_11_80241CB4 = 0x80241cb4
export const RAM_D_80242BA0_91E480 = 0x80242ba0
export const RAM_func_800E983C = 0x800e983c
export const RAM_flo_10_8024206C = 0x8024206c
export const RAM_omo_16_SetAngleClamped = 0x8024001c
export const RAM_partner_test_enemy_collision = 0x800eb5e4
export const RAM_ParakarryPutAway = 0x802be8d4
export const RAM_mim_04_HoppingAI_HopInit = 0x80240200
export const RAM_D_8024EF00_C0C780 = 0x8024ef00
export const RAM_D_802B6780 = 0x802b6780
export const RAM_func_802A91F8_425788 = 0x802a91f8
export const RAM_kpa_113_AwaitPlayerNearNpc = 0x802409cc
export const RAM_func_80240310_AA7790 = 0x80240310
export const RAM_b_area_omo2_light_bulb_handleEvent = 0x8022f7a8
export const RAM_flo_25_802449EC = 0x802449ec
export const RAM_func_80260A60 = 0x80260a60
export const RAM_flo_00_func_80240F80_C9EEA0 = 0x80240f80
export const RAM_b_area_nok_kent_c_koopa_8022B2E8 = 0x8022b2e8
export const RAM_b_area_isk_part_1_formation_05 = 0x80224270
export const RAM_sam_06_FlyingAI_Jump = 0x802411a4
export const RAM_func_80240000_B06570 = 0x80240000
export const RAM_check_input_spin = 0x800e6268
export const RAM_hos_03_Quizmo_StageEffect = 0x8024bdf0
export const RAM_D_E003E880 = 0xe003e880
export const RAM_b_area_trd_part_2_fake_bowser = 0x8021a8d4
export const RAM_kmr_12_goombaAISettings = 0x802402b0
export const RAM_sbk_30_TransformFoliage = 0x80241640
export const RAM_b_area_isk_part_2_chain_chomp_ChompChainUpdateHelperFunc = 0x80218150
export const RAM_flo_23_exitWalk_8024104C = 0x8024104c
export const RAM_D_802AD610 = 0x802ad610
export const RAM_b_area_dgb_tubba_blubba_takeTurn_80219750 = 0x80219750
export const RAM_filemenu_cursorGoalAlpha2 = 0x80249bac
export const RAM_D_8024EF98_C0C818 = 0x8024ef98
export const RAM_dro_02_Quizmo_SpinPartner = 0x80241180
export const RAM_b_area_kmr_part_1_kmr_06_foregroundModelList_80220EBC = 0x80220ebc
export const RAM_flo_24_80240690 = 0x80240690
export const RAM_alHeapInit = 0x80057f20
export const RAM_EF2680_FlyingAI_WanderInit = 0x80240070
export const RAM_kzn_20_LetterDelivery_SaveNpcAnim = 0x802409f8
export const RAM_func_80241E24_97F8F4 = 0x80241e24
export const RAM_D_80241C30_A124B0 = 0x80241c30
export const RAM_HES_HPDrain = 0x80108ad4
export const RAM_osPiGetCmdQueue = 0x8006b550
export const RAM_battle_item_mystery_func_802A123C_72C7EC = 0x802a123c
export const RAM_PlaySoundWithVolume = 0x802d617c
export const RAM_b_area_arn_hyper_paragoomba_8021D9F4 = 0x8021d9f4
export const RAM_D_E0126BC8 = 0xe0126bc8
export const RAM_b_area_iwa_buzzar_8021F808 = 0x8021f808
export const RAM_D_80241E30_AD5EC0 = 0x80241e30
export const RAM_flo_24_triggerCoord_80241718 = 0x80241718
export const RAM_evt_handle_set_const = 0x802c5790
export const RAM_b_area_sam_duplighost_sushie_defenseTable = 0x80224834
export const RAM_func_802403D8_DDF6E8 = 0x802403d8
export const RAM_omo_04_FlyingAI_Jump = 0x80240ff4
export const RAM_title_screen_draw_press_start = 0x80037bc8
export const RAM_PlaySoundAtPlayer = 0x802d2cd8
export const RAM_HES_Item_JellySuper = 0x80083018
export const RAM_moving_cloud_update = 0xe00e8264
export const RAM_set_world_fog_color = 0x8011bb88
export const RAM_D_800A0970 = 0x800a0970
export const RAM_ECAA80_BuildItemChoiceList = 0x80241a28
export const RAM_vtx_C21700 = 0x802007c0
export const RAM_msg_copy_to_print_buffer = 0x80124570
export const RAM_kmr_12_PlayMusic = 0x80240090
export const RAM_dro_01_8024D2B0 = 0x8024d2b0
export const RAM_hos_03_Quizmo_NPC_OnRender = 0x80242870
export const RAM_hos_03_Quizmo_AddViewRelativeOffset = 0x80243124
export const RAM_D_8014F134 = 0x8014f134
export const RAM_arn_07_makeEntities = 0x80243b54
export const RAM_dgb_09_npcSettings_8024418C = 0x8024418c
export const RAM_IES_FlowerFanatic = 0x8008c4c4
export const RAM_action_command_spook_free_hud_elements = 0x802a98dc
export const RAM_HES_Item_WhackasBump = 0x800870f8
export const RAM_npc_raycast_general = 0x800dd44c
export const RAM_flo_21_npcSettings_80241844 = 0x80241844
export const RAM_D_802477B0_DF6650 = 0x802477b0
export const RAM_D_E0122CE0 = 0xe0122ce0
export const RAM_D_80243D40_9A8390 = 0x80243d40
export const RAM_func_802409EC_B073EC = 0x802409ec
export const RAM_nok_01_Quizmo_AudienceEffect = 0x80251c64
export const RAM_ring_blast_main = 0xe0048000
export const RAM_npc_enable_collisions = 0x8003b184
export const RAM_drawbox_message_delegate = 0x80127b70
export const RAM_kzn_19_LetterDelivery_CalcLetterPos = 0x802419c4
export const RAM_D_8024F338 = 0x8024f338
export const RAM_evt_handle_unbind = 0x802c6850
export const RAM_func_8024030C_B2833C = 0x8024030c
export const RAM_fx_smoke_impact = 0x80070370
export const RAM_dro_01_openDoor_8024BAA0 = 0x8024baa0
export const RAM_D_80263430_C20CB0 = 0x80263430
export const RAM_mim_02_HoppingAI_Hop = 0x802402a0
export const RAM_fire_flower_appendGfx = 0xe007e54c
export const RAM_entity_Chest_setupGfx = 0x802e6ed8
export const RAM_b_area_sbk_formation_09 = 0x8021fc18
export const RAM_b_area_kmr_part_3_final_jr_troopa_init_8022CF80 = 0x8022cf80
export const RAM_EC71B0_SpawnSunEffect = 0x8024030c
export const RAM_shim_func_8011CFBC = 0xe0200570
export const RAM_pra_38_reflection_setup_floor = 0x80240530
export const RAM_flower_splash_main = 0xe00101e8
export const RAM_mac_01_PatrolAI_ChaseInit = 0x80245d6c
export const RAM_D_802313B8_63CF58 = 0x802313b8
export const RAM_D_801539C0 = 0x801539c0
export const RAM_arn_12_80240430 = 0x80240430
export const RAM_get_collider_flags = 0x8005bda8
export const RAM_b_area_omo_omo_04_intTable_8022DED4 = 0x8022ded4
export const RAM_b_area_nok_paratroopa_idleAnimations_80220AA0 = 0x802258d0
export const RAM_func_80240F60_D4DFA0 = 0x80240f60
export const RAM_D_E006AE00 = 0xe006ae00
export const RAM_b_area_hos_magikoopa_clone = 0x8022166c
export const RAM_gPauseBadgesNumItems = 0x80270384
export const RAM_D_80151474 = 0x80151474
export const RAM_D_80248380 = 0x80248380
export const RAM_D_8024B8F8_C09178 = 0x8024b8f8
export const RAM_SetPartMovementVar = 0x8026c22c
export const RAM_npc_dyn_entity_draw_no_op = 0x8003e670
export const RAM_b_area_tik2_UnkFunc56 = 0x80218be0
export const RAM_D_802809F6 = 0x802809f6
export const RAM_D_09000E18 = 0x09000e18
export const RAM_D_E00A29DC = 0xe00a29dc
export const RAM_b_area_kmr_part_3_paragoomba1_idle_8021C4D4 = 0x8021c4d4
export const RAM_kzn_03_GetItemName = 0x80242650
export const RAM_D_802E9170 = 0x802e9170
export const RAM_func_80240CA0_BD4510 = 0x80240ca0
export const RAM_gfx_frame_filter_pass_1 = 0x8002725c
export const RAM_dro_01_Quizmo_SetVannaAnim_Wave = 0x80241f94
export const RAM_func_800E0B14 = 0x800e0b14
export const RAM_kzn_04_SuperBlock_CantUpgradeMessages = 0x80241c40
export const RAM_npc_test_move_simple_without_slipping = 0x800ddcec
export const RAM_D_8024F570 = 0x8024f570
export const RAM_D_80249D60 = 0x80249d60
export const RAM_b_area_trd_part_1_trd_02_afterBattle_80223318 = 0x80223318
export const RAM_func_80240870_BD40E0 = 0x80240870
export const RAM_b_area_omo_formation_11 = 0x8022fe1c
export const RAM_b_area_isk_part_2_vector3D_80220420 = 0x80220420
export const RAM_b_area_sam2_sam_02c_UnkModelStuffScript4 = 0x80222b84
export const RAM_D_800A3FC8 = 0x800a3fc8
export const RAM_D_802423E0_C70710 = 0x802423e0
export const RAM_dro_02_EVS_Quizmo_ReturnPlayerToOriginalPos = 0x802462e4
export const RAM_D_80249D60_E00760 = 0x80249d60
export const RAM_arn_03_GetItemName = 0x80241364
export const RAM_ClearVariable = 0x802d6af0
export const RAM_func_80241838_C8F3E8 = 0x80241838
export const RAM_dead_gCurrentCamID = 0x800a158c
export const RAM_HES_Item_ToyboxTrain = 0x80081818
export const RAM_b_area_jan_formation_0B = 0x802288e4
export const RAM_HES_PeachBeamDisabled = 0x80107c7c
export const RAM_flo_09_npcAISettings_80243644 = 0x80243644
export const RAM_func_802BB1E8_E2B918 = 0x802bb1e8
export const RAM_sbk_34_GetItemName = 0x802400f4
export const RAM_fx_shape_spell = 0x8006ff50
export const RAM_mim_09_HoppingAI_HopInit = 0x802400a0
export const RAM_b_area_trd_part_2_fake_bowser_8021C1EC = 0x8021c1ec
export const RAM_kgr_01_make_tongue_gfx = 0x80240354
export const RAM_D_8014F4B4 = 0x8014f4b4
export const RAM_GoompaTakeOut = 0x802bd114
export const RAM_dgb_07_npcSettings_8024197C = 0x8024197c
export const RAM_jan_03_Pipe_SetAnimFlag = 0x80240000
export const RAM_vtx_C232A0 = 0x80202360
export const RAM_DoBurnHit = 0x8029a0d0
export const RAM_D_8024854C = 0x8024854c
export const RAM_b_area_sam2_gray_magikoopa_defenseTable = 0x8021f130
export const RAM_func_802197B0_5CFE40 = 0x802197b0
export const RAM_HES_Item_JumpCharge0_disabled = 0x80084548
export const RAM_b_area_isk_part_1_isk_06_afterBattle_80223C38 = 0x80223c38
export const RAM_osSpTaskYielded = 0x80065c70
export const RAM_sam_02_ToadHouse_GetPartnerBackOut = 0x80240af4
export const RAM_gPauseSpiritsViewport = 0x8024f8b0
export const RAM_D_E0116D6C = 0xe0116d6c
export const RAM_fx_floating_rock = 0x80071510
export const RAM_EC9D00_SuperBlock_HideBlockContent = 0x8024003c
export const RAM_mac_01_Quizmo_DestroyEffects = 0x80242898
export const RAM_b_area_omo2_general_guy_idleAnimations = 0x80218458
export const RAM_stars_burst_appendGfx = 0xe00423b8
export const RAM_ScaleGroup = 0x802c9a1c
export const RAM_flo_00_802476F4 = 0x802476f4
export const RAM_b_area_tik2_UnkWaterFountainFunc = 0x80218058
export const RAM_contRmbControl = 0x8005f920
export const RAM_func_80218930_59F010 = 0x80218930
export const RAM_something_rotating_init = 0xe0116184
export const RAM_D_802B6760_E27C70 = 0x802b6760
export const RAM_basic_hidden_window_update = 0x80147574
export const RAM_flo_13_npcGroup_80246624 = 0x80246624
export const RAM_D_80155D80 = 0x80155d80
export const RAM_func_802422F8_8B2368 = 0x802422f8
export const RAM_b_area_sam_sam_01 = 0x8022f3b4
export const RAM_b_area_kmr_part_3_stageTable = 0x8021a360
export const RAM_flo_11_vectorList_80241C0C = 0x80241c0c
export const RAM_kmr_03_pad_1468 = 0x80241468
export const RAM_func_802A1108_761A28 = 0x802a1108
export const RAM_dup_kkj_10_UnkAtan2Func = 0x80240e34
export const RAM_flo_24_tree1_Leaves = 0x802416a4
export const RAM_D_E00529E0 = 0xe00529e0
export const RAM_action_command_07_draw_hud_elements = 0x802a94d8
export const RAM_flo_16_FlyingAI_Jump = 0x80241aa4
export const RAM_ui_battle_cmd_gauge_result_png = 0x8028d440
export const RAM_kmr_02_ToadHouse_PutPartnerAway = 0x80243300
export const RAM_func_80240060_AB21D0 = 0x80240060
export const RAM_isk_13_StoneChompAI_ChaseInit = 0x80240ea8
export const RAM_b_area_nok_paragoomba_handleEvent_8021CEBC = 0x8021cebc
export const RAM_ItemEntitiesCreated = 0x801512f8
export const RAM_func_802428B8_9C7C98 = 0x802428b8
export const RAM_D_8024F6E8_C0CF68 = 0x8024f6e8
export const RAM_mim_11_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_vtx_C27300 = 0x802063c0
export const RAM_flo_08_init_80244BC8 = 0x80244bc8
export const RAM_D_802A2DA0_00736340 = 0x802a2da0
export const RAM_itemIcon = 0x802a2410
export const RAM_func_80241800_C8F3B0 = 0x80241800
export const RAM_battle_item_shooting_star_UseItemWithEffect = 0x802a1550
export const RAM_battle_item_strange_cake2_png = 0x802a2390
export const RAM_b_area_kzn_red_magikoopa = 0x80221060
export const RAM_mac_00_Quizmo_UpdateRecords = 0x80240d1c
export const RAM_mgm_02_RunMinigame = 0x80240bb0
export const RAM_ui_move_partner_1_png = 0x80103ae0
export const RAM_D_E0084E28 = 0xe0084e28
export const RAM_omo_07_ShyGuyPatrolAI_14 = 0x802413fc
export const RAM_func_80241944_7EC654 = 0x80241944
export const RAM_b_area_pra3_duplighost_sushie_idleAnimations = 0x80223868
export const RAM_D_E0066C50 = 0xe0066c50
export const RAM_b_area_omo2_general_guy = 0x80218430
export const RAM_D_80245D48_A99488 = 0x80245d48
export const RAM_mim_01_MeleeHitbox_CanSeePlayer = 0x80240708
export const RAM_IES_HPDrain = 0x8008c284
export const RAM_D_8029C12C = 0x8029c12c
export const RAM_D_802A9852_4292C2 = 0x802a9852
export const RAM_omo_04_FlyingAI_Init = 0x80241588
export const RAM_vtx_C23FD0 = 0x80203090
export const RAM_dgb_04_80243170 = 0x80243170
export const RAM_D_80248CD4_809554 = 0x80248cd4
export const RAM_b_area_jan_formation_02 = 0x8022860c
export const RAM_nok_01_ToadHouse_DisableStatusMenu = 0x80240a6c
export const RAM_flo_11_settings = 0x802403a0
export const RAM_coss = 0x80061f00
export const RAM_sbk_56_SuperBlock_ShowSelectPartnerMenu = 0x8024024c
export const RAM_SetActorIdleJumpGravity = 0x8026b5d8
export const RAM_omo_04_SomeItemEntityFunc = 0x80240118
export const RAM_sam_02_Pipe_AwaitDownInput = 0x80240034
export const RAM_ui_input_analog_stick_png = 0x800f8cc0
export const RAM_bind_trigger = 0x802c4220
export const RAM_b_area_kmr_part_1_spiked_goomba_handleEvent_8021E874 = 0x8021b494
export const RAM_D_802A9802_426972 = 0x802a9802
export const RAM_IES_SleepStomp = 0x8008c064
export const RAM_D_09001200_351420 = 0x09001200
export const RAM_D_8015A370 = 0x8015a370
export const RAM_jan_00_UnkAngleFunc001 = 0x802411ec
export const RAM_D_80241438_9EF028 = 0x80241438
export const RAM_mac_02_Quizmo_CreateStage = 0x80241f30
export const RAM_dgb_05_WanderMeleeAI_Main = 0x802411d0
export const RAM_b_area_kmr_part_2_red_goomba_2_handleEvent_8021F2C4 = 0x8021f2c4
export const RAM_SetRenderMode = 0x802ca4f4
export const RAM_omo_05_FlyingAI_JumpInit = 0x80241630
export const RAM_b_area_pra3_duplighost_bombette_init = 0x8021f500
export const RAM_osMapTLB = 0x800667a0
export const RAM_func_8024199C_ECB69C = 0x8024199c
export const RAM_b_area_dgb_tubba_blubba_defenseTable_80218C6C = 0x80218c6c
export const RAM_HES_Item_SilverCard = 0x80082028
export const RAM_b_area_trd_part_1_bob_omb_statusTable_8021A660 = 0x8021a660
export const RAM_func_80218170_4CF320 = 0x80218170
export const RAM_b_area_trd_part_2_blue_ninja_koopa_statusTable_8022B4B8 = 0x8022b4b8
export const RAM_ui_status_pow_star_1_png = 0x80100900
export const RAM_flo_17_makeEntities = 0x80245280
export const RAM_jan_03_Quizmo_DestroyEffects = 0x80242524
export const RAM_tik_08_UnkPosFunc2 = 0x802405f0
export const RAM_dro_02_pad_D_8024EF8C = 0x8024ef8c
export const RAM_b_area_trd_part_2_fake_bowser_D_8021CE68_48E058 = 0x8021ce68
export const RAM_func_802428E0_C88390 = 0x802428e0
export const RAM_piMgrMesgBuff = 0x800af8a8
export const RAM_stars_spread_update = 0xe005a1fc
export const RAM_HES_Item_FireFlower = 0x800860d8
export const RAM_pause_items_cleanup = 0x8024ad90
export const RAM_func_802401C0_842D90 = 0x802401c0
export const RAM_pra_28_reflection_setup_wall = 0x80240158
export const RAM_pra_20_reflection_render_floor = 0x8024068c
export const RAM_flo_07_pad_1214 = 0x80241214
export const RAM_flo_17_FlyingAI_Loiter = 0x80240908
export const RAM_b_area_kgr_kgr_01_afterBattle_8021D598 = 0x8021d598
export const RAM_kpa_95_PatrolAI_Chase = 0x80240a2c
export const RAM_D_80243B44_EEA0F4 = 0x80243b44
export const RAM_flo_16_80242AF8 = 0x80242af8
export const RAM_hos_06_CheckItemFlags40 = 0x80242238
export const RAM_smoke_impact_update = 0xe003a1dc
export const RAM_evt_set_float_variable = 0x802c8640
export const RAM_HES_Item_ChillOut_disabled = 0x80084848
export const RAM_dup2_b_area_tik_CalculateRotationZ = 0x802188e4
export const RAM_func_802404B0_7E7850 = 0x802404b0
export const RAM_func_80240000_8EBE20 = 0x80240000
export const RAM_ui_battle_status_sleep_1_png = 0x80101d20
export const RAM_kzn_09_SuperBlock_EndGlowEffect = 0x80240510
export const RAM_D_E00269F0 = 0xe00269f0
export const RAM_func_800287F0 = 0x800287f0
export const RAM_D_8010C968 = 0x8010c968
export const RAM_flo_24_tree1_Drops = 0x802416c8
export const RAM_HES_Item_Dolly = 0x80081548
export const RAM_D_800A0BBC = 0x800a0bbc
export const RAM_entity_BlueWarpPipe_enter_pipe_update = 0x802e8d74
export const RAM_b_area_pra3_duplighost_UnkWattEffectFunc2 = 0x80219304
export const RAM_D_800DC4EB = 0x800dc4eb
export const RAM_sam_01_Quizmo_VannaTEffect = 0x8024df20
export const RAM_flo_00_80245EB8 = 0x80245eb8
export const RAM_D_80242000_A7A4C0 = 0x80242000
export const RAM_arn_05_entryList = 0x80241300
export const RAM_pra_35_PatrolAI_Loiter = 0x802413e0
export const RAM_func_80280410 = 0x80280410
export const RAM_dgb_01_PatrolAI_LosePlayer = 0x80242f98
export const RAM_b_area_kmr_part_1_paragoomba_statusTable_8021B128 = 0x8021cb18
export const RAM_D_80241DD0_920340 = 0x80241dd0
export const RAM_au_memset = 0x80054e90
export const RAM_omo_02_ProjectileHitbox_33 = 0x80241e60
export const RAM_dgb_09_FlyingAI_Loiter = 0x80241cbc
export const RAM_func_80241848_844418 = 0x80241848
export const RAM_vtx_C2B340 = 0x8020a400
export const RAM_PATCH_appendGfx_title_screen = 0x80400198
export const RAM_update_windows = 0x80147414
export const RAM_vtx_C25500 = 0x802045c0
export const RAM_battle_item_fright_jar_func_802A12D4_72BBE4 = 0x802a12d4
export const RAM_isk_05_HoppingAI_Chase = 0x802408ac
export const RAM_D_E0002800 = 0xe0002800
export const RAM_func_8024027C_A7873C = 0x8024027c
export const RAM_func_802417FC_832FFC = 0x802417fc
export const RAM_pra_39_reflection_render_floor = 0x802406bc
export const RAM_obfuscated_load_engine_data = 0x80026ac7
export const RAM_vtx_C25950 = 0x80204a10
export const RAM_dup_b_area_tik_CalculateRotationZ = 0x8021860c
export const RAM_D_8007F1A8 = 0x8007f1a8
export const RAM_D_09002460_388810 = 0x09002460
export const RAM_gPlayerData = 0x8010f290
export const RAM_fio_erase_game = 0x8002b42c
export const RAM_b_area_omo_omo_07_afterBattle_8022F8EC = 0x8022f8ec
export const RAM_remove_status_chill_out = 0x80047b84
export const RAM_tik_15_Pipe_GetEntryPos = 0x802400f0
export const RAM_D_E0082D00 = 0xe0082d00
export const RAM_b_area_dig_GetModelPos = 0x80218580
export const RAM_flo_17_func_80240000_CD72E0 = 0x80240000
export const RAM_b_area_isk_part_1_isk_06b_pad = 0x80224d98
export const RAM_b_area_iwa_formation_07 = 0x80218398
export const RAM_omo_13_GrooveGuyAI_Main = 0x80240a00
export const RAM_b_area_hos_ember_idleAnimations_8021AEA8 = 0x8021aea8
export const RAM_func_E0090444 = 0xe0090444
export const RAM_clear_player_status = 0x800dfc50
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021BB68 = 0x8021bb68
export const RAM_flo_22_foliage_setup_shear_mtx = 0x80240050
export const RAM_dgb_18_npcAI_80242834 = 0x80242834
export const RAM_mac_02_UpdateTexturePanSmooth = 0x802402e0
export const RAM_func_802B735C_E2290C = 0x802b735c
export const RAM_D_80242248_D614F8 = 0x80242248
export const RAM_CheckActionState = 0x802831dc
export const RAM_ui_battle_hp_8_png = 0x8028ab60
export const RAM_D_80074414 = 0x80074414
export const RAM_D_E0044DF4 = 0xe0044df4
export const RAM_dgb_03_ClubbaNappingAI_LoiterInit = 0x80242020
export const RAM_D_802A3CF0_740550 = 0x802a3cf0
export const RAM_partner_can_use_ability = 0x800eb42c
export const RAM_jan_11_Pipe_AwaitDownInput = 0x8024018c
export const RAM_b_area_sam_duplighost_sushie_handleEvent = 0x80224994
export const RAM_vtx_C233D0 = 0x80202490
export const RAM_kmr_02_ToadHouse_Stub = 0x80243364
export const RAM_D_80249C1C = 0x80249c1c
export const RAM_D_E0122CC8 = 0xe0122cc8
export const RAM_b_area_omo_spy_guy_handleEvent_80227ABC = 0x80227abc
export const RAM_D_8014F4AC = 0x8014f4ac
export const RAM_D_802D9F10 = 0x802d9f10
export const RAM_flo_25_80242AD4 = 0x80242ad4
export const RAM_damage_stars_init = 0xe0030728
export const RAM_kgr_01_TongueWiggleTime = 0x802407c0
export const RAM_rising_bubble_main = 0xe0046000
export const RAM_D_80248480 = 0x80248480
export const RAM_b_area_isk_part_1_pokey_mummy_idleAnimations_80218EB8 = 0x80218eb8
export const RAM_D_80242F20_A66FB0 = 0x80242f20
export const RAM_D_09001F90_3521B0 = 0x09001f90
export const RAM_arn_12_init_80240710 = 0x80240710
export const RAM_omo_04_varStash = 0x80242630
export const RAM_draw_shop_items = 0x80281524
export const RAM_func_8024066C_BB76DC = 0x8024066c
export const RAM_GetPartPos = 0x8026ac88
export const RAM_func_800F0864 = 0x800f0864
export const RAM_ui_status_coin_1_pal = 0x80100c20
export const RAM_D_80241B7C_A28A4C = 0x80241b7c
export const RAM_state_step_pause = 0x8003497c
export const RAM_func_80050E18 = 0x80050e18
export const RAM_vtx_C2B410 = 0x8020a4d0
export const RAM_end_00_UpdatePosFX3D = 0x80242a30
export const RAM_pra_01_ReflectWall = 0x802400ec
export const RAM_EA8AE0_StashVars = 0x80241468
export const RAM_b_area_kmr_part_2_goomnut_tree_unk_missing_80223478 = 0x80223478
export const RAM_b_area_kmr_part_2_pos04 = 0x802197b8
export const RAM_b_area_nok_paratroopa_8021EE84 = 0x80223cb4
export const RAM_b_area_trd_part_2_trd_03_UnkAngleFunc2 = 0x8021a2e0
export const RAM_pause_partners_draw_movelist = 0x8024bd38
export const RAM_IES_RuinsStonePyramid = 0x8008b624
export const RAM_ui_battle_hp_3_pal = 0x8028aa40
export const RAM_D_80234800_6BBA00 = 0x80234800
export const RAM_D_8024160C_ABC89C = 0x8024160c
export const RAM_set_window_properties = 0x80147cc8
export const RAM_flo_25_802446BC = 0x802446bc
export const RAM_arn_09_802405C0 = 0x802405c0
export const RAM_ShowMessageAtWorldPos = 0x802d095c
export const RAM_D_8014BEC8 = 0x8014bec8
export const RAM_b_area_iwa_monty_mole_handleEvent_8021A824 = 0x8021a824
export const RAM_func_802199C0_60A470 = 0x802199c0
export const RAM_D_802BFEF8 = 0x802bfef8
export const RAM_b_area_omo_spy_guy_idleAnimations_80227498 = 0x80227498
export const RAM_b_area_omo_anti_guy_80221BBC = 0x80221bbc
export const RAM_world_bombette_init = 0x802bd2d8
export const RAM_flo_23_npcGroupList_80242568 = 0x80242568
export const RAM_omo_08_SetNpcPosYaw = 0x80240290
export const RAM_dgb_07_pad_17E4 = 0x802417e4
export const RAM_b_area_omo_groove_guy = 0x80224b10
export const RAM_func_80241790_BD0380 = 0x80241790
export const RAM_arn_03_npcSettings_80241C10 = 0x80241c10
export const RAM_mim_05_PiranhaPlantAI_10 = 0x80240aa8
export const RAM_D_E00C0A24 = 0xe00c0a24
export const RAM_D_80242288_97D8B8 = 0x80242288
export const RAM_omo_17_ProjectileHitbox_30 = 0x802427e0
export const RAM_osAiGetStatus = 0x80061550
export const RAM_osr_01_GetNpcCollisionHeight = 0x80240478
export const RAM_b_area_kmr_part_3_para_jr_troopa_idleAnimations_80225E48 = 0x80225e48
export const RAM_flo_16_80244DC8 = 0x80244dc8
export const RAM_b_area_kmr_part_3_jr_troopa_80222C08 = 0x80222c08
export const RAM_battle_action_cmd_whirlwind_3_png = 0x802a9b60
export const RAM_omo_03_CompareFloats = 0x80240104
export const RAM_func_802A9634 = 0x802a9634
export const RAM_shim_NULL = 0xe02004d0
export const RAM_flo_17_npcSettings_8024465C = 0x8024465c
export const RAM_dispatch_event_player_continue_turn = 0x802716e4
export const RAM_dp = 0x8006ae0c
export const RAM_b_area_sam2_sam_02b_beforeBattle = 0x802228f8
export const RAM_D_09002160_352380 = 0x09002160
export const RAM_gPauseMapPaths = 0x8024fa38
export const RAM_ui_status_pow_unit_empty_png = 0x801008c0
export const RAM_D_8024F0C8_C0C948 = 0x8024f0c8
export const RAM_kzn_09_GetNpcCollisionHeight = 0x802433c4
export const RAM_PlayerJump2 = 0x802d1920
export const RAM_nuSiMgrMesgQ = 0x800d91f8
export const RAM_dgb_09_enterWalk_80243BC4 = 0x80243bc4
export const RAM_dgb_08_SentinelAI_Main = 0x802438f0
export const RAM_mgm_01_DisableMenus = 0x802403fc
export const RAM_func_801363A0 = 0x801363a0
export const RAM_IES_MiracleWater = 0x8008b904
export const RAM_kzn_17_UnkFunc46 = 0x80242d4c
export const RAM_D_E002C944 = 0xe002c944
export const RAM_jan_12_MeleeHitbox_CanSeePlayer = 0x802408dc
export const RAM_dgb_18_npcAISettings_80241580 = 0x80241580
export const RAM_func_8004B9E4 = 0x8004b9e4
export const RAM_func_80219C80_5B3130 = 0x80219c80
export const RAM_flo_19_settings = 0x80240980
export const RAM_b_area_omo_formation_0B = 0x8022fc5c
export const RAM_IES_Unused_02D = 0x8008d554
export const RAM_b_area_trd_part_2_blue_ninja_koopa_UnkEnemyPosFunc = 0x80219530
export const RAM_EE70C0_FlyingAI_LosePlayer = 0x80241968
export const RAM_func_802A10E4_785C04 = 0x802a10e4
export const RAM_state_step_change_map = 0x8003617c
export const RAM_get_overriding_player_anim = 0x800dfd48
export const RAM_D_80231550_63D0F0 = 0x80231550
export const RAM_HES_SmallTextBp = 0x802422a8
export const RAM_b_area_sam_sam_02d_UnkModelStuffScript4 = 0x802300d4
export const RAM_b_area_jan2_jan_04b_afterBattle_8021F570 = 0x8021f570
export const RAM_D_800A0922 = 0x800a0922
export const RAM_b_area_isk_part_1_swooper_takeTurn_8021DED4 = 0x8021ded4
export const RAM_b_area_iwa_buzzar_8021FC34 = 0x8021fc34
export const RAM_entity_SaveBlock_pause_game = 0x802e114c
export const RAM_D_80244FB0_ADAE80 = 0x80244fb0
export const RAM_flo_14_UnkFunc43 = 0x80240340
export const RAM_b_area_sbk_pokey_8021C860 = 0x8021c860
export const RAM_HES_Item_ThunderRage_disabled = 0x800861c8
export const RAM_EnterWalk = 0x80285960
export const RAM_bombette_breaking_appendGfx = 0xe0084928
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022CBC4 = 0x8022cbc4
export const RAM_iwa_03_CleftAI_PostDisguise = 0x80240f8c
export const RAM_D_09002D20_3ADD50 = 0x09002d20
export const RAM_dgb_13_80240380 = 0x80240380
export const RAM_D_802454B0_BB2DF0 = 0x802454b0
export const RAM_b_area_trd_part_1_trd_04 = 0x80224338
export const RAM_kpa_134_SetEntityFlags100000 = 0x80240628
export const RAM_b_area_sam2_stageTable = 0x8022352c
export const RAM_arn_03_PatrolAI_Chase = 0x8024094c
export const RAM_flo_14_func_80242360_CCD670 = 0x80242360
export const RAM_D_E010AFA8 = 0xe010afa8
export const RAM_ui_battle_swap_background_png = 0x80291c80
export const RAM_b_area_hos_magikoopa_partsTable_8021D5F0 = 0x8021d5f0
export const RAM_arn_07_idle_80244CC8 = 0x80244cc8
export const RAM_D_80242390 = 0x80242390
export const RAM_D_802A9F84 = 0x802a9f84
export const RAM_ui_status_star_point_0_png = 0x80101140
export const RAM_HES_Item_DeluxeFeast = 0x80082d18
export const RAM_HES_MenuHammer3Disabled = 0x801088fc
export const RAM_battle_item_ultra_shroom_main = 0x802a18fc
export const RAM_dgb_12_80240310 = 0x80240310
export const RAM_pra_11_varStash = 0x80241610
export const RAM_dgb_17_80240268 = 0x80240268
export const RAM_b_area_isk_part_1_swooper_UnkBattleFunc1 = 0x802180d0
export const RAM_dro_02_80247AA0 = 0x80247aa0
export const RAM_pause_image_1439F0_png = 0x802506b0
export const RAM_kpa_91_ToadHouse_InitScreenOverlay = 0x8024173c
export const RAM_mac_00_Quizmo_AudienceEffect = 0x80255b94
export const RAM_got_item_outline_update = 0xe00360e4
export const RAM_ParakarryTakeOut = 0x802bd148
export const RAM_state_step_language_select = 0x800351c8
export const RAM_dgb_08_npcGroup_80246768 = 0x80246768
export const RAM_battle_menu_moveY = 0x802ad1f2
export const RAM_entity_HugeBlueSwitch_init = 0x802e2408
export const RAM_D_800A15CC = 0x800a15cc
export const RAM_func_E00E05F8 = 0xe00e05f8
export const RAM_func_80263E08 = 0x80263e08
export const RAM_dgb_03_MeleeHitbox_31 = 0x802402bc
export const RAM_gPauseBufferImg1 = 0x80270930
export const RAM_omo_05_GetNpcCollisionHeight = 0x80241f4c
export const RAM_dead_hide_popup_menu = 0x800f9260
export const RAM_flo_09_802434A8 = 0x802434a8
export const RAM_b_area_trd_part_2_green_ninja_koopa_handleEvent_80227E48 = 0x80227e48
export const RAM_vtx_C21130 = 0x802001f0
export const RAM_b_area_trd_part_2_green_ninja_koopa_802257A8 = 0x802257a8
export const RAM_dro_02_toggleVis_8024468C = 0x8024468c
export const RAM_dro_02_8024C05C = 0x8024c05c
export const RAM_mac_01_Quizmo_AnswerResult = 0x80262a58
export const RAM_dup_sam_01_StashVars = 0x80241c90
export const RAM_IES_BakingCream = 0x8008dbd4
export const RAM_dgb_04_settings = 0x80243130
export const RAM_func_802411F0_A3A6D0 = 0x802411f0
export const RAM_func_80261D98 = 0x80261d98
export const RAM_func_80240048_B028C8 = 0x80240048
export const RAM_iwa_10_foliage_setup_shear_mtx = 0x802415b0
export const RAM_AwaitPlayerLeave = 0x802d4a5c
export const RAM_b_area_sam_formation_06 = 0x80230bec
export const RAM_D_A4400010 = 0xa4400010
export const RAM_dup_jan_06_TransformFoliage = 0x80242410
export const RAM_BattleItemEntities = 0x801561a0
export const RAM_D_80240338_8FF1B8 = 0x80240338
export const RAM_ui_battle_cmd_charge_4_pal = 0x8028cf80
export const RAM_D_8023D2D0 = 0x8023d2d0
export const RAM_pra_11_reflection_render_wall = 0x802402f0
export const RAM_bss_802BFF04 = 0x802bff04
export const RAM_D_8009A668 = 0x8009a668
export const RAM_sam_06_FlyingAI_LoiterInit = 0x80240d28
export const RAM_dup_mac_00_LetterDelivery_CalcLetterPos = 0x8024395c
export const RAM_HES_SlowlyMashAButton = 0x80108c34
export const RAM_func_80241CF0_8EDB10 = 0x80241cf0
export const RAM_sam_02_Quizmo_DestroyEffects = 0x802414b4
export const RAM_omo_02_ShyGuyWanderAI_17 = 0x80240b48
export const RAM_func_8024301C_A2D25C = 0x8024301c
export const RAM_filemenu_8024C09C = 0x8024c09c
export const RAM_D_8029EFC0 = 0x8029efc0
export const RAM_b_area_omo_formation_01 = 0x8022f968
export const RAM_kkj_01_PatrolAI_Chase = 0x8024094c
export const RAM_b_area_pra2_sam_04_UnkModelStuffScript3 = 0x802212f4
export const RAM_func_80218E90_5CF520 = 0x80218e90
export const RAM_battle_item_sleepy_sheep1_png = 0x802a1f30
export const RAM_b_area_arn_formation_0A = 0x80227f54
export const RAM_b_area_pra3_duplighost_goombario_defenseTable = 0x8021bbc0
export const RAM_kzn_09_SuperBlock_get_partner_rank = 0x802400d4
export const RAM_D_802A15D8_7661F8 = 0x802a15d8
export const RAM_dro_01_interact_80249854 = 0x80249854
export const RAM_b_area_pra3_gray_magikoopa_knockOff = 0x8022f708
export const RAM_b_area_sam_gray_magikoopa_defenseTable_flying = 0x8022c31c
export const RAM_sam_11_Quizmo_HideEntities = 0x802408e8
export const RAM_D_09000D90_32DC50 = 0x09000d90
export const RAM_fx_cloud_puff = 0x8006fc50
export const RAM_pra_01_reflection_render_wall = 0x802402f0
export const RAM_func_80240604_AF7F54 = 0x80240604
export const RAM_b_area_sam_duplighost = 0x8021afd0
export const RAM_func_80242A80_825D40 = 0x80242a80
export const RAM_D_802AB340 = 0x802ab340
export const RAM_D_8029D410 = 0x8029d410
export const RAM_partner_flying_enable = 0x800ed310
export const RAM_b_area_sam_gray_magikoopa_flying = 0x8022c538
export const RAM_b_area_kmr_part_2_blue_goomba_2_handleEvent_8021D8C4 = 0x8021d8c4
export const RAM_IES_KootEmptyWallet = 0x8008bd44
export const RAM_dead_basic_ai_found_player_jump = 0x8004e2ec
export const RAM_dro_01_PatrolAI_LoiterInit = 0x802409e0
export const RAM_flo_08_80245C3C = 0x80245c3c
export const RAM_dro_01_BuildKeyItemChoiceList = 0x802427bc
export const RAM_b_area_isk_part_1_stageTable = 0x802248a4
export const RAM_b_area_nok_paratroopa_idle_80220D40 = 0x80225b70
export const RAM_b_area_kzn_formation_02 = 0x8022981c
export const RAM_func_802BC32C_E2EC5C = 0x802bc32c
export const RAM_D_09003DC8_3AEDF8 = 0x09003dc8
export const RAM_D_80247F88_808808 = 0x80247f88
export const RAM_b_area_arn_hypergoomba_takeTurn_802195F0 = 0x802195f0
export const RAM_dgb_01_D_80252688_C0FF08 = 0x80252688
export const RAM_make_entity_model_mtx_flipZ = 0x80121140
export const RAM_D_E0122CC0 = 0xe0122cc0
export const RAM_enable_actor_blur = 0x802542cc
export const RAM_pra_22_UnkFunc11 = 0x80240020
export const RAM_HES_WaterBlock2 = 0x802ab068
export const RAM_DoImmune = 0x8029ac70
export const RAM_hos_06_LetterDelivery_SavedNpcAnim = 0x80246424
export const RAM_func_802BC974_E2D0A4 = 0x802bc974
export const RAM_au_BGMCmd_F7_SubTrackReverbType = 0x80050128
export const RAM_kmr_20_Pipe_SetAnimFlag = 0x80240030
export const RAM_func_802463E0_806C60 = 0x802463e0
export const RAM_D_802BEBC4 = 0x802bebc4
export const RAM_kpa_130_BulletBillAI_Main = 0x80240048
export const RAM_D_8024CAF0_C0A370 = 0x8024caf0
export const RAM_AU_FX_CUSTOM_PARAMS = 0x8007f1f8
export const RAM_flo_25_tree1_Trunk = 0x80244654
export const RAM_D_E00B4EF0 = 0xe00b4ef0
export const RAM_D_802ACFE8 = 0x802acfe8
export const RAM_pra_38_reflection_unk_resolve_anim = 0x80240050
export const RAM_D_80243608_ACE128 = 0x80243608
export const RAM_b_area_sam_duplighost_pos_summon = 0x8021cae8
export const RAM_b_area_trd_part_3_eldstar = 0x80218368
export const RAM_func_80240310_90B9E0 = 0x80240310
export const RAM_trd_07_ParatroopaAI_Reset = 0x802422d8
export const RAM_D_8024F9E0_C0D260 = 0x8024f9e0
export const RAM_omo_04_FlyingAI_JumpVels = 0x80242ef0
export const RAM_npc_test_move_simple_with_slipping = 0x800ddc44
export const RAM_HES_AsleepEnd = 0x80104e64
export const RAM_arn_11_init_80240E70 = 0x80240e70
export const RAM_omo_09_ProjectileHitbox_32 = 0x802427d0
export const RAM_ui_coin_sparkle_3_pal = 0x800fbd60
export const RAM_dup_jan_07_TransformFoliage = 0x80241050
export const RAM_dro_02_EVS_Quizmo_Npc_AI = 0x80247390
export const RAM_entity_base_switch_anim_init = 0x802e176c
export const RAM_flo_12_npcGroupList_802429B8 = 0x802429b8
export const RAM_b_area_pra3_pra_01_beforeBattle = 0x80231aa0
export const RAM_ui_battle_cont_c_left_up_png = 0x8028b3a0
export const RAM_dgb_02_extraAnimationList_80241B94 = 0x80241b94
export const RAM_dgb_11_pad_168 = 0x80240168
export const RAM_ResetActorSounds = 0x8026f838
export const RAM_b_area_kmr_part_3_kpa_01_beforeBattle_80234D60 = 0x80234d60
export const RAM_b_area_iwa_monty_mole_partsTable_8021A508 = 0x8021a508
export const RAM_b_area_iwa_monty_mole_idleAnimations_8021A5F4 = 0x8021a5f4
export const RAM_sam_11_Quizmo_VannaTEffect = 0x80251478
export const RAM_pra_12_SomeItemEntityFunc = 0x80241068
export const RAM_mac_05_Quizmo_GetCamVfov = 0x80241f00
export const RAM_dro_02_D_80248B50_971D10 = 0x80248b50
export const RAM_advance_rng = 0x80029900
export const RAM_mac_00_PatrolAI_Jump = 0x80242d14
export const RAM_b_area_trd_part_2_trd_04_80232A40 = 0x80232a40
export const RAM_pra_11_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_HES_Item_Unused_0E6 = 0x80085a18
export const RAM_arn_13_80240060 = 0x80240060
export const RAM_b_area_kmr_part_3_paragoomba2_8021C574 = 0x80220bb4
export const RAM_SetZoneEnabled = 0x802ca254
export const RAM_NpcJump1 = 0x802ce6e0
export const RAM_action_update_falling = 0x802b6294
export const RAM_D_80154424 = 0x80154424
export const RAM_b_area_trd_part_2_trd_05d_80234540 = 0x80234540
export const RAM_func_802BB000_E2D930 = 0x802bb000
export const RAM_HES_Item_Unused_0F3_disabled = 0x80085f28
export const RAM_nuContDataClose = 0x8006060c
export const RAM_fx_fire_flower = 0x80071030
export const RAM_D_80232AE8_684868 = 0x80232ae8
export const RAM_entity_HeartBlockContent_anim_idle = 0x802e4c10
export const RAM_kzn_10_UpdateTexturePanStepped = 0x80240124
export const RAM_D_802BCBC8_E31518 = 0x802bcbc8
export const RAM_flo_08_FlowerGuard_MakeItemList = 0x80241bcc
export const RAM_dead_get_item_entity = 0x8013cf98
export const RAM_mgm_02_PeachPanelImg = 0x802482a0
export const RAM_CreateEntityVarArgBuffer2 = 0x8015c7d8
export const RAM_D_A4000000 = 0xa4000000
export const RAM_flo_00_npcSettings_802428BC = 0x802428bc
export const RAM_SetAnimationRate = 0x8026b2d0
export const RAM_b_area_isk_part_2_chain_chomp_8021972C = 0x8021972c
export const RAM_func_802D4C4C = 0x802d4c4c
export const RAM_ui_pow_cost_notenough_pal = 0x800fc400
export const RAM_mgm_01_EnableMenus = 0x80240438
export const RAM_func_802C3EE4 = 0x802c3ee4
export const RAM_b_area_pra2_crystal_bit_idleAnimations_1 = 0x80220480
export const RAM_flo_08_init_80243B20 = 0x80243b20
export const RAM_HES_Item_BakingMilk_disabled = 0x80087548
export const RAM_battle_item_shooting_star_GiveRefund = 0x802a1000
export const RAM_alHeapAlloc = 0x80057f90
export const RAM_D_80280A30 = 0x80280a30
export const RAM_YieldTurn = 0x8027d7f0
export const RAM_evt_handle_does_script_exist = 0x802c6a78
export const RAM_dro_01_Quizmo_SpinPlayer = 0x8024228c
export const RAM_D_802A97C0_42CEB0 = 0x802a97c0
export const RAM_D_E02006D0 = 0xe02006d0
export const RAM_func_80055D38 = 0x80055d38
export const RAM_quizmo_stage_update = 0xe009c108
export const RAM_get_current_map_config = 0x8005abd8
export const RAM_D_802433B0_B76460 = 0x802433b0
export const RAM_D_09000CA0_3270B0 = 0x09000ca0
export const RAM_pra_29_reflection_unk_resolve_anim = 0x80240020
export const RAM_battle_menu_moveCursorIcon = 0x802ad118
export const RAM_mac_05_LetterDelivery_Init = 0x802427a4
export const RAM_IES_HustleDrink = 0x8008dd34
export const RAM_state_init_logos = 0x80033e70
export const RAM_battle_menu_moveTextOpacity = 0x802ad116
export const RAM_D_802BFEF0 = 0x802bfef0
export const RAM_D_090009E8_3318E8 = 0x090009e8
export const RAM_snd_song_set_track_vol_quiet = 0x80055ff0
export const RAM_func_80053A98 = 0x80053a98
export const RAM_hud_element_set_aux_cache = 0x801452e8
export const RAM_D_80234840_6BBA40 = 0x80234840
export const RAM_b_area_hos_ember_8021C454 = 0x8021c454
export const RAM_ui_misstar_png = 0x800fd800
export const RAM_flo_00_PatrolAI_Move = 0x80240198
export const RAM_sbk_13_Bandit_DropCoin = 0x80240040
export const RAM_mac_03_Quizmo_HideEntities = 0x802405c8
export const RAM_b_area_jan_hurt_plant_init_8021DA98 = 0x8021da98
export const RAM_EA0C10_set_script_owner_npc_anim = 0x80241d68
export const RAM_b_area_mac_mac_01_intTable_802321A4 = 0x802321a4
export const RAM_D_802B6E98_E2A308 = 0x802b6e98
export const RAM_SetGroupEnabled = 0x802c9308
export const RAM_jan_06_HoppingAI_Main = 0x80241330
export const RAM_battle_star_star_storm_802A202C = 0x802a202c
export const RAM_dgb_02_ClubbaNappingAI_WakeUp = 0x80240c10
export const RAM_arn_07_npcGroupList_8024787C = 0x8024787c
export const RAM_D_09000C00_386FB0 = 0x09000c00
export const RAM_flo_21_80240D40 = 0x80240d40
export const RAM_ui_battle_hp_6_png = 0x8028aae0
export const RAM_windy_leaves_render = 0xe0056b8c
export const RAM_D_80246C04_D0C334 = 0x80246c04
export const RAM_b_area_kmr_part_2_kmr_05_afterBattle_802253BC = 0x802253bc
export const RAM_b_area_sbk_bandit_init_8021D750 = 0x8021d750
export const RAM_b_area_mac_lee_UnkActorSizeFunc = 0x80218ce4
export const RAM_dro_01_Quizmo_NpcSettings = 0x80247788
export const RAM_dgb_15_80242AD0 = 0x80242ad0
export const RAM_D_80245D40_A99480 = 0x80245d40
export const RAM_trd_03_UnkNpcAIFunc12 = 0x80240250
export const RAM_kzn_11_FlyingAI_Jump = 0x802410b4
export const RAM_D_802440E5 = 0x802440e5
export const RAM_end_01_CharAnim_FadeIn_5 = 0x80240ec0
export const RAM_entity_BlueWarpPipe_set_player_move_to_center = 0x802e8bc0
export const RAM_func_80240478_EB76A8 = 0x80240478
export const RAM_mac_04_AddPlayerHandsOffset = 0x80241550
export const RAM_guNormalize = 0x8006cbc0
export const RAM_b_area_mim_formation_0E = 0x8021e93c
export const RAM_SetForegroundModelsVisibleUnchecked = 0x80253aa8
export const RAM_jan_13_TransformFoliage = 0x80240400
export const RAM_HES_MisstarDisabled = 0x80107b1c
export const RAM_battle_menu_moveOptionDisplayCosts = 0x802ad320
export const RAM_sbk_42_Bandit_DropCoin = 0x80240040
export const RAM_battle_star_time_out_usePower = 0x802a23d8
export const RAM_func_80281C20 = 0x80281c20
export const RAM_nok_01_Quizmo_UpdatePartnerPosition = 0x80241c78
export const RAM_b_area_omo_omo_04_afterBattle_8022DDEC = 0x8022ddec
export const RAM_pra_05_StashVars = 0x80240f20
export const RAM_mac_01_Quizmo_FadeInWorld = 0x80242488
export const RAM_action_update_state_23 = 0x802b609c
export const RAM_mim_01_MeleeHitbox_30 = 0x80240500
export const RAM_D_E0200714 = 0xe0200714
export const RAM_CamPresetUpdate_G = 0x8024a214
export const RAM_sbk_56_SuperBlock_WaitForPlayerToLand = 0x80240d88
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022CC04 = 0x8022cc04
export const RAM_battle_item_ultra_shroom_PlayerGoHome = 0x802a1770
export const RAM_D_80246534 = 0x80246534
export const RAM_phys_clear_spin_history = 0x800e58f0
export const RAM_D_8024FA34 = 0x8024fa34
export const RAM_windy_leaves_main = 0xe0056000
export const RAM_balloon_init = 0xe00960ec
export const RAM_pra_02_ReflectPartner = 0x80240d3c
export const RAM_HES_Item_SlowGo = 0x80084578
export const RAM_battle_move_power_smash_IsBerserkerEquipped = 0x802a1000
export const RAM_vtx_C2E620 = 0x8020d6e0
export const RAM_arn_02_CleftAI_DisguiseInit = 0x80240b68
export const RAM_kpa_61_FlyingMagikoopaAI_OnHitInit = 0x802422b8
export const RAM_D_802E9E80 = 0x802e9e80
export const RAM_dup_mac_01_PatrolNoAttackAI_Main = 0x802460e4
export const RAM_b_area_hos_magikoopa_statusTable_8021D498 = 0x8021d498
export const RAM_dro_01_Quizmo_AddViewRelativeOffset = 0x802420f0
export const RAM_D_A6000000 = 0xa6000000
export const RAM_kzn_02_LetterDelivery_SaveNpcAnim = 0x802426b4
export const RAM_ui_battle_status_shrink_2_png = 0x80102c20
export const RAM_flo_00_init_802461E4 = 0x802461e4
export const RAM_b_area_kmr_part_2_blue_goomba_init_8021B268 = 0x8021b268
export const RAM_sbk_66_Bandit_DropCoin = 0x80240040
export const RAM_b_area_hos_hos_00_afterBattle_8022589C = 0x8022589c
export const RAM_b_area_dgb_dgb_01_beforeBattle_8021B050 = 0x8021b050
export const RAM_b_area_trd_part_2_trd_02_afterBattle_80231C98 = 0x80231c98
export const RAM_D_09002198_388548 = 0x09002198
export const RAM_tik_07_SuperBlock_SwitchToPartner = 0x802406fc
export const RAM_mac_04_SetNpcShadowScale = 0x80241bb8
export const RAM_kpa_33_UpdateTexturePanSmooth = 0x80240000
export const RAM_vtx_C2CF00 = 0x8020bfc0
export const RAM_b_area_kmr_part_1_paragoomba_8021B6F0 = 0x8021d0e0
export const RAM_lil_oink_render = 0xe0114340
export const RAM_D_E010AFA0 = 0xe010afa0
export const RAM_jan_12_SpearGuyAI_LoiterInit = 0x802404c0
export const RAM_flo_13_FlyingAI_Init = 0x802413ac
export const RAM_kpa_61_UpdateTexturePanSmooth = 0x80240000
export const RAM_IES_HeartPoint = 0x8008cecc
export const RAM_func_80218404_608EB4 = 0x80218404
export const RAM_dro_01_init_8024981C = 0x8024981c
export const RAM_func_8026962C = 0x8026962c
export const RAM_D_80242F40_992720 = 0x80242f40
export const RAM_SetupSplitDoubleDoors = 0x80283e2c
export const RAM_dgb_04_SuperBlock_UpgradeOrbAngles = 0x80243d88
export const RAM_func_E011A3BC = 0xe011a3bc
export const RAM_spr_get_npc_palettes = 0x802dea40
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_return_home = 0x80234040
export const RAM_mim_04_HoppingAI_Hop = 0x80240430
export const RAM_b_area_jan_spear_guy_summonBackup = 0x80219ed0
export const RAM_quizmo_audience_update = 0xe00a8104
export const RAM_nuContRmbMgrRemove = 0x8005fcec
export const RAM_func_80269344 = 0x80269344
export const RAM_dgb_10_enterWalk_80240378 = 0x80240378
export const RAM_D_802498D8_A33B18 = 0x802498d8
export const RAM_func_80240770_8A86F0 = 0x80240770
export const RAM_IES_RepelGel = 0x8008b364
export const RAM_D_802A3514_7994D4 = 0x802a3514
export const RAM_D_80241854_EDD834 = 0x80241854
export const RAM_vtx_C21770 = 0x80200830
export const RAM_dro_02_ToadHouse_DisableStatusMenu = 0x80243484
export const RAM_D_8024E888_C0C108 = 0x8024e888
export const RAM_kpa_95_ToadHouse_DoesPlayerNeedSleep = 0x802417c8
export const RAM_dgb_01_D_80252D3C_C105BC = 0x80252d3c
export const RAM_omo_05_ShyGuyWanderAI_Main = 0x80240238
export const RAM_func_802410E4_EB9F34 = 0x802410e4
export const RAM_battle_item_food_func_802A1378_733448 = 0x802a1378
export const RAM_D_80250FC8_C0E848 = 0x80250fc8
export const RAM_battle_action_cmd_whirlwind_1_png = 0x802a98e0
export const RAM_isk_09_SomeItemEntityFunc = 0x80240118
export const RAM_arn_05_802414E8 = 0x802414e8
export const RAM_dro_01_openDoor_8024BC24 = 0x8024bc24
export const RAM_battle_star_star_beam_usePower = 0x802a35b4
export const RAM_b_area_isk_part_1_isk_02b = 0x80223e64
export const RAM_omo_16_CompareFloats = 0x802400e4
export const RAM_D_80244180_D913D0 = 0x80244180
export const RAM_func_80241A6C_D3C03C = 0x80241a6c
export const RAM_disable_player_shadow = 0x800e00e4
export const RAM_kpa_14_func_80240710_A57BC0 = 0x80240710
export const RAM_D_E00587B0 = 0xe00587b0
export const RAM_squirt_appendGfx = 0xe00b24b0
export const RAM_fio_has_valid_backup = 0x8002afd8
export const RAM_D_802479C0_DF6860 = 0x802479c0
export const RAM_D_8023150C_63D0AC = 0x8023150c
export const RAM_func_80243254_8553C4 = 0x80243254
export const RAM_D_E00E0AC8 = 0xe00e0ac8
export const RAM_flo_08_SuperBlock_GetPartnerRank = 0x80240068
export const RAM_mac_00_LetterDelivery_Init = 0x80241ab4
export const RAM_HES_MarioHeadSmall = 0x802429d4
export const RAM_HES_MapWalk0 = 0x80242644
export const RAM_sbk_56_SuperBlock_LoadCurrentPartnerName = 0x802404c8
export const RAM_D_E02006D8 = 0xe02006d8
export const RAM_dgb_15_PatrolAI_LoiterInit = 0x80240cd0
export const RAM_b_area_pra3_white_clubba_defenseTable = 0x8022b17c
export const RAM_b_area_iwa_whacka_80220684 = 0x80220684
export const RAM_ui_status_star_point_4_png = 0x801013c0
export const RAM_D_802407E0_7E7B80 = 0x802407e0
export const RAM_D_8029D8E8 = 0x8029d8e8
export const RAM_ActorExists = 0x8026de5c
export const RAM_b_area_mac_master3_partsTable_8022DAF0 = 0x8022daf0
export const RAM_tik_06_UnkPosFunc2 = 0x802405f0
export const RAM_b_area_isk_part_1_buzzy_beetle_defenseTable_8021E4DC = 0x8021e4dc
export const RAM_ui_peach_beam_disabled_pal = 0x800fe320
export const RAM_D_80245950_B25E90 = 0x80245950
export const RAM_mac_03_Quizmo_Answers = 0x802466a8
export const RAM_vtx_C25DA0 = 0x80204e60
export const RAM_pause_spirits_draw_title = 0x8024d294
export const RAM_b_area_pra3_gray_magikoopa_idleAnimations2 = 0x8022f07c
export const RAM_b_area_sam_gulpit_handleEvent = 0x80228030
export const RAM_IES_BakingStrawberry = 0x8008dbf4
export const RAM_b_area_kmr_part_2_formation_01 = 0x802197d0
export const RAM_D_090015A8 = 0x090015a8
export const RAM_D_802411A0_D7FF00 = 0x802411a0
export const RAM_battle_star_chill_out_UnkStarFunc4 = 0x802a1218
export const RAM_HES_Item_BigCookie = 0x80082dd8
export const RAM_dgb_18_SomeItemEntityFunc = 0x802410c8
export const RAM_D_80244158_BC12F8 = 0x80244158
export const RAM_dgb_07_npcGroupList_80241E5C = 0x80241e5c
export const RAM_ui_hammer_pal = 0x800faa20
export const RAM_dead_gCameras = 0x800b8d80
export const RAM_IES_SpinSmash = 0x8008bee4
export const RAM_func_80241540_B2D9B0 = 0x80241540
export const RAM_func_8024052C_D3AAFC = 0x8024052c
export const RAM_EF7A80_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_80240B48_8EC968 = 0x80240b48
export const RAM_EA8AE0_GetItemName = 0x8024151c
export const RAM_D_8024F2A1 = 0x8024f2a1
export const RAM_wTransformGroups = 0x80151f70
export const RAM_state_step_startup = 0x80033568
export const RAM_b_area_jan_jan_03b = 0x80228430
export const RAM_au_bgm_player_initialize = 0x8004e5d8
export const RAM_battle_menu_DisabledPartnerHudScripts = 0x802ab438
export const RAM_fx_stars_shimmer = 0x80070550
export const RAM_dro_02_802442F0 = 0x802442f0
export const RAM_b_area_sam2_sam_02d_UnkFloatFunc2 = 0x80219a1c
export const RAM_func_802BBE14_E30764 = 0x802bbe14
export const RAM_battle_partner_kooper_80239384 = 0x80239384
export const RAM_b_area_kzn_medi_guy_healOneAlly = 0x802209f8
export const RAM_func_802424A0_833CA0 = 0x802424a0
export const RAM_D_802498FC_A33B3C = 0x802498fc
export const RAM_SetOwnerTarget = 0x8026e3a8
export const RAM_arn_07_802437AC = 0x802437ac
export const RAM_au_ambience_enable = 0x80055718
export const RAM_nuContDataGet = 0x8005f650
export const RAM_GetAngleToPlayer = 0x802d4830
export const RAM_mac_03_Quizmo_CreateStage = 0x80240c20
export const RAM_dro_01_pad_D_8024DFC4 = 0x8024dfc4
export const RAM_battle_item_egg_missile_modelCommandList = 0x802a2550
export const RAM_func_802405F0_8238B0 = 0x802405f0
export const RAM_b_area_mac_lee_defenseTable_8022445C = 0x8022445c
export const RAM_battle_item_pebble_GiveRefund = 0x802a1000
export const RAM_ui_super_boots_disabled_pal = 0x800fb340
export const RAM_D_E00E0AC0 = 0xe00e0ac0
export const RAM_arn_07_entryList = 0x80241e90
export const RAM_func_8028070C = 0x8028070c
export const RAM_D_8015C7A8 = 0x8015c7a8
export const RAM_au_BGMCmd_FF = 0x800502f0
export const RAM_filemenu_draw_contents_file_title = 0x8024544c
export const RAM_osr_02_SetPlayerSpriteSet2 = 0x80240000
export const RAM_D_8024FBC0_C0D440 = 0x8024fbc0
export const RAM_jan_05_HoppingAI_LoiterInit = 0x80240e0c
export const RAM_kpa_03_MagikoopaAI_11 = 0x8024150c
export const RAM_flo_18_vectorList_80243F68 = 0x80243f68
export const RAM_D_80245630 = 0x80245630
export const RAM_HES_SmallText4 = 0x802421a0
export const RAM_func_80241950_8D2E60 = 0x80241950
export const RAM_ui_stencil_mario_png = 0x8014cb00
export const RAM_dgb_06_main = 0x802404f0
export const RAM_func_80240CE8_B076E8 = 0x80240ce8
export const RAM_mim_08_PiranhaPlantAI_Main = 0x80242cec
export const RAM_au_get_bgm_player_and_file = 0x80053c58
export const RAM_gMessageWindowProjMatrix = 0x80155ca0
export const RAM_D_80243080_DE5510 = 0x80243080
export const RAM_ui_eldstar_png = 0x800fcf00
export const RAM_evt_handle_case_equal = 0x802c5030
export const RAM_WorldItemEntities = 0x80155da0
export const RAM_sam_02_Quizmo_varStash = 0x80246b60
export const RAM_D_802AD068 = 0x802ad068
export const RAM_fx_ice_pillar = 0x800728f0
export const RAM_D_E0024CC0 = 0xe0024cc0
export const RAM_gLogosImages = 0x800a0910
export const RAM_kzn_09_UnkDistFunc = 0x80242a40
export const RAM_EnemyTestTarget = 0x8027cfb8
export const RAM_lookup_defense = 0x80265e44
export const RAM_func_8024114C_D5113C = 0x8024114c
export const RAM_b_area_pra2_crystal_king_MoveBitToTarget = 0x8021ef84
export const RAM_npc_get_slip_vector = 0x800dd5b4
export const RAM_ui_battle_status_stop_2_png = 0x80102860
export const RAM_battle_item_sleepy_sheep_func_802A123C_71E88C = 0x802a123c
export const RAM_dgb_01_D_80251EF8_C0F778 = 0x80251ef8
export const RAM_dgb_09_dgb_00_name_hack = 0x802457a8
export const RAM_D_E0116DB8 = 0xe0116db8
export const RAM_D_802519F0_C0F270 = 0x802519f0
export const RAM_flo_14_npcSettings_802445A4 = 0x802445a4
export const RAM_dup_kmr_20_Pipe_GetPointAheadOfPlayer = 0x802419bc
export const RAM_D_09002170_352390 = 0x09002170
export const RAM_func_80240330_AD6200 = 0x80240330
export const RAM_kmr_11_StationaryAI_LosePlayer = 0x80240868
export const RAM_HES_MenuBoots3 = 0x801087e4
export const RAM_dgb_08_exitDoubleDoor_80243D90 = 0x80243d90
export const RAM_D_8010D6A0 = 0x8010d6a0
export const RAM_flo_07_updateTexturePan_802408D0 = 0x802408d0
export const RAM_dgb_18_npcAI_802426B0 = 0x802426b0
export const RAM_evt_handle_set_float = 0x802c57b8
export const RAM_dro_02_8024D494 = 0x8024d494
export const RAM_kkj_14_UpdateTexturePanStepped = 0x80240174
export const RAM_D_090003F0_392830 = 0x090003f0
export const RAM_D_8014BC50 = 0x8014bc50
export const RAM_b_area_sbk_pokey_8021D184 = 0x8021d184
export const RAM_embers_main = 0xe00e0000
export const RAM_pra_38_ReflectFloor = 0x802404cc
export const RAM_mac_03_Quizmo_NPC_Aux_Impl = 0x8024085c
export const RAM_D_80241890_D7CB30 = 0x80241890
export const RAM_nuAuPreNMIFuncSet = 0x8004add0
export const RAM_func_802427CC_8B283C = 0x802427cc
export const RAM_D_802838F8 = 0x802838f8
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021B338 = 0x8021cd28
export const RAM_b_area_iwa_whacka_80220764 = 0x80220764
export const RAM_b_area_omo2_2_stilt_guy_takeTurn = 0x80232c78
export const RAM_mim_01_MeleeHitbox_Main = 0x802408bc
export const RAM_vtx_C25C20 = 0x80204ce0
export const RAM_D_80244B50_A10840 = 0x80244b50
export const RAM_nuContData = 0x800b1d68
export const RAM_flo_00_npcSettings_80245E5C = 0x80245e5c
export const RAM_D_E0084E78 = 0xe0084e78
export const RAM_dro_01_exitWalk_80244960 = 0x80244960
export const RAM_D_E00BEB20 = 0xe00beb20
export const RAM_D_E0066C8C = 0xe0066c8c
export const RAM_battle_item_strange_cake_UseItem = 0x802a1eb4
export const RAM_sparkles_update = 0xe002254c
export const RAM_load_effect = 0x8005a564
export const RAM_func_8024033C_AA92DC = 0x8024033c
export const RAM_end_01_credits_load_message = 0x80242368
export const RAM_PlayEffect = 0x802d829c
export const RAM_btl_state_draw_partner_menu = 0x802a9ae8
export const RAM_b_area_pra3_pra_04_802321A0 = 0x802321a0
export const RAM_battle_partner_watt_AverageTargetParalyzeChance = 0x80238e5c
export const RAM_GetPartDispOffset = 0x8026bae8
export const RAM_D_8024F2E4 = 0x8024f2e4
export const RAM_player_raycast_below = 0x800ddfd0
export const RAM_HES_HPDigit5 = 0x80292070
export const RAM_b_area_hos_magikoopa_8021D784 = 0x8021d784
export const RAM_D_80151128 = 0x80151128
export const RAM_gfx_transfer_frame_to_depth = 0x800279b4
export const RAM_func_E00A639C = 0xe00a639c
export const RAM_D_8010D692 = 0x8010d692
export const RAM_D_8010D655 = 0x8010d655
export const RAM_D_802AD640 = 0x802ad640
export const RAM_jan_11_Pipe_GetEntryPos = 0x80240248
export const RAM_gAnimTranslateMtx = 0x80153ae8
export const RAM_func_80058050 = 0x80058050
export const RAM_b_area_jan2_jan_01_beforeBattle_8021E9C4 = 0x8021e9c4
export const RAM_D_E007AC00 = 0xe007ac00
export const RAM_sam_05_PiranhaPlantAI_12 = 0x80241508
export const RAM_kzn_09_WanderMeleeAI_Main = 0x8024163c
export const RAM_init_generic_entity_list = 0x80123114
export const RAM_ui_battle_status_refund_png = 0x801033e0
export const RAM_kmr_02_SyncStatusMenu = 0x8024293c
export const RAM_swapBufMsg = 0x8009c516
export const RAM_b_area_mac_lee_defenseTable_802269C8 = 0x802269c8
export const RAM_flo_17_set_script_owner_npc_anim = 0x80242144
export const RAM_func_8024035C_ADB1FC = 0x8024035c
export const RAM_omo_05_AddPlayerHandsOffset = 0x80241fa8
export const RAM_pra_38_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_flo_11_init_80242B58 = 0x80242b58
export const RAM_arn_03_settings = 0x80241740
export const RAM_mim_06_HoppingAI_LosePlayer = 0x80242ac0
export const RAM_func_80240574_9EE164 = 0x80240574
export const RAM_b_area_pra3_pra_01_foregroundModelList = 0x80231afc
export const RAM_dgb_05_entryList = 0x80241490
export const RAM_b_area_pra2_crystal_king_OnDeath = 0x80219b80
export const RAM_dgb_09_npcAISettings_802441B8 = 0x802441b8
export const RAM_ui_battle_cmd_timing_ok_png = 0x8028d5e0
export const RAM_D_80242B9C_A0E88C = 0x80242b9c
export const RAM_osPackReadData = 0x80061ac4
export const RAM_b_area_sam_duplighost_idleAnimations3 = 0x8021aee8
export const RAM_load_demo_battle = 0x80072e14
export const RAM_D_800A41C4 = 0x800a41c4
export const RAM_disable_transform_group = 0x8011b660
export const RAM_D_09001060_327470 = 0x09001060
export const RAM_gPauseSpiritsDrawOrder = 0x8024f974
export const RAM_ClubbaDisguiseExtraAnims = 0x800f7b94
export const RAM_draw_entity_model_E = 0x80122288
export const RAM_func_800E04D0 = 0x800e04d0
export const RAM_D_09000600_350820 = 0x09000600
export const RAM_world_goombaria_init = 0x802bd100
export const RAM_pause_stats_draw_contents = 0x80245980
export const RAM_battle_star_up_and_away_UnkStarFunc1 = 0x802a1000
export const RAM_dup_b_area_dig_Set80218630_Var0 = 0x80218890
export const RAM_tik_19_SuperBlock_GatherEnergyFX = 0x80240b34
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_80220B38 = 0x80220b38
export const RAM_pink_sparkles_appendGfx = 0xe01244c4
export const RAM_fx_huff_puff_breath = 0x800721d0
export const RAM_b_area_sam_duplighost_lakilester_handleEvent = 0x8022635c
export const RAM_kmr_21_settings = 0x02800280
export const RAM_b_area_mac_formation_01 = 0x802329ec
export const RAM_HES_TimingCharge2 = 0x80292874
export const RAM_b_area_pra2_crystal_king_defenseTable = 0x80218a2c
export const RAM_b_area_nok_nok_04_beforeBattle_8022BB90 = 0x8022bb90
export const RAM_HES_Item_Mystery_disabled = 0x80082628
export const RAM_filemenu_update_show_with_rotation = 0x8024381c
export const RAM_b_area_nok_paragoomba_statusTable_8021B128 = 0x8021b128
export const RAM_D_802AD618 = 0x802ad618
export const RAM_arn_03_init_802431A4 = 0x802431a4
export const RAM_action_hammer_play_hit_fx = 0x802b6048
export const RAM_StartRumbleWithParams = 0x80267d9c
export const RAM_spr_defaultQuad = 0x802df390
export const RAM_battle_star_time_out_UnkStarFunc4 = 0x802a1218
export const RAM_b_area_sam_duplighost_kooper_idleAnimations2 = 0x8021e6c8
export const RAM_D_8023D294 = 0x8023d294
export const RAM_func_802BD17C_31B19C = 0x802bd17c
export const RAM_D_8010D6A8 = 0x8010d6a8
export const RAM_b_area_sam_sam_01_UnkModelStuffScript4 = 0x8022f1c4
export const RAM_D_80243880_B7D8D0 = 0x80243880
export const RAM_b_area_sam_duplighost_UnkActorSizeFunc = 0x802189e4
export const RAM_HES_Item_Unused_0EF_disabled = 0x80085da8
export const RAM_kmr_02_Quizmo_CreateWorker = 0x802413f4
export const RAM_D_090011D0 = 0x090011d0
export const RAM_pra_06_ReflectPartner = 0x80240d6c
export const RAM_flo_19_pad_1044 = 0x80241044
export const RAM_stars_spread_init = 0xe005a1f4
export const RAM_hos_06_GetNpcCollisionHeight = 0x80240d50
export const RAM_battle_item_dusty_hammer_DrinkItem = 0x802a1784
export const RAM_b_area_trd_part_1_trd_02d_80224DB8 = 0x80224db8
export const RAM_ui_battle_cmd_charge_1_png = 0x8028cea0
export const RAM_b_area_kmr_part_3_para_jr_troopa_idleAnimations_80225DC0 = 0x80225dc0
export const RAM_D_E010E750 = 0xe010e750
export const RAM_Merlow_GetPlayerStarPieces = 0x80240a68
export const RAM_D_800DC060 = 0x800dc060
export const RAM_flo_25_D_8024527C = 0x8024527c
export const RAM_D_80242EE8_DE5378 = 0x80242ee8
export const RAM_nuContRmbForceStop = 0x800600a0
export const RAM_gPauseBufferPal1 = 0x80270730
export const RAM_vtx_C23D20 = 0x80202de0
export const RAM_btl_state_draw_player_menu = 0x802a8180
export const RAM_code_eb1170_bss = 0x802442d0
export const RAM_kpa_61_MagikoopaAI_SpellMain = 0x80240cb4
export const RAM_ui_battle_status_hp_drain_png = 0x80103740
export const RAM_flo_03_main = 0x80240c2c
export const RAM_D_8024CFF8_C0A878 = 0x8024cff8
export const RAM_func_80241850_A2BA90 = 0x80241850
export const RAM_nuScResetClientMesgType = 0x8005ef38
export const RAM_b_area_sam_white_clubba_statusTable = 0x80229b28
export const RAM_dro_02_Quizmo_GetCamVfov = 0x80240ee0
export const RAM_snd_set_song_fade = 0x80055a6c
export const RAM_D_80254C50 = 0x80254c50
export const RAM_D_802547D0 = 0x802547d0
export const RAM_kpa_95_PatrolAI_MoveInit = 0x802400e0
export const RAM_D_8029D8E0 = 0x8029d8e0
export const RAM_battle_item_thunder_bolt_func_802A1420_722F60 = 0x802a1420
export const RAM_ui_box_corners5_png = 0x8010b160
export const RAM_sam_03_MeleeHitbox_Main = 0x8024040c
export const RAM_HES_StatusDigit3 = 0x80108130
export const RAM_b_area_kmr_part_3_paragoomba2_statusTable_8021B1D4 = 0x8021f814
export const RAM_dup_kmr_11_TransformFoliage = 0x80240f90
export const RAM_sort_scripts = 0x802c3000
export const RAM_D_802A9B00 = 0x802a9b00
export const RAM_isk_10_SuperBlock_PartnerIDs = 0x80241108
export const RAM_D_800939B4 = 0x800939b4
export const RAM_b_area_omo2_toy_tank_8021A534 = 0x8021a534
export const RAM_flo_11_vectorList_80241AEC = 0x80241aec
export const RAM_StopLoopingSoundAtActor = 0x80253a18
export const RAM_flo_18_npcGroupList_8024669C = 0x8024669c
export const RAM_entity_HiddenPanel_init = 0x802e6c24
export const RAM_flo_21_StarSpiritEffectFunc2 = 0x8024004c
export const RAM_battle_item_sleepy_sheep_model = 0x802a2f50
export const RAM_omo_07_ShyGuyPatrolAI_Main = 0x80241634
export const RAM_b_area_nok_fuzzy_statusTable_8022205C = 0x8022205c
export const RAM_b_area_isk_part_1_vector3D_80224094 = 0x80224094
export const RAM_gPauseWS_25 = 0x8026fe78
export const RAM_D_09001D00 = 0x09001d00
export const RAM_func_802183EC_6DC18C = 0x802183ec
export const RAM_flo_10_80241F20 = 0x80241f20
export const RAM_b_area_kmr_part_1_paragoomba_defenseTable_8021B110 = 0x8021cb00
export const RAM_D_802A9FAC = 0x802a9fac
export const RAM_b_area_nok_nok_03_beforeBattle_8022BB20 = 0x8022bb20
export const RAM_flo_13_UnkNpcAIFunc12 = 0x80242448
export const RAM_b_area_omo_pink_shy_guy_takeTurn_8021FDB4 = 0x8021fdb4
export const RAM_entity_HugeBlueSwitch_idle = 0x802e1614
export const RAM_dgb_09_80243880 = 0x80243880
export const RAM_dgb_00_pad_3758 = 0x80243758
export const RAM_D_80232A70_6847F0 = 0x80232a70
export const RAM_D_802463A8_915A58 = 0x802463a8
export const RAM_b_area_omo_omo_03b_afterBattle_8022DCEC = 0x8022dcec
export const RAM_isk_13_HoppingAI_HopInit = 0x80240000
export const RAM_SetPartFlagBits = 0x8026d010
export const RAM_func_802190F0_6197E0 = 0x802190f0
export const RAM_ui_battle_status_frozen_2_png = 0x801025e0
export const RAM_b_area_trd_part_2_trd_05e_80234E98 = 0x80234e98
export const RAM_HES_Item_DefendPlus = 0x800846f8
export const RAM_b_area_sam_gulpit_parts = 0x80227eec
export const RAM_D_80240CBC_BC374C = 0x80240cbc
export const RAM_battle_item_life_shroom_UseItemWithEffect = 0x802a1500
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021B3D0 = 0x8021fa10
export const RAM_IdleJumpToGoal = 0x8027910c
export const RAM_b_area_kmr_part_1_spiked_goomba_partsTable_8021E468 = 0x8021b088
export const RAM_sam_08_MeleeHitbox_33 = 0x80240ef4
export const RAM_D_E0116DB0 = 0xe0116db0
export const RAM_battle_star_peach_focus_UnkStarFunc1 = 0x802a1000
export const RAM_D_802443F8_C3A928 = 0x802443f8
export const RAM_b_area_tik3_UpdateTexturePanStepped = 0x80219664
export const RAM_D_80243928_A4BEE8 = 0x80243928
export const RAM_b_area_iwa_whacka_statusTable_8021FCA8 = 0x8021fca8
export const RAM_ui_input_analog_stick2_pal = 0x800f9380
export const RAM_battle_item_snowman_doll_UseItem = 0x802a1744
export const RAM_flo_15_8024032C = 0x8024032c
export const RAM_D_09000000_3CFAF0 = 0x09000000
export const RAM_battle_move_multibounce_D_802A17C4 = 0x802a17c4
export const RAM_dgb_01_D_80251D6C_C0F5EC = 0x80251d6c
export const RAM_BattleCam_MoveTimeTotal = 0x8029f2a0
export const RAM_vtx_C228F0 = 0x802019b0
export const RAM_func_802429C0_EA32C0 = 0x802429c0
export const RAM_IES_CloseCall = 0x8008c744
export const RAM_omo_09_StashVars = 0x80240720
export const RAM_b_area_kmr_part_2_goomba_king_defenseTable_80220910 = 0x80220910
export const RAM_tik_24_set_script_owner_npc_col_height = 0x802409e4
export const RAM_pause_badges_count_equipped = 0x802470b0
export const RAM_kzn_09_SuperBlock_ShowSelectPartnerMenu = 0x802401cc
export const RAM_SetPlayerPos = 0x802d0ef0
export const RAM_func_802A1628_795908 = 0x802a1628
export const RAM_dead_heap_malloc = 0x8002bfd4
export const RAM_fx_aura = 0x80070eb0
export const RAM_HES_Item_StopWatch_disabled = 0x80087788
export const RAM_aura_main = 0xe0076000
export const RAM_ED8E20_Pipe_GetEntryPos = 0x802400f0
export const RAM_gCurrentItemEntities = 0x801565a0
export const RAM_IES_Unused_0F5 = 0x8008cb84
export const RAM_b_area_mac_master2_nextTurn_8022CFFC = 0x8022cffc
export const RAM_b_area_isk_part_1_isk_04_D_80224D60 = 0x80218000
export const RAM_b_area_mac_master2_singleStrike2 = 0x8022be70
export const RAM_evt_skip_else = 0x802c8978
export const RAM_D_800981C8 = 0x800981c8
export const RAM_battle_area_sam2_actor_img_pal = 0x8021a298
export const RAM_IES_IcePower = 0x8008c324
export const RAM_dgb_15_80242C38 = 0x80242c38
export const RAM_CamPreset_C = 0x80280dac
export const RAM_b_area_mac_lee_nextTurn_80226880 = 0x80226880
export const RAM_D_E0020D94 = 0xe0020d94
export const RAM_b_area_kmr_part_3_paragoomba2_8021B6F0 = 0x8021fd30
export const RAM_D_8010CFC8 = 0x8010cfc8
export const RAM_flo_08_npcGroup_802447E8 = 0x802447e8
export const RAM_D_802A1998_00734F38 = 0x802a1998
export const RAM_b_area_mac_master3_statusTable_8022DA44 = 0x8022da44
export const RAM_D_090024A0_388850 = 0x090024a0
export const RAM_kmr_10_CheckItemFlags40 = 0x80240268
export const RAM_kkj_20_ToadHouse_PartnerResumeAbilityScript = 0x80240460
export const RAM_D_80077788 = 0x80077788
export const RAM_b_area_sam_duplighost_bombette_idleAnimations = 0x80220330
export const RAM_D_E0070D80 = 0xe0070d80
export const RAM_func_80240000_DDC830 = 0x80240000
export const RAM_D_80153380 = 0x80153380
export const RAM_D_E0084E70 = 0xe0084e70
export const RAM_GetOwnerEncounterTrigger = 0x80044600
export const RAM_HES_AimReticle = 0x8029369c
export const RAM_func_80282880 = 0x80282880
export const RAM_D_802E9B5C = 0x802e9b5c
export const RAM_D_09000A50_32D910 = 0x09000a50
export const RAM_kpa_01_func_802408B4_97BEE4 = 0x802409d4
export const RAM_IES_AutoSmash = 0x8008c0c4
export const RAM_b_area_nok_kent_c_koopa_8022AD84 = 0x8022ad84
export const RAM_D_E007AC08 = 0xe007ac08
export const RAM_mac_01_Quizmo_SetCamVfov = 0x80242a7c
export const RAM_kkj_12_UnkAtan2Func = 0x80240604
export const RAM_D_8014B8E8 = 0x8014b8e8
export const RAM_D_8024A630_DD7300 = 0x8024a630
export const RAM_ui_kooper_disabled_pal = 0x800fea80
export const RAM_au_sfx_update_mode_1 = 0x8004c884
export const RAM_obk_03_LetterDelivery_CalcLetterPos = 0x802406cc
export const RAM_nuContDataMutexBuf = 0x800ae6d4
export const RAM_func_8024114C_D6FAFC = 0x8024114c
export const RAM_D_802A1CD0 = 0x802a1cd0
export const RAM_flo_18_80242474 = 0x80242474
export const RAM_ui_watt_png = 0x800ff160
export const RAM_D_E0070D88 = 0xe0070d88
export const RAM_dgb_10_pad_EDC = 0x80240edc
export const RAM_b_area_kmr_part_2_red_goomba_2_idleAnimations_8021EF50 = 0x8021ef50
export const RAM_dro_02_8024C0B8 = 0x8024c0b8
export const RAM_flo_08_npcAI_80242EE0 = 0x80242ee0
export const RAM_D_802510C8_C0E948 = 0x802510c8
export const RAM_battle_item_stop_watch_FadeBackgroundToBlack = 0x802a123c
export const RAM_D_802A9AA2_42D9B2 = 0x802a9aa2
export const RAM_b_area_sam_duplighost_goombario_attack = 0x8021d864
export const RAM_func_802BDDD8_321928 = 0x802bddd8
export const RAM_remove_item_entity_by_reference = 0x80133930
export const RAM_arn_08_npcSettings_8024124C = 0x8024124c
export const RAM_SetBattleInputMask = 0x8026e050
export const RAM_mac_01_Quizmo_ShouldQuizmoLeave = 0x80242a4c
export const RAM_D_8025595C = 0x8025595c
export const RAM_D_80241E98_8D5C58 = 0x80241e98
export const RAM_b_area_sam_frost_piranha_handleEvent = 0x8022adfc
export const RAM_func_800F09EC = 0x800f09ec
export const RAM_EF2680_set_script_owner_npc_anim = 0x8024219c
export const RAM_jan_03_Quizmo_AudienceEffect = 0x8024fefc
export const RAM_flo_09_npcGroup_80243F5C = 0x80243f5c
export const RAM_func_802444E8_A2E728 = 0x802444e8
export const RAM_iwa_03_CleftAI_Ambush = 0x802406e0
export const RAM_flo_10_8024324C = 0x8024324c
export const RAM_vtx_C24EB0 = 0x80203f70
export const RAM_HES_Item_FPPlus = 0x800845d8
export const RAM_tik_03_Pipe_GetCameraYaw = 0x802401d0
export const RAM_HES_Item_BakingFlour = 0x800874b8
export const RAM_kzn_18_SetCamera0Flag1000 = 0x80240350
export const RAM_b_area_iwa_whacka_init_8021FDA0 = 0x8021fda0
export const RAM_kmr_02_UnkFunc41 = 0x80241e90
export const RAM_HES_MenuDoNothing = 0x802922a0
export const RAM_flo_23_func_80240000_CEE260 = 0x80240000
export const RAM_func_802A11A4_743F94 = 0x802a11a4
export const RAM_func_802406E0_C96610 = 0x802406e0
export const RAM_b_area_jan2_white_magikoopa_healOne = 0x8021c3d0
export const RAM_au_SEFCmd_0F = 0x8004d2b0
export const RAM_D_8024161C_9EF20C = 0x8024161c
export const RAM_flo_15_pad_12D8 = 0x802412d8
export const RAM_func_80240540_BCF130 = 0x80240540
export const RAM_kmr_02_ToadHouse_ShowStatusMenu = 0x80243090
export const RAM_battle_item_ultra_shroom_GiveRefundCleanup = 0x802a11d4
export const RAM_func_80241C34_97F704 = 0x80241c34
export const RAM_func_80240540_EFF040 = 0x80240540
export const RAM_gPauseShownDescMsg = 0x80270108
export const RAM_kkj_maps = 0x80091a70
export const RAM_battle_item_mystery_modelCommandList = 0x802a2260
export const RAM_padding = 0x80240948
export const RAM_dead_increment_status_menu_disabled = 0x800f1718
export const RAM_D_80251710_C0EF90 = 0x80251710
export const RAM_mac_01_PatrolAI_PostLoiter = 0x80245bcc
export const RAM_HES_Item_CoinSparkleC = 0x80080c7c
export const RAM_tik_04_Pipe_GetCameraYaw = 0x802404dc
export const RAM_get_transform_group = 0x8011b1c0
export const RAM_b_area_kmr_part_3_paragoomba1_takeTurn_8021CDFC = 0x8021cdfc
export const RAM_func_802195A0_5B2A50 = 0x802195a0
export const RAM_dgb_04_FlyingAI_Jump = 0x80240c54
export const RAM_b_area_kmr_part_1_formation_06 = 0x80219368
export const RAM_func_802BC274_E2EBA4 = 0x802bc274
export const RAM_D_800A3644 = 0x800a3644
export const RAM_HES_Item_MegaJump = 0x80084c98
export const RAM_snaking_static_render = 0xe00ae4fc
export const RAM_vtx_C25900 = 0x802049c0
export const RAM_tik_03_UnkPosFunc2 = 0x802405f0
export const RAM_b_area_dig_Add1Coin = 0x80218608
export const RAM_pad_after_move_table = 0x8008fed4
export const RAM_gPauseStatsIconIDs = 0x80270150
export const RAM_b_area_sbk_sbk_02_GetModelPos = 0x80218220
export const RAM_tik_05_GetItemName = 0x80240734
export const RAM_flo_24_updateTexturePan_802417A0 = 0x802417a0
export const RAM_kpa_50_ProjectileAI_Reflect = 0x80240a98
export const RAM_floating_rock_main = 0xe0098000
export const RAM_D_80151348 = 0x80151348
export const RAM_obk_07_varStash = 0x80241fb0
export const RAM_dup_b_area_tik2_FadeBackgroundToBlack = 0x8021876c
export const RAM_IES_Artifact = 0x8008b5e4
export const RAM_b_area_pra3_duplighost_bow_handleEvent = 0x8022176c
export const RAM_vtx_C28F70 = 0x80208030
export const RAM_b_area_hos_magikoopa_shapeSpell = 0x8022028c
export const RAM_func_800E06D8 = 0x800e06d8
export const RAM_arn_10_npcGroup_802407D8 = 0x802407d8
export const RAM_D_E02006CC = 0xe02006cc
export const RAM_kmr_12_ExitWest = 0x802400c0
export const RAM_func_8024266C_8547DC = 0x8024266c
export const RAM_filemenu_update_show_title = 0x80243550
export const RAM_nok_12_PatrolNoAttackAI_Main = 0x802418d8
export const RAM_D_80245720_C4C300 = 0x80245720
export const RAM_D_E008E890 = 0xe008e890
export const RAM_b_area_sam_white_clubba_idle = 0x80229c6c
export const RAM_trd_07_UpdateTexturePanSmooth = 0x80240000
export const RAM_ui_star_spirit_png = 0x800fb7e0
export const RAM_omo_07_ShyGuyWanderAI_14 = 0x80240000
export const RAM_evt_handle_get_1_float = 0x802c5eb4
export const RAM_D_E0088D08 = 0xe0088d08
export const RAM_D_80234870_6BBA70 = 0x80234870
export const RAM_b_area_arn_tubba_blubba_80225E08 = 0x80225e08
export const RAM_func_802422A0_A2C4E0 = 0x802422a0
export const RAM_footprint_init = 0xe00182ac
export const RAM_b_area_sam_gulpit_attack_rock = 0x8022898c
export const RAM_b_area_kmr_part_3_mage_jr_troopa_8022AC40 = 0x8022ac40
export const RAM_flo_25_FlyingAI_WanderInit = 0x80240040
export const RAM_omo_11_SuperBlock_LoadCurrentPartnerName = 0x80240bd8
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_802256C4 = 0x802256c4
export const RAM_load_battle_hit_asset = 0x8005b1a0
export const RAM_b_area_sam2_sam_01_afterBattle = 0x802221b8
export const RAM_copy_world_hud_element_ref_to_battle = 0x801452b4
export const RAM_func_80218B78_4E32B8 = 0x80218b78
export const RAM_phys_check_interactable_collision = 0x800e5174
export const RAM_arn_05_idle_8024459C = 0x8024459c
export const RAM_D_E0076EC0 = 0xe0076ec0
export const RAM_b_area_kmr_part_1_spiked_goomba_ArcsinDeg = 0x80218280
export const RAM_kkj_23_SetPlayerSpriteSet2 = 0x80240000
export const RAM_pra_28_CheckItemFlags40 = 0x802411b8
export const RAM_D_8010CD04 = 0x8010cd04
export const RAM_b_area_mim_forest_fuzzy_partsTable_80218478 = 0x80218478
export const RAM_D_802409F0_7E7D90 = 0x802409f0
export const RAM_hos_05_UpdateTexturePanStepped = 0x8024041c
export const RAM_D_8014C008 = 0x8014c008
export const RAM_D_E004C67C = 0xe004c67c
export const RAM_D_80245348_CD0658 = 0x80245348
export const RAM_HES_Item_Unused_0EF = 0x80085d78
export const RAM_b_area_kgr_fuzzipede_handleEvent_802197FC = 0x802197fc
export const RAM_func_80238000_714CF0 = 0x80238000
export const RAM_battle_star_peach_dash_UnkStarFunc = 0x802a1494
export const RAM_b_area_arn_hypergoomba_partsTable_80218828 = 0x80218828
export const RAM_cold_breath_update = 0xe00de124
export const RAM_tik_07_SuperBlock_UpgradeDescMessages = 0x80243a08
export const RAM_func_802A10A4_756824 = 0x802a10a4
export const RAM_D_E001ED58 = 0xe001ed58
export const RAM_sam_11_SetEntityFlags100000 = 0x80240280
export const RAM_mim_05_MeleeHitbox_Main = 0x8024042c
export const RAM_dgb_01_D_80251FA8_C0F828 = 0x80251fa8
export const RAM_trd_07_FlyingAI_JumpVels = 0x80242eac
export const RAM_vtx_C294A0 = 0x80208560
export const RAM_D_802476DC_85984C = 0x802476dc
export const RAM_arn_04_CleftAI_Ambush = 0x80242728
export const RAM_battle_item_repel_gel_EatItem = 0x802a176c
export const RAM_HES_Item_SmashCharge0_disabled = 0x80084308
export const RAM_CamPreset_K = 0x80280e1c
export const RAM_flo_08_SuperBlock_ClearOverride40 = 0x8024001c
export const RAM_func_80241358_BCFF48 = 0x80241358
export const RAM_HES_Item_HoneySuper = 0x80082f58
export const RAM_D_80246598 = 0x80246598
export const RAM_func_8004DFD4 = 0x8004dfd4
export const RAM_fx_flashing_box_shockwave = 0x80071450
export const RAM_dro_01_init_80249168 = 0x80249168
export const RAM_jan_14_PullVine_apply_shear_mtx = 0x80240170
export const RAM_kmr_09_FlyingAI_WanderInit = 0x80240000
export const RAM_arn_10_exitSingleDoor_80240100 = 0x80240100
export const RAM_flo_10_pad_3218 = 0x80243218
export const RAM_func_802AE000 = 0x802ae000
export const RAM_D_80243D90_A72170 = 0x80243d90
export const RAM_save_tattle_flags = 0x80253d10
export const RAM_D_80234978_6BBB78 = 0x80234978
export const RAM_PlayerPowerBounceEnemy = 0x802744a0
export const RAM_func_802A10E4_781A04 = 0x802a10e4
export const RAM_b_area_pra2_crystal_king_AttackWithCrystalBit = 0x8021f3a4
export const RAM_func_80254610 = 0x80254610
export const RAM_gNumScripts = 0x802da488
export const RAM_dro_02_Quizmo_ShowEntities = 0x8024042c
export const RAM_HES_Dash = 0x802427a8
export const RAM_dro_01_80247D20 = 0x80247d20
export const RAM_func_802A9708 = 0x802a9708
export const RAM_gPausePartnersCurrentPartnerIdx = 0x802706a0
export const RAM_D_80257D00_8C7D70 = 0x80257d00
export const RAM_arn_09_npcSettings_802405D0 = 0x802405d0
export const RAM_battle_star_peach_dash_802A1948 = 0x802a1948
export const RAM_gBattleState2 = 0x800dc4dc
export const RAM_vtx_C229F0 = 0x80201ab0
export const RAM_func_802CA988 = 0x802ca988
export const RAM_kzn_03_LetterDelivery_RestoreNpcAnim = 0x80242cc8
export const RAM_b_area_isk_part_1_isk_05_pad = 0x80224d68
export const RAM_D_80098134 = 0x80098134
export const RAM_SetTexPanner = 0x802c9000
export const RAM_func_80242A68_E07DC8 = 0x80242a68
export const RAM_D_80248F60_A83460 = 0x80248f60
export const RAM_pra_06_reflection_setup_wall = 0x80240158
export const RAM_EDEA00_FlyingAI_Wander = 0x80240374
export const RAM_remove_effect = 0x8005a450
export const RAM_D_09002410 = 0x09002410
export const RAM_HES_BButton = 0x8029231c
export const RAM_b_area_omo_spy_guy_statusTable_802274B8 = 0x802274b8
export const RAM_sbk_64_SpawnSunEffect = 0x80240000
export const RAM_hos_01_UnkEffect0FFunc4 = 0x80240534
export const RAM_b_area_kmr_part_3_mage_jr_troopa_statusTable_8022A634 = 0x8022a634
export const RAM_state_init_title_screen = 0x80036df0
export const RAM_status_menu_stop_blinking_fp = 0x800e99d0
export const RAM_D_80244758_AA7478 = 0x80244758
export const RAM_b_area_mac_lee_nextTurn_80225884 = 0x80225884
export const RAM_ui_status_star_point_shine_png = 0x800fbe40
export const RAM_kpa_50_ProjectileAI_Main = 0x8024055c
export const RAM_battle_move_hammer_charge_0_IsRightOnEquipped = 0x802a1050
export const RAM_shim_load_effect = 0xe0200540
export const RAM_b_area_iwa_monty_mole_idle_8021A814 = 0x8021a814
export const RAM_sam_01_Quizmo_ShouldAppear = 0x8024083c
export const RAM_b_area_isk_part_2_tutankoopa_idleAnimations_8021B9F8 = 0x8021b9f8
export const RAM_b_area_arn_tubbas_heart_init_80223DA0 = 0x80223da0
export const RAM_nok_15_TransformFoliage = 0x802425b0
export const RAM_D_800760C0 = 0x800760c0
export const RAM_D_802AAA08 = 0x802aaa08
export const RAM_HES_Item_HustleDrink = 0x800876f8
export const RAM_D_80240850_B77000 = 0x80240850
export const RAM_b_area_jan_jungle_fuzzy_UnkBattleFunc2 = 0x802181a0
export const RAM_flo_14_80244738 = 0x80244738
export const RAM_omo_04_FlyingAI_Loiter = 0x80240c38
export const RAM_tik_17_SuperBlock_GatherEnergyFX = 0x80240574
export const RAM_jan_03_Quizmo_varStash = 0x80247764
export const RAM_b_area_kmr_part_3_formation_06 = 0x8021a274
export const RAM_EEDF50_FlyingAI_Main = 0x8024215c
export const RAM_D_8026FF98 = 0x8026ff98
export const RAM_D_E004AE98 = 0xe004ae98
export const RAM_gCurrentPrintContext = 0x802db260
export const RAM_trd_04_FlyingAI_Jump = 0x80242a6c
export const RAM_tik_12_SuperBlock_SwitchToPartner = 0x80241038
export const RAM_partner_init_get_out = 0x800eecc4
export const RAM_evt_handle_else = 0x802c4f40
export const RAM_filemenu_menus = 0x80249b84
export const RAM_D_80241748_9EF338 = 0x80241748
export const RAM_b_area_kmr_part_1_spiked_goomba_statusTable_8021E3BC = 0x8021afdc
export const RAM_func_80240678_9D7698 = 0x80240678
export const RAM_b_area_kpa_ShrinkActor = 0x80218200
export const RAM_nok_12_foliage_setup_shear_mtx = 0x80240330
export const RAM_D_00E3E260 = 0x00e3e260
export const RAM_D_80244EE8_CF7AB8 = 0x80244ee8
export const RAM_func_80240F20_D7DA70 = 0x80240f20
export const RAM_D_80242430 = 0x80242430
export const RAM_arn_12_npcGroup_802409CC = 0x802409cc
export const RAM_b_area_omo2_toy_tank_takeTurn = 0x8022d228
export const RAM_gSoundManager = 0x8009a640
export const RAM_HES_Item_Refund_disabled = 0x80084a88
export const RAM_replace_window_update = 0x80147e48
export const RAM_dgb_04_npcAI_802435E4 = 0x802435e4
export const RAM_D_80247878_DA4D48 = 0x80247878
export const RAM_osr_01_LetterDelivery_RestoreNpcAnim = 0x80240a8c
export const RAM_dro_02_D_8024F080 = 0x8024f080
export const RAM_gSunnyFlowerFieldsBg = 0x8014f128
export const RAM_IES_Multibounce = 0x8008bf04
export const RAM_bgm_reset_max_volume = 0x8014ac70
export const RAM_flo_25_80242C08 = 0x80242c08
export const RAM_func_80050900 = 0x80050900
export const RAM_get_model_list_index_from_tree_index = 0x8011b010
export const RAM_HES_Item_FeelingFine = 0x80084398
export const RAM_dgb_09_npcAI_802441E8 = 0x802441e8
export const RAM_flo_16_FlyingAI_ChaseInit = 0x80241b10
export const RAM_func_E0080448 = 0xe0080448
export const RAM_D_09000200_392640 = 0x09000200
export const RAM_b_area_sam2_sam_02_UnkModelStuffScript2 = 0x8022224c
export const RAM_D_80242520_A4EF40 = 0x80242520
export const RAM_action_command_smack_draw_hud_elements = 0x802a98b0
export const RAM_D_090009E8_3642A8 = 0x090009e8
export const RAM_D_80254B94 = 0x80254b94
export const RAM_kmr_20_ToadHouse_ShowStatusMenu = 0x802412c0
export const RAM_draw_merlee_message_string = 0x80045e4c
export const RAM_tik_23_Pipe_GetEntryPos = 0x802400f0
export const RAM_b_area_hos_goombario_tutor_idleAnimations_80219010 = 0x80219010
export const RAM_arn_03_idle_80243D28 = 0x80243d28
export const RAM_b_area_jan2_formationTable = 0x8021f6c8
export const RAM_gMatrixListPos = 0x800741f0
export const RAM_dro_02_moveWalls_8024454C = 0x8024454c
export const RAM_dgb_09_ClubbaNappingAI_ReturnHomeInit = 0x80240e54
export const RAM_D_80247410_C7E0F0 = 0x80247410
export const RAM_mac_05_unkAngleFunc002 = 0x80243800
export const RAM_ui_coin_sparkle_2_pal = 0x800fbd20
export const RAM_D_09000C20 = 0x09000c20
export const RAM_omo_02_StationaryAI_Idle = 0x80240f90
export const RAM_b_area_sam_duplighost_kooper = 0x8021e7fc
export const RAM_D_800B4311 = 0x800b4311
export const RAM_action_command_bomb_draw_hud_elements = 0x802a96ec
export const RAM_damage_stars_update = 0xe0030730
export const RAM_battle_move_multibounce_D_802A1320 = 0x802a1320
export const RAM_osFlashReInit = 0x8006eea0
export const RAM_dgb_01_D_802532A0_C10B20 = 0x802532a0
export const RAM_func_802401E0_8FF850 = 0x802401e0
export const RAM_b_area_omo2_5_shy_squad_dup_run_away = 0x802343a8
export const RAM_D_802440FB = 0x802440fb
export const RAM_alLink = 0x8004b3ec
export const RAM_fx_moving_cloud = 0x80072410
export const RAM_D_802BFEEC = 0x802bfeec
export const RAM_dgb_16_MeleeHitbox_Main = 0x802403bc
export const RAM_func_8025D4C8 = 0x8025d4c8
export const RAM_pra_35_CheckItemFlags40 = 0x80242bb8
export const RAM_omo_02_StationaryAI_AlertInit = 0x802410b0
export const RAM_nok_01_TransformFoliage = 0x802429a0
export const RAM_dro_01_8024CF7C = 0x8024cf7c
export const RAM_calc_partner_damage_enemy = 0x8027e328
export const RAM_dup2_kmr_20_Pipe_SetAnimFlag = 0x80241d50
export const RAM_b_area_isk_part_2_tutankoopa = 0x8021bbf4
export const RAM_vtx_C24FB0 = 0x80204070
export const RAM_D_8010D68E = 0x8010d68e
export const RAM_osGetTime = 0x800662a0
export const RAM_fio_deserialize_state = 0x8002b490
export const RAM_GetPartnerPos = 0x802cf438
export const RAM_SetCamProperties = 0x802cc3ec
export const RAM_HES_WhirlwindBubble = 0x802aa818
export const RAM_func_802403DC_C5AB1C = 0x802403dc
export const RAM_func_80240A70_9B2200 = 0x80240a70
export const RAM_partner_put_away = 0x800ee9b8
export const RAM_jan_03_ToadHouse_PartnerSuspendAbilityScript = 0x80241624
export const RAM_func_802BD414_31E184 = 0x802bd414
export const RAM_HES_Item_ShroomSteak_disabled = 0x80082b08
export const RAM_DoJumpBack = 0x8029bd70
export const RAM_pra_20_reflection_render_floor_fancy = 0x80240870
export const RAM_func_802A9000_427A70 = 0x802a9000
export const RAM_b_area_kmr_part_3_nok_01 = 0x80233640
export const RAM_EED1E0_SuperBlock_UpgradeDescMessages = 0x80243778
export const RAM_kmr_11_StationaryAI_Alert = 0x802405a4
export const RAM_b_area_sam_duplighost_goombario_statusTable = 0x8021cb4c
export const RAM_func_80240000_EB8E50 = 0x80240000
export const RAM_kmr_20_ToadHouse_PutPartnerAway = 0x80241530
export const RAM_EF2680_LakituAI_Loiter = 0x80241a40
export const RAM_battle_star_peach_focus_UnkBackgroundFunc2 = 0x802a137c
export const RAM_D_802559C8_8006D8 = 0x802559c8
export const RAM_EB7540_UnsetCamera0MoveFlag1 = 0x80240504
export const RAM_D_80246378_915A28 = 0x80246378
export const RAM_D_80263428_C20CA8 = 0x80263428
export const RAM_fx_water_block = 0x80071a50
export const RAM_state_init_world = 0x80035d30
export const RAM_dgb_01_entryList = 0x802433f0
export const RAM_D_800779B0 = 0x800779b0
export const RAM_shim_clamp_angle = 0xe02004f0
export const RAM_pra_32_StarSpiritEffectFunc1 = 0x80240020
export const RAM_func_80057B8C = 0x80057b8c
export const RAM_D_800A095E = 0x800a095e
export const RAM_b_area_trd_part_1_koopa_troopa = 0x8021ca68
export const RAM_entity_SaveBlock_show_result_message = 0x802e12f8
export const RAM_tik_15_varStash = 0x80242758
export const RAM_D_80098268 = 0x80098268
export const RAM_flo_07_80241C14 = 0x80241c14
export const RAM_flo_17_npcGroup_80245038 = 0x80245038
export const RAM_D_80231408_63CFA8 = 0x80231408
export const RAM_func_8021BC60_6A2E60 = 0x8021bc60
export const RAM_func_80200080 = 0x80200080
export const RAM_dead_update_lerp = 0x8002b6a4
export const RAM_jan_03_Quizmo_SetStageLightsDelay = 0x802425d8
export const RAM_kzn_03_PiranhaPlantAI_01 = 0x80241534
export const RAM_D_801A6000 = 0x801a6000
export const RAM_flo_14_80243B1C = 0x80243b1c
export const RAM_big_smoke_puff_main = 0xe0002000
export const RAM_kmr_03_80241470 = 0x80241470
export const RAM_mdl_renderModelFogStart = 0x8014b758
export const RAM_D_8029FBAC = 0x8029fbac
export const RAM_create_part_shadow_by_ref = 0x802673e4
export const RAM_D_80242CF0_C52200 = 0x80242cf0
export const RAM_sbk_00_settings = 0x802400a0
export const RAM_IES_CrazyHeart = 0x8008c764
export const RAM_IES_PayOff = 0x8008c364
export const RAM_guLookAtReflectF = 0x80063320
export const RAM_nuGfxUcode = 0x8009a610
export const RAM_D_8010C9A4 = 0x8010c9a4
export const RAM_func_8025D620 = 0x8025d620
export const RAM_kpa_04_UnkFunc11 = 0x80240000
export const RAM_b_area_omo2_2_stilt_guy_attack = 0x80232c94
export const RAM_arn_13_npcAI_802404AC = 0x802404ac
export const RAM_b_area_kmr_part_2_kmr_05_UnkAngleFunc2 = 0x80219230
export const RAM_func_802A9278 = 0x802a9278
export const RAM_flo_08_TransformFoliage = 0x80241c70
export const RAM_nok_15_FlyingAI_JumpInit = 0x80241690
export const RAM_b_area_sam_sam_02_Set80071270_0_16 = 0x8021a0c8
export const RAM_HES_Item_QuakeHammer_disabled = 0x80083d08
export const RAM_mim_08_MeleeHitbox_32 = 0x80241674
export const RAM_D_802479A8_DF6848 = 0x802479a8
export const RAM_gBattleDmaDest = 0x80284000
export const RAM_ui_input_analog_stick_right_png = 0x800f93a0
export const RAM_SetActorYaw = 0x8026b358
export const RAM_b_area_mac_lee_formation_bow = 0x80225958
export const RAM_nok_01_Quizmo_CreateWorker = 0x80241dcc
export const RAM_D_801507B0 = 0x801507b0
export const RAM_HES_Item_ShrinkSmash = 0x800854d8
export const RAM_b_area_dig_IsGameStatusUnkAA_1 = 0x802185e0
export const RAM_nok_02_PatrolAI_LoiterInit = 0x80240e80
export const RAM_D_802BFE60_320BD0 = 0x802bfe60
export const RAM_mac_02_Pipe_SetAnimFlag = 0x80240000
export const RAM_func_802407DC_B7103C = 0x802407dc
export const RAM_trd_07_UnkDistFunc = 0x8024038c
export const RAM_aura_appendGfx = 0xe007685c
export const RAM_IES_InsecticideHerb = 0x8008b384
export const RAM_D_80242FF0_AA1D30 = 0x80242ff0
export const RAM_b_area_jan_spear_guy_becomeShyGuy = 0x8021a8f8
export const RAM_pause_map_cleanup = 0x8024ee2c
export const RAM_kkj_12_UnkGooberFunc = 0x80240730
export const RAM_func_800322DC = 0x800322dc
export const RAM_gPauseMenuTextScrollInterpEasingLUT = 0x8024f0ac
export const RAM_D_80150088 = 0x80150088
export const RAM_D_80153370 = 0x80153370
export const RAM_EEDF50_FlyingAI_Chase = 0x80241c44
export const RAM_EA0C10_TackleAI_Main = 0x802420fc
export const RAM_nuContRmbCheck = 0x8005fef0
export const RAM_flo_18_init_80245C04 = 0x80245c04
export const RAM_D_8029FB54 = 0x8029fb54
export const RAM_func_802417AC_C8F35C = 0x802417ac
export const RAM_b_area_sam2_gray_magikoopa_canBeKnockedOff = 0x8021f94c
export const RAM_battle_move_mega_quake_IsRightOnEquipped = 0x802a1050
export const RAM_ui_battle_cmd_aim_shimmer_4_png = 0x8028ed00
export const RAM_func_80242634_8547A4 = 0x80242634
export const RAM_b_area_mac_master2_partsTable_8022B790 = 0x8022b790
export const RAM_D_80248650 = 0x80248650
export const RAM_D_800741FC = 0x800741fc
export const RAM_arn_05_exitWalk_80241430 = 0x80241430
export const RAM_dup2_kmr_20_TransformFoliage = 0x802410d0
export const RAM_func_80219E40_63F020 = 0x80219e40
export const RAM_pra_05_reflection_render_floor = 0x8024068c
export const RAM_func_802181F4_52B894 = 0x802181f4
export const RAM_D_80153378 = 0x80153378
export const RAM_func_80241B00_B366D0 = 0x80241b00
export const RAM_b_area_trd_part_1_trd_05b_80225578 = 0x80225578
export const RAM_gGameStatusPtr = 0x8007419c
export const RAM_b_area_sam_duplighost_watt_init = 0x80223a68
export const RAM_sam_02_Pipe_GetEntryPos = 0x802400f0
export const RAM_hos_03_FlyingAI_Main = 0x80241fbc
export const RAM_func_80055D8C = 0x80055d8c
export const RAM_D_802A37F8 = 0x802a37f8
export const RAM_HES_Item_Kaiden_disabled = 0x800858c8
export const RAM_kmr_11_StationaryAI_Idle = 0x80240424
export const RAM_gOverrideFlags = 0x8009a650
export const RAM_kpa_03_set_script_owner_npc_anim = 0x80240160
export const RAM_D_802A23E8 = 0x802a23e8
export const RAM_b_area_hos_ember_takeTurn_8021D284 = 0x8021d284
export const RAM_battle_item_ultra_shroom_UseItem = 0x802a1644
export const RAM_mac_00_Quizmo_UpdatePartnerPosition = 0x80241540
export const RAM_hos_00_SetCamera0Flag1000 = 0x80240b20
export const RAM_D_802483C4 = 0x802483c4
export const RAM_D_802A37F0 = 0x802a37f0
export const RAM_b_area_arn_tubba_blubba_takeTurn_802264AC = 0x802264ac
export const RAM_flo_18_80242174 = 0x80242174
export const RAM_pra_36_reflection_render_floor_fancy = 0x802408a0
export const RAM_D_09001E50_352070 = 0x09001e50
export const RAM_dup_mim_07_SetForeverForestFog = 0x80240000
export const RAM_mim_04_HoppingAI_LosePlayer = 0x80240dac
export const RAM_flo_21_defeat_802434D8 = 0x802434d8
export const RAM_dgb_09_npcGroup_80244D6C = 0x80244d6c
export const RAM_D_80159E7D = 0x80159e7d
export const RAM_EA0900_UpdateTexturePanSmooth = 0x80240000
export const RAM_ui_pow_cost_png = 0x800fc360
export const RAM_jan_16_foliage_setup_shear_mtx = 0x80240300
export const RAM_ExitSingleDoor = 0x80285dac
export const RAM_D_80074274 = 0x80074274
export const RAM_ui_status_text_slash_pal = 0x800fff60
export const RAM_pra_10_ReflectFloor = 0x8024049c
export const RAM_arn_03_npcGroup_80243F80 = 0x80243f80
export const RAM_BattleCam_IsFrozen = 0x80280ce0
export const RAM_flo_12_entryList = 0x802406f0
export const RAM_dro_01_openDoor_8024BD9C = 0x8024bd9c
export const RAM_func_80242B04_8EE924 = 0x80242b04
export const RAM_func_80242AD4_825D94 = 0x80242ad4
export const RAM_arn_07_defeat_80244FB8 = 0x80244fb8
export const RAM_D_800B430E = 0x800b430e
export const RAM_tik_19_SuperBlock_get_partner_rank = 0x802406c4
export const RAM_SwitchToLink = 0x802eb3a0
export const RAM_spr_component_update_finish = 0x802dd634
export const RAM_D_80246270_9AE660 = 0x80246270
export const RAM_b_area_sbk_pokey_8021C07C = 0x8021c07c
export const RAM_D_80243374 = 0x80243374
export const RAM_jan_11_DarkRoomUpdate = 0x80240000
export const RAM_fx_quizmo_audience = 0x80071810
export const RAM_kmr_02_Quizmo_SetCamVfov = 0x80240efc
export const RAM_func_80055B28 = 0x80055b28
export const RAM_HES_Item_Unused_02F_disabled = 0x80083948
export const RAM_dgb_08_pad_3D88 = 0x80243d88
export const RAM_D_8024D944_80E1C4 = 0x8024d944
export const RAM_add_part_decoration = 0x80267114
export const RAM_HES_Item_MegaQuake = 0x80083df8
export const RAM_D_802463B0_915A60 = 0x802463b0
export const RAM_D_8009A65C = 0x8009a65c
export const RAM_dro_01_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_flo_22_idle_8024054C = 0x8024054c
export const RAM_dgb_13_8024056C = 0x8024056c
export const RAM_D_8026FF90 = 0x8026ff90
export const RAM_is_partner_ability_active = 0x800ea0b8
export const RAM_dgb_18_npcGroupList_802436B4 = 0x802436b4
export const RAM_sam_11_Quizmo_DestroyEffects = 0x80241158
export const RAM_func_802405C0_A27490 = 0x802405c0
export const RAM_D_802452F8_BB2C38 = 0x802452f8
export const RAM_b_area_omo2_2_stilt_guy_parts = 0x802312f8
export const RAM_kmr_10_GetItemName = 0x802400b4
export const RAM_battle_item_unknown_item_UseItem = 0x802a1644
export const RAM_omo_06_UpdateTexturePanStepped = 0x80240680
export const RAM_func_80243740_803FC0 = 0x80243740
export const RAM_wEntityBlueprint = 0x80151668
export const RAM_func_80056FA4 = 0x80056fa4
export const RAM_dgb_15_MeleeHitbox_32 = 0x802400dc
export const RAM_b_area_pra3_duplighost_bombette = 0x8021f4d8
export const RAM_HES_MuskularDisabled = 0x80107ac4
export const RAM_dgb_01_D_80252554_C0FDD4 = 0x80252554
export const RAM_b_area_kmr_part_2_red_goomba_idleAnimations_8021B7C4 = 0x8021b7c4
export const RAM_func_80241CEC_8448BC = 0x80241cec
export const RAM_func_8026880C = 0x8026880c
export const RAM_func_8024037C_A54CCC = 0x8024037c
export const RAM_hos_02_FlyingAI_WanderInit = 0x80240ff0
export const RAM_action_update_peach_step_down_land = 0x802b644c
export const RAM_ui_battle_menu_strategies_pal = 0x80289cc0
export const RAM_dead_gPlayerActionState = 0x8011762c
export const RAM_jan_06_UpdateTexturePanStepped = 0x80240124
export const RAM_isk_08_func_80240814_97BE44 = 0x802407d4
export const RAM_vtx_C21240 = 0x80200300
export const RAM_btl_state_draw_22 = 0x802ab338
export const RAM_EA0C10_PiranhaPlantAI_13 = 0x80241994
export const RAM_omo_17_ProjectileAI_Main = 0x80242a08
export const RAM_b_area_kzn_red_magikoopa_8022C2B4 = 0x80220ff4
export const RAM_b_area_omo2_3_shy_stack = 0x802312e0
export const RAM_b_area_kzn2_StartRumbleWithParams = 0x80218000
export const RAM_get_model_from_list_index = 0x8011ad30
export const RAM_kmr_20_Pipe_GetCurrentFloor = 0x80240050
export const RAM_D_80240E04_946CD4 = 0x80240e04
export const RAM_D_802449D0_88D5A0 = 0x802449d0
export const RAM_D_802AD6D4 = 0x802ad6d4
export const RAM_D_80240EF0_AF8840 = 0x80240ef0
export const RAM_dup_b_area_tik3_UnkBattleFunc1 = 0x802180d0
export const RAM_dgb_05_ClubbaNappingAI_Sleep = 0x80240704
export const RAM_D_8024C5E0 = 0x8024c5e0
export const RAM_func_80240904_A16B24 = 0x80240904
export const RAM_dro_01_func_80243350_95E550 = 0x80243350
export const RAM_kmr_02_ToadHouse_PartnerSuspendAbilityScript = 0x802431e4
export const RAM_b_area_kmr_part_1_goomba_defenseTable_80219750 = 0x80219610
export const RAM_b_area_iwa_iwa_01_beforeBattle_80220860 = 0x80220860
export const RAM_ui_pip_png = 0x800fbc00
export const RAM_pra_18_SetPartnerFlags20000 = 0x80240eec
export const RAM_func_80240664_CF81D4 = 0x80240664
export const RAM_HES_Stopped = 0x801065b4
export const RAM_D_090023F8 = 0x090023f8
export const RAM_D_E00E6890 = 0xe00e6890
export const RAM_b_area_omo2_1_shy_squad_onHit = 0x80233f38
export const RAM_tmp_task = 0x800af8c0
export const RAM_GetDist2D = 0x802d5770
export const RAM_D_09001468 = 0x09001468
export const RAM_D_80243DD8_EAC8B8 = 0x80243dd8
export const RAM_b_area_hos_magikoopa_8021D890 = 0x8021d890
export const RAM_nok_14_UnsetCamera0Flag1000 = 0x8024019c
export const RAM_D_E00269F8 = 0xe00269f8
export const RAM_make_item_entity = 0x801313f4
export const RAM_kzn_02_FlyingAI_WanderInit = 0x802404e0
export const RAM_b_area_kmr_part_3_egg_jr_troopa_802251CC = 0x802251cc
export const RAM_kmr_03_TransformFoliage = 0x80240480
export const RAM_D_802B62E0 = 0x802b62e0
export const RAM_mim_08_SetForeverForestFog = 0x80240000
export const RAM_arn_13_exitSingleDoor_80240100 = 0x80240100
export const RAM_dgb_15_PatrolAI_LosePlayer = 0x8024133c
export const RAM_D_800A0F54 = 0x800a0f54
export const RAM_b_area_sbk_pokey_idleAnimations_802184B0 = 0x802184b0
export const RAM_b_area_omo_anti_guy_init_80221A60 = 0x80221a60
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_idleAnimations_8022E5E0 = 0x8022e5e0
export const RAM_D_802347A0_6BB9A0 = 0x802347a0
export const RAM_sbk_25_SpawnSunEffect = 0x80240000
export const RAM_b_area_pra3_duplighost_GetPartnerAndLevel = 0x802194b8
export const RAM_func_801413F8 = 0x801413f8
export const RAM_D_802431F0_C55730 = 0x802431f0
export const RAM_BattleCam_InitialPosX = 0x8029f2a8
export const RAM_gCurrentTransformGroups = 0x801512e0
export const RAM_D_802313E8_63CF88 = 0x802313e8
export const RAM_func_80242144_8EDF64 = 0x80242144
export const RAM_b_area_pra3_white_clubba_parts = 0x8022b244
export const RAM_battle_item_strange_cake_D_802A27F8 = 0x802a27f8
export const RAM_kmr_03_npcSettings_80240950 = 0x80240950
export const RAM_evt_handle_OR = 0x802c632c
export const RAM_dup6_b_area_kpa2_ItemEntityJumpToPos = 0x8021b0fc
export const RAM_D_80150080 = 0x80150080
export const RAM_func_802BDB84 = 0x802bdb84
export const RAM_nok_02_Pipe_GetCameraYaw = 0x802401d0
export const RAM_kkj_00_PatrolAI_ChaseInit = 0x802408cc
export const RAM_pra_10_reflection_setup_floor = 0x80240500
export const RAM_sbk_00_npcGroup_80240388 = 0x80240388
export const RAM_D_E0024CF0 = 0xe0024cf0
export const RAM_btl_check_enemies_defeated = 0x80263434
export const RAM_get_lava_reset_pos = 0x802c9fd4
export const RAM_btl_popup_messages_update = 0x8024ee48
export const RAM_D_802474B0_B65760 = 0x802474b0
export const RAM_D_09000C28 = 0x09000c28
export const RAM_mac_00_Quizmo_ShouldQuizmoLeave = 0x8024116c
export const RAM_PopSong = 0x802d5fd8
export const RAM_b_area_arn_vector3D_802280E8 = 0x802280e8
export const RAM_pra_38_reflection_render_wall = 0x80240320
export const RAM_b_area_omo_sky_guy_8022BF14 = 0x8022bf14
export const RAM_D_80244628_A06E68 = 0x80244628
export const RAM_handle_CPU = 0x8006b038
export const RAM_flo_09_FlyingAI_Wander = 0x802404d0
export const RAM_GetCommandAutoSuccess = 0x80269370
export const RAM_b_area_arn_hypergoomba = 0x8021884c
export const RAM_battle_star_star_beam_UnkStarFunc2 = 0x802a10ac
export const RAM_flo_25_pad_3C8C = 0x80243c8c
export const RAM_D_E00C6CD8 = 0xe00c6cd8
export const RAM_flo_17_FlyingAI_LosePlayer = 0x80240ec8
export const RAM_D_801507B8 = 0x801507b8
export const RAM_trd_04_FlyingAI_Main = 0x80243124
export const RAM_D_80252744_8648B4 = 0x80252744
export const RAM_sbk_56_SuperBlock_GetPartnerRank = 0x802400e8
export const RAM_sam_11_Quizmo_Worker = 0x80251450
export const RAM_dro_02_Quizmo_NPC_OnRender = 0x802406cc
export const RAM_func_80268770 = 0x80268770
export const RAM_func_80238B3C_70462C = 0x80238b3c
export const RAM_D_8009A6A8 = 0x8009a6a8
export const RAM_mac_04_Quizmo_CreateWorker = 0x802414c4
export const RAM_tik_19_Pipe_GetCameraYaw = 0x802401d0
export const RAM_dgb_01_D_802522EC_C0FB6C = 0x802522ec
export const RAM_flo_00_exitWalk_80241DD8 = 0x80241dd8
export const RAM_D_80243DA4 = 0x80243da4
export const RAM_D_802462E0_B4AA50 = 0x802462e0
export const RAM_dgb_03_pad_3738 = 0x80243738
export const RAM_iwa_04_CleftAI_Ambush = 0x80240420
export const RAM_D_80283690 = 0x80283690
export const RAM_end_00_CharAnim_HoldClearFold = 0x80240fec
export const RAM_battle_item_hustle_drink_UseItem = 0x802a15e4
export const RAM_pra_36_ReflectPartner = 0x80240d6c
export const RAM_D_802AD60E = 0x802ad60e
export const RAM_mgm_02_work_draw_score = 0x80240430
export const RAM_func_802BC570_E30EC0 = 0x802bc570
export const RAM_CircularShadowA = 0x802e98bc
export const RAM_wPartnerHudScripts = 0x800f7f00
export const RAM_func_80241310_D6FCC0 = 0x80241310
export const RAM_nuAuMgrThread = 0x800a0f58
export const RAM_b_area_dgb_formation_05 = 0x8021b370
export const RAM_BattleMerleeFadeStageToBlack = 0x80261530
export const RAM_CamPresetUpdate_B = 0x80248dd0
export const RAM_dgb_01_D_80252A50_C102D0 = 0x80252a50
export const RAM_omo_06_CompareFloats = 0x802400e4
export const RAM_iwa_01_MontyMoleAI_DrawRock = 0x80241a4c
export const RAM_pause_world_map_png = 0x802510b0
export const RAM_draw_number = 0x8012769c
export const RAM_HES_DescMsgPrev = 0x802427d4
export const RAM_flo_13_func_802434D4_CC6CE4 = 0x802434d4
export const RAM_init_virtual_entity_list = 0x802d4560
export const RAM_func_802A9590 = 0x802a9590
export const RAM_flo_00_npcAISettings_8024286C = 0x8024286c
export const RAM_mac_01_ToadHouse_CamSetFOV = 0x80243870
export const RAM_jan_08_SuperBlock_get_partner_rank = 0x80240734
export const RAM_b_area_nok_paragoomba_idleAnimations_8021B338 = 0x8021b338
export const RAM_b_area_kzn_formation_0A = 0x80229abc
export const RAM_mac_02_Quizmo_RenderInit = 0x80241b20
export const RAM_D_E0090A54 = 0xe0090a54
export const RAM_fx_sleep_bubble = 0x800707f0
export const RAM_SetAnimatorFlags = 0x802cd418
export const RAM_energy_orb_wave_main = 0xe00a4000
export const RAM_kpa_95_ToadHouse_DisableStatusMenu = 0x80241608
export const RAM_btl_state_draw_enemy_move = 0x80247afc
export const RAM_IES_Boots3 = 0x8008b2ac
export const RAM_dgb_07_MeleeHitbox_31 = 0x8024006c
export const RAM_D_80245D78_A994B8 = 0x80245d78
export const RAM_b_area_isk_part_1_isk_05 = 0x80223bc4
export const RAM_omo_11_SuperBlock_EndGlowEffect = 0x80240ca0
export const RAM_D_80246270_9247E0 = 0x80246270
export const RAM_D_090022E0 = 0x090022e0
export const RAM_nok_12_PatrolAI_Chase = 0x80241690
export const RAM_kpa_14_GetItemName = 0x80240434
export const RAM_pra_35_reflection_unk_change_anim_facing = 0x80240054
export const RAM_flo_24_80240600 = 0x80240600
export const RAM_pra_01_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_func_80057ED0 = 0x80057ed0
export const RAM_kpa_113_UnkFunc11 = 0x80240040
export const RAM_IES_KootAutographLuigi = 0x8008bd64
export const RAM_clear_player_data = 0x800e73a0
export const RAM_mgm_00_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_b_area_jan_jungle_fuzzy_statusTable_8021E9E4 = 0x8021e9e4
export const RAM_pra_35_PatrolAI_Jump = 0x802416c4
export const RAM_gCollisionNormalZ = 0x800a4260
export const RAM_omo_16_IsAOrBPressed = 0x80240518
export const RAM_stars_shimmer_main = 0xe0044000
export const RAM_sam_10_StashVars = 0x80240000
export const RAM_D_E0088D00 = 0xe0088d00
export const RAM_b_area_trd_part_2_trd_04 = 0x80232cb8
export const RAM_func_80240C70_D7F9D0 = 0x80240c70
export const RAM_b_area_arn_tubba_blubba_partsTable_80225D4C = 0x80225d4c
export const RAM_func_802BE3A4_31F114 = 0x802be3a4
export const RAM_Entity_BlueSwitch = 0x802e9bd4
export const RAM_D_802362D0 = 0x802362d0
export const RAM_dro_02_EVS_Quizmo_WrongAnswer = 0x80246470
export const RAM_dgb_15_makeEntities = 0x80242c64
export const RAM_sam_07_PiranhaPlantAI_01 = 0x80240f8c
export const RAM_b_area_sam_sam_03_UnkModelStuff_array = 0x80230260
export const RAM_evt_handle_exec1 = 0x802c646c
export const RAM_update_cameras = 0x8002d400
export const RAM_func_80240504_EE6AB4 = 0x80240504
export const RAM_nuContPakCallBack = 0x80093cd0
export const RAM_kmr_20_Pipe_GetEntryPos = 0x80240120
export const RAM_flo_12_StashVars = 0x8024027c
export const RAM_D_8009A6A0 = 0x8009a6a0
export const RAM_b_area_isk_part_1_swooper_statusTable_8021C4BC = 0x8021c4bc
export const RAM_b_area_iwa_iwa_01_foregroundModelList_802208A4 = 0x802208a4
export const RAM_D_E0024CF8 = 0xe0024cf8
export const RAM_dgb_01_init_80245FCC = 0x80245fcc
export const RAM_b_area_arn_hyper_paragoomba_StartRumbleWithParams = 0x802182e0
export const RAM_trd_07_FlyingAI_WanderInit = 0x80240ae4
export const RAM_mim_06_HoppingAI_Main = 0x80242b04
export const RAM_kmr_05_FlyingAI_Jump = 0x80240c54
export const RAM_flo_00_func_8024139C_C9F2BC = 0x8024139c
export const RAM_enable_status_debuff = 0x800478f8
export const RAM_dgb_01_D_80252B2C_C103AC = 0x80252b2c
export const RAM_func_800EF300 = 0x800ef300
export const RAM_D_E0074B60 = 0xe0074b60
export const RAM_battle_item_strange_cake_func_802A1A40_731D40 = 0x802a1a40
export const RAM_b_area_sam2_sam_01_UnkModelStuffScript1 = 0x80221e00
export const RAM_b_area_kzn_white_magikoopa_partsTable_80223C90 = 0x80223c90
export const RAM_D_80291C78 = 0x80291c78
export const RAM_kzn_11_FlyingAI_JumpVels = 0x802436d0
export const RAM_pra_01_SetPartnerFlags20000 = 0x80240eec
export const RAM_b_area_trd_part_1_trd_02b_80223478 = 0x80223478
export const RAM_b_area_omo_spy_guy_takeTurn_802293DC = 0x802293dc
export const RAM_D_E001ED50 = 0xe001ed50
export const RAM_appendGfx_enemy_actor_blur = 0x8025595c
export const RAM_b_area_pra3_duplighost_lakilester_takeTurn = 0x802259a4
export const RAM_mgm_01_UpdateRecords = 0x80240c54
export const RAM_msg_draw_frame = 0x8012db58
export const RAM_pra_18_reflection_render_floor_fancy = 0x80240870
export const RAM_HES_FrozenLoop = 0x80105f4c
export const RAM_status_menu_start_blinking_sp = 0x800e9a60
export const RAM_func_8024030C_EE68BC = 0x8024030c
export const RAM_hos_03_ToadHouse_DoesPlayerNeedSleep = 0x80242368
export const RAM_clear_game_modes = 0x80112b98
export const RAM_battle_item_ultra_shroom_func_802A123C_7239BC = 0x802a123c
export const RAM_kzn_03_UpdateTexturePanStepped = 0x80240124
export const RAM_D_E005E670 = 0xe005e670
export const RAM_flo_08_exitWalk_80242988 = 0x80242988
export const RAM_gHudElementsWorld = 0x80156f60
export const RAM_fx_red_impact = 0x80071f90
export const RAM_b_area_arn_tubbas_heart_idle_80223E98 = 0x80223e98
export const RAM_b_area_jan2_white_magikoopa_GetBootsHammerLevel = 0x802185bc
export const RAM_mdl_currentModelTreeNodeInfo = 0x8009a5f4
export const RAM_flo_18_80240E90 = 0x80240e90
export const RAM_D_80247A20_B52490 = 0x80247a20
export const RAM_flo_16_SuperBlock_get_partner_rank = 0x802401b4
export const RAM_D_8024CC28_C0A4A8 = 0x8024cc28
export const RAM_b_area_omo_omo_01_8022DA34 = 0x8022da34
export const RAM_dgb_02_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_kmr_12_ReadEastSign = 0x802409c0
export const RAM_b_area_isk_part_1_stone_chomp_80222324 = 0x80222324
export const RAM_evt_handle_case_less_equal = 0x802c51d8
export const RAM_D_A4700010 = 0xa4700010
export const RAM_D_09000D58_32DC18 = 0x09000d58
export const RAM_mdl_local_gfx_update_vtx_pointers = 0x8011c0dc
export const RAM_gPauseDLLabels = 0x8026f8d4
export const RAM_b_area_pra2_crystal_bit_3 = 0x80220694
export const RAM_func_802403F0_B93830 = 0x802403f0
export const RAM_D_80077140 = 0x80077140
export const RAM_jan_08_SuperBlock_ClearOverride40 = 0x8024067c
export const RAM_D_8029D9F0 = 0x8029d9f0
export const RAM_battle_item_strange_cake_GiveRefundCleanup = 0x802a11d4
export const RAM_sprintf = 0x800654f0
export const RAM_iwa_02_StashVars = 0x80240fe0
export const RAM_dro_02_8024C1F8 = 0x8024c1f8
export const RAM_mim_04_varStash = 0x80242614
export const RAM_D_802433AC_A195CC = 0x802433ac
export const RAM_battle_item_egg_missile2_pal = 0x802a1cf0
export const RAM_bind_enemy_aux = 0x8003e9b4
export const RAM_flo_13_8024445C = 0x8024445c
export const RAM_D_E008CB80 = 0xe008cb80
export const RAM_b_area_sam_gray_magikoopa_init = 0x8022e748
export const RAM_nok_02_Quizmo_UnkStageEffectMode = 0x80242380
export const RAM_func_802A12FC_75E8CC = 0x802a12fc
export const RAM_D_802BEC18_31CC38 = 0x802bec18
export const RAM_arn_11_802404CC = 0x802404cc
export const RAM_battle_move_auto_multibounce_D_802A1B9C = 0x802a1b9c
export const RAM_omo_11_SuperBlock_CantUpgradeMessages = 0x80241e40
export const RAM_D_80250168_C0D9E8 = 0x80250168
export const RAM_D_8024F2B4 = 0x8024f2b4
export const RAM_omo_10_UnkBufferFunc = 0x80240168
export const RAM_D_8029CC00 = 0x8029cc00
export const RAM_b_area_iwa_iwa_01_specialFormation_802208E4 = 0x802208e4
export const RAM_D_09001000_351220 = 0x09001000
export const RAM_dup3_b_area_kpa2_UnfadeBackgroundToBlack = 0x80219394
export const RAM_flo_10_802435F8 = 0x802435f8
export const RAM_flo_25_FlyingAI_Chase = 0x80240e34
export const RAM_b_area_sam2_sam_02d_UnkModelStuffScript4 = 0x80222ef4
export const RAM_b_area_kpa3_UnkActorPosFunc = 0x80218d10
export const RAM_shim_guMtxCatF = 0xe0200450
export const RAM_b_area_kmr_part_2_goomba_king_handleEvent_80220F34 = 0x80220f34
export const RAM_underwater_main = 0xe00ba000
export const RAM_arn_03_npcSettings_80241C3C = 0x80241c3c
export const RAM_dead_npc_move_heading = 0x8003e424
export const RAM_npc_reset_current_decoration = 0x8003c61c
export const RAM_dead_wDisabledPartnerHudScripts = 0x801000a0
export const RAM_func_80240344_8DA444 = 0x80240344
export const RAM_dgb_01_PatrolNoAttackAI_Main = 0x802430c0
export const RAM_omo_05_FlyingAI_Jump = 0x802416a8
export const RAM_pause_banner_bp_png = 0x8026ce30
export const RAM_ResumeTakeTurn = 0x80278a1c
export const RAM_func_80240B24_BBDCC4 = 0x80240b24
export const RAM_disable_x_render = 0xe0082520
export const RAM_PlayerDamageEnemy = 0x80274260
export const RAM_action_command_power_shock_CreateHudElements = 0x802a9000
export const RAM_nok_02_Quizmo_CreateStage = 0x80242080
export const RAM_b_area_kzn_white_magikoopa_handleEvent_80224A44 = 0x80224a44
export const RAM_battle_item_hustle_drink_main = 0x802a189c
export const RAM_D_8014F0C8 = 0x8014f0c8
export const RAM_D_8014BD40 = 0x8014bd40
export const RAM_D_E00C6CD0 = 0xe00c6cd0
export const RAM_func_802412AC_865DEC = 0x802412ac
export const RAM_b_area_trd_part_2_blue_ninja_koopa_defenseTable_8022B4AC = 0x8022b4ac
export const RAM_kpa_95_set_script_owner_npc_anim = 0x80240f70
export const RAM_dup3_b_area_flo_UnkFunc52 = 0x80219358
export const RAM_set_game_mode = 0x800334f0
export const RAM_D_8029D9F8 = 0x8029d9f8
export const RAM_gHudElementCacheTableRasterWorld = 0x80157970
export const RAM_kpa_33_UpdateTexturePanStepped = 0x80240124
export const RAM_end_01_InitCredits = 0x80242568
export const RAM_piAccessBuf = 0x800af8b0
export const RAM_CloseActionCommandInfo = 0x80269410
export const RAM_IES_LimeCandy = 0x8008d394
export const RAM_dgb_04_SuperBlock_HideBlockContent = 0x8024240c
export const RAM_au_sfx_enqueue_event = 0x8004b6d8
export const RAM_mac_03_Quizmo_SpinPartner = 0x80241330
export const RAM_battle_star_peach_dash_802A15B0 = 0x802a15b0
export const RAM_D_80248370_C95F20 = 0x80248370
export const RAM_func_8024013C_B1D89C = 0x8024013c
export const RAM_mgm_01_DoubleScore = 0x80240d84
export const RAM_D_802437C0 = 0x802437c0
export const RAM_dgb_05_MeleeHitbox_31 = 0x802400cc
export const RAM_D_80243A90_DD0760 = 0x80243a90
export const RAM_GetActorAttackBoost = 0x8026fc04
export const RAM_kkj_20_ToadHouse_GetPartnerBackOut = 0x80240584
export const RAM_func_80240000_98AA10 = 0x80240000
export const RAM_D_802A14F0_766110 = 0x802a14f0
export const RAM_gEntityColliderNormals = 0x8008ffb8
export const RAM_D_80249BA4 = 0x80249ba4
export const RAM_gPauseWS_10 = 0x8026fb30
export const RAM_battle_item_please_come_back_DrinkItem = 0x802a1784
export const RAM_D_8014C5E0 = 0x8014c5e0
export const RAM_tik_09_Pipe_GetCurrentFloor = 0x80240020
export const RAM_func_802BB0A0_E2D9D0 = 0x802bb0a0
export const RAM_dgb_01_D_80251F7C_C0F7FC = 0x80251f7c
export const RAM_battle_item_dizzy_dial_DrinkItem = 0x802a1914
export const RAM_dup_b_area_dig_UnkBattleFunc1 = 0x80218240
export const RAM_disable_player_blur = 0x80254914
export const RAM_D_80246BA0_C46950 = 0x80246ba0
export const RAM_pra_02_SetPartnerFlags20000 = 0x80240eec
export const RAM_osFlashChange = 0x8006ef28
export const RAM_flo_03_hit_80242138 = 0x80242138
export const RAM_dro_02_toggleVis_80244600 = 0x80244600
export const RAM_func_802803C8 = 0x802803c8
export const RAM_battle_partner_goombario_init_80238110 = 0x80238110
export const RAM_D_80283770 = 0x80283770
export const RAM_btl_cam_set_params = 0x8024e484
export const RAM_ECB930_foliage_setup_shear_mtx = 0x80241c30
export const RAM_func_80240654_A39B34 = 0x80240654
export const RAM_gCollisionRayDirY = 0x800a4240
export const RAM_b_area_arn_hyper_paragoomba_CalculateRotationZ = 0x802184c4
export const RAM_battle_star_focus_802A19C8 = 0x802a19c8
export const RAM_sbk_44_Bandit_DropCoin = 0x80240040
export const RAM_b_area_jan2_jan_01 = 0x8021eab4
export const RAM_battle_item_dusty_hammer_pal = 0x802a1a00
export const RAM_nuGfxTaskMgr = 0x8002ca40
export const RAM_get_entity_list = 0x80110620
export const RAM_flo_21_settings = 0x80240d00
export const RAM_func_80218440_5CEAD0 = 0x80218440
export const RAM_flo_17_npcAI_80244520 = 0x80244520
export const RAM_dgb_07_extraAnimationList_802418AC = 0x802418ac
export const RAM_sbk_16_SpawnSunEffect = 0x80240000
export const RAM_HES_Item_BoiledEgg = 0x800836d8
export const RAM_D_802D9E78 = 0x802d9e78
export const RAM_mac_01_PatrolAI_LosePlayer = 0x80245fbc
export const RAM_kpa_51_MagikoopaAI_20 = 0x80240cb8
export const RAM_ui_unk_bar_png = 0x800f9ca0
export const RAM_D_80257F24 = 0x80257f24
export const RAM_battle_star_peach_focus_usePower = 0x802a23e8
export const RAM_dro_01_Quizmo_AltNpcSettings = 0x802477b4
export const RAM_D_802D9ECC = 0x802d9ecc
export const RAM_reset_ambient_sounds = 0x80147230
export const RAM_D_00E4B2E0 = 0x00e4b2e0
export const RAM_b_area_trd_part_1_trd_03_beforeBattle_80223F08 = 0x80223f08
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_80228730 = 0x80228730
export const RAM_D_80234768_6BB968 = 0x80234768
export const RAM_battle_item_strange_cake_script9 = 0x802a2d10
export const RAM_b_area_sam2_sam_03_afterBattle = 0x80223448
export const RAM_dup_hos_05_UnkFunc26 = 0x80244de4
export const RAM_jan_09_MeleeHitbox_Main = 0x80240f10
export const RAM_clear_area_flags = 0x80145390
export const RAM_pra_10_UnkFunc001 = 0x80240f20
export const RAM_func_802A984C = 0x802a984c
export const RAM_func_802413C0_EA9EA0 = 0x802413c0
export const RAM_func_80240BD4_EA96B4 = 0x80240bd4
export const RAM_D_8029D418 = 0x8029d418
export const RAM_get_map_IDs_by_name = 0x8005abf8
export const RAM_b_area_jan_spike_top_intTable_8021D5CC = 0x8022483c
export const RAM_D_80245978_BAA0F8 = 0x80245978
export const RAM_func_E00A2234 = 0xe00a2234
export const RAM_ui_goombario_png = 0x800fe620
export const RAM_D_E00B4EF8 = 0xe00b4ef8
export const RAM_nok_01_ToadHouse_AwaitScriptComplete = 0x80240bb0
export const RAM_b_area_kmr_part_3_sam_01_Set80071270_0_16 = 0x80219c38
export const RAM_D_800936DC = 0x800936dc
export const RAM_obk_03_GetNpcCollisionHeight = 0x80240288
export const RAM_D_800F8000 = 0x800f8000
export const RAM_gPauseMsg_3E = 0x8026f6bc
export const RAM_b_area_pra3_duplighost_bombette_defenseTable = 0x8021f3fc
export const RAM_b_area_pra3_gray_magikoopa_statusTable = 0x8022edf8
export const RAM_HES_DizzyBegin = 0x80105900
export const RAM_b_area_omo2_toy_tank_summon_tank_squad = 0x8022e734
export const RAM_b_area_kmr_part_2_red_goomba_2_CalculateRotationZ = 0x8021893c
export const RAM_b_area_kmr_part_3_spiked_goomba_init_8021BEBC = 0x8021bebc
export const RAM_dgb_02_ClubbaNappingAI_ReturnHome = 0x80240f00
export const RAM_b_area_mim_formation_05 = 0x8021e648
export const RAM_b_area_kmr_part_1_kmr_03_clouds2 = 0x8021fef8
export const RAM_b_area_nok_koopa_troopa_defenseTable_8021C98C = 0x8021ff0c
export const RAM_EC9D00_SuperBlock_GatherEnergyFX = 0x80240544
export const RAM_mgm_maps = 0x80093450
export const RAM_func_802BB9E0_E2E310 = 0x802bb9e0
export const RAM_evt_handle_case_less = 0x802c5148
export const RAM_D_80262C38 = 0x80262c38
export const RAM_PlayerFaceNpc = 0x802d1b04
export const RAM_wFogSettings = 0x80153228
export const RAM_dgb_03_pad_3964 = 0x80243964
export const RAM_HES_TransparentBegin = 0x80106cd8
export const RAM_btl_state_draw_1C = 0x80244000
export const RAM_omo_06_UnkBufferFunc = 0x80240168
export const RAM_mac_06_UpdateTexturePanSmooth = 0x80240000
export const RAM_evt_handle_multiplyF = 0x802c5b54
export const RAM_ui_battle_status_sleep_2_png = 0x80101dc0
export const RAM_D_802455F8_DB78B8 = 0x802455f8
export const RAM_mim_07_HoppingAI_Chase = 0x80240b1c
export const RAM_ui_status_text_3_png = 0x80100160
export const RAM_flo_25_exitWalk_802426A8 = 0x802426a8
export const RAM_func_80243798_7EE4A8 = 0x80243798
export const RAM_func_80241130_BCFD20 = 0x80241130
export const RAM_D_80234950_6BBB50 = 0x80234950
export const RAM_dgb_04_SentinelAI_GrabPlayer = 0x80241d14
export const RAM_D_802444A0_9A0E30 = 0x802444a0
export const RAM_osCreateViManager = 0x80066a70
export const RAM_partner_create_npc = 0x800ea5d0
export const RAM_sbk_46_TransformFoliage = 0x802401d0
export const RAM_func_802D4D88 = 0x802d4d88
export const RAM_water_block_render = 0xe00b452c
export const RAM_D_E010E838 = 0xe010e838
export const RAM_D_80245B40_A99280 = 0x80245b40
export const RAM_D_80262A70 = 0x80262a70
export const RAM_b_area_kzn_white_magikoopa_idleAnimations_80223E08 = 0x80223e08
export const RAM_D_802454A0_BB2DE0 = 0x802454a0
export const RAM_D_80078E30 = 0x80078e30
export const RAM_func_80218774_5CEE04 = 0x80218774
export const RAM_fx_star_spirits_energy = 0x800729b0
export const RAM_DoRecover = 0x8029bfa0
export const RAM_dro_01_moveWalls_8024BAD0 = 0x8024bad0
export const RAM_func_80241028_DE34B8 = 0x80241028
export const RAM_D_8024DBC0 = 0x8024dbc0
export const RAM_D_09001A00 = 0x09001a00
export const RAM_SetSelfEnemyFlagBits = 0x80045524
export const RAM_snd_ambient_80055464 = 0x80055464
export const RAM_omo_17_GrooveGuyAI_Main = 0x80240ce0
export const RAM_b_area_pra_StartRumbleWithParams = 0x80218550
export const RAM_BattleFadeInMerlee = 0x80261648
export const RAM_sfx_compute_spatialized_sound_params_1 = 0x8014a1b4
export const RAM_D_80242058_A660E8 = 0x80242058
export const RAM_b_area_nok_formation_18 = 0x80219504
export const RAM_D_800F7B80 = 0x800f7b80
export const RAM_func_802BD29C_320DEC = 0x802bd29c
export const RAM_mim_01_PiranhaPlantAI_12 = 0x80241a08
export const RAM_D_8014B9B0 = 0x8014b9b0
export const RAM_D_80153A4D = 0x80153a4d
export const RAM_dro_02_pad_D_8024F3B4 = 0x8024f3b4
export const RAM_ui_input_a_button_pressed_png = 0x800f88e0
export const RAM_D_8029CC08 = 0x8029cc08
export const RAM_evt_handle_subtract = 0x802c5868
export const RAM_fx_sweat = 0x80070790
export const RAM_flo_21_extraAnimationList_80243DD4 = 0x80243dd4
export const RAM_b_area_iwa_iwa_01_afterBattle_80220894 = 0x80220894
export const RAM_evt_handle_end_switch = 0x802c5718
export const RAM_jan_02_PatrolAI_Loiter = 0x802408f0
export const RAM_func_8024045C_90BB2C = 0x8024045c
export const RAM_ui_misstar_disabled_pal = 0x800fda20
export const RAM_func_80240720_9AEE20 = 0x80240720
export const RAM_falling_leaves_update = 0xe00581b4
export const RAM_b_area_kmr_part_3_egg_jr_troopa_idle_80224B24 = 0x80224b24
export const RAM_flo_08_npcSettings_8024305C = 0x8024305c
export const RAM_func_80242F28_8B2F98 = 0x80242f28
export const RAM_b_area_sam_duplighost_bow_init = 0x80222690
export const RAM_b_area_mac_lee_init_Lakilester = 0x8022858c
export const RAM_EDEA00_LakituAI_Main = 0x80241de4
export const RAM_set_curtain_fade_goal = 0x8002bf14
export const RAM_inflict_status_set_duration = 0x80266cf0
export const RAM_b_area_pra3_red_magikoopa_8022E5FC = 0x8022e5fc
export const RAM_b_area_omo2_3_shy_stack_parts = 0x802310e8
export const RAM_fx_peach_star_beam = 0x80072590
export const RAM_kzn_19_LetterDelivery_SavedNpcAnim = 0x8024854c
export const RAM_b_area_kmr_part_1_kmr_06_clouds2 = 0x80220b98
export const RAM_dgb_17_settings = 0x80240020
export const RAM_playerNpc = 0x802d9d20
export const RAM_pause_image_143BF0_png = 0x802508b0
export const RAM_bActorOffsets = 0x80282ee8
export const RAM_D_800AC5D4 = 0x800ac5d4
export const RAM_b_area_sam_gulpit_rocks_handleEvent = 0x802297b0
export const RAM_D_8024F238_A39478 = 0x8024f238
export const RAM_D_8024561F = 0x8024561f
export const RAM_IES_Heart = 0x8008cbe4
export const RAM_b_area_omo_sky_guy_80229C40 = 0x80229c40
export const RAM_SetEntityHideMode0 = 0x80282624
export const RAM_func_80240040_D9D510 = 0x80240040
export const RAM_arn_03_ItemChoice_WaitForSelection = 0x802415f4
export const RAM_b_area_jan_jan_04b_afterBattle_80228540 = 0x80228540
export const RAM_fx_butterflies = 0x80071870
export const RAM_b_area_trd_part_1_bill_blaster_idleAnimations_80219E48 = 0x80219e48
export const RAM_LandJump = 0x80279ad4
export const RAM_D_802A23E8_735988 = 0x802a23e8
export const RAM_dup3_b_area_flo_UnkBattleFunc1 = 0x80218a54
export const RAM_hieroglyphs_appendGfx = 0xe00e2218
export const RAM_func_800561E4 = 0x800561e4
export const RAM_gLogosImage3 = 0x800a0914
export const RAM_b_area_kpa3_UnkBattleFunc1 = 0x80218000
export const RAM_D_802703A4 = 0x802703a4
export const RAM_func_802B7450_E20560 = 0x802b7450
export const RAM_HES_Item_SpinAttack_disabled = 0x800851a8
export const RAM_D_802A25E8 = 0x802a25e8
export const RAM_b_area_sam_gray_magikoopa_idleAnimations3 = 0x8022c5f8
export const RAM_func_80241B50_BD0740 = 0x80241b50
export const RAM_kzn_09_SuperBlock_GatherEnergyFX = 0x80240544
export const RAM_func_802413E0_91F950 = 0x802413e0
export const RAM_D_80270058 = 0x80270058
export const RAM_kpa_115_ProjectileHitbox_GetUsableProjectileID = 0x80240170
export const RAM_func_8024060C_A1B70C = 0x8024060c
export const RAM_HES_Item_PrettyLucky = 0x80084338
export const RAM_kmr_12_entryList = 0x80240030
export const RAM_D_8024E3A0_831660 = 0x8024e3a0
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_80228610 = 0x80228610
export const RAM_b_area_isk_part_1_pokey_mummy_8021B1B4 = 0x8021b1b4
export const RAM_dro_02_8024EFA8 = 0x8024efa8
export const RAM_func_802C0288 = 0x802bd388
export const RAM_D_09000330_392770 = 0x09000330
export const RAM_D_802465D8_915C88 = 0x802465d8
export const RAM_iwa_02_varStash = 0x80241da8
export const RAM_D_80224D74 = 0x80224d74
export const RAM_b_area_kzn_kzn_02 = 0x80227db8
export const RAM_func_80240050_AB9300 = 0x80240050
export const RAM_ShowMessageAtScreenPos = 0x802d0894
export const RAM_arn_03_init_80243ECC = 0x80243ecc
export const RAM_mac_01_Quizmo_GetItemName = 0x80241fc4
export const RAM_D_80234830_6BBA30 = 0x80234830
export const RAM_kmr_05_FlyingAI_WanderInit = 0x80240000
export const RAM_sbk_01_npcSettings_802403CC = 0x802403cc
export const RAM_trd_04_FlyingAI_ChaseInit = 0x80242ad8
export const RAM_b_area_sam_sam_02c_UnkModelStuffScript1 = 0x8022fb80
export const RAM_smoke_impact_appendGfx = 0xe003a354
export const RAM_D_80244154_BC12F4 = 0x80244154
export const RAM_kzn_17_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_8025D4A0 = 0x8025d4a0
export const RAM_D_09000400_350620 = 0x09000400
export const RAM_func_80240078_DCCD48 = 0x80240078
export const RAM_sbk_34_AddPlayerHandsOffset = 0x802401b4
export const RAM_IES_AttackFXD = 0x8008ca24
export const RAM_get_player_data = 0x800e7564
export const RAM_D_802A9860_42F680 = 0x802a9860
export const RAM_dgb_06_EVS_Chest_Interact = 0x8024076c
export const RAM_D_802AD605 = 0x802ad605
export const RAM_D_802AD258 = 0x802ad258
export const RAM_flo_10_802415E8 = 0x802415e8
export const RAM_flo_22_settings = 0x802402a0
export const RAM_dro_02_func_80242214_96B3D4 = 0x80242214
export const RAM_gHudElementCacheSizeWorld = 0x80157968
export const RAM_func_E005E334 = 0xe005e334
export const RAM_flo_15_802412E0 = 0x802412e0
export const RAM_copy_matrix = 0x80029720
export const RAM_throw_spiny_init = 0xe00c8218
export const RAM_D_80244CC0_DB20C0 = 0x80244cc0
export const RAM_dgb_12_main = 0x802404e0
export const RAM_ui_battle_cont_c_right_down_png = 0x8028bbf8
export const RAM_flo_24_flo_10_name_hack = 0x80242280
export const RAM_mac_00_Quizmo_StashVars = 0x80240630
export const RAM_kzn_02_FlyingNoAttackAI_20 = 0x80241d88
export const RAM_D_800A0948 = 0x800a0948
export const RAM_EA0C10_PiranhaPlantAI_00 = 0x80240c5c
export const RAM_b_area_kmr_part_1_goomba_ArcsinDeg = 0x80218000
export const RAM_osPfsNumFiles = 0x80069090
export const RAM_dgb_15_80241BE4 = 0x80241be4
export const RAM_func_802A97FC = 0x802a97fc
export const RAM_au_bgm_update_fade = 0x8004e3a4
export const RAM_D_802BFF0C = 0x802bff0c
export const RAM_func_802A2C58 = 0x802a2c58
export const RAM_b_area_nok_nok_01_8022B5F0 = 0x8022b5f0
export const RAM_b_area_arn_formation_02 = 0x80227cb4
export const RAM_gPauseWS_18 = 0x8026fcf0
export const RAM_battle_item_mystery_func_802A13E4_72C994 = 0x802a13e4
export const RAM_arn_10_80240248 = 0x80240248
export const RAM_kzn_11_FlyingNoAttackAI_20 = 0x80241d08
export const RAM_func_802693F0 = 0x802693f0
export const RAM_evt_handle_set_group = 0x802c6914
export const RAM_dgb_10_main = 0x8024050c
export const RAM_fx_ice_shard = 0x80072650
export const RAM_dgb_04_80243A98 = 0x80243a98
export const RAM_kmr_02_ToadHouse_DisableStatusMenu = 0x80243070
export const RAM_kzn_17_set_script_owner_npc_col_height = 0x80241fc8
export const RAM_D_80244788_B97BC8 = 0x80244788
export const RAM_HES_Item_HoneyShroom = 0x80083198
export const RAM_gPauseMsg_35 = 0x8026f680
export const RAM_flo_13_FlyingAI_Jump = 0x80240e18
export const RAM_b_area_mim_forest_fuzzy_UnkBattleFunc2 = 0x80220740
export const RAM_b_area_sam_duplighost_UnkTattleEffectFunc1 = 0x80218af4
export const RAM_D_80242BF8_B20358 = 0x80242bf8
export const RAM_dgb_09_MeleeHitbox_CanSeePlayer = 0x80240208
export const RAM_D_80151664 = 0x80151664
export const RAM_b_area_omo2_toy_tank_8022F2BC = 0x8022f2bc
export const RAM_func_80240068_BB2E68 = 0x80240068
export const RAM_EA7010_UpdateTexturePanSmooth = 0x80240040
export const RAM_kzn_17_WanderMeleeAI_Main = 0x8024092c
export const RAM_flo_08_SuperBlock_RadiateFaintEnergyFX = 0x802405a8
export const RAM_rising_bubble_update = 0xe0046160
export const RAM_sbk_54_SpawnSunEffect = 0x80240000
export const RAM_b_area_omo_medi_guy_statusTable_8021F8F0 = 0x80223470
export const RAM_ui_skolar_pal = 0x800fd580
export const RAM_b_area_kzn_lava_bubble_UnkSfxFunc = 0x80218000
export const RAM_flo_03_GetItemName = 0x802400f4
export const RAM_IES_Unused_08C = 0x8008be64
export const RAM_kmr_02_Quizmo_RenderInit = 0x802406f0
export const RAM_Entity_RenderNone = 0x802e9828
export const RAM_flo_14_802431D0 = 0x802431d0
export const RAM_D_802BDF3C = 0x802bdf3c
export const RAM_func_802454B4_805D34 = 0x802454b4
export const RAM_pra_19_ReflectWall = 0x802400ec
export const RAM_b_area_jan_jan_00 = 0x802272a8
export const RAM_D_80079540 = 0x80079540
export const RAM_arn_07_idle_80245210 = 0x80245210
export const RAM_b_area_kmr_part_3_para_jr_troopa_802263D4 = 0x802263d4
export const RAM_flo_22_802402E0 = 0x802402e0
export const RAM_b_area_sbk_formation_00 = 0x8021f940
export const RAM_D_8024BE60_C096E0 = 0x8024be60
export const RAM_vtx_C21930 = 0x802009f0
export const RAM_func_80240B54_8EC974 = 0x80240b54
export const RAM_func_80048E34 = 0x80048e34
export const RAM_D_802EB270 = 0x802eb270
export const RAM_battle_partner_kooper_idle_802385D0 = 0x802385d0
export const RAM_dro_02_npcSettings_80247558 = 0x80247558
export const RAM_flo_00_UnkFloatFunc = 0x802411ec
export const RAM_au_driver_release = 0x800565a4
export const RAM_omo_06_SetNpcPosYaw = 0x80240290
export const RAM_hos_03_ToadHouse_DisableStatusMenu = 0x802421a8
export const RAM_end_00_Font4Patterns = 0x80242c70
export const RAM_D_80232B10_684890 = 0x80232b10
export const RAM_bind_trigger_1 = 0x802c42a8
export const RAM_dma_write_block = 0x80029860
export const RAM_au_bgm_set_effect_indices = 0x8004e344
export const RAM_func_8024228C_A3B76C = 0x8024228c
export const RAM_ui_menu_times_png = 0x800fc260
export const RAM_IES_CocoPop = 0x8008d3b4
export const RAM_jan_09_MeleeHitbox_31 = 0x80240bc0
export const RAM_D_802BEC70 = 0x802bec70
export const RAM_D_80231110 = 0x80231110
export const RAM_HES_Item_MoneyMoney = 0x800847b8
export const RAM_b_area_trd_part_1_formation_12 = 0x802189ec
export const RAM_b_area_sbk_sbk_02_afterBattle_8021F8E8 = 0x8021f8e8
export const RAM_remove_status_debuff = 0x80047898
export const RAM_entity_SaveBlock_wait_for_close_choice = 0x802e1350
export const RAM_gPauseIconScripts = 0x8024ef60
export const RAM_CancelEnemyTurn = 0x8026e208
export const RAM_battle_menu_PartnerMoveHudScripts = 0x802ab590
export const RAM_iwa_04_CleftAI_RevUpInit = 0x802406c4
export const RAM_func_80240DE4_C77AC4 = 0x80240de4
export const RAM_kmr_02_Pipe_GetCurrentFloor = 0x80240020
export const RAM_D_802B6EE4 = 0x802b6ee4
export const RAM_HES_POWCost = 0x801076e8
export const RAM_dgb_15_pad_19B4 = 0x802419b4
export const RAM_D_80224DB0 = 0x80224db0
export const RAM_b_area_pra3_white_clubba_statusTable = 0x8022b198
export const RAM_kpa_03_MagikoopaAI_Main = 0x80241ab4
export const RAM_shim_draw_box = 0xe0200640
export const RAM_kpa_14_UpdateTexturePanStepped = 0x80240164
export const RAM_D_E00AC7BC = 0xe00ac7bc
export const RAM_dro_01_EVS_Quizmo_Npc_AI = 0x80247768
export const RAM_b_area_arn_hypergoomba_idleAnimations_80219818 = 0x80219818
export const RAM_fx_thunderbolt_ring = 0x80071990
export const RAM_smoke_burst_update = 0xe004e164
export const RAM_ui_battle_cmd_aim_target_0_pal = 0x8028f4e0
export const RAM_btl_state_draw_partner_striking_first = 0x802485fc
export const RAM_battle_move_quake_hammer_IsRightOnEquipped = 0x802a1050
export const RAM_sam_07_MeleeHitbox_30 = 0x80240650
export const RAM_omo_15_StarSpiritEffectFunc1 = 0x80240000
export const RAM_action_update_idle_peach = 0x802b61e4
export const RAM_pra_37_MeleeHitbox_Main = 0x8024130c
export const RAM_kpa_115_UnkNpcAIFunc48 = 0x80240360
export const RAM_flo_10_npcSettings_80243220 = 0x80243220
export const RAM_b_area_trd_part_2_trd_05f_afterBattle_80235D1C = 0x80235d1c
export const RAM_flo_17_LakituAI_GetAvailableSpiny = 0x80241568
export const RAM_b_area_sam_duplighost_UnkWattEffectFunc2 = 0x80219304
export const RAM_b_area_nok_formation_0B = 0x80219114
export const RAM_fx_65_init = 0xe00ca240
export const RAM_D_802416F0_A285C0 = 0x802416f0
export const RAM_b_area_trd_part_2_fake_bowser_8021ABE4 = 0x8021abe4
export const RAM_flo_21_802415A0 = 0x802415a0
export const RAM_nuAuTaskStop = 0x80078180
export const RAM_b_area_pra3_duplighost_kooper_parts = 0x8021d858
export const RAM_DoSleepHit = 0x802945e0
export const RAM_D_80156910 = 0x80156910
export const RAM_b_area_omo_omo_04_idle_8022E1F8 = 0x8022e1f8
export const RAM_vtx_C2B9B0 = 0x8020aa70
export const RAM_b_area_sam_sam_02c_UnkModelStuff_func1 = 0x8021a510
export const RAM_kzn_18_GetFloorCollider = 0x80240388
export const RAM_get_transform_group_index = 0x8011b090
export const RAM_dgb_02_enterSingleDoor_80241820 = 0x80241820
export const RAM_dgb_16_npcSettings_8024194C = 0x8024194c
export const RAM_set_effect_pos_offset = 0x80059c88
export const RAM_b_area_trd_part_2_fake_bowser_UnkFunc26 = 0x8021817c
export const RAM_shop_open_item_select_popup = 0x80280954
export const RAM_isk_10_SuperBlock_UpgradeOrbAngles = 0x80241158
export const RAM_tik_14_Pipe_AwaitDownInput = 0x80240034
export const RAM_nuGfxFuncSet = 0x8005f250
export const RAM_b_area_kmr_part_2_blue_goomba_doDeath_8021B388 = 0x8021b388
export const RAM_ui_battle_hp_0_png = 0x8028a960
export const RAM_kpa_52_UnkNpcAIFunc48 = 0x802402d0
export const RAM_b_area_pra3_duplighost_UnkTattleEffectFunc2 = 0x80218b2c
export const RAM_kpa_102_UpdateTexturePanSmooth = 0x80240000
export const RAM_D_802DADA4 = 0x802dada4
export const RAM_b_area_pra3_gray_magikoopa_handleEvent_flying = 0x8022fd04
export const RAM_hos_03_ToadHouse_PutPartnerAway = 0x80242438
export const RAM_b_area_omo_omo_04_statusTable_8022DF40 = 0x8022df40
export const RAM_D_80243920_A4BEE0 = 0x80243920
export const RAM_func_80240264_B06C64 = 0x80240264
export const RAM_D_8014BB10 = 0x8014bb10
export const RAM_D_802A3534_7994F4 = 0x802a3534
export const RAM_D_80244820_AF1C30 = 0x80244820
export const RAM_func_80240310_B06D10 = 0x80240310
export const RAM_vtx_C23810 = 0x802028d0
export const RAM_D_8010BB90 = 0x8010bb90
export const RAM_crash_screen_draw_rect = 0x8002bfb0
export const RAM_pra_28_SetPartnerFlags80000 = 0x80240ee8
export const RAM_flo_09_func_80240000_CB5000 = 0x80240000
export const RAM_D_80232C4C = 0x80232c4c
export const RAM_intro_logos_set_fade_alpha = 0x800337d0
export const RAM_ui_input_analog_stick2_png = 0x800f9180
export const RAM_gCloudyFlowerFieldsBg = 0x8014f120
export const RAM_HammerHit = 0x802b6db0
export const RAM_IES_HoneyShroom = 0x8008d314
export const RAM_BowTweesterPhysicsPtr = 0x802bdffc
export const RAM_b_area_trd_part_3_eldstar_takeTurn_80218414 = 0x80218414
export const RAM_flo_09_npcSettings_80243600 = 0x80243600
export const RAM_b_area_trd_part_2_blue_ninja_koopa_idleAnimations_8022B67C = 0x8022b67c
export const RAM_b_area_trd_part_2_trd_05f = 0x80235d2c
export const RAM_entity_MulticoinBlock_update_timer = 0x802e3a00
export const RAM_D_8015959E = 0x8015959e
export const RAM_ECAA80_UnkFloAI_Chase = 0x80241364
export const RAM_IES_MapleSuper = 0x8008d274
export const RAM_battle_menu_FleeHudScripts = 0x802ab488
export const RAM_GetOriginalActorType = 0x8027da68
export const RAM_dispatch_damage_event_player_0 = 0x8027337c
export const RAM_dup_kmr_20_foliage_setup_shear_mtx = 0x80240e20
export const RAM_b_area_omo2_general_guy_handleEvent = 0x802186ec
export const RAM_WattPutAway = 0x802bdd0c
export const RAM_D_80241688_BAB7C8 = 0x80241688
export const RAM_trd_02_UnkNpcAIFunc12 = 0x80240290
export const RAM_pra_05_CheckItemFlags40 = 0x80241188
export const RAM_b_area_omo_pyro_guy_UnkBattleFunc1 = 0x80218ee0
export const RAM_mim_08_MeleeHitbox_CanSeePlayer = 0x802417a0
export const RAM_isk_13_HoppingAI_Loiter = 0x80240628
export const RAM_dgb_03_npcAI_80243B58 = 0x80243b58
export const RAM_EED1E0_SuperBlock_GatherEnergyFX = 0x80240624
export const RAM_D_800A3FE0 = 0x800a3fe0
export const RAM_b_area_kmr_part_1_kmr_06_UnkAngleFunc2 = 0x80218f30
export const RAM_sam_02_Quizmo_StashVars = 0x80240b2c
export const RAM_AddActorVar = 0x8026c0d4
export const RAM_hos_00_UnkEffect0FFunc = 0x80240170
export const RAM_b_area_omo2_5_shy_squad_dup_move_guy_to_pos = 0x80232104
export const RAM_func_802197B8_465C48 = 0x802197b8
export const RAM_b_area_hos_formation_02 = 0x80225eb4
export const RAM_dead_entity_upgrade_block_hide_content = 0x802f3b00
export const RAM_IES_SpecialShake = 0x8008d1b4
export const RAM_gPauseSpiritsGridData = 0x8024f930
export const RAM_mac_03_Quizmo_AnswerResult = 0x802513e8
export const RAM_func_80240B8C_B0758C = 0x80240b8c
export const RAM_flo_09_802425DC = 0x802425dc
export const RAM_kpa_111_ProjectileAI_Reflect = 0x80240c08
export const RAM_flo_19_80241A70 = 0x80241a70
export const RAM_waterfall_update = 0xe00b6180
export const RAM_b_area_nok_formation_01 = 0x80218e58
export const RAM_kzn_03_PiranhaPlantAI_14 = 0x80242260
export const RAM_D_802BCBD0_E31520 = 0x802bcbd0
export const RAM_b_area_trd_part_1_koopa_troopa_init_8021CB74 = 0x8021cb74
export const RAM_player_test_move_without_slipping = 0x800df15c
export const RAM_dgb_02_80241AB0 = 0x80241ab0
export const RAM_D_8023D338 = 0x8023d338
export const RAM_dgb_08_SentinelAI_GrabPlayer = 0x802434b0
export const RAM_HES_Item_KootLuigiAutograph = 0x80082118
export const RAM_EB8E90_PatrolAI_ChaseInit = 0x8024085c
export const RAM_omo_07_GetItemName = 0x80242fd4
export const RAM_b_area_pra3_duplighost_bombette_idle = 0x8021f54c
export const RAM_IES_MegaSmash = 0x8008c584
export const RAM_sam_05_PiranhaPlantAI_Main = 0x80241754
export const RAM_is_entity_data_loaded = 0x8011115c
export const RAM_D_80228490_6CC020 = 0x80228490
export const RAM_ui_battle_cmd_aim_shimmer_0_png = 0x8028ea80
export const RAM_b_area_arn_hypergoomba_handleEvent_80219BD8 = 0x80219bd8
export const RAM_dup2_b_area_kpa2_RemoveChillOut = 0x802186a0
export const RAM_tik_21_Pipe_SetAnimFlag = 0x8024030c
export const RAM_ui_coin_sparkle_0_png = 0x800fbc80
export const RAM_D_E00CEE68 = 0xe00cee68
export const RAM_b_area_kgr_fuzzipede_hammer_text = 0x8021afe4
export const RAM_status_menu_stop_blinking_sp = 0x800e9a94
export const RAM_D_09000080_330F80 = 0x09000080
export const RAM_D_09002A38 = 0x09002a38
export const RAM_mgm_01_GiveCoinReward = 0x80240cd0
export const RAM_func_8025E108 = 0x8025e108
export const RAM_func_80240F20_D8A600 = 0x80240f20
export const RAM_npc_raycast_up = 0x800dce70
export const RAM_mac_05_Quizmo_HideEntities = 0x80241438
export const RAM_gPauseBadgesTargetScrollIndex = 0x80270394
export const RAM_pra_36_reflection_unk_change_anim_facing = 0x80240084
export const RAM_b_area_mac_lee_defenseTable_8021E998 = 0x8021e998
export const RAM_btl_show_battle_message = 0x8024f960
export const RAM_b_area_omo2_1_shy_squad_statusTable = 0x802310bc
export const RAM_coin_counter_draw_content = 0x800e92d8
export const RAM_pra_35_PatrolNoAttackAI_15 = 0x802419f4
export const RAM_D_80150A10 = 0x80150a10
export const RAM_D_802B7930_E217E0 = 0x802b7930
export const RAM_omo_12_UnsetCamera0Flag1000 = 0x802402f8
export const RAM_b_area_pra3_red_magikoopa_8022E334 = 0x8022e334
export const RAM_evt_handle_OR_const = 0x802c6390
export const RAM_D_8026FF60 = 0x8026ff60
export const RAM_flo_07_searchBush = 0x8024124c
export const RAM_b_area_kmr_part_3_spiked_goomba_ArcsinDeg = 0x80218000
export const RAM_D_8010C9B0 = 0x8010c9b0
export const RAM_RemoveEffect = 0x802d7ae4
export const RAM_D_802BEC78 = 0x802bec78
export const RAM_b_area_isk_part_1_pokey_mummy_8021B6AC = 0x8021b6ac
export const RAM_func_802A1108_75CA88 = 0x802a1108
export const RAM_flo_13_defeat_80246038 = 0x80246038
export const RAM_dup_b_area_omo3_UnkBattleFunc1 = 0x80218bb0
export const RAM_D_80245EEC_A3F3CC = 0x80245eec
export const RAM_flo_13_LakituAI_Wander = 0x80241704
export const RAM_ui_battle_menu_strategies_png = 0x80289ac0
export const RAM_dgb_02_npcAISettings_80241B9C = 0x80241b9c
export const RAM_D_E00947C0 = 0xe00947c0
export const RAM_D_E0016720 = 0xe0016720
export const RAM_entity_HeartBlockContent__setupGfx = 0x802e4730
export const RAM_b_area_hos_magikoopa_boostDefense = 0x80222508
export const RAM_tik_19_SuperBlock_UpgradeDescMessages = 0x802426c8
export const RAM_D_8010D67C = 0x8010d67c
export const RAM_gCurrentTriggerListPtr = 0x80159390
export const RAM_D_80297814 = 0x80297814
export const RAM_HES_EmptyBar = 0x8010790c
export const RAM_tik_12_UnkPosFunc2 = 0x802405f0
export const RAM_gPauseMessages = 0x8026f778
export const RAM_ui_ultra_boots_disabled_pal = 0x800fb580
export const RAM_dgb_04_FlyingAI_LoiterInit = 0x802407d8
export const RAM_b_area_sam_formation_03 = 0x8023094c
export const RAM_D_802510B0_8EB1B0 = 0x802510b0
export const RAM_battle_star_smooch_UnkBackgroundFunc2 = 0x802a137c
export const RAM_mac_02_PatrolNoAttackAI_Main = 0x802414c4
export const RAM_flo_07_tree1_Trunk = 0x80242898
export const RAM_D_8024655C = 0x8024655c
export const RAM_btl_state_draw_switch_to_player = 0x80242ba8
export const RAM_kzn_03_UpdateTexturePanSmooth = 0x80240000
export const RAM_ui_status_pow_star_1_pal = 0x80100920
export const RAM_b_area_jan_spear_guy_idleAnimations_8021BF40 = 0x8021bf40
export const RAM_kkj_11_GetPeachDisguise = 0x80240f18
export const RAM_vtx_C25710 = 0x802047d0
export const RAM_D_8024F584 = 0x8024f584
export const RAM_jan_02_Quizmo_DestroyEffects = 0x80241f9c
export const RAM_D_800DAABD = 0x800daabd
export const RAM_D_802451D8_9B6968 = 0x802451d8
export const RAM_trd_02_PatrolAI_Chase = 0x80241200
export const RAM_D_8029FB60 = 0x8029fb60
export const RAM_ui_battle_hp_9_pal = 0x8028abc0
export const RAM_func_800E9810 = 0x800e9810
export const RAM_tik_02_UpdateTexturePanStepped = 0x80240124
export const RAM_flo_24_pad_D38 = 0x80240d38
export const RAM_b_area_isk_part_2_tutankoopa_UnkBattleFunc1 = 0x80218690
export const RAM_arn_03_npcAI_80241BF0 = 0x80241bf0
export const RAM_floating_cloud_puff_update = 0xe00d2120
export const RAM_b_area_hos_hos_01 = 0x802255b0
export const RAM_D_E00CEE60 = 0xe00cee60
export const RAM_LoadAnimatedModel = 0x802cccec
export const RAM_execute_render_tasks = 0x8011d9b8
export const RAM_b_area_trd_part_2_blue_ninja_koopa_GetLastActorEventType = 0x80219ac8
export const RAM_filemenu_iterFileIdx = 0x8024c080
export const RAM_b_area_kzn_medi_guy_defenseTable_8021F8DC = 0x8021f8dc
export const RAM_dro_01_PatrolAI_Move = 0x80240738
export const RAM_func_802428C8_A2CB08 = 0x802428c8
export const RAM_kmr_03_802414A8 = 0x802414a8
export const RAM_SetPartSounds = 0x8026fa00
export const RAM_func_802182E4_59E9C4 = 0x802182e4
export const RAM_D_8029DA33 = 0x8029da33
export const RAM_world_goombario_can_pause = 0x802bd5a8
export const RAM_suggest_player_anim_clearUnkFlag = 0x800dfefc
export const RAM_pra_35_SetPartnerFlags20000 = 0x80240eec
export const RAM_b_area_jan_spear_guy_idle_80218BE0 = 0x80218be0
export const RAM_D_802314DC_63D07C = 0x802314dc
export const RAM_show_start_recovery_shimmer = 0x802d7460
export const RAM_D_802ACCD0 = 0x802accd0
export const RAM_tik_10_SuperBlock_SwitchToPartner = 0x80240a9c
export const RAM_D_E00947C8 = 0xe00947c8
export const RAM_D_E0016728 = 0xe0016728
export const RAM_func_80219658_465AE8 = 0x80219658
export const RAM_b_area_pra2_sam_04_UnkFloatFunc2 = 0x802188dc
export const RAM_b_area_trd_part_2_fake_bowser_D_8021AAC4_48BCB4 = 0x8021aac4
export const RAM_arn_03_func_80241068_BDFDF8 = 0x80241068
export const RAM_kpa_111_ProjectileHitbox_30 = 0x802404a4
export const RAM_SetSleepBubbleTimeLeft = 0x802d8248
export const RAM_gPausePartnersIconScripts = 0x8024f600
export const RAM_jan_07_MeleeHitbox_30 = 0x80240214
export const RAM_arn_04_main = 0x80243580
export const RAM_func_800E29C8 = 0x800e29c8
export const RAM_kpa_51_set_script_owner_npc_anim = 0x8024144c
export const RAM_vtx_C239D0 = 0x80202a90
export const RAM_hud_element_update = 0x80141704
export const RAM_HES_ArrowRight = 0x801049d8
export const RAM_obk_07_UnkAngleFunc004 = 0x802402cc
export const RAM_D_802498E0_A33B20 = 0x802498e0
export const RAM_HES_Item_SuspiciousNote = 0x800818d8
export const RAM_func_80240000_B767B0 = 0x80240000
export const RAM_b_area_kzn_spike_top_defenseTable_8021C8B0 = 0x8021c8b0
export const RAM_dead_dist2D = 0x8002b1c4
export const RAM_dgb_13_exitWalk_802400F0 = 0x802400f0
export const RAM_b_area_nok_goomba_idleAnimations_80219854 = 0x80219854
export const RAM_b_area_nok_goomba_statusTable_8021975C = 0x8021975c
export const RAM_dup4_b_area_pra_ShrinkActor = 0x802190c0
export const RAM_item_entity_disable_shadow = 0x80130f70
export const RAM_D_80244330_BE7AE0 = 0x80244330
export const RAM_D_80240334_8FF1B4 = 0x80240334
export const RAM_b_area_jan2_putrid_piranha_8021B354 = 0x80218974
export const RAM_clear_animator_flags = 0x80120118
export const RAM_battle_star_up_and_away_802A1A68 = 0x802a1a68
export const RAM_b_area_trd_part_1_trd_05f_beforeBattle_80227B94 = 0x80227b94
export const RAM_dro_02_D_80248C58_971E18 = 0x80248c58
export const RAM_b_area_tik2_StartRumbleWithParams = 0x80218000
export const RAM_enable_player_blur = 0x802546d4
export const RAM_SetCamPerspective = 0x802ca828
export const RAM_D_8010EF9C = 0x8010ef9c
export const RAM_D_8024AA28_A34C68 = 0x8024aa28
export const RAM_arn_07_FlyingAI_JumpVels = 0x80243bc0
export const RAM_D_090018D8 = 0x090018d8
export const RAM_D_802EB644 = 0x802eb644
export const RAM_nuAuDmaNext = 0x80078178
export const RAM_kzn_09_SuperBlock_SetOverride40 = 0x80240000
export const RAM_D_8023CD58_6F5E38 = 0x8023cd58
export const RAM_func_8025D2B0 = 0x8025d2b0
export const RAM_b_area_mim_forest_fuzzy_80219054 = 0x80219054
export const RAM_b_area_hos_magikoopa_vector3D_802216BC = 0x802216bc
export const RAM_bPlayerActorBlueprint = 0x802834d8
export const RAM_mac_06_UpdatePosFX3D = 0x80240da4
export const RAM_ED0310_FlyingAI_Main = 0x8024162c
export const RAM_RemoveBadge = 0x802d7050
export const RAM_btl_state_update_run_away = 0x80244f00
export const RAM_D_802435C0_E15D20 = 0x802435c0
export const RAM_pause_stats_bar_png = 0x8026e6b0
export const RAM_flo_08_npcGroup_802445F8 = 0x802445f8
export const RAM_D_802A10B0_73AF80 = 0x802a10b0
export const RAM_pra_29_ReflectFloor = 0x8024049c
export const RAM_func_802A12FC_7598AC = 0x802a12fc
export const RAM_IES_TubbaCastleKey = 0x8008b524
export const RAM_b_area_pra3_red_magikoopa_8022C1E4 = 0x8022c1e4
export const RAM_D_80150A18 = 0x80150a18
export const RAM_HES_Item_HappyFlower = 0x800856b8
export const RAM_ui_battle_status_pdown_1_pal = 0x80102ac0
export const RAM_kzn_09_GetItemName = 0x80243360
export const RAM_HES_PressBButton = 0x802923b0
export const RAM_IES_XBandage = 0x8008dcf4
export const RAM_IES_SuperShroom = 0x8008d7f4
export const RAM_mim_02_HoppingAI_HopInit = 0x80240070
export const RAM_D_802435B0_E15D10 = 0x802435b0
export const RAM_b_area_arn_hyper_cleft_80221DE0 = 0x80221de0
export const RAM_b_area_mac_lee_handleEvent_80220908 = 0x80220908
export const RAM_b_area_sbk_formation_0E = 0x8021fd68
export const RAM_D_802ACFFD = 0x802acffd
export const RAM_au_SEFCmd_17 = 0x8004d3a0
export const RAM_flo_09_80241880 = 0x80241880
export const RAM_mac_05_DisposeFX3D = 0x80243d78
export const RAM_D_80151248 = 0x80151248
export const RAM_pra_11_reflection_unk_resolve_anim = 0x80240020
export const RAM_flo_13_80244560 = 0x80244560
export const RAM_UnfadeBackgroundFromBlack = 0x8003ef98
export const RAM_mac_01_PatrolAI_Chase = 0x802419cc
export const RAM_dup_jan_07_foliage_setup_shear_mtx = 0x80240fe0
export const RAM_b_area_sam_pos_rocks_1 = 0x80230670
export const RAM_D_8015A1E4 = 0x8015a1e4
export const RAM_sam_07_WanderMeleeAI_Main = 0x80240c6c
export const RAM_flo_18_shakeTree = 0x80246a20
export const RAM_b_area_kmr_part_3_kmr_06_UnkAngleFunc2 = 0x80219730
export const RAM_flo_12_GetItemName = 0x80240330
export const RAM_tik_19_SuperBlock_UpgradeOrbAngles = 0x80242708
export const RAM_func_802407AC_BCD80C = 0x802407ac
export const RAM_kpa_62_UpdateTexturePanStepped = 0x80240204
export const RAM_mac_01_Quizmo_HideWorld = 0x8024233c
export const RAM_func_80240000_BB59E0 = 0x80240000
export const RAM_dup2_b_area_kpa2_GetItemEntityPosition = 0x8021cc5c
export const RAM_jan_00_TransformFoliage = 0x802419d0
export const RAM_battle_item_egg_missile_GiveRefund = 0x802a1000
export const RAM_D_80225FB8_48D068 = 0x80225fb8
export const RAM_state_drawUI_end_battle = 0x80033e64
export const RAM_pause_items_is_visible = 0x80249930
export const RAM_jan_02_PatrolNoAttackAI_Main = 0x80240fc4
export const RAM_func_80218000_5573E0 = 0x80218000
export const RAM_b_area_kzn_white_magikoopa_run_away = 0x80225d34
export const RAM_jan_22_PatrolAI_MoveInit = 0x80240000
export const RAM_arn_05_SwitchToPartner = 0x802412c8
export const RAM_IES_KoopaLeaf = 0x8008d954
export const RAM_D_802491C8_CA70E8 = 0x802491c8
export const RAM_D_8023D1E4 = 0x8023d1e4
export const RAM_dup_kkj_16_UnkAtan2Func = 0x80240de4
export const RAM_HES_Item_LimeCandy_disabled = 0x80083348
export const RAM_b_area_sam_gulpit_idleAnimations2 = 0x80227e1c
export const RAM_arn_03_npcGroupList_80244740 = 0x80244740
export const RAM_action_command_hammer_draw_hud_elements = 0x802a99d4
export const RAM_gPauseCursorPosY = 0x8024efa0
export const RAM_pra_20_SetPartnerFlags20000 = 0x80240eec
export const RAM_kzn_19_GetItemName = 0x8024151c
export const RAM_func_802DDFF8 = 0x802ddff8
export const RAM_flo_09_npcAI_802435E0 = 0x802435e0
export const RAM_HES_Item_CrazyHeart_disabled = 0x80085388
export const RAM_battle_item_dusty_hammer_GiveRefundCleanup = 0x802a11d4
export const RAM_EF2680_UnkDistFunc = 0x80242218
export const RAM_D_80220760 = 0x80220760
export const RAM_battle_move_mega_smash_IsRightOnEquipped = 0x802a1050
export const RAM_func_802A9000_426170 = 0x802a9000
export const RAM_filemenu_update_show_options_right = 0x80243468
export const RAM_func_80251454 = 0x80251454
export const RAM_dgb_04_FlyingAI_Init = 0x802411e8
export const RAM_D_E00A8678 = 0xe00a8678
export const RAM_dro_01_Quizmo_varStash = 0x802451b8
export const RAM_flo_08_BuildItemChoiceList = 0x802419fc
export const RAM_wPartnerCurrentScript = 0x8010cfd4
export const RAM_GetSelfVar = 0x800452c0
export const RAM_ui_status_coin_9_pal = 0x80101120
export const RAM_jan_08_UpdateTexturePanStepped = 0x80240124
export const RAM_btl_draw_prim_quad = 0x80267a3c
export const RAM_b_area_isk_part_2_isk_04 = 0x802201a0
export const RAM_mac_02_Quizmo_AddViewRelativeOffset = 0x80242440
export const RAM_dgb_01_pad_2 = 0x80253158
export const RAM_D_80242148_928B38 = 0x80242148
export const RAM_IES_Hammer1 = 0x8008b220
export const RAM_b_area_trd_part_2_fake_bowser_802230E8 = 0x802230e8
export const RAM_omo_09_ProjectileHitbox_GetUsableProjectileID = 0x80242358
export const RAM_mac_05_Quizmo_CreateReactionEffect = 0x80242318
export const RAM_sbk_01_npcGroup_802405E8 = 0x802405e8
export const RAM_func_80050CA0 = 0x80050ca0
export const RAM_dro_02_npcList_80244C64 = 0x80244c64
export const RAM_EF2680_FlyingAI_Loiter = 0x80240908
export const RAM_gPauseItemsCurrentScrollPos = 0x802705d4
export const RAM_update_generic_entities = 0x80123404
export const RAM_b_area_omo_groove_guy_sleepySpin = 0x80225904
export const RAM_D_80242C48_91B7F8 = 0x80242c48
export const RAM_func_80138E54 = 0x80138e54
export const RAM_b_area_trd_part_2_fake_bowser_idle_8021D508 = 0x8021d508
export const RAM_b_area_jan_spike_top_spikeTop_HandleEvent_Floor = 0x80224888
export const RAM_D_80241768_9EF358 = 0x80241768
export const RAM_dgb_01_D_80252E44_C106C4 = 0x80252e44
export const RAM_omo_04_FlyingAI_JumpInit = 0x80240f7c
export const RAM_D_80098938 = 0x80098938
export const RAM_HES_AimBlinkC = 0x802937c4
export const RAM_iwa_02_CleftAI_RevUpInit = 0x80240644
export const RAM_b_area_jan_jungle_fuzzy = 0x8021eab4
export const RAM_b_area_iwa_formationTable = 0x802185b8
export const RAM_D_800B0B34 = 0x800b0b34
export const RAM_flo_08_80245F5C = 0x80245f5c
export const RAM_D_80244890_9BB210 = 0x80244890
export const RAM_func_80112E4C = 0x80112e4c
export const RAM_b_area_kzn_red_magikoopa_8022C3BC = 0x802210fc
export const RAM_func_802C02E4 = 0x802bd3e4
export const RAM_ui_watt_pal = 0x800ff360
export const RAM_D_8010CD30 = 0x8010cd30
export const RAM_b_area_pra3_formation_01 = 0x802324d0
export const RAM_D_8015130E = 0x8015130e
export const RAM_gIsUpdatingScripts = 0x802d9ca4
export const RAM_kpa_115_ProjectileAI_Main = 0x802406cc
export const RAM_D_090025A0_388950 = 0x090025a0
export const RAM_b_area_trd_part_2_fake_bowser_8021AD68 = 0x8021ad68
export const RAM_GetActionSuccess = 0x80269294
export const RAM_b_area_arn_hyper_cleft_nextTurn_80220D3C = 0x80220d3c
export const RAM_D_802835D4 = 0x802835d4
export const RAM_filemenu_update_show_name_input_803C00 = 0x80243380
export const RAM_get_actor = 0x80269d6c
export const RAM_D_09002E98 = 0x09002e98
export const RAM_D_E00D6E40 = 0xe00d6e40
export const RAM_D_E0046630 = 0xe0046630
export const RAM_bulb_glow_update = 0xe007812c
export const RAM_HES_Item_Unused_0E7_disabled = 0x80085aa8
export const RAM_entity_HeartBlockContent_anim_beating = 0x802e5324
export const RAM_b_area_arn_hypergoomba_idle_802198FC = 0x802198fc
export const RAM_vtx_C25D30 = 0x80204df0
export const RAM_osFlashWriteBuffer = 0x8006f550
export const RAM_dup_mac_01_PatrolAI_PostLoiter = 0x802416fc
export const RAM_b_area_kmr_part_3_spiked_goomba_partsTable_8021A448 = 0x8021a448
export const RAM_b_area_isk_part_2_tutankoopa_idle_8021BE28 = 0x8021be28
export const RAM_b_area_kmr_part_3_kmr_03_clouds1 = 0x80232330
export const RAM_beforeFlag = 0x800773fc
export const RAM_battle_item_pebble_EatItem = 0x802a170c
export const RAM_func_8025D830 = 0x8025d830
export const RAM_D_80245390_EEB940 = 0x80245390
export const RAM_HES_Item_Unused_127 = 0x80086378
export const RAM_dgb_13_80240610 = 0x80240610
export const RAM_func_80219FFC_6A11FC = 0x80219ffc
export const RAM_EA0C10_GetItemName = 0x80242730
export const RAM_omo_11_SuperBlock_AnimateEnergyOrbs = 0x80240d90
export const RAM_sam_04_foliage_setup_shear_mtx = 0x80240000
export const RAM_dro_01_EVS_Quizmo_GiveItem_16 = 0x802451ec
export const RAM_b_area_omo_red_shy_guy_defenseTable_80219210 = 0x80219210
export const RAM_arn_02_CleftAI_ReturnHome = 0x80240a30
export const RAM_b_area_kmr_part_3_mage_jr_troopa_idle_8022ADA4 = 0x8022ada4
export const RAM_battle_star_peach_beam_802A2E2C = 0x802a2e2c
export const RAM_func_80240000_ACE140 = 0x80240000
export const RAM_b_area_kmr_part_2_blue_goomba_init_80219A4C = 0x80219a4c
export const RAM_dgb_01_init_80245784 = 0x80245784
export const RAM_mac_02_PatrolAI_Loiter = 0x80240df0
export const RAM_nok_14_set_script_owner_npc_anim = 0x802401c0
export const RAM_kpa_50_TackleAI_Main = 0x802415e0
export const RAM_dust_appendGfx = 0xe002a3c4
export const RAM_D_E0082D60 = 0xe0082d60
export const RAM_b_area_pra3_duplighost_bow_takeTurn = 0x80221cfc
export const RAM_b_area_pra3_duplighost_lakilester_parts = 0x80225310
export const RAM_b_area_pra3_duplighost_ActorJumpToPos = 0x80218890
export const RAM_EC2200_DeadUnkFunc1 = 0x80240000
export const RAM_flo_11_Pipe_GetCurrentFloor = 0x80240020
export const RAM_trd_03_set_script_owner_npc_anim = 0x802400a0
export const RAM_kpa_09_UnkNpcAIFunc48 = 0x80240290
export const RAM_kmr_03_searchBush = 0x802417f0
export const RAM_dup_hos_05_UpdateTexturePanStepped = 0x802450dc
export const RAM_D_802E9B94 = 0x802e9b94
export const RAM_NAMESPACE_handleEvent_802387F0 = 0x802387f0
export const RAM_sam_11_Quizmo_NPC_OnRender = 0x80240b9c
export const RAM_draw_merlee_message = 0x80045ed0
export const RAM_mac_01_LetterDelivery_SavedNpcAnim = 0x80262c34
export const RAM_D_802B6E68_E2B518 = 0x802b6e68
export const RAM_gPauseMenuCurrentTab = 0x802700d4
export const RAM_HES_Item_RepelGel_disabled = 0x80082688
export const RAM_dgb_15_802418E0 = 0x802418e0
export const RAM_dgb_18_PatrolAI_Chase = 0x8024094c
export const RAM_b_area_trd_part_1_trd_05e = 0x802274a0
export const RAM_tik_05_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_isk_part_1_stone_chomp_80221794 = 0x80221794
export const RAM_arn_05_PatrolAI_Loiter = 0x802404c0
export const RAM_D_8010C9CC = 0x8010c9cc
export const RAM_b_area_sam2_paragoomba_idleAnimations_8021B3DC = 0x8021c15c
export const RAM_ui_battle_cont_c_up_up_png = 0x8028bec0
export const RAM_func_8026E914 = 0x8026e914
export const RAM_D_8010C974 = 0x8010c974
export const RAM_b_area_pra3_formation_0A = 0x80232770
export const RAM_omo_17_ShyGuyWanderAI_17 = 0x80240738
export const RAM_gMsgSpeechBoxLQuad = 0x8014c3c0
export const RAM_dgb_01_npcGroup_80246660 = 0x80246660
export const RAM_D_802EA310 = 0x802ea310
export const RAM_shim_set_screen_overlay_center_worldpos = 0xe02005e0
export const RAM_MarioEnterStage = 0x80284a40
export const RAM_omo_10_UnkFloatFunc = 0x80240388
export const RAM_ui_input_start_text_png = 0x800fa7a0
export const RAM_D_802424F0_D95430 = 0x802424f0
export const RAM_dead_evt_get_variable = 0x802d4e8c
export const RAM_D_8027037C = 0x8027037c
export const RAM_battle_item_dizzy_dial_UseItem = 0x802a16d4
export const RAM_func_8024082C_B662DC = 0x8024082c
export const RAM_D_802B6794 = 0x802b6794
export const RAM_b_area_isk_part_1_isk_02_foregroundModelList_802239FC = 0x802239fc
export const RAM_D_E00C6CC8 = 0xe00c6cc8
export const RAM_func_80218350_4B1540 = 0x80218350
export const RAM_IES_JellyShroom = 0x8008d514
export const RAM_D_802EB028 = 0x802eb028
export const RAM_b_area_jan_spear_guy_8021B96C = 0x8021b96c
export const RAM_battle_item_egg_missile_func_802A123C_71CF1C = 0x802a123c
export const RAM_GetItemPower = 0x802d732c
export const RAM_b_area_pra3_swoopula_knockDown = 0x802276a4
export const RAM_D_8024BCF8_C09578 = 0x8024bcf8
export const RAM_func_80238328_707FC8 = 0x80238328
export const RAM_D_8014BD18 = 0x8014bd18
export const RAM_merlin_house_stars_init = 0xe00a611c
export const RAM_HES_Item_BlandMeal_disabled = 0x80082c88
export const RAM_D_802A3E88_7214E0 = 0x802a3e90
export const RAM_b_area_omo2_general_guy_statusTable = 0x8021833c
export const RAM_func_80218220_6DBFC0 = 0x80218220
export const RAM_b_area_iwa_cleft_idle_80218934 = 0x80218934
export const RAM_kzn_02_FlyingAI_ChaseInit = 0x802411a0
export const RAM_vtx_C2C130 = 0x8020b1f0
export const RAM_func_802403F0_BDABA0 = 0x802403f0
export const RAM_D_802B79B8_E21868 = 0x802b79b8
export const RAM_EDEA00_FlyingAI_Chase = 0x80240fb8
export const RAM_dgb_02_extraAnimationList_80241B6C = 0x80241b6c
export const RAM_D_E0046638 = 0xe0046638
export const RAM_D_8024AAB0_A34CF0 = 0x8024aab0
export const RAM_dup3_b_area_pra_StartRumbleWithParams = 0x80218fa0
export const RAM_b_area_pra2_crystal_king_OnHit = 0x8021952c
export const RAM_D_80244030_BA4760 = 0x80244030
export const RAM_au_BGMCmd_EB_SubTrackReverb = 0x8004ff58
export const RAM_hos_05_AwaitScriptComplete = 0x80240604
export const RAM_EA8AE0_LetterDelivery_SaveNpcAnim = 0x80241b50
export const RAM_nok_02_PatrolAI_PostLoiter = 0x802410fc
export const RAM_ui_bow_disabled_pal = 0x800ff140
export const RAM_b_area_pra2_crystal_king_formation_bit_1 = 0x8021d5e8
export const RAM_b_area_omo3_ArcsinDeg = 0x802186f0
export const RAM_ui_battle_swap_Z_png = 0x8028a180
export const RAM_func_802180C8_4CF278 = 0x802180c8
export const RAM_arn_10_80240440 = 0x80240440
export const RAM_PlayEatFX = 0x802878d0
export const RAM_ALT_virtual_entity_create = 0x802d42ac
export const RAM_entity_numEntities = 0x801512c0
export const RAM_D_80247B00_EE64C0 = 0x80247b00
export const RAM_HES_Item_AttackFXD = 0x80085b98
export const RAM_sbk_30_StationaryAI_LosePlayer = 0x80240868
export const RAM_dgb_08_enterDoubleDoor_80243EF8 = 0x80243ef8
export const RAM_get_default_variation_for_song = 0x8014a430
export const RAM_pink_sparkles_init = 0xe01242fc
export const RAM_battle_item_sleepy_sheep_modelCommandList = 0x802a3260
export const RAM_trd_01_UnkDistFunc = 0x8024046c
export const RAM_jan_03_foliage_setup_shear_mtx = 0x80243050
export const RAM_D_802E99DC = 0x802e99dc
export const RAM_tik_23_Pipe_GetCameraYaw = 0x802401d0
export const RAM_D_8023D2C4 = 0x8023d2c4
export const RAM_battle_item_hustle_drink_func_802A12C4_726224 = 0x802a12c4
export const RAM_dgb_09_SentinelAI_ChaseInit = 0x8024291c
export const RAM_D_80270050 = 0x80270050
export const RAM_nuPiReadRomOverlay = 0x800600e0
export const RAM_D_802452B4_C7BF94 = 0x802452b4
export const RAM_b_area_kpa3_UnkFunc62 = 0x802180d0
export const RAM_sam_10_MeleeHitbox_33 = 0x80240584
export const RAM_tik_14_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_flo_16_npcGroup_802442E4 = 0x802442e4
export const RAM_b_area_kmr_part_2_goomba_king_nextTurn_802229C4 = 0x802229c4
export const RAM_mac_01_LetterDelivery_RestoreNpcAnim = 0x802440bc
export const RAM_dgb_02_MeleeHitbox_33 = 0x802401b4
export const RAM_D_802EB120 = 0x802eb120
export const RAM_b_area_omo_sky_guy_StartRumbleWithParams = 0x80218db0
export const RAM_HES_Item_DizzyAttack = 0x800850b8
export const RAM_D_090021E0_352400 = 0x090021e0
export const RAM_D_E00C6CC0 = 0xe00c6cc0
export const RAM_player_get_slip_vector = 0x800df3fc
export const RAM_HES_Item_FriedShroom = 0x80082a18
export const RAM_EB8E90_PatrolAI_MoveInit = 0x80240040
export const RAM_rumbleButtons = 0x80074264
export const RAM_spr_get_notify_value = 0x802de5c8
export const RAM_func_802185D4_5CEC64 = 0x802185d4
export const RAM_lookup_status_chance = 0x80265e98
export const RAM_fx_sun_undeclared = 0x80072950
export const RAM_mac_01_TransformFoliage = 0x80246500
export const RAM_func_802823B0 = 0x802823b0
export const RAM_arn_08_makeEntities = 0x80241850
export const RAM_func_80243644_C7A324 = 0x80243644
export const RAM_dup_hos_10_UpdateTexturePanSmooth = 0x80240460
export const RAM_D_80246D40_C46AF0 = 0x80246d40
export const RAM_get_packed_buttons = 0x800e22e4
export const RAM_ui_move_partner_2_disabled_pal = 0x80103f40
export const RAM_gPauseMsg_48 = 0x8026f718
export const RAM_IES_YoshiCookie = 0x8008d4f4
export const RAM_D_800AF890 = 0x800af894
export const RAM_D_80098270 = 0x80098270
export const RAM_func_80239140_70CE50 = 0x80239140
export const RAM_D_09000000_330F00 = 0x09000000
export const RAM_dgb_maps = 0x80092450
export const RAM_tik_15_UpdateTexturePanStepped = 0x80240404
export const RAM_func_8005DD54 = 0x8005dd54
export const RAM_b_area_kzn_medi_guy_handleEvent_8021FC60 = 0x8021fc60
export const RAM_dgb_01_SentinelAI_ChaseInit = 0x80241618
export const RAM_IES_ITEM_12E = 0x8008d794
export const RAM_b_area_jan_jungle_fuzzy_handleEvent_8021EB9C = 0x8021eb9c
export const RAM_D_802367F0 = 0x802367f0
export const RAM_D_802A9964_427334 = 0x802a9964
export const RAM_b_area_sam_duplighost_returnHome = 0x8021b120
export const RAM_tik_19_Pipe_AwaitDownInput = 0x80240034
export const RAM_au_copy_bytes = 0x80054f48
export const RAM_b_area_omo_formation_25 = 0x802304c8
export const RAM_world_goombaria_put_away = 0x802bd244
export const RAM_func_802A9000_430020 = 0x802a9000
export const RAM_world_bow_put_away = 0x802be038
export const RAM_tik_07_SuperBlock_UpgradeOrbAngles = 0x80243a48
export const RAM_world_goompa_init = 0x802bd100
export const RAM_D_E00C49D4 = 0xe00c49d4
export const RAM_D_E0030EB4 = 0xe0030eb4
export const RAM_HES_Item_ShroomCake_disabled = 0x80082aa8
export const RAM_D_802434B8_A63E88 = 0x802434b8
export const RAM_trd_04_UnkNpcAIFunc12 = 0x80240660
export const RAM_b_area_trd_part_2_green_ninja_koopa_intTable_80227DF0 = 0x80227df0
export const RAM_mgm_00_PatrolAI_LoiterInit = 0x802406e0
export const RAM_b_area_iwa_monty_mole_1 = 0x8021a574
export const RAM_trd_10_StarSpiritEffectFunc3 = 0x802401ac
export const RAM_VirtualEntityMoveTo = 0x802d3a60
export const RAM_b_area_sam_duplighost_copyPartner = 0x80227420
export const RAM_EDEA00_FlyingAI_ChaseInit = 0x80240e84
export const RAM_HES_Whirlwind2 = 0x802aa728
export const RAM_D_E008AA70 = 0xe008aa70
export const RAM_func_80240A84_A401C4 = 0x80240a84
export const RAM_D_80098930 = 0x80098930
export const RAM_D_E0070D60 = 0xe0070d60
export const RAM_kpa_115_ProjectileHitbox_33 = 0x80240688
export const RAM_D_80241F10_AF4BB0 = 0x80241f10
export const RAM_func_80240118_90CD58 = 0x80240118
export const RAM_mgm_01_HideCoinCounter = 0x80241760
export const RAM_flo_09_802431E4 = 0x802431e4
export const RAM_ui_battle_swap_Z_pal = 0x8028a200
export const RAM_gCurrentBattleSection = 0x800dc4ea
export const RAM_D_E0088CC0 = 0xe0088cc0
export const RAM_dro_01_npcAISettings_80245010 = 0x80245010
export const RAM_EnableActorBlur = 0x8027d57c
export const RAM_b_area_kzn_spike_top_init_8021CAAC = 0x8021caac
export const RAM_jan_10_TransformFoliage = 0x802412f0
export const RAM_D_E01187B0 = 0xe01187b0
export const RAM_b_area_iwa_cleft_statusTable_80218740 = 0x80218740
export const RAM_dead_get_current_map_settings = 0x8005f128
export const RAM_func_800F4CF0 = 0x800f4cf0
export const RAM_gCurrentEntityListPtr = 0x80151470
export const RAM_shimmer_burst_appendGfx = 0xe0070740
export const RAM_b_area_sam_gray_magikoopa_StartRumbleWithParams = 0x80219a90
export const RAM_ShakeCamX = 0x802d9ce8
export const RAM_trd_04_PatrolAI_Jump = 0x80241428
export const RAM_arn_11_defeat_802409DC = 0x802409dc
export const RAM_obk_07_SomeItemEntityFunc = 0x80240118
export const RAM_D_802E91F0 = 0x802e91f0
export const RAM_D_802A4350 = 0x802a4350
export const RAM_draw_hud_element_internal = 0x80144298
export const RAM_battle_partner_kooper_handleEvent_802385E0 = 0x802385e0
export const RAM_battle_item_electro_pop_func_802A127C_73081C = 0x802a127c
export const RAM_D_8024F688_C0CF08 = 0x8024f688
export const RAM_HES_AimMarkerG = 0x802930ac
export const RAM_dro_01_8024797C = 0x8024797c
export const RAM_b_area_trd_part_1_trd_05_afterBattle_80224B30 = 0x80224b30
export const RAM_dgb_01_D_80253360_C10BE0 = 0x80253360
export const RAM_sbk_36_SpawnSunEffect = 0x80240000
export const RAM_b_area_trd_part_1_trd_03_clouds1 = 0x80223b80
export const RAM_dro_01_PatrolAI_ChaseInit = 0x80240dfc
export const RAM_MultiplyVec2ByActorScale = 0x80254048
export const RAM_create_status_icon_surprise = 0x80047f58
export const RAM_sam_05_MeleeHitbox_31 = 0x8024006c
export const RAM_D_09001658 = 0x09001658
export const RAM_OnDefeatEnemy = 0x8003f580
export const RAM_func_80028838 = 0x80028838
export const RAM_D_80244E28_A1B048 = 0x80244e28
export const RAM_D_8024BE28_C096A8 = 0x8024be28
export const RAM_free_npc = 0x800389cc
export const RAM_dup_mac_01_PatrolAI_ChaseInit = 0x8024189c
export const RAM_flo_09_802422BC = 0x802422bc
export const RAM_D_80246F18_DC41C8 = 0x80246f18
export const RAM_D_8026F778 = 0x8026f778
export const RAM_func_802A16F4_7907C4 = 0x802a16f4
export const RAM_omo_13_GetItemName = 0x802412e4
export const RAM_osWritebackDCache = 0x80061850
export const RAM_tattle_window_render = 0xe00d825c
export const RAM_func_80269524 = 0x80269524
export const RAM_pause_items_init = 0x8024a714
export const RAM_butterflies_init = 0xe00aa104
export const RAM_D_802441B8_EA4AB8 = 0x802441b8
export const RAM_jan_10_foliage_setup_shear_mtx = 0x80241280
export const RAM_func_802406D4_B1D124 = 0x802406d4
export const RAM_mac_06_resolve_npc = 0x802404c0
export const RAM_b_area_pra3_pra_03b_beforeBattle = 0x80231fa0
export const RAM_D_8026FEE8 = 0x8026fee8
export const RAM_battle_menu_centeredMessagesC = 0x802ab4dc
export const RAM_dro_02_Quizmo_SetCamVfov = 0x80240e6c
export const RAM_pra_02_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_802BE0C4 = 0x802be0c4
export const RAM_battle_item_pow_block_func_802A12E4_718B34 = 0x802a12e4
export const RAM_func_A4000874 = 0xa4000874
export const RAM_b_area_kzn_white_magikoopa = 0x80223d20
export const RAM_D_8038F800 = 0x8038f800
export const RAM_D_802EB128 = 0x802eb128
export const RAM_hud_element_create_transform_B = 0x80144fd8
export const RAM_b_area_nok_spiked_goomba_handleEvent_8021E874 = 0x8021e874
export const RAM_pra_14_varStash = 0x80241930
export const RAM_IES_MagicMirror = 0x8008d754
export const RAM_flo_13_npcAISettings_80244210 = 0x80244210
export const RAM_kmr_02_ToadHouse_CamSetFOV = 0x80243134
export const RAM_mac_05_Quizmo_ShowEntities = 0x8024144c
export const RAM_kmr_11_UnkNpcPosFunc = 0x80240ddc
export const RAM_HES_HeaderParty = 0x8024206c
export const RAM_iwa_03_StashVars = 0x80240000
export const RAM_flo_13_UnkFunc42 = 0x80243368
export const RAM_sam_06_ToadHouse_InitScreenOverlay = 0x80242164
export const RAM_dup_kkj_10_UnkModelMatrixFunc = 0x80240ab0
export const RAM_enable_npc_shadow = 0x8003aba0
export const RAM_end_maps = 0x80093410
export const RAM_entity_BlueWarpPipe_rise_up = 0x802e89f8
export const RAM_entity_HeartBlockContent_reset_data = 0x802e4de0
export const RAM_func_80240D54_A2AF94 = 0x80240d54
export const RAM_b_area_pra2_crystal_bit_init = 0x802206bc
export const RAM_ui_status_pow_star_6_png = 0x80100a40
export const RAM_battle_action_cmd_water_block_2_pal = 0x802aa3c0
export const RAM_IES_NuttyCake = 0x8008d494
export const RAM_func_8026E9A0 = 0x8026e9a0
export const RAM_kmr_20_Pipe_GetPointAheadOfPlayer = 0x8024026c
export const RAM_D_80078DB6 = 0x80078db6
export const RAM_battle_item_fire_flower_UseItemWithEffect = 0x802a1400
export const RAM_flo_14_FlyingAI_WanderInit = 0x80240b10
export const RAM_D_09002B60 = 0x09002b60
export const RAM_obk_03_StashVars = 0x80240170
export const RAM_hos_03_Quizmo_SpinPartner = 0x80243324
export const RAM_tik_03_Pipe_SetAnimFlag = 0x80240000
export const RAM_nok_11_FlyingAI_Chase = 0x802415c8
export const RAM_func_802A97AC = 0x802a97ac
export const RAM_D_8010CD38 = 0x8010cd38
export const RAM_partner_resume_ability_script = 0x800eb860
export const RAM_HES_Item_DeluxeFeast_disabled = 0x80082d48
export const RAM_D_80109890 = 0x80109890
export const RAM_dgb_03_exitDoubleDoor_802429C4 = 0x802429c4
export const RAM_func_802426A4_9C7A84 = 0x802426a4
export const RAM_popup_menu_update = 0x800f1a10
export const RAM_battle_item_snowman_doll_FadeBackgroundToBlack = 0x802a1320
export const RAM_D_8009BB30 = 0x8009bb30
export const RAM_omo_16_UnkFloatFunc = 0x80240388
export const RAM_get_game_mode = 0x80033528
export const RAM_kzn_08_PiranhaPlantAI_10 = 0x80242f74
export const RAM_dro_01_EVS_Quizmo_SetCharacterPositons = 0x80245a84
export const RAM_viMgrMain = 0x80066c10
export const RAM_func_802402D0_A78790 = 0x802402d0
export const RAM_entity_HeartBlockContent_anim_heal = 0x802e548c
export const RAM_dup_sam_11_Set80151310_0 = 0x80240554
export const RAM_flo_17_UnkDistFunc = 0x802421c0
export const RAM_dgb_13_entryList = 0x80240000
export const RAM_D_80255BA0 = 0x80255ba0
export const RAM_D_802402B0_A93410 = 0x802402b0
export const RAM_IES_StarStone = 0x8008bb04
export const RAM_mac_00_PatrolAI_Move = 0x802426c8
export const RAM_landing_dust_update = 0xe000c654
export const RAM_b_area_trd_part_2_fake_bowser_8021C594 = 0x8021c594
export const RAM_D_E008AA78 = 0xe008aa78
export const RAM_arn_07_enterWalk_8024346C = 0x8024346c
export const RAM_EB8E90_PatrolNoAttackAI_15 = 0x80240b14
export const RAM_gSpeakingActorPrintCtx = 0x8029fa60
export const RAM_dro_02_80243AF0 = 0x80243af0
export const RAM_dup_mac_01_PatrolAI_Jump = 0x80241824
export const RAM_func_802420EC_8EDF0C = 0x802420ec
export const RAM_func_8013B1B0 = 0x8013b1b0
export const RAM_gPauseDLBannerFp = 0x8026ec08
export const RAM_D_09000180_3925C0 = 0x09000180
export const RAM_func_802BD100_317E50 = 0x802bd100
export const RAM_D_802ECBF0 = 0x802ecbf0
export const RAM_func_80218038_557418 = 0x80218038
export const RAM_update_camera_zone_interp = 0x80031494
export const RAM_func_80045838 = 0x80045838
export const RAM_initialize_jump = 0x802b6000
export const RAM_flo_14_pad_4F64 = 0x80244f64
export const RAM_func_802409E0_B45150 = 0x802409e0
export const RAM_title_screen_draw_copyright = 0x80037e14
export const RAM_func_E00C844C = 0xe00c844c
export const RAM_func_802A981C = 0x802a981c
export const RAM_D_80244340_BE7AF0 = 0x80244340
export const RAM_b_area_pra3_formation_09 = 0x8023271c
export const RAM_b_area_sam2_paragoomba_idle_8021B434 = 0x8021c1b4
export const RAM_D_8029FA90 = 0x8029fa90
export const RAM_vtx_C23F10 = 0x80202fd0
export const RAM_D_E0126C10 = 0xe0126c10
export const RAM_OverrideBattleDmaDest = 0x802535f8
export const RAM_func_802BBCC0_E2E5F0 = 0x802bbcc0
export const RAM_EDEA00_UnkDistFunc = 0x8024236c
export const RAM_b_area_kgr_fuzzipede_idleAnimations_802189B0 = 0x802189b0
export const RAM_pause_partners_draw_movelist_flower = 0x8024c0a0
export const RAM_AdjustCam = 0x802cc660
export const RAM_kkj_20_ToadHouse_Stub = 0x802405b4
export const RAM_dgb_09_npcSettings_802448F4 = 0x802448f4
export const RAM_btl_state_update_end_training_battle = 0x80244710
export const RAM_dgb_16_ClubbaNappingAI_Main = 0x80240e20
export const RAM_npc_remove_decoration = 0x8003c428
export const RAM_fright_jar_render = 0xe00c21cc
export const RAM_b_area_kgr_fuzzipede_80218FC8 = 0x80218fc8
export const RAM_D_802473E0_C680F0 = 0x802473e0
export const RAM_mim_07_SetForeverForestFog = 0x80240070
export const RAM_dgb_09_pad_3FF8 = 0x80243ff8
export const RAM_D_8022AD80_4C3F70 = 0x8022ad80
export const RAM_b_area_mac_master3_80230240 = 0x80230240
export const RAM_end_00_Font3Patterns = 0x80242cd8
export const RAM_D_800A0930 = 0x800a0930
export const RAM_jan_02_Quizmo_AddViewRelativeOffset = 0x80242294
export const RAM_HES_Item_PowerPlus_disabled = 0x80083ac8
export const RAM_flo_00_802485A8 = 0x802485a8
export const RAM_func_8021848C_660BDC = 0x8021848c
export const RAM_D_801568E8 = 0x801568e8
export const RAM_b_area_kmr_part_3_jr_troopa_nextTurn_80223D2C = 0x80223d2c
export const RAM_tik_10_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_802A27D0 = 0x802a27d0
export const RAM_dro_02_EVS_Quizmo_RightAnswer = 0x802463c0
export const RAM_func_802402BC_8D878C = 0x802402bc
export const RAM_sam_02_ToadHouse_Stub = 0x80240b24
export const RAM_bgm_poll_music_events = 0x8005608c
export const RAM_D_802A203C_73E89C = 0x802a203c
export const RAM_func_80261B40 = 0x80261b40
export const RAM_battle_star_peach_dash_UnkStarFunc3 = 0x802a116c
export const RAM_b_area_trd_part_1_formation_04 = 0x80218570
export const RAM_HES_Item_RightOn_disabled = 0x800849c8
export const RAM_mim_06_PiranhaPlantAI_11 = 0x802419e8
export const RAM_D_802AD4B2 = 0x802ad4b2
export const RAM_sam_02_Quizmo_RenderInit = 0x80240e8c
export const RAM_jan_12_MeleeHitbox_31 = 0x80240740
export const RAM_dro_02_EVS_Quizmo_PlayerHitBuzzer = 0x80245720
export const RAM_D_09000200_3865B0 = 0x09000200
export const RAM_dro_01_Quizmo_MobileAISettings = 0x80247738
export const RAM_D_802427BC_89235C = 0x802427bc
export const RAM_gPausePanelPartners = 0x8024f88c
export const RAM_D_80244B30_A10820 = 0x80244b30
export const RAM_func_80269470 = 0x80269470
export const RAM_b_area_trd_part_1_bullet_bill_defenseTable_80218F60 = 0x80218f60
export const RAM_b_area_kmr_part_2_red_goomba_2_sinTable = 0x8021f984
export const RAM_b_area_jan_jungle_fuzzy_defenseTable_8021E9D0 = 0x8021e9d0
export const RAM_arn_04_PatrolAI_LoiterInit = 0x80240400
export const RAM_D_8029D3E8 = 0x8029d3e8
export const RAM_b_area_pra3_pra_02_80231C10 = 0x80231c10
export const RAM_suspend_group_script = 0x802c42d8
export const RAM_HES_Item_Map = 0x80081428
export const RAM_obk_01_AddPlayerHandsOffset = 0x80240954
export const RAM_kpa_09_ProjectileHitbox_31 = 0x802404d4
export const RAM_osViSetEvent = 0x80066db0
export const RAM_HES_Item_HeartPoint = 0x800811a0
export const RAM_flo_21_init_802437A8 = 0x802437a8
export const RAM_b_area_kmr_part_3_mage_jr_troopa_idleAnimations_8022A594 = 0x8022a594
export const RAM_obk_01_LetterDelivery_CalcLetterPos = 0x80240d3c
export const RAM_arn_04_PatrolAI_MoveInit = 0x80240000
export const RAM_D_8024F580_852150 = 0x8024f580
export const RAM_flo_10_func_80240A98_CB9C98 = 0x80240a98
export const RAM_mac_01_foliage_setup_shear_mtx = 0x80246490
export const RAM_b_area_mac_lee_goombario = 0x8021ea74
export const RAM_pause_spirits_bg_png = 0x8026a2b0
export const RAM_ui_status_text_5_png = 0x801002a0
export const RAM_gPauseWS_1 = 0x8026f938
export const RAM_ui_battle_status_dizzy_0_pal = 0x80102160
export const RAM_b_area_pra3_duplighost_defenseTable = 0x80219f74
export const RAM_b_area_omo2_2_stilt_guy_GetSelectedMoveID = 0x80231000
export const RAM_D_80242410 = 0x80242410
export const RAM_MAMAR_song_variation = 0x80420474
export const RAM_SetIdleGoal = 0x8026a62c
export const RAM_b_area_jan2_jan_04_afterBattle_8021F4E0 = 0x8021f4e0
export const RAM_func_802189FC_5CF08C = 0x802189fc
export const RAM_EE70C0_FlyingAI_ChaseInit = 0x802417d0
export const RAM_b_area_sam2_sam_03_UnkFloatFunc2 = 0x80219d3c
export const RAM_func_80053BA8 = 0x80053ba8
export const RAM_D_80248850 = 0x80248850
export const RAM_sleep_bubble_update = 0xe00520f0
export const RAM_fx_quizmo_assistant = 0x80072890
export const RAM_D_00E648D0 = 0x00e648d0
export const RAM_b_area_omo_formationTable = 0x80230730
export const RAM_func_80241098_865BD8 = 0x80241098
export const RAM_kzn_07_FlyingAI_JumpVels = 0x80242b90
export const RAM_func_8024175C_D7010C = 0x8024175c
export const RAM_b_area_pra3_duplighost_bow_idleAnimations = 0x8022159c
export const RAM_blend_background_channel = 0x80145e98
export const RAM_b_area_mim_bzzap = 0x8021a9fc
export const RAM_D_802463B8_kkj_19 = 0x802463b8
export const RAM_b_area_kgr_fuzzipede_802191AC = 0x802191ac
export const RAM_D_80294360 = 0x80294360
export const RAM_func_802BE280_31EFF0 = 0x802be280
export const RAM_flo_00_80243368 = 0x80243368
export const RAM_flo_13_npcGroup_80246814 = 0x80246814
export const RAM_b_area_jan_medi_guy = 0x80222680
export const RAM_b_area_kmr_part_1_goomba_sinTable = 0x8021a194
export const RAM_squirt_render = 0xe00b2460
export const RAM_dup2_hos_06_GetItemName = 0x802403c4
export const RAM_D_8014F988 = 0x8014f988
export const RAM_b_area_iwa_iwa_01_IsSaveVar123 = 0x80218170
export const RAM_flo_09_tree1 = 0x80243414
export const RAM_nuGfxCfb = 0x8009a658
export const RAM_isk_14_StoneChompAI_ChaseInit = 0x80240ea8
export const RAM_IES_Refund = 0x8008c464
export const RAM_action_command_body_slam_free_hud_elements = 0x802a9708
export const RAM_D_E0126C18 = 0xe0126c18
export const RAM_trd_01_StationaryAI_Idle = 0x80240c78
export const RAM_HES_Item_BakingSugar = 0x80087218
export const RAM_pra_05_SetPartnerFlags20000 = 0x80240eec
export const RAM_jan_08_SuperBlock_HideBlockContent = 0x8024069c
export const RAM_ui_status_pow_star_5_pal = 0x80100a20
export const RAM_entity_TriggerBlock_play_vanish_effect = 0x802e405c
export const RAM_set_part_goal_to_actor_part = 0x802699d8
export const RAM_set_screen_overlay_center_worldpos = 0x80137ec0
export const RAM_EA36E0_UnkFunc48 = 0x80242f64
export const RAM_b_area_nok_kent_c_koopa_StartRumbleWithParams = 0x80218b50
export const RAM_flo_19_80242A2C = 0x80242a2c
export const RAM_D_802AD028 = 0x802ad028
export const RAM_HES_PressStartButton = 0x80108d04
export const RAM_dgb_03_UnkFunc12 = 0x802400bc
export const RAM_animator_make_mirrorZ = 0x8011dfd0
export const RAM_pra_29_reflection_setup_floor = 0x80240500
export const RAM_D_801595C0 = 0x801595c0
export const RAM_gPauseTutorialSpriteAnims = 0x8024f0cc
export const RAM_b_area_hos_stageTable = 0x80225f88
export const RAM_dro_01_interact_802480E0 = 0x802480e0
export const RAM_hos_01_UnkEffect0FFunc = 0x80240120
export const RAM_b_area_pra3_red_magikoopa_8022C93C = 0x8022c93c
export const RAM_b_area_mac_chan_takeTurn_8021B81C = 0x8021b81c
export const RAM_fx_65_update = 0xe00ca248
export const RAM_dgb_18_802436E4 = 0x802436e4
export const RAM_gPausePartnersSelectedMove = 0x802706a4
export const RAM_func_80241B90_844760 = 0x80241b90
export const RAM_trd_07_TackleAI_Main = 0x802406a4
export const RAM_ending_decals_appendGfx = 0xe0068234
export const RAM_entity_HeartBlock_show_tutorial_message = 0x802e54cc
export const RAM_dro_01_interact_802498E8 = 0x802498e8
export const RAM_debuff_update = 0xe0062220
export const RAM_func_802405EC_E1EA4C = 0x802405ec
export const RAM_ui_parakarry_disabled_pal = 0x800fef00
export const RAM_b_area_mac_lee_UnkEffect6CFunc = 0x80218d90
export const RAM_GetDamageIntensity = 0x80280840
export const RAM_func_802A9310 = 0x802a9310
export const RAM_b_area_hos_goombario_tutor_init_80219160 = 0x80219160
export const RAM_D_E0070D68 = 0xe0070d68
export const RAM_battle_star_lullaby_UnkStarFunc2 = 0x802a10ac
export const RAM_remove_actor_decoration = 0x80267234
export const RAM_pra_39_reflection_unk_resolve_anim = 0x80240050
export const RAM_D_80242430_B22970 = 0x80242430
export const RAM_dead_gGameStatusPtr = 0x80079430
export const RAM_func_80241120_9406D0 = 0x80241120
export const RAM_func_80243910 = 0x80243910
export const RAM_jan_03_PatrolAI_LoiterInit = 0x80240a20
export const RAM_D_80245308_CDC5E8 = 0x80245308
export const RAM_suspend_all_script = 0x802c4420
export const RAM_kpa_91_ToadHouse_CamSetFOV = 0x802415ec
export const RAM_func_E00D227C = 0xe00d227c
export const RAM_iwa_00_StashVars = 0x80240000
export const RAM_dro_01_EVS_Quizmo_NPC_OtherAI = 0x80247628
export const RAM_kzn_03_LetterDelivery_CalcLetterPos = 0x80242af8
export const RAM_b_area_trd_part_1_formation_0D = 0x80218880
export const RAM_D_802ACC64 = 0x802acc64
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_80228574 = 0x80228574
export const RAM_nok_11_set_script_owner_npc_col_height = 0x80240314
export const RAM_pra_18_ReflectFloor = 0x8024049c
export const RAM_btl_cam_is_moving_done = 0x8024e584
export const RAM_D_E00E6898 = 0xe00e6898
export const RAM_start_bounce_b = 0x800e608c
export const RAM_dgb_14_entryList = 0x80240000
export const RAM_landing_dust_init = 0xe000c64c
export const RAM_b_area_arn_tubba_blubba_idleAnimations_80225C40 = 0x80225c40
export const RAM_D_09000620_3D0110 = 0x09000620
export const RAM_omo_04_UnsetCamera0Flag1000 = 0x80241e20
export const RAM_gSpinHistoryPosAngle = 0x8010f6b8
export const RAM_quizmo_assistant_main = 0xe011c000
export const RAM_func_80242870_8EE690 = 0x80242870
export const RAM_func_80240854_9B1FE4 = 0x80240854
export const RAM_dead_sfx_adjust_env_sound_pos = 0x8015704c
export const RAM_battle_item_shooting_star_func_802A1388_71DAF8 = 0x802a1388
export const RAM_b_area_sam_duplighost_UnkActorPosFunc = 0x80218d64
export const RAM_arn_08_func_802400C0_BF4860 = 0x802400c0
export const RAM_tik_12_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_kzn_03_GetNpcCollisionHeight = 0x802426b4
export const RAM_func_802A45D8 = 0x802a45d8
export const RAM_fx_steam_burst = 0x800709d0
export const RAM_D_802EAAE0 = 0x802eaae0
export const RAM_show_recovery_shimmer = 0x802d74c0
export const RAM_D_802BEB40_31CB60 = 0x802beb40
export const RAM_flo_08_npcAISettings_8024300C = 0x8024300c
export const RAM_b_area_kmr_part_2_kmr_03_foregroundModelList_80224B70 = 0x80224b70
export const RAM_b_area_kmr_part_1_kmr_03 = 0x8022021c
export const RAM_fx_bulb_glow = 0x80070f10
export const RAM_hide_popup_menu = 0x800f13b0
export const RAM_ui_move_partner_3_pal = 0x80104160
export const RAM_AmbientSoundData = 0x8015c7c0
export const RAM_arn_13_npcSettings_802404CC = 0x802404cc
export const RAM_siMgrThread = 0x8009e710
export const RAM_kgr_01_DarkRoomUpdate = 0x80240000
export const RAM_dust_init = 0xe002a284
export const RAM_nok_02_PatrolAI_ChaseInit = 0x8024129c
export const RAM_kmr_04_CheckPartnerFlags1000 = 0x80240000
export const RAM_mac_00_Quizmo_CreateStage = 0x80240da0
export const RAM_func_802A123C_727B4C = 0x802a123c
export const RAM_water_splash_appendGfx = 0xe00be5bc
export const RAM_D_E00328E0 = 0xe00328e0
export const RAM_gPauseTutorialSprites = 0x8027011c
export const RAM_au_SEFCmd_00_SetVolume = 0x8004ce70
export const RAM_func_80240274_907CA4 = 0x80240274
export const RAM_shim_guMtxF2L = 0xe0200460
export const RAM_D_E006CB78 = 0xe006cb78
export const RAM_D_090001B8_32EE08 = 0x090001b8
export const RAM_flo_14_D_80244788_CCFA98 = 0x80244788
export const RAM_HES_StatStarPiece_1 = 0x80242a24
export const RAM_b_area_omo_omo_02 = 0x8022dbec
export const RAM_iwa_10_UnkFloatFunc = 0x80240160
export const RAM_D_80245310_CDC5F0 = 0x80245310
export const RAM_b_area_trd_part_2_red_ninja_koopa_StartRumbleWithParams = 0x80218ba0
export const RAM_b_area_dgb_dgb_04_foregroundModelList_8021B1A0 = 0x8021b1a0
export const RAM_flo_21_npcGroup_80243DDC = 0x80243ddc
export const RAM_D_802700E4 = 0x802700e4
export const RAM_falling_leaves_main = 0xe0058000
export const RAM_get_player_normal_yaw = 0x80029e20
export const RAM_arn_04_CleftAI_FindPlayer = 0x80242858
export const RAM_dispatch_event_general = 0x80276f8c
export const RAM_D_A460000C = 0xa460000c
export const RAM_EnemyFollowupAfflictTarget = 0x8027ced4
export const RAM_D_80243BD2_A1ECD2 = 0x80243bd2
export const RAM_D_80242F30_BC71B0 = 0x80242f30
export const RAM_D_80240D9C_A7925C = 0x80240d9c
export const RAM_func_802418AC_84447C = 0x802418ac
export const RAM_trd_03_UnkDistFunc = 0x8024011c
export const RAM_dgb_07_npcAI_8024190C = 0x8024190c
export const RAM_kmr_05_FlyingAI_Chase = 0x80240df4
export const RAM_filemenu_yesno_update = 0x80247d14
export const RAM_func_80241020_B851A0 = 0x80241020
export const RAM_battle_item_volt_shroom_PlayerGoHome = 0x802a16b0
export const RAM_kzn_20_LetterDelivery_CalcLetterPos = 0x8024086c
export const RAM_flo_18_802455B4 = 0x802455b4
export const RAM_fx_merlin_house_stars = 0x800717b0
export const RAM_gCurrentHiddenPanels = 0x8015a578
export const RAM_b_area_sam2_sam_03_UnkModelStuff_array = 0x80223080
export const RAM_battle_menu_moveTitleIcon = 0x802ad188
export const RAM_flo_08_SuperBlock_WhiteScreenFlash = 0x80240b38
export const RAM_flo_07_SpawnSunEffect = 0x8024030c
export const RAM_HES_MashBButton2 = 0x802923fc
export const RAM_mgm_00_draw_record_display = 0x802411e0
export const RAM_entity_MulticoinBlock_spawn_coin = 0x802e38d8
export const RAM_end_01_Font3Patterns = 0x80243178
export const RAM_EF2680_FlyingAI_Init = 0x80241258
export const RAM_dgb_08_FlyingAI_JumpVels = 0x80244460
export const RAM_state_init_exit_language_select = 0x800355d4
export const RAM_dgb_06_npcGroup_80240954 = 0x80240954
export const RAM_D_8014EE68 = 0x8014ee68
export const RAM_D_8010D668 = 0x8010d668
export const RAM_dup_sam_01_GetItemName = 0x80241d44
export const RAM_D_801094AE = 0x801094ae
export const RAM_b_area_isk_part_2_isk_06b_beforeBattle_802202F0 = 0x802202f0
export const RAM_isk_16_UnkFunc26 = 0x80240924
export const RAM_toy_tank_as_move_forward = 0x80222a98
export const RAM_b_area_jan_spear_guy_setSpearStance = 0x802188a8
export const RAM_HES_AnimatedHandPointer = 0x80104a28
export const RAM_D_80246578 = 0x80246578
export const RAM_fx_63_appendGfx = 0xe00c67b0
export const RAM_state_render_backUI = 0x80112f58
export const RAM_D_802EB360 = 0x802eb360
export const RAM_D_802EBA70 = 0x802eba70
export const RAM_steam_burst_update = 0xe005c180
export const RAM_sam_02_Quizmo_HideEntities = 0x80240c44
export const RAM_D_80109898 = 0x80109898
export const RAM_D_E0090AB8 = 0xe0090ab8
export const RAM_D_8010949C = 0x8010949c
export const RAM_battle_item_insecticide_herb_png = 0x802a19e0
export const RAM_battle_item_tasty_tonic_func_802A123C_72223C = 0x802a123c
export const RAM_D_8024EE98_C0C718 = 0x8024ee98
export const RAM_func_802407FC_C6D11C = 0x802407fc
export const RAM_ui_status_star_point_7_png = 0x801015a0
export const RAM_flo_18_triggerCoord_802456A4 = 0x802456a4
export const RAM_sam_02_Quizmo_UnkStageEffectMode = 0x8024159c
export const RAM_func_80056144 = 0x80056144
export const RAM_smoke_burst_init = 0xe004e15c
export const RAM_dup2_b_area_tik2_StartRumbleWithParams = 0x80218680
export const RAM_dead_fx_sun = 0x80077bd0
export const RAM_D_E0200698 = 0xe0200698
export const RAM_flo_09_main = 0x80241c9c
export const RAM_D_09001D00_351F20 = 0x09001d00
export const RAM_D_80294460 = 0x80294460
export const RAM_b_area_arn_arn_02_afterBattle_80227A48 = 0x80227a48
export const RAM_func_802411A0_D65780 = 0x802411a0
export const RAM_battle_action_cmd_whirlwind_1_pal = 0x802a9a00
export const RAM_kgr_01_HasBombetteExploded = 0x80240500
export const RAM_GetPartRotation = 0x8026c828
export const RAM_HES_StatusSPIncrement3 = 0x801082e8
export const RAM_b_area_kmr_part_3_spiked_goomba_defenseTable_8021A390 = 0x8021a390
export const RAM_func_8013559C = 0x8013559c
export const RAM_gWindowAppearScales = 0x8014f298
export const RAM_tik_17_SuperBlock_AnimateEnergyOrbs = 0x80240630
export const RAM_D_8014BE28 = 0x8014be28
export const RAM_mac_04_Quizmo_UnkStageEffectMode = 0x80240ed0
export const RAM_b_area_isk_part_1_formation_00 = 0x802240d0
export const RAM_arn_11_802401BC = 0x802401bc
export const RAM_flo_13_npcAI_80244174 = 0x80244174
export const RAM_battle_move_multibounce_D_802A15D8 = 0x802a15d8
export const RAM_func_80241530_D6FEE0 = 0x80241530
export const RAM_filemenu_update_show_options_left = 0x802433f4
export const RAM_vtx_C2E020 = 0x8020d0e0
export const RAM_pause_world_map_pal = 0x8026a0b0
export const RAM_shim_queue_render_task = 0xe0200480
export const RAM_b_area_sam2_sam_02c_foregroundModelList = 0x80222cc0
export const RAM_gPausePartnersNextPortraitIndex = 0x8024f7c4
export const RAM_b_area_mac_master3_idle_8022DC34 = 0x8022dc34
export const RAM_b_area_nok_kent_c_koopa_defenseTable_802279DC = 0x802279dc
export const RAM_omo_13_AddPlayerHandsOffset = 0x80240d30
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_attack1 = 0x80234580
export const RAM_kzn_09_PiranhaPlantAI_10 = 0x80241a58
export const RAM_b_area_nok_kent_c_koopa_defenseTable_802279FC = 0x802279fc
export const RAM_battle_star_refresh_UnkStarFunc3 = 0x802a116c
export const RAM_HES_Lakilester = 0x80107f28
export const RAM_func_80200000 = 0x80200000
export const RAM_au_BGMCmd_E5_MasterVolumeFade = 0x8004fe10
export const RAM_b_area_isk_part_1_pokey_mummy_8021C2BC = 0x8021c2bc
export const RAM_D_E00E4DC0 = 0xe00e4dc0
export const RAM_b_area_kmr_part_3_egg_jr_troopa_idleAnimations_8022464C = 0x8022464c
export const RAM_D_80153A57 = 0x80153a57
export const RAM_D_80156968 = 0x80156968
export const RAM_EnableGroup = 0x802c9b98
export const RAM_kmr_05_FlyingAI_LoiterInit = 0x802407d8
export const RAM_arn_07_80243B28 = 0x80243b28
export const RAM_mac_01_LetterDelivery_CalcLetterPos = 0x80243eec
export const RAM_b_area_arn_arn_05_beforeBattle_80227B80 = 0x80227b80
export const RAM_HES_StatusStar6 = 0x801084a0
export const RAM_flo_12_80240870 = 0x80240870
export const RAM_HES_Item_Unused_02E_disabled = 0x800838e8
export const RAM_kzn_19_StarSpiritEffectFunc4 = 0x802408c8
export const RAM_D_800984A8 = 0x800984a8
export const RAM_func_8024107C_91C95C = 0x8024107c
export const RAM_cosine = 0x800293d4
export const RAM_b_area_omo2_formation_01 = 0x8022fba0
export const RAM_GetValueByRef = 0x802d593c
export const RAM_func_80057548 = 0x80057548
export const RAM_floating_flower_render = 0xe001a3fc
export const RAM_battle_star_time_out_802A1520 = 0x802a1520
export const RAM_D_80156960 = 0x80156960
export const RAM_flo_00_npcGroupList_80246834 = 0x80246834
export const RAM_can_pause = 0x800e69dc
export const RAM_mac_02_Quizmo_FadeInWorld = 0x80241d38
export const RAM_D_8014F980 = 0x8014f980
export const RAM_b_area_hos_magikoopa = 0x8021d680
export const RAM_gPauseItemsItemIDs = 0x80270400
export const RAM_nok_02_varStash = 0x80252720
export const RAM_battle_menu_moveOptionIconIDs = 0x802ad128
export const RAM_tik_12_SuperBlock_EndGlowEffect = 0x8024112c
export const RAM_flo_03_intTable_80240F90 = 0x80240f90
export const RAM_dro_02_8024AC14 = 0x8024ac14
export const RAM_pra_40_SetPartnerFlags20000 = 0x80240eec
export const RAM_D_802D9D72 = 0x802d9d72
export const RAM_b_area_kmr_part_3_final_jr_troopa_takeTurn_8022D920 = 0x8022d920
export const RAM_D_80242290_D97880 = 0x80242290
export const RAM_b_area_mac_master3_idleAnimations_8022D9EC = 0x8022d9ec
export const RAM_mac_03_Quizmo_AudienceEffect = 0x802513f4
export const RAM_D_80245830_C76CB0 = 0x80245830
export const RAM_dgb_01_D_80251A80_C0F300 = 0x80251a80
export const RAM_D_802A3188_73F9E8 = 0x802a3188
export const RAM_vtx_C27B80 = 0x80206c40
export const RAM_gPartnerActionStatus = 0x8010ebb0
export const RAM_bTransformGroups = 0x80151f80
export const RAM_D_8014B988 = 0x8014b988
export const RAM_kmr_09_FlyingAI_Main = 0x8024130c
export const RAM_b_area_mac_master3_init_8022DB60 = 0x8022db60
export const RAM_ui_status_star_point_3_pal = 0x801013a0
export const RAM_D_800984A0 = 0x800984a0
export const RAM_dead_cos_deg = 0x8002b640
export const RAM_b_area_sam_duplighost_lakilester_idle = 0x8022634c
export const RAM_dgb_06_Chest_VarStash = 0x80240620
export const RAM_D_80245601 = 0x80245601
export const RAM_entity_ItemBlock_idle = 0x802e3e80
export const RAM_D_80249914_A33B54 = 0x80249914
export const RAM_arn_07_npcSettings_80243DA0 = 0x80243da0
export const RAM_b_area_sam2_paragoomba = 0x8021c090
export const RAM_vtx_C26590 = 0x80205650
export const RAM_nuSiCallBackAdd = 0x800603f0
export const RAM_nok_01_UnkFloatFunc = 0x80242514
export const RAM_func_802A1194_743F84 = 0x802a1194
export const RAM_jan_07_MeleeHitbox_CanSeePlayer = 0x8024041c
export const RAM_nuContDataMutexQ = 0x800dc040
export const RAM_pink_sparkles_render = 0xe012448c
export const RAM_b_area_sam2_monstar = 0x80219f68
export const RAM_func_80051334 = 0x80051334
export const RAM_entity_get_collision_flags = 0x801108b8
export const RAM_D_802A21D4_73EA34 = 0x802a21d4
export const RAM_kpa_51_MagikoopaAI_01 = 0x802407a4
export const RAM_b_area_isk_part_2_chain_chomp_partsTable_80218D34 = 0x80218d34
export const RAM_jan_06_HeartPlant_SpawnHeart = 0x80241554
export const RAM_kmr_09_FlyingAI_LosePlayer = 0x80240e58
export const RAM_dgb_18_idle_80242494 = 0x80242494
export const RAM_mim_04_SetForeverForestFog = 0x80240000
export const RAM_D_E0090AB0 = 0xe0090ab0
export const RAM_func_802A9294_426C64 = 0x802a9294
export const RAM_gPauseItemsTargetScrollPos = 0x802705d8
export const RAM_b_area_omo2_omo_07_beforeBattle = 0x8022fb20
export const RAM_HES_BombetteDisabled = 0x80107dc0
export const RAM_b_area_sam_sam_02b_UnkModelStuffScript4 = 0x8022fa14
export const RAM_b_area_kmr_part_3_para_jr_troopa_partsTable_80225F0C = 0x80225f0c
export const RAM_func_A4000A34 = 0xa4000a34
export const RAM_func_80240000_B83E60 = 0x80240000
export const RAM_hos_03_ToadHouse_CamSetFOV = 0x8024226c
export const RAM_kpa_70_UpdateTexturePanStepped = 0x80240124
export const RAM_func_802400E4_AF7A34 = 0x802400e4
export const RAM_entity_GreenStompSwitch_extend = 0x802e14d8
export const RAM_b_area_jan_jan_01_afterBattle_80227A6C = 0x80227a6c
export const RAM_b_area_kmr_part_3_paragoomba2_8021CB94 = 0x802211d4
export const RAM_b_area_kmr_part_3_jr_troopa_takeTurn_802234A4 = 0x802234a4
export const RAM_func_80240000_BC2A90 = 0x80240000
export const RAM_tik_07_FlyingAI_Loiter = 0x80241c88
export const RAM_func_802435C0_803E40 = 0x802435c0
export const RAM_D_80251050_C0E8D0 = 0x80251050
export const RAM_toy_tank_as_close_hatch = 0x8021e798
export const RAM_mgm_01_work_draw_score = 0x802403c4
export const RAM_start_rumble_type = 0x80267df4
export const RAM_b_area_kmr_part_2_red_goomba_takeTurn_8021C3B0 = 0x8021c3b0
export const RAM_b_area_kmr_part_3_kmr_04_clouds1 = 0x80232780
export const RAM_update_camera_mode_4 = 0x8002e910
export const RAM_b_area_trd_part_2_trd_02b_80231CE0 = 0x80231ce0
export const RAM_b_area_pra3_duplighost_UnkWattEffectFunc5 = 0x802193f0
export const RAM_b_area_pra3_red_magikoopa_8022D6A8 = 0x8022d6a8
export const RAM_flo_23_80240DA0 = 0x80240da0
export const RAM_battle_partner_goombario_8023A754 = 0x8023a754
export const RAM_is_point_within_region = 0x80048fd4
export const RAM_battle_item_ultra_shroom_UseItemWithEffect = 0x802a1340
export const RAM_state_step_file_select = 0x800354ec
export const RAM_battle_star_star_storm_UnkStarFunc = 0x802a1494
export const RAM_D_8014F4BC = 0x8014f4bc
export const RAM_D_8009A670 = 0x8009a670
export const RAM_func_80241838_EAA318 = 0x80241838
export const RAM_func_80243070_C63D80 = 0x80243070
export const RAM_D_802EB368 = 0x802eb368
export const RAM_D_E00A8620 = 0xe00a8620
export const RAM_vtx_C2E980 = 0x8020da40
export const RAM_vtx_stencil1 = 0x8014e500
export const RAM_func_80240340_EF7DC0 = 0x80240340
export const RAM_gZoneCollisionData = 0x800d91d0
export const RAM_b_area_sam_sam_03_UnkModelStuffScript2 = 0x802302ac
export const RAM_IES_Goomnut = 0x8008d934
export const RAM_tst_13_UnkDistFunc2 = 0x802400a0
export const RAM_func_8027D2D8 = 0x8027d2d8
export const RAM_b_area_isk_part_2_tutankoopa_8021FA54 = 0x8021fa54
export const RAM_kzn_20_GetItemName = 0x802403c4
export const RAM_dgb_03_PatrolAI_Chase = 0x8024146c
export const RAM_b_area_sam_frost_piranha_return_home = 0x8022ad6c
export const RAM_b_area_jan_jan_00_beforeBattle_802271C4 = 0x802271c4
export const RAM_hos_06_AddPlayerHandsOffset = 0x80240dac
export const RAM_b_area_kmr_part_3_sam_01_UnkModelStuffScript4 = 0x802338d4
export const RAM_evt_handle_get_1_word = 0x802c5c90
export const RAM_pause_banner_fp_png = 0x8026ca30
export const RAM_dro_01_EVS_Quizmo_PlayerReaction_RightAnswer = 0x80245b34
export const RAM_fx_75_appendGfx = 0xe00ea66c
export const RAM_sam_01_Quizmo_UpdateRecords = 0x80240dec
export const RAM_mac_01_PatrolAI_MoveInit = 0x80245550
export const RAM_b_area_kmr_part_3_kmr_05_clouds3 = 0x80232e00
export const RAM_D_80225FE0_48D090 = 0x80225fe0
export const RAM_D_E00E2A38 = 0xe00e2a38
export const RAM_repartner_set_tether_distance = 0x800ef3a4
export const RAM_SquareShadow = 0x802e9904
export const RAM_omo_09_ProjectileAI_Reflect = 0x80242df0
export const RAM_GoombariaUseAbility = 0x802bd1ac
export const RAM_snowfall_init = 0xe008a288
export const RAM_isk_08_AwaitPlayerNearNpc = 0x8024081c
export const RAM_dgb_08_npcGroup_802450A0 = 0x802450a0
export const RAM_func_802B71E8 = 0x802b71e8
export const RAM_dgb_02_ClubbaNappingAI_Loiter = 0x80240d1c
export const RAM_nuContRmbSearchTime = 0x80093ce0
export const RAM_b_area_trd_part_2_blue_ninja_koopa_handleEvent_8022DD88 = 0x8022dd88
export const RAM_D_80232C50 = 0x80232c50
export const RAM_pra_39_reflection_render_floor_fancy = 0x802408a0
export const RAM_b_area_kmr_part_3_egg_jr_troopa_idleAnimations_802243F8 = 0x802243f8
export const RAM_D_8024AB30_DD7800 = 0x8024ab30
export const RAM_SetActorLevelToZero = 0x8023102c
export const RAM_D_A4000768 = 0xa4000768
export const RAM_D_8024304C_B2F4BC = 0x8024304c
export const RAM_flo_10_func_802404B0_CB96B0 = 0x802404b0
export const RAM_world_watt_take_out = 0x802be258
export const RAM_func_80241C40_844810 = 0x80241c40
export const RAM_iwa_10_AddPlayerHandsOffset = 0x80241210
export const RAM_nok_02_AddPlayerHandsOffset = 0x80242a00
export const RAM_func_80218A98_59F178 = 0x80218a98
export const RAM_flo_13_npcGroup_80246FD4 = 0x80246fd4
export const RAM_b_area_isk_part_1_formation_08 = 0x80224334
export const RAM_EC71B0_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_80052E18 = 0x80052e18
export const RAM_dgb_18_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_kkj_00_PatrolAI_Jump = 0x80240854
export const RAM_b_area_mac_lee_partsTable_8022851C = 0x8022851c
export const RAM_kmr_02_Quizmo_SetVannaAnim_Clap = 0x80240e98
export const RAM_dro_02_Quizmo_StashVars = 0x80240300
export const RAM_kmr_10_foliage_setup_shear_mtx = 0x802403d0
export const RAM_filemenu_choose_name_cleanup = 0x8024932c
export const RAM_dro_01_init_80249ABC = 0x80249abc
export const RAM_quizmo_assistant_appendGfx = 0xe011c1c4
export const RAM_func_80240194_D4BBB4 = 0x80240194
export const RAM_b_area_sbk_formation_08 = 0x8021fba8
export const RAM_ui_status_star_point_1_png = 0x801011e0
export const RAM_func_8025DBC8 = 0x8025dbc8
export const RAM_b_area_sam_duplighost_bombette_defenseTable = 0x8022037c
export const RAM_RemovePlayerBuffs = 0x8026f44c
export const RAM_D_E00027D8 = 0xe00027d8
export const RAM_b_area_trd_part_2_trd_03_foregroundModelList_80232930 = 0x80232930
export const RAM_func_80055BF0 = 0x80055bf0
export const RAM_EA7320_UnsetCamera0Flag1000 = 0x8024036c
export const RAM_omo_09_CheckItemFlags40 = 0x80240988
export const RAM_arn_07_npcAISettings_80243BD8 = 0x80243bd8
export const RAM_dgb_00_makeEntities = 0x80243760
export const RAM_end_00_CharAnim_Hold = 0x802411cc
export const RAM_virtual_entity_move_polar = 0x802d2f34
export const RAM_flo_14_ItemChoice_SelectedItemID = 0x80244600
export const RAM_D_802438F8_A4BEB8 = 0x802438f8
export const RAM_IES_PowerSmash = 0x8008c544
export const RAM_D_8024CCA0_C0A520 = 0x8024cca0
export const RAM_b_area_pra3_red_magikoopa_flying = 0x8022c348
export const RAM_GfxTaskMgrThread = 0x8009c518
export const RAM_func_80218B08_4E3248 = 0x80218b08
export const RAM_D_09000C88 = 0x09000c88
export const RAM_jan_06_MeleeHitbox_30 = 0x8024181c
export const RAM_mac_01_Quizmo_SpinPlayer = 0x80242d2c
export const RAM_dgb_10_80240670 = 0x80240670
export const RAM_sbk_56_SuperBlock_WhiteScreenFlash = 0x80240bb8
export const RAM_b_area_sam2_sam_02c_UnkModelStuffScript1 = 0x802229a0
export const RAM_b_area_iwa_cleft_idleAnimations_80218838 = 0x80218838
export const RAM_func_802401DC_B9DB3C = 0x802401dc
export const RAM_ui_status_pow_star_6_pal = 0x80100a60
export const RAM_b_area_iwa_cleft_init_802188D0 = 0x802188d0
export const RAM_D_E00CCDF0 = 0xe00ccdf0
export const RAM_jan_12_foliage_setup_shear_mtx = 0x80240280
export const RAM_dro_02_ToadHouse_GetPartnerBackOut = 0x80243748
export const RAM_battle_move_jump_charge_2_IsJumpMaxCharged = 0x802a10e4
export const RAM_D_E0038A40 = 0xe0038a40
export const RAM_b_area_hos_ember_partsTable_8021AD80 = 0x8021ad80
export const RAM_flo_09_defeat_802438C8 = 0x802438c8
export const RAM_sam_02_Quizmo_SpinPartner = 0x802419ac
export const RAM_ui_status_star_piece_2_pal = 0x800fc240
export const RAM_D_802809F5 = 0x802809f5
export const RAM_D_8026FB30 = 0x8026fb30
export const RAM_D_8023D280 = 0x8023d280
export const RAM_D_E00BCE40 = 0xe00bce40
export const RAM_RotateGroup = 0x802c98d8
export const RAM_filemenu_draw_contents_copy_arrow = 0x802440bc
export const RAM_arn_05_npcGroupList_80244FA4 = 0x80244fa4
export const RAM_bss_802BFEE4 = 0x802bfee4
export const RAM_b_area_kmr_part_1_kmr_04_beforeBattle_802205D8 = 0x802205d8
export const RAM_ui_battle_status_peril_png = 0x80103120
export const RAM_filemenu_update_hidden_name_confirm = 0x80243eec
export const RAM_D_802A12E4_737B74 = 0x802a1314
export const RAM_D_80247410_C68120 = 0x80247410
export const RAM_IES_RedBerry = 0x8008d8d4
export const RAM_kpa_91_PatrolAI_Move = 0x80240158
export const RAM_jan_02_PatrolAI_Jump = 0x80240bd4
export const RAM_dgb_01_D_8025244C_C0FCCC = 0x8025244c
export const RAM_pra_39_ReflectWall = 0x8024011c
export const RAM_func_802427FC_9B917C = 0x802427fc
export const RAM_pra_10_reflection_render_floor_fancy = 0x80240870
export const RAM_D_80244E00_A1B020 = 0x80244e00
export const RAM_D_8023D288 = 0x8023d288
export const RAM_D_800B0AE8 = 0x800b0ae8
export const RAM_sbk_35_Bandit_DropCoin = 0x80240040
export const RAM_func_80242F74_A2D1B4 = 0x80242f74
export const RAM_player_get_camera_facing_angle = 0x800e546c
export const RAM_sam_02_Quizmo_AnswerResult = 0x8024b9a8
export const RAM_jan_02_PatrolAI_Move = 0x80240588
export const RAM_HES_FPCostReduced = 0x80107664
export const RAM_WattTweesterPhysics = 0x802be318
export const RAM_dro_02_npcAISettings_80247408 = 0x80247408
export const RAM_SetItemFlags = 0x802d7194
export const RAM_btl_state_draw_end_training_battle = 0x80244a48
export const RAM_dro_02_main = 0x80243ee0
export const RAM_b_area_isk_part_1_formationTable = 0x80224700
export const RAM_D_E00BAE88 = 0xe00bae88
export const RAM_tik_09_Pipe_GetCameraYaw = 0x802401d0
export const RAM_spr_load_player_sprite = 0x802dd89c
export const RAM_jan_maps = 0x80091d90
export const RAM_entity_SuperBlockContent_get_previous_yaw = 0x802e5670
export const RAM_npc_set_palswap_mode_B = 0x8003b3f8
export const RAM_tik_22_Pipe_AwaitDownInput = 0x80240370
export const RAM_GetLoadType = 0x802ca4c0
export const RAM_bPeachIdleAnims = 0x8028340c
export const RAM_D_8009805C = 0x8009805c
export const RAM_func_80240AD0_9B2260 = 0x80240ad0
export const RAM_cloud_puff_update = 0xe0014260
export const RAM_kmr_05_FlyingAI_ChaseInit = 0x80240cc0
export const RAM_dup_omo_09_SetNpcB5_3 = 0x802435b8
export const RAM_D_E0200690 = 0xe0200690
export const RAM_D_80242538_A4EF58 = 0x80242538
export const RAM_shimmer_wave_update = 0xe00741e8
export const RAM_isk_05_HoppingAI_Main = 0x80240bf0
export const RAM_HES_Item_MegaSmash = 0x80084db8
export const RAM_D_8014BAE8 = 0x8014bae8
export const RAM_partner_set_tether_distance = 0x800ef394
export const RAM_osAiGetLength = 0x80061540
export const RAM_b_area_pra3_duplighost_goombario_statusTable = 0x8021bbcc
export const RAM_mac_01_Quizmo_SetStageLightsDelay = 0x8024294c
export const RAM_flo_22_searchBush = 0x802415a0
export const RAM_b_area_kmr_part_3_kmr_03_foregroundModelList_80232740 = 0x80232740
export const RAM_strchr = 0x80064a10
export const RAM_mac_00_Pipe_SetAnimFlag = 0x80240000
export const RAM_battle_star_up_and_away_SetNpcCollision32 = 0x802a14e8
export const RAM_func_80035DF0 = 0x80035df0
export const RAM_D_E0074C08 = 0xe0074c08
export const RAM_D_8024AAC0_A34D00 = 0x8024aac0
export const RAM_pause_badges_cleanup = 0x80249758
export const RAM_flo_22_npcGroupList_80240DE4 = 0x80240de4
export const RAM_dgb_18_PatrolAI_ChaseInit = 0x8024081c
export const RAM_kpa_08_MagikoopaAI_00 = 0x80240780
export const RAM_func_802186D0_69F8D0 = 0x802186d0
export const RAM_suspend_frozen_scripts = 0x802c3390
export const RAM_HES_SushieDisabled = 0x80107f00
export const RAM_dgb_18_idle_80242A24 = 0x80242a24
export const RAM_main_menu_numOptions = 0x802ad0ac
export const RAM_D_802A2DEC = 0x802a2dec
export const RAM_flo_12_AddPlayerHandsOffset = 0x802403f0
export const RAM_battle_star_star_beam_802A24F0 = 0x802a24f0
export const RAM_kmr_03_foliage_setup_shear_mtx = 0x80240410
export const RAM_D_8024F8B0_C0D130 = 0x8024f8b0
export const RAM_kmr_02_StationaryAI_AlertInit = 0x802415f8
export const RAM_func_80240064_BBB3E4 = 0x80240064
export const RAM_au_bgm_stop_song = 0x8004da0c
export const RAM_isk_02_SetEntityFlags100000 = 0x80240000
export const RAM_func_802182E4_660A34 = 0x802182e4
export const RAM_gv_01_UpdateTexturePanSmooth = 0x802402e0
export const RAM_b_area_kmr_part_2_blue_goomba_2_ArcsinDeg = 0x80218500
export const RAM_mac_05_PatrolAI_PostLoiter = 0x80240b0c
export const RAM_D_80250A54_862BC4 = 0x80250a54
export const RAM_au_mseq_read_next = 0x800521d0
export const RAM_b_area_sam_duplighost_kooper_dispOffsets = 0x8021e9c0
export const RAM_gPauseWS_9 = 0x8026faf8
export const RAM_kpa_95_ToadHouse_PutPartnerAway = 0x80241898
export const RAM_func_8024041C_B1418C = 0x8024041c
export const RAM_dgb_08_npcSettings_80244434 = 0x80244434
export const RAM_dro_01_80247D90 = 0x80247d90
export const RAM_D_802941C0 = 0x802941c0
export const RAM_flo_07_triggerCoord_802428B4 = 0x802428b4
export const RAM_D_E00BEB10 = 0xe00beb10
export const RAM_battle_item_insecticide_herb_EatItem = 0x802a18ec
export const RAM_kpa_113_set_script_owner_npc_col_height = 0x802404c4
export const RAM_D_80244FA8_ADAE78 = 0x80244fa8
export const RAM_arn_11_npcGroup_80240EEC = 0x80240eec
export const RAM_jan_10_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_kzn_red_magikoopa_8022D044 = 0x80221d84
export const RAM_enable_anim_model_fog = 0x80120028
export const RAM_D_802BFF28 = 0x802bff28
export const RAM_func_80242028_9C7408 = 0x80242028
export const RAM_D_80244440_9A0DD0 = 0x80244440
export const RAM_b_area_isk_part_2_isk_06_afterBattle_802202A8 = 0x802202a8
export const RAM_b_area_trd_part_2_fake_bowser_80220588 = 0x80220588
export const RAM_D_802D9E10 = 0x802d9e10
export const RAM_b_area_omo_formation_18 = 0x80230030
export const RAM_D_80240333_8FF1B3 = 0x80240333
export const RAM_D_80247AE8_CCB2F8 = 0x80247ae8
export const RAM_battle_star_star_beam_UnkStarFunc = 0x802a1494
export const RAM_D_80270388 = 0x80270388
export const RAM_b_area_nok_fuzzy_init_802221A0 = 0x802221a0
export const RAM_mdl_treeIterPos = 0x80153224
export const RAM_tik_19_SuperBlock_EndGlowEffect = 0x80240b00
export const RAM_func_80242250_7ECF60 = 0x80242250
export const RAM_jan_07_SpearGuyAI_LoiterInit = 0x80240000
export const RAM_b_area_omo_yellow_shy_guy_takeTurn_8021E2B4 = 0x8021e2b4
export const RAM_appendGfx_message = 0x80127d90
export const RAM_func_80282700 = 0x80282700
export const RAM_battle_move_jump_CheckForAPress = 0x802a1180
export const RAM_D_802A2E00 = 0x802a2e00
export const RAM_RegisterMusicEvents = 0x802d5c70
export const RAM_nuScCreateScheduler = 0x8005e9b0
export const RAM_RestartNpcAI = 0x80044c2c
export const RAM_ui_status_text_3_pal = 0x801001e0
export const RAM_DeleteTrigger = 0x802c6824
export const RAM_tattle_window_update = 0xe00d8128
export const RAM_D_8007F158 = 0x8007f158
export const RAM_func_802DE748 = 0x802de748
export const RAM_dup_b_area_pra_GetSelectedMoveID = 0x80219218
export const RAM_D_802423B0 = 0x802423b0
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_handleEvent_80230D28 = 0x80230d28
export const RAM_b_area_kzn_red_magikoopa_8022DCC4 = 0x80222a04
export const RAM_D_80240C24_A7B124 = 0x80240c24
export const RAM_vtx_C2AAB0 = 0x80209b70
export const RAM_mac_05_Quizmo_SetVannaAnim_Wave = 0x80241e44
export const RAM_func_80241F80_833780 = 0x80241f80
export const RAM_battle_star_focus_802A1D60 = 0x802a1d60
export const RAM_fold_get_state = 0x8013a920
export const RAM_flo_12_npcSettings_80240E5C = 0x80240e5c
export const RAM_dup_mac_00_LetterDelivery_SaveNpcAnim = 0x80243ae8
export const RAM_dup_b_area_tik2_UnfadeBackgroundToBlack = 0x80218804
export const RAM_HES_OptionMonoOn = 0x8024232c
export const RAM_EDEA00_UnkNpcAIFunc12 = 0x802424a0
export const RAM_D_802A1388_0073B258 = 0x802a1388
export const RAM_kzn_02_FlyingAI_Loiter = 0x80240d78
export const RAM_b_area_pra2_pos_crystal_king = 0x80221500
export const RAM_filemenu_draw_contents_option_left = 0x80244dd0
export const RAM_func_80269160 = 0x80269160
export const RAM_kmr_10_StashVars = 0x80240000
export const RAM_b_area_kgr_fuzzipede_nextTurn_80218C58 = 0x80218c58
export const RAM_nok_03_HoppingAI_Chase = 0x802408ac
export const RAM_SetNpcRotation = 0x802cddb0
export const RAM_pause_init = 0x80244060
export const RAM_crash_screen_init = 0x8002c8b0
export const RAM_battle_item_pow_block_UseItem = 0x802a16a4
export const RAM_b_area_kmr_part_2_red_goomba_idle_8021B8A8 = 0x8021b8a8
export const RAM_flo_00_init_80243BB0 = 0x80243bb0
export const RAM_b_area_kmr_part_2_blue_goomba_CalculateRotationZ = 0x8021818c
export const RAM_HES_MarioHeadLarge = 0x8024277c
export const RAM_ui_box_bg_flat_png = 0x8010bae0
export const RAM_battle_menu_leftJustfiedMessagesC = 0x802ab4b4
export const RAM_HES_Item_SuperFocus = 0x80085838
export const RAM_b_area_isk_part_2_isk_02c_foregroundModelList_8021FFF4 = 0x8021fff4
export const RAM_func_8024113C_B98D3C = 0x8024113c
export const RAM_D_802A18AC_00734E4C = 0x802a18ac
export const RAM_gfx_draw_frame = 0x80026b08
export const RAM_func_8024024C_D7EFAC = 0x8024024c
export const RAM_EDC020_AddPlayerHandsOffset = 0x802403f0
export const RAM_b_area_pra3_gray_magikoopa_parts_flying = 0x8022ef74
export const RAM_sbk_34_LetterDelivery_RestoreNpcAnim = 0x8024076c
export const RAM_func_802405C0_EDC5A0 = 0x802405c0
export const RAM_D_09000B88 = 0x09000b88
export const RAM_omo_09_varStash = 0x80247010
export const RAM_b_area_jan_formation_07 = 0x802287cc
export const RAM_CopyBuffs = 0x80271160
export const RAM_EEDF50_FlyingAI_WanderInit = 0x80240e50
export const RAM_trd_02_PatrolAI_PostLoiter = 0x80240f30
export const RAM_mgm_01_InitialConfigurations = 0x80241b9c
export const RAM_state_do_unk = 0x80112eec
export const RAM_spr_playerMaxComponents = 0x802df580
export const RAM_D_E0048A00 = 0xe0048a00
export const RAM_flo_09_FlyingAI_Jump = 0x80240f74
export const RAM_trd_01_set_script_owner_npc_anim = 0x802403f0
export const RAM_D_E00BAE80 = 0xe00bae80
export const RAM_omo_11_SuperBlock_PartnerIDs = 0x80241e48
export const RAM_get_entity_fog_color = 0x8012307c
export const RAM_dro_02_init_80247A80 = 0x80247a80
export const RAM_pra_34_GetEntityPosition = 0x80240f54
export const RAM_ED5540_TransformFoliage = 0x80241390
export const RAM_D_80243254_A2A124 = 0x80243254
export const RAM_shiny_flare_render = 0xe00da1e0
export const RAM_D_E01187E0 = 0xe01187e0
export const RAM_nok_15_Pipe_SetAnimFlag = 0x80240000
export const RAM_battle_item_egg_missile_main = 0x802a25a0
export const RAM_b_area_trd_part_2_trd_04_foregroundModelList_80232CB0 = 0x80232cb0
export const RAM_entity_Chest_open = 0x802e7230
export const RAM_dro_01_tree1 = 0x8024dd54
export const RAM_load_message_to_buffer = 0x8012588c
export const RAM_b_area_kmr_part_3_egg_jr_troopa_80224658 = 0x80224658
export const RAM_b_area_isk_part_2_isk_05_beforeBattle_802201D0 = 0x802201d0
export const RAM_crash_screen_printf_with_bg = 0x8002c94c
export const RAM_func_802A992C = 0x802a992c
export const RAM_func_802A1518_7905E8 = 0x802a1518
export const RAM_func_80240BA0_B075A0 = 0x80240ba0
export const RAM_npc_draw_palswap_mode_4 = 0x8003bed8
export const RAM_D_80098670 = 0x80098670
export const RAM_kmr_02_Quizmo_HideEntities = 0x802404a8
export const RAM_b_area_dgb_tubba_blubba_idle_80219204 = 0x80219204
export const RAM_hos_03_ToadHouse_PartnerSuspendAbilityScript = 0x8024231c
export const RAM_dro_02_Quizmo_DestroyEffects = 0x80240c88
export const RAM_func_80241310_D658F0 = 0x80241310
export const RAM_func_80240318_ED9138 = 0x80240318
export const RAM_mim_06_HoppingAI_Loiter = 0x8024253c
export const RAM_func_802BD2D4_320E24 = 0x802bd2d4
export const RAM_D_E0048A08 = 0xe0048a08
export const RAM_kpa_03_UnkDistFunc = 0x802401dc
export const RAM_b_area_nok_paratroopa_defenseTable_8021EAFC = 0x8022392c
export const RAM_arn_02_npcSettings_80241420 = 0x80241420
export const RAM_b_area_iwa_formation_08 = 0x802183ec
export const RAM_kmr_03_exitWalk_802406F0 = 0x802406f0
export const RAM_dgb_09_80244890 = 0x80244890
export const RAM_ui_battle_cont_c_left_down_pal = 0x8028b910
export const RAM_flo_11_Pipe_AwaitDownInput = 0x80240034
export const RAM_b_area_kzn_formation_03 = 0x8022988c
export const RAM_flo_11_Pipe_ExitHorizontal = 0x802410f8
export const RAM_pra_38_reflection_render_floor_fancy = 0x802408a0
export const RAM_kpa_111_UnkFunc11 = 0x80240000
export const RAM_b_area_kmr_part_3_paragoomba1_nextTurn_8021F690 = 0x8021f690
export const RAM_BattleCam_SetImmediately = 0x8029f2a4
export const RAM_b_area_sam_formation_09 = 0x80230d90
export const RAM_music_note_appendGfx = 0xe004c3a8
export const RAM_b_area_trd_part_1_trd_04_beforeBattle_80224298 = 0x80224298
export const RAM_dro_01_toggleVis_8024C040 = 0x8024c040
export const RAM_mac_00_Quizmo_SetVannaAnim_Idle = 0x80241120
export const RAM_sam_11_UpdateTexturePanSmooth = 0x80241aa0
export const RAM_vtx_C22870 = 0x80201930
export const RAM_kzn_03_AddPlayerHandsOffset = 0x80242710
export const RAM_tst_maps = 0x800932f0
export const RAM_dro_02_AddPlayerHandsOffset = 0x802430f0
export const RAM_sam_02_ToadHouse_PutPartnerAway = 0x80240ac0
export const RAM_HES_WaterBlock3 = 0x802ab090
export const RAM_func_80218130_4CF2E0 = 0x80218130
export const RAM_AlMinFrameSize = 0x800a3638
export const RAM_evt_handle_set_array = 0x802c61ac
export const RAM_func_80240020_D69830 = 0x80240020
export const RAM_mac_02_Quizmo_GetItemName = 0x80241874
export const RAM_b_area_kmr_part_3_paragoomba2_idleAnimations_8021CB48 = 0x80221188
export const RAM_HES_Item_Unused_0E5_disabled = 0x800859e8
export const RAM_mim_04_GetItemName = 0x802410c8
export const RAM_D_8007F204 = 0x8007f204
export const RAM_D_802493A0_E0E700 = 0x802493a0
export const RAM_dead_80117163 = 0x80117160
export const RAM_D_8023BDBC = 0x8023bdbc
export const RAM_jan_05_HoppingAI_Chase = 0x80241150
export const RAM_dgb_01_D_802522C0_C0FB40 = 0x802522c0
export const RAM_D_8014EE60 = 0x8014ee60
export const RAM_D_8010D660 = 0x8010d660
export const RAM_dro_01_npcAI_802450BC = 0x802450bc
export const RAM_action_command_air_raid_MashActionCommandInit = 0x802a9178
export const RAM_func_802A92F0_423F60 = 0x802a92f0
export const RAM_b_area_nok_paratroopa_nextTurn_8021EDE4 = 0x80223c14
export const RAM_b_area_omo2_1_shy_squad_flee = 0x80235484
export const RAM_omo_17_ProjectileHitbox_31 = 0x802428e0
export const RAM_kpa_11_UpdateTexturePanStepped = 0x80240124
export const RAM_nok_01_Quizmo_SpinPartner = 0x80241be8
export const RAM_dup_kkj_10_UnkPhysicsFunc = 0x802408a0
export const RAM_gPauseBadgesWindowBPs = 0x8024f528
export const RAM_HES_Item_PleaseComeBack = 0x800824d8
export const RAM_D_80243614_AD94E4 = 0x80243614
export const RAM_func_80240000_D799F0 = 0x80240000
export const RAM_dgb_15_UnkFunc1 = 0x80241760
export const RAM_render_animated_model = 0x8011f304
export const RAM_D_8024F208_A39448 = 0x8024f208
export const RAM_D_E0070D90 = 0xe0070d90
export const RAM_action_update_step_up = 0x802b6000
export const RAM_func_802B6000_E27510 = 0x802b6000
export const RAM_mgm_00_PatrolAI_ChaseInit = 0x80240afc
export const RAM_HES_AimShimmerF = 0x802934d4
export const RAM_func_8024116C_BD49DC = 0x8024116c
export const RAM_func_8004BA74 = 0x8004ba74
export const RAM_gPauseTabsPreviousTab = 0x80270148
export const RAM_D_802942E0 = 0x802942e0
export const RAM_gAnimTreeRoot = 0x80153b68
export const RAM_D_802BCE10_E2F740 = 0x802bce10
export const RAM_HES_StartButtonText = 0x80108d40
export const RAM_D_80243BD8_BC7E58 = 0x80243bd8
export const RAM_b_area_nok_nok_04 = 0x8022bbd8
export const RAM_b_area_omo2_5_shy_squad_dup_defenseTable = 0x80231000
export const RAM_kpa_03_AwaitPlayerNearNpc = 0x8024097c
export const RAM_D_802BFEFC = 0x802bfefc
export const RAM_D_802473D8_C680E8 = 0x802473d8
export const RAM_func_802BC4B0_E2EDE0 = 0x802bc4b0
export const RAM_entity_Chest_begin_opening = 0x802e71f8
export const RAM_b_area_jan_jan_01b_80227AB0 = 0x80227ab0
export const RAM_omo_17_FlyingAI_JumpVels = 0x80246184
export const RAM_bombette_breaking_get_model_center_and_size = 0xe0084000
export const RAM_osr_01_varStash = 0x802411b8
export const RAM_GetActionCommandResult = 0x802807d0
export const RAM_IES_Unused_02C = 0x8008d534
export const RAM_D_E02006DC = 0xe02006dc
export const RAM_dro_02_EVS_Quizmo_MovePlayerToPodium = 0x80245258
export const RAM_b_area_isk_part_1_isk_04_beforeBattle_80223AC0 = 0x80223ac0
export const RAM_flo_14_FlyingAI_Wander = 0x80240cc0
export const RAM_jan_03_PatrolAI_PostLoiter = 0x80240c9c
export const RAM_arn_07_80243E24 = 0x80243e24
export const RAM_mdl_renderModelFogColorB = 0x8014b756
export const RAM_blast_init = 0xe007c128
export const RAM_iwa_04_CleftAI_Hiding = 0x80240254
export const RAM_func_80240000_A83490 = 0x80240000
export const RAM_kzn_17_UnkFunc47 = 0x80242de4
export const RAM_D_8024F484 = 0x8024f484
export const RAM_entity_GiantChest_hide_effect = 0x802e75c0
export const RAM_evt_execute_next_command = 0x802c7404
export const RAM_hud_element_clear_cache = 0x80141100
export const RAM_D_E00789A8 = 0xe00789a8
export const RAM_ice_shard_main = 0xe0110000
export const RAM_entity_Chest_check_opened = 0x802e7034
export const RAM_D_80245360_EEB910 = 0x80245360
export const RAM_func_80240000_DF6A00 = 0x80240000
export const RAM_sfx_get_spatialized_sound_params = 0x80149e04
export const RAM_b_area_trd_part_1_trd_03 = 0x80223fc0
export const RAM_func_802A928C = 0x802a928c
export const RAM_mac_03_LetterDelivery_CalcLetterPos = 0x80241988
export const RAM_flo_25_BuildItemChoiceList = 0x80241de4
export const RAM_D_80234770_6BB970 = 0x80234770
export const RAM_D_E0126C20 = 0xe0126c20
export const RAM_check_for_ispy = 0x800e0294
export const RAM_dup_jan_04_TransformFoliage = 0x80240df0
export const RAM_dro_02_pad_D_8024F240 = 0x8024f240
export const RAM_clone_model = 0x8011b6f0
export const RAM_b_area_kzn_red_magikoopa_8022D6A8 = 0x802223e8
export const RAM_arn_03_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_flo_08_lavaResetList_80242A2C = 0x80242a2c
export const RAM_b_area_iwa_cleft_idleAnimations_80218884 = 0x80218884
export const RAM_kmr_02_Pipe_GetCameraYaw = 0x802401d0
export const RAM_D_8024100C_BCE06C = 0x8024100c
export const RAM_b_area_mac_master3_takeTurn_80230B20 = 0x80230b20
export const RAM_b_area_omo_formation_00 = 0x8022f930
export const RAM_get_nearest_home_index = 0x80269698
export const RAM_sbk_01_SpawnSunEffect = 0x80240000
export const RAM_mac_01_ToadHouse_Stub = 0x80243aa0
export const RAM_func_80254250 = 0x80254250
export const RAM_D_80093DD0 = 0x80093dd0
export const RAM_D_80249A8C_A33CCC = 0x80249a8c
export const RAM_mac_04_Quizmo_varStash = 0x80243fe8
export const RAM_HES_Item_Unused_127_disabled = 0x800863a8
export const RAM_flo_00_PatrolAI_LoiterInit = 0x80240440
export const RAM_tik_17_Pipe_GetCameraYaw = 0x80240f40
export const RAM_spr_get_player_raster_info = 0x802de044
export const RAM_kmr_12_settings = 0x80240050
export const RAM_Entity_PushBlock = 0x802ea2bc
export const RAM_kmr_02_Quizmo_Answers = 0x80245634
export const RAM_D_801500A0 = 0x801500a0
export const RAM_b_area_kkj_SetEntityPositionF = 0x802180b8
export const RAM_b_area_pra2_sam_04_UnkModelStuffScript2 = 0x8022122c
export const RAM_gPauseBufferImg2 = 0x802748b8
export const RAM_isk_18_func_802408B4_97BEE4 = 0x80240874
export const RAM_ShopResetSpeech = 0x80283ee0
export const RAM_b_area_arn_formation_0B = 0x80227f70
export const RAM_trd_04_UpdateTexturePanSmooth = 0x80240000
export const RAM_func_80242FBC = 0x80242fbc
export const RAM_D_80077AE0 = 0x80077ae0
export const RAM_D_802A3FC4_73A854 = 0x802a3ff4
export const RAM_D_802C05CC = 0x802c05cc
export const RAM_gPauseMsg_53 = 0x8026f760
export const RAM_restore_map_collision_data = 0x8005b058
export const RAM_func_80240CB4_AF8604 = 0x80240cb4
export const RAM_func_8026EB20 = 0x8026eb20
export const RAM_kpa_14_SetEntityFlags100000 = 0x8024063c
export const RAM_D_80244E78_A1B098 = 0x80244e78
export const RAM_ui_battle_menu_defend_pal = 0x80289a80
export const RAM_D_09001500 = 0x09001500
export const RAM_func_8024330C = 0x8024330c
export const RAM_D_8014BB38 = 0x8014bb38
export const RAM_dgb_06_makeEntities = 0x802407b8
export const RAM_kzn_09_SuperBlock_PartnerSparkles4 = 0x80240cc0
export const RAM_RunPartTo = 0x8027acac
export const RAM_fx_46_appendGfx = 0xe008c6fc
export const RAM_b_area_mac_lee_nextTurn_80223298 = 0x80223298
export const RAM_omo_09_PatrolAI_LoiterInit = 0x8024134c
export const RAM_func_802443E0_804C60 = 0x802443e0
export const RAM_D_00E35670 = 0x00e35670
export const RAM_ui_sushie_disabled_pal = 0x800ff5c0
export const RAM_D_8024AAD6_A34D16 = 0x8024aad6
export const RAM_func_80242364_C5CAA4 = 0x80242364
export const RAM_omo_02_ShyGuyWanderAI_16 = 0x80240ab0
export const RAM_func_80112E24 = 0x80112e24
export const RAM_b_area_pra3_white_clubba_init = 0x8022b290
export const RAM_gPauseMenuPageScrollInterpEasingLUT = 0x8024f0bc
export const RAM_D_8024F4E8 = 0x8024f4e8
export const RAM_D_80153A64 = 0x80153a64
export const RAM_isk_maps = 0x80090dd0
export const RAM_D_802E96F0 = 0x802e96f0
export const RAM_flo_25_FlowerGuard_SetItemEntityPosition = 0x80241e80
export const RAM_dgb_09_main = 0x80243d28
export const RAM_b_area_trd_part_1_paratroopa_statusTable_8021EB14 = 0x8021eb14
export const RAM_arn_07_80243DCC = 0x80243dcc
export const RAM_D_80097D78 = 0x80097d78
export const RAM_set_item_entity_position = 0x8013420c
export const RAM_dgb_18_8024374C = 0x8024374c
export const RAM_dead_get_npc_safe = 0x8003e534
export const RAM_b_area_kmr_part_2_goomnut_tree_handleEvent_80223688 = 0x80223688
export const RAM_world_kooper_init = 0x802bd200
export const RAM_sbk_00_npcGroup_80240578 = 0x80240578
export const RAM_load_tile_header = 0x80114d6c
export const RAM_b_area_mim_piranha_plant_unk_missing_8021D388 = 0x8021d388
export const RAM_D_802B6EA0_E2A310 = 0x802b6ea0
export const RAM_BadgeShopInteract = 0x80284034
export const RAM_b_area_isk_part_2_tutankoopa_partsTable_8021BAD4 = 0x8021bad4
export const RAM_kpa_113_TackleAI_Main = 0x80240544
export const RAM_omo_09_ShyGuyWanderAI_14 = 0x802409e0
export const RAM_jan_14_HoppingAI_LoiterInit = 0x80240a28
export const RAM_D_802510B8_8EB1B8 = 0x802510b8
export const RAM_b_area_arn_arn_04_afterBattle_80227B3C = 0x80227b3c
export const RAM_battle_move_auto_multibounce_UnkMoveFunc1 = 0x802a1000
export const RAM_b_area_pra_UnkBattleFunc2_2 = 0x80218180
export const RAM_func_80245504_805D84 = 0x80245504
export const RAM_dgb_03_MeleeHitbox_32 = 0x8024032c
export const RAM_D_8014F958 = 0x8014f958
export const RAM_tik_06_UpdateTexturePanSmooth = 0x802402e0
export const RAM_arn_03_GetNpcCollisionHeight = 0x802413c8
export const RAM_D_E00027B0 = 0xe00027b0
export const RAM_PlayerRasterBufferSetOffsets = 0x802e0050
export const RAM_battle_item_mystery_UseItemWithEffect = 0x802a1950
export const RAM_IES_CakeMix = 0x8008da14
export const RAM_b_area_kmr_part_3_final_jr_troopa_ArcsinDeg = 0x80218b9c
export const RAM_battle_item_thunder_bolt_main = 0x802a1a5c
export const RAM_arn_12_npcGroupList_80240BBC = 0x80240bbc
export const RAM_dgb_16_80241480 = 0x80241480
export const RAM_D_8029D8A8 = 0x8029d8a8
export const RAM_b_area_sbk_pokey_handleEvent_802186C0 = 0x802186c0
export const RAM_D_E009EDF8 = 0xe009edf8
export const RAM_play_movement_dust_effects = 0x802642f0
export const RAM_D_80247A30_CCB240 = 0x80247a30
export const RAM_PATCH_state_step_logos = 0x80400000
export const RAM_gravity_use_fall_parms = 0x800e306c
export const RAM_D_8010D658 = 0x8010d658
export const RAM_gWorldEntityModelList = 0x80153b70
export const RAM_kmr_05_settings = 0x028002c0
export const RAM_IES_JellyPop = 0x8008d3f4
export const RAM_HES_Item_SnowmanBucket = 0x80081bd8
export const RAM_omo_09_ProjectileHitbox_31 = 0x8024278c
export const RAM_func_800E0AD0 = 0x800e0ad0
export const RAM_trd_01_StationaryAI_Main = 0x802413b8
export const RAM_vtx_C26450 = 0x80205510
export const RAM_vtx_C22950 = 0x80201a10
export const RAM_dro_02_LetterDelivery_RestoreNpcAnim = 0x80243a40
export const RAM_D_E0124C20 = 0xe0124c20
export const RAM_D_E0126C28 = 0xe0126c28
export const RAM_D_801500A8 = 0x801500a8
export const RAM_vtx_curtains3 = 0x800759e0
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_handleEvent_802287D0 = 0x802287d0
export const RAM_func_80116698 = 0x80116698
export const RAM_func_E00A8318 = 0xe00a8318
export const RAM_kpa_08_MagikoopaAI_TeleportAnim = 0x80241cf0
export const RAM_b_area_kmr_part_2_blue_goomba_2_init_8021EDB8 = 0x8021edb8
export const RAM_entity_fog_enabled = 0x80154378
export const RAM_kpa_91_SetEntityFlags100000 = 0x80241830
export const RAM_set_message_value = 0x80125be8
export const RAM_func_80057C54 = 0x80057c54
export const RAM_vtx_C24AD0 = 0x80203b90
export const RAM_pra_14_ReflectPartner = 0x80240d3c
export const RAM_NpcJump0 = 0x802ce6c4
export const RAM_tik_12_UnkDistFunc = 0x802419dc
export const RAM_jan_08_SuperBlock_GetPartnerRank = 0x802406c8
export const RAM_SetNpcSprite = 0x802cf208
export const RAM_AlFrameSize = 0x800a3634
export const RAM_arn_07_StarSpiritEffectFunc1 = 0x80240000
export const RAM_func_802400B0_98AAC0 = 0x802400b0
export const RAM_battle_move_auto_multibounce_D_802A11AC = 0x802a11ac
export const RAM_D_09001400_351620 = 0x09001400
export const RAM_HES_Item_LemonCandy_disabled = 0x80083408
export const RAM_set_message_msg = 0x80125b3c
export const RAM_D_80246D10_B59540 = 0x80246d10
export const RAM_kkj_12_GetPeachDisguise = 0x802406e8
export const RAM_phys_update_action_state = 0x800e5a2c
export const RAM_tik_06_Pipe_GetCurrentFloor = 0x80240020
export const RAM_battle_menu_moveX = 0x802ad1f0
export const RAM_pause_badges_scroll_offset_y = 0x80247298
export const RAM_D_8024C117 = 0x8024c117
export const RAM_dgb_04_SuperBlock_ShowSelectPartnerMenu = 0x8024259c
export const RAM_mac_00_Quizmo_CreateReactionEffect = 0x80241628
export const RAM_b_area_kpa4_StartRumbleWithParams = 0x80218530
export const RAM_dup_b_area_kpa2_FadeBackgroundToBlack = 0x80218000
export const RAM_D_80245674_8B56E4 = 0x80245674
export const RAM_D_80245F40_AD4080 = 0x80245f40
export const RAM_dro_02_EVS_Quizmo_NPC_Interact = 0x802472c4
export const RAM_b_area_sam_formation_0A = 0x80230e70
export const RAM_set_npc_animation = 0x802cdb0c
export const RAM_BattleCam_UseLinearInterp = 0x8029f2a5
export const RAM_D_802BEC50 = 0x802bec50
export const RAM_flo_03_searchBush = 0x80244480
export const RAM_GetItemCount = 0x80240bd4
export const RAM_D_8014F56C = 0x8014f56c
export const RAM_kmr_07_StationaryAI_Chase = 0x802403dc
export const RAM_b_area_omo2_2_stilt_guy_check_fall_off = 0x80232a78
export const RAM_b_area_omo_medi_guy_8021FB9C = 0x8022371c
export const RAM_water_fountain_update = 0xe00b8134
export const RAM_battle_item_please_come_back_UseItem = 0x802a1544
export const RAM_kzn_09_SuperBlock_AnimateEnergyOrbs = 0x80240600
export const RAM_IES_KootGlasses = 0x8008bdc4
export const RAM_D_80244038_BA4768 = 0x80244038
export const RAM_IES_QuakeHammer = 0x8008bfe4
export const RAM_nok_01_LetterDelivery_Init = 0x80242150
export const RAM_action_command_tidal_wave_update = 0x802a9228
export const RAM_D_8024EF70_978130 = 0x8024ef70
export const RAM_D_80245450_DB7710 = 0x80245450
export const RAM_D_802EA871 = 0x802ea871
export const RAM_b_area_pra3_duplighost_OnDeath = 0x8021ac7c
export const RAM_floating_rock_appendGfx = 0xe00982ec
export const RAM_vtx_C26130 = 0x802051f0
export const RAM_dead_basic_ai_chase = 0x8004e544
export const RAM_flo_00_itemList_80248598 = 0x80248598
export const RAM_tik_07_Pipe_GetEntryPos = 0x802400f0
export const RAM_b_area_kmr_part_3_final_jr_troopa_80232170 = 0x80232170
export const RAM_b_area_mac_master1_takeTurn_8022AA54 = 0x8022aa54
export const RAM_func_80258E14 = 0x80258e14
export const RAM_dgb_02_exitDoubleDoor_80241604 = 0x80241604
export const RAM_mim_08_FlyingAI_JumpVels = 0x802443c0
export const RAM_func_80138740 = 0x80138740
export const RAM_b_area_jan_spear_guy_handleEvent_80218E3C = 0x80218e3c
export const RAM_bgm_set_song = 0x8014a918
export const RAM_mdl_renderModelFogColorR = 0x8014b754
export const RAM_dgb_08_SentinelAI_ChaseInit = 0x80242c58
export const RAM_b_area_omo_pink_shy_guy_8021EB04 = 0x8021eb04
export const RAM_omo_17_FlyingAI_Chase = 0x80241da8
export const RAM_state_init_end_battle = 0x80033b88
export const RAM_b_area_jan_hurt_plant_idleAnimations_8021D940 = 0x8021d940
export const RAM_flo_21_init_80243684 = 0x80243684
export const RAM_SetPanTarget = 0x802cbe2c
export const RAM_b_area_kpa3_UnkEffect6CFunc = 0x80218b60
export const RAM_D_802AC880 = 0x802ac880
export const RAM_virtual_entity_set_rotation = 0x802d43d0
export const RAM_npc_remove_decoration_seeing_stars = 0x8003c984
export const RAM_blendedCamSettings = 0x800a08c0
export const RAM_trd_02_PatrolAI_Move = 0x80240a0c
export const RAM_flo_12_searchBush = 0x80240e88
export const RAM_D_802A3C4C_007371EC = 0x802a3c4c
export const RAM_fx_confetti = 0x80071210
export const RAM_dro_02_pad_3BC8 = 0x80243bc8
export const RAM_au_copy_words = 0x80054f74
export const RAM_b_area_kmr_part_3_para_jr_troopa_defenseTable_80225E54 = 0x80225e54
export const RAM_ui_ultra_hammer_png = 0x800faca0
export const RAM_b_area_mim_forest_fuzzy_init_80218510 = 0x80218510
export const RAM_b_area_sam2_paragoomba_8021C574 = 0x8021d2f4
export const RAM_quizmo_stage_render = 0xe009c1dc
export const RAM_exec_ShakeCam1 = 0x802cb56c
export const RAM_b_area_iwa_monty_mole_defenseTable_8021A450 = 0x8021a450
export const RAM_gPausePanelTabs = 0x8024f314
export const RAM_kzn_11_FlyingAI_Wander = 0x80240610
export const RAM_dro_02_init_8024BBAC = 0x8024bbac
export const RAM_D_802A1388_00734928 = 0x802a1388
export const RAM_RemoveItemEntity = 0x802d6fcc
export const RAM_D_80097D70 = 0x80097d70
export const RAM_pause_tabs_update = 0x802455bc
export const RAM_D_E0124C28 = 0xe0124c28
export const RAM_D_E00DE8B4 = 0xe00de8b4
export const RAM_func_802CACC0 = 0x802cacc0
export const RAM_func_8026D940 = 0x8026d940
export const RAM_func_802427B0_8B2820 = 0x802427b0
export const RAM_battle_star_star_beam_SetNpcCollision32 = 0x802a14e8
export const RAM_func_80240050_A6ACF0 = 0x80240050
export const RAM_npc_update_decoration_seeing_stars = 0x8003c8b4
export const RAM_b_area_kpa3_UnkActorSizeFunc = 0x80218ab4
export const RAM_sam_08_SuperBlock_UpgradeOrbAngles = 0x80241b38
export const RAM_dro_02_npcSettings_80247458 = 0x80247458
export const RAM_fx_shattering_stones = 0x800700d0
export const RAM_D_09001258 = 0x09001258
export const RAM_mdl_get_copied_vertices = 0x8011c2b0
export const RAM_D_802BCFA8_E2D6D8 = 0x802bcfa8
export const RAM_func_802427C0_9C7BA0 = 0x802427c0
export const RAM_sam_01_Quizmo_HideWorld = 0x80240b2c
export const RAM_b_area_kmr_part_3_final_jr_troopa_8022CB2C = 0x8022cb2c
export const RAM_dup3_b_area_pra_ShrinkActor = 0x80218d50
export const RAM_b_area_trd_part_1_paratroopa_defenseTable_8021EAD0 = 0x8021ead0
export const RAM_battle_star_smooch_UnkBackgroundFunc = 0x802a1414
export const RAM_b_area_isk_part_2_chain_chomp_idleAnimations_80218C0C = 0x80218c0c
export const RAM_jan_05_MeleeHitbox_33 = 0x80241a80
export const RAM_D_80150A30 = 0x80150a30
export const RAM_b_area_sam2_gray_magikoopa_init_flying = 0x8022160c
export const RAM_D_09000A10_331910 = 0x09000a10
export const RAM_ui_sushie_pal = 0x800ff5a0
export const RAM_entity_HeartBlockContent_get_previous_yaw = 0x802e4710
export const RAM_evt_handle_set_timescale = 0x802c68d8
export const RAM_pause_badges_try_remove = 0x802472b0
export const RAM_jan_02_Quizmo_SetCamVfov = 0x80242180
export const RAM_b_area_sam_duplighost_attack = 0x8021b65c
export const RAM_vtx_C29570 = 0x80208630
export const RAM_D_80249C5C = 0x80249c5c
export const RAM_nuGfxSwapCfbFuncSet = 0x80060310
export const RAM_nok_01_Quizmo_GetItemName = 0x80240e1c
export const RAM_D_802440E0 = 0x802440e0
export const RAM_D_80249C90 = 0x80249c90
export const RAM_b_area_nok_nok_02_afterBattle_8022BACC = 0x8022bacc
export const RAM_dgb_02_npcGroupList_802425C8 = 0x802425c8
export const RAM_D_802DB830 = 0x802db830
export const RAM_b_area_kmr_part_2_pos05 = 0x802197c4
export const RAM_snd_start_sound = 0x800551e0
export const RAM_battle_menu_isEnabled = 0x802ad0d0
export const RAM_D_802BEC58 = 0x802bec58
export const RAM_D_E0080B0C = 0xe0080b0c
export const RAM_D_E00B8CA4 = 0xe00b8ca4
export const RAM_kkj_19_AddPlayerHandsOffset = 0x8024030c
export const RAM_b_area_nok_nok_03_afterBattle_8022BB40 = 0x8022bb40
export const RAM_b_area_sam2_gray_magikoopa_UnkBattleFunc1 = 0x80218994
export const RAM_HES_Item_CakeIcing = 0x80080640
export const RAM_b_area_kmr_part_2_red_goomba_2 = 0x8021eedc
export const RAM_D_802435D0_E15D30 = 0x802435d0
export const RAM_fold_init_state = 0x8013a9e8
export const RAM_func_80266FD8 = 0x80266fd8
export const RAM_D_80245434_9DC454 = 0x80245434
export const RAM_pause_tabs_draw_spirits = 0x80245038
export const RAM_CamPresetUpdate_J = 0x8024c570
export const RAM_FreezeBattleCam = 0x8024ed78
export const RAM_jan_02_PatrolAI_PostLoiter = 0x80240aac
export const RAM_D_802440E8 = 0x802440e8
export const RAM_mgm_00_PatrolNoAttackAI_15 = 0x80240db4
export const RAM_omo_03_SetPlayerStatusPosYaw = 0x8024020c
export const RAM_HES_HeaderBadges = 0x80242014
export const RAM_arn_08_func_802400F4_BF4894 = 0x802400f4
export const RAM_battle_action_cmd_water_block_circle_pal = 0x802aaba0
export const RAM_func_80238408_703EF8 = 0x80238408
export const RAM_flo_18_pad_66B4 = 0x802466b4
export const RAM_D_E009EDF0 = 0xe009edf0
export const RAM_jan_22_UnsetCamera0Flag1000 = 0x80240fc4
export const RAM_D_8024CB68_C0A3E8 = 0x8024cb68
export const RAM_D_E0090AC0 = 0xe0090ac0
export const RAM_dgb_04_npcGroup_80243B28 = 0x80243b28
export const RAM_dgb_09_FlyingAI_JumpInit = 0x80242000
export const RAM_func_80240020_AB2190 = 0x80240020
export const RAM_calc_partner_test_enemy = 0x8027df7c
export const RAM_world_bombette_put_away = 0x802be8d8
export const RAM_D_80247950_BF4740 = 0x80247950
export const RAM_D_802A1B9C = 0x802a1b9c
export const RAM_ui_battle_hp_6_pal = 0x8028ab00
export const RAM_init_hud_element_list = 0x80141300
export const RAM_kzn_07_FlyingAI_LosePlayer = 0x80241678
export const RAM_D_8024F1E8_A39428 = 0x8024f1e8
export const RAM_quizmo_assistant_update = 0xe011c0fc
export const RAM_mim_07_HoppingAI_LosePlayer = 0x80240e1c
export const RAM_battle_item_mushroom_GiveRefundCleanup = 0x802a11d4
export const RAM_func_802403E0_8CACE0 = 0x802403e0
export const RAM_CreateEntityVarArgBuffer1 = 0x8015c7d4
export const RAM_entity_init_SuperBlockContent2 = 0x802e5f7c
export const RAM_b_area_jan2_white_magikoopa_GetSelectedMoveID = 0x802183a8
export const RAM_flo_17_npcGroupList_80245228 = 0x80245228
export const RAM_b_area_isk_part_1_buzzy_beetle_idleAnimations_8021E678 = 0x8021e678
export const RAM_StartDefend = 0x802852b8
export const RAM_b_area_kmr_part_2_formation_00 = 0x80219768
export const RAM_omo_04_FlyingAI_LosePlayer = 0x802411f8
export const RAM_D_80247618_DF64B8 = 0x80247618
export const RAM_b_area_omo2_5_shy_squad_dup_displace_guy_2 = 0x80233088
export const RAM_b_area_mac_lee_lakilester = 0x80228564
export const RAM_D_802DFE44 = 0x802dfe44
export const RAM_b_area_kzn_medi_guy_idleAnimations_8021F890 = 0x8021f890
export const RAM_func_802BD564_317484 = 0x802bd564
export const RAM_kzn_19_UpdateTexturePanSmooth = 0x80240000
export const RAM_arn_08_8024116C = 0x8024116c
export const RAM_b_area_hos_hos_02_afterBattle_80225E20 = 0x80225e20
export const RAM_b_area_pra3_duplighost_kooper_statusTable = 0x8021d7ac
export const RAM_b_area_isk_part_2_tutankoopa_8021E9C8 = 0x8021e9c8
export const RAM_pra_18_reflection_setup_wall = 0x80240128
export const RAM_dead_wPartnerHudScripts = 0x80100060
export const RAM_btl_create_popup = 0x8024ef68
export const RAM_battle_menu_moveOptionBPCosts = 0x802ad440
export const RAM_b_area_kmr_part_2_red_goomba_2_takeTurn_8021FAF0 = 0x8021faf0
export const RAM_sfx_clear_sounds = 0x80149618
export const RAM_status_icons_rodata_alignment = 0x80098708
export const RAM_update_locomotion_state = 0x800e5f68
export const RAM_D_8014F950 = 0x8014f950
export const RAM_jan_22_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_b_area_jan_jan_00_80226C18 = 0x80226c18
export const RAM_jan_02_Quizmo_HideEntities = 0x8024172c
export const RAM_gPauseMsg_40 = 0x8026f6d0
export const RAM_kzn_17_AwaitPlayerNearNpc = 0x802424d0
export const RAM_D_80245460_EF2560 = 0x80245460
export const RAM_dgb_05_exitSingleDoor_80241580 = 0x80241580
export const RAM_dgb_04_FlyingAI_Wander = 0x802401b0
export const RAM_kpa_12_UpdateTexturePanSmooth = 0x80240040
export const RAM_D_8008FF60 = 0x8008ff60
export const RAM_D_8024F8E8_C0D168 = 0x8024f8e8
export const RAM_D_802435B8_E15D18 = 0x802435b8
export const RAM_RemoveActor = 0x8027c7b0
export const RAM_func_802194E0_5B2990 = 0x802194e0
export const RAM_D_80244840_AF1C50 = 0x80244840
export const RAM_b_area_iwa_iwa_02_afterBattle_802215B0 = 0x802215b0
export const RAM_D_80247A38_CCB248 = 0x80247a38
export const RAM_b_area_pra3_gray_magikoopa_idleAnimations3 = 0x8022f0c8
export const RAM_sam_11_Quizmo_UnkStageEffectMode = 0x80241240
export const RAM_D_E0052A88 = 0xe0052a88
export const RAM_HES_Item_HeartPiece = 0x800807f0
export const RAM_D_802B7A20_E20B30 = 0x802b7a20
export const RAM_arn_13_idle_802404F8 = 0x802404f8
export const RAM_pra_18_ReflectPartner = 0x80240d3c
export const RAM_b_area_pra3_duplighost_formation_sushie = 0x802251e4
export const RAM_D_80244838_AF1C48 = 0x80244838
export const RAM_EF2680_set_script_owner_npc_col_height = 0x802424b0
export const RAM_dro_02_Quizmo_Requirements = 0x80244e84
export const RAM_dgb_01_D_80253660_C10EE0 = 0x80253660
export const RAM_dup2_b_area_tik_UnkFunc56 = 0x802194c0
export const RAM_D_80245718_C4C2F8 = 0x80245718
export const RAM_PushSong = 0x802d5ff8
export const RAM_b_area_omo2_general_guy_idleAnimations_bomb = 0x802184b4
export const RAM_shop_draw_item_desc = 0x802814d0
export const RAM_omo_09_UnkNpcAIFunc48 = 0x80242548
export const RAM_D_802AA8B4_425524 = 0x802aa8b4
export const RAM_func_E007684C = 0xe007684c
export const RAM_func_8024072C_D3ACFC = 0x8024072c
export const RAM_mim_06_HoppingAI_LoiterInit = 0x8024247c
export const RAM_flo_11_Pipe_GetCameraYaw = 0x802401d0
export const RAM_D_80093D24 = 0x80093d24
export const RAM_dro_01_dropDoor_8024BDCC = 0x8024bdcc
export const RAM_b_area_pra3_duplighost_watt_takeTurn = 0x80222fd0
export const RAM_kmr_02_Quizmo_UnkStageEffectMode = 0x80240e00
export const RAM_ED4260_DeadUnkFunc1 = 0x80240040
export const RAM_HES_Item_Spaghetti = 0x80083078
export const RAM_ui_star_beam_png = 0x800fdec0
export const RAM_evt_handle_get_Nth_float = 0x802c614c
export const RAM_lightning_init = 0xe006c298
export const RAM_bSavedPartner = 0x8029da30
export const RAM_fold_update_gfx_cache = 0x8013a650
export const RAM_D_802A9984_429CA4 = 0x802a9984
export const RAM_D_8010C924 = 0x8010c924
export const RAM_D_802418B0_D645C0 = 0x802418b0
export const RAM_dgb_01_D_8025265C_C0FEDC = 0x8025265c
export const RAM_func_80243CD4_804554 = 0x80243cd4
export const RAM_omo_02_StationaryAI_Main = 0x802416d0
export const RAM_sbk_15_SetNpcB5_3 = 0x80240040
export const RAM_flo_24_settings = 0x802405c0
export const RAM_jan_11_GetFloorCollider = 0x802407b0
export const RAM_isk_10_SuperBlock_PartnerSparkles2 = 0x80240c78
export const RAM_mim_08_FlyingAI_Init = 0x80241288
export const RAM_HES_BoostJumpLoop = 0x80106ff0
export const RAM_isk_08_UnkDistFunc = 0x8024007c
export const RAM_SetNpcFlagBits = 0x802cf060
export const RAM_D_8029D8A0 = 0x8029d8a0
export const RAM_b_area_kmr_part_3_kmr_06_clouds2 = 0x802330e8
export const RAM_btl_cam_set_zoom = 0x8024e594
export const RAM_ui_battle_cmd_aim_marker_7_pal = 0x8028ea60
export const RAM_fx_63_init = 0xe00c6394
export const RAM_func_80241C78_8D3188 = 0x80241c78
export const RAM_HES_SmallText5 = 0x802421cc
export const RAM_kmr_00_settings = 0x02800100
export const RAM_battle_item_repel_gel_UseItemWithEffect = 0x802a12a0
export const RAM_flo_18_80244058 = 0x80244058
export const RAM_PeachDisguiseNpcIndex = 0x8010c96c
export const RAM_dgb_01_D_802533E0_C10C60 = 0x802533e0
export const RAM_func_800F0248 = 0x800f0248
export const RAM_HES_Item_SpikeShield = 0x80083bb8
export const RAM_D_E00BAE90 = 0xe00bae90
export const RAM_kzn_02_FlyingAI_Wander = 0x80240690
export const RAM_sam_02_TransformFoliage = 0x8024065c
export const RAM_D_E0074C00 = 0xe0074c00
export const RAM_func_802D286C = 0x802d286c
export const RAM_b_area_sam_duplighost_lakilester_parts = 0x80226290
export const RAM_dro_02_D_8024EF90 = 0x8024ef90
export const RAM_ui_status_text_8_pal = 0x80100500
export const RAM_snowman_doll_main = 0xe00c0000
export const RAM_D_8014BE50 = 0x8014be50
export const RAM_osViGetCurrentMode = 0x80066a30
export const RAM_HES_AButtonDown = 0x80108b54
export const RAM_load_model_transforms = 0x8011adc8
export const RAM_nuContRmbModeSet = 0x8005ff90
export const RAM_b_area_sam_gulpit_takeTurn = 0x802291b4
export const RAM_D_8024F2AF = 0x8024f2af
export const RAM_b_area_pra3_swoopula_idleAnimations_flying = 0x80226fa0
export const RAM_battle_item_egg_missile_EatItem = 0x802a17dc
export const RAM_D_80155D8C = 0x80155d8c
export const RAM_dgb_09_FlyingAI_Chase = 0x80242218
export const RAM_nok_12_PatrolAI_JumpInit = 0x80241474
export const RAM_flo_21_intTable_802425D4 = 0x802425d4
export const RAM_nok_15_FlyingAI_LoiterInit = 0x8024128c
export const RAM_b_area_kzn_lava_bubble_handleEvent_80219500 = 0x80219500
export const RAM_b_area_pra3_white_clubba_takeTurn = 0x8022b7fc
export const RAM_action_command_fire_shell_draw_hud_elements = 0x802a9834
export const RAM_D_80249A6C_A33CAC = 0x80249a6c
export const RAM_gHudElementsDLFrontUI = 0x8014f0a0
export const RAM_IES_DizzyDial = 0x8008dd74
export const RAM_func_80240364_92A614 = 0x80240364
export const RAM_ui_bombette_disabled_pal = 0x800fecc0
export const RAM_func_80240050_A834E0 = 0x80240050
export const RAM_D_80245B20_A99260 = 0x80245b20
export const RAM_kmr_03_802414C8 = 0x802414c8
export const RAM_flo_15_8024094C = 0x8024094c
export const RAM_flo_16_pad_36FC = 0x802436fc
export const RAM_D_802A9920 = 0x802a9920
export const RAM_HES_Item_StrangeLeaf = 0x80086d38
export const RAM_b_area_isk_part_2_tutankoopa_8021C974 = 0x8021c974
export const RAM_D_80151268 = 0x80151268
export const RAM_b_area_jan2_white_magikoopa_idleAnimations_80223E08 = 0x8021a1b8
export const RAM_b_area_sam_gray_magikoopa_statusTable_flying = 0x8022c3d4
export const RAM_b_area_mac_chan_handleEvent_8021A560 = 0x8021a560
export const RAM_au_load_PRG = 0x800545b4
export const RAM_D_80231388_63CF28 = 0x80231388
export const RAM_D_80242528_A4EF48 = 0x80242528
export const RAM_npc_raycast_up_corners = 0x800dd228
export const RAM_D_800B4520 = 0x800b4520
export const RAM_func_80242EC4_EA37C4 = 0x80242ec4
export const RAM_jan_12_WanderMeleeAI_Main = 0x80240cf0
export const RAM_D_800DC4FC = 0x800dc4fc
export const RAM_D_80246BE8 = 0x80246be8
export const RAM_b_area_flo2_SetBackgroundAlpha = 0x802188ec
export const RAM_b_area_pra3_swoopula_parts = 0x80226f08
export const RAM_func_80240E90_9EEA80 = 0x80240e90
export const RAM_omo_09_PatrolAI_Move = 0x802410a4
export const RAM_b_area_sam_gray_magikoopa_canBeKnockedOff = 0x8022cb2c
export const RAM_vtx_C29650 = 0x80208710
export const RAM_func_80266E40 = 0x80266e40
export const RAM_dgb_07_ClubbaNappingAI_Init = 0x8024061c
export const RAM_pra_09_reflection_setup_floor = 0x80240500
export const RAM_func_8024047C_8525EC = 0x8024047c
export const RAM_entity_MulticoinBlock_init = 0x802e3898
export const RAM_update_curtains = 0x8002ba78
export const RAM_hos_10_UpdateTexturePanSmooth = 0x80240020
export const RAM_jan_03_Quizmo_VannaTEffect = 0x8024ff00
export const RAM_battle_star_time_out_UnkStarFunc1 = 0x802a1000
export const RAM_dgb_09_ClubbaNappingAI_Loiter = 0x80240d1c
export const RAM_func_802418E8_A3ADC8 = 0x802418e8
export const RAM_D_800A08E0 = 0x800a08e0
export const RAM_func_802BD14C_324A5C = 0x802bd14c
export const RAM_update_window_hierarchy = 0x80147d70
export const RAM_D_8025599C = 0x8025599c
export const RAM_D_8010CFCA = 0x8010cfca
export const RAM_dgb_01_pad_3548 = 0x80243548
export const RAM_status_menu_start_blinking_sp_bars = 0x800e9ab8
export const RAM_D_802312F8_battle_area_omo2 = 0x802312f8
export const RAM_end_01_CreditsBufferIndex = 0x80243210
export const RAM_IES_StoneCap = 0x8008d6d4
export const RAM_HES_StatHammer1 = 0x80242934
export const RAM_trd_04_FlyingAI_LosePlayer = 0x80242c70
export const RAM_dgb_00_802409A8 = 0x802409a8
export const RAM_ui_battle_cont_b_down_pal = 0x8028b380
export const RAM_D_8014B898 = 0x8014b898
export const RAM_b_area_kzn_red_magikoopa_parts = 0x80220fd0
export const RAM_func_801491E4 = 0x801491e4
export const RAM_D_802A1408_766028 = 0x802a1408
export const RAM_arn_08_init_802413E8 = 0x802413e8
export const RAM_IES_MysteryNote = 0x8008b7c4
export const RAM_func_8026919C = 0x8026919c
export const RAM_check_input_open_menus = 0x800e6ce4
export const RAM_HES_Item_DoubleDip = 0x80083d38
export const RAM_b_area_sam_duplighost_OnShockDeath = 0x8021c784
export const RAM_D_80249D70 = 0x80249d70
export const RAM_D_80247D00 = 0x80247d00
export const RAM_kpa_32_UpdateTexturePanSmooth = 0x80240000
export const RAM_jan_09_foliage_setup_shear_mtx = 0x802405f0
export const RAM_func_8027143C = 0x8027143c
export const RAM_b_area_iwa_formation_00 = 0x80218210
export const RAM_gWindowDisappearFlags = 0x8014f2b4
export const RAM_func_802A948C = 0x802a948c
export const RAM_D_E0044E28 = 0xe0044e28
export const RAM_dgb_01_PatrolAI_Chase = 0x80242e78
export const RAM_b_area_omo2_4_signal_guy_parts = 0x802310b8
export const RAM_func_802416BC_A2B8FC = 0x802416bc
export const RAM_D_80286534 = 0x80286534
export const RAM_D_80241770_9996E0 = 0x80241770
export const RAM_flo_07_802428C4 = 0x802428c4
export const RAM_tik_17_UpdateTexturePanSmooth = 0x80241050
export const RAM_D_E0086AC0 = 0xe0086ac0
export const RAM_D_80241538_B9EE98 = 0x80241538
export const RAM_b_area_pra3_duplighost = 0x8021a050
export const RAM_func_80240000_91E570 = 0x80240000
export const RAM_flo_21_intTable_8024249C = 0x8024249c
export const RAM_DoShockHit = 0x8029a6fc
export const RAM_trd_02_PatrolAI_Loiter = 0x80240d74
export const RAM_b_area_omo2_6_shy_guy_dup_takeTurn = 0x8023173c
export const RAM_pra_19_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_D_E0044E20 = 0xe0044e20
export const RAM_IES_Jump = 0x8008b108
export const RAM_btl_state_update_peach_menu = 0x802a9b44
export const RAM_func_802BC358_E2EC88 = 0x802bc358
export const RAM_flo_16_SuperBlock_ShowSelectPartnerMenu = 0x802402ac
export const RAM_battle_item_pebble_png = 0x802a1800
export const RAM_b_area_nok_spiked_goomba_sinTable = 0x8021f050
export const RAM_D_802457B0_BA9F30 = 0x802457b0
export const RAM_set_entity_fog_color = 0x80123028
export const RAM_mac_01_ToadHouse_GetPartnerBackOut = 0x80243a70
export const RAM_pause_items_handle_input = 0x8024a7d8
export const RAM_b_area_kgr_fuzzipede_init_80218B70 = 0x80218b70
export const RAM_b_area_isk_part_1_buzzy_beetle_idle_8021E88C = 0x8021e88c
export const RAM_pause_items_comparator = 0x802497b0
export const RAM_SetupSplitSingleDoor = 0x80283d6c
export const RAM_func_802390C8_6F21A8 = 0x802390c8
export const RAM_battle_item_please_come_back_GiveRefund = 0x802a1000
export const RAM_ui_input_a_button_pressed_pal = 0x800f8ca0
export const RAM_D_E01187D8 = 0xe01187d8
export const RAM_D_8024ACB8_A34EF8 = 0x8024acb8
export const RAM_func_80260A20 = 0x80260a20
export const RAM_trd_01_StationaryAI_ReturnHomeInit = 0x80241104
export const RAM_dgb_18_pad_36D8 = 0x802436d8
export const RAM_D_80245BD0_A8C620 = 0x80245bd0
export const RAM_D_80243BB8_EAC698 = 0x80243bb8
export const RAM_EB7230_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_arn_tubba_blubba_80225F8C = 0x80225f8c
export const RAM_battle_star_up_and_away_UnkStarFunc2 = 0x802a10ac
export const RAM_pra_36_SetPartnerFlags20000 = 0x80240f1c
export const RAM_func_80240000_9BD660 = 0x80240000
export const RAM_set_part_flag_bits = 0x80263fe8
export const RAM_arn_08_init_80241288 = 0x80241288
export const RAM_reset_actor_blur = 0x802545ac
export const RAM_D_E0084E34 = 0xe0084e34
export const RAM_b_area_hos_magikoopa_idleAnimations_8021D71C = 0x8021d71c
export const RAM_GetPlayerTargetYaw = 0x802d1d28
export const RAM_ui_input_a_button_unpressed_png = 0x800f8500
export const RAM_b_area_nok_fuzzy_takeTurn_80222860 = 0x80222860
export const RAM_b_area_kmr_part_2_kmr_06_beforeBattle_80225788 = 0x80225788
export const RAM_hos_03_FlyingAI_Chase = 0x80241aa4
export const RAM_battle_star_focus_UnkStarFunc1 = 0x802a1000
export const RAM_dead_decode_yay0 = 0x80074070
export const RAM_flo_09_FlyingAI_JumpInit = 0x80240efc
export const RAM_toy_tank_as_5 = 0x80221384
export const RAM_b_area_isk_part_1_isk_01_beforeBattle_80223930 = 0x80223930
export const RAM_func_802419AC_84457C = 0x802419ac
export const RAM_func_802421CC_DB448C = 0x802421cc
export const RAM_kmr_02_StationaryAI_Chase = 0x80241800
export const RAM_pause_items_get_pos_y = 0x80249828
export const RAM_entity_base_block_idle = 0x802e3650
export const RAM_b_area_kmr_part_3_egg_jr_troopa_802250E4 = 0x802250e4
export const RAM_stop_watch_init = 0xe00c4170
export const RAM_filemenu_8024C088 = 0x8024c088
export const RAM_D_8024E930_C0C1B0 = 0x8024e930
export const RAM_sbk_56_SuperBlock_PartnerSparkles2 = 0x80240cf8
export const RAM_pra_14_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_D_8015A010 = 0x8015a010
export const RAM_pra_10_reflection_unk_change_anim_facing = 0x80240054
export const RAM_b_area_trd_part_2_green_ninja_koopa_partsTable_80225624 = 0x80225624
export const RAM_flo_24_main = 0x80240a3c
export const RAM_D_802E99FC = 0x802e99fc
export const RAM_update_hero_shadows = 0x80255fac
export const RAM_HES_PartnerADisabled = 0x80107ff0
export const RAM_SetupDoubleDoors = 0x80283df0
export const RAM_b_area_sbk_bandit_init_8021D7C4 = 0x8021d7c4
export const RAM_dgb_01_D_80252000_C0F880 = 0x80252000
export const RAM_flo_00_exitWalk_80241E34 = 0x80241e34
export const RAM_ExecutePlayerAction = 0x8028505c
export const RAM_gEntityColliderFaces = 0x8008ff70
export const RAM_D_E01187D0 = 0xe01187d0
export const RAM_kpa_134_GetEntityPosition = 0x8024065c
export const RAM_flo_19_pad_1A68 = 0x80241a68
export const RAM_D_802A99F4_42ED94 = 0x802a99f4
export const RAM_mac_01_GetPlayerCoins = 0x802440e8
export const RAM_b_area_omo_yellow_shy_guy_8021D72C = 0x8021d72c
export const RAM_b_area_omo_formation_0A = 0x8022fc08
export const RAM_hos_03_Quizmo_NPC_Aux_Impl = 0x80242850
export const RAM_func_802BB8D4_E2E204 = 0x802bb8d4
export const RAM_D_80243250_A9C7E0 = 0x80243250
export const RAM_HES_StatusDigit2 = 0x80108108
export const RAM_flo_00_interact_80243854 = 0x80243854
export const RAM_b_area_kmr_part_1_spiked_goomba_idle_8021E598 = 0x8021b1b8
export const RAM_D_E00926A4 = 0xe00926a4
export const RAM_vtx_C21360 = 0x80200420
export const RAM_mdl_bgMultiplyColorG = 0x8014b74e
export const RAM_D_F79DDD0F = 0xf79ddd0f
export const RAM_jan_09_Pipe_GetCameraYaw = 0x802401d0
export const RAM_pra_28_ReflectPartner = 0x80240d6c
export const RAM_D_80257B00_8C7B70 = 0x80257b00
export const RAM_ED42A0_UpdateTexturePanStepped = 0x802402bc
export const RAM_D_8024501C_D3F5EC = 0x8024501c
export const RAM_b_area_jan2_jan_01b_8021EBF8 = 0x8021ebf8
export const RAM_kkj_11_UnkPhysicsFunc = 0x802400b0
export const RAM_func_802A9A30 = 0x802a9a30
export const RAM_PlayerJump = 0x802d18e8
export const RAM_gWorldMapFlags = 0x802dbc70
export const RAM_D_8024FD68 = 0x8024fd68
export const RAM_HES_Item_HeartFinder = 0x80084ff8
export const RAM_D_8023D29C = 0x8023d29c
export const RAM_b_area_arn_tubba_blubba_80226B88 = 0x80226b88
export const RAM_b_area_hos_ember_8021C654 = 0x8021c654
export const RAM_CamPreset_D = 0x80280dc8
export const RAM_HES_Item_Unused_0F6_disabled = 0x80086048
export const RAM_ui_skolar_png = 0x800fd380
export const RAM_HES_Item_KootPackage = 0x80081c98
export const RAM_omo_08_ShyGuyWanderAI_14 = 0x80240870
export const RAM_D_8010BC50 = 0x8010bc50
export const RAM_trd_06_UpdateTexturePanStepped = 0x80240124
export const RAM_arn_12_defeat_802406E4 = 0x802406e4
export const RAM_dispatch_damage_event_actor_1 = 0x80278914
export const RAM_dgb_01_D_80252764_C0FFE4 = 0x80252764
export const RAM_func_80240E20_A01420 = 0x80240e20
export const RAM_vtx_C2CD20 = 0x8020bde0
export const RAM_func_80238358_6FB068 = 0x80238358
export const RAM_b_area_mac_chan_idleAnimations_80219EBC = 0x80219ebc
export const RAM_battle_star_peach_focus_UnkStarFunc2 = 0x802a10ac
export const RAM_battle_item_super_soda_func_802A123C_724F1C = 0x802a123c
export const RAM_EED1E0_SuperBlock_get_partner_rank = 0x802401b4
export const RAM_isk_09_StashVars = 0x80240000
export const RAM_Entity_GreenStompSwitch = 0x802e9c1c
export const RAM_D_8024E968_C0C1E8 = 0x8024e968
export const RAM_quizmo_assistant_render = 0xe011c17c
export const RAM_nuContPakCompanyCode = 0x80093ca0
export const RAM_arn_08_func_8024019C_BF493C = 0x8024019c
export const RAM_entity_RedSwitch_wait_and_reset = 0x802e1740
export const RAM_HES_Item_AttackFXF_disabled = 0x80085d48
export const RAM_update_encounters = 0x8003e524
export const RAM_func_802403D4_EE6984 = 0x802403d4
export const RAM_b_area_trd_part_2_fake_bowser_8021F630 = 0x8021f630
export const RAM_dro_02_802482A8 = 0x802482a8
export const RAM_sam_11_Quizmo_SetVannaAnim_Wave = 0x802412f4
export const RAM_dro_01_main = 0x80244e58
export const RAM_sam_08_MeleeHitbox_32 = 0x80240e1c
export const RAM_entity_shattering_block_init = 0x802e2eb0
export const RAM_b_area_jan_jan_04_afterBattle_802284B0 = 0x802284b0
export const RAM_mac_05_Quizmo_RenderInit = 0x80241680
export const RAM_HES_HPDigit4 = 0x80292048
export const RAM_b_area_nok_formationTable = 0x80219520
export const RAM_IES_Unused_0F4 = 0x8008cb64
export const RAM_GotoMapByID = 0x802ca440
export const RAM_flo_09_npcSettings_802436E4 = 0x802436e4
export const RAM_func_8024029C_B1B80C = 0x8024029c
export const RAM_func_802A92A0_42DCB0 = 0x802a92a0
export const RAM_b_area_nok_kent_c_koopa_takeTurn_80228A7C = 0x80228a7c
export const RAM_D_E005C704 = 0xe005c704
export const RAM_D_8024DDE0_968FE0 = 0x8024dde0
export const RAM_clear_sprite_shading_data = 0x80148040
export const RAM_b_area_sbk_pokey_8021BF50 = 0x8021bf50
export const RAM_dro_01_Quizmo_NPC_Aux_Impl = 0x8024181c
export const RAM_rdpstateinit_dl = 0x80093bb8
export const RAM_ui_battle_status_charge_jump_png = 0x80101780
export const RAM_D_E00427B0 = 0xe00427b0
export const RAM_big_snowflakes_render = 0xe006040c
export const RAM_au_BGMCmd_F3_TrackTremoloStop = 0x8004fffc
export const RAM_pra_16_ReflectWall = 0x802400ec
export const RAM_b_area_omo_formation_09 = 0x8022fb98
export const RAM_D_80248FF0_C06870 = 0x80248ff0
export const RAM_D_80098990 = 0x80098990
export const RAM_b_area_mac_formation_02 = 0x80232a08
export const RAM_ui_battle_hp_8_pal = 0x8028ab80
export const RAM_bPlayerStatusTable = 0x8028342c
export const RAM_au_song_is_playing = 0x800559fc
export const RAM_signF = 0x80029a7c
export const RAM_b_area_mac_master3_idleAnimations_8022D9A0 = 0x8022d9a0
export const RAM_dgb_09_exitWalk_80243B3C = 0x80243b3c
export const RAM_hos_00_varStash = 0x80242970
export const RAM_SetMusicTrack = 0x802d5d4c
export const RAM_b_area_mac_chan_nextTurn_8021CD7C = 0x8021cd7c
export const RAM_b_area_mim_bzzap_8021B77C = 0x8021b77c
export const RAM_filemenu_update_deselect_file = 0x80243b10
export const RAM_b_area_kzn_lava_bubble_idleAnimations_80219010 = 0x80219010
export const RAM_kmr_02_SetEntityPositionF = 0x802433ac
export const RAM_battle_item_thunder_rage_main = 0x802a1a5c
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_idle_802286C0 = 0x802286c0
export const RAM_b_area_nok_nok_04_foregroundModelList_8022BBC0 = 0x8022bbc0
export const RAM_npc_update_decoration_none = 0x8003c658
export const RAM_dro_02_D_80248D08_971EC8 = 0x80248d08
export const RAM_ui_battle_hp_bar_png = 0x8028a840
export const RAM_battle_item_stone_cap_GiveRefund = 0x802a1000
export const RAM_tik_10_SuperBlock_RadiateFaintEnergyFX = 0x80240c28
export const RAM_func_802A9744 = 0x802a9744
export const RAM_BattleCam_InitialBoomLength = 0x8029f28c
export const RAM_HES_StatusHP = 0x80108248
export const RAM_sam_08_MeleeHitbox_CanSeePlayer = 0x80240f48
export const RAM_D_80245340_CD0650 = 0x80245340
export const RAM_D_800777D8 = 0x800777d8
export const RAM_dead_create_shadow_type = 0x8011aab4
export const RAM_au_sfx_update_mode_0 = 0x8004c578
export const RAM_D_800F7B44 = 0x800f7b44
export const RAM_kzn_20_LetterDelivery_RestoreNpcAnim = 0x80240a3c
export const RAM_D_80075730 = 0x80075730
export const RAM_D_80244450_C3A980 = 0x80244450
export const RAM_D_80243830_B9B430 = 0x80243830
export const RAM_b_area_kzn_kzn_01_beforeBattle_80227780 = 0x80227780
export const RAM_gGlobalTimeSpace = 0x802d9ca8
export const RAM_ui_box_corners4_png = 0x8010b360
export const RAM_b_area_kmr_part_2_kmr_03_beforeBattle_80224AE8 = 0x80224ae8
export const RAM_func_80218DF8_48DF98 = 0x80218df8
export const RAM_pra_12_reflection_render_wall = 0x80240320
export const RAM_dgb_05_npcAI_80241A78 = 0x80241a78
export const RAM_flo_12_802419F4 = 0x802419f4
export const RAM_mgm_01_EndPanelAnimation = 0x80240bf4
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_statusTable_80227F68 = 0x80227f68
export const RAM_get_actor_part = 0x8026445c
export const RAM_func_80240000_A93440 = 0x80240000
export const RAM_sam_02_Quizmo_CreateReactionEffect = 0x80241b24
export const RAM_dgb_10_makeEntities = 0x80240f40
export const RAM_update_collider_transform = 0x8005ba24
export const RAM_b_area_mac_lee_8021F5F8 = 0x8021f5f8
export const RAM_b_area_hos_magikoopa_specialFormation_802216C8 = 0x802216c8
export const RAM_D_800A0956 = 0x800a0956
export const RAM_b_area_kmr_part_3_spiked_goomba_nextTurn_8021C0FC = 0x8021c0fc
export const RAM_IES_SmashCharge = 0x8008c084
export const RAM_trd_01_set_script_owner_npc_col_height = 0x80240704
export const RAM_kmr_02_UnkFunc42 = 0x802436fc
export const RAM_b_area_pra3_duplighost_watt_defenseTable = 0x802229b8
export const RAM_b_area_mac_master2_8022B8C0 = 0x8022b8c0
export const RAM_D_802AD002 = 0x802ad002
export const RAM_quizmo_assistant_init = 0xe011c0f4
export const RAM_omo_04_FlyingAI_Main = 0x802416ac
export const RAM_D_8014EE50 = 0x8014ee50
export const RAM_D_8010D650 = 0x8010d650
export const RAM_D_8023C1B4 = 0x8023c1b4
export const RAM_D_8007783C = 0x8007783c
export const RAM_HES_MenuBoots1Disabled = 0x8010876c
export const RAM_b_area_kzn_spike_top_spikeTop_HandleEvent_Floor = 0x8021d618
export const RAM_kmr_20_foliage_setup_shear_mtx = 0x80240380
export const RAM_pra_20_ReflectPartner = 0x80240d3c
export const RAM_flo_23_AddPlayerHandsOffset = 0x8024009c
export const RAM_HES_Item_CoinSparkleD = 0x80080d94
export const RAM_D_800A08E8 = 0x800a08e8
export const RAM_RunToGoal = 0x80279e64
export const RAM_b_area_isk_part_1_isk_07_pad = 0x80224d88
export const RAM_func_802BAF84_E2F8D4 = 0x802baf84
export const RAM_dgb_00_init_80241F70 = 0x80241f70
export const RAM_PartnerPowerBounceEnemy = 0x80280270
export const RAM_action_command_tidal_wave_CreateHudElements = 0x802a9000
export const RAM_trd_07_FlyingAI_Main = 0x80241df0
export const RAM_b_area_iwa_cleft_takeTurn_8021A3EC = 0x8021a3ec
export const RAM_flo_10_shakeTree = 0x802443f0
export const RAM_D_800B4310 = 0x800b4310
export const RAM_EA0C10_UnkDistFunc = 0x80241de4
export const RAM_D_E00B8B58 = 0xe00b8b58
export const RAM_func_802409C4_A2AC04 = 0x802409c4
export const RAM_b_area_sam_white_clubba_takeTurn = 0x8022a18c
export const RAM_dro_02_80248788 = 0x80248788
export const RAM_b_area_kmr_part_3_kmr_03_UnkAngleFunc2 = 0x80218fe0
export const RAM_dgb_11_80240570 = 0x80240570
export const RAM_kzn_04_SuperBlock_WaitForPlayerToLand = 0x80241018
export const RAM_flo_23_pad_2598 = 0x80242598
export const RAM_func_80241CCC_A3B1AC = 0x80241ccc
export const RAM_battle_star_peach_beam_UnkStarFunc4 = 0x802a1218
export const RAM_flo_16_SuperBlock_ShowUpgradeEffects = 0x80243764
export const RAM_ai = 0x8006ad64
export const RAM_tik_01_Pipe_GetCurrentFloor = 0x8024032c
export const RAM_battle_item_fright_jar_PlayerGoHome = 0x802a1790
export const RAM_HES_Item_BakingCleanser_disabled = 0x80087428
export const RAM_fio_calc_file_checksum = 0x8002b0d0
export const RAM_kpa_60_UpdateTexturePanStepped = 0x80240204
export const RAM_func_80240D00_BC8B60 = 0x80240d00
export const RAM_D_800DC4D0 = 0x800dc4d0
export const RAM_b_area_kmr_part_1_formation_05 = 0x80219330
export const RAM_b_area_pra3_pra_02_80231B40 = 0x80231b40
export const RAM_EB8E90_PatrolAI_LoiterInit = 0x80240440
export const RAM_func_80240678_AACEA8 = 0x80240678
export const RAM_b_area_jan2_jan_01b_afterBattle_8021F27C = 0x8021f27c
export const RAM_D_80097D80 = 0x80097d80
export const RAM_b_area_kmr_part_3_final_jr_troopa_idle_8022D058 = 0x8022d058
export const RAM_b_area_mac_master3_handleEvent_80231408 = 0x80231408
export const RAM_arn_07_80242A30 = 0x80242a30
export const RAM_mac_03_Quizmo_varStash = 0x80246644
export const RAM_load_battle = 0x80072c90
export const RAM_b_area_trd_part_1_trd_01_beforeBattle_80222AE0 = 0x80222ae0
export const RAM_func_80244D68_8055E8 = 0x80244d68
export const RAM_D_80243098_BD1C88 = 0x80243098
export const RAM_b_area_sbk_bandit_partsTable_8021D6B4 = 0x8021d6b4
export const RAM_D_E00427B8 = 0xe00427b8
export const RAM_jan_14_HoppingAI_Main = 0x802410b0
export const RAM_b_area_omo2_1_shy_squad_GetActorPartSize = 0x80231000
export const RAM_D_802A9810_428280 = 0x802a9810
export const RAM_dro_01_8024B5E4 = 0x8024b5e4
export const RAM_D_800B451C = 0x800b451c
export const RAM_func_80238B50_70C860 = 0x80238b50
export const RAM_D_80097D88 = 0x80097d88
export const RAM_flo_16_entryList = 0x80242970
export const RAM_gMsgBGScrollAmtY = 0x8015130c
export const RAM_update_player_blink = 0x800dffcc
export const RAM_func_802404D0_EFEFD0 = 0x802404d0
export const RAM_GetPartScale = 0x8026cc34
export const RAM_action_command_hammer_free_hud_elements = 0x802a9a30
export const RAM_btl_state_draw_end_partner_turn = 0x802472a4
export const RAM_func_80218CEC_47FD9C = 0x80218cec
export const RAM_flo_08_tree1 = 0x802458f0
export const RAM_fx_footprint = 0x8006fd10
export const RAM_isk_10_SuperBlock_CantUpgradeMessages = 0x80241100
export const RAM_b_area_pra2_crystal_king_pos_crystal_clone = 0x8021c80c
export const RAM_D_09002040_3883F0 = 0x09002040
export const RAM_func_80244934_A2EB74 = 0x80244934
export const RAM_func_802A97CC = 0x802a97cc
export const RAM_pause_banner_star_energy_pal = 0x8026dab0
export const RAM_b_area_kzn_lava_bubble_80219464 = 0x80219464
export const RAM_dgb_01_npcAI_802452C8 = 0x802452c8
export const RAM_func_8024059C_D3AB6C = 0x8024059c
export const RAM_flo_08_npcGroup_80244CD4 = 0x80244cd4
export const RAM_mim_06_MeleeHitbox_CanSeePlayer = 0x80240778
export const RAM_kzn_08_FlyingAI_WanderInit = 0x80240a50
export const RAM_b_area_kmr_part_3_final_jr_troopa_statusTable_8022C4E8 = 0x8022c4e8
export const RAM_sbk_11_SpawnSunEffect = 0x80240000
export const RAM_au_bgm_load_subsegment = 0x8004ead4
export const RAM_fx_pink_sparkles = 0x80072a10
export const RAM_D_802B6718_E274F8 = 0x802b6718
export const RAM_sam_11_UnsetCamera0MoveFlag1 = 0x80240564
export const RAM_func_802411D8_D59A68 = 0x802411d8
export const RAM_tik_01_Pipe_GetEntryPos = 0x802403fc
export const RAM_Gfx_LoadStencilTex_BlurryCircle = 0x8014e718
export const RAM_pra_11_reflection_render_floor_fancy = 0x80240870
export const RAM_hos_01_UnkEffect0FFunc3 = 0x8024031c
export const RAM_flo_23_ItemChoice_SelectedItemID = 0x80241310
export const RAM_iwa_01_MontyMoleAI_Burrow = 0x80241cf0
export const RAM_dgb_07_npcAISettings_802418DC = 0x802418dc
export const RAM_b_area_mac_lee_handleEvent_8021D600 = 0x8021d600
export const RAM_nok_11_FlyingAI_Init = 0x802419bc
export const RAM_b_area_iwa_iwa_01_80221414 = 0x80221414
export const RAM_D_80245D70_A994B0 = 0x80245d70
export const RAM_StartRumble = 0x80254218
export const RAM_battle_move_multibounce_D_802A11AC = 0x802a11ac
export const RAM_b_area_jan_medi_guy_partsTable_8021F99C = 0x8022265c
export const RAM_D_802A1DD8_73E638 = 0x802a1dd8
export const RAM_CamPreset_L = 0x80280e38
export const RAM_battle_item_sleepy_sheep_PlayerGoHome = 0x802a1da0
export const RAM_arn_05_80242C50 = 0x80242c50
export const RAM_D_80245234_9C2894 = 0x80245234
export const RAM_b_area_dgb_formation_02 = 0x8021b284
export const RAM_battle_star_up_and_away_FadeBackgroundToBlack = 0x802a12e4
export const RAM_arn_09_init_80240730 = 0x80240730
export const RAM_vtx_C23C70 = 0x80202d30
export const RAM_vtx_C26750 = 0x80205810
export const RAM_fx_energy_orb_wave = 0x80071750
export const RAM_b_area_sam2_sam_03_Set80071270_0_16 = 0x80219d18
export const RAM_osEPiWriteIo = 0x80060d60
export const RAM_huff_puff_breath_update = 0xe00dc198
export const RAM_func_801497FC = 0x801497fc
export const RAM_D_80245D38_A99478 = 0x80245d38
export const RAM_func_800457F8 = 0x800457f8
export const RAM_b_area_trd_part_1_bob_omb = 0x8021a730
export const RAM_b_area_pra3_duplighost_goombario_idle = 0x8021bd10
export const RAM_b_area_sbk_bandit_statusTable_8021D5C0 = 0x8021d5c0
export const RAM_b_area_kmr_part_3_formation_05 = 0x8021a258
export const RAM_b_area_pra3_duplighost_kooper_UnkActorPosFunc = 0x80218c40
export const RAM_func_802BB374_E2DCA4 = 0x802bb374
export const RAM_func_8025D3C4 = 0x8025d3c4
export const RAM_flo_13_TackleAI_Main = 0x8024262c
export const RAM_b_area_omo2_1_shy_squad_onShock = 0x80233494
export const RAM_tik_12_SuperBlock_ClearOverride40 = 0x80240c38
export const RAM_b_area_omo_spy_guy_idleAnimations_80227400 = 0x80227400
export const RAM_clear_virtual_entity_list = 0x802d4488
export const RAM_func_80219444_6A0644 = 0x80219444
export const RAM_D_802BCBC0_E31510 = 0x802bcbc0
export const RAM_state_drawUI_world = 0x80035da0
export const RAM_LoadPath = 0x802d5430
export const RAM_flashing_box_shockwave_update = 0xe0094198
export const RAM_IES_SuspiciousNote = 0x8008b7e4
export const RAM_D_802438E8_C59828 = 0x802438e8
export const RAM_sbk_46_SetNpcB5_3 = 0x80240140
export const RAM_D_80247680_D1FE60 = 0x80247680
export const RAM_b_area_kmr_part_3_paragoomba1_CalculateRotationZ = 0x80218464
export const RAM_iwa_01_RangedAttackAI_Main = 0x80241f6c
export const RAM_shop_owner_end_speech = 0x8028035c
export const RAM_entity_inactive_block_hit_init = 0x802e3714
export const RAM_SetActorJumpGravity = 0x8026b55c
export const RAM_jan_03_PatrolNoAttackAI_Main = 0x802411b4
export const RAM_func_80241D08_9914E8 = 0x80241d08
export const RAM_arn_02_npcGroup_802414CC = 0x802414cc
export const RAM_SpeakToPlayer = 0x802d02b0
export const RAM_b_area_trd_part_2_red_ninja_koopa_idleAnimations_802286DC = 0x802286dc
export const RAM_calc_player_test_enemy = 0x80271754
export const RAM_sam_maps = 0x80092b50
export const RAM_b_area_pra2_crystal_king_AttackIcyBreath = 0x8021b670
export const RAM_pra_28_SetPartnerFlagsA0000 = 0x80240eb4
export const RAM_shim_mem_clear = 0xe02004c0
export const RAM_vtx_C23260 = 0x80202320
export const RAM_D_80232B88_684908 = 0x80232b88
export const RAM_gPauseDLPathPoints = 0x8026f1b8
export const RAM_HES_Item_Unused_12F = 0x80086678
export const RAM_D_80164000 = 0x80164000
export const RAM_collision_heap_free = 0x8002adf4
export const RAM_D_80099BD0 = 0x80099bd0
export const RAM_D_800B4318 = 0x800b4318
export const RAM_mac_03_GetPlayerCoins = 0x80241f6c
export const RAM_gPauseWS_26 = 0x8026feb0
export const RAM_D_8024FB48_C0D3C8 = 0x8024fb48
export const RAM_D_8010BD30 = 0x8010bd30
export const RAM_dgb_15_unk_missing_80242100 = 0x80242100
export const RAM_can_btl_state_update_switch_to_player = 0x802a5818
export const RAM_HES_Item_PulseStone = 0x800815a8
export const RAM_flo_23_80241104 = 0x80241104
export const RAM_UseMystery = 0x80293e40
export const RAM_action_command_water_block_draw_hud_elements = 0x802aa014
export const RAM_b_area_isk_part_2_isk_03_beforeBattle_80220030 = 0x80220030
export const RAM_func_80240DA4_9087D4 = 0x80240da4
export const RAM_flo_24_tree1 = 0x80241704
export const RAM_D_80245600_DB78C0 = 0x80245600
export const RAM_iwa_01_CleftAI_PreAmbush = 0x802425c8
export const RAM_HES_ShrunkEnd = 0x80106b04
export const RAM_D_E00E8630 = 0xe00e8630
export const RAM_pause_spirits_cleanup = 0x8024d8d8
export const RAM_HES_Item_AutoSmash_disabled = 0x80083fa8
export const RAM_dead_load_asset_by_name = 0x8005f238
export const RAM_D_80228400_6CBF90 = 0x80228400
export const RAM_dro_02_D_80248CB0_971E70 = 0x80248cb0
export const RAM_spr_load_sprite = 0x802deb0c
export const RAM_flo_23_UnkDistFunc = 0x80240410
export const RAM_tik_17_SuperBlock_UpgradeOrbAngles = 0x80241478
export const RAM_func_80218058_5CE6E8 = 0x80218058
export const RAM_dgb_01_exitDoubleDoor_8024376C = 0x8024376c
export const RAM_D_802A9800_426970 = 0x802a9800
export const RAM_screen_overlay_backType = 0x80156908
export const RAM_b_area_hos_magikoopa_flying_clone = 0x80221694
export const RAM_b_area_kzn_formation_10 = 0x80229cb4
export const RAM_flo_09_triggerCoord_80243460 = 0x80243460
export const RAM_D_802BEB98_31B108 = 0x802beb98
export const RAM_D_E0128480 = 0xe0128480
export const RAM_dgb_15_exitSingleDoor_80241B30 = 0x80241b30
export const RAM_battle_item_fright_jar_DrinkItem = 0x802a18a4
export const RAM_sbk_62_SpawnSunEffect = 0x80240000
export const RAM_load_model_animator_node = 0x80120234
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_init_80230BCC = 0x80230bcc
export const RAM_sam_11_TransformFoliage = 0x802400b0
export const RAM_b_area_nok_koopa_troopa_idleAnimations_8021CB28 = 0x802200a8
export const RAM_D_801595A0 = 0x801595a0
export const RAM_D_8029D4E8 = 0x8029d4e8
export const RAM_D_80298948 = 0x80298948
export const RAM_kkj_15_UpdateTexturePanStepped = 0x80240124
export const RAM_D_A430000C = 0xa430000c
export const RAM_D_800A0BA0 = 0x800a0ba0
export const RAM_ui_status_text_hp_png = 0x801005c0
export const RAM_D_E0052A80 = 0xe0052a80
export const RAM_ui_status_pow_unit_7_pal = 0x801008a0
export const RAM_D_80245950_BAA0D0 = 0x80245950
export const RAM_tik_01_Pipe_GetCameraYaw = 0x802404dc
export const RAM_D_80232AB0_684830 = 0x80232ab0
export const RAM_D_8024F374 = 0x8024f374
export const RAM_kzn_03_MeleeHitbox_Main = 0x80240fb4
export const RAM_battle_item_snowman_doll_UseItemWithEffect = 0x802a1440
export const RAM_battle_item_repel_gel_main = 0x802a185c
export const RAM_pra_38_SetPartnerFlags80000 = 0x80240ee8
export const RAM_HES_Item_Cake = 0x80082e38
export const RAM_kmr_20_ToadHouse_DisableStatusMenu = 0x802412a0
export const RAM_WattTweesterPhysicsPtr = 0x802be274
export const RAM_pra_32_StarSpiritEffectFunc2 = 0x8024006c
export const RAM_dgb_01_D_80251E74_C0F6F4 = 0x80251e74
export const RAM_flo_24_exitWalk_8024093C = 0x8024093c
export const RAM_D_09001100 = 0x09001100
export const RAM_battle_menu_DisabledStrategiesHudScript = 0x802ab47c
export const RAM_D_8024995C_A33B9C = 0x8024995c
export const RAM_func_80245488_805D08 = 0x80245488
export const RAM_tik_17_SuperBlock_StartGlowEffect = 0x802404b0
export const RAM_play_ambient_sounds = 0x80147368
export const RAM_light_rays_appendGfx = 0xe006a94c
export const RAM_tik_19_SuperBlock_LoadCurrentPartnerName = 0x80240a38
export const RAM_func_80240080_B02900 = 0x80240080
export const RAM_TwinkUseAbility = 0x802bd1a8
export const RAM_func_80144278 = 0x80144278
export const RAM_NpcMoveTo = 0x802ce22c
export const RAM_flo_25_ItemChoice_SaveSelected = 0x80241dac
export const RAM_D_80245370_EEB920 = 0x80245370
export const RAM_flo_17_set_script_owner_npc_col_height = 0x80242458
export const RAM_D_80150A08 = 0x80150a08
export const RAM_kzn_03_PiranhaPlantAI_00 = 0x802414c8
export const RAM_HES_StatTimes = 0x80242b24
export const RAM_dgb_05_npcSettings_80241B20 = 0x80241b20
export const RAM_osr_01_UpdateTexturePanSmooth = 0x80240050
export const RAM_bMarioIdleAnims = 0x80283238
export const RAM_action_command_squirt_free_hud_elements = 0x802a9720
export const RAM_D_80159394 = 0x80159394
export const RAM_spr_swizzle_anim_offsets = 0x802deaa0
export const RAM_b_area_trd_part_1_koopa_troopa_statusTable_8021C998 = 0x8021c998
export const RAM_inflict_status = 0x80265f40
export const RAM_player_create_target_list = 0x8026302c
export const RAM_kmr_03_UnsetCamera0MoveFlag1 = 0x80240320
export const RAM_fire_flower_render = 0xe007e508
export const RAM_flo_19_UnkFloatFunc = 0x802407b0
export const RAM_D_09000370_3927B0 = 0x09000370
export const RAM_func_80240510_AA94B0 = 0x80240510
export const RAM_b_area_arn_hypergoomba_idleAnimations_80219864 = 0x80219864
export const RAM_set_world_fog_dist = 0x8011bb74
export const RAM_trd_06_UnkFunc41 = 0x80240af0
export const RAM_nok_01_LetterDelivery_RestoreNpcAnim = 0x80242410
export const RAM_func_80266DAC = 0x80266dac
export const RAM_D_802485C8_B34A38 = 0x802485c8
export const RAM_D_802BFE58_320BC8 = 0x802bfe58
export const RAM_D_80294340 = 0x80294340
export const RAM_battle_partner_goombario_idle_8023818C = 0x8023818c
export const RAM_flo_14_FlyingAI_Loiter = 0x802413a8
export const RAM_b_area_trd_part_1_trd_05c_beforeBattle_80226324 = 0x80226324
export const RAM_kzn_08_FlyingAI_ChaseInit = 0x80241710
export const RAM_func_80240D10_BD4580 = 0x80240d10
export const RAM_D_8029F254 = 0x8029f254
export const RAM_fold_update = 0x8013aa9c
export const RAM_ui_move_basic_disabled_pal = 0x80103ac0
export const RAM_flo_16_func_80242940_CD4770 = 0x80242940
export const RAM_D_8024373C_CFB2AC = 0x8024373c
export const RAM_func_802446AC_804F2C = 0x802446ac
export const RAM_flo_08_SuperBlock_OnHit = 0x80242178
export const RAM_func_80242260_EE8810 = 0x80242260
export const RAM_D_8024C420_C09CA0 = 0x8024c420
export const RAM_HES_Item_Unused_0F5 = 0x80085fb8
export const RAM_nok_02_LetterDelivery_RestoreNpcAnim = 0x80242fb8
export const RAM_fire_flower_update = 0xe007e204
export const RAM_hos_03_FlyingAI_Wander = 0x80240e60
export const RAM_D_802463C8_915A78 = 0x802463c8
export const RAM_recover_fp = 0x800ea264
export const RAM_b_area_trd_part_1_paratroopa_idleAnimations_8021ED98 = 0x8021ed98
export const RAM_dro_02_Quizmo_MobileAISettings = 0x80247360
export const RAM_iwa_03_CleftAI_Hiding = 0x80240514
export const RAM_bgm_update_volume = 0x8014ac94
export const RAM_b_area_kmr_part_3_paragoomba1_handleEvent_8021DF5C = 0x8021df5c
export const RAM_dgb_09_FlyingAI_Wander = 0x802415d4
export const RAM_b_area_iwa_iwa_01_80221334 = 0x80221334
export const RAM_hud_element_draw_without_clipping = 0x801447e0
export const RAM_dgb_01_D_80253920_C111A0 = 0x80253920
export const RAM_mac_02_Quizmo_UpdatePartnerPosition = 0x802426d0
export const RAM_spiky_white_aura_appendGfx = 0xe0038748
export const RAM_kzn_08_FlyingAI_LoiterInit = 0x80241228
export const RAM_flo_07_StashVars = 0x8024061c
export const RAM_HES_Item_DriedShroom = 0x80086858
export const RAM_HES_StickMashLeft = 0x80108f30
export const RAM_star_outline_main = 0xe0126000
export const RAM_smoke_ring_init = 0xe002e248
export const RAM_fio_validate_header_checksums = 0x8002af70
export const RAM_sam_08_SuperBlock_EndGlowEffect = 0x80240510
export const RAM_entity_RedSwitch_animate_scale = 0x802e17a8
export const RAM_D_802AD670 = 0x802ad670
export const RAM_b_area_jan2_white_magikoopa_idleAnimations_80223DBC = 0x8021a16c
export const RAM_pra_10_SetPartnerFlags80000 = 0x80240eb8
export const RAM_b_area_sam_sam_02b_beforeBattle = 0x8022fad8
export const RAM_spr_get_player_palettes = 0x802de0c0
export const RAM_battle_item_fright_jar_GiveRefundCleanup = 0x802a11d4
export const RAM_battle_item_food_script6 = 0x802a1bec
export const RAM_EA0C10_MeleeHitbox_CanSeePlayer = 0x80240518
export const RAM_spr_init_component_anim_state = 0x802dd7b0
export const RAM_D_09001050_3AC080 = 0x09001050
export const RAM_b_area_isk_part_2_tutankoopa_8021F80C = 0x8021f80c
export const RAM_physicalSaveInfo = 0x8009ba50
export const RAM_D_E0088CDC = 0xe0088cdc
export const RAM_b_area_isk_part_2_chain_chomp_ChompChainUpdate = 0x8021825c
export const RAM_GetPushBlock = 0x802835c0
export const RAM_flo_11_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_func_80243054_EA3954 = 0x80243054
export const RAM_b_area_kmr_part_2_red_goomba_2_init_8021EF9C = 0x8021ef9c
export const RAM_func_80243214_9DA234 = 0x80243214
export const RAM_set_status_icons_properties = 0x8004778c
export const RAM_D_80241E30_A6CAD0 = 0x80241e30
export const RAM_b_area_jan_spear_guy_spearThrow = 0x8021acbc
export const RAM_D_80243F98_E12738 = 0x80243f98
export const RAM_jan_08_SuperBlock_LoadCurrentPartnerName = 0x80240aa8
export const RAM_setup_item_popup = 0x800e6c44
export const RAM_b_area_omo_groove_guy_statusTable_80224A40 = 0x80224a40
export const RAM_ECBB70_UnkFunc43 = 0x80241e70
export const RAM_sDlists = 0xe0002800
export const RAM_omo_04_GetItemName = 0x802400b4
export const RAM_HES_Item_PowerStar_disabled = 0x800864c8
export const RAM_arn_13_80240248 = 0x80240248
export const RAM_func_802186D8_609188 = 0x802186d8
export const RAM_kmr_10_varStash = 0x80240c20
export const RAM_func_80240148_BB5B28 = 0x80240148
export const RAM_D_800B430D = 0x800b430d
export const RAM_D_09000A30_32D8F0 = 0x09000a30
export const RAM_pra_01_GetNpcCollisionHeight = 0x80240f80
export const RAM_D_8029FB90 = 0x8029fb90
export const RAM_D_80257F10_8C7F80 = 0x80257f10
export const RAM_arn_12_idle_802404E8 = 0x802404e8
export const RAM_InterpMotionBlurParams = 0x802d7ba4
export const RAM_D_8009A5C4 = 0x8009a5c4
export const RAM_flo_03_npcGroup_80243E5C = 0x80243e5c
export const RAM_HES_Item_CakeMix = 0x80086d98
export const RAM_D_80159E7C = 0x80159e7c
export const RAM_HES_Item_MiracleWater = 0x80081a88
export const RAM_dgb_01_D_80251ECC_C0F74C = 0x80251ecc
export const RAM_kpa_08_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_D_80098720 = 0x80098720
export const RAM_HES_Item_DojoCard1 = 0x80081ed8
export const RAM_kpa_62_SetEntityFlags100000 = 0x80240000
export const RAM_D_8029D368 = 0x8029d368
export const RAM_HES_AButton = 0x80108b28
export const RAM_D_8024DBCC_A37E0C = 0x8024dbcc
export const RAM_kpa_03_MagikoopaAI_22 = 0x802418ac
export const RAM_pra_05_reflection_setup_floor = 0x80240500
export const RAM_b_area_sam2_paragoomba_statusTable_8021B1D4 = 0x8021bf54
export const RAM_D_09002000_3883B0 = 0x09002000
export const RAM_b_area_jan_spike_top_defenseTable_8021C8B0 = 0x80223b20
export const RAM_mac_03_Quizmo_SpinPlayer = 0x802412cc
export const RAM_b_area_dgb_formationTable = 0x8021b38c
export const RAM_tik_25_SetEntityPosition = 0x802406ac
export const RAM_kpa_16_UpdateTexturePanStepped = 0x80240124
export const RAM_kkj_00_PatrolAI_Loiter = 0x80240570
export const RAM_dgb_07_WanderMeleeAI_Main = 0x80241170
export const RAM_kzn_02_FlyingAI_LosePlayer = 0x80241338
export const RAM_func_802430CC_9DA0EC = 0x802430cc
export const RAM_flo_14_FlyingAI_Init = 0x80241cf8
export const RAM_gPauseTutorialIconIDs = 0x8024f090
export const RAM_HES_MenuPouchDisabled = 0x80292278
export const RAM_vtx_C2B560 = 0x8020a620
export const RAM_D_E011AC24 = 0xe011ac24
export const RAM_evt_handle_set_priority = 0x802c689c
export const RAM_action_command_stop_leech_free_hud_elements = 0x802a9544
export const RAM_IES_DodgeMaster = 0x8008bf44
export const RAM_integer_log = 0x8012fe10
export const RAM_b_area_arn_hyper_paragoomba_handleEvent_8021F41C = 0x8021f41c
export const RAM_update_player_shadow = 0x800e1bc4
export const RAM_get_xz_dist_to_player = 0x800e0088
export const RAM_func_802412BC_BD4B2C = 0x802412bc
export const RAM_jan_02_PatrolAI_MoveInit = 0x80240430
export const RAM_D_80231468_63D008 = 0x80231468
export const RAM_D_802B6EF4 = 0x802b6ef4
export const RAM_func_8023FF84 = 0x8023ff84
export const RAM_nuScWaitTaskReady = 0x8005f120
export const RAM_msg_printer_load_msg = 0x80125a68
export const RAM_partner_init_after_battle = 0x800eb200
export const RAM_gv_01_Pipe_SetAnimFlag = 0x80240000
export const RAM_HES_Item_EggMissile_disabled = 0x80083108
export const RAM_b_area_isk_part_2_tutankoopa_handleEvent_8021BFD0 = 0x8021bfd0
export const RAM_func_802B62A4_E25174 = 0x802b62a4
export const RAM_b_area_omo2_5_shy_squad_dup_onShock = 0x80232fc0
export const RAM_D_802417C8_9091F8 = 0x802417c8
export const RAM_b_area_jan2_white_magikoopa_wMagikoopa_WandStrike = 0x8021ba74
export const RAM_check_for_conversation_prompt = 0x800e0580
export const RAM_shockwave_init = 0xe004a328
export const RAM_D_09000880_364140 = 0x09000880
export const RAM_D_8010D688 = 0x8010d688
export const RAM_ui_battle_cmd_aim_shimmer_6_png = 0x8028ee40
export const RAM_BattleCam_TargetActorPart = 0x8029f27e
export const RAM_sam_03_WanderMeleeAI_Main = 0x8024066c
export const RAM_dgb_05_802418C0 = 0x802418c0
export const RAM_mac_00_Quizmo_RenderInit = 0x80240990
export const RAM_D_E001A610 = 0xe001a610
export const RAM_b_area_pra3_duplighost_formation_bow = 0x80222950
export const RAM_func_80240B10_ED4D30 = 0x80240b10
export const RAM_HES_Item_Unused_02F = 0x80083918
export const RAM_D_80247664 = 0x80247664
export const RAM_func_802BD754_31D2C4 = 0x802bd754
export const RAM_ui_status_star_point_0_pal = 0x801011c0
export const RAM_b_area_mac_master3_doubleStrike3 = 0x8022ea64
export const RAM_b_area_omo_sky_guy_80229A34 = 0x80229a34
export const RAM_dgb_15_MeleeHitbox_31 = 0x8024006c
export const RAM_b_area_pra3_duplighost_parakarry_takeTurn = 0x80221564
export const RAM_D_80150A00 = 0x80150a00
export const RAM_D_8026FCF0 = 0x8026fcf0
export const RAM_sbk_15_SpawnSunEffect = 0x80240000
export const RAM_ItemEntityAlternatingSpawn = 0x80155d84
export const RAM_dup_kkj_11_UnkModelMatrixFunc2 = 0x80240b5c
export const RAM_filemenu_yesno_handle_input = 0x802471ec
export const RAM_EF2680_FlyingAI_LosePlayer = 0x80240ec8
export const RAM_gPauseMapCursorCurrentOption = 0x8027071c
export const RAM_dgb_01_D_80252D94_C10614 = 0x80252d94
export const RAM_D_80235FC0 = 0x80218000
export const RAM_b_area_jan_spear_guy_init_80218980 = 0x80218980
export const RAM_b_area_kmr_part_2_kmr_06_clouds1 = 0x80225400
export const RAM_HES_Item_ISpy = 0x800851d8
export const RAM_EED1E0_SuperBlock_CountEligiblePartners = 0x80240200
export const RAM_HES_Item_StopWatch = 0x80087758
export const RAM_nuGfxDisplay = 0x8009a5f8
export const RAM_pra_14_GetItemName = 0x80240fd4
export const RAM_wPartnerCurrentScriptID = 0x8010cfdc
export const RAM_tik_03_UpdateTexturePanStepped = 0x80240404
export const RAM_flo_16_SuperBlock_WhiteScreenFlash = 0x80240c18
export const RAM_D_802434A0_A63E70 = 0x802434a0
export const RAM_dup3_b_area_flo_GetSelectedMoveID = 0x80219208
export const RAM_dgb_16_ClubbaNappingAI_LoiterInit = 0x802409c0
export const RAM_HES_CLeftButtonHeld = 0x80292464
export const RAM_spr_appendGfx_component_flat = 0x802dc008
export const RAM_sbk_16_foliage_setup_shear_mtx = 0x80240040
export const RAM_BattleCam_InitialPosY = 0x8029f2ac
export const RAM_nok_01_AddPlayerHandsOffset = 0x80241e58
export const RAM_D_8024F594 = 0x8024f594
export const RAM_D_802454B8_BB2DF8 = 0x802454b8
export const RAM_set_screen_overlay_params_back = 0x80137da4
export const RAM_b_area_iwa_cleft_StartRumbleWithParams = 0x8021805c
export const RAM_b_area_pra3_red_magikoopa_8022CA48 = 0x8022ca48
export const RAM_D_8024EA14 = 0x8024ea14
export const RAM_jan_06_SpearGuyAI_LoiterInit = 0x80241608
export const RAM_StopSound = 0x802d62b8
export const RAM_D_80294440 = 0x80294440
export const RAM_D_800B0D08 = 0x800b0d08
export const RAM_dgb_01_D_80252630_C0FEB0 = 0x80252630
export const RAM_padding2 = 0x802acc04
export const RAM_sam_10_WanderMeleeAI_Main = 0x802409ec
export const RAM_D_8029DA48 = 0x8029da48
export const RAM_D_8029D5C8 = 0x8029d5c8
export const RAM_ui_battle_projector_spot_png = 0x8028f868
export const RAM_D_80244D08_DB2108 = 0x80244d08
export const RAM_b_area_sam2_sam_02_UnkModelStuffScript3 = 0x80222314
export const RAM_HES_Item_DDownJump = 0x80085598
export const RAM_battle_star_focus_UnkStarFunc4 = 0x802a1218
export const RAM_tik_07_FlyingAI_Wander = 0x802415a0
export const RAM_D_8024BE48_D2BCD8 = 0x8024be48
export const RAM_battle_item_stop_watch_DrinkItem = 0x802a18a4
export const RAM_D_E0116D90 = 0xe0116d90
export const RAM_b_area_pra2_pos_crystal_bit_3 = 0x80221524
export const RAM_D_8014AFB0 = 0x8014afb0
export const RAM_battle_move_auto_multibounce_D_802A2184 = 0x802a2184
export const RAM_sbk_13_SpawnSunEffect = 0x80240000
export const RAM_HES_Clock = 0x802429fc
export const RAM_kpa_102_UnkDistFunc = 0x802415d8
export const RAM_func_800F4C1C = 0x800f4c1c
export const RAM_D_802BE924 = 0x802be924
export const RAM_dro_01_dro_01_name_hack = 0x8024dfb0
export const RAM_func_802189B0_4EFE50 = 0x802189b0
export const RAM_D_8023CD50_6F5E30 = 0x8023cd50
export const RAM_D_8024990C_A33B4C = 0x8024990c
export const RAM_vtx_C26510 = 0x802055d0
export const RAM_dgb_00_init_80241FB4 = 0x80241fb4
export const RAM_b_area_trd_part_1_paratroopa_8021EE84 = 0x8021ee84
export const RAM_basic_ai_loiter = 0x80049c04
export const RAM_D_80241B14_AF9464 = 0x80241b14
export const RAM_D_09002270_388620 = 0x09002270
export const RAM_IES_HappyHeart = 0x8008c3c4
export const RAM_pra_16_ReflectFloor = 0x8024049c
export const RAM_D_80098968 = 0x80098968
export const RAM_b_area_kzn_white_magikoopa_flying = 0x80223d48
export const RAM_D_80099DD0 = 0x80099dd0
export const RAM_entity_switch_fall_down = 0x802e153c
export const RAM_tik_07_SuperBlock_ClearOverride40 = 0x802402fc
export const RAM_D_E0046600 = 0xe0046600
export const RAM_pause_badges_draw_bp_orbs = 0x80247458
export const RAM_kmr_03_tree1 = 0x80242294
export const RAM_D_80280EB8 = 0x80280eb8
export const RAM_battle_move_power_jump_UnkMoveFunc1 = 0x802a1000
export const RAM_flo_13_npcAISettings_80244144 = 0x80244144
export const RAM_kmr_03_80242340 = 0x80242340
export const RAM_flo_19_UpdateTexturePanSmooth = 0x80240000
export const RAM_dro_02_D_802479B8_970B78 = 0x802479b8
export const RAM_gBattleMapFlags = 0x802dbd34
export const RAM_dgb_07_MeleeHitbox_32 = 0x802400dc
export const RAM_b_area_kzn_white_magikoopa_healOne = 0x80226020
export const RAM_func_8027D628 = 0x8027d628
export const RAM_mim_06_WanderMeleeAI_Main = 0x80240b8c
export const RAM_hud_element_draw_clipped = 0x801447a8
export const RAM_battle_move_sleep_stomp_UnkMoveFunc1 = 0x802a1000
export const RAM_func_802C03E8 = 0x802bd4e8
export const RAM_tik_12_SuperBlock_get_partner_rank = 0x80240cf0
export const RAM_contPakFree = 0x8005f748
export const RAM_b_area_pra2_formationTable = 0x802215a0
export const RAM_flo_13_80244888 = 0x80244888
export const RAM_kzn_19_LetterDelivery_RestoreNpcAnim = 0x80241b94
export const RAM_kzn_17_MeleeHitbox_33 = 0x802404c4
export const RAM_battle_star_star_beam_UnkStarFunc3 = 0x802a116c
export const RAM_sam_11_Quizmo_SpinPartner = 0x80241650
export const RAM_kzn_17_LetterDelivery_SaveNpcAnim = 0x80242c84
export const RAM_BowTweesterPhysics = 0x802be0c8
export const RAM_HES_Item_Unused_139 = 0x80082538
export const RAM_b_area_arn_hyper_paragoomba_8021C94C = 0x8021c94c
export const RAM_dro_02_pad_4DD4 = 0x80244dd4
export const RAM_b_area_trd_part_2_trd_03 = 0x80232940
export const RAM_try_player_footstep_sounds = 0x800e5098
export const RAM_b_area_kmr_part_2_goomnut_tree_defenseTable_802232DC = 0x802232dc
export const RAM_b_area_jan_jan_01b_beforeBattle_80228174 = 0x80228174
export const RAM_D_802E0C64 = 0x802e0c64
export const RAM_b_area_trd_part_1_bill_blaster_partsTable_80219DD8 = 0x80219dd8
export const RAM_osSetEventMesg = 0x80065950
export const RAM_isk_10_SuperBlock_WhiteScreenFlash = 0x80240b38
export const RAM_omo_09_ShyGuyPatrolAI_15 = 0x80241e4c
export const RAM_b_area_isk_part_1_isk_04 = 0x80223b30
export const RAM_SetVirtualEntityRotation = 0x802d3840
export const RAM_mac_02_Quizmo_SetVannaAnim_Wave = 0x802422e4
export const RAM_HES_Item_PowerStar = 0x80086498
export const RAM_mac_05_PatrolAI_LoiterInit = 0x80240890
export const RAM_b_area_isk_part_1_vector3D_802241F4 = 0x802241f4
export const RAM_dead_sfx_get_spatialized_sound_params = 0x801574dc
export const RAM_battle_partner_kooper_takeTurn_80238974 = 0x80238974
export const RAM_b_area_hos_ember_idle_8021B07C = 0x8021b07c
export const RAM_sam_03_MeleeHitbox_32 = 0x8024012c
export const RAM_sam_01_Quizmo_UpdatePartnerPosition = 0x80241610
export const RAM_D_802943E0 = 0x802943e0
export const RAM_b_area_sam2_gray_magikoopa_statusTable = 0x8021f148
export const RAM_battle_item_life_shroom_func_802A123C_72E76C = 0x802a123c
export const RAM_GetDistanceToGoal = 0x8026e9fc
export const RAM_b_area_dgb_dgb_01 = 0x8021b080
export const RAM_pause_tutorial_input = 0x802442a0
export const RAM_D_80246480_EA6D80 = 0x80246480
export const RAM_D_800F7FF4 = 0x800f7ff4
export const RAM_kzn_07_FlyingAI_WanderInit = 0x80240820
export const RAM_flo_03_80240BE4 = 0x80240be4
export const RAM_b_area_pra3_pra_03c_beforeBattle = 0x80232030
export const RAM_arn_07_npcSettings_80243D74 = 0x80243d74
export const RAM_b_area_trd_part_1_koopa_troopa_UnkBattleFunc1 = 0x80218020
export const RAM_IES_Boots2 = 0x8008b290
export const RAM_D_8023CD90_6F5E70 = 0x8023cd90
export const RAM_EDEA00_FlyingAI_Loiter = 0x80240a5c
export const RAM_b_area_kzn_formation_0B = 0x80229b10
export const RAM_state_render_frontUI = 0x80112fc4
export const RAM_func_E00DC2FC = 0xe00dc2fc
export const RAM_EED1E0_SuperBlock_WaitForPlayerToLand = 0x80240de8
export const RAM_nuPiCartHandle = 0x8009a638
export const RAM_D_80243254_9F0E44 = 0x80243254
export const RAM_pra_28_SetPartnerFlags20000 = 0x80240f1c
export const RAM_flo_14_802432E8 = 0x802432e8
export const RAM_D_80244400_C3A930 = 0x80244400
export const RAM_mac_02_PatrolAI_Jump = 0x802410d4
export const RAM_gather_energy_pink_main = 0xe0026000
export const RAM_IES_UltraStone = 0x8008b924
export const RAM_dgb_08_defeat_80244E58 = 0x80244e58
export const RAM_HES_Item_KoopaFortressKey = 0x80081458
export const RAM_vtx_C23A70 = 0x80202b30
export const RAM_sbk_51_SetNpcB5_3 = 0x80240140
export const RAM_b_area_mim_formation_00 = 0x8021e4c0
export const RAM_jan_06_HoppingAI_Hop = 0x80240970
export const RAM_HES_Item_CakeBowl = 0x800805e0
export const RAM_ui_battle_swap_arrow_right_pal = 0x8028a2c0
export const RAM_dro_02_D_8024A3B8_973578 = 0x8024a3b8
export const RAM_ui_item_pal = 0x800fb7a0
export const RAM_D_80246560_kkj_19 = 0x80246560
export const RAM_b_area_kgr_kgr_01_8021CDA4 = 0x8021cda4
export const RAM_D_E00A8648 = 0xe00a8648
export const RAM_D_802C0410 = 0x802c0410
export const RAM_func_80218680_4FF0D0 = 0x80218680
export const RAM_b_area_sbk_pokey_defenseTable_80218280 = 0x80218280
export const RAM_b_area_arn_tubbas_heart_statusTable_80223C84 = 0x80223c84
export const RAM_HES_Item_CoinSparkleRandom = 0x80080fc4
export const RAM_ED4220_get_tattle = 0x80240000
export const RAM_D_802458F0 = 0x802458f0
export const RAM_D_8010C320 = 0x8010c320
export const RAM_D_09001300 = 0x09001300
export const RAM_flo_22_idle_80240778 = 0x80240778
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_GetLastActorEventType = 0x8021a288
export const RAM_b_area_pra3_duplighost_bombette_idleAnimations = 0x8021f3b0
export const RAM_vtx_C2B220 = 0x8020a2e0
export const RAM_func_80240B18_B07518 = 0x80240b18
export const RAM_arn_12_80240060 = 0x80240060
export const RAM_sam_08_SuperBlock_UpgradeDescMessages = 0x80241af8
export const RAM_DoFreezeHit = 0x8029466c
export const RAM_EF2680_UnkNpcAIFunc12 = 0x8024234c
export const RAM_D_8023C1BC = 0x8023c1bc
export const RAM_hos_10_SetPlayerSpriteSet2 = 0x80240000
export const RAM_b_area_sam2_sam_01_beforeBattle = 0x802220a8
export const RAM_b_area_omo2_stageTable = 0x8022fc1c
export const RAM_ui_red_bar1_pal = 0x800fcb40
export const RAM_D_80245A38_A8C488 = 0x80245a38
export const RAM_balloon_update = 0xe00960f4
export const RAM_D_80245840_C76CC0 = 0x80245840
export const RAM_func_80241D7C_A2BFBC = 0x80241d7c
export const RAM_kmr_02_Quizmo_SpinPartner = 0x80241210
export const RAM_kmr_06_FlyingAI_Init = 0x802411e8
export const RAM_b_area_pra_UnkBattleFunc5 = 0x8021948c
export const RAM_bgm_init_music_players = 0x8014ac08
export const RAM_dgb_04_SuperBlock_ShowUpgradeEffects = 0x80243d94
export const RAM_appendGfx_shading_palette = 0x801489b8
export const RAM_pra_33_SetPartnerFlagsA0000 = 0x80240e84
export const RAM_flo_21_80242290 = 0x80242290
export const RAM_tik_23_UpdateTexturePanStepped = 0x80240404
export const RAM_b_area_nok_paragoomba_sinTable = 0x8021d57c
export const RAM_b_area_pra2_crystal_bit_2 = 0x8022066c
export const RAM_D_802429A4 = 0x802429a4
export const RAM_b_area_kmr_part_3_mage_jr_troopa_takeTurn_8022B4F0 = 0x8022b4f0
export const RAM_battle_item_super_soda_UseItemWithEffect = 0x802a1530
export const RAM_D_802BDF64 = 0x802bdf64
export const RAM_walking_dust_init = 0xe000e16c
export const RAM_func_802C73B8 = 0x802c73b8
export const RAM_b_area_sam2_sam_02d_UnkModelStuffScript3 = 0x80222e14
export const RAM_dgb_01_D_80251DC4_C0F644 = 0x80251dc4
export const RAM_ui_battle_cmd_flee_0_pal = 0x8028d920
export const RAM_D_E006AF58 = 0xe006af58
export const RAM_fx_walking_dust = 0x8006fb30
export const RAM_b_area_omo2_toy_tank_8021A508 = 0x8021a508
export const RAM_set_max_SP = 0x800ea1d8
export const RAM_pra_19_reflection_setup_floor = 0x80240500
export const RAM_func_802B7000_2 = 0x802b7000
export const RAM_isk_05_HoppingAI_ChaseInit = 0x802407cc
export const RAM_D_8024EFF8_C0C878 = 0x8024eff8
export const RAM_b_area_arn_tubbas_heart_partsTable_80223D30 = 0x80223d30
export const RAM_osFlashSectorErase = 0x8006f424
export const RAM_arn_07_802433C8 = 0x802433c8
export const RAM_get_current_partner_id = 0x800e5088
export const RAM_remove_status_icon_boost_jump = 0x80047ca4
export const RAM_D_090039D8_3AEA08 = 0x090039d8
export const RAM_dgb_18_exitDoubleDoor_80241360 = 0x80241360
export const RAM_battle_partner_kooper_idleAnimations_80238430 = 0x80238430
export const RAM_func_80240410_D7F170 = 0x80240410
export const RAM_pra_33_reflection_render_floor = 0x8024068c
export const RAM_pra_05_reflection_unk_resolve_anim = 0x80240020
export const RAM_D_8024264C_98011C = 0x8024264c
export const RAM_dgb_01_D_80252F20_C107A0 = 0x80252f20
export const RAM_battle_item_hustle_drink_EatItem = 0x802a17ac
export const RAM_sbk_45_SpawnSunEffect = 0x80240000
export const RAM_func_8013E2F0 = 0x8013e2f0
export const RAM_func_80027774 = 0x80027774
export const RAM_battle_item_pebble_GiveRefundCleanup = 0x802a11d4
export const RAM_D_8010BD80 = 0x8010bd80
export const RAM_D_8010C984 = 0x8010c984
export const RAM_D_8015437F = 0x8015437f
export const RAM_D_E0060758 = 0xe0060758
export const RAM_D_802A25EC = 0x802a25ec
export const RAM_tik_12_func_80240814_97BE44 = 0x80242134
export const RAM_disable_anim_model_fog = 0x8012003c
export const RAM_D_8029DA40 = 0x8029da40
export const RAM_D_8029D5C0 = 0x8029d5c0
export const RAM_flo_15_entryList = 0x80240000
export const RAM_b_area_sam_duplighost_GetPartnerAndLevel = 0x802194b8
export const RAM_dro_02_EVS_Quizmo_QuizMain = 0x80246520
export const RAM_HES_Item_CookBook = 0x80081b78
export const RAM_sam_08_SuperBlock_ClearOverride40 = 0x8024001c
export const RAM_D_E008AA60 = 0xe008aa60
export const RAM_dead_basic_ai_wander_init = 0x8004d9c0
export const RAM_b_area_omo_spy_guy_slingshotAttack = 0x80228138
export const RAM_HES_Spirit5 = 0x8024253c
export const RAM_D_8014BD88 = 0x8014bd88
export const RAM_b_area_sam2_sam_02_UnkFloatFunc2 = 0x802190bc
export const RAM_func_80241BC4_9942F4 = 0x80241bc4
export const RAM_flo_17_SpinyAI_Main = 0x80242918
export const RAM_ui_status_text_fp_pal = 0x801006e0
export const RAM_D_80232AA0_684820 = 0x80232aa0
export const RAM_b_area_jan_jungle_fuzzy_80221468 = 0x80221468
export const RAM_create_camera_leadplayer_matrix = 0x80033394
export const RAM_b_area_tik2_UnkBattleFunc2 = 0x80218a78
export const RAM_filemenu_draw_contents_file_1_title = 0x80245718
export const RAM_b_area_jan2_putrid_piranha = 0x802187f0
export const RAM_hos_03_foliage_setup_shear_mtx = 0x80240a70
export const RAM_func_802381C8_7110B8 = 0x802381c8
export const RAM_D_090014E0 = 0x090014e0
export const RAM_D_80151338 = 0x80151338
export const RAM_gPauseWS_11 = 0x8026fb68
export const RAM_ui_battle_cont_c_up_up_pal = 0x8028c168
export const RAM_b_area_trd_part_1_vector3D_80218B9C = 0x80218b9c
export const RAM_battle_item_strange_cake_func_802A18D8_731BD8 = 0x802a18d8
export const RAM_IES_DojoCard5 = 0x8008bc64
export const RAM_evt_handle_if_less_equal = 0x802c4dc8
export const RAM_b_area_jan_jan_01 = 0x80227a84
export const RAM_fold_load_gfx = 0x8013c220
export const RAM_HES_StatusStarPoint = 0x801086ac
export const RAM_D_80241C58_BBEDF8 = 0x80241c58
export const RAM_b_area_pra3_gray_magikoopa_init = 0x80231218
export const RAM_func_E00828B4 = 0xe00828b4
export const RAM_b_area_mac_chan_idleAnimations_80219F08 = 0x80219f08
export const RAM_func_802A247C_79AC2C = 0x802a247c
export const RAM_arn_03_802429D4 = 0x802429d4
export const RAM_isk_14_StoneChompFXA = 0x80241108
export const RAM_D_E0044DD0 = 0xe0044dd0
export const RAM_RunningMusicEvents = 0x802db7d8
export const RAM_b_area_kmr_part_3_para_jr_troopa_takeTurn_80226F58 = 0x80226f58
export const RAM_trd_04_PatrolAI_PostLoiter = 0x80241300
export const RAM_au_reset_instrument = 0x800532f4
export const RAM_tik_25_UpdateTexturePanSmooth = 0x80240000
export const RAM_mac_04_Quizmo_UpdateRecords = 0x80240b4c
export const RAM_flo_12_pad_E28 = 0x80240e28
export const RAM_dgb_03_unk_missing_80243A2C = 0x80243a2c
export const RAM_sam_01_Quizmo_SetVannaAnim_Idle = 0x802411f0
export const RAM_HES_CDownButton = 0x80292630
export const RAM_b_area_mac_formation_03 = 0x80232a24
export const RAM_D_8010CCF8 = 0x8010ccf8
export const RAM_dup3_b_area_kpa2_UnkFireBreathFXFunc = 0x80218178
export const RAM_D_802A3834 = 0x802a3834
export const RAM_nok_01_Quizmo_DestroyEffects = 0x802416f0
export const RAM_ui_fp_cost_pal = 0x800fc2e0
export const RAM_kzn_17_AddPlayerHandsOffset = 0x80242710
export const RAM_ui_status_star_piece_0_pal = 0x800fc100
export const RAM_D_E0116C70 = 0xe0116c70
export const RAM_HES_SkolarDisabled = 0x80107a6c
export const RAM_evt_handle_break_loop = 0x802c4b0c
export const RAM_b_area_sam_duplighost_watt_idleAnimations = 0x802238ec
export const RAM_MakeTransformGroup = 0x802c971c
export const RAM_func_80240068_B9B4E8 = 0x80240068
export const RAM_nok_15_set_script_owner_npc_col_height = 0x802405f4
export const RAM_InitVirtualEntityList = 0x802d3398
export const RAM_b_area_jan2_jan_02 = 0x8021f31c
export const RAM_sam_11_Quizmo_AddViewRelativeOffset = 0x80241450
export const RAM_battle_action_cmd_whirlwind_7_png = 0x802aa060
export const RAM_battle_partner_kooper_80238E38 = 0x80238e38
export const RAM_b_area_mac_lee_defenseTable_8021D3C4 = 0x8021d3c4
export const RAM_BattleCam_ModeX = 0x8029f2a3
export const RAM_b_area_omo_spy_guy_init_802275D4 = 0x802275d4
export const RAM_SetCamDistance = 0x802cbbe4
export const RAM_func_8024370C_A2D94C = 0x8024370c
export const RAM_SetNpcCollisionChannel = 0x802cf1b4
export const RAM_D_80257F23 = 0x80257f23
export const RAM_b_area_isk_part_2_chain_chomp_idleAnimations_80218B40 = 0x80218b40
export const RAM_func_802457C0_A2FA00 = 0x802457c0
export const RAM_sbk_01_802401F4 = 0x802401f4
export const RAM_PlayerTweesterPhysics = 0x802b6350
export const RAM_pra_05_SomeItemEntityFunc = 0x80241038
export const RAM_b_area_mac_lee_statusTable_80224468 = 0x80224468
export const RAM_D_80249B9C = 0x80249b9c
export const RAM_func_8004DCB8 = 0x8004dcb8
export const RAM_func_80240000_AD4090 = 0x80240000
export const RAM_func_80240260_B1B7D0 = 0x80240260
export const RAM_flo_11_80241520 = 0x80241520
export const RAM_dro_01_dup_Pipe_GetCameraYaw = 0x80243770
export const RAM_read_background_size = 0x80145e28
export const RAM_enable_status_2 = 0x80047a00
export const RAM_GetGridIndexFromPos = 0x802836b8
export const RAM_HES_Item_Unused_136_disabled = 0x80087848
export const RAM_SPIncrementHudScripts = 0x800f7fb0
export const RAM_pause_badges_load_badges = 0x80248d8c
export const RAM_func_80243FC4_A2E204 = 0x80243fc4
export const RAM_arn_07_func_80240800_BED5F0 = 0x80240800
export const RAM_D_8029D918 = 0x8029d918
export const RAM_D_8015796C = 0x8015796c
export const RAM_entity_BlueWarpPipe_wait_player_move_to_center = 0x802e8c94
export const RAM_flo_21_D_802414FC_CE7BFC = 0x802414fc
export const RAM_get_global_byte = 0x80145538
export const RAM_star_spirits_energy_update = 0xe012217c
export const RAM_battle_item_strange_cake_script8 = 0x802a2c40
export const RAM_arn_10_makeEntities = 0x80240c28
export const RAM_GetCurrentPartnerID = 0x802cfc74
export const RAM_D_802B68BC = 0x802b68bc
export const RAM_D_E008AA68 = 0xe008aa68
export const RAM_action_command_flee_draw_hud_elements = 0x802a96f4
export const RAM_btl_state_update_prepare_menu = 0x80243918
export const RAM_world_watt_use_ability = 0x802be298
export const RAM_step_model_animator = 0x8011ebf0
export const RAM_D_80244E08_A1B028 = 0x80244e08
export const RAM_func_800328A4 = 0x800328a4
export const RAM_jan_04_get_tattle = 0x80240000
export const RAM_D_80242470_8A7EF0 = 0x80242470
export const RAM_kzn_03_UnkDistFunc = 0x8024038c
export const RAM_snd_ambient_8005553C = 0x8005553c
export const RAM_hos_03_FlyingAI_Loiter = 0x80241548
export const RAM_fx_86_update = 0xe0128130
export const RAM_fx_fright_jar = 0x80071cf0
export const RAM_isk_05_StoneChompAI_HopInit = 0x80240e14
export const RAM_fire_breath_update = 0xe006e35c
export const RAM_SetPushBlock = 0x8028347c
export const RAM_mac_02_Pipe_AwaitDownInput = 0x80240034
export const RAM_IES_MegaRush = 0x8008c304
export const RAM_gCollisionNormalY = 0x800a425c
export const RAM_D_8023D2B4 = 0x8023d2b4
export const RAM_au_bgm_set_proximity_mix = 0x800506c8
export const RAM_flo_15_intTable_80241360 = 0x80241360
export const RAM_func_802687BC = 0x802687bc
export const RAM_gBattleEntityModelList = 0x80153f70
export const RAM_ui_battle_cont_c_up_down_png = 0x8028c188
export const RAM_b_area_dgb_dgb_01_afterBattle_8021B070 = 0x8021b070
export const RAM_b_area_omo2_toy_tank_summon_pos = 0x8022f548
export const RAM_b_area_isk_part_1_isk_02b_beforeBattle_80223D90 = 0x80223d90
export const RAM_flo_08_searchBush = 0x80244f40
export const RAM_dro_02_BuildKeyItemChoiceList = 0x8024334c
export const RAM_add_item = 0x800e7574
export const RAM_nok_11_set_script_owner_npc_anim = 0x80240000
export const RAM_b_area_nok_formation_17 = 0x80219494
export const RAM_b_area_trd_part_3_nok_01_beforeBattle_80218ED8 = 0x80218ed8
export const RAM_HES_SwapZ = 0x80292c50
export const RAM_dgb_03_ClubbaNappingAI_Loiter = 0x802420c8
export const RAM_jan_09_MeleeHitbox_30 = 0x80240b54
export const RAM_D_80244B88_A3E068 = 0x80244b88
export const RAM_IES_Dictionary = 0x8008b7a4
export const RAM_gPauseMsg_3F = 0x8026f6c8
export const RAM_arn_08_D_80241188_BF5928 = 0x80241188
export const RAM_au_bgm_player_update_playing = 0x8004ec68
export const RAM_b_area_mac_lee_partsTable_80225A80 = 0x80225a80
export const RAM_omo_07_ShyGuyWanderAI_Main = 0x80240238
export const RAM_kkj_20_GetItemName = 0x802400b4
export const RAM_D_801094A0 = 0x801094a0
export const RAM_guFrustumF = 0x80061f30
export const RAM_mim_01_PiranhaPlantAI_11 = 0x80241978
export const RAM_end_00_CharAnim_FadeIn_1 = 0x80240270
export const RAM_D_800B4384 = 0x800b4384
export const RAM_kmr_05_FlyingAI_Init = 0x802411e8
export const RAM_MerleeExpBonus = 0x80288a58
export const RAM_ui_super_hammer_pal = 0x800fac60
export const RAM_tik_07_FlyingAI_ChaseInit = 0x802420b0
export const RAM_HES_UnusedDigit1 = 0x8010785c
export const RAM_D_802455D0_C76A50 = 0x802455d0
export const RAM_nok_01_Quizmo_varStash = 0x80245f44
export const RAM_fx_3D_init = 0xe007a504
export const RAM_func_80050654 = 0x80050654
export const RAM_HES_Item_BooWeight = 0x80081758
export const RAM_kmr_04_GetItemName = 0x8024037c
export const RAM_func_8024EDA4 = 0x8024eda4
export const RAM_b_area_kmr_part_1_kmr_03_clouds1 = 0x8021fe00
export const RAM_dup_jan_22_foliage_setup_shear_mtx = 0x80241720
export const RAM_set_model_fog_color_parameters = 0x8011beb4
export const RAM_entity_HiddenItemBlock_init = 0x802e4694
export const RAM_b_area_pra3_duplighost_bow_idle = 0x8022175c
export const RAM_arn_05_pad_4FF8 = 0x80244ff8
export const RAM_KooperPutAway = 0x802be7e0
export const RAM_b_area_omo2_3_shy_stack_80235168 = 0x80235168
export const RAM_is_ability_active = 0x800e9d48
export const RAM_flo_00_npcSettings_80242940 = 0x80242940
export const RAM_isk_16_StarSpiritEffectFunc1 = 0x80240000
export const RAM_sfx_compute_spatialized_sound_params_2 = 0x80149250
export const RAM_b_area_kzn_medi_guy_init_8021F9E8 = 0x8021f9e8
export const RAM_fx_damage_indicator = 0x800703d0
export const RAM_b_area_kmr_part_1_kmr_06_clouds1 = 0x80220aa0
export const RAM_b_area_jan_spear_guy_handleEvent_8021C14C = 0x8021c14c
export const RAM_b_area_jan2_white_magikoopa_ShrinkActor = 0x80218250
export const RAM_get_path_position = 0x802d5270
export const RAM_battle_move_auto_multibounce_D_802A2384 = 0x802a2384
export const RAM_get_msg_lines = 0x801264b0
export const RAM_b_area_kmr_part_1_kmr_05_clouds2 = 0x80220798
export const RAM_dgb_18_pad_XXX = 0x802438d8
export const RAM_shim_get_msg_width = 0xe0200660
export const RAM_sam_01_Quizmo_ShowEntities = 0x8024082c
export const RAM_battle_move_hammer_IsBerserkerEquipped = 0x802a1000
export const RAM_D_8029C890 = 0x8029c890
export const RAM_D_09002230_3885E0 = 0x09002230
export const RAM_HES_Item_IcedPotato_disabled = 0x80086fa8
export const RAM_b_area_trd_part_3_nok_01_foregroundModelList_80218FB0 = 0x80218fb0
export const RAM_dgb_08_MeleeHitbox_Main = 0x8024124c
export const RAM_b_area_pra3_duplighost_handleEvent = 0x8021a1dc
export const RAM_b_area_isk_part_2_stageTable = 0x80220470
export const RAM_sam_08_SuperBlock_PartnerIDs = 0x80241ae8
export const RAM_D_8014BB88 = 0x8014bb88
export const RAM_b_area_arn_arn_01_afterBattle_80227910 = 0x80227910
export const RAM_D_8010D680 = 0x8010d680
export const RAM_tik_08_Pipe_GetEntryPos = 0x802400f0
export const RAM_battle_item_please_come_back_UseItemWithEffect = 0x802a1240
export const RAM_dro_02_Quizmo_CreateWorker = 0x80241364
export const RAM_battle_menu_TwinkStarPowerHudScripts = 0x802ab72c
export const RAM_some_80241FBC = 0x80241fbc
export const RAM_IES_Unused_0E7 = 0x8008c9c4
export const RAM_HES_Item_RuinsKey = 0x80081488
export const RAM_arn_03_init_802428A8 = 0x802428a8
export const RAM_D_802E0C14 = 0x802e0c14
export const RAM_AddStarPieces = 0x802d72f0
export const RAM_D_80257118 = 0x80257118
export const RAM_D_80294C68 = 0x80294c68
export const RAM_mac_00_UnkPositionFunc = 0x80242078
export const RAM_D_802B7C20_E20D30 = 0x802b7c20
export const RAM_D_800B0F08 = 0x800b0f08
export const RAM_gLogosImage2 = 0x800a091c
export const RAM_b_area_kmr_part_3_kmr_02_beforeBattle_802322D0 = 0x802322d0
export const RAM_StartBossBattle = 0x8004489c
export const RAM_D_8026FFB0 = 0x8026ffb0
export const RAM_hos_03_Quizmo_HideWorld = 0x802428d0
export const RAM_b_area_sam_sam_02c_UnkModelStuffScript2 = 0x8022fbbc
export const RAM_flo_19_updateTexturePan_80240B00 = 0x80240b00
export const RAM_b_area_isk_part_2_chain_chomp_idle_80219108 = 0x80219108
export const RAM_KooperTweesterPhysics = 0x802bec80
export const RAM_tik_07_Pipe_SetAnimFlag = 0x80240000
export const RAM_func_8013A888 = 0x8013a888
export const RAM_trd_04_FlyingAI_JumpInit = 0x802429f4
export const RAM_b_area_isk_part_1_isk_04_DeleteTorchFX = 0x80218944
export const RAM_CloseChoice = 0x802d0bf8
export const RAM_b_area_jan2_jan_03b = 0x8021f460
export const RAM_func_80218AA0_5CF130 = 0x80218aa0
export const RAM_b_area_omo2_toy_tank_8021A400 = 0x8021a400
export const RAM_peach_star_beam_main = 0xe010c000
export const RAM_npc__remove_decoration = 0x8003c53c
export const RAM_battle_menu_StrategiesHudScript = 0x802ab478
export const RAM_D_80247BD8_DA50A8 = 0x80247bd8
export const RAM_b_area_isk_part_1_stone_chomp = 0x8022176c
export const RAM_HES_Item_HappyHeart_disabled = 0x800848a8
export const RAM_b_area_trd_part_1_trd_04_802240C0 = 0x802240c0
export const RAM_dgb_15_80242184 = 0x80242184
export const RAM_kmr_09_FlyingAI_JumpVels = 0x8024186c
export const RAM_flo_25_FlowerGuard_ItemChoiceList = 0x80245110
export const RAM_func_80052E30 = 0x80052e30
export const RAM_D_802AD04C = 0x802ad04c
export const RAM_func_80044238 = 0x80044238
export const RAM_b_area_isk_part_1_isk_05_D_80224D74 = 0x80224d74
export const RAM_kpa_102_GetEncounterEnemyIsOwner = 0x80241524
export const RAM_ShowBattleChoice = 0x80253538
export const RAM_dro_01_enterWalk_80244C14 = 0x80244c14
export const RAM_arn_05_npcGroup_802447E4 = 0x802447e4
export const RAM_func_80057E08 = 0x80057e08
export const RAM_b_area_sam_duplighost_UnkWattEffectFunc5 = 0x802193f0
export const RAM_b_area_trd_part_2_fake_bowser_D_8021A8FC_48BAEC = 0x8021a8fc
export const RAM_b_area_mim_formation_08 = 0x8021e798
export const RAM_gPauseDLArrows = 0x8026f2a0
export const RAM_try_inflict_status = 0x80266b78
export const RAM_dro_02_D_80248B7C_971D3C = 0x80248b7c
export const RAM_D_8014F6F0 = 0x8014f6f0
export const RAM_D_802AD606 = 0x802ad606
export const RAM_IES_AttackFXE = 0x8008ca64
export const RAM_b_area_trd_part_2_green_ninja_koopa = 0x80225648
export const RAM_dgb_08_MeleeHitbox_CanSeePlayer = 0x80241098
export const RAM_D_8024BF90_C09810 = 0x8024bf90
export const RAM_EA0C10_MeleeHitbox_33 = 0x802404c4
export const RAM_ui_battle_cmd_timing_miss_png = 0x8028d720
export const RAM_D_8010CFF0 = 0x8010cff0
export const RAM_dgb_01_D_802536A0_C10F20 = 0x802536a0
export const RAM_D_80247428_C7E108 = 0x80247428
export const RAM_mim_05_MeleeHitbox_CanSeePlayer = 0x80240278
export const RAM_b_area_kzn_kzn_01 = 0x802277f8
export const RAM_dro_01_LetterDelivery_SaveNpcAnim = 0x80243014
export const RAM_omo_11_SuperBlock_SetOverride40 = 0x80240790
export const RAM_D_802463D0_915A80 = 0x802463d0
export const RAM_D_80262890_823110 = 0x80262890
export const RAM_kmr_02_Quizmo_ShouldQuizmoLeave = 0x80240ecc
export const RAM_D_802465D0_kzn_02 = 0x802465d0
export const RAM_b_area_sam_duplighost_watt_defenseTable = 0x80223938
export const RAM_btl_state_draw_begin_turn = 0x8024201c
export const RAM_hos_02_FlyingAI_Jump = 0x80241c44
export const RAM_battle_item_electro_pop_func_802A123C_7307DC = 0x802a123c
export const RAM_gather_energy_pink_appendGfx = 0xe00264f4
export const RAM_IES_KootRedJar = 0x8008ba84
export const RAM_HES_Item_UNK_1DC430 = 0x80081e48
export const RAM_gPauseTutorialInputState = 0x8024f01c
export const RAM_hud_element_set_alpha = 0x80144eb0
export const RAM_pra_11_StashVars = 0x80240f20
export const RAM_D_8009A684 = 0x8009a684
export const RAM_b_area_mac_lee_takeTurn_802270BC = 0x802270bc
export const RAM_pra_33_ReflectPartner = 0x80240d3c
export const RAM_SetEnemyHP = 0x8027c5c8
export const RAM_func_80240C10_AF8560 = 0x80240c10
export const RAM_arn_13_80240430 = 0x80240430
export const RAM_sam_01_Quizmo_UnkStageEffectMode = 0x80241170
export const RAM_nuBoot = 0x8005e8b0
export const RAM_delete_shadow = 0x80112328
export const RAM_D_E0060750 = 0xe0060750
export const RAM_pause_image_143FF0_png = 0x80250cb0
export const RAM_D_800A3524 = 0x800a3524
export const RAM_battle_star_peach_beam_UnkBackgroundFunc2 = 0x802a137c
export const RAM_UseExitHeading = 0x802d216c
export const RAM_b_area_mac_mac_01_80231E00 = 0x80231e00
export const RAM_func_802405EC_7EB2FC = 0x802405ec
export const RAM_battle_star_chill_out_802A267C = 0x802a267c
export const RAM_b_area_omo2_general_guy_formation_shy_squad = 0x8021a284
export const RAM_mim_08_PiranhaPlantAI_10 = 0x80241fd0
export const RAM_SushieTweesterPhysicsPtr = 0x802bfdf8
export const RAM_ui_parakarry_pal = 0x800feee0
export const RAM_b_area_trd_part_2_trd_05e = 0x80235530
export const RAM_func_80241518_BD0108 = 0x80241518
export const RAM_dgb_01_SentinelAI_Main = 0x802422b0
export const RAM_sbk_25_foliage_setup_shear_mtx = 0x80240140
export const RAM_flo_00_npcGroup_80245814 = 0x80245814
export const RAM_IES_Unused_08B = 0x8008be44
export const RAM_b_area_arn_formation_03 = 0x80227d08
export const RAM_func_800F0D5C = 0x800f0d5c
export const RAM_btl_state_update_begin_player_turn = 0x80242024
export const RAM_D_80210000 = 0x80210000
export const RAM_EED100_DeadUnkFunc1 = 0x80240000
export const RAM_vtx_C22050 = 0x80201110
export const RAM_get_screen_coords = 0x8002e538
export const RAM_energy_in_out_main = 0xe00d6000
export const RAM_D_09001938 = 0x09001938
export const RAM_D_8010BD88 = 0x8010bd88
export const RAM_dgb_15_npcAISettings_8024271C = 0x8024271c
export const RAM_WorldMerleeOrbEffect = 0x800a0ba8
export const RAM_gPauseMsg_36 = 0x8026f68c
export const RAM_D_80247B00 = 0x80247b00
export const RAM_b_area_sam_duplighost_UnkTattleEffectFunc2 = 0x80218b2c
export const RAM_arn_02_exitWalk_802410F0 = 0x802410f0
export const RAM_func_80240000_DE2490 = 0x80240000
export const RAM_jan_22_PatrolAI_LosePlayer = 0x80240a6c
export const RAM_render_hud_element = 0x80142bfc
export const RAM_b_area_sbk_bandit_8021D820 = 0x8021d820
export const RAM_b_area_jan2_formation_03 = 0x8021f658
export const RAM_flo_00_80247588 = 0x80247588
export const RAM_SetNpcSpeed = 0x802ce01c
export const RAM_b_area_arn_hyper_cleft_takeTurn_80223B5C = 0x80223b5c
export const RAM_flo_08_802429E4 = 0x802429e4
export const RAM_b_area_arn_hyper_paragoomba_nextTurn_8021E194 = 0x8021e194
export const RAM_D_800A3FEC = 0x800a3fec
export const RAM_b_area_kmr_part_3_mage_jr_troopa_partsTable_8022A6E0 = 0x8022a6e0
export const RAM_D_80247A30_B524A0 = 0x80247a30
export const RAM_func_802A10A4_74AE34 = 0x802a10a4
export const RAM_func_80283240 = 0x80283240
export const RAM_D_80244130_BE78E0 = 0x80244130
export const RAM_func_8024F84C = 0x8024f84c
export const RAM_gPauseWS_19 = 0x8026fd28
export const RAM_pra_12_reflection_unk_resolve_anim = 0x80240050
export const RAM_b_area_trd_part_1_formation_11 = 0x80218998
export const RAM_BeginSoundUpdateCallback = 0x8009a5e8
export const RAM_omo_17_SetNpcPosYaw = 0x80240290
export const RAM_func_80053E58 = 0x80053e58
export const RAM_mac_01_ToadHouse_AwaitScriptComplete = 0x802438f0
export const RAM_HES_Item_Peekaboo = 0x80085ad8
export const RAM_func_80054CE0 = 0x80054ce0
export const RAM_D_800B1B0C = 0x800b1b0c
export const RAM_dro_02_interact_8024BCF8 = 0x8024bcf8
export const RAM_b_area_sam2_gray_magikoopa_init = 0x80221568
export const RAM_flo_16_80243DB0 = 0x80243db0
export const RAM_b_area_arn_hyper_paragoomba_idle_8021C690 = 0x8021c690
export const RAM_iwa_01_CleftAI_FindPlayer = 0x80242794
export const RAM_HES_Item_JellyUltra = 0x80082958
export const RAM_flo_08_UnkFloAI_LosePlayer = 0x802414c8
export const RAM_flo_25_ItemChoice_WaitForSelection = 0x80241d58
export const RAM_battle_partner_goombario_8023ADC4 = 0x8023adc4
export const RAM_dgb_01_PatrolAI_MoveInit = 0x8024252c
export const RAM_battle_star_peach_beam_FadeBackgroundToBlack = 0x802a12e4
export const RAM_b_area_kmr_part_3_kpa_01_afterBattle_80234D9C = 0x80234d9c
export const RAM_mgm_01_OnBreakBlock = 0x80241090
export const RAM_D_80232BE0_684960 = 0x80232be0
export const RAM_D_E0002788 = 0xe0002788
export const RAM_sweat_main = 0xe0050000
export const RAM_create_status_icon_set = 0x80047608
export const RAM_nuContMgrRemove = 0x800605e8
export const RAM_D_E0116CF8 = 0xe0116cf8
export const RAM_iwa_02_CleftAI_PreAmbush = 0x80240304
export const RAM_func_802CAE50 = 0x802cae50
export const RAM_kpa_14_varStash = 0x80241900
export const RAM_b_area_pra3_duplighost_kooper_dispOffsets = 0x8021da40
export const RAM_func_802A98D0 = 0x802a98d0
export const RAM_pause_interp_text_scroll = 0x80243418
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021F05C = 0x8021f05c
export const RAM_b_area_hos_magikoopa_statusTable_8021D544 = 0x8021d544
export const RAM_small_gold_sparkle_update = 0xe00921b4
export const RAM_b_area_isk_part_1_pokey_mummy_partsTable_80218CC8 = 0x80218cc8
export const RAM_dgb_01_npcSettings_8024526C = 0x8024526c
export const RAM_b_area_pra3_formationTable = 0x80232888
export const RAM_func_802406E0_AACF10 = 0x802406e0
export const RAM_HES_Item_Unused_137_disabled = 0x800878a8
export const RAM_tik_17_SuperBlock_WhiteScreenFlash = 0x80240b68
export const RAM_func_80242900_E07C60 = 0x80242900
export const RAM_iwa_02_CleftAI_ReturnHome = 0x80240a30
export const RAM_dead_set_screen_overlay_params_back = 0x80144194
export const RAM_EA0C10_AddPlayerHandsOffset = 0x802427f0
export const RAM_b_area_hos_formationTable = 0x80225f24
export const RAM_btl_cam_set_target_pos = 0x8024e520
export const RAM_flo_07_80240C74 = 0x80240c74
export const RAM_kpa_08_MagikoopaAI_20 = 0x80240d08
export const RAM_ContinueSpeech = 0x802d02e8
export const RAM_btl_state_update_defeat = 0x80245440
export const RAM_iwa_02_CleftAI_DisguiseInit = 0x80240b68
export const RAM_dgb_14_enterDoubleDoor_80240268 = 0x80240268
export const RAM_b_area_kmr_part_1_spiked_goomba = 0x8021b0ac
export const RAM_pra_09_ReflectWall = 0x802400ec
export const RAM_D_80244880_9BB200 = 0x80244880
export const RAM_b_area_nok_formation_00 = 0x80218e20
export const RAM_b_area_kmr_part_3_nok_01_80233450 = 0x80233450
export const RAM_HES_Item_LastStand_disabled = 0x800852c8
export const RAM_SetVirtualEntityMoveSpeed = 0x802d3998
export const RAM_dgb_04_entryList = 0x80243110
export const RAM_D_09000240_364E40 = 0x09000240
export const RAM_b_area_arn_hyper_paragoomba_idleAnimations_8021F0A8 = 0x8021f0a8
export const RAM_CheckButtonHeld = 0x8026e0d0
export const RAM_dgb_01_init_80245D80 = 0x80245d80
export const RAM_D_E004AE60 = 0xe004ae60
export const RAM_flo_03_80241EF4 = 0x80241ef4
export const RAM_vtx_C28420 = 0x802074e0
export const RAM_b_area_hos_formation_03 = 0x80225f08
export const RAM_flo_09_npcAI_8024373C = 0x8024373c
export const RAM_battle_item_mystery_EatItem = 0x802a1e1c
export const RAM_b_area_omo2_2_stilt_guy_idleAnimations = 0x80233e98
export const RAM_action_update_first_strike = 0x802b63d4
export const RAM_filemenu_update_cursor = 0x80242f68
export const RAM_b_area_kmr_part_3_jr_troopa_idleAnimations_802229F0 = 0x802229f0
export const RAM_b_area_arn_tubbas_heart_idleAnimations_80223C1C = 0x80223c1c
export const RAM_arn_03_StashVars = 0x802412b0
export const RAM_b_area_kpa4_ShrinkActor = 0x80218150
export const RAM_D_802809F4 = 0x802809f4
export const RAM_kpa_111_ProjectileAI_Main = 0x802406cc
export const RAM_SetPartTargetFlags = 0x8026d0d0
export const RAM_flo_21_802415E0 = 0x802415e0
export const RAM_dispatch_damage_event_player_1 = 0x802733a8
export const RAM_D_802DBB68 = 0x802dbb68
export const RAM_func_8024027C_8DA37C = 0x8024027c
export const RAM_D_8014B7A8 = 0x8014b7a8
export const RAM_bulb_glow_init = 0xe0078124
export const RAM_dgb_05_pad_17E8 = 0x802417e8
export const RAM_iwa_01_ProjectileAI_Main = 0x80240a7c
export const RAM_func_E00826C4 = 0xe00826c4
export const RAM_virtual_entity_get_by_index = 0x802d4164
export const RAM_pra_35_varStash = 0x80243de0
export const RAM_battle_star_refresh_UnkBackgroundFunc2 = 0x802a137c
export const RAM_vtx_C235C0 = 0x80202680
export const RAM_ui_battle_status_shrink_1_png = 0x80102b80
export const RAM_huff_puff_breath_main = 0xe00dc000
export const RAM_D_8024EF78_978138 = 0x8024ef78
export const RAM_func_802397C8_701548 = 0x802397c8
export const RAM_D_E02006B0 = 0xe02006b0
export const RAM_dgb_09_extraAnimationList_802440BC = 0x802440bc
export const RAM_b_area_kzn_white_magikoopa_init_80225FBC = 0x80225fbc
export const RAM_func_80241E80_7ECB90 = 0x80241e80
export const RAM_battle_star_peach_dash_FadeBackgroundToBlack = 0x802a12e4
export const RAM_dro_01_triggerCoord_8024DD68 = 0x8024dd68
export const RAM_b_area_isk_part_2_isk_04_afterBattle_80220178 = 0x80220178
export const RAM_b_area_kzn_red_magikoopa_UnkBattleFunc1 = 0x802187b4
export const RAM_D_8024F318 = 0x8024f318
export const RAM_pra_34_SetPartnerFlags80000 = 0x80240eb8
export const RAM_kzn_03_PiranhaPlantAI_13 = 0x80242200
export const RAM_set_curtain_fade = 0x8002bf24
export const RAM_b_area_pra3_duplighost_UnkTattleEffectFunc1 = 0x80218af4
export const RAM_ui_battle_menu_pouch_pal = 0x8028a140
export const RAM_mac_01_Quizmo_SetVannaAnim_Clap = 0x80242a18
export const RAM_kpa_03_set_script_owner_npc_col_height = 0x80240474
export const RAM_HES_Item_ThunderBolt = 0x800861f8
export const RAM_func_80240314_8FF984 = 0x80240314
export const RAM_func_80240000_842BD0 = 0x80240000
export const RAM_dgb_09_npcAI_802443DC = 0x802443dc
export const RAM_func_80240738_C6D058 = 0x80240738
export const RAM_iwa_03_CheckItemFlags40 = 0x80240268
export const RAM_flo_03_settings = 0x80240660
export const RAM_entity_upgrade_block_idle = 0x802e56d0
export const RAM_func_802413EC_EBA23C = 0x802413ec
export const RAM_D_8029EFE8 = 0x8029efe8
export const RAM_b_area_mac_lee_init_Watt = 0x80225af0
export const RAM_D_E010CA10 = 0xe010ca10
export const RAM_b_area_jan2_white_magikoopa_8022433C = 0x8021a6ec
export const RAM_state_init_enter_world = 0x80035e24
export const RAM_battle_star_lullaby_802A23AC = 0x802a23ac
export const RAM_func_80243C78_9DAC98 = 0x80243c78
export const RAM_D_8015438A = 0x8015438a
export const RAM_stub_generic_entity_delegate = 0x801230b0
export const RAM_D_802BFF04 = 0x802bff04
export const RAM_func_802403A8_907DD8 = 0x802403a8
export const RAM_b_area_tik_UnkFunc56 = 0x80219020
export const RAM_func_80240000_B150B0 = 0x80240000
export const RAM_b_area_tik3_UnkBattleFunc2_2 = 0x80218250
export const RAM_func_802400BC_A4C01C = 0x802400bc
export const RAM_b_area_isk_part_1_pokey_mummy = 0x80218da0
export const RAM_flo_10_8024244C = 0x8024244c
export const RAM_func_802C73B0 = 0x802c73b0
export const RAM_dgb_08_idle_80246210 = 0x80246210
export const RAM_flo_00_npcSettings_80242840 = 0x80242840
export const RAM_D_8029FBB0 = 0x8029fbb0
export const RAM_D_8024F583 = 0x8024f583
export const RAM_jan_05_SpearGuyAI_Loiter = 0x80241708
export const RAM_pra_28_varStash = 0x80241690
export const RAM_D_80234760_6BB960 = 0x80234760
export const RAM_ui_status_text_2_png = 0x801000c0
export const RAM_pra_40_reflection_setup_wall = 0x80240128
export const RAM_D_80247E68 = 0x80247e68
export const RAM_ui_unused_2_pal = 0x800fc8c0
export const RAM_b_area_trd_part_1_bob_omb_blastAttack = 0x8021c3f4
export const RAM_dro_01_D_802477E0_9629E0 = 0x802477e0
export const RAM_D_802A99D2_42ED72 = 0x802a99d2
export const RAM_show_foreground_models = 0x80267d28
export const RAM_arn_03_init_80243328 = 0x80243328
export const RAM_IES_BlandMeal = 0x8008d154
export const RAM_func_80241D1C_8EDB3C = 0x80241d1c
export const RAM_jan_01_HoppingAI_LoiterInit = 0x802408a8
export const RAM_dgb_02_802414B0 = 0x802414b0
export const RAM_b_area_trd_part_1_formation_19 = 0x80218c40
export const RAM_func_802409B0_A27880 = 0x802409b0
export const RAM_dgb_18_PatrolAI_Jump = 0x802407a4
export const RAM_dup_b_area_tik2_UnkFunc56 = 0x80219080
export const RAM_iwa_03_CleftAI_HidingInit = 0x802404a0
export const RAM_action_command_body_slam_draw_hud_elements = 0x802a9664
export const RAM_osr_00_SpawnSunEffect = 0x80240344
export const RAM_tik_12_Pipe_GetEntryPos = 0x802400f0
export const RAM_vtx_C27A40 = 0x80206b00
export const RAM_pra_05_reflection_render_wall = 0x802402f0
export const RAM_D_09003600 = 0x09003600
export const RAM_dgb_09_MeleeHitbox_31 = 0x8024006c
export const RAM_BattleMerleeOrbEffect = 0x8029fb98
export const RAM_HES_Item_SleepySheep = 0x800875d8
export const RAM_func_8004B328 = 0x8004b328
export const RAM_open_status_menu_short = 0x800e978c
export const RAM_jan_02_PatrolAI_LoiterInit = 0x80240830
export const RAM_entity_block_handle_collision = 0x802e3ba4
export const RAM_fx_tubba_heart_attack = 0x80071ed0
export const RAM_D_80251578_C0EDF8 = 0x80251578
export const RAM_b_area_sam_formation_02 = 0x80230888
export const RAM_sam_02_ToadHouse_PartnerSuspendAbilityScript = 0x802409a4
export const RAM_b_area_omo_pink_shy_guy_partsTable_8021E3D0 = 0x8021e3d0
export const RAM_b_area_kgr_stageTable = 0x8021d624
export const RAM_ice_pillar_appendGfx = 0xe011e578
export const RAM_nuSiCallBackRemove = 0x80060490
export const RAM_func_80245018_805898 = 0x80245018
export const RAM_func_80240384_ADB224 = 0x80240384
export const RAM_D_8024F310 = 0x8024f310
export const RAM_dro_02_openDoor_8024451C = 0x8024451c
export const RAM_b_area_sam2_gray_magikoopa_statusTable_flying = 0x8021f1f4
export const RAM_D_E0022CF0 = 0xe0022cf0
export const RAM_flo_08_npcSettings_80242FB4 = 0x80242fb4
export const RAM_D_8024F248 = 0x8024f248
export const RAM_func_80240014_982454 = 0x80240014
export const RAM_D_80098A10 = 0x80098a10
export const RAM_D_80251400 = 0x80251400
export const RAM_b_area_kmr_part_1_paragoomba = 0x8021cd00
export const RAM_func_802415F8_D59E88 = 0x802415f8
export const RAM_HES_StatBp = 0x802429ac
export const RAM_arn_02_settings = 0x80241000
export const RAM_func_802B6820_E256F0 = 0x802b6820
export const RAM_b_area_sbk_bandit_idleAnimations_8021D55C = 0x8021d55c
export const RAM_gfx_init_state = 0x800271a0
export const RAM_dgb_17_enterDoubleDoor_802402B0 = 0x802402b0
export const RAM_mac_02_AddPlayerHandsOffset = 0x802428b0
export const RAM_pra_16_reflection_render_floor = 0x8024068c
export const RAM_dup_b_area_flo_UnkFunc52 = 0x80218b78
export const RAM_func_80138198 = 0x80138198
export const RAM_func_80052614 = 0x80052614
export const RAM_dup_kkj_16_UnkGooberFunc50 = 0x80240f10
export const RAM_player_input_to_move_vector = 0x800e2304
export const RAM_arn_05_idle_80241784 = 0x80241784
export const RAM_D_80243098_DB5358 = 0x80243098
export const RAM_D_80242C40_91B7F0 = 0x80242c40
export const RAM_flo_22_init_80240740 = 0x80240740
export const RAM_D_80098074 = 0x80098074
export const RAM_dgb_08_npcGroupList_80246958 = 0x80246958
export const RAM_b_area_trd_part_1_formation_1A = 0x80218c5c
export const RAM_flo_00_80247488 = 0x80247488
export const RAM_HES_Item_HealthyHealthy = 0x80085dd8
export const RAM_SetGoalToFirstTarget = 0x8026a49c
export const RAM_thunderbolt_ring_appendGfx = 0xe00b0234
export const RAM_D_090016A0 = 0x090016a0
export const RAM_dro_02_Quizmo_SetStageLightsDelay = 0x80240d3c
export const RAM_dgb_08_FlyingAI_Jump = 0x802423b4
export const RAM_b_area_omo2_6_shy_guy_dup_defenseTable = 0x80231000
export const RAM_arn_07_80243CB4 = 0x80243cb4
export const RAM_mgm_01_CreateMinigame = 0x802415ec
export const RAM_b_area_kmr_part_3_final_jr_troopa_handleEvent_8022D1C4 = 0x8022d1c4
export const RAM_D_802347D8_6BB9D8 = 0x802347d8
export const RAM_D_E02006B8 = 0xe02006b8
export const RAM_au_ambience_set_volume = 0x8005566c
export const RAM_D_09002050_352270 = 0x09002050
export const RAM_hos_00_StashVars = 0x802407f0
export const RAM_LoadBattleSection = 0x80269de4
export const RAM_sam_07_MeleeHitbox_31 = 0x802406bc
export const RAM_func_80244C8C_A2EECC = 0x80244c8c
export const RAM_D_802BCFF0_E2D720 = 0x802bcff0
export const RAM_dgb_08_FlyingAI_Main = 0x80242a6c
export const RAM_b_area_hos_hos_02 = 0x80225e30
export const RAM_b_area_sam2_sam_02d_beforeBattle = 0x80222fb8
export const RAM_ui_coin_sparkle_none_pal = 0x800fbe20
export const RAM_D_8024E440_C0BCC0 = 0x8024e440
export const RAM_func_80268C9C = 0x80268c9c
export const RAM_pra_16_reflection_unk_change_anim_facing = 0x80240054
export const RAM_D_8024C998_C0A218 = 0x8024c998
export const RAM_jan_10_HoppingAI_Loiter = 0x80240938
export const RAM_b_area_sam2_gray_magikoopa_flee = 0x80221344
export const RAM_D_80098528 = 0x80098528
export const RAM_D_80154389 = 0x80154389
export const RAM_sbk_34_LetterDelivery_CalcLetterPos = 0x8024059c
export const RAM_dgb_00_init_80241F94 = 0x80241f94
export const RAM_func_802409AC_B0322C = 0x802409ac
export const RAM_IES_BumpAttack = 0x8008c5e4
export const RAM_dro_01_8024B7B0 = 0x8024b7b0
export const RAM_func_80240A3C_D7F79C = 0x80240a3c
export const RAM_sam_01_Quizmo_NPC_Aux_Impl = 0x80240aac
export const RAM_D_800DA444 = 0x800da444
export const RAM_kpa_91_PatrolAI_LoiterInit = 0x80240400
export const RAM_D_80244B8C_A3E06C = 0x80244b8c
export const RAM_wModelSpecialDls = 0x80151f90
export const RAM_battle_item_sleepy_sheep2_png = 0x802a2490
export const RAM_D_E00DE910 = 0xe00de910
export const RAM_HES_Item_NuttyCake_disabled = 0x80083648
export const RAM_mac_03_Quizmo_UnkStageEffectMode = 0x80240f20
export const RAM_b_area_omo2_toy_tank_8021A458 = 0x8021a458
export const RAM_pause_banner_bp_pal = 0x8026d030
export const RAM_kzn_09_SuperBlock_CantUpgradeMessages = 0x80243d00
export const RAM_IES_FireShield = 0x8008c0e4
export const RAM_func_802CA90C = 0x802ca90c
export const RAM_IES_Hammer2 = 0x8008b23c
export const RAM_b_area_isk_part_2_chain_chomp_80218FCC = 0x80218fcc
export const RAM_boot_idle = 0x8005e90c
export const RAM_create_popup_menu = 0x800f4e40
export const RAM_battle_item_mystery_D_802A227C_72D82C = 0x802a227c
export const RAM_omo_04_FlyingAI_Wander = 0x80240550
export const RAM_ConsumeLifeShroom = 0x802611e8
export const RAM_D_802ACFFC = 0x802acffc
export const RAM_sam_11_Quizmo_StashVars = 0x802407d0
export const RAM_b_area_arn_formation_0D = 0x80227ffc
export const RAM_state_init_file_select = 0x80035058
export const RAM_func_80240AA4_890644 = 0x80240aa4
export const RAM_D_80249D14 = 0x80249d14
export const RAM_b_area_omo_groove_guy_getFirstOpenColumn = 0x80225fc8
export const RAM_dro_01_pad_D3BC = 0x8024d3bc
export const RAM_b_area_isk_part_2_chain_chomp_8021A200 = 0x8021a200
export const RAM_merlin_house_stars_update = 0xe00a6124
export const RAM_D_E003CCA0 = 0xe003cca0
export const RAM_D_09000800_3D02F0 = 0x09000800
export const RAM_func_802A916C_42EF8C = 0x802a916c
export const RAM_BindTakeTurn = 0x80278930
export const RAM_sbk_41_SpawnSunEffect = 0x80240000
export const RAM_sbk_04_SpawnSunEffect = 0x80240000
export const RAM_vtx_C273A0 = 0x80206460
export const RAM_b_area_sam_pos_rocks_6 = 0x8023079c
export const RAM_b_area_kmr_part_3_kmr_04_UnkAngleFunc2 = 0x80219250
export const RAM_b_area_omo2_3_shy_stack_defenseTable = 0x80231030
export const RAM_D_09000600_3D00F0 = 0x09000600
export const RAM_b_area_sbk_pokey_8021C574 = 0x8021c574
export const RAM_D_80228428_6CBFB8 = 0x80228428
export const RAM_D_802B62B0 = 0x802b62b0
export const RAM_evt_handle_set_flag_array = 0x802c61e0
export const RAM_pra_05_ReflectWall = 0x802400ec
export const RAM_func_80240010_E05370 = 0x80240010
export const RAM_D_8015A070 = 0x8015a070
export const RAM_create_target_list = 0x80262240
export const RAM_D_802E9B00 = 0x802e9b00
export const RAM_tik_10_SuperBlock_UpgradeOrbAngles = 0x80242088
export const RAM_D_8024CD98_C0A618 = 0x8024cd98
export const RAM_D_802D9D80 = 0x802d9d80
export const RAM_b_area_sam_gulpit_idleAnimations1 = 0x80227dd0
export const RAM_func_8023859C_70408C = 0x8023859c
export const RAM_kmr_02_Quizmo_AddViewRelativeOffset = 0x80241010
export const RAM_isk_07_SetNpcB5_3 = 0x80240000
export const RAM_DisablePulseStone = 0x802d2aa8
export const RAM_b_area_pra3_duplighost_goombario_returnBack = 0x8021c290
export const RAM_mac_02_Quizmo_HideEntities = 0x802418d8
export const RAM_func_80240F58_D8A638 = 0x80240f58
export const RAM_func_802D2484 = 0x802d2484
export const RAM_D_8024FF04 = 0x8024ff04
export const RAM_IES_DizzyStomp = 0x8008c1a4
export const RAM_dgb_04_SuperBlock_OnHit = 0x80243ef8
export const RAM_dead_gCurrentCameraID = 0x8007c760
export const RAM_flower_splash_update_part = 0xe0010104
export const RAM_flo_00_pad_16BC = 0x802416bc
export const RAM_EED1E0_SuperBlock_WhiteScreenFlash = 0x80240c18
export const RAM_D_8024F58C = 0x8024f58c
export const RAM_kzn_02_LetterDelivery_Init = 0x80242438
export const RAM_ui_battle_status_dizzy_2_png = 0x80102220
export const RAM_b_area_sam_sam_02c_afterBattle = 0x8022fe90
export const RAM_D_80243DF8_E12598 = 0x80243df8
export const RAM_func_8025950C = 0x8025950c
export const RAM_b_area_pra3_duplighost_OnHitElectric = 0x8021acb4
export const RAM_func_802A9000_42DA10 = 0x802a9000
export const RAM_tik_10_SuperBlock_PartnerSparkles4 = 0x80241340
export const RAM_D_80153222 = 0x80153222
export const RAM_kkj_01_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_IES_MegaQuake = 0x8008c044
export const RAM_func_80240338_92A5E8 = 0x80240338
export const RAM_gPausePartnersImageBuffers = 0x8024f73c
export const RAM_HES_Item_MegaRush = 0x80084638
export const RAM_D_800DC4F4 = 0x800dc4f4
export const RAM_npc_update_decoration_glow_behind = 0x8003cb44
export const RAM_func_80242A90_854C00 = 0x80242a90
export const RAM_show_merlee_message = 0x80045d00
export const RAM_EC2240_varStash = 0x80240f28
export const RAM_HES_Item_Unused_030_disabled = 0x800839a8
export const RAM_b_area_isk_part_2_isk_03 = 0x8022007c
export const RAM_firework_render = 0xe0086390
export const RAM_kzn_03_StashVars = 0x8024259c
export const RAM_D_802BCD40_E2F670 = 0x802bcd40
export const RAM_b_area_mac_lee_idleAnimations_80224410 = 0x80224410
export const RAM_func_80282314 = 0x80282314
export const RAM_func_80241060_91C940 = 0x80241060
export const RAM_func_802A43DC = 0x802a43dc
export const RAM_ui_input_analog_stick_up_png = 0x800f9a80
export const RAM_b_area_pra3_formation_02 = 0x80232524
export const RAM_flo_10_GetItemName = 0x80240134
export const RAM_b_area_trd_part_2_green_ninja_koopa_defenseTable_80225560 = 0x80225560
export const RAM_gBattleState = 0x800dc068
export const RAM_ui_bluepip_png = 0x80101640
export const RAM_tik_12_SuperBlock_PartnerSparkles2 = 0x80241894
export const RAM_func_8025D8EC = 0x8025d8ec
export const RAM_arn_10_pad_BDC = 0x80240bdc
export const RAM_ui_battle_hp_4_png = 0x8028aa60
export const RAM_flo_11_80241CF4 = 0x80241cf4
export const RAM_GoompaTweesterPhysics = 0x802bd600
export const RAM_osEPiReadIo = 0x80060dc0
export const RAM_shiny_flare_appendGfx = 0xe00da230
export const RAM_arn_07_npcSettings_80243CF0 = 0x80243cf0
export const RAM_D_80243F30_D2FC60 = 0x80243f30
export const RAM_b_area_kzn_white_magikoopa_StartRumbleWithParams = 0x80218bf0
export const RAM_shop_owner_reset_speech = 0x802802d0
export const RAM_func_80241DA0_7ECAB0 = 0x80241da0
export const RAM_func_80240034_ACAB54 = 0x80240034
export const RAM_battle_move_auto_multibounce_D_802A19B0 = 0x802a19b0
export const RAM_flo_08_ItemChoiceList = 0x802462c0
export const RAM_D_09003700 = 0x09003700
export const RAM_D_09000C50_3ABC80 = 0x09000c50
export const RAM_func_80241168_B852E8 = 0x80241168
export const RAM_nuContRmbCtl = 0x800b1d40
export const RAM_PlayerRunToGoal = 0x80273c64
export const RAM_kgr_02_HasBombetteExploded = 0x80240760
export const RAM_entity_HeartBlock_create_child_entity = 0x802e55a8
export const RAM_D_80246610_8491E0 = 0x80246610
export const RAM_D_8014C078 = 0x8014c078
export const RAM_b_area_jan2_jan_00_8021DC48 = 0x8021dc48
export const RAM_flo_13_80245014 = 0x80245014
export const RAM_func_802A9000_42E3A0 = 0x802a9000
export const RAM_D_80242860_8D3D70 = 0x80242860
export const RAM_spr_load_npc_extra_anims = 0x802df00c
export const RAM_b_area_kzn_lava_bubble_fireTackle = 0x80219a84
export const RAM_battle_item_mushroom_UseItemWithEffect = 0x802a1600
export const RAM_D_800A095F = 0x800a095f
export const RAM_gPauseStatsBootsMessages = 0x8024f380
export const RAM_b_area_hos_magikoopa_init_80221478 = 0x80221478
export const RAM_SetActorPos = 0x8026ae40
export const RAM_func_80241B40_A2BD80 = 0x80241b40
export const RAM_stop_watch_update = 0xe00c4178
export const RAM_flo_10_tree1 = 0x80244a2c
export const RAM_flo_13_D_8024BA90 = 0x8024ba90
export const RAM_jan_01_UpdateTexturePanStepped = 0x80240124
export const RAM_b_area_jan_hurt_plant_partsTable_8021DA4C = 0x8021da4c
export const RAM_world_twink_use_ability = 0x802bd228
export const RAM_AuHeapBase = 0x801aa000
export const RAM_b_area_trd_part_2_fake_bowser_intTable_8021C2D4 = 0x8021c2d4
export const RAM_sam_11_Quizmo_UpdateRecords = 0x80240ebc
export const RAM_b_area_nok_goomba_handleEvent_80219C14 = 0x80219c14
export const RAM_b_area_mac_lee_80223B74 = 0x80223b74
export const RAM_dro_02_80243B9C = 0x80243b9c
export const RAM_omo_09_PatrolAI_Jump = 0x802416f0
export const RAM_D_802A9F34 = 0x802a9f34
export const RAM_SetMotionBlurParams = 0x802d7cf8
export const RAM_D_802A2600 = 0x802a2600
export const RAM_D_802B69A8_E26708 = 0x802b69a8
export const RAM_D_090013F0 = 0x090013f0
export const RAM_func_802186E0_6DE4A0 = 0x802186e0
export const RAM_entity_WoodenCrate_init_fragments = 0x802e7de0
export const RAM_D_802453B0 = 0x802453b0
export const RAM_D_80248420_808CA0 = 0x80248420
export const RAM_omo_17_ShyGuyWanderAI_16 = 0x802406a0
export const RAM_pause_handle_input = 0x80244424
export const RAM_b_area_kmr_part_3_paragoomba2_statusTable_8021B128 = 0x8021f768
export const RAM_D_802AB738 = 0x802ab738
export const RAM_create_generic_entity_world = 0x80123158
export const RAM_battle_item_super_soda_func_802A1418_7250F8 = 0x802a1418
export const RAM_ui_input_a_button_unpressed_pal = 0x800f88c0
export const RAM_pra_36_ReflectFloor = 0x802404cc
export const RAM_D_802451C0_87EDA0 = 0x802451c0
export const RAM_sPartScales = 0xe00027b0
export const RAM_D_80231438_63CFD8 = 0x80231438
export const RAM_ui_arrow_left_pal = 0x800fbaa0
export const RAM_sam_10_MeleeHitbox_32 = 0x802404ac
export const RAM_shim_mdl_get_next_texture_address = 0xe02005f0
export const RAM_b_area_omo2_2_stilt_guy_return_home = 0x80232304
export const RAM_mac_05_Quizmo_UpdateRecords = 0x80241a0c
export const RAM_kzn_04_SuperBlock_UpgradeDescMessages = 0x80241c58
export const RAM_D_8014B7F8 = 0x8014b7f8
export const RAM_D_80098154 = 0x80098154
export const RAM_dgb_01_itemList_80243C40 = 0x80243c40
export const RAM_D_802AD620 = 0x802ad620
export const RAM_D_80159E80 = 0x80159e80
export const RAM_kmr_02_Quizmo_NPC_OnRender = 0x8024075c
export const RAM_pra_10_SetGameStatusUnk84_1 = 0x80240000
export const RAM_kkj_17_CheckItemFlags40 = 0x80240268
export const RAM_b_area_pra3_formation_0B = 0x802327c4
export const RAM_D_801568F0 = 0x801568f0
export const RAM_b_area_arn_hyper_cleft_idle_80221DD0 = 0x80221dd0
export const RAM_D_80263478_C20CF8 = 0x80263478
export const RAM_func_80267018 = 0x80267018
export const RAM_kpa_111_ProjectileHitbox_GetUsableProjectileID = 0x80240170
export const RAM_contPakFileState = 0x8005f8a4
export const RAM_shim_remove_effect = 0xe02004a0
export const RAM_D_090030F8 = 0x090030f8
export const RAM_iwa_10_SuperBlock_CantUpgradeMessages = 0x80245a60
export const RAM_hos_03_Quizmo_UpdatePartnerPosition = 0x802433b4
export const RAM_flo_25_FlyingAI_LoiterInit = 0x80240818
export const RAM_b_area_hos_magikoopa_idleAnimations_8021D6D0 = 0x8021d6d0
export const RAM_func_802BC3E4_E2ED14 = 0x802bc3e4
export const RAM_pause_partners_update = 0x8024c7a4
export const RAM_sbk_62_SetNpcB5_3 = 0x80240040
export const RAM_D_80244B88_C36068 = 0x80244b88
export const RAM_sbk_40_Bandit_DropCoin = 0x80240040
export const RAM_D_800A0988 = 0x800a0988
export const RAM_emote_appendGfx = 0xe00205d0
export const RAM_b_area_sam_sam_02b_Set80071270_0_16 = 0x8021a3e8
export const RAM_trd_02_PatrolAI_MoveInit = 0x802408b4
export const RAM_func_802BB6D4_E2BE04 = 0x802bb6d4
export const RAM_dro_01_Quizmo_Requirements = 0x8024525c
export const RAM_flo_14_init_80244ADC = 0x80244adc
export const RAM_mac_02_Quizmo_SetVannaAnim_Clap = 0x802422c8
export const RAM_render_npcs = 0x8003a7f0
export const RAM_dro_02_npcSettings_80248754 = 0x80248754
export const RAM_mac_02_PatrolAI_PostLoiter = 0x80240fac
export const RAM_kpa_91_KoopaPatrolAI_Main = 0x80241224
export const RAM_isk_08_UnkNpcAIFunc12 = 0x802401b0
export const RAM_D_802DF428 = 0x802df428
export const RAM_battle_item_egg_missile_frame3_displayList = 0x802a2370
export const RAM_osExceptionPreamble = 0x8006aa00
export const RAM_b_area_trd_part_2_green_ninja_koopa_idleAnimations_8022573C = 0x8022573c
export const RAM_D_E004E5EC = 0xe004e5ec
export const RAM_set_game_mode_slot = 0x80112ca8
export const RAM_sam_11_Quizmo_RenderInit = 0x80240b30
export const RAM_battle_star_focus_UnkBackgroundFunc = 0x802a1414
export const RAM_dgb_03_UnkFunc11 = 0x80240000
export const RAM_arn_04_CleftAI_LosePlayer = 0x80242d0c
export const RAM_flo_14_npcGroup_80244D10 = 0x80244d10
export const RAM_D_80249BB0 = 0x80249bb0
export const RAM_hos_03_FlyingAI_WanderInit = 0x80240cb0
export const RAM_arn_07_init_80245090 = 0x80245090
export const RAM_arn_04_802433D0 = 0x802433d0
export const RAM_action_update_walk_peach = 0x802b65e8
export const RAM_HES_Item_MysticKey = 0x800817b8
export const RAM_HES_Item_PowerJump_disabled = 0x80084c08
export const RAM_D_09000420_326830 = 0x09000420
export const RAM_b_area_isk_part_2_isk_02_foregroundModelList_8021FE3C = 0x8021fe3c
export const RAM_gPauseMsg_49 = 0x8026f720
export const RAM_D_80099E1C = 0x80099e1c
export const RAM_D_80242A3C_E187BC = 0x80242a3c
export const RAM_b_area_mac_lee_idle_80225B68 = 0x80225b68
export const RAM_kzn_03_MeleeHitbox_30 = 0x80240bf8
export const RAM_flo_00_exitWalk_80241E90 = 0x80241e90
export const RAM_flo_11_802414E4 = 0x802414e4
export const RAM_D_8009BAA8 = 0x8009baa8
export const RAM_D_09002920_3AD950 = 0x09002920
export const RAM_ui_pow_cost_reduced_pal = 0x800fc3c0
export const RAM_dgb_02_MeleeHitbox_32 = 0x802400dc
export const RAM_mdl_renderTaskLists = 0x801533a0
export const RAM_D_E00A2A24 = 0xe00a2a24
export const RAM_b_area_kmr_part_1_kmr_02_afterBattle_8021FDC0 = 0x8021fdc0
export const RAM_b_area_omo2_toy_tank_8021A668 = 0x8021a668
export const RAM_jan_01_TransformFoliage = 0x80241200
export const RAM_TweesterTouchingPartner = 0x8010c954
export const RAM_b_area_pra3_stageTable = 0x8023298c
export const RAM_b_area_jan_spear_guy_spearStab = 0x802199ac
export const RAM_func_802546B0 = 0x802546b0
export const RAM_b_area_arn_arn_02_beforeBattle_80227A0C = 0x80227a0c
export const RAM_trd_07_FlyingAI_ChaseInit = 0x802417a4
export const RAM_pra_12_reflection_unk_change_anim_facing = 0x80240084
export const RAM_b_area_sbk_sbk_02 = 0x8021f90c
export const RAM_kpa_13_func_80240350_A56270 = 0x80240350
export const RAM_D_800A0980 = 0x800a0980
export const RAM_b_area_kmr_part_2_blue_goomba_defenseTable_802198B0 = 0x802198b0
export const RAM_omo_09_PatrolNoAttackAI_15 = 0x80241a20
export const RAM_D_802433D8_B76488 = 0x802433d8
export const RAM_func_80240EF8_BCFAE8 = 0x80240ef8
export const RAM_battle_item_tasty_tonic_GiveRefundCleanup = 0x802a11d4
export const RAM_flo_11_init_80242C38 = 0x80242c38
export const RAM_flo_22_pad_1594 = 0x80241594
export const RAM_HES_StickMashDown = 0x801090c4
export const RAM_D_80099C10 = 0x80099c10
export const RAM_D_8010BD90 = 0x8010bd90
export const RAM_tik_07_FlyingAI_LoiterInit = 0x80241bc8
export const RAM_nuContPfs = 0x800daad8
export const RAM_dro_01_Quizmo_ShouldQuizmoLeave = 0x80241fac
export const RAM_b_area_omo2_light_bulb_statusTable = 0x8022f614
export const RAM_D_802AD63C = 0x802ad63c
export const RAM_NAMESPACE = 0x80238758
export const RAM_vtx_C2DEA0 = 0x8020cf60
export const RAM_omo_04_FlyingAI_ChaseInit = 0x80241060
export const RAM_ui_goombario_disabled_pal = 0x800fe840
export const RAM_dgb_01_D_80251F24_C0F7A4 = 0x80251f24
export const RAM_D_8024D8D0_C0B150 = 0x8024d8d0
export const RAM_D_090035C0 = 0x090035c0
export const RAM_dro_02_pad_3C3C = 0x80243c3c
export const RAM_HES_StatusSPShine = 0x80104bec
export const RAM_sam_06_FlyingAI_Loiter = 0x80240de8
export const RAM_b_area_iwa_monty_mole_2 = 0x8021a690
export const RAM_D_8029CC10 = 0x8029cc10
export const RAM_mgm_02_SetBoxContents = 0x80240790
export const RAM_D_8015A1E1 = 0x8015a1e1
export const RAM_D_80250648_C0DEC8 = 0x80250648
export const RAM_hos_03_FlyingAI_JumpVels = 0x802457f4
export const RAM_b_area_trd_part_1_bill_blaster_vector3D_8021A4DC = 0x8021a4dc
export const RAM_battle_move_auto_multibounce_D_802A14F0 = 0x802a14f0
export const RAM_battle_item_pebble_pal = 0x802a1a00
export const RAM_omo_10_IsAOrBPressed = 0x80240518
export const RAM_sam_02_Quizmo_HideWorld = 0x80240f58
export const RAM_D_E00CACC8 = 0xe00cacc8
export const RAM_b_area_nok_spiked_goomba_CalculateRotationZ = 0x802186ec
export const RAM_b_area_kmr_part_1_kmr_04_clouds2 = 0x80220348
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa = 0x8022805c
export const RAM_dgb_01_D_80252318_C0FB98 = 0x80252318
export const RAM_battle_item_electro_pop_UseItemWithEffect = 0x802a1540
export const RAM_gPauseBadgesCurrentTab = 0x8027039c
export const RAM_kpa_111_ProjectileHitbox_33 = 0x80240688
export const RAM_flo_12_shakeTree = 0x802411e8
export const RAM_sparkle_script_init = 0x801309f0
export const RAM_EA36E0_GetActingPartner = 0x80242e00
export const RAM_flo_09_searchBush = 0x80242880
export const RAM_hud_element_create_transform_A = 0x80144f28
export const RAM_func_8025A50C = 0x8025a50c
export const RAM_pra_27_reflection_setup_wall = 0x80240158
export const RAM_HES_Whirlwind3 = 0x802aa750
export const RAM_D_09003800 = 0x09003800
export const RAM_heap_generalHead = 0x802fb800
export const RAM_tik_19_SuperBlock_ClearOverride40 = 0x8024060c
export const RAM_func_80218DF4_465284 = 0x80218df4
export const RAM_dgb_10_80240F00 = 0x80240f00
export const RAM_ui_battle_menu_unused_disabled_pal = 0x80289620
export const RAM_b_area_jan2_putrid_piranha_idleAnimations_8021B0A0 = 0x802186c0
export const RAM_func_8006EF60 = 0x8006ef60
export const RAM_kkj_19_GetNpcCollisionHeight = 0x802402b0
export const RAM_D_8029CC18 = 0x8029cc18
export const RAM_flo_03_pad_4ECC = 0x80244ecc
export const RAM_b_area_omo_groove_guy_randomSummon = 0x80226654
export const RAM_b_area_mim_stageTable = 0x8021ebec
export const RAM_flo_13_UnkDistFunc = 0x80242314
export const RAM_b_area_iwa_iwa_01_init_80220A50 = 0x80220a50
export const RAM_D_80246500_C60C40 = 0x80246500
export const RAM_b_area_jan_jan_02_foregroundModelList_802282DC = 0x802282dc
export const RAM_gPauseItemsIconIDs = 0x802705e8
export const RAM_osr_00_UpdateTexturePanStepped = 0x80240124
export const RAM_dgb_12_exitSingleDoor_802403B0 = 0x802403b0
export const RAM_dro_02_image2 = 0x8024a520
export const RAM_D_802AD10B = 0x802ad10b
export const RAM_b_area_kzn_spike_top = 0x8021c9a8
export const RAM_trd_10_StarSpiritEffectFunc4 = 0x802405bc
export const RAM_battle_move_hammer_D_802A27D0 = 0x802a27d0
export const RAM_b_area_trd_part_2_fake_bowser_80223870 = 0x80223870
export const RAM_sam_06_LetterDelivery_Init = 0x8024224c
export const RAM_arn_07_FlyingAI_WanderInit = 0x80240950
export const RAM_b_area_arn_arn_05_afterBattle_80227BBC = 0x80227bbc
export const RAM_D_80244D84_8F0BA4 = 0x80244d84
export const RAM_D_802A25F8 = 0x802a25f8
export const RAM_D_8024A2C4 = 0x8024a2c4
export const RAM_D_8024E1B4 = 0x8024e1b4
export const RAM_b_area_sbk_pokey_8021B590 = 0x8021b590
export const RAM_EC9D00_SuperBlock_SetOverride40 = 0x80240000
export const RAM_D_802A118C_0073472C = 0x802a118c
export const RAM_tik_12_set_script_owner_npc_anim = 0x80241960
export const RAM_pra_19_reflection_setup_wall = 0x80240128
export const RAM_gPauseMapTargetY = 0x80270718
export const RAM_clear_script_flags = 0x802c4880
export const RAM_ED0310_FlyingAI_Jump = 0x80240f74
export const RAM_D_800B1B10 = 0x800b1b10
export const RAM_D_802B7C00_E231B0 = 0x802b7c00
export const RAM_b_area_omo_formation_24 = 0x80230474
export const RAM_nok_11_FlyingAI_JumpVels = 0x80242888
export const RAM_end_01_CharAnim_FadeOut_3 = 0x80241a08
export const RAM_nok_12_UnsetCamera0Flag1000 = 0x8024030c
export const RAM_cold_breath_main = 0xe00de000
export const RAM_D_8024F7B8_C0D038 = 0x8024f7b8
export const RAM_battle_item_coconut_pal = 0x802a1a00
export const RAM_kpa_115_ProjectileHitbox_30 = 0x802404a4
export const RAM_HES_Item_IronKey = 0x800817e8
export const RAM_CurrentSefCmdHandler = 0x8009a5d4
export const RAM_b_area_hos_magikoopa_init_8022143C = 0x8022143c
export const RAM_dead_gItemTable = 0x8008d2c0
export const RAM_D_8024F448 = 0x8024f448
export const RAM_kpa_60_UpdateTexturePanSmooth = 0x802400e0
export const RAM_jan_05_HoppingAI_LosePlayer = 0x80241450
export const RAM_D_80250DE0_C0E660 = 0x80250de0
export const RAM_sam_02_Quizmo_SetVannaAnim_Clap = 0x80241634
export const RAM_func_80240D14_908744 = 0x80240d14
export const RAM_nok_12_PatrolAI_ChaseInit = 0x80241560
export const RAM_ui_battle_cmd_aim_marker_6_png = 0x8028e940
export const RAM_entity_Chest_enable_player_input = 0x802e7c8c
export const RAM_EB1170_LetterDelivery_RestoreNpcAnim = 0x80240a3c
export const RAM_dgb_01_exitDoubleDoor_802438D4 = 0x802438d4
export const RAM_whirlwind_update = 0xe00ce224
export const RAM_b_area_omo_blue_shy_guy_takeTurn_8021C7B4 = 0x8021c7b4
export const RAM_func_80241238_D4E278 = 0x80241238
export const RAM_dgb_01_SentinelAI_LosePlayer = 0x80241ca8
export const RAM_pra_01_80243178 = 0x80243178
export const RAM_func_80241EF8_9916D8 = 0x80241ef8
export const RAM_HES_Partner0Disabled = 0x80107cd0
export const RAM_sam_02_ToadHouse_CamSetFOV = 0x802408f4
export const RAM_snd_ambient_80055618 = 0x80055618
export const RAM_general_heap_free = 0x8002ac78
export const RAM_dro_01_Quizmo_VannaTEffect = 0x8024dfe8
export const RAM_HES_Item_InsecticideHerb = 0x800826b8
export const RAM_gPauseMapCursorCurrentOptionCopy = 0x80270720
export const RAM_b_area_jan_spear_guy_partsTable_802187F0 = 0x802187f0
export const RAM_b_area_kzn_white_magikoopa_healAll = 0x80226e1c
export const RAM_b_area_omo_sky_guy_idleAnimations_802295F0 = 0x802295f0
export const RAM_dup_mac_01_PatrolAI_Chase = 0x80245e9c
export const RAM_battle_partner_goombario_8023C90C = 0x8023c90c
export const RAM_b_area_kmr_part_3_final_jr_troopa_statusTable_8022C43C = 0x8022c43c
export const RAM_D_802D9E88 = 0x802d9e88
export const RAM_evt_handle_resume_all = 0x802c697c
export const RAM_D_80298374 = 0x80298374
export const RAM_b_area_pra3_swoopula_defenseTable = 0x80226e50
export const RAM_b_area_nok_formation_0A = 0x802190c0
export const RAM_func_80238E74_6F1F54 = 0x80238e74
export const RAM_btl_state_update_normal_start = 0x80241218
export const RAM_flo_17_FlyingAI_Chase = 0x80240e64
export const RAM_GetDoorData = 0x802827cc
export const RAM_hide_coin_counter = 0x800e9698
export const RAM_au_BGMCmd_F2_TrackTremoloTime = 0x8004fff0
export const RAM_func_80218B30_4E3270 = 0x80218b30
export const RAM_flo_00_npcSettings_80245E30 = 0x80245e30
export const RAM_battle_item_dusty_hammer_PlayerGoHome = 0x802a1670
export const RAM_dro_01_moveWalls_8024BC54 = 0x8024bc54
export const RAM_b_area_sam2_sam_03_UnkModelStuffScript3 = 0x80223194
export const RAM_dgb_13_main = 0x80240178
export const RAM_flo_17_npcAI_80244454 = 0x80244454
export const RAM_dro_01_dup_Pipe_GetEntryPos = 0x80243690
export const RAM_BoostDefense = 0x80270174
export const RAM_func_80240CB0_8ACC40 = 0x80240cb0
export const RAM_b_area_iwa_monty_mole_specialFormation_8021A6E0 = 0x8021a6e0
export const RAM_evt_handle_multiply = 0x802c58cc
export const RAM_LeoDiskHandle = 0x800b1b08
export const RAM_D_80247050 = 0x80247050
export const RAM_b_area_omo_groove_guy_basicAttack = 0x80225240
export const RAM_omo_11_SuperBlock_GatherEnergyFX = 0x80240cd4
export const RAM_kmr_02_StationaryAI_ReturnHomeInit = 0x80241964
export const RAM_flo_21_npcSettings_802417C0 = 0x802417c0
export const RAM_flo_11_802403E0 = 0x802403e0
export const RAM_dgb_16_MeleeHitbox_33 = 0x802401b4
export const RAM_b_area_mac_lee_partsTable_8022348C = 0x8022348c
export const RAM_spr_appendGfx_component = 0x802dc8f4
export const RAM_ui_status_text_8_png = 0x80100480
export const RAM_func_E000C0C8 = 0xe000c0c8
export const RAM_GetNextPathPos = 0x802d5524
export const RAM_D_80077828 = 0x80077828
export const RAM_HES_Item_Present = 0x800804f0
export const RAM_nok_01_ToadHouse_GetPartnerBackOut = 0x80240d30
export const RAM_b_area_omo_omo_04_takeTurn_8022F4A8 = 0x8022f4a8
export const RAM_D_80241600_A48560 = 0x80241600
export const RAM_func_E00AC288 = 0xe00ac288
export const RAM_b_area_kmr_part_2_goomnut_tree_tree = 0x80223d7c
export const RAM_func_802400C8_800948 = 0x802400c8
export const RAM_func_802A9000_4273B0 = 0x802a9000
export const RAM_func_80056D34 = 0x80056d34
export const RAM_b_area_isk_part_1_isk_03_beforeBattle_80223A40 = 0x80223a40
export const RAM_omo_07_PatrolAI_LoiterInit = 0x8024096c
export const RAM_state_init_startup = 0x80033540
export const RAM_func_80240388_8AC318 = 0x80240388
export const RAM_D_80243048_B2F4B8 = 0x80243048
export const RAM_D_802703FC = 0x802703fc
export const RAM_SetModelTexVariant = 0x802c91a4
export const RAM_D_80109484 = 0x80109484
export const RAM_b_area_iwa_iwa_02_specialFormation_whacka = 0x80221600
export const RAM_b_area_pra3_duplighost_watt_idleAnimations = 0x8022296c
export const RAM_D_802AD678 = 0x802ad678
export const RAM_IES_EarthquakeJump = 0x8008c864
export const RAM_func_80240000_9AE700 = 0x80240000
export const RAM_D_8014C6F4 = 0x8014c6f4
export const RAM_flo_21_802418C0 = 0x802418c0
export const RAM_D_E0200734 = 0xe0200734
export const RAM_D_80231614_63D1B4 = 0x80231614
export const RAM_kmr_02_Quizmo_UpdateRecords = 0x80240a7c
export const RAM_func_8013BC88 = 0x8013bc88
export const RAM_kmr_07_StationaryAI_ReturnHome = 0x802405ec
export const RAM_flo_13_FlyingAI_WanderInit = 0x802401c4
export const RAM_sam_06_ToadHouse_UpdateScreenOverlay = 0x80241f98
export const RAM_shop_owner_continue_speech = 0x8028017c
export const RAM_nok_15_FlyingAI_Jump = 0x80241708
export const RAM_GetActionResult = 0x802695a8
export const RAM_D_802477B8_DF6658 = 0x802477b8
export const RAM_flo_07_init_80242428 = 0x80242428
export const RAM_D_E0090A78 = 0xe0090a78
export const RAM_flo_11_80241E4C = 0x80241e4c
export const RAM_D_09000080_363940 = 0x09000080
export const RAM_b_area_isk_part_2_chain_chomp = 0x80218e9c
export const RAM_flo_14_FlyingAI_Main = 0x80241e1c
export const RAM_D_80099B50 = 0x80099b50
export const RAM_flo_00_makeEntities = 0x80249130
export const RAM_shim_npc_raycast_down_sides = 0xe0200530
export const RAM_pra_09_SetGameStatusUnk84_1 = 0x80240000
export const RAM_D_80241E90_8D5C50 = 0x80241e90
export const RAM_D_800A0931 = 0x800a0931
export const RAM_D_80231378_63CF18 = 0x80231378
export const RAM_D_E00628D8 = 0xe00628d8
export const RAM_water_block_update = 0xe00b413c
export const RAM_kpa_16_UpdateTexturePanSmooth = 0x80240000
export const RAM_world_watt_dispose_static_effect = 0x802bd180
export const RAM_D_E00CACC0 = 0xe00cacc0
export const RAM_tik_15_Pipe_AwaitDownInput = 0x80240034
export const RAM_HES_Item_SpikeShield_disabled = 0x80083be8
export const RAM_vtx_C2DFC0 = 0x8020d080
export const RAM_dro_01_Quizmo_Worker = 0x8024dfc0
export const RAM_D_80247AF0_CCB300 = 0x80247af0
export const RAM_b_area_nok_formation_09 = 0x80219088
export const RAM_D_8029F644 = 0x8029f644
export const RAM_func_802427D0_833FD0 = 0x802427d0
export const RAM_D_800F7B90 = 0x800f7b90
export const RAM_sign = 0x800294b0
export const RAM_b_area_sam2_sam_03 = 0x80223464
export const RAM_b_area_kzn_red_magikoopa_flying = 0x80221088
export const RAM_D_80241D9C_D5A62C = 0x80241d9c
export const RAM_evt_handle_exec1_get_id = 0x802c6524
export const RAM_func_80240CA8_9086D8 = 0x80240ca8
export const RAM_osCreateThread = 0x80065e60
export const RAM_GetPartMovementVar = 0x8026c170
export const RAM_HES_GreenArrowUp = 0x801077e4
export const RAM_kmr_02_Quizmo_VannaTEffect = 0x80257f50
export const RAM_flo_16_SuperBlock_GatherEnergyFX = 0x80240624
export const RAM_func_8024030C_AACB3C = 0x8024030c
export const RAM_b_area_arn_formation_10 = 0x802280f4
export const RAM_func_800F4FC4 = 0x800f4fc4
export const RAM_D_80154381 = 0x80154381
export const RAM_D_802B7D88_E20E98 = 0x802b7d88
export const RAM_ui_battle_cmd_fill_result_pal = 0x8028e560
export const RAM_b_area_kmr_part_1_spiked_goomba_CalculateRotationZ = 0x8021840c
export const RAM_calc_item_damage_enemy = 0x802516e8
export const RAM_vtx_C2AF80 = 0x8020a040
export const RAM_squirt_update = 0xe00b21e4
export const RAM_iwa_10_SuperBlock_WaitForPlayerToLand = 0x80240ff8
export const RAM_func_80218B38_59F218 = 0x80218b38
export const RAM_HES_AimMarkerF = 0x80293014
export const RAM_D_80241EA8_98AA08 = 0x80241ea8
export const RAM_b_area_trd_part_1_formation_03 = 0x80218538
export const RAM_b_area_sam2_gray_magikoopa_UnkFunc52 = 0x80218ab8
export const RAM_D_802AB520 = 0x802ab520
export const RAM_kpa_09_ProjectileHitbox_30 = 0x802403d4
export const RAM_ui_green_arrow_down_png = 0x800fc420
export const RAM_trd_07_set_script_owner_npc_anim = 0x80240310
export const RAM_battle_star_refresh_FadeBackgroundToBlack = 0x802a12e4
export const RAM_gPauseWS_4 = 0x8026f9e0
export const RAM_jan_03_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_stars_orbiting_update = 0xe005e1b8
export const RAM_KooperTakeOut = 0x802bd228
export const RAM_func_E00C2214 = 0xe00c2214
export const RAM_b_area_kzn_red_magikoopa_8022E334 = 0x80223074
export const RAM_mgm_02_OnHitBox = 0x80240644
export const RAM_mac_00_Quizmo_StageEffect = 0x80255b90
export const RAM_jan_12_MeleeHitbox_30 = 0x802406d4
export const RAM_clear_item_entity_flags = 0x80134188
export const RAM_func_80240E88_C77B68 = 0x80240e88
export const RAM_btl_state_update_22 = 0x802ab330
export const RAM_close_action_command_instruction_popup = 0x8024fb0c
export const RAM_HES_CRightButtonHeld = 0x802924f8
export const RAM_gPauseMsg_4A = 0x8026f728
export const RAM_hos_00_EnableActionCommands = 0x80240908
export const RAM_func_80240D14_BC8B74 = 0x80240d14
export const RAM_suspend_all_group = 0x802c45e8
export const RAM_b_area_sam_duplighost_kooper_defenseTable2 = 0x8021e720
export const RAM_b_area_trd_part_2_trd_02b = 0x802324d0
export const RAM_mac_05_Quizmo_CreateWorker = 0x80242384
export const RAM_battle_star_peach_focus_UnkStarFunc = 0x802a1494
export const RAM_fx_hieroglyphs = 0x800722f0
export const RAM_func_80240590_D13B60 = 0x80240590
export const RAM_kpa_91_PatrolNoAttackAI_15 = 0x80240ad4
export const RAM_kmr_20_ToadHouse_AwaitScriptComplete = 0x802413e4
export const RAM_spr_update_sprite = 0x802de2ac
export const RAM_D_E00628D0 = 0xe00628d0
export const RAM_D_801512B0 = 0x801512b0
export const RAM_BindNpcAux = 0x80044eb8
export const RAM_kpa_102_FlyingMagikoopaAI_11 = 0x8024244c
export const RAM_update_entity_inverse_rotation_matrix = 0x801104a4
export const RAM_b_area_kmr_part_1_spiked_goomba_init_8021E54C = 0x8021b16c
export const RAM_HES_PartnerRank = 0x80242b4c
export const RAM_mac_05_Quizmo_ShouldAppear = 0x8024145c
export const RAM_func_80240000_C71480 = 0x80240000
export const RAM_player_raycast_up_corners = 0x800de7e4
export const RAM_SetValueByRef = 0x802d58e0
export const RAM_EDC020_StashVars = 0x8024027c
export const RAM_dgb_01_D_80252B58_C103D8 = 0x80252b58
export const RAM_D_8014B7F0 = 0x8014b7f0
export const RAM_LoadSettings = 0x802cb9f8
export const RAM_D_090023D8 = 0x090023d8
export const RAM_flo_23_pad_12D8 = 0x802412d8
export const RAM_HES_Item_Refund = 0x80084a58
export const RAM_flo_22_80241028 = 0x80241028
export const RAM_func_802404E4_8FFB54 = 0x802404e4
export const RAM_mgm_00_PatrolNoAttackAI_Main = 0x80240e74
export const RAM_ui_battle_hp_3_png = 0x8028aa20
export const RAM_battle_item_life_shroom_UseItem = 0x802a1804
export const RAM_ui_battle_status_dizzy_0_png = 0x801020e0
export const RAM_tik_19_SuperBlock_PartnerSparkles2 = 0x80241268
export const RAM_D_80242F38_980A08 = 0x80242f38
export const RAM_D_80245A30_A8C480 = 0x80245a30
export const RAM_dgb_01_D_80253160_C109E0 = 0x80253160
export const RAM_mgm_00_Pipe_AwaitDownInput = 0x80240034
export const RAM_contPakFileDelete = 0x8005f864
export const RAM_SetNpcCollisionSize = 0x802cdf8c
export const RAM_dro_01_8024B664 = 0x8024b664
export const RAM_D_802427D8_A517D8 = 0x802427d8
export const RAM_b_area_sam_sam_02_UnkModelStuffScript1 = 0x8022f3f0
export const RAM_mac_03_Quizmo_UpdatePartnerPosition = 0x802413c0
export const RAM_D_802B79A8_E21858 = 0x802b79a8
export const RAM_flo_00_exitWalk_80241D7C = 0x80241d7c
export const RAM_b_area_nok_koopa_troopa_takeTurn_8021DD9C = 0x8022131c
export const RAM_action_command_spook_draw_hud_elements = 0x802a97fc
export const RAM_dgb_07_extraAnimationList_802418D4 = 0x802418d4
export const RAM_HES_Item_Unused_0E4 = 0x80085958
export const RAM_D_802559C0_8006D0 = 0x802559c0
export const RAM_b_area_sam_sam_03_afterBattle = 0x80230628
export const RAM_dead_get_npc_unsafe = 0x8003e4bc
export const RAM_gMsgSpeechBoxMQuad = 0x8014c400
export const RAM_kmr_03_npcGroupList_80241450 = 0x80241450
export const RAM_mgm_01_InitPanelHoldAboveBlock = 0x802407e4
export const RAM_battle_star_lullaby_UnkStarFunc1 = 0x802a1000
export const RAM_func_802BC200_E2C930 = 0x802bc200
export const RAM_b_area_sam_duplighost_goombario_tattle = 0x8021e2f0
export const RAM_D_E00E29F0 = 0xe00e29f0
export const RAM_clear_item_entity_data = 0x80130ff0
export const RAM_energy_shockwave_appendGfx = 0xe00723d4
export const RAM_D_802A25F0 = 0x802a25f0
export const RAM_evt_handle_case_equal_AND = 0x802c55b8
export const RAM_status_menu_start_blinking_hp = 0x800e9920
export const RAM_D_800743E0 = 0x800743e0
export const RAM_Merlow_SetBadgePurchased = 0x80240a94
export const RAM_b_area_mac_lee_sushie = 0x80226aa4
export const RAM_IES_AncientVase = 0x8008b604
export const RAM_DispatchDamageEvent = 0x8027d1bc
export const RAM_D_80249BB8 = 0x80249bb8
export const RAM_pause_items_update = 0x8024ac54
export const RAM_HES_Spirit5Missing = 0x80242568
export const RAM_fx_46_render = 0xe008c6b8
export const RAM_battle_star_focus_802A23BC = 0x802a23bc
export const RAM_b_area_trd_part_1_formation_0C = 0x80218810
export const RAM_arn_05_interact_80241794 = 0x80241794
export const RAM_ui_kaime_pal = 0x800fc780
export const RAM_flower_trail_main = 0xe0012204
export const RAM_func_80240B20_8EC940 = 0x80240b20
export const RAM_battle_action_cmd_water_block_3_pal = 0x802aa500
export const RAM_HES_StatusTimes = 0x80108068
export const RAM_b_area_omo2_2_stilt_guy_shy_guy_attack2 = 0x80234ca8
export const RAM_HES_Item_HPDrain_disabled = 0x800844e8
export const RAM_sam_06_ToadHouse_PutPartnerAway = 0x802421e0
export const RAM_func_80240F14_B21454 = 0x80240f14
export const RAM_D_801516FC = 0x801516fc
export const RAM_MAMAR_out_trackReadPos = 0x804204c4
export const RAM_gHudElementCacheTablePaletteWorld = 0x80157f70
export const RAM_jan_02_Quizmo_StageEffect = 0x80248940
export const RAM_D_80250360_C0DBE0 = 0x80250360
export const RAM_test_ray_zone_aabb = 0x80030458
export const RAM_update_coin_counter = 0x800e93dc
export const RAM_D_8015132C = 0x8015132c
export const RAM_D_802435B0_DA0A80 = 0x802435b0
export const RAM_D_80245EE0_9AE2D0 = 0x80245ee0
export const RAM_func_80055110 = 0x80055110
export const RAM_kmr_03_tree1Point = 0x802422a8
export const RAM_D_E002C9E0 = 0xe002c9e0
export const RAM_HES_PressCDownButton = 0x802926c4
export const RAM_D_E00587A8 = 0xe00587a8
export const RAM_battle_item_hustle_drink_GiveRefundCleanup = 0x802a11d4
export const RAM_HES_Watt = 0x80107e88
export const RAM_D_802438E8_A4BEA8 = 0x802438e8
export const RAM_b_area_kmr_part_1_kmr_02 = 0x8021fdd0
export const RAM_func_80055C64 = 0x80055c64
export const RAM_nok_15_FlyingAI_Init = 0x80241c9c
export const RAM_vtx_C2E2B0 = 0x8020d370
export const RAM_b_area_omo2_5_shy_squad_dup_onSpinSmash = 0x80232268
export const RAM_dgb_01_D_80252134_C0F9B4 = 0x80252134
export const RAM_func_802A9254 = 0x802a9254
export const RAM_D_80243DDC_EAC8BC = 0x80243ddc
export const RAM_ui_battle_cont_c_left_down_png = 0x8028b668
export const RAM_D_E8000008 = 0xe8000008
export const RAM_HES_Item_SuperSmash = 0x80084d58
export const RAM_D_8024C670_C09EF0 = 0x8024c670
export const RAM_D_09001200_3875B0 = 0x09001200
export const RAM_ui_stencil_blurry_circle_png = 0x8014d500
export const RAM_water_fountain_appendGfx = 0xe00b8208
export const RAM_osUnmapTLBAll = 0x80066960
export const RAM_kzn_10_UnkFunc49 = 0x802404ec
export const RAM_arn_05_interact_80241F88 = 0x80241f88
export const RAM_D_801507D0 = 0x801507d0
export const RAM_flo_11_Pipe_EnterHorizontal = 0x80240a50
export const RAM_D_800B6590 = 0x800b6590
export const RAM_dro_02_pad_D_8024F07C = 0x8024f07c
export const RAM_GetCamLookAtObjVector = 0x8003f398
export const RAM_D_80098208 = 0x80098208
export const RAM_battle_star_peach_focus_802A1E6C = 0x802a1e6c
export const RAM_SetActorRotation = 0x8026c3ac
export const RAM_D_8014C110 = 0x8014c110
export const RAM_mac_06_UpdateTexturePanStepped = 0x80240124
export const RAM_dro_01_PatrolNoAttackAI_15 = 0x802410b4
export const RAM_battle_partner_kooper_8023AA38 = 0x8023aa38
export const RAM_iwa_01_ProjectileHitbox_30 = 0x80240854
export const RAM_func_8024068C_A2755C = 0x8024068c
export const RAM_omo_02_UnkNpcAIFunc48 = 0x80241b38
export const RAM_pra_34_reflection_unk_resolve_anim = 0x80240020
export const RAM_kkj_01_PatrolAI_Loiter = 0x802404c0
export const RAM_D_8029F236 = 0x8029f236
export const RAM_kpa_95_ToadHouse_InitScreenOverlay = 0x8024181c
export const RAM_npc_iter_no_op = 0x8003857c
export const RAM_flo_17_FlyingAI_WanderInit = 0x80240070
export const RAM_arn_03_init_802424E0 = 0x802424e0
export const RAM_func_80240390_AD6260 = 0x80240390
export const RAM_nuGfxZBuffer = 0x8009a5dc
export const RAM_sam_01_Quizmo_StashVars = 0x80240700
export const RAM_b_area_nok_paratroopa_idle_8021EF5C = 0x80223d8c
export const RAM_fx_63_render = 0xe00c6768
export const RAM_D_E0076EA0 = 0xe0076ea0
export const RAM_D_E00587A0 = 0xe00587a0
export const RAM_flo_12_8024199C = 0x8024199c
export const RAM_jan_02_PatrolAI_Chase = 0x80240d7c
export const RAM_b_area_kmr_part_3_paragoomba1_idleAnimations_8021C470 = 0x8021c470
export const RAM_SetBattleMusic = 0x80044a38
export const RAM_kzn_08_FlyingAI_Wander = 0x80240c00
export const RAM_kzn_04_FireBar_Sounds = 0x802422f0
export const RAM_func_80240A7C_BD42EC = 0x80240a7c
export const RAM_HES_100pct = 0x80292974
export const RAM_b_area_sbk_pokey_idleAnimations_802184EC = 0x802184ec
export const RAM_func_8024FAE8 = 0x8024fae8
export const RAM_dgb_01_D_80251DF0_C0F670 = 0x80251df0
export const RAM_b_area_omo_medi_guy_UnkBattleFunc1 = 0x802184e0
export const RAM_HES_BlueMeter = 0x80108afc
export const RAM_D_80234980_6BBB80 = 0x80234980
export const RAM_flo_18_80245324 = 0x80245324
export const RAM_mac_02_Quizmo_StageEffect = 0x8024e560
export const RAM_D_80241E50_A6CAF0 = 0x80241e50
export const RAM_EC9D00_SuperBlock_PartnerSparkles2 = 0x80240c78
export const RAM_b_area_jan_spear_guy_nextTurn_80218B24 = 0x80218b24
export const RAM_b_area_sam2_sam_02_afterBattle = 0x802225f0
export const RAM_D_D9FCFFFF = 0xd9fcffff
export const RAM_b_area_mim_bzzap_8021B28C = 0x8021b28c
export const RAM_D_80153A56 = 0x80153a56
export const RAM_dgb_01_D_80252C60_C104E0 = 0x80252c60
export const RAM_jan_03_ToadHouse_DoesPlayerNeedSleep = 0x80241670
export const RAM_b_area_iwa_monty_mole_idleAnimations_8021A5E8 = 0x8021a5e8
export const RAM_kkj_20_ToadHouse_InitScreenOverlay = 0x802404d4
export const RAM_b_area_arn_hypergoomba_init_802188C0 = 0x802188c0
export const RAM_evt_handle_set_int_buffer_ptr = 0x802c5c28
export const RAM_b_area_kpa2_GetItemEntityPosition = 0x8021b314
export const RAM_IES_KootBook = 0x8008bce4
export const RAM_func_802549A0 = 0x802549a0
export const RAM_D_802E9874 = 0x802e9874
export const RAM_battle_move_super_smash_IsRightOnEquipped = 0x802a1050
export const RAM_test_ray_triangle_general = 0x8005be84
export const RAM_SetPlayerAnimationSpeed = 0x802d1104
export const RAM_D_802A9974_427344 = 0x802a9974
export const RAM_pra_27_ReflectWall = 0x8024011c
export const RAM_ui_battle_menu_unused_png = 0x80289400
export const RAM_snd_ambient_80055448 = 0x80055448
export const RAM_ui_super_boots_png = 0x800fb120
export const RAM_ui_status_text_9_png = 0x80100520
export const RAM_nok_01_Quizmo_SetVannaAnim_Wave = 0x8024188c
export const RAM_kpa_61_set_script_owner_npc_anim = 0x80240310
export const RAM_sbk_30_LetterDelivery_Init = 0x802412e4
export const RAM_D_80246558_kkj_19 = 0x80246558
export const RAM_pra_27_reflection_render_floor = 0x802406bc
export const RAM_HES_Empty = 0x8014efc8
export const RAM_battle_star_peach_dash_UnkStarFunc4 = 0x802a1218
export const RAM_b_area_trd_part_2_blue_ninja_koopa_partsTable_8022B564 = 0x8022b564
export const RAM_CheckButtonDown = 0x8026e108
export const RAM_mgm_01_PanelModelIDs = 0x80241b44
export const RAM_b_area_pra3_duplighost_sushie = 0x80223990
export const RAM_battle_item_snowman_doll_PlayerGoHome = 0x802a1870
export const RAM_b_area_trd_part_3_nok_01 = 0x80218fc0
export const RAM_func_802411D4_D7DD24 = 0x802411d4
export const RAM_D_802498F4_A33B34 = 0x802498f4
export const RAM_sbk_22_SetNpcB5_3 = 0x80240140
export const RAM_D_80245600 = 0x80245600
export const RAM_gScriptIndexList = 0x802da898
export const RAM_D_80099B58 = 0x80099b58
export const RAM_osAiSetFrequency = 0x80061560
export const RAM_func_80244C60_A2EEA0 = 0x80244c60
export const RAM_b_area_sam_duplighost_formation_kooper = 0x80220314
export const RAM_hud_element_set_script = 0x801447fc
export const RAM_jan_01_SetCamera0Flag1000 = 0x80241154
export const RAM_filemenu_draw_contents_option_right = 0x80244f44
export const RAM_flo_09_FlyingAI_Loiter = 0x80240bb8
export const RAM_btl_state_draw_next_enemy = 0x80247568
export const RAM_dgb_01_D_8025291C_C1019C = 0x8025291c
export const RAM_HES_StatusStar5 = 0x80108478
export const RAM_flo_21_UnkFloatFunc = 0x80240b40
export const RAM_battle_item_repel_gel_PlayerGoHome = 0x802a16d0
export const RAM_dup_mac_00_varStash = 0x8024ece8
export const RAM_D_8010BBB8 = 0x8010bbb8
export const RAM_b_area_sam_gray_magikoopa_init_flying = 0x8022e7ec
export const RAM_D_802AB558 = 0x802ab558
export const RAM_HES_StatusSPIncrement2 = 0x801082c0
export const RAM_filemenu_cursorGoalAlpha = 0x80249ba8
export const RAM_explosion_main = 0xe0032000
export const RAM_DropStarPoints = 0x8027c8a4
export const RAM_D_8014F514 = 0x8014f514
export const RAM_dro_02_LetterDelivery_SavedNpcAnim = 0x8024f3b8
export const RAM_func_80243758_A2D998 = 0x80243758
export const RAM_kzn_17_UnkNpcAIFunc12 = 0x80241e64
export const RAM_phys_update_falling = 0x800e3100
export const RAM_b_area_pra3_duplighost_lakilester_idle = 0x802253cc
export const RAM_iwa_01_MontyMoleAI_CanAttack = 0x80241414
export const RAM_D_E00E0AD8 = 0xe00e0ad8
export const RAM_mac_02_LetterDelivery_Init = 0x80243104
export const RAM_D_8010D66C = 0x8010d66c
export const RAM_battle_star_refresh_UnkStarFunc2 = 0x802a10ac
export const RAM_kkj_10_UnkModelMatrixFunc2 = 0x8024036c
export const RAM_BasicAI_Main = 0x8004a47c
export const RAM_state_init_intro = 0x80036650
export const RAM_func_80245744_A2F984 = 0x80245744
export const RAM_evt_handle_get_Nth_word = 0x802c5e5c
export const RAM_evt_handle_get_2_float = 0x802c5f0c
export const RAM_D_09002500_3AD530 = 0x09002500
export const RAM_pra_28_StashVars = 0x80240f50
export const RAM_HES_Item_MapleSuper_disabled = 0x80082fe8
export const RAM_D_8010BBB0 = 0x8010bbb0
export const RAM_battle_item_thunder_rage_EatItem = 0x802a196c
export const RAM_flower_splash_render = 0xe00104b0
export const RAM_ui_input_analog_stick_up_pal = 0x800f9c80
export const RAM_func_8021835C_59EA3C = 0x8021835c
export const RAM_func_802A9234 = 0x802a9234
export const RAM_nok_02_Quizmo_RenderInit = 0x80241c70
export const RAM_dgb_01_D_80252D68_C105E8 = 0x80252d68
export const RAM_flo_00_exitWalk_80241F48 = 0x80241f48
export const RAM_HES_Item_DizzyAttack_disabled = 0x800850e8
export const RAM_kmr_02_LetterDelivery_SaveNpcAnim = 0x802426a0
export const RAM_battle_item_super_soda_GiveRefund = 0x802a1000
export const RAM_b_area_pra3_duplighost_goombario_idleAnimations = 0x8021bb74
export const RAM_HES_Item_FlowerSaver = 0x80084ab8
export const RAM_radial_shimmer_main = 0xe0066000
export const RAM_D_80231564_63D104 = 0x80231564
export const RAM_D_E0030EE0 = 0xe0030ee0
export const RAM_dro_02_pad_D_8024EFBC = 0x8024efbc
export const RAM_b_area_isk_part_1_ChompChainUpdate = 0x802184cc
export const RAM_trd_04_SetEntityPosition = 0x80240344
export const RAM_D_80244630_EEABE0 = 0x80244630
export const RAM_arn_07_npcGroup_802470BC = 0x802470bc
export const RAM_kzn_19_StarSpiritEffectFunc5 = 0x80240908
export const RAM_D_8024DDE8_968FE8 = 0x8024dde8
export const RAM_tik_10_SuperBlock_AnimateEnergyOrbs = 0x80240c80
export const RAM_sin_cos_rad = 0x8002a0b4
export const RAM_D_800B0B6C = 0x800b0b6c
export const RAM_battle_item_fright_jar_FadeBackgroundToBlack = 0x802a123c
export const RAM_PlayerCreateTargetList = 0x8026e2cc
export const RAM_b_area_isk_part_1_isk_02c_beforeBattle_80223E90 = 0x80223e90
export const RAM_D_E003A800 = 0xe003a800
export const RAM_spirit_card_update = 0xe01121c0
export const RAM_kpa_133_UpdateTexturePanStepped = 0x80240124
export const RAM_flo_10_pad_1CA8 = 0x80241ca8
export const RAM_ui_status_pow_unit_5_png = 0x80100800
export const RAM_b_area_nok_paragoomba_statusTable_8021B1D4 = 0x8021b1d4
export const RAM_load_model_animator_tree = 0x801203ac
export const RAM_flo_03_init_8024374C = 0x8024374c
export const RAM_b_area_pra3_white_clubba_handleEvent = 0x8022b2ec
export const RAM_D_E0030EE8 = 0xe0030ee8
export const RAM_b_area_isk_part_2_tutankoopa_takeTurn_8021CCD4 = 0x8021ccd4
export const RAM_au_ambience_disable = 0x800556d0
export const RAM_D_09000450_3308B0 = 0x09000450
export const RAM_b_area_sam_sam_01_afterBattle = 0x8022f398
export const RAM_D_802440F4 = 0x802440f4
export const RAM_D_80241470_9EF060 = 0x80241470
export const RAM_D_8029D8D8 = 0x8029d8d8
export const RAM_flo_19_802416E8 = 0x802416e8
export const RAM_world_parakarry_update = 0x802beab0
export const RAM_ED8E20_Pipe_GetCurrentFloor = 0x80240020
export const RAM_hud_element_set_render_depth = 0x801449dc
export const RAM_gBGMPlayerA = 0x8009a664
export const RAM_D_8025C0D0 = 0x8025c0d0
export const RAM_jan_22_varStash = 0x802425bc
export const RAM_ShowRecoveryShimmer = 0x802d75d8
export const RAM_b_area_omo_omo_05b_foregroundModelList_8022F5F4 = 0x8022f5f4
export const RAM_D_802435D8_E15D38 = 0x802435d8
export const RAM_status_menu_draw_stat = 0x800e7e34
export const RAM_pra_37_ReflectFloor = 0x802404cc
export const RAM_kmr_05_FlyingAI_Main = 0x8024130c
export const RAM_kmr_03_main = 0x802407f0
export const RAM_b_area_kzn_red_magikoopa_GetSelectedMoveID = 0x80218788
export const RAM_D_E00D6F00 = 0xe00d6f00
export const RAM_D_802465B8_B6C068 = 0x802465b8
export const RAM_D_E02006E0 = 0xe02006e0
export const RAM_b_area_trd_part_1_bill_blaster_takeTurn_8021A200 = 0x8021a200
export const RAM_sam_06_AddPlayerHandsOffset = 0x80241bbc
export const RAM_b_area_kmr_part_3_para_jr_troopa_idleAnimations_80225E04 = 0x80225e04
export const RAM_mac_04_foliage_setup_shear_mtx = 0x80240220
export const RAM_tik_23_UnkNpcAIFunc12 = 0x80240b10
export const RAM_b_area_kmr_part_2_goomnut_tree_80223DBC = 0x80223dbc
export const RAM_enable_status_transparent = 0x80047b08
export const RAM_au_bgm_end_segment_loop = 0x8004ea34
export const RAM_b_area_mac_lee_statusTable_802269D4 = 0x802269d4
export const RAM_arn_11_npcGroupList_802410DC = 0x802410dc
export const RAM_IES_MapleSyrup = 0x8008daf4
export const RAM_D_E02006E8 = 0xe02006e8
export const RAM_D_E020069C = 0xe020069c
export const RAM_func_8027CC10 = 0x8027cc10
export const RAM_osViSwapBuffer = 0x80067020
export const RAM_HES_Item_Lemon_disabled = 0x80086ca8
export const RAM_ECAA80_FlowerGuard_ItemChoiceList = 0x80246460
export const RAM_SetTargetActor = 0x8027c548
export const RAM_evt_handle_allocate_array = 0x802c6214
export const RAM_D_801510F0 = 0x801510f0
export const RAM_pra_35_ReflectWall = 0x802400ec
export const RAM_D_80245608 = 0x80245608
export const RAM_D_802A1814_73E074 = 0x802a1814
export const RAM_action_command_tidal_wave_free_hud_elements = 0x802a9764
export const RAM_tik_10_SuperBlock_PartnerIDs = 0x80242038
export const RAM_func_80219190_5CF820 = 0x80219190
export const RAM_dgb_16_main = 0x80241688
export const RAM_gPauseMapCameraY = 0x80270708
export const RAM_b_area_nok_paratroopa_idleAnimations_80220AEC = 0x8022591c
export const RAM_flo_10_foliage_setup_shear_mtx = 0x80241230
export const RAM_disable_world_fog = 0x8011bb64
export const RAM_func_E00D8288 = 0xe00d8288
export const RAM_func_80240020_B06A20 = 0x80240020
export const RAM_arn_05_func_80241068_BE8C48 = 0x80241068
export const RAM_jan_03_Quizmo_UpdatePartnerPosition = 0x80242aac
export const RAM_b_area_pra3_duplighost_UnkWattEffectFunc4 = 0x802193a4
export const RAM_b_area_jan2_putrid_piranha_UnkEffect6FFunc = 0x802180cc
export const RAM_PlaySound = 0x802d6150
export const RAM_b_area_pra2_crystal_king_FlyWithClones = 0x80218e34
export const RAM_dro_01_tree1_Trunk = 0x8024dd2c
export const RAM_sam_06_GetItemName = 0x80241afc
export const RAM_sbk_55_TransformFoliage = 0x802400d0
export const RAM_ldiv = 0x8006d620
export const RAM_D_8010CFBC = 0x8010cfbc
export const RAM_flo_19_80241734 = 0x80241734
export const RAM_D_E00628F0 = 0xe00628f0
export const RAM_b_area_arn_arn_06_foregroundModelList_80227C30 = 0x80227c30
export const RAM_BoostAttack = 0x8026fcd4
export const RAM_func_80144218 = 0x80144218
export const RAM_dro_02_npcList_80244C70 = 0x80244c70
export const RAM_D_A4300000 = 0xa4300000
export const RAM_D_80159B50 = 0x80159b50
export const RAM_b_area_kmr_part_3_spiked_goomba_statusTable_8021A39C = 0x8021a39c
export const RAM_pause_banner_hp_png = 0x8026c630
export const RAM_flo_09_FlyingAI_ChaseInit = 0x80240fe0
export const RAM_dgb_00_npcSettings_80240640 = 0x80240640
export const RAM_func_80240310_C6CC30 = 0x80240310
export const RAM_kkj_00_PatrolAI_PostLoiter = 0x8024072c
export const RAM_HES_TransparentEnd = 0x80106e98
export const RAM_EB1170_GetNpcCollisionHeight = 0x80240428
export const RAM_kmr_12_ExitEast = 0x8024011c
export const RAM_b_area_kmr_part_3_kpa_01_80234CB0 = 0x80234cb0
export const RAM_b_area_kzn_kzn_01b = 0x80227884
export const RAM_dgb_01_D_802528F0_C10170 = 0x802528f0
export const RAM_omo_17_ProjectileAI_Reflect = 0x80242f44
export const RAM_D_80097DD0 = 0x80097dd0
export const RAM_kpa_91_PatrolAI_JumpInit = 0x80240730
export const RAM_func_802D1380 = 0x802d1380
export const RAM_landing_dust_main = 0xe000c160
export const RAM_b_area_pra3_duplighost_bombette_handleEvent = 0x8021f6d4
export const RAM_flo_17_FlyingAI_JumpVels = 0x8024440c
export const RAM_D_80270289 = 0x80270289
export const RAM_flo_08_main = 0x80242a6c
export const RAM_b_area_kmr_part_3_kmr_05_clouds2 = 0x80232cc8
export const RAM_func_802BC00C_E2E93C = 0x802bc00c
export const RAM_tik_03_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_D_8010C92C = 0x8010c92c
export const RAM_D_802DB268 = 0x802db268
export const RAM_D_80242310 = 0x80242310
export const RAM_D_8023BD98 = 0x8023bd98
export const RAM_func_80242694_833E94 = 0x80242694
export const RAM_b_area_sam_sam_03_UnkModelStuffScript3 = 0x80230374
export const RAM_func_8021B678_6A2878 = 0x8021b678
export const RAM_D_802313A8_63CF48 = 0x802313a8
export const RAM_b_area_mim_forest_fuzzy_idle_80218574 = 0x80218574
export const RAM_au_SEFCmd_08 = 0x8004d07c
export const RAM_action_update_run_peach = 0x802b6738
export const RAM_pra_40_reflection_render_floor = 0x8024068c
export const RAM_func_80238000_703AF0 = 0x80238000
export const RAM_HES_MoveDiamond = 0x80109338
export const RAM_b_area_omo2_5_shy_squad_dup_idle = 0x80231fd8
export const RAM_D_80286520 = 0x80286520
export const RAM_battle_item_egg_missile1_png = 0x802a18d0
export const RAM_hud_element_set_transform_rotation = 0x80145224
export const RAM_dgb_01_D_80253220_C10AA0 = 0x80253220
export const RAM_D_80247BE0_EE65A0 = 0x80247be0
export const RAM_func_802405D0_AE5B60 = 0x802405d0
export const RAM_purple_ring_init = 0xe003e3f8
export const RAM_b_area_kmr_part_3_mac_01 = 0x80234c80
export const RAM_flo_16_pad_44F8 = 0x802444f8
export const RAM_toy_tank_as_shake = 0x8021a724
export const RAM_D_E002C9E8 = 0xe002c9e8
export const RAM_D_80073E0A = 0x80073e0a
export const RAM_world_kooper_pre_battle = 0x802bea24
export const RAM_func_802414BC_B6D84C = 0x802414bc
export const RAM_get_world_fog_color = 0x8011bbd8
export const RAM_IES_JadeRaven = 0x8008b9c4
export const RAM_D_090012A0_3276B0 = 0x090012a0
export const RAM_cancel_current_message = 0x802d0c48
export const RAM_D_8027028A = 0x8027028a
export const RAM_ui_box_corners9_png = 0x801099e0
export const RAM_func_80240F8C_DE341C = 0x80240f8c
export const RAM_SetGoalPos = 0x8026a510
export const RAM_trd_04_FlyingAI_Chase = 0x80242c0c
export const RAM_func_E00D8280 = 0xe00d8280
export const RAM_b_area_omo2_formationTable = 0x8022fbf4
export const RAM_dgb_04_SuperBlock_GetPartnerRank = 0x80242438
export const RAM_IES_RightOn = 0x8008c424
export const RAM_D_80243BF0_A78460 = 0x80243bf0
export const RAM_b_area_trd_part_2_fake_bowser_8021BF14 = 0x8021bf14
export const RAM_HES_Item_FireShield_disabled = 0x80084008
export const RAM_suspend_group_script_index = 0x802c44f8
export const RAM_b_area_kmr_part_3_formation_02 = 0x8021a204
export const RAM_D_80243BD0_A1ECD0 = 0x80243bd0
export const RAM_arn_12_80240248 = 0x80240248
export const RAM_b_area_isk_part_1_formation_07 = 0x802242fc
export const RAM_trd_02_PatrolAI_LoiterInit = 0x80240cb4
export const RAM_func_80282E30 = 0x80282e30
export const RAM_dgb_01_D_80251D98_C0F618 = 0x80251d98
export const RAM_kpa_81_GetNpcCollisionHeight = 0x80240050
export const RAM_b_area_sbk_formation_07 = 0x8021fb54
export const RAM_battle_item_sleepy_sheep_frame2_displayList = 0x802a3080
export const RAM_EC9D00_SuperBlock_WaitForPlayerToLand = 0x80240d08
export const RAM_b_area_kmr_part_1_paragoomba_idleAnimations_8021B384 = 0x8021cd74
export const RAM_D_E7000008 = 0xe7000008
export const RAM_b_area_kzn_lava_bubble_partsTable_80218EE8 = 0x80218ee8
export const RAM_D_E000CC38 = 0xe000cc38
export const RAM_EA7320_SetCamera0Flag1000 = 0x80240350
export const RAM_D_802626E0_822F60 = 0x802626e0
export const RAM_nok_02_Quizmo_ShouldQuizmoLeave = 0x8024244c
export const RAM_dgb_15_PatrolNoAttackAI_Main = 0x80241464
export const RAM_sbk_32_SpawnSunEffect = 0x80240000
export const RAM_DispatchEventPlayer = 0x80276f1c
export const RAM_dgb_06_EVS_Chest_GetItem = 0x8024068c
export const RAM_battle_item_super_soda_UseItem = 0x802a1834
export const RAM_kzn_07_FlyingAI_JumpInit = 0x802413fc
export const RAM_pause_update_page_inactive_2 = 0x80243388
export const RAM_D_80286528 = 0x80286528
export const RAM_D_8024C088 = 0x8024c088
export const RAM_D_801094A8 = 0x801094a8
export const RAM_cold_breath_render = 0xe00de260
export const RAM_ui_bombette_pal = 0x800feca0
export const RAM_D_802485E8_808E68 = 0x802485e8
export const RAM_func_80266EE8 = 0x80266ee8
export const RAM_jan_06_MeleeHitbox_31 = 0x80241888
export const RAM_D_E0044EF8 = 0xe0044ef8
export const RAM_D_80247F70_C057F0 = 0x80247f70
export const RAM_b_area_pra3_duplighost_sushie_takeTurn = 0x80223f84
export const RAM_D_E0034790 = 0xe0034790
export const RAM_D_801507D8 = 0x801507d8
export const RAM_D_8023C1C8 = 0x8023c1c8
export const RAM_D_E00C4A10 = 0xe00c4a10
export const RAM_HES_ListPrevPage = 0x802426a0
export const RAM_dro_01_8024B614 = 0x8024b614
export const RAM_b_area_sam2_sam_02c_UnkModelStuffScript2 = 0x802229dc
export const RAM_D_80099E30 = 0x80099e30
export const RAM_HES_Item_PUpDDown = 0x80084f38
export const RAM_gPausePartnersPartnerIDs = 0x8024f6b0
export const RAM_func_80218224_59E904 = 0x80218224
export const RAM_ui_battle_status_exclamation_png = 0x80101b40
export const RAM_battle_partner_kooper_runAway = 0x80238a7c
export const RAM_b_area_jan2_jan_03_afterBattle_8021F3A0 = 0x8021f3a0
export const RAM_ui_battle_status_transparent_2_pal = 0x80102f20
export const RAM_flo_03_defeat_80241F64 = 0x80241f64
export const RAM_D_802A9852_4292C0 = 0x802a9850
export const RAM_mac_00_LetterDelivery_SavedNpcAnim = 0x80255ed8
export const RAM_pra_14_reflection_render_floor_fancy = 0x80240870
export const RAM_b_area_omo_anti_guy_takeTurn_802233AC = 0x802233ac
export const RAM_func_8024159C_8ED3BC = 0x8024159c
export const RAM_func_802430B4_855224 = 0x802430b4
export const RAM_omo_07_ShyGuyPatrolAI_16 = 0x8024153c
export const RAM_b_area_kmr_part_2_red_goomba_2_DivActorLevel4 = 0x80218a30
export const RAM_arn_05_func_80240E90_BE8A70 = 0x80240e90
export const RAM_ui_battle_status_frozen_1_png = 0x80102540
export const RAM_battle_item_stone_cap_main = 0x802a198c
export const RAM_D_E007AC30 = 0xe007ac30
export const RAM_func_80240000_DCCCD0 = 0x80240000
export const RAM_delete_model_animator_nodes = 0x8011e400
export const RAM_dgb_18_PatrolAI_Move = 0x80240158
export const RAM_IES_DizzyAttack = 0x8008c684
export const RAM_gSpeakingActor = 0x8029fa74
export const RAM_b_area_jan2_white_magikoopa_partsTable_80223CB4 = 0x8021a064
export const RAM_is_starting_conversation = 0x80044148
export const RAM_kpa_08_MagikoopaAI_01 = 0x802407f4
export const RAM_npc_set_palswap_mode_A = 0x8003b3d0
export const RAM_battle_partner_kooper_80238B7C = 0x80238b7c
export const RAM_D_E00E0AD0 = 0xe00e0ad0
export const RAM_fx_stat_change = 0x800718d0
export const RAM_jan_02_Quizmo_ShouldAppear = 0x80241750
export const RAM_SetPlayerCollisionSize = 0x802d0fb0
export const RAM_arn_08_func_80240000_BF47A0 = 0x80240000
export const RAM_HES_Item_LifeShroom_disabled = 0x80086828
export const RAM_mac_00_Quizmo_AnswerResult = 0x80255b88
export const RAM_ui_battle_cmd_aim_marker_3_png = 0x8028e760
export const RAM_IES_ForestPass = 0x8008b684
export const RAM_dgb_00_80240AB0 = 0x80240ab0
export const RAM_kpa_95_UnkNpcAIFunc12 = 0x80241120
export const RAM_D_E0090A70 = 0xe0090a70
export const RAM_b_area_jan_formation_00 = 0x80228580
export const RAM_flo_17_FlyingAI_ChaseInit = 0x80240d30
export const RAM_D_8029D8D0 = 0x8029d8d0
export const RAM_flo_21_main = 0x8024137c
export const RAM_D_80243328_A2A1F8 = 0x80243328
export const RAM_sam_01_LetterDelivery_SavedNpcAnim = 0x8024df24
export const RAM_b_area_mac_lee_init_Parakarry = 0x802234fc
export const RAM_vtx_C284F0 = 0x802075b0
export const RAM_func_80241DF8_97F8C8 = 0x80241df8
export const RAM_D_80151138 = 0x80151138
export const RAM_b_area_kzn_medi_guy_flyingAttack = 0x80220210
export const RAM_HES_Item_IcePower = 0x80084698
export const RAM_flo_16_FlyingAI_Init = 0x80242038
export const RAM_nuAuDmaState = 0x800a3bd0
export const RAM_evt_handle_addF = 0x802c5a84
export const RAM_LoadDemoBattle = 0x80044264
export const RAM_D_090038F8_3AE928 = 0x090038f8
export const RAM_D_8010CFF8 = 0x8010cff8
export const RAM_b_area_jan_m_bush_init_80221988 = 0x80221988
export const RAM_pra_16_reflection_unk_resolve_anim = 0x80240020
export const RAM_flo_08_exitWalk_8024292C = 0x8024292c
export const RAM_IES_BakingWater = 0x8008dc54
export const RAM_vtx_C2AE50 = 0x80209f10
export const RAM_mac_00_Quizmo_SetCamVfov = 0x8024119c
export const RAM_nok_14_FlyingAI_Wander = 0x80240b44
export const RAM_dro_01_EVS_Quizmo_RightAnswer = 0x80246798
export const RAM_func_802402B4_8FF134 = 0x802402b4
export const RAM_flo_19_80241BB0 = 0x80241bb0
export const RAM_D_E0098510 = 0xe0098510
export const RAM_D_E008CB60 = 0xe008cb60
export const RAM_dgb_03_MeleeHitbox_33 = 0x80240404
export const RAM_D_E000E660 = 0xe000e660
export const RAM_test_item_entity_position = 0x80133fc8
export const RAM_battle_item_shooting_star_func_802A14D4_71DC44 = 0x802a14d4
export const RAM_b_area_trd_part_2_trd_04_80232970 = 0x80232970
export const RAM_D_802A99E4_42ED84 = 0x802a99e4
export const RAM_dgb_03_80242CF0 = 0x80242cf0
export const RAM_D_8024A978_DD7648 = 0x8024a978
export const RAM_b_area_iwa_buzzar_idleAnimations_8021B644 = 0x8021b644
export const RAM_func_80240480_ADB320 = 0x80240480
export const RAM_D_8010D6B0 = 0x8010d6b0
export const RAM_hud_element_load_script = 0x8013f350
export const RAM_func_8021833C_6DC0DC = 0x8021833c
export const RAM_HES_Item_BakingWater = 0x80087458
export const RAM_mdl_renderModelFogEnd = 0x8014b75c
export const RAM_mgm_00_Pipe_GetCurrentFloor = 0x80240020
export const RAM_HES_Item_CrystalBerry = 0x800819c8
export const RAM_ShowShockEffect = 0x8026fbb0
export const RAM_dgb_01_dgb_08_name_hack = 0x802636b0
export const RAM_dgb_09_ClubbaNappingAI_FallAsleep = 0x80241078
export const RAM_b_area_iwa_buzzar_defenseTable_8021B650 = 0x8021b650
export const RAM_b_area_omo_formation_17 = 0x8022fff8
export const RAM_b_area_trd_part_1_trd_02_80222B60 = 0x80222b60
export const RAM_PostChapter3StatUpdate = 0x80241e48
export const RAM_D_80241B74_A28A44 = 0x80241b74
export const RAM_D_8014B9D8 = 0x8014b9d8
export const RAM_mgm_00_work_draw_record = 0x802416c0
export const RAM_gHudElementCacheBufferWorld = 0x80157964
export const RAM_b_area_trd_part_2_trd_00_foregroundModelList_8023142C = 0x8023142c
export const RAM_D_80243F40_D2FC70 = 0x80243f40
export const RAM_render_transform_group = 0x8011800c
export const RAM_b_area_pra3_gray_magikoopa_parts = 0x8022ef50
export const RAM_D_80280000 = 0x80280000
export const RAM_tik_07_SuperBlock_GatherEnergyFX = 0x80240824
export const RAM_D_80244480_B70810 = 0x80244480
export const RAM_func_80242680_DF9080 = 0x80242680
export const RAM_func_802535B4 = 0x802535b4
export const RAM_tik_21_UnkPosFunc2 = 0x802405ec
export const RAM_func_80241500_8D2A10 = 0x80241500
export const RAM_mac_06_unkAngleFunc003 = 0x80240974
export const RAM_appendGfx_enemy_actor_decorations = 0x80257b68
export const RAM_HES_Item_KoopaTea_disabled = 0x80082f28
export const RAM_b_area_jan2_white_magikoopa_statusTable_80223B38 = 0x80219ee8
export const RAM_nok_12_SetEntityPosition = 0x80240224
export const RAM_func_8010FD98 = 0x8010fd98
export const RAM_D_800984E0 = 0x800984e0
export const RAM_ED0310_FlyingAI_Chase = 0x80241114
export const RAM_pra_13_reflection_render_floor = 0x8024068c
export const RAM_dro_02_func_80241874_96AA34 = 0x80241874
export const RAM_flo_21_npcGroup_80243804 = 0x80243804
export const RAM_HES_Item_Unused_0E4_disabled = 0x80085988
export const RAM_omo_07_PatrolNoAttackAI_Main = 0x80241100
export const RAM_au_BGMCmd_E4_MasterTempoFade = 0x8004fd94
export const RAM_pause_draw_menu_label = 0x8024ee94
export const RAM_HES_Item_Kaiden = 0x80085898
export const RAM_EFEB00_UpdateTexturePanSmooth = 0x80240000
export const RAM_kzn_07_FlyingNoAttackAI_Main = 0x80242124
export const RAM_func_802CFE2C = 0x802cfe2c
export const RAM_dgb_06_npcGroupList_80240B44 = 0x80240b44
export const RAM_func_E0076854 = 0xe0076854
export const RAM_HES_Item_UltraStone = 0x80081ab8
export const RAM_npc__reset_current_decoration = 0x8003cfa8
export const RAM_D_802A36D8_73FF38 = 0x802a36d8
export const RAM_kmr_04_func_80240444_8CAD44 = 0x80240444
export const RAM_battle_partner_goombario_statusTable_80238018 = 0x80238018
export const RAM_func_802B7728_E20838 = 0x802b7728
export const RAM_PlayerFirstStrike = 0x802851fc
export const RAM_setup_partner_popup = 0x800e6b78
export const RAM_flower_splash_dispose = 0xe00104f4
export const RAM_state_init_demo = 0x80038080
export const RAM_battle_item_life_shroom_GiveRefund = 0x802a1000
export const RAM_ui_status_coin_5_png = 0x80100e20
export const RAM_dup3_b_area_pra_UnkBattleFunc1 = 0x80218b64
export const RAM_flo_00_main = 0x80242248
export const RAM_b_area_isk_part_2_chain_chomp_defenseTable_80218C5C = 0x80218c5c
export const RAM_b_area_sam_sam_03_UnkModelStuff_func1 = 0x8021ab50
export const RAM_WaitForBuffDone = 0x8027114c
export const RAM_b_area_sam_duplighost_lakilester_idleAnimations = 0x80226180
export const RAM_D_80242000_C80180 = 0x80242000
export const RAM_D_801510F8 = 0x801510f8
export const RAM_b_area_kmr_part_3_paragoomba1_init_8021F450 = 0x8021f450
export const RAM_battle_partner_goombario_StopGlowingAndGet = 0x80239068
export const RAM_func_802400C0_D4BAE0 = 0x802400c0
export const RAM_func_80240520_C9C820 = 0x80240520
export const RAM_D_802BCB78_E314C8 = 0x802bcb78
export const RAM_jan_08_SuperBlock_EndGlowEffect = 0x80240b70
export const RAM_func_802BC220_E2EB50 = 0x802bc220
export const RAM_func_802B75E8_E206F8 = 0x802b75e8
export const RAM_vtx_C2E4A0 = 0x8020d560
export const RAM_D_802A26C8_742388 = 0x802a26b0
export const RAM_func_80110F10 = 0x80110f10
export const RAM_b_area_jan2_jan_00_afterBattle_8021E2C8 = 0x8021e2c8
export const RAM_func_80240020_D546B0 = 0x80240020
export const RAM_func_802D1270 = 0x802d1270
export const RAM_b_area_mac_chan_statusTable_80219D78 = 0x80219d78
export const RAM_mgm_01_GetPanelInfo = 0x8024045c
export const RAM_flo_25_npcAISettings_80242A00 = 0x80242a00
export const RAM_D_802AD66C = 0x802ad66c
export const RAM_dro_02_npcGroupList_8024EEF4 = 0x8024eef4
export const RAM_b_area_sam2_sam_01_foregroundModelList = 0x802221c8
export const RAM_nok_01_StationaryAI_LosePlayer = 0x802404f8
export const RAM_IES_BakingSugar = 0x8008db94
export const RAM_battle_star_refresh_UnkStarFunc = 0x802a1494
export const RAM_b_area_omo_sky_guy_idleAnimations_80229558 = 0x80229558
export const RAM_D_E0076EF8 = 0xe0076ef8
export const RAM_dgb_02_settings = 0x80241470
export const RAM_b_area_arn_hyper_paragoomba_handleEvent_8021C9C4 = 0x8021c9c4
export const RAM_flo_18_80242E28 = 0x80242e28
export const RAM_flo_16_SuperBlock_LoadCurrentPartnerName = 0x80240528
export const RAM_HES_Item_Unused_08B_disabled = 0x80082328
export const RAM_dgb_04_npcSettings_80243AFC = 0x80243afc
export const RAM_b_area_arn_hyper_cleft_idleAnimations_80221CD4 = 0x80221cd4
export const RAM_D_8024DD28_C0B5A8 = 0x8024dd28
export const RAM_flo_10_80243628 = 0x80243628
export const RAM_func_8004DC80 = 0x8004dc80
export const RAM_D_802204E0_4F7980 = 0x802204e0
export const RAM_func_8024001C_A4F01C = 0x8024001c
export const RAM_arn_04_PatrolAI_Chase = 0x8024094c
export const RAM_dro_01_LetterDelivery_SavedNpcAnim = 0x8024e1b4
export const RAM_flo_14_802425B4 = 0x802425b4
export const RAM_evt_handle_AND = 0x802c6270
export const RAM_D_8024EF90 = 0x8024ef90
export const RAM_D_8023BDB8 = 0x8023bdb8
export const RAM_filemenu_info_update = 0x80248160
export const RAM_suspend_group_others = 0x802c46a8
export const RAM_D_80097DD8 = 0x80097dd8
export const RAM_arn_08_npcGroupList_80241828 = 0x80241828
export const RAM_D_800AF8B4 = 0x800af8b4
export const RAM_ui_coin_sparkle_0_pal = 0x800fbca0
export const RAM_func_80238000_710EF0 = 0x80238000
export const RAM_guPositionF = 0x80063f10
export const RAM_b_area_mac_chan_idle_8021A11C = 0x8021a11c
export const RAM_omo_17_ProjectileHitbox_32 = 0x80242924
export const RAM_flo_13_vectorList_802443E4 = 0x802443e4
export const RAM_b_area_omo2_1_shy_squad_displace_guy = 0x80232810
export const RAM_D_80245CF0_9FA730 = 0x80245cf0
export const RAM_omo_11_SuperBlock_UpgradeDescMessages = 0x80241e58
export const RAM_dead_get_model_from_list_index = 0x80124f44
export const RAM_func_802BC9FC_E3134C = 0x802bc9fc
export const RAM_GetActorYaw = 0x8026b3c8
export const RAM_mim_08_MeleeHitbox_Main = 0x80241954
export const RAM_D_800939A4 = 0x800939a4
export const RAM_b_area_jan_formation_08 = 0x80228820
export const RAM_D_E00628F8 = 0xe00628f8
export const RAM_b_area_kmr_part_2_red_goomba = 0x8021b79c
export const RAM_kzn_04_SuperBlock_SwitchToPartner = 0x8024072c
export const RAM_kpa_111_UnkFunc12 = 0x802400bc
export const RAM_battle_item_sleepy_sheep_frame1_displayList = 0x802a2f90
export const RAM_flo_14_80244604 = 0x80244604
export const RAM_update_input = 0x80028874
export const RAM_gSpeakingActorPart = 0x8029fa78
export const RAM_b_area_sam2_sam_02b_UnkModelStuff_array = 0x80222640
export const RAM_flo_25_FlyingAI_Loiter = 0x802408d8
export const RAM_world_parakarry_put_away = 0x802beae8
export const RAM_sam_11_UpdateTexturePanStepped = 0x80241bc4
export const RAM_IES_DDownPound = 0x8008c164
export const RAM_kpa_102_FlyingMagikoopaAI_24 = 0x802430a8
export const RAM_b_area_kmr_part_1_goomba_idleAnimations_802198A0 = 0x80219760
export const RAM_D_80247698_D1FE78 = 0x80247698
export const RAM_pra_04_UnkFunc001 = 0x802401b0
export const RAM_floating_cloud_puff_render = 0xe00d2234
export const RAM_b_area_jan_formationTable = 0x80228af8
export const RAM_b_area_isk_part_1_isk_01_afterBattle_8022396C = 0x8022396c
export const RAM_D_80099B80 = 0x80099b80
export const RAM_fx_rising_bubble = 0x800705b0
export const RAM_mgm_01_GetPanelPos = 0x80240ecc
export const RAM_HES_ParakarryDisabled = 0x80107e10
export const RAM_ui_status_coin_8_pal = 0x80101080
export const RAM_D_E004AEB0 = 0xe004aeb0
export const RAM_gGameState = 0x8009a600
export const RAM_flo_16_FlyingAI_Wander = 0x80241000
export const RAM_mac_02_Quizmo_GetCamVfov = 0x802423a0
export const RAM_action_command_water_block_free_hud_elements = 0x802aa0d8
export const RAM_D_80097E20 = 0x80097e20
export const RAM_b_area_jan_jungle_fuzzy_idle_8021EB8C = 0x8021eb8c
export const RAM_b_area_mac_lee_partsTable_80226A80 = 0x80226a80
export const RAM_HES_TimingCharge4b = 0x80292848
export const RAM_update_item_entity_static = 0x80135658
export const RAM_b_area_sam_formation_08 = 0x80230d20
export const RAM_shimmer_burst_update = 0xe00701f4
export const RAM_b_area_kzn_formation_04 = 0x802298fc
export const RAM_nuIdleFunc = 0x8009a5a8
export const RAM_EDEA00_set_script_owner_npc_col_height = 0x80242604
export const RAM_D_8023C1C0 = 0x8023c1c0
export const RAM_func_802427E8_E07B48 = 0x802427e8
export const RAM_D_8024F074 = 0x8024f074
export const RAM_sam_01_Quizmo_SetCamVfov = 0x8024126c
export const RAM_b_area_mim_piranha_plant = 0x8021d470
export const RAM_D_090022B0_388660 = 0x090022b0
export const RAM_jan_08_MeleeHitbox_Main = 0x80241a24
export const RAM_dro_02_Quizmo_Worker = 0x8024efa0
export const RAM_func_802406C8_8EC4E8 = 0x802406c8
export const RAM_D_80099B88 = 0x80099b88
export const RAM_flo_00_idle_80244188 = 0x80244188
export const RAM_nok_14_FlyingAI_LosePlayer = 0x802417ec
export const RAM_EED1E0_SuperBlock_CantUpgradeMessages = 0x80243760
export const RAM_func_802412E0_B85460 = 0x802412e0
export const RAM_IES_AutoMultibounce = 0x8008c184
export const RAM_b_area_arn_arn_03_foregroundModelList_80227AC0 = 0x80227ac0
export const RAM_D_80098200 = 0x80098200
export const RAM_b_area_sam_duplighost_parakarry_handleEvent = 0x80221760
export const RAM_ui_status_text_4_png = 0x80100200
export const RAM_ECAA80_FlowerGuard_JudgeItemTastiness = 0x80241b88
export const RAM_heap_battleHead = 0x803da800
export const RAM_D_E010CA18 = 0xe010ca18
export const RAM_hos_06_varStash = 0x80245e80
export const RAM_D_8024A970_DD7640 = 0x8024a970
export const RAM_wMapShapeName = 0x800d9230
export const RAM_D_802A25C8 = 0x802a25c8
export const RAM_trd_07_UnkNpcAIFunc12 = 0x802404c0
export const RAM_D_09001648 = 0x09001648
export const RAM_kkj_20_CheckItemFlags40 = 0x80240268
export const RAM_D_80243CD0_A720B0 = 0x80243cd0
export const RAM_dgb_01_D_80252108_C0F988 = 0x80252108
export const RAM_dup_kmr_20_Pipe_GetCameraYaw = 0x80241950
export const RAM_D_8024909C_82C35C = 0x8024909c
export const RAM_DigitHudScripts = 0x800f7f80
export const RAM_EED1E0_SuperBlock_ShowSelectPartnerMenu = 0x802402ac
export const RAM_kpa_111_GetEncounterEnemyIsOwner = 0x80241384
export const RAM_battle_item_unknown_item_EatItem = 0x802a180c
export const RAM_battle_item_mystery_GiveRefund = 0x802a1000
export const RAM_b_area_kzn_white_magikoopa_defenseTable_80223B20 = 0x80223b20
export const RAM_b_area_omo_omo_04_vector3D_8022DE34 = 0x8022de34
export const RAM_D_80254D68 = 0x80254d68
export const RAM_b_area_omo_red_shy_guy = 0x802192f4
export const RAM_dgb_01_D_80252B84_C10404 = 0x80252b84
export const RAM_arn_02_exitWalk_8024114C = 0x8024114c
export const RAM_dro_02_KeyItemChoiceList = 0x8024f080
export const RAM_guPerspective = 0x80063d40
export const RAM_b_area_nok_paragoomba_idleAnimations_8021B3DC = 0x8021b3dc
export const RAM_flo_14_interact_80244790 = 0x80244790
export const RAM_end_01_CreditsProjMatrices = 0x8024b9d0
export const RAM_spirit_card_main = 0xe0112000
export const RAM_EA0900_UpdateTexturePanStepped = 0x80240124
export const RAM_tik_07_Pipe_GetCameraYaw = 0x802401d0
export const RAM_iwa_01_CleftAI_HidingInit = 0x80242424
export const RAM_flo_16_FlyingNoAttackAI_Main = 0x80242754
export const RAM_kpa_95_ToadHouse_UpdateScreenOverlay = 0x80241650
export const RAM_lens_flare_render = 0xe00342c8
export const RAM_func_802A27E4 = 0x802a27e4
export const RAM_HES_WaterBlock4 = 0x802ab0e0
export const RAM_D_09003540 = 0x09003540
export const RAM_btl_cam_add_zoom = 0x8024e5b4
export const RAM_D_802AD628 = 0x802ad628
export const RAM_gWindowStyles = 0x8014f150
export const RAM_mgm_00_UpdateRecordDisplay = 0x802416f8
export const RAM_b_area_nok_nok_03 = 0x8022bb5c
export const RAM_sbk_56_TransformFoliage = 0x80240e30
export const RAM_b_area_pra2_crystal_king_paths = 0x80221630
export const RAM_b_area_sam_duplighost_handleEvent = 0x8021b15c
export const RAM_b_area_omo3_UnkBattleFunc2_2 = 0x80218a24
export const RAM_tik_12_SuperBlock_ShowSelectPartnerMenu = 0x80240de8
export const RAM_mac_02_SetEntityFlags100000 = 0x802408f0
export const RAM_sparkles_init = 0xe0022544
export const RAM_b_area_sam2_monstar_SetBackgroundAlpha = 0x8021849c
export const RAM_func_800DFCF4 = 0x800dfcf4
export const RAM_D_E00D2500 = 0xe00d2500
export const RAM_D_80242EB0_980980 = 0x80242eb0
export const RAM_b_area_sam_duplighost_lakilester_statusTable = 0x802261e4
export const RAM_func_8024043C_D9D90C = 0x8024043c
export const RAM_kzn_17_UnkFunc48 = 0x80242e84
export const RAM_iwa_10_SuperBlock_LoadCurrentPartnerName = 0x80240738
export const RAM_dgb_05_npcAI_802419DC = 0x802419dc
export const RAM_SetPartFlags = 0x8026cf88
export const RAM_D_80241F98_D4EFD8 = 0x80241f98
export const RAM_D_E0040848 = 0xe0040848
export const RAM_kmr_11_settings = 0x028000c0
export const RAM_AssignChestFlag = 0x801120b8
export const RAM_gather_energy_pink_update = 0xe002618c
export const RAM_green_impact_update = 0xe00641a4
export const RAM_b_area_omo_pink_shy_guy_8021F22C = 0x8021f22c
export const RAM_entity_TriggerBlock_start_bound_script = 0x802e44cc
export const RAM_HES_Item_Multibounce_disabled = 0x80083a68
export const RAM_HES_AimShimmerE = 0x8029343c
export const RAM_D_8024636C_DD303C = 0x8024636c
export const RAM_func_80240000_A9E980 = 0x80240000
export const RAM_dro_02_init_8024D3E8 = 0x8024d3e8
export const RAM_D_8024F800_841000 = 0x8024f800
export const RAM_D_80152220 = 0x80152220
export const RAM_b_area_arn_hyper_paragoomba_partsTable_8021B460 = 0x8021b460
export const RAM_dispatch_damage_event_partner = 0x8027f9cc
export const RAM_dro_01_Quizmo_CreateReactionEffect = 0x80242468
export const RAM_set_aux_pan_u = 0x8011bc4c
export const RAM_GetAngleBetweenNPCs = 0x802d4708
export const RAM_b_area_kpa_UnkFloatFunc = 0x80218358
export const RAM_D_09000C40_3ABC70 = 0x09000c40
export const RAM_b_area_omo2_general_guy_attack_throw_bomb = 0x80218c8c
export const RAM_dgb_05_MeleeHitbox_CanSeePlayer = 0x80240268
export const RAM_pra_06_reflection_render_floor = 0x802406bc
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_UnkBattleFunc1 = 0x80218910
export const RAM_func_802A92DC = 0x802a92dc
export const RAM_D_802502F8_C0DB78 = 0x802502f8
export const RAM_b_area_kzn_white_magikoopa_wMagikoopa_ShapeSpell = 0x802250a8
export const RAM_flo_11_80241698 = 0x80241698
export const RAM_GotoMapSpecial = 0x802ca420
export const RAM_sam_01_Quizmo_RenderInit = 0x80240a60
export const RAM_D_8029FB84 = 0x8029fb84
export const RAM_rising_bubble_init = 0xe0046158
export const RAM_func_802BC658_E2EF88 = 0x802bc658
export const RAM_b_area_kmr_part_2_blue_goomba_2 = 0x8021d4dc
export const RAM_Entity_HitFloatingYellowBlock = 0x802ea63c
export const RAM_b_area_omo_omo_05_afterBattle_8022F534 = 0x8022f534
export const RAM_D_80077908 = 0x80077908
export const RAM_entity_anim_make_vertex_pointers = 0x801110b4
export const RAM_entity_HeartBlockContent__reset = 0x802e4b60
export const RAM_b_area_iwa_buzzar_handleEvent_8021B940 = 0x8021b940
export const RAM_DispatchDamagePlayerEvent = 0x8027491c
export const RAM_heap_free = 0x8002ad2c
export const RAM_flo_13_func_802433C0_CC6BD0 = 0x802433c0
export const RAM_omo_02_ProjectileHitbox_31 = 0x80241d7c
export const RAM_func_802A120C_74D77C = 0x802a120c
export const RAM_D_E00DE858 = 0xe00de858
export const RAM_PostChapter5StatUpdate = 0x80240fe0
export const RAM_spr_unpack_signed_16bit = 0x802dd0fc
export const RAM_nok_15_TackleAI_Main = 0x80240674
export const RAM_b_area_isk_part_1_swooper_idle_8021C6B0 = 0x8021c6b0
export const RAM_firework_rocket_appendGfx = 0xe010a4e8
export const RAM_b_area_arn_formation_0C = 0x80227fa8
export const RAM_battle_star_chill_out_UnkStarFunc = 0x802a1494
export const RAM_HES_Item_ShrinkSmash_disabled = 0x80085508
export const RAM_WalkPeachAnims = 0x802b6910
export const RAM_HES_Item_FriedEgg_disabled = 0x80083168
export const RAM_omo_03_UnsetCamera0MoveFlag1 = 0x80240000
export const RAM_D_8014C260 = 0x8014c260
export const RAM_flo_22_tree1 = 0x80241f14
export const RAM_D_09000000_32EC50 = 0x09000000
export const RAM_func_802404D0_9EE0C0 = 0x802404d0
export const RAM_flo_25_FlyingNoAttackAI_13 = 0x80241668
export const RAM_HES_ParalyzedLoop = 0x80105430
export const RAM_gPauseMsg_54 = 0x8026f764
export const RAM_D_80242118_D999A8 = 0x80242118
export const RAM_D_80094664 = 0x80094664
export const RAM_battle_star_refresh_SetNpcCollision32 = 0x802a14e8
export const RAM_update_encounters_conversation = 0x8004309c
export const RAM_alUnlink = 0x8004b40c
export const RAM_func_80242040_8B20B0 = 0x80242040
export const RAM_func_802BD5F4_31B614 = 0x802bd5f4
export const RAM_func_80240488_90BB58 = 0x80240488
export const RAM_D_802BFF2C = 0x802bff2c
export const RAM_func_80241000_C77CE0 = 0x80241000
export const RAM_b_area_omo_yellow_shy_guy_init_8021C968 = 0x8021c968
export const RAM_b_area_sam_sam_02b_foregroundModelList = 0x8022fb3c
export const RAM_b_area_pra3_duplighost_watt_idle = 0x80222b34
export const RAM_flo_10_80244A50 = 0x80244a50
export const RAM_gPauseSpiritsPositions = 0x8024f93c
export const RAM_D_8029F240 = 0x8029f240
export const RAM_EF2680_LakituAI_GetAvailableSpiny = 0x80241594
export const RAM_mim_08_FlyingAI_Loiter = 0x80240938
export const RAM_b_area_trd_part_2_blue_ninja_koopa_UnkWhirlwindEffectFunc = 0x802193b8
export const RAM_pra_34_SetEntityFlags100000 = 0x80240f20
export const RAM_D_80252784_9E97A4 = 0x80252784
export const RAM_omo_09_ShyGuyWanderAI_15 = 0x80240a50
export const RAM_func_80050C64 = 0x80050c64
export const RAM_battle_item_insecticide_herb_displayList = 0x802a1c40
export const RAM_HES_WaterBlockBlock = 0x802ab108
export const RAM_flo_25_npcSettings_802429D4 = 0x802429d4
export const RAM_func_802406DC_B4B14C = 0x802406dc
export const RAM_func_8006EF18 = 0x8006ef18
export const RAM_D_E0122BA8 = 0xe0122ba8
export const RAM_func_802A9398_42A888 = 0x802a9398
export const RAM_dgb_04_exitSingleDoor_80243210 = 0x80243210
export const RAM_func_80240068_AF79B8 = 0x80240068
export const RAM_dgb_08_FlyingAI_ChaseInit = 0x80242420
export const RAM_b_area_pra3_duplighost_goombario_parts = 0x8021bc78
export const RAM_flo_23_80240D30 = 0x80240d30
export const RAM_kpa_130_BillBlasterAI_GetIdleBulletNpcID = 0x80240000
export const RAM_func_802401A0_DDC9D0 = 0x802401a0
export const RAM_dro_01_EVS_Quizmo_SetQuizCamera = 0x8024535c
export const RAM_HES_Item_HappyHeart = 0x80084878
export const RAM_kzn_18_get_tattle = 0x80240000
export const RAM_b_area_kzn_kzn_04c_80228900 = 0x80228900
export const RAM_b_area_iwa_iwa_01b_beforeBattle_80221510 = 0x80221510
export const RAM_omo_13_ShyGuyWanderAI_14 = 0x80240280
export const RAM_base_GiveRefund = 0x80260e90
export const RAM_flo_16_80244500 = 0x80244500
export const RAM_pra_31_AddPlayerHandsOffset = 0x8024007c
export const RAM_D_80241760_DDDF90 = 0x80241760
export const RAM_battle_star_smooch_802A19C8 = 0x802a19c8
export const RAM_b_area_mim_mim_01_UnkFogFunc2 = 0x80218360
export const RAM_huff_puff_breath_init = 0xe00dc190
export const RAM_b_area_trd_part_1_paratroopa_defenseTable_8021EB08 = 0x8021eb08
export const RAM_set_curtain_scale_goal = 0x8002bedc
export const RAM_D_802EB0A0 = 0x802eb0a0
export const RAM_pra_13_ReflectPartner = 0x80240d3c
export const RAM_D_80246648_EA6F48 = 0x80246648
export const RAM_arn_04_exitWalk_80243480 = 0x80243480
export const RAM_D_09000420 = 0x09000420
export const RAM_D_8024DFE0 = 0x8024dfe0
export const RAM_D_802A2384 = 0x802a2384
export const RAM_D_E0076EF0 = 0xe0076ef0
export const RAM_update_lerp = 0x8002a3b4
export const RAM_b_area_omo_pink_shy_guy_statusTable_8021E324 = 0x8021e324
export const RAM_D_E00DC640 = 0xe00dc640
export const RAM_func_80044290 = 0x80044290
export const RAM_func_80240090_D4BAB0 = 0x80240090
export const RAM_b_area_omo_groove_guy_specialFormation_802273A8 = 0x802273a8
export const RAM_gv_01_Pipe_GetPointAheadOfPlayer = 0x8024023c
export const RAM_battle_star_peach_dash_802A1EEC = 0x802a1eec
export const RAM_D_802417D0_909200 = 0x802417d0
export const RAM_D_8010D694 = 0x8010d694
export const RAM_BattleCam_BoomPitch = 0x8029f284
export const RAM_moving_cloud_appendGfx = 0xe00e83bc
export const RAM_b_area_iwa_cleft_partsTable_802187EC = 0x802187ec
export const RAM_jan_04_StashVars = 0x80240350
export const RAM_b_area_omo_sky_guy_handleEvent_8022B310 = 0x8022b310
export const RAM_battle_action_cmd_water_block_block_pal = 0x802aa860
export const RAM_vtx_C28A70 = 0x80207b30
export const RAM_dgb_08_SentinelAI_LosePlayer = 0x802432e8
export const RAM_dgb_08_npcGroup_80245290 = 0x80245290
export const RAM_battle_item_sleepy_sheep2_pal = 0x802a29d0
export const RAM_au_pvoice_set_playing = 0x80056f78
export const RAM_D_80075F68 = 0x80075f68
export const RAM_AddEffectOffset = 0x80280880
export const RAM_vtx_C29CD0 = 0x80208d90
export const RAM_vtx_C2DCB0 = 0x8020cd70
export const RAM_func_E0082580 = 0xe0082580
export const RAM_omo_07_FlyingAI_Loiter = 0x80242210
export const RAM_func_802D2148 = 0x802d2148
export const RAM_broken_kmr_04_function_addr = 0x80243770
export const RAM_GetPartTarget = 0x8026a9b8
export const RAM_D_8014F130 = 0x8014f130
export const RAM_gItemEntityScripts = 0x8008ddd4
export const RAM_set_mdl_custom_gfx_set = 0x8011bc7c
export const RAM_func_8024030C_AF2FAC = 0x8024030c
export const RAM_func_80241274_D65854 = 0x80241274
export const RAM_D_80098490 = 0x80098490
export const RAM_D_802447D4_A3DCB4 = 0x802447d4
export const RAM_b_area_kmr_part_2_red_goomba_2_defenseTable_8021EE00 = 0x8021ee00
export const RAM_jan_00_AddPlayerHandsOffset = 0x80240568
export const RAM_D_80249D30_AC4FC0 = 0x80249d30
export const RAM_D_09001378 = 0x09001378
export const RAM_D_09001CC8 = 0x09001cc8
export const RAM_D_80258140 = 0x80258140
export const RAM_b_area_sam_duplighost_formation_sushie = 0x80226164
export const RAM_arn_07_StarSpiritEffectFunc2 = 0x8024004c
export const RAM_b_area_sam2_paragoomba_StartRumbleWithParams = 0x80218530
export const RAM_func_802408D0_BB7940 = 0x802408d0
export const RAM_D_8009A5B8 = 0x8009a5b8
export const RAM_func_80240000_BC7E60 = 0x80240000
export const RAM_dgb_04_npcAISettings_802435B4 = 0x802435b4
export const RAM_phys_get_spin_history = 0x800e5938
export const RAM_IES_StoreroomKey = 0x8008b744
export const RAM_gather_magic_appendGfx = 0xe008e4e0
export const RAM_D_8024EF98 = 0x8024ef98
export const RAM_pause_badges_scroll_offset_x = 0x802472a8
export const RAM_D_8024DFE8 = 0x8024dfe8
export const RAM_D_09000640_326A50 = 0x09000640
export const RAM_HES_Item_HoneySyrup = 0x80086fd8
export const RAM_battle_item_fire_flower_FadeBackgroundToBlack = 0x802a12e0
export const RAM_pra_10_reflection_render_floor = 0x8024068c
export const RAM_mac_06_UnkFloatFunc001 = 0x802416f8
export const RAM_sam_11_SetCamera0MoveFlag1 = 0x80240580
export const RAM_ParakarryUpdate = 0x802bd180
export const RAM_func_802401B0_7E7550 = 0x802401b0
export const RAM_D_E011E818 = 0xe011e818
export const RAM_b_area_omo2_5_shy_squad_dup_idleAnimations_watt = 0x80231334
export const RAM_D_E011E810 = 0xe011e810
export const RAM_battle_item_egg_missile4_png = 0x802a1f30
export const RAM_D_80298520 = 0x80298520
export const RAM_b_area_arn_tubba_blubba_80227878 = 0x80227878
export const RAM_func_80282634 = 0x80282634
export const RAM_D_80246558_C60C98 = 0x80246558
export const RAM_b_area_arn_hypergoomba_ArcsinDeg = 0x80218058
export const RAM_ui_pow_cost_pal = 0x800fc3a0
export const RAM_battle_move_jump_GetJumpDamage = 0x802a10e4
export const RAM_mac_03_Quizmo_VannaTEffect = 0x802513f8
export const RAM_AddCoin = 0x802d7278
export const RAM_D_802EA728 = 0x802ea728
export const RAM_FullyRestoreSP = 0x802d1ee0
export const RAM_b_area_omo_sky_guy_statusTable_802296D0 = 0x802296d0
export const RAM_flo_08_UnkFloAI_ChaseInit = 0x80240d80
export const RAM_D_802452B0_BB2BF0 = 0x802452b0
export const RAM_D_80234908_6BBB08 = 0x80234908
export const RAM_kmr_20_ToadHouse_PartnerResumeAbilityScript = 0x80241440
export const RAM_jan_05_MeleeHitbox_32 = 0x802419a8
export const RAM_b_area_pra2_crystal_bit_idleAnimations_3 = 0x80220498
export const RAM_dro_02_func_802415CC_96A78C = 0x802415cc
export const RAM_b_area_omo2_toy_tank_8022F458 = 0x8022f458
export const RAM_gSaveSlotHasData = 0x80077a24
export const RAM_ExecutePeachAction = 0x80285174
export const RAM_SetModelFlags = 0x802c9518
export const RAM_kmr_02_StationaryAI_Alert = 0x80241658
export const RAM_b_area_arn_formation_05 = 0x80227dcc
export const RAM_arn_02_makeEntities = 0x802412d0
export const RAM_flo_00_init_80243474 = 0x80243474
export const RAM_vtx_curtains2 = 0x80075980
export const RAM_D_E00DC648 = 0xe00dc648
export const RAM_shattering_stones_init = 0xe002c234
export const RAM_D_80290C70 = 0x80290c70
export const RAM_ShakeCam = 0x802cb2a8
export const RAM_world_lakilester_take_out = 0x802bfe60
export const RAM_evt_goto_next_case = 0x802c8a60
export const RAM_kpa_52_GetEntityPosition = 0x80240034
export const RAM_b_area_omo_groove_guy_8022671C = 0x8022671c
export const RAM_D_802424E0 = 0x802424e0
export const RAM_D_80245468_EF7A78 = 0x80245468
export const RAM_b_area_nok_kent_c_koopa_tackle_KentC = 0x8022a0d0
export const RAM_spr_draw_player_sprite = 0x802ddc40
export const RAM_b_area_arn_arn_04_beforeBattle_80227B00 = 0x80227b00
export const RAM_sbk_02_GetNpcCollisionHeight = 0x802406f0
export const RAM_clear_render_tasks_alt = 0x8011d8d0
export const RAM_kzn_19_UpdateTexturePanStepped = 0x80240124
export const RAM_D_8024C4C0_C09D40 = 0x8024c4c0
export const RAM_nok_13_HoppingAI_LosePlayer = 0x80240bac
export const RAM_b_area_mac_master2_handleEvent_8022D634 = 0x8022d634
export const RAM_dup_kmr_20_Pipe_GetEntryPos = 0x80241870
export const RAM_omo_17_RangedAttackAI_Main = 0x802433a0
export const RAM_D_E010CA60 = 0xe010ca60
export const RAM_GetSelfAnimationFromTable = 0x80045734
export const RAM_kpa_03_DarkRoomUpdate = 0x80240000
export const RAM_b_area_kmr_part_3_spiked_para_jr_troopa_80228084 = 0x80228084
export const RAM_bgm_adjust_proximity = 0x8014aa54
export const RAM_dgb_08_init_80244E94 = 0x80244e94
export const RAM_entity_TriggerBlock_start_bound_script_2 = 0x802e4040
export const RAM_spr_playerSprites = 0x802df548
export const RAM_b_area_trd_part_2_trd_05_80232DF8 = 0x80232df8
export const RAM_b_area_hos_magikoopa_defenseTable_8021D48C = 0x8021d48c
export const RAM_init_model_animators = 0x802cd57c
export const RAM_fx_star = 0x8006fe30
export const RAM_HES_Item_DojoCard2 = 0x80081f08
export const RAM_GetAnimatedRotationByTreeIndex = 0x802cd8f8
export const RAM_HES_Item_UltraShroom = 0x80086798
export const RAM_CamPresetUpdate_I = 0x8024a990
export const RAM_firework_rocket_render = 0xe010a4a0
export const RAM_moving_cloud_main = 0xe00e8000
export const RAM_D_E00AC8D0 = 0xe00ac8d0
export const RAM_dro_02_8024486C = 0x8024486c
export const RAM_D_80244170_C755F0 = 0x80244170
export const RAM_dro_02_D_8024B898_974A58 = 0x8024b898
export const RAM_nok_01_Quizmo_SetVannaAnim_Clap = 0x80241870
export const RAM_flo_14_pad_4F24 = 0x80244f24
export const RAM_D_E00DE84C = 0xe00de84c
export const RAM_b_area_isk_part_1_swooper_8021C880 = 0x8021c880
export const RAM_func_8024007C_BBD21C = 0x8024007c
export const RAM_b_area_kzn_putrid_piranha_idle_8021B244 = 0x8021b244
export const RAM_b_area_trd_part_2_yellow_ninja_koopa_effectUnk = 0x802367f0
export const RAM_D_80224D90 = 0x80224d90
export const RAM_sam_03_MeleeHitbox_CanSeePlayer = 0x80240258
export const RAM_flo_17_lavaResetList_8024369C = 0x8024369c
export const RAM_battle_item_coconut_UseItem = 0x802a1544
export const RAM_D_8024DD60_C0B5E0 = 0x8024dd60
export const RAM_dro_01_npcSettings_80245160 = 0x80245160
export const RAM_kkj_01_PatrolAI_LoiterInit = 0x80240400
export const RAM_D_E0040840 = 0xe0040840
export const RAM_D_8014C160 = 0x8014c160
export const RAM_D_090018B8 = 0x090018b8
export const RAM_ui_mamar_disabled_pal = 0x800fd360
export const RAM_omo_11_SuperBlock_ShowSelectPartnerMenu = 0x8024095c
export const RAM_action_update_use_munchlesia = 0x802b6000
