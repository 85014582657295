
{
    "names": [
        "Toad Town",
        null,
        "Normal Battle",
        "Special Battle",
        "Jr. Troopa Battle",
        "Final Bowser Battle",
        null,
        "Goomba King Battle",
        "Koopa Bros. Battle",
        "Fake Bowser Battle",
        "Tutankoopa Battle",
        "Tubba Blubba Battle",
        "General Guy Battle",
        "Lava Piranha Battle",
        "Huff N. Puff Battle",
        "Crystal King Battle",
        "Goomba Village",
        "Pleasant Path",
        "Fuzzy Attack",
        "Koopa Village",
        "Koopa Fortress",
        "Dry Dry Outpost",
        "Mt. Rugged",
        "Dry Dry Desert",
        "Dry Dry Ruins",
        "Ruins Basement",
        "Forever Forest",
        "Boo's Mansion",
        "Cheerful Boo's Mansion",
        "Gusty Gulch",
        "Tubba's Manor",
        "Tubba Escape",
        "Shy Guy Toybox",
        "Toybox Train",
        "Creepy Toybox",
        null,
        "Jade Jungle",
        "Deep Jungle",
        "Yoshi's Village",
        "Yoshi's Panic",
        "Raphael Raven",
        "Mt. Lavalava",
        "Volcano Escape",
        "Star Way Opens",
        "Master Battle",
        "Radio Island Sounds",
        "Radio Hot Hits",
        "Radio Golden Oldies",
        "Flower Fields Cloudy",
        "Flower Fields Sunny",
        "Cloudy Climb",
        "Puff Puff Machine",
        "Sun Tower Cloudy",
        "Sun Tower Sunny",
        null,
        "Crystal Palace",
        "Shiver City",
        "Penguin Mystery",
        "Shiver Snowfield",
        "Shiver Mountain",
        "Starborn Valley",
        "Merlar Theme",
        "Mail Call",
        "Peach's Castle Party",
        "Chapter End",
        "Chapter Start",
        "Item Upgrade",
        null,
        "Phonograph Music",
        "Tutankoopa Theme",
        "Kammy Koopa Theme",
        "Jr. Troopa Theme",
        "Bullet Bill Assault",
        "Monty Mole Assault",
        "Shy Guy Invasion",
        "Toad Town Tunnels",
        "Whale Theme",
        "Forever Forest Warning",
        "Yoshi Kids Found",
        "Unused Fanfare",
        "Goomba King Theme",
        "Koopa Bros. Interlude",
        "Koopa Bros. Theme",
        "Tutankoopa Warning",
        "Tutankoopa Revealed",
        "Tubba Blubba Theme",
        "General Guy Theme",
        "Lava Piranha Theme",
        "Huff N. Puff Theme",
        "Crystal King Theme",
        "Blooper Theme",
        "Miniboss Battle",
        "Monstar Theme",
        "Club 64",
        "Unused Opening",
        "Bowser's Castle Falls",
        "Star Haven",
        "Shooting Star Summit",
        "Starship Theme",
        "Star Sanctuary",
        "Bowser's Castle",
        "Bowser's Castle Caves",
        "Bowser Theme",
        "Bowser Battle",
        "Peach Wishes",
        "File Select",
        "Main Theme",
        "Bowser Attacks",
        "Mario Falls",
        "Peach Appears",
        "The End",
        "Recovered Star Rod",
        "Twink Theme",
        "Stirring Cake",
        "Gourmet Guy Freakout",
        "Prisoner Peach Theme",
        "Peach Mission",
        "Peach Sneaking",
        "Peach Caught",
        "Peach Quiz Intro",
        "Star Spirit Theme",
        "Penguin Whodunnit",
        "Penguin Wakes Up",
        "Magic Beanstalk",
        "Merlee Spell",
        "Lakilester Theme",
        "Goomba Bros Retreat",
        "Sunshine Returns",
        "Riding The Rails",
        "Riding The Whale",
        "New Partner",
        "Dry Dry Ruins Appear",
        "Candy Canes",
        "Playroom",
        "Moustafa Theme",
        "Game Over",
        "Taking Rest",
        "Flower NPC Theme",
        "Flower Gate Appears",
        "Battle End",
        "Pop Diva Song",
        "Boo Minigame",
        "Level Up",
        null,
        "Parade Day",
        null,
        null,
        "Mario Bros House",
        "Intro Story",
        "New Partner JP"
    ]
}
