$trackHeight: 70px;

.table {
    border-collapse: collapse;

    width: 100%;

    user-select: none;

    /*tr {
        &:not(:first-child) {
            background: var(--spectrum-global-color-gray-100);
        }

        &:nth-child(even) {
            background: var(--spectrum-global-color-gray-75);
        }
    }*/

    td {
        padding: 0;
    }
}

.track {
    height: $trackHeight;

    &:nth-child(even) {
        background: var(--spectrum-global-color-gray-75);
    }
}

td.trackHead {
    width: 8em;
    border-right: 1px solid var(--spectrum-global-color-gray-100);

    padding: 4px 16px;
    height: 100%;

    .trackName {
        color: var(--spectrum-global-color-gray-900);
        margin-bottom: 4px;
    }
}

td.loop {
    color: black;
    background: var(--yellow);
    font-weight: 600;

    padding: 0 4px;
}

.pianoRollThumbnail {
    --color-800: #ffffffaa;
    --color-500: var(--spectrum-global-color-blue-500);
    --color-400: var(--spectrum-global-color-blue-400);
    --color-200: #000000cc;

    border: 1px solid var(--spectrum-global-color-gray-300);
    background: linear-gradient(var(--color-500) 0%, var(--color-400) 100%);
    color: #ffffffee;
    border-radius: 6px;
    overflow: hidden;

    height: $trackHeight;

    &:focus-visible {
        outline: 3px solid var(--spectrum-global-color-yellow-500);
    }

    .segmentName {
        padding: 6px 10px;
    }

    &.selected {
        border-color: var(--color-800);

        .segmentName {
            color: var(--color-200);
            background: var(--color-800);
        }
    }

    &.hasInterestingParentTrack {
        --color-500: var(--spectrum-global-color-indigo-500);
        --color-400: var(--spectrum-global-color-indigo-400);
    }

    &.drumRegion {
        --color-500: var(--spectrum-global-color-seafoam-500);
        --color-400: var(--spectrum-global-color-seafoam-400);
    }

    &.disabledRegion {
        --color-800: var(--spectrum-global-color-gray-800);
        --color-500: var(--spectrum-global-color-gray-500);
        --color-400: var(--spectrum-global-color-gray-400);
        --color-200: var(--spectrum-global-color-gray-200);
    }
}
