.input {
    color: inherit;
    background: transparent;
    border: 0;
    font: inherit;
    text-align: center;

    cursor: row-resize;

    &:active,
    &:focus {
        color: var(--spectrum-global-color-gray-900, #fff);
        outline: none;
    }

    // Hide spinner arrows
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

body:has(.input:active) * {
    cursor: row-resize !important;
}
